define("budgeta/templates/components/delete-used-dimension-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XLXL9Aq9",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"model\",\"isUsed\"]]],null,1,0],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"append\",[\"helper\",[\"t\"],[\"delete-used-dimension-group.are-you-sure\"],null],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"t\"],[\"delete-used-dimension-group.deleting\"],null],false],[\"text\",\"\\n  \"],[\"append\",[\"unknown\",[\"model\",\"dimension\",\"name\"]],false],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"t\"],[\"delete-used-dimension-group.delete-data\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"t\"],[\"delete-used-dimension-group.deleting\"],null],false],[\"text\",\"\\n  \"],[\"append\",[\"unknown\",[\"model\",\"dimension\",\"name\"]],false],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"t\"],[\"delete-used-dimension-group.delete-data-where-used\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/delete-used-dimension-group.hbs"
    }
  });
});