define("budgeta/components/advanced-chart", ["exports", "budgeta/components/modal-new", "budgeta/templates/components/modal-new"], function (_exports, _modalNew, _modalNew2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    layout: _modalNew2.default,
    classNames: ["advanced-chart"],
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var wrapper = this.$(".select-list-wrapper");
      wrapper.sortable({
        cursor: "move",
        update: function update() {
          _this.get("controllertmp").send("saveOrder", wrapper.find(".kpi-selection").map(function (idx, el) {
            return {
              type: Ember.$(el).data("id"),
              uniqueId: Ember.$(el).data("key")
            };
          }).get());
        }
      });
      wrapper.disableSelection();
    }
  });
});