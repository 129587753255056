define("budgeta/components/budgeta-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["budgeta-switch"],
    actions: {
      switchToggel: function switchToggel() {
        this.set("switchState", !this.get("switchState"));
      }
    }
  });
});