define("budgeta/serializers/budget", ["exports", "ember-data", "budgeta/serializers/application", "budgeta/config/environment"], function (_exports, _emberData, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attrs = {
    transactions: {
      embedded: "always"
    },
    scenarios: {
      embedded: "always"
    },
    comments: {
      embedded: "always"
    },
    dimensionsMapping: {
      embedded: "always"
    },
    dimensionsValuesMapping: {
      embedded: "always"
    },
    actualAccounts: {
      embedded: "always"
    },
    children: {
      serialize: false,
      deserialize: "ids"
    }
  };
  if (_environment.default.environment !== "test") {
    attrs.dimensions = {
      embedded: "always"
    };
  }
  var _default = _exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: attrs
  });
});