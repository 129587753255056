define("budgeta/components/intacct-match-stat-accounts-to-dimensions", ["exports", "budgeta/services/ember-service-container", "lodash/lodash", "ember-inflector", "budgeta/utils/dimensions"], function (_exports, _emberServiceContainer, _lodash, _emberInflector, _dimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNameBindings: [":intacct-match-stat-accounts-to-dimensions"],
    filteredStatAccounts: Ember.computed("data.intacctStatisticalAccounts", function () {
      return this.get("data.intacctStatisticalAccounts").filterBy("selected", true);
    }),
    tooltipText: Ember.computed("intl", function () {
      return this.get("intl").t("intacct-match-stat-accounts-to-dimensions.tooltip");
    }),
    topLevelDimension: Ember.computed("data.statTopLevelDim", function () {
      return this.get("data.statTopLevelDim") || "";
    }),
    levelDimensionsMap: undefined,
    dimensionsList: Ember.computed("data.dimensions", function () {
      var _this = this;
      var result = [];
      (this.get("data.dimensions") || []).filter(function (dim) {
        return dim.selected && dim.values && (dim.values.length || !dim.importNoValues);
      }).forEach(function (dim) {
        if (_this.get("data.singleEntity") !== "1" || dim.internalName.toLowerCase() !== "location") {
          result.pushObject({
            isSub: false,
            id: dim.id,
            dimension: dim,
            text: (0, _emberInflector.pluralize)(dim.dimension || "")
          });
        }
        var maxLevel = Math.max.apply(Math, dim.values.map(function (val) {
          return parseInt(val.level);
        }));
        for (var i = 1; i <= maxLevel; i++) {
          if (dim.values.findBy("level", i)) {
            result.pushObject({
              isSub: true,
              subLevel: i,
              id: dim.id,
              dimension: dim,
              text: intl.t("general.intacct-match-stat-accounts-to-dimensions-1", {
                var1: (0, _emberInflector.pluralize)(dim.dimension || "").toLowerCase()
              }) + (i !== 1 ? " ".concat(i) : "")
            });
          }
        }
      });
      return result;
    }),
    topLevelDimensions: Ember.computed("dimensionsList.[]", "filteredStatAccounts.@each.levelDimensions", function () {
      var disableLevelDimension = function disableLevelDimension(dim) {
        if (selectedLevelDimensionsMap[dim.id] && (dim.subLevel || -1) >= selectedLevelDimensionsMap[dim.id]) {
          return {
            isSub: dim.isSub,
            subLevel: dim.subLevel,
            id: dim.id,
            dimension: dim.dimension,
            text: dim.text,
            disabled: true
          };
        }
        return dim;
      };
      var selectedLevelDimensions = _lodash.default.flatten(this.get("filteredStatAccounts").mapBy("levelDimensions"));
      if (!selectedLevelDimensions.length) {
        return this.get("dimensionsList");
      }
      var selectedLevelDimensionsMap = {};
      selectedLevelDimensions.forEach(function (dim) {
        if (!selectedLevelDimensionsMap[dim.id]) {
          selectedLevelDimensionsMap[dim.id] = dim.isSub ? dim.subLevel : -1;
        } else if (selectedLevelDimensionsMap[dim.id] > (dim.subLevel || -1)) {
          selectedLevelDimensionsMap[dim.id] = dim.isSub ? dim.subLevel : -1;
        }
      });
      return this.get("dimensionsList").map(function (dim) {
        return disableLevelDimension(dim);
      });
    }),
    levelDimensions: Ember.computed("dimensionsList.[]", "topLevelDimension", function () {
      var topLevelDimension = this.get("topLevelDimension");
      var disableTopDimension = function disableTopDimension(dim) {
        if (topLevelDimension.id === dim.id && (!dim.isSub || topLevelDimension.isSub && dim.subLevel <= topLevelDimension.subLevel)) {
          return {
            isSub: dim.isSub,
            subLevel: dim.subLevel,
            id: dim.id,
            dimension: dim.dimension,
            text: dim.text,
            disabled: true
          };
        }
        return dim;
      };
      if (!topLevelDimension) {
        return this.get("dimensionsList");
      }
      return this.get("dimensionsList").map(function (dim) {
        return disableTopDimension(dim);
      });
    }),
    actions: {
      attributeChanged: function attributeChanged(account, selectedValues) {
        var levelDimensions = _dimensions.default.sortDimensionsByLevel(selectedValues);
        Ember.set(account, "levelDimensions", levelDimensions);
      },
      topLevelChanged: function topLevelChanged(value) {
        this.set("topLevelDimension", value);
        this.set("data.statTopLevelDim", value);
      },
      removeItem: function removeItem() {
        this.send("topLevelChanged", "");
      },
      setDropdownStatus: function setDropdownStatus(isOpen) {
        this.set("isDropdownOpen", isOpen);
      }
    }
  });
});