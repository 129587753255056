define("budgeta/controllers/model-store", ["exports", "budgeta/services/ember-service-container", "budgeta/controllers/modal", "budgeta/utils/model-store"], function (_exports, _emberServiceContainer, _modal, _modelStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _modal.default.extend({
    mainController: Ember.inject.controller("main"),
    trackingEvents: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    isNonProfitBudget: Ember.computed.alias("budgetService.isNonProfitBudget"),
    activeModelType: Ember.computed("modelTypes.@each.active", function () {
      return this.get("modelTypes").findBy("active");
    }),
    modelTypes: Ember.computed("modelStore", "isNonProfitBudget", function () {
      var isNonProfitBudget = this.get("isNonProfitBudget");
      var removeGroups = isNonProfitBudget ? [intl.t("general.title_1695611600217"), intl.t("general.title_1695611600152"), intl.t("general.title_1695611600173"), intl.t("general.title_1695611597971"), intl.t("general.title_1695611600163")] : [];
      var types = [{
        title: intl.t("general.title_1695611598297"),
        active: true,
        items: []
      }];
      this.get("modelStore").forEach(function (item) {
        var typesArray = [intl.t("general.title_1695611598297")];
        if (removeGroups.indexOf(Ember.get(item, "title")) !== -1) {
          return;
        }
        typesArray.pushObjects(Ember.get(item, "types"));
        typesArray.forEach(function (type) {
          var thisType = types.findBy("title", type);
          if (!thisType) {
            thisType = {
              title: type,
              items: [item]
            };
            types.pushObject(thisType);
          } else {
            thisType.items.pushObject(item);
          }
        });
      });
      return types.sortBy("title");
    }),
    modelStore: _modelStore.default.store,
    actions: {
      changeModelType: function changeModelType(type) {
        this.get("modelTypes").setEach("active", false);
        Ember.set(type, "active", true);
      }
    }
  });
});