define("budgeta/components/integration-wizard-export-missing-locations", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":export-missing-mapping"],
    locationValues: Ember.computed("model.exportDimensions", function () {
      var locationDim = this.get("model.exportDimensions").findBy("dimension.type", "location");
      var values = Ember.get(locationDim, "dimension.values");
      return values.map(function (val) {
        return {
          name: val.displayName,
          id: val.uniqueId
        };
      });
    }),
    didRender: function didRender() {
      this.sendAction(this.get("continueActionName"), this.get("model.isSkipSteps.exportMissingLocations") ? intl.t("general.skip_1698234255443") : "");
    },
    actions: {
      attributeChanged: function attributeChanged() {
        var value = arguments[0];
        if (arguments[1]) {
          var isChecked = arguments[1].isChecked;
          if (isChecked) {
            var checkedLines = this.get("model.missingLocations").filterBy("checked", true);
            checkedLines.forEach(function (line) {
              Ember.set(line, "location", value);
              Ember.set(line, "checked", false);
            });
          }
          this.set("model.isSkipSteps.exportMissingLocations", false);
          this.sendAction(this.get("continueActionName"), "");
        }
      }
    }
  });
});