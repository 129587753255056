define("budgeta/templates/components/dimension-tree-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cX4jLjYu",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"react-component\"],null,[[\"componentName\",\"deleteText\",\"rename\",\"enterNameText\",\"data\",\"parentElement\",\"onChange\",\"showAddButton\",\"readOnly\",\"parent\",\"isFlat\"],[\"BigTree\",[\"helper\",[\"t\"],[\"uic.delete\"],null],[\"helper\",[\"t\"],[\"uic.rename\"],null],[\"helper\",[\"t\"],[\"uic.enter-name\"],null],[\"get\",[\"treeData\"]],[\"get\",[\"parentElement\"]],[\"helper\",[\"action\"],[[\"get\",[null]],\"treeChange\"],null],false,[\"get\",[\"isReadOnly\"]],[\"get\",[\"activeDimension\",\"id\"]],[\"get\",[\"isFlatTree\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/dimension-tree-values.hbs"
    }
  });
});