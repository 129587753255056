define("budgeta/components/wizard-question-radio-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":wizard-question-radio", ":budgeta-checkbox", "disabled:disabled"],
    tagName: "label",
    layoutName: "components/wizard-question-radio",
    inputId: Ember.computed("elementId", function () {
      return this.get("elementId") + "_input";
    }),
    type: "radio",
    typeRadio: Ember.computed.equal("type", "radio"),
    matchByType: false,
    checked: Ember.computed("selectedOption", function () {
      return this.get("selectedOption") === this.get("value") || typeof this.get("selectedOption") === "string" && this.get("value").toString && this.get("value").toString() === this.get("selectedOption");
    }),
    click: function click(event) {
      event.preventDefault();
      var $el = Ember.$(event.target);
      var inputEl = document.getElementById($el.attr("for"));
      if ($el.is("label") && inputEl) {
        this.set("selectedOption", inputEl.value);
      }
    }
  });
});