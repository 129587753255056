define("budgeta/components/model-store", ["exports", "budgeta/components/modal-new"], function (_exports, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.get("controllertmp.modelTypes").setEach("active", false);
      this.set("controllertmp.modelTypes.firstObject.active", true);
    }
  });
});