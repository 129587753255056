define("budgeta/components/integration-wizard-pnl-accounts", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["table-editor"],
    budgetService: Ember.inject.service(),
    mapOptions: _const.default.DEFAULT_OPENING_BALANCES.map(function (line) {
      return {
        id: line.name,
        text: line.displayName
      };
    }),
    toggleAll: false,
    toggleAllObserver: Ember.observer("toggleAll", function () {
      this.get("model.pnlAccounts").setEach("checked", this.get("toggleAll"));
    }),
    change: function change(e) {
      var el = Ember.$(e.target);
      var lineEl = el.closest(".table-edit-line");
      if (!el.is('[type="checkbox"]') && lineEl && lineEl.length) {
        this.get("model.pnlAccounts").filterBy("checked").setEach("mapping", el.val());
      }
    },
    click: function click(e) {
      var target = Ember.$(e.target);
      var wrapper = target.closest(".budgeta-checkbox");
      if (e.shiftKey && wrapper.length && !wrapper.find('input[type="checkbox"]').is(":checked")) {
        // find the last selected line and select all the lines in between
        wrapper.closest(".ember-list-view.ember-list-view-list > div > div > div").prevUntil(":has(:checked)").each(function (idx, line) {
          Ember.$(line).find(".budgeta-checkbox label").click();
        });
      }
    }
  });
});