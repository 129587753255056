define("budgeta/routes/model-not-found", ["exports", "budgeta/mixins/budgeta-route"], function (_exports, _budgetaRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_budgetaRoute.default, {
    trackingEvents: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    afterModel: function afterModel() {
      this.get("budgetService").removeBudgetInfo();
      this.set("budgetService.budgetInfoToken", undefined);
      this.set("budgetService.isPartialInfo", undefined);
      this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.MODEL_NOT_FOUND"));
    }
  });
});