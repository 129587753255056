define("budgeta/components/table-edit-line", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/budget-utils", "ember-validations", "budgeta/utils/dimensions"], function (_exports, _emberServiceContainer, _budgetUtils, _emberValidations, _dimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend(_emberValidations.default, {
    cache: Ember.inject.service(),
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    activityLog: Ember.inject.service(),
    lastInputValue: null,
    budgetAttributes: Ember.computed.alias("line.budgetAttributesWithScenarios"),
    isNoGrouping: Ember.computed.alias("line.isNoGrouping"),
    dimensionData: Ember.computed("budgetAttributes.dimensions", "line.inheritedAttributes.dimensions", function () {
      return _dimensions.default.getDimensionsForLineEdit(this);
    }),
    mouseEnter: function mouseEnter() {
      if (this.get("line.moveDestinations")) {
        return;
      }
      this.set("line.moveDestinations", _budgetUtils.default.getMoveDestinations(this.get("line")));
    },
    checkboxId: Ember.computed("elementId", function () {
      return this.get("elementId") + "-checkbox";
    }),
    level: Ember.computed("line.parent.id", function () {
      return this.get("line.location.length") || 0;
    }),
    // Handle the date picker change event when changing the date
    change: function change(event) {
      var $event = Ember.$(event.target);
      if ($event.parent(".column.end-date-column").length || $event.parent(".column.start-date-column").length) {
        var currentValue = $event.find("input")[0].value;
        if (currentValue !== this.get("lastInputValue")) {
          this.send("attributeChanged", $event.parent(".column.start-date-column").length ? "startDate" : null);
        }
      }
    },
    focusIn: function focusIn(event) {
      this.set("lastInputValue", Ember.$(event.target)[0].value);
    },
    focusOut: function focusOut(event) {
      var currentValue = Ember.$(event.target)[0].value;
      if (currentValue !== this.get("lastInputValue")) {
        this.send("attributeChanged");
      }
    },
    tagsValue: Ember.computed("budgetAttributes.tags", "budgetAttributes.tags.length", function () {
      var value = (this.get("budgetAttributes.tags") || Ember.A([])).mapBy("name").join(", ");
      return value && value !== "" ? value : undefined;
    }),
    inheritedTagsValue: Ember.computed("tagsValue", "line.inheritedAttributes.tags", "line.inheritedAttributes.tags.length", "budgetAttributes.tags", "budgetAttributes.tags.length", function () {
      var tagsValue = this.get("tagsValue");
      var value = (this.get("line.inheritedAttributes.tags") || Ember.A([])).mapBy("name");
      var tags = (this.get("budgetAttributes.tags") || Ember.A([])).mapBy("name");
      value.removeObjects(tags);
      if (value && value.length) {
        return value.join(", ") + (tagsValue && tagsValue.length ? ", " : "");
      }
      return undefined;
    }),
    tagsTitle: Ember.computed("tagsValue", "inheritedTagsValue", function () {
      var tagsValue = this.get("tagsValue"),
        inheritedTagsValue = this.get("inheritedTagsValue");
      if (tagsValue && inheritedTagsValue) {
        return inheritedTagsValue + ", " + tagsValue;
      }
      return inheritedTagsValue || tagsValue || "";
    }),
    moveTitle: Ember.computed("line.moveDestinations.length", function () {
      return this.get("line.moveDestinations.length") ? intl.t("general.move_1698234255246") : intl.t("general.no_destinations_1698234255246");
    }),
    inheritedStartDate: Ember.computed.alias("line.parent.inheritedAttributes.startDate"),
    inheritedEndDate: Ember.computed.alias("line.parent.inheritedAttributes.endDate"),
    validations: {
      "budgetAttributes.endDate": {
        "required-when": {
          ref: "line.isRoot"
        },
        "date-valid": true,
        "date-after": {
          otherDates: ["startDate||inheritedStartDate"]
        }
      },
      "budgetAttributes.startDate": {
        "required-when": {
          ref: "line.isRoot"
        },
        "date-valid": true,
        "date-before": {
          otherDates: ["endDate||inheritedEndDate"]
        }
      }
    },
    actions: {
      confirmBudgetStartDateChange: function confirmBudgetStartDateChange() {
        this.sendAction("confirmBudgetStartDateChange", arguments[0]);
      },
      duplicateLine: function duplicateLine(line) {
        if (!Ember.get(line, "isRoot") && !Ember.get(line, "showOnce") && !Ember.get(line, "isNoGrouping")) {
          this.sendAction("duplicateLine", line, undefined, "table", "general_table");
          this.get("activityLog").log("general table - duplicate line");
        }
      },
      flag: function flag(line) {
        this.sendAction("flag", line);
      },
      move: function move(line) {
        this.sendAction("moveSelected", line, this.get("line.moveDestinations"));
      },
      deleteLine: function deleteLine(line) {
        if (!Ember.get(line, "isRoot")) {
          this.sendAction("deleteLine", line, undefined, undefined, "table", "general_table");
          this.get("activityLog").log("general table - delete line");
        }
      },
      attributeChanged: function attributeChanged(attr) {
        var _this = this;
        this.validate().then(function () {
          if (attr === "startDate") {
            // check for root start date change
            if (_budgetUtils.default.hasOpenningBalances(_this.get("line"))) {
              _this.sendAction(_this.get("confirmBudgetStartDateChange"), function (confirmed) {
                if (!confirmed) {
                  _this.set("budgetAttributes.startDate", moment(_this.get("lastInputValue"), "MM/YYYY").toDate());
                }
                _this.sendAction("save", _this.get("line"), "table", "general_table");
              });
            }
          } else {
            _this.sendAction("save", _this.get("line"), "table", "general_table");
          }
          _this.get("activityLog").log("general table - input");
        }).catch(function () {
          _this.get("line").reload();
          noty({
            type: "warning",
            text: _this.get("errors.budgetAttributes.startDate.firstObject") || _this.get("errors.budgetAttributes.endDate.firstObject")
          });
        });
      }
    }
  });
});