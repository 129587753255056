define("budgeta/components/budget-copy", ["exports", "budgeta/utils/budget-utils", "budgeta/services/ember-service-container"], function (_exports, _budgetUtils, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    tagName: "li",
    clicked: false,
    classNameBindings: ["budget.inheritedIsLocked:disable:", "hideNoGrouping:disable"],
    attributeBindings: ["data-hook"],
    "data-hook": Ember.computed("actionName", function () {
      return "budget-copy-".concat(this.get("actionName"));
    }),
    isCopy: Ember.computed("actionName", function () {
      return this.get("actionName") === "copy";
    }),
    hideNoGrouping: Ember.computed("budget.isNoGrouping", "actionName", function () {
      return this.get("budget.isNoGrouping") && this.get("actionName") === "copy" || this.get("budget.isNoGrouping") && this.get("budget.budgetModule.isGroup") && this.get("actionName") === "move";
    }),
    triggerId: Ember.computed("elementId", function () {
      return this.get("elementId") + "-trigger";
    }),
    click: function click() {
      this.set("clicked", true && !this.get("budget.inheritedIsLocked") && !this.get("hideNoGrouping"));
      this.send(this.get("actionName"));
    },
    heading: Ember.computed("actionName", "budget.isRoot", "budget.budgetType", function () {
      if (this.get("budget.isRoot") && this.get("budget.budgetType") === "group-department") {
        return intl.t("budget-copy.".concat(this.get("actionName"), ".header-group-department"));
      } else {
        return intl.t("budget-copy.".concat(this.get("actionName"), ".header"));
      }
    }),
    destinations: Ember.computed("budget", "allBudgets.[]", "budgetTypes", "clicked", function () {
      var result = Ember.A([]),
        _this = this;
      if (!this.get("clicked")) {
        return result;
      }
      if (_this.get("budget")) {
        result = _budgetUtils.default.findPossibleParents(_this.get("budget"));

        // sort by tree order
        result = this.get("treeManager.budgetsList").filter(function (line) {
          return result.includes(Ember.get(line, "budget"));
        }).map(function (line) {
          return Ember.get(line, "budget");
        });
      }
      return result;
    }),
    isEmptyClass: Ember.computed("hasDestinations", function () {
      return this.get("hasDestinations") ? "" : "empty-list";
    }),
    hasDestinations: Ember.computed("destinations.length", function () {
      return this.get("destinations.length") > 0;
    }),
    actions: {
      copy: function copy(budget, dest) {
        this.sendAction("copy", budget, dest);
      },
      move: function move(budget, dest) {
        if (this.get("hideNoGrouping")) {
          return;
        }
        this.sendAction("move", budget, dest);
      },
      listClickOnElement: function listClickOnElement(item) {
        this.sendAction(this.get("actionName"), this.get("budget"), item);
      }
    }
  });
});