define("budgeta/templates/edit-line-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vF9c+Nej",
    "block": "{\"statements\":[[\"block\",[\"edit-line-modal\"],null,[[\"controllertmp\",\"confirmDisabled\",\"close\",\"title\",\"templateName\",\"hideCancel\",\"hideFooter\",\"addClass\",\"lastStep\",\"confirmActionName\",\"confirm\"],[[\"get\",[null]],[\"get\",[\"confirmDisabled\"]],[\"helper\",[\"action\"],[[\"get\",[null]],\"close\"],null],[\"get\",[\"title\"]],[\"get\",[\"templateName\"]],[\"get\",[\"hideCancel\"]],[\"get\",[\"hideFooter\"]],\"edit-line-modal-dialog\",true,[\"get\",[\"confirmActionName\"]],[\"helper\",[\"action\"],[[\"get\",[null]],\"confirm\"],null]]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"id\",\"budget-attributes-wrapper\"],[\"dynamic-attr\",\"class\",[\"concat\",[\"scrollable\\n      \",[\"helper\",[\"if\"],[[\"get\",[\"model\",\"inheritedReadOnlyOrLock\"]],\"readonly-mode\"],null],\"\\n      \",[\"helper\",[\"if\"],[[\"get\",[\"model\",\"isScenarioRemoved\"]],\"disabled\"],null],\"\\n      my-qtip-container\"]]],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"budget-line-input\"],null,[[\"disableNameEdit\",\"disableMedia\",\"readOnly\",\"budgetLine\",\"openFormulaEditor\",\"errors\",\"changed\"],[[\"get\",[\"model\",\"hasInherited\"]],true,[\"get\",[\"model\",\"inheritedReadOnlyOrLock\"]],[\"get\",[\"model\"]],\"openFormulaEditor\",[\"get\",[\"errors\"]],\"changed\"]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/edit-line-modal.hbs"
    }
  });
});