define("budgeta/templates/components/content-budget-tree-group-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3XbW7vFF",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"action\",\"bubbles\",\"name\"],[\"collapse-tree\",\"collapse\",false,\"arrow_down\"]]],false],[\"text\",\"\\n\"],[\"open-element\",\"span\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"budget-name \",[\"helper\",[\"if\"],[[\"get\",[\"budget\",\"isScenarioRemoved\"]],\"scenarioRemovedWrapper\"],null]]]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"missing-name\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"content-budget-tree-group-loading.loading\"],null],true],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"unless\"],[[\"get\",[\"hideType\"]]],null,0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"type\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"budget\",\"displayBudgetType\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/content-budget-tree-group-loading.hbs"
    }
  });
});