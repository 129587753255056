define("budgeta/controllers/change-password", ["exports", "budgeta/controllers/login"], function (_exports, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _login.default.extend({
    templateName: "login"
  });
});