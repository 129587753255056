define("budgeta/controllers/add-to-dashboard-modal", ["exports", "budgeta/services/ember-service-container", "budgeta/controllers/modal", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _modal, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/avoid-leaking-state-in-ember-objects */

  var DashboardChart = Ember.Object.extend({
    dashboardIncludeLength: Ember.computed("dashboardInclude.[]", function () {
      return this.get("dashboardInclude").length;
    }),
    linesLength: Ember.computed("lines.[]", function () {
      return this.get("lines").length;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      // call computed properties to ensure that they are computed for dependent keys in front-end/app/models/budget.js
      this.get("dashboardIncludeLength");
      this.get("linesLength");
    }
  });
  var _default = _exports.default = _modal.default.extend({
    mainBudgetDashboardController: Ember.inject.controller("main/budget/dashboard"),
    charts: Ember.computed("model.modelObject", "model.modelObject.budgetAttributes.dashboardCharts.length", function () {
      var _this = this;
      if (!this.get("model.modelObject.budgetAttributes.dashboardCharts")) {
        this.set("model.modelObject.budgetAttributes.dashboardCharts", []);
      }
      if (this.get("model.modelObject.budgetAttributes.dashboardLines.length")) {
        this.get("model.modelObject.budgetAttributes.dashboardCharts").pushObject(DashboardChart.create({
          title: this.get("model.modelObject.budgetAttributes.dashboardTitle"),
          lines: this.get("model.modelObject.budgetAttributes.dashboardLines"),
          columns: [],
          chartType: "basicLine",
          dashboardInclude: [],
          _id: this.get("model.modelObject.budgetAttributes._id")
        }));
        this.set("model.modelObject.budgetAttributes.dashboardLines", []);
        this.set("model.modelObject.budgetAttributes.dashboardTitle", undefined);
      }
      if (!this.get("model.modelObject.budgetAttributes.dashboardCharts.length")) {
        this.send("add");
      }
      var modelCharts = this.get("model.modelObject.budgetAttributes.dashboardCharts"),
        dashboardList = this.get("dashboardList"),
        dashboardCharts = this.get("dashboardCharts") || Ember.A([]);
      modelCharts = _const.default.getModelDashboardCharts(this.get("model.modelObject.id"), this.get("model.modelObject.uniqueId"), modelCharts, dashboardList, dashboardCharts);
      this.set("model.charts", modelCharts);

      // BUD-7381 verify that all line references exist and remove the ones that don't
      modelCharts.forEach(function (chart) {
        Ember.set(chart, "lines", Ember.getWithDefault(chart, "lines", []).filter(function (line) {
          return _this.getWithDefault("model.modelObject.children", []).mapBy("uniqueId").includes(line);
        }));
        Ember.set(chart, "columns", Ember.getWithDefault(chart, "columns", []).filter(function (line) {
          return _this.getWithDefault("model.modelObject.children", []).mapBy("uniqueId").includes(line);
        }));
      });
      return modelCharts;
    }),
    allChartTypes: [{
      value: "basicColumn",
      name: intl.t("general.name_1695611601902")
    }, {
      value: "stackedColumn",
      name: intl.t("general.name_1695611601893")
    }, {
      value: "stackedPercenatageColumn",
      name: intl.t("general.name_1695611601882")
    }, {
      value: "lineAndColumn",
      name: intl.t("general.name_1695611601872")
    }, {
      value: "basicLine",
      name: intl.t("general.name_1695611601861")
    }, {
      value: "basicLineWithLabels",
      name: intl.t("general.name_1695611601851")
    }, {
      value: "pie",
      name: intl.t("general.name_1695611601840")
    }, {
      value: "basicArea",
      name: intl.t("general.name_1695611601829")
    }],
    dashboardList: Ember.computed.alias("model.modelObject.root.dashboardList"),
    dashboardCharts: Ember.computed.alias("model.modelObject.root.dashboardOrder"),
    actions: {
      add: function add() {
        var firstDashboard = this.get("model.modelObject.root.dashboardList.firstObject");
        var dashboardInclude = [];
        if (firstDashboard) {
          dashboardInclude.push({
            title: Ember.get(firstDashboard, "title"),
            dashboardId: Ember.get(firstDashboard, "uniqueId"),
            selected: true
          });
        }
        this.get("model.modelObject.budgetAttributes.dashboardCharts").pushObject(DashboardChart.create({
          title: "",
          lines: [],
          columns: [],
          dashboardInclude: dashboardInclude,
          chartType: "basicLine",
          _id: undefined,
          forceSelect: true
        }));
        this.get("model.modelObject").save();
      },
      delete: function _delete(idx) {
        this.get("model.modelObject.budgetAttributes.dashboardCharts").removeAt(idx);
      },
      confirm: function confirm() {
        var modelCharts = this.get("model.modelObject.budgetAttributes.dashboardCharts");
        for (var i = 0; i < modelCharts.length; i++) {
          if (Ember.get(modelCharts[i], "title") && !Ember.get(modelCharts[i], "lines.length")) {
            return;
          }
        }
        this._super();
      }
    }
  });
});