define("budgeta/components/test-calculations", ["exports", "budgeta/components/modal-new"], function (_exports, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    classNames: ["test-calculations"]
  });
});