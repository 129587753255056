define("budgeta/components/forecast-budget-actual-nodiff-row", ["exports", "budgeta/components/forecast-row-master"], function (_exports, _forecastRowMaster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _forecastRowMaster.default.extend({
    displayActuals: Ember.computed("displayValues", "headerItems", "headerItems.@each.hasActuals", "headerItems.@each.isClosed", function () {
      var headerItems = this.get("headerItems");
      (this.get("displayValues") || []).forEach(function (displayValue) {
        var column = headerItems ? headerItems.findBy("key", displayValue.key) : null;
        if (column && Ember.get(column, "hasActuals")) {
          Ember.set(displayValue, "columnHasActuals", true);
        } else {
          Ember.set(displayValue, "columnHasActuals", false);
        }
        if (column && Ember.get(column, "isClosed")) {
          Ember.set(displayValue, "columnIsClosed", true);
        } else {
          Ember.set(displayValue, "columnIsClosed", false);
        }
      });
      return this.get("displayValues");
    })
  });
});