define("budgeta/templates/components/forecast-budget-hc-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+QtNDtad",
    "block": "{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"displayValues\"]]],null,2]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"hcValue\"]],null,null,2,[\"get\",[\"showNegativeAsPositive\"]],\"column\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"hcValue\"]],\"%\",null,2,[\"get\",[\"showNegativeAsPositive\"]],\"column\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"displayValue\",\"isPercent\"]]],null,1,0]],\"locals\":[\"displayValue\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/forecast-budget-hc-row.hbs"
    }
  });
});