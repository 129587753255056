define("budgeta/utils/object-util", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-unused-vars */
  var iterateAllKeys = function iterateAllKeys(_ref) {
    var object = _ref.object,
      _ref$isCondition = _ref.isCondition,
      isCondition = _ref$isCondition === void 0 ? function (_ref2) {
        var key = _ref2.key,
          value = _ref2.value,
          object = _ref2.object;
      } : _ref$isCondition,
      _ref$onConditionFulfi = _ref.onConditionFulfilled,
      onConditionFulfilled = _ref$onConditionFulfi === void 0 ? function (_ref3) {
        var key = _ref3.key,
          value = _ref3.value,
          object = _ref3.object;
      } : _ref$onConditionFulfi;
    for (var key in object) {
      var value = object[key];
      if (isCondition({
        key: key,
        value: value,
        object: object
      })) {
        onConditionFulfilled({
          key: key,
          value: value,
          object: object
        });
      }
      if ((0, _typeof2.default)(value) === "object") {
        iterateAllKeys({
          object: value,
          isCondition: isCondition,
          onConditionFulfilled: onConditionFulfilled
        });
      }
    }
  };
  var _default = _exports.default = {
    iterateAllKeys: iterateAllKeys
  };
});