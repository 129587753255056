define("budgeta/locales/he-il", ["exports", "ember-intl/models/locale"], function (_exports, _locale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _locale.default.extend({
    messages: {}
  });
});