define("budgeta/services/tooltips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this.tips = {
        ID269584: {
          text: this.get("intl").t("tooltips.ID269584")
        },
        ID240449: {
          text: this.get("intl").t("tooltips.ID240449")
        },
        ID229958: {
          text: this.get("intl").t("tooltips.ID229958")
        },
        ID229349: {
          text: this.get("intl").t("tooltips.ID229349")
        },
        ID256410: {
          text: this.get("intl").t("tooltips.ID256410")
        },
        ID346726: {
          text: this.get("intl").t("tooltips.ID346726")
        },
        ID229366: {
          text: this.get("intl").t("tooltips.ID229366")
        },
        Expense: {
          text: this.get("intl").t("tooltips.Expense")
        },
        "Calculated Expense": {
          text: this.get("intl").t("tooltips.Calculated Expense")
        },
        "Per employee Expense": {
          text: this.get("intl").t("tooltips.Per employee Expense")
        },
        ID229964: {
          text: this.get("intl").t("tooltips.ID229964")
        },
        "Vacation days": {
          text: this.get("intl").t("tooltips.Vacation days")
        },
        "Accounts payable & accrued expenses": {
          text: this.get("intl").t("tooltips.Accounts payable & accrued expenses")
        },
        "Other long term assets": {
          text: this.get("intl").t("tooltips.Other long term assets")
        },
        "Other shareholders equity": {
          text: this.get("intl").t("tooltips.Other shareholders equity")
        },
        "EXPENSE CATEGORIES": {
          text: this.get("intl").t("tooltips.EXPENSE CATEGORIES")
        },
        "MRR churn": {
          text: this.get("intl").t("tooltips.MRR churn")
        },
        "ACV churn": {
          text: this.get("intl").t("tooltips.ACV churn")
        },
        "New MRR": {
          text: this.get("intl").t("tooltips.New MRR")
        },
        "Tax payments": {
          text: this.get("intl").t("tooltips.Tax payments")
        },
        "Per New Employee Expense": {
          text: this.get("intl").t("tooltips.Per New Employee Expense")
        },
        "Cross department Expense": {
          text: this.get("intl").t("tooltips.Cross department Expense")
        },
        "Multi department": {
          text: this.get("intl").t("tooltips.Multi department")
        },
        modelingInfoTip: {
          text: this.get("intl").t("tooltips.modelingInfoTip")
        },
        reportsInfoTip: {
          text: this.get("intl").t("tooltips.reportsInfoTip")
        },
        sharingInfoTip: {
          text: this.get("intl").t("tooltips.sharingInfoTip")
        },
        advancedCollaborationInfoTip: {
          text: this.get("intl").t("tooltips.advancedCollaborationInfoTip")
        },
        multiCompanyInfoTip: {
          text: this.get("intl").t("tooltips.multiCompanyInfoTip")
        },
        "Use externally": {
          text: this.get("intl").t("tooltips.Use externally")
        },
        "Select model line": {
          text: this.get("intl").t("tooltips.Select model line")
        },
        "Equity financing": {
          text: this.get("intl").t("tooltips.Equity financing")
        },
        "loan-number-of-payments": {
          text: this.get("intl").t("tooltips.loan-number-of-payments")
        },
        "hide-headcount-information": {
          text: this.get("intl").t("tooltips.hide-headcount-information")
        },
        "Budget from Intacct": {
          text: this.get("intl").t("tooltips.Budget from Intacct")
        },
        "share view": {
          text: this.get("intl").t("tooltips.share view")
        },
        "share edit": {
          text: this.get("intl").t("tooltips.share edit")
        },
        "share scenario": {
          text: this.get("intl").t("tooltips.share scenario")
        },
        "share dashboard": {
          text: this.get("intl").t("tooltips.share dashboard")
        },
        "expense-adjustment": {
          text: this.get("intl").t("tooltips.expense-adjustment")
        },
        "expense-reserve": {
          text: this.get("intl").t("tooltips.expense-reserve")
        },
        ID229356: {
          text: this.get("intl").t("tooltips.ID229356")
        },
        ID229959: {
          text: this.get("intl").t("tooltips.ID229959")
        },
        ID236888: {
          text: this.get("intl").t("tooltips.ID236888")
        },
        ID229357: {
          text: this.get("intl").t("tooltips.ID229357")
        },
        ID229359: {
          text: this.get("intl").t("tooltips.ID229359")
        },
        ID346725: {
          text: this.get("intl").t("tooltips.ID346725")
        },
        ID229350: {
          text: this.get("intl").t("tooltips.ID229350")
        },
        ID229364: {
          text: this.get("intl").t("tooltips.ID229364")
        },
        ID229365: {
          text: this.get("intl").t("tooltips.ID229365")
        },
        ID232106: {
          text: this.get("intl").t("tooltips.ID232106")
        },
        "Allocated Expense ": {
          text: this.get("intl").t("tooltips.Allocated Expense ")
        },
        ID229351: {
          text: this.get("intl").t("tooltips.ID229351")
        },
        ID229352: {
          text: this.get("intl").t("tooltips.ID229352")
        },
        ID229960: {
          text: this.get("intl").t("tooltips.ID229960")
        },
        ID229965: {
          text: this.get("intl").t("tooltips.ID229965")
        },
        ID229966: {
          text: this.get("intl").t("tooltips.ID229966")
        },
        ID229967: {
          text: this.get("intl").t("tooltips.ID229967")
        },
        ID229968: {
          text: this.get("intl").t("tooltips.ID229968")
        },
        ID229969: {
          text: this.get("intl").t("tooltips.ID229969")
        },
        ID229970: {
          text: this.get("intl").t("tooltips.ID229970")
        },
        ID229961: {
          text: this.get("intl").t("tooltips.ID229961")
        },
        ID229962: {
          text: this.get("intl").t("tooltips.ID229962")
        },
        ID229353: {
          text: this.get("intl").t("tooltips.ID229353")
        },
        ID236893: {
          text: this.get("intl").t("tooltips.ID236893")
        },
        ID228354: {
          text: this.get("intl").t("tooltips.ID228354")
        },
        Contractor: {
          text: this.get("intl").t("tooltips.Contractor")
        },
        "Past balances and transactions": {
          text: this.get("intl").t("tooltips.Past balances and transactions")
        },
        "Pre-payments": {
          text: this.get("intl").t("tooltips.Pre-payments")
        },
        Assets: {
          text: this.get("intl").t("tooltips.Assets")
        },
        "Deferred/ Past Revenue": {
          text: this.get("intl").t("tooltips.Deferred/ Past Revenue")
        },
        "Other Current liabilities ": {
          text: this.get("intl").t("tooltips.Other Current liabilities ")
        },
        "Other long term liabilities": {
          text: this.get("intl").t("tooltips.Other long term liabilities")
        },
        "Other current assets": {
          text: this.get("intl").t("tooltips.Other current assets")
        },
        "Table edit": {
          text: this.get("intl").t("tooltips.Table edit")
        },
        "REVENUE TYPES": {
          text: this.get("intl").t("tooltips.REVENUE TYPES")
        },
        "Show account ID for budget lines": {
          text: this.get("intl").t("tooltips.Show account ID for budget lines")
        },
        ID229231: {
          text: this.get("intl").t("tooltips.ID229231")
        },
        ID232646: {
          text: this.get("intl").t("tooltips.ID232646")
        },
        ID232648: {
          text: this.get("intl").t("tooltips.ID232648")
        },
        "Company Budget": {
          text: this.get("intl").t("tooltips.Company Budget")
        },
        "Department Budget": {
          text: this.get("intl").t("tooltips.Department Budget")
        },
        "Revenues budget": {
          text: this.get("intl").t("tooltips.Revenues budget")
        },
        "Multi company": {
          text: this.get("intl").t("tooltips.Multi company")
        },
        "Clone structure": {
          text: this.get("intl").t("tooltips.Clone structure")
        },
        "Beginning MRR": {
          text: this.get("intl").t("tooltips.Beginning MRR")
        },
        "Beginning ARR": {
          text: this.get("intl").t("tooltips.Beginning ARR")
        },
        "New ACV": {
          text: this.get("intl").t("tooltips.New ACV")
        },
        "MRR expansion": {
          text: this.get("intl").t("tooltips.MRR expansion")
        },
        "ACV expansion": {
          text: this.get("intl").t("tooltips.ACV expansion")
        },
        "SaaS Subscription": {
          text: this.get("intl").t("tooltips.SaaS Subscription")
        },
        ID229354: {
          text: this.get("intl").t("tooltips.ID229354")
        },
        "Can View Dashboard": {
          text: this.get("intl").t("tooltips.Can View Dashboard")
        },
        "Other income": {
          text: this.get("intl").t("tooltips.Other income")
        },
        "Other Expenses": {
          text: this.get("intl").t("tooltips.Other Expenses")
        },
        "Multi company ": {
          text: this.get("intl").t("tooltips.Multi company ")
        },
        Department: {
          text: this.get("intl").t("tooltips.Department")
        },
        "Revenue Group": {
          text: this.get("intl").t("tooltips.Revenue Group")
        },
        planningInfoTip: {
          text: this.get("intl").t("tooltips.planningInfoTip")
        },
        reportingInfoTip: {
          text: this.get("intl").t("tooltips.reportingInfoTip")
        },
        dashboardInfoTip: {
          text: this.get("intl").t("tooltips.dashboardInfoTip")
        },
        versionsInfoTip: {
          text: this.get("intl").t("tooltips.versionsInfoTip")
        },
        scenarioInfoTip: {
          text: this.get("intl").t("tooltips.scenarioInfoTip")
        },
        actualsInfoTip: {
          text: this.get("intl").t("tooltips.actualsInfoTip")
        },
        supportInfoTip: {
          text: this.get("intl").t("tooltips.supportInfoTip")
        },
        "My license - adding users ": {
          text: this.get("intl").t("tooltips.My license - adding users ")
        },
        "Add model line to budget": {
          text: this.get("intl").t("tooltips.Add model line to budget")
        },
        Loan: {
          text: this.get("intl").t("tooltips.Loan")
        },
        "Interactive Assumptions": {
          text: this.get("intl").t("tooltips.Interactive Assumptions")
        },
        exportInfoTip: {
          text: this.get("intl").t("tooltips.exportInfoTip")
        }
      };
    },
    findById: function findById(id) {
      return this.tips[id] || {
        text: ""
      };
    }
  });
});