define("budgeta/templates/components/svg/collapse-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Gdq5j5gr",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M9.85714 9.0833C10.0216 9.0833 10.1639 9.02322 10.284 8.90306L14.534 4.65306C14.6542 4.53289 14.7143 4.3906 14.7143 4.22616C14.7143 4.06173 14.6542 3.91943 14.534 3.79926C14.4139 3.6791 14.2716 3.61902 14.1071 3.61902H5.60714C5.44271 3.61902 5.30041 3.6791 5.18025 3.79926C5.06008 3.91943 5 4.06173 5 4.22616C5 4.3906 5.06008 4.53289 5.18025 4.65306L9.43025 8.90306C9.55041 9.02322 9.69271 9.0833 9.85714 9.0833Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M14.1071 16.9643C14.2716 16.9643 14.4139 16.9042 14.534 16.784C14.6542 16.6639 14.7143 16.5216 14.7143 16.3571C14.7143 16.1927 14.6542 16.0504 14.534 15.9302L10.284 11.6802C10.1639 11.5601 10.0216 11.5 9.85714 11.5C9.69271 11.5 9.55041 11.5601 9.43025 11.6802L5.18025 15.9302C5.06008 16.0504 5 16.1927 5 16.3571C5 16.5216 5.06008 16.6639 5.18025 16.784C5.30041 16.9042 5.44271 16.9643 5.60714 16.9643H14.1071Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/collapse-svg.hbs"
    }
  });
});