define("budgeta/templates/components/perforemance-logger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lXQUrhIC",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"info\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"chart-box\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"charts-container\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"line-chart\"],null,[[\"highchartWidth\",\"ignoreTooltip\",\"budgetaChartData\",\"chartCategories\",\"showLegend\",\"showLegendAmounts\"],[480,true,[\"get\",[\"calcForecastSizeArrForChart\"]],[\"get\",[\"timeArr\"]],true,false]]],false],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"charts-container\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"line-chart\"],null,[[\"highchartWidth\",\"ignoreTooltip\",\"budgetaChartData\",\"chartCategories\",\"showLegend\",\"showLegendAmounts\"],[480,true,[\"get\",[\"memCacheItemsLengthArrForChart\"]],[\"get\",[\"timeArr\"]],true,false]]],false],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"close-button\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"button\",[]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"onCloseWizard\"]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"performance-logger.close-widget\"],null],false],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/perforemance-logger.hbs"
    }
  });
});