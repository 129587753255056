define("budgeta/components/share-expand-trigger", ["exports", "budgeta/utils/budget-utils"], function (_exports, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    lines: Ember.computed("share", "budgetService.selectedRoot", function () {
      return _budgetUtils.default.shareToText(this.get("store"), this.get("budgetService.selectedRoot"), this.get("share"));
    }),
    show: Ember.computed("share", "line.length", function () {
      return this.get("share.access") !== "owner" && this.get("lines.length");
    })
  });
});