define("budgeta/services/css-vars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://css-tricks.com/updating-a-css-variable-with-javascript/
  var _default = _exports.default = Ember.Service.extend({
    root: document.documentElement,
    makeVarName: function makeVarName(keyOrig, context) {
      var keyNew = "--".concat(Ember.String.dasherize(keyOrig));
      if (typeof context === "string") {
        return "".concat(keyNew, "--").concat(context);
      }
      if (context) {
        return "".concat(keyNew, "--").concat(Ember.guidFor(context));
      }
      return keyNew;
    },
    set: function set(keyOrig, value, context) {
      var key = this.makeVarName(keyOrig, context);
      if (value == null || value == "") {
        this._remove(key);
      } else {
        this._set(key, value);
      }
    },
    setPx: function setPx(keyOrig, valueOrig, context) {
      var key = this.makeVarName(keyOrig, context);
      if (valueOrig == null) {
        this._remove(key);
      } else {
        var value = valueOrig == 0 ? valueOrig : "".concat(valueOrig, "px");
        this._set(key, value);
      }
    },
    remove: function remove(keyOrig, context) {
      var key = this.makeVarName(keyOrig, context);
      this._remove(key);
    },
    _set: function _set(key, value) {
      this.root.style.setProperty(key, value);
    },
    _remove: function _remove(key) {
      this.root.style.removeProperty(key);
    }
  });
});