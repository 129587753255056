define("budgeta/templates/components/integration-wizard-select-time-periods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QCS1RjrF",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"isLoadingSettings\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"wizard-question-radio-simple\"],null,[[\"name\",\"value\",\"selectedOption\",\"label\"],[\"timePeriod\",\"Monthly\",[\"get\",[\"model\",\"exportPeriod\"]],[\"helper\",[\"t\"],[\"periods.monthly\"],null]]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"wizard-question-radio-simple\"],null,[[\"name\",\"value\",\"selectedOption\",\"label\"],[\"timePeriod\",\"Quarterly\",[\"get\",[\"model\",\"exportPeriod\"]],[\"helper\",[\"t\"],[\"periods.quarterly\"],null]]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"wizard-question-radio-simple\"],null,[[\"name\",\"value\",\"selectedOption\",\"label\"],[\"timePeriod\",\"Yearly\",[\"get\",[\"model\",\"exportPeriod\"]],[\"helper\",[\"t\"],[\"periods.yearly\"],null]]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"unknown\",[\"budgeta-spinner\"]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/integration-wizard-select-time-periods.hbs"
    }
  });
});