define("budgeta/generic-file/components/finalize-preview/finalize-preview", ["exports", "react", "@sibp/ui-components", "react-redux", "lodash/lodash", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container"], function (_exports, _react, _uiComponents, _reactRedux, _lodash, _stepsActions, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = FinalizePreview;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function FinalizePreview(_ref) {
    var startTableAction = _ref.startTableAction,
      endTableAction = _ref.endTableAction;
    var dispatch = _reactRedux.default.useDispatch();
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.stepsStore;
      }),
      currentStep = _ReactRedux$useSelect.currentStep,
      accountCategoryErrorSkip = _ReactRedux$useSelect.accountCategoryErrorSkip;
    var notAllowedLinesIndexes = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.notAllowedLinesDetails;
    });
    _react.default.useEffect(function () {
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CATEGORY_ERROR_PREVIEW);
      startTableAction(currentStep, {
        currentStep: 3,
        notAllowedLinesIndexes: notAllowedLinesIndexes
      });
    }, []);
    var getSelectionBox = function getSelectionBox() {
      var numOfErros = _lodash.default.flatten(Object.values(notAllowedLinesIndexes.notAllowedCategory)).length;
      var message = numOfErros ? _react.default.createElement("div", {
        className: "selection-box-final-preview error"
      }, _react.default.createElement("div", {
        className: "not-allowed-counter"
      }, "".concat(intl.t("generic-file.step-finalize-preview.missing-mismatch")).concat(numOfErros)), _react.default.createElement("div", {
        className: "desc"
      }, intl.t("generic-file.step-finalize-preview.error-desc"))) : _react.default.createElement("div", {
        className: "selection-box-final-preview ok"
      }, _react.default.createElement("div", {
        className: "desc"
      }, intl.t("generic-file.step-finalize-preview.ok-desc")));
      return message;
    };
    var buttons = _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.close"),
      onClick: function onClick() {
        endTableAction(currentStep, {
          closeTable: true,
          back: true
        });
        dispatch(_stepsActions.default.prevStep({
          skipped: accountCategoryErrorSkip
        }));
      }
    });
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.MoveablePlaceholder, {
      dataHook: "headers-moveable-placeholder",
      content: getSelectionBox()
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Footer, footer), ";");
  }
});