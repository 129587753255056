define("budgeta/controllers/select-assumptions-modal", ["exports", "budgeta/controllers/modal", "budgeta/utils/assumption"], function (_exports, _modal, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modal.default.extend({
    treeManager: Ember.inject.service(),
    store: Ember.inject.service(),
    isElegantModal: true,
    selectedModel: null,
    selectedModelAssumptions: Ember.computed("fixAssumptions", "selectedModel", function () {
      var fixAssumptions = this.get("fixAssumptions"),
        selectedModel = this.get("selectedModel.budget.id"),
        selectedModelAssumptions;
      if (fixAssumptions && selectedModel) {
        selectedModelAssumptions = fixAssumptions[selectedModel];
      }
      return selectedModelAssumptions || Ember.A([]);
    }),
    missingAssumption: Ember.computed("selectedModelAssumptions", "selectedModelAssumptions.length", function () {
      return Ember.isEmpty(this.get("selectedModelAssumptions.length")) || this.get("selectedModelAssumptions.length") === 0;
    }),
    budgetModels: Ember.computed("model", "selectedModel", function () {
      var budgetModels = this.get("treeManager.budgetsObjModelList");
      var levelText = function levelText(number) {
        var text = "";
        for (var i = 0; i < number; i++) {
          text += "\u25CF";
        }
        return text;
      };
      budgetModels = budgetModels.map(function (model) {
        Ember.setProperties(model, {
          levelText: levelText(model.level),
          active: false,
          isGroup: false
        });
        return model;
      });
      if (budgetModels && budgetModels.length > 0) {
        if (!this.get("selectedModel")) {
          this.set("selectedModel", budgetModels[0]);
          Ember.set(budgetModels[0], "active", true);
        } else if (this.get("selectedModel.budget.id")) {
          var selected = budgetModels.findBy("budget.id", this.get("selectedModel.budget.id"));
          if (selected) {
            Ember.set(selected, "active", true);
          }
        }
      }
      return budgetModels;
    }),
    assumptions: Ember.computed("model.selectedRoot.selectedBudget", "model.selectedDashboard.uniqueId", function () {
      return _assumption.default.getFixedAssumptions(this.get("model.selectedRoot"), "#".concat(this.get("model.selectedDashboard.uniqueId"), "#"), null, {
        mergeWithBase: true,
        withOriginalValue: true,
        addScenarioDataIfMissing: true,
        withParent: true,
        store: this.get("store")
      });
    }),
    fixAssumptionObserver: Ember.observer("fixAssumptions", function () {
      var fixAssumptionsMap = this.get("fixAssumptions");
      var budgetModels = this.get("budgetModels");
      var parent;
      for (var key in fixAssumptionsMap) {
        parent = budgetModels && budgetModels.findBy("budget.id", key);
        if (parent) {
          Ember.set(parent, "isGroup", true);
        }
      }
    }),
    fixAssumptions: Ember.computed("budgetModels", "assumptions", "assumptions.length", function () {
      var fixAssumptions = this.get("assumptions"),
        fixAssumptionsMap = {},
        budgetModels = this.get("budgetModels"),
        selectedFixAssumptions = this.get("model.selectedFixAssumptions");
      if (fixAssumptions && budgetModels) {
        fixAssumptions.forEach(function (fixAssumption) {
          var parentId = Ember.get(fixAssumption, "parent.id");
          if (!fixAssumptionsMap[parentId]) {
            fixAssumptionsMap[parentId] = Ember.A([]);
          }
          if (selectedFixAssumptions.findBy("uniqueId", Ember.get(fixAssumption, "assumption.uniqueId"))) {
            Ember.set(fixAssumption, "selected", true);
          }
          fixAssumptionsMap[parentId].pushObject(fixAssumption);
        });
      }
      return fixAssumptionsMap;
    }),
    actions: {
      changeSelectedModel: function changeSelectedModel(model) {
        var activeModel = this.get("budgetModels").findBy("active", true);
        if (activeModel) {
          Ember.set(activeModel, "active", false);
        }
        Ember.set(model, "active", true);
        this.set("selectedModel", model);
      },
      selectAssumption: function selectAssumption(fixAssumption) {
        if (Ember.get(fixAssumption, "selected")) {
          Ember.set(fixAssumption, "selected", false);
          this.get("model.deleteAssumptions").addObject(Ember.get(fixAssumption, "assumption.uniqueId"));
          this.get("model.addAssumptions").removeObject(Ember.get(fixAssumption, "assumption.uniqueId"));
          return;
        }
        Ember.set(fixAssumption, "selected", true);
        this.get("model.deleteAssumptions").removeObject(Ember.get(fixAssumption, "assumption.uniqueId"));
        this.get("model.addAssumptions").addObject(Ember.get(fixAssumption, "assumption.uniqueId"));
      }
    }
  });
});