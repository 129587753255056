define("budgeta/components/currency-change", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers, ember/closure-actions, ember/no-global-jquery, no-undef */
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    classNames: ["wrap"],
    attributeBindings: ["data-key", "data-order", "data-hide", "data-hide-unless"],
    youtubeId: null,
    init: function init() {
      this._super();
      this.set("elementId", "attribute-" + this.get("key") + "-" + this.get("elementId"));
    },
    addIconTitle: Ember.computed("key", function () {
      return intl.t("general.add__1698234255163") + this.get("key") + " change";
    }),
    validateChangeDates: Ember.observer("budgetAttributes.amountChanges.@each.start", function () {
      if (this.get("budgetAttributes.amountChanges.length")) {
        var startDate = this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate");
        var endDate = this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.endDate");
        this.get("budgetAttributes.amountChanges").setEach("error", "");
        this.get("budgetAttributes.amountChanges").find(function (change) {
          var start = Ember.get(change, "start");
          if (start && (startDate.isAfter(start) || endDate.isBefore(start))) {
            noty({
              type: "warning",
              text: intl.t("general.currency-change-1")
            });
            return true;
          }
          return false;
        });
      }
    }),
    actions: {
      addAmountChange: function addAmountChange() {
        var newVal = {
          amount: null,
          start: null
        };
        if (this.get("budgetAttributes.amountChanges")) {
          this.get("budgetAttributes.amountChanges").pushObject(newVal);
        } else {
          this.set("budgetAttributes.amountChanges", Ember.A([newVal]));
        }
      },
      deleteRow: function deleteRow(amount, event) {
        this.get("budgetAttributes.amountChanges").removeObject(amount);
        this.sendAction("changed");
        if (this.$()) {
          $(event.target).closest(".data-row").find("input").trigger("change");
        }
        this.rerender();
      },
      openFormulaEditor: function openFormulaEditor(ctx) {
        this.sendAction("openFormulaEditor", ctx);
      }
    }
  });
});