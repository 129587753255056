define("budgeta/components/actuals-month-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    tagName: "span",
    hide: Ember.computed("readonly", "version", "budgetService.inForecast", "budgetService.selectedRoot.isPartialShare", function () {
      return this.get("readonly") || this.get("budgetService.selectedRoot.isPartialShare") || this.get("version") && !this.get("budgetService.inForecast");
    }),
    menuTriggerId: Ember.computed("elementId", function () {
      return "menu-trigger-" + this.get("elementId");
    }),
    actions: {
      closeActual: function closeActual(key) {
        this.sendAction("closeActual", key);
      },
      removeActual: function removeActual(key) {
        this.sendAction("removeActual", key);
      },
      toggleIncludeInForecast: function toggleIncludeInForecast(item) {
        this.sendAction("toggleIncludeInForecast", item);
      }
    }
  });
});