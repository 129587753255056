define("budgeta/templates/components/power-tags-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RNXCSqZn",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"dynamic-attr\",\"class\",[\"unknown\",[\"labelClass\"]],null],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"tag-list-wrapper\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"tag-list\"],null,[[\"title\",\"tags\",\"selfTags\"],[[\"helper\",[\"t\"],[\"general.inherited_tags_1698824574692\"],null],[\"get\",[\"budget\",\"parent\",\"inheritedAttributes\",\"tags\"]],[\"get\",[\"value\"]]]]],false],[\"text\",\"\\n\\n\"],[\"block\",[\"power-select-multiple\"],null,[[\"searchEnabled\",\"searchField\",\"class\",\"options\",\"selected\",\"noMatchesMessage\",\"oninput\",\"keyPress\",\"placeholder\",\"disabled\",\"onchange\"],[true,\"name\",\"fit-to-line\",[\"get\",[\"options\"]],[\"get\",[\"selectedValues\"]],[\"helper\",[\"t\"],[\"messages.no-matches-found\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"onKeyEnter\"],null],[\"get\",[\"validateTerm\"]],[\"get\",[\"prompt\"]],[\"get\",[\"readOnly\"]],[\"helper\",[\"action\"],[[\"get\",[null]],\"attributeChanged\"],null]]],0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"path-list-element\",[]],[\"flush-element\"],[\"append\",[\"unknown\",[\"option\",\"name\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"option\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/power-tags-select.hbs"
    }
  });
});