define("budgeta/common-react-components/login/ForgotPassword", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "react", "@sibp/ui-components", "react-router-dom", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/common-react-components/login/utils"], function (_exports, _slicedToArray2, _react, _uiComponents, _reactRouterDom, _emberServiceContainer, _const, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var validatorsObj = [_utils.validators.VALUE_NOT_EMPTY, _utils.validators.VALID_EMAIL];
  var ForgotPassword = function ForgotPassword(_ref) {
    var updateDataAction = _ref.updateDataAction,
      restoreEmail = _ref.restoreEmail,
      resetPassword = _ref.resetPassword;
    var history = _reactRouterDom.default.useHistory();
    var _React$useState = _react.default.useState(restoreEmail || ""),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      _email = _React$useState2[0],
      setEmail = _React$useState2[1];
    var handleCancelClick = function handleCancelClick() {
      updateDataAction({
        restoreEmail: _email
      });
      history.push("/login");
    };
    var handleResetPasswordClick = function handleResetPasswordClick(e) {
      e.preventDefault();
      updateDataAction({
        restoreEmail: _email
      });
      resetPassword();
      history.push("/login");
    };
    var options = {
      hideClose: true,
      width: "500px",
      className: _const.default.FF_SSO ? "modal-sso-container" : ""
    };
    var header = {
      text: _const.default.FF_SSO ? intl.t("login-form.forgot-password-header") : intl.t("login-form.intacct-header"),
      options: {
        titleClass: "login-header",
        hideSeperator: true
      }
    };
    var body = _react.default.createElement(_react.default.Fragment, null, !_const.default.FF_SSO && _react.default.createElement("div", {
      className: "sub-header"
    }, intl.t("login-form.forgot-password-header")), _react.default.createElement("span", {
      className: "intro ".concat(_const.default.FF_SSO ? "align-start" : "")
    }, intl.t("forgot.enter-your-email")), _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
      type: "email",
      label: intl.t("login-form.email-address"),
      onChange: function onChange(event) {
        return setEmail(event.target.value);
      },
      className: "forgot-email-address",
      value: _email,
      validators: validatorsObj,
      withErrorIcon: true,
      required: true,
      requiredMessage: intl.t("uic.required_field")
    }));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "round",
      text: intl.t("forgot.reset-password"),
      type: "submit"
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "round white-button",
      text: intl.t("login-form.cancel"),
      onClick: handleCancelClick
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement("form", {
      onSubmit: handleResetPasswordClick
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        body: body,
        footer: footer
      },
      options: options
    }));
  };
  var _default = _exports.default = ForgotPassword;
});