define("budgeta/helpers/format-numbers", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/typeof", "budgeta/utils/const"], function (_exports, _toConsumableArray2, _typeof2, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNumbers = formatNumbers;
  function formatSingle(input, currency, hasSecondValue, addClass, precision, showNegativeAsPositive, calculationError) {
    return _const.default.formatSingle({
      input: input,
      currency: currency,
      hasSecondValue: hasSecondValue,
      addClass: addClass,
      precision: precision,
      showNegativeAsPositive: showNegativeAsPositive,
      calculationError: calculationError
    });
  }
  function formatNumbers(input, currency, secondValue, precision, showNegativeAsPositive, addClass, calculationError) {
    var hasSecondValue = typeof secondValue !== "undefined" && (0, _typeof2.default)(secondValue) !== "object" && input !== secondValue,
      result = formatSingle(input, currency, false, (hasSecondValue ? "strong" : "") + " " + addClass ? addClass : "", precision, showNegativeAsPositive, calculationError);
    if (hasSecondValue) {
      result += formatSingle(secondValue, currency, true, "strong" + " " + addClass ? addClass : "", precision, showNegativeAsPositive, calculationError);
    }
    return Ember.String.htmlSafe(result);
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return formatNumbers.apply(void 0, (0, _toConsumableArray2.default)(params));
  });
});