define("budgeta/templates/components/icon-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "a7gSuShW",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"name\"],[\"ignore-hover\",[\"get\",[\"icon\"]]]]],false],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"id\",\"name\",\"type\",\"value\",\"required\",\"placeholder\"],[[\"get\",[\"inputId\"]],[\"get\",[\"name\"]],[\"get\",[\"type\"]],[\"get\",[\"value\"]],[\"get\",[\"required\"]],[\"get\",[\"placeholder\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/icon-input.hbs"
    }
  });
});