define("budgeta/components/attribute-group-select", ["exports", "@babel/runtime/helpers/esm/typeof", "budgeta/utils/const", "budgeta/utils/budget-utils"], function (_exports, _typeof2, _const, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mergeCustomAmounts = function mergeCustomAmounts(_ref) {
    var budget = _ref.budget,
      oldPeriod = _ref.oldPeriod,
      newPeriod = _ref.newPeriod;
    /*
    merge amounts if amounts are spread on months which will not be displayed to the user after period change.
    for example if user change from monthly to quarterly - the customAmounts should merge like so.
    customAmounts: { "m012022": "1", "m022022": "2" }
    to = customAmounts: { "m012022": 3, "m022022": null }
    */

    var key = "customAmounts";
    var checkPeriod = function checkPeriod(_ref2) {
      var oldPeriod = _ref2.oldPeriod,
        newPeriod = _ref2.newPeriod;
      var periodHirarchy = {
        m: 1,
        q: 2,
        y: 3
      };
      return periodHirarchy[oldPeriod] < periodHirarchy[newPeriod];
    };
    var budgetTypes = new Set(["expense", "expense-adjustment", "expense-allocated", "expense-benefit", "expense-equipment", "expense-facility", "expense-office", "expense-programs", "expense-reserve", "expense-travel", "group-expense-op", "no-grouping-line", "per-employee", "per-new-employee", "revenue-adjustment", "sales", "sales-perpetual-license", "sales-service", "sales-subscription"]);
    var budgetAttributes = _budgetUtils.default.getBudgetAttributesWithScenario(budget, budget.get("budgetService.scenario"));
    var shouldMergePeriod = checkPeriod({
      oldPeriod: oldPeriod,
      newPeriod: newPeriod
    });
    var shouldMergeAmounts = budgetAttributes.amountType === 1; //customAmounts
    var shouldMergeBudgetType = budgetTypes.has(budget.get("budgetType"));
    if (shouldMergePeriod && shouldMergeAmounts && shouldMergeBudgetType) {
      _budgetUtils.default.calcPeriodValues({
        key: key,
        period: newPeriod,
        budget: budget,
        budgetAttributes: budgetAttributes,
        shouldMergeMonths: true
      });
    }
  };
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["budgeta-type-value", "budgeta-type-dropdown"],
    attributeBindings: ["data-key", "data-hide", "data-default", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    enabled: Ember.computed("readOnly", function () {
      return this.get("readOnly") ? false : true;
    }),
    valueObserver: Ember.observer("value", function () {
      var _this = this;
      Ember.run.next(function () {
        if (_this.get("content")) {
          _this.get("content").forEach(function (item) {
            (item.attributeGroup || []).forEach(function (attr) {
              var defaultValue;
              if (_this.get("budgetModule.budgetAttributes.".concat(attr, ".defaultValueFor.length"))) {
                _this.get("budgetModule.budgetAttributes.".concat(attr, ".defaultValueFor")).forEach(function (item) {
                  if (_this.get("budgetAttributes.".concat(item.attr)) == item.value) {
                    defaultValue = item.defaultValue;
                  }
                });
                if (defaultValue) {
                  _this.set("budgetAttributes." + attr, defaultValue);
                }
              }
            });
          });
        }
      });
      this.valueChangedOrInsert(true);
    }),
    valueChangedOrInsert: function valueChangedOrInsert(didValueChange) {
      var showAttributes = this.get("content").findBy("value", this.get("value")) || [];
      if (showAttributes) {
        showAttributes = showAttributes.attributeGroup;
      }
      if (didValueChange && this.get("budget") && this.get("key") === "period") {
        mergeCustomAmounts({
          budget: this.get("budget"),
          newPeriod: this.get("value"),
          oldPeriod: this.get("oldValue")
        });
      }
      Ember.run.schedule("afterRender", this, function () {
        var _this2 = this;
        if (this.$()) {
          var wrapper = this.$().closest(".budget-attributes-wrapper");
          this.get("content").forEach(function (item) {
            (item.attributeGroup || []).forEach(function (attr) {
              var el = wrapper.find("[data-key=\"".concat(attr, "\"]"));
              var showThis = false;
              if (el.length && showAttributes) {
                if (showAttributes.includes(attr)) {
                  el.data("attribute-group-hidden", false);
                  el.removeClass("attribute-group-hidden");
                  el.trigger("show");
                  showThis = true;

                  // add required if removed
                  var inputEl = el.find("input");
                  if (inputEl.length && el.data("required-removed")) {
                    inputEl.attr("required", true);
                  }
                } else {
                  el.data("attribute-group-hidden", true);
                  el.addClass("attribute-group-hidden");
                }
                Ember.run.scheduleOnce("afterRender", function () {
                  if (!el.is(":visible") && !wrapper.find("[data-type-ref=\"".concat(attr, "\"]:visible")).length) {
                    // remove required if exists
                    var _inputEl = el.find("input");
                    if (_inputEl.length && el.find("input").get(0).required) {
                      el.data("required-removed", true);
                      _inputEl.removeAttr("required");
                    }
                    el.trigger("hide", [didValueChange]);
                    if (!el.hasClass("keep-value")) {
                      // Clear the object
                      if ((0, _typeof2.default)(_this2.get("budgetAttributes." + attr)) === "object" && !Ember.isArray(_this2.get("budgetAttributes." + attr))) {
                        var objectToClear = _this2.get("budgetAttributes." + attr);
                        for (var prop in objectToClear) {
                          if (objectToClear.hasOwnProperty(prop)) {
                            delete objectToClear[prop];
                          }
                        }
                      } else {
                        if (_this2.get("budgetModule.budgetAttributes.".concat(attr)).hasOwnProperty("defaultValue")) {
                          var defaultValue = _this2.get("budgetModule.budgetAttributes.".concat(attr, ".defaultValue"));
                          if (_this2.get("budgetModule.budgetAttributes.".concat(attr, ".defaultValueFor.length"))) {
                            _this2.get("budgetModule.budgetAttributes.".concat(attr, ".defaultValueFor")).forEach(function (item) {
                              if (_this2.get("budgetAttributes.".concat(item.attr)) == item.value) {
                                defaultValue = item.defaultValue;
                              }
                            });
                          }
                          if ((0, _typeof2.default)(defaultValue) === "object") {
                            defaultValue = JSON.parse(JSON.stringify(defaultValue));
                          }
                          _this2.set("budgetAttributes." + attr, defaultValue);
                        }
                        if (!showThis) {
                          el.data("attribute-group-hidden", true);
                          el.addClass("attribute-group-hidden"); // make sure the hide class remains in case the value reset causes rerender
                        }
                      }
                    }
                  }
                });
              }
            });
          });
          _const.default.updateEvenOddClass(wrapper);
        }
      });
      this.set("oldValue", this.get("value"));
    },
    displayPlaceholder: Ember.computed("placeholder", "content.length", function () {
      var val = (this.get("content") || Ember.A([])).findBy(this.get("optionValuePath"), this.get("placeholder"));
      return val && Ember.get(val, this.get("optionLabelPath"));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set("oldValue", this.get("value"));
      this.valueChangedOrInsert();
    }
  });
});