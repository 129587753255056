define("budgeta/templates/import-generic-file-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "feKSWGbI",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"import-preview import-generic-file\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"step1\"]]],null,3],[\"block\",[\"if\"],[[\"get\",[\"step2\"]]],null,2],[\"block\",[\"if\"],[[\"get\",[\"step3\"]]],null,1],[\"block\",[\"if\"],[[\"get\",[\"step4\"]]],null,0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"partial\",\"import-final-preview-step\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"partial\",\"import-row-selection-step\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"partial\",\"import-column-mapping-step\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"partial\",\"import-row-column-step\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":true}",
    "meta": {
      "moduleName": "budgeta/templates/import-generic-file-table.hbs"
    }
  });
});