define("budgeta/utils/id-generator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = idGenerator;
  var MACHINE_ID = parseInt(Math.random() * 0xffffff, 10);
  var index = parseInt(Math.random() * 0xffffff, 10);
  var pid = Math.floor(Math.random() * 100000);
  function next() {
    return index = (index + 1) % 0xffffff;
  }
  function generate(time) {
    if (typeof time !== "number") {
      time = Date.now() / 1000;
    }

    //keep it in the ring!
    time = parseInt(time, 10) % 0xffffffff;

    //FFFFFFFF FFFFFF FFFF FFFFFF
    return hex(8, time) + hex(6, MACHINE_ID) + hex(4, pid) + hex(6, next());
  }
  function hex(length, n) {
    n = n.toString(16);
    return n.length === length ? n : "00000000".substring(n.length, length) + n;
  }
  function idGenerator() {
    return generate();
  }
});