define("budgeta/templates/components/forecast-budget-hc-diff-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/KIQ5+Sy",
    "block": "{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"displayCompareValues\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"column compare-format-numbers\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"span\",[]],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"hcValue\"]],null,null,2,[\"get\",[\"showNegativeAsPositive\"]]],null],false],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"span\",[]],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"hcValue2\"]],null,null,2,[\"get\",[\"showNegativeAsPositive\"]]],null],false],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"span\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"diff-column \",[\"helper\",[\"if\"],[[\"get\",[\"displayValue\",\"negative\"]],\"negative-vs\"],null],\" \",[\"helper\",[\"if\"],[[\"get\",[\"displayValue\",\"positive\"]],\"positive-vs\"],null]]]],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"diff\"]],null,null,2,[\"get\",[\"showNegativeAsPositive\"]]],null],false],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"displayValue\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/forecast-budget-hc-diff-row.hbs"
    }
  });
});