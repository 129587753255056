define("budgeta/components/dimension-tree-values", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/dimensions", "budgeta/utils/budget-utils"], function (_exports, _toConsumableArray2, _emberServiceContainer, _const, _dimensions, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */

  var moveDimensionOrder = function moveDimensionOrder(moveDirection, dimension, dest, _this, doMerge) {
    var destLine = dest,
      dimensionTree = _this.get("dimensionTree"),
      activeDimension = _this.get("activeDimension"),
      activeDimensionValues = _this.get("activeDimension.values");
    if (moveDirection !== 0) {
      // not drop - check index before or after
      var dimensionParent = _dimensions.default.findDimensionValue(Ember.get(destLine, "parent"), dimensionTree);
      var sameParentNodes = !dimensionParent && !Ember.get(dimension, "parent") || dimensionParent && Ember.get(dimensionParent, "uniqueId") === Ember.get(dimension, "parent") ? undefined : _dimensions.default.findDuplicatesByName({
        dimensionTree: dimensionTree,
        parentId: dimensionParent ? Ember.get(dimensionParent, "uniqueId") : undefined,
        name: Ember.get(dimension, "name"),
        atList: 1
      });
      if (sameParentNodes && !doMerge) {
        // need to merge
        return _this.sendAction("openModal", "modal", {
          model: {
            newName: Ember.get(dimension, "name"),
            sameParentNodes: sameParentNodes,
            dimension: activeDimension,
            moveDirection: moveDirection,
            dimensionValue: dimension,
            destValue: dest
          },
          outlet: "modal2",
          title: intl.t("general.dimension-tree-values-1", {
            var1: Ember.get(dimension, intl.t("general.name_1698234255194"))
          }),
          templateName: "dimension-merge",
          confirmAction: "confirmDimensionMoveAndMerge",
          confirmActionName: intl.t("general.confirmActionName_1695611613044"),
          actionTarget: _this
        });
      }
      activeDimensionValues.removeObject(dimension);
      var newPosition = _this.get("activeDimension.values").indexOf(destLine);
      if (moveDirection === 1) {
        newPosition++;
      }
      Ember.set(dimension, "parent", Ember.get(destLine, "parent"));
      activeDimensionValues.insertAt(newPosition, dimension);
      if (doMerge) {
        Ember.run.next(function () {
          _dimensions.default.mergeDimensionChildren({
            store: _this.get("store"),
            dimension: dimensionParent,
            dimensionTree: dimensionTree,
            dimensionGroup: activeDimension
          });
        });
      }
    } else {
      if (!_this.get("activeDimension.allowHierarchy")) {
        return;
      }
      // drop
      var _sameParentNodes = _dimensions.default.findDuplicatesByName({
        dimensionTree: dimensionTree,
        parentId: destLine ? Ember.get(destLine, "uniqueId") : undefined,
        name: Ember.get(dimension, "name"),
        atList: 1
      });
      if (_sameParentNodes && !doMerge) {
        // need to merge
        return _this.sendAction("openModal", "modal", {
          model: {
            newName: Ember.get(dimension, "name"),
            sameParentNodes: _sameParentNodes,
            dimension: activeDimension,
            moveDirection: moveDirection,
            dimensionValue: dimension,
            destValue: dest
          },
          outlet: "modal2",
          title: intl.t("general.dimension-tree-values-1", {
            var1: Ember.get(dimension, intl.t("general.name_1698234255194"))
          }),
          templateName: "dimension-merge",
          confirmAction: "confirmDimensionMoveAndMerge",
          confirmActionName: intl.t("general.confirmActionName_1695611613044"),
          actionTarget: _this
        });
      }
      activeDimensionValues.removeObject(dimension);
      var _newPosition = _this.get("activeDimension.values").indexOf(destLine) + 1;
      Ember.set(dimension, "parent", Ember.get(destLine, "uniqueId"));
      activeDimensionValues.insertAt(_newPosition, dimension);
      if (doMerge) {
        Ember.run.next(function () {
          _dimensions.default.mergeDimensionChildren({
            store: _this.get("store"),
            dimension: destLine,
            dimensionTree: dimensionTree,
            dimensionGroup: activeDimension
          });
        });
      }
    }
  };
  var _default = _exports.default = Ember.Component.extend({
    cache: Ember.inject.service(),
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    scenario: Ember.computed.alias("budgetService.scenario"),
    isReadOnly: Ember.computed("scenario", function () {
      return !!this.get("scenario");
    }),
    classNameBindings: [":dimension-tree-values", "isDragabble:draggable-list"],
    recalcTreeTrigger: null,
    isFlatTree: Ember.computed("activeDimension.allowHierarchy", function () {
      return !this.get("activeDimension.allowHierarchy");
    }),
    didRender: function didRender() {
      var _this2 = this;
      //  verify benefit reference
      (this.get("dimensionTree") || []).forEach(function (item) {
        if (Ember.get(item, "benefitRef") && !_budgetUtils.default.findLineByUniqueId(_this2.get("store"), _this2.get("cache"), Ember.get(item, "benefitRef"), _this2.get("budgetService.selectedRoot.id"))) {
          Ember.set(item, "benefitRef", null);
        }
      });
    },
    treeData: Ember.computed("dimensionTree.length", "activeDimension", "recalcTreeTrigger", function () {
      var itemMap = {};
      var data = this.get("dimensionTree").map(function (item) {
        var treeRow = {
          hasChildren: item.isGroup,
          id: item.uniqueId,
          text: item.name,
          level: item.level,
          path: [item.uniqueId],
          data: item,
          readOnly: !!item.benefitRef,
          collapsed: true
        };
        if (item.newRow) {
          treeRow.newRow = true;
          delete item.newRow;
        }
        itemMap[treeRow.id] = treeRow;
        return treeRow;
      });
      data.forEach(function (item) {
        if (item.data.parent) {
          var _item$path;
          (_item$path = item.path).splice.apply(_item$path, [0, 0].concat((0, _toConsumableArray2.default)(itemMap[item.data.parent].path)));
        }
      });
      return data;
    }),
    actions: {
      confirmDimensionMoveAndMerge: function confirmDimensionMoveAndMerge(model) {
        moveDimensionOrder(model.moveDirection, model.dimensionValue, model.destValue, this, true);
      },
      handleDuplicateValues: function handleDuplicateValues(dimension, oldName, newName) {
        var dimensionTree = this.get("dimensionTree");
        var dimensionParent = Ember.get(dimension, "parent");
        var dimensionName = Ember.get(dimension, "name");
        var sameParentNodes = _dimensions.default.findDuplicatesByName({
          dimensionTree: dimensionTree,
          parentId: dimensionParent,
          name: dimensionName
        });
        if (sameParentNodes) {
          // need to merge
          return this.sendAction("openModal", "modal", {
            model: {
              oldName: oldName,
              newName: newName,
              sameParentNodes: sameParentNodes,
              dimension: dimension
            },
            outlet: "modal2",
            title: intl.t("general.dimension-tree-values-3", {
              var1: newName
            }),
            templateName: "dimension-merge",
            cancelAction: "cancelDimensionMerge",
            confirmAction: "confirmDimensionMerge",
            confirmActionName: intl.t("general.confirmActionName_1695611613044"),
            actionTarget: this
          });
        }
        // dimension was renamed
      },
      confirmDimensionMerge: function confirmDimensionMerge(model) {
        var dimensionTree = this.get("dimensionTree");
        var dimension = model.dimension;
        var parent = Ember.get(dimension, "parent") ? dimensionTree.findBy("uniqueId", Ember.get(dimension, "parent")) : undefined;
        _dimensions.default.mergeDimensionChildren({
          store: this.get("store"),
          dimension: parent,
          dimensionTree: dimensionTree,
          dimensionGroup: this.get("activeDimension")
        });
      },
      cancelDimensionMerge: function cancelDimensionMerge(model) {
        Ember.set(model, "dimension.name", Ember.get(model, "oldName"));
        this.notifyPropertyChange("recalcTreeTrigger");
      },
      addChildren: function addChildren(parentPath) {
        var didFoundParent = false,
          foundPlace = false,
          index = 0,
          parentId = parentPath[parentPath.length - 1],
          values = this.get("activeDimension.values");
        if (!this.get("activeDimension.values")) {
          this.set("activeDimension.values", Ember.A([]));
        }
        while (index < values.length && !foundPlace) {
          var item = values[index];
          if (didFoundParent) {
            if (Ember.get(item, "parent") !== parentId) {
              foundPlace = true;
              break;
            }
          } else if (Ember.get(item, "uniqueId") === parentId) {
            didFoundParent = true;
          }
          index++;
        }
        var uniqueId = _const.default.generateUUID();
        var newValue = {
          uniqueId: uniqueId,
          name: "",
          parent: parentId,
          newRow: true
        };
        values.insertAt(index, newValue);
        values.find(function (v) {
          return v.uniqueId === parentId;
        }).isGroup = true;
      },
      treeChange: function treeChange(change) {
        switch (change.type) {
          case "delete":
            this.sendAction("deleteDimensionAttention", change.row.data);
            break;
          case "add":
            this.send("addChildren", change.parentPath);
            break;
          case "update":
            {
              var dimension = change.row.data;
              var oldName = dimension.name;
              Ember.set(dimension, "name", _dimensions.default.normalizeDimensionValue(change.newText));
              this.send("handleDuplicateValues", dimension, oldName, change.newText);
              break;
            }
          case "drop":
            {
              var moveDirection = change.dragType === "on" ? 0 : change.dragType === "before" ? -1 : 1;
              moveDimensionOrder(moveDirection, change.source.data, change.target.data, this);
              break;
            }
          default:
            break;
        }
      }
    }
  });
});