define("budgeta/components/formula-editable-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    attributeBindings: ["contenteditable", "data-gramm", "spellcheck", "data-gramm_editor"],
    "data-gramm": false,
    spellcheck: false,
    "data-gramm_editor": false,
    contenteditable: true,
    processValue: function processValue() {
      if (this.get("value")) {
        return this.setContent();
      }
    },
    valueObserver: Ember.observer("value", function () {
      Ember.run.once(this, "processValue");
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setContent();
    },
    keyDown: function keyDown(event) {
      var newVal = this.$().html();
      var lastVarRE = /<span[^>]*>[^<>]*<\/span>$/;
      if (event.keyCode === 8 && lastVarRE.test(newVal)) {
        newVal = newVal.replace(lastVarRE, "");
        this.$().html(newVal);
        this.set("value", newVal);
        event.preventDefault();
      }
    },
    keyUp: function keyUp( /*event*/
    ) {
      return this.set("value", this.$().html());
    },
    setContent: function setContent() {
      if (this.$()) {
        return this.$().html(this.get("value"));
      }
    }
  });
});