define("budgeta/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr("string"),
    contactEmail: _emberData.default.attr("string"),
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    fullName: Ember.computed("firstName", "lastName", function () {
      return this.getWithDefault("firstName", "") + " " + this.getWithDefault("lastName", "");
    }),
    displayName: Ember.computed("fullName", "email", function () {
      return this.get("fullName").trim() ? this.get("fullName") : this.get("email");
    }),
    phoneNumber: _emberData.default.attr("string"),
    currentPassword: _emberData.default.attr("string"),
    password: _emberData.default.attr("string"),
    hideTips: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    roles: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isAdmin: Ember.computed("roles", function () {
      return this.get("roles") && this.get("roles").indexOf("admin") >= 0;
    }),
    status: _emberData.default.attr("string"),
    models: _emberData.default.hasMany("user-model", {
      async: true,
      inverse: "createdBy"
    }),
    adminConsole: _emberData.default.attr("raw"),
    registeredId: _emberData.default.attr("string"),
    accessToken: _emberData.default.attr("string"),
    expiresAt: _emberData.default.attr("date"),
    created: _emberData.default.attr("date"),
    lastActivity: _emberData.default.attr("date"),
    company: _emberData.default.attr("string"),
    intacctCompanyId: _emberData.default.attr("string"),
    companies: _emberData.default.attr("raw"),
    position: _emberData.default.attr("string"),
    howFound: _emberData.default.attr("string"),
    comments: _emberData.default.attr("string"),
    user_device: _emberData.default.attr("string"),
    changeSupportAccessAuthorization: _emberData.default.attr("raw", {
      defaultValue: null
    }),
    supportAccessAuthorization: _emberData.default.attr("date"),
    preferences: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return {
          inputDisplayType: "form",
          locale: "en-us"
        };
      }
    }),
    FLAGS: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    authenticatedBy: _emberData.default.attr("string")
  });
});