define("budgeta/validators/local/benefit-name-unique", ["exports", "budgeta/services/ember-service-container", "ember-validations/validators/base", "budgeta/utils/budget-utils"], function (_exports, _emberServiceContainer, _base, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _base.default.extend({
    call: function call() {
      var _this = this;
      var line = this.model.get("model");
      if (line) {
        var benefits = line.get("parent.benefits") ? line.get("parent.benefits").filter(function (b) {
          return b !== line;
        }).mapBy("name") : [];
        benefits.addObjects(_budgetUtils.default.getInheritedBenefits(line.get("parent"), {
          scenario: line.get("budgetService.scenario")
        }).filter(function (b) {
          return b !== _this.model.get("inheritedFrom");
        }).mapBy("name"));
        if (benefits.includes(line.get("name"))) {
          this.errors.pushObject(this.options.message || intl.t("general.name_already_in_use_please_enter_a_unique_name_1698234257401"));
        }
      }
    }
  });
});