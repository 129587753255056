define("budgeta/popup-menu/flows/budgeta-around", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    return this.orientBelow.andSnapTo(this.center, this.rightEdge, this.leftEdge).then(this.orientAbove.andSnapTo(this.center, this.leftEdge, this.rightEdge)).then(this.orientRight.andSlideBetween(this.bottomEdge, this.topEdge)).then(this.orientLeft.andSlideBetween(this.topEdge, this.bottomEdge)).then(this.orientAbove.andSnapTo(this.center));
  }
});