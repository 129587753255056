define("budgeta/templates/components/actions-budget-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fFjw1pHg",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"actions-inner-wrapper\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/actions-budget-tree.hbs"
    }
  });
});