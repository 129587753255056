define("budgeta/initializers/select-2-mod", ["exports", "ember-select-2/components/select-2"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* eslint-disable ember/no-observers, ember/closure-actions */

  function initialize( /* application */
  ) {
    _select.default.reopen({
      attributeBindings: ["style", "tabindex", "title", "data-hook"],
      title: "",
      valueSeparator: "#;#",
      titleObserver: Ember.observer("title", function () {
        this.$().parent().find(".select2-container.ember-view.form-control.location-select").attr("title", this.get("title"));
        this.$().parent().find(".select2-container").attr("title", this.get("title"));
      }),
      didInsertElement: function didInsertElement() {
        var _this2 = this;
        this._super.apply(this, arguments);
        var _this = this;
        var select2 = this._select;
        var closing = false;
        var ignoreOpen = false;
        select2.on("select2-focus", function () {
          if (closing) {
            closing = false;
          } else {
            select2.select2("open");
          }
          select2.one("change", function () {
            if (_this2.get("afterChangeAction")) {
              _this2.sendAction("afterChangeAction");
            }
            closing = true;
            select2.select2("close");
          });
        }).on("select2-close", function () {
          if (_this2.get("updateOpenStatus") && _this2.$()) {
            _this2.$().parents(_this2.get("updateOpenStatus")).removeClass("is-open");
          }
          closing = true;
        }).on("select2-blur", function () {
          closing = false;
        }).on("select2-selecting", function () {
          if (_this2.get("attributeChanged")) {
            _this2.sendAction("attributeChanged");
          }
          if (_this2.get("onOptionSelecting")) {
            _this2.sendAction(_this2.get("onOptionSelecting"));
          }
        }).on("select2-open", function () {
          generateTitles(select2);
          if (_this2.open && !ignoreOpen) {
            ignoreOpen = true;
            _this2.open();
            Ember.run.scheduleOnce("afterRender", function () {
              select2.select2("close");
              select2.select2("open");
              ignoreOpen = false;
            });
          }
          if (_this2.get("onOpenAction")) {
            _this2.sendAction(_this2.get("onOpenAction"));
          }
          Ember.run.scheduleOnce("afterRender", function () {
            if (_this2.get("updateOpenStatus") && _this2.$()) {
              _this2.$().parents(_this2.get("updateOpenStatus")).addClass("is-open");
            }
          });
        });
        select2.select2("dropdown").on("mousedown", ".select2-no-results", function () {
          if (_this.get("addMissingAction")) {
            _this.send(_this.get("addMissingAction"), select2.select2("dropdown").find(".select2-search input").val());
            select2.select2("close");
          }
        });
        select2.select2("container").attr("data-hook", "".concat(this.get("dataHook") || "select2-dropdown-container"));
        select2.select2("container").find(">a.select2-choice>span").on("mousedown", function () {
          closing = true;
        });
        select2.select2("container").find("input").on("keydown", function (e) {
          if (_this.get("unallowedChars") && _this.get("unallowedChars").indexOf(e.key) >= 0) {
            e.preventDefault();
            return false;
          }
          generateTitles(select2);
        });
        Ember.$(window).on("focus.".concat(this.get("elementId")), function () {
          closing = true;
        });
      },
      willDestroyElement: function willDestroyElement() {
        var select2 = this._select;
        if (select2) {
          select2.select2("dropdown").off("mousedown");
          select2.select2("container").find(">a.select2-choice>span").off("mousedown");
          select2.select2("container").find("input").off("keydown");
          select2.off("change select2-open select2-focus select2-close select2-blur select2-selecting");
        }
        this._super.apply(this, arguments);
        Ember.$(window).off("focus.".concat(this.get("elementId")));
      },
      transformTag: function transformTag(tag) {
        Ember.set(tag, "name", Ember.get(tag, "name").trim());
        return this._super(tag);
      },
      //override obsever to work correctly with ember run loop
      watchDisabled: Ember.observer("_hasSelectedMissingItems", "_hasPendingContentPromise", "_hasFailedContentPromise", "_hasPendingValuePromise", "_hasFailedValuePromise", "enabled", function () {
        var select = this._select;
        var disabled = this.get("_hasSelectedMissingItems") || this.get("_hasPendingContentPromise") || this.get("_hasFailedContentPromise") || this.get("_hasPendingValuePromise") || this.get("_hasFailedValuePromise") || !this.get("enabled");
        if (select) {
          Ember.run.bind(this, select.select2, "readonly", disabled);
        }
      })
    });
  }
  var generateTitles = function generateTitles(select2) {
    Ember.run.scheduleOnce("afterRender", function () {
      select2.select2("dropdown").find("li.select2-result-selectable").each(function () {
        var text = Ember.$(this).text().trim();
        if (text.length > 0) {
          Ember.$(this).attr("title", text);
        }
      });
      select2.select2("dropdown").find("ul").attr("data-hook", "select2-container");
      select2.select2("dropdown").find("li").each(function (index) {
        Ember.$(this).attr("data-hook", "select2-option-index-" + index);
      });
    });
  };
  var _default = _exports.default = {
    name: "select-2-mod",
    initialize: initialize
  };
});