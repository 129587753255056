define("budgeta/templates/components/edit-budget-tree-rename-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UkEiH1tT",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"input\"],null,[[\"name\",\"value\",\"placeholder\",\"focus-out\"],[\"name\",[\"get\",[\"budget\",\"name\"]],[\"get\",[\"budget\",\"namePlaceholder\"]],\"save\"]]],false],[\"text\",\"\\n\"],[\"open-element\",\"a\",[]],[\"static-attr\",\"class\",\"add\"],[\"modifier\",[\"action\"],[[\"get\",[null]],\"editTree\",[\"get\",[\"budget\"]]]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"edit-budget-tree-rename-simple.change-type\"],null],false],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/edit-budget-tree-rename-simple.hbs"
    }
  });
});