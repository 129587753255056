define("budgeta/components/intacct-year-over-year", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":intacct-year-over-year"],
    actions: {
      setValue: function setValue(input, e) {
        _const.default.upToOneDecimal(input, e);
      }
    }
  });
});