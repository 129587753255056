define("budgeta/templates/import-file-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ayJOMBxt",
    "block": "{\"statements\":[[\"block\",[\"file-drop\"],null,[[\"action\",\"uploadBudget\"],[\"loadFile\",[\"get\",[\"uploadBudget\"]]]],3]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"            \"],[\"open-element\",\"li\",[]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"loadFile\",[\"get\",[\"file\"]]]],[\"flush-element\"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"name\"],[\"attachment\"]]],false],[\"open-element\",\"span\",[]],[\"dynamic-attr\",\"title\",[\"unknown\",[\"file\",\"name\"]],null],[\"static-attr\",\"class\",\"file-name\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"file\",\"name\"]],false],[\"close-element\"],[\"text\",\"\\n              \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"when\"],[\"flush-element\"],[\"append\",[\"helper\",[\"format-date-relative\"],[[\"get\",[\"file\",\"uploaded\"]]],null],false],[\"close-element\"],[\"text\",\"\\n            \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"file\"]},{\"statements\":[[\"text\",\"      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"recent-files centerHElement\"],[\"flush-element\"],[\"text\",\"\\n        \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"recent-files-header\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"import-all.recent-uploads\"],null],false],[\"close-element\"],[\"text\",\"\\n        \"],[\"open-element\",\"ul\",[]],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"unModifiedRecentFiles\"]]],null,0],[\"text\",\"        \"],[\"close-element\"],[\"text\",\"\\n      \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"unModifiedRecentFiles\",\"length\"]]],null,1]],\"locals\":[]},{\"statements\":[[\"block\",[\"unless\"],[[\"get\",[\"uploadBudget\"]]],null,2]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/import-file-drop.hbs"
    }
  });
});