define("budgeta/components/past-data-input", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/budget-utils"], function (_exports, _emberServiceContainer, _const, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["disable-render-preview"],
    budgetService: Ember.inject.service(),
    triggerId: Ember.computed("elementId", function () {
      return this.get("elementId") + "-trigger";
    }),
    tipStyle: Ember.computed("enablePastData", "isButton", function () {
      var offset = this.get("isButton") ? 80 : this.get("enablePastData") ? -30 : 0;
      return {
        width: 15,
        height: 10,
        mimic: "right center",
        offset: offset
      };
    }),
    adjustY: Ember.computed("isButton", function () {
      return this.get("isButton") ? -40 : -10;
    }),
    adjustX: Ember.computed("isButton", function () {
      return this.get("isButton") ? 0 : -10;
    }),
    positionMy: Ember.computed("isButton", function () {
      return this.get("isButton") ? "top right" : "center right";
    }),
    enableFiscalYearSelection: Ember.computed("fiscalYearOptions", function () {
      return this.get("fiscalYearOptions").length > 1 ? true : false;
    }),
    growthPercentage: Ember.computed("budgetService.pastData.selectedGrowth", function () {
      if (!this.get("budgetService.pastData.selectedGrowth")) {
        return 0;
      } else {
        return this.get("budgetService.pastData.selectedGrowth");
      }
    }),
    growthValue: Ember.computed("budgetService.pastData.growthValue", function () {
      if (!this.get("budgetService.pastData.growthValue")) {
        return "hundreds";
      } else {
        return this.get("budgetService.pastData.growthValue");
      }
    }),
    disableGroupPopulate: Ember.computed("budgetLine.budgetType", function () {
      if (this.get("budgetLine.budgetType") === "group-payroll" || this.get("budgetLine.budgetType") === "group-ps") {
        var children = (this.get("budgetLine.children") || []).toArray();
        for (var i = 0; i < children.length; i++) {
          if (Ember.get(children[i], "budgetType").indexOf("group") === 0 || !Ember.get(children[i], "disablePopulateBudget")) {
            return false;
          }
        }
        return true;
      }
      return false;
    }),
    disablePastData: Ember.computed("enablePastData", "readOnly", "disableGroupPopulate", function () {
      return !this.get("enablePastData") || this.get("readOnly") || this.get("disableGroupPopulate");
    }),
    fiscalYearValue: Ember.computed("fiscalYearOptions", function () {
      if (this.get("budgetService.pastData.fiscalYearValue") && this.get("fiscalYearOptions").findBy("id", parseInt(this.get("budgetService.pastData.fiscalYearValue")))) {
        return this.get("budgetService.pastData.fiscalYearValue");
      } else {
        var fiscalYearOptions = this.get("fiscalYearOptions");
        if (!fiscalYearOptions.length) {
          return "";
        } else if (fiscalYearOptions.length === 1) {
          return fiscalYearOptions[0].text;
        } else {
          return fiscalYearOptions[fiscalYearOptions.length - 1].text;
        }
      }
    }),
    fiscalYearOptions: Ember.computed("budgetService.selectedRoot.absoluteRoot.yearStart", "budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate", "isPnLPreview", function () {
      var _this = this;
      var transacations = _budgetUtils.default.getTransactionsRecursiveFaster(this.get("budgetService.selectedRoot.absoluteRoot")) || [];
      var pastTransactions = _budgetUtils.default.filterTransactionsByPeriod(transacations, "past", {
        filterBudgetTransactions: false
      });
      var result = [];
      pastTransactions.forEach(function (trx) {
        var trxDate;
        var fiscalYearStart = _this.get("budgetService.selectedRoot.absoluteRoot.yearStart");
        if (_this.get("isPnLPreview")) {
          trxDate = Ember.get(trx, "fiscalDate");
        } else {
          trxDate = Ember.get(trx, "trxDate");
        }
        if (trxDate) {
          var trxYear = trxDate.getFullYear();
          if (fiscalYearStart !== 0 && trxDate.getMonth() >= fiscalYearStart) {
            trxYear++;
          }
          if (!result.findBy("id", trxYear) && trxDate.isBefore(_this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate"))) {
            result.pushObject({
              id: trxYear,
              text: trxYear
            });
          }
        }
      });
      return result.sortBy("text");
    }),
    roundOptions: [{
      id: "num",
      text: intl.t("general.text_1695611609113")
    }, {
      id: "tens",
      text: intl.t("general.text_1695611609103")
    }, {
      id: "hundreds",
      text: intl.t("general.text_1695611609092")
    }, {
      id: "thousands",
      text: intl.t("general.title_1695611600022")
    }],
    click: function click() {
      if (!this.get("isButton")) {
        this.set("budgetService.viewDataType", "past");
      }
    },
    actions: {
      setValue: function setValue(input, e) {
        _const.default.upToOneDecimal(input, e);
      },
      disableAutoSave: function disableAutoSave(event) {
        event.preventDefault();
      },
      populateData: function populateData() {
        var _this2 = this;
        this.set("budgetService.pastData.selectedGrowth", this.get("growthPercentage"));
        this.set("budgetService.pastData.fiscalYearValue", this.get("fiscalYearValue"));
        this.set("budgetService.pastData.growthValue", this.get("growthValue"));
        Ember.run.next(function () {
          _this2.sendAction("populateDataValues", _this2.get("fiscalYearValue"), _this2.get("growthPercentage"), _this2.get("growthValue"));
        });
      }
    }
  });
});