define("budgeta/models/tooltip", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    key: _emberData.default.attr("string"),
    text: _emberData.default.attr("string")
  });
});