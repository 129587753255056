define("budgeta/components/integration-wizard-sync-accounts", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    accountsToSyncRows: Ember.computed.alias("step.accountsToSyncRows"),
    sipAccountList: Ember.computed("budgetService.accountsMap", function () {
      return (0, _toConsumableArray2.default)(this.get("budgetService.accountsMap").values()).sortBy("account");
    }),
    sipPnlAccountList: Ember.computed("sipAccountList", function () {
      return this.get("sipAccountList").filterBy("type", "pnl");
    }),
    sipBsAccountList: Ember.computed("sipAccountList", function () {
      return this.get("sipAccountList").filterBy("type", "bs");
    }),
    sipStatAccountList: Ember.computed("sipAccountList", function () {
      return this.get("sipAccountList").filterBy("type", "stat");
    }),
    syncAccounts: Ember.computed("accountsToSyncRows.length", function () {
      return (this.get("accountsToSyncRows") || []).mapBy("siAccount");
    }),
    showDimensionsColumn: Ember.computed("syncAccounts.length", function () {
      return this.get("syncAccounts").find(function (account) {
        return account["ACCOUNTTYPE"] !== "balancesheet";
      });
    }),
    showReportingColumn: Ember.computed("syncAccounts.length", function () {
      return this.get("syncAccounts").find(function (account) {
        return account["ACCOUNTTYPE"] === "cumulative" || account["ACCOUNTTYPE"] === "forperiod";
      });
    })
  });
});