define("budgeta/services/automation", ["exports", "budgeta/utils/automation"], function (_exports, _automation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    treeManager: Ember.inject.service(),
    moreParams: {},
    setParam: function setParam(key, value) {
      if (_automation.default.isAutomationMode()) {
        this.moreParams[key] = value;
      }
    },
    isAutomationMode: false,
    init: function init() {
      this.set("isAutomationMode", localStorage.getItem("isAutomationMode"));
      var sheetsData = this.get("moreParams.sheetsData") || [];
      var gridData = this.get("moreParams.gridData") || [];
      // noty = () => {};
      var _this = this;
      window.getAppDetails = function () {
        var treeExpandedItemsCount = _this.get("treeManager.inSearchMode") ? _this.get("treeManager.budgetsListSearchMode") : _this.get("treeManager.budgetsListTreeWithoutModel");
        return Object.assign({}, {
          treeExpandedItemsCount: treeExpandedItemsCount.length,
          sheetsData: sheetsData,
          gridData: gridData
        }, _this.moreParams);
      };
    }
  });
});