define("budgeta/admin/company-admin-app/pages/auditLoggingList/const", ["exports", "@sibp/ui-components", "budgeta/services/audit-logging/audit-logging-parser", "budgeta/services/ember-service-container", "budgeta/admin/company-admin-app/pages/auditLoggingList/audit-logging-messages", "budgeta/admin/models/userModel", "react"], function (_exports, _uiComponents, _auditLoggingParser, _emberServiceContainer, _auditLoggingMessages, _userModel, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.auditLoggingColumns = void 0;
  var auditLoggingColumns = _exports.auditLoggingColumns = function auditLoggingColumns() {
    var intl = new _emberServiceContainer.default().lookup("intl");
    var messages = (0, _auditLoggingMessages.default)();
    var logParser = (0, _auditLoggingParser.default)(messages);
    return [{
      header: intl.t("admin_app.tables.company_name"),
      accessor: "company.name",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref) {
        var value = _ref.value;
        return _react.default.createElement("div", null, value);
      },
      sortDir: 1
    }, {
      header: intl.t("admin_app.tables.action"),
      accessor: "actionId",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref2) {
        var value = _ref2.value;
        return _react.default.createElement("div", {
          "data-hook": value
        }, value);
      }
    }, {
      header: intl.t("admin_app.tables.action_details"),
      accessor: "actionDetails",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref3) {
        var otherProps = _ref3.otherProps;
        return logParser.parse(otherProps);
      }
    }, {
      header: intl.t("admin_app.tables.action_date"),
      accessor: "timestamp",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.DATE,
      cell: function cell(_ref4) {
        var value = _ref4.value;
        return value ? new Date(value).format("{Mon} {d}, {yyyy}, {HH}:{mm}") : "";
      }
    }, {
      header: intl.t("admin_app.tables.username"),
      accessor: "user",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref5) {
        var value = _ref5.value;
        return value ? new _userModel.default(value).fullName : "";
      }
    }, {
      header: intl.t("admin_app.tables.email"),
      accessor: "user.email",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref6) {
        var value = _ref6.value;
        return value || "";
      }
    }, {
      header: intl.t("admin_app.tables.role"),
      accessor: "roleId",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING
    }];
  };
});