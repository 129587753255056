define("budgeta/components/content-budget-tree-no-data", ["exports", "budgeta/services/ember-service-container", "lodash/lodash"], function (_exports, _emberServiceContainer, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var onEmptyRowAdded = _lodash.default.debounce(function () {
    var cache = new _emberServiceContainer.default().lookup("cache");
    Ember.set(cache, "cacheUpdatedTime", new Date().getTime());
  }, 200);
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["empty-budget-label", "centerVElement"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.schedule("afterRender", this, function () {
        onEmptyRowAdded();
      });
    }
  });
});