define("budgeta/components/main/budget/import-modal", ["exports", "budgeta/services/ember-service-container", "budgeta/components/modal-new"], function (_exports, _emberServiceContainer, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers, ember/jquery-ember-run */
  var _default = _exports.default = _modalNew.default.extend({
    classNameBindings: [":import", ":simple"],
    scrollWidth: 600,
    scrollWidthWithAddedCols: Ember.computed("scrollWidth", function () {
      return this.get("scrollWidth") + (this.get("controllertmp.mappingColumn") ? 480 : 240);
    }),
    scrollHeightStyle: Ember.String.htmlSafe("height:100%;position:relative;"),
    adjustListSize: function adjustListSize() {
      var _this2 = this;
      Ember.run.schedule("afterRender", function () {
        var listContainer = _this2.$(".scroll-list.main:not(.top)");
        if (listContainer && listContainer.length) {
          _this2.set("scrollWidth", listContainer.width());
        }
      });
    },
    adjustListSizeObserver: Ember.observer("controllertmp.currentStep", "controllertmp.loadingMessage", "controllertmp.workbook", "controllertmp.mapCategories", function () {
      this.adjustListSize();
    }),
    checkHasInnerScroll: function checkHasInnerScroll() {
      var _this3 = this;
      Ember.run.schedule("afterRender", function () {
        if (!_this3.$() || !Ember.$(".scrollable") || !Ember.$(".scrollable").height) {
          return;
        }
        var tableHeight = Ember.$(".scrollable").height();
        var rowsHeight = Ember.$(".scrollable>div>ul>div>div>div").height();
        if (rowsHeight > tableHeight) {
          _this3.set("controllertmp.hasInnerScroll", true);
        } else {
          _this3.set("controllertmp.hasInnerScroll", false);
        }
      });
    },
    syncScroll: function syncScroll() {
      Ember.run.schedule("afterRender", this, function () {
        Ember.$(document).off("click.import-select").on("click.import-select", ".import .scroll-list.selectable li, .import .scroll-list.selectable li input[type='checkbox']", function (e) {
          var _this4 = this;
          Ember.run(function () {
            if (e.shiftKey) {
              var $this = Ember.$(_this4);
              var item = $this.data("item");
              // select all elements to the one clicked
              $this.closest("li").prevUntil(".ui-selected").each(function (idx, el) {
                Ember.$(el).click();
              });
              Ember.$("li[data-item=\"".concat(item, "\"]")).click();
            }
          });
        });
        var headerScroll = this.$(".scroll-list.top.main");
        if (headerScroll && headerScroll.length > 0) {
          var _this = this;
          var leftScrollList = _this.$(".left-scroll-wrapper .scroll-list .ember-list-view-list > div");
          var leftScrollRowsSync = _this.$(".scroll-list.main, .scroll-list.main .ember-list-view-list > div");
          var vertScrollRowsSync = _this.$(".scroll-list.main .ember-list-view-list > div");
          var isScrollingLeft = false;
          var isScrollingMain = false;
          var syncScrollLeftSide = function syncScrollLeftSide(event) {
            if (!isScrollingLeft && event.deltaY !== 0) {
              isScrollingMain = true;
              vertScrollRowsSync.scrollTop(leftScrollList.scrollTop());
            }
            isScrollingLeft = false;
          };
          var syncScrollVert = function syncScrollVert(event) {
            var scrollFactor = event.deltaFactor || 1,
              scrollLeft = event.deltaX ? event.deltaX * scrollFactor : event.deltaX,
              scrollTop = event.deltaY ? event.deltaY * scrollFactor : event.deltaY;
            if (scrollLeft === undefined) {
              scrollLeft = event.target.scrollLeft;
              if (leftScrollRowsSync) {
                leftScrollRowsSync.each(function (idx, el) {
                  if (el !== event.target) {
                    el.scrollLeft = scrollLeft;
                  }
                });
              }
            } else if (scrollLeft) {
              event.preventDefault();
              if (leftScrollRowsSync) {
                leftScrollRowsSync.each(function (idx, el) {
                  el.scrollLeft += scrollLeft;
                });
              }
            }
            if (!isScrollingMain && scrollTop !== 0) {
              isScrollingLeft = true;
              leftScrollList.scrollTop(vertScrollRowsSync.scrollTop());
            }
            isScrollingMain = false;
          };
          this.$(".top-scroll-wrapper").off("mousewheel DOMMouseScroll scroll touchmove", ".scroll-list.top.main").on("mousewheel DOMMouseScroll scroll touchmove", ".scroll-list.top.main", syncScrollVert);
          this.$(".scroll-list.main:not(.top)").off("mousewheel DOMMouseScroll scroll touchmove", ".ember-list-view-list").on("mousewheel DOMMouseScroll scroll touchmove", ".ember-list-view-list", syncScrollVert);
          vertScrollRowsSync.on("scroll", syncScrollVert);
          this.$(".scrollable .scroll-list.left").off("mousewheel DOMMouseScroll scroll touchmove", ".ember-list-view-list").on("mousewheel DOMMouseScroll scroll touchmove", ".ember-list-view-list", syncScrollLeftSide);
          leftScrollList.on("scroll", syncScrollLeftSide);
        }
      });
    },
    syncScrollObserver: Ember.observer("controllertmp.loadingMessage", "controllertmp.currentStep", "controllertmp.selectedSheet", "controllertmp.namesLocation", "controllertmp.nameColumn", "controllertmp.renderTableInReact", function () {
      this.syncScroll();
      this.checkHasInnerScroll();
    }),
    change: function change(event) {
      var topContainer = Ember.$(event.target).closest("ul.scroll-list.top");
      if (topContainer.length > 0) {
        var el = Ember.$(event.target);
        var value = el.val();
        var selectedValues = topContainer.find("select:not(#".concat(event.target.id, ")")).map(function (idx, item) {
          return Ember.$(item).val();
        }).get();
        if (value && value !== "#date#" && value !== "notes" && selectedValues.indexOf(value) >= 0) {
          this.get("controllertmp").send("showNotification", "Error", el.find("option[value=\"".concat(value, "\"]")).text() + intl.t("general._is_already_selected_1698234255640"));
          el.val("");
        }
      } else {
        var files = event.target.files;
        if (files && !this.get("controllertmp.importGenericFile")) {
          this.get("controllertmp").send("loadFile", files[0]);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this5 = this;
      this._super.apply(this, arguments);
      Ember.$(window).on("resize.import", function () {
        _this5.adjustListSize();
      });
      this.adjustListSize();
      this.syncScroll();
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off("resize.import");
      Ember.$(document).off("click.import-select");
    }
  });
});