define("budgeta/generic-file/components/account-category-error/account-category-error", ["exports", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "react"], function (_exports, _uiComponents, _reactRedux, _stepsActions, _emberServiceContainer, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = CategoryError;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function CategoryError() {
    var dispatch = _reactRedux.default.useDispatch();
    var options = {
      hideClose: true,
      height: "345px",
      className: "step-account-category-error-container"
    };
    var prefix = _react.default.createElement(_uiComponents.default.IMAGES.BigWarningSvg, null);
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore;
      }),
      isDimensionMapped = _ReactRedux$useSelect.isDimensionMapped,
      currencyColumnKey = _ReactRedux$useSelect.currencyColumnKey,
      dimensionUniqueValuesCounter = _ReactRedux$useSelect.dimensionUniqueValuesCounter;
    _react.default.useEffect(function () {
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.MISMATCH_POPUP);
    }, []);
    var header = {
      text: intl.t("generic-file.step-account-category-error.header"),
      options: {
        prefix: prefix,
        prefixClass: "header-container title-next-to-prefix",
        titleClass: "title",
        hideSeperator: true
      }
    };
    var body = _react.default.createElement("div", {
      className: "body-container"
    }, _react.default.createElement("div", {
      className: "description"
    }, intl.t("generic-file.step-account-category-error.description")));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button",
      dataHook: "footer-tertiary-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button with-border",
      dataHook: "footer-secondary-button",
      text: intl.t("generic-file.preview"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CLICKED_MISMATCH_LINES_PREVIEW);
        dispatch(_stepsActions.default.accountCategorySkip(false));
        dispatch(_stepsActions.default.nextStep({
          continue: false,
          nextStep: null,
          skipDimensionsHierarchyQuestionStep: !isDimensionMapped || !Object.values(dimensionUniqueValuesCounter).some(function (value) {
            return value > 1;
          })
        }));
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.continue-anyway"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.SKIPPED_ACCOUNT_GROUP_ERROR);
        dispatch(_stepsActions.default.nextStep({
          continue: true,
          currencyColumnKey: currencyColumnKey,
          skipDimensionsHierarchyQuestionStep: !isDimensionMapped || !Object.values(dimensionUniqueValuesCounter).some(function (value) {
            return value > 1;
          })
        }));
      }
    }));
    var footer = {
      buttons: buttons,
      options: {
        className: "footer-container"
      }
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});