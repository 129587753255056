define("budgeta/templates/components/dimension-draggable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fqSwUxEN",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"dimension-content\"],[\"dynamic-attr\",\"draggable\",[\"unknown\",[\"draggable\"]],null],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"unless\"],[[\"get\",[\"disableDimension\"]]],null,0],[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"small-text overflow-ellipsis dimension-content-text\"],[\"dynamic-attr\",\"id\",[\"concat\",[[\"unknown\",[\"triggerId\"]]]]],[\"dynamic-attr\",\"title\",[\"concat\",[[\"unknown\",[\"dimension\",\"text\"]]]]],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"unknown\",[\"dimension\",\"text\"]],false],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\\n\"],[\"append\",[\"helper\",[\"help-iq\"],null,[[\"tooltipTextList\",\"valueId\",\"valueId2\",\"maxWidth\",\"height\",\"defaultName\",\"addClasses\"],[[\"get\",[\"dimensionValues\"]],\"NAME\",\"ID\",260,200,\"info_outline\",\"remove-padding\"]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"name\",\"class\"],[\"grab_white\",\"drag-handle\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/dimension-draggable.hbs"
    }
  });
});