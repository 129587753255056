define("budgeta/components/add-model-to-budget-modal", ["exports", "budgeta/components/modal-new", "budgeta/templates/components/modal-new"], function (_exports, _modalNew, _modalNew2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    layout: _modalNew2.default,
    attachTips: function attachTips() {
      var _this = this;
      Ember.run.scheduleOnce("afterRender", function () {
        if (_this.$()) {
          _this.$("[title]:not([data-hasqtip]):not(.modal-title):not(.added):not(.new-line)").qtip({
            position: {
              target: "mouse"
            }
          });
        }
      });
    },
    attachTipsObserver: Ember.observer("controllertmp.attachTips", function () {
      this.attachTips();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.attachTips();
    }
  });
});