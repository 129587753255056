define("budgeta/components/input-comma-separator", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":input-comma"],
    displayValue: Ember.computed("realValue", {
      get: function get() {
        return _const.default.formatStringNumber(this.get("realValue"));
      },
      set: function set(key, value) {
        var newValue = _const.default.formatStringNumber(value);
        this.set("realValue", newValue);
        return newValue;
      }
    })
  });
});