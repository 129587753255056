define("budgeta/generic-file/components/generic-manager/functionRenderer", ["exports", "react", "@sibp/ui-components", "budgeta/services/ember-service-container"], function (_exports, _react, _uiComponents, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contentRendering = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var PROGRESS_BAR_PATH = "generic-file.progress-bar";
  var _SIBP$COMPONENTS$Tree = _uiComponents.default.COMPONENTS.TreeMenu,
    Tree = _SIBP$COMPONENTS$Tree.Tree,
    Folder = _SIBP$COMPONENTS$Tree.Folder,
    File = _SIBP$COMPONENTS$Tree.File;
  var renderStepList = function renderStepList(textList, subStep) {
    var activeStep = textList.indexOf(subStep) + 1;
    var stepList = textList.map(function (text, idx) {
      return {
        text: text,
        number: idx + 1
      };
    });
    return _react.default.createElement(_uiComponents.default.COMPONENTS.StepManager, {
      steps: stepList,
      activeStep: activeStep
    });
  };
  var renderTextWithIcon = function renderTextWithIcon(text, iconName, stepClassName) {
    return _react.default.createElement("div", {
      className: "text-icon-container " + stepClassName
    }, iconName === "arrow-left" ? _react.default.createElement(_uiComponents.default.IMAGES.ArrowLeftSvg, null) : iconName === "Show" ? _react.default.createElement(_uiComponents.default.IMAGES.ShowSvg, null) : _react.default.createElement(_uiComponents.default.IMAGES.SubdirectoryRightSvg, null), _react.default.createElement("div", {
      className: "text"
    }, text));
  };
  var contentRendering = _exports.contentRendering = {};
  contentRendering[intl.t("".concat(PROGRESS_BAR_PATH, ".main-steps.setup"))] = function (textList, subStep) {
    return renderStepList(textList, subStep);
  };
  contentRendering[intl.t("".concat(PROGRESS_BAR_PATH, ".main-steps.map-data"))] = function (textList, subStep) {
    var stepProgressLength = 4;
    var subStepIdx = textList.indexOf(subStep);
    if (subStepIdx < stepProgressLength) {
      return _react.default.createElement(_react.default.Fragment, null, renderStepList(textList.slice(0, stepProgressLength), subStep), subStep === intl.t("".concat(PROGRESS_BAR_PATH, ".sub-steps.finalize-mapping")) && _react.default.createElement("div", {
        className: "finalize-arrow-right"
      }, _react.default.createElement(_uiComponents.default.IMAGES.ArrowRightSvg, null)));
    } else {
      var treeSteps = textList.slice(stepProgressLength);
      var activeIdx = subStepIdx - stepProgressLength;
      var childrenWithIcons = treeSteps.map(function (childText, idx) {
        return {
          text: childText,
          iconName: "SubdirectoryRight",
          className: idx === activeIdx ? "active" : idx > activeIdx ? "next" : "prev"
        };
      });
      return _react.default.createElement(Tree, {
        initializedSelectedIndex: "file-0-".concat(activeIdx)
      }, _react.default.createElement(Folder, {
        withArrowIcon: false,
        content: function content() {
          return renderTextWithIcon(intl.t("".concat(PROGRESS_BAR_PATH, ".sub-steps.finalize-mapping")), "arrow-left", "");
        },
        isAlwaysExpanded: true
      }, childrenWithIcons.map(function (child) {
        var text = child.text,
          iconName = child.iconName,
          className = child.className;
        return _react.default.createElement(File, {
          key: text,
          content: function content() {
            return renderTextWithIcon(text, iconName, className);
          }
        });
      })));
    }
  };
  contentRendering[intl.t("".concat(PROGRESS_BAR_PATH, ".main-steps.taylor-budget"))] = function (textList, subStep) {
    return renderStepList(textList, subStep);
  };
});