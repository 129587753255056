define("budgeta/templates/components/forecast-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "s9oZ97A0",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"component\"],[[\"get\",[\"forecastRowTemplateName\"]]],[[\"classNames\",\"allContent\",\"mode\",\"budget\",\"currency\",\"exchangeRates\",\"showNegativeAsPositive\",\"headerItems\",\"isCount\",\"isPercent\",\"userId\",\"defaultPrecision\",\"dataPrecision\",\"scenario\",\"sideBySide\",\"vsOption\"],[\"forecast-row\",[\"get\",[\"data\",\"values\"]],[\"get\",[\"mode\"]],[\"get\",[\"data\",\"budget\"]],[\"get\",[\"data\",\"originalCurrency\"]],[\"get\",[\"exchangeRates\"]],[\"get\",[\"data\",\"showNegativeAsPositive\"]],[\"get\",[\"headerItems\"]],[\"get\",[\"data\",\"isCount\"]],[\"get\",[\"data\",\"isPercent\"]],[\"get\",[\"userId\"]],[\"get\",[\"defaultPrecision\"]],[\"get\",[\"data\",\"precision\"]],[\"get\",[\"scenario\"]],[\"get\",[\"sideBySide\"]],[\"get\",[\"vsOption\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/forecast-row.hbs"
    }
  });
});