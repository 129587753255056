define("budgeta/admin/utils/routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInRoutes = _exports.isInRoute = void 0;
  var isInRoutes = _exports.isInRoutes = function isInRoutes(currentRoute, routes) {
    return routes.find(function (route) {
      return isInRoute(currentRoute, route, false);
    });
  };
  var isInRoute = _exports.isInRoute = function isInRoute(currentRoute, expectedRoute) {
    var exactMatch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var currentRouteParts = currentRoute.split("/");
    var expectedRouteParts = expectedRoute.split("/");

    // firstly, check array length
    if (exactMatch && currentRouteParts.length !== expectedRouteParts.length) {
      return false;
    }
    var partIndex = 0;

    // compare every part in arrays
    while (partIndex < expectedRouteParts.length) {
      // ignore query parameter
      var shouldSkipComparison = expectedRouteParts[partIndex].indexOf(":") === 0;
      var isSameRoutePart = expectedRouteParts[partIndex] === currentRouteParts[partIndex];
      if (!shouldSkipComparison && !isSameRoutePart) {
        return false;
      }
      partIndex++;
    }
    return true;
  };
});