define("budgeta/components/intacct-entities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isLoadingSettings: Ember.computed("data.refresh", function () {
      return !this.get("data.refresh");
    }),
    singleEntity: Ember.computed.equal("data.singleEntity", "1")
  });
});