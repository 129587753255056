define("budgeta/components/mix-chart", ["exports", "budgeta/utils/const", "budgeta/utils/dashboard", "budgeta/components/budgeta-chart", "budgeta/config/environment"], function (_exports, _const, _dashboard, _budgetaChart, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _budgetaChart.default.extend({
    stacking: null,
    chartOptions: Ember.computed("chartCategories", "showLegend", "highchartCurrency", "stacking", "highchartShowAnimation", "colorPalette", "highchartHeight", "highchartWidth", function () {
      var chartCategories = this.get("chartCategories");
      var showLegend = this.get("showLegend") ? true : false;
      var colorPalette = this.get("colorPalette");
      var chartWidth = _dashboard.default.getChartWidth(this.get("isExpanded"));
      var chartHeight = _dashboard.default.getChartHeight(this.get("isExpanded"), showLegend);
      var showAnimation = this.get("highchartShowAnimation") ? true : false,
        ignoreTooltip = this.get("ignoreTooltip"),
        value_factors = this.get("value_factors") || {},
        secondSeriesName = this.get("secondSeriesName"),
        isRollingForecastViewType = this.get("isRollingForecastViewType");
      if (this.get("highchartHeight")) {
        chartHeight = this.get("highchartHeight");
      }
      if (this.get("highchartWidth")) {
        chartWidth = this.get("highchartWidth");
      }
      var currecnyArray = [""];
      if (this.get("highchartCurrency")) {
        currecnyArray = this.get("highchartCurrency");
      }
      return {
        chart: showLegend ? {
          height: chartHeight,
          width: chartWidth,
          marginTop: 100
        } : {
          height: chartHeight,
          width: chartWidth
        },
        colors: colorPalette,
        title: {
          text: ""
        },
        exporting: {
          enabled: false
        },
        xAxis: {
          categories: chartCategories
        },
        yAxis: [{
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArray[0];
              if (currency === "%") {
                return _const.default.formatKMBT(this.value, 2) + currency;
              }
              return currency + _const.default.formatKMBT(this.value, 2);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2
        }, {
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArray.length === 3 ? currecnyArray[2] : _const.default.nextDiffElementInArray(currecnyArray[0], 1, currecnyArray);
              if (currency === "%") {
                return _const.default.formatKMBT(this.value, 2) + currency;
              }
              return currency + _const.default.formatKMBT(this.value, 2);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2,
          opposite: true
        }],
        tooltip: {
          useHTML: true,
          formatter: function formatter() {
            var points = '<table class="tip">' + this.x + "<tbody>";
            //loop each point in this.points
            Ember.$.each(this.points, function (i, point) {
              var currencySymbol = point.series.index >= currecnyArray.length ? currecnyArray[currecnyArray.length - 1] : currecnyArray[point.series.index];
              var valueWithCurrency;
              if (currencySymbol === "%") {
                valueWithCurrency = _const.default.formatStringNumber(_const.default.round(point.y, 2)) + currencySymbol;
              } else {
                valueWithCurrency = currencySymbol + _const.default.formatKMBT(point.y, 2);
              }
              var seriesName = point.series.name;
              if (isRollingForecastViewType) {
                var valueKey = new Date("01 " + point.key);
                if (!value_factors[valueKey.format("{yyyy}/{MM}")]) {
                  seriesName = seriesName.replace(" (Actual)", secondSeriesName);
                }
              }
              points += "<tr>" + '<td style="color: ' + point.series.color + '; padding-right: 10px">' + seriesName + "</td>" + '<td style="text-align: right">' + valueWithCurrency + "</td>" + "</tr>";
            });
            points += "</tbody></table>";
            if (ignoreTooltip) {
              return points;
            } else {
              Ember.$(".chart-tooltip").html(points).removeClass("empty");
              return false;
            }
          },
          shared: true
        },
        plotOptions: {
          column: {
            stacking: this.get("stacking"),
            animation: showAnimation
          },
          series: {
            marker: {
              enabled: false
            },
            events: {
              mouseOut: function mouseOut() {
                Ember.$(".chart-tooltip").html("").addClass("empty");
              }
            }
          }
        },
        legend: {
          enabled: showLegend,
          verticalAlign: "top",
          align: "left",
          floating: true,
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 6,
          itemStyle: {
            color: "#334356",
            fontSize: "15px",
            fontWeight: "normal"
          }
        }
      };
    }),
    chartData: Ember.computed("chartDataBeforeOrder", function () {
      var chartData = this.get("chartDataBeforeOrder");
      return chartData;
    }),
    chartDataBeforeOrder: Ember.computed("budgetaChartData", "showy2", function () {
      var chartData = this.get("budgetaChartData");
      chartData = _dashboard.default.roundChartData(chartData);
      if (this.get("showy2")) {
        chartData = chartData.map(function (ele, index) {
          if (!ele.hasOwnProperty("yAxis")) {
            ele.yAxis = index % 2;
          }
          return ele;
        });
      }
      return chartData;
    }),
    attributeBindings: ["data-highchart-data", "data-highchart-categories"],
    "data-highchart-data": Ember.computed("chartDataBeforeOrder", function () {
      if (_environment.default.environment === "development" || _environment.default.environment === "staging" || _environment.default.budgetaEnvironment === "qa" || _environment.default.budgetaEnvironment === "dev") {
        return (JSON.stringify(this.get("chartDataBeforeOrder")) || "").replace(new RegExp('"', "g"), "'");
      }
      return "";
    }),
    "data-highchart-categories": Ember.computed("chartCategories", function () {
      if (_environment.default.environment === "development" || _environment.default.environment === "staging" || _environment.default.budgetaEnvironment === "qa" || _environment.default.budgetaEnvironment === "dev") {
        return (JSON.stringify(this.get("chartCategories")) || "").replace(new RegExp('"', "g"), "'");
      }
      return "";
    })
  });
});