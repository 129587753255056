define("budgeta/components/employees-table-location-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    location: Ember.computed("line.parent.id", {
      get: function get() {
        return this.get("line.parent.id");
      },
      set: function set(key, value) {
        this.sendAction("move", this.get("store").peekRecord("budget", this.get("line.id")), this.get("store").peekRecord("budget", value));
      }
    }),
    disable: Ember.computed("isEnabled", function () {
      return !this.get("isEnabled");
    }),
    locationText: Ember.computed("location", "employeeLocations", function () {
      var locations = this.get("employeeLocations");
      var location = locations.findBy("id", this.get("location"));
      return location ? location.text : "";
    })
  });
});