define("budgeta/components/integration-wizard-cash-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["table-editor"],
    columnsLayout: [100],
    listStyle: Ember.String.htmlSafe("height:400px;position:relative;"),
    click: function click(e) {
      var target = Ember.$(e.target);
      var wrapper = target.closest(".budgeta-checkbox");
      if (e.shiftKey && wrapper.length && !wrapper.find('input[type="checkbox"]').is(":checked")) {
        // find the last selected line and select all the lines in between
        wrapper.closest(".ember-list-view.ember-list-view-list > div > div > div").prevUntil(":has(:checked)").each(function (idx, line) {
          Ember.$(line).find(".budgeta-checkbox label").click();
        });
      }
    }
  });
});