define("budgeta/components/budget-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "ol",
    treeHeight: 500,
    maxResizeTreeHeight: 500,
    budgetTreeContainerResizeObserver: null,
    treeHeightStyle: Ember.computed("treeHeight", function () {
      return Ember.String.htmlSafe("height:".concat(this.get("treeHeight"), "px;position:relative;"));
    }),
    columnsLayout: [100],
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      var _this = this;
      this.updateTreeHeight();
      Ember.$(window).off("resize.tree").on("resize.tree", function () {
        Ember.run.schedule("afterRender", function () {
          if (_this2.$() && _this2.$().height() > 0) {
            _this2.set("treeHeight", _this2.$().height());
          }
        });
      });
      this.get("verticalResize") && Ember.$(".tree-resizer").draggable({
        axis: "y",
        containment: "parent",
        helper: "clone",
        start: function start(event, ui) {
          _this.set("heightOffSet", Math.floor(ui.offset.top));
          _this.set("offSetFromEvent", false);
        },
        drag: function drag(event, ui) {
          if (!_this.get("offSetFromEvent") && _this.get("heightOffSet") === Math.floor(ui.offset.top)) {
            _this.set("offSetFromEvent", true);
          }
          var topNewOffSet = _this.get("offSetFromEvent") ? event.clientY : Math.floor(ui.offset.top);
          var newHeight = _this.get("treeHeight") - (_this.get("heightOffSet") - topNewOffSet);
          if (newHeight > 0 && newHeight < _this.get("maxResizeTreeHeight")) {
            _this.set("treeHeight", newHeight);
            _this.set("heightOffSet", topNewOffSet);
          }
        }
      });
      this.get("listenToTreeItemsLength") && this.listenToSplitBudgetTreeChanges();
    },
    willDestroyElement: function willDestroyElement() {
      var _this$budgetTreeConta;
      Ember.$(window).off("resize.tree");
      (_this$budgetTreeConta = this.budgetTreeContainerResizeObserver) === null || _this$budgetTreeConta === void 0 || _this$budgetTreeConta.disconnect();
    },
    viewSelections: Ember.inject.service(),
    updateTreeHeight: function updateTreeHeight() {
      var _this3 = this;
      Ember.run.scheduleOnce("afterRender", function () {
        var height = _this3.get("treeHeight");
        if (_this3.$()) {
          var parentHeight;
          if (_this3.$().parent()) {
            parentHeight = _this3.$().parent().height();
            _this3.set("maxResizeTreeHeight", parentHeight - 97);
          }
          height = parentHeight || _this3.$().height();
        }
        _this3.set("treeHeight", height * (_this3.get("splitHeight") ? 0.7 : 1));
      });
    },
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    collapsedBudgets: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    showTree: Ember.computed("maximized", function () {
      if (!this.get("maximized")) {
        Ember.$(window).trigger("resize.tree");
        return true;
      }
      return false;
    }),
    attributeBindings: ["data-hook"],
    "data-hook": Ember.computed("dataHook", function () {
      return this.get("dataHook") || "budget-tree";
    }),
    classNameBindings: [":tree", ":nav", "showTree:show-tree", "importing:importing:", "scenario:scenario:", "isTreeViewOnly:tree-read-only:", "metrics:metrics:", "forSearchMode:search-tree-list:", "inSearchMode:in-search-mode:"],
    treeItemsObserver: Ember.observer("listenToTreeItemsLength", function () {
      this.get("listenToTreeItemsLength") ? this.listenToSplitBudgetTreeChanges() : this.updateTreeHeight();
    }),
    listenToSplitBudgetTreeChanges: function listenToSplitBudgetTreeChanges() {
      var _this4 = this;
      Ember.run.scheduleOnce("afterRender", this, function () {
        var _this4$budgetTreeCont;
        (_this4$budgetTreeCont = _this4.budgetTreeContainerResizeObserver) === null || _this4$budgetTreeCont === void 0 || _this4$budgetTreeCont.disconnect();
        var budgetTreeContainerElement = document.querySelector("ol[data-hook='budget-tree-model'] .budget-tree-container");
        if (!budgetTreeContainerElement) {
          return;
        }
        if (!_this4.budgetTreeContainerResizeObserver) {
          _this4.budgetTreeContainerResizeObserver = new ResizeObserver(function (entries) {
            if (_this4.get("isDestroying") || _this4.get("isDestroyed")) {
              return;
            }
            !_this4.get("initialTreeHeight") && _this4.set("initialTreeHeight", _this4.get("treeHeight"));
            _this4.set("treeHeight", _this4.get("initialTreeHeight") - entries[0].target.offsetHeight - 100);
          });
        }
        _this4.budgetTreeContainerResizeObserver.observe(budgetTreeContainerElement);
      });
    },
    actions: {
      goto: function goto(budget, dest, resetVersion) {
        this.sendAction("goto", budget, dest, resetVersion, undefined, true);
      },
      duplicate: function duplicate(budget, formatOnly) {
        this.sendAction("duplicate", budget, formatOnly);
      },
      delete: function _delete(budget) {
        this.sendAction("delete", budget);
      },
      addChildren: function addChildren(budget) {
        this.sendAction("addChildren", budget);
        return false;
      },
      showMore: function showMore() {
        this.sendAction("showMore");
      },
      showLess: function showLess() {
        this.sendAction("showLess");
      },
      copy: function copy(budget, dest) {
        this.sendAction("copy", budget, dest);
      },
      move: function move(budget, dest, position, tagEvent, subTagEvent) {
        this.sendAction("move", budget, dest, position, undefined, tagEvent, subTagEvent);
      },
      flag: function flag(budget) {
        this.sendAction("flag", budget);
      },
      mergeScenario: function mergeScenario(options) {
        this.sendAction("mergeScenario", options);
      },
      share: function share(budget) {
        this.sendAction("share", budget);
      },
      editTree: function editTree() {
        this.sendAction("editTree");
      },
      newLineChosen: function newLineChosen(budget) {
        this.sendAction("newLineChosen", budget);
      },
      lineRenamed: function lineRenamed(line, nameBefore) {
        this.sendAction("lineRenamed", line, nameBefore);
      },
      saveNew: function saveNew(budget) {
        this.sendAction("saveNew", budget);
      },
      importWizard: function importWizard(budget) {
        this.sendAction("importWizard", budget || this.get("budget"));
      },
      downloadTemplate: function downloadTemplate(budget) {
        this.sendAction("downloadTemplate", budget);
      },
      saveToModels: function saveToModels(budget) {
        this.sendAction("saveToModels", budget);
      },
      addModelFromStore: function addModelFromStore(budget) {
        this.sendAction("addModelFromStore", budget);
      },
      showLineDependencies: function showLineDependencies(budget) {
        this.sendAction("showLineDependencies", budget);
      },
      discardAll: function discardAll() {
        this.sendAction("discardAll");
      }
    }
  });
});