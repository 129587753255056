define("budgeta/controllers/company-admin-route", ["exports", "budgeta/services/ember-service-container", "react", "react-dom", "budgeta/components/company-admin-root"], function (_exports, _emberServiceContainer, _react, _reactDom, _companyAdminRoot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/require-super-in-init */
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service("-routing"),
    init: function init() {
      var companyId = this.get("router.router.router.state.params.company-admin-route.companyId");
      if (!companyId) {
        return;
      }
      Ember.run.next(function () {
        var root = document.querySelector("#company-admin-root");
        if (root) {
          var container = _reactDom.default.createRoot(root);
          container.render(_react.default.createElement(_companyAdminRoot.default, {
            companyId: companyId
          }));
        } else {
          noty({
            type: "error",
            text: intl.t("general.text_1695611609403")
          });
        }
      });
    }
  });
});