define("budgeta/components/select-dimension-values-multi", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["multi-select", "budgeta-type-value", "budget-type-multi"],
    attributeBindings: ["data-key", "data-hide", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    budgetService: Ember.inject.service(),
    selectedValues: Ember.computed("budgetAttributes", "key", "content.[]", {
      get: function get() {
        var _this = this;
        return (this.get("budgetAttributes.".concat(this.get("key"))) || []).map(function (v) {
          var _this$get;
          return v && ((_this$get = _this.get("content")) === null || _this$get === void 0 ? void 0 : _this$get.findBy("id", v));
        }).filter(function (v) {
          return v;
        });
      },
      set: function set(key, value) {
        this.set("budgetAttributes.".concat(this.get("key")), value.mapBy("id"));
        return value;
      }
    }),
    applyToDimensionObserver: Ember.observer("budgetAttributes.applyToDimension", function () {
      var _this2 = this;
      var dimension = this.get("budgetAttributes.applyToDimension") && (this.get("budgetService.dimensions") || Ember.A([])).findBy("uniqueId", this.get("budgetAttributes.applyToDimension"));
      if (dimension) {
        Ember.run.later(function () {
          _this2.set("label", intl.t("general.select-dimension-values-multi-1", {
            var1: dimension.get("lowerCasePlural")
          }));
        });
      }
      Ember.run.later(function () {
        _this2.set("content", (dimension && dimension.get("values") || []).map(function (v) {
          return {
            id: Ember.get(v, "uniqueId"),
            text: Ember.get(v, "displayName")
          };
        }));
      });
    }).on("init")
  });
});