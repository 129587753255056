define("budgeta/templates/components/svg/logout-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Fw/Y4pbY",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"20\"],[\"static-attr\",\"height\",\"20\"],[\"static-attr\",\"viewBox\",\"0 0 20 20\"],[\"static-attr\",\"fill\",\"none\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M4 1C2.35498 1 1 2.35498 1 4V16C1 17.645 2.35498 19 4 19H12.5C13.888 19 15.1235 18.0903 15.4688 16.749C15.5577 16.4029 15.4553 16.0356 15.2 15.7855C14.9448 15.5355 14.5755 15.4405 14.2313 15.5365C13.8871 15.6325 13.6202 15.9049 13.5312 16.251C13.4325 16.6347 12.976 17 12.5 17H4C3.43602 17 3 16.564 3 16V4C3 3.43602 3.43602 3 4 3H12.5C12.976 3 13.4325 3.36525 13.5312 3.74902C13.6202 4.09512 13.8871 4.36747 14.2313 4.46347C14.5755 4.55948 14.9448 4.46455 15.2 4.21445C15.4553 3.96435 15.5577 3.59708 15.4688 3.25098C15.1235 1.90975 13.888 1 12.5 1H4ZM14.9893 5.99023C14.5825 5.99074 14.2166 6.23753 14.0637 6.61446C13.9108 6.99138 14.0015 7.42334 14.293 7.70703L15.5859 9H6.5C6.13936 8.9949 5.80392 9.18437 5.62211 9.49587C5.44031 9.80737 5.44031 10.1926 5.62211 10.5041C5.80392 10.8156 6.13936 11.0051 6.5 11H15.5859L14.293 12.293C14.0317 12.5438 13.9265 12.9162 14.0178 13.2667C14.1092 13.6171 14.3829 13.8908 14.7333 13.9822C15.0838 14.0735 15.4562 13.9683 15.707 13.707L18.707 10.707C19.0974 10.3165 19.0974 9.6835 18.707 9.29297L15.707 6.29297C15.5185 6.09919 15.2596 5.98999 14.9893 5.99023Z\"],[\"static-attr\",\"fill\",\"#007E45\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/logout-svg.hbs"
    }
  });
});