define("budgeta/utils/formula-helper", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/budget-utils", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _budgetUtils, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/new-module-imports */

  function FormulaError() {
    var temp = Error.apply(this, arguments);
    temp.name = this.name = "FormulaError";
    this.message = temp.message;
    if (Object.defineProperty) {
      Object.defineProperty(this, "stack", {
        get: function get() {
          return temp.stack;
        },
        configurable: true // so you can change it if you want
      });
    } else {
      this.stack = temp.stack;
    }
  }
  //inherit prototype using ECMAScript 5 (IE 9+)
  FormulaError.prototype = Object.create(Error.prototype, {
    constructor: {
      value: FormulaError,
      writable: true,
      configurable: true
    }
  });
  var FormulaUtilHelper = {
    parseToFormula: function parseToFormula(str, options) {
      if (str === "=" || str === "" || typeof str !== "string" || str === "null" || str === "=null") {
        return jsep("");
      }
      if (!str || str.charAt(0) !== "=" || str.length > 1 && str.charAt(1) === "=") {
        return {
          error: intl.t("general.error_not_a_function_1698234257249")
        };
      }
      str = str.substring(1);
      var jsepObj,
        jsepText = "",
        missingIdsName = [],
        idMap = {};
      var cacheService = options.cache,
        formulaHash;
      if (cacheService) {
        formulaHash = _const.default.hashCode(str);
        var cachedResult = cacheService.getItem(formulaHash, "formulaCache");
        if (cachedResult) {
          return cachedResult;
        }
      }
      if (str.charAt(0) !== "{") {
        if (!str.startsWith("<p>")) {
          str = "<p>" + str;
        }
        if (!str.endsWith("</p>")) {
          str = str + "</p>";
        }
        var formulaElement = Ember.$(str);
        var children = formulaElement[0].childNodes;
        for (var i = 0; i < children.length; i++) {
          if (FormulaUtilHelper.isTextNode(children[i])) {
            var _str = children[i].textContent;
            _str = _str.replace(/(\d|\))=(\d|-|\+|i|m|s|r|\()/gi, "$1==$2") // i - for if, m - max/min, s - sum, r - round
            .replace(/^=(\d*|-|\+|i|m|s|r|\()/i, "==$1").replace(/(\d|\))=$/, "$1==");
            _str = _str.replace(/(\d|\))<>(\d|-|\+|i|m|s|r|\()/gi, "$1!=$2").replace(/^<>(\d*|-|\+|i|m|s|r|\()/i, "!=$1").replace(/(\d|\))<>$/, "$1!=");
            jsepText += _str;
          } else if (FormulaUtilHelper.isSpanNode(children[i])) {
            jsepText += FormulaUtilHelper.convertSpan(children[i]);
            idMap[FormulaUtilHelper.convertSpan(children[i])] = children[i].textContent;
            if (FormulaUtilHelper.hasMissingIdentifier(FormulaUtilHelper.convertSpan(children[i]), options)) {
              // missing
              missingIdsName.push(children[i].textContent);
            }
          }
        }
      } else {
        var data;
        try {
          data = JSON.parse(str);
        } catch (e) {
          data = {
            error: e.toString()
          };
        }
        if (cacheService) {
          cacheService.setItem(formulaHash, "formulaCache", null, data);
        }
        options.originalData = data;
        var missingReferences = FormulaUtilHelper.getMissingReference(data, options);
        if (missingReferences.length > 0) {
          return Ember.merge({
            missingReferences: missingReferences.join(", "),
            error: intl.t("general.missing_references_1698234257249")
          }, data);
        }
        return data;
      }
      try {
        jsepObj = jsep(jsepText);
        jsepObj = Ember.merge({
          references: idMap
        }, jsepObj);
        options.originalData = jsepObj;
        if (jsepObj && jsepObj.type === "Compound") {
          return jsep("");
        }
        if (FormulaUtilHelper.getMissingReference(jsepObj, options).length > 0) {
          var _missingReferences = !missingIdsName || missingIdsName.length === 0 ? FormulaUtilHelper.getMissingReference(jsepObj, options) : missingIdsName;
          return Ember.merge({
            missingReferences: _missingReferences.join(", "),
            error: intl.t("general.missing_references_1698234257249")
          }, jsepObj);
        }
        return jsepObj;
      } catch (e) {
        return {
          error: e.toString(),
          jsepText: jsepText
        };
      }
    },
    isTextNode: function isTextNode(node) {
      return node.nodeName === "#text";
    },
    isSpanNode: function isSpanNode(node) {
      return node.nodeName === "SPAN";
    },
    convertSpan: function convertSpan(span) {
      var spanElement = Ember.$(span);
      return (spanElement.hasClass("line") ? "M" : "A") + spanElement.data("id");
    },
    parseFormulaStringToObject: function parseFormulaStringToObject(str) {
      var obj;
      if (str.charAt(0) === "=") {
        str = str.substring(1);
      }
      try {
        obj = JSON.parse(str);
      } catch (e) {
        obj = {
          error: e.toString()
        };
      }
      return obj;
    },
    JSONToString: function JSONToString(data, options) {
      var withoutFormulaSymbol = options.withoutFormulaSymbol;
      if (data === "=") {
        return withoutFormulaSymbol ? "" : data;
      }
      if (typeof data === "string") {
        if (data.charAt(0) === "{" || data.charAt(0) === "=" && data.charAt(1) === "{") {
          data = this.parseFormulaStringToObject(data);
        } else {
          data = FormulaUtilHelper.parseToFormula(data, options);
        }
      }
      options.originalData = data;
      return (withoutFormulaSymbol ? "" : "=") + FormulaUtilHelper.JSONToStringHelper(data, options);
    },
    JSONToStringHelper: function JSONToStringHelper(data, options) {
      if (FormulaUtilHelper.isLiteral(data)) {
        return data.raw;
      }
      if (FormulaUtilHelper.isIdentifier(data)) {
        return FormulaUtilHelper.convertIdentifierToString(data.name, options);
      }
      if (FormulaUtilHelper.isBinaryExpression(data)) {
        return FormulaUtilHelper.binaryJSONToString(data, options);
      }
      if (FormulaUtilHelper.isUnaryExpression(data)) {
        if (data.argument) {
          return data.operator + FormulaUtilHelper.JSONToStringHelper(data.argument, options);
        } else {
          return data.operator;
        }
      }
      if (FormulaUtilHelper.isCallExpression(data)) {
        var strCallExpression = data.callee.name + "(";
        var innerArgs = [];
        for (var i = 0; i < data.arguments.length; i++) {
          innerArgs.push(FormulaUtilHelper.JSONToStringHelper(data.arguments[i], options));
        }
        strCallExpression += innerArgs.join(",");
        return strCallExpression + ")";
      }
      return "";
    },
    binaryJSONToString: function binaryJSONToString(data, options) {
      var finalOperator = data.operator === "==" ? "=" : data.operator === "!=" ? "<>" : data.operator,
        shouldAddBracketsLeft,
        shouldAddBracketsRight,
        addLeftStartBrackets = "",
        addLeftEndBrackets = "",
        addRightStartBrackets = "",
        addRightEndBrackets = "";
      if (FormulaUtilHelper.isUnaryExpression(data.left)) {
        addLeftStartBrackets = "(";
        addLeftEndBrackets = ")";
      }
      if (FormulaUtilHelper.isUnaryExpression(data.right)) {
        addRightStartBrackets = "(";
        addRightEndBrackets = ")";
      }
      if (finalOperator === "*" || finalOperator === "/" || finalOperator === "^") {
        shouldAddBracketsLeft = FormulaUtilHelper.shouldAddBrackets(data.left, true);
        shouldAddBracketsRight = FormulaUtilHelper.shouldAddBrackets(data.right, true);
        addLeftStartBrackets = shouldAddBracketsLeft ? "(" : "";
        addLeftEndBrackets = shouldAddBracketsLeft ? ")" : "";
        addRightStartBrackets = shouldAddBracketsRight ? "(" : "";
        addRightEndBrackets = shouldAddBracketsRight ? ")" : "";
      }
      return "".concat(addLeftStartBrackets).concat(FormulaUtilHelper.JSONToStringHelper(data.left, options)).concat(addLeftEndBrackets, " ").concat(finalOperator, " ").concat(addRightStartBrackets).concat(FormulaUtilHelper.JSONToStringHelper(data.right, options)).concat(addRightEndBrackets);
    },
    shouldAddBrackets: function shouldAddBrackets(data, foundOperator) {
      if (FormulaUtilHelper.isBinaryExpression(data)) {
        if (data.operator === "*" || data.operator === "/" || data.operator === "^") {
          return FormulaUtilHelper.shouldAddBrackets(data.left, true) || FormulaUtilHelper.shouldAddBrackets(data.right, true);
        }
        return foundOperator;
      }
      return false;
    },
    getObjectForIdentifier: function getObjectForIdentifier(identifier, options) {
      var store = options.store,
        fixedAssumptions = options.fixedAssumptions,
        cache = options.cache,
        scenario = options.scenario,
        budget = options.budget,
        element,
        uniqueId = identifier.substring(1);
      if (cache && (FormulaUtilHelper.isAssumptionIdentifier(identifier) || FormulaUtilHelper.isModelIdentifier(identifier))) {
        if (budget && budget.get("id") && budget.get("id").includes("|")) {
          if (cache.versionsUniqueIdMap[budget.get("absoluteRoot.id")] && cache.versionsUniqueIdMap[budget.get("absoluteRoot.id")][uniqueId]) {
            return cache.versionsUniqueIdMap[budget.get("absoluteRoot.id")][uniqueId];
          }
        } else if (cache.uniqueIdMap[uniqueId]) {
          return cache.uniqueIdMap[uniqueId];
        }
      }
      if (FormulaUtilHelper.isAssumptionIdentifier(identifier)) {
        element = fixedAssumptions.findBy("uniqueId", uniqueId);
      } else if (FormulaUtilHelper.isModelIdentifier(identifier)) {
        element = _budgetUtils.default.findLineByUniqueId(store, cache, uniqueId, undefined, scenario);
      }
      if (cache && (FormulaUtilHelper.isAssumptionIdentifier(identifier) || FormulaUtilHelper.isModelIdentifier(identifier))) {
        if (budget && budget.get("id") && budget.get("id").includes("|")) {
          cache.updateVersionsUniqueIdMap(budget, uniqueId, budget.get("absoluteRoot.id"));
        } else {
          cache.uniqueIdMap[uniqueId] = element;
        }
      }
      return element;
    },
    hasMissingIdentifier: function hasMissingIdentifier(identifier, options) {
      return FormulaUtilHelper.getObjectForIdentifier(identifier, options) ? false : true;
    },
    getMissingReference: function getMissingReference(data, options) {
      if (FormulaUtilHelper.isLiteral(data)) {
        return [];
      }
      if (FormulaUtilHelper.isIdentifier(data)) {
        if (FormulaUtilHelper.hasMissingIdentifier(data.name, options)) {
          return [options.originalData.references[data.name] || data.name];
        }
        return [];
      }
      if (FormulaUtilHelper.isBinaryExpression(data)) {
        var left = FormulaUtilHelper.getMissingReference(data.left, options),
          right = FormulaUtilHelper.getMissingReference(data.right, options);
        return left.concat(right);
      }
      if (FormulaUtilHelper.isUnaryExpression(data)) {
        if (data.argument) {
          return FormulaUtilHelper.getMissingReference(data.argument, options);
        }
        return [];
      }
      if (FormulaUtilHelper.isCallExpression(data)) {
        var missingReferences = [];
        for (var i = 0; i < data.arguments.length; i++) {
          missingReferences = missingReferences.concat(FormulaUtilHelper.getMissingReference(data.arguments[i], options));
        }
        return missingReferences;
      }
      return [];
    },
    isAssumptionIdentifier: function isAssumptionIdentifier(literal) {
      return FormulaUtilHelper.isLegalIdentifier(literal) && literal.charAt(0) === "A";
    },
    isModelIdentifier: function isModelIdentifier(literal) {
      return FormulaUtilHelper.isLegalIdentifier(literal) && literal.charAt(0) === "M";
    },
    isLegalIdentifier: function isLegalIdentifier(literal) {
      return literal && (literal.length === 33 || literal.length === 25);
    },
    setFormulaError: function setFormulaError(options) {
      var isModel = Ember.get(options.budget, "isModelGroup") || Ember.get(options.budget, "isModelLine") || Ember.get(options.budget, "budgetType") === "assumption";
      if (isModel) {
        var _hrefLink = "<a href='/budget/".concat(Ember.get(options.budget, "parent.id"), "?metrics=true'>").concat(intl.t("general.go_to_model_1695803189583"), "</a>");
        if (location && location.pathname === "/budget/".concat(Ember.get(options.budget, "parent.id"))) {
          _hrefLink = "";
        }
        var _message = "".concat(intl.t("general.missing_reference_in_model_line"), "<b>").concat(Ember.get(options.budget, "title"), "</b> under: <b>").concat(Ember.get(options.budget, "parent.title"), "</b>. ").concat(_hrefLink);
        return new FormulaError(_message);
      }
      var hrefLink = "<a href='/budget/".concat(Ember.get(options.budget, "id"), "'>").concat(intl.t("general.go_to_line_1695803189573"), "</a>");
      if (location && location.pathname === "/budget/".concat(Ember.get(options.budget, "id"))) {
        hrefLink = "";
      }
      if (Ember.get(options, "budget.root.readOnly")) {
        hrefLink = "";
      }
      var message = "".concat(intl.t("general.missing_reference_in_budget_line"), "<b>").concat(Ember.get(options.budget, "title"), "</b> under: <b>").concat(Ember.get(options.budget, "parent.title"), "</b>. ").concat(hrefLink);
      return new FormulaError(message);
    },
    convertIdentifierToString: function convertIdentifierToString(identifier, options) {
      var store = options.store,
        fixedAssumptions = options.fixedAssumptions,
        throwException = options.throwException,
        ignoreStore = options.ignoreStore,
        cache = options.cache,
        model = options.model,
        element;
      if (FormulaUtilHelper.isAssumptionIdentifier(identifier)) {
        if (!ignoreStore) {
          element = fixedAssumptions.findBy("assumption.uniqueId", identifier.substring(1));
          if (element) {
            var addParent = "";
            if (!model || model && Ember.get(element, "parent.uniqueId") !== Ember.get(model, "parent.uniqueId") && Ember.get(element, "parent.uniqueId") !== Ember.get(model, "uniqueId")) {
              addParent = Ember.get(element, "parent.name") + " > ";
              if (Ember.get(element, "parent.parent.budgetType") === "group-model-parent") {
                addParent = Ember.get(element, "parent.parent.title") + " > " + addParent;
              }
            }
            return addParent + Ember.get(element, "assumption.name");
          }
        }
        if (throwException) {
          throw FormulaUtilHelper.setFormulaError(options);
        }
        return options.originalData && options.originalData.references && options.originalData.references[identifier] ? options.originalData.references[identifier] : FormulaUtilHelper.isLegalIdentifier(identifier) ? "Missing reference" : identifier;
      } else if (FormulaUtilHelper.isModelIdentifier(identifier)) {
        if (!ignoreStore) {
          element = _budgetUtils.default.findLineByUniqueId(store, cache, identifier.substring(1));
          if (element) {
            var _addParent = "";
            if (!model || model && Ember.get(element, "parent.uniqueId") !== Ember.get(model, "parent.uniqueId")) {
              _addParent = Ember.get(element, "parent.title") + " > ";
              if (Ember.get(element, "parent.parent.budgetType") === "group-model-parent") {
                _addParent = Ember.get(element, "parent.parent.title") + " > " + _addParent;
              }
            }
            return _addParent + Ember.get(element, "title");
          }
        }
        if (throwException) {
          throw FormulaUtilHelper.setFormulaError(options);
        }
        return options.originalData && options.originalData.references && options.originalData.references[identifier] ? options.originalData.references[identifier] : FormulaUtilHelper.isLegalIdentifier(identifier) ? "Missing reference" : identifier;
      } else {
        return identifier;
      }
    },
    convertFormulaToStringWithoutCheck: function convertFormulaToStringWithoutCheck(formulaStr) {
      if (formulaStr === "=" || !formulaStr) {
        return "";
      }
      try {
        var formula = JSON.parse(formulaStr.substring(1));
        return FormulaUtilHelper.JSONToString(formula, {
          ignoreStore: true,
          withoutFormulaSymbol: true
        });
      } catch (e) {
        return formulaStr;
      }
    },
    isFormulaString: function isFormulaString(str) {
      return str && typeof str === "string" && str.substr(0, 2) === "={";
    },
    isFormula: function isFormula(str) {
      return str && typeof str === "string" && str.substr(0, 1) === "=";
    },
    isLiteral: function isLiteral(node) {
      // for numbers
      return node.type === "Literal";
    },
    isBinaryExpression: function isBinaryExpression(node) {
      // for expressions
      return node.type === "BinaryExpression";
    },
    isCallExpression: function isCallExpression(node) {
      // for functions
      return node.type === "CallExpression";
    },
    isIdentifier: function isIdentifier(node) {
      // for assumption / model line
      return node.type === "Identifier";
    },
    isUnaryExpression: function isUnaryExpression(node) {
      // for negative numbers
      return node.type === "UnaryExpression";
    },
    convertLiteralToLine: function convertLiteralToLine(startDate, endDate, literal) {
      var result = {},
        budgetEnd = new Date(Date.parse(endDate)).beginningOfMonth();
      for (var thisMonth = new Date(Date.parse(startDate)).beginningOfMonth(); !thisMonth.isAfter(budgetEnd); thisMonth.addMonths(1)) {
        result[_const.default.formatDate(thisMonth)] = (literal || 0) * 1;
      }
      return result;
    },
    lineLiteralMultiply: function lineLiteralMultiply(line, literal) {
      var result = {};
      for (var key in line) {
        result[key] = Ember.isEmpty(line[key]) ? line[key] : line[key] * literal;
      }
      return result;
    },
    lineIterator: function lineIterator(line, callback) {
      var result = {};
      for (var key in line) {
        result[key] = Ember.isEmpty(line[key]) ? line[key] : callback(line[key], key);
      }
      return result;
    },
    lineBinaryIterator: function lineBinaryIterator(line1, line2, callback) {
      var result = {},
        keys = Object.keys(line1);
      keys.addObjects(Object.keys(line2));
      keys.forEach(function (key) {
        return result[key] = Ember.isEmpty(line1[key]) && Ember.isEmpty(line2[key]) ? null : callback(line1[key], line2[key]);
      });
      return result;
    },
    convertLineCurrency: function convertLineCurrency(budgetLine, items, currency, toCurrency, scenario, cache) {
      var convertedResult = {};
      for (var key in items) {
        convertedResult[key] = _const.default.convertCurrency(budgetLine, items[key], currency, toCurrency, key, false, scenario, cache);
      }
      return convertedResult;
    }
  };
  var api = {
    parseToFormula: FormulaUtilHelper.parseToFormula,
    JSONToString: FormulaUtilHelper.JSONToString,
    parseFormulaStringToObject: FormulaUtilHelper.parseFormulaStringToObject,
    isFormulaString: FormulaUtilHelper.isFormulaString,
    isFormula: FormulaUtilHelper.isFormula,
    isLiteral: FormulaUtilHelper.isLiteral,
    isBinaryExpression: FormulaUtilHelper.isBinaryExpression,
    isCallExpression: FormulaUtilHelper.isCallExpression,
    isIdentifier: FormulaUtilHelper.isIdentifier,
    isUnaryExpression: FormulaUtilHelper.isUnaryExpression,
    isAssumptionIdentifier: FormulaUtilHelper.isAssumptionIdentifier,
    isModelIdentifier: FormulaUtilHelper.isModelIdentifier,
    shouldAddBrackets: FormulaUtilHelper.shouldAddBrackets,
    getObjectForIdentifier: FormulaUtilHelper.getObjectForIdentifier,
    setFormulaError: FormulaUtilHelper.setFormulaError,
    convertFormulaToStringWithoutCheck: FormulaUtilHelper.convertFormulaToStringWithoutCheck,
    convertLiteralToLine: FormulaUtilHelper.convertLiteralToLine,
    lineLiteralMultiply: FormulaUtilHelper.lineLiteralMultiply,
    lineIterator: FormulaUtilHelper.lineIterator,
    lineBinaryIterator: FormulaUtilHelper.lineBinaryIterator,
    convertLineCurrency: FormulaUtilHelper.convertLineCurrency
  };
  var _default = _exports.default = api;
});