define("budgeta/common-react-components/custom-hooks/use-event-listener", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function useEventListener(_ref) {
    var eventName = _ref.eventName,
      handler = _ref.handler,
      element = _ref.element;
    // Create a ref that stores handler
    var savedHandler = _react.default.useRef();
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    _react.default.useEffect(function () {
      savedHandler.current = handler;
    }, [handler]);
    _react.default.useEffect(function () {
      // Create event listener that calls handler function stored in ref
      var eventListener = function eventListener(event) {
        return savedHandler.current(event);
      };
      // Add event listener
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return function () {
        element.removeEventListener(eventName, eventListener);
      };
    }, [eventName, element] // Re-run if eventName or element changes
    );
  }
  var _default = _exports.default = {
    useEventListener: useEventListener
  };
});