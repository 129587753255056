define("budgeta/components/location-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["wizard-question scrollable"],
    filteredData: Ember.computed("data", "data.@each.deleted", "data.@each.parentid", function () {
      var result = [];
      if (this.get("data.length")) {
        result = this.get("data").filter(function (l) {
          return !l.parentid;
        });
        if (result.length === 1) {
          var parentid = result[0].locationid;
          result = this.get("data").filterBy("parentid", parentid);
          if (!result.length) {
            result = this.get("data").filter(function (l) {
              return !l.parentid;
            });
          }
        }
      }
      return result;
    }),
    actions: {
      deleteDataItem: function deleteDataItem(arg) {
        Ember.set(arg, "deleted", true);
      }
    }
  });
});