define("budgeta/generic-file/components/table-column-mapping-step/table-column-mapping-step", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectWithoutProperties", "react", "@sibp/ui-components", "react-redux", "budgeta/utils/useEmberBridge", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "budgeta/services/ember-service-container", "budgeta/admin/store/actions/notifActions", "lodash/lodash"], function (_exports, _slicedToArray2, _objectWithoutProperties2, _react, _uiComponents, _reactRedux, _useEmberBridge, _stepsActions, _budgetActions, _emberServiceContainer, _notifActions, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = TableColumnMappingStep;
  var _excluded = ["startTableAction", "getDataAction", "endTableAction"];
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function TableColumnMappingStep(_ref) {
    var startTableAction = _ref.startTableAction,
      getDataAction = _ref.getDataAction,
      endTableAction = _ref.endTableAction,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
    var dispatch = _reactRedux.default.useDispatch();
    var stepName = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var budgetStartDate = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.startDate;
    });
    var budgetEndDate = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.endDate;
    });
    var columnMapping = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.columnMapping;
    });
    var selectedDimensions = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.selectedDimensions;
    }) || [];
    var nameColumnIsAccount = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.nameColumnIsAccount;
    });
    var nameColumn = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.nameColumn;
    });
    var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      nextButtonEnabled = _React$useState2[0],
      setNextButtonEnabled = _React$useState2[1];
    var _React$useState3 = _react.default.useState([]),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      mendatoryFields = _React$useState4[0],
      setMendatoryFields = _React$useState4[1];
    (0, _useEmberBridge.default)("currentTypeMappings.@each.hide", function () {
      var data = getDataAction(stepName);
      setNextButtonEnabled(data && data.columnMapping ? data.columnMapping.length : false);
    });
    (0, _useEmberBridge.default)("currentStep", function () {
      var stepData = getDataAction(stepName);
      endTableAction(stepName, {
        removeListeners: true,
        closeTable: true
      });
      if (!_lodash.default.isEqual(columnMapping, stepData.columnMapping)) {
        dispatch(_budgetActions.default.setColumnMapping(stepData.columnMapping));
        rest.clearDependencies();
      }
      dispatch(_stepsActions.default.nextStep());
    });
    _react.default.useEffect(function () {
      startTableAction(stepName, {
        currentStep: 1,
        selectedDimensions: selectedDimensions,
        budgetStartDate: budgetStartDate,
        budgetEndDate: budgetEndDate,
        clear: !columnMapping || !columnMapping.length
      });
      setMendatoryFields(nameColumnIsAccount ? ["accountGroup", "#date#"] : ["accountId", "accountGroup", "#date#"]);
      setNextButtonEnabled(columnMapping ? columnMapping.length : false);
    }, []);
    var handleDataValidation = function handleDataValidation(data) {
      if (data.columnMapping && mendatoryFields.any(function (field) {
        return !new Set(data.columnMapping.mapBy("selectedAttribute")).has(field);
      })) {
        return false;
      }
      return true;
    };
    var updateColumnsKeys = function updateColumnsKeys(selectedColumns) {
      var currencyColumn = selectedColumns.find(function (x) {
        return x.selectedAttribute === "currency";
      });
      var accountColumn = selectedColumns.find(function (x) {
        return x.selectedAttribute === "accountId";
      });
      var currencyColumnKey = currencyColumn && currencyColumn.key && currencyColumn.key.replace(/[0-9]/, "");
      var accountColumnKey = nameColumnIsAccount ? nameColumn.replace(/[0-9]/g, "") : accountColumn && accountColumn.key && accountColumn.key.replace(/[0-9]/, "");
      dispatch(_budgetActions.default.setColumnsKeys({
        currencyColumnKey: currencyColumnKey,
        accountColumnKey: accountColumnKey
      }));
    };
    var handleNextButton = function handleNextButton() {
      var stepData = getDataAction(stepName);
      if (handleDataValidation(stepData)) {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_COLUMN_MAPPING);
        updateColumnsKeys(stepData.columnMapping);
        dispatch(_notifActions.default.hideNoty());
        endTableAction(stepName);
      } else {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.MAP_DATA_NEXT_ERROR);
        var mappedids = stepData.columnMapping.filter(function (mapping) {
          return mendatoryFields.includes(mapping.selectedAttribute);
        }).map(function (mapping) {
          return mapping.selectedAttribute;
        });
        var missingIds = mendatoryFields.filter(function (mapping) {
          return !mappedids.includes(mapping);
        });
        var mappingNames = stepData.mappingNames.concat([{
          name: intl.t("general.name_1695611604274"),
          id: "#date#"
        }]).filter(function (mapping) {
          return missingIds.includes(mapping.id);
        }).mapBy("name").join(", ");
        dispatch(_notifActions.default.showNoty({
          notiContent: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("span", null, intl.t("generic-file.step-column-mapping.mendatory-fields-alert")), _react.default.createElement("span", null, mappingNames))
        }));
      }
    };
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        var stepData = getDataAction(stepName);
        if (!_lodash.default.isEqual(columnMapping, stepData.columnMapping)) {
          dispatch(_notifActions.default.hideNoty());
          dispatch(_budgetActions.default.setColumnMapping(stepData.columnMapping));
          rest.clearDependencies();
        }
        endTableAction(stepName, {
          removeListeners: true,
          closeTable: true,
          back: true
        });
        dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: handleNextButton,
      disabled: !nextButtonEnabled
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.Footer, footer);
  }
});