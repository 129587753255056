define("budgeta/components/intacct-emp-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    mappingContent: Ember.computed("hasDepartments", "data2", "data3", function () {
      return this.get("hasDepartments") ? this.get("data2") : this.get("data3").filter(function (d) {
        return !d.deleted && !d.parentid;
      });
    })
  });
});