define("budgeta/mixins/query-param-delegate-fix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: the following provides a temporary workaround to this issue:
  //   https://github.com/emberjs/ember.js/issues/11592
  // This mixin should be included by controllers which may have query-param-bound
  //   properties set prior to them being configured by a route.
  // This mixin should be removed once warnings are no longer logged by
  //   `_qpDelegate`, meaning it is no longer necessary.
  var _default = _exports.default = Ember.Mixin.create({
    _qpDelegate: function _qpDelegate(prop, value) {
      console.warn("WARNING: Query-param-bound property '".concat(prop, "' changed to value '").concat(value, "' prior to controller being setup by route."));
    }
  });
});