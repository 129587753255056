define("budgeta/admin/company-admin-app/pages/auditLoggingList/audit-logging-messages", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@sibp/ui-components", "budgeta/admin/company-admin-app/routes", "react", "react-router-dom", "budgeta/services/audit-logging/audit-logging-utils", "budgeta/admin/utils/const"], function (_exports, _defineProperty2, _uiComponents, _routes, _react, _reactRouterDom, _auditLoggingUtils, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MESSAGES = function MESSAGES() {
    var history = _reactRouterDom.default.useHistory();
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, _const.USER_ADMIN_ACTION.DELETE_COMPANY_USER, function (logObject) {
      var user = logObject.actionDetails[0].entities[0];
      return _react.default.createElement("div", {
        "data-hook": user.email
      }, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement("div", null, "".concat(user.firstName, " ").concat(user.lastName)));
    }), _const.USER_ADMIN_ACTION.ADD_COMPANY_USER, function (logObject) {
      var user = logObject.actionDetails[0].entities[0];
      return _react.default.createElement("div", {
        "data-hook": user.email
      }, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
        text: "".concat(user.firstName, " ").concat(user.lastName),
        onClick: function onClick() {
          history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.USER_EDIT, {
            userId: user.id
          }));
        }
      }));
    }), _const.USER_ADMIN_ACTION.EDIT_COMPANY_USER, function (logObject) {
      var userObj = logObject.actionDetails[0].entities[0];
      var details = logObject.actionDetails[0].details;
      var logTextDiffComponents = _auditLoggingUtils.default.getLogTextDiffComponents(details);
      return _react.default.createElement("div", {
        "data-hook": userObj.email
      }, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
        text: "".concat(userObj.firstName, " ").concat(userObj.lastName),
        onClick: function onClick() {
          history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.USER_EDIT, {
            userId: userObj.id
          }));
        }
      }), logTextDiffComponents);
    });
  };
  var _default = _exports.default = MESSAGES;
});