define("budgeta/components/inline-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tooltips: Ember.inject.service(),
    classNames: ["inline-tip"],
    tipId: null,
    tooltip: Ember.computed("tipId", function () {
      return this.get("tooltips").findById(this.get("tipId"));
    })
  });
});