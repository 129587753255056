define("budgeta/components/is-admin-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "span",
    userId: null,
    adminUsers: null,
    isAdmin: Ember.computed("userId", "adminUsers.length", function () {
      return this.get("adminUsers").includes(this.get("userId"));
    }),
    actions: {
      makeAdmin: function makeAdmin(userId) {
        this.sendAction("makeAdmin", userId);
      }
    }
  });
});