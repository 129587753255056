define("budgeta/components/integration-wizard-select-time-periods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    })
  });
});