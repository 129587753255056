define("budgeta/admin/admin-app/pages/companyList/const", ["exports", "react-router-dom", "@sibp/ui-components", "budgeta/admin/admin-app/routes", "budgeta/admin/utils/const", "budgeta/admin/utils/date", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _reactRouterDom, _uiComponents, _routes, _const, _date, _emberServiceContainer, _const2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.columns = void 0;
  /* eslint-disable react-hooks/rules-of-hooks, react/react-in-jsx-scope */

  var isPassedDays = function isPassedDays(date, numOfDays) {
    if (!date) {
      return;
    }
    var diffDays = (0, _date.calcDaysAbsoluteDiff)(new Date(), new Date(date));
    return diffDays > numOfDays;
  };
  var columns = _exports.columns = function columns() {
    var history = _reactRouterDom.default.useHistory();
    var companyOrigins = new Map(Object.values(_const.COMPANY_ORIGIN).map(function (origin) {
      return [origin.value, origin.text];
    }));
    var intl = new _emberServiceContainer.default().lookup("intl");
    return [{
      header: intl.t("admin_app.tables.company_name"),
      accessor: "name",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref) {
        var value = _ref.value,
          company = _ref.otherProps;
        return React.createElement(_uiComponents.default.COMPONENTS.Link, {
          text: value,
          onClick: function onClick() {
            history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_EDIT_DETAILS, {
              companyId: company._id
            }));
          }
        });
      },
      sortDir: 1
    }, {
      header: intl.t("admin_app.form.status"),
      accessor: "status",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref2) {
        var value = _ref2.value,
          otherProps = _ref2.otherProps;
        return React.createElement("div", {
          className: "status-cell",
          title: intl.t("admin_app.warning.hover_text")
        }, React.createElement("span", null, value === "active" ? intl.t("admin_app.tables.active") : intl.t("admin_app.tables.inactive")), isPassedDays(otherProps.disabledDate, _const2.default.GRACE_PERIOD_BEFORE_DELETE_COMPANY) ? React.createElement(_uiComponents.default.IMAGES.WarningSvg, null) : null);
      }
    }, {
      header: intl.t("admin_app.form.instanceId"),
      accessor: "externalId",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING
    }, {
      header: intl.t("admin_app.tables.associated_company"),
      accessor: "origin",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref3) {
        var value = _ref3.value;
        return React.createElement("span", null, companyOrigins.get(value));
      }
    }, {
      header: intl.t("admin_app.tables.license_total"),
      accessor: "totalLicenseCount",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.NUMBER
    }, {
      header: intl.t("admin_app.tables.contact_email"),
      accessor: "contactEmail",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING
    }];
  };
});