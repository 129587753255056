define("budgeta/routes/admin-app-route", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var isUserLoggedIn = this.get("session.isAuthenticated");
      if (!isUserLoggedIn) {
        this.transitionTo("login");
        return;
      }
      var userRoles = this.get("session.data.currentUser.roles") || [];
      var isAdmin = userRoles.includes(_const.USER_ROLE.ADMIN) || userRoles.includes(_const.USER_ROLE.SUPER_ADMIN);
      var isActive = this.get("session.data.currentUser.adminConsole.status") === "active";
      if (!isAdmin || !isActive) {
        this.transitionTo("unauthorized");
        return;
      }

      //continue to route
      this.get("trackingEvents").bootPendoAdminApp();
    }
  });
});