define("budgeta/templates/rename-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MkMNNLUZ",
    "block": "{\"statements\":[[\"block\",[\"rename-chart\"],null,[[\"controllertmp\"],[[\"get\",[null]]]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"rename-chart.name\"],null],false],[\"close-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"input\"],null,[[\"value\",\"placeholder\"],[[\"get\",[\"newName\"]],[\"helper\",[\"t\"],[\"rename-chart.type-widget-name\"],null]]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/rename-chart.hbs"
    }
  });
});