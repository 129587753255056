define("budgeta/templates/components/select-2-exclusions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3IbfNadz",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"select-2\"],null,[[\"searchEnabled\",\"content\",\"value\",\"optionValuePath\",\"optionIdPath\",\"optionLabelPath\",\"placeholder\",\"attributeChanged\"],[[\"get\",[\"searchEnabled\"]],[\"get\",[\"content\"]],[\"get\",[\"value\"]],[\"get\",[\"optionValuePath\"]],[\"helper\",[\"if\"],[[\"get\",[\"optionIdPath\"]],[\"get\",[\"optionIdPath\"]],\"id\"],null],[\"helper\",[\"if\"],[[\"get\",[\"optionLabelPath\"]],[\"get\",[\"optionLabelPath\"]],\"text\"],null],[\"get\",[\"placeholder\"]],[\"get\",[\"attributeChanged\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/select-2-exclusions.hbs"
    }
  });
});