define("budgeta/initializers/cookie", ["exports", "budgeta/lib/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'cookie',
    initialize: function initialize() {
      var app = arguments[1] || arguments[0];
      app.register('cookie:main', _cookie.default);
    }
  };
});