define("budgeta/admin/utils/validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validator = {
    isEmailValid: function isEmailValid(email) {
      return !email || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]/.test(email);
    }
  };
  var _default = _exports.default = Validator;
});