define("budgeta/templates/main/budget/forecast-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jWbWpGmN",
    "block": "{\"statements\":[[\"block\",[\"main/budget/forecast-body\"],null,[[\"scrollHeight\",\"controllertmp\"],[[\"get\",[\"scrollHeight\"]],[\"get\",[null]]]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"partial\",\"main/budget/forecast-body-content\"],[\"text\",\"\\n\"]],\"locals\":[\"forecastDraggableScrollHeightStyle\",\"rowDimensions\",\"isForecastPreview\",\"scrollHeightStyle\"]}],\"hasPartials\":true}",
    "meta": {
      "moduleName": "budgeta/templates/main/budget/forecast-body.hbs"
    }
  });
});