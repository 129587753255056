define("budgeta/components/wizard-question-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["wizard-question-radio", "budgeta-checkbox"],
    tagName: "label",
    inputId: Ember.computed("elementId", function () {
      return this.get("elementId") + "_input";
    }),
    type: "radio",
    typeRadio: Ember.computed.equal("type", "radio"),
    matchByType: false,
    wizardOrBudgetType: Ember.computed.or("wizardType", "budgetType"),
    sendActionThrottled: function sendActionThrottled() {
      if (!Ember.isEmpty(this.get("firstTime")) && this.get("firstTime") !== false) {
        this.set("firstTime.".concat(this.get("wizardOrBudgetType")), false);
      }
      if (this.get("action") === "add") {
        this.$().closest(".wizard-question").find(".wizard-question-after").show();
      } else {
        this.$().closest(".wizard-question").find(".wizard-question-after").hide();
      }
      this.notifyPropertyChange("wizardOrBudgetType");
      this.sendAction("action", this.get("budgetType"), this.get("name"), this.get("once"), this.get("allTypes"), undefined, undefined, this.get("wizardType"));
    },
    click: function click(event) {
      event.preventDefault();
      Ember.run.throttle(this, "sendActionThrottled", 100);
    },
    checked: Ember.computed("wizardOrBudgetType", "parent.children.length", "reverse", function () {
      if (this.get("firstTime") !== false && !this.get("firstTime").hasOwnProperty(this.get("wizardType") || this.get("budgetType"))) {
        return false;
      }
      if (this.get("matchParent")) {
        var _result = this.get("wizardOrBudgetType") === this.get("parent.wizardOrBudgetType");
        return _result;
      }
      var line = this.get("parent.children") && (this.get("matchByType") ? this.get("parent.children").findBy("wizardOrBudgetType", this.get("wizardOrBudgetType")) : this.get("parent.children").findBy("name", this.get("name")));
      var result = this.get("reverse") ? !line : line;
      return result ? true : false;
    })
  });
});