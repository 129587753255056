define("budgeta/templates/components/integration-wizard-export-dimensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0Ugmpkmq",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"isLoadingSettings\"]]],null,4,3]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"            \"],[\"append\",[\"helper\",[\"help-iq\"],null,[[\"tooltipText\",\"classNames\"],[[\"get\",[\"dim\",\"tooltipText\"]],\"inline-icon\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"append\",[\"unknown\",[\"dim\",\"dimension\",\"name\"]],false],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"dim\",\"tooltipText\"]]],null,0]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n        \"],[\"block\",[\"budgeta-checkbox\"],null,[[\"checked\",\"disabled\"],[[\"get\",[\"dim\",\"selected\"]],[\"get\",[\"dim\",\"disabled\"]]]],1],[\"text\",\"      \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"dim\"]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"scrollable short-list\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"dimensions\"]]],null,2],[\"text\",\"  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"unknown\",[\"budgeta-spinner\"]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/integration-wizard-export-dimensions.hbs"
    }
  });
});