define("budgeta/components/dimension-value-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    classNames: ["trx-row-content"],
    attributeBindings: ["hook:data-hook"],
    hook: "dimension-value-row",
    isWide: false,
    enabled: true,
    resizeList: false,
    change: function change() {
      var _this = this;
      if (this.get("forImport")) {
        return;
      }
      this.set("blockRecalc", true);
      this.sendAction("submit");
      Ember.run.next(function () {
        _this.set("blockRecalc", false);
        if (_this.get("dimValueMappingChanged")) {
          _this.sendAction("dimValueMappingChanged");
        }
      });
    },
    actions: {
      lineToggle: function lineToggle() {
        this.sendAction("lineToggle", this.get("row"));
      },
      dimensionChanged: function dimensionChanged() {
        this.set("row.dimensionValueIds", []);
      },
      dimensionValuesChanged: function dimensionValuesChanged(values) {
        if (this.get("singleSelection")) {
          this.set("row.dimensionValueIds", [values.id]);
        } else {
          this.set("row.dimensionValueIds", values.mapBy("id"));
          if (this.get("itemSizesDimensions")) {
            this.sendAction("updateItemSizesDimensions", this.get("index"), values.length);
          }
        }
        this.change();
      },
      duplicateDimensionValue: function duplicateDimensionValue() {
        this.sendAction("duplicateDimensionValue", this.get("row"));
      },
      deleteDimensionValue: function deleteDimensionValue() {
        this.sendAction("deleteDimensionValue", this.get("row"));
      }
    }
  });
});