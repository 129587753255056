define("budgeta/components/path-list-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "li",
    classNames: ["path-list-element"],
    attributeBindings: ["title"],
    budgetService: Ember.inject.service(),
    showAccountId: Ember.computed("budgetService.accountsInInput", "disableAccountId", function () {
      if (this.get("disableAccountId") || !this.get("budget")) {
        return false;
      }
      if (this.get("budgetService.accountsInInput")) {
        return true;
      }
      return false;
    }),
    title: Ember.computed("budget", "disableAccountId", "customTitle", "pathName", function () {
      if (this.get("customTitle")) {
        return this.get("customTitle");
      }
      if (this.get("disableAccountId")) {
        return this.get("budget.fullPathWithoutAccounts") || this.get("pathName");
      }
      return this.get("budget.budgetFullPath");
    })
  });
});