define("budgeta/components/inline-edit", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    classNameBindings: [":inline-edit", "editing:editing", "readonly:readonly", "value::empty-value", "withShowValue:with-show-value"],
    attributeBindings: ["title", "data-hook"],
    "data-hook": "inline-edit",
    title: Ember.computed.alias("value"),
    editing: false,
    readonly: undefined,
    enterPressed: false,
    init: function init() {
      this._super();
      if (Ember.isEmpty(this.get("value"))) {
        this.set("editing", true);
      }
      if (!Ember.isEmpty(this.get("initPrecision"))) {
        this.set("value", _const.default.toFixed(this.get("value"), this.get("initPrecision")));
      }
      this.set("initialValue", this.get("value"));
    },
    focusOut: function focusOut() {
      this.set("changed", true);
    },
    keyDown: function keyDown(event) {
      if (event.which === 13 || event.keyCode === 13) {
        if (!this.get("preventEnter")) {
          this.set("enterPressed", true);
          this.$("input").focusout();
        }
        return false;
      }
    },
    click: function click(event) {
      event.stopPropagation();
      if (this.get("readonly")) {
        return false;
      }
      this.set("editing", true);
      this.set("initialValue", this.get("value"));
      Ember.run.scheduleOnce("afterRender", this, function () {
        this.$("input").focus();
        if (this.get("focus-in")) {
          this.sendAction("focus-in");
        }
      });
      return false;
    },
    willDestroyElement: function willDestroyElement() {
      if (this.$()) {
        this.$().off("focusout", "input");
      }
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _this = this;
      this.$().on("focusout", "input", function (e) {
        if (_this.get("editing")) {
          if (_this.get("preventDuplicates") && _this.get("value") !== _this.get("initialValue") && _this.get("preventDuplicates").indexOf(_this.get("value")) >= 0) {
            noty({
              type: "warning",
              text: intl.t("general.inline-edit-1", {
                var1: _this.get("value")
              })
            });
            e.preventDefault();
            return false;
          } else {
            _this.set("editing", false);
            if (_this.get("done") && (!_this.get("model.isNew") || _this.get("enterPressed"))) {
              _this.sendAction("done", _this.get("model"), _this.get("initialValue"), _this.get("value"), _this.get("noSave"));
            }
          }
        }
        _this.set("enterPressed", false);
      });
      if (this.get("autoFocus")) {
        Ember.run.scheduleOnce("afterRender", this, function () {
          this.$().click();
        });
      }
    }
  });
});