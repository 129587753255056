define("budgeta/styles/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "login-form-logo": "_login-form-logo_ehxo3h"
  };
});