define("budgeta/validators/local/date-before", ["exports", "ember-validations/validators/base", "budgeta/utils/const"], function (_exports, _base, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    init: function init() {
      this._super();
      var _this = this;
      this.options.otherDates.forEach(function (key) {
        if (key.indexOf("||") >= 0) {
          key.split("||").forEach(function (k) {
            _this.dependentValidationKeys.pushObject(k);
          });
        } else {
          _this.dependentValidationKeys.pushObject(key);
        }
      });
    },
    call: function call() {
      if (!Ember.isBlank(this.model.get(this.property))) {
        for (var i = 0; i < this.options.otherDates.length; i++) {
          var otherDate = this.options.otherDates[i].indexOf("||") >= 0 ? this.model.get(this.options.otherDates[i].split("||")[0]) || this.model.get(this.options.otherDates[i].split("||")[1]) : this.model.get(this.options.otherDates[i]);
          if (!Ember.isBlank(otherDate) && moment(this.model.get(this.property)).startOf("month").isAfter(moment(otherDate).startOf("month"))) {
            var otherDateName = this.options.otherDates[i].indexOf("||") >= 0 ? this.options.otherDates[i].split("||")[0] : this.options.otherDates[i];
            this.errors.pushObject(this.options.message || _const.default.camelize(this.property.split(".").slice(-1).pop()) + " must be before " + _const.default.camelize(otherDateName.split(".").slice(-1).pop()));
            break;
          }
        }
      }
    }
  });
});