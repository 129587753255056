define("budgeta/templates/components/svg/help-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pXHYKskv",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"20\"],[\"static-attr\",\"height\",\"20\"],[\"static-attr\",\"viewBox\",\"0 0 20 20\"],[\"static-attr\",\"fill\",\"none\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM10 16C9.4475 16 9 15.5525 9 15C9 14.4475 9.4475 14 10 14C10.5525 14 11 14.4475 11 15C11 15.5525 10.5525 16 10 16ZM11.495 10.4885C10.999 10.875 10.75 11.069 10.75 11.75C10.75 12.1645 10.4145 12.5 10 12.5C9.5855 12.5 9.25 12.1645 9.25 11.75C9.25 10.336 10.0145 9.7405 10.573 9.3055C11.111 8.886 11.5 8.583 11.5 7.5C11.5 6.673 10.827 6 10 6C9.173 6 8.5 6.673 8.5 7.5V7.75C8.5 8.1645 8.1645 8.5 7.75 8.5C7.3355 8.5 7 8.1645 7 7.75V7.5C7 5.8455 8.3455 4.5 10 4.5C11.6545 4.5 13 5.8455 13 7.5C13 9.3155 12.13 9.9935 11.495 10.4885Z\"],[\"static-attr\",\"fill\",\"black\"],[\"static-attr\",\"fill-opacity\",\"0.65\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/help-svg.hbs"
    }
  });
});