define("budgeta/components/power-action-select", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNameBindings: ["bindingError:not-allowed", "markDirty:ui-selected-dirty"],
    attributeBindings: ["hook:data-hook"],
    classNames: ["action-select-container"],
    hook: "power-select-action",
    actionElementId: "action-element-id",
    defaultId: "defaultId",
    actionEnabled: true,
    toggleDisabled: false,
    markSelected: false,
    dropdownClassComputed: Ember.computed("markSelected", "dropdownClass", function () {
      var markSelected = this.get("markSelected") ? "mark-selected" : "";
      var defaultEnabled = this.get("defaultValue") ? "power-select-default" : "";
      return Ember.String.htmlSafe("auto-width no-wrap no-padding power-action-select-dropdown ".concat(this.get("dropdownClass"), " ").concat(markSelected, " ").concat(defaultEnabled));
    }),
    selectedComputed: Ember.computed("selected", {
      get: function get() {
        if (this.get("selected")) {
          return this.get("options") ? this.get("options").findBy(this.get("optionValuePath"), this.get("selected")) : [];
        }
        return this.get("options") ? this.get("options").findBy(this.get("optionValuePath"), this.get("defaultValue")) : [];
      },
      set: function set(key, value) {
        if (value.id === this.get("defaultId")) {
          this.set("selected", this.get("defaultValue"));
          return this.get("options").findBy(this.get("optionValuePath"), this.get("defaultValue"));
        } else {
          if (this.get("bulkEnabled") && this.get("enableActionOption")) {
            this.send("actionClick", this.get("sender"), value["".concat(this.get("optionValuePath"))]);
          } else {
            this.set("selected", value["".concat(this.get("optionValuePath"))]);
          }
        }
        return this.get("options").findBy(this.get("optionValuePath"), this.get("selected"));
      }
    }),
    options: Ember.computed("content", "toggleDisabled", "forceDisabled", "bulkEnabled", "enableActionOption", function () {
      var defaultOption = [];
      defaultOption = defaultOption.concat(_const.default.deepClone(this.get("content")));
      if (this.get("toggleDisabled")) {
        defaultOption.forEach(function (option) {
          if ("disabled" in option) {
            option.disabled = true;
          }
        });
      }
      if (this.get("defaultValue")) {
        defaultOption.push({
          text: this.get("intl").t("power-select.reset"),
          id: this.get("defaultId"),
          title: this.get("intl").t("power-select.reset"),
          disabled: !this.get("resetEnabled")
        });
      }
      if (this.get("forceDisabled")) {
        defaultOption.forEach(function (option) {
          if ("disabled" in option) {
            option.disabled = true;
          }
        });
      }
      return defaultOption;
    }),
    click: function click(event) {
      if (this.get("stopPropogation")) {
        event.stopPropagation();
      }
    },
    actions: {
      powerSelectChange: function powerSelectChange(val) {
        if (this.get("powerSelectChange")) {
          this.sendAction(this.get("powerSelectChange"), val["".concat(this.get("optionValuePath"))]);
        } else {
          this.set("selectedComputed", val);
        }
        if (this.get("onChange")) {
          this.get("onChange")(this.get("sender"), val);
        }
      },
      actionClick: function actionClick(sender, id) {
        if (this.get("bulkEnabled") && this.get("enableActionOption")) {
          this.get("actionClick")(sender, id);
        }
      },
      onClose: function onClose(val, event) {
        if (event.srcElement.parentElement && event.srcElement.parentElement.id === this.get("actionElementId")) {
          return false;
        }
      }
    }
  });
});