define("budgeta/templates/components/is-admin-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ebvk9MKp",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"isAdmin\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"a\",[]],[\"static-attr\",\"class\",\"is-admin-label\"],[\"modifier\",[\"action\"],[[\"get\",[null]],\"makeAdmin\",[\"get\",[\"userId\"]]]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"is-admin-helper.make-admin\"],null],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"is-admin-label\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"is-admin-helper.admin\"],null],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/is-admin-helper.hbs"
    }
  });
});