define("budgeta/components/account-row", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/accounts"], function (_exports, _emberServiceContainer, _accounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-function-prototype-extensions, ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    intl: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    classNameBindings: [":trx-row-content", ":account-row", "showAccountRowError:account-error"],
    attributeBindings: ["data-account-row-type"],
    "data-account-row-type": Ember.computed("account.type", function () {
      return "account-row-type-".concat(this.get("account.type"));
    }),
    notReadOnly: Ember.computed.not("readOnly"),
    showAccountsImportWizard: Ember.computed("account.isSingle", "forImport", function () {
      return this.get("forImport") && this.get("account.isSingle");
    }),
    typeEnabled: Ember.computed("readOnly", "forImport", function () {
      return !this.get("forImport") && !this.get("readOnly");
    }),
    isReadOnly: Ember.computed("readOnly", function () {
      return this.get("readOnly") ? true : undefined;
    }),
    showAccountRowError: Ember.computed("account.isDuplicate", "account.accountError", "showErrors", function () {
      return this.get("showErrors") && (this.get("account.accountError") || this.get("account.isDuplicate"));
    }),
    setInitialAccountLines: function () {
      this.set("account.initialBudgetLines", []);
      if (this.get("account.budgetLines")) {
        this.get("account.initialBudgetLines").pushObjects(this.get("account.budgetLines"));
      }
    }.on("init"),
    hideClone: Ember.computed.or("forImport", "account.isStatisticalType"),
    change: function change() {
      if (this.get("forImport")) {
        return;
      }
      Ember.run.throttle(this, "changeAccount", 200);
    },
    changeAccount: function changeAccount() {
      var _this = this;
      var account = this.get("account");
      var accountIdChanged = account.get("initAccount") && account.get("account") !== account.get("initAccount");
      var prevAccountId = accountIdChanged && account.get("initAccount");
      var budgetLines = this.get("budgetLines");
      var bsLines = this.get("bsLines");
      var dimensionOptions = this.get("dimensionOptions");
      var pnlFullName = this.get("budgetService.pnlFullName");
      // fix #.000
      account.set("account", _accounts.default.fixAccountId(account.get("account")));
      if (account.get("initAccount") && account.get("initAccount") !== account.get("account")) {
        var _copyAccounts = this.get("actualAccounts").filterBy("copyFromAccount", account.get("initAccount"));
        _copyAccounts.setEach("copyFromAccount", account.get("account"));
      }
      account.set("initAccount", account.get("account"));
      var copyAccounts = this.get("actualAccounts").filterBy("copyFromAccount", account.get("account"));
      copyAccounts.setEach("type", account.get("type"));
      this.set("recalcAccount", !this.get("recalcAccount"));
      var checkFilter = function checkFilter(filterName, newValue) {
        if (filterName) {
          var filter = _this.get("filters.".concat(filterName, ".filters"));
          var hasFilter;
          if (filterName === "types") {
            hasFilter = filter.findBy("statementId", newValue);
          } else if (filterName === "mappingTypes") {
            hasFilter = filter.findBy("id", newValue);
          } else if (filterName === "budgetLines") {
            hasFilter = filter.findBy("budgetId", newValue);
          } else if (filterName === "dimensions") {
            hasFilter = filter.findBy("dimensionId", newValue);
          } else {
            hasFilter = filter.findBy("name", newValue);
          }
          if (!hasFilter) {
            if (filterName === "budgetLines") {
              var budgetLine = budgetLines.findBy("id", newValue) || bsLines.findBy("id", newValue);
              filter.addObject({
                selected: false,
                name: budgetLine ? Ember.get(budgetLine, "name") : "",
                title: budgetLine ? Ember.get(budgetLine, "name") : "",
                budgetId: newValue
              });
            } else if (filterName === "dimensions") {
              var dimension = dimensionOptions.findBy("id", newValue);
              filter.addObject({
                selected: false,
                name: dimension ? Ember.get(dimension, "text") : "",
                title: dimension ? Ember.get(dimension, "text") : "",
                dimensionId: newValue
              });
            } else if (filterName === "types") {
              var statement = newValue === "pnl" ? pnlFullName : intl.t("general.name_1695611604193");
              filter.addObject({
                selected: false,
                name: statement,
                statementId: newValue
              });
            } else if (filterName === "mappingTypes") {
              var mappingType = newValue === "single" ? _this.get("intl").t("account-mapping-menu.single-".concat(account.isStatisticalType ? "model" : "budget", "-line")) : newValue === "multi" ? _this.get("intl").t("account-mapping-menu.multiple-".concat(account.isStatisticalType ? "model" : "budget", "-lines")) : newValue === "clone" ? _this.get("intl").t("account-mapping-menu.same-as-account") : _this.get("intl").t("account-mapping-menu.no-mapping");
              filter.addObject({
                selected: false,
                name: mappingType,
                id: newValue
              });
            } else {
              filter.addObject({
                selected: false,
                name: newValue
              });
            }
            filter.sortBy("name");
          }
        }
      };
      checkFilter("descriptions", this.get("account.description"));
      checkFilter("accounts", this.get("account.account"));
      checkFilter("types", this.get("account.type"));
      checkFilter("mappingTypes", this.get("account.mappingType"));
      var accountBudgetLines = this.get("account.budgetLines");
      if (!accountBudgetLines.length) {
        accountBudgetLines = [""];
      }
      if (accountBudgetLines && Array.isArray(accountBudgetLines)) {
        accountBudgetLines.forEach(function (budgetLineId) {
          checkFilter("budgetLines", budgetLineId);
        });
      }
      var accountDimensions = this.get("account.dimensions");
      if (!accountDimensions.length) {
        accountDimensions = [""];
      }
      if (accountDimensions && Array.isArray(accountDimensions)) {
        accountDimensions.forEach(function (dimensionId) {
          checkFilter("dimensions", dimensionId);
        });
      }
      var trxs = [];
      // change for linked transactions
      this.get("store").peekAll("transaction").forEach(function (trx) {
        //update transaction with new account id
        if (accountIdChanged && trx.get("accountId") === prevAccountId) {
          trx.set("accountId", account.get("account"));
          trx.save();
        } else if (account.get("budgetLines").includes(trx.get("computedBudgetLine.uniqueId")) || (account.get("initialBudgetLines") || []).includes(trx.get("computedBudgetLine.uniqueId"))) {
          trxs.push(trx);
        } else if (trx.get("accountId") === account.get("account")) {
          trxs.push(trx);
        }
      });
      this.get("changedTransactionsMapping").addObjects(trxs);
      this.setInitialAccountLines();
      this.sendAction("submit");
    },
    isMaxHeightBudgetLines: Ember.computed("account.budgetLines.length", "account.cloneBudgetLines.length", function () {
      return (Math.max(this.get("account.budgetLines.length"), this.get("account.cloneBudgetLines.length")) || 0) > 7;
    }),
    isMaxHeightDimensionsLines: Ember.computed("account.dimensions", "account.cloneDimensions.length", function () {
      return (Math.max(this.get("account.dimensions.length"), this.get("account.cloneDimensions.length")) || 0) > 7;
    }),
    multiSelectOnOpenAction: Ember.computed("account.isStatisticalType", function () {
      return this.get("account.isStatisticalType") ? "multiSelectOnOpenAction" : "";
    }),
    actions: {
      lineToggle: function lineToggle() {
        this.sendAction("lineToggle");
      },
      duplicateAccount: function duplicateAccount() {
        this.sendAction("duplicateAccount", this.get("account"));
      },
      deleteAccount: function deleteAccount() {
        this.sendAction("deleteAccount", this.get("account"));
      },
      updateModelLinesPeriodValueAction: function updateModelLinesPeriodValueAction(modelLine) {
        var _this2 = this;
        if (this.get("account.isStatisticalType")) {
          var modelLines = modelLine && [modelLine] || this.get("account.budgetLines").map(function (id) {
            return _this2.get("budgetLines").findBy("id", id);
          });
          _accounts.default.updateModelLinesPeriodValue(modelLines, this.get("account.reportingType"));
        }
      },
      multiSelectOnOpenAction: function multiSelectOnOpenAction(dropdownModel) {
        _accounts.default.calculateModelLinesWithExcludedContent(dropdownModel, this.get("account"), this.get("budgetService"));
      },
      reportingTypePendoTracking: function reportingTypePendoTracking() {
        if (this.get("account.reportingType") !== "period") {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.STATISTICAL_DATA.STATISTICAL_ACCOUNT_MAPPING_TABLE_PERIOD"), {});
        } else {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.STATISTICAL_DATA.STATISTICAL_ACCOUNT_MAPPING_TABLE_CUMULATIVE"), {});
        }
      }
    }
  });
});