define("budgeta/components/fix-assumption-live-edit", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/assumption", "budgeta/utils/const", "budgeta/utils/formula", "budgeta/utils/formula-helper"], function (_exports, _emberServiceContainer, _assumption, _const, _formula, _formulaHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/require-super-in-init, ember/no-observers, ember/closure-actions */

  var runLater;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":fix-assumption-live-edit", ":full", ":panel", ":panel-default", "collapsed:collapsed"],
    attributeBindings: ["interactiveStatus"],
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    interactiveSize: Ember.computed.alias("viewSelections.interactiveSize"),
    collapsed: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.assumptionsChanged();
    },
    getInteractiveSize: function getInteractiveSize() {
      var size = this.get("collapsed") ? 0 : this.get("fixAssumptions.length") || 0;
      return size;
    },
    interactiveStatusObserver: Ember.observer("interactiveStatus", function () {
      var _this2 = this;
      Ember.run.later(function () {
        if (_this2.get("isDestroying") || _this2.get("isDestroyed")) {
          return;
        }
        var size = _this2.getInteractiveSize();
        _this2.set("interactiveSize", size);
      });
    }),
    interactiveStatus: Ember.computed("fixAssumptions.length", "collapsed", function () {
      var size = this.getInteractiveSize();
      return "interactive-status-".concat(size);
    }),
    assumptionsWithParents: Ember.computed("selectedBudget", "selectedDashboard.uniqueId", function () {
      return _assumption.default.getFixedAssumptions(this.get("selectedRoot"), "#".concat(this.get("selectedDashboard.uniqueId"), "#"), null, {
        mergeWithBase: true,
        withParent: true,
        withOriginalValue: true,
        addScenarioDataIfMissing: true,
        store: this.get("store")
      });
    }),
    assumptions: Ember.computed("selectedBudget", "selectedDashboard.uniqueId", function () {
      return _assumption.default.getFixedAssumptions(this.get("selectedRoot"), "#".concat(this.get("selectedDashboard.uniqueId"), "#"), null, {
        mergeWithBase: true,
        withOriginalValue: true,
        addScenarioDataIfMissing: true,
        store: this.get("store")
      });
    }),
    hasAssumptionsChanges: false,
    fixAssumptions: Ember.computed("assumptions", "assumptions.length", "selectedDashboard.fixAssumptions", "selectedDashboard.fixAssumptions.length", function () {
      var _this3 = this;
      var publicAssumptions = this.get("assumptions"),
        dashboardAssumptions = this.get("selectedDashboard.fixAssumptions"),
        returnAssumptions = Ember.A([]),
        hasAssumptionsChanges = false;
      if (dashboardAssumptions) {
        returnAssumptions = publicAssumptions.filter(function (assumption) {
          return dashboardAssumptions.indexOf(Ember.get(assumption, "uniqueId")) !== -1;
        });
      }
      if (Ember.isEmpty(returnAssumptions) || returnAssumptions.length === 0) {
        this.set("collapsed", true);
      }
      returnAssumptions = returnAssumptions.map(function (assumption) {
        var currency = Ember.get(assumption, "currency"),
          originalCurrency = Ember.get(assumption, "originalCurrency"),
          value = Ember.get(assumption, "value");
        if (currency !== originalCurrency) {
          if (!_formulaHelper.default.isFormulaString(value)) {
            if (originalCurrency === "%") {
              value /= 100;
            } else if (currency === "%") {
              value *= 100;
            }
          }
          hasAssumptionsChanges = true;
          _this3.get("changedAssumptions").addObject(assumption);
        }
        Ember.setProperties(assumption, {
          currency: Ember.get(assumption, "originalCurrency") || Ember.get(assumption, "currency"),
          name: Ember.get(assumption, "originalName") || Ember.get(assumption, "name"),
          value: value
        });
        return assumption;
      });
      publicAssumptions.forEach(function (publicAssumption) {
        var assumption = returnAssumptions.findBy("uniqueId", Ember.get(publicAssumption, "uniqueId"));
        if (!assumption) {
          // missing in dashboard - checking values for interactive scenario
          var currency = Ember.get(publicAssumption, "currency"),
            originalCurrency = Ember.get(publicAssumption, "originalCurrency"),
            value = Ember.get(publicAssumption, "value"),
            originalValue = Ember.get(publicAssumption, "originalValue");
          if (currency !== originalCurrency || value !== originalValue) {
            if (currency !== originalCurrency) {
              if (!_formulaHelper.default.isFormulaString(value)) {
                if (originalCurrency === "%") {
                  value /= 100;
                } else if (currency === "%") {
                  value *= 100;
                }
              }
            }
            Ember.setProperties(publicAssumption, {
              currency: Ember.get(publicAssumption, "originalCurrency") || Ember.get(publicAssumption, "currency"),
              name: Ember.get(publicAssumption, "originalName") || Ember.get(publicAssumption, "name"),
              value: value
            });
            returnAssumptions.pushObject(publicAssumption);
            hasAssumptionsChanges = true;
            _this3.get("changedAssumptions").addObject(publicAssumption);
          }
        }
      });
      if (hasAssumptionsChanges) {
        this.set("hasAssumptionsChanges", true);
      }
      return returnAssumptions;
    }),
    assumptionsChanged: function assumptionsChanged() {
      var saveModels = Ember.A([]),
        fixAssumption = this.get("fixAssumptions"),
        assumptionsWithParents = this.get("assumptionsWithParents");
      if (assumptionsWithParents) {
        assumptionsWithParents.forEach(function (assumptionsWithParent) {
          if (fixAssumption.findBy("uniqueId", Ember.get(assumptionsWithParent, "assumption.uniqueId"))) {
            saveModels.addObject(assumptionsWithParent.parent);
          }
        });
      }
      if (saveModels) {
        this.set("saveModels", saveModels);
        // this.notifyPropertyChange('saveModels');
      }
    },
    assumptionsObserver: Ember.observer("fixAssumptions", "fixAssumptions.length", "assumptionsWithParents", "assumptionsWithParents.length", function () {
      this.assumptionsChanged();
    }),
    hasAssumptionsChangesObserver: Ember.observer("hasAssumptionsChanges", function () {
      if (this.get("hasAssumptionsChanges")) {
        this.send("recalcDashboard");
      }
      this.set("hasAssumptionsChanges", false);
    }),
    assumptionsString: Ember.computed("fixAssumptions.@each.value", function () {
      var _this = this;
      return this.get("fixAssumptions") && this.get("fixAssumptions").map(function (a) {
        var displayValue = typeof a.value === "string" ? a.value.replace(/<span[^>]*>([^<]*)<\/span>/g, "$1").replace(/=<p>([^<]*)<\/p>/, "$1") : a.value;
        if (typeof a.value === "string" && a.value.substr(0, 1) === "=") {
          displayValue = _formula.default.calcFormula(a.value, {
            store: _this.get("store"),
            cache: _this.get("cache"),
            fixedAssumptions: _this.get("assumptionsWithParents"),
            currency: a.currency,
            budget: _this.get("selectedBudget"),
            month: moment(_this.get("selectedBudget.root.budgetAttributes.startDate"))
          }, {
            scenario: _this.get("budgetService.scenario"),
            cache: _this.get("cache")
          });
        }
        if (a.currency === "%") {
          return "".concat(a.name, " = ").concat(typeof a.value === "string" && a.value.substr(0, 1) === "=" ? displayValue : _const.default.round(a.value * 100, 6), "%");
        } else {
          return "".concat(a.name, " = ").concat(a.currency !== "#" && a.currency !== "." ? _const.default.currency_symbols[a.currency] || a.currency : "").concat(displayValue);
        }
      }).join(", ");
    }),
    recalcDashboardLis: function recalcDashboardLis() {
      this.send("recalcDashboard");
    },
    currencyOptionsArray: Ember.computed("currencyOptions", "currencyOptions.length", function () {
      var currencyOptions = this.get("currencyOptions");
      if (currencyOptions) {
        currencyOptions = currencyOptions.map(function (currency) {
          if (currency.name && currency.value) {
            return {
              id: currency.name,
              text: currency.value
            };
          }
          return currency;
        });
      }
      return currencyOptions;
    }),
    changedAssumptions: Ember.A([]),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _this = this;
      var setChanged = function setChanged(e) {
        if (Ember.$(e.target).closest(".disable-render-preview").length > 0) {
          return true;
        }
        if (e.type === "change" || e.which >= 46 && e.which !== 187 || e.which === 8 || e.which === 9) {
          var fixedAssumption = Ember.$(e.target).closest(".fix-assumption[data-id]");
          if (fixedAssumption.length) {
            _this.get("changedAssumptions").addObject(_this.get("fixAssumptions").findBy("uniqueId", fixedAssumption.data("id")));
          }
          runLater = Ember.run.throttle(_this, "recalcDashboardLis", 2000, false);
        }
        return true;
      };
      Ember.run.schedule("afterRender", this, function () {
        _this.$().on("keyup change", setChanged);
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(runLater);
    },
    blockRecalcDashboard: false,
    actions: {
      openFormulaEditor: function openFormulaEditor(arg) {
        this.set("blockRecalcDashboard", true);
        var formulaTemplate = _const.default.FORMULA_VERSION;
        this.sendAction("openModal", formulaTemplate, {
          model: arg,
          modelLine: null,
          title: intl.t("general.title_1695611597384"),
          templateName: formulaTemplate,
          confirmActionName: intl.t("general.confirmActionName_1695611613098"),
          confirmAction: "closeFormulaEditor",
          actionTarget: this,
          outlet: "modal",
          hideCancel: false
        });
        this.sendAction("openFormulaEditor", arg);
      },
      closeFormulaEditor: function closeFormulaEditor(model) {
        var fixAssumption = this.get("fixAssumptions").findBy("uniqueId", model.get("excludeAssumption.uniqueId"));
        Ember.set(fixAssumption, "value", model.get("value"));
        this.get("changedAssumptions").addObject(fixAssumption);
        this.set("blockRecalcDashboard", false);
        this.send("recalcDashboard");
      },
      selectAssumptions: function selectAssumptions() {
        this.sendAction("openModal", "select-assumptions-modal", {
          model: {
            selectedRoot: this.get("selectedRoot"),
            selectedDashboard: this.get("selectedDashboard"),
            selectedFixAssumptions: this.get("fixAssumptions"),
            deleteAssumptions: Ember.A([]),
            addAssumptions: Ember.A([])
          },
          actionTarget: this,
          templateName: "select-assumptions-modal",
          title: intl.t("general.title_1695611597374"),
          confirmActionName: intl.t("general.confirmActionName_1695611613098"),
          confirmAction: "confirmSelection",
          cancelAction: "cancelSelection",
          lastStep: 1
        });
      },
      confirmSelection: function confirmSelection(model) {
        var _this4 = this;
        if (this.get("collapsed")) {
          this.send("collapse");
        }
        var addAssumptions = Ember.get(model, "addAssumptions"),
          deleteAssumptions = Ember.get(model, "deleteAssumptions"),
          budget = this.get("selectedRoot"),
          dashboard = budget.get("dashboardList").findBy("uniqueId", this.get("selectedDashboard.uniqueId"));
        if (!Ember.get(dashboard, "fixAssumptions")) {
          Ember.set(dashboard, "fixAssumptions", addAssumptions);
        } else {
          Ember.get(dashboard, "fixAssumptions").addObjects(addAssumptions).removeObjects(deleteAssumptions);
        }
        if (deleteAssumptions && deleteAssumptions.length) {
          this.get("fixAssumptions").filter(function (assumption) {
            return deleteAssumptions.indexOf(Ember.get(assumption, "uniqueId")) !== -1;
          }).forEach(function (assumption) {
            Ember.set(assumption, "value", Ember.get(assumption, "originalValue"));
            _this4.get("cache").clearBudgetCache(assumption);
          });
          this.send("recalcDashboard");
        }
        this.set("selectedDashboard.fixAssumptions", Ember.get(dashboard, "fixAssumptions"));
        budget.save();
      },
      cancelSelection: function cancelSelection() {
        if (this.get("collapsed")) {
          this.send("collapse");
        }
      },
      collapse: function collapse() {
        this.set("collapsed", !this.get("collapsed"));
      },
      recalcDashboard: function recalcDashboard() {
        var _this5 = this;
        if (this.get("blockRecalcDashboard")) {
          return;
        }
        // clear assumptions
        var fixAssumptions = this.get("changedAssumptions");
        if (fixAssumptions) {
          fixAssumptions.forEach(function (assumption) {
            _this5.get("cache").clearBudgetCache(assumption);
          });
        }
        this.setProperties({
          changedAssumptions: Ember.A([]),
          blockRecalcDashboard: false
        });
        this.sendAction("recalcDashboard");
      },
      saveAssumption: function saveAssumption() {
        // TODO
      },
      deleteAssumption: function deleteAssumption(assumption) {
        var deleteAssumption = Ember.get(assumption, "uniqueId"),
          budget = this.get("selectedRoot"),
          dashboard = budget.get("dashboardList").findBy("uniqueId", this.get("selectedDashboard.uniqueId"));
        if (Ember.get(dashboard, "fixAssumptions")) {
          this.send("clearChanges", assumption);
          Ember.get(dashboard, "fixAssumptions").removeObject(deleteAssumption);
        }
        this.set("selectedDashboard.fixAssumptions", Ember.get(dashboard, "fixAssumptions"));
        budget.save();
      },
      clearChanges: function clearChanges(assumption) {
        Ember.set(assumption, "value", Ember.get(assumption, "originalValue"));
        this.get("cache").clearBudgetCache(assumption);
        this.send("recalcDashboard");
      }
    }
  });
});