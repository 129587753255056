define("budgeta/templates/components/forecast-row-root", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CQ0pFgqI",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"dynamic-attr\",\"data-level\",[\"unknown\",[\"data\",\"level\"]],null],[\"dynamic-attr\",\"data-id\",[\"unknown\",[\"data\",\"id\"]],null],[\"dynamic-attr\",\"data-type\",[\"unknown\",[\"data\",\"type\"]],null],[\"dynamic-attr\",\"class\",[\"concat\",[\"group \",[\"helper\",[\"if\"],[[\"get\",[\"data\",\"children\"]],\"has-children\"],null],\" \",[\"helper\",[\"if\"],[[\"get\",[\"data\",\"outOfRange\"]],\"out-of-range\"],null]]]],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"data\",\"children\"]]],null,0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"forecast-row\"],null,[[\"data\",\"actuals\",\"mode\",\"showColumns\",\"actualChanged\",\"exchangeRates\",\"selectedBudget\",\"precision\",\"viewType\"],[[\"get\",[\"child\"]],[\"get\",[\"actuals\"]],[\"get\",[\"mode\"]],[\"get\",[\"showColumns\"]],[\"get\",[\"actualChanged\"]],[\"get\",[\"exchangeRates\"]],[\"get\",[\"selectedBudget\"]],[\"get\",[\"precision\"]],[\"get\",[\"viewType\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[\"child\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/forecast-row-root.hbs"
    }
  });
});