define("budgeta/components/main/budget/index-component", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var runLater;
  var idleAutoSaveInterval;
  var IDLE_TIME = 5 * 60 * 1000;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    cache: Ember.inject.service(),
    renderPreview: function renderPreview() {
      var ctl = this.get("controllertmp");
      if (ctl) {
        ctl.send("renderPreviewOnChange");
      }
    },
    clearCacheAndRender: function clearCacheAndRender() {
      var _this2 = this;
      // for employees clear cache for advanced benefits
      if (this.get("controllertmp.model.isEmployee")) {
        (this.get("controllertmp.model.benefits") || []).forEach(function (b) {
          return _this2.get("cache").clearBudgetCache(b);
        });
      }
      if (!this.get("controllertmp.editLine")) {
        this.renderPreview();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _this = this;
      var canvas = document.getElementById("snow");
      if (canvas && this.get("controllertmp.session.isAuthenticated")) {
        canvas.height = 48 + 60;
      }
      var autoSave = function autoSave() {
        if (_this.get("controllertmp.changed")) {
          if (Ember.$(".formula-editor-modal").length) {
            // close formula editor if open
            _this.get("controllertmp").send("closeModal");
          }
          _this.get("controllertmp").send("submit");
        }
        idleAutoSaveInterval = window.setTimeout(autoSave, IDLE_TIME);
      };
      var setChanged = function setChanged(e) {
        var disableChange = !!e.target.closest("[data-budget-dirty=false]") || e.target.closest('[data-attr="disableChanged"]');
        if (disableChange || _this.get("budgetService.inTransition") || _this.get("controllertmp.model.inheritedReadOnlyOrLock") || _this.get("budgetService.disableTriggerChanges")) {
          if (_this.get("viewSelections.isGridView")) {
            _this.set("controllertmp.changed", false);
          }
          return true;
        }
        var $target = Ember.$(e.target);
        if ((e.type === "change" || e.type === "paste") && $target.val() !== "=" || e.which >= 46 && e.which !== 187 || e.which === 8 || e.which === 9 && $target.closest(".custom-amount").length || e.type === "click" && $target.closest(".design-icons").length) {
          var budgetLineChanged = $target.closest(".no-model-changes").length === 0;
          //				_this.set('lastChanged', _this.$('input').index(e.target));
          if (budgetLineChanged) {
            _this.set("controllertmp.changed", true);
          }

          // The budget line has changed.
          _this.set("controllertmp.disableSave", false);
          window.clearTimeout(idleAutoSaveInterval);
          idleAutoSaveInterval = window.setTimeout(autoSave, IDLE_TIME);
          if ($target.closest(".force-disable-render-preview").length || $target.closest(".disable-render-preview").length > 0 && e.originalEvent /* don't refresh the preview for keyboard events that are fired in specified fields  */) {
            return true;
          }
          if (budgetLineChanged) {
            runLater = Ember.run.debounce(_this, _this.clearCacheAndRender, 500);
          } else {
            _this.get("controllertmp") && _this.get("controllertmp").send("renderPreview");
          }
        }
        return true;
      };
      var setChangedTab = function setChangedTab(e) {
        if ((e.keyCode || e.which) === 9) {
          var $target = Ember.$(e.target);
          var isCustomAmount = $target.closest(".custom-amount");
          if (isCustomAmount.length) {
            var indexInput = $target.closest(".custom-amount").next().index();
            var dataKey = $target.closest(".budgeta-type-custom-amounts").attr("data-key");
            if (dataKey) {
              Ember.run.next(function () {
                Ember.run.scheduleOnce("afterRender", this, function () {
                  if (indexInput !== -1) {
                    _this.$(_this.$("div[data-key=\"".concat(dataKey, "\"] .custom-amount"))[indexInput]).find("input").focus();
                  }
                });
              });
            }
          }
        }
      };
      Ember.run.schedule("afterRender", this, function () {
        _this.$().on("keyup change paste", "#main-input-form", setChanged);
        _this.$().on("click", "#main-input-form .design-icons", setChanged);
        _this.$().on("keydown", "#main-input-form .custom-amount input", setChangedTab);
        Ember.$('[data-spy="scroll"]').each(function () {
          Ember.$(this).scrollspy("refresh");
        });
        _this.$().on("change.employeeName", '[data-budget-module="employee"] #attribute-name input', function () {
          var ctl = _this.get("controllertmp");
          var name = Ember.$(this).val();
          var match = _const.default.findBudget(ctl.get("model.root"), {
            name: name,
            budgetType: "employee"
          }, ctl.get("model.id"));
          if (match) {
            ctl.send("showNotification", "Notice", "Please note. An employee with the name " + name + " already exists under " + match.get("parent.parent.name") + ".");
          }
        });
        Ember.$(".main-content .secondary ol.tree.nav").scrollTo("li.budget-list-item.active");
      });
      Ember.$(window).on("beforeunload.input", function (e) {
        if (_this.get("controllertmp.changed")) {
          var confirmationMessage = intl.t("general.changes_you_made_may_not_be_saved_1698234255664");
          e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
          return confirmationMessage; // Gecko, WebKit, Chrome <34
        } else {
          return undefined;
        }
      });
      Ember.$(document).on("click.autosave keyup.autosave", ".formula-editor-modal .modal-dialog .modal-content", function () {
        window.clearTimeout(idleAutoSaveInterval);
        idleAutoSaveInterval = window.setTimeout(autoSave, IDLE_TIME);
      });

      // auto save when idle for 3 minutes
      idleAutoSaveInterval = window.setTimeout(autoSave, IDLE_TIME);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(runLater);
      this.$("form .scrollable").off("keyup change");
      Ember.$(window).off("beforeunload.input");
      Ember.$(document).off("click.autosave keyup.autosave", ".formula-editor-modal .modal-dialog .modal-content");
      window.clearTimeout(idleAutoSaveInterval);
    }
  });
});