define("budgeta/components/react/download-options-modal/download-options-modal", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "@sibp/ui-components", "react", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _defineProperty2, _objectSpread3, _toConsumableArray2, _slicedToArray2, _uiComponents, _react, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var emberSeviceContainer = new _emberServiceContainer.default();
  var budgetService = emberSeviceContainer.lookup("budgetService");
  var trackingEvents = emberSeviceContainer.lookup("trackingEvents");
  var intl = emberSeviceContainer.lookup("intl");
  var DownloadOptionsModal = function DownloadOptionsModal(_ref) {
    var action = _ref.action,
      onCancel = _ref.onCancel,
      onConfirm = _ref.onConfirm;
    var _React$useState = _react.default.useState([]),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      versions = _React$useState2[0],
      setVersions = _React$useState2[1];
    var _React$useState3 = _react.default.useState([]),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      displayedVersions = _React$useState4[0],
      setDisplayedVersions = _React$useState4[1];
    var initialFilter = {
      snapshot: true,
      forecast: true,
      base: true
    };
    var _React$useState5 = _react.default.useState(initialFilter),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      filter = _React$useState6[0],
      setFilter = _React$useState6[1];
    var _React$useState7 = _react.default.useState(false),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      isShowVersions = _React$useState8[0],
      setIsShowVersions = _React$useState8[1];
    var isPartialShare = budgetService.get("selectedRoot.isPartialShare");
    var versionTypeNames = new Map(Object.values(_const.VERSION_TYPES).map(function (ver) {
      return [ver.id, ver.text];
    }));
    var getText = function getText(key) {
      return intl.t("download-options-modal.".concat(action, ".").concat(key));
    };
    var save = function save() {
      var selectedVersions = [];
      if (isShowVersions) {
        selectedVersions = displayedVersions.filter(function (v) {
          return v.selected;
        });
      }
      trackingEvents.trackEvent(trackingEvents.events.DOWNLOAD_OPTIONS_MODAL.SAVE.buildEvent(action, selectedVersions.map(function (v) {
        return v.type;
      })));
      onConfirm({
        versions: selectedVersions.map(function (v) {
          return v.id;
        })
      });
    };
    _react.default.useEffect(function () {
      var isSnapBeforeRevert = function isSnapBeforeRevert(ver) {
        return ver.get("subType") === _const.default.VERSION_SUB_TYPES.BEFORE_REVERT;
      };
      var isSnapBeforeMerge = function isSnapBeforeMerge(ver) {
        return ver.get("subType") === _const.default.VERSION_SUB_TYPES.BEFORE_MERGE;
      };
      var isBaseBud = function isBaseBud(ver) {
        return ver.get("type") === "base";
      };
      var isForecast = function isForecast(ver) {
        return ver.get("type") === "forecast";
      };
      var isSnap = function isSnap(ver) {
        return ver.get("type") === "snapshot";
      };
      var shouldDisplaySnap = function shouldDisplaySnap(ver) {
        return isSnap(ver) && !isSnapBeforeMerge(ver) && !isSnapBeforeRevert(ver);
      };
      var _versions = (budgetService.get("selectedRoot.versions") || []).filter(function (v) {
        return isBaseBud(v) || isForecast(v) || shouldDisplaySnap(v);
      }).sort(function (v1, v2) {
        return v1.get("created") > v2.get("created");
      }).map(function (v) {
        return {
          id: v.id,
          name: v.get("tag"),
          type: v.get("type"),
          selected: false,
          note: v.get("note"),
          createdDate: v.get("created"),
          subType: v.get("subType"),
          createdBy: v.get("createdByDisplayName")
        };
      });
      setVersions(_versions);
      setDisplayedVersions((0, _toConsumableArray2.default)(_versions));
    }, []);
    var selectVersion = function selectVersion(id, selected) {
      versions.find(function (v) {
        return v.id === id;
      }).selected = selected;
    };
    var selectAll = function selectAll(selected) {
      displayedVersions.forEach(function (v) {
        return v.selected = selected;
      });
      setDisplayedVersions((0, _toConsumableArray2.default)(displayedVersions));
    };
    var changeFilter = function changeFilter(versionType, selected) {
      var _displayedVersions = displayedVersions;
      if (selected) {
        versions.forEach(function (v) {
          if (v.type === versionType) {
            _displayedVersions.push(v);
          }
        });
      } else {
        _displayedVersions = _displayedVersions.filter(function (v) {
          return v.type !== versionType;
        });
      }
      _displayedVersions.sort(function (ver1, ver2) {
        return ver1.createdDate > ver2.createdDate ? -1 : 1;
      });
      setFilter((0, _objectSpread3.default)((0, _objectSpread3.default)({}, filter), {}, (0, _defineProperty2.default)({}, versionType, selected)));
      setDisplayedVersions((0, _toConsumableArray2.default)(_displayedVersions));
    };
    var toggleVersions = function toggleVersions(isOpen) {
      setIsShowVersions(isOpen);
    };
    var optionsWithVersions = _react.default.createElement("div", null, _react.default.createElement("div", {
      className: "description"
    }, getText("description-with-versions")), _react.default.createElement(_uiComponents.default.COMPONENTS.RadioGroup, {
      onChange: function onChange(event) {
        toggleVersions(event.target.value === "show");
      },
      defaultValue: "hide"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Radio, {
      label: getText("save-without-versions"),
      value: "hide",
      checked: !isShowVersions,
      dataHook: "radio-option-hide",
      requiredMessage: intl.t("uic.required_field")
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Radio, {
      label: _react.default.createElement("span", null, getText("save-with-versions-1"), " (", _react.default.createElement("span", {
        className: "note"
      }, intl.t("download-options-modal.note")), ":", " " + getText("save-with-versions-2"), ")"),
      requiredMessage: intl.t("uic.required_field"),
      value: "show",
      checked: isShowVersions,
      dataHook: "radio-option-show"
    })));
    var optionsWithoutVersions = _react.default.createElement("div", null, isPartialShare ? _react.default.createElement("div", null, getText("description-partial-share")) : _react.default.createElement("div", null, _react.default.createElement("p", null, _react.default.createElement("span", {
      className: "note"
    }, intl.t("download-options-modal.note")), ":", " " + getText("description-without-versions-1")), _react.default.createElement("p", null, getText("description-without-versions-2"))));
    var filterSection = _react.default.createElement("div", {
      className: "filter"
    }, _react.default.createElement("span", null, intl.t("download-options-modal.display")), _react.default.createElement(_uiComponents.default.COMPONENTS.Checkbox, {
      checked: filter.snapshot,
      onChange: function onChange(checked) {
        return changeFilter("snapshot", checked);
      },
      label: intl.t("download-options-modal.version-types.snapshot"),
      dataHook: "version-type-snapshot",
      className: "filter-checkbox",
      requiredMessage: intl.t("uic.required_field")
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Checkbox, {
      checked: filter.forecast,
      onChange: function onChange(checked) {
        return changeFilter("forecast", checked);
      },
      label: intl.t("download-options-modal.version-types.forecast"),
      dataHook: "version-type-forecast",
      className: "filter-checkbox",
      requiredMessage: intl.t("uic.required_field")
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Checkbox, {
      checked: filter.base,
      onChange: function onChange(checked) {
        return changeFilter("base", checked);
      },
      label: intl.t("download-options-modal.version-types.base"),
      dataHook: "version-type-base",
      className: "filter-checkbox",
      requiredMessage: intl.t("uic.required_field")
    }));
    var versionsTable = _react.default.createElement("div", null, _react.default.createElement("table", {
      className: "versions-header"
    }, _react.default.createElement("thead", null, _react.default.createElement("tr", {
      className: "table-header"
    }, _react.default.createElement("th", {
      className: "select-version"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Checkbox, {
      checked: false,
      onChange: function onChange(checked) {
        return selectAll(checked);
      },
      requiredMessage: intl.t("uic.required_field")
    })), _react.default.createElement("th", {
      className: "name"
    }, intl.t("general.version_1695803192543")), _react.default.createElement("th", {
      className: "type"
    }, intl.t("general.type_1695803192053")), _react.default.createElement("th", {
      className: "created-date"
    }, intl.t("general.date_1695803188979")), _react.default.createElement("th", {
      className: "note"
    }, intl.t("general.comments_1695803188762")), _react.default.createElement("th", {
      className: "created-by"
    }, intl.t("general.created_by_1695803188751"))))), _react.default.createElement("div", {
      className: "versions-wrapper",
      "data-hook": "versions-wrapper"
    }, _react.default.createElement("table", null, _react.default.createElement("tbody", null, displayedVersions.map(function (ver, idx) {
      var id = "version-".concat(ver.id);
      return _react.default.createElement("tr", {
        key: id,
        "data-hook": "version-".concat(idx),
        "data-id": ver.id
      }, _react.default.createElement("td", {
        className: "select-version"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Checkbox, {
        checked: ver.selected,
        onChange: function onChange(checked) {
          return selectVersion(ver.id, checked);
        },
        requiredMessage: intl.t("uic.required_field")
      })), _react.default.createElement("td", {
        className: "name clip-text",
        title: ver.name,
        "data-hook": "version-name"
      }, _react.default.createElement("span", null, ver.name)), _react.default.createElement("td", {
        className: "type"
      }, versionTypeNames.get(ver.type)), _react.default.createElement("td", {
        className: "created-date"
      }, moment(ver.createdDate).format(_const.default.MomentDateAndTimeDisplayFormat)), _react.default.createElement("td", {
        className: "note clip-text",
        title: ver.note
      }, _react.default.createElement("span", null, ver.note)), _react.default.createElement("td", {
        className: "created-by clip-text",
        title: ver.createdBy
      }, _react.default.createElement("span", null, ver.createdBy)));
    })))));
    var body = _react.default.createElement("div", null, versions.length ? optionsWithVersions : optionsWithoutVersions, _react.default.createElement("div", {
      className: "versions-body-container  ".concat(isShowVersions ? "show" : "")
    }, filterSection, versionsTable));
    var footer = {
      buttons: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
        className: "secondary",
        text: getText("cancel"),
        onClick: function onClick() {
          return onCancel && onCancel();
        }
      }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
        text: getText("confirm"),
        onClick: function onClick() {
          return save();
        },
        dataHook: "confirm-btn"
      }))
    };
    var content = {
      header: {
        text: getText("title"),
        options: {
          hAlign: "left"
        }
      },
      body: body,
      footer: footer
    };
    var options = {
      className: "download-options-modal-wrapper",
      width: "1000px",
      height: "auto",
      useBackdrop: true
    };
    return _react.default.createElement("div", {
      id: "download-options-modal-wrapper"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: content,
      options: options,
      dataHook: "download-budget-modal-container"
    }));
  };
  var _default = _exports.default = DownloadOptionsModal;
});