define("budgeta/components/icon-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: "icon-input",
    type: "text",
    keyUp: function keyUp() {
      this.sendAction();
    }
  });
});