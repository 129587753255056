define("budgeta/templates/components/budgeta-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AOc47r67",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"lds-ring\"],[\"static-attr\",\"data-hook\",\"ring-loader\"],[\"flush-element\"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"close-element\"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"close-element\"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"close-element\"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"close-element\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/budgeta-spinner.hbs"
    }
  });
});