define("budgeta/utils/versions", ["exports", "budgeta/utils/ajax", "budgeta/config/environment", "budgeta/services/ember-service-container"], function (_exports, _ajax, _environment, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VersionsUtils = {
    isBaseBudgetForecast: function isBaseBudgetForecast(forecastId) {
      var versionId = VersionsUtils.getForecastVersionId(forecastId);
      return VersionsUtils.isBaseBudget(versionId);
    },
    getForecastVersionId: function getForecastVersionId(forecastId) {
      return forecastId === null || forecastId === void 0 ? void 0 : forecastId.split("#")[0].split("|")[1];
    },
    isBaseBudget: function isBaseBudget(versionId) {
      if (!versionId) {
        return false;
      }
      var budgetService = new _emberServiceContainer.default().lookup("budgetService");
      var version = budgetService.get("selectedRoot.versions").findBy("id", versionId);
      var isBaseBudget = version && version.get("type") === "base";
      return isBaseBudget;
    },
    createVersion: function createVersion(selectedRoot, version, immediate) {
      return (0, _ajax.default)("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/budgets/").concat(selectedRoot.id, "/createVersion?immediate=").concat(immediate || ""), {
        method: "POST",
        data: Object.assign(version, {
          force: true
        })
      });
    }
  };
  var _default = _exports.default = VersionsUtils;
});