define("budgeta/services/modal", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  function calcModalPosition(refElement, offset, options) {
    var marginTop, translateXValue;
    if (!options.position) {
      translateXValue = offset.left - 30;
      marginTop = offset.top + refElement.height() + 10;
      if (document.body.clientWidth < this.$bar.width() + translateXValue) {
        translateXValue = document.body.clientWidth - this.$bar.width() - 10;
      }
    } else if (options.position === "topRight") {
      marginTop = offset.top;
      var selectorElementOffsetRight = offset.left + refElement.width();
      translateXValue = -1 * (this.$bar.offset().left - selectorElementOffsetRight);
    } else if (options.position === "centerRight") {
      marginTop = offset.top - refElement.height() - 10;
      var _selectorElementOffsetRight = offset.left + refElement.width() + 30;
      translateXValue = -1 * (this.$bar.offset().left - _selectorElementOffsetRight);
    } else if (options.position === "topCenter") {
      marginTop = offset.top - refElement.height() - 30;
      var _selectorElementOffsetRight2 = offset.left - 10;
      translateXValue = -1 * (this.$bar.offset().left - _selectorElementOffsetRight2);
    }
    return {
      marginTop: marginTop,
      translateXValue: translateXValue
    };
  }
  var modal = Ember.Service.extend({
    show: function show(options /* message, type, selector*/) {
      var _this = this;
      Ember.run.scheduleOnce("afterRender", function () {
        var refElement = Ember.$(options.selector).first();
        if (refElement.length === 0) {
          refElement = Ember.$(options.backupSelector);
          if (refElement.length === 0) {
            return;
          }
        }
        options.scrollIntoView && refElement[0].scrollIntoView();
        var offset = refElement.offset();
        var controller = options.controller;
        var buttons = [{
          text: options.okLabel || intl.t("general.got_it!_1698234257020"),
          addClass: "btn btn-primary",
          onClick: function onClick($noty) {
            if (controller && options.okAction) {
              controller.send(options.okAction, options.okActionArg);
            }
            $noty.close();
          }
        }];
        if (options.cancelLabel) {
          buttons.insertAt(0, {
            text: options.cancelLabel,
            addClass: "btn btn-cancel",
            onClick: function onClick($noty) {
              if (controller && options.cancelAction) {
                controller.send(options.cancelAction, options.cancelActionArg);
              }
              $noty.close();
            }
          });
        }
        Ember.run.throttle(_this, noty, {
          type: options.type || "information",
          text: options.text,
          theme: "budgeta",
          layout: options.position || "topLeft",
          closeWith: options.closeWith,
          // TODO: topLeft does not work as expected. it always display from bottom.
          // Layout options are: topLeft/topCenter/topRight/centerLeft/center/centerRight/bottomLeft/bottomCenter/bottomRight
          // The arrow position is declared in app.scss file (i.e. noty_centerLeft_layout_container -> &.before)
          timeout: options.noTimeout ? false : Ember.getWithDefault(options, "timeout", 5000),
          callback: {
            onShow: function onShow() {
              options.overlay && Ember.$("#overlay").removeClass("hidden");
              var _calcModalPosition$ca = calcModalPosition.call(this, refElement, offset, options),
                marginTop = _calcModalPosition$ca.marginTop,
                translateXValue = _calcModalPosition$ca.translateXValue;
              this.$bar.find(".noty_bar").css({
                "margin-top": marginTop,
                transform: "translateX(".concat(translateXValue, "px)")
              });
            },
            onClose: function onClose() {
              options.overlay && Ember.$("#overlay").addClass("hidden");
            }
          },
          buttons: buttons
        }, 1000);
      });
    }
  });
  var _default = _exports.default = modal;
});