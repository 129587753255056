define("budgeta/components/dropdown-with-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    isNonProfitBudget: Ember.computed.alias("budgetService.isNonProfitBudget"),
    elementIdDropdown: Ember.computed("elementId", function () {
      return this.get("elementId") + "dropdown";
    }),
    filteredContent: Ember.computed("wizard", "content", "isNonProfitBudget", function () {
      var content = this.get("content");
      if (this.get("isNonProfitBudget")) {
        content = content.filter(function (item) {
          return !Ember.get(item, "unavailableInNonProfitBudget");
        });
      } else {
        content = content.filter(function (item) {
          return !Ember.get(item, "onlyInNonProfit");
        });
      }
      if (this.get("wizard")) {
        return content.filter(function (item) {
          return !Ember.get(item, "excludeFromWizard");
        });
      } else {
        return content;
      }
    }),
    addTipsToContent: function () {
      var _this = this;
      if (this.get("helpIq") && !this.get("content").findBy("tipId")) {
        this.get("content").forEach(function (item) {
          if (!Ember.isEmpty(Ember.get(item, "overrideTipId"))) {
            Ember.set(item, "tipId", Ember.get(item, "overrideTipId"));
            return;
          }
          var module = _this.get("store").peekRecord("budgetModule", Ember.get(item, "id"));
          if (module) {
            Ember.set(item, "tipId", Ember.get(module, "tipId"));
          }
        });
      }
    }.on("init"),
    displayValue: Ember.computed("value", "content", "placeholder", function () {
      if (this.get("value") && this.get("content")) {
        for (var i = 0; i < this.get("content.length"); i++) {
          if (this.get("content.".concat(i, ".id")) === this.get("value")) {
            return this.get("content.".concat(i, ".name"));
          }
        }
      }
      return this.get("placeholder");
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var target = Ember.$("#" + this.get("elementIdDropdown"));
      var api = target ? target.qtip("api") : null;
      if (api) {
        api.destroy();
      }
    },
    actions: {
      selectDropdown: function selectDropdown(item) {
        var target = Ember.$("#" + this.get("elementIdDropdown"));
        var api = target ? target.qtip("api") : null;
        var emberItem = Ember.Object.create(item);
        this.set("value", emberItem.get("id"));
        if (api) {
          api.hide("externalHide");
        }
      }
    }
  });
});