define("budgeta/components/multi-budget-lines-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    trackingEvents: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    classNameBindings: [":multi-budget-lines-mapping", "isSingle:single-mapping", "hasMultiSelection:has-multi-selection"],
    hasMultiSelection: Ember.computed("filteredSelectedBudgets.[]", function () {
      return this.get("filteredSelectedBudgets.length") > 1;
    }),
    isEmpty: Ember.computed("filteredSelectedBudgets.[]", function () {
      return !this.get("filteredSelectedBudgets.length");
    }),
    optionLabelPath: Ember.computed("isDimensions", function () {
      return this.get("isDimensions") ? "text" : "name";
    }),
    dropdownWidth: Ember.computed("isDimensions", function () {
      return this.get("isDimensions") ? 250 : 400;
    }),
    bsLinesObserver: Ember.observer("account.isBalanceSheetType", "account.type", function () {
      var _this = this;
      Ember.run.later(function () {
        if (_this.get("account.isBalanceSheetType") || _this.get("account.type") === "bs") {
          (_this.get("bsLines") || []).forEach(function (item) {
            Ember.set(item, "budget", Ember.Object.create({
              name: item.name,
              fullPathWithoutAccounts: item.name
            }));
            Ember.set(item, "isBs", true);
          });
        }
      });
    }),
    budgetLinesSelect: Ember.computed("account.isBalanceSheetType", "isDimensions", function () {
      if (this.get("isDimensions")) {
        return this.get("dimensionOptions");
      }
      if (this.get("account.isBalanceSheetType") || this.get("account.type") === "bs") {
        return this.get("bsLines");
      }
      return this.get("budgetLines");
    }),
    filteredSelectedBudgets: Ember.computed("isDimensions", "account.isBalanceSheetType", "account.type", "selectedBudgets.[]", "budgetLines.[]", {
      get: function get() {
        var _this2 = this;
        if (this.get("isDimensions") || this.get("account.isBalanceSheetType") || this.get("account.type") === "bs") {
          return this.get("selectedBudgets");
        }
        // remove missing lines
        return (this.get("selectedBudgets") || []).filter(function (b) {
          return (_this2.get("budgetLines") || []).findBy("id", b);
        });
      },
      set: function set(key, value) {
        this.set("selectedBudgets", value);
        return value;
      }
    }),
    notReadOnly: Ember.computed.not("readOnly"),
    realEnableMulti: Ember.computed.and("notReadOnly", "enableMulti"),
    actions: {
      closeFilterDropdown: function closeFilterDropdown() {
        Ember.$(".trx-filter").qtip("hide");
      },
      powerSelectChange: function powerSelectChange(newValue, multiSelectedItem) {
        var _this3 = this;
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.USER_MAP_ACCOUNT_TO_LINE"), {
          map_type: this.get("account.type")
        });
        if (this.get("isClone")) {
          var newValues = newValue;
          var cloneDimensions = this.get("account.dimensions");
          var cloneBudgetLines = this.get("account.budgetLines");
          var thisAccount = this.get("thisAccount");
          if (this.get("isSingle")) {
            newValues = [newValue.id];
          } else {
            newValues = newValues.mapBy("id");
          }
          if (this.get("isDimensions")) {
            thisAccount.setProperties({
              dimensions: newValues,
              budgetLines: cloneBudgetLines,
              copyFromAccount: undefined,
              mappingType: this.get("account.mappingType")
            });
          } else {
            // budget lines
            thisAccount.setProperties({
              dimensions: cloneDimensions,
              budgetLines: newValues,
              copyFromAccount: undefined,
              mappingType: this.get("account.mappingType")
            });
          }
          this.$().trigger("change");
          return;
        }
        if (this.get("isSingle")) {
          this.set("account.budgetLines", [newValue.id]);
        }
        if (this.get("onPowerSelectChangeAction")) {
          var selectedValue = this.get("isSingle") ? newValue : multiSelectedItem;
          if (selectedValue) {
            this.sendAction("onPowerSelectChangeAction", selectedValue);
          }
        }
        Ember.run.next(function () {
          _this3.$().trigger("change");
        });
      },
      multiSelectOnOpenAction: function multiSelectOnOpenAction(dropdownModel) {
        if (this.get("multiSelectOnOpenAction")) {
          this.sendAction("multiSelectOnOpenAction", dropdownModel);
        }
      }
    }
  });
});