define("budgeta/templates/components/integration-wizard-select-dimensions-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fdUNu6fa",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"t\"],[\"integration-wizard-select-dimensions-data.subtitle\"],null],false],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"scrollable no-margin short-list\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"dimensions\"]]],null,1],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"error\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"modal-error error\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"escapedError\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"flex highlight-field\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"without-border mid-text-steel ignore-color\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"dim\",\"dimension\"]],false],[\"close-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"multi-select-dropdown\"],null,[[\"classNames\",\"enabled\",\"value\",\"content\",\"optionIdPath\",\"optionValuePath\",\"optionLabelPath\",\"attributeChanged\",\"allOrSomeDim\",\"enableSelectAndClearAll\",\"disabled\"],[\"wizard-field\",true,[\"get\",[\"dim\",\"selectedValue\"]],[\"get\",[\"dim\",\"options\"]],\"id\",\"id\",\"text\",\"dimensionValuesChanged\",[\"get\",[\"dim\",\"dimension\"]],true,[\"get\",[\"dim\",\"unActive\"]]]]],false],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"dim\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/integration-wizard-select-dimensions-data.hbs"
    }
  });
});