define("budgeta/controllers/formula-editor-modal", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/assumption", "budgeta/controllers/modal", "budgeta/utils/formula", "lodash/lodash"], function (_exports, _emberServiceContainer, _assumption, _modal, _formula, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable no-useless-escape, ember/no-side-effects, ember/no-observers */

  var setFloatingError = function setFloatingError(options) {
    var floatingError = Ember.$(".floating-error"),
      width = options.width,
      rightPosition = width + options.left,
      parentWidth = floatingError.parent().outerWidth();
    if (rightPosition > parentWidth) {
      width -= rightPosition - parentWidth;
    }
    floatingError.css({
      width: width,
      top: options.top + 28,
      left: options.left
    });
  };
  var hasValidInput = function hasValidInput(textOption) {
    return textOption.split(/[0-9\s-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/).filter(function (text) {
      var lower = text.toLowerCase();
      return lower && lower !== "round" && lower !== "max" && lower !== "min" && lower !== "if";
    }).length;
  };
  var symbolCheck = function symbolCheck(symbol, unallowStart) {
    if (unallowStart) {
      for (var i = 0; i < unallowStart.length; i++) {
        if (symbol.startsWith(unallowStart[i])) {
          return true;
        }
      }
    }
    if (!(symbol === "." || symbol === "+" || symbol === "-" || symbol === "*" || symbol === "/" || symbol === "(" || symbol === ")" || symbol === "^" || symbol === "!" || symbol === "=" || symbol === "<=" || symbol === ">=" || symbol === ">" || symbol === "<" || symbol === "<>" || symbol === ",") && symbol.indexOf("if(") === -1 && symbol.indexOf("round(") === -1 && symbol.indexOf("min(") === -1 && symbol.indexOf("max(") === -1 && symbol.indexOf("sum(") === -1 && symbol.indexOf("sumtotal(") === -1) {
      if (symbol.indexOf("(") !== -1) {
        var index = symbol.indexOf("(");
        var left = symbol.substr(0, index);
        var right = symbol.substr(index + 1);
        if (left && symbolCheck(left)) {
          return true;
        }
        if (right && symbolCheck(right, ["*", "/", "^", "=", "<=", ">=", ">", "<", "<>", ",", ")"])) {
          return true;
        }
        return false;
      }
      if (symbol.indexOf(")") !== -1) {
        var _index = symbol.indexOf(")");
        var _left = symbol.substr(0, _index);
        var _right = symbol.substr(_index + 1);
        if (_left) {
          // cannot have a symbol before )
          return true;
        }
        if (_right && symbolCheck(_right)) {
          return true;
        }
        return false;
      }
      if (symbol.indexOf("<>") !== -1) {
        var _index2 = symbol.indexOf("<>");
        var _left2 = symbol.substr(0, _index2);
        var _right2 = symbol.substr(_index2 + 2);
        if (_left2) {
          // cannot have a symbol before <>
          return true;
        }
        if (_right2 && symbolCheck(_right2, ["*", "/", "^", "=", "<=", ">=", ">", "<", "<>", ",", ")"])) {
          return true;
        }
        return false;
      }
      if (symbol.indexOf("-") !== -1) {
        var _index3 = symbol.indexOf("-");
        var _left3 = symbol.substr(0, _index3);
        var _right3 = symbol.substr(_index3 + 1);
        if (_left3 && symbolCheck(_left3)) {
          return true;
        }
        if (_right3 && symbolCheck(_right3, ["-", "+", "*", "/", "^", "=", "<=", ">=", ">", "<", "<>", ",", ")"])) {
          return true;
        }
        return false;
      }
      return true;
    }
    if (!(symbol.indexOf("if(") === -1 && symbol.indexOf("round(") === -1 && symbol.indexOf("min(") === -1 && symbol.indexOf("max(") === -1 && symbol.indexOf("sumtotal(") === -1 && symbol.indexOf("sum(") === -1)) {
      var functionNames = ["if(", "round(", "min(", "max(", "sum(", "sumtotal("];
      var functionName;
      for (var _i = 0; _i < functionNames.length; _i++) {
        if (symbol.indexOf(functionNames[_i]) !== -1) {
          functionName = functionNames[_i];
          break;
        }
      }
      var _index4 = symbol.indexOf(functionName);
      var _left4 = symbol.substr(0, _index4);
      var _right4 = symbol.substr(_index4 + functionName.length);
      if (_left4 && symbolCheck(_left4)) {
        return true;
      }
      if (_right4 && symbolCheck(_right4, ["*", "/", "^", "=", "<=", ">=", ">", "<", ",", ")"])) {
        return true;
      }
    }
  };
  var checkParentheses = function checkParentheses(nodes) {
    var textNodes = [],
      text = "";
    for (var i = 0; i < nodes.length; i++) {
      if (nodes[i].nodeName === "#text") {
        textNodes.push(nodes[i]);
      }
    }
    var count = 0;
    var startPosition, startNode;
    var setError = function setError() {
      var range = document.createRange();
      range.setStart(startNode, startPosition);
      range.setEnd(startNode, startPosition);
      var rects = range.getClientRects();
      var formulaNode = Ember.$(".formula-textarea p")[0];
      setFloatingError({
        width: 10,
        top: rects[0].top - Ember.$(formulaNode).offset().top + 6,
        left: rects[0].left - Ember.$(formulaNode).offset().left + 13
      });
    };
    for (var n = 0; n < textNodes.length; n++) {
      text = textNodes[n].nodeValue;
      for (var _i2 = 0; _i2 < text.length; _i2++) {
        if (text[_i2] === "(") {
          if (count === 0) {
            startNode = textNodes[n];
            startPosition = _i2;
          }
          // check if we have symbol before
          if (text[_i2 - 1] && !isNaN(text[_i2 - 1] * 1)) {
            startNode = textNodes[n];
            startPosition = _i2;
            setError();
            return true;
          }
          count++;
        } else if (text[_i2] === ")") {
          count--;
          if (text[_i2 + 1] && !isNaN(text[_i2 + 1] * 1)) {
            startNode = textNodes[n];
            startPosition = _i2;
            setError();
            return true;
          }
          if (count < 0) {
            startNode = textNodes[n];
            startPosition = _i2;
            setError();
            return true;
          }
        }
      }
    }
    if (count) {
      setError();
    }
    return count !== 0;
  };
  var checkSumFunction = function checkSumFunction(nodes) {
    var sumText = "SUM(";
    var textNodes = [];
    for (var i = 0; i < nodes.length; i++) {
      if (nodes[i].nodeName === "#text") {
        textNodes.push(nodes[i]);
      }
    }
    var showError = false;
    for (var n = 0; n < textNodes.length; n++) {
      var text = textNodes[n].nodeValue || "";
      var pos = text.toUpperCase().indexOf(sumText);
      if (pos !== -1) {
        var range = document.createRange();
        var formulaNode = Ember.$(".formula-textarea p")[0];
        range.setStart(textNodes[n], pos);
        range.setEnd(textNodes[n], pos + sumText.length);
        var rects = range.getClientRects();
        setFloatingError({
          width: rects[0].right - rects[0].left,
          top: rects[0].top - Ember.$(formulaNode).offset().top + 6,
          left: rects[0].left - Ember.$(formulaNode).offset().left + 13
        });
        showError = true;
      }
    }
    return showError;
  };
  var _default = _exports.default = _modal.default.extend({
    budgetService: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    budgetsObjModelList: Ember.computed.alias("treeManager.budgetsObjModelList"),
    showError: false,
    savedSelection: undefined,
    chosenAssumptionPos: null,
    lastResult: null,
    scenario: Ember.computed.alias("budgetService.scenario"),
    functionsAllowed: Ember.computed("model.assumptionLines", function () {
      return this.get("model.assumptionLines.length");
    }),
    sumAllowed: Ember.computed("functionsAllowed", "modelLine.budgetAttributesWithScenarios.amountType", function () {
      var amountType = this.get("modelLine.budgetAttributesWithScenarios.amountType");
      var allowSum = amountType === 0 || amountType === 1 || amountType === 3;
      return this.get("functionsAllowed") && allowSum;
    }),
    allAssumptions: Ember.computed("model.assumptions", "model.assumptionLines", "model.excludeAssumption", function () {
      var result = [];
      if (this.get("model.assumptions")) {
        if (this.get("model.excludeAssumption")) {
          var toRemove = this.get("model.assumptions").findBy("assumption", this.get("model.excludeAssumption"));
          if (toRemove) {
            this.get("model.assumptions").removeObject(toRemove);
          }
        }
        var duplicateNames = _lodash.default.filter(this.get("model.assumptions").mapBy("name"), function (value, index, iteratee) {
          return _lodash.default.includes(iteratee, value, index + 1);
        });
        this.get("model.assumptions").forEach(function (a) {
          var realName = (Ember.get(a, "name") || "").trim(),
            name = realName;
          if (!Ember.isEmpty(name)) {
            if (duplicateNames.includes(Ember.get(a, "name"))) {
              name = Ember.get(a, "parent.name") + " > " + name;
              if (Ember.get(a, "parent.parent.budgetType") === "group-model-parent") {
                name = Ember.get(a, "parent.parent.name") + " > " + name;
              }
            }
            result.pushObject({
              realName: realName,
              name: name,
              lcName: name.toLowerCase(),
              type: null,
              allowed: true,
              id: Ember.get(a, "assumption.uniqueId"),
              uniqueId: Ember.get(a, "assumption.uniqueId"),
              parent: Ember.get(a, "parent"),
              subBudgetType: Ember.get(a, "parent.subBudgetType")
            });
          }
        });
      }
      if (this.get("model.assumptionLines")) {
        var _duplicateNames = _lodash.default.filter(this.get("model.assumptionLines").mapBy("name"), function (value, index, iteratee) {
          return _lodash.default.includes(iteratee, value, index + 1);
        });
        this.get("model.assumptionLines").forEach(function (a) {
          var realName = (Ember.get(a, "name") || "").trim(),
            name = realName;
          if (_duplicateNames.includes(Ember.get(a, "name"))) {
            name = Ember.get(a, "parent.name") + " > " + name;
            if (Ember.get(a, "parent.parent.budgetType") === "group-model-parent") {
              name = Ember.get(a, "parent.parent.name") + " > " + name;
            }
          }
          result.pushObject({
            realName: realName,
            name: name,
            lcName: name.toLowerCase(),
            type: "line",
            allowed: true,
            id: Ember.get(a, "uniqueId"),
            uniqueId: Ember.get(a, "uniqueId"),
            parent: Ember.get(a, "parent"),
            subBudgetType: Ember.get(a, "subBudgetType")
          });
        });
      }
      return result;
    }),
    assumptionList: Ember.computed("cleanFormula", "allAssumptions", function () {
      Ember.$(".assumption-list-wrapper").scrollTop(0);
      var result = this.get("lastResult") || this.get("allAssumptions");
      var cleanFormula = this.get("cleanFormula");
      result.setEach("allowed", true);
      var selection = window.getSelection();
      var selectionNode = selection.anchorNode;
      var textNode;
      if (_formula.default.isFormulaTextSection(selection)) {
        textNode = selectionNode.nodeValue;
      } else if (cleanFormula && cleanFormula !== "=" && cleanFormula.length === 1) {
        // first char in formula
        textNode = cleanFormula;
      }
      if (textNode && this.getLastOperationIndex(textNode.toLowerCase()) !== textNode.length) {
        result.setEach("allowed", false);
        var textInOffset,
          allTextOptions = [];
        if (_formula.default.isFormulaTextSection(selection)) {
          textInOffset = textNode.substr(0, selection.anchorOffset);
        }
        var regexStrings = [intl.t("formula.buttons.if"), intl.t("formula.buttons.round"), intl.t("formula.buttons.max"), intl.t("formula.buttons.min")].join("|");
        var regexString1 = "[\\s-!^*()+=\\/](".concat(regexStrings, ")\\({0,1}$");
        var regexString2 = "^(".concat(regexStrings, ")\\({0,1}$");
        if (!new RegExp(regexString1, "i").test(textInOffset) && !new RegExp(regexString2, "i").test(textInOffset)) {
          if (textInOffset) {
            var lastValidWord = textInOffset.replace(/\s/g, " ").match(/(\w*\s*)*\w+$/),
              wordOption = "",
              lastIndex = textInOffset.length;
            if (lastValidWord) {
              if (hasValidInput(lastValidWord[0].toLowerCase())) {
                allTextOptions.pushObject(lastValidWord[0].toLowerCase());
              }
              wordOption = lastValidWord[0];
              lastIndex = lastValidWord.index;
            }
            for (var i = 0; i < lastIndex; i++) {
              var textOption = textInOffset.substring(i, lastIndex) + wordOption;
              if (hasValidInput(textOption)) {
                allTextOptions.pushObject(textOption);
              }
            }
          }
          result = result.filter(function (a) {
            var foundOption;
            allTextOptions.forEach(function (option) {
              if (Ember.get(a, "realName").toLowerCase().indexOf(option) !== -1) {
                foundOption = true;
              }
            });
            return foundOption;
          });
        }
        if (result.length === 0) {
          result = this.get("lastResult") || this.get("allAssumptions");
        }
      } else {
        result = this.get("allAssumptions");
      }
      (result || Ember.A([])).setEach("allowed", true);
      this.set("lastResult", result);
      return result;
    }),
    statisticalModelList: Ember.computed("assumptionList", "budgetsObjModelList", function () {
      var assumptionList = this.get("assumptionList").filter(function (assumption) {
          return assumption.allowed && assumption.subBudgetType === "stat";
        }),
        budgetsObjModelList = this.get("budgetsObjModelList").filter(function (model) {
          return Ember.get(model, "budget.budgetType") !== "group-model-parent" && Ember.get(model, "budget.subBudgetType") === "stat";
        });
      var models = Ember.A([]),
        budgetLine = this.get("model.budget") || this.get("modelLine"),
        currentModelId = budgetLine && budgetLine.get("isModelGroup") ? budgetLine.get("id") : budgetLine && budgetLine.get("parent.id");
      var modelTitles = {};
      budgetsObjModelList = Ember.A(budgetsObjModelList.map(function (model) {
        var modelTitle = Ember.get(model, "budget.title");
        if (!modelTitles[modelTitle]) {
          modelTitles[modelTitle] = 0;
        }
        modelTitles[modelTitle]++;
        return Ember.Object.create({
          model: Ember.get(model, "budget"),
          assumptions: assumptionList.filterBy("parent.id", Ember.get(model, "budget.id")),
          first: false
        });
      }).filter(function (model) {
        return Ember.get(model, "assumptions.length");
      }));
      budgetsObjModelList.forEach(function (model) {
        var titleDisplay = Ember.get(model, "model.title");
        if (modelTitles[titleDisplay] > 1 && Ember.get(model, "model.parent.isModelGroupParent")) {
          titleDisplay = Ember.get(model, "model.parent.title") + " > " + titleDisplay;
        }
        Ember.set(model, "titleDisplay", titleDisplay);
        if (currentModelId && Ember.get(model, "model.id") === currentModelId) {
          Ember.set(model, "first", true);
          models.insertAt(0, model);
        } else {
          models.pushObject(model);
        }
      });
      return models;
    }),
    modelList: Ember.computed("assumptionList", "budgetsObjModelList", function () {
      var assumptionList = this.get("assumptionList").filter(function (assumption) {
          return assumption.allowed && !assumption.subBudgetType;
        }),
        budgetsObjModelList = this.get("budgetsObjModelList").filter(function (model) {
          return Ember.get(model, "budget.budgetType") !== "group-model-parent" && !Ember.get(model, "budget.subBudgetType");
        });
      var models = Ember.A([]),
        budgetLine = this.get("model.budget") || this.get("modelLine"),
        currentModelId = budgetLine && budgetLine.get("isModelGroup") ? budgetLine.get("id") : budgetLine && budgetLine.get("parent.id");
      var modelTitles = {};
      budgetsObjModelList = Ember.A(budgetsObjModelList.map(function (model) {
        var modelTitle = Ember.get(model, "budget.title");
        if (!modelTitles[modelTitle]) {
          modelTitles[modelTitle] = 0;
        }
        modelTitles[modelTitle]++;
        return Ember.Object.create({
          model: Ember.get(model, "budget"),
          assumptions: assumptionList.filterBy("parent.id", Ember.get(model, "budget.id")),
          first: false
        });
      }).filter(function (model) {
        return Ember.get(model, "assumptions.length");
      }));
      budgetsObjModelList.forEach(function (model) {
        var titleDisplay = Ember.get(model, "model.title");
        if (modelTitles[titleDisplay] > 1 && Ember.get(model, "model.parent.isModelGroupParent")) {
          titleDisplay = Ember.get(model, "model.parent.title") + " > " + titleDisplay;
        }
        Ember.set(model, "titleDisplay", titleDisplay);
        if (currentModelId && Ember.get(model, "model.id") === currentModelId) {
          Ember.set(model, "first", true);
          models.insertAt(0, model);
        } else {
          models.pushObject(model);
        }
      });
      return models;
    }),
    isFormulaError: Ember.computed("formulaOutput", "formulaValue", function () {
      var formulaNode = Ember.$(".formula-textarea p")[0]; // check errors
      var amountType = this.get("model.budget.budgetAttributesWithScenarios.amountType");
      if (formulaNode) {
        var nodes = formulaNode.childNodes;
        if (checkParentheses(nodes)) {
          return true;
        }
        if (this.get("model.budget.isModelLine") && amountType === 2 && checkSumFunction(nodes)) {
          return true;
        }
        // check unallow start
        if (nodes[0] && nodes[0].nodeName === "#text" && nodes[0].nodeValue) {
          var firstChar = nodes[0].nodeValue[0];
          if (firstChar && isNaN(Number.parseInt(firstChar)) && firstChar.toLowerCase() === firstChar.toUpperCase() && firstChar !== "+" && firstChar !== "-" && firstChar !== "(") {
            var range = document.createRange();
            var startIndex = 0;
            range.setStart(nodes[0], startIndex);
            range.setEnd(nodes[0], 1);
            var rects = range.getClientRects();
            if (rects[0]) {
              setFloatingError({
                width: rects[0].right - rects[0].left,
                top: rects[0].top - Ember.$(formulaNode).offset().top + 9,
                left: rects[0].left - Ember.$(formulaNode).offset().left + 13
              });
            }
            return true;
          }
        }
      }
      if (this.get("formulaOutput.error")) {
        return true;
      }
      if (this.get("formulaOutput") === "Compound") {
        return true;
      }
      setFloatingError({
        width: 0,
        top: 0,
        left: 0
      });
      return false;
    }),
    formulaErrorObserver: Ember.observer("isFormulaError", function () {
      var _this = this;
      if (this.get("isFormulaError")) {
        Ember.run.later(function () {
          _this.set("showError", true);
        }, 1000);
      } else {
        this.set("showError", false);
      }
    }),
    formulaOutputClean: Ember.computed("formulaOutput", "isFormulaError", function () {
      var result = this.get("formulaOutput");
      if (this.get("isFormulaError")) {
        result = Ember.get(result, "error");
      }
      return result;
    }),
    allFixedAssumptions: Ember.computed("budgetService.selectedRoot", "budgetService.selectedBudget.budgetAttributes.assumptions.@each.name", function () {
      return _assumption.default.getFixedAssumptions(this.get("budgetService.selectedRoot"), null, null, {
        ignoreScope: true,
        withParent: true
      });
    }),
    formulaOutput: Ember.computed("formulaValue", "allFixedAssumptions", "model.assumptionLines", "isFormulaError", function () {
      var markedError = false;
      var formulaOutput = _formula.default.parseToFormula(this.get("formulaValue"), {
        store: this.get("store"),
        fixedAssumptions: this.get("budgetService.fixedAssumptions"),
        budget: this.get("budgetService.selectedRoot"),
        scenario: this.get("budgetService.scenario")
      });
      var formulaNode = Ember.$(".formula-textarea p")[0]; // check errors
      if (formulaNode) {
        var nodes = formulaNode.childNodes;
        var prevNode;
        for (var i = 0; i < nodes.length; i++) {
          if (prevNode) {
            if (prevNode.nodeName === "#text" && prevNode.nodeValue && nodes[i].nodeName === "SPAN" && !/[\+\-\*\/=<>(^,]$/.test(prevNode.nodeValue)) {
              Ember.set(formulaOutput, "error", intl.t("general.formula-editor-modal-1", {
                var1: Ember.$(prevNode).text(),
                var2: Ember.$(nodes[i]).text()
              }));
              var range = document.createRange();
              range.setStart(prevNode, 0);
              range.setEnd(prevNode, prevNode.length);
              var rects = range.getClientRects();
              if (rects[0]) {
                setFloatingError({
                  width: rects[0].right - rects[0].left + Ember.$(nodes[i]).outerWidth() + 10,
                  top: rects[0].top - Ember.$(formulaNode).offset().top + 9,
                  left: rects[0].left - Ember.$(formulaNode).offset().left + 15
                });
              }
              markedError = true;
              break;
            }
            if (prevNode.nodeName === "SPAN" && nodes[i].nodeName === "#text" && nodes[i].nodeValue && !/^[\+\-\*\/=<>)^,]/.test(nodes[i].nodeValue)) {
              Ember.set(formulaOutput, "error", intl.t("general.formula-editor-modal-1", {
                var1: Ember.$(prevNode).text(),
                var2: Ember.$(nodes[i]).text()
              }));
              var _range = document.createRange();
              _range.setStart(nodes[i], 0);
              _range.setEnd(nodes[i], nodes[i].length);
              var _rects = _range.getClientRects();
              if (_rects[0]) {
                setFloatingError({
                  width: _rects[0].right - _rects[0].left + Ember.$(prevNode).outerWidth() + 10,
                  top: Ember.$(prevNode).position().top - 4,
                  left: Ember.$(prevNode).position().left + 5
                });
              }
              markedError = true;
              break;
            }
          }
          if (nodes[i].nodeName === "#text") {
            var nodeValue = nodes[i].nodeValue;
            var symbols = nodeValue.split(/[0-9]+/);
            for (var s = 0; s < symbols.length; s++) {
              if (symbols[s] && isNaN(Number.parseInt(symbols[s]))) {
                var symbol = symbols[s].toLowerCase();
                if (symbolCheck(symbol)) {
                  Ember.set(formulaOutput, "error", intl.t("general.formula-editor-modal-3", {
                    var1: symbols[s]
                  }));
                  var _range2 = document.createRange();
                  var startIndex = nodeValue.toLowerCase().indexOf(symbol);
                  _range2.setStart(nodes[i], startIndex);
                  _range2.setEnd(nodes[i], startIndex + symbol.length);
                  var _rects2 = _range2.getClientRects();
                  if (_rects2[0]) {
                    setFloatingError({
                      width: _rects2[0].right - _rects2[0].left,
                      top: _rects2[0].top - Ember.$(formulaNode).offset().top + 9,
                      left: _rects2[0].left - Ember.$(formulaNode).offset().left + 13
                    });
                  }
                  markedError = true;
                  break;
                }
              }
            }
          }
          if (i < nodes.length - 1 && nodes[i].nodeName === "SPAN" && nodes[i + 1] && nodes[i + 1].nodeName === "SPAN") {
            var leftSpan = Ember.$(nodes[i]);
            var rightSpan = Ember.$(nodes[i + 1]);
            setFloatingError({
              width: leftSpan.outerWidth() + rightSpan.outerWidth() + 10,
              top: leftSpan.position().top - 4,
              left: leftSpan.position().left + 5
            });
            Ember.set(formulaOutput, "error", intl.t("general.formula-editor-modal-4", {
              var1: Ember.$(nodes[i]).text(),
              var2: Ember.$(nodes[i + 1]).text()
            }));
            markedError = true;
            break;
          }
          prevNode = nodes[i];
        }
        // check Parentheses
        if (nodes.length) {
          checkParentheses(nodes);
        }
      }
      if (Ember.get(formulaOutput, "type") === "Compound" && Ember.get(formulaOutput, "body.length") > 0) {
        Ember.set(formulaOutput, "error", intl.t("general.formula_error_1698234256199"));
      }
      if (Ember.get(formulaOutput, "error") && !markedError) {
        var matchs = (Ember.get(formulaOutput, "error") || "").match(/character\s\d+$/);
        if (matchs && matchs[0] && !Ember.get(formulaOutput, "error").startsWith("Error: Unclosed") && !Ember.get(formulaOutput, "error").startsWith('Error: Unexpected ")"')) {
          var index = matchs[0].substr(10) * 1; // remove character\s
          if (Ember.get(formulaOutput, "jsepText.length") === index) {
            setFloatingError({
              width: 0,
              top: 0,
              left: 0
            });
          }
        }
        if (Ember.get(formulaOutput, "error").startsWith("Error: Variable names cannot start with a number")) {
          var textError = Ember.get(formulaOutput, "error").match(/Error: Variable names cannot start with a number.*\((.*)\).*at character \d+/);
          if (textError && textError.length > 1) {
            textError = textError[1];
            var _formulaNode = Ember.$(".formula-textarea p")[0];
            if (_formulaNode) {
              var _nodes = _formulaNode.childNodes;
              for (var _i3 = 0; _i3 < _nodes.length; _i3++) {
                if (_nodes[_i3].nodeName === "#text") {
                  if (_nodes[_i3].nodeValue.indexOf(textError) !== -1) {
                    var _range3 = document.createRange();
                    var _startIndex = _nodes[_i3].nodeValue.indexOf(textError);
                    _range3.setStart(_nodes[_i3], _startIndex);
                    _range3.setEnd(_nodes[_i3], _nodes[_i3].nodeValue.indexOf(textError) + textError.length);
                    var _rects3 = _range3.getClientRects();
                    if (_rects3[0]) {
                      setFloatingError({
                        width: _rects3[0].right - _rects3[0].left,
                        top: _rects3[0].top - Ember.$(_formulaNode).offset().top + 9,
                        left: _rects3[0].left - Ember.$(_formulaNode).offset().left + 13
                      });
                      markedError = true;
                    }
                  }
                }
              }
            }
          }
        }
        markedError = true;
      }
      return formulaOutput;
    }),
    inputClass: Ember.computed("isFormulaError", function () {
      return this.get("isFormulaError") ? "invalid" : "";
    }),
    formulaValue: Ember.computed("model", "model.value", "model.initializedFormulaValue", {
      get: function get() {
        var modelValue = this.get("model.initializedFormulaValue") || this.get("model.value");
        var options = {
          store: this.get("store"),
          fixedAssumptions: this.get("budgetService.fixedAssumptions"),
          budget: this.get("budgetService.selectedRoot"),
          scenario: this.get("budgetService.scenario"),
          model: this.get("model.budget")
        };
        var jsepObj = _formula.default.parseToFormula(modelValue, options);
        return !jsepObj ? "=<p></p>" : "=" + _formula.default.JSONToHTML(jsepObj, options);
      },
      set: function set(key, value) {
        return value;
      }
    }),
    isLongFormula: Ember.computed("formulaValue", function () {
      return this.get("formulaValue").length > 90;
    }),
    formulaLineNumber: Ember.computed("isLongFormula", function () {
      return this.get("isLongFormula") ? 4 : 3;
    }),
    cleanFormula: Ember.computed("formulaValue", function () {
      return this.get("formulaValue") ? this.get("formulaValue").replace(/\=<p>(.*)<\/p>/, "$1") : null;
    }),
    backspaceManual: function backspaceManual(isManual) {
      if (this.get("formulaValue") === "=" || this.get("formulaValue") === "=<p></p>") {
        return true;
        // return e.preventDefault();
      }
      var selection = window.getSelection(),
        selectionNode = selection.anchorNode.parentNode,
        selectionNodeText = selection.anchorNode,
        range = document.createRange();
      if (!Ember.$(selectionNode).closest("p").length) {
        var p = Ember.$(".formula-textarea p")[0];
        var lastChild = p.lastChild;
        if (lastChild.nodeName === "#text") {
          lastChild.nodeValue = lastChild.nodeValue.slice(0, -1);
        } else {
          p.removeChild(lastChild);
        }
        this.set("formulaValue", Ember.$(".formula-textarea")[0].innerHTML);
        return;
      }
      if (selectionNodeText.nodeName === "#text" && selectionNodeText.nodeValue === "=" && !selectionNodeText.previousSibling && selectionNodeText.parentNode.nodeName === "DIV") {
        return true;
      }
      if (selectionNode.nodeName === "SPAN" && selectionNode.parentElement) {
        // remove span (assumption/model line)
        // set selection to prev element
        var prev = selectionNode.previousSibling;
        var next = selectionNode.nextSibling;
        if (selection.anchorOffset) {
          if (prev) {
            if (prev.nodeName === "SPAN") {
              prev = prev.firstChild;
              range.setStart(prev, prev.length);
              range.setEnd(prev, prev.length);
            } else {
              // text
              var prevLength = prev.length;
              if (next && next.nodeName === "#text") {
                // merge the text elements
                prev.nodeValue += next.nodeValue;
                selectionNode.parentElement.removeChild(next);
              }
              range.setStart(prev, prevLength);
              range.setEnd(prev, prevLength);
            }
            selection.removeAllRanges();
            selection.addRange(range);
          } else if (selectionNode.nextSibling) {
            if (next.nodeName === "SPAN") {
              next = next.firstChild;
            }
            range.setStart(next, 0);
            range.setEnd(next, 0);
            selection.removeAllRanges();
            selection.addRange(range);
          }
          selectionNode.parentElement.removeChild(selectionNode); // remove span
        }
        this.set("formulaValue", this.getFormulaHTML());
        return true;

        // return e.preventDefault();
      } else if (selectionNodeText.nodeName === "#text" && !selectionNodeText.previousSibling && selection.anchorOffset === 1) {
        // if first text child and first char
        var _next = selectionNodeText.nextSibling;
        selectionNodeText.nodeValue = selectionNodeText.nodeValue.substr(1);
        if (_next && !selectionNodeText.nodeValue.length) {
          if (_next.nodeName === "SPAN") {
            _next = _next.firstChild;
          }
          range.setStart(_next, 0);
          range.setEnd(_next, 0);
          selection.removeAllRanges();
          selection.addRange(range);
          selectionNodeText.parentElement.removeChild(selectionNodeText);
        } else if (selectionNodeText.nodeValue.length) {
          range.setStart(selectionNodeText, 0);
          range.setEnd(selectionNodeText, 0);
          selection.removeAllRanges();
          selection.addRange(range);
        } else {
          var parentElement = selectionNodeText.parentElement;
          parentElement.removeChild(selectionNodeText);
          range.setStart(parentElement, 0);
          range.setEnd(parentElement, 0);
          selection.removeAllRanges();
          selection.addRange(range);
        }
        this.set("formulaValue", this.getFormulaHTML());
        return true;
        // return e.preventDefault();
      } else if (selectionNodeText.nodeName === "#text" && !selectionNodeText.previousSibling && selection.anchorOffset === 0) {
        // at start of text
        this.set("formulaValue", this.getFormulaHTML());
        return true;
        // return e.preventDefault();
      } else if (selectionNodeText.nodeName === "#text" && selectionNodeText.previousSibling && selectionNodeText.previousSibling.nodeName === "SPAN" && selection.anchorOffset === 0) {
        // at start of text with a prev span
        var spanEle = selectionNodeText.previousSibling;
        var _parentElement = selectionNodeText.parentElement;
        if (spanEle.previousSibling && spanEle.previousSibling.nodeName === "#text") {
          var _prevLength = spanEle.previousSibling.nodeValue.length;
          spanEle.previousSibling.nodeValue += selectionNodeText.nodeValue;
          range.setStart(spanEle.previousSibling, _prevLength);
          range.setEnd(spanEle.previousSibling, _prevLength);
          selection.removeAllRanges();
          selection.addRange(range);
          _parentElement.removeChild(selectionNodeText);
        }
        _parentElement.removeChild(spanEle); // remove span
        this.set("formulaValue", this.getFormulaHTML());
        return true;
        // return e.preventDefault();
      } else if (selectionNodeText.nodeName === "#text" && isManual) {
        // manual delete
        var anchorOffset = selection.anchorOffset;
        selectionNodeText.nodeValue = selectionNodeText.nodeValue.slice(0, anchorOffset - 1) + selectionNodeText.nodeValue.slice(anchorOffset);
        range.setStart(selectionNodeText, anchorOffset - 1);
        range.setEnd(selectionNodeText, anchorOffset - 1);
        selection.removeAllRanges();
        selection.addRange(range);
        this.set("formulaValue", this.getFormulaHTML());
      }
    },
    getFormulaHTML: function getFormulaHTML() {
      return "=<p>".concat(Ember.$(".formula-textarea p").html(), "</p>");
    },
    getLastOperationIndex: function getLastOperationIndex(str) {
      var symbols = ["-", "*", "(", ")", "+", "=", "/", "if(", "round(", "max(", "min(", "max", "sum(", "sumtotal(", ">", "<"];
      var index = -1;
      symbols.forEach(function (symbol) {
        if (str.indexOf(symbol) > index) {
          index = str.indexOf(symbol);
        }
      });
      return index + 1;
    },
    allowPaste: Ember.computed("scenario", "budgetService.formulaCopy.budget.value", "budgetService.formulaCopy.scenario.value", function () {
      if (this.get("scenario")) {
        var copyScenario = this.get("budgetService.formulaCopy.scenario");
        return copyScenario.value ? this.get("scenario") === copyScenario.scenarioName : copyScenario.value;
      }
      return this.get("budgetService.formulaCopy.budget.value");
    }),
    allowCopy: Ember.computed("formulaValue", function () {
      return this.get("formulaValue") !== "=<p></p>";
    }),
    allowCopyPaste: Ember.computed("model.budget.isModelLine", "model.budget.isModelGroup", function () {
      return this.get("model.budget.isModelLine") && !this.get("model.budget.isModelGroup");
    }),
    buildCopyRefMap: function buildCopyRefMap(value) {
      if (this.get("formulaOutput.references")) {
        return this.get("formulaOutput.references");
      } else {
        var references = value.match(/data-id="\w+"/g);
        var refMap = {};
        if (references) {
          references.forEach(function (ref) {
            var refId = ref.split('"')[1];
            refMap[refId] = true;
          });
        }
        return refMap;
      }
    },
    actions: {
      keyPress: function keyPress(e) {
        var keyCode = e.which || e.keyCode;
        var keyChar = String.fromCharCode(e.keyCode);
        if (keyCode === 8 && this.get("formulaValue") === "=") {
          e.preventDefault();
          return false;
        } else if (keyCode === 8 || keyCode === 16) {
          return false;
        }
        var lastChar = keyChar;
        this.send("add", lastChar, e);
      },
      clear: function clear() {
        var formulaNode = Ember.$(".formula-textarea p")[0]; // check errors
        if (formulaNode) {
          var nodes = formulaNode.childNodes;
          if (nodes) {
            var childNodes = [];
            for (var i = 0; i < nodes.length; i++) {
              childNodes.push(nodes[i]);
            }
            childNodes.forEach(function (node) {
              formulaNode.removeChild(node);
            });
          }
        }
        this.set("formulaValue", "=<p></p>");
      },
      add: function add(char, e) {
        var selection = window.getSelection();
        var selectionNode = selection.anchorNode;
        var anchorOffset = selection.anchorOffset;
        if (selectionNode && selectionNode.nodeName === "SPAN") {
          selectionNode = selectionNode.firstChild;
        }
        if (selectionNode && selectionNode.nodeValue === "=" && !selectionNode.previousSibling) {
          // put the cursor at the beginning
          var range;
          var para = selection.anchorNode.nextElementSibling; // get <p>
          var firstChild = para ? para.firstChild : null;
          if (firstChild) {
            // if first child is a model line/assumption
            if (firstChild.nodeName === "SPAN") {
              firstChild = firstChild.firstChild;
            }
            range = document.createRange();
            range.setStart(firstChild, 0);
            range.setEnd(firstChild, 0);
            selection.removeAllRanges();
            selection.addRange(range);
            selectionNode = firstChild;
            anchorOffset = 0;
          }
        }
        if (_formula.default.isFormulaTextSection(selectionNode)) {
          var nodeValue = selectionNode.nodeValue;
          var newValue = nodeValue.substr(0, anchorOffset) + char + nodeValue.substr(anchorOffset);
          selectionNode.nodeValue = newValue;
          var _range4 = document.createRange();
          _range4.setStart(selectionNode, anchorOffset + char.length);
          _range4.setEnd(selectionNode, anchorOffset + char.length);
          selection.removeAllRanges();
          selection.addRange(_range4);
        } else if (_formula.default.isFormulaSpanSection(selectionNode)) {
          // check if we need to create a new node or we have a text element to add to
          var spanNode = selectionNode.parentNode;
          var next = spanNode.nextSibling;
          if (next && anchorOffset && next.nodeName === "#text") {
            next.nodeValue = char + next.nodeValue;
            var _range5 = document.createRange();
            _range5.setStart(next, char.length);
            _range5.setEnd(next, char.length);
            selection.removeAllRanges();
            selection.addRange(_range5);
          } else {
            // need to create a new text node
            var charNode = document.createTextNode(char);
            if (anchorOffset) {
              charNode = Ember.$(charNode).insertAfter(selectionNode.parentNode);
            } else {
              charNode = Ember.$(charNode).insertBefore(selectionNode.parentNode);
            }
            var _range6 = document.createRange();
            _range6.setStart(charNode[0], char.length);
            _range6.setEnd(charNode[0], char.length);
            selection.removeAllRanges();
            selection.addRange(_range6);
          }
        } else {
          var _newValue = this.get("cleanFormula");
          if (_newValue === "=") {
            _newValue = "";
          }
          this.set("formulaValue", "=<p>".concat(_newValue).concat(char, "</p>"));
        }
        e.preventDefault();
      },
      addSymbol: function addSymbol(char) {
        if (char && char === "SUM(") {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.USE_SUM_IN_FORMULA"), {});
        }
        if (char && char === "SUMTOTAL(") {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.USE_SUMTOTAL_IN_FORMULA"), {});
        }
        var selection = window.getSelection();
        var selectionNode = selection.anchorNode;
        var anchorOffset = selection.anchorOffset;
        if (selectionNode && selectionNode.nodeName === "SPAN") {
          selectionNode = selectionNode.firstChild;
        }
        if (selectionNode && selectionNode.nodeValue === "=" && !selectionNode.previousSibling) {
          // put the cursor at the beginning
          var range;
          var para = selection.anchorNode.nextElementSibling; // get <p>
          var firstChild = para ? para.firstChild : null;
          if (firstChild) {
            // if first child is a model line/assumption
            if (firstChild.nodeName === "SPAN") {
              firstChild = firstChild.firstChild;
            }
            range = document.createRange();
            range.setStart(firstChild, 0);
            range.setEnd(firstChild, 0);
            selection.removeAllRanges();
            selection.addRange(range);
            selectionNode = firstChild;
            anchorOffset = 0;
          }
        }
        if (_formula.default.isFormulaTextSection(selectionNode)) {
          var nodeValue = selectionNode.nodeValue;
          var newValue = nodeValue.substr(0, anchorOffset) + char + nodeValue.substr(anchorOffset);
          selectionNode.nodeValue = newValue;
          var _range7 = document.createRange();
          _range7.setStart(selectionNode, anchorOffset + char.length);
          _range7.setEnd(selectionNode, anchorOffset + char.length);
          selection.removeAllRanges();
          selection.addRange(_range7);
          this.set("formulaValue", "=<p>".concat(Ember.$(".formula-textarea p").html(), "</p>"));
        } else if (_formula.default.isFormulaSpanSection(selectionNode)) {
          var spanNode = selectionNode.parentNode;
          var next = spanNode.nextSibling;
          if (next && anchorOffset && next.nodeName === "#text") {
            next.nodeValue = char + next.nodeValue;
            var _range8 = document.createRange();
            _range8.setStart(next, char.length);
            _range8.setEnd(next, char.length);
            selection.removeAllRanges();
            selection.addRange(_range8);
          } else {
            var charNode = document.createTextNode(char);
            if (anchorOffset) {
              charNode = Ember.$(charNode).insertAfter(selectionNode.parentNode);
            } else {
              charNode = Ember.$(charNode).insertBefore(selectionNode.parentNode);
            }
            var _range9 = document.createRange();
            _range9.setStart(charNode[0], char.length);
            _range9.setEnd(charNode[0], char.length);
            selection.removeAllRanges();
            selection.addRange(_range9);
          }
          this.set("formulaValue", "=<p>".concat(Ember.$(".formula-textarea p").html(), "</p>"));
        } else {
          var _newValue2 = this.get("cleanFormula") === "=" ? "" : this.get("cleanFormula");
          this.set("formulaValue", "=<p>".concat(_newValue2).concat(char, "</p>"));
        }
      },
      addAssumption: function addAssumption(assumption) {
        var selection = window.getSelection();
        var selectionNode = selection.anchorNode,
          anchorOffset = selection.anchorOffset;
        this.set("lastResult", null);
        if (this.get("savedSelection")) {
          var range = document.createRange();
          range.setStart(this.get("savedSelection.selectionNode"), this.get("savedSelection.anchorOffset"));
          range.setEnd(this.get("savedSelection.selectionNode"), this.get("savedSelection.anchorOffset"));
          selection.removeAllRanges();
          selection.addRange(range);
          selectionNode = selection.anchorNode;
          anchorOffset = this.get("savedSelection.anchorOffset");
          this.set("savedSelection", undefined);
        }
        if (selectionNode && selectionNode.nodeName === "SPAN") {
          selectionNode = selectionNode.firstChild;
        }
        if (!selectionNode || selectionNode && selectionNode.nodeValue === "=" && !selectionNode.previousSibling && selectionNode.parentNode.nodeName === "DIV" || Ember.$(selectionNode).parents().filter(function () {
          return this.tagName === "P";
        }) === 0) {
          var formulaNode = Ember.$(".formula-textarea p")[0];
          var _range10;
          var firstChild = formulaNode ? formulaNode.firstChild : null;
          if (firstChild) {
            // if first child is a model line/assumption
            if (firstChild.nodeName === "SPAN") {
              firstChild = firstChild.firstChild;
            }
            _range10 = document.createRange();
            _range10.setStart(firstChild, 0);
            _range10.setEnd(firstChild, 0);
            selection.removeAllRanges();
            selection.addRange(_range10);
            selectionNode = firstChild;
            anchorOffset = 0;
          }
        }
        var name = _assumption.default.wrapName(Ember.get(assumption, "name"), Ember.get(assumption, "type"), Ember.get(assumption, "uniqueId"), Ember.get(assumption, "parent.uniqueId"), assumption, this.get("model.budget"));
        if (_formula.default.isFormulaTextSection(selectionNode)) {
          var nodeValue = selectionNode.nodeValue;
          var firstValue = nodeValue.substr(0, anchorOffset); // first part

          var secondValue = nodeValue.substr(anchorOffset);
          var secondNode = document.createTextNode(secondValue);

          // remove assumption name
          var opIndex = this.getLastOperationIndex(firstValue.toLowerCase());
          selectionNode.nodeValue = firstValue.substr(0, opIndex).trim();
          var newAssumptionNode = Ember.$(name).insertAfter(selectionNode);
          if (!selectionNode.nodeValue) {
            // remove the node because we added it at the start
            selectionNode.parentNode.removeChild(selectionNode);
          }
          secondNode = Ember.$(secondNode).insertAfter(newAssumptionNode);
          var _range11 = document.createRange();
          _range11.setStart(secondNode[0], 0);
          _range11.setEnd(secondNode[0], 0);
          selection.removeAllRanges();
          selection.addRange(_range11);
          this.set("formulaValue", "=<p>".concat(Ember.$(".formula-textarea p").html(), "</p>"));
        } else if (_formula.default.isFormulaSpanSection(selectionNode)) {
          var _newAssumptionNode;
          if (anchorOffset) {
            _newAssumptionNode = Ember.$(name).insertAfter(selectionNode.parentNode);
          } else {
            _newAssumptionNode = Ember.$(name).insertBefore(selectionNode.parentNode);
          }
          var _range12 = document.createRange();
          _range12.setStart(_newAssumptionNode[0].childNodes[0], _newAssumptionNode[0].childNodes[0].length);
          _range12.setEnd(_newAssumptionNode[0].childNodes[0], _newAssumptionNode[0].childNodes[0].length);
          selection.removeAllRanges();
          selection.addRange(_range12);
          this.set("formulaValue", "=<p>".concat(Ember.$(".formula-textarea p").html(), "</p>"));
        } else {
          var newValue = (this.get("cleanFormula") === "=" ? "" : this.get("cleanFormula")) + name;
          this.set("formulaValue", "=<p>".concat(newValue, "</p>"));
        }
        Ember.$(".assumption-list-wrapper").scrollTop(0);
        this.set("chosenAssumptionPos", assumption);
      },
      backspace: function backspace() {
        this.backspaceManual(true);
      },
      close: function close() {
        this.set("chosenAssumptionPos", null);
        this.set("showError", false);
        if (this.get("model") && !this.get("model").isDestroying) {
          this.set("model.value", this.get("initialValue"));
          this.notifyPropertyChange("model.value");
        }
        return this.send("closeModal", this.get("outlet"), this.get("onClose"));
      },
      confirm: function confirm() {
        this.set("chosenAssumptionPos", null);
        this.set("showError", false);
        if (this.get("isFormulaError")) {
          this.set("showError", true);
          return noty({
            type: "warning",
            text: intl.t("general.text_1695611609430")
          });
        }
        if (this.get("model") && !this.get("model").isDestroying) {
          if (this.get("cleanFormula") === "") {
            this.set("model.value", "");
          } else if (this.get("cleanFormula") === "=") {
            this.set("model.value", "=");
          } else {
            this.set("model.value", "=" + JSON.stringify(this.get("formulaOutput")));
          }
        }
        if (this.get("confirmAction")) {
          this.get("actionTarget").send(this.get("confirmAction"), this.get("model"), this.get("onSave"));
        }
        return this.send("closeModal", this.get("outlet"));
      },
      copyFormula: function copyFormula() {
        var mode = this.get("scenario") ? "scenario" : "budget";
        var formulaValue = (this.get("formulaValue") || "").replace(/(=<p>|<\/p>)/g, "");
        var formulaCopyObj = {
          value: formulaValue,
          references: this.buildCopyRefMap(formulaValue)
        };
        if (this.get("scenario")) {
          formulaCopyObj.scenarioName = this.get("scenario");
        }
        this.set("budgetService.formulaCopy.".concat(mode), formulaCopyObj);
      },
      pasteFormula: function pasteFormula() {
        var _this2 = this;
        var mode = this.get("scenario") ? "scenario" : "budget";
        var formulaCopy = this.get("budgetService.formulaCopy.".concat(mode, ".value")) || "";
        var formulaValue = this.get("formulaValue") || "";
        formulaValue = formulaValue.replace(/<\/p>/, formulaCopy) + "</p>";
        this.set("formulaValue", formulaValue);
        Ember.run.scheduleOnce("afterRender", ".formula-textarea p", function () {
          _this2.notifyPropertyChange("isFormulaError");
        });
      }
    }
  });
});