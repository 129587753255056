define("budgeta/templates/components/svg/map-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tyBEY8Fh",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M8.07326 0.5C4.49391 0.5 1.59998 3.54 1.59998 7.3C1.59998 8.9 2.13307 10.5 3.1231 11.7C3.27541 11.86 5.94088 15.06 6.85476 16.02C7.15938 16.34 7.61632 16.5 7.9971 16.5C8.37788 16.5 8.83482 16.34 9.13944 16.02C10.2056 14.98 12.7949 11.86 12.8711 11.7C13.8611 10.5 14.3942 8.9 14.3942 7.3C14.5465 3.54 11.6526 0.5 8.07326 0.5ZM7.99998 9.3C6.63998 9.3 5.59998 8.26 5.59998 6.9C5.59998 5.54 6.63998 4.5 7.99998 4.5C9.35998 4.5 10.4 5.54 10.4 6.9C10.4 8.26 9.35998 9.3 7.99998 9.3Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/map-svg.hbs"
    }
  });
});