define("budgeta/components/edit-line-modal", ["exports", "budgeta/services/ember-service-container", "budgeta/components/modal-new"], function (_exports, _emberServiceContainer, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _modalNew.default.extend({
    classNames: ["edit-line-modal"],
    init: function init() {
      this._super.apply(this, arguments);
      this.get("controllertmp").setProperties({
        initialName: this.get("controllertmp.name"),
        changed: false
      });
    },
    change: function change() {
      this.set("controllertmp.changed", true);
    },
    focusIn: function focusIn(e) {
      if (!e.target.readOnly && e.target && e.target.name === "name" && e.target.value && e.target.value.startsWith(intl.t("general.new_benefit_1698234255209"))) {
        e.target.value = "";
      }
    }
  });
});