define("budgeta/templates/components/notes-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gdO0LSeK",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"textarea\"],null,[[\"value\",\"placeholder\",\"class\"],[[\"get\",[\"model\",\"notes\"]],[\"helper\",[\"t\"],[\"placeholders.add-comments-here\"],null],\"notes-text\"]]],false],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/notes-modal.hbs"
    }
  });
});