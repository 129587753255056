define("budgeta/templates/components/forecast-budget-hc-cost-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JHE+u9Bn",
    "block": "{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"displayValues\"]]],null,2]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"hcValue\"]],null,null,2,[\"get\",[\"showNegativeAsPositive\"]],\"headcount-value\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"hcValue\"]],[\"get\",[\"displayValue\",\"currency\"]],null,2,[\"get\",[\"showNegativeAsPositive\"]],\"headcount-value\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"column multi-value\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"helper\",[\"eq\"],[[\"get\",[\"displayValue\",\"currency\"]],\"%\"],null]],null,1,0],[\"text\",\"    \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"value\"]],[\"get\",[\"displayValue\",\"currency\"]],null,[\"get\",[\"precision\"]],[\"get\",[\"showNegativeAsPositive\"]],\"cost-value\"],null],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"displayValue\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/forecast-budget-hc-cost-row.hbs"
    }
  });
});