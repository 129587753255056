define("budgeta/initializers/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register("service:-document", window.document, {
      instantiate: false
    });
  }
  var _default = _exports.default = {
    initialize: initialize,
    before: ["intl", "get-config"]
  };
});