define("budgeta/admin/components/nav-bar/nav-bar", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "react", "react-router-dom", "budgeta/common-react-components/utils/tabsUtil", "@sibp/ui-components", "budgeta/services/ember-service-container"], function (_exports, _slicedToArray2, _react, _reactRouterDom, _tabsUtil, _uiComponents, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NavBar = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _SIBP$COMPONENTS$Tree = _uiComponents.default.COMPONENTS.TreeMenu,
    Tree = _SIBP$COMPONENTS$Tree.Tree,
    Folder = _SIBP$COMPONENTS$Tree.Folder,
    File = _SIBP$COMPONENTS$Tree.File;
  var NavBar = _exports.NavBar = function NavBar(_ref) {
    var tabs = _ref.tabs,
      routerBasename = _ref.routerBasename;
    var adminTitle = intl.t("admin_app.nav-bar.administration");
    var history = _reactRouterDom.default.useHistory();
    var location = _reactRouterDom.default.useLocation();
    var _React$useState = _react.default.useState(),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      activeTab = _React$useState2[0],
      setActiveTab = _React$useState2[1];
    _react.default.useEffect(function () {
      var activeTabIndex = _tabsUtil.default.findActiveTab(tabs, routerBasename);
      if (activeTabIndex >= 0) {
        setActiveTab(activeTabIndex);
      }
    }, [location]);
    var navigateTo = function navigateTo(route) {
      history.push(route);
    };
    return _react.default.createElement("div", {
      className: "nav-bar-container"
    }, _react.default.createElement(Tree, {
      initializedSelectedIndex: "file-0-".concat(activeTab),
      className: "with-selected-background"
    }, _react.default.createElement(Folder, {
      name: adminTitle,
      isAlwaysExpanded: true
    }, tabs.map(function (tab) {
      return _react.default.createElement(File, {
        name: tab.name,
        key: tab.id,
        onClick: function onClick() {
          tab.onClick && tab.onClick();
          navigateTo(tab.route);
        }
      });
    }))));
  };
});