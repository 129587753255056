define("budgeta/admin/admin-app/components/body/body", ["exports", "react", "react-redux", "react-router-dom", "budgeta/admin/admin-app/pages/companyEdit/companyEdit", "budgeta/admin/admin-app/pages/companyAdd/companyAdd", "budgeta/admin/admin-app/pages/companyList/companyList", "budgeta/admin/admin-app/pages/adminUserEdit/adminUserEdit", "budgeta/admin/admin-app/pages/adminUserList/adminUserList", "budgeta/admin/admin-app/pages/userList/userList", "budgeta/admin/admin-app/pages/budgetSearch/budgetSearch", "budgeta/admin/admin-app/pages/userBudgetList/userBudgetList", "budgeta/admin/admin-app/pages/userProfile/userProfile", "budgeta/admin/admin-app/pages/reports/reports", "budgeta/admin/admin-app/routes", "budgeta/admin/components/nav-bar/nav-bar", "budgeta/admin/admin-app/components/body/bodyContext", "budgeta/admin/store/actions/securityActions", "budgeta/admin/admin-app/pages/auditLoggingList/auditLoggingList", "budgeta/admin/admin-app/components/body/tabs"], function (_exports, _react, _reactRedux, _reactRouterDom, _companyEdit, _companyAdd, _companyList, _adminUserEdit, _adminUserList, _userList, _budgetSearch, _userBudgetList, _userProfile, _reports, _routes, _navBar, _bodyContext, _securityActions, _auditLoggingList, _tabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Body = void 0;
  // The Body component renders one of the provided ROUTES.
  var Body = _exports.Body = function Body() {
    var _React$useContext = _react.default.useContext(_bodyContext.default),
      showNavBar = _React$useContext.showNavBar;
    var userProfilePath = _routes.ROUTES.PROFILE(_routes.BASE_ROUTE);
    var dispatch = _reactRedux.default.useDispatch();
    var loggedInUser = _reactRedux.default.useSelector(function (state) {
      return state.securityStore.loggedInUser;
    });
    var isSuperAdmin = loggedInUser && loggedInUser.isSuperAdmin;
    var fileterdTabs = _tabs.default.filter(function (tab) {
      return tab.roles.includes("admin") || isSuperAdmin;
    });
    _react.default.useEffect(function () {
      _securityActions.default.getLoggedInUser()(dispatch); //this is done once for the entire application
    }, []);
    return loggedInUser && _react.default.createElement("div", {
      id: "body-container"
    }, showNavBar ? _react.default.createElement(_navBar.NavBar, {
      tabs: fileterdTabs
    }) : null, _react.default.createElement("main", null, _react.default.createElement(_reactRouterDom.default.Switch, null, _react.default.createElement(_reactRouterDom.default.Route, {
      exact: true,
      path: _routes.BASE_ROUTE,
      render: function render() {
        return _react.default.createElement(_reactRouterDom.default.Redirect, {
          to: _routes.ROUTES.COMPANY_LIST
        });
      }
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.ADMIN_USER_ADD,
      component: _adminUserEdit.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.ADMIN_USER_EDIT,
      component: _adminUserEdit.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.ADMIN_USER_LIST,
      component: _adminUserList.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.COMPANY_LIST,
      component: _companyList.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.COMPANY_EDIT,
      component: _companyEdit.default
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.COMPANY_ADD,
      component: _companyAdd.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.USER_LIST,
      component: _userList.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.USER_BUDGET_LIST,
      component: _userBudgetList.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: userProfilePath,
      component: _userProfile.default
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.AUDIT_LOGGING_LIST,
      component: _auditLoggingList.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.REPORTS,
      component: _reports.default,
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: _routes.ROUTES.BUGDET_SEARCH,
      component: _budgetSearch.default,
      exact: true
    }))));
  };
});