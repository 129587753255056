define("budgeta/components/select-2-exclusions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["select-2-exclusions"],
    content: Ember.computed("allContent.length", "exclude.[]", "optionValuePath", "value", function () {
      var optionValuePath = this.get("optionValuePath"),
        exclude = this.get("exclude"),
        value = this.get("value");
      return this.get("allContent").filter(function (item) {
        return exclude.indexOf(Ember.get(item, optionValuePath)) === -1 || Ember.get(item, optionValuePath) === value;
      });
    }),
    actions: {
      attributeChanged: function attributeChanged() {
        this.sendAction(this.get("attributeChanged"));
      }
    }
  });
});