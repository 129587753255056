define("budgeta/controllers/dimension-settings", ["exports", "budgeta/controllers/modal", "budgeta/utils/dimensions"], function (_exports, _modal, _dimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modal.default.extend({
    store: Ember.inject.service(),
    disabledHierarchies: Ember.computed("model.originalDimension.values.length", function () {
      return (this.get("model.originalDimension.values") || []).filter(function (item) {
        return Ember.get(item, "parent");
      }).length;
    }),
    disabledAllocations: Ember.computed("model.originalDimension", function () {
      var dimension = this.get("model.originalDimension");
      var store = this.get("store");
      return _dimensions.default.isDimensionUsedValues(dimension, store);
    }),
    actions: {
      close: function close() {
        this._super();
      },
      confirm: function confirm() {
        return this.confirm();
      }
    }
  });
});