define("budgeta/templates/components/integration-wizard-export-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mEl2kRZJ",
    "block": "{\"statements\":[[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"message\"]]],null,2,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question wizard-question-single\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"t\"],[\"integration-wizard-export-done.wizard-question\"],null],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question wizard-question-single\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"extraMessage\"]],false],[\"close-element\"],[\"text\",\"\\n\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question wizard-question-single\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"a\",[]],[\"static-attr\",\"class\",\"blue-link\"],[\"static-attr\",\"href\",\"https://www.intacct.com/ia/acct/login.phtml\"],[\"static-attr\",\"target\",\"_blank\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"integration-wizard-export-done.go-to-sage-intacct\"],null],false],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"        \"],[\"append\",[\"unknown\",[\"budgeta-spinner\"]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-top-section-part\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"unknown\",[\"message\"]],false],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"isLoadingSettings\"]]],null,1],[\"text\",\"    \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/integration-wizard-export-done.hbs"
    }
  });
});