define("budgeta/templates/components/budget-select-lazy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fM3woQUP",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"loading\"]]],null,2],[\"block\",[\"if\"],[[\"get\",[\"active\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"span\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"select-2-lazy-inactive-label enabled \",[\"helper\",[\"if\"],[[\"get\",[\"selectedLabel\"]],\"\",\"prompt\"],null]]]],[\"dynamic-attr\",\"title\",[\"concat\",[[\"unknown\",[\"selectedLabel\"]]]]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"activate\"],[[\"bubbles\"],[false]]],[\"flush-element\"],[\"append\",[\"helper\",[\"if\"],[[\"get\",[\"selectedLabel\"]],[\"get\",[\"selectedLabel\"]],[\"get\",[\"prompt\"]]],null],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"budget-select\"],null,[[\"readOnly\",\"prompt\",\"readyContent\",\"root\",\"budget\",\"includeSelf\",\"rootName\",\"multiple\",\"selectedBudgets\",\"showUncategorizedActuals\",\"mapByDatabaseId\",\"excludeTypes\",\"onlyCurrencyModelLines\",\"type\"],[false,[\"get\",[\"prompt\"]],[\"get\",[\"readyContent\"]],[\"get\",[\"root\"]],[\"get\",[\"budget\"]],[\"get\",[\"includeSelf\"]],[\"get\",[\"rootName\"]],[\"get\",[\"multiple\"]],[\"get\",[\"selectedBudgets\"]],[\"get\",[\"showUncategorizedActuals\"]],[\"get\",[\"mapByDatabaseId\"]],[\"get\",[\"excludeTypes\"]],[\"get\",[\"onlyCurrencyModelLines\"]],[\"get\",[\"type\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"enabled prompt\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"budget-select-lazy.loading\"],null],true],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/budget-select-lazy.hbs"
    }
  });
});