define("budgeta/admin/admin-app/store/reducers/companyReducer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/admin/admin-app/models/companyModel", "budgeta/admin/admin-app/store/actions/companyActions", "budgeta/admin/utils/const"], function (_exports, _toConsumableArray2, _objectSpread2, _companyModel, _companyActions, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initialState = {
    companies: [],
    selectedCompany: null
  };
  function rootReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case _companyActions.GET_COMPANY_PREVIEW_LIST:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            companies: action.companies
          });
        }
      case _companyActions.GET_COMPANY_DETAILS:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: action.company
          });
        }
      case _companyActions.DISABLE_COMPANY:
        {
          var companyId = action.companyId;
          var companies = (0, _toConsumableArray2.default)(state.companies);
          var idx = companies.findIndex(function (c) {
            return c._id === companyId;
          });
          if (idx > -1) {
            var company = new _companyModel.default((0, _objectSpread2.default)((0, _objectSpread2.default)({}, companies[idx]), {}, {
              status: _const.COMPANY_STATUS.INACTIVE
            }));
            companies[idx] = company;
          }
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            companies: companies,
            selectedCompany: null
          });
        }
      case _companyActions.UPDATE_COMPANY:
        {
          var _action$company = action.company,
            name = _action$company.name,
            licenses = _action$company.licenses,
            contactEmail = _action$company.contactEmail,
            contactPhone = _action$company.contactPhone,
            contactName = _action$company.contactName,
            expectedImplementDate = _action$company.expectedImplementDate,
            salesforceId = _action$company.salesforceId,
            notes = _action$company.notes;
          var updatedCompany = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state.selectedCompany), {}, {
            name: name,
            licenses: licenses,
            contactEmail: contactEmail,
            contactPhone: contactPhone,
            contactName: contactName,
            expectedImplementDate: expectedImplementDate,
            salesforceId: salesforceId,
            notes: notes
          });
          var _companies = (0, _toConsumableArray2.default)(state.companies);
          var _idx = _companies.findIndex(function (c) {
            return c._id === action.company._id;
          });
          if (_idx > -1) {
            var _company = new _companyModel.default((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _companies[_idx]), {}, {
              name: name,
              licenses: licenses,
              contactEmail: contactEmail,
              contactPhone: contactPhone,
              contactName: contactName,
              expectedImplementDate: expectedImplementDate,
              salesforceId: salesforceId,
              notes: notes
            }));
            _companies[_idx] = _company;
          }
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            companies: _companies,
            selectedCompany: updatedCompany
          });
        }
      case _companyActions.ADD_COMPANY:
        {
          var _companies2 = (0, _toConsumableArray2.default)(state.companies);
          // if companies list is empty
          // dont add company so the companyList component will know to get the list of companies from the server
          if (_companies2.length) {
            _companies2.push(action.company);
          }
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            companies: _companies2
          });
        }
      case _companyActions.ADD_COMPANY_USER:
        {
          var selectedCompany = state.selectedCompany;
          selectedCompany.users.push(action.user);
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: selectedCompany
          });
        }
      case _companyActions.UPDATE_COMPANY_USER:
        {
          var _selectedCompany = state.selectedCompany;
          var idxUser = _selectedCompany.users.findIndex(function (u) {
            return u._id === action.user._id;
          });
          _selectedCompany.users[idxUser] = action.user;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: _selectedCompany
          });
        }
      case _companyActions.DELETE_COMPANY_USER:
        {
          var _selectedCompany2 = new _companyModel.default(state.selectedCompany);
          var _idx2 = _selectedCompany2.users.findIndex(function (u) {
            return u.user._id === action.userId;
          });
          if (_idx2 > -1) {
            _selectedCompany2.users.splice(_idx2, 1);
          }
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: _selectedCompany2
          });
        }
      case _companyActions.DELETE_COMPANY:
        {
          var _companies3 = (0, _toConsumableArray2.default)(state.companies);
          var _idx3 = _companies3.findIndex(function (c) {
            return c._id === action.companyId;
          });
          _companies3.splice(_idx3, 1);
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            companies: _companies3,
            selectedCompany: null
          });
        }
      default:
        return state;
    }
  }
  var _default = _exports.default = rootReducer;
});