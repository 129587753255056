define("budgeta/helpers/format-date-today-past", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDateTodayPast = formatDateTodayPast;
  function formatDateTodayPast(input) {
    var time = new Date(input);
    if (time.isToday()) {
      return Ember.String.htmlSafe(time.format("Today, {hh}:{mm} {TT}"));
    }
    return Ember.String.htmlSafe(time.format("{Mon} {d}, {yyyy}"));
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return formatDateTodayPast(params[0]);
  });
});