define("budgeta/components/compare-format-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNameBindings: [":compare-format-numbers", "hasActuals:column-has-actual"],
    value1: null,
    value2: null,
    currency: null,
    valueCurrency: Ember.computed("inCompareCurrency", "currency", function () {
      return this.get("inCompareCurrency") === undefined ? this.get("currency") : this.get("inCompareCurrency");
    }),
    precision: null,
    isShowComapreZero: null,
    showNegativeAsPositive: false,
    hasActuals: false,
    showComapreZero: Ember.computed("isShowComapreZero", function () {
      if (this.get("isShowComapreZero")) {
        return true;
      }
      return false;
    }),
    showValue2: Ember.computed("value2", "forceValue2", function () {
      return this.get("value2") || this.get("forceValue2");
    }),
    diffValues: Ember.computed("value1", "value2", function () {
      var value1 = this.get("value1") || 0,
        value2 = this.get("value2") || 0;
      return value1 - value2;
    }),
    negative: Ember.computed("diffValues", function () {
      if (this.get("diffValues") && this.get("diffValues") < 0) {
        return true;
      }
      return false;
    }),
    positive: Ember.computed("diffValues", function () {
      if (this.get("diffValues") && this.get("diffValues") > 0) {
        return true;
      }
      return false;
    }),
    isCompareValuesHasDiffCurrency: Ember.computed("inCompareCurrency", "currency", function () {
      return this.get("inCompareCurrency") !== undefined && this.get("inCompareCurrency") !== this.get("currency");
    }),
    sameUnitTypeTooltip: Ember.computed(function () {
      return this.get("intl").t("sheets.same-unit-type-warning");
    })
  });
});