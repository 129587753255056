define("budgeta/templates/components/edit-budget-tree-with-type-rename", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Tp0steYU",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"input\"],null,[[\"name\",\"value\",\"placeholder\"],[\"name\",[\"get\",[\"budget\",\"name\"]],[\"get\",[\"budget\",\"namePlaceholder\"]]]]],false],[\"text\",\"\\n\"],[\"append\",[\"helper\",[\"dropdown-with-value\"],null,[[\"classNames\",\"helpIq\",\"content\",\"value\",\"placeholder\"],[\"budget-type-dropdown\",true,[\"get\",[\"modulesForGroup\"]],[\"get\",[\"budget\",\"budgetType\"]],[\"get\",[\"budget\",\"templateOptionsPrompt\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/edit-budget-tree-with-type-rename.hbs"
    }
  });
});