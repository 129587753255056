define("budgeta/generic-file/components/dimensions-intro/dimensions-intro", ["exports", "@babel/runtime/helpers/esm/objectDestructuringEmpty", "@babel/runtime/helpers/esm/extends", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "react", "budgeta/store/actions/budgetActions", "lodash/lodash"], function (_exports, _objectDestructuringEmpty2, _extends2, _uiComponents, _reactRedux, _stepsActions, _emberServiceContainer, _react, _budgetActions, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = DimensionsIntro;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function DimensionsIntro(_ref) {
    var rest = (0, _extends2.default)({}, ((0, _objectDestructuringEmpty2.default)(_ref), _ref));
    var dispatch = _reactRedux.default.useDispatch();
    var _suggestedDimensions = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.suggestedDimensions;
    });
    var _selectedDimensions = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.selectedDimensions;
    });
    var _stepDimensionsMapping = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.stepDimensionsMapping;
    });
    var options = {
      hideClose: true,
      height: "405px"
    };
    var prefix = _react.default.createElement(_uiComponents.default.IMAGES.SegmentsSquaresSvg, null);
    var header = {
      text: intl.t("generic-file.step-dimensions-intro.header"),
      options: {
        prefix: prefix,
        prefixClass: "dimensions-intro-header-container title-next-to-prefix",
        titleClass: "title",
        hideSeperator: true
      }
    };
    var body = _react.default.createElement("div", {
      className: "dimensions-intro-body-container padding-intro-body"
    }, _react.default.createElement("div", {
      className: "description description-left"
    }, intl.t("generic-file.step-dimensions-intro.description")));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button",
      dataHook: "footer-tertiary-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button with-border",
      dataHook: "footer-secondary-button",
      text: intl.t("generic-file.no"),
      onClick: function onClick() {
        if (!_lodash.default.isEqual(_selectedDimensions, []) || !_lodash.default.isEqual(_suggestedDimensions, null) || _stepDimensionsMapping) {
          dispatch(_budgetActions.default.setDimensionsMappingStep(false));
          dispatch(_budgetActions.default.setSelectedDimensions({
            selectedDimensions: [],
            suggestedDimensions: null
          }));
          rest.clearDependencies();
        }
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.SKIPPED_DIMENSIONS_MAPPING);
        dispatch(_stepsActions.default.nextStep(false));
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.yes"),
      onClick: function onClick() {
        if (!_stepDimensionsMapping) {
          dispatch(_budgetActions.default.setDimensionsMappingStep(true));
          rest.clearDependencies();
        }
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CHOOSED_DIMENSIONS_MAPPING);
        dispatch(_stepsActions.default.nextStep(true));
      }
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});