define("budgeta/templates/components/main/budget/grid-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SofsGVWF",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"input-grid-view\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"react-component\"],null,[[\"componentName\",\"selectedBudgetId\",\"displayedTreeItems\",\"cacheUpdatedTime\",\"isLocked\",\"exitGrid\",\"updateGridParams\",\"scenario\",\"selectBudget\",\"openFormulaEditor\"],[\"BudgetLinesGrid\",[\"get\",[\"selectedBudgetId\"]],[\"get\",[\"displayedTreeItems\"]],[\"get\",[\"cacheUpdatedTime\"]],[\"get\",[\"isLocked\"]],[\"helper\",[\"action\"],[[\"get\",[null]],[\"get\",[\"exitGrid\"]]],null],[\"helper\",[\"action\"],[[\"get\",[null]],[\"get\",[\"updateGridParams\"]]],null],[\"get\",[\"scenario\"]],[\"helper\",[\"action\"],[[\"get\",[null]],[\"get\",[\"selectBudget\"]]],null],[\"helper\",[\"action\"],[[\"get\",[null]],[\"get\",[\"openFormulaEditor\"]]],null]]]],false],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"grid-editable-budget-line-input\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"text\",\"    \"],[\"block\",[\"budget-line-input\"],null,[[\"readOnly\",\"isLocked\",\"budgetLine\",\"mainController\",\"inputController\",\"openFormulaEditor\",\"isShortView\"],[[\"get\",[\"mainController\",\"selectedBudget\",\"inheritedReadOnlyOrLock\"]],[\"get\",[\"mainController\",\"selectedBudget\",\"inheritedIsLocked\"]],[\"get\",[\"mainController\",\"selectedBudget\"]],[\"get\",[\"mainController\"]],[\"get\",[\"inputController\"]],\"openFormulaEditor\",false]],0],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/main/budget/grid-component.hbs"
    }
  });
});