define("budgeta/helpers/contains", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function contains(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      array = _ref2[0],
      item = _ref2[1];
    return array.includes(item);
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return contains(params);
  });
});