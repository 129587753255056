define("budgeta/components/month-picker-control", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/require-super-in-init, ember/no-observers, ember/closure-actions */

  var MonthNames = [intl.t("general.name_1695611601425"), intl.t("general.name_1695611601414"), intl.t("general.name_1695611601404"), intl.t("general.name_1695611601393"), intl.t("general.name_1695611601382"), intl.t("general.name_1695611601371"), intl.t("general.name_1695611601360"), intl.t("general.name_1695611601349"), intl.t("general.name_1695611601338"), intl.t("general.name_1695611601327"), intl.t("general.name_1695611601316"), intl.t("general.name_1695611601305")];
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ["month-picker-control"],
    year: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.setYear();
    },
    setYear: function setYear() {
      var startYear = Ember.isEmpty(this.get("startDate")) ? new Date().getYear() + 1900 : _const.default.getMoment(this.get("startDate")).get("year");
      this.set("year", this.get("momentValue") ? this.get("momentValue").get("year") : startYear);
    },
    valueObserver: Ember.observer("value", function () {
      this.setYear();
    }),
    momentValue: Ember.computed("value", function () {
      return _const.default.getMoment(this.get("value")) || _const.default.getMoment(this.get("inheritedValue"));
    }),
    months: Ember.computed("value", "year", "from", "to", function () {
      var momentValue = this.get("momentValue");
      var months = Ember.A([]);
      for (var i = 0; i < 12; i++) {
        var thisMonth = Date.create(this.get("year"), i);
        var monthSelected = momentValue && this.get("year") === momentValue.get("year") && i === momentValue.get("month");
        var isPastDataMonth = false;
        if (!monthSelected && this.get("from")) {
          var fromDate = Date.create(this.get("from")).reset();
          if (!thisMonth.isBefore(fromDate) && thisMonth.isBefore(momentValue.toDate())) {
            monthSelected = true;
          }
        }
        if (!monthSelected && this.get("to")) {
          var toDate = Date.create(this.get("to")).reset();
          if (!thisMonth.isAfter(toDate) && thisMonth.isAfter(momentValue.toDate())) {
            monthSelected = true;
          }
        }
        if (this.get("reverseColors") && this.get("startDate") && _const.default.formatDate(thisMonth) < _const.default.formatDate(this.get("startDate"))) {
          isPastDataMonth = true;
        }
        months.pushObject({
          id: i,
          name: MonthNames[i],
          selected: monthSelected,
          isPastDataMonth: isPastDataMonth,
          disabled: this.get("excludeMonths.length") && this.get("excludeMonths").includes(thisMonth.format("{yyyy}/{MM}")) || this.get("minStartDate") && this.get("minStartDate").isAfter(thisMonth) || this.get("maxEndDate") && !this.get("maxEndDate").isAfter(thisMonth)
        });
      }
      return months;
    }),
    actions: {
      next: function next() {
        this.set("year", this.get("year") + 1);
      },
      prev: function prev() {
        this.set("year", this.get("year") - 1);
      },
      select: function select(month) {
        var _this = this;
        if (this.get("months").findBy("id", month).disabled) {
          return;
        }
        var newValue = Date.create(this.get("year"), month);
        var isEndDatePriorStartDate = function isEndDatePriorStartDate(endDate) {
          return _this.get("isEndMonthField") && _this.get("selectedStartDate") && endDate.isBefore(_this.get("selectedStartDate"));
        };
        var isStartDateAfterEndDate = function isStartDateAfterEndDate(startDate) {
          return !_this.get("isEndMonthField") && _this.get("selectedEndDate") && startDate.isAfter(_this.get("selectedEndDate"));
        };
        if (isEndDatePriorStartDate(newValue) || isStartDateAfterEndDate(newValue)) {
          noty({
            type: "error",
            text: this.get("intl").t("messages.start-date-bigger-than-end-date")
          });
          return;
        }
        if (this.get("endOfMonth")) {
          newValue.endOfMonth("month").reset();
        }
        this.set("value", newValue);
        this.sendAction();
      }
    }
  });
});