define("budgeta/admin/admin-app/pages/auditLoggingList/audit-logging-messages", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@sibp/ui-components", "budgeta/admin/admin-app/routes", "react", "react-router-dom", "budgeta/services/audit-logging/audit-logging-utils", "budgeta/admin/utils/const"], function (_exports, _defineProperty2, _uiComponents, _routes, _react, _reactRouterDom, _auditLoggingUtils, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ADMIN_APP_MESSAGES = function ADMIN_APP_MESSAGES() {
    var history = _reactRouterDom.default.useHistory();
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, _const.ADMIN_APP_ACTION.ADD_COMPANY, function (logObject) {
      var companyName = logObject.company.name;
      return _react.default.createElement("div", null, _react.default.createElement("span", null, _const.AUDIT_LOG.COMPANY_PREFIX), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
        text: companyName,
        onClick: function onClick() {
          history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_EDIT_DETAILS, {
            companyId: logObject.company.id
          }));
        }
      }));
    }), _const.ADMIN_APP_ACTION.DISABLE_COMPANY, function (logObject) {
      return _react.default.createElement("div", {
        className: "disable-details"
      }, _react.default.createElement("div", null, "Company: ", logObject.company.name, " "), _react.default.createElement(_uiComponents.default.COMPONENTS.Tooltip, {
        text: logObject.company.disableDescription,
        direction: _uiComponents.default.TOOLTIP_DIRECTIONS.RIGHT
      }));
    }), _const.ADMIN_APP_ACTION.DELETE_COMPANY, function (logObject) {
      return _react.default.createElement("div", null, "Company: ", logObject.company.name, " - ", logObject.actionStatus);
    }), _const.ADMIN_APP_ACTION.EDIT_COMPANY, function (logObject) {
      var companyName = logObject.company.name;
      var details = logObject.actionDetails[0].details;
      var logTextDiffComponents = _auditLoggingUtils.default.getLogTextDiffComponents(details);
      return _react.default.createElement("div", null, _react.default.createElement("span", null, _const.AUDIT_LOG.COMPANY_PREFIX), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
        text: companyName,
        onClick: function onClick() {
          history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_EDIT_DETAILS, {
            companyId: logObject.company.id
          }));
        }
      }), logTextDiffComponents);
    }), _const.ADMIN_APP_ACTION.ADD_COMPANY_USER, function (logObject) {
      var userObj = logObject.actionDetails[0].entities[0];
      var companyId = logObject.company.id;
      return _react.default.createElement("div", null, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
        text: userObj.firstName + " " + userObj.lastName,
        onClick: function onClick() {
          history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_USER_EDIT, {
            companyId: companyId,
            userId: userObj.id
          }));
        }
      }));
    }), _const.ADMIN_APP_ACTION.EDIT_COMPANY_USER, function (logObject) {
      var companyId = logObject.company.id;
      var userObj = logObject.actionDetails[0].entities[0];
      var details = logObject.actionDetails[0].details;
      var logTextDiffComponents = _auditLoggingUtils.default.getLogTextDiffComponents(details);
      return _react.default.createElement("div", null, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
        text: userObj.firstName + " " + userObj.lastName,
        onClick: function onClick() {
          history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_USER_EDIT, {
            companyId: companyId,
            userId: userObj.id
          }));
        }
      }), logTextDiffComponents);
    }), _const.ADMIN_APP_ACTION.DELETE_COMPANY_USER, function (logObject) {
      var user = logObject.actionDetails[0].entities[0];
      return _react.default.createElement("div", null, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement("div", null, "".concat(user.firstName, " ").concat(user.lastName)));
    }), _const.ADMIN_APP_ACTION.DELETE_ADMIN_USER, function (logObject) {
      var user = logObject.actionDetails[0].entities[0];
      return _react.default.createElement("div", null, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement("div", null, "".concat(user.firstName, " ").concat(user.lastName)));
    }), _const.ADMIN_APP_ACTION.EDIT_ADMIN_USER, function (logObject) {
      var userObj = logObject.actionDetails[0].entities[0];
      var details = logObject.actionDetails[0].details;
      var logTextDiffComponents = _auditLoggingUtils.default.getLogTextDiffComponents(details);
      return _react.default.createElement("div", null, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
        text: userObj.firstName + " " + userObj.lastName,
        onClick: function onClick() {
          history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.ADMIN_USER_EDIT, {
            userId: userObj.id
          }));
        }
      }), logTextDiffComponents);
    }), _const.ADMIN_APP_ACTION.ADD_ADMIN_USER, function (logObject) {
      var userObj = logObject.actionDetails[0].entities[0];
      return _react.default.createElement("div", null, _react.default.createElement("span", null, _const.AUDIT_LOG.USER_PREFIX), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
        text: userObj.firstName + " " + userObj.lastName,
        onClick: function onClick() {
          history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.ADMIN_USER_EDIT, {
            userId: userObj.id
          }));
        }
      }));
    });
  };
  var _default = _exports.default = ADMIN_APP_MESSAGES;
});