define("budgeta/store/reducers/stepsReducer", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/store/actions/stepsActions", "budgeta/generic-file/components/generic-manager/stepMap"], function (_exports, _objectSpread2, _stepsActions, _stepMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-undef */

  var initialState = {
    currentStep: "fileIntro",
    accountCategoryErrorSkip: false
  };
  function stepsReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var stepsLog = function stepsLog(stepName, stepDirection, nextStepName) {
      if (_stepMap.stepsOrder) {
        _stepMap.stepsOrder.stepIndexTracker[_stepMap.stepsOrder.currentStepIndex] = stepName;
        if (stepDirection > 0) {
          _stepMap.stepsOrder.currentStepIndex = _stepMap.stepsOrder.currentStepIndex + stepDirection;
        } else {
          var lastIndex = Object.keys(_stepMap.stepsOrder.stepIndexTracker).reverse().find(function (index) {
            return _stepMap.stepsOrder.stepIndexTracker[index] === nextStepName;
          });
          _stepMap.stepsOrder.currentStepIndex = lastIndex * 1;
        }
      }
    };
    var stepNext = function stepNext(stepName) {
      return stepsLog(stepName, 1);
    };
    var stepBack = function stepBack(stepName, nextStepName) {
      return stepsLog(stepName, -1, nextStepName);
    };
    switch (action.type) {
      case _stepsActions.NEXT_STEP:
        {
          stepNext(state.currentStep);
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            currentStep: _stepMap.stepsOrder[state.currentStep].next(action.payload.budgetConfig)
          });
        }
      case _stepsActions.PREV_STEP:
        {
          stepBack(state.currentStep, _stepMap.stepsOrder[state.currentStep].prev(action.payload.budgetConfig));
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            currentStep: _stepMap.stepsOrder[state.currentStep].prev(action.payload.budgetConfig)
          });
        }
      case _stepsActions.ACC_CAT_SKIP:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            accountCategoryErrorSkip: action.payload
          });
        }
      case _stepsActions.CLEAR_STEP_DEPENDENCIES:
        {
          var stepDependencies = action.payload.stepDependencies;
          var updatedProperties = (0, _objectSpread2.default)({}, state);
          if (stepDependencies && stepDependencies.length) {
            stepDependencies.forEach(function (dep) {
              if (_.has(initialState, dep)) {
                _.set(updatedProperties, dep, _.get(initialState, dep));
              }
            });
          }
          Object.keys(_stepMap.stepsOrder.stepIndexTracker).forEach(function (index) {
            if (index > _stepMap.stepsOrder.currentStepIndex) {
              _stepMap.stepsOrder.stepIndexTracker[index] = null;
            }
          });
          return (0, _objectSpread2.default)({}, updatedProperties);
        }
      default:
        return state;
    }
  }
  var _default = _exports.default = stepsReducer;
});