define("budgeta/templates/components/dropdown-with-sub-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BZ/ZbzEm",
    "block": "{\"statements\":[[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"dynamic-attr\",\"id\",[\"concat\",[[\"unknown\",[\"elementIdDropdown\"]]]]],[\"static-attr\",\"class\",\"centerIcon action-popup\"],[\"flush-element\"],[\"yield\",\"default\"],[\"close-element\"],[\"text\",\"\\n\\n\"],[\"block\",[\"drop-down\"],null,[[\"for\",\"classNames\",\"qtipClasses\",\"isOpen\"],[[\"get\",[\"elementIdDropdown\"]],\"drop-down no-padding\",\"popup-menu\",[\"get\",[\"isOpen\"]]]],2]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"          \"],[\"append\",[\"helper\",[\"help-iq\"],null,[[\"docId\",\"type\",\"classNames\"],[[\"get\",[\"item\",\"tipId\"]],\"tip\",\"centerVElement without-margin-top\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"\\n      \"],[\"open-element\",\"li\",[]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"selectDropdown\",[\"get\",[\"item\"]]]],[\"flush-element\"],[\"text\",\"\\n        \"],[\"open-element\",\"a\",[]],[\"dynamic-attr\",\"title\",[\"helper\",[\"if\"],[[\"get\",[\"item\",\"altName\"]],[\"get\",[\"item\",\"altName\"]],[\"get\",[\"item\",\"name\"]]],null],null],[\"flush-element\"],[\"append\",[\"helper\",[\"if\"],[[\"get\",[\"item\",\"altName\"]],[\"get\",[\"item\",\"altName\"]],[\"get\",[\"item\",\"name\"]]],null],false],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"item\",\"tipId\"]]],null,0],[\"text\",\"      \"],[\"close-element\"],[\"text\",\"\\n\\n\"]],\"locals\":[\"item\"]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"ul\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"budgeta-dropdown-list \",[\"helper\",[\"if\"],[[\"get\",[\"helpIq\"]],\"with-help-iq\"],null]]]],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"filteredContent\"]]],null,1],[\"text\",\"  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/dropdown-with-sub-values.hbs"
    }
  });
});