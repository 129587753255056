define("budgeta/helpers/highlight", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "budgeta/utils/const"], function (_exports, _slicedToArray2, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.highlight = highlight;
  var bold = function bold(text, term) {
    term = _const.default.escapeRegExp(term);
    var regexValue = new RegExp("(".concat(term, ")"), "i");
    return text.replace(regexValue, "<span class='term-highlight'>$1</span>");
  };
  function highlight(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      name = _ref2[0],
      term = _ref2[1];
    if (!term) {
      return name;
    }
    var el = document.createElement("span");
    el.innerHTML = bold(name, term);
    return el;
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return highlight(params);
  });
});