define("budgeta/components/attribute-modifier", ["exports", "budgeta/utils/const", "budgeta/mixins/formula-editor-component", "budgeta/utils/formula-helper"], function (_exports, _const, _formulaEditorComponent, _formulaHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function moveCaretToEnd(el) {
    if (typeof el.selectionStart === "number") {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange !== "undefined") {
      el.focus();
      var range = el.createTextRange();
      range.collapse(false);
      range.select();
    }
  }
  var _default = _exports.default = Ember.Component.extend(_formulaEditorComponent.default, {
    attributeBindings: ["data-key", "data-order", "data-hide", "data-type-ref", "data-alt-labels", "data-original-label", "data-alt-tips", "title"],
    "data-key": Ember.computed.alias("key"),
    trueValue: true,
    falseValue: undefined,
    title: Ember.computed("previewContent", {
      get: function get() {
        return this.get("previewContent") || this.get("initialTitle");
      },
      set: function set(key, value) {
        this.set("initialTitle", value);
        return value;
      }
    }),
    disableTitle: Ember.computed("formulaObject.references", "budget.isModelLine", function () {
      if (this.get("budget.isModelLine")) {
        var formulaReferences = this.get("formulaObject.references") || {};
        for (var ref in formulaReferences) {
          if (ref[0] === "M") {
            return true;
          }
        }
      }
      return false;
    }),
    forceFormulaPreview: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set("elementId", "attribute-".concat(this.get("key"), "-").concat(this.get("elementId")));

      // convert old growth format to new format
      if (!this.get("percentOnly") && this.get("key") === "growth" && this.get("isFixed") === undefined && this.get("value")) {
        this.set("value", this.get("value") / 100);
        this.set("isFixed", null);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get("isFixed") === undefined && this.get("undefinedValue") !== undefined) {
        var newValue = null;
        if (this.get("undefinedValue") === "false") {
          newValue = this.get("falseValue") === undefined ? false : this.get("falseValue");
        } else if (this.get("undefinedValue") === "true") {
          newValue = this.get("trueValue");
        } else if (this.get("undefinedValue") !== undefined) {
          newValue = this.get("undefinedValue");
        }
        this.set("isFixed", this.get("typeOptions").findBy("id", newValue) ? newValue : this.get("typeOptions.firstObject.id"));
      }
    },
    didUpdate: function didUpdate() {
      if (this.$() && this.$().hasClass("attribute-group-hidden") && !this.$().data("attribute-group-hidden")) {
        this.$().removeClass("attribute-group-hidden");
      }
      if (this.$() && !this.$().hasClass("attribute-group-hidden") && this.$().data("attribute-group-hidden")) {
        this.$().addClass("attribute-group-hidden");
      }
    },
    previewContent: Ember.computed("formattedValue", "cleanFormulaValue", "disableTitle", function () {
      if (this.get("isFormula")) {
        if (this.get("disableTitle")) {
          return "";
        }
        return this.get("cleanFormulaValue");
      }
      return this.get("formattedValue") !== null ? this.get("formattedValue") : "";
    }),
    tips: Ember.computed("tipId", "data-alt-tips", function () {
      var tips = [];
      if (this.get("tipId")) {
        tips.pushObject({
          key: "",
          id: this.get("tipId")
        });
      }
      if (this.get("data-alt-tips")) {
        var altTips = JSON.parse(this.get("data-alt-tips"));
        if (altTips.tips) {
          for (var k in altTips.tips) {
            tips.pushObject({
              key: k,
              id: altTips.tips[k]
            });
          }
        }
      }
      return tips;
    }),
    store: Ember.inject.service(),
    currencySelectionEnabled: Ember.computed("amountType", "readOnly", "disableCurrencySelect", "value", function () {
      return this.get("value") && !this.get("disableCurrencySelect") && !this.get("readOnly") && this.get("amountType") !== 3; // Model line with type "Beginning month value from"
    }),
    currencySelectionDisabled: Ember.computed.not("currencySelectionEnabled"),
    typeOptions: Ember.computed("currencySymbol", "currencyOptions.length", function () {
      var result;
      if (this.get("allowNumbers")) {
        result = (this.get("hasBudgetLineRefs") ? [] : [{
          text: "%",
          id: "%"
        }, {
          text: "#",
          id: "#"
        }, {
          text: "#.##",
          id: "."
        }]).concat(this.getWithDefault("currencyOptions", []).filter(function (v) {
          return v.id !== "auto";
        }).map(function (c) {
          return {
            id: c.id,
            text: c.id
          };
        }));
      } else {
        var falseValue = this.get("falseValue") === undefined ? null : this.get("falseValue");
        result = [{
          name: "%",
          value: falseValue,
          id: falseValue
        }, {
          name: this.get("currencySymbol"),
          value: this.get("trueValue"),
          id: this.get("trueValue")
        }];
      }
      return result;
    }),
    showFormulaPreview: Ember.computed("isFormula", "forceFormulaPreview", function () {
      return this.get("isFormula") || this.get("forceFormulaPreview");
    }),
    currencySymbol: Ember.computed("currency", "inheritedCurrency", function () {
      return _const.default.currency_symbols[this.get("currency") || this.get("inheritedCurrency")] || "#";
    }),
    classNameBindings: [":modifier", "isPercent:percent"],
    percentOnly: false,
    oldMode: undefined,
    budgetObserver: Ember.observer("budget", function () {
      this.set("oldMode", undefined);
    }),
    changedMade: Ember.computed("isFormula", "isPercent", "oldMode", "budget", {
      get: function get() {
        if (this.get("oldMode") === undefined) {
          if (this.get("isPercent")) {
            this.set("oldMode", "isPercent");
          } else {
            this.set("oldMode", "isFixed");
          }
        }
        if (this.get("oldMode") === "isPercent" && this.get("isFixed") && this.get("isFixed") !== "%") {
          this.set("oldMode", "isFixed");
          return true;
        }
        if (this.get("oldMode") === "isFixed" && this.get("isPercent")) {
          this.set("oldMode", "isPercent");
          return true;
        }
        return false;
      },
      set: function set(key, value) {
        if (this.get("isPercent")) {
          this.set("oldMode", "isPercent");
        } else {
          this.set("oldMode", "isFixed");
        }
        return value;
      }
    }),
    isPercent: Ember.computed("percentOnly", "isFixed", function () {
      return this.get("percentOnly") || !this.get("isFixed") || this.get("isFixed") === "%";
    }),
    formattedPlaceholder: Ember.computed("placeholder", "disablePlacholderCurrency", "parent.budgetAttributesWithScenarios.bonusIsFixed", "percentOnly", "inheritedCurrency", function () {
      if (this.get("placeholder") === null) {
        return "";
      }
      if (this.get("placeholder") && !isNaN(this.get("placeholder") * 1)) {
        if (this.get("disablePlacholderCurrency")) {
          return this.get("placeholder");
        } else if (!this.budget.get("parent.budgetAttributesWithScenarios.bonusIsFixed") || this.get("percentOnly")) {
          return (this.get("noRound") ? this.get("placeholder") : _const.default.round(this.get("placeholder"))) * 100 + "%";
        } else if (this.get("inheritedCurrency")) {
          return _const.default.formatCurrency(this.get("placeholder"), this.get("inheritedCurrency"));
        }
      }
      var result = this.get("placeholder");
      if (result && typeof result === "string") {
        if (_formulaHelper.default.isFormulaString(result)) {
          return this.get("cleanPlaceholder");
        }
        return result;
      }
      return result;
    }),
    keyDown: function keyDown(event) {
      if (event.key === "=" && this.get("value")) {
        // only allow to enter equal sign when field is empty
        return false;
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var el = this.$("input");
      Ember.run.schedule("afterRender", this, function () {
        if (el) {
          el.on("keyup", function () {
            moveCaretToEnd(this);
          });
        }
      });
    },
    changedMadeObserver: Ember.observer("isFixed", function () {
      if (this.get("value") && this.get("isPercent")) {
        if (this.get("changedMade")) {
          this.set("value", this.get("value") / 100);
          this.set("changedMade", false);
        }
      } else if (this.get("value") && this.get("isFixed")) {
        if (this.get("changedMade")) {
          this.set("value", this.get("value") * 100);
          this.set("changedMade", false);
        }
      }
    }),
    formattedValue: Ember.computed("value", "cleanFormula", "isFormula", "isPercent", {
      get: function get() {
        if (this.get("isFormula")) {
          return this.get("cleanFormula");
        }
        if (Ember.isEmpty(this.get("value"))) {
          return null;
        }
        if (this.get("value") && this.get("isPercent")) {
          var thisValue = _const.default.round(100 * this.get("value"), 6);
          if (!this.get("noRound")) {
            thisValue = thisValue.toFixed(2);
          }
          return _const.default.formatStringNumber(thisValue * 1);
        } else if (this.get("value") && this.get("isFixed")) {
          return this.get("allowNumbers") && this.get("isFixed") && this.get("isFixed").length === 1 ? _const.default.formatStringNumber(this.get("value")) : _const.default.formatCurrency(this.get("value"), this.get("currency") === "%" ? "" : this.getCurrencyValue(), undefined, true);
        }
      },
      set: function set(key, value) {
        if (typeof value === "string" && value.substr(0, 1) === "=") {
          this.set("value", value);
        } else {
          value = value.trim();
          if (value.substr(value.length - 1) === "%") {
            value = value.substr(0, value.length - 1);
            this.set("isFixed", this.get("allowNumbers") ? "%" : null);
          }
          var num = value.replace(/[^\-\.0-9km]/gi, "");
          if (num && num.match(/k/i)) {
            value = num.replace(/k/i, "") * 1000;
          } else if (num && num.match(/m/i)) {
            value = num.replace(/m/i, "") * 1000000;
          }
          value = _const.default.formatStringNumber(value);
          if (Ember.isEmpty(value)) {
            this.set("value", null);
            return null;
          }
          var realValue = value.replace(/,/g, "") * 1;
          if (this.get("isPercent")) {
            this.set("value", isNaN(realValue / 100) ? 0 : _const.default.round(realValue, 5) / 100);
          } else {
            this.set("value", isNaN(realValue) ? null : _const.default.round(realValue));
          }
        }
        if (this.get("isFormula")) {
          return this.get("value");
        }
        var finalPctValue = this.get("noRound") ? _const.default.round(100 * this.get("value"), 6) : (100 * this.get("value")).toFixed(2) * 1;
        return this.get("value") && this.get("isPercent") ? _const.default.formatStringNumber(finalPctValue) : value;
      }
    })
  });
});