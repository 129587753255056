define("budgeta/store/reducers/budgetReducer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/store/actions/budgetActions", "lodash/lodash"], function (_exports, _toConsumableArray2, _objectSpread2, _budgetActions, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-prototype-builtins */

  var initialState = {
    name: "",
    mainCurrency: "",
    budgetRootId: null,
    budgetRootType: "",
    startDate: null,
    endDate: null,
    fiscalYearStartMonth: "",
    headerLineNumber: null,
    nameColumn: null,
    columnMapping: null,
    validColumnMappings: null,
    dimensionUniqueValuesCounter: null,
    format: null,
    stepDimensionsMapping: null,
    currencyColumnKey: null,
    accountColumnKey: null,
    skipDimensionStructure: null,
    skipBudgetStructure: null,
    skipBudgetStructureModify: null,
    selectedLines: [],
    suggestedDimensions: null,
    selectedDimensions: null,
    isDimensionMapped: false,
    notAllowedLinesDetails: {
      notAllowedCategory: {},
      activeStep: ""
    },
    categoryMapping: null,
    nameColumnIsAccount: false,
    dimensionHierarchy: {},
    budgetTree: [],
    currenciesMapping: null,
    //should we add this to json?
    showPreviewStepWalkthrough: true,
    fileCurrenciesToSIBP: null
  };
  function budgetReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case _budgetActions.SET_BUDGET_SETTINGS:
        {
          var _action$payload = action.payload,
            name = _action$payload.name,
            mainCurrency = _action$payload.mainCurrency,
            startDate = _action$payload.startDate,
            endDate = _action$payload.endDate,
            fiscalYearStartMonth = _action$payload.fiscalYearStartMonth,
            budgetRootType = _action$payload.budgetRootType;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            name: name,
            mainCurrency: mainCurrency,
            startDate: startDate,
            endDate: endDate,
            fiscalYearStartMonth: fiscalYearStartMonth,
            budgetRootType: budgetRootType
          });
        }
      case _budgetActions.SET_BUDGET_ROOT_ID:
        {
          var budgetRootId = action.payload.budgetRootId;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            budgetRootId: budgetRootId
          });
        }
      case _budgetActions.SET_HEADER_ROW_MAIN_COLUMN:
        {
          var _action$payload2 = action.payload,
            headerLineNumber = _action$payload2.headerLineNumber,
            nameColumn = _action$payload2.nameColumn,
            nameColumnIsAccount = _action$payload2.nameColumnIsAccount;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            headerLineNumber: headerLineNumber,
            nameColumn: nameColumn,
            nameColumnIsAccount: nameColumnIsAccount
          });
        }
      case _budgetActions.SET_BUDGET_COLUMN_MAPPING:
        {
          var columnMapping = action.payload.columnMapping;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            columnMapping: columnMapping
          });
        }
      case _budgetActions.SET_BUDGET_SELECTED_LINES:
        {
          var _action$payload3 = action.payload,
            selectedLines = _action$payload3.selectedLines,
            _columnMapping = _action$payload3.columnMapping,
            formattedFile = _action$payload3.formattedFile;
          var getTrimmedValue = function getTrimmedValue(data) {
            if (typeof data === "string") {
              return data.trim();
            }
            return data;
          };
          var getDimensionUniqueValuesCounter = function getDimensionUniqueValuesCounter() {
            var dimensionUniqueValuesCounter = {};
            _columnMapping && _columnMapping.forEach(function (mapping) {
              if (state.selectedDimensions && state.selectedDimensions.some(function (dim) {
                return dim.id === mapping.selectedAttribute;
              })) {
                var mappingKey = mapping.key.replace(/[0-9]/, "");
                var validValues = selectedLines.reduce(function (values, selectedLine) {
                  var line = formattedFile[selectedLine.lineNumber];
                  var value = line[mappingKey] && getTrimmedValue(line[mappingKey].v || line[mappingKey].w);
                  if (value && value !== "-") {
                    values.add(value);
                  }
                  return values;
                }, new Set());
                dimensionUniqueValuesCounter[mapping.selectedAttribute] = validValues.size;
              }
            });
            return dimensionUniqueValuesCounter;
          };
          var dimensionUniqueValuesCounter = getDimensionUniqueValuesCounter();
          var validColumnMappings = _columnMapping.filter(function (mapping) {
            return !dimensionUniqueValuesCounter.hasOwnProperty(mapping.selectedAttribute) || dimensionUniqueValuesCounter[mapping.selectedAttribute] > 0;
          });
          var selectedDimensions = state.selectedDimensions && (0, _toConsumableArray2.default)(state.selectedDimensions).map(function (dim) {
            var mapping = validColumnMappings.find(function (col) {
              return col.selectedAttribute === dim.id;
            });
            if (mapping) {
              dim.key = mapping.key;
            } else {
              dim.key = null;
            }
            return dim;
          });
          var isDimensionMapped = selectedDimensions && selectedDimensions.length && selectedDimensions.some(function (dimension) {
            if (!dimension.key) return false;
            var key = dimension.key.replace(/[0-9]/, "");
            return Object.values(formattedFile).some(function (line) {
              var getTrimmedValue = function getTrimmedValue(data) {
                if (typeof data === "string") {
                  return data.trim();
                }
                return data;
              };
              var value = line[key] && getTrimmedValue(line[key].v || line[key].w);
              return value && value !== "-";
            });
          });
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedLines: selectedLines,
            validColumnMappings: validColumnMappings,
            dimensionUniqueValuesCounter: dimensionUniqueValuesCounter,
            selectedDimensions: selectedDimensions,
            isDimensionMapped: isDimensionMapped
          });
        }
      case _budgetActions.SET_DATA_FORMAT:
        {
          var format = action.payload.format;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            format: format
          });
        }
      case _budgetActions.SET_DIMENSIONS_MAPPING_STEP:
        {
          var stepDimensionsMapping = action.payload.stepDimensionsMapping;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            stepDimensionsMapping: stepDimensionsMapping
          });
        }
      case _budgetActions.SET_COLUMNS_KEYS:
        {
          var _action$payload4 = action.payload,
            currencyColumnKey = _action$payload4.currencyColumnKey,
            accountColumnKey = _action$payload4.accountColumnKey;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            currencyColumnKey: currencyColumnKey,
            accountColumnKey: accountColumnKey
          });
        }
      case _budgetActions.SET_DIMENSION_STRUCTURE_SKIP:
        {
          var skipDimensionStructure = action.payload.skipDimensionStructure;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            skipDimensionStructure: skipDimensionStructure
          });
        }
      case _budgetActions.SET_BUDGET_STRUCTURE_SKIP:
        {
          var skipBudgetStructure = action.payload.skipBudgetStructure;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            skipBudgetStructure: skipBudgetStructure
          });
        }
      case _budgetActions.SET_BUDGET_STRUCTURE_MODIFY_SKIP:
        {
          var skipBudgetStructureModify = action.payload.skipBudgetStructureModify;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            skipBudgetStructureModify: skipBudgetStructureModify
          });
        }
      case _budgetActions.SET_SELECTED_DIMENSIONS:
        {
          var _action$payload5 = action.payload,
            _selectedDimensions = _action$payload5.selectedDimensions,
            suggestedDimensions = _action$payload5.suggestedDimensions;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedDimensions: _selectedDimensions,
            suggestedDimensions: suggestedDimensions
          });
        }
      case _budgetActions.SET_NOT_ALLOWED_CATEGORY_LINES:
        {
          var notAllowedCategory = action.payload.notAllowedCategory;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            notAllowedLinesDetails: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state.notAllowedLinesDetails), {}, {
              notAllowedCategory: notAllowedCategory
            })
          });
        }
      case _budgetActions.SET_NOT_ALLOWED_CURRENCIES_LINES:
        {
          var notAllowedCurrencies = action.payload.notAllowedCurrencies;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            notAllowedLinesDetails: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state.notAllowedLinesDetails), {}, {
              notAllowedCurrencies: notAllowedCurrencies
            })
          });
        }
      case _budgetActions.SET_PREVIEW_ACTIVE_STEP:
        {
          var activeStep = action.payload.activeStep;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            notAllowedLinesDetails: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state.notAllowedLinesDetails), {}, {
              activeStep: activeStep
            })
          });
        }
      case _budgetActions.SET_CATEGORIES_MAPPING:
        {
          var categoryMapping = action.payload.categoryMapping;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            categoryMapping: categoryMapping
          });
        }
      case _budgetActions.SET_DIMENSION_HIERARCHY:
        {
          var dimensionHierarchy = action.payload.dimensionHierarchy;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            dimensionHierarchy: dimensionHierarchy
          });
        }
      case _budgetActions.SET_BUDGET_TREE:
        {
          var budgetTree = action.payload.budgetTree;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            budgetTree: budgetTree
          });
        }
      case _budgetActions.SET_CURRENCIES_MAPPING:
        {
          var _action$payload6 = action.payload,
            currenciesMapping = _action$payload6.currenciesMapping,
            fileCurrenciesToSIBP = _action$payload6.fileCurrenciesToSIBP;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            currenciesMapping: currenciesMapping,
            fileCurrenciesToSIBP: fileCurrenciesToSIBP
          });
        }
      case _budgetActions.SET_SHOULD_SHOW_PREVIEW_WALKTHROUGH:
        {
          var showPreviewStepWalkthrough = action.payload.showPreviewStepWalkthrough;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            showPreviewStepWalkthrough: showPreviewStepWalkthrough
          });
        }
      case _budgetActions.CLEAR_BUDGET_DEPENDENCIES:
        {
          var stepDependencies = action.payload.stepDependencies;
          var updatedProperties = (0, _objectSpread2.default)({}, state);
          if (stepDependencies && stepDependencies.length) {
            stepDependencies.forEach(function (dep) {
              if (_lodash.default.has(initialState, dep)) {
                if (_lodash.default.get(state, dep) && _lodash.default.get(state, dep).deleteRecord) {
                  _lodash.default.get(state, dep).deleteRecord();
                }
                _lodash.default.set(updatedProperties, dep, _lodash.default.get(initialState, dep));
              }
            });
          }
          return (0, _objectSpread2.default)({}, updatedProperties);
        }
      default:
        return state;
    }
  }
  var _default = _exports.default = budgetReducer;
});