define("budgeta/admin/admin-app/components/licenses/licenses", ["exports", "@sibp/ui-components", "react", "budgeta/services/ember-service-container", "budgeta/admin/admin-app/components/licenses/const"], function (_exports, _uiComponents, _react, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Licenses = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var Licenses = _exports.Licenses = function Licenses(_ref) {
    var _ref$licenses = _ref.licenses,
      licenses = _ref$licenses === void 0 ? [] : _ref$licenses,
      _onChange = _ref.onChange,
      total = _ref.total,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled;
    return _react.default.createElement("div", {
      className: "licenses-container"
    }, _react.default.createElement("div", {
      className: "licenses-total"
    }, _react.default.createElement("span", null, intl.t("admin_app.licenses.license-summarize-title")), _react.default.createElement("span", null, total)), licenses.map(function (license, index) {
      return _react.default.createElement("div", {
        key: index,
        className: "license"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Counter, {
        text: _const.LICENSE_TITLES[license.type.toUpperCase()],
        dataHook: license.type,
        counter: license.count,
        onChange: function onChange(e) {
          return _onChange({
            type: license.type,
            count: e
          });
        },
        disabled: disabled
      }));
    }));
  };
});