define("budgeta/components/department-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["wizard-question scrollable disable-more-btn"],
    departments: Ember.computed("data", "data2", function () {
      var result = [],
        allDeps = this.get("data2.departments") || this.get("data") || [];
      var addDep = function addDep(dep) {
        result.pushObject(dep);
        result.pushObjects(allDeps.filterBy("parentid", dep.departmentid));
      };
      allDeps.filterBy("parentid", "").forEach(addDep);
      return result;
    }),
    deleteSubtree: function deleteSubtree(dep) {
      var _this = this;
      Ember.set(dep, "deleted", true);
      this.get("departments").filterBy("parentid", dep.departmentid).forEach(function (child) {
        _this.deleteSubtree.apply(_this, [child]);
      });
    },
    actions: {
      deleteDataItem: function deleteDataItem(arg) {
        this.deleteSubtree(arg);
      }
    }
  });
});