define("budgeta/components/budgeta-video", ["exports", "budgeta/utils/videosUtil/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      // abilty to support hard coded tab
      var videos = this.get("playlist") || (0, _index.getVideosOfCurrentTab)();
      this.set("playlistMap", videos);
    },
    trackingEvents: Ember.inject.service(),
    playlistMap: [],
    classNames: ["budgeta-video"],
    willDestroyElement: function willDestroyElement() {
      this.set("didPause", false);
      this.send("ytPaused");
    },
    videoWidth: null,
    videoHeight: null,
    searchValue: "",
    customPlayerVars: {
      autoplay: 1,
      modestbranding: 0,
      rel: 0,
      controls: 1,
      showinfo: 0
    },
    videoIndex: 0,
    activeVideo: Ember.computed("youtubeId", {
      get: function get() {
        var activeVideo = this.get("playlistMap").objectAt(0);
        if (this.get("youtubeId")) {
          activeVideo = this.get("playlistMap").findBy("youtubeId", this.get("youtubeId")) || this.get("playlistMap").objectAt(0);
        }
        return activeVideo;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    nextVideo: Ember.computed("activeVideo", function () {
      var nextVideo;
      if (this.get("activeVideo")) {
        nextVideo = this.get("playlistMap").objectAt(this.get("playlistMap").indexOf(this.get("activeVideo")) + 1);
      }
      return nextVideo || this.get("playlistMap").objectAt(0);
    }),
    suggestionsList: Ember.computed("nextVideo", "activeVideo", "playlist", "searchValue", function () {
      var suggestionsList,
        activeVideoId = this.get("activeVideo.youtubeId"),
        nextVideoId = this.get("nextVideo.youtubeId"),
        searchRegExp = new RegExp(this.get("searchValue"), "gi");
      if (this.get("playlistMap") && activeVideoId && nextVideoId && this.get("searchValue") == "") {
        suggestionsList = this.get("playlistMap");
      } else {
        suggestionsList = (0, _index.getAllVideos)();
      }
      suggestionsList = suggestionsList.filter(function (video) {
        return video.youtubeId !== nextVideoId && video.youtubeId !== activeVideoId && video.title.search(searchRegExp) !== -1;
      });
      return Ember.isEmpty(suggestionsList) || suggestionsList.length === 0 ? undefined : suggestionsList;
    }),
    videoTime: function videoTime() {
      var emberYoutube = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var currentTime = Ember.get(emberYoutube, "currentTime");
      return moment.utc(moment.duration(currentTime || 0, "seconds").asMilliseconds()).format("HH:mm:ss");
    },
    didPause: false,
    actions: {
      playVideo: function playVideo(video) {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CLICK_VIDEO"), {
          video_name: Ember.get(video, "title"),
          from_tutorial: this.get("fromTutorial")
        }, {
          video_name: Ember.get(video, "title"),
          from_tutorial: this.get("fromTutorial")
        });
        this.set("didPause", false);
        this.set("activeVideo", video);
      },
      ytPlaying: function ytPlaying() {
        if (this.get("didPause")) {
          var activeVideo = this.get("activeVideo");
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.PLAY_VIDEO"), {
            play_video_id: Ember.get(activeVideo, "youtubeId"),
            play_video_title: Ember.get(activeVideo, "title"),
            video_time: this.get("videoTime")(this.get("emberYoutube"))
          });
        }
        this.set("didPause", false);
      },
      ytPaused: function ytPaused() {
        var activeVideo = this.get("activeVideo");
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.PAUSE_VIDEO"), {
          play_video_id: Ember.get(activeVideo, "youtubeId"),
          play_video_title: Ember.get(activeVideo, "title"),
          video_time: this.get("videoTime")(this.get("emberYoutube"))
        });
        this.set("didPause", true);
      },
      ytEnded: function ytEnded() {
        var activeVideo = this.get("activeVideo");
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.END_VIDEO"), {
          play_video_id: Ember.get(activeVideo, "youtubeId"),
          play_video_title: Ember.get(activeVideo, "title"),
          video_time: this.get("videoTime")(this.get("emberYoutube"))
        });
        // play next video
        if (this.get("nextVideo")) {
          this.send("playVideo", this.get("nextVideo"));
        }
      }
    }
  });
});