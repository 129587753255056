define("budgeta/templates/components/department-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zKcySMJR",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"data\",\"length\"]]],null,3,0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"t\"],[\"department-list.loading\"],null],true],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"          \"],[\"open-element\",\"li\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"flex name-add-delete \",[\"helper\",[\"if\"],[[\"get\",[\"department\",\"parentid\"]],\"indented\"],null]]]],[\"flush-element\"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"class\",\"value\",\"placeholder\",\"required\"],[\"prevent-submit\",[\"get\",[\"department\",\"title\"]],[\"helper\",[\"t\"],[\"placeholders.enter-name\"],null],\"true\"]]],false],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"name\",\"action\",\"actionArg\"],[\"delete\",\"delete\",\"deleteDataItem\",[\"get\",[\"department\"]]]]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"unless\"],[[\"get\",[\"department\",\"deleted\"]]],null,1]],\"locals\":[\"department\"]},{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"ul\",[]],[\"static-attr\",\"class\",\"sortable\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"departments\"]]],null,2],[\"text\",\"    \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/department-list.hbs"
    }
  });
});