define("budgeta/templates/components/attribute-month-year-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FpAoHUyB",
    "block": "{\"statements\":[[\"block\",[\"unless\"],[[\"get\",[\"args\",\"split\"]]],null,0],[\"text\",\"\\n\"],[\"append\",[\"helper\",[\"month-year-picker\"],null,[[\"changed\",\"readOnly\",\"showTo\",\"isRange\",\"enableSingleMonth\",\"inGrid\",\"from\",\"fromLabel\",\"fromPlaceholder\",\"fromError\",\"showTo\",\"to\",\"toPlaceholder\",\"toError\",\"toLabel\",\"startDate\"],[\"changed\",[\"get\",[\"readOnly\"]],[\"get\",[\"displayData\",\"attached\"]],[\"get\",[\"displayData\",\"isRange\"]],[\"get\",[\"displayData\",\"isRange\"]],[\"get\",[\"inGrid\"]],[\"get\",[\"from\"]],[\"get\",[\"computedFromLabel\"]],[\"get\",[\"fromPlaceholder\"]],[\"get\",[\"error\"]],[\"get\",[\"showTo\"]],[\"get\",[\"to\"]],[\"get\",[\"toPlaceholder\"]],[\"get\",[\"toError\"]],[\"get\",[\"toLabel\"]],[\"get\",[\"budgetService\",\"selectedRoot\",\"budgetAttributes\",\"startDate\"]]]]],false],[\"text\",\"\\n\"],[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"error\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"error\"]],false],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"close-element\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/attribute-month-year-picker.hbs"
    }
  });
});