define("budgeta/components/attribute-month-year-picker", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    classNameBindings: [":budgeta-type-value", ":budgeta-type-datepicker", ":budgeta-type-datepicker-singel-label", "displayData.attached:budgeta-type-datepicker-start-end:budgeta-type-datepicker-singel"],
    attributeBindings: ["data-key", "data-hide", "data-default", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    "data-key": Ember.computed.alias("key"),
    displayData: Ember.computed("key", "budgetModule", function () {
      return this.get("budgetModule") && this.get("budgetModule.budgetAttributes.".concat(this.get("key")));
    }),
    showTo: Ember.computed.alias("displayData.attached"),
    from: Ember.computed("budgetAttributes", "key", "budgetAttributes.startDate", {
      get: function get() {
        return this.get("budgetAttributes.".concat(this.get("key")));
      },
      set: function set(key, value) {
        this.set("budgetAttributes.".concat(this.get("key")), value);
        return value;
      }
    }),
    computedFromLabel: Ember.computed("args.split", "label", function () {
      return this.get("args.split") ? this.get("label") : undefined;
    }),
    fromPlaceholder: Ember.computed("placeHolderAttributes", "key", function () {
      return this.get("placeHolderAttributes.".concat(this.get("key")));
    }),
    to: Ember.computed("budgetAttributes", "displayData.attached", "budgetAttributes.endDate", {
      get: function get() {
        return this.get("budgetAttributes.".concat(this.get("displayData.attached")));
      },
      set: function set(key, value) {
        this.set("budgetAttributes.".concat(this.get("displayData.attached")), value);
        return value;
      }
    }),
    toPlaceholder: Ember.computed("placeHolderAttributes", "displayData.attached", function () {
      return this.get("placeHolderAttributes.".concat(this.get("displayData.attached")));
    }),
    toError: Ember.computed("errors", "displayData.attached", function () {
      return this.get("errors.".concat(this.get("displayData.attached")));
    }),
    toLabel: Ember.computed("budgetModule", "displayData.attached", "args.split", function () {
      return this.get("args.split") ? this.get("budgetModule.budgetAttributes.".concat(this.get("displayData.attached"), ".label")) || _const.default.camelize(this.get("displayData.attached")) : "";
    }),
    init: function init() {
      this._super();
      this.set("elementId", "attribute-".concat(this.get("key"), "-").concat(this.get("elementId")));
    },
    actions: {
      changed: function changed() {
        this.sendAction("changed");
        if (this.$()) {
          this.$().trigger("change");
        }
      }
    }
  });
});