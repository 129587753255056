define("budgeta/admin/admin-app/modalContent", ["exports", "@sibp/ui-components", "react", "budgeta/services/ember-service-container"], function (_exports, _uiComponents, _react, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");

  // modals content

  var DISABLE_COMPANY_SUCCESSFULLY = function DISABLE_COMPANY_SUCCESSFULLY(companyName) {
    return {
      header: intl.t("admin_app.modals.disable-company.disabled-successfully"),
      bodyLine1: intl.t("admin_app.modals.disable-company.disabled-message", {
        companyName: companyName
      }),
      okButtonText: intl.t("admin_app.buttons.close")
    };
  };
  var DELETE_USER = function DELETE_USER(_ref) {
    var userName = _ref.userName,
      onOk = _ref.onOk,
      onCancel = _ref.onCancel;
    return {
      header: intl.t("admin_app.modals.delete-user.title"),
      bodyLine1: intl.t("admin_app.modals.delete-user.are-you-sure", {
        userName: userName
      }),
      bodyLine2: intl.t("admin_app.modals.delete-user.cannot-undone"),
      headerPrefixIcon: _react.default.createElement(_uiComponents.default.IMAGES.WarningSvg, null),
      cancelButtonText: intl.t("admin_app.modals.delete-user.keep"),
      okButtonText: intl.t("admin_app.modals.delete-user.delete-button"),
      onOk: onOk,
      onCancel: onCancel
    };
  };
  var DELETE_USER_SUCCESSFULLY = function DELETE_USER_SUCCESSFULLY(userName) {
    return {
      header: intl.t("admin_app.modals.delete-user.deleted-successfully"),
      bodyLine1: intl.t("admin_app.modals.delete-user.deleted-message", {
        userName: userName
      }),
      okButtonText: intl.t("admin_app.buttons.close")
    };
  };
  var REMOVE_COMPANY_OWNER = function REMOVE_COMPANY_OWNER(_ref2) {
    var onOk = _ref2.onOk;
    return {
      header: intl.t("admin_app.modals.remove-owner.title"),
      bodyLine1: intl.t("admin_app.modals.remove-owner.are-you-sure"),
      bodyLine2: intl.t("admin_app.modals.remove-owner.cannot-undone"),
      headerPrefixIcon: _react.default.createElement(_uiComponents.default.IMAGES.WarningSvg, null),
      cancelButtonText: intl.t("admin_app.modals.remove-owner.cancel-button"),
      okButtonText: intl.t("admin_app.modals.remove-owner.continue-button"),
      onOk: onOk
    };
  };
  var _default = _exports.default = {
    DISABLE_COMPANY_SUCCESSFULLY: DISABLE_COMPANY_SUCCESSFULLY,
    DELETE_USER: DELETE_USER,
    DELETE_USER_SUCCESSFULLY: DELETE_USER_SUCCESSFULLY,
    REMOVE_COMPANY_OWNER: REMOVE_COMPANY_OWNER
  };
});