define("budgeta/templates/admin-app-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "t/VV85D6",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"id\",\"admin-app-root\"],[\"flush-element\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/admin-app-route.hbs"
    }
  });
});