define("budgeta/components/range-month-picker", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/require-super-in-init, ember/closure-actions */

  var MonthNames = [intl.t("general.name_1695611601425"), intl.t("general.name_1695611601414"), intl.t("general.name_1695611601404"), intl.t("general.name_1695611601393"), intl.t("general.name_1695611601382"), intl.t("general.name_1695611601371"), intl.t("general.name_1695611601360"), intl.t("general.name_1695611601349"), intl.t("general.name_1695611601338"), intl.t("general.name_1695611601327"), intl.t("general.name_1695611601316"), intl.t("general.name_1695611601305")];
  var getMonthWithTypes = function getMonthWithTypes(momentValue, rangeYear) {
    // get month list by type
    var months = Ember.A([]),
      fromDate = this.get("fromMomentValue") ? _const.default.formatDate(this.get("fromMomentValue")) : undefined,
      toDate = this.get("toMomentValue") ? _const.default.formatDate(this.get("toMomentValue")) : undefined,
      minStartDate = this.get("minStartDate") ? _const.default.formatDate(this.get("minStartDate")) : undefined,
      maxEndDate = this.get("maxEndDate") ? _const.default.formatDate(this.get("maxEndDate")) : undefined,
      valueDate = momentValue ? _const.default.formatDate(momentValue) : undefined;
    for (var i = 0; i < 12; i++) {
      var thisMonth = _const.default.formatDate(new Date(rangeYear, i)),
        isStartMonth = valueDate === thisMonth,
        monthSelected = void 0,
        isEndMonth = void 0,
        isPastDataMonth = void 0,
        beforeMin = false,
        afterMax = false;
      if (momentValue) {
        if (!monthSelected && fromDate) {
          if (thisMonth >= fromDate && thisMonth < valueDate) {
            monthSelected = true;
          }
        }
        if (!monthSelected && toDate && (this.get("valueTo") || !(this.get("valueFrom") || this.get("valueTo")))) {
          if (thisMonth <= toDate && thisMonth > valueDate) {
            monthSelected = true;
          }
        }
        if ((this.get("valueTo") || !(this.get("valueFrom") || this.get("valueTo"))) && this.get("toMomentValue") && thisMonth === toDate) {
          isEndMonth = true;
        }
      }
      if (minStartDate && thisMonth < minStartDate) {
        beforeMin = true;
      }
      if (maxEndDate && maxEndDate < thisMonth) {
        afterMax = true;
      }
      if (this.get("enablePastDataRange") && !(beforeMin || afterMax) && this.get("startDate") && thisMonth < _const.default.formatDate(this.get("startDate"))) {
        isPastDataMonth = true;
      }
      months.pushObject({
        id: i,
        name: MonthNames[i],
        selected: monthSelected || isStartMonth || isEndMonth,
        isStartMonth: isStartMonth,
        isEndMonth: isEndMonth,
        isPastDataMonth: isPastDataMonth,
        disableMonth: beforeMin || afterMax
      });
    }
    return months;
  };
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["month-picker-control", "range-month-picker-control"],
    leftYear: null,
    rightYear: null,
    minStartDate: undefined,
    maxEndDate: undefined,
    budgetService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.valueChanged();
    },
    valueChanged: function valueChanged() {
      if (this.get("fromMomentValue")) {
        var fromYear = this.get("fromMomentValue").get("year");
        if (this.get("leftYear") === fromYear || this.get("rightYear") === fromYear) {
          return;
        }
      }
      var startYear = Ember.isEmpty(this.get("startDate")) ? new Date().getYear() + 1900 : _const.default.getMoment(this.get("startDate")).get("year");
      this.set("leftYear", this.get("fromMomentValue") ? this.get("fromMomentValue").get("year") : startYear);
      var endYear = this.get("fromMomentValue") && this.get("fromMomentValue").get("year") >= this.get("leftYear") + 1 ? this.get("fromMomentValue").get("year") : this.get("leftYear") + 1;
      this.set("rightYear", endYear);
    },
    fromMomentValue: Ember.computed("valueFrom", function () {
      return _const.default.getMoment(this.get("valueFrom")) || _const.default.getMoment(this.get("inheritedFromValue"));
    }),
    toMomentValue: Ember.computed("valueTo", function () {
      return _const.default.getMoment(this.get("valueTo")) || _const.default.getMoment(this.get("inheritedToValue"));
    }),
    leftMonths: Ember.computed("valueFrom", "valueTo", "leftYear", "from", "to", function () {
      var momentValue = this.get("fromMomentValue");
      return getMonthWithTypes.call(this, momentValue, this.get("leftYear"));
    }),
    rightMonths: Ember.computed("valueTo", "valueFrom", "rightYear", "from", "to", function () {
      var momentValue = this.get("fromMomentValue");
      return getMonthWithTypes.call(this, momentValue, this.get("rightYear"));
    }),
    isStartDatePickerSetter: true,
    setWhoOpensDatePicker: function setWhoOpensDatePicker() {
      // 'isDatePickerOpenedWithStartDate' is true if user opens the date picker when clicking on start date
      // or false if user opens the date picker when clicking on end date
      if (this.shouldChangeDatesBasedOnWhoOpensDatePicker()) {
        this.set("isStartDatePickerSetter", this.get("isDatePickerOpenedWithStartDate"));
        this.dontChangeDatesBasedOnWhoOpensDatePicker();
      }
    },
    shouldChangeDatesBasedOnWhoOpensDatePicker: function shouldChangeDatesBasedOnWhoOpensDatePicker() {
      return typeof this.get("isDatePickerOpenedWithStartDate") === "boolean";
    },
    dontChangeDatesBasedOnWhoOpensDatePicker: function dontChangeDatesBasedOnWhoOpensDatePicker() {
      this.set("isDatePickerOpenedWithStartDate", undefined);
    },
    actions: {
      next: function next() {
        this.setProperties({
          leftYear: this.get("leftYear") + 1,
          rightYear: this.get("rightYear") + 1
        });
      },
      prev: function prev() {
        this.setProperties({
          leftYear: this.get("leftYear") - 1,
          rightYear: this.get("rightYear") - 1
        });
      },
      select: function select(monthObj, isLeft) {
        this.setWhoOpensDatePicker();
        var month = monthObj.id;
        if (monthObj.disableMonth) {
          return;
        }
        var valueFrom = _const.default.getMoment(this.get("valueFrom") || this.get("inheritedFromValue"));
        if (valueFrom) {
          valueFrom = valueFrom.toDate().beginningOfDay();
        }
        var valueTo = _const.default.getMoment(this.get("valueTo") || this.get("inheritedToValue"));
        if (valueTo) {
          valueTo = valueTo.toDate().beginningOfDay();
        }
        var valueToOriginal = valueTo;
        var sideYear = isLeft ? this.get("leftYear") : this.get("rightYear");
        var newDate = _const.default.getMoment(Date.create(sideYear, month)).toDate().beginningOfDay();
        var isSelectingDateThatIsAfterEndDate = function isSelectingDateThatIsAfterEndDate() {
          return valueTo && newDate.isAfter(valueTo);
        };
        var isSelectingDateThatIsBeforeStartDate = function isSelectingDateThatIsBeforeStartDate() {
          return valueFrom && newDate.isBefore(valueFrom);
        };
        if (isSelectingDateThatIsAfterEndDate()) {
          this.setProperties({
            valueTo: newDate
          });
          if (this.$()) {
            this.$(".close-popup").click();
          }
          this.set("isStartDatePickerSetter", true);
        } else if (isSelectingDateThatIsBeforeStartDate()) {
          this.setProperties({
            valueFrom: newDate
          });
          if (this.$()) {
            this.$(".close-popup").click();
          }
          this.set("isStartDatePickerSetter", false);
        } else if (this.get("isStartDatePickerSetter")) {
          valueFrom = newDate;
          if (valueTo && valueFrom && (!valueTo.isAfter(valueFrom) || valueTo.getMonth() === valueFrom.getMonth() && valueFrom.getYear() === valueTo.getYear())) {
            valueTo = null;
          }
          this.setProperties({
            valueFrom: valueFrom,
            valueTo: valueTo
          });
          this.set("isStartDatePickerSetter", false);
        } else {
          valueTo = newDate;
          if (valueFrom && !this.get("enableSingleMonth") && valueFrom.isAfter(valueTo)) {
            valueTo = valueFrom;
            valueFrom = newDate;
            this.setProperties({
              valueFrom: valueFrom,
              valueTo: valueTo,
              isStartDatePickerSetter: false
            });
          } else if (valueFrom && this.get("enableSingleMonth") && valueFrom.isAfter(valueTo) && !(valueFrom.getDay() === valueTo.getDay() && valueFrom.getMonth() === valueTo.getMonth() && valueFrom.getFullYear() === valueTo.getFullYear())) {
            valueFrom = newDate;
            this.setProperties({
              valueFrom: valueFrom,
              valueTo: valueToOriginal,
              isStartDatePickerSetter: true
            });
          } else {
            this.setProperties({
              valueTo: newDate
            });
            if (this.$()) {
              this.$(".close-popup").click();
            }
            this.set("isStartDatePickerSetter", true);
          }
        }
        this.sendAction();
        this.sendAction("changed");
      }
    }
  });
});