define("budgeta/controllers/tour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    applicationController: Ember.inject.controller("application"),
    trackingEvents: Ember.inject.service(),
    currentPage: "tour-page-1",
    customPlayerVars: {
      autoplay: 1,
      modestbranding: 0,
      rel: 0,
      controls: 1,
      showinfo: 0
    },
    actions: {
      gotoPage: function gotoPage(page) {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.VIEW_TUTORIAL"), {
          screenName: page
        });
        this.set("currentPage", page);
      }
    }
  });
});