define("budgeta/services/view-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    actualsSummaryTypeSetByUser: null,
    inputsMainViewType: "pnl",
    sheetsViewType: "pnl",
    actualsSummaryViewType: "pnl"
  });
});