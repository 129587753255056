define("budgeta/components/application-component", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    automation: Ember.inject.service(),
    inIframeChanged: Ember.observer("controllertmp.inIframe", function () {
      var rootDiv = document.querySelector("body>.ember-view");
      if (rootDiv) {
        if (this.get("controllertmp.inIframe")) {
          rootDiv.classList.add("app-view-iframe");
        } else {
          rootDiv.classList.remove("app-view-iframe");
        }
      }
    }),
    showPerformanceLoggerChanged: Ember.observer("controllertmp.showPerformanceLogger", function () {
      var rootDiv = document.querySelector("body>.ember-view");
      if (rootDiv) {
        if (this.get("controllertmp.showPerformanceLogger")) {
          rootDiv.classList.add("add-performance-logger-container");
        } else {
          rootDiv.classList.remove("add-performance-logger-container");
        }
      }
    }),
    setLoginPageSpecialClass: Ember.observer("controllertmp.isLoginPage", function () {
      var rootDiv = document.querySelector("body>.ember-view");
      if (rootDiv) {
        if (this.get("controllertmp.isLoginPage")) {
          rootDiv.classList.add("login-container");
        } else {
          rootDiv.classList.remove("login-container");
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.inIframeChanged();
      this.showPerformanceLoggerChanged();
      this.setLoginPageSpecialClass();
      var rootDiv = document.querySelector("body>.ember-view");
      if (rootDiv) {
        rootDiv.classList.add("app-view");
      }
      var body = Ember.$("body");
      try {
        this.set("controllertmp.inIframe", window.self !== window.top);
      } catch (e) {
        this.set("controllertmp.inIframe", true);
      }
      var overlay = Ember.$('<div id="overlay" class="hidden"/>');
      overlay.appendTo("body");

      // add class if hidden scroll is detected
      var el = Ember.$('<div style="width:100px;height:100px;overflow:scroll;position:absolute;top:-9999px;"/>'),
        elDom = el.get(0);
      el.appendTo("body");
      if (elDom.offsetWidth === elDom.clientWidth) {
        body.addClass("hiddenscroll");
      }
      el.remove();
      Ember.run.schedule("afterRender", this, function () {
        Ember.$(".main-content .inner-content form .budgeta-type-value input, .main-content .inner-content form .budgeta-type-value textarea, .main-content form .user-input input").on("focus", function (e) {
          Ember.$(e.currentTarget).parents(".budgeta-type-value").addClass("budgeta-focus");
          Ember.$(e.currentTarget).parents(".user-input").addClass("budgeta-focus");
        });
        Ember.$(".main-content .inner-content form .budgeta-type-value input, .main-content .inner-content form .budgeta-type-value textarea, .main-content form .user-input input").on("focusout blur", function (e) {
          Ember.$(e.currentTarget).parents(".budgeta-type-value").removeClass("budgeta-focus");
          Ember.$(e.currentTarget).parents(".user-input").removeClass("budgeta-focus");
        });
        Ember.$(document).on("click", ".main-content .inner-content form .budgeta-type-value:not(.budgeta-checkbox) label, .main-content form .user-input label, .main-content form .comments .comment label, .modal-body .highlight-field label", function (e) {
          var nextInput = Ember.$(e.target).next("input");
          if (Ember.isEmpty(nextInput)) {
            nextInput = Ember.$(e.target).nextAll().find("input:first-child").first();
          }
          nextInput.click();
          nextInput.focus();
          if (Ember.isEmpty(nextInput)) {
            nextInput = Ember.$(e.target).next("div.select2-container");
            nextInput.select2("open");
          }
          if (Ember.isEmpty(nextInput)) {
            nextInput = Ember.$(e.target).nextAll().find("div.select2-container").first();
            nextInput.select2("open");
          }
          if (Ember.isEmpty(nextInput)) {
            nextInput = Ember.$(e.target).next("textarea");
            if (Ember.isEmpty(nextInput) && !Ember.isEmpty(Ember.$(e.target).next(".textarea-auto-height-wrapper"))) {
              nextInput = Ember.$(e.target).next(".textarea-auto-height-wrapper").find("textarea");
            }
            nextInput.click();
            nextInput.focus();
          }

          // we don't want to run the click twice in case that input was bounded to label
          var isInputBoundedToLabel = function isInputBoundedToLabel(input) {
            return e.target.tagName === "LABEL" && e.target.getAttribute("for") === input.attr("id");
          };
          var handlePrevInput = function handlePrevInput() {
            if (Ember.isEmpty(nextInput)) {
              var _prevInput = nextInput;
              nextInput = Ember.$(e.target).prev("input");
              if (isInputBoundedToLabel(nextInput)) {
                // do nothing and return the nextInput to be the previous one
                nextInput = _prevInput;
              } else {
                nextInput.click();
                nextInput.focus();
              }
            }
          };
          handlePrevInput();
          if (Ember.isEmpty(nextInput)) {
            nextInput = Ember.$(e.target).nextAll().find("span.select-2-lazy-inactive-label");
            nextInput.click();
            nextInput.focus();
          }
          handlePrevInput();
          if (Ember.$(e.target).closest(".modal-body").length) {
            if (Ember.isEmpty(nextInput)) {
              nextInput = Ember.$(e.target).nextAll().find("textarea.textarea-auto-height").first();
              nextInput.click();
              nextInput.focus();
            }
            if (Ember.isEmpty(nextInput)) {
              nextInput = Ember.$(e.target).nextAll().find("span.select-2-lazy-inactive-label");
              nextInput.click();
              nextInput.focus();
            }
            if (Ember.isEmpty(nextInput)) {
              nextInput = Ember.$(e.target).next("div.account-mapping-menu");
              nextInput.click();
              nextInput.focus();
            }
          }
        });
        Ember.$(".main-content .inner-content form .budgeta-type-value .select2-container").bind("DOMSubtreeModified", function (e) {
          if (Ember.$(e.currentTarget).hasClass("select2-container-active")) {
            Ember.$(e.currentTarget).parents(".budgeta-type-value").addClass("budgeta-focus");
          } else {
            Ember.$(e.currentTarget).parents(".budgeta-type-value").removeClass("budgeta-focus");
          }
        });
      });
      Ember.$(document).on("keyup", function (event) {
        if (event.target.tagName !== "INPUT") {
          var current = Ember.$("aside > ul.tree .budget-list-item.active");
          if (current.length) {
            var direction = null;
            if (event.which === 38) {
              direction = -1;
            } else if (event.which === 40) {
              direction = 1;
            }
            if (direction) {
              var list = Ember.$("aside > ul.tree").find(".budget-list-item");
              var curIdx = list.get().indexOf(current.get(0));
              var moveTo = list[curIdx + direction];
              if (moveTo) {
                Ember.$(moveTo).find(">.tree-element>.actions-toggle>a").click();
              }
            }
          }
        }
      });
      if (_const.default.SHOW_SNOW) {
        var snow = function snow() {
          var now = new Date().getTime();
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          var flakeCountDown = flakeCount - Math.floor(flakeCount * ((now - startTime) / (40 * 1000)));
          for (var i = 0; i < flakeCountDown; i++) {
            var flake = flakes[i],
              x = mX,
              y = mY,
              minDist = 150,
              x2 = flake.x,
              y2 = flake.y;
            var dist = Math.sqrt((x2 - x) * (x2 - x) + (y2 - y) * (y2 - y));
            // dx = x2 - x,
            // dy = y2 - y;
            if (dist < minDist) {
              var force = minDist / (dist * dist),
                xcomp = (x - x2) / dist,
                ycomp = (y - y2) / dist,
                deltaV = force / 2;
              flake.velX -= deltaV * xcomp;
              flake.velY -= deltaV * ycomp;
            } else {
              flake.velX *= 0.98;
              if (flake.velY <= flake.speed) {
                flake.velY = flake.speed;
              }
              flake.velX += Math.cos(flake.step += 0.05) * flake.stepSize;
            }
            ctx.fillStyle = "rgba(255,255,255," + flake.opacity + ")";
            flake.y += flake.velY;
            flake.x += flake.velX;
            if (flake.y >= canvas.height || flake.y <= 0) {
              reset(flake);
            }
            if (flake.x >= canvas.width || flake.x <= 0) {
              reset(flake);
            }
            ctx.beginPath();
            // ctx.drawImage(image, flake.x, flake.y);
            ctx.arc(flake.x, flake.y, flake.size, 0, Math.PI * 2);
            ctx.fill();
          }
          for (var _i = flakeCountDown - 1; _i < flakeCount; _i++) {
            var _flake = flakes[_i];
            if (_flake) {
              _flake.opacity = 0;
            }
          }
          if (now - startTime < 40 * 1000) {
            var nav = Ember.$(".budget-navigator-wrapper");
            if (nav) {
              nav.addClass("with-snow");
            }
            requestAnimationFrame(snow);
          } else {
            var _nav = Ember.$(".budget-navigator-wrapper");
            if (_nav) {
              _nav.removeClass("with-snow");
            }
            canvas.parentNode.removeChild(canvas);
          }
        };
        var reset = function reset(flake) {
          flake.x = Math.floor(Math.random() * canvas.width);
          flake.y = 0;
          flake.size = Math.random() * 3 + 2;
          flake.speed = Math.random() * 1 + 0.5;
          flake.velY = flake.speed;
          flake.velX = 0;
          flake.opacity = Math.random() * 0.5 + 0.03;
        };
        var init = function init() {
          for (var i = 0; i < flakeCount; i++) {
            var x = Math.floor(Math.random() * canvas.width),
              y = Math.floor(Math.random() * canvas.height),
              size = Math.random() * 3 + 2,
              speed = Math.random() * 1 + 0.5,
              opacity = Math.random() * 0.5 + 0.03;
            flakes.push({
              speed: speed,
              velY: speed,
              velX: 0,
              x: x,
              y: y,
              size: size,
              stepSize: Math.random() / 30,
              step: 0,
              opacity: opacity
            });
          }
          snow();
        };
        var flakes = [],
          canvas = document.getElementById("snow"),
          ctx,
          flakeCount = 50,
          mX = -100,
          mY = -100;
        var startTime = new Date().getTime();
        if (canvas && !this.get("automation.isAutomationMode")) {
          ctx = canvas.getContext("2d");
          canvas.width = Ember.$(".app-view").width();
          canvas.height = 48;
          if (this.get("controllertmp.session.isAuthenticated")) {
            canvas.height = 48 + 60;
          }
          window.addEventListener("resize", function () {
            canvas.width = Ember.$(".app-view").width();
          });
          try {
            init();
          } catch (e) {
            Ember.Logger.warn(e);
          }
        }
      }
    }
  });
});