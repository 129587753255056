define("budgeta/components/buttons-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ["isRadio:radio-buttons-group", "isCheckbox:checkbox-buttons-group"],
    actions: {
      selectLine: function selectLine(item) {
        this.get("group").setEach("checked", false);
        Ember.set(item, "checked", true);
      }
    }
  });
});