define("budgeta/templates/components/setup-guide-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "puDmB40K",
    "block": "{\"statements\":[[\"block\",[\"modal-dialog\"],null,[[\"close\",\"confirm\",\"next\",\"back\",\"title\",\"templateName\",\"hideFooter\",\"hideHeader\",\"showBack\",\"lastStep\",\"confirmActionName\",\"addClass\",\"backText\",\"cancelActionName\"],[[\"get\",[\"close\"]],[\"get\",[\"confirm\"]],[\"get\",[\"next\"]],[\"get\",[\"back\"]],[\"get\",[\"title\"]],[\"get\",[\"templateName\"]],[\"get\",[\"hideFooter\"]],[\"get\",[\"hideHeader\"]],[\"get\",[\"showBack\"]],[\"get\",[\"lastStep\"]],[\"get\",[\"confirmActionName\"]],[\"get\",[\"addClass\"]],[\"get\",[\"backText\"]],[\"get\",[\"cancelActionName\"]]]],0]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/setup-guide-modal.hbs"
    }
  });
});