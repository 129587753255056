define("budgeta/templates/components/rename-scenario", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/1MarZQp",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"rename-scenario.scenario-name\"],null],false],[\"close-element\"],[\"text\",\"\\n\"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"type\",\"required\",\"value\"],[\"text\",true,[\"get\",[\"model\",\"newScenarioName\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/rename-scenario.hbs"
    }
  });
});