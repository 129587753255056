define("budgeta/utils/automation", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "budgeta/utils/const", "budgeta/utils/forecast", "budgeta/utils/grid/grid-utils"], function (_exports, _toConsumableArray2, _const, _forecast, _gridUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Automation = {
    isAutomationMode: function isAutomationMode() {
      return localStorage.getItem("isAutomationMode");
    },
    getSheetsData: function getSheetsData(result) {
      return _forecast.default.flattenTree(result.root, result.forecast, _gridUtils.default.mapDataBy).reduce(Automation.reduceByBudgetIdAndValues, []);
    },
    isLeaf: function isLeaf(_ref) {
      var budgetId = _ref.budgetId,
        store = _ref.store;
      var budget = store.peekRecord("budget", budgetId);
      var isEmptyAmounts = budget && budget.get("isEmptyAmounts");
      var isLeafBudget = budget && !_const.default.isTopLevel(budget) && !budget.get("hasChildren") && !isEmptyAmounts && budget.get("budgetType") !== "group-payroll";
      return isLeafBudget;
    },
    // sum duplications values of same budgetId
    reduceByBudgetIdAndValues: function reduceByBudgetIdAndValues(acc, cur) {
      var matchedIndex = acc.findIndex(function (_ref2) {
        var budgetId = _ref2.budgetId;
        return budgetId === cur.budgetId;
      });
      if (matchedIndex >= 0) {
        acc[matchedIndex].values = Automation.sumObjects(acc[matchedIndex].values, cur.values);
        return acc;
      } else {
        return [].concat((0, _toConsumableArray2.default)(acc), [cur]);
      }
    },
    sumObjects: function sumObjects(obj1, obj2) {
      var sum = {};
      Object.keys(obj1).forEach(function (key) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
          sum[key] = obj1[key] + obj2[key];
        }
      });
      return sum;
    }
  };
  var _default = _exports.default = Automation;
});