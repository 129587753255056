define("budgeta/components/budget-tree-element-master", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TemplateEdit = {
    BUDGET_RENAME_SIMPLE: "edit-budget-tree-rename-simple",
    BUDGET_RENAME: "-rename",
    BUDGET_EDIT_READY: "-ready",
    BUDGET_EDIT_INCLUDE_TYPE: "-with-type"
  };
  var newState, moveBudgetsOrder, budgetTree;
  var runLater;
  moveBudgetsOrder = function moveBudgetsOrder(moveDirection, realBudget, destLine, _this) {
    var budgetId = realBudget.get("id"),
      destId = destLine.get("id"),
      destParentData = destLine && destLine.get("parent"),
      destParentId = destParentData && destParentData.get("id"),
      newOrder = [],
      destPosition,
      budgetPosition;
    if (!destParentId) {
      return;
    }
    newOrder = destParentData.get("children").mapBy("id");
    destPosition = newOrder.indexOf(destId);

    // if brothers
    if (realBudget.get("parent.id") === destParentId) {
      budgetPosition = newOrder.indexOf(budgetId);

      // move down
      if (moveDirection === -1) {
        if (destPosition > budgetPosition) {
          destPosition--;
        }
      } else if (moveDirection === 1) {
        if (destPosition < newOrder.length && destPosition < budgetPosition) {
          destPosition++;
        }
      }
      if (destParentData) {
        var childToMove = destParentData.get("children").objectAt(budgetPosition);
        destParentData.get("children").removeObject(childToMove);
        destParentData.get("children").insertAt(destPosition, childToMove);
        destParentData.save();
        _this.get("trackingEvents").trackEvent(_this.get("trackingEvents.events.MOVE_LINE"), {
          tag: "drag&drop",
          line_type: realBudget ? Ember.get(realBudget, "budgetType") : undefined,
          destination_id: destParentData ? destParentData.get("id") : undefined,
          destination_line_type: destParentData ? destParentData.get("budgetType") : undefined
        });
        _this.get("treeManager").recalcBudgetList();
      }
    } else {
      // move down
      if (moveDirection === 1) {
        destPosition++;
      }
      _this.send("move", realBudget, destParentData, destPosition, "drag&drop");
    }
  };
  var _default = _exports.default = Ember.Component.extend({
    tagName: "li",
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    collapsedBudgets: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    classNameBindings: [":budget-list-item", "budget.hasChildren:has-children", "searchFound:search-found:", "isCollapsed:collapsed:expanded", "budget.budgetType:has-budget-type", "budget.isScenarioAdded:scenario-added:", "budget.isScenarioRemoved:scenario-removed:", "budget.hasScenarioChanges:scenario-changed:", "budget.hasVersionChanges:scenario-changed:", "budget.isBudgetAddedForScenario:any-scenario-added:", "active:active:", "budget.hideFromTree:hide-from-tree", "budget.hasActiveChild:has-active-child:", "budget.selectedRoot:selected-root:", "budget.isEmptyGroup:empty-group:", "budget.hasModels:has-models:", "budget.isNoGrouping:no-grouping:", "editName:new-line:", "newEmpty:new-empty-line:", "budget.budgetModule.allowedChildTypes:droppable", "isTopLevel:is-top-level", "inEditMode:edit-mode", "importing:importing", "budget.chosen:chosen", "budget.isEmptyAmounts:empty-amounts", "budget.isNewLine:new-budget", "inSearchMode:in-search-mode:", "isDropAllowed:dropAllowed", "dropIn:dropIn", "moveUp:moveUp", "moveDown:moveDown", "treeManager.dragBudgetLine:drag-and-drop-mode", "moveNotAllowed:moveNotAllowed", "inDragMode:in-drag-mode"],
    attributeBindings: ["draggable", "data-level", "data-id", "data-type", "data-allowed-types", "data-hook", "data-parent", "data-children-num"],
    draggable: "true",
    isFirefox: navigator.userAgent.indexOf("Firefox") !== -1,
    throttleExpand: function throttleExpand() {
      this.send("collapse");
    },
    dragEnter: function dragEnter() {
      return true;
    },
    scroll: function scroll(container, step) {
      var _this2 = this;
      var scrollY = container.scrollTop;
      if (scrollY + step > 0 || container.scrollHeight - container.offsetHeight + step < scrollY) {
        container.scrollTop += step;
        if (!this.get("stopScroll")) {
          setTimeout(function () {
            return _this2.scroll(container, step);
          }, 20);
        }
      } else {
        this.set("stopScroll", true);
      }
    },
    dragOver: function dragOver() {
      this.set("treeManager.overBudgetLine", this.get("budget"));
      if (this.get("isCollapsed")) {
        runLater = Ember.run.throttle(this, "throttleExpand", 1000, false);
      }
      return false;
    },
    dragStart: function dragStart() {
      var _this3 = this;
      this.set("scrollContainer", Ember.$(".ember-list-view-list>div"));
      if (this.get("isFirefox")) {
        Ember.$(".ember-list-view-list>div").off("dragover").on("dragover", function (e) {
          Ember.run.debounce(function () {
            _this3.set("eventPageY", e.originalEvent.pageY);
          }, 50);
        });
      }
      if (this.$().closest(".budget-list").length === 0 && !this.get("budgetService.sharedReadOnly") && !this.get("version")) {
        if (this.get("inSearchMode") || this.get("treeEditMode") || this.get("viewSelections.isLeafDisplay")) {
          return false;
        }
        // disable dragging when there's an active scenario
        if (this.get("scenario")) {
          return false;
        }
        this.set("treeManager.dragBudgetLine", this.get("budget"));
        this.set("inDragMode", true);
        return true;
      }
    },
    drag: function drag(event) {
      if (this.get("inSearchMode")) {
        return;
      }
      var container = this.get("scrollContainer"),
        offset = container.offset(),
        containerHeight = container.height(),
        containerWidth = container.width();
      var containerView = container.get(0);
      this.set("stopScroll", true);

      // first check if we're over the container
      if (event.originalEvent.clientX >= offset.left && event.originalEvent.clientX <= offset.left + containerWidth) {
        if (event.originalEvent.clientY >= offset.top - 20 && event.originalEvent.clientY < offset.top + 40) {
          this.set("stopScroll", false);
          this.scroll(containerView, -1);
        }
        if (event.originalEvent.clientY <= offset.top + containerHeight + 20 && event.originalEvent.clientY > offset.top + containerHeight - 40) {
          this.set("stopScroll", false);
          this.scroll(containerView, 1);
        }
      }
      var overArea,
        newOverParent,
        newOverListItem,
        newOverParentId,
        splitUp = 0,
        splitDown = 0,
        overBudgetLine = this.get("treeManager.overBudgetLine"),
        dragBudgetLine = this.get("treeManager.dragBudgetLine");
      if (!dragBudgetLine || !overBudgetLine || overBudgetLine === this.get("budget")) {
        return;
      }
      var newOverElement = budgetTree.find("li[data-id=\"".concat(overBudgetLine.get("id"), "\"]"));
      if (this.get("treeManager.overBudgetLine") && newOverElement.length) {
        if (this.get("isFirefox")) {
          overArea = newOverElement.offset().top - this.get("eventPageY") + 25;
        } else {
          overArea = newOverElement.offset().top - event.originalEvent.clientY + 25;
        }
        newOverListItem = overBudgetLine;
        var newOverLine = overBudgetLine;
        if (!newOverLine) {
          return;
        }
        newOverParentId = newOverLine.get("parent.id");
        newOverParent = newOverListItem.get("parent");
        if (_const.default.isAncestor(dragBudgetLine, overBudgetLine)) {
          newState = undefined;
          return;
        }
        if (newOverListItem && (newOverParentId === dragBudgetLine.get("parent.id") ||
        // if brothers
        newOverParent && newOverParent.get("isDropAllowed") || newOverListItem.get("isDropAllowed"))) {
          // in different parent - if wrapper allowed drop and move
          if (newOverListItem.get("isDropAllowed")) {
            splitUp = 10;
            splitDown = -10;
          }
          if (overArea < -20 || overArea > 20) {
            newState = undefined;
            newOverListItem.set("dragType", undefined);
          } else if (overArea > splitDown && overArea < splitUp) {
            newState = 0;
            newOverListItem.set("dragType", "dropIn");
          } else if (!newOverListItem.get("selectedRoot") &&
          // missing
          newOverParent && newOverParent.get("isDropAllowed")) {
            // if the parent support dropAllow then the item support reorder
            if (overArea <= splitDown) {
              newState = 1;
              newOverListItem.set("dragType", "moveDown");
            } else if (overArea >= splitUp) {
              newState = -1;
              newOverListItem.set("dragType", "moveUp");
            }
          }
        } else {
          newState = undefined;
        }
      }
      return true;
    },
    dragEnd: function dragEnd() {
      this.set("stopScroll", true);
      if (this.get("inSearchMode")) {
        return false;
      }
      this.set("treeManager.dragBudgetLine", undefined);
      this.set("inDragMode", false);
      return true;
    },
    dragLeave: function dragLeave() {
      var destBudget = this.get("budget");
      destBudget.set("dragType", undefined);
      Ember.run.cancel(runLater);
      return true;
    },
    drop: function drop() {
      Ember.run.cancel(runLater);
      var dest, budgetId, realBudget, destId;
      dest = this.get("budget");
      realBudget = this.get("treeManager.dragBudgetLine");
      this.setProperties({
        "treeManager.overBudgetLine.dragType": undefined,
        "treeManager.overBudgetLine": undefined,
        "treeManager.dragBudgetLine": undefined
      });
      if (!dest || !realBudget) {
        return;
      }
      budgetId = realBudget.get("id");
      destId = dest.get("id");
      if (typeof newState !== "undefined" && budgetId) {
        if (newState === 1) {
          moveBudgetsOrder(newState, realBudget, dest, this);
          this.send("goto", realBudget, undefined, undefined, undefined, true);
        } else if (newState === -1) {
          moveBudgetsOrder(newState, realBudget, dest, this);
          this.send("goto", realBudget, undefined, undefined, undefined, true);
        } else if (newState === 0 && realBudget.get("parent.id") !== destId) {
          // if drop not to parent
          var parent = realBudget.get("parent");
          while (parent) {
            this.get("cache").clearBudgetCache(parent, undefined, undefined, {
              noChildren: true,
              noRefs: true,
              noDependecyChanges: true
            });
            parent = parent.get("parent");
          }
          this.get("cache").clearBudgetCache(dest, undefined, undefined, {
            noChildren: true,
            noRefs: true,
            noDependecyChanges: true
          });
          this.send("move", realBudget, dest, undefined, "drag&drop");
          this.send("goto", realBudget, undefined, undefined, undefined, true);
          return true;
        }
      }
    },
    "data-hook": Ember.computed("budget.name", "budget.budgetAttributes.role", function () {
      return this.get("budget.name") || this.get("budget.budgetAttributes.role");
    }),
    "data-children-num": Ember.computed.alias("childrenNum"),
    "data-level": Ember.computed("level", function () {
      return this.get("level");
    }),
    "data-id": Ember.computed("budget.id", function () {
      return this.get("budget.id");
    }),
    "data-parent": Ember.computed.alias("budget.parent.id"),
    "data-type": Ember.computed("budget.budgetType", function () {
      return this.get("budget.budgetType");
    }),
    isTopLevel: Ember.computed("budget.id", function () {
      return _const.default.isTopLevel(this.get("budget"));
    }),
    "data-allowed-types": Ember.computed.alias("budget.budgetModule.allowedChildTypes"),
    isDropAllowed: Ember.computed.alias("budget.isDropAllowed"),
    dropIn: Ember.computed("treeManager.dragBudgetLine", "treeManager.overBudgetLine", "budget.id", "budget.dragType", function () {
      return this.get("treeManager.dragBudgetLine") && this.get("budget.id") === this.get("treeManager.overBudgetLine.id") && this.get("budget.dragType") === "dropIn";
    }),
    moveUp: Ember.computed("treeManager.dragBudgetLine", "treeManager.overBudgetLine", "budget.id", "budget.dragType", function () {
      return this.get("treeManager.dragBudgetLine") && this.get("budget.id") === this.get("treeManager.overBudgetLine.id") && this.get("budget.dragType") === "moveUp";
    }),
    moveDown: Ember.computed("treeManager.dragBudgetLine", "treeManager.overBudgetLine", "budget.id", "budget.dragType", function () {
      return this.get("treeManager.dragBudgetLine") && this.get("budget.id") === this.get("treeManager.overBudgetLine.id") && this.get("budget.dragType") === "moveDown";
    }),
    moveNotAllowed: Ember.computed("treeManager.dragBudgetLine", "budget.selectedRoot", function () {
      return this.get("treeManager.dragBudgetLine") && this.get("budget.selectedRoot");
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce("afterRender", this, function () {
        if (this.$()) {
          budgetTree = Ember.$(".main-content-overflow .tree");
          if (this.$() && this.get("budget.focusAfterRender")) {
            this.$('input[name="name"]').focus();
            this.set("budget.focusAfterRender", false);
          }
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    inEditMode: Ember.computed("budget.inheritedReadOnly", "budget.allowChildren", "budget.hasNewChildren", function () {
      return !this.get("budget.inheritedReadOnly") && this.get("budget.allowChildren") && this.get("budget.hasNewChildren");
    }),
    displayName: Ember.computed("budget.name", "budget.budgetAttributes.role", function () {
      return this.get("budget.name") || this.get("budget.budgetAttributes.role");
    }),
    editName: Ember.computed("budget.renameMode", "budget.isNew", function () {
      return this.get("budget.renameMode") || this.get("budget.isNew");
    }),
    newEmpty: Ember.computed("budget.isNew", function () {
      return this.get("budget.isNew") && Ember.isEmpty(this.get("budget.name"));
    }),
    isCollapsed: Ember.computed("collapsedBudgets.collapsed_tree_map", "budget", "searchFilter", "inSearchMode", "budget.recalcCollapse", {
      get: function get() {
        if (this.get("inSearchMode")) {
          var budgetEle = this.get("treeManager.backupBudgetsListSearchMode").findBy("budget.id", this.get("budget.id"));
          if (budgetEle) {
            return Ember.get(budgetEle, "isCollapsed");
          }
          return this.get("searchFilter");
        }
        return this.get("collapsedBudgets").getTreeItem(this.get("budget")) ? true : false;
      },
      set: function set(key, value) {
        if (this.get("inSearchMode")) {
          var budgetEle = this.get("treeManager.backupBudgetsListSearchMode").findBy("budget.id", this.get("budget.id"));
          if (budgetEle) {
            Ember.set(budgetEle, "isCollapsed", value);
          }
        }
        return value;
      }
    }),
    showTypeOptions: Ember.computed("budget.chosen", "budget.budgetModule.typeGroup", "budget.templateOptions", "budget.children.length", function () {
      return !this.get("budget.children.length") && (this.get("budget.budgetModule.typeGroup") || this.get("budget.templateOptions.length"));
    }),
    enableAdd: Ember.computed("budget.name", "budget.budgetType", "modulesForGroup.length", "showTypeOptions", function () {
      if (this.get("budget.budgetType") && this.get("budget.name")) {
        if (this.get("showTypeOptions")) {
          return (this.get("modulesForGroup") || Ember.A([])).findBy("id", this.get("budget.budgetType"));
        }
        return true;
      }
      return false;
    }),
    active: Ember.computed("selectedBudget", "budget", "budget.chosen", function () {
      return this.get("selectedBudget") && this.get("selectedBudget") === this.get("budget") || this.get("budget.isNew") && this.get("budget.chosen");
    }),
    modulesForGroup: Ember.computed("budget.budgetModule", "budget.hasChildren", function () {
      var _this = this,
        modules = this.get("budgetTypes"),
        tempModule;
      if (this.get("budget.templateOptions")) {
        return this.get("budget.templateOptions").map(function (item) {
          item = Ember.Object.create(item);
          tempModule = modules.findBy("id", item.get("id"));
          if (item.get("id") && tempModule) {
            item.set("tipId", Ember.get(tempModule, "tipId"));
          }
          return item;
        });
      }
      modules = modules.filterBy("typeGroup", this.get("budget.budgetModule.typeGroup")).filter(function (t) {
        return !t.get("abstract") && !t.get("deprecated");
      }).sort(function (a, b) {
        return a.get("typeGroupOrder") - b.get("typeGroupOrder");
      });
      if (!this.get("budget.hasChildren") && this.get("budget.budgetModule.interchange.length")) {
        this.get("budget.budgetModule.interchange").forEach(function (m) {
          modules.push(_this.get("budgetTypes").findBy("id", m));
        });
      }
      return modules;
    }),
    editComponent: Ember.computed("budget.renameMode", "treeEditMode", "showTypeOptions", function () {
      /*
      options-list
      ====================
      edit-budget-tree-rename-simple
      edit-budget-tree-with-type
      edit-budget-tree
       edit-budget-tree-with-type-rename
      edit-budget-tree-rename
      */
      var editComponent = "edit-budget-tree";
      if (!this.get("editName")) {
        return undefined;
      }
      if (this.get("showTypeOptions")) {
        editComponent += TemplateEdit.BUDGET_EDIT_INCLUDE_TYPE;
      }
      if (this.get("budget.renameMode")) {
        editComponent += TemplateEdit.BUDGET_RENAME;
        if (!this.get("treeEditMode")) {
          return TemplateEdit.BUDGET_RENAME_SIMPLE;
        }
      }
      return editComponent;
    }),
    treeElementComponent: Ember.computed("editName", "editComponent", function () {
      if (this.get("editName")) {
        return this.get("editComponent");
      }
      return "budget-tree-element";
    }),
    actions: {
      goto: function goto(budget, dest, resetVersion) {
        this.sendAction("goto", budget, dest, resetVersion);
      },
      duplicate: function duplicate(budget, formatOnly) {
        this.sendAction("duplicate", budget, formatOnly);
      },
      delete: function _delete(budget) {
        this.sendAction("delete", budget);
      },
      addChildren: function addChildren(budget) {
        this.sendAction("addChildren", budget);
        return false;
      },
      showMore: function showMore() {
        this.sendAction("showMore");
      },
      showLess: function showLess() {
        this.sendAction("showLess");
      },
      copy: function copy(budget, dest) {
        this.sendAction("copy", budget, dest);
      },
      move: function move(budget, dest, position, tagEvent, subTagEvent) {
        this.sendAction("move", budget, dest, position, tagEvent, subTagEvent);
      },
      flag: function flag(budget) {
        this.sendAction("flag", budget);
      },
      mergeScenario: function mergeScenario(options) {
        this.sendAction("mergeScenario", options);
      },
      share: function share(budget) {
        this.sendAction("share", budget);
      },
      editTree: function editTree() {
        this.sendAction("editTree");
      },
      rename: function rename(budget) {
        var _this = this;
        var nameBefore = budget.get("name");
        if (this.get("active") && !budget.get("inheritedReadOnly")) {
          this.set("noSave", true);
          budget.set("renameMode", true);
          this.sendAction("editTree");
          Ember.run.scheduleOnce("afterRender", this, function () {
            var inputEle = this.$('input[type="text"]');
            var tmpStr = inputEle.val();
            inputEle.focus().val("").val(tmpStr).on("keypress", function (event) {
              if (event.which === 13 || event.keyCode === 13) {
                budget.set("renameMode", false);
                _this.send("lineRenamed", budget, nameBefore);
                if (_this.get("viewSelections.isGridView")) {
                  _this.get("cache").clearBudgetCache(budget);
                }
              }
              if (event.which === 27 || event.keyCode === 27) {
                budget.set("renameMode", false);
                budget.rollbackAttributes();
              }
            });
            this.set("noSave", false);
          });
          return false;
        } else {
          this.send("goto", budget);
        }
        return true;
        // this.sendAction('openModal', 'modal', {
        //     model: budget,
        //     title: 'Rename Budget',
        //     templateName: 'rename-budget-modal',
        //     cancelAction: 'rollback',
        //     confirmAction: 'save',
        //     confirmActionName: 'Save'
        // });
      },
      lineRenamed: function lineRenamed(line, nameBefore) {
        this.sendAction("lineRenamed", line, nameBefore);
      },
      newLineChosen: function newLineChosen(budget) {
        this.sendAction("newLineChosen", budget);
      },
      saveNew: function saveNew(budget) {
        this.sendAction("saveNew", budget);
      },
      importWizard: function importWizard(budget) {
        this.sendAction("importWizard", budget || this.get("budget"));
      },
      downloadTemplate: function downloadTemplate(budget) {
        this.sendAction("downloadTemplate", budget);
      },
      saveToModels: function saveToModels(budget) {
        this.sendAction("saveToModels", budget);
      },
      showLineDependencies: function showLineDependencies(budget) {
        this.sendAction("showLineDependencies", budget);
      },
      collapse: function collapse() {
        if (this.get("inSearchMode")) {
          return;
        }
        if (this.get("isCollapsed")) {
          this.get("collapsedBudgets").removeTreeItem(this.get("budget"), true);
        } else {
          this.get("collapsedBudgets").addTreeItem(this.get("budget"), true);
        }
        this.get("collapsedBudgets").save();
      },
      discardAll: function discardAll() {
        this.sendAction("discardAll");
      }
    }
  });
});