define("budgeta/components/employees-table-new-hire-amount-cell", ["exports", "budgeta/utils/forecast", "budgeta/utils/budget-utils"], function (_exports, _forecast, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    cache: Ember.inject.service(),
    store: Ember.inject.service(),
    numOfEmployees: Ember.computed("budgetAttributes.customAmounts", "budgetAttributes.amountType", "budgetAttributes.allocatedFrom", "budgetAttributes.startDate", {
      get: function get() {
        var monthKey = "";
        var customAmounts = this.getCustomAmounts(this.get("budgetAttributes"), this.get("line"), this.get("cache"), this.get("store"));
        if (this.get("budgetAttributes.amountType") === 1 || this.get("line.budgetType") === "employee") {
          monthKey = "m" + this.get("date").format("{MM}{yyyy}");
        }
        if (this.get("budgetAttributes.amountType") === 2) {
          monthKey = this.get("date").format("{yyyy}/{MM}");
        }
        return customAmounts ? customAmounts[monthKey] || 0 : 0;
      },
      set: function set(key, value) {
        if (this.get("line.budgetType") === "employee") {
          this.set("budgetAttributes.startDate", this.get("date"));
          return 1;
        } else {
          var customAmounts = this.get("budgetAttributes.customAmounts") || {};
          var monthKey = "m" + this.get("date").format("{MM}{yyyy}");
          customAmounts[monthKey] = value;
          this.set("budgetAttributes.customAmounts", customAmounts);
          return value;
        }
      }
    }),
    getCustomAmounts: function getCustomAmounts(budgetAttributes, line, cache, store) {
      var customAmounts = {};
      if (this.get("line.budgetType") === "employee") {
        var startDate = this.get("budgetAttributes.startDate");
        if (startDate) {
          startDate = typeof startDate === "string" ? new Date(startDate) : startDate;
          var startMonthKey = "m" + startDate.format("{MM}{yyyy}");
          customAmounts[startMonthKey] = 1;
        }
      }
      if (budgetAttributes.amountType === 1) {
        customAmounts = budgetAttributes.customAmounts;
      }
      if (budgetAttributes.amountType === 2) {
        customAmounts = _forecast.default.calcForecast(cache, store, _budgetUtils.default.getIdByUniqueId(store, cache, budgetAttributes.allocatedFrom), line, {
          metrics: true
        }).items;
      }
      return customAmounts;
    },
    readOnly: Ember.computed("budgetAttributes.amountType", "numOfEmployees", {
      get: function get() {
        var startDate = typeof this.get("budgetAttributes.startDate") === "string" ? new Date(this.get("budgetAttributes.startDate")) : this.get("budgetAttributes.startDate");
        if (this.get("line.budgetType") === "employee" && this.get("numOfEmployees") || this.get("budgetAttributes.amountType") === 2 || this.get("line.budgetType") === "employee-multi" && startDate && startDate.isAfter(this.get("date"))) {
          return true;
        }
        return null;
      }
    }),
    actions: {
      openFormulaEditor: function openFormulaEditor(component) {
        this.sendAction("openFormulaEditor", component);
      }
    }
  });
});