define("budgeta/templates/components/edit-budget-tree-rename", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5mLcQqO7",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"input\"],null,[[\"name\",\"value\",\"placeholder\"],[\"name\",[\"get\",[\"budget\",\"name\"]],[\"get\",[\"budget\",\"namePlaceholder\"]]]]],false],[\"text\",\"\\n\"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"budget-type\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"budget\",\"displayBudgetType\"]],false],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/edit-budget-tree-rename.hbs"
    }
  });
});