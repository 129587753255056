define("budgeta/templates/components/steps-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ss7pKLNh",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"data-hook\",\"steps-progress-bar\"],[\"dynamic-attr\",\"class\",[\"concat\",[\"steps-progress-bar \",[\"helper\",[\"unless\"],[[\"get\",[\"hideBarConnector\"]],\"bar-connector\"],null]]]],[\"dynamic-attr\",\"style\",[\"unknown\",[\"stepsBarWidthStyle\"]],null],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"ul\",[]],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"steps\"]]],null,2],[\"text\",\"  \"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"            \"],[\"append\",[\"unknown\",[\"step\",\"index\"]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"        \"],[\"open-element\",\"li\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"step-item \",[\"helper\",[\"if\"],[[\"get\",[\"step\",\"isCurrent\"]],\"current-step\"],null],\" \",[\"helper\",[\"if\"],[[\"get\",[\"step\",\"selected\"]],\"selected\"],null]]]],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"unless\"],[[\"get\",[\"step\",\"selected\"]]],null,0],[\"text\",\"        \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"help-iq\"],null,[[\"hideIcon\",\"tooltipText\",\"showAbove\",\"addClasses\",\"textAlign\"],[true,[\"get\",[\"step\",\"title\"]],true,\"white-tip\",\"center\"]],1]],\"locals\":[\"step\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/steps-progress-bar.hbs"
    }
  });
});