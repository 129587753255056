define("budgeta/mixins/add-children-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce("afterRender", this, function () {
        var container = this.$();
        if (container) {
          container.on("click", "ul.addChildren > li", function () {
            var $el = Ember.$(this);
            var qtipContent = $el.closest(".qtip-content");
            Ember.run.scheduleOnce("afterRender", function () {
              var numVisibleLevels = qtipContent.find("ul.addChildren:has(li):visible").length;
              qtipContent.closest(".qtip").css("max-width", 211 * numVisibleLevels);
              qtipContent.width(211 * numVisibleLevels);
            });
          }).off("click.expand").on("click.expand", "li:not(.disabled):has(ul.children)", function (e) {
            if (Ember.$(e.target).closest(".children").length === 0) {
              var $this = Ember.$(this);
              $this.toggleClass("expand");
              e.stopPropagation();
            }
          });
        }
      });
    },
    actions: {
      new: function _new(budget, type, multi, isRoot, name, budgetAttributes, grid, properties, options) {
        this.sendAction("new", budget, type, multi, isRoot, name, budgetAttributes, grid, properties, options);
      },
      deleteByType: function deleteByType(budget, type, specific) {
        this.sendAction("deleteByType", budget, type, specific);
      },
      hideTip: function hideTip(tipId) {
        this.sendAction("hideTip", tipId);
      }
    }
  });
});