define("budgeta/components/sync-account-row", ["exports", "budgeta/utils/const", "budgeta/utils/accounts"], function (_exports, _const, _accounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    classNameBindings: [":sync-account-row", "isCreateNewAccount:create-new-account", "isUpdateDescription:update-description", "isMatchAccount:match-account"],
    accountId: Ember.computed.alias("row.siAccount.ACCOUNTNO"),
    createNewAccounts: Ember.Object.create({}),
    selectedAccount: Ember.computed("accountId", "row.matchAccount", "isCreateNewAccount", "isRowPNL", "isRowBS", function () {
      var accountId = this.get("accountId");
      if (this.get("isCreateNewAccount")) {
        var newAccount = {
          account: accountId,
          description: this.get("row.siAccount.TITLE"),
          mappingType: "single",
          budgetLines: [],
          dimensions: [],
          reportingType: this.get("isRowStatistical") ? "period" : null,
          type: this.get("isRowPNL") ? "pnl" : this.get("isRowBS") ? "bs" : "stat",
          budget: this.get("budgetService.selectedRoot.absoluteRoot")
        };
        this.get("createNewAccounts")[accountId] = newAccount;
        return newAccount;
      } else if (this.get("createNewAccounts")[accountId]) {
        delete this.get("createNewAccounts")[accountId];
      }
      return this.get("sipAccountsMap").get(this.get("row.matchAccount"));
    }),
    isCreateNewAccount: Ember.computed("row.action", function () {
      return this.get("row.action") === _const.default.SYNC_ACCOUNTS_ACTIONS.NEW;
    }),
    isUpdateDescription: Ember.computed("row.action", function () {
      return this.get("row.action") === _const.default.SYNC_ACCOUNTS_ACTIONS.UPDATE;
    }),
    isMatchAccount: Ember.computed("row.action", function () {
      return this.get("row.action") === _const.default.SYNC_ACCOUNTS_ACTIONS.MATCH;
    }),
    isRowPNL: Ember.computed("row.siAccount", function () {
      return this.get("row.siAccount.ACCOUNTTYPE") === "incomestatement";
    }),
    isRowBS: Ember.computed("row.siAccount", function () {
      return this.get("row.siAccount.ACCOUNTTYPE") === "balancesheet";
    }),
    isRowStatistical: Ember.computed("row.siAccount", function () {
      return this.get("row.siAccount.ACCOUNTTYPE") === "cumulative" || this.get("row.siAccount.ACCOUNTTYPE") === "forperiod";
    }),
    showDescriptionsDropdown: Ember.computed("isUpdateDescription", "isMatchAccount", "row.selectedDescription", function () {
      return this.get("isUpdateDescription") || this.get("isMatchAccount") && this.get("row.selectedDescription");
    }),
    bsLines: _const.default.DEFAULT_OPENING_BALANCES_ID_NAME,
    budgetLines: Ember.computed("budgetService.budgetLinesAccounts", "budgetService.statisticalBudgetLinesAccounts", "isRowPNL", function () {
      return this.get("isRowPNL") ? this.get("budgetService.budgetLinesAccounts") : this.get("budgetService.statisticalBudgetLinesAccounts");
    }),
    sipAccountList: Ember.computed("isRowPNL", "isRowBS", function () {
      if (this.get("isRowPNL")) {
        return this.get("sipPnlAccountList");
      } else if (this.get("isRowBS")) {
        return this.get("sipBsAccountList");
      }
      return this.get("sipStatAccountList");
    }),
    showMappingCell: Ember.computed.alias("row.action"),
    showLinesMappingCell: Ember.computed("showMappingCell", "isUpdateDescription", "isMatchAccount", "selectedAccount", "selectedAccount.mappingType", "selectedAccount.budgetLines.length", function () {
      if (this.get("isUpdateDescription") || this.get("isMatchAccount")) {
        return this.get("selectedAccount.budgetLines.length");
      }
      return this.get("showMappingCell") && this.get("selectedAccount.mappingType") !== "none";
    }),
    showDimensionsCell: Ember.computed("isRowBS", "isCreateNewAccount", "isUpdateDescription", "isMatchAccount", "showMappingCell", "selectedAccount", "selectedAccount.mappingType", "selectedAccount.dimensions.length", function () {
      if (!this.get("isRowBS")) {
        if (this.get("isCreateNewAccount")) {
          return this.get("selectedAccount.mappingType") === "multi";
        } else if (this.get("isUpdateDescription") || this.get("isMatchAccount")) {
          return this.get("selectedAccount.dimensions.length");
        }
      }
      return false;
    }),
    showReportingTypeCell: Ember.computed("isRowStatistical", "showMappingCell", "selectedAccount", "selectedAccount.reportingType", function () {
      return this.get("isRowStatistical") && this.get("showMappingCell") && this.get("selectedAccount.reportingType");
    }),
    reportingTypeOptions: Ember.computed.alias("budgetService.reportingTypeOptions"),
    multiSelectOnOpenAction: Ember.computed("isRowStatistical", function () {
      return this.get("isRowStatistical") ? "multiSelectOnOpenAction" : "";
    }),
    setDescriptionsList: function setDescriptionsList() {
      var matchedAccount = this.get("sipAccountsMap").get(this.get("row.matchAccount"));
      var siAccountDescription = this.get("row.siAccount.TITLE");
      var isDifferentDescriptions = function isDifferentDescriptions() {
        return siAccountDescription !== matchedAccount.get("description");
      };
      var descriptionsList = isDifferentDescriptions() ? [siAccountDescription, matchedAccount.get("description")] : [];
      var selectedDescription = isDifferentDescriptions() ? siAccountDescription : undefined;
      Ember.setProperties(this.get("row"), {
        descriptionsList: descriptionsList,
        selectedDescription: selectedDescription
      });
    },
    actions: {
      selectDescription: function selectDescription(value) {
        this.set("row.selectedDescription", value);
      },
      planningAccountChanged: function planningAccountChanged(value) {
        if (value.type === "create-new-val") {
          this.set("row.action", _const.default.SYNC_ACCOUNTS_ACTIONS.NEW);
        } else {
          this.set("row.action", _const.default.SYNC_ACCOUNTS_ACTIONS.MATCH);
          this.setDescriptionsList();
        }
        this.set("row.selectedAccount", this.get("selectedAccount"));
      },
      multiSelectOnOpenAction: function multiSelectOnOpenAction(dropdownModel) {
        var _this = this;
        // Get the model lines that was selected in the new accounts rows.
        var newStatAccountsModelLines = Object.values(this.get("createNewAccounts")).filter(function (account) {
          return account.type == "stat" && account.account !== _this.get("accountId");
        }).mapBy("budgetLines");
        _accounts.default.calculateModelLinesWithExcludedContent(dropdownModel, this.get("selectedAccount"), this.get("budgetService"), newStatAccountsModelLines);
      },
      mappingTypeChanged: function mappingTypeChanged() {
        Ember.setProperties(this.get("selectedAccount"), {
          budgetLines: [],
          dimensions: []
        });
      }
    }
  });
});