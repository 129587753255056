define("budgeta/helpers/get-property", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getProperty = getProperty;
  function getProperty(context, property) {
    return Ember.get(context, property);
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return getProperty.apply(void 0, (0, _toConsumableArray2.default)(params));
  });
});