define("budgeta/components/integration-wizard-import-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      changeCurrentStep: function changeCurrentStep(actionArg) {
        this.sendAction(this.get("changeCurrentStep"), actionArg);
      }
    }
  });
});