define("budgeta/components/tag-list", ["exports", "lodash/lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":tag-list", "uniqueTags.length::empty"],
    attributeBindings: ["title", "selfTags"],
    uniqueTags: Ember.computed("tags", "selfTags", function () {
      var uniqueTags = _lodash.default.uniq(this.get("tags"), "name");
      var selfTags = this.get("selfTags");
      if (selfTags) {
        var uniqueTagsExceptSelfTags = uniqueTags.filter(function (tag) {
          return !selfTags.find(function (selfTag) {
            return selfTag.name === tag.name;
          });
        });
        return uniqueTagsExceptSelfTags;
      } else {
        return uniqueTags;
      }
    })
  });
});