define("budgeta/components/dropdown-with-sub-values", ["exports", "budgeta/components/dropdown-with-value"], function (_exports, _dropdownWithValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = _dropdownWithValue.default.extend({
    actions: {
      selectDropdown: function selectDropdown(item) {
        this.sendAction("selectDropdown", item);
      }
    }
  });
});