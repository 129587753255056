define("budgeta/components/name-value", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/formula", "budgeta/utils/formula-helper", "budgeta/utils/assumption"], function (_exports, _emberServiceContainer, _const, _formula, _formulaHelper, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/require-super-in-init, ember/no-observers, no-useless-escape, ember/closure-actions, no-prototype-builtins */
  var _default = _exports.default = Ember.Component.extend({
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    classNameBindings: [":full", ":panel", ":panel-default", "collapsed:collapsed"],
    blankItem: {
      name: "",
      value: undefined,
      currency: "#",
      comment: "",
      isMissing: false,
      writable: true,
      hasDependencies: false
    },
    collapsed: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set("elementId", "attribute-".concat(this.get("key"), "-").concat(this.get("elementId")));
      this.checkValues();
    },
    assumptionsString: Ember.computed("value.@each.name", "value.@each.value", "value.@each.currency", "budgetService.scenario", function () {
      var _this = this;
      return this.get("value") && this.get("value").map(function (a) {
        var displayValue = typeof a.value === "string" ? a.value.replace(/<span[^>]*>([^<]*)<\/span>/g, "$1").replace(/=<p>([^<]*)<\/p>/, "$1") : a.value;
        if (typeof a.value === "string" && a.value.substr(0, 1) === "=") {
          displayValue = _formula.default.calcFormula(a.value, {
            store: _this.get("budget.store"),
            cache: _this.get("budget.cache"),
            fixedAssumptions: _this.get("assumptions"),
            currency: a.currency,
            budget: _this.get("budget"),
            month: moment(_this.get("budget.root.budgetAttributes.startDate"))
          }, {
            scenario: _this.get("budgetService.scenario"),
            cache: _this.get("cache")
          });
        }
        if (a.currency === "%") {
          return "".concat(a.name, " = ").concat(typeof a.value === "string" && a.value.substr(0, 1) === "=" ? displayValue : _const.default.round(a.value * 100, 6), "%");
        } else {
          if (!isNaN(displayValue * 1)) {
            displayValue = _const.default.round(displayValue, 2);
          }
          return "".concat(a.name, " = ").concat(a.currency !== "#" && a.currency !== "." ? _const.default.currency_symbols[a.currency] || a.currency : "").concat(displayValue || 0);
        }
      }).join(", ");
    }),
    makeSortable: function makeSortable() {
      var _this2 = this;
      var _this = this;
      if (this.get("collapsed")) {
        return;
      }
      if (!this.get("readOnly")) {
        Ember.run.scheduleOnce("afterRender", function () {
          if (_this2.$()) {
            var sortable = _this2.$(".data-rows-wrapper").sortable({
              axis: "y",
              items: ">div.data-row",
              update: function update(event, ui) {
                var position = ui.item.parent().children().index(ui.item);
                var assumptions = _this.get("value");
                var moved = assumptions.findBy("uniqueId", ui.item.data("id"));
                assumptions.removeObject(moved);
                assumptions.insertAt(position, moved);
                _this.sendAction("changed");
                sortable.sortable("refresh");
              }
            });
          }
        });
      }
    },
    collapsedObserver: Ember.observer("collapsed", function () {
      this.makeSortable();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _this = this,
        container = _this.$();
      container.on("focusout", "input", function (e) {
        var $target = Ember.$(e.target);
        if ($target.closest(".fix-assumption-input").length > 0 && e.originalEvent) {
          // if a fixed assumption was changed, clear its cache
          var fixedAssumptionElement = $target.closest(".fix-assumption-input[data-id]");
          if (fixedAssumptionElement.length) {
            var fixedAssumption = _this.get("value").findBy("uniqueId", fixedAssumptionElement.data("id"));
            if (fixedAssumption && !_formulaHelper.default.isFormula(fixedAssumption.value)) {
              // if formula change is not needed in this point
              _this.get("cache").clearBudgetCache(fixedAssumption);
              _this.sendAction("changed", true);
            }
          }
        }
      });
      container.on("paste", "input", function (e) {
        //handle paste from excel
        var data = e.originalEvent.clipboardData.getData("text/plain").trim();
        var rows = VerEx().lineBreak().replace(data, "/\t").split("/\t");
        if (rows.length === 1 && rows[0].split(/\t/gi).length === 1) {
          return;
        }
        if (rows.length > 0) {
          e.preventDefault();
          if (Ember.isEmpty(_this.get("value.lastObject.name")) && Ember.isEmpty(_this.get("value.lastObject.value"))) {
            _this.get("value").removeObject(_this.get("value.lastObject"));
          }
          rows.forEach(function (raw) {
            var row = raw.split(/\t/gi);
            if (row[0] || row[1] || row[2]) {
              _this.get("value").pushObject({
                name: row[0],
                uniqueId: _const.default.generateUUID(),
                value: row[1] ? row[1].replace(/[^0-9\.\-]/g, "") : null,
                currency: "#",
                comment: row[2]
              });
            }
          });
        }
      });
      this.makeSortable();
    },
    keyUp: function keyUp() {
      if (this.get("budget.isNewLine")) {
        this.send("removeNew");
      }
    },
    checkValues: function checkValues() {
      var cache = this.get("budget.cache");
      var assumptions = this.get("value") || [];
      assumptions.forEach(function (a) {
        if (Ember.get(a, "isMissing") && Ember.get(a, "value") !== "") {
          Ember.set(a, "isMissing", false);
        }
        if (!cache) {
          Ember.set(a, "hasDependencies", false);
        } else {
          var using = cache.get("dependencyTable.assumptions.".concat(Ember.get(a, "uniqueId"))) || [];
          var useOtherAssumption = cache.findReferencesToId(Ember.get(a, "uniqueId")).length > 0;
          Ember.set(a, "hasDependencies", using.length > 0 || useOtherAssumption);
        }
      });
    },
    valuesObserver: Ember.observer("value.@each.value", function () {
      this.checkValues();
    }),
    singleValue: Ember.computed("value.length", function () {
      return this.get("value.length") === 1;
    }),
    actions: {
      collapse: function collapse() {
        this.set("collapsed", !this.get("collapsed"));
        this.sendAction("recalcModelPreview");
      },
      openFormulaEditor: function openFormulaEditor(arg) {
        this.sendAction("openFormulaEditor", arg);
      },
      add: function add() {
        this.set("collapsed", false);
        this.get("value").pushObject({
          uniqueId: _const.default.generateUUID(),
          name: this.get("blankItem.name"),
          value: this.get("blankItem.value"),
          currency: this.get("blankItem.currency"),
          comment: this.get("blankItem.comment")
        });
        this.setProperties({
          "blankItem.name": "",
          "blankItem.value": undefined,
          "blankItem.currency": "#",
          "blankItem.comment": ""
        });
        this.sendAction("changed");
        this.notifyPropertyChange("budgetService.selectedBudget.budgetAttributesWithScenarios.assumptions.length"); // trigger recalc of fixedAssumptions in budget service
        Ember.run.scheduleOnce("afterRender", this, function () {
          this.$(".data-rows-wrapper").scrollTo(this.$(".data-rows-wrapper .data-row").last());
          this.$(".data-rows-wrapper .data-row").last().find("input").first().focus();
        });
        this.send("removeNew");
      },
      duplicateItem: function duplicateItem(item) {
        var _this3 = this;
        var uniqueId = _const.default.generateUUID();
        var pos = this.get("value").indexOf(item);
        var name = intl.t("general.copy_of__1698234255794") + Ember.get(item, "name"),
          originalName = name;
        var model = this.get("budget.parent.isModelGroup") ? this.get("budget.parent") : this.get("budget");
        var numDup = 1;
        while (_assumption.default.nameExists(model, model, name)) {
          name = "".concat(originalName, " ").concat(numDup++);
        }
        this.get("value").insertAt(pos + 1, {
          uniqueId: uniqueId,
          name: name,
          value: Ember.get(item, "value"),
          currency: Ember.get(item, "currency"),
          comment: Ember.get(item, "comment")
        });
        this.sendAction("changed");
        Ember.run.scheduleOnce("afterRender", function () {
          _this3.$(".data-row[data-id=\"".concat(uniqueId, "\"] .fix-assumption-input:first-child input")).focus();
        });
        this.send("removeNew");
      },
      removeItem: function removeItem(item) {
        var scrollTopPosition = this.$(".data-rows-wrapper").scrollTop();
        var inUse = this.get("cache").findOneReferenceToIdInAllScenariosAndBase(Ember.get(item, "uniqueId"));
        if (inUse) {
          noty({
            type: "warning",
            text: "".concat(intl.t("general.assumption_cannot_be_deleted"), " ").concat(inUse.ref.hasOwnProperty("assumption") ? intl.t("general.assumption_1698234255794") : intl.t("general.line_1698234255794"), " ").concat(Ember.get(inUse, "ref.name"), " ").concat(Ember.get(inUse, "ref.parent.name") ? intl.t("general.under__1698234255793") + Ember.get(inUse, "ref.parent.name") : "", " ").concat(Ember.get(inUse, intl.t("general.scenario_1698234255793")) ? intl.t("general._in_scenario__1698234255793") + Ember.get(inUse, intl.t("general.scenario_1698234255793")) : "")
          });
        } else if (this.get("value")) {
          this.get("value").removeObject(item);
          this.get("cache").deleteFromDependencyTable(item, "assumptions");
          this.rerender();
          this.sendAction("changed");
          // Clear formula copy if deleted assumption is in use
          _const.default.clearFormulaCopy(this.get("budgetService"), Ember.get(item, "uniqueId"));
        }
        this.sendAction("recalcModelPreview");
        Ember.run.scheduleOnce("afterRender", this, function () {
          this.$(".data-rows-wrapper").scrollTop(scrollTopPosition);
        });
        this.send("removeNew");
      },
      removeNew: function removeNew() {
        this.set("budget.isNewLine", false);
        if (this.get("budget.parent.isNewLine")) {
          this.set("budget.parent.isNewLine", false);
          this.get("budget.parent").save();
        }
      },
      showLineDependencies: function showLineDependencies(item) {
        this.sendAction("showLineDependencies", item);
      }
    }
  });
});