define("budgeta/components/svg-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["svg-icon"],
    viewBox: "0 0 24 24",
    preserveAspectRatio: "xMinYMin meet",
    attributeBindings: ["title", "href", "target", "name:data-hook"],
    actionBubbles: true,
    unicode: Ember.computed("unicodeValue", function () {
      return this.get("unicodeValue") ? String.fromCharCode(this.get("unicodeValue")) : "";
    }),
    path: Ember.computed("name", function () {
      return "/assets/images/svg-store.svg#" + this.get("name");
    }),
    click: function click(e) {
      if (!this.get("actionBubbles")) {
        e.stopPropagation();
      }
      this.sendAction("action", this.get("actionArg"), this.get("actionArg2"));
    }
  });
});