define("budgeta/components/trx-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CSS_VAR_NAME = "trx-filter-min-width";
  var _default = _exports.default = Ember.Component.extend({
    cssVars: Ember.inject.service(),
    classNameBindings: [":trx-filter", "sortAscending:sort-ascending", "sortDescending:sort-descending", "didFilter:has-filter"],
    //maxHeight - Is used in Mappings -> dimensions since the dropdown list overlows
    sortAscending: Ember.computed("filter.sortOrder", "filter.sortBy", function () {
      return this.get("filter.sortBy") ? this.get("filter.sortOrder") : false;
    }),
    sortDescending: Ember.computed("filter.sortOrder", "filter.sortBy", function () {
      return this.get("filter.sortBy") ? !this.get("filter.sortOrder") : false;
    }),
    columnsLayout: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set("columnsLayout", [100]);
    },
    searchValue: "",
    dropDownWidth: Ember.computed("isWide", function () {
      return this.get("isWide") ? 350 : 200;
    }),
    enablePathList: Ember.computed.equal("attr", "budgetLines"),
    filterByPropName: Ember.computed("isDate", "showTitle", function () {
      return this.get("isDate") ? "dateStr" : this.get("showTitle") ? "title" : "name";
    }),
    allFilterOptions: Ember.computed("attr", "searchValue", "filter", "filters", "filterByPropName", "enablePathList", function () {
      var thisFilter = this.get("filter.filters") || this.get("filters.".concat(this.get("attr"), ".filters"));
      var searchValue = this.get("searchValue");
      var byProp = this.get("filterByPropName");
      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        thisFilter = thisFilter.filter(function (item) {
          return (Ember.get(item, byProp) || "").toString().toLowerCase().indexOf(searchValue) !== -1;
        });
      }
      return thisFilter;
    }),
    click: function click() {
      if (this.get("searchValue")) {
        this.set("searchValue", "");
      }
    },
    filterOptionsDividerIndex: Ember.computed("filterOptions.[]", "enablePathList", function () {
      var bsLines = this.get("filterOptions").map(function (filterOption, index) {
        return {
          filterOption: filterOption,
          index: index
        };
      }).filterBy("isBs");
      return this.get("enablePathList") && bsLines.length ? bsLines.get("lastObject.index") : null;
    }),
    oldFilterList: undefined,
    filterOptions: Ember.computed("recalcFilterMap", "filterMap", "searchValue", "allFilterOptions.[]", "hasAnyFilter", "filteredColumns", "attr", "filter", "filter.selectList.[]", "oldFilterList", "enablePathList", function () {
      var attr = this.get("attr");
      var allFilterOptions = this.get("allFilterOptions");
      if (allFilterOptions && !allFilterOptions.length) {
        return allFilterOptions;
      }
      if (this.get("enablePathList")) {
        var bsLines = allFilterOptions.filterBy("isBs");
        var budgetLines = allFilterOptions.filterBy("isBs", false);
        allFilterOptions = bsLines.concat(budgetLines);
      }
      if (!this.get("hasAnyFilter")) {
        return allFilterOptions;
      }
      if (this.get("filteredColumns.length") >= 2 && (this.get("filter.selectList.length") || this.get("filters.".concat(attr, ".selectList.length"))) && this.get("oldFilterList")) {
        // if this column was filter before we return the old filter list
        return this.get("oldFilterList");
      }
      if (this.get("filteredColumns.length") === 1 && this.get("filteredColumns").indexOf(attr) !== -1) {
        // this is the first column that was filtered. return all the filter option and freeze the filter option
        this.set("oldFilterList", allFilterOptions);
        return allFilterOptions;
      }
      var newFilterOptions = this.get("filterMap.".concat(attr, ".filters")) || Ember.A([]);
      this.set("oldFilterList", newFilterOptions);
      var searchValue = this.get("searchValue");
      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        var byProp = this.get("filterByPropName");
        newFilterOptions = newFilterOptions.filter(function (item) {
          return (Ember.get(item, byProp) || "").toString().toLowerCase().indexOf(searchValue) !== -1;
        });
      }
      return newFilterOptions;
    }),
    linesHeight: Ember.computed("filterOptions.length", function () {
      return Math.min(this.get("filterOptions.length") * 40, this.get("maxHeight") ? this.get("maxHeight") : 400) || 40;
    }),
    linesStyle: Ember.computed("linesHeight", function () {
      var style = "\n      height: ".concat(this.get("linesHeight"), "px;\n      position: relative;\n      min-width: var(").concat(this.get("cssVars").makeVarName(CSS_VAR_NAME, this), ");");
      return Ember.String.htmlSafe(style);
    }),
    qtipClasses: Ember.computed("isWide", function () {
      var _class = this.get("isWide") ? "full-size" : "";
      return _class + " popup-menu";
    }),
    didFilter: Ember.computed("filteredColumns.[]", "attr", function () {
      return this.get("filteredColumns").includes(this.get("attr"));
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      if (this.get("resizeFilter")) {
        if (this.get("isWide") && this.$()) {
          this.get("cssVars").setPx(CSS_VAR_NAME, this.$().width(), this);
        }
        Ember.$(window).on("resize.trxfilter", function () {
          Ember.run.schedule("afterRender", function () {
            if (_this.$() && _this.$().width() > 0) {
              _this.get("cssVars").setPx(CSS_VAR_NAME, _this.$().width(), _this);
            }
          });
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off("resize.trxfilter");
      this.get("cssVars").remove(CSS_VAR_NAME, this);
    },
    actions: {
      clear: function clear() {
        this.set("oldFilterList", undefined);
        var filter = this.get("filter");
        if (this.get("dimension")) {
          filter = this.get("filters.".concat(this.get("attr")));
        }
        Ember.get(filter, "filters").forEach(function (filter) {
          Ember.set(filter, "selected", false);
        });
        Ember.set(filter, "selectList", Ember.A([]));
        if (this.get("dimension")) {
          this.set("recalcDimension", !this.get("recalcDimension"));
        }
      },
      sort: function sort() {
        if (!this.get("filterOptions.length") || !this.get("sort")) {
          return;
        }
        this.get("sort")(this.get("attr"));
      },
      lineToggle: function lineToggle() {
        var _this2 = this;
        Ember.run.next(function () {
          var filter = _this2.get("filter");
          if (_this2.get("dimension")) {
            filter = _this2.get("filters.".concat(_this2.get("attr")));
          }
          var selected = Ember.get(filter, "filters").filterBy("selected");
          Ember.set(filter, "selectList", selected);
          if (_this2.get("dimension")) {
            _this2.set("recalcDimension", !_this2.get("recalcDimension"));
          }
        });
      }
    }
  });
});