define("budgeta/templates/components/svg/edit-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qXBe5T7o",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M9.46465 3.22L1.87005 10.82C1.71017 10.98 1.55028 11.22 1.55028 11.38L0.0313621 15.7C-0.048581 15.94 0.0313621 16.18 0.191248 16.34C0.351135 16.42 0.511021 16.5 0.670907 16.5C0.75085 16.5 0.830793 16.5 0.830793 16.5L5.06778 15.06C5.30761 14.98 5.46749 14.9 5.62738 14.74L13.222 7.14L9.46465 3.22ZM14.1813 6.18L15.2206 5.14C16.2598 4.1 16.2598 2.34 15.2206 1.3C14.7409 0.82 14.0214 0.5 13.3019 0.5C12.5824 0.5 11.8629 0.82 11.3833 1.3L10.344 2.34L14.1813 6.18Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/edit-svg.hbs"
    }
  });
});