define("budgeta/utils/cookie-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-undef */
  function clearCookie(_ref) {
    var name = _ref.name,
      _ref$path = _ref.path,
      path = _ref$path === void 0 ? "/" : _ref$path;
    $.cookie(name, "", {
      path: path,
      expires: -1
    });
  }
  var _default = _exports.default = {
    clearCookie: clearCookie
  };
});