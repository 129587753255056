define("budgeta/controllers/add-custom-view-modal", ["exports", "budgeta/controllers/modal", "ember-validations"], function (_exports, _modal, _emberValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _modal.default.extend({
    intl: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    mainBudgetForecastController: Ember.inject.controller("main/budget/forecast"),
    newView: Ember.computed.alias("mainBudgetForecastController.newView"),
    validations: {
      newView: {
        presence: true,
        inline: (0, _emberValidations.validator)(function () {
          if ((this.get("viewSelections.customViews") || []).map(function (view) {
            return view.name;
          }).includes(this.model.get("newView").trim())) {
            return this.get("intl").t("view-toolbar.custom-view.errors.double-name-warning");
          }
        })
      }
    }
  });
});