define("budgeta/components/integration-wizard-export-dimension-values-mapping", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    }),
    dimensions: Ember.computed("model.missingDimensionValues.length", function () {
      var _this = this;
      var result = [],
        intacctDimensions = this.get("model.intacctDimensions") || [];
      (this.get("model.missingDimensionValues") || []).forEach(function (valueMap) {
        var dimensionData = result.findBy("dimension.uniqueId", Ember.get(valueMap, "dimensionId"));
        if (!dimensionData) {
          var dimMapping = _this.get("budgetService.dimensionsMapping").findBy("dimensionId", Ember.get(valueMap, "dimensionId"));
          if (dimMapping) {
            var intacctDimension = intacctDimensions.findBy("id", Ember.get(dimMapping, "financialDimensionId"));
            dimensionData = {
              dimension: _this.get("budgetService.dimensions").findBy("uniqueId", Ember.get(valueMap, "dimensionId")),
              valueOptions: intacctDimension ? intacctDimension.values : null,
              missingValues: []
            };
            result.pushObject(dimensionData);
          }
        }
        if (dimensionData) {
          dimensionData.missingValues.pushObject(valueMap);
        }
      });
      return result;
    }),
    willDestroyElement: function willDestroyElement() {
      var continueBtn = Ember.$(".modal-content .modal-footer #continue-btn");
      continueBtn.removeClass("disabled");
    },
    didRender: function didRender() {
      this.sendAction(this.get("continueActionName"), this.get("model.isSkipSteps.exportMissingDimensionsValues") ? intl.t("general.skip_1698234255443") : "");
    },
    actions: {
      attributeChanged: function attributeChanged() {
        this.set("model.isSkipSteps.exportMissingDimensionsValues", false);
        this.sendAction(this.get("continueActionName"), "");
      }
    }
  });
});