define("budgeta/utils/grid/const", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GRID_TABLE = _exports.GRID_FILTERS = _exports.AMOUNT_TYPE = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var GRID_TABLE = _exports.GRID_TABLE = Object.freeze({
    HEADER_HEIGHT: 40,
    SUB_HEADER_HEIGHT: 22,
    X_SCROLL_HEIGHT: 15,
    ROW_HEIGHT: 46,
    DEFAULT_GRID_HEIGHT: 500,
    PROPS_KEY: "PROPS",
    TOTAL_COLUMN_NAME: intl.t("general.title_1695611599096")
  });
  var AMOUNT_TYPE = _exports.AMOUNT_TYPE = Object.freeze({
    AMOUNT: 0,
    CUSTOM: 1,
    GROWTH: 2
  });
  var GRID_FILTERS = _exports.GRID_FILTERS = Object.freeze({
    ORIGINAL_CURRENCY_ID: "original"
  });
});