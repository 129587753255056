define("budgeta/routes/admin/activity", ["exports", "budgeta/utils/ajax", "budgeta/mixins/budgeta-route", "ember-simple-auth/mixins/authenticated-route-mixin", "budgeta/config/environment"], function (_exports, _ajax, _budgetaRoute, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _budgetaRoute.default, {
    session: Ember.inject.service(),
    queryParams: {
      fromDate: {
        refreshModel: true
      },
      toDate: {
        refreshModel: true
      },
      userId: {
        refreshModel: true
      },
      selectedDomain: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return (0, _ajax.default)("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/admin/activity"), {
        data: {
          fromDate: this.get("controller.fromDate") || Date.create().format(Date.ISO8601_DATE),
          toDate: this.get("controller.toDate") || Date.create().format(Date.ISO8601_DATE),
          userId: this.get("controller.userId"),
          domain: params.selectedDomain || this.get("controller.selectedDomain")
        }
      });
    }
  });
});