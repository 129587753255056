define("budgeta/controllers/admin/flagged", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/excel", "budgeta/mixins/query-param-delegate-fix"], function (_exports, _emberServiceContainer, _excel, _queryParamDelegateFix) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = Ember.Controller.extend(_queryParamDelegateFix.default, {
    queryParams: ["recent"],
    recent: null,
    selectedMonth: Date.create().beginningOfMonth(),
    selectedMonthFormatted: Ember.computed("selectedMonth", function () {
      return this.get("selectedMonth").format("{Mon} {year}");
    }),
    dateCols: Ember.computed("selectedMonth", function () {
      var result = [];
      var thisDate = Date.create(this.get("selectedMonth"));
      var endDate = Date.create(thisDate).addMonths(1);
      while (thisDate.isBefore(endDate)) {
        result.push({
          value: thisDate.format("{year}-{MM}-{dd}"),
          formatted: thisDate.format("{dd}")
        });
        thisDate.addDays(1);
      }
      return result;
    }),
    addLineCounts: Ember.observer("dateCols", "model", function () {
      var _this = this;
      this.get("model").forEach(function (line) {
        Ember.set(line, "lineCounts", _this.get("dateCols").map(function (dateCol) {
          var ver = line.budgetLog.filter(function (v) {
            return v.timestamp.indexOf(dateCol.value) === 0;
          }).mapBy("lineCount");
          return ver.length ? ver.sort().get("lastObject") : null;
        }));
      });
    }),
    actions: {
      prev: function prev() {
        this.set("selectedMonth", Date.create(this.get("selectedMonth")).addMonths(-1));
      },
      next: function next() {
        this.set("selectedMonth", Date.create(this.get("selectedMonth")).addMonths(1));
      },
      exportExcel: function exportExcel() {
        var data = [],
          header = ["Budget Name", intl.t("general.owner_1698234256101")];
        this.get("dateCols").forEach(function (col) {
          header.push(col.value);
        });
        data.push(header);
        data.pushObjects(this.get("model").map(function (line) {
          var lineResult = [line.name, line.owner && line.owner.email];
          lineResult.pushObjects(line.lineCounts);
          return lineResult;
        }));
        _excel.default.saveToExcel(data);
      }
    }
  });
});