define("budgeta/services/router-service", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var generateSSOLogoutUrl = function generateSSOLogoutUrl() {
    var query = {
      returnTo: "".concat(window.location.origin, "/logout"),
      client_id: _const.default.SAGE_ID_CLIENT_ID,
      federated: true
    };
    return "".concat(_const.default.SAGE_ID_BASE_URL, "/v2/logout?").concat(new URLSearchParams(query).toString());
  };
  var _default = _exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    logout: function logout() {
      var url = this.get("session.data.currentUser.authenticatedBy") === _const.AUTH_PROVIDERS.SIF ? generateSSOLogoutUrl() : window.location.origin + "/logout";
      window.location = url;
    }
  });
});