define("budgeta/components/import-management-banner", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNameBindings: [":left-side", "hide:hide-banner"],
    hide: false,
    mainHeader: Ember.computed("importType", function () {
      return this.get("importType") ? this.get("intl").t("".concat(this.get("importType"), ".header")) : "".concat(this.get("intl").t("import-all.import"), " ").concat(this.get("importTargetModel.name"));
    }),
    mainColumn: Ember.computed("importType", function () {
      return this.get("importType") === "upload-accounts" ? this.get("intl").t("upload-accounts.select-account-column") : this.get("intl").t("import-all.select-main-column");
    }),
    step4Intro: Ember.computed("importType", function () {
      return this.get("displayThousands") ? this.get("intl").t("import-all.step4-intro") : this.get("intl").t("".concat(this.get("importType"), ".step4-intro"));
    }),
    stepHeader: Ember.computed("progressSteps", function () {
      return this.get("progressSteps").findBy("isCurrent").title;
    }),
    progressSteps: Ember.computed("steps", "currentStep", function () {
      var _this = this;
      var isSelected = true;
      return (this.get("steps") || []).map(function (step, index) {
        if (_this.get("currentStep") === index) {
          isSelected = false;
          return {
            index: index + 1,
            title: step.title,
            isCurrent: true
          };
        }
        return {
          index: index + 1,
          title: step.title,
          selected: isSelected
        };
      });
    }),
    displayThousands: Ember.computed.empty("importType"),
    importActualsReadMoreDetailsUrl: "".concat(_const.default.HELP_URL, "/intacct-planning/SIP_Topics/Upload_excel_file.htm?tocpath=Create%20your%20plan%7CUpload%20an%20Excel%20file%7C_____0"),
    actions: {
      toggleHide: function toggleHide() {
        this.set("hide", !this.get("hide"));
        this.get("expand")();
      }
    }
  });
});