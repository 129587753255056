define("budgeta/services/real-time-collaboration/const", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LOGS = void 0;
  var LOGS = _exports.LOGS = {
    ERRORS: {
      SETUP: "Real-time collaboration | Error to setup: ",
      ADD_USER: "Real-time collaboration | Error to add user to redis: ",
      REGISTER_TO_SOCKET_MESSAGES: "Real-time collaboration | error to register socket messages: ",
      SAVE_USERS: "Real-time collaboration | error to save users: ",
      CONNECTED_CHANGED_HANDLER: "Real-time collaboration | error in connected changed handler: ",
      SUBSCRIPTION_CHANGED_HANDLER: "Real-time collaboration | error in subscription changed handler: ",
      GET_ACCESS_TOKEN: "Real-time collaboration | error to get access token: "
    }
  };
});