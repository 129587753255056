define("budgeta/controllers/add-root-modal", ["exports", "budgeta/services/ember-service-container", "budgeta/controllers/modal", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _modal, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/require-super-in-init, ember/no-observers, ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _modal.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.stepChanged();
    },
    trackingEvents: Ember.inject.service(),
    permissions: Ember.inject.service(),
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    isElegantModal: true,
    validateOnConfirm: false,
    confirmDisabled: Ember.computed("step", "name", "showErp", "showTemplate", "permissions.featureFlagIntacct", function () {
      if (this.get("step") === 2 && !this.get("name") && !this.get("showTemplate")) {
        return true;
      }
      return this.get("showErp") && !this.get("permissions.featureFlagIntacct");
    }),
    steps: 2,
    stepObserver: Ember.observer("selectedOption", "step", "permissions.featureFlagIntacct", function () {
      this.stepChanged();
    }),
    stepChanged: function stepChanged() {
      this.setProperties({
        validateOnConfirm: this.get("selectedOption") === "wizard" && this.get("step") > 1,
        steps: this.get("selectedOption") === "wizard" || this.get("selectedOption") === "erp" && !this.get("permissions.featureFlagIntacct") ? 3 : 2
      });
    },
    templates: Ember.computed(function () {
      var templates = [];
      this.get("store").findAll("templateProvider").then(function (providers) {
        providers.forEach(function (provider) {
          provider.get("templates").setEach("provider", provider);
          templates.pushObjects(provider.get("templates"));
        });
        Ember.set(templates, "firstObject.selected", true);
      });
      return templates;
    }),
    selectNewBudgetType: Ember.computed("budgetService.selectedRoot", function () {
      var budgetNewTypes = [{
        id: "erp",
        text: intl.t("general.text_1695611609364"),
        disabled: _const.default.DISABLE_INTEGRATION
      }, {
        id: "upload",
        text: intl.t("general.text_1695611609353")
      }, {
        id: "genericWizard",
        text: intl.t("general.text_1695611609342")
      }];
      // manual wizard enable only by role
      if (this.get("permissions.featureFlagManualWizard")) {
        budgetNewTypes.push({
          id: "wizard",
          text: intl.t("general.text_1695611609330")
        });
      }
      return budgetNewTypes;
    }),
    rootBudgetModules: [{
      id: "group-company",
      name: intl.t("general.name_1695611601251")
    }, {
      id: "group-company-non-profit",
      name: intl.t("general.name_1695611601240")
    }],
    selectedOption: Ember.computed("step", function () {
      var selectedOption = Ember.$.cookie("selectedOption");
      if (_const.default.DISABLE_INTEGRATION) {
        return "wizard";
      }
      if (!selectedOption) {
        return "erp";
      }
      return selectedOption;
    }),
    selectedTemplate: false,
    isIntacctBudget: Ember.computed("model.addRootProperties.budgetType", function () {
      return this.get("model.addRootProperties.budgetType") === "group-company-intacct";
    }),
    showErp: Ember.computed("selectedOption", function () {
      var result = this.get("selectedOption") === "erp";
      return result;
    }),
    showRequest: Ember.computed("showErp", "step", function () {
      return this.get("showErp") && this.get("step") === 3;
    }),
    showTemplate: Ember.computed("selectedOption", "selectedTemplate", function () {
      return this.get("selectedOption") === "template" && this.get("selectedTemplate");
    }),
    modelChanged: Ember.observer("model.addRootAttributes.startDate", function () {
      if (!this.get("model.addRootAttributes.startDate")) {
        var startDate = new Date().reset();
        startDate.setDate(1);
        this.set("model.addRootAttributes.startDate", startDate);
        var endDate = new Date(this.get("model.addRootAttributes.startDate"));
        _const.default.addMonths(endDate, 11);
        this.set("model.addRootAttributes.endDate", endDate);
      }
      if (!this.get("isIntacctBudget")) {
        this.set("model.addRootProperties.yearStart", this.get("model.addRootAttributes.startDate").getMonth ? this.get("model.addRootAttributes.startDate").getMonth() : new Date().getMonth());
      }
    }),
    budgetModule: Ember.computed("model.addRootProperties.budgetType", function () {
      var result = this.store.peekRecord("budget-module", this.get("model.addRootProperties.budgetType"));
      if (result.get("addRootOnSelectAction")) {
        this.send(result.get("addRootOnSelectAction"));
      }
      return result;
    }),
    tipId: Ember.computed("budgetModule", function () {
      return this.get("budgetModule.addRootHelpLink");
    }),
    name: Ember.computed.alias("model.addRootProperties.name"),
    startDate: Ember.computed.alias("model.addRootAttributes.startDate"),
    endDate: Ember.computed.alias("model.addRootAttributes.endDate"),
    validations: {
      name: {
        presence: true
      },
      endDate: {
        presence: {
          message: intl.t("general.end_date_is_required_1698234256018")
        },
        "date-after": {
          otherDates: ["startDate"]
        }
      },
      startDate: {
        presence: {
          message: intl.t("general.start_date_is_required_1698234256018")
        },
        "date-before": {
          otherDates: ["endDate"]
        }
      }
    },
    showTypeCheck: Ember.computed("model.addRootProperties.budgetType", "budgetService.intacctLoginData.verified", function () {
      return this.get("model.addRootProperties.budgetType") === "group-company-intacct" && this.get("budgetService.intacctLoginData.verified");
    }),
    actions: {
      intacctLoginNext: function intacctLoginNext(name) {
        Ember.$(".modal-content .modal-footer #confirm-btn").addClass("disabled");
        this.set("title", "");
        this.set("step", 4); // this is to show an empty screen before we switch over to the intacct wizard
        this.set("model.addRootProperties.budgetType", "group-company-intacct");
        this.set("model.addRootProperties.budgetRootType", "group-company");
        this.set("name", typeof name === "string" ? name : this.get("budgetService.intacctLoginData.companyid"));
        this.set("model.addRootProperties.name", this.get("name"));
        this.set("model.addRootAttributes.intacctCompanyId", this.get("budgetService.intacctLoginData.companyid"));
        this.set("model.addRootAttributes.intacctUserId", this.get("budgetService.intacctLoginData.userid"));
        this.set("startDate", new Date());
        this.set("endDate", new Date());
        this.send("closeModal", "modal2");
        this.send("confirm", {
          noClose: true
        });
      },
      intacctLoginCancel: function intacctLoginCancel() {
        this.set("model.addRootProperties.budgetType", "group-company");
        this.set("name", null);
        this.set("budgetService.intacctLoginData.verified", false);
        this.set("step", 1);
        this.send("closeModal", "modal2");
      },
      intacctLogin: function intacctLogin() {
        this.send("openModal", "intacct-login-modal", {
          model: this.get("budgetService.intacctLoginData"),
          title: intl.t("general.title_1695611597418"),
          classNames: "intacct-login-modal",
          templateName: "intacct-login-modal",
          confirmActionName: intl.t("general.confirmActionName_1695611613152"),
          confirmAction: "intacctLoginNext",
          confirmActionDismiss: false,
          cancelAction: "close",
          backAction: "intacctLoginCancel",
          actionTarget: this,
          hideCancel: false,
          showBack: true,
          isSIwizard: true,
          outlet: "modal2"
        });
      },
      next: function next() {
        var wizardTitle;
        if (this.get("selectedOption") === "wizard") {
          if (this.get("step") === 1) {
            wizardTitle = intl.t("general.wizardTitle _1695957078639");
          } else if (this.get("step") === 2) {
            wizardTitle = intl.t("general.wizardTitle _1695957078544");
          } else if (this.get("step") === 3) {
            wizardTitle = intl.t("general.wizardTitle _1695957078555");
          }
        } else if (this.get("selectedOption") === "upload") {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.WIZARD_NEW_BUDGET_FROM_FILE"));
          wizardTitle = intl.t("general.wizardTitle _1695957078607");
        } else if (this.get("selectedOption") === "erp") {
          this.set("model.addRootProperties.budgetType", "group-company-intacct");
          this.get("budgetModule"); // trigger login to intacct
          // this.incrementProperty('step');
        } else if (this.get("selectedOption") === "template") {
          wizardTitle = intl.t("general.wizardTitle _1695957078597");
        }
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.WIZARD_NEXT"), {
          wizard_screen_name: wizardTitle,
          step: this.get("step")
        }, {
          wizard_screen_name: wizardTitle
        });
        if (this.get("step") === 1) {
          this.set("model.addRootProperties.budgetType", "group-company");
          this.set("model.addRootProperties.name", "");
          this.set("errors.name", []);
          Ember.$.cookie("selectedOption", this.get("selectedOption"), {
            expires: 1,
            path: "/"
          });
        }
        if (this.get("step") === 1 && !this.get("showErp") && !this.get("showTemplate")) {
          if (this.get("selectedOption") === "wizard") {
            this.send("resetModal");
            this.set("title", intl.t("general.create_new_budget_1695957078639"));
          } else if (this.get("selectedOption") === "upload") {
            this.send("close");
            this.set("selectedOption", "wizard");
            return this.transitionToRoute("main.budget.import", {
              queryParams: {
                importType: "upload-budget",
                uploadBudget: true,
                onClose: "addRoot"
              }
            });
          } else if (this.get("selectedOption") === "template") {
            this.set("selectedTemplate", true);
            this.set("selectedOption", "template");
            this.set("title", intl.t("general.select_template_1695957078639"));
          } else if (this.get("selectedOption") === "genericWizard") {
            this.send("close");
            this.set("selectedOption", "geneircWizard");
            return this.transitionToRoute("main.budget.import-generic-file", {
              queryParams: {
                importType: "upload-generic-budget",
                uploadBudget: "",
                onClose: "addRoot"
              }
            });
          }
        } else {
          this.set("title", intl.t("general.create_new_budget_1695957078639"));
          return this._super();
        }
        this.incrementProperty("step");
      },
      selectTemplate: function selectTemplate(template) {
        this.get("templates").setEach("selected", false);
        Ember.set(template, "selected", true);
      },
      confirm: function confirm() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var noClose = options.noClose;
        if (this.get("step") === 2 && this.get("showTemplate")) {
          var selectedTemplate = this.get("templates").findBy("selected");
          if (selectedTemplate) {
            Ember.set(selectedTemplate, "code", null);
          }
          this.send("loadBudgetTemplate", selectedTemplate);
          return;
        }
        var wizardTitle;
        if (this.get("step") === 2) {
          wizardTitle = intl.t("general.wizardTitle _1695957078544");
        } else if (this.get("step") === 3) {
          wizardTitle = intl.t("general.wizardTitle _1695957078555");
        }
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.WIZARD_NEXT"), {
          wizard_screen_name: wizardTitle
        }, {
          wizard_screen_name: wizardTitle
        });
        if (noClose) {
          (this.get("actionTarget") || this).send(this.get("confirmAction"), this.get("confirmActionArg") || this.get("model"));
        } else {
          this.send("resetModal");
          this._super.apply(this, arguments);
        }
      },
      resetModal: function resetModal() {
        this.set("selectedTemplate", false);
        this.set("title", intl.t("general.how_build_budget_1695957078639"));
      },
      close: function close() {
        this.send("resetModal");
        this._super();
      },
      back: function back() {
        var wizardTitle;
        if (this.get("step") === 2) {
          this.send("resetModal");
          wizardTitle = intl.t("general.wizardTitle _1695957078544");
        } else if (this.get("step") === 3) {
          wizardTitle = intl.t("general.wizardTitle _1695957078555");
        }
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.WIZARD_BACK"), {
          wizard_screen_name: wizardTitle
        }, {
          wizard_screen_name: wizardTitle
        });
        this._super();
      },
      backToVideo: function backToVideo() {
        this.send("resetModal");
        var wizardTitle = intl.t("general.wizardTitle _1695957078544");
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.WIZARD_BACK_TO_VIDEO"), {
          wizard_screen_name: wizardTitle
        }, {
          wizard_screen_name: wizardTitle
        });
        this.send("openVideoModal");
      },
      backFromSelection: function backFromSelection() {
        this.send("resetModal");
      }
    }
  });
});