define("budgeta/components/integration-wizard-select-dimensions-data", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    dimensions: Ember.computed.alias("model.selectDimensionsData"),
    escapedError: Ember.computed("error", function () {
      return Ember.String.htmlSafe(this.get("error"));
    }),
    actions: {
      dimensionValuesChanged: function dimensionValuesChanged(values) {
        var continueBtn = Ember.$(".modal-content .modal-footer #continue-btn");
        var backBtn = Ember.$(".modal-content .modal-footer #back-btn");
        if (values.length) {
          this.set("error", "");
          continueBtn.removeClass("disabled");
          backBtn.removeClass("disabled");
          this.get("dimensions").setEach("unActive", false);
        } else {
          this.set("error", intl.t("general.select_at_least_one_value_to_continue_1698234255562"));
          continueBtn.addClass("disabled");
          backBtn.addClass("disabled");
          this.get("dimensions").filter(function (dim) {
            return dim.selectedValue.length;
          }).setEach("unActive", true);
        }
      }
    }
  });
});