define("budgeta/templates/duplicate-options-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JZp23hxz",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"react-component\"],null,[[\"componentName\",\"onConfirm\",\"onCancel\",\"action\"],[\"DownloadOptionsModal\",[\"helper\",[\"action\"],[[\"get\",[null]],\"duplicateBudget\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"cancel\"],null],\"duplicate\"]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/duplicate-options-modal.hbs"
    }
  });
});