define("budgeta/utils/si-statistical", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _toConsumableArray2, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SIStatistical = {
    getStatisticalStructure: function getStatisticalStructure(statAccounts, topLevelDimension) {
      var statisticalStructure = [];
      if (topLevelDimension) {
        var dimensionLevel = topLevelDimension.isSub ? topLevelDimension.subLevel : 0;
        for (var i = 0; i < topLevelDimension.dimension.values.length; i++) {
          var currDimensionValueObject = topLevelDimension.dimension.values[i];
          if (currDimensionValueObject.level === dimensionLevel || !currDimensionValueObject.children.length && currDimensionValueObject.level < dimensionLevel) {
            var currDimensionValueNode = {
              id: currDimensionValueObject.ID,
              budgetType: "group-model-parent",
              collapse: false,
              dimLevelParent: (0, _defineProperty2.default)({}, topLevelDimension.dimension.dimension, currDimensionValueObject.ID),
              dimension: topLevelDimension.dimension,
              dimensionValue: currDimensionValueObject.ID,
              level: 0,
              name: currDimensionValueObject.NAME,
              parent: null,
              deleted: true,
              expand: true
            };
            currDimensionValueNode.children = SIStatistical.getAccountsStructure(statAccounts, currDimensionValueNode, currDimensionValueObject);
            statisticalStructure.push(currDimensionValueNode);
          }
        }
      } else {
        statisticalStructure.push.apply(statisticalStructure, (0, _toConsumableArray2.default)(SIStatistical.getAccountsStructure(statAccounts, null, null)));
      }
      return statisticalStructure;
    },
    //create accounts nodes (group model) - [{a1...}, {a2...}, {a3...}]
    //each account object points (children) to all its combinations (lines)
    getAccountsStructure: function getAccountsStructure(statAccounts, dimensionParent, topLevelDimensionValue) {
      var structure = statAccounts.reduce(function (selectedAccounts, currAccount) {
        if (currAccount.selected) {
          var accountRoot = {
            name: currAccount.TITLE,
            level: dimensionParent ? 1 : 0,
            totalActuals: 0,
            collapse: false,
            deleted: true,
            parent: dimensionParent,
            budgetType: "group-model"
          };
          accountRoot.children = SIStatistical.getAccountLines(currAccount, accountRoot, topLevelDimensionValue);
          selectedAccounts.push(accountRoot);
        }
        return selectedAccounts;
      }, []);
      return structure;
    },
    isDimensionSubOfDimension: function isDimensionSubOfDimension(dimension, parentDimension) {
      return parentDimension && dimension.values.some(function (value) {
        return value.NAME === parentDimension.NAME && value.ID === parentDimension.ID && value.level === parentDimension.level;
      });
    },
    getDimensionParentByLevel: function getDimensionParentByLevel(dimensionValueObject, level) {
      return function (dimObject, topLevel) {
        var getParent = function getParent(currDimObject) {
          if (!currDimObject.parentRef || topLevel === currDimObject.level) {
            return currDimObject;
          }
          return getParent(currDimObject.parentRef);
        };
        return getParent(dimObject);
      }(dimensionValueObject, level);
    },
    //create the leaves (lines) of the tree
    getAccountLines: function getAccountLines(account, parent, topLevelDimensionValue) {
      var _dimensionsCombinatio;
      var accountId = account.ACCOUNTNO;
      if (!account.levelDimensions || !account.levelDimensions.length) {
        var accountName = account.TITLE;
        var line = {
          accountId: accountId,
          deleted: true,
          level: 1,
          name: accountName,
          collapse: false,
          parent: parent,
          budgetType: "assumption",
          subBudgetType: "stat",
          accounts: [account]
        };
        return [line];
      }
      var accountChildren = [];
      var dimensionsValues = [];
      var dimensionValues = [];
      if (account.levelDimensions && account.levelDimensions.length) {
        //create array of account diemnsions values - [["ENG", "QA", "R&D"],["Dallas","Texas"]]
        account.levelDimensions.forEach(function (currDimension) {
          dimensionValues = [];
          var dimensionLevel = currDimension.isSub ? currDimension.subLevel : 0;
          var currDimensionSubOfTopDimension = SIStatistical.isDimensionSubOfDimension(currDimension.dimension, topLevelDimensionValue);
          currDimension.dimension.values.forEach(function (dimensionValueObject) {
            var dimensionLevelZeroParent;
            if (currDimensionSubOfTopDimension) {
              dimensionLevelZeroParent = SIStatistical.getDimensionParentByLevel(dimensionValueObject, topLevelDimensionValue.level);
            }
            if ((dimensionValueObject.level === dimensionLevel || !dimensionValueObject.children.length && dimensionValueObject.level < dimensionLevel && !dimensionsValues.some(function (d) {
              return d.includes(dimensionValueObject);
            })) && (!dimensionLevelZeroParent || dimensionLevelZeroParent.ID === topLevelDimensionValue.ID)) {
              dimensionValueObject.dimension = currDimension.dimension;
              dimensionValues.push(dimensionValueObject);
            }
          });
          dimensionsValues.push(dimensionValues);
        });
      }
      var dimensionsCombinations = SIStatistical.cartesianProductForDimensionLevels.apply(SIStatistical, dimensionsValues);
      if (!((_dimensionsCombinatio = dimensionsCombinations) !== null && _dimensionsCombinatio !== void 0 && _dimensionsCombinatio.length)) {
        dimensionsCombinations = [[{
          NAME: topLevelDimensionValue.NAME,
          ID: topLevelDimensionValue.ID
        }]];
      }

      //dimensionsCombinations (array of arrays - each element is dimensions values combination) - [["ENG","Dallas"],["ENG","Texas"],["QA","Dallas"],...[]]
      dimensionsCombinations.forEach(function (accountDimensionValues) {
        var dimensionsValuesNames = accountDimensionValues.mapBy("NAME");
        var dimensionsValuesIds = accountDimensionValues.mapBy("ID");
        var accountName = "".concat(account.TITLE, " | ".concat(dimensionsValuesNames.join(" | ")));
        var line = {
          accountId: accountId,
          deleted: true,
          level: topLevelDimensionValue ? 2 : 1,
          name: accountName,
          collapse: false,
          parent: parent,
          budgetType: "assumption",
          reportingType: account.ACCOUNTTYPE,
          accounts: [account],
          dimensions: account.levelDimensions.map(function (dimensionObject) {
            return dimensionObject.dimension;
          }),
          dimensionsValues: dimensionsValuesIds
        };
        accountChildren.push(line);
      });
      return accountChildren;
    },
    cartesianProductForDimensionLevels: function cartesianProductForDimensionLevels() {
      for (var _len = arguments.length, a = new Array(_len), _key = 0; _key < _len; _key++) {
        a[_key] = arguments[_key];
      }
      return a.reduce(function (a, b) {
        return a.flatMap(function (d) {
          return b.reduce(function (all, e) {
            if (!d.length || d.every(function (insertedDim) {
              var _insertedDim$dimensio, _e$dimension;
              return (insertedDim === null || insertedDim === void 0 || (_insertedDim$dimensio = insertedDim.dimension) === null || _insertedDim$dimensio === void 0 ? void 0 : _insertedDim$dimensio.dimension) !== (e === null || e === void 0 || (_e$dimension = e.dimension) === null || _e$dimension === void 0 ? void 0 : _e$dimension.dimension);
            }) || d.some(function (insertedDim) {
              var _insertedDim$dimensio2, _e$dimension2, _SIStatistical$getDim;
              return (insertedDim === null || insertedDim === void 0 || (_insertedDim$dimensio2 = insertedDim.dimension) === null || _insertedDim$dimensio2 === void 0 ? void 0 : _insertedDim$dimensio2.dimension) === (e === null || e === void 0 || (_e$dimension2 = e.dimension) === null || _e$dimension2 === void 0 ? void 0 : _e$dimension2.dimension) && insertedDim.ID === ((_SIStatistical$getDim = SIStatistical.getDimensionParentByLevel(e, insertedDim.level)) === null || _SIStatistical$getDim === void 0 ? void 0 : _SIStatistical$getDim.ID);
            })) {
              all.push([d, e].flat());
            } else if (!all.includes(d)) {
              all.push(d);
            }
            return all;
          }, []);
        });
      }, [[]]);
    },
    // create helper object for update matching line given transaction
    createAccountToLinesTree: function createAccountToLinesTree(structure) {
      var accountToLines = {};
      structure.forEach(function (linesRoot) {
        var topLevelDimensionValueId = linesRoot.budgetType === "group-model-parent" ? linesRoot.dimensionValue : null;
        SIStatistical.createAccountToLinesHelper(linesRoot, topLevelDimensionValueId, accountToLines);
      });
      return accountToLines;
    },
    createAccountToLinesHelper: function createAccountToLinesHelper(node, topLevelDimensionValueId, accountToLines) {
      if (node.budgetType === "assumption") {
        var currLocationInTree = accountToLines;
        var accountKeys = [node.accountId, topLevelDimensionValueId].concat((0, _toConsumableArray2.default)(node.dimensionsValues || [])).filter(function (key) {
          return key;
        });
        var prevLocation;
        accountKeys.forEach(function (key) {
          if (!currLocationInTree[key]) {
            currLocationInTree[key] = {};
          }
          prevLocation = currLocationInTree;
          currLocationInTree = currLocationInTree[key];
        });
        prevLocation[accountKeys.pop()] = node;
      } else {
        var _node$children;
        (_node$children = node.children) === null || _node$children === void 0 || _node$children.forEach(function (child) {
          SIStatistical.createAccountToLinesHelper(child, topLevelDimensionValueId, accountToLines);
        });
      }
    },
    // {accounts:["a1, a2",...], groupby:["location", "department"]}
    getStatAccountRequestDetails: function getStatAccountRequestDetails(statAccounts, topLevelDimension) {
      var accounts = new Set();
      var groupBy = new Set();
      if (topLevelDimension) {
        groupBy.add(topLevelDimension.dimension.internalName.toLowerCase());
      }
      statAccounts.forEach(function (statAcount) {
        accounts.add(statAcount.ACCOUNTNO);
        statAcount.levelDimensions.forEach(function (accountDimension) {
          groupBy.add(accountDimension.dimension.internalName);
        });
      });
      return {
        accounts: (0, _toConsumableArray2.default)(accounts),
        groupBy: (0, _toConsumableArray2.default)(groupBy)
      };
    }
  };
  var _default = _exports.default = SIStatistical;
});