define("budgeta/components/integration-wizard-export-missing-bs", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    }),
    bsAccounts: Ember.computed("budgetService.actualAccounts", function () {
      return (this.get("budgetService.actualAccounts") || Ember.A([])).filterBy("type", "bs");
    }),
    lines: Ember.computed("missingAccounts.bs", function () {
      return (this.get("missingAccounts.bs") || []).map(function (line) {
        var bsLine = Ember.get(line, "bsLine");
        if (bsLine) {
          Ember.setProperties(line, {
            displayName: _const.default.BALANCE_SHEET_LINES.findBy("name", Ember.get(line, "bsLine")).displayName
          });
        }
        return line;
      });
    }),
    didRender: function didRender() {
      this.sendAction(this.get("continueActionName"), this.get("model.isSkipSteps.exportMissingBs") ? intl.t("general.skip_1698234255443") : "");
    },
    actions: {
      attributeChanged: function attributeChanged() {
        this.set("model.isSkipSteps.exportMissingBs", false);
        this.sendAction(this.get("continueActionName"), "");
      }
    }
  });
});