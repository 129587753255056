define("budgeta/admin/company-admin-app/store/reducers/index", ["exports", "budgeta/admin/company-admin-app/store/reducers/companyReducer", "budgeta/admin/store/reducers/securityReducer", "budgeta/admin/store/reducers/notifReducer"], function (_exports, _companyReducer, _securityReducer, _notifReducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = window.Redux.combineReducers({
    companyStore: _companyReducer.default,
    securityStore: _securityReducer.default,
    notifStore: _notifReducer.default
  });
});