define("budgeta/templates/components/display-account-ids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "k0/M8yXR",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"sortedAccounts\",\"length\"]]],null,3]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"wrapper\"],[\"flush-element\"],[\"text\",\")\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"spliter\"],[\"flush-element\"],[\"text\",\" | \"],[\"close-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"value-display\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"highlight\"],[[\"get\",[\"otherAccount\"]],[\"get\",[\"treeManager\",\"searchValue\"]]],null],false],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"wrapper\"],[\"flush-element\"],[\"text\",\"(\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"showParenthesis\"]]],null,2],[\"text\",\"  \"],[\"open-element\",\"span\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"value-display-first\",[\"helper\",[\"if\"],[[\"get\",[\"shortAccount\"]],\" short\"],null]]]],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"highlight\"],[[\"get\",[\"firstAccount\"]],[\"get\",[\"treeManager\",\"searchValue\"]]],null],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"otherAccount\"]]],null,1],[\"block\",[\"if\"],[[\"get\",[\"showParenthesis\"]]],null,0]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/display-account-ids.hbs"
    }
  });
});