define("budgeta/components/b-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["alert"],
    init: function init() {
      this._super();
      if (this.get("type")) {
        this.get("classNames").push("alert-" + this.get("type"));
      }
    }
  });
});