define("budgeta/utils/videosUtil/const", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var VIDEOS = {
    "inputs-main": [{
      title: intl.t("sip-video-list.inputs-main.getting-started"),
      youtubeId: "P02zqf4tQ6M"
    }, {
      title: intl.t("sip-video-list.inputs-main.budget-structure"),
      youtubeId: "3aGX1Ol55uI"
    }, {
      title: intl.t("sip-video-list.inputs-main.create-budget"),
      youtubeId: "DGYUR1pOUiM"
    }, {
      title: intl.t("sip-video-list.inputs-main.budget-line-types"),
      youtubeId: "higzz48Zjq4"
    }, {
      title: intl.t("sip-video-list.inputs-main.budget-tree-menu"),
      youtubeId: "K1eHMwi26tw"
    }, {
      title: intl.t("sip-video-list.inputs-main.employees-import"),
      youtubeId: "tElaCU8pv1A"
    }, {
      title: intl.t("sip-video-list.inputs-main.employees-types"),
      youtubeId: "UuUUl_lgz6w"
    }, {
      title: intl.t("sip-video-list.inputs-main.employees-vacation"),
      youtubeId: "Ben2q3JLLy0"
    }, {
      title: intl.t("sip-video-list.inputs-main.employees-headcount"),
      youtubeId: "vQlyfzJr8Zk"
    }],
    "inputs-model": [{
      title: intl.t("sip-video-list.inputs-model.understanding-models"),
      youtubeId: "510KDF1G200"
    }],
    administration: [{
      title: intl.t("sip-video-list.admin.getting-started-admin"),
      youtubeId: "4noMZmfHgYg"
    }]
  };
  var _default = _exports.default = VIDEOS;
});