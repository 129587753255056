define("budgeta/components/wizard-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":wizard-step", "isPastStep:past", "isNextStep:next", "isFutureStep:future", "isCurrentStep:current"],
    isCurrentStep: Ember.computed("currentStep", "stepIndex", function () {
      return this.get("currentStep") === this.get("stepIndex");
    }),
    isPastStep: Ember.computed("currentStep", "stepIndex", function () {
      return this.get("currentStep") > this.get("stepIndex");
    }),
    isFutureStep: Ember.computed("currentStep", "stepIndex", function () {
      return this.get("currentStep") < this.get("stepIndex");
    }),
    isNextStep: Ember.computed("currentStep", "stepIndex", function () {
      return this.get("currentStep") === this.get("stepIndex") - 1;
    }),
    click: function click() {
      this.sendAction("action", this.get("stepIndex"));
    }
  });
});