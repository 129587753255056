define("budgeta/components/select-2-dimension-value", ["exports", "budgeta/services/ember-service-container", "ember-select-2/components/select-2"], function (_exports, _emberServiceContainer, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = _select.default.extend({
    budgetService: Ember.inject.service(),
    cssClass: Ember.computed("cssClassNames", "dimension.name", function () {
      if (!this.get("cssClassNames")) {
        return "select-2-typeahead";
      }
      return "".concat(this.get("cssClassNames"), " ").concat((this.get("dimension.name") ? this.get("dimension.name") + "-dropdown" : "").toLowerCase());
    }),
    title: Ember.computed("value", function () {
      return this.get("value.displayName");
    }),
    // layoutName: "select-2",
    allowDuplicates: false,
    typeaheadNoMatchesText: Ember.computed("dimension.name", "budgetService.scenario", function () {
      return this.get("budgetService.scenario") ? intl.t("general.select-2-dimension-value-1", {
        var1: this.get("dimension.name").toLowerCase()
      }) : intl.t("general.select-2-dimension-value-2", {
        var1: this.get("dimension.name").toLowerCase()
      });
    }),
    optionIdPath: "uniqueId",
    optionLabelPath: "displayName",
    optionHeadlinePath: "displayName",
    addMissingAction: "addNewValue",
    value: Ember.computed("targetValue", "targetProperty", "listenOnValue", {
      get: function get() {
        var value = this.get("targetValue") ? Ember.get(this.get("targetValue"), this.get("targetProperty")) : undefined;
        return value ? this.get("content").findBy("uniqueId", value) : value;
      },
      set: function set(key, value) {
        Ember.set(this.get("targetValue"), this.get("targetProperty"), value && Ember.get(value, "uniqueId"));
        if (this.get("attributeChanged")) {
          this.sendAction("attributeChanged", this.get("targetValue"), this.get("targetProperty"));
        }
        return value;
      }
    }),
    actions: {
      onOptionSelecting: function onOptionSelecting() {
        var onOptionSelecting = this.get("onOptionSelecting");
        if (onOptionSelecting) {
          this.sendAction(onOptionSelecting);
        }
      },
      addNewValue: function addNewValue(value) {
        if (!this.get("budgetService.scenario")) {
          var newValue = this.get("dimension").addValue(value, {
            budgetLineParent: this.get("budgetLine.budgetModule.isGroup") ? this.get("budgetLine.parent") : null
          });
          if (newValue) {
            this.set("value", newValue);
          }
        }
      }
    }
  });
});