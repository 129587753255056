define("budgeta/components/content-budget-tree-master", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    collapsedBudgets: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    // used in hbs
    classNameBindings: ["viewSelections.isLeafDisplay:leaf-mode"],
    tagName: "a",
    attributeBindings: ["title"],
    title: Ember.computed.alias("budget.title"),
    getContentClassName: Ember.computed("budget.accountIdsList.length", "accountBeforeTitle", "budget.isScenarioRemoved", function () {
      var isScenarioRemoved = this.get("budget.isScenarioRemoved");
      var shouldDisplayAccountBeforeTitle = this.get("accountBeforeTitle") && (this.get("budget.accountIdsList") || []).length > 0;
      var classes = ["budget-name-text", isScenarioRemoved && "strike scenarioRemoved", shouldDisplayAccountBeforeTitle && "account-before-title"].filter(function (cls) {
        return cls;
      });
      return classes.join(" ");
    }),
    showBudgetTitle: Ember.computed("viewSelections.treeViewOption.key", "budget.accountIdsList.length", function () {
      return this.get("viewSelections.treeViewOption.key") !== "account" || !this.get("budget.accountIdsList.length");
    }),
    accountBeforeTitle: Ember.computed("viewSelections.treeViewOption.key", function () {
      return this.get("viewSelections.treeViewOption.key") === "both" || this.get("viewSelections.treeViewOption.key") === "leaf";
    }),
    hasAccountIdMinWidth: Ember.computed("budgetService.accountsInInput", "budget.title.length", function () {
      return this.get("budgetService.accountsInInput") && this.get("budget.title.length") > 6;
    }),
    actions: {
      goto: function goto() {
        this.sendAction("goto", this.get("budget"));
      },
      collapse: function collapse() {
        var _this = this;
        if (this.get("isCollapsed")) {
          if (this.get("isRoot")) {
            Ember.get(this.get("budget"), "children").forEach(function (child) {
              _this.get("collapsedBudgets").removeTreeItem(child, true, _this.get("inSearchMode"));
            });
            this.get("collapsedBudgets").removeTreeItem(this.get("budget"), false, this.get("inSearchMode"));
          } else {
            this.get("collapsedBudgets").removeTreeItem(this.get("budget"), true, this.get("inSearchMode"));
          }
        } else {
          if (this.get("isRoot")) {
            Ember.get(this.get("budget"), "children").forEach(function (child) {
              _this.get("collapsedBudgets").addTreeItem(child, true, _this.get("inSearchMode"));
            });
            this.get("collapsedBudgets").addTreeItem(this.get("budget"), false, this.get("inSearchMode"));
          } else {
            this.get("collapsedBudgets").addTreeItem(this.get("budget"), true, this.get("inSearchMode"));
          }
        }
        if (this.get("inSearchMode")) {
          this.set("isCollapsed", !this.get("isCollapsed"));
        } else {
          this.get("collapsedBudgets").save();
        }
        this.sendAction("collapse", this.get("inSearchMode"));
      }
    }
  });
});