define("budgeta/components/react/grid-components/budget-lines-grid", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "@sibp/ui-components", "budgeta/admin/store/actions/notifActions", "budgeta/services/ember-service-container", "budgeta/utils/grid/grid-filters-utils", "react", "react-redux", "budgeta/store/store", "budgeta/components/react/grid-components/filters/filters", "budgeta/components/react/grid-components/grid-main"], function (_exports, _toConsumableArray2, _slicedToArray2, _uiComponents, _notifActions, _emberServiceContainer, _gridFiltersUtils, _react, _reactRedux, _store2, _filters, _gridMain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var trackingEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
  var intl = new _emberServiceContainer.default().lookup("intl");
  var multipleRowsChangesError = intl.t("grid.multiple-rows-changes-error");
  var _store = (0, _store2.getStore)();
  var GridNotifications = function GridNotifications(_ref) {
    var showLoader = _ref.showLoader;
    var dispatch = _reactRedux.default.useDispatch();
    var noty = _reactRedux.default.useSelector(function (state) {
      return state.notifStore.noty;
    });
    var closeNoty = function closeNoty() {
      dispatch(_notifActions.default.hideNoty());
    };
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Loader, {
      visible: showLoader
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Noty, {
      timeout: 2000,
      dataHook: "noty-grid-multiple-rows-change",
      text: multipleRowsChangesError,
      visible: noty.visible,
      onClose: closeNoty
    }));
  };
  var BudgetLinesGrid = function BudgetLinesGrid(_ref2) {
    var exitGrid = _ref2.exitGrid,
      updateGridParams = _ref2.updateGridParams,
      selectedBudgetId = _ref2.selectedBudgetId,
      displayedTreeItems = _ref2.displayedTreeItems,
      scenario = _ref2.scenario,
      cacheUpdatedTime = _ref2.cacheUpdatedTime,
      selectBudget = _ref2.selectBudget,
      openFormulaEditor = _ref2.openFormulaEditor;
    var _React$useState = _react.default.useState([]),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      gridFilters = _React$useState2[0],
      setGridFilters = _React$useState2[1];
    var _React$useState3 = _react.default.useState(false),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      showLoader = _React$useState4[0],
      setShowLoader = _React$useState4[1];
    var _React$useState5 = _react.default.useState(false),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      isSkeletonShown = _React$useState6[0],
      switchSkeleton = _React$useState6[1];
    var _React$useState7 = _react.default.useState(false),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      isCopyClicked = _React$useState8[0],
      setIsCopyClicked = _React$useState8[1];
    var onCopyClick = function onCopyClick() {
      setIsCopyClicked(true);
    };
    var updateFilter = function updateFilter(selection) {
      var id = selection.id,
        name = selection.name,
        value = selection.value;
      var filters = (0, _toConsumableArray2.default)(gridFilters);
      var selectedFilter = filters.find(function (item) {
        return item.id === id;
      });
      if (selectedFilter.isMultiple) {
        var valueIds = Object.prototype.hasOwnProperty.call(value, "id") ? [value.id] : Object.values(value);
        if (valueIds.length > 1) {
          trackingEvents.trackEvent(trackingEvents.events.GRID.FILTERS.BUILD_MULTI_SELECTION_EVENT_OF({
            filterName: selection.name
          }));
        }
        if (selectedFilter.isDimension) {
          _gridFiltersUtils.default.setEmberDimension(id, valueIds);
        } else {
          if (selectedFilter.isAccount) {
            valueIds = _gridFiltersUtils.default.setGridAccounts(valueIds);
          } else if (selectedFilter.isTag) {
            valueIds = _gridFiltersUtils.default.setGridTags(valueIds);
          }
          updateGridParams({
            name: name,
            value: valueIds
          });
        }
      } else {
        trackingEvents.trackEvent(trackingEvents.events.GRID.FILTERS.BUILD_FILTER_EVENT_OF({
          filterName: selection.name
        }));
        viewSelections.set("".concat(name), value.id);
        updateGridParams({
          name: name,
          value: value.id
        });
      }
      viewSelections.set("gridFiltersUpdatedTime", new Date().getTime());
    };
    var onFiltersChange = function onFiltersChange(selection) {
      switchSkeleton(true);
      setTimeout(function () {
        if (Object.keys(selection.value).length === 0) {
          selection.value = {
            0: "all"
          };
        }
        updateFilter(selection);
      });
    };
    var onFilterVisibilityChange = function onFilterVisibilityChange(id) {
      var filters = (0, _toConsumableArray2.default)(gridFilters);
      var filter = filters.find(function (item) {
        return item.id === id;
      });
      filter.hidden = !filter.hidden;
      viewSelections.set("gridFiltersVisibility.".concat(filter.id), filter.hidden);
      //for dimensions && account filters - set empty choice on hidden, and update data accordingly
      if ((filter.isDimension || filter.isAccount || filter.isTag) && filter.hidden) {
        if (filter.isDimension) {
          _gridFiltersUtils.default.setEmberDimension(filter.id, "all");
        } else if (filter.isAccount) {
          viewSelections.set("gridAccounts", []);
        } else if (filter.isTag) {
          viewSelections.set("gridTags", []);
        }
        if (filter.isMultiple) {
          filter.currentSelectedValues = [];
        } else {
          filter.currentIndex = 0;
        }
        //triger useEffect -> updateData
        viewSelections.set("gridFiltersUpdatedTime", new Date().getTime());
      }
      setGridFilters(filters);
    };
    var filtersSetup = function filtersSetup() {
      var gridFiltersRes = _gridFiltersUtils.default.getGridFilters();
      setGridFilters(gridFiltersRes);
      setTimeout(function () {
        viewSelections.set("gridFiltersUpdatedTime", new Date().getTime());
      });
    };
    _react.default.useEffect(function () {
      filtersSetup();
    }, [cacheUpdatedTime]);

    /*   dedicated useEffect for scenario change:
    side effects:
    1.reneder skeleton on scenario change
    2.gridBudgetOption changed to "bud"
    3.clear budget option filter -  recreate filters
    */
    _react.default.useEffect(function () {
      switchSkeleton(true);
      scenario && viewSelections.set("gridBudgetOption", "bud");
      updateGridParams({
        name: "gridBudgetOption",
        value: "bud"
      });
      filtersSetup();
    }, [scenario]);
    return _react.default.createElement(_reactRedux.default.Provider, {
      store: _store
    }, _react.default.createElement("div", {
      className: "budget-lines-grid"
    }, _react.default.createElement(GridNotifications, {
      showLoader: showLoader
    }), _react.default.createElement("div", {
      className: "grid-header"
    }, _react.default.createElement("div", {
      className: "grid-filters"
    }, _react.default.createElement(_filters.default, {
      onChange: onFiltersChange,
      onFilterVisibilityChange: onFilterVisibilityChange,
      options: gridFilters,
      key: JSON.stringify(gridFilters)
    })), _react.default.createElement("div", {
      className: "grid-control"
    }, _react.default.createElement("div", {
      className: "grid-toggle-area grid-switch-on",
      "data-hook": "grid-switch",
      role: "button",
      tabIndex: "0",
      onClick: function onClick() {
        return exitGrid();
      },
      onKeyUp: function onKeyUp() {
        return exitGrid();
      }
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.ToggleSwitch, {
      checked: true,
      round: true,
      label: intl.t("grid.grid-entry")
    })))), _react.default.createElement(_gridMain.default, {
      isCopyClicked: isCopyClicked,
      onCopyClick: onCopyClick,
      account: viewSelections.get("gridAccounts"),
      period: viewSelections.get("gridPeriod"),
      currency: viewSelections.get("gridCurrency"),
      viewType: viewSelections.get("gridViewType"),
      budgetOption: viewSelections.get("gridBudgetOption"),
      scale: viewSelections.get("gridScale"),
      exitGrid: exitGrid,
      selectedBudgetId: selectedBudgetId,
      displayedTreeItems: displayedTreeItems,
      scenario: scenario,
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      isSkeletonShown: isSkeletonShown,
      switchSkeleton: switchSkeleton,
      selectBudget: selectBudget,
      openFormulaEditor: openFormulaEditor
    })));
  };
  var _default = _exports.default = BudgetLinesGrid;
});