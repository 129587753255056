define("budgeta/components/edit-line-design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["edit-line-design", "budgeta-type-value", "budgeta-type-value-design", "budget-without-highlight-value-border"],
    actions: {
      selectBold: function selectBold() {
        this.set("editLine.budgetAttributes.displayBold", !this.get("editLine.budgetAttributes.displayBold"));
      },
      selectColor: function selectColor() {
        var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
        this.set("editLine.budgetAttributes.displayColor", color);
      }
    }
  });
});