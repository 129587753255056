define("budgeta/validators/local/period-validator", ["exports", "budgeta/services/ember-service-container", "ember-validations/validators/base", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _base, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _base.default.extend({
    call: function call() {
      var _this$options = this.options,
        field = _this$options.field,
        _this$options$min = _this$options.min,
        min = _this$options$min === void 0 ? 1 : _this$options$min,
        _this$options$max = _this$options.max,
        max = _this$options$max === void 0 ? 60 : _this$options$max;
      if (!Ember.isBlank(this.model.get(this.property))) {
        var period = _const.default.convertToNum(this.model.get(this.property));
        if (typeof period === "number") {
          if (period < min || period > max) {
            // TODO: i18n
            return this.errors.pushObject(intl.t("general.period-validator-1", {
              var1: field,
              var2: min,
              var3: max
            }));
          }
        } else {
          this.errors.pushObject(intl.t("general.period-validator-2", {
            var1: field
          }));
        }
      }
    }
  });
});