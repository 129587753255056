define("budgeta/components/integration-wizard-export-budget-settings", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects */
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    }),
    isCreateNewBudget: Ember.computed.equal("model.exportSelection", "create"),
    startDate: Ember.computed("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate", function () {
      return new Date(this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate")).format("{MM}/{yyyy}");
    }),
    endDate: Ember.computed("budgetService.selectedRoot.absoluteRoot.budgetAttributes.endDate", function () {
      return new Date(this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.endDate")).format("{MM}/{yyyy}");
    }),
    getAddLabel: Ember.computed("startDate", "endDate", function () {
      return intl.t("general.add_to__1698234255412") + this.get("startDate") + " - " + this.get("endDate");
    }),
    getReplaceLabel: Ember.computed("startDate", "endDate", function () {
      return intl.t("general.replace__1698234255412") + this.get("startDate") + " - " + this.get("endDate");
    }),
    budgetsList: Ember.computed("model.budgetsList", function () {
      return (this.get("model.budgetsList") || []).map(function (b) {
        var isConsolidated = b.ISCONSOLIDATED === "true" ? true : false;
        var isPrivate = b.MEGAENTITYID ? true : false;
        var budgetText = isConsolidated ? intl.t("general.integration-wizard-export-budget-settings-1", {
          var1: b.BUDGETID
        }) : isPrivate ? intl.t("general.integration-wizard-export-budget-settings-2", {
          var1: b.BUDGETID
        }) : b.BUDGETID;
        return {
          id: b.BUDGETID,
          recordNumber: b.RECORDNO,
          text: budgetText,
          budgetHeader: b,
          selectedEntity: b.MEGAENTITYID,
          isConsolidated: isConsolidated,
          currency: b.CURRENCY
        };
      }).sortBy("id");
    }),
    budgetTypes: Ember.computed("model.isMultiCurrency", function () {
      var types = [{
        name: intl.t("general.name_1695611601461"),
        id: "top"
      }, {
        name: intl.t("general.name_1695611601450"),
        id: "consolidated"
      }, {
        name: intl.t("general.name_1695611601441"),
        id: "private"
      }];
      if (!this.get("model.isMultiCurrency")) {
        types.splice(1, 1);
      }
      return types;
    }),
    currencyList: Ember.computed("model.entities", function () {
      return this.get("model.entities").mapBy("currency").filter(function (value, index, array) {
        return array.indexOf(value) === index;
      }).map(function (currency) {
        return {
          name: currency,
          id: currency
        };
      }).sortBy("name");
    }),
    isMultiEntity: Ember.computed.alias("model.entities"),
    showEntityDropdown: Ember.computed("model.selectedBudgetType", function () {
      if (this.get("model.selectedBudgetType") === "private") {
        return true;
      }
      this.set("model.selectedEntity", "");
      return false;
    }),
    showCurrencyDropdown: Ember.computed("model.selectedBudgetType", function () {
      if (this.get("model.selectedBudgetType") === "consolidated") {
        return true;
      }
      this.set("model.selectedCurrency", "");
      return false;
    })
  });
});