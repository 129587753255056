define("budgeta/templates/components/svg/profile-management-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xilz6dph",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"20\"],[\"static-attr\",\"height\",\"20\"],[\"static-attr\",\"viewBox\",\"0 0 20 20\"],[\"static-attr\",\"fill\",\"none\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M15 10C17.8 10 20 12.2 20 15C20 17.8 17.8 20 15 20C12.2 20 10 17.8 10 15C10 12.2 12.2 10 15 10ZM9.8 12C9.3 12.9 9 13.9 9 15C9 17 9.9 18.7 11.4 19.8C10.6 19.9 9.8 20 9 20C7 20 5.1 19.6 3.7 18.8C1.9 17.9 1 16.5 1 14.8V14C1 12.9 1.9 12 3 12H9.8ZM17.0701 13.1407L17 13.2L13.9 16.3L12.8 15.2C12.6 15 12.3 15 12.1 15.2C11.9222 15.3778 11.9025 15.6346 12.0407 15.8299L12.1 15.9L13.6 17.4C13.7 17.5 13.8 17.5 14 17.5C14.1667 17.5 14.2639 17.5 14.3495 17.4421L14.4 17.4L17.9 13.9C18 13.7 17.9 13.4 17.7 13.2C17.5222 13.0222 17.2654 13.0025 17.0701 13.1407ZM9 0C11.7614 0 14 2.23858 14 5C14 7.76142 11.7614 10 9 10C6.23858 10 4 7.76142 4 5C4 2.23858 6.23858 0 9 0Z\"],[\"static-attr\",\"fill\",\"black\"],[\"static-attr\",\"fill-opacity\",\"0.65\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/profile-management-svg.hbs"
    }
  });
});