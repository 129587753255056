define("budgeta/components/file-drop", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":file-drop", "over:over"],
    over: false,
    bottomMessage: intl.t("general.drag_a_file_here_to_upload_or_1698234255291"),
    dropMessage: intl.t("general.drop_file_anywhere_to_upload!_1698234255291"),
    setBeforeContent: function setBeforeContent() {
      var _this = this;
      Ember.run.scheduleOnce("afterRender", function () {
        var el = document.getElementById(_this.elementId);
        var importSelectFile = el.querySelector(".import-select-file");
        importSelectFile.setAttribute("data-before", intl.t("general.click_here_to_browse_for_one"));
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setBeforeContent();
    },
    dragOver: function dragOver(e) {
      e.preventDefault();
    },
    dragEnter: function dragEnter(e) {
      e.preventDefault();
      this.set("over", true);
    },
    dragLeave: function dragLeave(e) {
      e.preventDefault();
      this.set("over", false);
      this.setBeforeContent();
    },
    drop: function drop(e) {
      e.preventDefault();
      this.set("over", false);
      this.sendAction("action", e.dataTransfer.files[0]);
    }
  });
});