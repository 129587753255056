define("budgeta/utils/dashboard", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/forecast", "budgeta/utils/const", "budgeta/utils/dimensions", "lodash/lodash"], function (_exports, _emberServiceContainer, _forecast, _const, _dimensions, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable no-prototype-builtins, ember/new-module-imports */

  var BVA = "#bva#";
  var mapHeadcountObjectToHighchartArray = function mapHeadcountObjectToHighchartArray(highchartDataMap, stack) {
    var categories = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var highchartData = [];
    for (var key in highchartDataMap) {
      var dataValues = highchartDataMap[key].data;
      var keys = highchartDataMap[key].keys;
      var categoryIndex = 0;
      if (categories.length) {
        while (dataValues.length && categories[0] > keys[0]) {
          // remove data that is before the categories date range
          dataValues.shift();
        }
        while (categoryIndex < categories.length && categories[categoryIndex] < keys[0]) {
          // padding with 0
          dataValues.unshift(0);
          categoryIndex++;
        }
        dataValues.length = categories.length;
      }
      var data = {
        name: key,
        data: dataValues,
        level: highchartDataMap[key].level,
        stack: stack
      };
      if (highchartDataMap[key].hasOwnProperty("color")) {
        data.color = highchartDataMap[key].color;
      }
      highchartData.push(data);
    }
    return highchartData;
  };
  var mapObjectToArray = function mapObjectToArray(object, getKey) {
    var newArray = [];
    var key;
    if (getKey) {
      for (key in object) {
        newArray.push(Ember.get(object[key], getKey));
      }
    } else {
      for (key in object) {
        newArray.push(object[key]);
      }
    }
    return newArray;
  };
  var currencyArrayToSymbols = function currencyArrayToSymbols(currencyArr) {
    return currencyArr.map(function (item) {
      return _const.default.getCurrencySymbol(item) || "";
    });
  };
  var changeDateFormatArray = function changeDateFormatArray(arr, period, options) {
    var ignoreYearly = options && options.ignoreYearly || false;
    return arr.map(function (item) {
      var newDate = Date.create(item);
      if (!newDate.isValid()) {
        newDate = Date.create(item + "/01");
      }
      return newDate.isValid() ? period === "Yearly" && !ignoreYearly ? newDate.format("{yyyy}") : newDate.format("{mon} {yy}").toUpperCase() : item;
    });
  };

  // var getTotalHeadCount = function(headCount) {
  //     var total = 0;
  //     for (var key in headCount) {
  //         total += headCount[key].count || 0;
  //     }
  //     return total;
  // };

  var colorPalette = {
    simple: ["#39da95", "#44a3e5", "#ff5e4c", "#9691f5", "#ffab51", "#75e2e4", "#fdd700", "#db68c9", "#cf8163", "#799cae", "#cbcdc8"],
    SaasColorPalette: ["#39da95", "#44a3e5", "#ff5e4c", "#9691f5"]
  };
  var DashboardUtil = {
    colorPalette: colorPalette,
    colorSchema: {
      rev: colorPalette.simple,
      "rev-growth": colorPalette.simple,
      "deferred-rev-ar": colorPalette.simple,
      "bookings-by": colorPalette.simple,
      bookings: colorPalette.simple,
      "bookings-type": colorPalette.simple,
      "bookings-cash-rev": colorPalette.simple,
      "operating-expenses": colorPalette.simple,
      "operating-expenses-pie": colorPalette.simple,
      "expenses-by-category": colorPalette.simple,
      "operating-expenses-revenue": colorPalette.simple,
      hc: colorPalette.simple,
      "revenues-per-employee": colorPalette.simple,
      "hc-new": colorPalette.simple,
      "avg-salary-per-employee": colorPalette.simple,
      "net-margin": colorPalette.simple,
      "gross-margin": colorPalette.simple,
      cash: colorPalette.simple,
      "net-reveunes-margin": colorPalette.simple,
      saas: colorPalette.SaasColorPalette,
      saas2: colorPalette.simple,
      "saas-y": colorPalette.SaasColorPalette,
      "saas-y2": colorPalette.simple,
      kpiChart: colorPalette.simple,
      "single-kpi": colorPalette.simple,
      simple: colorPalette.simple
    },
    removeOutOfRangeKeys: function removeOutOfRangeKeys(obj, startDate, endDate) {
      var cloneObj = _const.default.deepClone(obj);
      var keys = Object.keys(cloneObj);
      for (var i = 0; i < keys.length; i++) {
        var tempDate = new Date(keys[i]);
        if (tempDate.isBefore(startDate) || tempDate.isAfter(endDate)) {
          delete cloneObj[keys[i]];
        }
      }
      return cloneObj;
    },
    greyColorSchema: ["#939393", "#999999", "#a3a3a3", "#b3b3b3", "#c3c3c3"],
    getColorPalette: function getColorPalette() {
      return DashboardUtil.colorPalette.simple;
    },
    getChartWidth: function getChartWidth(isPopup) {
      if (isPopup) {
        return Math.round(Ember.$(document).width() * 0.8) - 72;
      }
      return 530;
    },
    getChartHeight: function getChartHeight(isPopup, showLegend) {
      var addLegend = showLegend ? 10 : 0;
      if (isPopup) {
        return Math.round(Ember.$(document).height() * 0.8) - 166 + addLegend;
      }
      return 310 + addLegend;
    },
    changeMapToArrayAndSort: function changeMapToArrayAndSort(map, order) {
      var newArr = [];
      for (var key in map) {
        newArr.push({
          name: key,
          value: map[key]
        });
      }
      if (order === "desc") {
        return newArr.sort(function (a, b) {
          return a.value - b.value;
        });
      }
      return newArr.sort(function (a, b) {
        return b.value - a.value;
      });
    },
    sortChartData: function sortChartData(chartData, order) {
      var totals = {};
      var sortChartData = [];
      chartData.forEach(function (data) {
        var tempData = data.data;
        if (Ember.isEmpty(totals[data.name])) {
          totals[data.name] = 0;
        }
        if (!Ember.isEmpty(tempData)) {
          for (var i = 0; i < tempData.length; i++) {
            totals[data.name] = totals[data.name] + tempData[i];
          }
        }
      });
      var totalsArr = DashboardUtil.changeMapToArrayAndSort(totals, order);
      totalsArr.forEach(function (total) {
        sortChartData.push(chartData.findBy("name", total.name));
      });
      return sortChartData;
    },
    calcCashBalance: function calcCashBalance(controller, budget, chart, currency, scenario, addScenario) {
      return new Ember.RSVP.Promise(function (resolve) {
        var forecastViewOption = controller.get("forecastViewOption"),
          vsOption = controller.get("vsOption"),
          isRollingForecast = controller.get("isRollingForecastViewType"),
          isFvB = controller.get("isFvB"),
          dimensions = controller.get("dimensions"),
          periodType = chart && chart.get("periodType") ? chart.get("periodType") : "Monthly",
          options = Ember.Object.create({
            selectedScenario: scenario ? "#" : "",
            scenario: scenario === BVA ? null : scenario,
            currency: budget.get("forecastAttributes.currency"),
            type: "cf",
            dimensions: dimensions,
            selectedPeriod: periodType,
            actualViewOption: isRollingForecast || vsOption === "#" && forecastViewOption ? "rollf" : forecastViewOption ? "ba" : undefined,
            budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
            forecastViewOption: forecastViewOption ? forecastViewOption : undefined,
            vsOption: vsOption ? vsOption : undefined
          }),
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          cashFlowData,
          cashFlowDataByCompany,
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          isInteractiveDashboardType = controller.get("scenario") && controller.get("isInteractiveDashboardType") ? "Modified" : null,
          cache = controller.get("cache"),
          value_factors = {},
          budgetId = budget.id;
        var promiseCallback = function promiseCallback(cashFlowData) {
          cashFlowDataByCompany = [cashFlowData];
          var cashBalanceData = [],
            cfValues,
            cfValues2;
          value_factors = isRollingForecast ? DashboardUtil.getActualsKeys(cashFlowData.value_factors) : {};
          cashFlowDataByCompany.forEach(function (cfData) {
            cfValues = (cfData.root.values || Ember.A([])).mapBy(scenario === BVA ? "cashFlowTotal.firstObject.convertedActualValue" : "cashFlowTotal.firstObject.value");
            cfValues2 = (cfData.root.values || Ember.A([])).mapBy(scenario === BVA ? "cashFlowTotal.firstObject.value" : "cashFlowTotal.firstObject.value2");
            addScenario = isInteractiveDashboardType || addScenario;
            cashBalanceData.pushObject({
              name: intl.t("general.cash_balance") + (cashFlowDataByCompany.length > 1 ? cfData.forecast.name + " " : "") + DashboardUtil.getFirstSeriesName(controller),
              data: cfValues,
              displayName: DashboardUtil.getFirstSeriesName(controller),
              stack: DashboardUtil.getFirstStackName(controller)
            });
            if (scenario && isBudgetVsScenario || isFvB) {
              var budgetData = {
                name: intl.t("general.cash_balance").trim() + (cashFlowDataByCompany.length > 1 ? " " + cfData.forecast.name : "") + " " + DashboardUtil.getSecondSeriesName(controller),
                data: cfValues2,
                displayName: DashboardUtil.getSecondSeriesName(controller),
                stack: DashboardUtil.getSecondStackName(controller)
              };
              if (isInteractiveDashboardType) {
                budgetData.color = DashboardUtil.greyColorSchema[0];
              }
              cashBalanceData.pushObject(budgetData);
            }
          });
          var highchartCategories = [];
          if (cashFlowDataByCompany.length > 0 && Ember.get(cashFlowDataByCompany, "0.root.values")) {
            Ember.get(cashFlowDataByCompany, "0.root.values").forEach(function (value) {
              highchartCategories.push(Ember.get(value, "key"));
            });
          }
          highchartCategories = changeDateFormatArray(highchartCategories, chart.get("periodType"));
          if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
            var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
              newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
            var _DashboardUtil$getSta = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
              startIndex = _DashboardUtil$getSta.startIndex,
              endIndex = _DashboardUtil$getSta.endIndex;
            highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
            DashboardUtil.changeDataPeriod(cashBalanceData, startIndex, endIndex);
          }
          resolve({
            highchartData: cashBalanceData,
            highchartCategories: highchartCategories,
            value_factors: value_factors
          });
        };
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
          selectedPeriod: periodType
        });
        options.store = controller.get("store");
        options = Ember.Object.create(options);
        cashFlowData = cache.getItem(budgetId, "getForecastDataModel", hashOptions);
        if (!cashFlowData) {
          _forecast.default.getForecastDataModel(options, cache, "cf", budgetId, options, controller).then(function (cashFlowData) {
            cache.setItem(budgetId, "getForecastDataModel", hashOptions, cashFlowData);
            promiseCallback(cashFlowData);
          });
        } else {
          promiseCallback(cashFlowData);
        }
      });
    },
    calcEndOfCash: function calcEndOfCash(controller, budget, chart, scenario) {
      return new Ember.RSVP.Promise(function (resolve) {
        // end of cash
        var EOC = null,
          EOC2 = null,
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          endTime = new Date(budgetEndDate),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          checkPeriod = chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf(),
          cache = controller.get("cache"),
          store = controller.get("store"),
          cashFlowData,
          isRollingForecast = controller.get("isRollingForecastViewType"),
          isFvB = controller.get("isFvB"),
          forecastViewOption = controller.get("forecastViewOption"),
          vsOption = controller.get("vsOption"),
          budgetId = budget.id;
        var options = {
          selectedScenario: scenario ? "#" : "",
          scenario: scenario,
          currency: budget.get("forecastAttributes.currency"),
          type: "cf",
          selectedPeriod: "Monthly",
          actualViewOption: isRollingForecast ? "rollf" : undefined,
          budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
          forecastViewOption: forecastViewOption ? forecastViewOption : undefined,
          vsOption: vsOption ? vsOption : undefined
        };
        var promiseCallback = function promiseCallback(cashFlowData) {
          var calcEOC = function calcEOC(prop) {
            var deltas = [];
            var lastValue = null;
            var result = null;
            if (cashFlowData && cashFlowData.root && cashFlowData.root.values) {
              cashFlowData.root.values.forEach(function (value) {
                var thisValue = Ember.get(value, "cashFlowTotal.firstObject." + prop);
                if (lastValue) {
                  deltas.push(thisValue - lastValue);
                }
                lastValue = thisValue;
                if (Ember.isEmpty(result) && thisValue <= 0) {
                  result = value.key;
                }
              });
            }
            if (result === null) {
              // calculate expected EOC
              var avgDelta = (deltas[deltas.length - 1] + deltas[deltas.length - 2] + deltas[deltas.length - 3]) / 3;
              if (avgDelta < 0) {
                var thisMonth = moment(Ember.get(cashFlowData.root.values, "lastObject.key"), _const.default.MomentYearMonthDateFormat);
                while (lastValue > 0) {
                  thisMonth.add(1, "months");
                  lastValue += avgDelta;
                }
                result = thisMonth.format(_const.default.MomentYearMonthDateFormat);
              }
            }
            if (result) {
              var resultTime = new Date(result);
              if (resultTime.isValid() && endTime.isValid() && resultTime.isAfter(endTime)) {
                return null;
              }
            }
            return result;
          };
          EOC = calcEOC(scenario === BVA ? "convertedActualValue" : "value");
          EOC2 = calcEOC(scenario === BVA ? "value" : "value2");
          var eocChart = Ember.Object.create({
            type: "end-of-cash"
          });
          EOC = moment(EOC, _const.default.MomentYearMonthDateFormat).format(_const.default.MomentMonthYearDisplayFormat);
          if (checkPeriod && chartEndDate.isBefore(EOC) && EOC !== "Invalid date") {
            EOC = "Invalid date";
          }
          eocChart.setProperties({
            name: DashboardUtil.getFirstSeriesName(controller),
            data: EOC
          });
          if (scenario && (scenario === BVA || isBudgetVsScenario) || isFvB) {
            EOC2 = moment(EOC2, _const.default.MomentYearMonthDateFormat).format(_const.default.MomentMonthYearDisplayFormat);
            if (checkPeriod && chartEndDate.isBefore(EOC2) && EOC2 !== "Invalid date") {
              EOC2 = "Invalid date";
            }
            eocChart.setProperties({
              name2: DashboardUtil.getSecondSeriesName(controller),
              data2: EOC2
            });
          }
          resolve(eocChart);
        };
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget);
        options.store = store;
        options = Ember.Object.create(options);
        cashFlowData = cache.getItem(budgetId, "getForecastDataModel", hashOptions);
        if (!cashFlowData) {
          _forecast.default.getForecastDataModel(options, cache, "cf", budgetId, options, controller).then(function (cashFlowData) {
            cache.setItem(budgetId, "getForecastDataModel", hashOptions, cashFlowData);
            promiseCallback(cashFlowData);
          });
        } else {
          promiseCallback(cashFlowData);
        }
      });
    },
    getVSBudgetLines: function getVSBudgetLines(controller, store, vsBudgetId, condition) {
      var rootBudget = DashboardUtil.getVSBudget(controller, store, vsBudgetId),
        lines = [];
      if (rootBudget) {
        lines = store.peekAll("budget").filter(function (b) {
          return b && b.get("root.id") === rootBudget.get("id") && condition(b);
        });
      }
      return lines;
    },
    getVSBudget: function getVSBudget(controller, store, vsBudgetId) {
      if (vsBudgetId === "#") {
        return controller.get("budgetService.selectedRoot");
      }
      return store.peekRecord("budget", controller.get("budgetService.selectedRoot.id") + "|" + vsBudgetId);
    },
    calcSaaSSubscriptionsAnnual: function calcSaaSSubscriptionsAnnual(controller, cacheService, store, budget, chart, currencySymbol) {
      var saasLines,
        highchartData,
        highchartDataBudget,
        isFvB = controller.get("isFvB"),
        isActuals = controller.get("scenario") === BVA,
        dimensions = controller.get("dimensions"),
        forecastViewOption = controller.get("forecastViewOption"),
        vsOption = controller.get("vsOption"),
        vsLines = [],
        scenario = controller.get("scenario") && controller.get("scenario") !== "" && controller.get("scenario") !== BVA ? controller.get("scenario") : null;

      // SaaS subscriptions - Annual
      var saasCondition = function saasCondition(b) {
        return b.get("budgetType") === "subscription-saas" && b.get("budgetAttributes.subscriptionType") === "y";
      };
      saasLines = store.peekAll("budget").filter(function (b) {
        return b && b.get("root.id") === budget.get("id") && saasCondition(b);
      });
      if (isFvB && vsOption) {
        vsLines = DashboardUtil.getVSBudgetLines(controller, store, vsOption, saasCondition);
      }
      var retrunCharts = {
        newNewACV: undefined,
        totalACV: undefined
      };
      if (saasLines.length || vsLines.length) {
        var highchartCategories = [],
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          options = scenario && scenario !== "" ? {
            scenario: scenario,
            dimensions: dimensions
          } : {
            dimensions: dimensions
          },
          saasChart = Ember.Object.create({}),
          saasChart2 = Ember.Object.create({}),
          highchartData2 = [{
            name: intl.t("general.dashboard-8", {
              var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
            }),
            data: [],
            stack: DashboardUtil.getSecondStackName(controller)
          }],
          highchartDataBudget2 = [{
            name: intl.t("general.dashboard-9", {
              var1: DashboardUtil.getSecondSeriesName(controller)
            }),
            data: [],
            stack: DashboardUtil.getSecondStackName(controller)
          }],
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          emptyMonthsList = DashboardUtil.getEmptyMonthsObj(budgetStartDate, budgetEndDate),
          isInteractiveDashboardType = controller.get("scenario") && controller.get("isInteractiveDashboardType") ? "Modified" : null;
        highchartData = [{
          name: intl.t("general.dashboard-10", {
            var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
          }),
          data: [],
          stack: DashboardUtil.getSecondStackName(controller)
        }, {
          name: intl.t("general.dashboard-11", {
            var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
          }),
          data: [],
          stack: DashboardUtil.getSecondStackName(controller)
        }, {
          name: intl.t("general.dashboard-12", {
            var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
          }),
          data: [],
          stack: DashboardUtil.getSecondStackName(controller)
        }, {
          name: intl.t("general.dashboard-13", {
            var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
          }),
          data: [],
          stack: DashboardUtil.getSecondStackName(controller)
        }];
        var calcSaaS = function calcSaaS(lines, highchartData, highchartData2, options) {
          var endingData = [];
          lines.forEach(function (line, idx) {
            var lineResult = _forecast.default.calcForecast(cacheService, store, Ember.get(line, "id"), null, options, 0),
              items = Ember.merge(Ember.merge({}, emptyMonthsList), lineResult.items),
              colNum = 0,
              emberLineResult = Ember.Object.create(lineResult),
              endingMRR = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.cf", {})),
              newAmount = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.newAmount", {})),
              churn = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.churn", {})),
              expansion = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.expansion", {})),
              netNew = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.netNew", {}));
            for (var key in items) {
              if (!new Date(key).isBefore(budgetStartDate) && !new Date(key).isAfter(budgetEndDate)) {
                if (idx === 0) {
                  highchartCategories.push(key);
                  highchartData[0].data.push(0);
                  highchartData[1].data.push(0);
                  highchartData[2].data.push(0);
                  highchartData[3].data.push(0);
                  endingData.push(0);
                }
                endingData[colNum] += (endingMRR[key] || 0) * 12;
                highchartData[0].data[colNum] += (newAmount[key] || 0) * 12;
                highchartData[1].data[colNum] += (churn[key] || 0) * 12;
                highchartData[2].data[colNum] += (expansion[key] || 0) * 12;
                highchartData[3].data[colNum] += (netNew[key] || 0) * 12;
                colNum++;
              }
            }
          });
          highchartData2[0].data = endingData;
        };
        calcSaaS(saasLines, highchartData, highchartData2, options);
        if (scenario || forecastViewOption) {
          scenario = isInteractiveDashboardType || scenario;
          highchartData[0].name = intl.t("general.dashboard-14", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData[1].name = intl.t("general.dashboard-15", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData[2].name = intl.t("general.dashboard-16", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData[3].name = intl.t("general.dashboard-17", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData2[0].name = intl.t("general.dashboard-18", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData[0].stack = DashboardUtil.getFirstStackName(controller);
          highchartData[1].stack = DashboardUtil.getFirstStackName(controller);
          highchartData[2].stack = DashboardUtil.getFirstStackName(controller);
          highchartData[3].stack = DashboardUtil.getFirstStackName(controller);
          highchartData2[0].stack = DashboardUtil.getFirstStackName(controller);
          if (scenario && isBudgetVsScenario || isFvB) {
            highchartDataBudget = [{
              name: intl.t("general.dashboard-19", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: [],
              stack: DashboardUtil.getSecondStackName(controller)
            }, {
              name: intl.t("general.dashboard-20", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: [],
              stack: DashboardUtil.getSecondStackName(controller)
            }, {
              name: intl.t("general.dashboard-21", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: [],
              stack: DashboardUtil.getSecondStackName(controller)
            }, {
              name: intl.t("general.dashboard-22", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: [],
              stack: DashboardUtil.getSecondStackName(controller)
            }];
            if (isInteractiveDashboardType) {
              highchartDataBudget[0].color = DashboardUtil.greyColorSchema[0];
              highchartDataBudget[1].color = DashboardUtil.greyColorSchema[1];
              highchartDataBudget[2].color = DashboardUtil.greyColorSchema[2];
              highchartDataBudget[3].color = DashboardUtil.greyColorSchema[3];
              highchartDataBudget2[0].color = DashboardUtil.greyColorSchema[0];
            }
            if (isFvB && vsOption) {
              calcSaaS(vsLines, highchartDataBudget, highchartDataBudget2, {});
            } else {
              calcSaaS(saasLines, highchartDataBudget, highchartDataBudget2, {});
            }
            highchartData = highchartData.concat(highchartDataBudget);
            highchartData2 = highchartData2.concat(highchartDataBudget2);
          }
        }
        highchartCategories = changeDateFormatArray(highchartCategories, chart.get("periodType"));
        if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
          var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
            newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
          var _DashboardUtil$getSta2 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
            startIndex = _DashboardUtil$getSta2.startIndex,
            endIndex = _DashboardUtil$getSta2.endIndex;
          highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
          DashboardUtil.changeDataPeriod(highchartData, startIndex, endIndex);
          DashboardUtil.changeDataPeriod(highchartData2, startIndex, endIndex);
        }
        saasChart.setProperties({
          showLegend: true,
          showLegendAmounts: false,
          highchartShowAnimation: true,
          highchartData: highchartData,
          highchartCurrency: currencyArrayToSymbols([currencySymbol]),
          highchartCategories: highchartCategories
        });
        saasChart2.setProperties({
          showLegend: false,
          highchartShowAnimation: true,
          highchartData: highchartData2,
          highchartCurrency: currencyArrayToSymbols([currencySymbol]),
          highchartCategories: highchartCategories
        });
        retrunCharts["newNewACV"] = saasChart;
        retrunCharts["totalACV"] = saasChart2;
      }
      return retrunCharts;
    },
    calcSaaSSubscriptionsMonthly: function calcSaaSSubscriptionsMonthly(controller, cacheService, store, budget, chart, currencySymbol) {
      var highchartData,
        retrunCharts = {
          newNewACV: undefined,
          totalACV: undefined
        },
        isActuals = controller.get("scenario") === BVA,
        scenario = controller.get("scenario") && controller.get("scenario") !== "" && controller.get("scenario") !== BVA ? controller.get("scenario") : null,
        dimensions = controller.get("dimensions"),
        isFvB = controller.get("isFvB"),
        vsOption = controller.get("vsOption"),
        vsLines = [];
      var saasCondition = function saasCondition(b) {
        return b.get("budgetType") === "subscription-saas" && b.get("budgetAttributes.subscriptionType") === "m";
      };

      // SaaS subscriptions - Monthly
      var saasLines = store.peekAll("budget").filter(function (b) {
        return b && b.get("root.id") === budget.get("id") && saasCondition(b);
      });
      if (isFvB && vsOption) {
        vsLines = DashboardUtil.getVSBudgetLines(controller, store, vsOption, saasCondition);
      }
      if (saasLines.length || vsLines.length) {
        var highchartCategories = [],
          options = scenario && scenario !== "" ? {
            scenario: scenario,
            dimensions: dimensions
          } : {
            dimensions: dimensions
          },
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          highchartDataBudget,
          saasChart = Ember.Object.create({}),
          saasChart2 = Ember.Object.create({}),
          highchartData2 = [{
            name: intl.t("general.dashboard-23", {
              var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
            }),
            data: [],
            stack: DashboardUtil.getSecondStackName(controller)
          }],
          highchartDataBudget2 = [{
            name: intl.t("general.dashboard-24", {
              var1: DashboardUtil.getSecondSeriesName(controller)
            }),
            data: [],
            stack: DashboardUtil.getSecondStackName(controller)
          }],
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          emptyMonthsList = DashboardUtil.getEmptyMonthsObj(budgetStartDate, budgetEndDate),
          isInteractiveDashboardType = controller.get("scenario") && controller.get("isInteractiveDashboardType") ? "Modified" : null,
          _isFvB = controller.get("isFvB"),
          forecastViewOption = controller.get("forecastViewOption"),
          _vsOption = controller.get("vsOption");
        highchartData = [{
          name: intl.t("general.dashboard-25", {
            var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
          }),
          data: [],
          stack: DashboardUtil.getSecondStackName(controller)
        }, {
          name: intl.t("general.dashboard-26", {
            var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
          }),
          data: [],
          stack: DashboardUtil.getSecondStackName(controller)
        }, {
          name: intl.t("general.dashboard-27", {
            var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
          }),
          data: [],
          stack: DashboardUtil.getSecondStackName(controller)
        }, {
          name: intl.t("general.dashboard-28", {
            var1: isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""
          }),
          data: [],
          stack: DashboardUtil.getSecondStackName(controller)
        }];
        var calcSaaS = function calcSaaS(lines, highchartData, highchartData2, options) {
          var endingData = [];
          lines.forEach(function (line, idx) {
            var lineResult = _forecast.default.calcForecast(cacheService, store, Ember.get(line, "id"), null, options, 0),
              items = Ember.merge(Ember.merge({}, emptyMonthsList), lineResult.items),
              colNum = 0,
              emberLineResult = Ember.Object.create(lineResult),
              endingMRR = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.cf", {})),
              newAmount = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.newAmount", {})),
              churn = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.churn", {})),
              expansion = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.expansion", {})),
              netNew = Ember.merge(Ember.merge({}, emptyMonthsList), emberLineResult.getWithDefault("accounts.netNew", {}));
            for (var key in items) {
              if (!new Date(key).isBefore(budgetStartDate) && !new Date(key).isAfter(budgetEndDate)) {
                if (idx === 0) {
                  highchartCategories.push(key);
                  highchartData[0].data.push(0);
                  highchartData[1].data.push(0);
                  highchartData[2].data.push(0);
                  highchartData[3].data.push(0);
                  endingData.push(0);
                }
                endingData[colNum] += endingMRR[key] || 0;
                highchartData[0].data[colNum] += newAmount[key] || 0;
                highchartData[1].data[colNum] += churn[key] || 0;
                highchartData[2].data[colNum] += expansion[key] || 0;
                highchartData[3].data[colNum] += netNew[key] || 0;
                colNum++;
              }
            }
          });
          highchartData2[0].data = endingData;
        };
        calcSaaS(saasLines, highchartData, highchartData2, options);
        if (scenario || forecastViewOption) {
          scenario = isInteractiveDashboardType || scenario;
          highchartData[0].name = intl.t("general.dashboard-29", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData[1].name = intl.t("general.dashboard-30", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData[2].name = intl.t("general.dashboard-31", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData[3].name = intl.t("general.dashboard-32", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData2[0].name = intl.t("general.dashboard-33", {
            var1: DashboardUtil.getFirstSeriesName(controller)
          });
          highchartData[0].stack = DashboardUtil.getFirstStackName(controller);
          highchartData[1].stack = DashboardUtil.getFirstStackName(controller);
          highchartData[2].stack = DashboardUtil.getFirstStackName(controller);
          highchartData[3].stack = DashboardUtil.getFirstStackName(controller);
          highchartData2[0].stack = DashboardUtil.getFirstStackName(controller);
          if (scenario && isBudgetVsScenario || _isFvB) {
            highchartDataBudget = [{
              name: intl.t("general.dashboard-34", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: [],
              stack: DashboardUtil.getSecondStackName(controller)
            }, {
              name: intl.t("general.dashboard-35", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: [],
              stack: DashboardUtil.getSecondStackName(controller)
            }, {
              name: intl.t("general.dashboard-36", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: [],
              stack: DashboardUtil.getSecondStackName(controller)
            }, {
              name: intl.t("general.dashboard-37", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: [],
              stack: DashboardUtil.getSecondStackName(controller)
            }];
            if (isInteractiveDashboardType) {
              highchartDataBudget[0].color = DashboardUtil.greyColorSchema[0];
              highchartDataBudget[1].color = DashboardUtil.greyColorSchema[1];
              highchartDataBudget[2].color = DashboardUtil.greyColorSchema[2];
              highchartDataBudget[3].color = DashboardUtil.greyColorSchema[3];
              highchartDataBudget2[0].color = DashboardUtil.greyColorSchema[0];
            }
            if (_isFvB && _vsOption) {
              calcSaaS(vsLines, highchartDataBudget, highchartDataBudget2, {});
            } else {
              calcSaaS(saasLines, highchartDataBudget, highchartDataBudget2, {});
            }
            highchartData = highchartData.concat(highchartDataBudget);
            highchartData2 = highchartData2.concat(highchartDataBudget2);
          }
        }
        highchartCategories = changeDateFormatArray(highchartCategories, chart.get("periodType"));
        if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
          var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
            newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
          var _DashboardUtil$getSta3 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
            startIndex = _DashboardUtil$getSta3.startIndex,
            endIndex = _DashboardUtil$getSta3.endIndex;
          highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
          DashboardUtil.changeDataPeriod(highchartData, startIndex, endIndex);
          DashboardUtil.changeDataPeriod(highchartData2, startIndex, endIndex);
        }
        saasChart.setProperties({
          colorPalette: DashboardUtil.SaasColorPalette,
          showLegend: true,
          showLegendAmounts: false,
          highchartShowAnimation: true,
          highchartData: highchartData,
          highchartCurrency: currencyArrayToSymbols([currencySymbol]),
          highchartCategories: highchartCategories
        });
        saasChart2.setProperties({
          showLegend: false,
          highchartShowAnimation: true,
          highchartData: highchartData2,
          highchartCurrency: currencyArrayToSymbols([currencySymbol]),
          highchartCategories: highchartCategories
        });
        retrunCharts["newNewACV"] = saasChart;
        retrunCharts["totalACV"] = saasChart2;
      }
      return retrunCharts;
    },
    calcOperatingExpenses: function calcOperatingExpenses(controller, store, chart, asPie) {
      return new Ember.RSVP.Promise(function (resolve) {
        var opexChart = Ember.Object.create({}),
          budget = controller.get("model"),
          filter = chart.get("filters.by") === "all" ? undefined : chart.get("filters.by"),
          scenario = controller.get("scenario"),
          isInteractiveDashboardType = controller.get("isInteractiveDashboardType"),
          forecastViewOption = controller.get("forecastViewOption"),
          vsOption = controller.get("vsOption"),
          isFvB = controller.get("isFvB"),
          dimensions = controller.get("dimensions"),
          isRollingForecast = controller.get("isRollingForecastViewType"),
          periodType = chart.get("periodType") || "Monthly",
          options = {
            selectedScenario: scenario ? "#" : "",
            scenario: scenario === BVA ? null : scenario,
            currency: budget.get("forecastAttributes.currency"),
            type: "pnl",
            dimensions: dimensions,
            selectedPeriod: periodType,
            actualViewOption: isRollingForecast ? "rollf" : undefined,
            budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
            forecastViewOption: forecastViewOption ? forecastViewOption : undefined,
            vsOption: vsOption ? vsOption : undefined
          },
          forecastData,
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          changePeriod = chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf()) ? true : false,
          newStartDate,
          newEndDate,
          addScenario = scenario && scenario !== "",
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          cache = controller.get("cache");
        var pnlReport = _const.default.deepClone(budget.get("absoluteRoot.budgetModule.reports.pnl")) || {
          lines: []
        };
        var promiseCallback = function promiseCallback(forecastData) {
          forecastData = [forecastData];
          if (changePeriod) {
            newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase();
            newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
          }
          var value_factors = isRollingForecast ? DashboardUtil.getActualsKeys(forecastData[0].value_factors) : {};
          if (filter || asPie) {
            var forecastGroup = _forecast.default.findInForecastByReportTypes(forecastData[0], ["opex"]),
              highchartData = [],
              highchartCategories = [];
            if (forecastGroup && forecastGroup.length) {
              forecastGroup = forecastGroup[0].children;

              // get detail
              if (filter && filter.endsWith(":detail")) {
                forecastGroup = DashboardUtil.getDetailDimensions(forecastGroup);
              }
              if (asPie) {
                var _DashboardUtil$calcOp = DashboardUtil.calcOperatingExpensesPie({
                    controller: controller,
                    forecastGroup: forecastGroup,
                    changePeriod: changePeriod,
                    newStartDate: newStartDate,
                    newEndDate: newEndDate,
                    chart: chart,
                    addScenario: addScenario,
                    scenario: scenario,
                    isInteractiveDashboardType: isInteractiveDashboardType,
                    forecastViewOption: forecastViewOption,
                    vsOption: vsOption,
                    isFvB: isFvB,
                    isRollingForecast: isRollingForecast,
                    value_factors: value_factors,
                    filter: filter
                  }),
                  highchartCategoriesPie = _DashboardUtil$calcOp.highchartCategoriesPie,
                  highchartDataPie = _DashboardUtil$calcOp.highchartDataPie;
                highchartData = highchartDataPie;
                highchartCategories = highchartCategoriesPie;
              } else {
                var _DashboardUtil$calcOp2 = DashboardUtil.calcOperatingExpensesWithBreak({
                    controller: controller,
                    forecastGroup: forecastGroup,
                    changePeriod: changePeriod,
                    newStartDate: newStartDate,
                    newEndDate: newEndDate,
                    chart: chart,
                    addScenario: addScenario,
                    scenario: scenario,
                    isBudgetVsScenario: isBudgetVsScenario,
                    isInteractiveDashboardType: isInteractiveDashboardType,
                    forecastViewOption: forecastViewOption,
                    vsOption: vsOption,
                    isFvB: isFvB,
                    isRollingForecast: isRollingForecast,
                    value_factors: value_factors
                  }),
                  highchartCategoriesBy = _DashboardUtil$calcOp2.highchartCategoriesBy,
                  highchartDataBy = _DashboardUtil$calcOp2.highchartDataBy;
                highchartData = highchartDataBy;
                highchartCategories = highchartCategoriesBy;
              }
              opexChart.setProperties({
                highchartCategories: asPie ? [] : highchartCategories,
                highchartData: highchartData,
                value_factors: value_factors
              });
              resolve(opexChart);
            } else {
              opexChart.setProperties({
                highchartCategories: [],
                highchartData: [],
                value_factors: {}
              });
              resolve(opexChart);
            }
          } else {
            DashboardUtil.calcOperatingExpensesNoBreak({
              controller: controller,
              forecastData: forecastData,
              scenario: scenario,
              resolve: resolve,
              isBudgetVsScenario: isBudgetVsScenario,
              changePeriod: changePeriod,
              opexChart: opexChart,
              chart: chart,
              newStartDate: newStartDate,
              newEndDate: newEndDate,
              isInteractiveDashboardType: isInteractiveDashboardType,
              forecastViewOption: forecastViewOption,
              vsOption: vsOption,
              isFvB: isFvB,
              isRollingForecast: isRollingForecast,
              value_factors: value_factors
            });
          }
        };
        if (filter || asPie) {
          var opexLine = pnlReport.lines.findBy("id", "opex");
          opexLine.groupBy = filter.replace(":detail", "");
        }
        options.customReport = pnlReport;
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
          selectedPeriod: periodType
        });
        options.store = store;
        options = Ember.Object.create(options);
        forecastData = cache.getItem(budget.get("id"), "getForecastDataModel", hashOptions);
        if (!forecastData) {
          var promise = _forecast.default.getForecastDataModel(options, controller.get("cache"), "pnl", budget.get("id"), options, controller);
          promise.then(function (forecastData) {
            cache.setItem(budget.get("id"), "getForecastDataModel", hashOptions, forecastData);
            promiseCallback(forecastData);
          });
        } else {
          promiseCallback(forecastData);
        }
      });
    },
    calcOperatingExpensesPie: function calcOperatingExpensesPie(options) {
      var forecastGroup = options.forecastGroup,
        changePeriod = options.changePeriod,
        newStartDate = options.newStartDate,
        newEndDate = options.newEndDate,
        scenario = options.scenario,
        chart = options.chart,
        filter = options.filter;
      var highchartDataPie = [],
        highchartCategoriesPie = [],
        dataMap = {},
        isActuals = scenario && scenario === BVA,
        valueName = isActuals ? "convertedActualValue" : "value";
      if (changePeriod) {
        highchartCategoriesPie = Ember.isEmpty(Ember.get(forecastGroup, "0.values")) ? [] : changeDateFormatArray(forecastGroup[0].values.map(function (value) {
          return value.key;
        }), chart.get("periodType"));
        var _DashboardUtil$getSta4 = DashboardUtil.getStartAndEndDateIndexs(highchartCategoriesPie, newStartDate, newEndDate),
          startIndex = _DashboardUtil$getSta4.startIndex,
          endIndex = _DashboardUtil$getSta4.endIndex;
        highchartCategoriesPie = DashboardUtil.changeDataArrayPeriod(highchartCategoriesPie, startIndex, endIndex);
      }
      var calcData = function calcData(forecastGroup) {
        forecastGroup.forEach(function (child) {
          if (!child.isEmpty) {
            var groupName;
            groupName = filter ? Ember.get(child, "group_value_with_parent") : "Operating expenses";
            if (!dataMap[groupName]) {
              dataMap[groupName] = {
                name: groupName,
                y: 0
              };
            }
            if (changePeriod) {
              var childTotal = 0;
              Ember.get(child, "values").forEach(function (value) {
                if (highchartCategoriesPie.indexOf(Date.create(Ember.get(value, "key") + "/01").format("{Mon} {yy}").toUpperCase()) !== -1) {
                  childTotal += Ember.get(value, valueName) * 1;
                }
              });
              dataMap[groupName].y += childTotal * -1;
            } else {
              dataMap[groupName].y += (isActuals ? child.totalActual : child.total) * -1;
            }
          }
        });
      };
      calcData(forecastGroup);
      highchartDataPie = mapObjectToArray(dataMap);
      return {
        highchartDataPie: highchartDataPie,
        highchartCategoriesPie: highchartCategoriesPie
      };
    },
    calcExpensesByCategory: function calcExpensesByCategory(controller, store, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        var budget = controller.get("model"),
          scenario = controller.get("scenario"),
          forecastViewOption = controller.get("forecastViewOption"),
          vsOption = controller.get("vsOption"),
          periodType = chart.get("periodType") || "Monthly",
          options = {
            selectedScenario: scenario ? "#" : "",
            scenario: scenario === BVA ? null : scenario,
            currency: budget.get("forecastAttributes.currency"),
            type: "pnl",
            selectedPeriod: periodType,
            budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
            forecastViewOption: forecastViewOption ? forecastViewOption : undefined,
            vsOption: vsOption ? vsOption : undefined
          },
          dimensions = controller.get("dimensions"),
          forecastData,
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          changePeriod = chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf()) ? true : false,
          isFvB = controller.get("isFvB"),
          isRollingForecast = controller.get("isRollingForecastViewType"),
          isInteractiveDashboardType = controller.get("isInteractiveDashboardType") && scenario ? "Modified" : null,
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          cache = controller.get("cache"),
          filter = chart.get("filters.by") === "all" ? undefined : chart.get("filters.by"),
          value_factors,
          budgetId = budget.id;
        var pnlReport = _const.default.deepClone(budget.get("absoluteRoot.budgetModule.reports.pnl")) || {
          lines: []
        };
        var opexLine = pnlReport.lines.findBy("id", "opex");
        var cosLine = pnlReport.lines.findBy("id", "cos");
        var categoryDimension = dimensions.findBy("dimension.isCategory");
        if (categoryDimension) {
          opexLine.groupBy = Ember.get(categoryDimension, "dimension.uniqueId");
          cosLine.groupBy = Ember.get(categoryDimension, "dimension.uniqueId");
        }
        var promiseCallback = function promiseCallback(forecastData) {
          forecastData = [forecastData];
          value_factors = isRollingForecast ? DashboardUtil.getActualsKeys(forecastData[0].value_factors) : {};
          var lines = _forecast.default.findInForecastByReportTypes(forecastData[0], ["opex", "cos"]);
          var groups = Ember.A([]);
          lines.forEach(function (line) {
            return groups.pushObjects(Ember.get(line, "children"));
          });
          var isEqualOrChildOf = function isEqualOrChildOf(uniqueId, goalUniqueId) {
            if (!uniqueId) return;
            var values = categoryDimension.dimension.get("values");
            if (uniqueId === goalUniqueId) {
              return true;
            }
            var node = values.find(function (value) {
              return value.uniqueId === uniqueId;
            });
            var grandParentId = node && node.parent;
            return grandParentId && isEqualOrChildOf(grandParentId, goalUniqueId);
          };
          if (chart.get("chartType") === "donut") {
            var newGroups = [];
            var colors = DashboardUtil.getColorPalette();
            var colorI = 0;
            groups.forEach(function (group) {
              var groupId = categoryDimension && Ember.get(group, "id").split("#");
              if (!Ember.get(group, "isEmpty") && (!categoryDimension || categoryDimension.selectedValue === "all" || groupId.length > 1 && isEqualOrChildOf(categoryDimension.selectedValue, groupId[1]))) {
                var color = colors[colorI % colors.length];
                newGroups.pushObject({
                  level: 1,
                  color: color,
                  group: group
                });
                var subGroups = DashboardUtil.getDetailDimensions([group]);
                subGroups = subGroups.filter(function (subGroup) {
                  var subGroupId = categoryDimension && Ember.get(subGroup, "id").split("#");
                  return !Ember.get(subGroup, "isEmpty") && (!categoryDimension || categoryDimension.selectedValue === "all" || subGroupId.length > 1 && isEqualOrChildOf(subGroupId[1], categoryDimension.selectedValue));
                });
                if (subGroups.length) {
                  subGroups = subGroups.map(function (subGroup, i) {
                    var brightness = 0.2 - i / subGroups.length / 5;
                    var bColor = Highcharts.Color(color).brighten(brightness).get();
                    return {
                      level: 0,
                      group: subGroup,
                      color: bColor
                    };
                  });
                  newGroups.pushObjects(subGroups);
                } else {
                  var bColor = Highcharts.Color(color).brighten(0.2).get();
                  newGroups.pushObject({
                    level: 0,
                    group: group,
                    color: bColor
                  });
                }
                colorI++;
              }
            });
            groups = newGroups;
          } else {
            // get detail
            if (filter && filter.endsWith(":detail")) {
              groups = DashboardUtil.getDetailDimensions(groups);
            }
            groups = groups.reduce(function (allGroups, group) {
              var groupId = categoryDimension && Ember.get(group, "id").split("#");
              if (!Ember.get(group, "isEmpty") && (!categoryDimension || categoryDimension.selectedValue === "all" || groupId.length > 1 && isEqualOrChildOf(categoryDimension.selectedValue, groupId[1]))) {
                allGroups.push({
                  level: 1,
                  group: group
                });
              }
              return allGroups;
            }, []);
          }
          var dataMap = {};
          var valueText = scenario && isBudgetVsScenario || isFvB ? "value2" : "value",
            value2Text = scenario === BVA ? "convertedActualValue" : scenario && isBudgetVsScenario || isFvB ? "value" : "value2";
          var mapByValue = function mapByValue(monthData) {
            if (isRollingForecast) {
              return -1 * ((value_factors[monthData.key] ? monthData["convertedActualValue"] : monthData[valueText]) || 0);
            }
            return -1 * (monthData[valueText] || 0);
          };
          var mapByValue2 = function mapByValue2(monthData) {
            return -1 * (monthData[value2Text] || 0);
          };
          groups.forEach(function (groupObj, i) {
            var level = groupObj.level || 0;
            var group = groupObj.group;
            var color = groupObj.color;
            var groupValue = group.group_value_with_parent;
            var groupName = groupValue + (scenario === BVA && !isRollingForecast || scenario && isBudgetVsScenario || isFvB ? DashboardUtil.getSecondSeriesName(controller) : DashboardUtil.getFirstSeriesName(controller));
            var groupName2 = groupValue + DashboardUtil.getFirstSeriesName(controller);
            var groupNameWithLevel = groupName + "_" + level;
            var groupName2WithLevel = groupName2 + "_" + level;
            if (group) {
              if (!dataMap[groupNameWithLevel]) {
                var stack = scenario === BVA || scenario && isBudgetVsScenario || isFvB ? DashboardUtil.getSecondStackName(controller) : DashboardUtil.getFirstStackName(controller);
                dataMap[groupNameWithLevel] = {
                  name: groupName,
                  data: undefined,
                  stack: stack,
                  level: level
                };
                if (color) {
                  dataMap[groupNameWithLevel].color = color;
                }
                if (isInteractiveDashboardType) {
                  dataMap[groupNameWithLevel].color = DashboardUtil.greyColorSchema[i % DashboardUtil.greyColorSchema.length];
                }
              }
              dataMap[groupNameWithLevel].data = _const.default.addArrayToArray(dataMap[groupNameWithLevel].data, group.values.map(mapByValue));
              if (scenario && isBudgetVsScenario || isFvB) {
                if (!dataMap[groupName2WithLevel]) {
                  dataMap[groupName2WithLevel] = {
                    name: groupName2,
                    data: undefined,
                    stack: "".concat(DashboardUtil.getFirstStackName(controller)),
                    level: level
                  };
                }
                dataMap[groupName2WithLevel].data = _const.default.addArrayToArray(dataMap[groupName2WithLevel].data, group.values.map(mapByValue2));
              }
            }
          });
          // remove empty values
          DashboardUtil.removeEmptyValues(dataMap);
          var highchartCategories = changeDateFormatArray(forecastData[0].value_factors.mapBy("key"), chart.get("periodType")),
            highchartData = mapObjectToArray(dataMap);
          if (highchartData && highchartData.length > 0 && "name" in highchartData[0]) highchartData.sort(function (a, b) {
            return a.name > b.name ? 1 : -1;
          });
          if (changePeriod) {
            var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
              newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
            var _DashboardUtil$getSta5 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
              startIndex = _DashboardUtil$getSta5.startIndex,
              endIndex = _DashboardUtil$getSta5.endIndex;
            highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
            DashboardUtil.changeDataPeriod(highchartData, startIndex, endIndex);
          }
          resolve({
            highchartData: highchartData,
            highchartCategories: highchartCategories,
            value_factors: value_factors
          });
        };
        options.customReport = pnlReport;
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
          selectedPeriod: periodType
        });
        options.store = store;
        options = Ember.Object.create(options);
        forecastData = cache.getItem(budgetId, "getForecastDataModel", hashOptions);
        if (!forecastData) {
          var promise = _forecast.default.getForecastDataModel(options, controller.get("cache"), "pnl", budgetId, options, controller);
          promise.then(function (forecastData) {
            cache.setItem(budgetId, "getForecastDataModel", hashOptions, forecastData);
            promiseCallback(forecastData);
          });
        } else {
          promiseCallback(forecastData);
        }
      });
    },
    calcOperatingExpensesWithBreak: function calcOperatingExpensesWithBreak(options) {
      var controller = options.controller,
        forecastGroup = options.forecastGroup,
        changePeriod = options.changePeriod,
        newStartDate = options.newStartDate,
        newEndDate = options.newEndDate,
        chart = options.chart,
        addScenario = options.addScenario,
        scenario = options.scenario,
        isBudgetVsScenario = options.isBudgetVsScenario,
        isInteractiveDashboardType = options.isInteractiveDashboardType,
        isFvB = options.isFvB,
        isRollingForecast = options.isRollingForecast,
        value_factors = options.value_factors;
      var groupWithValues,
        highchartDataBy = [],
        highchartCategoriesBy = [],
        dataMap = {};
      isInteractiveDashboardType = isInteractiveDashboardType && scenario ? true : false;
      var valueText = addScenario && isBudgetVsScenario || isFvB ? "value2" : "value",
        value2Text = scenario === BVA ? "convertedActualValue" : addScenario && isBudgetVsScenario || isFvB ? "value" : "value2";
      var calcData = function calcData(forecastGroup) {
        forecastGroup.forEach(function (child, i) {
          if (!child.isEmpty) {
            if (!groupWithValues) {
              groupWithValues = child;
            }
            var groupName;
            groupName = Ember.get(child, "group_value_with_parent");
            if (!dataMap[groupName]) {
              var stack = addScenario && isBudgetVsScenario || isFvB ? DashboardUtil.getSecondStackName(controller) : DashboardUtil.getFirstStackName(controller);
              dataMap[groupName] = {
                name: groupName + (addScenario && isBudgetVsScenario || isFvB ? DashboardUtil.getSecondSeriesName(controller) : DashboardUtil.getFirstSeriesName(controller)),
                data: undefined,
                stack: stack
              };
              if (isInteractiveDashboardType) {
                dataMap[groupName].color = DashboardUtil.greyColorSchema[i % DashboardUtil.greyColorSchema.length];
              }
            }
            dataMap[groupName].data = _const.default.addArrayToArray(dataMap[groupName].data, child.values.map(function (value) {
              return ((isRollingForecast ? value_factors[value.key] ? value["convertedActualValue"] : value["value"] : value[valueText]) || 0) * -1;
            }));
            if (addScenario && isBudgetVsScenario || isFvB) {
              groupName += DashboardUtil.getFirstSeriesName(controller);
              if (!dataMap[groupName]) {
                dataMap[groupName] = {
                  name: groupName,
                  data: undefined,
                  stack: DashboardUtil.getFirstStackName(controller)
                };
              }
              dataMap[groupName].data = _const.default.addArrayToArray(dataMap[groupName].data, child.values.map(function (value) {
                return (value[value2Text] || 0) * -1;
              }));
            }
          }
        });
      };
      calcData(forecastGroup);
      highchartDataBy = mapObjectToArray(dataMap);
      highchartCategoriesBy = Ember.isEmpty(groupWithValues) ? [] : changeDateFormatArray(groupWithValues.values.map(function (value) {
        return value.key;
      }), chart.get("periodType"));
      if (changePeriod) {
        var _DashboardUtil$getSta6 = DashboardUtil.getStartAndEndDateIndexs(highchartCategoriesBy, newStartDate, newEndDate),
          startIndex = _DashboardUtil$getSta6.startIndex,
          endIndex = _DashboardUtil$getSta6.endIndex;
        highchartCategoriesBy = DashboardUtil.changeDataArrayPeriod(highchartCategoriesBy, startIndex, endIndex);
        DashboardUtil.changeDataPeriod(highchartDataBy, startIndex, endIndex);
      }
      return {
        highchartDataBy: highchartDataBy,
        highchartCategoriesBy: highchartCategoriesBy,
        value_factors: value_factors
      };
    },
    calcOperatingExpensesNoBreak: function calcOperatingExpensesNoBreak(options) {
      var controller = options.controller,
        forecastData = options.forecastData,
        scenario = options.scenario,
        resolve = options.resolve,
        isBudgetVsScenario = options.isBudgetVsScenario,
        changePeriod = options.changePeriod,
        opexChart = options.opexChart,
        chart = options.chart,
        newStartDate = options.newStartDate,
        newEndDate = options.newEndDate,
        isInteractiveDashboardType = options.isInteractiveDashboardType,
        isFvB = options.isFvB,
        isRollingForecast = options.isRollingForecast,
        value_factors = options.value_factors;
      var valuesByMonth = {};
      var opex = forecastData.map(function (d) {
        return _forecast.default.findInForecastByReportTypes(d, ["opex"]);
      });
      opex.forEach(function (opexGroup) {
        opexGroup.forEach(function (tempOpexGroup) {
          var items = Ember.get(tempOpexGroup, "values");
          items.forEach(function (item) {
            var key = Ember.get(item, "key");
            var value = scenario === BVA ? (isRollingForecast ? value_factors[item.key] ? item.convertedActualValue : item.value : item.convertedActualValue) * -1 : (Ember.isEmpty(item.value) ? 0 : item.value) * -1;
            var value2 = scenario === BVA ? (Ember.isEmpty(item.value) ? 0 : item.value) * -1 : (Ember.isEmpty(Ember.get(item, "value2")) ? 0 : Ember.get(item, "value2")) * -1;
            if (!valuesByMonth[key]) {
              valuesByMonth[key] = {
                value: 0,
                value2: 0
              };
            }
            valuesByMonth[key].value = valuesByMonth[key].value + value;
            valuesByMonth[key].value2 = valuesByMonth[key].value2 + value2;
          });
        });
      });
      var valueArray = mapObjectToArray(valuesByMonth, "value"),
        value2Array = mapObjectToArray(valuesByMonth, "value2"),
        highchartData = [{
          name: intl.t("general.title_1695611597716") + DashboardUtil.getFirstSeriesName(controller),
          data: valueArray,
          stack: DashboardUtil.getFirstStackName(controller)
        }],
        budgetData = {
          name: intl.t("general.title_1695611597716") + DashboardUtil.getSecondSeriesName(controller),
          data: value2Array,
          stack: DashboardUtil.getSecondStackName(controller)
        },
        highchartData2 = [],
        highchartCategories = changeDateFormatArray(Object.keys(valuesByMonth), chart.get("periodType"));
      if (isInteractiveDashboardType) {
        budgetData.color = DashboardUtil.greyColorSchema[0];
      }
      highchartData2.push(budgetData);
      if (isBudgetVsScenario || isFvB) {
        highchartData = scenario || isFvB ? highchartData.concat(highchartData2) : highchartData;
      }
      if (changePeriod) {
        var _DashboardUtil$getSta7 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
          startIndex = _DashboardUtil$getSta7.startIndex,
          endIndex = _DashboardUtil$getSta7.endIndex;
        highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
        DashboardUtil.changeDataPeriod(highchartData, startIndex, endIndex);
      }
      opexChart.setProperties({
        highchartCategories: highchartCategories,
        highchartData: highchartData,
        value_factors: value_factors
      });
      resolve(opexChart);
    },
    calcCostOfRevenues: function calcCostOfRevenues(controller, store, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        var costOfRevenuesChart = Ember.Object.create({}),
          budget = controller.get("model"),
          scenario = controller.get("scenario"),
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          isFvB = controller.get("isFvB"),
          forecastViewOption = controller.get("forecastViewOption"),
          vsOption = controller.get("vsOption"),
          dimensions = controller.get("dimensions"),
          isRollingForecast = controller.get("isRollingForecastViewType"),
          periodType = chart.get("periodType") || "Monthly",
          options = {
            selectedScenario: scenario ? "#" : "",
            scenario: scenario === BVA ? null : scenario,
            currency: budget.get("forecastAttributes.currency"),
            type: "pnl",
            forceDetails: true,
            dimensions: dimensions,
            selectedPeriod: periodType,
            actualViewOption: isRollingForecast ? "rollf" : undefined,
            budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
            forecastViewOption: forecastViewOption ? forecastViewOption : undefined,
            vsOption: vsOption ? vsOption : undefined
          },
          forecastData,
          cache = controller.get("cache"),
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth();
        var promiseCallback = function promiseCallback(forecastData) {
          var value_factors = isRollingForecast ? DashboardUtil.getActualsKeys(forecastData.value_factors) : {};
          forecastData = [forecastData];
          var valuesByMonth = {};
          var costOfRev = forecastData.map(function (d) {
            return _forecast.default.findInForecastByTypes(d.root, ["group-expense-costofsale"], {
              returnFirstAppearance: true
            });
          });
          costOfRev.forEach(function (costOfRevGroup) {
            costOfRevGroup.forEach(function (tempOpexGroup) {
              var items = Ember.get(tempOpexGroup, "values");
              items.forEach(function (item) {
                var key = Ember.get(item, "key");
                var value = scenario === BVA ? (isRollingForecast ? (value_factors[key] ? item.convertedActualValue : item.value) || 0 : item.convertedActualValue) * -1 : (Ember.isEmpty(item.value) ? 0 : item.value) * -1;
                var value2 = scenario === BVA ? (Ember.isEmpty(item.value) ? 0 : item.value) * -1 : (Ember.isEmpty(Ember.get(item, "value2")) ? 0 : Ember.get(item, "value2")) * -1;
                if (!valuesByMonth[key]) {
                  valuesByMonth[key] = {
                    value: 0,
                    value2: 0
                  };
                }
                valuesByMonth[key].value = valuesByMonth[key].value + value;
                valuesByMonth[key].value2 = valuesByMonth[key].value2 + value2;
              });
            });
          });
          var highchartCategories = changeDateFormatArray(Object.keys(valuesByMonth), chart.get("periodType")),
            valueArray = mapObjectToArray(valuesByMonth, "value"),
            value2Array = mapObjectToArray(valuesByMonth, "value2"),
            firstSeriesName = DashboardUtil.getFirstSeriesName(controller),
            highchartData = [{
              name: intl.t("general.name_1695611604994") + (firstSeriesName ? "(" + firstSeriesName + ")" : ""),
              data: valueArray
            }],
            highchartData2 = [{
              name: intl.t("general.dashboard-38", {
                var1: DashboardUtil.getSecondSeriesName(controller)
              }),
              data: value2Array
            }];
          if (isBudgetVsScenario || isFvB) {
            highchartData = scenario || isFvB ? highchartData.concat(highchartData2) : highchartData;
          }
          if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
            var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
              newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
            var _DashboardUtil$getSta8 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
              startIndex = _DashboardUtil$getSta8.startIndex,
              endIndex = _DashboardUtil$getSta8.endIndex;
            highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
            DashboardUtil.changeDataPeriod(highchartData, startIndex, endIndex);
          }
          costOfRevenuesChart.setProperties({
            highchartCategories: highchartCategories,
            highchartData: highchartData
          });
          resolve(costOfRevenuesChart);
        };
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
          selectedPeriod: periodType
        });
        options.store = store;
        options = Ember.Object.create(options);
        forecastData = cache.getItem(budget.get("id"), "getForecastDataModel", hashOptions);
        if (!forecastData) {
          _forecast.default.getForecastDataModel(options, controller.get("cache"), "pnl", budget.get("id"), options, controller).then(function (forecastData) {
            cache.setItem(budget.get("id"), "getForecastDataModel", hashOptions, forecastData);
            promiseCallback(forecastData);
          });
        } else {
          promiseCallback(forecastData);
        }
      });
    },
    calcOperatingExpensesRevenue: function calcOperatingExpensesRevenue(controller, store, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        DashboardUtil.calcOperatingExpenses(controller, store, chart, false).then(function (opexChart) {
          DashboardUtil.calcRevenues(controller, store, chart).then(function (revChart) {
            var scenario = controller.get("scenario"),
              isFvB = controller.get("isFvB"),
              isBudgetVsScenario = controller.get("isBudgetVsScenario"),
              opexDataScenario = Ember.get(opexChart, "highchartData.0.data"),
              revDataScenario = Ember.get(revChart, "revenues.highchartData.0.data"),
              isBVAViewType = controller.get("isBVAViewType"),
              isInteractiveDashboardType = controller.get("isInteractiveDashboardType") && scenario ? "Modified" : null,
              highchartDataScenario,
              opexData = Ember.get(opexChart, "highchartData.1.data") || opexDataScenario,
              revData = Ember.get(revChart, "revenues.highchartData.1.data") || revDataScenario,
              stack2,
              stack1,
              highchartData,
              highchartCategories = Ember.get(opexChart, "highchartCategories");
            if (isBVAViewType || isFvB || isBudgetVsScenario) {
              stack2 = DashboardUtil.getFirstStackName(controller);
              stack1 = DashboardUtil.getSecondStackName(controller);
            } else {
              stack2 = DashboardUtil.getSecondStackName(controller);
              stack1 = DashboardUtil.getFirstStackName(controller);
            }
            highchartData = [{
              name: "Operating expenses as % of revenue".concat(isBVAViewType || isFvB || isBudgetVsScenario ? DashboardUtil.getSecondSeriesName(controller) : DashboardUtil.getFirstSeriesName(controller)),
              data: [],
              stack: stack1
            }];
            highchartDataScenario = {
              name: "Operating expenses as % of revenue".concat(isBVAViewType || isFvB || isBudgetVsScenario ? DashboardUtil.getFirstSeriesName(controller) : DashboardUtil.getSecondSeriesName(controller)),
              data: [],
              stack: stack2
            };
            if (!Ember.isEmpty(opexData) && !Ember.isEmpty(revData) && revData.length === opexData.length) {
              for (var i = 0; i < revData.length; i++) {
                highchartData[0].data.push(revData[i] && revData[i] !== 0 ? opexData[i] / revData[i] * 100 : null);
              }
              if ((scenario && isBudgetVsScenario || isFvB) && !Ember.isEmpty(opexDataScenario) && !Ember.isEmpty(revDataScenario) && revDataScenario.length === opexDataScenario.length) {
                for (var _i = 0; _i < revDataScenario.length; _i++) {
                  highchartDataScenario.data.push(revDataScenario[_i] && revDataScenario[_i] !== 0 ? opexDataScenario[_i] / revDataScenario[_i] * 100 : null);
                }
                if (isInteractiveDashboardType) {
                  highchartData[0].color = DashboardUtil.greyColorSchema[0];
                }
                highchartData.push(highchartDataScenario);
              }
            } else {
              highchartCategories = undefined;
            }
            resolve(Ember.Object.create({
              highchartData: highchartData,
              highchartCategories: highchartCategories,
              value_factors: Ember.get(revChart, "revenues.value_factors")
            }));
          });
        });
      });
    },
    calcGrossMargin: function calcGrossMargin(controller, store, currencySymbol, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        DashboardUtil.calcCostOfRevenues(controller, store, chart).then(function (costOfRevChart) {
          DashboardUtil.calcRevenues(controller, store, chart, undefined).then(function (revChart) {
            var costOfRevData = Ember.get(costOfRevChart, "highchartData.0.data"),
              revData = Ember.get(revChart, "revenues.highchartData.0.data"),
              costOfRevDataOriginal = Ember.get(costOfRevChart, "highchartData.1.data"),
              revDataOriginal = Ember.get(revChart, "revenues.highchartData.1.data"),
              highchartData,
              forecastViewOption = controller.get("forecastViewOption"),
              isFvB = controller.get("isFvB"),
              scenario = controller.get("scenario"),
              isBudgetVsScenario = controller.get("isBudgetVsScenario"),
              isInteractiveDashboardType = controller.get("scenario") && controller.get("isInteractiveDashboardType") ? "Modified" : null,
              grossProfit = {
                name: intl.t("general.dashboard-39"),
                data: [],
                type: "column",
                yAxis: 0
              },
              grossProfitPercent = {
                name: intl.t("general.dashboard-40"),
                data: [],
                type: "spline",
                yAxis: 1
              },
              grossProfitOriginal = {
                name: intl.t("general.dashboard-41", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: [],
                type: "column",
                yAxis: 0
              },
              grossProfitPercentOriginal = {
                name: intl.t("general.dashboard-42", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: [],
                type: "spline",
                yAxis: 1
              },
              tempDiffRev,
              highchartCategories = Ember.get(costOfRevChart, "highchartCategories") || Ember.get(revChart, "revenues.highchartCategories"),
              highchartCurrency = [currencySymbol, "%"];
            var calcGrossProfit = function calcGrossProfit(cost, rev, grossProfitObj, grossProfitPercentObj) {
              var dataLength = (cost ? cost.length : 0) || (rev ? rev.length : 0);
              if (!Ember.isEmpty(cost) || !Ember.isEmpty(rev)) {
                for (var i = 0; i < dataLength; i++) {
                  tempDiffRev = (rev && rev[i] ? rev[i] : 0) - (cost && cost[i] ? cost[i] : 0);
                  grossProfitObj.data.push(tempDiffRev);
                  grossProfitPercentObj.data.push(!rev || !rev[i] || rev[i] === 0 ? null : tempDiffRev / rev[i] * 100);
                }
              }
            };
            calcGrossProfit(costOfRevData, revData, grossProfit, grossProfitPercent);
            highchartData = [grossProfit, grossProfitPercent];
            if (scenario || forecastViewOption) {
              calcGrossProfit(costOfRevDataOriginal, revDataOriginal, grossProfitOriginal, grossProfitPercentOriginal);
              grossProfit.name = intl.t("general.dashboard-43", {
                var1: DashboardUtil.getFirstSeriesName(controller)
              });
              grossProfitPercent.name = intl.t("general.dashboard-44", {
                var1: DashboardUtil.getFirstSeriesName(controller)
              });
              if (isInteractiveDashboardType) {
                grossProfitOriginal.color = DashboardUtil.greyColorSchema[0];
                grossProfitPercentOriginal.color = DashboardUtil.greyColorSchema[1];
              }
              if (scenario && isBudgetVsScenario || isFvB) {
                highchartData = [grossProfit, grossProfitOriginal, grossProfitPercent, grossProfitPercentOriginal];
                highchartCurrency = [currencySymbol, currencySymbol, "%", "%"];
              }
            }
            resolve(Ember.Object.create({
              highchartData: highchartData,
              highchartCategories: highchartCategories,
              highchartCurrency: highchartCurrency,
              value_factors: Ember.get(revChart, "revenues.value_factors")
            }));
          });
        });
      });
    },
    getFirstSeriesName: function getFirstSeriesName(controller, removeParentheses) {
      if (removeParentheses) {
        var name = controller.get("firstSeriesName");
        if (name) {
          return name.substr(2, name.length - 3);
        }
      }
      return controller.get("firstSeriesName");
    },
    getSecondSeriesName: function getSecondSeriesName(controller, removeParentheses) {
      if (removeParentheses) {
        var name = controller.get("secondSeriesName");
        if (name) {
          return name.substr(2, name.length - 3);
        }
      }
      return controller.get("secondSeriesName");
    },
    getFirstStackName: function getFirstStackName(controller) {
      return controller.get("firstStackName");
    },
    getSecondStackName: function getSecondStackName(controller) {
      return controller.get("secondStackName");
    },
    getActualsKeys: function getActualsKeys() {
      var value_factors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var actualKeys = {};
      value_factors.forEach(function (factor) {
        actualKeys[factor.key] = factor.hasActuals;
      });
      return actualKeys;
    },
    calcRevenues: function calcRevenues(controller, store, chart) {
      var viewType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "pnl";
      return new Ember.RSVP.Promise(function (resolve) {
        var revenueChart = Ember.Object.create({}),
          revenueGrowthChart = Ember.Object.create({}),
          scenario = controller.get("scenario"),
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          filter = chart.get("filters.by") === "all" ? undefined : chart.get("filters.by"),
          periodType = chart.get("periodType") || "Monthly",
          isFvB = controller.get("isFvB"),
          dimensions = controller.get("dimensions"),
          forecastViewOption = controller.get("forecastViewOption"),
          isRollingForecast = controller.get("isRollingForecastViewType"),
          budget = controller.get("model"),
          options = {
            selectedScenario: scenario ? "#" : "",
            scenario: scenario === BVA ? null : scenario,
            currency: budget.get("forecastAttributes.currency"),
            type: viewType,
            selectedPeriod: periodType,
            dimensions: dimensions,
            budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
            actualViewOption: isRollingForecast ? "rollf" : undefined,
            revenueType: undefined,
            forecastViewOption: controller.get("forecastViewOption") ? controller.get("forecastViewOption") : undefined,
            vsOption: controller.get("vsOption") ? controller.get("vsOption") : undefined
          },
          cache = controller.get("cache"),
          isInteractiveDashboardType = controller.get("isInteractiveDashboardType") && scenario,
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          changePeriod = chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf()),
          value_factors = {},
          pnlReport = _const.default.deepClone(budget.get("absoluteRoot.budgetModule.reports.pnl"));
        var promiseCallback = function promiseCallback(forecastData) {
          revData = [forecastData];
          value_factors = isRollingForecast ? DashboardUtil.getActualsKeys(revData[0].value_factors) : {};
          if (!filter) {
            revenueChart.setProperties({
              highchartData: [],
              highchartShowAnimation: true,
              value_factors: value_factors,
              highchartCurrency: []
            });
            revenueGrowthChart.setProperties({
              highchartData: [],
              highchartShowAnimation: true,
              value_factors: value_factors,
              highchartCurrency: []
            });
            var revenues = (Ember.get(revData, "0.root.children") || Ember.A([])).filterBy("type", "group-revenue");
            if (revenues.length) {
              var revenue = revenues[0];
              var items = Ember.get(revenue, "values");
              var highchartData1 = revData.map(function (sub) {
                var stack = "";
                if (scenario === BVA && isFvB) {
                  stack = DashboardUtil.getSecondStackName(controller);
                } else {
                  stack = scenario === BVA || scenario && isBudgetVsScenario || isFvB ? DashboardUtil.getFirstStackName(controller) : DashboardUtil.getSecondStackName(controller);
                }
                return {
                  name: "Revenue" + (revData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getFirstSeriesName(controller),
                  data: [],
                  stack: stack.toLowerCase(),
                  type: "column",
                  yAxis: 0
                };
              });
              var highchartData2 = revData.map(function (sub) {
                var stack = DashboardUtil.getSecondStackName(controller);
                if (scenario === BVA && isFvB) {
                  stack = DashboardUtil.getFirstStackName(controller);
                }
                var series = {
                  name: "Revenue" + (revData.length > 1 ? " " + sub.forecast.name + " " : "") + DashboardUtil.getSecondSeriesName(controller),
                  data: [],
                  stack: stack.toLowerCase(),
                  type: "column",
                  yAxis: 0
                };
                if (isInteractiveDashboardType) {
                  series.color = DashboardUtil.greyColorSchema[0];
                }
                return series;
              });
              var revGrowthData1 = revData.map(function (sub) {
                return {
                  name: "Revenue growth" + (revData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getFirstSeriesName(controller),
                  data: [],
                  type: "spline",
                  yAxis: 1
                };
              });
              var revGrowthData2 = revData.map(function (sub) {
                var series = {
                  name: "Revenue growth" + (revData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getSecondSeriesName(controller),
                  data: [],
                  type: "spline",
                  yAxis: 1
                };
                if (isInteractiveDashboardType) {
                  series.color = DashboardUtil.greyColorSchema[1];
                }
                return series;
              });
              var lastRev1 = null;
              var lastRev2 = null;
              revenues.forEach(function (rev, i) {
                items = Ember.get(rev, "values");
                items.forEach(function (item) {
                  var value1 = (scenario === BVA ? isRollingForecast ? value_factors[item.key] ? item.convertedActualValue : item.value || 0 : item.convertedActualValue : Ember.isEmpty(item.value) ? 0 : item.value) || 0;
                  var value2 = (scenario === BVA ? Ember.isEmpty(item.value) ? 0 : item.value : Ember.isEmpty(item.value2) ? 0 : item.value2) || 0;
                  if (highchartData1[i]) {
                    highchartData1[i].data.push(value1);
                  }
                  if (highchartData2[i]) {
                    highchartData2[i].data.push(value2);
                  }
                  if (revGrowthData1[i]) {
                    revGrowthData1[i].data.push(!lastRev1 ? null : 100 * (value1 / lastRev1 - 1));
                  }
                  if (revGrowthData2[i]) {
                    revGrowthData2[i].data.push(!lastRev2 ? null : 100 * (value2 / lastRev2 - 1));
                  }
                  lastRev1 = value1;
                  lastRev2 = value2;
                });
              });
              var revenueCategories = changeDateFormatArray(items.mapBy("key"), chart.get("periodType")),
                revenueData = scenario && isBudgetVsScenario || isFvB ? highchartData1.concat(highchartData2) : highchartData1,
                revenueGrowthCategories = revenueCategories,
                revenueGrowthData = scenario && isBudgetVsScenario || isFvB ? highchartData1.concat(revGrowthData1).concat(highchartData2).concat(revGrowthData2) : highchartData1.concat(revGrowthData1);
              if (changePeriod) {
                var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
                  newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
                var _DashboardUtil$getSta9 = DashboardUtil.getStartAndEndDateIndexs(revenueCategories, newStartDate, newEndDate),
                  startIndex = _DashboardUtil$getSta9.startIndex,
                  endIndex = _DashboardUtil$getSta9.endIndex;
                revenueCategories = DashboardUtil.changeDataArrayPeriod(revenueCategories, startIndex, endIndex);
                revenueGrowthCategories = DashboardUtil.changeDataArrayPeriod(revenueGrowthCategories, startIndex, endIndex);
                revenueGrowthData = Ember.$.extend(true, [], revenueGrowthData);
                DashboardUtil.changeDataPeriod(revenueData, startIndex, endIndex);
                DashboardUtil.changeDataPeriod(revenueGrowthData, startIndex, endIndex);
              }
              revenueChart.setProperties({
                highchartCategories: revenueCategories,
                highchartData: revenueData,
                highchartShowAnimation: true,
                value_factors: value_factors,
                highchartCurrency: currencyArrayToSymbols([budget.get("forecastAttributes.currency")])
              });
              revenueGrowthChart.setProperties({
                highchartCategories: revenueGrowthCategories,
                highchartData: revenueGrowthData,
                highchartShowAnimation: true,
                value_factors: value_factors,
                highchartCurrency: scenario || forecastViewOption ? currencyArrayToSymbols([budget.get("forecastAttributes.currency"), "%", budget.get("forecastAttributes.currency"), "%"]) : currencyArrayToSymbols([budget.get("forecastAttributes.currency"), "%"])
              });
            }
            resolve({
              revenues: revenueChart,
              revenueGrowthChart: revenueGrowthChart
            });
          } else {
            // build for filter
            var groups = ((Ember.get(forecastData, "root.children") || Ember.A([])).findBy("type", "group-revenue") || {}).children || [];
            // get detail
            if (filter && filter.endsWith(":detail")) {
              groups = DashboardUtil.getDetailDimensions(groups);
            }
            var dataMap = {};
            var valueText = scenario && isBudgetVsScenario || isFvB ? "value2" : "value",
              value2Text = scenario === BVA ? "convertedActualValue" : scenario && isBudgetVsScenario || isFvB ? "value" : "value2";
            var mapByValue = function mapByValue(monthData) {
              if (isRollingForecast) {
                return (value_factors[monthData.key] ? monthData["convertedActualValue"] : monthData[valueText]) || 0;
              }
              return monthData[valueText] || 0;
            };
            var mapByValue2 = function mapByValue2(monthData) {
              return monthData[value2Text] || 0;
            };
            groups.forEach(function (group, i) {
              var groupValue = group.group_value_with_parent;
              var groupName = groupValue + (scenario === BVA && !isRollingForecast || scenario && isBudgetVsScenario || isFvB ? DashboardUtil.getSecondSeriesName(controller) : DashboardUtil.getFirstSeriesName(controller));
              var groupName2 = groupValue + DashboardUtil.getFirstSeriesName(controller);
              var groupRevenues = group;
              if (groupRevenues) {
                if (!dataMap[groupName]) {
                  var stack = scenario === BVA || scenario && isBudgetVsScenario || isFvB ? DashboardUtil.getSecondStackName(controller) : DashboardUtil.getFirstStackName(controller);
                  dataMap[groupName] = {
                    name: groupName,
                    data: undefined,
                    stack: stack
                  };
                  if (isInteractiveDashboardType) {
                    dataMap[groupName].color = DashboardUtil.greyColorSchema[i % DashboardUtil.greyColorSchema.length];
                  }
                }
                dataMap[groupName].data = groupRevenues.values.map(mapByValue);
                if (scenario && isBudgetVsScenario || isFvB) {
                  if (!dataMap[groupName2]) {
                    dataMap[groupName2] = {
                      name: groupName2,
                      data: undefined,
                      stack: "".concat(DashboardUtil.getFirstStackName(controller))
                    };
                  }
                  dataMap[groupName2].data = groupRevenues.values.map(mapByValue2);
                }
              }
            });
            // remove empty values
            DashboardUtil.removeEmptyValues(dataMap);
            var _revenueCategories = changeDateFormatArray(forecastData.value_factors.mapBy("key"), chart.get("periodType")),
              _revenueData = mapObjectToArray(dataMap);
            if (changePeriod) {
              var _newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
                _newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
              var _DashboardUtil$getSta10 = DashboardUtil.getStartAndEndDateIndexs(_revenueCategories, _newStartDate, _newEndDate),
                _startIndex = _DashboardUtil$getSta10.startIndex,
                _endIndex = _DashboardUtil$getSta10.endIndex;
              _revenueCategories = DashboardUtil.changeDataArrayPeriod(_revenueCategories, _startIndex, _endIndex);
              DashboardUtil.changeDataPeriod(_revenueData, _startIndex, _endIndex);
            }
            revenueChart.setProperties({
              highchartCategories: _revenueCategories,
              highchartData: _revenueData,
              highchartShowAnimation: true,
              value_factors: value_factors,
              highchartCurrency: currencyArrayToSymbols([budget.get("forecastAttributes.currency")])
            });
            resolve({
              revenues: revenueChart,
              revenueGrowthChart: revenueGrowthChart
            });
          }
        };
        var revData;
        if (filter) {
          var revLine = pnlReport.lines.findBy("id", "rev");
          revLine.groupBy = filter.replace(":detail", "");
        }
        options.customReport = pnlReport;
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
            selectedPeriod: periodType
          }),
          forecastData;
        options.store = store;
        options = Ember.Object.create(options);
        forecastData = cache.getItem(budget.get("id"), "getForecastDataModel", hashOptions);
        if (!forecastData) {
          forecastData = _forecast.default.getForecastDataModel(options, controller.get("cache"), viewType, budget.get("id"), options, controller);
          forecastData.then(function (data) {
            cache.setItem(budget.get("id"), "getForecastDataModel", hashOptions, data);
            promiseCallback(data);
          });
        } else {
          promiseCallback(forecastData);
        }
      });
    },
    calcBookings: function calcBookings(controller, store, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        var bookingsChart = Ember.Object.create({}),
          bookingsTypeChart = Ember.Object.create({}),
          budget = controller.get("model"),
          scenario = controller.get("scenario"),
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          periodType = chart.get("periodType") || "Monthly",
          forecastViewOption = controller.get("forecastViewOption"),
          vsOption = controller.get("vsOption"),
          dimensions = controller.get("dimensions"),
          isFvB = controller.get("isFvB"),
          options = {
            selectedScenario: scenario ? "#" : "",
            scenario: scenario === BVA ? null : scenario,
            currency: budget.get("forecastAttributes.currency"),
            type: "booking",
            dimensions: dimensions,
            budgetViewType: forecastViewOption ? "forecast" : undefined,
            forecastViewOption: forecastViewOption ? forecastViewOption : undefined,
            vsOption: vsOption ? vsOption : undefined
          },
          isInteractiveDashboardType = controller.get("isInteractiveDashboardType") && scenario,
          cache = controller.get("cache"),
          isNonProfitBudget = controller.get("isNonProfitBudget"),
          bookingsTitle = isNonProfitBudget ? intl.t("general.income_1698234257150") : intl.t("general.bookings_1698234255113"),
          bookingsTitleStart = isNonProfitBudget ? intl.t("general.name_1695611602498") : intl.t("general.title_1695611598161");
        var bookingsData = [],
          bookingsDataScenario = [];
        var promiseCallback = function promiseCallback(bookingsData) {
          bookingsData = [bookingsData];
          bookingsChart.setProperties({
            highchartData: [],
            highchartShowAnimation: true,
            highchartCurrency: []
          });
          bookingsTypeChart.setProperties({
            highchartData: [],
            highchartShowAnimation: true,
            highchartCurrency: []
          });
          var bookingsMap = {
            cf: {},
            expansion: {},
            newAmount: {},
            renewal: {}
          };
          var bookingsMapBase = {
            cf: {},
            expansion: {},
            newAmount: {},
            renewal: {}
          };
          var getAccountData = function getAccountData(accounts, key, bookingsMapObj) {
            var data = accounts[key];
            for (var monthKey in data) {
              if (!bookingsMapObj[key][monthKey]) {
                bookingsMapObj[key][monthKey] = 0;
              }
              bookingsMapObj[key][monthKey] = bookingsMapObj[key][monthKey] + (data[monthKey] || 0);
            }
          };
          var findBooking = function findBooking(budget, bookingsMapObj) {
            if (!budget) {
              return;
            }
            if (budget.accounts) {
              getAccountData(budget.accounts, "cf", bookingsMapObj);
              getAccountData(budget.accounts, "expansion", bookingsMapObj);
              getAccountData(budget.accounts, "newAmount", bookingsMapObj);
              getAccountData(budget.accounts, "renewal", bookingsMapObj);
              return;
            }
            if (budget.children) {
              budget.children.forEach(function (child) {
                findBooking(child, bookingsMapObj);
              });
            }
          };

          // calc booking for budget
          bookingsData.forEach(function (company) {
            findBooking(company, bookingsMap);
          });
          if (bookingsData && bookingsData[0]) {
            bookingsMap.keys = Object.keys(bookingsData[0].items).sort();
          } else {
            bookingsMap.keys = Object.keys(bookingsMap.cf).sort();
          }
          if (periodType !== "Monthly") {
            var yearStart = Ember.get(budget, "yearStart");
            bookingsMap.cf = _forecast.default.changePeriod(bookingsMap.cf, periodType, yearStart);
            bookingsMap.expansion = _forecast.default.changePeriod(bookingsMap.expansion, periodType, yearStart);
            bookingsMap.newAmount = _forecast.default.changePeriod(bookingsMap.newAmount, periodType, yearStart);
            bookingsMap.renewal = _forecast.default.changePeriod(bookingsMap.renewal, periodType, yearStart);
            if (bookingsData && bookingsData[0]) {
              var items = _forecast.default.changePeriod(bookingsData[0].items, periodType, yearStart);
              bookingsMap.keys = Object.keys(items).sort();
            } else {
              bookingsMap.keys = Object.keys(bookingsMap.cf).sort();
            }
          }
          if (scenario && isBudgetVsScenario || isFvB && forecastViewOption && vsOption) {
            var newOptions = Ember.Object.create({
              selectedScenario: "",
              scenario: "",
              currency: budget.get("forecastAttributes.currency"),
              type: "booking"
            });
            var budgetIdVS = Ember.get(budget, "id");
            if (forecastViewOption) {
              budgetIdVS = controller.get("budgetService.selectedRoot.id");
              if (vsOption !== "#") {
                newOptions.budgetViewType = "forecast";
                newOptions.forecastViewOption = vsOption;
                newOptions.vsOption = undefined;
                budgetIdVS += "|" + vsOption;
              }
            }
            var _hashOptions = _forecast.default.calcForecastHash(cache, newOptions, budget, undefined, undefined, undefined, {
              selectedPeriod: periodType
            });
            options = Ember.Object.create(newOptions);
            bookingsDataScenario = cache.getItem(budgetIdVS, "calcForecast", _hashOptions);
            if (!bookingsDataScenario) {
              bookingsDataScenario = _forecast.default.calcForecast(controller.get("cache"), store, budgetIdVS, null, options, 0);
              cache.setItem(budgetIdVS, "calcForecast", _hashOptions, bookingsDataScenario);
            }
            bookingsDataScenario = [bookingsDataScenario];
            bookingsDataScenario.forEach(function (company) {
              findBooking(company, bookingsMapBase);
            });
            if (bookingsDataScenario && bookingsDataScenario[0]) {
              bookingsMapBase.keys = Object.keys(bookingsData[0].items).sort();
            } else {
              bookingsMapBase.keys = Object.keys(bookingsData.cf).sort();
            }
            if (periodType !== "Monthly") {
              var _yearStart = Ember.get(budget, "yearStart");
              bookingsMapBase.cf = _forecast.default.changePeriod(bookingsMapBase.cf, periodType, _yearStart);
              bookingsMapBase.expansion = _forecast.default.changePeriod(bookingsMapBase.expansion, periodType, _yearStart);
              bookingsMapBase.newAmount = _forecast.default.changePeriod(bookingsMapBase.newAmount, periodType, _yearStart);
              bookingsMapBase.renewal = _forecast.default.changePeriod(bookingsMapBase.renewal, periodType, _yearStart);
              if (bookingsDataScenario && bookingsDataScenario[0]) {
                var _items = _forecast.default.changePeriod(bookingsDataScenario[0].items, periodType, _yearStart);
                bookingsMapBase.keys = Object.keys(_items).sort();
              } else {
                bookingsMapBase.keys = Object.keys(bookingsMapBase.cf).sort();
              }
            }
          }
          var expansionData1 = bookingsData.map(function (sub) {
            var stack = DashboardUtil.getFirstStackName(controller);
            return {
              name: "Expansion " + bookingsTitle + (bookingsData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getFirstSeriesName(controller),
              data: [],
              stack: stack.toLowerCase(),
              type: "column",
              yAxis: 0
            };
          });
          var expansionData2 = bookingsDataScenario.map(function (sub) {
            var series = {
              name: "Expansion " + bookingsTitle + (bookingsDataScenario.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getSecondSeriesName(controller),
              data: [],
              stack: "budget",
              type: "column",
              yAxis: 0
            };
            if (isInteractiveDashboardType) {
              series.color = DashboardUtil.greyColorSchema[0];
            }
            return series;
          });
          var newBookingsData1 = bookingsData.map(function (sub) {
            var stack = DashboardUtil.getFirstStackName(controller);
            return {
              name: "New " + bookingsTitle + (bookingsData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getFirstSeriesName(controller),
              data: [],
              stack: stack.toLowerCase(),
              type: "column",
              yAxis: 0
            };
          });
          var newBookingsData2 = bookingsDataScenario.map(function (sub) {
            var series = {
              name: "New " + bookingsTitle + (bookingsDataScenario.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getSecondSeriesName(controller),
              data: [],
              stack: "budget",
              type: "column",
              yAxis: 0
            };
            if (isInteractiveDashboardType) {
              series.color = DashboardUtil.greyColorSchema[1];
            }
            return series;
          });
          var renewalBookingsData1 = bookingsData.map(function (sub) {
            var stack = DashboardUtil.getFirstStackName(controller);
            return {
              name: "Renewal " + bookingsTitle + (bookingsData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getFirstSeriesName(controller),
              data: [],
              stack: stack.toLowerCase(),
              type: "column",
              yAxis: 0
            };
          });
          var renewalBookingsData2 = bookingsDataScenario.map(function (sub) {
            var series = {
              name: "Renewal " + bookingsTitle + (bookingsDataScenario.length > 1 ? sub.forecast.name : "") + DashboardUtil.getSecondSeriesName(controller),
              data: [],
              stack: "budget",
              type: "column",
              yAxis: 0
            };
            if (isInteractiveDashboardType) {
              series.color = DashboardUtil.greyColorSchema[2];
            }
            return series;
          });
          var bookingsData1 = bookingsData.map(function (sub) {
            var stack = DashboardUtil.getFirstStackName(controller);
            return {
              name: bookingsTitleStart + (bookingsData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getFirstSeriesName(controller),
              data: [],
              stack: stack.toLowerCase(),
              type: "column",
              yAxis: 0
            };
          });
          var bookingsData2 = bookingsDataScenario.map(function (sub) {
            var series = {
              name: bookingsTitleStart + (bookingsData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getSecondSeriesName(controller),
              data: [],
              stack: "budget",
              type: "column",
              yAxis: 0
            };
            if (isInteractiveDashboardType) {
              series.color = DashboardUtil.greyColorSchema[0];
            }
            return series;
          });
          var bookingsGrowthData1 = bookingsData.map(function (sub) {
            var stack = DashboardUtil.getFirstStackName(controller);
            return {
              name: bookingsTitleStart + " growth" + (bookingsData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getFirstSeriesName(controller),
              data: [],
              stack: stack.toLowerCase(),
              type: "spline",
              yAxis: 1
            };
          });
          var bookingsGrowthData2 = bookingsDataScenario.map(function (sub) {
            var series = {
              name: bookingsTitleStart + " growth" + (bookingsData.length > 1 ? " " + sub.forecast.name : "") + DashboardUtil.getSecondSeriesName(controller),
              data: [],
              stack: "budget",
              type: "spline",
              yAxis: 1
            };
            if (isInteractiveDashboardType) {
              series.color = DashboardUtil.greyColorSchema[1];
            }
            return series;
          });
          if (!(scenario && scenario === BVA) || forecastViewOption) {
            // without bva
            var lastBookings1 = null;
            bookingsMap.keys.forEach(function (key) {
              expansionData1[0].data.push(bookingsMap.expansion[key] || null);
              newBookingsData1[0].data.push(bookingsMap.newAmount[key] || null);
              renewalBookingsData1[0].data.push(bookingsMap.renewal[key] || null);
              bookingsData1[0].data.push(bookingsMap.cf[key] || null);
              bookingsGrowthData1[0].data.push(!lastBookings1 ? null : 100 * (bookingsMap.cf[key] / lastBookings1 - 1));
              lastBookings1 = bookingsMap.cf[key];
            });
            if (scenario && isBudgetVsScenario || isFvB) {
              // build base
              var lastBookings2 = null;
              bookingsMapBase.keys.forEach(function (key) {
                expansionData2[0].data.push(bookingsMapBase.expansion[key] || null);
                newBookingsData2[0].data.push(bookingsMapBase.newAmount[key] || null);
                renewalBookingsData2[0].data.push(bookingsMapBase.renewal[key] || null);
                bookingsData2[0].data.push(bookingsMapBase.cf[key] || null);
                bookingsGrowthData2[0].data.push(!lastBookings2 ? null : 100 * (bookingsMapBase.cf[key] / lastBookings2 - 1));
                lastBookings2 = bookingsMapBase.cf[key];
              });
            }
          }
          var bookingsTypeData1 = expansionData1.concat(newBookingsData1).concat(renewalBookingsData1);
          var bookingsTypeData2 = expansionData2.concat(newBookingsData2).concat(renewalBookingsData2);
          var bookingVSGrowthData1 = bookingsData1.concat(bookingsGrowthData1);
          var bookingVSGrowthData2 = bookingsData2.concat(bookingsGrowthData2);
          var bookingsCategories = changeDateFormatArray(bookingsMap.keys, chart.get("periodType")),
            bookingsChartData = scenario && isBudgetVsScenario || forecastViewOption && isFvB ? bookingVSGrowthData1.concat(bookingVSGrowthData2) : bookingVSGrowthData1,
            bookingsTypeCategories = bookingsCategories,
            bookingsTypeData = scenario && isBudgetVsScenario || forecastViewOption && isFvB ? bookingsTypeData1.concat(bookingsTypeData2) : bookingsTypeData1,
            budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
            budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
            chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
            chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth();
          if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
            var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
              newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
            var _DashboardUtil$getSta11 = DashboardUtil.getStartAndEndDateIndexs(bookingsCategories, newStartDate, newEndDate),
              startIndex = _DashboardUtil$getSta11.startIndex,
              endIndex = _DashboardUtil$getSta11.endIndex;
            bookingsCategories = DashboardUtil.changeDataArrayPeriod(bookingsCategories, startIndex, endIndex);
            bookingsTypeCategories = DashboardUtil.changeDataArrayPeriod(bookingsTypeCategories, startIndex, endIndex);
            DashboardUtil.changeDataPeriod(bookingsChartData, startIndex, endIndex);
            DashboardUtil.changeDataPeriod(bookingsTypeData, startIndex, endIndex);
          } else {
            // check overlapdates
            var numberOfMonths = chartEndDate.monthsSince(chartStartDate) + 1;
            for (var i = 0; i < bookingsChartData.length; i++) {
              if (bookingsChartData[i].data.length > numberOfMonths) {
                bookingsChartData[i].data = bookingsChartData[i].data.slice(0, numberOfMonths - bookingsChartData[i].data.length);
              }
            }
            for (var _i2 = 0; _i2 < bookingsTypeData.length; _i2++) {
              if (bookingsTypeData[_i2].data.length > numberOfMonths) {
                bookingsTypeData[_i2].data = bookingsTypeData[_i2].data.slice(0, numberOfMonths - bookingsTypeData[_i2].data.length);
              }
            }
            if (bookingsCategories.length > numberOfMonths) {
              bookingsCategories = bookingsCategories.slice(0, numberOfMonths - bookingsCategories.length);
            }
            if (bookingsTypeCategories.length > numberOfMonths) {
              bookingsTypeCategories = bookingsTypeCategories.slice(0, numberOfMonths - bookingsTypeCategories.length);
            }
          }
          bookingsChart.setProperties({
            highchartCategories: bookingsCategories,
            highchartData: bookingsChartData,
            highchartShowAnimation: true,
            highchartCurrency: scenario && isBudgetVsScenario || isFvB ? currencyArrayToSymbols([budget.get("forecastAttributes.currency"), "%", budget.get("forecastAttributes.currency"), "%"]) : currencyArrayToSymbols([budget.get("forecastAttributes.currency"), "%"])
          });

          // remove empty booking data
          bookingsTypeData = bookingsTypeData.filter(function (serie) {
            return serie.data && _const.default.isNonZeroArray(serie.data);
          });
          bookingsTypeChart.setProperties({
            highchartCategories: bookingsTypeCategories,
            highchartData: bookingsTypeData,
            highchartShowAnimation: true,
            highchartCurrency: currencyArrayToSymbols([budget.get("forecastAttributes.currency")])
          });
          resolve({
            bookings: bookingsChart,
            bookingsType: bookingsTypeChart
          });
        };
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
          selectedPeriod: periodType
        });
        options = Ember.Object.create(options);
        bookingsData = cache.getItem(budget.get("id"), "calcForecast", hashOptions);
        if (!bookingsData) {
          _forecast.default.calcForecastNonBlocking(controller.get("cache"), store, Ember.get(budget, "id"), null, options, controller).then(function (bookingsData) {
            cache.setItem(budget.get("id"), "calcForecast", hashOptions, bookingsData);
            promiseCallback(bookingsData);
          });
        } else {
          promiseCallback(bookingsData);
        }
      });
    },
    calcBookingsBy: function calcBookingsBy(controller, store, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        var bookingsChart = Ember.Object.create({}),
          budget = controller.get("model"),
          scenario = controller.get("scenario"),
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          periodType = chart.get("periodType") || "Monthly",
          forecastViewOption = controller.get("forecastViewOption"),
          filter = chart.get("filters.by") === "all" ? undefined : chart.get("filters.by"),
          isRollingForecast = controller.get("isRollingForecastViewType"),
          dimensions = controller.get("dimensions"),
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          changePeriod = chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf()),
          isFvB = controller.get("isFvB"),
          value_factors = {},
          options = {
            selectedScenario: scenario ? "#" : "",
            scenario: scenario === BVA ? null : scenario,
            currency: budget.get("forecastAttributes.currency"),
            type: "booking",
            selectedPeriod: periodType,
            dimensions: dimensions,
            budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
            actualViewOption: isRollingForecast ? "rollf" : undefined,
            revenueType: undefined,
            forecastViewOption: controller.get("forecastViewOption") ? controller.get("forecastViewOption") : undefined,
            vsOption: controller.get("vsOption") ? controller.get("vsOption") : undefined
          },
          isInteractiveDashboardType = controller.get("isInteractiveDashboardType") && scenario,
          cache = controller.get("cache");
        if (filter) {
          options.groupByDimension = Ember.get(dimensions.findBy("dimension.uniqueId", filter.replace(":detail", "")), "dimension");
        }
        var promiseCallback = function promiseCallback(bookingsData) {
          // bookingsData = [bookingsData];
          bookingsChart.setProperties({
            highchartData: [],
            highchartShowAnimation: true,
            highchartCurrency: []
          });

          // build for filter
          var groups = [Ember.get(bookingsData, "root") || {}];
          // get detail
          if (filter) {
            groups = groups[0].children;
            if (filter.endsWith(":detail")) {
              groups = DashboardUtil.getDetailDimensions(groups);
            }
          }
          var dataMap = {};
          var valueText = scenario && isBudgetVsScenario || isFvB ? "value2" : "value",
            value2Text = scenario === BVA ? "convertedActualValue" : scenario && isBudgetVsScenario || isFvB ? "value" : "value2";
          var mapByValue = function mapByValue(monthData) {
            if (isRollingForecast) {
              return (value_factors[monthData.key] ? monthData["convertedActualValue"] : monthData[valueText]) || 0;
            }
            return monthData[valueText] || 0;
          };
          var mapByValue2 = function mapByValue2(monthData) {
            return monthData[value2Text] || 0;
          };
          groups.forEach(function (group, i) {
            var groupValue = group.group_value_with_parent;
            var groupName = groupValue + (scenario === BVA && !isRollingForecast || scenario && isBudgetVsScenario || isFvB ? DashboardUtil.getSecondSeriesName(controller) : DashboardUtil.getFirstSeriesName(controller));
            var groupName2 = groupValue + DashboardUtil.getFirstSeriesName(controller);
            if (group) {
              if (!dataMap[groupName]) {
                var stack = scenario === BVA || scenario && isBudgetVsScenario || isFvB ? DashboardUtil.getSecondStackName(controller) : DashboardUtil.getFirstStackName(controller);
                dataMap[groupName] = {
                  name: groupName,
                  data: undefined,
                  stack: stack
                };
                if (isInteractiveDashboardType) {
                  dataMap[groupName].color = DashboardUtil.greyColorSchema[i % DashboardUtil.greyColorSchema.length];
                }
              }
              dataMap[groupName].data = group.values.map(mapByValue);
              if (scenario && isBudgetVsScenario || isFvB) {
                if (!dataMap[groupName2]) {
                  dataMap[groupName2] = {
                    name: groupName2,
                    data: undefined,
                    stack: "".concat(DashboardUtil.getFirstStackName(controller))
                  };
                }
                dataMap[groupName2].data = group.values.map(mapByValue2);
              }
            }
          });
          // remove empty values
          DashboardUtil.removeEmptyValues(dataMap);
          var bookingsCategories = changeDateFormatArray(bookingsData.value_factors.mapBy("key"), chart.get("periodType")),
            bookingsByData = mapObjectToArray(dataMap);
          if (changePeriod) {
            var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
              newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
            var _DashboardUtil$getSta12 = DashboardUtil.getStartAndEndDateIndexs(bookingsCategories, newStartDate, newEndDate),
              startIndex = _DashboardUtil$getSta12.startIndex,
              endIndex = _DashboardUtil$getSta12.endIndex;
            bookingsCategories = DashboardUtil.changeDataArrayPeriod(bookingsCategories, startIndex, endIndex);
            DashboardUtil.changeDataPeriod(bookingsByData, startIndex, endIndex);
          }
          bookingsChart.setProperties({
            highchartCategories: bookingsCategories,
            highchartData: bookingsByData,
            highchartShowAnimation: true,
            value_factors: value_factors,
            highchartCurrency: currencyArrayToSymbols([budget.get("forecastAttributes.currency")])
          });
          resolve(bookingsChart);
        };
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
            selectedPeriod: periodType
          }),
          forecastData;
        options.store = store;
        options = Ember.Object.create(options);
        forecastData = cache.getItem(budget.get("id"), "getForecastDataModel", hashOptions);
        if (!forecastData) {
          forecastData = _forecast.default.getForecastDataModel(options, controller.get("cache"), "booking", budget.get("id"), options, controller);
          forecastData.then(function (data) {
            cache.setItem(budget.get("id"), "getForecastDataModel", hashOptions, data);
            promiseCallback(data);
          });
        } else {
          promiseCallback(forecastData);
        }
      });
    },
    calcBookingsCashRev: function calcBookingsCashRev(controller, store, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        DashboardUtil.calcRevenues(controller, store, chart, undefined).then(function (revenuesPNL) {
          DashboardUtil.calcBookings(controller, store, chart).then(function (bookings) {
            DashboardUtil.calcRevenues(controller, store, chart, "cf").then(function (revenuesCash) {
              var revPnlData = revenuesPNL.revenues.highchartData[0],
                revCashData = revenuesCash.revenues.highchartData[0],
                bookingsData = bookings.bookings.highchartData[0],
                revPnlDataBase,
                revCashDataBase,
                bookingsDataBase,
                forecastViewOption = controller.get("forecastViewOption"),
                isFvB = controller.get("isFvB"),
                scenario = controller.get("scenario"),
                isBudgetVsScenario = controller.get("isBudgetVsScenario"),
                addScenarioName = scenario ? scenario === BVA ? "" : scenario + " " : "",
                bookingsCashRevHighchartData,
                isNonProfitBudget = controller.get("isNonProfitBudget"),
                bookingsTitleStart = isNonProfitBudget ? intl.t("general.name_1695611602498") : intl.t("general.title_1695611598161");
              addScenarioName = DashboardUtil.getFirstSeriesName(controller);
              if (!(scenario && scenario === BVA) || forecastViewOption) {
                // without bva
                if (!revPnlData) {
                  revPnlData = {
                    data: [],
                    name: "",
                    stack: "budget",
                    type: "column",
                    yAxis: 0
                  };
                }
                if (!revCashData) {
                  revCashData = {
                    data: [],
                    name: "",
                    stack: "budget",
                    type: "column",
                    yAxis: 0
                  };
                }
                revPnlData.name = intl.t("general.dashboard-45", {
                  var1: addScenarioName
                });
                revCashData.name = intl.t("general.dashboard-46", {
                  var1: addScenarioName
                });
                bookingsData.name = "".concat(bookingsTitleStart).concat(addScenarioName);
                bookingsCashRevHighchartData = [revPnlData, bookingsData, revCashData];
                if (scenario && isBudgetVsScenario || isFvB) {
                  revPnlDataBase = revenuesPNL.revenues.highchartData[1];
                  revCashDataBase = revenuesCash.revenues.highchartData[1];
                  bookingsDataBase = bookings.bookings.highchartData[2];
                  if (!revPnlDataBase) {
                    revPnlDataBase = {
                      data: [],
                      name: "",
                      stack: "budget",
                      type: "column",
                      yAxis: 0
                    };
                  }
                  if (!revCashDataBase) {
                    revCashDataBase = {
                      data: [],
                      name: "",
                      stack: "budget",
                      type: "column",
                      yAxis: 0
                    };
                  }
                  revPnlDataBase.name = intl.t("general.dashboard-47", {
                    var1: DashboardUtil.getSecondSeriesName(controller)
                  });
                  revCashDataBase.name = intl.t("general.dashboard-48", {
                    var1: DashboardUtil.getSecondSeriesName(controller)
                  });
                  bookingsDataBase.name = intl.t("general.dashboard-49", {
                    var1: DashboardUtil.getSecondSeriesName(controller)
                  });
                  bookingsCashRevHighchartData.pushObjects([revPnlDataBase, bookingsDataBase, revCashDataBase]);
                }
              } else {
                // for bva
                bookingsCashRevHighchartData = [];
              }
              resolve({
                highchartData: bookingsCashRevHighchartData,
                highchartCategories: bookings.bookings.highchartCategories,
                highchartCurrency: revenuesCash.revenues.highchartCurrency
              });
            });
          });
        });
      });
    },
    calcHeadcount: function calcHeadcount(controller, options) {
      var countNew = options.countNew,
        chart = options.chart,
        calcType = options.calcType,
        withoutContractors = options.withoutContractors;
      var headCountChart = Ember.Object.create({});
      var headcountName = countNew ? intl.t("general.title_1695611597653") : intl.t("general.overall_headcount_1698234257147");
      headCountChart.set("highchartShowAnimation", true);
      headCountChart.set("showLegend", false);
      if (controller.get("scenario") === BVA) {
        headCountChart.set("highchartData", undefined);
        headCountChart.set("highchartCategories", undefined);
      } else {
        var headcountHelper = DashboardUtil.calcHeadcountHelper({
          controller: controller,
          countNew: countNew,
          chart: chart,
          headcountName: headcountName,
          calcType: calcType,
          withoutContractors: withoutContractors
        });
        headCountChart.set("highchartData", headcountHelper.data);
        headCountChart.set("highchartCategories", headcountHelper.categories);
      }
      return {
        headCountChart: headCountChart
      };
    },
    calcHeadcountHelper: function calcHeadcountHelper(options) {
      var controller = options.controller,
        countNew = options.countNew,
        chart = options.chart,
        headcountName = options.headcountName,
        calcType = options.calcType,
        ignoreRange = options.ignoreRange,
        headcountCalcType = options.headcountCalcType;
      var scenario = controller.get("scenario") && controller.get("scenario") !== "" ? controller.get("scenario") : null,
        isBudgetVsScenario = controller.get("isBudgetVsScenario"),
        isInteractiveDashboardType = controller.get("scenario") && controller.get("isInteractiveDashboardType") ? " (Modified)" : null,
        isActuals = false,
        dimensions,
        globalDimensionsFilters = controller.get("dimensions"),
        calculationType = headcountCalcType === "cost" ? "cost_employee_filter" : "count_employee_filter",
        budget = controller.get("model"),
        highchartCategories = Ember.A([]),
        highchartData = [],
        highchartDataScenario = [],
        highchartDataMap = {},
        highchartDataScenarioMap = {},
        isFvB = controller.get("isFvB"),
        periodType = chart.get("periodType") || "Monthly",
        forecastViewOption = controller.get("forecastViewOption"),
        budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
        budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
        chartStartDate = chart.get("startDate") && periodType === "Monthly" ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
        chartEndDate = chart.get("endDate") && periodType === "Monthly" ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth();
      if (scenario === BVA) {
        scenario = null;
        isActuals = true;
      }
      dimensions = globalDimensionsFilters.map(function (d) {
        return {
          dimension: Ember.get(d, "dimension.uniqueId"),
          values: Ember.get(d, "selectedValue") === "all" ? [] : [Ember.get(d, "selectedValue")]
        };
      });
      var includeContractos = chart.get("includeContractos");
      var calcOptions = {
        calculationFilter: {
          monthsOnly: true,
          onlyNew: countNew ? true : false,
          dimensions: dimensions,
          noDimensionDrillDown: true,
          type: includeContractos ? 2 : 0
        }
      };
      var calcOptionsScenario = {
        calculationFilter: {
          monthsOnly: true,
          onlyNew: countNew ? true : false,
          dimensions: dimensions,
          noDimensionDrillDown: true,
          type: includeContractos ? 2 : 0
        },
        scenario: scenario
      };
      var filter = chart.get("filters.by") === "all" ? undefined : chart.get("filters.by"),
        fullFilter = filter ? filter.replace(":detail", "") : undefined,
        isDetail = filter && filter.endsWith(":detail"),
        globalDimensionFilter,
        dimensionFilterSubtree,
        allFilterOptions;
      if (filter) {
        globalDimensionFilter = globalDimensionsFilters.findBy("dimension.uniqueId", fullFilter);
        dimensionFilterSubtree = DashboardUtil.getDiemensionSubtree(globalDimensionFilter);
        if (Ember.get(globalDimensionFilter, "selectedValue") !== "no") {
          allFilterOptions = DashboardUtil.getAllFillterOptionsArray(globalDimensionFilter, dimensionFilterSubtree, filter, globalDimensionFilter);
        } else {
          allFilterOptions = [];
        }
      }
      var headCountByDimension = [];
      var headCountScenarioByDimension = [];
      if (allFilterOptions) {
        allFilterOptions.forEach(function (filter) {
          var cloneDimensions = _lodash.default.cloneDeep(dimensions);
          var foundEmptyIntersection = false;
          var tempDimensions = cloneDimensions.map(function (dimension) {
            var newValues = dimension.values;
            if (Ember.get(dimension, "dimension") === Ember.get(globalDimensionFilter, "dimension.uniqueId")) {
              if (isDetail) {
                newValues.addObject(Ember.get(filter, "uniqueId"));
              } else {
                var dimensionValues = Ember.get(globalDimensionFilter, "dimension.values");
                var parent = _dimensions.default.findDimensionValue(Ember.get(filter, "uniqueId"), dimensionValues);
                var filterSubtree = _dimensions.default.getDimensionSubtree({
                  parent: parent,
                  dimensionTree: dimensionValues
                });
                var treeIntersection = _lodash.default.intersection(filterSubtree.mapBy("uniqueId"), dimensionFilterSubtree.mapBy("uniqueId"));
                if (!treeIntersection.length) {
                  foundEmptyIntersection = true;
                }
                newValues.addObjects(treeIntersection);
              }
            }
            return {
              dimension: dimension.dimension,
              values: newValues
            };
          });
          if (foundEmptyIntersection) {
            return;
          }
          var calcOptionsTemp = _lodash.default.cloneDeep(calcOptions);
          var calcOptionsScenarioTemp = _lodash.default.cloneDeep(calcOptionsScenario);
          calcOptionsTemp.calculationFilter.dimensions = tempDimensions;
          calcOptionsScenarioTemp.calculationFilter.dimensions = tempDimensions;
          if (!scenario || isBudgetVsScenario || isInteractiveDashboardType) {
            var headCount = _forecast.default.runCalculation(controller.get("cache"), controller.store, budget.get("id"), budget, calculationType, calcOptionsTemp);
            headCount = DashboardUtil.removeOutOfRangeKeys(headCount, chartStartDate, chartEndDate);
            headCountByDimension.push({
              dimensionName: Ember.get(filter, "displayName"),
              hc: headCount
            });
          }
          if (scenario) {
            var headCountScenario = scenario ? _forecast.default.runCalculation(controller.get("cache"), controller.store, budget.get("id"), budget, calculationType, calcOptionsScenarioTemp) : {};
            headCountScenario = DashboardUtil.removeOutOfRangeKeys(headCountScenario, chartStartDate, chartEndDate);
            headCountScenarioByDimension.push({
              dimensionName: Ember.get(filter, "displayName"),
              hc: headCountScenario
            });
          }
        });
      } else {
        var headCount = _forecast.default.runCalculation(controller.get("cache"), controller.store, budget.get("id"), budget, calculationType, calcOptions);
        var headCountScenario = scenario ? _forecast.default.runCalculation(controller.get("cache"), controller.store, budget.get("id"), budget, calculationType, calcOptionsScenario) : {};
        headCount = DashboardUtil.removeOutOfRangeKeys(headCount, chartStartDate, chartEndDate);
        headCountScenario = DashboardUtil.removeOutOfRangeKeys(headCountScenario, chartStartDate, chartEndDate);
        headCountByDimension.push({
          hc: headCount
        });
        headCountScenarioByDimension.push({
          hc: headCountScenario
        });
      }
      if (filter && globalDimensionFilter && (globalDimensionFilter.selectedValue === "all" || globalDimensionFilter.selectedValue === "no")) {
        var tempDimensions = _lodash.default.cloneDeep(dimensions);
        var tempDimension = tempDimensions.findBy("dimension", Ember.get(globalDimensionFilter, "dimension.uniqueId"));
        tempDimension.values.addObject("no");
        var calcOptionsTemp = _lodash.default.cloneDeep(calcOptions);
        var calcOptionsScenarioTemp = _lodash.default.cloneDeep(calcOptionsScenario);
        calcOptionsTemp.calculationFilter.dimensions = tempDimensions;
        calcOptionsScenarioTemp.calculationFilter.dimensions = tempDimensions;
        if (!scenario || isBudgetVsScenario || isInteractiveDashboardType) {
          var _headCount = _forecast.default.runCalculation(controller.get("cache"), controller.store, budget.get("id"), budget, calculationType, calcOptionsTemp);
          _headCount = DashboardUtil.removeOutOfRangeKeys(_headCount, chartStartDate, chartEndDate);
          headCountByDimension.push({
            dimensionName: "No " + Ember.get(globalDimensionFilter, "dimension.name"),
            hc: _headCount
          });
        }
        if (scenario) {
          var _headCountScenario = scenario ? _forecast.default.runCalculation(controller.get("cache"), controller.store, budget.get("id"), budget, calculationType, calcOptionsScenarioTemp) : {};
          _headCountScenario = DashboardUtil.removeOutOfRangeKeys(_headCountScenario, chartStartDate, chartEndDate);
          headCountScenarioByDimension.push({
            dimensionName: "No " + Ember.get(globalDimensionFilter, "dimension.name"),
            hc: _headCountScenario
          });
        }
      }
      var calc = function calc(hc, dimensionName) {
        var firstSeriesName = "";
        if (Ember.isEmpty(hc)) {
          return;
        }
        if (forecastViewOption || scenario) {
          firstSeriesName = DashboardUtil.getSecondSeriesName(controller);
        }
        var keys = Object.keys(hc).sort();
        highchartDataMap[(dimensionName || headcountName) + firstSeriesName] = {
          level: undefined,
          data: [],
          keys: keys
        };
        keys.forEach(function (key) {
          highchartDataMap[(dimensionName || headcountName) + firstSeriesName].data.push(hc[key] || 0);
        });
      };
      var calcScenario = function calcScenario(hc, dimensionName) {
        var addScenario = DashboardUtil.getFirstSeriesName(controller);
        if (addScenario) {
          addScenario = "".concat(addScenario);
        }
        if (Ember.isEmpty(hc)) {
          return;
        }
        var keys = Object.keys(hc).sort();
        highchartDataScenarioMap[(dimensionName || headcountName) + addScenario] = {
          level: undefined,
          data: [],
          keys: keys
        };
        keys.forEach(function (key) {
          highchartDataScenarioMap[(dimensionName || headcountName) + addScenario].data.push(hc[key] || 0);
        });
      };
      headCountByDimension.forEach(function (hcObj) {
        if (hcObj) {
          highchartCategories.addObjects(Object.keys(hcObj.hc).sort());
          calc(hcObj.hc, hcObj.dimensionName);
        }
      });
      if (scenario || forecastViewOption) {
        headCountScenarioByDimension.forEach(function (hcObj) {
          if (hcObj) {
            highchartCategories.addObjects(Object.keys(hcObj.hc).sort());
            calcScenario(hcObj.hc, hcObj.dimensionName);
          }
        });
      }

      // remove empty values
      DashboardUtil.removeEmptyValues(highchartDataMap);
      highchartCategories = highchartCategories.sort();

      // add missing periods
      DashboardUtil.addMissingPeriodValues({
        highchartCategories: highchartCategories,
        highchartDataMap: highchartDataMap,
        highchartDataScenarioMap: highchartDataScenarioMap,
        chartStartDate: chartStartDate,
        chartEndDate: chartEndDate
      });
      highchartData = mapHeadcountObjectToHighchartArray(highchartDataMap, forecastViewOption || scenario ? DashboardUtil.getSecondStackName(controller) : DashboardUtil.getFirstStackName(controller), highchartCategories);
      highchartDataScenario = mapHeadcountObjectToHighchartArray(highchartDataScenarioMap, forecastViewOption || scenario ? DashboardUtil.getFirstStackName(controller) : DashboardUtil.getSecondStackName(controller), highchartCategories);

      // padding highchart result
      DashboardUtil.paddingHighchartResult(highchartData, highchartDataScenario, highchartCategories.length);
      DashboardUtil.paddingHighchartMapResult(highchartDataMap, highchartDataScenarioMap, highchartCategories.length);
      if (scenario || forecastViewOption) {
        if (isInteractiveDashboardType) {
          highchartData = highchartData.map(function (data, i) {
            data.color = DashboardUtil.greyColorSchema[i % DashboardUtil.greyColorSchema.length];
            return data;
          });
        }
        if (scenario && isBudgetVsScenario || isFvB) {
          highchartData = highchartData.concat(highchartDataScenario);
        } else {
          highchartData = highchartDataScenario;
        }
      }
      if (isActuals) {
        for (var i = 0; i < highchartData.length; i++) {
          highchartData[i].name += DashboardUtil.getSecondSeriesName(controller);
        }
      }
      highchartCategories = changeDateFormatArray(highchartCategories, periodType, {
        ignoreYearly: true
      });
      var newCategories = [];
      var reduceData = function reduceData(highchartData, highchartCategories, highchartDataMap, highchartDataScenarioMap, reduce, calcType, yearStart) {
        var period = reduce === 3 ? "q" : "y";
        var convertMonths = highchartCategories.map(function (month) {
          return _const.default.convertMonth(Date.create("01 " + month).beginningOfMonth(), period, yearStart || 0);
        });
        var reducerArr = function reducerArr(data) {
          var value = 0,
            counter = 0;
          var newData = [];
          for (var _i3 = 0; _i3 < data.length; _i3++) {
            value += data[_i3];
            counter++;
            if (!convertMonths[_i3 + 1] || convertMonths[_i3 + 1] !== convertMonths[_i3]) {
              if (calcType === "avg") {
                newData.push(value / counter);
              } else if (calcType === "sum") {
                newData.push(value);
              } else {
                newData.push(data[_i3]);
              }
              value = 0;
              counter = 0;
            }
          }
          return newData;
        };
        // reduce highchart data
        highchartData.forEach(function (dataObj) {
          var data = dataObj.data;
          dataObj.data = reducerArr(data);
        });
        // reduce highchart categories
        for (var _i4 = 0; _i4 < highchartCategories.length; _i4++) {
          if (!convertMonths[_i4 + 1] || convertMonths[_i4 + 1] !== convertMonths[_i4]) {
            newCategories.push(countNew ? convertMonths[_i4] : highchartCategories[_i4]);
          }
        }

        // reduce highchart data map
        for (var key in highchartDataMap) {
          var tempData = highchartDataMap[key].data;
          highchartDataMap[key].data = reducerArr(tempData);
        }

        // reduce highchart data map
        for (var _key in highchartDataScenarioMap) {
          var _tempData = highchartDataScenarioMap[_key].data;
          highchartDataScenarioMap[_key].data = reducerArr(_tempData);
        }
      };
      if (periodType === "Quarterly") {
        reduceData(highchartData, highchartCategories, highchartDataMap, highchartDataScenarioMap, 3, calcType, budget.get("yearStart"));
        highchartCategories = newCategories;
      } else if (periodType === "Yearly") {
        reduceData(highchartData, highchartCategories, highchartDataMap, highchartDataScenarioMap, 12, calcType, budget.get("yearStart"));
        highchartCategories = newCategories;
      } else if (!ignoreRange && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
        var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
          newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
        var _DashboardUtil$getSta13 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
          startIndex = _DashboardUtil$getSta13.startIndex,
          endIndex = _DashboardUtil$getSta13.endIndex;
        highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
        DashboardUtil.changeDataPeriod(highchartData, startIndex, endIndex);
        DashboardUtil.changeObjectPeriod(highchartDataMap, startIndex, endIndex);
        DashboardUtil.changeObjectPeriod(highchartDataScenarioMap, startIndex, endIndex);
      }
      return {
        data: highchartData,
        categories: highchartCategories,
        budgetData: highchartDataMap,
        scenarioData: highchartDataScenarioMap
      };
    },
    calcSalaryPerEmployee: function calcSalaryPerEmployee(controller, budget, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        var scenario = controller.get("scenario"),
          isActuals = false;
        if (scenario === BVA) {
          scenario = null;
          isActuals = true;
        }
        var isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          forecastViewOption = controller.get("forecastViewOption"),
          isFvB = controller.get("isFvB"),
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          highchartData,
          highchartCategories;
        var headcount = DashboardUtil.calcHeadcountHelper({
            controller: controller,
            countNew: false,
            chart: chart,
            headcountName: intl.t("general.avg_salary_1698234257147"),
            calcType: "sum",
            withoutContractors: true,
            ignoreRange: true
          }),
          headcountCost = DashboardUtil.calcHeadcountHelper({
            controller: controller,
            countNew: false,
            chart: chart,
            headcountName: intl.t("general.avg_salary_1698234257147"),
            calcType: "sum",
            headcountCalcType: "cost",
            withoutContractors: true,
            ignoreRange: true
          }),
          budgetData = scenario || forecastViewOption ? headcount.scenarioData : headcount.budgetData,
          budgetData2 = headcount.budgetData,
          budgetDataCost = scenario || forecastViewOption ? headcountCost.scenarioData : headcountCost.budgetData,
          budgetDataCost2 = headcountCost.budgetData;
        for (var key in budgetData) {
          for (var i = 0; i < budgetData[key].data.length; i++) {
            var tempSalary = (Ember.get(budgetDataCost, "".concat(key, ".data.").concat(i)) || 0) * -1;
            budgetData[key].data[i] = budgetData[key].data[i] && budgetData[key].data[i] !== 0 ? tempSalary / budgetData[key].data[i] : 0;
          }
        }
        if (scenario && isBudgetVsScenario || isFvB) {
          for (var _key2 in budgetData2) {
            for (var _i5 = 0; _i5 < budgetData2[_key2].data.length; _i5++) {
              var _tempSalary = (Ember.get(budgetDataCost2, "".concat(_key2, ".data.").concat(_i5)) || 0) * -1;
              budgetData2[_key2].data[_i5] = budgetData2[_key2].data[_i5] && budgetData2[_key2].data[_i5] !== 0 ? _tempSalary / budgetData2[_key2].data[_i5] : 0;
            }
          }
        }
        DashboardUtil.removeEmptyValues(budgetData);
        highchartData = mapHeadcountObjectToHighchartArray(budgetData, DashboardUtil.getFirstStackName(controller));
        if (isActuals) {
          for (var _i6 = 0; _i6 < highchartData.length; _i6++) {
            highchartData[_i6].name += DashboardUtil.getSecondSeriesName(controller);
          }
        }
        if (scenario && isBudgetVsScenario || isFvB) {
          highchartData = highchartData.concat(mapHeadcountObjectToHighchartArray(budgetData2, DashboardUtil.getSecondStackName(controller)));
        }
        highchartCategories = headcount.categories;
        if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
          var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
            newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
          var _DashboardUtil$getSta14 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
            startIndex = _DashboardUtil$getSta14.startIndex,
            endIndex = _DashboardUtil$getSta14.endIndex;
          highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
          DashboardUtil.changeDataPeriod(highchartData, startIndex, endIndex);
        }
        resolve({
          highchartData: highchartData,
          highchartCategories: highchartCategories
        });
      });
    },
    calcRevenuesPerEmployee: function calcRevenuesPerEmployee(controller, store, currencySymbol, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        DashboardUtil.calcRevenues(controller, store, chart, undefined).then(function (revenues) {
          var scenario = controller.get("scenario"),
            isActuals = false;
          if (scenario === BVA) {
            scenario = null;
            isActuals = true;
          }
          var isBudgetVsScenario = controller.get("isBudgetVsScenario"),
            forecastViewOption = controller.get("forecastViewOption"),
            isFvB = controller.get("isFvB"),
            isInteractiveDashboardType = controller.get("scenario") && controller.get("isInteractiveDashboardType") ? "Modified" : null,
            revenuesData = Ember.get(revenues, "revenues.highchartData.0.data"),
            revenuesOriginalData = Ember.get(revenues, "revenues.highchartData.1.data") || revenuesData,
            headcountChart = DashboardUtil.calcHeadcount(controller, {
              countNew: false,
              chart: chart,
              calcType: "avg",
              withoutContractors: true
            }),
            headcountObject = Ember.get(headcountChart, "headCountChart.highchartData").findBy("stack", scenario && scenario !== "" || forecastViewOption ? DashboardUtil.getSecondStackName(controller) : DashboardUtil.getFirstStackName(controller)),
            headcountDataObject = headcountObject ? Ember.get(headcountObject, "data") : undefined,
            headcountScenarioObject = Ember.get(headcountChart, "headCountChart.highchartData").findBy("stack", scenario && scenario !== "" || forecastViewOption ? DashboardUtil.getFirstStackName(controller) : DashboardUtil.getSecondStackName(controller)),
            headcountScenarioDataObject = headcountScenarioObject ? Ember.get(headcountScenarioObject, "data") : undefined,
            highchartData = [],
            highchartCurrency = ["", currencySymbol];
          var calcRevPerEmp = function calcRevPerEmp(revenues, headcount) {
            var revPerEmp = [];
            if (headcount) {
              if (!Ember.isEmpty(revenues)) {
                for (var i = 0; i < revenues.length; i++) {
                  if (!headcount[i]) {
                    revPerEmp.push(null);
                  } else {
                    revPerEmp.push(revenues[i] / headcount[i]);
                  }
                }
              } else {
                for (var _i7 = 0; _i7 < headcount.length; _i7++) {
                  revPerEmp.push(0);
                }
              }
            }
            return revPerEmp;
          };
          var revPerEmp = calcRevPerEmp(revenuesOriginalData, headcountDataObject);
          highchartData = [{
            name: intl.t("general.name_1695611604982") + (isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""),
            data: headcountDataObject,
            type: "column",
            yAxis: 0
          }, {
            name: intl.t("general.title_1695611598055") + (isActuals ? DashboardUtil.getSecondSeriesName(controller) : ""),
            data: revPerEmp,
            type: "spline",
            yAxis: 1
          }];
          if (scenario && scenario !== "" || forecastViewOption) {
            var revPerEmpScenario = calcRevPerEmp(revenuesData, headcountScenarioDataObject);
            scenario = isInteractiveDashboardType || scenario;
            if (isBudgetVsScenario && scenario || isFvB) {
              highchartData = [{
                name: intl.t("general.dashboard-50", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: headcountDataObject,
                type: "column",
                yAxis: 0
              }, {
                name: intl.t("general.dashboard-51", {
                  var1: DashboardUtil.getFirstSeriesName(controller)
                }),
                data: headcountScenarioDataObject,
                type: "column",
                yAxis: 0
              }, {
                name: intl.t("general.dashboard-52", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: revPerEmp,
                type: "spline",
                yAxis: 1
              }, {
                name: intl.t("general.dashboard-53", {
                  var1: DashboardUtil.getFirstSeriesName(controller)
                }),
                data: revPerEmpScenario,
                type: "spline",
                yAxis: 1
              }];
              if (isInteractiveDashboardType) {
                highchartData[0].color = DashboardUtil.greyColorSchema[0];
                highchartData[2].color = DashboardUtil.greyColorSchema[1];
              }
              highchartCurrency = ["", "", currencySymbol, currencySymbol];
            } else {
              highchartData = [{
                name: intl.t("general.dashboard-51", {
                  var1: DashboardUtil.getFirstSeriesName(controller)
                }),
                data: headcountScenarioDataObject,
                type: "column",
                yAxis: 0
              }, {
                name: intl.t("general.dashboard-53", {
                  var1: DashboardUtil.getFirstSeriesName(controller)
                }),
                data: revPerEmpScenario,
                type: "spline",
                yAxis: 1
              }];
            }
          }
          resolve({
            highchartData: highchartData,
            highchartCategories: Ember.get(headcountChart, "headCountChart.highchartCategories"),
            highchartCurrency: highchartCurrency
          });
        });
      });
    },
    calcNetIncomeAndMargin: function calcNetIncomeAndMargin(controller, store, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        var scenario = controller.get("scenario"),
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          budget = controller.get("model"),
          isInteractiveDashboardType = controller.get("scenario") && controller.get("isInteractiveDashboardType") ? "Modified" : null,
          forecastViewOption = controller.get("forecastViewOption"),
          vsOption = controller.get("vsOption"),
          dimensions = controller.get("dimensions"),
          isRollingForecast = controller.get("isRollingForecastViewType"),
          isFvB = controller.get("isFvB"),
          periodType = chart.get("periodType") || "Monthly";
        var options = {
            selectedScenario: scenario ? "#" : "",
            scenario: scenario === BVA ? null : scenario,
            currency: budget.get("forecastAttributes.currency"),
            type: "pnl",
            forceDetails: true,
            dimensions: dimensions,
            selectedPeriod: periodType,
            actualViewOption: isRollingForecast ? "rollf" : undefined,
            budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
            forecastViewOption: forecastViewOption ? forecastViewOption : undefined,
            vsOption: vsOption ? vsOption : undefined
          },
          forecastData,
          cache = controller.get("cache"),
          value_factors = {},
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth();
        var promiseCallbackForecast = function promiseCallbackForecast(forecastData) {
          value_factors = isRollingForecast ? DashboardUtil.getActualsKeys(forecastData.value_factors) : {};
          DashboardUtil.calcRevenues(controller, store, chart, undefined).then(function (revenues) {
            var revenuesData = Ember.get(revenues, "revenues.highchartData.0.data"),
              revenuesDataOriginal = Ember.get(revenues, "revenues.highchartData.1.data"),
              marginData = {
                name: intl.t("general.name_1695611602191"),
                data: [],
                type: "spline",
                yAxis: 1
              },
              marginDataOriginal = {
                name: intl.t("general.dashboard-56", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: [],
                type: "spline",
                yAxis: 1
              },
              totalNet = 0,
              totalNetOriginal = 0,
              pnlItems = Ember.get(forecastData, "root.values").map(function (value) {
                return Ember.get(value, controller.get("scenario") === BVA ? isRollingForecast ? value_factors[value.key] ? "convertedActualValue" : "value" : "convertedActualValue" : "value");
              }),
              pnlItemsOriginal = Ember.get(forecastData, "root.values").map(function (value) {
                return Ember.get(value, controller.get("scenario") === BVA ? "value" : "value2");
              }),
              highchartCategories = revenues.revenues.highchartCategories,
              i,
              netAndMarginHighchartData,
              netRevAndMarginHighchartData,
              netAndMarginHighchartCurrency,
              netRevAndMarginHighchartCurrency;
            if (!highchartCategories) {
              // no revenues
              highchartCategories = changeDateFormatArray(Ember.get(forecastData, "root.values").map(function (value) {
                return value.key;
              }), chart.get("periodType"));
              if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
                var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
                  newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
                var _DashboardUtil$getSta15 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
                  startIndex = _DashboardUtil$getSta15.startIndex,
                  endIndex = _DashboardUtil$getSta15.endIndex;
                highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
              }
            }
            if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
              if (highchartCategories) {
                pnlItems = Ember.get(forecastData, "root.values").filter(function (value) {
                  return highchartCategories.indexOf(Date.create(Ember.get(value, "key") + "/01").format("{Mon} {yy}").toUpperCase()) !== -1;
                });
                pnlItems = pnlItems.map(function (value) {
                  return Ember.get(value, controller.get("scenario") === BVA ? "convertedActualValue" : "value");
                });
                pnlItemsOriginal = Ember.get(forecastData, "root.values").filter(function (value) {
                  return highchartCategories.indexOf(Date.create(Ember.get(value, "key") + "/01").format("{Mon} {yy}").toUpperCase()) !== -1;
                }).map(function (value) {
                  return Ember.get(value, controller.get("scenario") === BVA ? "value" : "value2");
                });
              }
            }
            if (revenuesData && pnlItems.get("length") === revenuesData.get("length")) {
              for (i = 0; i < pnlItems.length; i++) {
                totalNet += pnlItems[i] || 0;
                if (!revenuesData[i]) {
                  marginData.data.push(null);
                } else {
                  marginData.data.push(pnlItems[i] / revenuesData[i] * 100);
                }
              }
            }
            var netData = {
              name: intl.t("general.name_1695611602201"),
              data: pnlItems,
              type: "column",
              yAxis: 0
            };
            revenuesData = {
              name: intl.t("general.title_1695611597810"),
              data: revenuesData ? revenuesData : [],
              type: "column",
              yAxis: 0
            };
            netAndMarginHighchartData = [netData, marginData];
            netRevAndMarginHighchartData = [netData, revenuesData, marginData];
            netAndMarginHighchartCurrency = currencyArrayToSymbols([budget.get("forecastAttributes.currency"), "%"]);
            netRevAndMarginHighchartCurrency = currencyArrayToSymbols([budget.get("forecastAttributes.currency"), budget.get("forecastAttributes.currency"), "%"]);
            pnlItemsOriginal = pnlItemsOriginal.map(function (num) {
              return num || 0;
            });
            var netDataOriginalHighchart = {
                name: intl.t("general.dashboard-57", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: pnlItemsOriginal,
                type: "column",
                yAxis: 0
              },
              revenuesDataOriginalHighchart = {
                name: intl.t("general.dashboard-58", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: revenuesDataOriginal,
                type: "column",
                yAxis: 0
              };
            if (controller.get("scenario") || forecastViewOption) {
              marginData.name = intl.t("general.dashboard-59", {
                var1: DashboardUtil.getFirstSeriesName(controller)
              });
              netData.name = intl.t("general.dashboard-60", {
                var1: DashboardUtil.getFirstSeriesName(controller)
              });
              revenuesData.name = intl.t("general.dashboard-61", {
                var1: DashboardUtil.getFirstSeriesName(controller)
              });
              if (scenario && isBudgetVsScenario || isFvB) {
                if (revenuesDataOriginal && pnlItemsOriginal.get("length") === revenuesDataOriginal.get("length")) {
                  for (i = 0; i < pnlItemsOriginal.length; i++) {
                    totalNetOriginal += pnlItemsOriginal[i] || 0;
                    if (!revenuesDataOriginal[i]) {
                      marginDataOriginal.data.push(null);
                    } else {
                      marginDataOriginal.data.push(pnlItemsOriginal[i] / revenuesDataOriginal[i] * 100);
                    }
                  }
                }
                if (isInteractiveDashboardType) {
                  netDataOriginalHighchart.color = DashboardUtil.greyColorSchema[0];
                  marginDataOriginal.color = DashboardUtil.greyColorSchema[1];
                  revenuesDataOriginalHighchart.color = DashboardUtil.greyColorSchema[2];
                }
                netAndMarginHighchartData = [netData, netDataOriginalHighchart, marginData, marginDataOriginal];
                netRevAndMarginHighchartData = [netData, netDataOriginalHighchart, revenuesData, revenuesDataOriginalHighchart, marginData, marginDataOriginal];
                netAndMarginHighchartCurrency = currencyArrayToSymbols([budget.get("forecastAttributes.currency"), budget.get("forecastAttributes.currency"), "%", "%"]);
                netRevAndMarginHighchartCurrency = currencyArrayToSymbols([budget.get("forecastAttributes.currency"), budget.get("forecastAttributes.currency"), budget.get("forecastAttributes.currency"), budget.get("forecastAttributes.currency"), "%", "%"]);
              }
            }
            resolve({
              netAndMargin: {
                highchartData: netAndMarginHighchartData,
                highchartCurrency: netAndMarginHighchartCurrency,
                highchartCategories: highchartCategories,
                value_factors: value_factors
              },
              netRevAndMargin: {
                highchartData: netRevAndMarginHighchartData,
                highchartCurrency: netRevAndMarginHighchartCurrency,
                highchartCategories: highchartCategories,
                value_factors: value_factors
              },
              newTotal: totalNet,
              newTotalOriginal: totalNetOriginal
            });
          });
        };
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
          selectedPeriod: periodType
        });
        options.store = store;
        options = Ember.Object.create(options);
        forecastData = cache.getItem(budget.get("id"), "getForecastDataModel", hashOptions);
        if (!forecastData) {
          _forecast.default.getForecastDataModel(options, controller.get("cache"), "pnl", budget.id, options, controller).then(function (forecastData) {
            cache.setItem(budget.get("id"), "getForecastDataModel", hashOptions, forecastData);
            promiseCallbackForecast(forecastData);
          });
        } else {
          promiseCallbackForecast(forecastData);
        }
      });
    },
    calcDeferredRevenuesAr: function calcDeferredRevenuesAr(controller, store, budget, chart) {
      return new Ember.RSVP.Promise(function (resolve) {
        var scenario = controller.get("scenario"),
          scenarioName = controller.get("displayScenario"),
          isBudgetVsScenario = controller.get("isBudgetVsScenario"),
          forecastViewOption = controller.get("forecastViewOption"),
          vsOption = controller.get("vsOption"),
          isRollingForecast = controller.get("isRollingForecastViewType"),
          isFvB = controller.get("isFvB"),
          dimensions = controller.get("dimensions"),
          periodType = chart.get("periodType"),
          options = Ember.Object.create({
            selectedScenario: scenario && scenario !== BVA ? "#" : "",
            scenario: scenario === BVA ? undefined : scenario,
            currency: budget.get("forecastAttributes.currency"),
            viewType: "bs",
            dimensions: dimensions,
            actualViewOption: isRollingForecast ? "rollf" : undefined,
            selectedPeriod: periodType || "Monthly",
            budgetViewType: scenario === BVA ? "act" : forecastViewOption ? "forecast" : undefined,
            forecastViewOption: forecastViewOption ? forecastViewOption : undefined,
            vsOption: vsOption ? vsOption : undefined
          }),
          arSum = {},
          drSum = {},
          value_factors = {},
          bsForecast,
          arData,
          drData,
          ldrData,
          arDataOriginal,
          drDataOriginal,
          ldrDataOriginal,
          budgetStartDate = Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(budget.get("budgetAttributesWithScenarios.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : Date.create(budget.get("budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : Date.create(budgetEndDate).beginningOfMonth(),
          isInteractiveDashboardType = controller.get("scenario") && controller.get("isInteractiveDashboardType") ? "Modified" : null,
          cache = controller.get("cache"),
          budgetId = budget.id;
        var promiseCallback = function promiseCallback(bsForecast) {
          arDataOriginal = arData = _forecast.default.findBalanceSheetLinesByProperty(bsForecast.root, "bsLine", "ar");
          drDataOriginal = drData = _forecast.default.findBalanceSheetLinesByProperty(bsForecast.root, "bsLine", "dr");
          ldrDataOriginal = ldrData = _forecast.default.findBalanceSheetLinesByProperty(bsForecast.root, "bsLine", "ldr");
          value_factors = isRollingForecast ? DashboardUtil.getActualsKeys(bsForecast.value_factors) : {};
          var sumsFunction = function sumsFunction(sumTo, data) {
            var valueKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "value";
            data.forEach(function (budget) {
              budget.values.forEach(function (value) {
                var key = Ember.get(value, "key");
                if (!sumTo[key]) {
                  sumTo[key] = 0;
                }
                sumTo[key] = sumTo[key] + (Ember.get(value, valueKey) || 0) * 1;
              });
            });
          };
          sumsFunction(arSum, arData, scenario === BVA ? "convertedActualValue" : "value");
          sumsFunction(drSum, drData, scenario === BVA ? "convertedActualValue" : "value");
          sumsFunction(drSum, ldrData, scenario === BVA ? "convertedActualValue" : "value");
          arData = mapObjectToArray(arSum);
          drData = mapObjectToArray(drSum);
          var highchartCategories = changeDateFormatArray(Object.keys(arSum), chart.get("periodType"));
          if (!Ember.isEmpty(arData)) {
            arData.shift();
          }
          if (!Ember.isEmpty(drData)) {
            drData.shift();
          }
          if (!Ember.isEmpty(highchartCategories)) {
            highchartCategories.shift();
          }
          var highchartData = [{
            name: intl.t("general.dashboard-62", {
              var1: DashboardUtil.getFirstSeriesName(controller)
            }),
            data: drData,
            stack: DashboardUtil.getFirstStackName(controller)
          }, {
            name: intl.t("general.dashboard-63", {
              var1: DashboardUtil.getFirstSeriesName(controller)
            }),
            data: arData,
            stack: DashboardUtil.getFirstStackName(controller)
          }];
          if (!isRollingForecast && (scenario || forecastViewOption)) {
            if (isBudgetVsScenario && scenario || isFvB) {
              var arSumOriginal = {},
                drSumOriginal = {};
              if (scenario !== BVA) {
                sumsFunction(arSumOriginal, arDataOriginal, "value2");
                sumsFunction(drSumOriginal, drDataOriginal, "value2");
                sumsFunction(drSumOriginal, ldrDataOriginal, "value2");
              } else {
                sumsFunction(arSumOriginal, arDataOriginal);
                sumsFunction(drSumOriginal, drDataOriginal);
                sumsFunction(drSumOriginal, ldrDataOriginal);
              }
              arDataOriginal = mapObjectToArray(arSumOriginal);
              drDataOriginal = mapObjectToArray(drSumOriginal);
              if (!Ember.isEmpty(arDataOriginal)) {
                arDataOriginal.shift();
              }
              if (!Ember.isEmpty(drDataOriginal)) {
                drDataOriginal.shift();
              }
              scenarioName = isInteractiveDashboardType || scenarioName;
              highchartData = [{
                name: intl.t("general.dashboard-62", {
                  var1: DashboardUtil.getFirstSeriesName(controller)
                }),
                data: drData,
                stack: DashboardUtil.getFirstStackName(controller)
              }, {
                name: intl.t("general.dashboard-65", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: drDataOriginal,
                stack: DashboardUtil.getSecondStackName(controller)
              }, {
                name: intl.t("general.dashboard-63", {
                  var1: DashboardUtil.getFirstSeriesName(controller)
                }),
                data: arData,
                stack: DashboardUtil.getFirstStackName(controller)
              }, {
                name: intl.t("general.dashboard-67", {
                  var1: DashboardUtil.getSecondSeriesName(controller)
                }),
                data: arDataOriginal,
                stack: DashboardUtil.getSecondStackName(controller)
              }];
              if (isInteractiveDashboardType) {
                highchartData[1].color = DashboardUtil.greyColorSchema[0];
                highchartData[3].color = DashboardUtil.greyColorSchema[1];
              }
            } else {
              highchartData = [{
                name: intl.t("general.dashboard-62", {
                  var1: DashboardUtil.getFirstSeriesName(controller)
                }),
                data: drData,
                stack: DashboardUtil.getFirstStackName(controller)
              }, {
                name: intl.t("general.dashboard-63", {
                  var1: DashboardUtil.getFirstSeriesName(controller)
                }),
                data: arData,
                stack: DashboardUtil.getFirstStackName(controller)
              }];
            }
          }
          if (chart.get("periodType") === "Monthly" && (chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate.valueOf() !== budgetEndDate.valueOf())) {
            var newStartDate = chartStartDate.format("{Mon} {yy}").toUpperCase(),
              newEndDate = chartEndDate.format("{Mon} {yy}").toUpperCase();
            var _DashboardUtil$getSta16 = DashboardUtil.getStartAndEndDateIndexs(highchartCategories, newStartDate, newEndDate),
              startIndex = _DashboardUtil$getSta16.startIndex,
              endIndex = _DashboardUtil$getSta16.endIndex;
            highchartCategories = DashboardUtil.changeDataArrayPeriod(highchartCategories, startIndex, endIndex);
            DashboardUtil.changeDataPeriod(highchartData, startIndex, endIndex);
          }
          resolve(Ember.Object.create({
            highchartData: highchartData,
            highchartCategories: highchartCategories,
            value_factors: value_factors
          }));
        };
        var hashOptions = _forecast.default.calcForecastHash(cache, options, budget, undefined, undefined, undefined, {
          selectedPeriod: periodType
        });
        options.store = store;
        options = Ember.Object.create(options);
        bsForecast = cache.getItem(budgetId, "getForecastDataModel", hashOptions);
        if (!bsForecast) {
          _forecast.default.getForecastDataModel(options, controller.get("cache"), "bs", budgetId, options, controller).then(function (bsForecast) {
            cache.setItem(budgetId, "getForecastDataModel", hashOptions, bsForecast);
            promiseCallback(bsForecast);
          });
        } else {
          promiseCallback(bsForecast);
        }
      });
    },
    getDefaultChartTitle: function getDefaultChartTitle(chart, isNonProfitBudget) {
      var titles = {
        rev: intl.t("general.title_1695611597810"),
        "rev-growth": intl.t("general.title_1695611597800"),
        "deferred-rev-ar": intl.t("general.title_1695611597789"),
        "bookings-by": intl.t("general.title_1695611598161"),
        bookings: intl.t("general.title_1695611598151"),
        "bookings-type": "Bookings by type",
        "bookings-cash-rev": intl.t("general.title_1695611598130"),
        "bookings-by_non_profit": intl.t("general.title_1695611597769"),
        bookings_non_profit: intl.t("general.title_1695611597758"),
        "bookings-type_non_profit": intl.t("general.title_1695611597748"),
        "bookings-cash-rev_non_profit": intl.t("general.title_1695611597737"),
        "operating-expenses": intl.t("general.title_1695611597716"),
        "operating-expenses-pie": intl.t("general.title_1695611597716"),
        "expenses-by-category": intl.t("general.title_1695611597696"),
        "operating-expenses-revenue": intl.t("general.title_1695611597705"),
        hc: intl.t("general.title_1695611597579"),
        "revenues-per-employee": intl.t("general.title_1695611598055"),
        "hc-new": intl.t("general.title_1695611597653"),
        "avg-salary-per-employee": intl.t("general.title_1695611597664"),
        "net-margin": intl.t("general.title_1695611598012"),
        "net-margin_non_profit": "Net revenues/expenditures",
        "gross-margin": intl.t("general.title_1695611597981"),
        cash: intl.t("general.title_1695611597622"),
        "net-reveunes-margin": intl.t("general.title_1695611597992"),
        saas: intl.t("general.title_1695611597960"),
        saas2: intl.t("general.title_1695611597949"),
        "saas-y": intl.t("general.title_1695611597938"),
        "saas-y2": intl.t("general.title_1695611597927"),
        kpiChart: intl.t("general.title_1695611597537"),
        "single-kpi": intl.t("general.title_1695611597569")
      };
      var chartId = chart.chartId || chart.id || chart;
      var chartTitle = "";
      if (isNonProfitBudget) {
        chartTitle = titles[chartId + "_non_profit"];
      }
      return chartTitle ? chartTitle : titles[chartId] || "";
    },
    getDefaultCharts: function getDefaultCharts(isNonProfitBudget) {
      return Ember.A([Ember.Object.create({
        id: "cash",
        title: intl.t("general.title_1695611597622")
      }), Ember.Object.create({
        id: "operating-expenses",
        title: intl.t("general.title_1695611597716")
      }), Ember.Object.create({
        id: "bookings",
        title: isNonProfitBudget ? intl.t("general.title_1695611597758") : intl.t("general.title_1695611598151")
      }), Ember.Object.create({
        id: "expenses-by-category",
        title: intl.t("general.title_1695611597696")
      }), Ember.Object.create({
        id: "hc-new",
        title: intl.t("general.title_1695611597653")
      }), Ember.Object.create({
        id: "hc",
        title: intl.t("general.title_1695611597579")
      }), Ember.Object.create({
        id: "rev",
        title: intl.t("general.title_1695611597810")
      }), Ember.Object.create({
        id: "kpiChart",
        title: intl.t("general.title_1695611597537")
      }), Ember.Object.create({
        id: "single-kpi",
        title: intl.t("general.title_1695611600046"),
        highchartData: {},
        highchartCurrency: ""
      })]);
    },
    getDefaultChartsForBudget: function getDefaultChartsForBudget(dashboardId, isNonProfitBudget) {
      var charts = DashboardUtil.getDefaultCharts(isNonProfitBudget);
      charts = charts.map(function (chart) {
        return {
          chartId: chart.id,
          title: chart.title,
          uniqueId: chart.id + "-" + _const.default.makeId(),
          periodType: "Monthly",
          dashboardId: dashboardId
        };
      });
      return charts;
    },
    getCharts: function getCharts(controller, budget, scenario, store, allowEmptyCharts, dashboardId) {
      var chartOrder = Ember.A([]);
      var dashboardOrder = Ember.A([]);
      var isNonProfitBudget = controller.get("budgetService.isNonProfitBudget");
      if (budget) {
        chartOrder = Ember.get(budget, "dashboardOrder");
        var budgetStartDate = Date.create(Ember.get(budget, "budgetAttributes.startDate")).beginningOfMonth(),
          budgetEndDate = Date.create(Ember.get(budget, "budgetAttributes.endDate") || new Date(budgetStartDate).addMonths(11)).beginningOfMonth(),
          scenarioStartDate = Date.create(Ember.get(budget, "budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
          scenarioEndDate = Date.create(Ember.get(budget, "budgetAttributesWithScenarios.endDate") || new Date(scenarioStartDate).addMonths(11)).beginningOfMonth();
        if (!Ember.isEmpty(chartOrder)) {
          chartOrder = chartOrder.filter(function (chart) {
            return chart.chartId && chart.hide !== true && chart.chartId !== "per-emp" && chart.chartId.indexOf("cat-") !== 0;
          });
        }
        dashboardOrder = chartOrder;
        if (!allowEmptyCharts && (Ember.isEmpty(chartOrder) || chartOrder.length === 0)) {
          chartOrder = DashboardUtil.getDefaultCharts(isNonProfitBudget);
        }
        chartOrder = chartOrder.map(function (chart) {
          var chartId = chart.chartId || chart.id;
          var newUniqueId = chartId.indexOf("model") === 0 ? chartId : chartId + "-" + _const.default.makeId();
          var chartStartDate, chartEndDate;
          if (!Ember.isEmpty(Ember.get(chart, "uniqueId"))) {
            newUniqueId = Ember.get(chart, "uniqueId");
          }
          var scenariosDates = chart.scenariosDates || {}; // fix scenario dates
          if (!scenariosDates[Ember.get(budget, "uniqueId")]) {
            // dates fore budget
            scenariosDates[Ember.get(budget, "uniqueId")] = {
              startDate: budgetStartDate,
              endDate: budgetEndDate
            };
          }
          if (scenario && scenario !== "") {
            // date by scenario
            if (!scenariosDates[scenario]) {
              scenariosDates[scenario] = {
                startDate: scenarioStartDate,
                endDate: scenarioEndDate
              };
            }
            chartStartDate = scenariosDates[scenario].startDate;
            chartEndDate = scenariosDates[scenario].endDate;
          } else {
            // date by budget
            chartStartDate = scenariosDates[Ember.get(budget, "uniqueId")].startDate || budgetStartDate;
            chartEndDate = scenariosDates[Ember.get(budget, "uniqueId")].endDate || budgetEndDate;
          }
          return Ember.Object.create({
            id: chartId,
            uniqueId: newUniqueId,
            isReady: false,
            title: chart.title ? chart.title : DashboardUtil.getDefaultChartTitle(chart, isNonProfitBudget),
            startDate: chartStartDate,
            endDate: chartEndDate,
            periodType: chart.periodType ? chart.periodType : "Monthly",
            scenariosDates: scenariosDates,
            filterOption: chart.filterOption || "all",
            kpiSelection: chart.kpiSelection,
            chartType: chart.chartType,
            dashboardId: chart.dashboardId ? chart.dashboardId : dashboardId ? dashboardId : Ember.get(budget, "uniqueId")
          });
        });
        var modelCharts = Ember.A([]);
        // get models
        store.peekAll("budget").filter(function (b) {
          return b && b.get("root.id") === budget.get("id") && b.get("budgetType") === "group-model" && (b.get("budgetAttributes.dashboardLines.length") || b.get("budgetAttributes.dashboardCharts.length"));
        }).forEach(function (dbModel) {
          var dashboardCharts = [];
          if (dbModel.get("budgetAttributes.dashboardLines.length")) {
            dashboardCharts.pushObject({
              title: dbModel.get("budgetAttributes.dashboardTitle"),
              lines: dbModel.get("budgetAttributes.dashboardLines")
            });
          }
          if (dbModel.get("budgetAttributes.dashboardCharts.length")) {
            dashboardCharts.pushObjects(dbModel.get("budgetAttributes.dashboardCharts"));
          }
          dashboardCharts.forEach(function (modelChartData) {
            var modelChartId = "model-".concat(dbModel.get("uniqueId"), "_").concat(Ember.get(modelChartData, "_id"));
            var modelChartOldId = "model-".concat(dbModel.get("id"), "_").concat(Ember.get(modelChartData, "_id"));
            var modelChart = Ember.Object.create({
              id: modelChartId,
              oldid: modelChartOldId,
              uniqueId: modelChartId,
              isReady: false,
              title: Ember.get(modelChartData, "title"),
              startDate: scenario && scenario !== "" ? scenarioStartDate : budgetStartDate,
              endDate: scenario && scenario !== "" ? scenarioStartDate : budgetEndDate,
              periodType: "Monthly",
              dashboardId: dashboardId
            });
            modelCharts.addObject(modelChart);
          });
        });
        // remove old models
        var oldChartModel = Ember.A([]);
        chartOrder.forEach(function (chart) {
          if (DashboardUtil.isModelChart(chart) && !modelCharts.findBy("id", chart.get("id")) && !modelCharts.findBy("oldid", chart.get("id"))) {
            oldChartModel.pushObject(chart);
          }
        });
        if (!Ember.isEmpty(oldChartModel)) {
          oldChartModel.forEach(function (chart) {
            chartOrder.removeObject(chart);
          });
        }
        chartOrder = chartOrder.map(function (chart) {
          chart["hide"] = chart.dashboardId !== dashboardId && dashboardId || Ember.isEmpty(DashboardUtil.colorSchema[Ember.get(chart, "id")]) && !DashboardUtil.isModelChart(chart);
          return chart;
        });
      }
      if ((Ember.isEmpty(dashboardOrder) || dashboardOrder.length === 0) && !allowEmptyCharts) {
        DashboardUtil.saveCharts(controller, budget, scenario, chartOrder);
      }
      return chartOrder;
    },
    saveCharts: function saveCharts(controller, budget, scenario, charts) {
      var newOrder = Ember.A([]);
      if (!budget) {
        return;
      }
      var budgetStartDate = Date.create(Ember.get(budget, "budgetAttributes.startDate")).beginningOfMonth(),
        budgetEndDate = Date.create(Ember.get(budget, "budgetAttributes.endDate")).beginningOfMonth(),
        scenarioStartDate = Date.create(Ember.get(budget, "budgetAttributesWithScenarios.startDate")).beginningOfMonth(),
        scenarioEndDate = Date.create(Ember.get(budget, "budgetAttributesWithScenarios.endDate")).beginningOfMonth(),
        chartStartDate,
        chartEndDate,
        saveBudgetDate,
        saveScenarioDate;
      charts.forEach(function (chart) {
        chartStartDate = chart.get("startDate") ? Date.create(chart.get("startDate")).beginningOfMonth() : undefined;
        chartEndDate = chart.get("endDate") ? Date.create(chart.get("endDate")).beginningOfMonth() : undefined;
        if (chartStartDate && chartStartDate.valueOf() !== budgetStartDate.valueOf() || chartEndDate && chartEndDate.valueOf() !== budgetEndDate.valueOf()) {
          saveBudgetDate = true;
        } else {
          saveBudgetDate = false;
        }
        var scenariosDates = chart.get("scenariosDates") || {}; // fix scenario dates
        if (scenario && scenario !== "") {
          // date by scenario
          if (chartStartDate && chartStartDate.valueOf() !== scenarioStartDate.valueOf() || chartEndDate && chartEndDate.valueOf() !== scenarioEndDate.valueOf()) {
            saveScenarioDate = true;
          } else {
            saveScenarioDate = false;
          }
          if (!scenariosDates[scenario]) {
            scenariosDates[scenario] = {
              startDate: undefined,
              endDate: undefined
            };
          }
          if (chartStartDate && chartStartDate.valueOf() === scenarioStartDate.valueOf()) {
            chartStartDate = undefined;
          }
          if (chartEndDate && chartEndDate.valueOf() === scenarioEndDate.valueOf()) {
            chartEndDate = undefined;
          }
          scenariosDates[scenario] = {
            startDate: saveScenarioDate ? chartStartDate : undefined,
            endDate: saveScenarioDate ? chartEndDate : undefined
          };
        } else {
          // date by budget

          if (chartStartDate && chartStartDate.valueOf() === budgetStartDate.valueOf()) {
            chartStartDate = undefined;
          }
          if (chartEndDate && chartEndDate.valueOf() === budgetEndDate.valueOf()) {
            chartEndDate = undefined;
          }
          scenariosDates[Ember.get(budget, "uniqueId")] = {
            startDate: saveBudgetDate ? chartStartDate : undefined,
            endDate: saveBudgetDate ? chartEndDate : undefined
          };
        }
        newOrder.pushObject(Ember.Object.create({
          chartId: chart.get("id"),
          title: chart.get("title"),
          uniqueId: chart.get("uniqueId"),
          periodType: chart.periodType ? chart.periodType : "Monthly",
          scenariosDates: scenariosDates,
          dashboardId: chart.get("dashboardId"),
          filterOption: chart.get("filterOption") || "all",
          kpiSelection: chart.get("kpiSelection"),
          chartType: chart.get("chartType")
        }));
      });
      if (budget && !budget.isDestroyed && !Ember.get(budget, "isDeleted")) {
        Ember.set(budget, "dashboardOrder", newOrder);
        controller.send("saveDashboard");
      }
    },
    convertKPIData: function convertKPIData(objectData, highchartCurrency) {
      var kpiSelection = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var kpiSelected = kpiSelection.filterBy("selected");
      var kpiData = Ember.A(new Array(kpiSelected.length));
      var index;
      for (var key in objectData) {
        var item = kpiSelected.findBy("type", key);
        if (item) {
          index = kpiSelected.indexOf(item) || 0;
          if (key === "assumption" || key === "model-line") {
            for (var i = 0; i < objectData[key].length; i++) {
              var _item = kpiSelected.findBy("uniqueId", objectData[key][i].uniqueId);
              index = kpiSelected.indexOf(_item) || 0;
              var setKpiData = {
                title: objectData[key][i].title,
                data: _const.default.formatCurrency(objectData[key][i].data || 0, objectData[key][i].currency, 2, true)
              };
              if (objectData[key][i].name) {
                setKpiData.name = objectData[key][i].name;
              }
              if (objectData[key][i].name2) {
                setKpiData.name2 = objectData[key][i].name2;
                setKpiData.data2 = _const.default.formatCurrency(objectData[key][i].data2 || 0, objectData[key][i].currency2, 2, true);
              }
              kpiData[index] = setKpiData;
            }
          } else {
            if (objectData[key].addCurrency) {
              if (typeof objectData[key].data === "number") {
                Ember.set(objectData, "".concat(key, ".data"), Ember.isEmpty(objectData[key].data) && objectData[key].data !== 0 ? "" : highchartCurrency + _const.default.formatKMBT(objectData[key].data));
              } else {
                Ember.set(objectData, "".concat(key, ".data"), Ember.isEmpty(objectData[key].data) && objectData[key].data !== 0 ? "" : objectData[key].data);
              }
              if (typeof objectData[key].data2 === "number") {
                Ember.set(objectData, "".concat(key, ".data2"), Ember.isEmpty(objectData[key].data2) && objectData[key].data2 !== 0 ? "" : highchartCurrency + _const.default.formatKMBT(objectData[key].data2));
              } else {
                Ember.set(objectData, "".concat(key, ".data2"), Ember.isEmpty(objectData[key].data2) && objectData[key].data2 !== 0 ? "" : objectData[key].data2);
              }
            } else {
              if (typeof objectData[key].data === "number") {
                Ember.set(objectData, "".concat(key, ".data"), _const.default.toFixed(objectData[key].data, 2));
              }
              if (objectData[key].data2 && typeof objectData[key].data2 === "number") {
                Ember.set(objectData, "".concat(key, ".data2"), _const.default.toFixed(objectData[key].data2, 2));
              }
            }
            kpiData[index] = objectData[key];
          }
        }
      }
      if (!(options.scenario && options.isBudgetVsScenario) && !(options.forecastViewOption && options.isFvB)) {
        kpiData.forEach(function (data) {
          delete data.name2;
          delete data.data2;
        });
      }
      kpiData = kpiData.filter(function (item) {
        return item;
      }); // filter all the empty cells
      return kpiData;
    },
    convertSingleData: function convertSingleData(objectData, highchartCurrency) {
      if (typeof objectData.data === "number") {
        Ember.set(objectData, "data", Ember.isEmpty(objectData.data) && objectData.data !== 0 ? "" : highchartCurrency + _const.default.formatKMBT(objectData.data));
      } else {
        Ember.set(objectData, "data", Ember.isEmpty(objectData.data) && objectData.data !== 0 ? "" : objectData.data);
      }
      return objectData;
    },
    noDataChartSVG: function noDataChartSVG(chart, chartHeight, chartWidth) {
      var svgHeader = "<svg xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" style=\"font-family:'lucida grande', 'lucida sans unicode', arial, helvetica, sans-serif;\" xmlns=\"http://www.w3.org/2000/svg\" width=\"".concat(chartWidth, "\" height=\"").concat(chartHeight, "\" viewBox=\"0 0 ").concat(chartWidth, " ").concat(chartHeight, "\">");
      var midW = chartWidth / 2;
      var midH = chartHeight / 2;
      var text;
      var header = "<rect x=\"0\" y=\"0\" width=\"".concat(chartWidth, "\" height=\"").concat(chartHeight, "\" style=\"fill:#FFFFFF;stroke-width:1;stroke:rgb(0,0,0)\"></rect><text x=\"").concat(midW, "\" y=\"10\" text-anchor=\"middle\" font-size=\"18px\"><tspan x=\"").concat(midW, "\" dy=\"18px\">").concat(chart.title, "</tspan></text>");
      var innerText = "<tspan x=\"".concat(midW, "\" dy=\"18px\" font-size='18px'>").concat(intl.t("general.no_data_1695803190786"), "</tspan>");
      var svgEnd = "</svg>";
      text = "<text x=\"".concat(midW, "\" y=\"").concat(midH, "\" text-anchor=\"middle\">").concat(innerText, "</text>");
      return svgHeader + header + text + svgEnd;
    },
    isEmptyChart: function isEmptyChart(ignoreCategories, data, categories) {
      var hasData = false;
      if (data) {
        for (var i = 0; i < data.length; i++) {
          if (hasData) {
            break;
          }
          if (data[i].data) {
            for (var j = 0; j < data[i].data.length; j++) {
              if (!Ember.isEmpty(data[i].data[j])) {
                hasData = true;
                break;
              }
            }
          }
        }
      }
      if (ignoreCategories) {
        return Ember.isEmpty(data);
      }
      return Ember.isEmpty(data) || !hasData || Ember.isEmpty(categories);
    },
    createLineHighchart: function createLineHighchart(chart, chartHeight, chartWidth) {
      var chartCategories = Ember.get(chart, "highchartCategories");
      var highchartLabels = Ember.get(chart, "highchartLabels");
      var showLegendAmounts = chart.id === "cash";
      var colorPalette = Ember.get(chart, "colorPalette");
      var seriesData = Ember.get(chart, "highchartData");
      var title = Ember.get(chart, "title");
      if (Ember.get(chart, "showy2")) {
        seriesData = seriesData.map(function (ele, index) {
          ele.yAxis = index;
          return ele;
        });
      } else if (seriesData && seriesData.length > 1) {
        seriesData = DashboardUtil.sortChartData(seriesData, "asc");
      }
      var currecnyArray = [""];
      if (Ember.get(chart, "highchartCurrency")) {
        currecnyArray = Ember.get(chart, "highchartCurrency");
      }
      if (DashboardUtil.isEmptyChart(chart.get("ignoreCategories"), chart.get("highchartData"), chart.get("highchartCategories"))) {
        return DashboardUtil.noDataChartSVG(chart, chartHeight, chartWidth);
      }
      return {
        chart: {
          height: chartHeight,
          width: chartWidth,
          type: "line",
          spacingLeft: 20,
          spacingRight: 20,
          borderColor: "#000000",
          borderWidth: 1
        },
        credits: {
          enabled: false
        },
        colors: colorPalette,
        title: {
          text: title
        },
        xAxis: {
          categories: chartCategories
        },
        yAxis: [{
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArray[0];
              if (currency === "%") {
                return _const.default.formatKMBT(this.value) + currency;
              }
              return currency + _const.default.formatKMBT(this.value);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2
        }, {
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArray[1];
              if (currency === "%") {
                return _const.default.formatKMBT(this.value) + currency;
              }
              return currency + _const.default.formatKMBT(this.value);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2,
          opposite: true
        }],
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
          line: {
            dataLabels: {
              enabled: highchartLabels,
              formatter: function formatter() {
                var currencySymbol = this.series.index >= currecnyArray.length ? currecnyArray[currecnyArray.length - 1] : currecnyArray[this.series.index];
                if (currencySymbol === "%") {
                  return _const.default.formatKMBT(this.y, 1) + currencySymbol;
                } else {
                  return currencySymbol + _const.default.formatKMBT(this.y, 1);
                }
              }
            }
          }
        },
        legend: {
          enabled: true,
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 6,
          itemStyle: {
            color: "#334356",
            fontSize: "15px",
            fontWeight: "normal"
          },
          labelFormatter: function labelFormatter() {
            var currencySymbol = this.index >= currecnyArray.length ? currecnyArray[currecnyArray.length - 1] : currecnyArray[this.index];
            var lastValueWithCurrency = _const.default.formatKMBT(this.yData[this.yData.length - 1], 2) + currencySymbol;
            var result = this.name;
            if (showLegendAmounts) {
              result += '<span style="color: #334356; font-size: 14px; font-weight: 300">' + lastValueWithCurrency + "</span>";
            }
            return result;
          }
        },
        series: seriesData
      };
    },
    createAreaHighchart: function createAreaHighchart(chart, chartHeight, chartWidth) {
      var chartCategories = Ember.get(chart, "highchartCategories");
      var highchartLabels = Ember.get(chart, "highchartLabels");
      var showLegendAmounts = chart.id === "cash";
      var colorPalette = Ember.get(chart, "colorPalette");
      var seriesData = Ember.get(chart, "highchartData");
      var title = Ember.get(chart, "title");
      if (Ember.get(chart, "showy2")) {
        seriesData = seriesData.map(function (ele, index) {
          ele.yAxis = index;
          return ele;
        });
      } else if (seriesData && seriesData.length > 1) {
        seriesData = DashboardUtil.sortChartData(seriesData, "asc");
      }
      var currecnyArray = [""];
      if (Ember.get(chart, "highchartCurrency")) {
        currecnyArray = Ember.get(chart, "highchartCurrency");
      }
      if (DashboardUtil.isEmptyChart(chart.get("ignoreCategories"), chart.get("highchartData"), chart.get("highchartCategories"))) {
        return DashboardUtil.noDataChartSVG(chart, chartHeight, chartWidth);
      }
      return {
        chart: {
          height: chartHeight,
          width: chartWidth,
          type: "area",
          spacingLeft: 20,
          spacingRight: 20,
          borderColor: "#000000",
          borderWidth: 1
        },
        credits: {
          enabled: false
        },
        colors: colorPalette,
        title: {
          text: title
        },
        xAxis: {
          categories: chartCategories
        },
        yAxis: [{
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArray[0];
              if (currency === "%") {
                return _const.default.formatKMBT(this.value) + currency;
              }
              return currency + _const.default.formatKMBT(this.value);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2
        }, {
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArray[1];
              if (currency === "%") {
                return _const.default.formatKMBT(this.value) + currency;
              }
              return currency + _const.default.formatKMBT(this.value);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2,
          opposite: true
        }],
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
          line: {
            dataLabels: {
              enabled: highchartLabels,
              formatter: function formatter() {
                var currencySymbol = this.series.index >= currecnyArray.length ? currecnyArray[currecnyArray.length - 1] : currecnyArray[this.series.index];
                if (currencySymbol === "%") {
                  return _const.default.formatKMBT(this.y, 1) + currencySymbol;
                } else {
                  return currencySymbol + _const.default.formatKMBT(this.y, 1);
                }
              }
            }
          }
        },
        legend: {
          enabled: true,
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 6,
          itemStyle: {
            color: "#334356",
            fontSize: "15px",
            fontWeight: "normal"
          },
          labelFormatter: function labelFormatter() {
            var currencySymbol = this.index >= currecnyArray.length ? currecnyArray[currecnyArray.length - 1] : currecnyArray[this.index];
            var lastValueWithCurrency = _const.default.formatKMBT(this.yData[this.yData.length - 1], 2) + currencySymbol;
            var result = this.name;
            if (showLegendAmounts) {
              result += '<span style="color: #334356; font-size: 14px; font-weight: 300">' + lastValueWithCurrency + "</span>";
            }
            return result;
          }
        },
        series: seriesData
      };
    },
    createMixHighchart: function createMixHighchart(chart, chartHeight, chartWidth) {
      var chartCategories = Ember.get(chart, "highchartCategories");
      var colorPalette = Ember.get(chart, "colorPalette");
      var currecnyArray = [""];
      if (Ember.get(chart, "highchartCurrency")) {
        currecnyArray = Ember.get(chart, "highchartCurrency");
      }
      if (DashboardUtil.isEmptyChart(chart.get("ignoreCategories"), chart.get("highchartData"), chart.get("highchartCategories"))) {
        return DashboardUtil.noDataChartSVG(chart, chartHeight, chartWidth);
      }
      return {
        chart: {
          height: chartHeight,
          width: chartWidth,
          spacingLeft: 20,
          spacingRight: 20,
          borderColor: "#000000",
          borderWidth: 1
        },
        credits: {
          enabled: false
        },
        colors: colorPalette,
        title: {
          text: Ember.get(chart, "title")
        },
        xAxis: {
          categories: chartCategories
        },
        yAxis: [{
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArray[0];
              if (currency === "%") {
                return _const.default.formatKMBT(this.value) + currency;
              }
              return currency + _const.default.formatKMBT(this.value);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2
        }, {
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArray.length === 3 ? currecnyArray[2] : currecnyArray[1];
              if (currency === "%") {
                return _const.default.formatKMBT(this.value) + currency;
              }
              return currency + _const.default.formatKMBT(this.value);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2,
          opposite: true
        }],
        plotOptions: {
          column: {
            stacking: Ember.get(chart, "highchartStacking")
          },
          series: {
            marker: {
              enabled: false
            }
          }
        },
        legend: {
          enabled: true,
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 6,
          itemStyle: {
            color: "#334356",
            fontSize: "15px",
            fontWeight: "normal"
          }
        },
        series: Ember.get(chart, "highchartData")
      };
    },
    createPieHighchart: function createPieHighchart(chart, chartHeight, chartWidth, asImage) {
      var colorPalette = Ember.get(chart, "colorPalette");
      var isDonut = chart.get("chartType") === "donut";
      if (DashboardUtil.isEmptyChart(chart.get("ignoreCategories"), chart.get("highchartData"), chart.get("highchartCategories"))) {
        return DashboardUtil.noDataChartSVG(chart, chartHeight, chartWidth);
      }
      var chartData = chart.get("highchartData");
      if (chartData && chartData.length > 1 && !isDonut) {
        chartData = chartData.sort(function (a, b) {
          return b.y - a.y;
        });
      }
      if (isDonut) {
        var levelData1 = [],
          levelData0 = [];
        chartData.forEach(function (data) {
          if (!data.level) {
            levelData0.push(data);
          } else if (data.level === 1) {
            levelData1.push(data);
          }
        });
        chartData = [{
          colorByPoint: true,
          data: levelData1,
          size: "60%"
        }, {
          colorByPoint: true,
          data: levelData0,
          size: "80%",
          innerSize: "60%"
        }];
      } else {
        chartData = [{
          colorByPoint: true,
          data: chartData
        }];
      }
      var legendSize = "15px";
      var pieSize = chartHeight - 100;
      chartData[0].data.forEach(function (item) {
        if (item.name.length > 20) {
          legendSize = "10px";
        }
      });
      return {
        chart: {
          type: "pie",
          height: chartHeight,
          width: chartWidth,
          spacingLeft: 10,
          spacingRight: 10,
          borderColor: "#000000",
          borderWidth: 1
        },
        credits: {
          enabled: false
        },
        title: {
          text: Ember.get(chart, "title")
        },
        colors: colorPalette,
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false
            }
          },
          pie: {
            dataLabels: {
              enabled: false
            },
            animation: false,
            showInLegend: true,
            borderWidth: 0,
            size: pieSize,
            center: [asImage ? 200 : 110, "50%"]
          }
        },
        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 6,
          itemStyle: {
            color: "#878d94",
            fontSize: legendSize,
            fontWeight: "normal"
          },
          labelFormatter: function labelFormatter() {
            var labelName = this.name;
            if (typeof this.name === "string" && this.name.length > 20) {
              var breakIndex = 10;
              if (this.name.lastIndexOf(" ") < 20) {
                breakIndex = this.name.lastIndexOf(" ");
              }
              labelName = "<span>".concat(this.name.substr(0, breakIndex), "<br/>").concat(this.name.substr(breakIndex), "</span>");
            }
            return "".concat(labelName, "<span style=\"color: #334356; font-size: ").concat(legendSize, "; font-weight: 300\">  ").concat(_const.default.round(this.percentage, 2), "%</span>");
          }
        },
        series: chartData
      };
    },
    createColumnHighchart: function createColumnHighchart(chart, chartHeight, chartWidth) {
      var chartCategories = Ember.get(chart, "highchartCategories");
      var colorPalette = Ember.get(chart, "colorPalette");
      var currecnyArray = [""];
      var currecnyArrayY = [""];
      if (Ember.get(chart, "highchartCurrency")) {
        currecnyArray = Ember.get(chart, "highchartCurrency");
        currecnyArrayY = currecnyArray;
      }
      if (Ember.get(chart, "highchartStacking") === "percent") {
        currecnyArrayY = ["%"];
      }
      var chartData = Ember.get(chart, "highchartData");
      if (chartData && chartData.length > 1) {
        chartData = DashboardUtil.sortChartData(chartData, "desc");
      }
      if (DashboardUtil.isEmptyChart(chart.get("ignoreCategories"), chart.get("highchartData"), chart.get("highchartCategories"))) {
        return DashboardUtil.noDataChartSVG(chart, chartHeight, chartWidth);
      }
      return {
        chart: {
          height: chartHeight,
          width: chartWidth,
          type: "column",
          spacingLeft: 20,
          spacingRight: 20,
          borderColor: "#000000",
          borderWidth: 1
        },
        credits: {
          enabled: false
        },
        colors: colorPalette,
        title: {
          text: Ember.get(chart, "title")
        },
        xAxis: {
          categories: chartCategories
        },
        yAxis: {
          title: {
            text: ""
          },
          labels: {
            formatter: function formatter() {
              var currency = currecnyArrayY[0];
              if (currency === "%") {
                return _const.default.formatKMBT(this.value, 2) + currency;
              }
              return currency + _const.default.formatKMBT(this.value, 2);
            },
            style: {
              color: "#878d94",
              fontWeight: "normal",
              fontSize: "15px"
            }
          },
          gridLineColor: "#ebebeb",
          gridLineWidth: 2
        },
        plotOptions: {
          column: {
            stacking: Ember.get(chart, "highchartStacking"),
            dataLabels: {
              enabled: Ember.get(chart, "labelsOnColums")
            }
          },
          series: {
            marker: {
              enabled: false
            }
          }
        },
        legend: {
          enabled: true,
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 6,
          itemStyle: {
            color: "#334356",
            fontSize: "15px",
            fontWeight: "normal"
          }
        },
        series: chartData
      };
    },
    createKPIChart: function createKPIChart(chart, chartHeight, chartWidth) {
      var text = "";
      var scenario = Ember.get(chart, "scenario"),
        isBudgetVsScenario = chart.get("controller.isBudgetVsScenario"),
        forecastViewOption = chart.get("controller.forecastViewOption"),
        isFvB = chart.get("controller.isFvB");
      var data = DashboardUtil.convertKPIData(chart.get("highchartData"), chart.get("highchartCurrency"), chart.get("kpiSelection"), {
        scenario: scenario,
        isBudgetVsScenario: isBudgetVsScenario,
        forecastViewOption: forecastViewOption,
        isFvB: isFvB
      });
      var svgHeader = "<svg xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" style=\"font-family:'lucida grande', 'lucida sans unicode', arial, helvetica, sans-serif;\" xmlns=\"http://www.w3.org/2000/svg\" width=\"".concat(chartWidth, "\" height=\"").concat(chartHeight, "\" viewBox=\"0 0 ").concat(chartWidth, " ").concat(chartHeight, "\">");
      var midW = chartWidth / 2;
      var headerHeight = 30;
      var padding = 10;
      var numberOfLines = 3;
      if (data.length <= 2) {
        numberOfLines = 1;
      } else if (data.length <= 4) {
        numberOfLines = 2;
      }
      var midLine = "<line x1=\"".concat(midW, "\" y1=\"").concat(headerHeight + padding, "\" x2=\"").concat(midW, "\" y2=\"").concat(chartHeight - padding, "\" style=\"stroke:rgb(0,0,0);stroke-width:2\" />");
      var vertLineHeight1 = (chartHeight - headerHeight - 2 * padding) / numberOfLines + headerHeight + padding;
      var vertLineHeight2 = 2 * (chartHeight - headerHeight - 2 * padding) / numberOfLines + headerHeight + padding;
      var vertLineHeightDiff = vertLineHeight2 - vertLineHeight1;
      var vertLine1 = "<line x1=\"".concat(padding, "\" y1=\"").concat(vertLineHeight1, "\" x2=\"").concat(chartWidth - padding, "\" y2=\"").concat(vertLineHeight1, "\" style=\"stroke:rgb(0,0,0);stroke-width:2\" />");
      if (numberOfLines === 1) {
        vertLine1 = "";
      }
      var vertLine2 = "";
      if (numberOfLines >= 3) {
        vertLine2 = "<line x1=\"".concat(padding, "\" y1=\"").concat(vertLineHeight2, "\" x2=\"").concat(chartWidth - padding, "\" y2=\"").concat(vertLineHeight2, "\" style=\"stroke:rgb(0,0,0);stroke-width:2\" />");
      }
      var svgEnd = "</svg>";
      var title = chart ? Ember.get(chart, "title") : "";
      var header = "<rect x=\"0\" y=\"0\" width=\"".concat(chartWidth, "\" height=\"").concat(chartHeight, "\" style=\"fill:#FFFFFF;stroke-width:1;stroke:rgb(0,0,0)\"></rect><text x=\"").concat(midW, "\" y=\"10\" text-anchor=\"middle\" font-size=\"18px\"><tspan x=\"").concat(midW, "\" dy=\"18px\">").concat(title).concat(chart.get("extraTitle") ? " - " + chart.get("extraTitle") : "", "</tspan></text>");
      for (var i = 0; i < data.length; i++) {
        var xPoint = i % 2 === 0 ? (midW + padding) / 2 : (midW + chartWidth - padding) / 2;
        var yPoint = 0;
        if (data.length > 4) {
          if (i <= 1) {
            yPoint = vertLineHeight1 - vertLineHeightDiff / 2;
          } else if (i <= 3) {
            yPoint = vertLineHeight1 + vertLineHeightDiff / 2;
          } else {
            yPoint = vertLineHeight2 + vertLineHeightDiff / 2;
          }
        } else {
          if (i <= 1) {
            yPoint = vertLineHeight1 - vertLineHeightDiff / 2;
          } else if (i <= 3) {
            yPoint = vertLineHeight1 + vertLineHeightDiff / 2;
          }
        }
        var tmpData = data[i];
        var innerText = "<tspan x=\"".concat(xPoint, "\" dy=\"14px\" font-size='12px'>").concat(tmpData.title, "</tspan>");
        if (scenario || forecastViewOption) {
          var textSize = tmpData.data2 ? "12px" : "16px";
          var name = tmpData.name ? tmpData.name : "";
          name = name.replace("&", "&amp;");
          if (tmpData.data) {
            innerText += "<tspan x=\"".concat(xPoint, "\" dy=\"20px\" font-size='").concat(textSize, "' fill=\"rgb(0, 0, 0)\">").concat(name, "</tspan>");
            innerText += "<tspan x=\"".concat(xPoint, "\" dy=\"20px\" font-size='").concat(textSize, "' fill=\"rgb(80, 184, 131)\">").concat(tmpData.data, "</tspan>");
          } else {
            innerText += "<tspan x=\"".concat(xPoint, "\" dy=\"20px\" font-size='").concat(textSize, "' fill=\"rgb(0, 0, 0)\">").concat(name, "</tspan>");
            innerText += "<tspan x=\"".concat(xPoint, "\" dy=\"20px\" font-size='").concat(textSize, "' fill=\"rgb(80, 184, 131)\">").concat(intl.t("general.no_data_1695803190786"), "</tspan>");
          }
          if (tmpData.data2) {
            var name2 = tmpData.name2 ? tmpData.name2 : "";
            name2 = name2.replace("&", "&amp;");
            innerText += "<tspan x=\"".concat(xPoint, "\" dy=\"20px\" font-size='12px' fill=\"rgb(0, 0, 0)\">").concat(name2, "</tspan>");
            innerText += "<tspan x=\"".concat(xPoint, "\" dy=\"20px\" font-size='12px' fill=\"rgb(80, 184, 131)\">").concat(tmpData.data2, "</tspan>");
          }
          yPoint -= tmpData.data2 ? 50 : 30;
        } else {
          if (tmpData.data) {
            innerText += "<tspan x=\"".concat(xPoint, "\" dy=\"20px\" font-size='16px' fill=\"rgb(80, 184, 131)\">").concat(tmpData.data, "</tspan>");
          } else {
            innerText += "<tspan x=\"".concat(xPoint, "\" dy=\"20px\" font-size='16px' fill=\"rgb(80, 184, 131)\">").concat(intl.t("general.no_data_1695803190786"), "</tspan>");
          }
          yPoint -= 20;
        }
        text += "<text x=\"".concat(xPoint, "\" y=\"").concat(yPoint, "\" text-anchor=\"middle\">").concat(innerText, "</text>");
      }
      return svgHeader + header + midLine + vertLine1 + vertLine2 + text + svgEnd;
    },
    createSingleKPIChart: function createSingleKPIChart(chart, chartHeight, chartWidth) {
      var svgHeader = "<svg xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" style=\"font-family:'lucida grande', 'lucida sans unicode', arial, helvetica, sans-serif;\" xmlns=\"http://www.w3.org/2000/svg\" width=\"".concat(chartWidth, "\" height=\"").concat(chartHeight, "\" viewBox=\"0 0 ").concat(chartWidth, " ").concat(chartHeight, "\">");
      var midW = chartWidth / 2;
      var midH = chartHeight / 2 - 30;
      var text;
      var data = DashboardUtil.convertSingleData(chart.get("highchartData"), chart.get("highchartCurrency")),
        name = data.name || "",
        name2 = data.name2 || "";
      name = name.replace("&", "&amp;");
      var header = "<rect x=\"0\" y=\"0\" width=\"".concat(chartWidth, "\" height=\"").concat(chartHeight, "\" style=\"fill:#FFFFFF;stroke-width:1;stroke:rgb(0,0,0)\"></rect><text x=\"").concat(midW, "\" y=\"10\" text-anchor=\"middle\" font-size=\"18px\"><tspan x=\"").concat(midW, "\" dy=\"18px\">").concat(chart.title).concat(chart.get("extraTitle") ? " - " + chart.get("extraTitle") : "", "</tspan></text>");
      var innerText = "<tspan x=\"".concat(midW, "\" dy=\"14px\" font-size='14px'>").concat(name, "</tspan>") + "<tspan x=\"".concat(midW, "\" dy=\"24px\" font-size='18px' fill=\"rgb(80, 184, 131)\">").concat(data.data, "</tspan>");
      var svgEnd = "</svg>";
      var scenario = Ember.get(chart, "scenario"),
        isBudgetVsScenario = chart.get("controller.isBudgetVsScenario"),
        forecastViewOption = chart.get("controller.forecastViewOption"),
        isFvB = chart.get("controller.isFvB");
      if (scenario && isBudgetVsScenario || forecastViewOption && isFvB) {
        name2 = name2.replace("&", "&amp;");
        innerText += "<tspan x=\"".concat(midW, "\" dy=\"14px\" font-size='14px'></tspan>") + "<tspan x=\"".concat(midW, "\" dy=\"14px\" font-size='14px'>").concat(name2, "</tspan>") + "<tspan x=\"".concat(midW, "\" dy=\"24px\" font-size='18px' fill=\"rgb(80, 184, 131)\">").concat(data.data2, "</tspan>");
      }
      text = "<text x=\"".concat(midW, "\" y=\"").concat(midH, "\" text-anchor=\"middle\">").concat(innerText, "</text>");
      return svgHeader + header + text + svgEnd;
    },
    getHighchartsCharts: function getHighchartsCharts(charts, height, width, asImage) {
      var highchartCharts = [];
      var createChart = {
        "line-chart": DashboardUtil.createLineHighchart,
        "mix-chart": DashboardUtil.createMixHighchart,
        "pie-chart": DashboardUtil.createPieHighchart,
        "column-chart": DashboardUtil.createColumnHighchart,
        "area-chart": DashboardUtil.createAreaHighchart,
        "kpi-chart": DashboardUtil.createKPIChart,
        "single-kpi": DashboardUtil.createSingleKPIChart
      };
      charts.forEach(function (chart) {
        if (Ember.get(chart, "highchartType") && createChart[Ember.get(chart, "highchartType")]) {
          var chartTemplate = createChart[Ember.get(chart, "highchartType")](chart, height, width, asImage);
          if (chartTemplate && typeof chartTemplate !== "string") {
            highchartCharts.push({
              name: chart.get("title"),
              id: chart.get("id"),
              uniqueId: chart.get("uniqueId"),
              width: width,
              height: height,
              image: Ember.$("<div></div>").highcharts(chartTemplate).highcharts().getSVG()
            });
          } else {
            highchartCharts.push({
              name: chart.get("title"),
              id: chart.get("id"),
              uniqueId: chart.get("uniqueId"),
              width: width,
              height: height,
              image: chartTemplate
            });
          }
        }
      });
      return highchartCharts;
    },
    changeDataArrayPeriod: function changeDataArrayPeriod(dataArray, startIndex, endIndex) {
      if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
        return dataArray.slice(startIndex, endIndex + 1);
      }
      return dataArray;
    },
    getStartAndEndDateIndexs: function getStartAndEndDateIndexs(highchartCategories, startDate, endDate) {
      var startIndex = highchartCategories.indexOf(startDate),
        endIndex = highchartCategories.indexOf(endDate);
      if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
        return {
          startIndex: startIndex,
          endIndex: endIndex
        };
      }
      return {
        startIndex: -1,
        endIndex: -1
      };
    },
    changeDataPeriod: function changeDataPeriod(highchartData, startIndex, endIndex) {
      highchartData.forEach(function (tempData) {
        tempData.data = DashboardUtil.changeDataArrayPeriod(tempData.data, startIndex, endIndex);
      });
    },
    changeObjectPeriod: function changeObjectPeriod(objData, startIndex, endIndex) {
      for (var key in objData) {
        objData[key].data = DashboardUtil.changeDataArrayPeriod(objData[key].data, startIndex, endIndex);
      }
    },
    getEmptyMonthsObj: function getEmptyMonthsObj(startDate, endDate) {
      var lastMonth = startDate ? new Date(startDate) : null;
      var monthsObj = {};
      if (lastMonth) {
        while (endDate && lastMonth && lastMonth.isValid() && lastMonth.isBefore(endDate)) {
          monthsObj[lastMonth.format("{yyyy}/{MM}")] = 0;
          lastMonth = lastMonth.addMonths(1);
        }
        monthsObj[endDate.format("{yyyy}/{MM}")] = 0;
      }
      return monthsObj;
    },
    isModelChart: function isModelChart(chart) {
      return Ember.get(chart, "id") && Ember.get(chart, "id").indexOf("model-") === 0;
    },
    fixMissingDashboardId: function fixMissingDashboardId(dashboardId, budget, chartOrder) {
      // check for unmapped chart to old dashboard
      if (dashboardId && budget) {
        var unmappedCharts = Ember.A([]),
          dashboardList = (budget.get("dashboardList") || Ember.A([])).mapBy("uniqueId"),
          firstDashboardHasCharts = false;
        if (dashboardList.indexOf(dashboardId) === 0) {
          chartOrder.forEach(function (chart) {
            if (!Ember.get(chart, "hide")) {
              firstDashboardHasCharts = true;
            }
            if (Ember.get(chart, "dashboardId") && dashboardList.indexOf(Ember.get(chart, "dashboardId")) === -1) {
              unmappedCharts.pushObject(chart);
            }
          });
          if (!firstDashboardHasCharts) {
            unmappedCharts.forEach(function (unmappedChart) {
              Ember.setProperties(unmappedChart, {
                hide: false,
                dashboardId: dashboardId
              });
            });
          }
        }
      }
    },
    roundChartData: function roundChartData(chartData) {
      return chartData && chartData.map(function (serie) {
        serie.data = _const.default.roundArray(serie.data);
        return serie;
      });
    },
    defaultChartTypes: {
      rev: "stackedColumn",
      "rev-growth": "lineAndColumn",
      "deferred-rev-ar": "basicLine",
      "bookings-by": "stackedColumn",
      bookings: "lineAndColumn",
      "bookings-type": "stackedColumn",
      "bookings-cash-rev": "basicColumn",
      "operating-expenses": "stackedColumn",
      "operating-expenses-pie": "pie",
      "expenses-by-category": "donut",
      "operating-expenses-revenue": "basicColumn",
      hc: "stackedColumn",
      "revenues-per-employee": "lineAndColumn",
      "hc-new": "pie",
      "avg-salary-per-employee": "basicLine",
      "net-margin": "lineAndColumn",
      "gross-margin": "lineAndColumn",
      cash: "basicLine",
      "net-reveunes-margin": "lineAndColumn",
      saas: "basicLine",
      saas2: "basicLine",
      "saas-y": "basicLine",
      "saas-y2": "basicLine",
      kpiChart: "kpi-chart",
      "single-kpi": "single-kpi"
    },
    chartTypes: {
      rev: {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        basicArea: true
      },
      "rev-growth": {
        lineAndColumn: true
      },
      "deferred-rev-ar": {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        basicArea: true
      },
      "bookings-by": {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        // donut: true,
        basicArea: true
      },
      bookings: {
        lineAndColumn: true
      },
      "bookings-type": {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        basicArea: true
      },
      "bookings-cash-rev": {
        basicColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        basicArea: true
      },
      "operating-expenses": {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        basicArea: true,
        pie: true
        // donut: true
      },
      "operating-expenses-pie": {
        pie: true
        // donut: true
      },
      "expenses-by-category": {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        donut: true,
        basicArea: true
      },
      "operating-expenses-revenue": {
        basicColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        basicArea: true
      },
      hc: {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        // donut: true,
        basicArea: true
      },
      "revenues-per-employee": {
        lineAndColumn: true
      },
      "hc-new": {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        // donut: true,
        basicArea: true
      },
      "avg-salary-per-employee": {
        basicColumn: true,
        basicLine: true,
        basicLineWithLabels: true
      },
      "net-margin": {
        lineAndColumn: true
      },
      "gross-margin": {
        lineAndColumn: true
      },
      cash: {
        basicColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        basicArea: true
      },
      "net-reveunes-margin": {
        lineAndColumn: true
      },
      saas: {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        basicArea: true
      },
      saas2: {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        basicArea: true
      },
      "saas-y": {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        basicArea: true
      },
      "saas-y2": {
        basicColumn: true,
        stackedColumn: true,
        stackedPercenatageColumn: true,
        basicLine: true,
        basicLineWithLabels: true,
        pie: true,
        basicArea: true
      },
      kpiChart: {
        "kpi-chart": true
      },
      "single-kpi": {
        "single-kpi": true
      }
    },
    getDefaultChartType: function getDefaultChartType(chartId) {
      if (chartId.startsWith("model")) {
        return "basicLine";
      }
      return DashboardUtil.defaultChartTypes[chartId];
    },
    getChartTypesForChart: function getChartTypesForChart(chartId) {
      if (chartId.startsWith("model")) {
        return {
          basicColumn: true,
          stackedColumn: true,
          stackedPercenatageColumn: true,
          lineAndColumn: true,
          basicLine: true,
          basicLineWithLabels: true,
          pie: true,
          basicArea: true
        };
      }
      return DashboardUtil.chartTypes[chartId];
    },
    paddingHighchartResult: function paddingHighchartResult(highchartData, highchartDataScenario, dataLength) {
      for (var i = 0; i < highchartData.length; i++) {
        var data = highchartData[i],
          data2 = highchartDataScenario[i];
        for (var j = 0; j < dataLength; j++) {
          if (data && data.data && !data.data[j]) {
            data.data[j] = 0;
          }
          if (data2 && data2.data && !data2.data[j]) {
            data2.data[j] = 0;
          }
        }
      }
    },
    paddingHighchartMapResult: function paddingHighchartMapResult(highchartDataMap) {
      var highchartDataScenarioMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var dataLength = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      for (var key in highchartDataMap) {
        var data = highchartDataMap[key];
        for (var j = 0; j < dataLength; j++) {
          if (data && data.data && !data.data[j]) {
            data.data[j] = 0;
          }
        }
      }
      for (var _key3 in highchartDataScenarioMap) {
        var _data = highchartDataScenarioMap[_key3];
        for (var _j = 0; _j < dataLength; _j++) {
          if (_data && _data.data && !_data.data[_j]) {
            _data.data[_j] = 0;
          }
        }
      }
    },
    getDetailDimensions: function getDetailDimensions(forecastGroup) {
      var detailDimensions = [];
      var findDimensionLeafs = function findDimensionLeafs(group) {
        group.forEach(function (child) {
          if (Ember.get(child, "type") === "by-dimension") {
            var hasChildDimension = Ember.get(child, "children").filter(function (child) {
              return Ember.get(child, "type") === "by-dimension" && !Ember.get(child, "isEmpty");
            });
            if (!hasChildDimension.length) {
              // is leaf
              detailDimensions.pushObject(child);
            } else {
              findDimensionLeafs(Ember.get(child, "children"));
            }
          }
        });
      };
      findDimensionLeafs(forecastGroup);
      return detailDimensions;
    },
    getDiemensionSubtree: function getDiemensionSubtree(dimension) {
      var dimensionValues = Ember.get(dimension, "dimension.values");
      var parent = _dimensions.default.findDimensionValue(Ember.get(dimension, "selectedValue"), dimensionValues);
      return _dimensions.default.getDimensionSubtree({
        parent: parent,
        dimensionTree: dimensionValues
      });
    },
    getAllFillterOptionsArray: function getAllFillterOptionsArray(dimension, dimensionFilteredSubtree, filter, globalDimensionFilter) {
      var dimensionValues = Ember.get(dimension, "dimension.values");
      if (filter.endsWith("detail")) {
        return dimensionFilteredSubtree.map(function (dimension) {
          var newDimension = _lodash.default.cloneDeep(dimension);
          if (_dimensions.default.isGroup(dimension, dimensionValues)) {
            Ember.set(newDimension, "name", Ember.get(newDimension, "name") + " - No Sub " + Ember.get(globalDimensionFilter, "dimension.name"));
          }
          return newDimension;
        });
      }
      return dimensionValues.filter(function (value) {
        return !Ember.get(value, "parent");
      });
    },
    removeEmptyValues: function removeEmptyValues(dataMap) {
      // remove empty values
      var removeKeys = [];
      for (var key in dataMap) {
        var isEmpty = true;
        if (_lodash.default.difference(dataMap[key].data, [0]).length !== 0) {
          isEmpty = false;
        }
        if (isEmpty) {
          removeKeys.push(key);
        }
      }
      removeKeys.forEach(function (key) {
        delete dataMap[key];
      });
    },
    addMissingPeriodValues: function addMissingPeriodValues(options) {
      var highchartCategories = options.highchartCategories,
        highchartDataMap = options.highchartDataMap,
        highchartDataScenarioMap = options.highchartDataScenarioMap,
        chartStartDate = options.chartStartDate,
        chartEndDate = options.chartEndDate;
      var startDate = new Date(chartStartDate),
        endDate = new Date(chartEndDate);
      var months = [];
      while (!startDate.isAfter(endDate)) {
        months.push(startDate.format("{yyyy}/{MM}"));
        startDate.addMonths(1);
      }
      var objectPadding = function objectPadding(currentRange, addToArray) {
        var i = 0;
        while (i < months.length && months[i] < currentRange[i]) {
          for (var arrI = 0; arrI < addToArray.length; arrI++) {
            var data = addToArray[arrI].data;
            var value = addToArray[arrI].addDate ? months[i] : 0;
            data.insertAt(i, value);
          }
          i++;
        }
      };
      objectPadding(highchartCategories, [{
        data: highchartCategories,
        addDate: true
      }]);
      for (var key in highchartDataMap) {
        objectPadding(highchartDataMap[key].keys, [{
          data: highchartDataMap[key].data,
          addDate: false
        }, {
          data: highchartDataMap[key].keys,
          addDate: true
        }]);
      }
      for (var _key4 in highchartDataScenarioMap) {
        objectPadding(highchartDataScenarioMap[_key4].keys, [{
          data: highchartDataScenarioMap[_key4].data,
          addDate: false
        }, {
          data: highchartDataScenarioMap[_key4].keys,
          addDate: true
        }]);
      }
    }
  };
  var api = {
    colorSchema: DashboardUtil.colorSchema,
    chartTypes: DashboardUtil.chartTypes,
    colorPalette: DashboardUtil.colorPalette,
    SaasColorPalette: DashboardUtil.SaasColorPalette,
    getColorPalette: DashboardUtil.getColorPalette,
    getChartWidth: DashboardUtil.getChartWidth,
    getChartHeight: DashboardUtil.getChartHeight,
    calcRevenues: DashboardUtil.calcRevenues,
    calcBookings: DashboardUtil.calcBookings,
    calcBookingsBy: DashboardUtil.calcBookingsBy,
    calcBookingsCashRev: DashboardUtil.calcBookingsCashRev,
    calcDeferredRevenuesAr: DashboardUtil.calcDeferredRevenuesAr,
    calcOperatingExpenses: DashboardUtil.calcOperatingExpenses,
    calcExpensesByCategory: DashboardUtil.calcExpensesByCategory,
    calcCostOfRevenues: DashboardUtil.calcCostOfRevenues,
    calcOperatingExpensesRevenue: DashboardUtil.calcOperatingExpensesRevenue,
    calcGrossMargin: DashboardUtil.calcGrossMargin,
    calcRevenuesPerEmployee: DashboardUtil.calcRevenuesPerEmployee,
    calcCashBalance: DashboardUtil.calcCashBalance,
    calcEndOfCash: DashboardUtil.calcEndOfCash,
    calcSaaSSubscriptionsMonthly: DashboardUtil.calcSaaSSubscriptionsMonthly,
    calcSaaSSubscriptionsAnnual: DashboardUtil.calcSaaSSubscriptionsAnnual,
    calcHeadcount: DashboardUtil.calcHeadcount,
    calcSalaryPerEmployee: DashboardUtil.calcSalaryPerEmployee,
    calcNetIncomeAndMargin: DashboardUtil.calcNetIncomeAndMargin,
    getDefaultChartTitle: DashboardUtil.getDefaultChartTitle,
    getDefaultCharts: DashboardUtil.getDefaultCharts,
    getCharts: DashboardUtil.getCharts,
    saveCharts: DashboardUtil.saveCharts,
    changeMapToArrayAndSort: DashboardUtil.changeMapToArrayAndSort,
    sortChartData: DashboardUtil.sortChartData,
    convertKPIData: DashboardUtil.convertKPIData,
    convertSingleData: DashboardUtil.convertSingleData,
    noDataChartSVG: DashboardUtil.noDataChartSVG,
    isEmptyChart: DashboardUtil.isEmptyChart,
    createLineHighchart: DashboardUtil.createLineHighchart,
    createMixHighchart: DashboardUtil.createMixHighchart,
    createPieHighchart: DashboardUtil.createPieHighchart,
    createColumnHighchart: DashboardUtil.createColumnHighchart,
    createKPIChart: DashboardUtil.createKPIChart,
    createSingleKPIChart: DashboardUtil.createSingleKPIChart,
    getHighchartsCharts: DashboardUtil.getHighchartsCharts,
    changeDataArrayPeriod: DashboardUtil.changeDataArrayPeriod,
    getStartAndEndDateIndexs: DashboardUtil.getStartAndEndDateIndexs,
    changeDataPeriod: DashboardUtil.changeDataPeriod,
    changeObjectPeriod: DashboardUtil.changeObjectPeriod,
    getEmptyMonthsObj: DashboardUtil.getEmptyMonthsObj,
    isModelChart: DashboardUtil.isModelChart,
    getVSBudgetLines: DashboardUtil.getVSBudgetLines,
    getVSBudget: DashboardUtil.getVSBudget,
    getDefaultChartsForBudget: DashboardUtil.getDefaultChartsForBudget,
    getFirstSeriesName: DashboardUtil.getFirstSeriesName,
    getSecondSeriesName: DashboardUtil.getSecondSeriesName,
    getSecondStackName: DashboardUtil.getSecondStackName,
    getFirstStackName: DashboardUtil.getFirstStackName,
    fixMissingDashboardId: DashboardUtil.fixMissingDashboardId,
    roundChartData: DashboardUtil.roundChartData,
    getDefaultChartType: DashboardUtil.getDefaultChartType,
    getChartTypesForChart: DashboardUtil.getChartTypesForChart
  };
  var _default = _exports.default = api;
});