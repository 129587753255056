define("budgeta/templates/components/select-assumptions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "i5poq3mu",
    "block": "{\"statements\":[[\"block\",[\"modal-dialog\"],null,[[\"close\",\"confirm\",\"title\",\"templateName\",\"lastStep\",\"hideHeader\",\"hideCancel\",\"confirmActionName\",\"addClass\",\"isElegantModal\"],[[\"get\",[\"close\"]],[\"get\",[\"confirm\"]],[\"get\",[\"title\"]],[\"get\",[\"templateName\"]],[\"get\",[\"lastStep\"]],[\"get\",[\"hideHeader\"]],[\"get\",[\"hideCancel\"]],[\"get\",[\"confirmActionName\"]],[\"get\",[\"addClass\"]],[\"get\",[\"isElegantModal\"]]]],0]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/select-assumptions-modal.hbs"
    }
  });
});