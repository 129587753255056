define("budgeta/helpers/format-date", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  function formatDate(input, format) {
    return input ? Ember.String.htmlSafe(moment(input).format(typeof format === "string" ? format : "l LT")) : "";
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return formatDate.apply(void 0, (0, _toConsumableArray2.default)(params));
  });
});