define("budgeta/components/main/budget/import-generic-file", ["exports", "budgeta/components/main/budget/import-modal"], function (_exports, _importModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _importModal.default.extend({
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get("controllertmp.genericBudgetRoot.destroyRecord") && !this.get("controllertmp.genericBudgetRoot.isDeleted") && this.get("controllertmp.genericBudgetRoot").destroyRecord();
    }
  });
});