define("budgeta/controllers/edit-line-modal", ["exports", "budgeta/services/ember-service-container", "budgeta/controllers/modal", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _modal, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _modal.default.extend({
    mainController: Ember.inject.controller("main"),
    mainBudgetIndexController: Ember.inject.controller("main/budget/index"),
    currencyOptions: Ember.computed.alias("mainBudgetIndexController.currencyOptions"),
    validateOnConfirm: true,
    name: Ember.computed.alias("model.name"),
    validations: {
      name: {
        presence: true,
        benefitNameUnique: true
      }
    },
    confirmDisabled: Ember.computed.not("isValid"),
    actions: {
      gotoModel: function gotoModel() {
        this.send("close");
        return true;
      },
      confirm: function confirm() {
        if (!this.get("changed")) {
          this.send("close");
        } else {
          this._super.apply(this);
        }
      },
      closeFormulaEditor: function closeFormulaEditor() {
        this.set("changed", true);
        this.send("closeModal", "modal3");
      },
      openFormulaEditor: function openFormulaEditor(component) {
        if (this.get("model.isModelGroup")) {
          // cause the fixed assumtions list to refresh
          this.notifyPropertyChange("refreshFixedAssumptions");
        }
        if (!Ember.isEmpty(component.get("value")) && component.get("value").toString().substr(0, 1) !== "=") {
          component.set("value", "=" + component.get("value"));
        }
        var formulaTemplate = _const.default.FORMULA_VERSION;
        this.send("openModal", formulaTemplate, {
          model: component,
          modelLine: this.get("model"),
          title: intl.t("general.title_1695611597384"),
          templateName: formulaTemplate,
          confirmActionName: intl.t("general.confirmActionName_1695611613098"),
          confirmAction: "closeFormulaEditor",
          actionTarget: this,
          outlet: "modal3",
          hideCancel: false
        });
      },
      changed: function changed() {
        this.set("changed", true);
      },
      didSelect: function didSelect() {
        return;
      }
    }
  });
});