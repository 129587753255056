define("budgeta/templates/components/budgeta-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9GYGrYNx",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"noData\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"dynamic-high-charts\"],null,[[\"content\",\"chartOptions\"],[[\"get\",[\"chartData\"]],[\"get\",[\"chartOptions\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"no-data-chart centerElement large_header\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"budgeta-chart.no-data\"],null],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/budgeta-chart.hbs"
    }
  });
});