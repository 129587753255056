define("budgeta/common-react-components/login/MainLogin", ["exports", "react", "react-router-dom", "budgeta/common-react-components/login/Passcode", "budgeta/common-react-components/login/ForgotPassword", "budgeta/common-react-components/login/SignIn", "budgeta/common-react-components/login/IntacctSignInCallback", "budgeta/common-react-components/login/ChangePassword", "budgeta/utils/const"], function (_exports, _react, _reactRouterDom, _Passcode, _ForgotPassword, _SignIn, _IntacctSignInCallback, _ChangePassword, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MainLogin = function MainLogin(_ref) {
    var _ref$identification = _ref.identification,
      identification = _ref$identification === void 0 ? "" : _ref$identification,
      _ref$restoreEmail = _ref.restoreEmail,
      restoreEmail = _ref$restoreEmail === void 0 ? "" : _ref$restoreEmail,
      _ref$password = _ref.password,
      password = _ref$password === void 0 ? "" : _ref$password,
      resetPassword = _ref.resetPassword,
      submitNewPassword = _ref.submitNewPassword,
      rememberChecked = _ref.rememberChecked,
      resendPasscode = _ref.resendPasscode,
      authenticate = _ref.authenticate,
      updateDataAction = _ref.updateDataAction,
      errorMessage = _ref.errorMessage,
      subHeader = _ref.subHeader,
      showPasscode = _ref.showPasscode,
      changePassword = _ref.changePassword,
      notice = _ref.notice,
      removeErrorMessage = _ref.removeErrorMessage,
      signinButtonLabel = _ref.signinButtonLabel,
      shouldResetPasswordLink = _ref.shouldResetPasswordLink,
      afterUserAuthenticated = _ref.afterUserAuthenticated;
    return _react.default.createElement(_reactRouterDom.default.BrowserRouter, null, _react.default.createElement(_reactRouterDom.default.Switch, null, _react.default.createElement(_reactRouterDom.default.Route, {
      path: "/login",
      render: function render() {
        return _react.default.createElement(_SignIn.default, {
          rememberChecked: rememberChecked,
          identification: identification,
          password: password,
          updateDataAction: updateDataAction,
          authenticate: authenticate,
          subHeader: subHeader,
          notice: notice,
          showPasscode: showPasscode,
          changePassword: changePassword,
          signinButtonLabel: signinButtonLabel
        });
      },
      exact: true
    }), _const.default.FF_SSO && _react.default.createElement(_reactRouterDom.default.Route, {
      path: "/login/sso-callback",
      render: function render() {
        return _react.default.createElement(_IntacctSignInCallback.default, {
          afterUserAuthenticated: afterUserAuthenticated
        });
      },
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: "/login/forgot-password",
      render: function render() {
        return _react.default.createElement(_ForgotPassword.default, {
          resetPassword: resetPassword,
          restoreEmail: restoreEmail,
          updateDataAction: updateDataAction,
          notice: notice
        });
      },
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: "/login/passcode",
      render: function render(props) {
        if (!showPasscode) {
          return _react.default.createElement(_reactRouterDom.default.Redirect, {
            to: {
              pathname: "/login",
              state: {
                from: props.location
              }
            }
          });
        }
        return _react.default.createElement(_Passcode.default, {
          removeErrorMessage: removeErrorMessage,
          errorMessage: errorMessage,
          shouldResetPasswordLink: shouldResetPasswordLink,
          authenticate: authenticate,
          updateDataAction: updateDataAction,
          resendPasscode: resendPasscode,
          notice: notice,
          identification: identification,
          changePassword: changePassword
        });
      },
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: "/change-password",
      render: function render() {
        return _react.default.createElement(_ChangePassword.default, {
          errorMessage: errorMessage,
          updateDataAction: updateDataAction,
          submitNewPassword: submitNewPassword,
          identification: identification
        });
      },
      exact: true
    }), _react.default.createElement(_reactRouterDom.default.Route, {
      path: "/login/*",
      render: function render(props) {
        return _react.default.createElement(_reactRouterDom.default.Redirect, {
          to: {
            pathname: "/login",
            state: {
              from: props.location
            }
          }
        });
      },
      exact: true
    })));
  };
  var _default = _exports.default = MainLogin;
});