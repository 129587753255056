define("budgeta/admin/axios.init", ["exports", "budgeta/utils/local-storage-util", "budgeta/config/environment", "budgeta/services/ember-service-container", "budgeta/admin/utils/const", "budgeta/admin/store/actions/notifActions"], function (_exports, _localStorageUtil, _environment, _emberServiceContainer, _const, _notifActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.axiosInit = axiosInit;
  /* eslint-disable import/no-unresolved, import/no-duplicates, no-undef, no-console */

  function axiosInit(store) {
    var SESSION_EXPIRED = "SESSION_EXPIRED";
    var emberSession = new _emberServiceContainer.default().lookup("session");
    function getAccessToken() {
      var session = _localStorageUtil.default.getItem("ember_simple_auth-session");
      return session && session.authenticated && session.authenticated.access_token;
    }
    axios.defaults.baseURL = "".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath);
    axios.defaults.timeout = _environment.default.adminApp.httpRequestTimeout;
    var sessionExpired = function sessionExpired() {
      var expired = $.cookie(_const.SESSION_EXPIRED_COOKIE);
      return expired === "true";
    };
    axios.interceptors.request.use(function (config) {
      //authorization
      var accessToken = getAccessToken();
      if (!accessToken || sessionExpired()) {
        var cancel;
        config.cancelToken = new axios.CancelToken(function (c) {
          cancel = c;
        });
        cancel(SESSION_EXPIRED); // cancel the request
        return config;
      }
      config.headers.Authorization = "Bearer ".concat(accessToken);
      config.headers["sibp-automation-token"] = _localStorageUtil.default.getItem("automationToken");
      return config;
    });
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      console.log("ERROR", error);
      if (error.message === SESSION_EXPIRED) {
        $.removeCookie(_const.SESSION_EXPIRED_COOKIE);
        emberSession.invalidate();
        return Promise.reject(error);
      }
      var status = error.response && error.response.status || 999;
      var errMsg = "";
      if (status / 100 === _const.HTTP_STATUS_CODE.INTERNAL) {
        //catch all 500-599 errors. internal server errors
        errMsg = "Internal error occurred. please try again later";
      } else if (status === _const.HTTP_STATUS_CODE.UNAUTHORIZED) {
        window.location = window.location.origin + "/logout";
        return Promise.reject(error);
      } else if (status === _const.HTTP_STATUS_CODE.EMAIL_ALREADY_EXISTS) {
        return Promise.reject({
          status: _const.HTTP_STATUS_CODE.EMAIL_ALREADY_EXISTS
        });
      } else if (status === _const.HTTP_STATUS_CODE.NOT_FOUND) {
        errMsg = error.response.data || 'An error occurred ("not found")';
      } else {
        errMsg = "Error occurred";
      }
      if (errMsg) {
        store.dispatch(_notifActions.default.showNoty({
          text: errMsg
        }));
      }
      return Promise.reject(error);
    });
  }
});