define("budgeta/templates/components/intacct-building-budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4bylRhvm",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle modal-padding\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"t\"],[\"intacct-building-budget.please-wait\"],null],true],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\"],[\"append\",[\"unknown\",[\"budgeta-spinner\"]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/intacct-building-budget.hbs"
    }
  });
});