define("budgeta/templates/components/collapse-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "51eVujff",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"collapse\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"title\",\"Expand\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"name\"],[\"arrow_up\"]]],false],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"name\"],[\"arrow_down\"]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"title\",\"Collapse\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"name\"],[\"arrow_down\"]]],false],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"name\"],[\"arrow_up\"]]],false],[\"text\",\"\\n\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/collapse-expand.hbs"
    }
  });
});