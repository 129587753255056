define("budgeta/components/select-2", ["exports", "ember-select-2/components/select-2"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  	This is just a proxy file requiring the component from the /addon folder and
  	making it available to the dummy application!
   */
  var _default = _exports.default = _select.default;
});