define("budgeta/templates/components/admin-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9zIX8IY2",
    "block": "{\"statements\":[[\"block\",[\"link-to\"],[\"admin.list\",[\"helper\",[\"query-params\"],null,[[\"role\",\"withAuth\"],[\"\",\"\"]]]],null,7],[\"text\",\"\\n|\\n\"],[\"block\",[\"link-to\"],[\"admin.list\",[\"helper\",[\"query-params\"],null,[[\"role\",\"withAuth\"],[\"beta\",\"\"]]]],null,6],[\"text\",\"\\n|\\n\"],[\"block\",[\"link-to\"],[\"admin.list\",[\"helper\",[\"query-params\"],null,[[\"role\",\"withAuth\"],[\"GA\",\"\"]]]],null,5],[\"text\",\"\\n|\\n\"],[\"block\",[\"link-to\"],[\"admin.list\",[\"helper\",[\"query-params\"],null,[[\"role\",\"withAuth\"],[\"demo\",\"\"]]]],null,4],[\"text\",\"\\n|\\n\"],[\"block\",[\"link-to\"],[\"admin.activity\"],null,3],[\"text\",\"\\n|\\n\"],[\"block\",[\"link-to\"],[\"admin.find-budget\"],null,2],[\"text\",\"\\n|\\n\"],[\"block\",[\"link-to\"],[\"admin.tips\"],null,1],[\"text\",\"\\n|\\n\"],[\"block\",[\"link-to\"],[\"admin.list\",[\"helper\",[\"query-params\"],null,[[\"role\",\"withAuth\"],[\"\",\"true\"]]]],null,0],[\"text\",\"\\n\"],[\"yield\",\"default\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"admin-menu.support-authorizations\"],null],false]],\"locals\":[]},{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"admin-menu.tooltips\"],null],false]],\"locals\":[]},{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"admin-menu.find-budget-by-id\"],null],false]],\"locals\":[]},{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"admin-menu.user-activity\"],null],false]],\"locals\":[]},{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"admin-menu.demo-users\"],null],false]],\"locals\":[]},{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"admin-menu.ga-users\"],null],false]],\"locals\":[]},{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"admin-menu.beta-users\"],null],false]],\"locals\":[]},{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"admin-menu.user-list\"],null],false]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/admin-menu.hbs"
    }
  });
});