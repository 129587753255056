define("budgeta/generic-file/components/budget-structure/budget-structure", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectWithoutProperties", "react", "react-redux", "@sibp/ui-components", "budgeta/services/ember-service-container", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "lodash/lodash", "budgeta/utils/const"], function (_exports, _toConsumableArray2, _slicedToArray2, _objectWithoutProperties2, _react, _reactRedux, _uiComponents, _emberServiceContainer, _stepsActions, _budgetActions, _lodash, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = BudgetStructure;
  var _excluded = ["getDataAction"];
  /* eslint-disable react-hooks/rules-of-hooks */
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function BudgetStructure(_ref) {
    var getDataAction = _ref.getDataAction,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
    var stepName = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var budgetName = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.name;
    });
    var budgetRootType = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.budgetRootType;
    });
    var _budgetStructure = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.budgetTree;
    });
    var selectedDimensions = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.selectedDimensions;
    });
    var dimensionUniqueValuesCounter = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.dimensionUniqueValuesCounter;
    });
    var _dimensionStructure = Object.keys(_reactRedux.default.useSelector(function (state) {
      return state.budgetStore.dimensionHierarchy;
    }) || {}).length ? _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.dimensionHierarchy;
    }) : selectedDimensions && selectedDimensions.reduce(function (dims, dim) {
      return dims[dim.id] = dimensionUniqueValuesCounter[dim.id] > 0 ? [{}] : [], dims;
    }, {}) || {};
    var _React$useState = _react.default.useState({}),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      budgetTree = _React$useState2[0],
      setBudgetTree = _React$useState2[1];
    var _React$useState3 = _react.default.useState(_budgetStructure),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      budgetStructure = _React$useState4[0],
      setBudgetStructure = _React$useState4[1];
    var _React$useState5 = _react.default.useState(0),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      maxLevelNumber = _React$useState6[0],
      setMaxLevelNumber = _React$useState6[1];
    var dispatch = _reactRedux.default.useDispatch();
    var options = {
      hideClose: true,
      height: "100%",
      width: "100%",
      className: "budget-structure-container"
    };
    var budgetProfitGroupsLength = _const.default.BUDGET_TYPE_TO_GROUPS[budgetRootType].profit.length;
    _react.default.useEffect(function () {
      var _getDataAction = getDataAction(stepName, {
          budgetRootType: budgetRootType
        }),
        _groupings = _getDataAction._groupings,
        _maxLevelNumber = _getDataAction._maxLevelNumber;
      setMaxLevelNumber(_maxLevelNumber);
      var typesSet = new Set(_groupings.map(function (grouping) {
        return grouping.type;
      }));
      var isPlaceHolderDefined = !Object.keys(_budgetStructure).length || !typesSet.has(_budgetStructure[0].id);
      var initialBudgetTree = Object.keys(_budgetStructure).length ? _budgetStructure.map(function (struc) {
        struc.preventDelete = typesSet.has(struc.id);
        if (struc.level === 0 && !isPlaceHolderDefined) {
          struc.parent = "0";
        }
        if (struc.level === 0 && !struc.preventDelete) {
          struc.isPlaceholder = true;
          struc.placeholderLabel = intl.t("generic-file.step-budget-tree.add-top-level-entity");
        }
        struc.level = isPlaceHolderDefined ? struc.level : struc.level + 1;
        return struc;
      }) : [{
        id: "0",
        value: null,
        isPlaceholder: true,
        placeholderLabel: intl.t("generic-file.step-budget-tree.add-top-level-entity"),
        label: null
      }].concat(_groupings.map(function (grouping) {
        return {
          id: grouping.type,
          value: grouping.type,
          label: grouping.text,
          parent: "0",
          level: 1,
          preventDelete: true
        };
      }));
      if (initialBudgetTree[0].preventDelete) {
        initialBudgetTree = [{
          id: "0",
          value: null,
          isPlaceholder: true,
          placeholderLabel: intl.t("generic-file.step-budget-tree.add-top-level-entity"),
          label: null
        }].concat(initialBudgetTree);
      }
      setBudgetTree(initialBudgetTree);
    }, []);
    var filterOptions = function filterOptions(dataSource, id) {
      var getStructureObjectName = function getStructureObjectName(dimensionId, level) {
        if (isNaN(level)) return null;
        var dimension = selectedDimensions.find(function (selectedDimension) {
          return selectedDimension.id === dimensionId;
        }).dimensionName;
        switch (level * 1) {
          case 0:
            return dimension;
          case 1:
            return "".concat(intl.t("generic-file.step-budget-tree.sub"), " ").concat(dimension);
          default:
            return "".concat(intl.t("generic-file.step-budget-tree.sub"), " ").concat(dimension, " ").concat(level);
        }
      };
      var isDimension = function isDimension(node) {
        return !isNaN(node.dimLevel) && node.value;
      };
      var getUsedDimensions = function getUsedDimensions(currentNode) {
        var exclusive = getExculsiveType(currentNode.id);
        var maxLevel = getRelativeMaxLevel(currentNode, !!exclusive);
        var nodeLastChildren = maxLevel === currentNode.level ? [currentNode] : dataSource.filter(function (node) {
          return node.level === maxLevel && node.id.includes(exclusive);
        });
        var dims = {};
        nodeLastChildren.forEach(function (node) {
          if (isDimension(node)) {
            if (!dims[node.value]) {
              dims[node.value] = [];
            }
            dims[node.value].push({
              dimLevel: node.dimLevel,
              level: node.level
            });
          }
          var parentId = node.parent;
          while (parentId) {
            var parentNode = dataSource.find(function (node) {
              return node.id === parentId;
            });
            if (isDimension(parentNode)) {
              if (!dims[parentNode.value]) {
                dims[parentNode.value] = [];
              }
              dims[parentNode.value].push({
                dimLevel: parentNode.dimLevel,
                level: parentNode.level
              });
            }
            parentId = parentNode.parent;
          }
        });
        return dims;
      };
      var getExculsiveType = function getExculsiveType(id) {
        if (!id) return "";
        var currentNode = dataSource.find(function (node) {
          return node.id === id;
        });
        if (currentNode.preventDelete) {
          return "-".concat(currentNode.id);
        } else {
          return getExculsiveType(currentNode.parent);
        }
      };
      var getRelativeMaxLevel = function getRelativeMaxLevel(node, isExclusive) {
        if (!node) return 0;
        if (!isExclusive) {
          return Math.max.apply(Math, (0, _toConsumableArray2.default)(Object.values(dataSource).map(function (dimension) {
            return dimension.level;
          })));
        }
        var childNode = dataSource.find(function (n) {
          return n.parent === node.id;
        });
        if (!childNode) {
          return node.level;
        }
        return getRelativeMaxLevel(childNode, isExclusive);
      };
      var options = [];
      var currentNode = dataSource.find(function (node) {
        return node.id === id;
      });
      var isPlaceHolderDefined = dataSource.some(function (node) {
        return node.isPlaceholder && node.value;
      });
      var exclusiveNodeId = getExculsiveType(id);
      var maxRelativeAbsoluteLevel = getRelativeMaxLevel(currentNode, !!exclusiveNodeId);
      var isExceedMaxLevel = maxLevelNumber - (isPlaceHolderDefined ? 1 : 0) <= maxRelativeAbsoluteLevel;
      var dimensionName = isDimension(currentNode) || currentNode.isPlaceholder ? currentNode.value : null;
      var children = dataSource.filter(function (node) {
        return node.parent === id;
      }) || [];
      children = children.concat(children.filter(function (child) {
        return !isDimension(child);
      }).map(function (node) {
        return dataSource.find(function (n) {
          return n.parent === node.id;
        });
      }).filter(function (n) {
        return n;
      }));
      var disabled = isExceedMaxLevel;
      if (isDimension(currentNode) || currentNode.isPlaceholder && currentNode.value) {
        var currentVisited = false;
        var numberOfDimensionLevels = _dimensionStructure[dimensionName] ? Math.max.apply(Math, (0, _toConsumableArray2.default)(Object.values(_dimensionStructure[dimensionName]).map(function (dimension) {
          return dimension.level || 0;
        }))) : -1;
        var _loop = function _loop() {
          var newId = "".concat(dimensionName, "-").concat(i).concat(exclusiveNodeId);
          var isChildOfCurrentNode = children.some(function (child) {
            return child.id === newId;
          });
          currentVisited = currentVisited || currentNode.id === newId || currentNode.id === "".concat(dimensionName, "-").concat(i);
          disabled = disabled || isChildOfCurrentNode || children.some(function (child) {
            return child.id.includes(newId);
          });
          options.push({
            id: newId,
            value: dimensionName,
            label: getStructureObjectName(dimensionName, i),
            parent: isChildOfCurrentNode ? id : null,
            isDisabled: !currentVisited || disabled || currentNode.id === newId || currentNode.id === "".concat(dimensionName, "-").concat(i),
            dimLevel: i
          });
        };
        for (var i = 0; i <= numberOfDimensionLevels; i++) {
          _loop();
        }
      }
      var usedDimensions = getUsedDimensions(currentNode);
      Object.keys(_dimensionStructure).filter(function (dimensionId) {
        return dimensionId !== dimensionName;
      }).forEach(function (dimensionId) {
        var numberOfDimensionLevels = _dimensionStructure[dimensionId] && _dimensionStructure[dimensionId].length ? Math.max.apply(Math, (0, _toConsumableArray2.default)(Object.values(_dimensionStructure[dimensionId]).map(function (dimension) {
          return dimension.level || 0;
        }))) + 1 : 0;
        numberOfDimensionLevels && (0, _toConsumableArray2.default)(Array(numberOfDimensionLevels).keys()).forEach(function (levelNumber) {
          disabled = isExceedMaxLevel;
          var newId = "".concat(dimensionId, "-").concat(levelNumber).concat(exclusiveNodeId);
          var isChildOfCurrentNode = children.some(function (child) {
            return child.id === newId;
          });
          disabled = disabled || usedDimensions[dimensionId] && (usedDimensions[dimensionId].some(function (used) {
            return used.level <= currentNode.level && used.dimLevel >= levelNumber;
          }) || usedDimensions[dimensionId].some(function (used) {
            return used.level >= currentNode.level && used.dimLevel <= levelNumber;
          }));
          options.push({
            id: newId,
            value: dimensionId,
            label: getStructureObjectName(dimensionId, levelNumber),
            parent: isChildOfCurrentNode ? id : null,
            isDisabled: disabled,
            dimLevel: levelNumber
          });
        });
      });
      return options.concat(children.filter(function (child) {
        return !isDimension(child);
      }));
    };
    var getCleanBudgetTreeForStore = function getCleanBudgetTreeForStore() {
      return budgetStructure.filter(function (node) {
        return node.value;
      }).map(function (_ref2) {
        var id = _ref2.id,
          label = _ref2.label,
          value = _ref2.value,
          level = _ref2.level,
          dimLevel = _ref2.dimLevel,
          parent = _ref2.parent;
        return {
          id: id,
          label: label,
          value: value,
          level: budgetStructure[0].isPlaceholder && budgetStructure[0].value ? level : level - 1,
          dimLevel: dimLevel,
          parent: parent === "0" ? null : parent
        };
      });
    };
    var isStructureMapped = function isStructureMapped() {
      return getCleanBudgetTreeForStore().length > budgetProfitGroupsLength;
    };
    var nextHandler = function nextHandler() {
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_BUDGET_STRUCTURE);
      var newBudgetTree = getCleanBudgetTreeForStore();
      if (!_lodash.default.isEqual(newBudgetTree, _budgetStructure)) {
        isStructureMapped() && trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.STRUCTURE_CREATED);
        dispatch(_budgetActions.default.setBudgetTree(newBudgetTree));
        rest.clearDependencies();
      }
      dispatch(_stepsActions.default.nextStep());
    };
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back border big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        var newBudgetTree = getCleanBudgetTreeForStore();
        if (!_lodash.default.isEqual(newBudgetTree, _budgetStructure)) {
          dispatch(_budgetActions.default.setBudgetTree(newBudgetTree));
          rest.clearDependencies();
        }
        dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: nextHandler,
      disabled: false
    }));
    var body = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("section", {
      className: "hierarchy-nav-container"
    }, _react.default.createElement("label", {
      className: "header-name large-text"
    }, "".concat(budgetName, " ").concat(intl.t("generic-file.step-budget-tree.structure")))), _react.default.createElement("section", {
      className: "hierarchy-tree-container"
    }, budgetTree && budgetTree.length ? _react.default.createElement(_uiComponents.default.COMPONENTS.TreeStructure, {
      NodeComponent: _uiComponents.default.COMPONENTS.TreeNodeCombo,
      filterOptions: filterOptions,
      dataSource: budgetTree,
      onChange: function onChange(tree) {
        return setBudgetStructure(tree);
      },
      showExpandAll: true,
      isMulti: false,
      isSearchable: false,
      keepOptionOnRemove: false,
      expandAllText: intl.t("uic.expand_all"),
      collapseAllText: intl.t("uic.collapse_all"),
      selectAllText: intl.t("uic.selectAllText"),
      clearAllText: intl.t("uic.clearAllText"),
      requiredMessage: intl.t("uic.required_field")
    }) : null));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        footer: footer,
        body: body
      },
      options: options
    });
  }
});