define("budgeta/templates/components/generic-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qSAdIv99",
    "block": "{\"statements\":[[\"append\",[\"unknown\",[\"model\"]],true]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/generic-modal.hbs"
    }
  });
});