define("budgeta/services/websocket/const", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MESSAGE_TYPE = _exports.LOGS = void 0;
  var MESSAGE_TYPE = _exports.MESSAGE_TYPE = {
    NEW_CONNECTION: "NEW_CONNECTION",
    REAL_TIME_COLLABORATION: {
      CONNECTED_USERS_CHANGED: "REAL_TIME_COLLABORATION_CONNECTED_USERS_CHANGED",
      SUBSCRIPTION_CHANGED: "REAL_TIME_COLLABORATION_SUBSCRIPTION_CHANGED"
    }
  };
  var LOGS = _exports.LOGS = {
    CONNECTION_ESTABLISHED: "Client socket | Connection established",
    RECIEVED_CONNECTION_ID: "Client socket | Connection Id",
    SOCKET_CLOSED: "Client socket | Closed",
    INIT_ERROR: "Client socket | Error to init: ",
    CONNECT_SERVER_ERROR: "Client socket | Error to connect to server socket: ",
    INIT_HANDLERS_ERROR: "Client socket | Error to init events handlers: ",
    ON_ERROR: "Client socket | On error: "
  };
});