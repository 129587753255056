define("budgeta/initializers/intl", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    var intlService = application.__container__.lookup("service:intl");
    new _emberServiceContainer.default().register("intl", intlService);
    intlService.setLocale(localStorage.getItem("locale") || "en-us");
  }
  var _default = _exports.default = {
    initialize: initialize,
    before: ["get-config"]
  };
});