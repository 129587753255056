define("budgeta/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lSbkxIJx",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"react-component\"],null,[[\"componentName\",\"identification\",\"restoreEmail\",\"signinButtonLabel\",\"password\",\"passcode\",\"showPasscode\",\"rememberChecked\",\"shouldResetPasswordLink\",\"subHeader\",\"notice\",\"errorMessage\",\"resetPassword\",\"submitNewPassword\",\"resendPasscode\",\"authenticate\",\"updateDataAction\",\"removeErrorMessage\",\"afterUserAuthenticated\"],[\"MainLogin\",[\"get\",[\"identification\"]],[\"get\",[\"restoreEmail\"]],[\"get\",[\"signinButtonLabel\"]],[\"get\",[\"password\"]],[\"get\",[\"passcode\"]],[\"get\",[\"showPasscode\"]],[\"get\",[\"remember\"]],[\"get\",[\"shouldResetPasswordLink\"]],[\"get\",[\"subtitle\"]],[\"get\",[\"notice\"]],[\"get\",[\"errorMessage\"]],[\"helper\",[\"action\"],[[\"get\",[null]],\"resetPassword\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"submitNewPassword\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"resendPasscode\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"authenticate\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"updateDataAction\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"removeErrorMessage\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"afterUserAuthenticated\"],null]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/login.hbs"
    }
  });
});