define("budgeta/components/admin-app-root", ["exports", "react", "budgeta/admin/admin-app/main/main", "react-router-dom"], function (_exports, _react, _main, _reactRouterDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = AdminAppRoot;
  //this is the root react component which holds the admin application
  function AdminAppRoot() {
    return _react.default.createElement(_reactRouterDom.default.BrowserRouter, null, _react.default.createElement("div", null, _react.default.createElement(_main.default, null)));
  }
});