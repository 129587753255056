define("budgeta/components/reports-modal", ["exports", "budgeta/components/modal-new"], function (_exports, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    classNames: ["modal-reports"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.resetSelection();
    },
    resetSelection: function resetSelection() {
      this.set("controllertmp.selectedMode", 1);
      this.get("controllertmp.reports").forEach(function (report) {
        Ember.set(report, "selected", false);
      });
    }
  });
});