define("budgeta/generic-file/components/generic-manager/GenericManager", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/slicedToArray", "react", "react-redux", "@sibp/ui-components", "budgeta/admin/store/actions/notifActions", "budgeta/services/ember-service-container", "budgeta/generic-file/components/loader-screen/loader-screen", "budgeta/store/actions/fileActions", "budgeta/store/actions/budgetActions", "budgeta/store/actions/stepsActions", "budgeta/generic-file/components/generic-manager/stepMap"], function (_exports, _objectSpread2, _slicedToArray2, _react, _reactRedux, _uiComponents, _notifActions, _emberServiceContainer, _loaderScreen, _fileActions, _budgetActions, _stepsActions, _stepMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var GenericManager = function GenericManager(props) {
    var dispatch = _reactRedux.default.useDispatch();

    //need to set Provider
    var currentStep = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var showLoader = _reactRedux.default.useSelector(function (state) {
      return state.notifStore.showLoader;
    });
    var noty = _reactRedux.default.useSelector(function (state) {
      return state.notifStore.noty;
    });
    var _React$useState = _react.default.useState(currentStep),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      _currentStep = _React$useState2[0],
      setCurrentStep = _React$useState2[1];
    var CurrentComp = (0, _stepMap.getCurrentStep)(currentStep);
    var closeNoty = function closeNoty() {
      dispatch(_notifActions.default.hideNoty());
    };
    _react.default.useEffect(function () {
      setCurrentStep(currentStep);
    }, [CurrentComp]);
    _react.default.useEffect(function () {
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.OPEN_WIZARD);
    }, []);
    var clearDependencies = function clearDependencies() {
      var dependencies = (0, _stepMap.getDependencies)();
      dispatch(_fileActions.default.clearDependencies(dependencies.fileStore));
      dispatch(_budgetActions.default.clearDependencies(dependencies.budgetStore));
      dispatch(_stepsActions.default.clearDependencies(dependencies.stepsStore));
    };
    return _react.default.createElement("div", {
      className: "generic-manager"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Loader, {
      visible: showLoader
    }), _react.default.createElement(_loaderScreen.default, {
      visible: _currentStep !== currentStep,
      text: intl.t("generic-file.load-screen.label")
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Noty, {
      text: noty.text,
      visible: noty.visible,
      notiContent: noty.notiContent,
      errorLevel: noty.errorLevel,
      onClose: closeNoty
    }), _react.default.createElement(CurrentComp, (0, _objectSpread2.default)((0, _objectSpread2.default)({}, props), {}, {
      clearDependencies: clearDependencies
    })));
  };
  var _default = _exports.default = GenericManager;
});