define("budgeta/components/react/synced-users-menu/circlesWithTooltip", ["exports", "@sibp/ui-components", "react"], function (_exports, _uiComponents, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MARGIN_LEFT_PIXELS = 6;
  var CirclesWithTooltip = function CirclesWithTooltip(_ref) {
    var users = _ref.users;
    return _react.default.createElement("div", {
      className: "displayed-users"
    }, users.map(function (item, idx) {
      var divStyle = {
        position: "relative",
        right: idx === 0 ? 0 : idx * MARGIN_LEFT_PIXELS
      };
      return _react.default.createElement("div", {
        key: item.id,
        style: divStyle
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Tooltip, {
        hook: item.initials,
        tooltipClassName: "circle-tooltip",
        key: item.id,
        direction: "bottom",
        forcePlacement: "bottom",
        iconName: _react.default.createElement(_uiComponents.default.COMPONENTS.CircleWithText, {
          label: item.initials,
          className: "displayed-user"
        }),
        text: item.fullName,
        tooltipParentSelector: "body",
        elementFadeSeconds: 50
      }));
    }));
  };
  var _default = _exports.default = CirclesWithTooltip;
});