define("budgeta/templates/components/share-expand-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TGgCP1Bt",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"show\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"name\"],[\"expand-trigger\",\"arrow_down\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/share-expand-trigger.hbs"
    }
  });
});