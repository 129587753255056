define("budgeta/templates/components/employees-table-location-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rxpOFB7b",
    "block": "{\"statements\":[[\"block\",[\"power-select-lazy\"],null,[[\"component\",\"selected\",\"options\",\"readOnly\",\"optionValuePath\",\"optionLabelPath\"],[\"path-list-element\",[\"get\",[\"location\"]],[\"get\",[\"employeeLocations\"]],[\"get\",[\"disable\"]],\"id\",\"text\"]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/employees-table-location-cell.hbs"
    }
  });
});