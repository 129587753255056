define("budgeta/services/navigator-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    dashboard: undefined,
    dashboardStatus: {
      base: {},
      live: {}
    },
    isBaseDashboard: Ember.computed("dashboard.dashboardType", function () {
      return this.get("dashboard.dashboardType") === "base";
    }),
    isInteractiveDashboard: Ember.computed("isBaseDashboard", function () {
      return !this.get("isBaseDashboard");
    }),
    changeDashboardView: function changeDashboardView(dashboardItem) {
      // if (this.get('isBaseDashboard')) {
      //   this.set('dashboardStatus.base', {
      //     subsidiary: this.get('dashboard.subsidiary'),
      //     scenario: this.get('dashboard.scenario'),
      //     dashboardMode: this.get('dashboard.dashboardMode'),
      //     isScenarioOnly: this.get('dashboard.isScenarioOnly')
      //   });
      // } else if (this.get('isInteractiveDashboard')) {

      if (!dashboardItem.isSelected) {
        if (dashboardItem.type === "budget") {
          this.get("dashboard").setProperties({
            subsidiary: this.get("dashboardStatus.base.subsidiary"),
            scenario: this.get("dashboardStatus.base.scenario"),
            dashboardMode: this.get("dashboardStatus.base.dashboardMode"),
            isScenarioOnly: this.get("dashboardStatus.base.isScenarioOnly")
          });
        } else if (dashboardItem.type === "interactive") {
          this.get("dashboard").setProperties({
            subsidiary: "*",
            scenario: null,
            dashboardMode: "budget",
            isScenarioOnly: "scenario"
          });
        }
        // this.get('dashboard').send('change')
      }

      // }
    },
    recalcDashboard: function recalcDashboard() {
      this.get("dashboard").send("recalcDashboardAction");
    }
  });
});