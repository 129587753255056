define("budgeta/services/change-log-service", ["exports", "@babel/runtime/helpers/esm/typeof", "budgeta/utils/const", "budgeta/utils/dimensions"], function (_exports, _typeof2, _const, _dimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    getDimensionChanges: function getDimensionChanges(change, what, rootDimensions, budgetDimensions) {
      var isDeletedDimension = !change.after && !change.before && what.length >= 2;
      if (isDeletedDimension) {
        return this.getDeletedDimensionChange(what, rootDimensions, budgetDimensions);
      } else {
        return this.getDimensionChangeLog(change, rootDimensions);
      }
    },
    getDimensionChangeLog: function getDimensionChangeLog(change, rootDimensions) {
      var isChangeEmpty = change.after && (0, _typeof2.default)(change.after) === "object" && _const.default.isEmptyObject(change.after.values);
      if (isChangeEmpty) {
        return null;
      }
      var dimensionDataAfterChange = this.getDimensionChangeObject(change, "after", rootDimensions);
      if (dimensionDataAfterChange) {
        return {
          what: dimensionDataAfterChange.name,
          before: "",
          after: dimensionDataAfterChange.values.join(",")
        };
      }
      var dimensionDataBeforeChange = this.getDimensionChangeObject(change, "before", rootDimensions);
      if (dimensionDataBeforeChange) {
        return {
          what: dimensionDataBeforeChange.name,
          before: dimensionDataBeforeChange.values.join(","),
          after: ""
        };
      }
      return null;
    },
    getDimensionChangeObject: function getDimensionChangeObject(change, beforeOrAfter, rootDimensions) {
      var dimensionNameChange = this.getDimensionNameByChange(change, beforeOrAfter, rootDimensions);
      var dimensionValueChange = this.getDimensionValuesByChange(change, beforeOrAfter, rootDimensions);
      if (dimensionNameChange && dimensionValueChange) {
        return {
          name: dimensionNameChange,
          values: dimensionValueChange
        };
      } else {
        return null;
      }
    },
    getDimensionNameByChange: function getDimensionNameByChange(change, beforeOrAfter, rootDimensions) {
      // change[beforeOrAfter] can be:
      // an object with a dimension property that contains the dimensionId.
      // or a string.
      // If it's a string, this is the valueId.
      var changeData = change[beforeOrAfter];
      var isDimensionIdExistInChange = changeData && (0, _typeof2.default)(changeData) === "object";
      if (isDimensionIdExistInChange) {
        var dimensionIdChange = changeData.dimension;
        var dimensionIdToName = _dimensions.default.getDimensionIdToName(rootDimensions);
        return dimensionIdToName[dimensionIdChange];
      } else {
        // get dimension id by it's value id
        var valueId = changeData;
        var valueIdToValueNameAndDimensionName = _dimensions.default.getValueIdToValueNameAndDimensionName(rootDimensions);
        return valueIdToValueNameAndDimensionName[valueId] && valueIdToValueNameAndDimensionName[valueId].dimensionName;
      }
    },
    getDimensionValuesByChange: function getDimensionValuesByChange(change, beforeOrAfter, rootDimensions) {
      // change[beforeOrAfter] can be:
      // an array of value ids
      // or a valueId
      var changeData = change[beforeOrAfter];
      var valueIdToValueNameAndDimensionName = _dimensions.default.getValueIdToValueNameAndDimensionName(rootDimensions);
      var isChangeContainsValues = changeData && !_const.default.isEmptyObject(changeData.values);
      if (isChangeContainsValues) {
        var valuesIds = changeData.values.map(function (o) {
          return o.value;
        });
        return valuesIds.map(function (id) {
          var valueObj = valueIdToValueNameAndDimensionName[id] || {};
          return valueObj.name;
        });
      } else {
        var valueObj = valueIdToValueNameAndDimensionName[changeData];
        return valueObj && [valueObj.name];
      }
    },
    getDeletedDimensionChange: function getDeletedDimensionChange(what, rootDimensions, budgetDimensions) {
      var deletedDimension = this.getDeletedDimensionName(what, rootDimensions, budgetDimensions);
      if (deletedDimension) {
        return {
          what: deletedDimension,
          before: "",
          after: ""
        };
      } else {
        return null;
      }
    },
    getDeletedDimensionName: function getDeletedDimensionName(dimensionSplittedPath, rootDimensions, budgetDimensions) {
      var dimensionIdToName = _dimensions.default.getDimensionIdToName(rootDimensions);
      var dimensionIndex = dimensionSplittedPath && dimensionSplittedPath[1]; // it will be dimensions,1,values,0
      var budgetAttributesDimensions = budgetDimensions || [];
      var deletedDimensionObj = budgetAttributesDimensions[dimensionIndex] || {};
      var deletedDimensionId = deletedDimensionObj.dimension;
      var deletedDimension = dimensionIdToName[deletedDimensionId];
      return deletedDimension || null; // we return null if the dimension was deleted from the root (we will ignore this change)
    }
  });
});