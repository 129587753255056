define("budgeta/templates/components/intacct-emp-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/Km+ElfT",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"data\",\"length\"]]],null,2,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"t\"],[\"intacct-emp-map.loading\"],null],true],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"intacct-emp-map-row\"],null,[[\"emp\",\"hasLocations\",\"hasDepartments\",\"data\"],[[\"get\",[\"emp\"]],[\"get\",[\"hasLocations\"]],[\"get\",[\"hasDepartments\"]],[\"get\",[\"mappingContent\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[\"emp\"]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"scrollable disable-more-btn\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"data\"]]],null,1],[\"text\",\"  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/intacct-emp-map.hbs"
    }
  });
});