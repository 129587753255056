define("budgeta/routes/user-profile", ["exports", "budgeta/mixins/budgeta-route", "ember-simple-auth/mixins/authenticated-route-mixin", "budgeta/utils/const"], function (_exports, _budgetaRoute, _authenticatedRouteMixin, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _budgetaRoute.default, {
    session: Ember.inject.service(),
    model: function model() {
      return Ember.Object.create(this.get("session.data.currentUser"));
    },
    setupController: function setupController(controller, model) {
      controller.set("showIntacctAPISetttings", _const.default.INTACCT_API_ALLOW_OVERRIDE);
      if (!controller.get("positions").mapBy("id").includes(model.get("position"))) {
        model.set("position", null);
      }
      if (_const.default.INTACCT_API_ALLOW_OVERRIDE) {
        controller.set("apiSettings", {
          apiHost: localStorage.getItem("SI_API_HOST") || "www.intacct.com",
          apiPath: localStorage.getItem("SI_API_PATH") || "/ia/xml/xmlgw.phtml",
          apiSender: localStorage.getItem("SI_API_SENDER"),
          apiPassword: localStorage.getItem("SI_API_PASSWORD")
        });
      }
      this._super(controller, model);
    },
    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        this.set("controller.errorMessage", null);
        this.set("controller.successMessage", null);
        Ember.run.scheduleOnce("afterRender", function () {
          var emptyInput = Ember.$("input.ember-text-field").filter(function () {
            return !this.value;
          });
          emptyInput.first().focus();
        });
      }
    }
  });
});