define("budgeta/controllers/admin-app-route", ["exports", "react", "react-dom", "budgeta/components/admin-app-root"], function (_exports, _react, _reactDom, _adminAppRoot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init: function init() {
      Ember.run.next(function () {
        var root = document.querySelector("#admin-app-root");
        if (root) {
          var container = _reactDom.default.createRoot(root);
          container.render(_react.default.createElement(_adminAppRoot.default, null));
        } else {
          noty({
            type: "error",
            text: "admin-app-root element not found"
          });
        }
      });
    }
  });
});