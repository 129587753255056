define("budgeta/components/advanced-benefits", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/budget-utils", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _budgetUtils, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ["advanced-benefits"],
    inheritedBenefits: Ember.computed("model", "model.absoluteRoot.benefits.length", "model.parent.benefits.length", "model.budgetType", "budgetService.scenario", "parent.budgetAttributes.deletedBenefits.length", "budgetAttributes.deletedBenefits.length", function () {
      return this.get("model") && _budgetUtils.default.getInheritedBenefits(this.get("model"), {
        markDeleted: true,
        scenario: this.get("budgetService.scenario")
      }) || [];
    }),
    filteredInheritedBenefits: Ember.computed("model.benefits.length", "inheritedBenefits", "inheritedBenefits.@each.name", "inheritedBenefits.@each.isBenefitDeleted", "model.benefits.@each.name", function () {
      var _this2 = this;
      var benefits = this.get("model.benefits");
      if (benefits) {
        benefits.setEach("hasInherited", false);
      }
      return this.get("inheritedBenefits").filter(function (b) {
        if ((_this2.get("budgetAttributes.deletedBenefits") || []).includes(b.get("uniqueId"))) {
          b.set("isBenefitDeleted", true);
        }
        var found = benefits.findBy("name", b.get("name"));
        if (found) {
          Ember.set(found, "hasInherited", b);
        } else {
          var title = [b.get("name"), "(" + _this2.get("intl").t("advanced-benefits.from"), (b.get("parent.parent") ? b.get("parent.name") : _this2.get("intl").t("advanced-benefits.settings")) + ")"];
          Ember.set(b, "title", title.join(" "));
        }
        return !found;
      }).sortBy("name");
    }),
    sortedBenefits: Ember.computed("model.benefits.length", "inheritedBenefits", "inheritedBenefits.@each.name", "inheritedBenefits.@each.isBenefitDeleted", function () {
      var benefits = _budgetUtils.default.getBenefits(this.get("model"), {
        scenario: this.get("scenario") || this.get("budgetService.scenario")
      });
      return (benefits || Ember.A([])).sortBy("name");
    }),
    editBenefit: function editBenefit(benefit, deleteOnCancel, inheritedFrom) {
      this.get("mainController").send("editBenefitLine", benefit, deleteOnCancel, inheritedFrom, this.get("scenario"));
    },
    deleteInheritedBenefit: function deleteInheritedBenefit(benefit) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var noSave = options.noSave;
      if (!this.get("budgetAttributes.deletedBenefits")) {
        this.set("budgetAttributes.deletedBenefits", []);
      }
      this.get("budgetAttributes.deletedBenefits").addObject(benefit.get("uniqueId"));
      if (!noSave) {
        this.sendAction("changed");
        this.get("mainController").send("submit");
      }
    },
    actions: {
      addBenefit: function addBenefit() {
        this.get("mainController").send("new", this.get("model"), "expense-benefit", false, false, intl.t("general.new_benefit_1698234255209"), undefined, undefined, undefined, {
          dontSave: true,
          noTransition: true,
          scenario: this.get("scenario")
        }, "editBenefitLine");
      },
      editBenefit: function editBenefit(benefit) {
        this.editBenefit(benefit);
      },
      duplicateBenefit: function duplicateBenefit(benefit) {
        this.get("mainController").send("duplicate", benefit, false, true, undefined, undefined, undefined, undefined, undefined, this.get("scenario"));
      },
      deleteBenefit: function deleteBenefit(benefit) {
        var _this3 = this;
        this.get("mainController").send("delete", benefit, undefined, undefined, undefined, undefined, this.get("scenario"));
        if (Ember.get(benefit, "hasInherited")) {
          Ember.run.next(function () {
            _this3.deleteInheritedBenefit(Ember.get(benefit, "hasInherited"), {
              noSave: true
            });
          });
        }
      },
      deleteBenefitNoConfirm: function deleteBenefitNoConfirm(benefit) {
        this.get("mainController").send("delete", benefit, true, undefined, undefined, undefined, this.get("scenario"));
      },
      mapBenefit: function mapBenefit(benefit) {
        this.get("mainController").send("showLineDependencies", benefit);
      },
      deleteInheritedBenefit: function deleteInheritedBenefit(benefit) {
        this.deleteInheritedBenefit(benefit);
      },
      restoreInheritedBenefit: function restoreInheritedBenefit(benefit) {
        if (this.getWithDefault("budgetAttributes.deletedBenefits", []).includes(benefit.get("uniqueId"))) {
          this.get("budgetAttributes.deletedBenefits").removeObject(benefit.get("uniqueId"));
          this.sendAction("changed");
          this.get("mainController").send("submit");
        } else {
          noty({
            type: "warning",
            text: intl.t("general.advanced-benefits-1", {
              var1: this.get("model.parent.uniqueName")
            })
          });
        }
      },
      duplicateInheritedBenefit: function duplicateInheritedBenefit(benefit) {
        var _this4 = this;
        this.get("mainController").send("copy", benefit, this.get("model"), function (copy, _this) {
          var name = _const.default.getUniqueName(_this4.get("model"), intl.t("general.advanced-benefits-2", {
            var1: copy.get("name")
          }));
          copy.set("name", name);
          copy.save();
          _this.controllerFor("main.budget.index").send("renderPreviewUnthrottled");
        });
      },
      editInheritedBenefit: function editInheritedBenefit(benefit) {
        var _this5 = this;
        this.get("mainController").send("copy", benefit, this.get("model"), function (copy) {
          _this5.editBenefit(copy, true, benefit);
        });
      }
    }
  });
});