define("budgeta/popup-menu/flows/budgeta-down-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    return this.orientBelow.andSnapTo(this.rightEdge);
  }
});