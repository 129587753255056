define("budgeta/initializers/oauth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Authenticator from 'simple-auth-oauth2/authenticators/oauth2';
  var _default = _exports.default = {
    name: "oauth",
    initialize: function initialize() {
      /*	Authenticator.reopen({
      makeRequest: function(url, data) {
       data.client_id = 'sibp/webapp';
       data.client_secret = 'none';
       return this._super(url, data);
      }
      });
      */
    } /* application */
  };
});