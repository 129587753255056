define("budgeta/components/import-column-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["import-column-menu"],
    classNameBindings: ["clicked:blue-dots"],
    attributeBindings: ["data-hook"],
    trackingEvents: Ember.inject.service(),
    "data-hook": "import-column-menu",
    click: function click() {
      this.set("clicked", true);
    },
    dropDownClassNames: Ember.computed("dropDownWide", function () {
      var names = ["import-column-dropdown", "drop-down", "no-padding"];
      if (this.get("dropDownWide")) {
        names.push("wide");
      }
      return names;
    }),
    actions: {
      displayTagged: function displayTagged() {
        this.get("displayTagged")();
      },
      fiGrid: function fiGrid() {
        if (this.get("fitGridEnabled")) {
          this.get("fitGrid")();
        }
      },
      toggleFilter: function toggleFilter() {
        this.get("changeFiltersView")();
      },
      toggleBulkAction: function toggleBulkAction() {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_BULK_ACTION"));
        this.get("changeBulkOption")();
      },
      toggleBulkSelection: function toggleBulkSelection() {
        this.get("changeBulkSelectionOption")();
      },
      toggleShowDifferences: function toggleShowDifferences() {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_SHOW_DIFFERENCES"));
        this.get("changeDifferencesOption")();
      },
      deleteFilters: function deleteFilters() {
        this.get("clearFilters")();
      },
      closedDropDown: function closedDropDown() {
        this.set("clicked", false);
      }
    }
  });
});