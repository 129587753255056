define("budgeta/components/main/budget/grid-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    treeManager: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    cache: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    displayedTreeItems: Ember.computed.alias("treeManager.displayedTreeItems"),
    selectedBudgetId: Ember.computed.alias("budgetService.selectedBudget.id"),
    cacheUpdatedTime: Ember.computed.alias("cache.cacheUpdatedTime"),
    scenario: Ember.computed.alias("budgetService.scenario"),
    isLocked: Ember.computed.alias("budgetService.selectedRoot.absoluteRoot.isLocked")
  });
});