define("budgeta/templates/components/forecast-past-data-budget-only-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GODLqSQQ",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"showActuals\"]]],null,7,3]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"value\"]],[\"get\",[\"displayValue\",\"currency\"]],null,[\"get\",[\"precision\"]],[\"get\",[\"showNegativeAsPositive\"]],\"column\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"column\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"displayValue\",\"hide\"]]],null,1,0]],\"locals\":[\"displayValue\"]},{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"pastDataAndBudget\",\"budget\"]]],null,2]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"convertedActualValue\"]],[\"get\",[\"displayValue\",\"currency\"]],null,[\"get\",[\"precision\"]],[\"get\",[\"showNegativeAsPositive\"]],\"column\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"column\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"displayValue\",\"hide\"]]],null,5,4]],\"locals\":[\"displayValue\"]},{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"pastDataAndBudget\",\"past\"]]],null,6]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/forecast-past-data-budget-only-row.hbs"
    }
  });
});