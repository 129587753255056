define("budgeta/services/audit-logging/audit-logging-const", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ENTITY_REF = _exports.APP_ID = void 0;
  var APP_ID = _exports.APP_ID = Object.freeze({
    SIBP: "SIBP",
    ADMIN_CONSOLE: "admin_console",
    COMPANY_ADMIN: "company-admin-app"
  });
  var ENTITY_REF = _exports.ENTITY_REF = Object.freeze({
    USER: "User",
    BUDGET: "Budget"
  });
});