define("budgeta/initializers/ember-validations", ["exports", "ember-validations/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // https://budgeta.atlassian.net/browse/SIM-192

  function initialize( /* application */
  ) {
    _errors.default.reopen({
      unknownProperty: function unknownProperty(property) {
        this[property] = Ember.A();
        return Ember.get(this, property);
      }
    });
  }
  var _default = _exports.default = {
    name: "ember-validations",
    initialize: initialize
  };
});