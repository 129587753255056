define("budgeta/components/intacct-account-list", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/no-observers */
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":view-table-edit", ":intacct-account-list"],
    showMapping: Ember.computed.equal("step.id", "accounts"),
    accountData: Ember.computed.alias("data.accounts"),
    open: true,
    disableHide: Ember.computed("filtereredAccountsSelected.length", function () {
      return this.get("filtereredAccountsSelected.length") ? true : false;
    }),
    filtereredAccounts: Ember.computed("accountData.length", "showMapping", function () {
      if (this.get("showMapping") && this.get("accountData")) {
        var mappedAccountData = this.get("accountData").filter(function (a) {
          return !a.isMappedByCategory;
        });
        if (mappedAccountData.length) {
          return mappedAccountData;
        }
      }
      return this.get("accountData");
    }),
    filtereredAccountsSelected: Ember.computed("filtereredAccounts.length", "filtereredAccounts.@each.checked", function () {
      var selected = this.get("filtereredAccounts").filterBy("checked");
      if (!selected.length) {
        var target = Ember.$("#merge-group");
        var api = target ? target.qtip("api") : null;
        if (api) {
          api.hide("externalHide");
        }
      }
      return selected;
    }),
    mapOptions: [{
      id: "rev",
      text: intl.t("general.title_1695611597810")
    }, {
      id: "cogs",
      text: intl.t("general.text_1695611609008")
    }, {
      id: "exp",
      text: intl.t("general.title_1695611597727")
    }, {
      id: "other-rev",
      text: intl.t("general.text_1695611608987")
    }, {
      id: "other",
      text: intl.t("general.text_1695611608976")
    }],
    newGroupName: "",
    toggleAll: false,
    toggleAllObserver: Ember.observer("toggleAll", function () {
      this.get("accountData").setEach("checked", this.get("toggleAll"));
    }),
    groupNames: [],
    groupNamesRecalc: Ember.observer("accountData.@each.groupName", function () {
      var result = this.get("includeEmployees") ? ["Salary & Wages"] : [];
      (this.get("accountData") || []).forEach(function (item) {
        var thisGroup = Ember.get(item, "groupName");
        if (thisGroup) {
          result.addObject(thisGroup);
        }
      });
      this.get("groupNames").setObjects(result.sort().map(function (item) {
        return Ember.Object.create({
          name: item,
          value: item,
          checked: false
        });
      }));
    }).on("init"),
    columnsLayout: [100],
    tableStyle: Ember.computed("listHeight", function () {
      return Ember.String.htmlSafe("height:".concat(this.get("listHeight"), "px;position:relative;"));
    }),
    listHeight: Ember.computed("showMapping", function () {
      return this.get("showMapping") ? 312 : 311;
    }),
    missingGroupSelection: Ember.computed("groupNames.@each.checked", "newGroupName", function () {
      return !(this.get("newGroupName") || this.get("groupNames").findBy("checked"));
    }),
    change: function change(e) {
      var el = Ember.$(e.target);
      if (el.closest(".table-edit-line").find('input[type="checkbox"]').is(":checked")) {
        // make the same change for all selected lines
        var key = el.closest(".column").data("key");
        var val = el.val();
        if (key) {
          this.get("accountData").filterBy("checked").setEach(key, val);
          this.get("accountData").filterBy("checked").setEach("checked", false);
          if (this.get("toggleAll")) {
            this.set("toggleAll", false);
          }
        }
      }
    },
    click: function click(e) {
      var target = Ember.$(e.target);
      var wrapper = target.closest(".budgeta-checkbox");
      if (e.shiftKey && wrapper.length && !wrapper.find('input[type="checkbox"]').is(":checked")) {
        // find the last selected line and select all the lines in between
        wrapper.closest(".ember-list-view.ember-list-view-list > div > div > div").prevUntil(":has(:checked)").each(function (idx, line) {
          Ember.$(line).find(".budgeta-checkbox label").click();
        });
      }
    },
    actions: {
      mergeGroups: function mergeGroups() {
        var target = Ember.$("#merge-group");
        var api = target ? target.qtip("api") : null;
        var groupNames = this.get("groupNames");
        var newName = groupNames.findBy("checked") ? Ember.get(groupNames.findBy("checked"), "name") : this.get("newGroupName");
        var mergeGroups = this.get("filtereredAccountsSelected");
        mergeGroups.setEach("groupName", newName);
        mergeGroups.setEach("checked", false);
        this.set("newGroupName", "");
        groupNames.setEach("checked", false);
        if (this.get("toggleAll")) {
          this.set("toggleAll", false);
        }
        if (api) {
          api.hide("externalHide");
        }
      },
      addNewGroupName: function addNewGroupName(newValue) {
        if (!newValue) {
          return;
        }
        this.get("groupNames").setEach("checked", false);
        this.get("groupNames").insertAt(0, Ember.Object.create({
          name: newValue,
          value: newValue,
          checked: true
        }));
        this.set("newGroupName", "");
      }
    }
  });
});