define("budgeta/components/dimension-tree-item-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    menuTriggerId: Ember.computed("elementId", function () {
      return this.get("elementId") + "-menu-trigger";
    }),
    click: function click() {
      if (!this.get("clicked")) {
        this.set("clicked", true);
      }
    },
    actions: {
      rename: function rename(item) {
        this.sendAction("rename", item);
      },
      delete: function _delete(item) {
        this.sendAction("delete", item);
      },
      openDimensionSettings: function openDimensionSettings(item) {
        this.sendAction("openDimensionSettings", item);
      }
    }
  });
});