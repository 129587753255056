define("budgeta/utils/intacct-export", ["exports", "budgeta/utils/intacct", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/utils/forecast", "lodash/lodash", "budgeta/utils/wait-for-job", "budgeta/utils/assumption"], function (_exports, _intacct, _const, _budgetUtils, _forecast, _lodash, _waitForJob, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/new-module-imports */

  var IntacctExport = {
    getBudgetDetails: function getBudgetDetails(loginData) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return new Ember.RSVP.Promise(function (resolve) {
        var budgetId = options.budgetId,
          startDate = options.startDate,
          endDate = options.endDate,
          accounttypes = options.accounttypes,
          dimensionsAPIFields = options.dimensionsAPIFields;
        _intacct.default.getData(loginData, "getBudgetDetails", {
          budgetId: budgetId,
          startDate: startDate,
          endDate: endDate,
          accounttypes: accounttypes,
          dimensions: dimensionsAPIFields.join(",")
        }).then(function (data) {
          if (data.jobId) {
            // wait for the job to complete
            (0, _waitForJob.default)(data.jobId).then(function (budgets) {
              if (budgets.length) {
                resolve(budgets);
              } else {
                resolve();
              }
            });
          }
        });
      });
    },
    createBudgetDetail: function createBudgetDetail(options) {
      var loginData = options.loginData,
        intacctBudgetId = options.intacctBudgetId,
        reportingPeriods = options.reportingPeriods,
        data = options.data,
        recordsMap = options.recordsMap,
        detailsMap = options.detailsMap,
        recordNo = options.recordNo,
        selectedPeriod = options.selectedPeriod,
        excludeRetainedEarnings = options.excludeRetainedEarnings,
        bsAccounts = options.bsAccounts,
        pnlAccounts = options.pnlAccounts,
        statAccounts = options.statAccounts,
        actualsMapping = options.actualsMapping,
        budgetService = options.budgetService,
        cacheService = options.cacheService,
        store = options.store,
        versionId = options.versionId,
        selectedEntity = options.selectedEntity,
        entities = options.entities,
        locationValues = options.locationValues,
        isMultiCurrency = options.isMultiCurrency,
        isConsolidated = options.isConsolidated,
        exportInAddReplaceMode = options.exportInAddReplaceMode,
        consolidationCurrency = options.consolidationCurrency,
        model = options.model,
        linkingMappingTableFromBudgetLine = options.linkingMappingTableFromBudgetLine,
        selectedDimensions = options.selectedDimensions,
        tasks = options.tasks,
        intacctApiDimensionsFieldsMap = options.intacctApiDimensionsFieldsMap;
      var chunkSize = _const.default.EXPORT_CHUNK_SIZE ? _const.default.EXPORT_CHUNK_SIZE * 1 : 10000;
      var handleChunk = function handleChunk(chunk) {
        return function () {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            _intacct.default.getData(loginData, "createRecords", {
              recordType: "glbudgetheader",
              recordNo: recordNo,
              data: chunk,
              budgetId: Ember.get(budgetService, "selectedRoot.absoluteRoot.id"),
              exportType: model.exportSelection,
              selectedSIbudget: model.intacctBudgetId
            }).then(function (data) {
              if (data.jobId) {
                // wait for the job to complete
                (0, _waitForJob.default)(data.jobId).then(resolve, reject);
              }
            });
          });
        };
      };
      var getDetailsChunks = function getDetailsChunks(details, chunkSize) {
        var checkKeys = function checkKeys(obj1, obj2) {
          for (var index = 0; index < Object.keys(obj1).length; index++) {
            var key = Object.keys(obj1)[index];
            if (key !== "EXTERNALID" && key !== "NOTE" && key !== "AMOUNT" && key !== "RECORDNO" && key !== "PERIODNAME" && key !== "budgetaKey" && obj1[key] !== obj2[key]) {
              return false;
            }
          }
          return true;
        };
        var chunks = _lodash.default.chunk(details, chunkSize);
        for (var i = 0; i < chunks.length - 1; i++) {
          var firstChunkArray = chunks[i];
          var secondChunkArray = chunks[i + 1];
          var chunkLastObj = firstChunkArray[firstChunkArray.length - 1];
          var chunkFirstObj = secondChunkArray[0];
          if (chunkLastObj && chunkFirstObj && checkKeys(chunkFirstObj, chunkLastObj)) {
            var numberOfRecordsToMove = 1;
            var isStillSameObj = true;
            for (var index = 1; index < secondChunkArray.length && isStillSameObj; index++) {
              isStillSameObj = checkKeys(chunkFirstObj, secondChunkArray[index]);
              if (isStillSameObj) {
                numberOfRecordsToMove++;
              }
            }
            chunks[i] = firstChunkArray.concat(chunks[i + 1].splice(0, numberOfRecordsToMove));
          }
        }
        return chunks.filter(function (chunk) {
          return chunk.length;
        });
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        for (var report in data) {
          var details = {};
          var p0 = performance.now();
          IntacctExport.convertDataToBudgetDetails({
            result: details,
            root: data[report].root,
            data: data[report].root,
            intacctBudgetId: intacctBudgetId,
            reportingPeriods: reportingPeriods,
            selectedPeriod: selectedPeriod,
            excludeRetainedEarnings: excludeRetainedEarnings,
            bsAccounts: bsAccounts,
            pnlAccounts: pnlAccounts,
            statAccounts: statAccounts,
            actualsMapping: actualsMapping,
            budgetService: budgetService,
            cacheService: cacheService,
            versionId: versionId,
            store: store,
            selectedEntity: selectedEntity,
            entities: entities,
            locationValues: locationValues,
            isMultiCurrency: isMultiCurrency,
            isConsolidated: isConsolidated,
            consolidationCurrency: consolidationCurrency,
            linkingMappingTableFromBudgetLine: linkingMappingTableFromBudgetLine,
            selectedDimensions: selectedDimensions,
            exportInAddReplaceMode: exportInAddReplaceMode,
            model: model,
            recordsMap: recordsMap,
            detailsMap: detailsMap,
            intacctApiDimensionsFieldsMap: intacctApiDimensionsFieldsMap
          });
          var p1 = performance.now() - p0;
          Ember.Logger.debug("createBudgetDetails : ".concat(p1));
          if (Object.keys(details).length) {
            var newDetails = [];
            for (var key in details) {
              var values = details[key].values;
              if (details[key].sort) {
                values = values.sortBy("budgetaKey");
              }
              newDetails = newDetails.concat(values);
            }
            if (detailsMap) {
              for (var _key in detailsMap) {
                detailsMap[_key].AMOUNT = 0;
                newDetails.push(detailsMap[_key]);
              }
            }
            Ember.Logger.debug("export results : ".concat(newDetails.length));
            var chunks = getDetailsChunks(newDetails, chunkSize);
            tasks.pushObjects(chunks.map(handleChunk));
          }
        }
        _const.default.promiseSerial(tasks, 1).then(resolve, reject);
      });
    },
    convertDataToBudgetDetails: function convertDataToBudgetDetails(options) {
      var result = options.result,
        root = options.root,
        data = options.data,
        intacctBudgetId = options.intacctBudgetId,
        reportingPeriods = options.reportingPeriods,
        selectedPeriod = options.selectedPeriod,
        excludeRetainedEarnings = options.excludeRetainedEarnings,
        parent = options.parent,
        bsAccounts = options.bsAccounts,
        pnlAccounts = options.pnlAccounts,
        statAccounts = options.statAccounts,
        actualsMapping = options.actualsMapping,
        budgetService = options.budgetService,
        cacheService = options.cacheService,
        versionId = options.versionId,
        store = options.store,
        selectedEntity = options.selectedEntity,
        entities = options.entities,
        locationValues = options.locationValues,
        isMultiCurrency = options.isMultiCurrency,
        isConsolidated = options.isConsolidated,
        consolidationCurrency = options.consolidationCurrency,
        linkingMappingTableFromBudgetLine = options.linkingMappingTableFromBudgetLine,
        selectedDimensions = options.selectedDimensions,
        exportInAddReplaceMode = options.exportInAddReplaceMode,
        model = options.model,
        exportedDimensions = options.exportedDimensions,
        recordsMap = options.recordsMap,
        detailsMap = options.detailsMap,
        parentAccounts = options.parentAccounts,
        intacctApiDimensionsFieldsMap = options.intacctApiDimensionsFieldsMap;
      var getBudgetLineBenefits = function getBudgetLineBenefits(line) {
        var benefits = (Ember.get(line, "budget.benefits") || []).concat(_budgetUtils.default.getInheritedBenefits(line.budget) || []);
        // if Allocated Employee, get the allocated line benefits include inherited
        if (Ember.get(line, "budget.budgetType") === "employee-allocated" && Ember.get(line, "budget.budgetAttributes.allocatedFrom")) {
          var allocatedLine = _budgetUtils.default.findLineByUniqueId(store, cacheService, Ember.get(line, "budget.budgetAttributes.allocatedFrom"), Ember.get(line, "budget.absoluteRoot.id"));
          var allocatedLineBenefits = [];
          if (allocatedLine) {
            allocatedLineBenefits = (Ember.get(allocatedLine, "benefits") || []).concat(_budgetUtils.default.getInheritedBenefits(allocatedLine) || []);
          }
          return allocatedLine ? benefits.concat(allocatedLineBenefits) : benefits;
        }
        return benefits;
      };
      if (recordsMap && exportInAddReplaceMode && !exportedDimensions) {
        exportedDimensions = [];
        Ember.get(model, "exportDimensions").forEach(function (dim) {
          var dimId = Ember.get(dim, "dimension.uniqueId");
          var intacctDimension = budgetService.get("dimensionsMapping").findBy("dimensionId", dimId);
          if (intacctDimension) {
            var dimInternalName = (Ember.get(intacctDimension, "financialInternalName") || "").toUpperCase();
            exportedDimensions.push(intacctApiDimensionsFieldsMap[dimInternalName]);
          }
        });
      }
      var isBS = Ember.get(data, "options.type") === "bs";
      var accounts = actualsMapping.getAccountsForLine(isBS ? Ember.get(data, "bsLine") : Ember.get(data, "budget.uniqueId"));
      accounts = accounts.length ? accounts : parentAccounts;
      if (isBS) {
        if (excludeRetainedEarnings && Ember.get(data, "bsLine") === "re") {
          if (data.children) {
            // substract current pnl amount from retained earnings
            var lastMonthValue = 0,
              lastMonthKey;
            if (data.children[1]) {
              data.children[1].values.forEach(function (v) {
                if (lastMonthKey) {
                  data.values.findBy("key", v.key).value -= v.value - lastMonthValue;
                }
                lastMonthKey = v.key;
                lastMonthValue = v.value;
              });
            }
          }
        }
      }
      // handle perpetual-license - seperate to license/support
      if (Ember.get(data, "budget.budgetType") === "sales-perpetual-license") {
        if (accounts && accounts.length) {
          var addPerpetualLicenseComponent = function addPerpetualLicenseComponent(catAccounts, component, idx) {
            var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
            var consolidationCurrency = options.consolidationCurrency,
              entities = options.entities,
              selectedEntity = options.selectedEntity;
            var currency;
            var budgetCurrency = budgetService.get("selectedRoot.absoluteRoot.budgetAttributes.currency");
            if (consolidationCurrency) {
              currency = consolidationCurrency;
            } else if (selectedEntity) {
              currency = entities.findBy("id", selectedEntity.id).currency;
            }
            if (currency != budgetCurrency) {
              _budgetUtils.default.addCurrencyToBudget(budgetService.get("selectedRoot.absoluteRoot"), currency);
            }
            var componentData = _forecast.default.calcForecast(Ember.get(data, "budget.cache"), Ember.get(data, "budget.store"), Ember.get(data, "budget.id"), Ember.get(data, "budget"), {
              type: "pnl",
              extraLine: component,
              currency: currency
            });
            var thisData = _forecast.default.calcForcastData(Ember.get(data, "budget.cache"), Ember.Object.create({
              viewType: "pnl",
              selectedPeriod: selectedPeriod,
              forceDetails: true
            }), componentData);
            IntacctExport.convertLineToBudgetDetail({
              intacctBudgetId: intacctBudgetId,
              result: result,
              data: thisData.root,
              accounts: catAccounts.mapBy("account"),
              reportingPeriods: reportingPeriods,
              bsAccounts: bsAccounts,
              pnlAccounts: pnlAccounts,
              statAccounts: statAccounts,
              budgetService: budgetService,
              cacheService: cacheService,
              dimValueIndex: idx,
              selectedEntity: selectedEntity,
              entities: entities,
              locationValues: locationValues,
              isMultiCurrency: isMultiCurrency,
              isConsolidated: isConsolidated,
              consolidationCurrency: consolidationCurrency,
              versionId: versionId,
              actualsMapping: actualsMapping,
              linkingMappingTableFromBudgetLine: linkingMappingTableFromBudgetLine,
              selectedDimensions: selectedDimensions,
              exportInAddReplaceMode: exportInAddReplaceMode,
              exportedDimensions: exportedDimensions,
              recordsMap: recordsMap,
              detailsMap: detailsMap,
              intacctApiDimensionsFieldsMap: intacctApiDimensionsFieldsMap
            });
            IntacctExport.substractFromParent(data, thisData.root);
            IntacctExport.substractFromParent(parent, thisData.root);
          };
          var mappedLinesCategory = [];
          var mappedLinesCategoryAccounts = [];
          var mappedLinesAccounts = [];
          accounts.forEach(function (a) {
            if (a.get("budgetLines").includes(Ember.get(data, "budget.uniqueId"))) {
              mappedLinesAccounts.push(a);
            }
            var extraLines = a.get("budgetLines").filter(function (l) {
              return l.startsWith("".concat(Ember.get(data, "budget.uniqueId"), ":"));
            });
            if (extraLines.length) {
              mappedLinesCategoryAccounts.push(a);
            }
          });
          var foundLines = [];
          mappedLinesCategoryAccounts.forEach(function (a) {
            var extraLines = (a.get("budgetLines") || []).filter(function (l) {
              return l.startsWith("".concat(Ember.get(data, "budget.uniqueId"), ":"));
            });
            mappedLinesCategory = mappedLinesCategory.concat(extraLines);
            (extraLines || []).forEach(function (extraLine) {
              if (!foundLines.includes(extraLine)) {
                foundLines.push(extraLine);
                var line = _const.default.DEFAULT_REVENUE_TYPES_MAPPING[extraLine.slice(-1)];
                line = line ? line.name : undefined;
                if (line) {
                  addPerpetualLicenseComponent(mappedLinesCategoryAccounts, line.toLowerCase(), "", {
                    consolidationCurrency: consolidationCurrency,
                    entities: entities,
                    selectedEntity: selectedEntity
                  });
                }
              }
            });
          });
          if (mappedLinesCategory.length === 1) {
            var index = mappedLinesCategory[0].slice(-1) === "0" ? 1 : 0;
            var line = _const.default.DEFAULT_REVENUE_TYPES_MAPPING[index];
            line = line ? line.name : undefined;
            if (line) {
              var _accounts = mappedLinesAccounts.length ? mappedLinesAccounts : parentAccounts;
              (_accounts || []).forEach(function (a) {
                addPerpetualLicenseComponent([a], line.toLowerCase(), "", {
                  consolidationCurrency: consolidationCurrency,
                  entities: entities,
                  selectedEntity: selectedEntity
                });
              });
            }
          }
          IntacctExport.convertLineToBudgetDetail({
            intacctBudgetId: intacctBudgetId,
            result: result,
            data: data,
            accounts: accounts.filter(function (catAccount) {
              return catAccount.get("budgetLines").find(function (line) {
                return line.indexOf(":") === -1;
              });
            }).mapBy("account"),
            reportingPeriods: reportingPeriods,
            bsAccounts: bsAccounts,
            pnlAccounts: pnlAccounts,
            statAccounts: statAccounts,
            budgetService: budgetService,
            cacheService: cacheService,
            selectedEntity: selectedEntity,
            entities: entities,
            locationValues: locationValues,
            isMultiCurrency: isMultiCurrency,
            isConsolidated: isConsolidated,
            consolidationCurrency: consolidationCurrency,
            versionId: versionId,
            actualsMapping: actualsMapping,
            linkingMappingTableFromBudgetLine: linkingMappingTableFromBudgetLine,
            selectedDimensions: selectedDimensions,
            exportInAddReplaceMode: exportInAddReplaceMode,
            exportedDimensions: exportedDimensions,
            recordsMap: recordsMap,
            detailsMap: detailsMap,
            intacctApiDimensionsFieldsMap: intacctApiDimensionsFieldsMap
          });
        }
      } else if (Ember.get(data, "budget.isEmployeeIncludeAllocated")) {
        if (accounts && accounts.length) {
          var addSalaryComponent = function addSalaryComponent(catAccounts, component, idx) {
            var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
            var consolidationCurrency = options.consolidationCurrency,
              entities = options.entities,
              selectedEntity = options.selectedEntity;
            var currency;
            var budgetCurrency = budgetService.get("selectedRoot.absoluteRoot.budgetAttributes.currency");
            if (consolidationCurrency) {
              currency = consolidationCurrency;
            } else if (selectedEntity) {
              currency = entities.findBy("id", selectedEntity.id).currency;
            } else {
              // All lines are first converted into the root currency. Later in the 'convertLineToBudgetDetail' function, they will be converted according to their entity currency.
              currency = budgetCurrency;
            }
            if (currency != budgetCurrency) {
              _budgetUtils.default.addCurrencyToBudget(budgetService.get("selectedRoot.absoluteRoot"), currency);
            }
            var componentData = _forecast.default.calcForecast(Ember.get(data, "budget.cache"), Ember.get(data, "budget.store"), Ember.get(data, "budget.id"), Ember.get(data, "budget"), {
              type: "pnl",
              extraLine: component,
              currency: currency
            });
            var thisData = _forecast.default.calcForcastData(Ember.get(data, "budget.cache"), Ember.Object.create({
              viewType: "pnl",
              selectedPeriod: selectedPeriod,
              forceDetails: true,
              currency: currency
            }), componentData);
            IntacctExport.convertLineToBudgetDetail({
              intacctBudgetId: intacctBudgetId,
              result: result,
              data: thisData.root,
              accounts: catAccounts.mapBy("account"),
              reportingPeriods: reportingPeriods,
              bsAccounts: bsAccounts,
              pnlAccounts: pnlAccounts,
              statAccounts: statAccounts,
              budgetService: budgetService,
              cacheService: cacheService,
              dimValueIndex: idx,
              selectedEntity: selectedEntity,
              entities: entities,
              locationValues: locationValues,
              isMultiCurrency: isMultiCurrency,
              isConsolidated: isConsolidated,
              consolidationCurrency: consolidationCurrency,
              versionId: versionId,
              actualsMapping: actualsMapping,
              linkingMappingTableFromBudgetLine: linkingMappingTableFromBudgetLine,
              selectedDimensions: selectedDimensions,
              exportInAddReplaceMode: exportInAddReplaceMode,
              exportedDimensions: exportedDimensions,
              recordsMap: recordsMap,
              detailsMap: detailsMap,
              intacctApiDimensionsFieldsMap: intacctApiDimensionsFieldsMap
            });
            IntacctExport.substractFromParent(data, thisData.root);
            IntacctExport.substractFromParent(parent, thisData.root);
          };
          _const.default.DEFAULT_SALARY_COMPONENTS.forEach(function (component, idx) {
            var catAccounts = accounts.filter(function (catAccount) {
              return (catAccount.get("budgetLines") || []).includes("".concat(Ember.get(data, "parent.budget.uniqueId"), ":").concat(idx));
            });
            if (catAccounts && catAccounts.length) {
              addSalaryComponent(catAccounts, component, idx, {
                consolidationCurrency: consolidationCurrency,
                entities: entities,
                selectedEntity: selectedEntity
              });
            }
          });
          // handle advanced benefits
          var benefits = getBudgetLineBenefits(data);
          var extraLines = {};
          if (benefits.length) {
            accounts.forEach(function (a) {
              (a.get("budgetLines") || []).forEach(function (l) {
                var benefitId = l.split("::")[1];
                if (benefitId) {
                  var benefit = benefits.findBy("uniqueId", benefitId);
                  if (benefit && benefit.get("name")) {
                    var key = "benefits#".concat(benefit.get("name"));
                    extraLines[key] = extraLines[key] || [];
                    if (!extraLines[key].includes(a)) {
                      extraLines[key].push(a);
                    }
                  }
                }
              });
            });
            if (!Ember.isEmpty(extraLines)) {
              for (var key in extraLines) {
                addSalaryComponent(extraLines[key], [key], "", {
                  consolidationCurrency: consolidationCurrency,
                  entities: entities,
                  selectedEntity: selectedEntity
                });
              }
            }
          }

          // map the remainder amounts to the Employee line directly
          IntacctExport.convertLineToBudgetDetail({
            intacctBudgetId: intacctBudgetId,
            result: result,
            data: data,
            accounts: accounts.filter(function (catAccount) {
              return catAccount.get("budgetLines").find(function (line) {
                return line.indexOf(":") === -1;
              });
            }).mapBy("account"),
            reportingPeriods: reportingPeriods,
            bsAccounts: bsAccounts,
            pnlAccounts: pnlAccounts,
            statAccounts: statAccounts,
            budgetService: budgetService,
            cacheService: cacheService,
            selectedEntity: selectedEntity,
            entities: entities,
            locationValues: locationValues,
            isMultiCurrency: isMultiCurrency,
            isConsolidated: isConsolidated,
            consolidationCurrency: consolidationCurrency,
            versionId: versionId,
            actualsMapping: actualsMapping,
            linkingMappingTableFromBudgetLine: linkingMappingTableFromBudgetLine,
            selectedDimensions: selectedDimensions,
            exportInAddReplaceMode: exportInAddReplaceMode,
            exportedDimensions: exportedDimensions,
            recordsMap: recordsMap,
            detailsMap: detailsMap,
            intacctApiDimensionsFieldsMap: intacctApiDimensionsFieldsMap
          });
        }
      } else if (data.children && data.children.length) {
        data.children.forEach(function (child) {
          IntacctExport.convertDataToBudgetDetails({
            result: result,
            root: root,
            data: child,
            intacctBudgetId: intacctBudgetId,
            reportingPeriods: reportingPeriods,
            selectedPeriod: selectedPeriod,
            excludeRetainedEarnings: excludeRetainedEarnings,
            parent: data,
            parentAccounts: isBS ? [] : accounts,
            bsAccounts: bsAccounts,
            pnlAccounts: pnlAccounts,
            statAccounts: statAccounts,
            actualsMapping: actualsMapping,
            budgetService: budgetService,
            cacheService: cacheService,
            versionId: versionId,
            store: store,
            selectedEntity: selectedEntity,
            entities: entities,
            locationValues: locationValues,
            isMultiCurrency: isMultiCurrency,
            isConsolidated: isConsolidated,
            consolidationCurrency: consolidationCurrency,
            linkingMappingTableFromBudgetLine: linkingMappingTableFromBudgetLine,
            selectedDimensions: selectedDimensions,
            exportInAddReplaceMode: exportInAddReplaceMode,
            model: model,
            exportedDimensions: exportedDimensions,
            recordsMap: recordsMap,
            detailsMap: detailsMap,
            intacctApiDimensionsFieldsMap: intacctApiDimensionsFieldsMap
          });
        });
        if (isBS && accounts && accounts.length) {
          IntacctExport.convertLineToBudgetDetail({
            intacctBudgetId: intacctBudgetId,
            result: result,
            data: data,
            accounts: accounts.filter(function (catAccount) {
              return catAccount.get("budgetLines").find(function (line) {
                return line.indexOf(":") === -1;
              });
            }).mapBy("account"),
            reportingPeriods: reportingPeriods,
            bsAccounts: bsAccounts,
            pnlAccounts: pnlAccounts,
            statAccounts: statAccounts,
            budgetService: budgetService,
            cacheService: cacheService,
            versionId: versionId,
            store: store,
            selectedEntity: selectedEntity,
            entities: entities,
            locationValues: locationValues,
            isMultiCurrency: isMultiCurrency,
            isConsolidated: isConsolidated,
            consolidationCurrency: consolidationCurrency,
            actualsMapping: actualsMapping,
            linkingMappingTableFromBudgetLine: linkingMappingTableFromBudgetLine,
            selectedDimensions: selectedDimensions,
            exportInAddReplaceMode: exportInAddReplaceMode,
            exportedDimensions: exportedDimensions,
            recordsMap: recordsMap,
            detailsMap: detailsMap
          });
          IntacctExport.substractFromParent(parent, data);
        }
      } else if (accounts && accounts.length) {
        IntacctExport.convertLineToBudgetDetail({
          intacctBudgetId: intacctBudgetId,
          result: result,
          data: data,
          accounts: accounts.filter(function (catAccount) {
            return catAccount.get("budgetLines").find(function (line) {
              return line.indexOf(":") === -1;
            });
          }).mapBy("account"),
          reportingPeriods: reportingPeriods,
          bsAccounts: bsAccounts,
          pnlAccounts: pnlAccounts,
          statAccounts: statAccounts,
          budgetService: budgetService,
          cacheService: cacheService,
          versionId: versionId,
          store: store,
          selectedEntity: selectedEntity,
          entities: entities,
          locationValues: locationValues,
          isMultiCurrency: isMultiCurrency,
          isConsolidated: isConsolidated,
          consolidationCurrency: consolidationCurrency,
          actualsMapping: actualsMapping,
          linkingMappingTableFromBudgetLine: linkingMappingTableFromBudgetLine,
          selectedDimensions: selectedDimensions,
          exportInAddReplaceMode: exportInAddReplaceMode,
          exportedDimensions: exportedDimensions,
          recordsMap: recordsMap,
          detailsMap: detailsMap,
          intacctApiDimensionsFieldsMap: intacctApiDimensionsFieldsMap
        });
        IntacctExport.substractFromParent(parent, data);
      }
    },
    convertLineToBudgetDetail: function convertLineToBudgetDetail(options) {
      var _this = this;
      var intacctBudgetId = options.intacctBudgetId,
        result = options.result,
        data = options.data,
        accounts = options.accounts,
        reportingPeriods = options.reportingPeriods,
        bsAccounts = options.bsAccounts,
        pnlAccounts = options.pnlAccounts,
        statAccounts = options.statAccounts,
        budgetService = options.budgetService,
        dimValueIndex = options.dimValueIndex,
        cacheService = options.cacheService,
        versionId = options.versionId,
        store = options.store,
        selectedEntity = options.selectedEntity,
        entities = options.entities,
        locationValues = options.locationValues,
        isMultiCurrency = options.isMultiCurrency,
        isConsolidated = options.isConsolidated,
        actualsMapping = options.actualsMapping,
        linkingMappingTableFromBudgetLine = options.linkingMappingTableFromBudgetLine,
        selectedDimensions = options.selectedDimensions,
        exportInAddReplaceMode = options.exportInAddReplaceMode,
        exportedDimensions = options.exportedDimensions,
        recordsMap = options.recordsMap,
        detailsMap = options.detailsMap,
        intacctApiDimensionsFieldsMap = options.intacctApiDimensionsFieldsMap;
      var isBS = Ember.get(data, "options.type") === "bs",
        lastValue = 0;
      var isStat = Ember.get(data, "options.metrics") && Ember.get(data, "options.metrics") !== "false";
      var budgetLineDimensions = Ember.get(data, "budget.inheritedAttributes.dimensions");
      // filter dimensions that doesn't have value and wasn't selected
      var dimensionalData = isBS ? [] : (budgetLineDimensions || Ember.A([])).filterBy("values.firstObject.value");
      dimensionalData = (dimensionalData || []).filter(function (dim) {
        var selectDim = selectedDimensions.findBy("dimension.uniqueId", dim && dim.dimension);
        return selectDim && selectDim.selected;
      });
      accounts = accounts.filter(function (a) {
        return (bsAccounts || []).concat(pnlAccounts || []).concat(statAccounts || []).find(function (acc) {
          return acc.id === a && acc.status === "active";
        });
      });
      var budgetCurrency = budgetService.get("selectedRoot.absoluteRoot.budgetAttributes.currency");
      var budgetLineCurrency = Ember.get(data, "budget.budgetAttributes.currency");
      var root = budgetService.get("selectedRoot.absoluteRoot");
      var comments = (Ember.get(data, "budget.comments") || []).mapBy("comment").join(".").slice(0, 200).replace(/[^\s\-,.a-zA-Z0-9]/gi, "_").replace(/\s/g, " ");
      var budgetLineId = Ember.get(data, "budget.budgetType") === "assumption" ? Ember.get(_assumption.default.getModelLineGroup(Ember.get(data, "budget")), "uniqueId") : Ember.get(data, "budget.uniqueId");
      var uniqueMapId = linkingMappingTableFromBudgetLine[budgetLineId];
      var externalId = "";
      var budgetType = Ember.get(data, "budget.budgetType");
      var isPastBalances = _const.default.PAST_BALANCES[budgetType] || false;
      //when create private budget - take only budget line that under the same entity
      if (selectedEntity && !this.isValidForEntity(budgetLineDimensions, selectedEntity.id, {
        budgetService: budgetService,
        locationValues: locationValues
      })) {
        return;
        //when create private budget - budget line must have location otherwise we won't export it
      } else if (isMultiCurrency) {
        var locationDimId = budgetService.get("dimensions").findBy("type", "location") && budgetService.get("dimensions").findBy("type", "location").get("uniqueId");
        if (!locationDimId) {
          return;
        }
        var budgetLocationValue = budgetLineDimensions.findBy("dimension", locationDimId).values.get("firstObject.value");
        if (!budgetLocationValue) {
          return;
        }
      }
      if (data.values.findBy("value")) {
        var missingDates = false;
        data.values.forEach(function (item) {
          var reportingPeriod = reportingPeriods.findBy("budgetaKey", item.key);
          var bsLine = data.bsLine || data.parent && data.parent.bsLine;
          if (reportingPeriod) {
            accounts.forEach(function (accountId) {
              var reverse = 1;
              if (!isStat) {
                if (isBS && bsLine) {
                  var account = bsAccounts.findBy("id", accountId);
                  if (account) {
                    if (_const.default.isBSLineLiability(bsLine)) {
                      if (account.accountType === "debit") {
                        reverse = -1;
                      }
                    } else {
                      if (account.accountType === "credit") {
                        reverse = -1;
                      }
                    }
                  }
                } else if (isPastBalances) {
                  // in past balaces , credit account stay with reverse 1
                  // debit account get -1
                  var pnlAccount = pnlAccounts.findBy("id", accountId);
                  if (pnlAccount.accountType === "debit") {
                    reverse = -1;
                  }
                } else {
                  var _pnlAccount = pnlAccounts.findBy("id", accountId);
                  if (_pnlAccount.accountType === "debit") {
                    reverse = -1;
                  }
                }
              }
              var details = [{
                BUDGETID: intacctBudgetId,
                PERIODNAME: reportingPeriod && reportingPeriod.NAME,
                ACCT_NO: accountId,
                AMOUNT: (item.value - lastValue) * reverse / accounts.length,
                NOTE: comments,
                budgetaKey: reportingPeriod.budgetaKey
              }];
              // when create budget for top multi entity/currency only
              // convert all lines that not fit to the budget currency , from budget currency to the entity currency
              if (isMultiCurrency && !isConsolidated && !selectedEntity) {
                var budgetLineCurrencyByEntity = _intacct.default.getEntityCurrencyForBudgetLine(budgetLineDimensions, entities, {
                  budgetService: budgetService
                });
                var targetCurrency = budgetLineCurrencyByEntity === budgetLineCurrency ? budgetLineCurrency : budgetLineCurrencyByEntity;
                details.map(function (line) {
                  if (budgetCurrency !== targetCurrency) {
                    _budgetUtils.default.addCurrencyToBudget(root, targetCurrency);
                    line.AMOUNT = _const.default.convertCurrency(root, line.AMOUNT, budgetCurrency, targetCurrency, item.key);
                  }
                });
              }

              // BUD-12620 add 0 value cause garbage lines in SI.
              // manipulate the amount and replace it after the split
              var isAddWithZero = false;
              if (exportInAddReplaceMode === "add" && details[0] && details[0].AMOUNT === 0) {
                details[0].AMOUNT = 9999;
                isAddWithZero = true;
              }
              dimensionalData.forEach(function (dimData) {
                IntacctExport.splitDetailsForDimension(dimData, details, {
                  budgetService: budgetService,
                  dimValueIndex: dimValueIndex,
                  cacheService: cacheService,
                  store: store,
                  key: item.key,
                  intacctApiDimensionsFieldsMap: intacctApiDimensionsFieldsMap
                });
              });

              // after the split:
              // remove entries with small amount
              // in private budget we can have entries with allocated location - remove the un-match (not ancestor)
              var deleteDetails = details.filter(function (thisDetail) {
                if (Math.abs(thisDetail.AMOUNT) < 1) {
                  return true;
                } else if (selectedEntity && !_intacct.default.isValueAncestorUsingValues(locationValues, selectedEntity.id, thisDetail["LOCATION_NO"], "ID", {
                  includeSelf: true
                })) {
                  return true;
                }
                return false;
              });
              if (deleteDetails.length) {
                details.removeObjects(deleteDetails);
                missingDates = true;
              }
              if (exportInAddReplaceMode === "add" && isAddWithZero) {
                details.forEach(function (thisDetail) {
                  thisDetail.AMOUNT = 0;
                });
              }
              var isSplittedForAllocations = details.length && details[0]["AMOUNT"] !== _const.default.round(details[0]["initialAmount"], 2);
              if (isMultiCurrency && !isConsolidated && isSplittedForAllocations) {
                details.forEach(function (thisDetail) {
                  var detailLocation = thisDetail["LOCATION_NO"];
                  var detailLocationCurrency = _this.getCurrencyForLocation(detailLocation, entities);
                  var budgetLineCurrencyByEntity = _intacct.default.getEntityCurrencyForBudgetLine(budgetLineDimensions, entities, {
                    budgetService: budgetService
                  });
                  if (detailLocationCurrency && detailLocationCurrency !== budgetLineCurrencyByEntity) {
                    _budgetUtils.default.addCurrencyToBudget(root, detailLocationCurrency);
                    thisDetail.AMOUNT = _const.default.convertCurrency(root, thisDetail.AMOUNT, budgetLineCurrencyByEntity, detailLocationCurrency, item.key);
                  }
                });
              }
              details.forEach(function (thisDetail) {
                delete thisDetail.initialAmount;
                if (!isBS) {
                  if (uniqueMapId && uniqueMapId.length) {
                    var uniqueId = _this.getDetailUniqueId(uniqueMapId, budgetService, thisDetail, actualsMapping, accountId, intacctApiDimensionsFieldsMap);
                    if (!uniqueId) {
                      uniqueId = budgetLineId;
                    }
                    externalId = "".concat(Ember.get(data, "budget.absoluteRoot.id"), "_").concat(uniqueId, "_").concat(versionId);
                  } else {
                    externalId = "".concat(Ember.get(data, "budget.absoluteRoot.id"), "_").concat(budgetLineId, "_").concat(versionId);
                  }
                  thisDetail["EXTERNALID"] = externalId;
                }
                // find budget record
                if (recordsMap && exportInAddReplaceMode) {
                  var _obj = {};
                  _obj.ACCT_NO = thisDetail.ACCT_NO;
                  _obj.PERIODNAME = thisDetail.PERIODNAME;
                  exportedDimensions.forEach(function (dim) {
                    if (thisDetail["".concat(dim)]) {
                      _obj["".concat(dim)] = thisDetail["".concat(dim)];
                    }
                  });
                  var _sortedObj = {};
                  // needs to 'sort' the object to keep same order when stringify
                  (Object.keys(_obj) || []).sort().forEach(function (key) {
                    _sortedObj[key] = _obj[key];
                  });
                  var record = recordsMap[cacheService.hashOptions(_sortedObj)];
                  if (record) {
                    thisDetail.RECORDNO = record;
                  }
                  if (detailsMap && record) {
                    delete detailsMap[record];
                  }
                }
                var obj = {};
                Object.keys(thisDetail).forEach(function (key) {
                  if (key !== "EXTERNALID" && key !== "NOTE" && key !== "AMOUNT" && key !== "RECORDNO" && key !== "PERIODNAME" && key !== "budgetaKey") {
                    obj[key] = thisDetail[key];
                  }
                });
                var sortedObj = {};
                // needs to 'sort' the object to keep same order when stringify
                (Object.keys(obj) || []).sort().forEach(function (key) {
                  sortedObj[key] = obj[key];
                });
                obj = cacheService.hashOptions(sortedObj);
                result[obj] = result[obj] || {};
                if (missingDates) {
                  result[obj].sort = true;
                }
                var found = (result[obj].values || []).find(function (r) {
                  var isEqual = true;
                  var xorKeys = _lodash.default.xor(Object.keys(r), Object.keys(thisDetail));
                  if (xorKeys.length) {
                    return false;
                  }
                  Object.keys(r).forEach(function (key) {
                    if (key !== "EXTERNALID" && key !== "NOTE" && key !== "AMOUNT" && key !== "RECORDNO" && r[key] !== thisDetail[key]) {
                      isEqual = false;
                    }
                  });
                  return isEqual;
                });
                if (found) {
                  found.AMOUNT = _const.default.toFixed(found.AMOUNT + thisDetail.AMOUNT, 2);
                } else {
                  result[obj].values = result[obj].values || [];
                  result[obj].values.pushObject(thisDetail);
                }
              });
            });
          }
          if (isBS && bsLine) {
            lastValue = item.value;
          }
        });
      }
    },
    substractFromParent: function substractFromParent(parent, data) {
      if (parent) {
        parent.values.forEach(function (item) {
          var thisItem = data.values.findBy("key", item.key);
          if (thisItem) {
            item.value -= thisItem.value;
          }
        });
        IntacctExport.substractFromParent(parent.parent, data);
      }
    },
    splitDetailsForDimension: function splitDetailsForDimension(dimData, details) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var budgetService = options.budgetService,
        dimValueIndex = options.dimValueIndex,
        cacheService = options.cacheService,
        store = options.store,
        key = options.key,
        intacctApiDimensionsFieldsMap = options.intacctApiDimensionsFieldsMap;
      var dimValues = dimData && Ember.get(dimData, "values").filterBy("value");
      var budgetaDimension = budgetService.get("dimensions").findBy("uniqueId", Ember.get(dimData, "dimension"));
      var intacctDimension = Ember.get(budgetService, "dimensionsMapping").findBy("dimensionId", Ember.get(dimData, "dimension"));
      if (!intacctDimension) {
        return;
      }
      var financialInternalName = (Ember.get(intacctDimension, "financialInternalName") || "").toUpperCase();
      var intacctFieldName = intacctApiDimensionsFieldsMap[financialInternalName];
      var newDetails = [],
        allocations = {},
        dimId = Ember.get(dimData, "dimension"),
        isPerCapita = Ember.get(dimData, "allocatePerCapita");
      // calculate per capita allocations if needed
      if (isPerCapita) {
        var allValues = dimData.values.mapBy("value");
        var notAllocated = 100;
        var employeeCount = _forecast.default.runCalculation(cacheService, store, budgetService.get("selectedRoot.id"), budgetService.get("selectedRoot"), "count_employee", {
          byAllItem: true
        });
        var contractorCount,
          totalCount = 0;
        if (Ember.get(dimData, "allocatePerCapitaType") !== "employee") {
          contractorCount = _forecast.default.runCalculation(cacheService, store, budgetService.get("selectedRoot.id"), budgetService.get("selectedRoot"), "count_contractor", {
            byAllItem: true
          });
        }
        if (Ember.get(dimData, "allocatePerCapitaType") === "employee") {
          if (employeeCount[key]) {
            allValues.forEach(function (value) {
              allocations[value] = employeeCount[key] && employeeCount[key].dimensions[dimId] && employeeCount[key].dimensions[dimId].count && employeeCount[key].dimensions[dimId].count[value] || 0;
              totalCount += allocations[value];
            });
          }
        } else if (Ember.get(dimData, "allocatePerCapitaType") === "contractor") {
          if (contractorCount[key]) {
            allValues.forEach(function (value) {
              allocations[value] = contractorCount[key] && contractorCount[key].dimensions[dimId] && contractorCount[key].dimensions[dimId].count && contractorCount[key].dimensions[dimId].count[value] || 0;
              totalCount += allocations[value];
            });
          }
        } else {
          allValues.forEach(function (value) {
            var thisContractorCount = contractorCount[key] && contractorCount[key].dimensions[dimId] && contractorCount[key].dimensions[dimId].count[value] || 0;
            var thisEmployeeCount = employeeCount[key] && employeeCount[key].dimensions[dimId] && employeeCount[key].dimensions[dimId].count && employeeCount[key].dimensions[dimId].count[value] || 0;
            allocations[value] = thisContractorCount + thisEmployeeCount;
            totalCount += allocations[value];
          });
        }
        allValues.forEach(function (value) {
          allocations[value] *= 100 / totalCount;
          notAllocated -= allocations[value];
        });
        allocations["no"] = notAllocated;
      }
      details.forEach(function (thisDetail) {
        return thisDetail.initialAmount = thisDetail.AMOUNT;
      });
      dimValues.forEach(function (dimValue, idx) {
        if (budgetaDimension.get("isCategory") && !Ember.isEmpty(dimValueIndex) && dimValueIndex !== idx) {
          // for employee category dimension only take the relevant category
          return;
        }
        var intacctValue = Ember.get(budgetService, "dimensionsValuesMapping").find(function (mapping) {
          return Ember.get(mapping, "dimensionId") === Ember.get(dimData, "dimension") && Ember.get(mapping, "dimensionValueIds").includes(dimValue.value);
        });
        // Ignore dimensions values that related to entities
        if (intacctValue && !intacctValue.MEGAENTITYID) {
          details.forEach(function (thisDetail) {
            var totalAmount = thisDetail.initialAmount;
            var allocation = isPerCapita ? allocations[dimValue.value] : dimValue.allocation;
            var thisAmount = _const.default.round(totalAmount * (Ember.isEmpty(allocation) ? 100 : allocation) / 100, 2);
            if (Math.abs(thisAmount) > 0.5) {
              var newDetail = Ember.merge({}, thisDetail);
              newDetail.AMOUNT = thisAmount;
              thisDetail.AMOUNT -= thisAmount;
              newDetail[intacctFieldName] = Ember.get(intacctValue, "financialDimensionValueId");
              newDetails.push(newDetail);
            } else {
              thisDetail.AMOUNT -= thisAmount;
            }
          });
        }
      });
      details.pushObjects(newDetails);
    },
    getDetailUniqueId: function getDetailUniqueId(uniqueMapId, budgetService, detail, actualsMapping, accountId, intacctApiDimensionsFieldsMap) {
      var thisAccount = actualsMapping.getAccount(accountId);
      var thisFinalAccount = actualsMapping.getFinalAccount(thisAccount);
      var accountDimensions = thisFinalAccount.get("dimensions");
      var accountDimensionsIntacct = [];
      accountDimensions.forEach(function (dimId) {
        var intacctDimension = Ember.get(budgetService, "dimensionsMapping").findBy("dimensionId", dimId);
        if (!intacctDimension) {
          return;
        }
        var intacctFieldName = intacctApiDimensionsFieldsMap[(Ember.get(intacctDimension, "financialInternalName") || "").toUpperCase()];
        accountDimensionsIntacct[dimId] = intacctFieldName;
      });
      var _loop = function _loop() {
          var isCorrect = true;
          var item = uniqueMapId[index];
          Object.keys(item.dimensions).forEach(function (itemDim) {
            var intacctMappedDimension = Ember.get(budgetService, "dimensionsValuesMapping").filterBy("dimensionId", itemDim).filter(function (element) {
              return (element.get("dimensionValueIds") || []).find(function (ele) {
                return ele === item.dimensions[itemDim];
              });
            });
            if (intacctMappedDimension) {
              intacctMappedDimension = intacctMappedDimension[0];
            }
            var intacctMappedDimensionValue = intacctMappedDimension && intacctMappedDimension.get("financialDimensionValueId");
            if (!intacctMappedDimensionValue || intacctMappedDimensionValue !== detail[accountDimensionsIntacct[itemDim]]) {
              isCorrect = false;
            }
          });
          if (isCorrect) {
            return {
              v: item.uniqueId
            };
          }
        },
        _ret;
      for (var index = 0; index < uniqueMapId.length; index++) {
        _ret = _loop();
        if (_ret) return _ret.v;
      }
      return "";
    },
    isValidForEntity: function isValidForEntity(dimensions, selectedEntityId) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var budgetService = options.budgetService,
        locationValues = options.locationValues;
      var locationDim = budgetService.get("dimensions").findBy("type", "location");
      var locationDimId = locationDim && locationDim.get("uniqueId");
      if (!locationDimId) {
        return false;
      }
      var budgetLineDimensionValue = dimensions.findBy("dimension", locationDimId).values.get("firstObject.value");
      var budgetLineDimensionValueIntacct = (Ember.get(budgetService, "dimensionsValuesMapping").filterBy("dimensionId", locationDimId) || []).filter(function (c) {
        return c.get("dimensionValueIds")[0] === budgetLineDimensionValue;
      });
      var budgetLineDimensionValueIntacctValue = budgetLineDimensionValueIntacct[0] && budgetLineDimensionValueIntacct[0].get("financialDimensionValueId");
      if (_intacct.default.isValueAncestorUsingValues(locationValues, selectedEntityId, budgetLineDimensionValueIntacctValue, "ID", {
        includeSelf: true
      })) {
        return true;
      }
      return false;
    },
    getCurrencyForLocation: function getCurrencyForLocation(locationId, entities) {
      if (!locationId) {
        return undefined;
      }
      return entities.findBy("id", locationId) && entities.findBy("id", locationId).currency;
    }
  };
  var _default = _exports.default = IntacctExport;
});