define("budgeta/components/select-2-dimension-value-lazy", ["exports", "budgeta/components/select-2-lazy", "budgeta/utils/dimensions"], function (_exports, _select2Lazy, _dimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-side-effects, ember/closure-actions */
  var _default = _exports.default = _select2Lazy.default.extend({
    selected: Ember.computed("content", "optionValuePath", "targetValue.value", function () {
      var content = this.get("content");
      return content && content.findBy(this.get("optionValuePath"), this.get("targetValue.value"));
    }),
    selectedLabel: Ember.computed("selected.name", "optionLabelPath", "placeholder", function () {
      var selected = this.get("selected");
      if (selected) {
        return Ember.get(selected, this.get("optionLabelPath") || "name");
      } else {
        return this.get("placeholder") || "";
      }
    }),
    content: Ember.computed("dimension.values", "addAllOption", "addNoneOption", "addByOption", "allowDuplicates", "allValues.@each.value", "includeValues", "targetValue.selectedValue", "targetProperty", function () {
      return _dimensions.default.getDimensionFilterOptions({
        dimension: this.get("dimension"),
        addAllOption: this.get("addAllOption"),
        addByOption: this.get("addByOption"),
        addNoneOption: this.get("addNoneOption"),
        includeValues: this.get("includeValues"),
        targetValue: this.get("targetValue"),
        targetProperty: this.get("targetProperty"),
        allowDuplicates: this.get("allowDuplicates"),
        allValues: this.get("allValues")
      });
    }),
    actions: {
      onOptionSelecting: function onOptionSelecting() {
        var onOptionSelecting = this.get("onOptionSelecting");
        if (onOptionSelecting) {
          this.sendAction(onOptionSelecting);
        }
      },
      dimensionValueSelected: function dimensionValueSelected() {
        this.sendAction("attributeChanged", this.get("targetValue"), this.get("targetProperty"));
      },
      clear: function clear() {
        var _this = this;
        Ember.run.scheduleOnce("afterRender", function () {
          Ember.set(_this.get("targetValue"), _this.get("targetProperty"), null);
          if (_this.get("attributeChanged")) {
            _this.sendAction("attributeChanged", _this.get("targetValue"), _this.get("targetProperty"));
          }
        });
      }
    }
  });
});