define("budgeta/utils/local-storage-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getItem(key) {
    var item = localStorage.getItem(key);
    try {
      item = JSON.parse(item);
    } catch (e) {
      //do nothing
    }
    return item;
  }
  function setItem(key, value) {
    localStorage.setItem(key, value);
  }
  var _default = _exports.default = {
    getItem: getItem,
    setItem: setItem
  };
});