define("budgeta/components/user-activity-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["user-row"],
    actions: {
      selectUser: function selectUser() {
        this.sendAction("selectUser", this.get("user.name"));
      }
    }
  });
});