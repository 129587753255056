define("budgeta/templates/components/svg/guided-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uByNTsFC",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"20\"],[\"static-attr\",\"height\",\"20\"],[\"static-attr\",\"viewBox\",\"0 0 20 20\"],[\"static-attr\",\"fill\",\"none\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M14 14.25C14 16.045 12.545 17.5 10.75 17.5H3.25C1.455 17.5 0 16.045 0 14.25V5.75C0 3.955 1.455 2.5 3.25 2.5H10.75C12.545 2.5 14 3.955 14 5.75V14.25ZM19.6195 4.5975C19.3845 4.4645 19.096 4.468 18.864 4.607L15 6.9255V13.0745L18.8645 15.393C18.983 15.4645 19.116 15.5 19.25 15.5C19.3775 15.5 19.505 15.4675 19.6195 15.4025C19.8545 15.2695 20 15.0205 20 14.75V5.25C20 4.9795 19.8545 4.7305 19.6195 4.5975Z\"],[\"static-attr\",\"fill\",\"black\"],[\"static-attr\",\"fill-opacity\",\"0.65\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/guided-svg.hbs"
    }
  });
});