define("budgeta/components/config-modal", ["exports", "budgeta/components/modal-new"], function (_exports, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    classNames: ["modal-config", "tabpanel"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get("controllertmp").notifyPropertyChange("insertElement");
      Ember.run.schedule("afterRender", this, function () {
        if (this.$()) {
          this.$("a.used-in").qtip();

          //handle paste from excel
          this.$().on("paste", ".table .col input", function (e) {
            var data = e.originalEvent.clipboardData.getData("text/plain");
            var result = VerEx().tab().or().lineBreak().replace(data, "/\t").split("/\t");
            if (result.length > 1) {
              e.preventDefault();
              var curInput = Ember.$(this);
              while (result.length > 0 && curInput.length) {
                var val = result.shift();
                curInput.focus().val(val);
                curInput = curInput.closest(".col").next(".col").find("input");
              }
            }
          });
        }
      });
      this.adjustTable();
    },
    adjustTable: function adjustTable() {
      var _this = this;
      Ember.run.schedule("afterRender", function () {
        if (_this.$()) {
          _this.$(".table").each(function (idx, table) {
            var $table = Ember.$(table);
            var numCols = $table.find(".row:eq(0) .col").length;
            var colWidth = $table.find(".row:eq(0) .col:eq(0)").outerWidth();
            if (numCols && colWidth) {
              $table.width(numCols * colWidth);
            }
          });
        }
      });
    },
    _data: Ember.computed("controllertmp.fxSections.@each.data", function () {
      if (!this.get("controllertmp.fxSections")) {
        return [];
      }
      return this.get("controllertmp.fxSections").map(function (section) {
        return section.get("data");
      });
    }),
    adjustTableObserver: Ember.observer("_data.@each.term", function () {
      this.adjustTable();
    }),
    rerenderObserver: Ember.observer("controllertmp.rerender", function () {
      this.rerender();
    }),
    keyUp: function keyUp() {
      var _this2 = this;
      Ember.run.next(function () {
        _this2.set("controllertmp.changed", Math.random());
      });
    }
  });
});