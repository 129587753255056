define("budgeta/admin/admin-app/components/licenses/const", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LICENSE_TITLES = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var LICENSE_TITLES = _exports.LICENSE_TITLES = {
    ADMIN: intl.t("admin_app.licenses.admin-title"),
    EDIT: intl.t("admin_app.licenses.edit-title"),
    VIEW: intl.t("admin_app.licenses.view-title")
  };
});