define("budgeta/routes/main/budget/import", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "budgeta/mixins/budgeta-route", "budgeta/utils/import"], function (_exports, _authenticatedRouteMixin, _budgetaRoute, _import) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _budgetaRoute.default, {
    budgetService: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    queryParams: {
      importType: {
        refreshModel: true
      }
    },
    activate: function activate() {
      this.set("viewSelections.maximized", true);
    },
    deactivate: function deactivate() {
      this.set("viewSelections.maximized", false);
    },
    setupController: function setupController(controller /*, model*/) {
      if (controller.get("importType") === "") {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE"));
      }
      if (controller.get("importType") === "" || controller.get("importType") === "upload-group-lines") {
        if (controller.get("importTargetModel.isRoot")) {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE_ROOT"));
        } else if (controller.get("importTargetModel.isCompany")) {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE_SUBSIDIARY"));
        } else if (controller.get("importTargetModel.parent.isCompany") || controller.get("importTargetModel.parent.parent.isCompany") && (controller.get("importTargetModel.budgetType") === "group-payroll" || controller.get("importTargetModel.budgetType") === "group-ps")) {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE_GROUPING"));
        } else {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE_GROUP"));
        }
      }
      var recentFiles = controller.get("recentFiles");
      if (recentFiles && recentFiles.length) {
        recentFiles.forEach(function (recentFile) {
          _import.default.setFileModified(recentFile);
        });
      }
      controller.send("reset");
      this._super.apply(this, arguments);
    },
    actions: {
      willTransition: function willTransition() {
        this.get("controller").reset(); // this is to clear the excel from memory
        Ember.$.noty.closeAll();
      },
      setImportType: function setImportType(type) {
        this.set("controller.importType", type);
      }
    }
  });
});