define("budgeta/generic-file/components/budget-structure-intro/budget-structure-intro", ["exports", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "react", "budgeta/store/actions/budgetActions"], function (_exports, _uiComponents, _reactRedux, _stepsActions, _emberServiceContainer, _react, _budgetActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = previewBudgetIntro;
  /* eslint-disable react-hooks/rules-of-hooks */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function previewBudgetIntro() {
    var dispatch = _reactRedux.default.useDispatch();
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore;
      }),
      isDimensionMapped = _ReactRedux$useSelect.isDimensionMapped,
      currencyColumnKey = _ReactRedux$useSelect.currencyColumnKey,
      skipDimensionStructure = _ReactRedux$useSelect.skipDimensionStructure,
      dimensionUniqueValuesCounter = _ReactRedux$useSelect.dimensionUniqueValuesCounter;
    var options = {
      hideClose: true,
      height: "675px"
    };
    var header = {
      text: intl.t("generic-file.step-budget-structure-intro.header")
    };
    var body = _react.default.createElement("div", {
      className: "budget-structure-intro-body-container padding-intro-body"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.LottieAnimation, {
      animationData: _uiComponents.default.LOTTIE.EyeStructure
    }), _react.default.createElement("div", {
      className: "description"
    }, intl.t("generic-file.step-budget-structure-intro.description")));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button",
      dataHook: "footer-tertiary-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep({
          skipDimensionsHierarchyQuestionStep: !isDimensionMapped || !Object.values(dimensionUniqueValuesCounter).some(function (value) {
            return value > 1;
          }),
          skipCurrencyStep: !currencyColumnKey,
          skipDimensionStructureStep: skipDimensionStructure
        }));
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.got-it"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CLICKED_BUDGET_STRUCTURE_INTRO_NEXT);
        var skipBudgetStep = !isDimensionMapped || !Object.values(dimensionUniqueValuesCounter).some(function (value) {
          return value > 0;
        });
        dispatch(_budgetActions.default.setBudgetStructureModifySkip(skipBudgetStep));
        dispatch(_stepsActions.default.nextStep({
          skipBudgetStructureStep: skipBudgetStep
        }));
      }
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});