define("budgeta/admin/admin-app/store/reducers/userReducer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/admin/admin-app/dataServices/userService", "budgeta/admin/models/userModel", "budgeta/admin/admin-app/store/actions/userActions"], function (_exports, _toConsumableArray2, _objectSpread2, _userService, _userModel, _userActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initialState = {
    users: [],
    selectedUser: _userService.default.getEmptyUser()
  };
  function rootReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case _userActions.GET_USERS:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            users: action.users
          });
        }
      case _userActions.DELETE_USER:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            users: state.users.filter(function (user) {
              return user._id !== action.userId;
            })
          });
        }
      case _userActions.UPDATE_USER:
        {
          var users = (0, _toConsumableArray2.default)(state.users);
          var idx = users.findIndex(function (u) {
            return u._id === action.user._id;
          });
          var user = new _userModel.default((0, _objectSpread2.default)((0, _objectSpread2.default)({}, users[idx]), action.user));
          users[idx] = user;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            users: users,
            selectedUser: _userService.default.getEmptyUser()
          });
        }
      case _userActions.ADD_USER:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            users: [].concat((0, _toConsumableArray2.default)(state.users), [action.user])
          });
        }
      case _userActions.GET_USER_BY_ID:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedUser: action.user
          });
        }
      default:
        return state;
    }
  }
  var _default = _exports.default = rootReducer;
});