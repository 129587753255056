define("budgeta/components/attribute-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    trackingEvents: Ember.inject.service(),
    classNameBindings: [":budgeta-type-value", "addLogicCheckbox:add-logic-checkbox"],
    attributeBindings: ["data-key", "data-hide", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    init: function init() {
      var _this = this;
      this._super();
      this.set("elementId", "attribute-".concat(this.get("key"), "-").concat(this.get("elementId")));
      // Remove roles values that don't exist. (This part should be removed when a feature for managing roles budget will be released)
      if (this.get("key") === "filterRoles") {
        var _this$get;
        var selectedValues = (_this$get = this.get("value")) !== null && _this$get !== void 0 ? _this$get : [];
        selectedValues = selectedValues.filter(function (val) {
          var _this$get2;
          return (_this$get2 = _this.get("content")) === null || _this$get2 === void 0 ? void 0 : _this$get2.findBy("".concat(_this.get("optionValuePath")), val);
        });
        this.set("value", selectedValues);
      }
    },
    enabled: Ember.computed("readOnly", function () {
      return this.get("readOnly") ? false : true;
    }),
    placeholder: Ember.computed("prompt", "inheritedCurrency", "key", function () {
      return this.get("key") === "currency" ? this.get("inheritedCurrency") : this.get("prompt");
    }),
    optionValuePath: Ember.computed("key", function () {
      return this.get("key") === "currency" ? "value" : "id";
    }),
    optionLabelPath: Ember.computed("key", function () {
      return this.get("key") === "currency" ? "label" : "value";
    }),
    checkedValue: Ember.computed("fieldData", {
      get: function get() {
        return this.get("budgetAttributes.".concat(this.get("fieldData.typeRef"))) ? true : false;
      },
      set: function set(key, value) {
        this.set("budgetAttributes.".concat(this.get("fieldData.typeRef")), value);
        return value;
      }
    }),
    addLogicCheckbox: Ember.computed("args.andCheckbox", "value.length", function () {
      return this.get("args.andCheckbox") && this.get("value.length") > 1;
    }),
    computedSearchEnabled: Ember.computed.or("searchEnabled", "args.multiple"),
    valueObserver: Ember.observer("value", function () {
      var _this2 = this;
      Ember.run.next(function () {
        if (_this2.get("content")) {
          _this2.get("content").forEach(function (item) {
            var _item$attributeGroup;
            (_item$attributeGroup = item.attributeGroup) === null || _item$attributeGroup === void 0 || _item$attributeGroup.forEach(function (attr) {
              var _this2$get;
              var defaultValue;
              (_this2$get = _this2.get("budgetModule.budgetAttributes.".concat(attr, ".defaultValueFor"))) === null || _this2$get === void 0 || _this2$get.forEach(function (item) {
                if (_this2.get("budgetAttributes.".concat(item.attr)) == item.value) {
                  defaultValue = item.defaultValue;
                }
              });
              if (defaultValue) {
                _this2.set("budgetAttributes." + attr, defaultValue);
              }
            });
          });
        }
      });
    }),
    andToggleDebounce: function andToggleDebounce(value) {
      if (value) {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.USE_AND_FOR_MODEL_LINE_TAGS"), {});
      }
    },
    actions: {
      andToggle: function andToggle(value) {
        var _this3 = this;
        if (this.get("budget.budgetType") === "assumption") {
          Ember.run.debounce(this, this.andToggleDebounce, value, 100);
        }
        Ember.run.next(function () {
          _this3.set("checkedValue", value);
        });
      }
    }
  });
});