define("budgeta/templates/components/main/budget/forecast-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Zd6HlhNs",
    "block": "{\"statements\":[[\"yield\",\"default\",[[\"get\",[\"forecastDraggableScrollHeightStyle\"]],[\"get\",[\"rowDimensions\"]],[\"get\",[\"isForecastPreview\"]],[\"get\",[\"scrollHeightStyle\"]]]]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/main/budget/forecast-page.hbs"
    }
  });
});