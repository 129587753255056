define("budgeta/components/forecast-row-draggable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    dragDropService: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    attributeBindings: ["draggable"],
    isDraggableEnabled: Ember.computed("budgetService.version", "budgetService.scenario", "budgetService.sharedReadOnlyOrVersion", "budgetService.isSelectedRootLocked", function () {
      var isVersion = this.get("budgetService.version");
      var isScenario = this.get("budgetService.scenario");
      var isViewMode = this.get("budgetService.sharedReadOnlyOrVersion");
      var isLocked = this.get("budgetService.isSelectedRootLocked");
      if (isVersion || isScenario || isViewMode || isLocked) {
        return false;
      }
      return true;
    }),
    init: function init() {
      var _this = this;
      this._super(arguments);
      var dragDropInterface = this.get("dragDropService").create({
        isDraggableEnabled: function isDraggableEnabled() {
          return _this.get("isDraggableEnabled");
        },
        onDraggedItemIndexChanged: this.get("onDraggedItemIndexChanged")
      });
      Object.assign(this, dragDropInterface); // es6
    },
    itemIndex: Ember.computed("line.budget.parent.children.length", "line.id", function () {
      return this.get("line.budget.parent.children").toArray().mapBy("id").indexOf(this.get("line.id"));
    }),
    actions: {
      edit: function edit(budgetLine) {
        if (budgetLine.get("isNewMissingLine")) {
          budgetLine.set("isNewMissingLine", false);
        }
        if (budgetLine.get("parent.isNewLine")) {
          budgetLine.set("parent.isNewLine", false);
        }
        if (budgetLine.get("parent.parent.isNewLine")) {
          budgetLine.set("parent.parent.isNewLine", false);
          budgetLine.get("parent.parent").save();
        }
        this.sendAction("edit", budgetLine);
      },
      delete: function _delete(budgetLine) {
        this.sendAction("delete", budgetLine);
      },
      duplicate: function duplicate(budgetLine) {
        this.sendAction("duplicate", budgetLine, undefined, true);
      },
      showLineDependencies: function showLineDependencies(budget) {
        this.sendAction("showLineDependencies", budget);
      },
      flag: function flag(budgetLine) {
        budgetLine.set("flagged", !budgetLine.get("flagged"));
        budgetLine.save();
      },
      openModal: function openModal() {
        this.sendAction.apply(this, ["openModal"].concat(Array.prototype.slice.call(arguments)));
      },
      actualChanged: function actualChanged(budgetId, key) {
        this.sendAction("actualChanged", budgetId, key);
      }
    }
  });
});