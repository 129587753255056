define("budgeta/components/demo-empty-budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    dateHeaders: Ember.computed(function () {
      var returnDates = [];
      for (var i = 0; i < 25; i++) {
        returnDates.push(Date.create().addMonths(i).format("{Mon} {yyyy}"));
      }
      return returnDates;
    })
  });
});