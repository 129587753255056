define("budgeta/components/inline-edit-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    cache: Ember.inject.service(),
    classNameBindings: [":inline-edit-select", "editing:editing", "readonly:readonly", "hasOptions:has-options:no-options"],
    editing: false,
    readonly: undefined,
    optionValuePath: "value",
    optionLabelPath: "label",
    hasOptions: Ember.computed("content.length", function () {
      return this.get("content.length");
    }),
    click: function click() {
      if (this.get("hasOptions")) {
        this.set("editing", true);
        Ember.run.scheduleOnce("afterRender", this, function () {
          this.$(".select2-container").select2("open");
        });
      }
    },
    valueObserver: Ember.observer("value", function () {
      this.$("input").trigger("change");
      this.set("editing", false);
    }),
    displayValue: Ember.computed("value", "content.length", function () {
      if (this.get("value")) {
        var val = this.get("content").findBy(this.get("optionValuePath"), this.get("value"));
        return Ember.get(val ? val : this.get("content.firstObject"), this.get("optionLabelPath"));
      } else {
        return this.get("placeholder");
      }
    })
  });
});