define("budgeta/components/power-select-lazy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    classNameBindings: ["isActive:active", ":power-select-lazy", "isHighlight:power-select-lazy-highlight"],
    attributeBindings: ["hook:data-hook"],
    hook: Ember.computed("hookIndex", function () {
      if (this.get("hookIndex")) {
        return "power-select-lazy-".concat(this.get("hookIndex"));
      }
      return "power-select-lazy";
    }),
    activeOverride: false,
    isPlaceHolder: Ember.computed("placeholder", "selectedComputed", function () {
      return this.get("placeholder") && !this.get("selectedComputed");
    }),
    show: true,
    showPathList: Ember.computed.equal("component", "path-list-element"),
    selectedComputed: Ember.computed("selected", "options", "optionValuePath", {
      get: function get() {
        return this.get("options") ? this.get("options").findBy(this.get("optionValuePath"), this.get("selected")) : null;
      },
      set: function set(key, value) {
        this.set("selected", value["".concat(this.get("optionValuePath"))]);
        return value;
      }
    }),
    isActive: Ember.computed("active", "activeOverride", function () {
      return this.get("active") || this.get("activeOverride");
    }),
    click: function click(event) {
      if (this.get("stopPropogation")) {
        event.stopPropagation();
      }
    },
    actions: {
      activate: function activate() {
        if (this.get("readOnly")) {
          return;
        } else {
          this.set("activeOverride", true);
        }
      },
      powerSelectChange: function powerSelectChange(val) {
        if (this.get("powerSelectChange")) {
          this.sendAction(this.get("powerSelectChange"), val["".concat(this.get("optionValuePath"))]);
        } else {
          this.set("selectedComputed", val);
        }
        this.$().trigger("change");
      },
      search: function search(option, searchVal) {
        if (option.title) {
          return option.title.toLowerCase().indexOf(searchVal.toLowerCase());
        }
        if (this.get("disableAccountId")) {
          return (Ember.get(option || {}, "budget.fullPathWithoutAccounts") || "").toLowerCase().indexOf(searchVal.toLowerCase());
        }
        return (Ember.get(option || {}, "budget.budgetFullPath") || "").toLowerCase().indexOf(searchVal.toLowerCase());
      }
    }
  });
});