define("budgeta/components/chart-menu", ["exports", "budgeta/utils/const", "budgeta/utils/dashboard"], function (_exports, _const, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    newMenuId: Ember.computed("menuId", function () {
      var newId = this.get("menuId") + "-" + _const.default.makeId();
      return newId;
    }),
    isModelChart: Ember.computed("chart", function () {
      return _dashboard.default.isModelChart(this.get("chart"));
    }),
    actions: {
      hide: function hide(chartId) {
        this.sendAction("hide", chartId);
      },
      expand: function expand(chart) {
        this.sendAction("expand", chart);
      },
      renameChart: function renameChart(chart) {
        this.sendAction("renameChart", chart);
      },
      duplicateChart: function duplicateChart(chart) {
        this.sendAction("duplicateChart", chart);
      },
      advancedChart: function advancedChart(chart) {
        this.sendAction("advancedChart", chart);
      }
    }
  });
});