define("budgeta/components/table-filter", ["exports", "lodash/lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-side-effects */

  var CSS_VAR_NAME = "table-filter-min-width";
  var _default = _exports.default = Ember.Component.extend({
    cssVars: Ember.inject.service(),
    classNameBindings: [":table-filter", "sortAscending:sort-ascending", "sortDescending:sort-descending", "didFilter:has-filter"],
    init: function init() {
      this._super.apply(this, arguments);
      this.set("columnsLayout", [100]);
    },
    sortAscending: Ember.computed("filter.sortOrder", "filter.sortBy", function () {
      return this.get("filter.sortBy") ? this.get("filter.sortOrder") : false;
    }),
    sortDescending: Ember.computed("filter.sortOrder", "filter.sortBy", function () {
      return this.get("filter.sortBy") ? !this.get("filter.sortOrder") : false;
    }),
    searchValue: "",
    dropDownWidth: Ember.computed("isWide", function () {
      return this.get("isWide") ? 350 : 200;
    }),
    allFilterOptions: Ember.computed("attr", "searchValue", "filter", "filters", "isDate", "filter.filters.[]", "filteredTransactions.[]", function () {
      var thisFilter = this.get("filter.filters") || this.get("filters.".concat(this.get("attr"), ".filters"));
      var searchValue = this.get("searchValue");
      var byProp = "name";
      if (this.get("isDate")) {
        byProp = "dateStr";
      }
      if (this.get("showTitle")) {
        byProp = "title";
      }
      if (this.get("enablePathList")) {
        byProp = "budget.fullPathWithoutAccounts";
      }
      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        thisFilter = thisFilter.filter(function (item) {
          return (Ember.get(item, byProp) || "").toString().toLowerCase().includes(searchValue);
        });
        return thisFilter;
      }
      return thisFilter;
    }),
    click: function click() {
      if (this.get("searchValue")) {
        this.set("searchValue", "");
      }
    },
    oldFilterList: undefined,
    filterOptions: Ember.computed("recalcFilterMap", "filterMap", "searchValue", "allFilterOptions.[]", "hasAnyFilter", "filteredColumns", "attr", "filter", "filter.selectList.[]", "oldFilterList", function () {
      var attr = this.get("attr");
      var allFilterOptions = this.get("allFilterOptions");
      var newFilterOptions = this.get("filterMap.".concat(attr, ".filters"));
      if (!this.get("hasAnyFilter")) {
        return allFilterOptions;
      }
      if (this.get("filteredColumns.length") >= 2 && (this.get("filter.selectList.length") || this.get("filters.".concat(attr, ".selectList.length"))) && this.get("oldFilterList")) {
        // if this column was filter before we return the old filter list
        var oldFilters = this.get("oldFilterList");
        var newfilters = newFilterOptions.filter(function (item) {
          return !oldFilters.includes(item);
        });
        if (newfilters.length) {
          oldFilters.addObjects(newfilters);
          this.set("oldFilterList", oldFilters);
        }
        return _lodash.default.intersection(oldFilters, allFilterOptions); // If the a search is enacted, allFilters will have the filtered results after search, so we want to intersect them
      }
      if (this.get("filteredColumns.length") === 1 && this.get("filteredColumns").includes(attr)) {
        // this is the first column that was filtered. return all the filter option and freeze the filter option
        this.set("oldFilterList", allFilterOptions);
        return allFilterOptions;
      }
      this.set("oldFilterList", newFilterOptions);
      var searchValue = this.get("searchValue");
      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        var byProp = "name";
        if (this.get("isDate")) {
          byProp = "dateStr";
        }
        if (this.get("showTitle")) {
          byProp = "title";
        }
        newFilterOptions = newFilterOptions.filter(function (item) {
          return (Ember.get(item, byProp) || "").toString().toLowerCase().includes(searchValue);
        });
        return newFilterOptions;
      }
      return newFilterOptions;
    }),
    linesHeight: Ember.computed("filterOptions.[]", function () {
      return Math.min(this.get("filterOptions.length") * 40, 400) || 40;
    }),
    linesStyle: Ember.computed("linesHeight", function () {
      var style = "\n      height: ".concat(this.get("linesHeight"), "px;\n      position:relative;\n      min-width: var(").concat(this.get("cssVars").makeVarName(CSS_VAR_NAME, this), ");\n    ");
      return Ember.String.htmlSafe(style);
    }),
    qtipClasses: Ember.computed("isWide", function () {
      var _class = this.get("isWide") ? "full-size" : "";
      return _class + " popup-menu";
    }),
    budgetFilterOptions: Ember.computed("filterOptions.[]", function () {
      var filterOptions = this.get("filterOptions");
      return filterOptions.mapBy("budget");
    }),
    didFilter: Ember.computed("filteredColumns.[]", "attr", function () {
      return this.get("filteredColumns").includes(this.get("attr"));
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      if (this.get("resizeFilter")) {
        if (this.get("isWide") && this.$()) {
          this.get("cssVars").setPx(CSS_VAR_NAME, this.$().width(), this);
        }
        Ember.$(window).on("resize.trxfilter", function () {
          Ember.run.schedule("afterRender", function () {
            if (_this.$() && _this.$().width() > 0) {
              _this.get("cssVars").setPx(CSS_VAR_NAME, _this.$().width(), _this);
            }
          });
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off("resize.trxfilter");
      this.get("cssVars").remove(CSS_VAR_NAME, this);
    },
    actions: {
      clear: function clear() {
        this.set("oldFilterList", undefined);
        var filter = this.get("filter");
        if (this.get("dimension")) {
          filter = this.get("filters.".concat(this.get("attr")));
        }
        Ember.get(filter, "filters").forEach(function (filter) {
          Ember.set(filter, "selected", false);
        });
        Ember.set(filter, "selectList", Ember.A([]));
        if (this.get("dimension")) {
          this.set("recalcDimension", !this.get("recalcDimension"));
        }
        if (Ember.get(filter, "filters") && Ember.get(filter, "transactions")) {
          var listOfFilters = Ember.get(filter, "filters").filter(function (f) {
            return f.transactions && f.transactions.size;
          });
          Ember.set(filter, "filters", listOfFilters); // update all the faulty transactions
        }
      },
      sort: function sort() {
        this.get("sort")(this.get("attr"));
      },
      lineToggle: function lineToggle() {
        var _this2 = this;
        Ember.run.next(function () {
          var filter = _this2.get("filter");
          if (_this2.get("dimension")) {
            filter = _this2.get("filters.".concat(_this2.get("attr")));
          }
          var selected = Ember.get(filter, "filters").filterBy("selected");
          Ember.set(filter, "selectList", selected);

          // If there are no selection we will clear selection
          if (!selected.length && Ember.get(filter, "filters")) {
            var listOfFilters = Ember.get(filter, "filters").filter(function (f) {
              return f.transactions && f.transactions.size;
            });
            Ember.set(filter, "filters", listOfFilters); // update all the faulty transactions
          }
          if (_this2.get("dimension")) {
            _this2.set("recalcDimension", !_this2.get("recalcDimension"));
          }
        });
      }
    }
  });
});