define("budgeta/admin/admin-app/components/body/tabs", ["exports", "budgeta/admin/admin-app/routes", "budgeta/services/ember-service-container"], function (_exports, _routes, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var tabs = [{
    name: intl.t("admin_app.nav-bar.companies"),
    id: "companyManagement",
    route: _routes.ROUTES.COMPANY_LIST,
    baseInnerRoutes: [_routes.BASE_COMPANY_ROUTE],
    roles: ["admin", "super-admin"]
  }, {
    name: intl.t("admin_app.nav-bar.users"),
    id: "adminConsoleUserList",
    route: _routes.ROUTES.ADMIN_USER_LIST,
    baseInnerRoutes: [_routes.BASE_ADMIN_USER_ROUTE],
    roles: ["admin", "super-admin"]
  }, {
    name: "Users",
    id: "users",
    route: _routes.ROUTES.USER_LIST,
    baseInnerRoutes: [_routes.BASE_USER_ROUTE],
    roles: ["super-admin"]
  }, {
    name: "Budgets",
    id: "budgets",
    route: _routes.ROUTES.BUDGET_SEARCH,
    baseInnerRoutes: [_routes.BASE_BUDGET_ROUTE],
    roles: ["super-admin"]
  }, {
    name: intl.t("admin_app.nav-bar.auditLog"),
    id: "auditLog",
    route: _routes.ROUTES.AUDIT_LOGGING_LIST,
    onClick: function onClick() {
      return trackEvents.trackEvent(trackEvents.events.ADMIN.ENTER_AUDIT_LOG);
    },
    roles: ["admin", "super-admin"]
  }, {
    name: intl.t("admin_app.nav-bar.reports"),
    id: "reports",
    route: _routes.ROUTES.REPORTS,
    roles: ["super-admin"]
  }];
  var _default = _exports.default = tabs;
});