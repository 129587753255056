define("budgeta/controllers/advanced-chart", ["exports", "budgeta/services/ember-service-container", "budgeta/controllers/modal", "budgeta/utils/dashboard"], function (_exports, _emberServiceContainer, _modal, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _modal.default.extend({
    treeManager: Ember.inject.service(),
    budgetsListModelLines: Ember.computed.alias("treeManager.budgetsListModelLines"),
    budgetService: Ember.inject.service(),
    fixedAssumptions: Ember.computed.alias("budgetService.fixedAssumptions"),
    fixedAssumptionsBase: Ember.computed.alias("budgetService.fixedAssumptionsBase"),
    trackingEvents: Ember.inject.service(),
    columnsLayout: [100],
    periodTypeOptions: Ember.A([{
      name: intl.t("general.name_1695611602094"),
      value: "Monthly"
    }, {
      name: intl.t("general.name_1695611602083"),
      value: "Quarterly"
    }, {
      name: intl.t("general.name_1695611602073"),
      value: "Yearly"
    }]),
    confirmDisabled: false,
    disablePeriodChange: Ember.computed("model.chart.periodType", function () {
      return this.get("model.chart.periodType") !== "Monthly";
    }),
    isModelChart: Ember.computed("model.chart", function () {
      return _dashboard.default.isModelChart(this.get("model.chart"));
    }),
    numberOfSelected: Ember.computed("model.kpiSelection.@each.selected", "numberOfAssumptionSelected", "numberOfModelLineSelected", function () {
      var numberOfSelected = this.get("model.kpiSelection").filter(function (selection) {
        return selection.type !== "assumption" && selection.type !== "model-line" && selection.selected;
      }).length + this.get("numberOfAssumptionSelected") + this.get("numberOfModelLineSelected");
      if (numberOfSelected > 6) {
        this.set("confirmDisabled", true);
      } else {
        this.set("confirmDisabled", false);
      }
      return numberOfSelected;
    }),
    isAboveMax: Ember.computed("numberOfSelected", function () {
      return this.get("numberOfSelected") > 6;
    }),
    kpiSelection: Ember.computed("model.kpiSelection", function () {
      return this.get("model.kpiSelection").filter(function (selection) {
        return selection.type !== "assumption" && selection.type !== "model-line";
      });
    }),
    assumptions: Ember.computed("fixedAssumptions.length", "model.kpiSelection.@each.selected", function () {
      var newArray = Ember.A([]);
      var selectedAssumption = this.get("model.kpiSelection").filterBy("selected").filterBy("type", "assumption");
      (this.get("fixedAssumptions") || []).forEach(function (assumption) {
        var isSelected = selectedAssumption.findBy("uniqueId", Ember.get(assumption, "uniqueId")) ? true : false;
        newArray.push({
          selected: isSelected,
          assumption: assumption
        });
      });
      return newArray;
    }),
    modelLines: Ember.computed("budgetsListModelLines.length", "model.kpiSelection.@each.selected", function () {
      var newArray = Ember.A([]);
      var selectedModelLines = this.get("model.kpiSelection").filterBy("selected").filterBy("type", "model-line");
      this.get("budgetsListModelLines").forEach(function (modelLine) {
        var isSelected = selectedModelLines.findBy("uniqueId", Ember.get(modelLine, "uniqueId")) ? true : false;
        newArray.push({
          selected: isSelected,
          modelLine: modelLine
        });
      });
      return newArray;
    }),
    models: Ember.computed("assumptions", "budgetsListModelLines", "modelLines", function () {
      var newArray = Ember.A([]);
      var assumptions = this.get("assumptions");
      var models = {};
      assumptions.forEach(function (assumption) {
        if (!models[Ember.get(assumption, "assumption.parent.uniqueId")]) {
          models[Ember.get(assumption, "assumption.parent.uniqueId")] = {
            modelTitle: Ember.get(assumption, "assumption.parent.name"),
            assumptions: Ember.A([]),
            modelLines: Ember.A([])
          };
        }
        models[Ember.get(assumption, "assumption.parent.uniqueId")].assumptions.push(assumption);
      });
      var modelLines = this.get("modelLines");
      modelLines.forEach(function (modelLine) {
        if (!models[Ember.get(modelLine, "modelLine.parent.uniqueId")]) {
          models[Ember.get(modelLine, "modelLine.parent.uniqueId")] = {
            modelTitle: Ember.get(modelLine, "modelLine.parent.name"),
            assumptions: Ember.A([]),
            modelLines: Ember.A([])
          };
        }
        models[Ember.get(modelLine, "modelLine.parent.uniqueId")].modelLines.push(modelLine);
      });
      for (var key in models) {
        newArray.push(models[key]);
      }
      return newArray;
    }),
    flatList: Ember.computed("kpiSelection.length", "models.length", function () {
      var flatList = Ember.A();
      flatList.pushObjects(this.get("kpiSelection"));
      (this.get("models") || []).forEach(function (model) {
        flatList.pushObject({
          isTitle: true,
          title: Ember.get(model, "modelTitle")
        });
        if (Ember.get(model, "modelLines.length")) {
          flatList.pushObject({
            isSubTitle: true,
            title: intl.t("general.title_1695611597495")
          });
          flatList.pushObjects(Ember.get(model, "modelLines"));
        }
        if (Ember.get(model, "assumptions.length")) {
          flatList.pushObject({
            isSubTitle: true,
            title: intl.t("general.title_1695611597485")
          });
          flatList.pushObjects(Ember.get(model, "assumptions"));
        }
      });
      return flatList;
    }),
    numberOfAssumptionSelected: Ember.computed("assumptions.@each.selected", function () {
      var _this = this;
      var selected = [];
      var kpiSelection = this.get("model.kpiSelection").filterBy("type", "assumption");
      this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CUSTOM_KPI_SELECT"), {
        selected: "Assumption"
      });
      this.get("assumptions").forEach(function (assumption) {
        var selection = kpiSelection.findBy("uniqueId", Ember.get(assumption, "assumption.uniqueId"));
        if (Ember.get(assumption, "selected")) {
          selected.push(assumption);
          if (selection) {
            Ember.set(selection, "selected", true);
            Ember.set(selection, "assumptionName", Ember.get(assumption, "assumption.name"));
          } else {
            _this.get("model.kpiSelection").addObject({
              type: "assumption",
              uniqueId: Ember.get(assumption, "assumption.uniqueId"),
              selected: true,
              assumptionName: Ember.get(assumption, "assumption.name")
            });
          }
          Ember.set(assumption, "selected", true);
        } else if (selection) {
          Ember.set(selection, "selected", false);
        }
      });
      return selected.length;
    }),
    numberOfModelLineSelected: Ember.computed("modelLines.@each.selected", function () {
      var _this2 = this;
      var selected = [];
      var kpiSelection = this.get("model.kpiSelection").filterBy("type", "model-line");
      this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CUSTOM_KPI_SELECT"), {
        selected: "Model line"
      });
      this.get("modelLines").forEach(function (modelLine) {
        var selection = kpiSelection.findBy("uniqueId", Ember.get(modelLine, "modelLine.uniqueId"));
        if (Ember.get(modelLine, "selected")) {
          selected.push(modelLine);
          if (selection) {
            Ember.set(selection, "selected", true);
            Ember.set(selection, "modelLineName", Ember.get(modelLine, "modelLine.name"));
          } else {
            _this2.get("model.kpiSelection").addObject({
              type: "model-line",
              uniqueId: Ember.get(modelLine, "modelLine.uniqueId"),
              selected: true,
              modelLineName: Ember.get(modelLine, "modelLine.name")
            });
          }
          Ember.set(modelLine, "selected", true);
        } else if (selection) {
          Ember.set(selection, "selected", false);
        }
      });
      return selected.length;
    }),
    allChartTypes: [{
      value: "basicColumn",
      name: intl.t("general.name_1695611601902")
    }, {
      value: "stackedColumn",
      name: intl.t("general.name_1695611601893")
    }, {
      value: "stackedPercenatageColumn",
      name: intl.t("general.name_1695611601882")
    }, {
      value: "lineAndColumn",
      name: intl.t("general.name_1695611601872")
    }, {
      value: "basicLine",
      name: intl.t("general.name_1695611601861")
    }, {
      value: "basicLineWithLabels",
      name: intl.t("general.name_1695611601851")
    }, {
      value: "pie",
      name: intl.t("general.name_1695611601840")
    }, {
      value: "donut",
      name: intl.t("general.name_1695611601989")
    }, {
      value: "basicArea",
      name: intl.t("general.name_1695611601829")
    }],
    selectedPieType: Ember.computed("chartType", function () {
      return this.get("chartType") === "pie" || this.get("chartType") === "donut";
    }),
    chartType: Ember.computed("model.chart.chartType", "model.chart.id", {
      get: function get() {
        return this.get("model.chart.chartType") || _dashboard.default.getDefaultChartType(this.get("model.chart.id"));
      },
      set: function set(key, value) {
        if (value === "pie" || value === "donut") {
          this.set("model.chart.periodType", "Monthly");
        }
        this.set("model.chart.chartType", value);
        return value;
      }
    }),
    chartTypes: Ember.computed("model.chart.id", function () {
      var chartId = this.get("model.chart.id");
      var allChartTypes = this.get("allChartTypes");
      if (chartId === "kpiChart") {
        return [{
          value: "kpi-chart",
          name: intl.t("general.name_1695611601958")
        }];
      } else if (chartId === "single-kpi") {
        return [{
          value: "single-kpi",
          name: intl.t("general.name_1695611601958")
        }];
      }
      var allowCharts = _dashboard.default.getChartTypesForChart(chartId);
      if (allowCharts) {
        allChartTypes = allChartTypes.filter(function (type) {
          return allowCharts[type.value];
        });
      }
      return allChartTypes;
    }),
    showChartType: Ember.computed("chartTypes.length", function () {
      return (this.get("chartTypes.length") || 0) > 1;
    }),
    actions: {
      saveOrder: function saveOrder(order) {
        var kpiSelection = this.get("model.kpiSelection");
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CUSTOM_KPI_REORDER"), {});
        order.reverse().forEach(function (item) {
          var kpiItem;
          if (item.type === "assumption") {
            kpiItem = kpiSelection.findBy("uniqueId", item.uniqueId);
          } else {
            kpiItem = kpiSelection.findBy("type", item.type);
          }
          if (kpiItem) {
            kpiSelection.removeObject(kpiItem);
            kpiSelection.insertAt(0, kpiItem);
          }
        });
      }
    }
  });
});