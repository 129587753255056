define("budgeta/components/accounts-page", ["exports", "budgeta/utils/budget-utils"], function (_exports, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":view-table-edit", ":short-content", ":accounts-table", "hasRightScroll:hasRightScroll"],
    hasRightScroll: false,
    scrollHeight: 500,
    performanceLogger: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    intl: Ember.inject.service(),
    selectedAccountType: Ember.computed.alias("viewSelections.selectedAccountType"),
    dimensionOptions: Ember.computed.alias("budgetService.dimensionOptions"),
    dimensionsMapping: Ember.computed.alias("budgetService.dimensionsMapping"),
    mappingLinesTitle: Ember.computed("viewSelections.selectedAccountType", function () {
      var titleOptions = {
        pnl: this.get("intl").t("accounts.mapping-lines-table-header.budget-line"),
        bs: this.get("intl").t("accounts.mapping-lines-table-header.balance-sheet-line"),
        stat: this.get("intl").t("accounts.mapping-lines-table-header.model-line")
      };
      return titleOptions[this.get("viewSelections.selectedAccountType")];
    }),
    linesObserver: Ember.observer("filteredTransactions", "filteredTransactions.length", "filteredDimensionValues.length", "filteredDimensionValues.@each.maxItemsLength", "itemSizes", "isDimensionsViewType", function () {
      var _this = this;
      Ember.run.scheduleOnce("afterRender", function () {
        Ember.run.throttle(_this, "setForecastHeight", 10);
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.setForecastHeight();
    },
    scrollHeightStyle: Ember.computed("scrollHeight", function () {
      return Ember.String.htmlSafe("height:".concat(this.get("scrollHeight"), "px;position:relative;overflow-x:hidden;"));
    }),
    setForecastHeight: function setForecastHeight() {
      var singleRowHeight = 41;
      if (this.$()) {
        var forecastWrapper = this.$(".accounts-table-editor");
        var height;
        var lineCount = 0;
        if (this.get("isDimensionsViewType")) {
          (this.get("filteredDimensionValues") || []).forEach(function (dimValMapping) {
            lineCount += dimValMapping.get("maxItemsLength");
          });
        } else {
          lineCount = this.get("filteredTransactions.length");
        }
        var tableVisibleRowsHeight = (lineCount || 1) * singleRowHeight;
        var tableHeight = this.get("isDimensionsViewType") ? this.$('[data-hook="selection-content"]').height() - this.$('[data-hook="top-section"]').height() - singleRowHeight : forecastWrapper.height() - forecastWrapper.find(".table-header").outerHeight();
        if (!this.get("isDimensionsViewType") && (tableVisibleRowsHeight < tableHeight || !tableHeight)) {
          tableVisibleRowsHeight = 0;
          this.get("filteredTransactions").forEach(function (account) {
            tableVisibleRowsHeight += Math.max(account.get("budgetLines.length") || 1, account.get("dimensions.length") || 1, account.get("cloneBudgetLines.length") || 1, account.get("cloneDimensions.length") || 1) * singleRowHeight + 1;
          });
        }
        if (tableVisibleRowsHeight < tableHeight || !tableHeight) {
          height = tableVisibleRowsHeight;
          this.setRightScroll(false);
          this.set("RightScroll", false);
        } else {
          height = tableHeight;
          this.setRightScroll(true);
          this.set("hasRightScroll", true);
        }
        this.set("scrollHeight", height);
      }
    },
    setTableWidth: function setTableWidth() {
      var el = this.get("isDimensionsViewType") ? this.$('[data-hook="selection-content"]') : this.$();
      el = el || Ember.$(window);
      var width = el.width();
      this.setControllerTableWidth(width);
    },
    tableWidth: Ember.observer("isDimensionsViewType", function () {
      var _this2 = this;
      Ember.run.scheduleOnce("afterRender", function () {
        Ember.run.throttle(_this2, "setTableWidth", 10);
      });
    }),
    didInsertElement: function didInsertElement() {
      var _this3 = this;
      this._super.apply(this, arguments);
      Ember.$(window).off("resize.table").on("resize.table", function () {
        Ember.run.debounce(_this3, "setForecastHeight", 500);
        Ember.run.debounce(_this3, "setTableWidth", 500);
      });
      Ember.run.scheduleOnce("afterRender", this, function () {
        this.setForecastHeight();
        this.setTableWidth();
        //Run action from controller.
        this.get("performanceLogger").measureAction(this.get("controller"));
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this4 = this;
      Ember.$(window).off("resize.table").on("resize.table", function () {
        _this4.notifyPropertyChange("scrollHeight");
      });
    },
    hasDimensionsColumn: Ember.computed("allAccounts.@each.mappingType", function () {
      return this.get("allAccounts").findBy("mappingType", "multi");
    }),
    sortedAccounts: Ember.computed("allAccounts.@each.account", function () {
      return this.get("allAccounts").sortBy("account");
    }),
    actions: {
      sortDimension: function sortDimension(sortByAttr) {
        this.sortDimension(sortByAttr);
      },
      selectAccountType: function selectAccountType(acType) {
        this.set("viewSelections.selectedAccountType", acType);
      },
      selectAll: function selectAll() {
        this.selectAllAction.apply(this, arguments);
      },
      submit: function submit() {
        this.submit();
      },
      addActualAccount: function addActualAccount() {
        this.addActualAccount.apply(this, arguments);
      },
      selectDimensionMapping: function selectDimensionMapping() {
        this.selectDimensionMapping.apply(this, arguments);
      },
      dimensionMappingChanged: function dimensionMappingChanged() {
        this.dimensionMappingChanged.apply(this, arguments);
      },
      duplicateAccount: function duplicateAccount() {
        this.duplicateAccount.apply(this, arguments);
      },
      deleteAccount: function deleteAccount() {
        this.deleteAccount.apply(this, arguments);
      },
      duplicateSelected: function duplicateSelected() {
        this.duplicateSelected.apply(this, arguments);
      },
      deleteSelected: function deleteSelected() {
        this.deleteSelected.apply(this, arguments);
      },
      lineToggle: function lineToggle() {
        this.lineToggle.apply(this, arguments);
      },
      dimValueMappingChanged: function dimValueMappingChanged() {
        this.dimValueMappingChanged();
      },
      sort: function sort(sortByAttr) {
        _budgetUtils.default.sortByAttribute(this, sortByAttr);
      }
    }
  });
});