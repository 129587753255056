define("budgeta/components/react-component", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/typeof", "react-dom", "budgeta/components/react/componentX/componentX", "budgeta/components/react/tooltipWithIcon/tooltipWithIcon", "budgeta/components/react/list/videosTooltip", "budgeta/components/react/locale/locale", "budgeta/generic-file/components/generic-app/generic-app", "budgeta/components/react/grid-components/budget-lines-grid", "budgeta/common-react-components/login/MainLogin", "budgeta/components/react/download-options-modal/download-options-modal", "budgeta/components/react/synced-users-menu/synced-users-menu", "budgeta/components/react/dropdown-multi-select-wrapper/dropdown-multi-select-wrapper", "@sibp/ui-components"], function (_exports, _objectSpread2, _typeof2, _reactDom, _componentX, _tooltipWithIcon, _videosTooltip, _locale, _genericApp, _budgetLinesGrid, _MainLogin, _downloadOptionsModal, _syncedUsersMenu, _dropdownMultiSelectWrapper, _uiComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    examples:
    <div id="zzz"></div>
    {{!-- example react in ember - render component ComponentX --}}
    {{react-component componentName="ComponentX" isInline=true text="CCC" onClick=(action "buttonX_click" ) }}
    {{!-- example react in ember - render Button component from @sibp/ui-components --}}
    {{react-component componentName="Button" text="AAA" onClick=(action "buttonX_click" ) }}
    {{!--
      example react in ember - render component inside a parent element
      this will render the Button component into element with id="zzz"
    --}}
    {{react-component componentName="Button" parentElement="#zzz" text="BBB" }}
  */

  var isEmberProp = function isEmberProp(prop) {
    return (0, _typeof2.default)(prop) === "object" && Object.keys(prop).some(function (key) {
      return key.match(/MUTABLE_CELL/);
    });
  };
  var convertToReactProps = function convertToReactProps(props) {
    for (var key in props) {
      if (isEmberProp(props[key])) {
        props[key] = props[key].value;
      }
    }
  };
  var components = {
    //add react components here (dont add componenets from @sibp/ui-components package)
    ComponentX: _componentX.default,
    TooltipWithIcon: _tooltipWithIcon.default,
    DropdownMultiSelectWrapper: _dropdownMultiSelectWrapper.default,
    VideosTooltip: _videosTooltip.default,
    GenericApp: _genericApp.default,
    BudgetLinesGrid: _budgetLinesGrid.default,
    MainLogin: _MainLogin.default,
    DownloadOptionsModal: _downloadOptionsModal.default,
    SyncedUsersMenu: _syncedUsersMenu.default,
    Locale: _locale.default
  };
  var componentsRoot = {};
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      if (this.get("parentElement")) {
        this.set("tagName", "");
      }
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var selector = this.get("parentElement") || "#" + this.elementId;
      var root = componentsRoot[selector];
      if (root) {
        root.unmount();
        delete componentsRoot[selector];
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var componentName = this.get("componentName");
      var selector = this.get("parentElement") || "#" + this.elementId;
      var props = (0, _objectSpread2.default)({}, this.attrs);
      delete props.parentElement;
      delete props.componentName;
      convertToReactProps(props);
      Ember.run.scheduleOnce("afterRender", this, function () {
        var container = document.querySelector(selector);
        if (container) {
          var COMPONENT = _uiComponents.default.COMPONENTS[componentName] || components[componentName] || _uiComponents.default.IMAGES[componentName];
          if (!COMPONENT) {
            console.error("react component not found: ".concat(_this.get("componentName")));
          }
          var root = componentsRoot[selector] || _reactDom.default.createRoot(container);
          componentsRoot[selector] = root;
          root.render(React.createElement(COMPONENT, props));
        } else {
          var msg = "react component: container element not found: ".concat("#" + _this.elementId);
          console.error(msg);
          noty({
            type: "error",
            text: msg
          });
        }
      });
    }
  });
});