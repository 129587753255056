define("budgeta/utils/videosUtil/index", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "budgeta/utils/videosUtil/const", "budgeta/services/ember-service-container"], function (_exports, _toConsumableArray2, _const, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getVideosOfCurrentTab = _exports.getVideoTitles = _exports.getAllVideos = void 0;
  var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
  var MAX_VIDEO_COUNT = 10;
  var getAllVideos = _exports.getAllVideos = function getAllVideos() {
    var entries = Object.entries(_const.default);
    var filteredArray = entries.flatMap(function (data) {
      return data[1];
    });
    return Ember.A(filteredArray);
  };

  // current tab selected by user
  var getCurrentPath = function getCurrentPath() {
    var path = viewSelections.get("currentPath");
    var isSheetsView = viewSelections.get("isSheetsView");
    var isMetricsView = viewSelections.get("isMetricsView");

    // INPUTS tab
    if (path === "main.budget.index") {
      if (isMetricsView) {
        return "inputs-model";
      }
      return "inputs-main";
    } else if (path === "main.budget.table") {
      return "inputs-tables";
    }
    // ACTUALS
    else if (path === "main.budget.forecast") {
      // SHEETS
      if (isSheetsView) {
        return "sheets";
      }
      return "actuals-summary";
    } else if (path === "main.budget.trx") {
      return "actuals-details";
    } else if (path === "main.budget.accounts") {
      return "actuals-mapping";
    }
    // DASHBOARD tab
    else if (path === "main.budget.dashboard") {
      return "dashboard";
    }
  };

  // retrive videos by current tab
  var getVideosByTab = function getVideosByTab(tab) {
    return _const.default[tab];
  };

  // rest videos except the current tab
  var getRemainingVideos = function getRemainingVideos(tab) {
    var entries = Object.entries(_const.default);
    var filteredArray = entries.filter(function (data) {
      return data[0] !== tab;
    }).flatMap(function (data) {
      return data[1];
    });
    return filteredArray;
  };

  // videos title to be representd in dropdown
  var getVideoTitles = _exports.getVideoTitles = function getVideoTitles() {
    var currentTab = getCurrentPath();
    var selectedVideos = getVideosByTab(currentTab);
    return Ember.A(selectedVideos);
  };
  var getVideosOfCurrentTab = _exports.getVideosOfCurrentTab = function getVideosOfCurrentTab(tab) {
    var currentTab = tab || getCurrentPath();
    var selectedVideos = getVideosByTab(currentTab);
    // don't have videos for currentTab
    if (!selectedVideos) {
      return Ember.A();
    }
    // completion to MAX_VIDEO_COUNT videos
    if (selectedVideos.length < MAX_VIDEO_COUNT) {
      var filteredVideos = getRemainingVideos(currentTab);
      // section of `more-videos` contain max 10 videos
      filteredVideos = filteredVideos.slice(0, MAX_VIDEO_COUNT - selectedVideos.length + 2);
      var newData = [].concat((0, _toConsumableArray2.default)(selectedVideos), (0, _toConsumableArray2.default)(filteredVideos));
      return Ember.A(newData);
    }
    return Ember.A(selectedVideos);
  };
});