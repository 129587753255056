define("budgeta/components/perforemance-logger", ["exports", "budgeta/services/ember-service-container", "lodash/lodash"], function (_exports, _emberServiceContainer, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":performance-logger-container", ":perforemance-logger"],
    cache: Ember.inject.service(),
    isShowGraphs: false,
    intervalObject: null,
    interval: 3000,
    calcForecastSize: 0,
    calcForecastSizeArr: Ember.A([]),
    memoryData: null,
    usedJSHeapSizeArr: [],
    totalJSHeapSizeArr: [],
    jsHeapSizeLimit: [],
    startTime: new Date(),
    timeArr: Ember.A([]),
    data: {
      calcForecast: [],
      preFilter: [],
      getAmount: [],
      formulaCache: []
    },
    chartDataChangedTrigger: null,
    calcForecastSizeArrForChart: Ember.computed("chartDataChangedTrigger", function () {
      var _this = this;
      return Object.keys(this.get("data")).map(function (e) {
        return {
          name: e,
          data: _this.get("data.".concat(e))
        };
      });
    }),
    memCacheItemsLengthArrForChart: Ember.computed("jsHeapSizeLimit.length", function () {
      return [{
        name: intl.t("general.name_1695611601622"),
        data: this.get("totalJSHeapSizeArr")
      }, {
        name: intl.t("general.name_1695611601612"),
        data: this.get("usedJSHeapSizeArr")
      }];
    }),
    init: function init() {
      var _this2 = this;
      this._super.apply(this, arguments);
      this.intervalObject = setInterval(function () {
        _this2.runLogger();
      }, this.get("interval"));
    },
    didDestroyElement: function didDestroyElement() {
      this.timeArr = Ember.A([0]);
      clearInterval(this.intervalObject);
    },
    runLogger: function runLogger() {
      var _this3 = this;
      // add time passed from start to time arr
      var elapsedInSec = Math.floor((new Date().getTime() - this.startTime) / 1000);
      this.timeArr.pushObject(elapsedInSec);
      var forecastCache = this.get("cache").getForecastCache();
      Object.keys(this.get("data")).forEach(function (prop) {
        var thisSize = 0;
        for (var key in forecastCache) {
          if (forecastCache[key][prop]) {
            thisSize += Object.keys(forecastCache[key][prop]).length;
          }
        }
        _this3.get("data.".concat(prop)).pushObject(thisSize);
      });
      this.notifyPropertyChange("chartDataChangedTrigger");
      this.setMemoryData();
    },
    setMemoryData: function setMemoryData() {
      this.set("memoryData", {
        usedJSHeapSize: _lodash.default.get(window, "performance.memory.usedJSHeapSize", 0) > 0 ? (_lodash.default.get(window, "performance.memory.usedJSHeapSize") / 1024 / 1024).toFixed(2) : "",
        totalJSHeapSize: _lodash.default.get(window, "performance.memory.usedJSHeapSize", 0) > 0 ? (_lodash.default.get(window, "performance.memory.totalJSHeapSize") / 1024 / 1024).toFixed(2) : "",
        jsHeapSizeLimit: _lodash.default.get(window, "performance.memory.jsHeapSizeLimit", 0) > 0 ? (_lodash.default.get(window, "performance.memory.jsHeapSizeLimit") / 1024 / 1024).toFixed(2) : ""
      });
      var memoryData = this.get("memoryData");
      this.totalJSHeapSizeArr.pushObject(memoryData.totalJSHeapSize);
      this.usedJSHeapSizeArr.pushObject(memoryData.usedJSHeapSize);
      this.jsHeapSizeLimit.pushObject(memoryData.jsHeapSizeLimit);
    },
    actions: {
      onCloseWizard: function onCloseWizard() {
        this.toggleProperty("showPerformanceLogger");
      }
    }
  });
});