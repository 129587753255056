define("budgeta/components/table-edit-search-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    showSearch: false,
    columnName: "",
    enableSort: true,
    columnSelector: Ember.computed(function () {
      return "." + this.get("columnClass");
    }),
    actions: {
      enableSearch: function enableSearch() {
        this.set("showSearch", true);
        Ember.run.scheduleOnce("afterRender", this, function () {
          Ember.$(".main-content .table-editor .table-header .column.name-column .search-input").focus();
        });
      },
      cancelSearch: function cancelSearch() {
        this.set("showSearch", false);
        this.sendAction("filterLinesByColumn", "");
      },
      sort: function sort(sortBy, columnSelector) {
        this.sendAction("sort", sortBy, columnSelector);
      },
      filterLinesByColumn: function filterLinesByColumn(filterBy) {
        this.sendAction("filterLinesByColumn", filterBy);
      }
    }
  });
});