define("budgeta/generic-file/components/budget-lines-intro/budget-lines-intro", ["exports", "react", "react-redux", "@sibp/ui-components", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container"], function (_exports, _react, _reactRedux, _uiComponents, _stepsActions, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = BudgetLinesIntro;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function BudgetLinesIntro() {
    var dispatch = _reactRedux.default.useDispatch();
    var options = {
      hideClose: true,
      height: "675px"
    };
    var header = {
      text: intl.t("generic-file.step-budget-lines-intro.header")
    };
    var body = _react.default.createElement("div", {
      className: "budget-lines-intro-body-container"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.LottieAnimation, {
      animationData: _uiComponents.default.LOTTIE.MonitorClick
    }), _react.default.createElement("div", {
      className: "description"
    }, intl.t("generic-file.step-budget-lines-intro.description")));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button",
      dataHook: "footer-secondary-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.got-it"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CLICKED_SELECT_LINES_INTRO_NEXT);
        dispatch(_stepsActions.default.nextStep());
      }
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});