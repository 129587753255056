define("budgeta/components/intacct-select-stat-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":intacct-select-stat-accounts"],
    intacctStatisticalAccounts: Ember.computed.alias("data.intacctStatisticalAccounts"),
    columnsLayout: [100],
    toggleAll: Ember.computed("intacctStatisticalAccounts.@each.selected", {
      get: function get() {
        var _this$get;
        return !((_this$get = this.get("intacctStatisticalAccounts")) !== null && _this$get !== void 0 && _this$get.some(function (account) {
          return !Ember.get(account, "selected");
        }));
      },
      set: function set(key, value) {
        this.get("intacctStatisticalAccounts").setEach("selected", value);
        return value;
      }
    }),
    accountsExists: Ember.computed.alias("data.intacctStatisticalAccounts.[]"),
    isInImportStatisticalWizard: Ember.computed.alias("data.isInImportStatisticalWizard")
  });
});