define("budgeta/templates/email-success-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kBLjUAYl",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"email-success-modal.message\"],null],false],[\"text\",\" \"],[\"append\",[\"unknown\",[\"model\"]],false],[\"text\",\".\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/email-success-modal.hbs"
    }
  });
});