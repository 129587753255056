define("budgeta/templates/video-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Qk/QzWxC",
    "block": "{\"statements\":[[\"block\",[\"video-modal\"],null,[[\"controllertmp\",\"close\",\"title\",\"templateName\",\"hideFooter\",\"hideHeader\",\"addClass\"],[[\"get\",[null]],[\"helper\",[\"action\"],[[\"get\",[null]],\"close\"],null],[\"get\",[\"title\"]],[\"get\",[\"templateName\"]],[\"get\",[\"hideFooter\"]],[\"get\",[\"hideHeader\"]],\"video-modal-dialog\"]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"video-modal-content\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"budgeta-video\"],null,[[\"videoWidth\",\"videoHeight\",\"youtubeId\",\"playlist\"],[[\"get\",[\"videoWidth\"]],[\"get\",[\"videoHeight\"]],[\"get\",[\"youtubeId\"]],[\"get\",[\"playlist\"]]]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/video-modal.hbs"
    }
  });
});