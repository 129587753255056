define("budgeta/initializers/selectize-mod", ["exports", "ember-cli-selectize/components/ember-selectize"], function (_exports, _emberSelectize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    _emberSelectize.default.reopen({
      _create: function _create(input, callback) {
        // Delete user entered text
        this.$()[0].selectize.setTextboxValue("");
        // Send action to controller
        Ember.get(this, "actionTarget").send(Ember.get(this, "createAction"), input, this);
        // We cancel the creation here, so it's up to you to include the created element
        // in the content and selection property
        callback(null);
      },
      _updateSelection: function _updateSelection(selection) {
        this.set("selection", selection && Ember.get(selection, this.get("_valuePath")));

        // checking if update function was defined
        if (Ember.get(this, "updateAction") && Ember.get(this, "actionTarget")) {
          Ember.get(this, "actionTarget").send(Ember.get(this, "updateAction"), selection, this);
        }
        // allow the observers and computed properties to run first
        Ember.run.schedule("actions", this, function () {
          var value = this.get("value");
          this.sendAction("select-item", selection, value);
          this.sendAction("select-value", value);
        });
      },
      selection: Ember.computed("value", "placeholder", {
        get: function get() {
          var _this = this;
          var value = this.get("value") ? this.get("content").findBy(this.get("_valuePath"), this.get("value")) : null;
          Ember.run.scheduleOnce("afterRender", function () {
            if (value && _this.$()) {
              _this.$().parent().find(".selectize-control.ember-selectize.force-disable-render-preview").attr("title", value.displayName);
            } else if (_this.get("placeholder") && _this.$()) {
              _this.$().parent().find(".selectize-control.ember-selectize.force-disable-render-preview").attr("title", _this.get("placeholder"));
            }
          });
          return value;
        },
        set: function set(key, value) {
          var _this2 = this;
          if (typeof value === "string") {
            value = this.get("content").findBy(this.get("_valuePath"), value);
          }
          if (value && Ember.get(value, this.get("_valuePath"))) {
            this.set("value", Ember.get(value, this.get("_valuePath")));
          } else {
            this.set("value", value || null);
          }
          if (value) {
            Ember.run.scheduleOnce("afterRender", function () {
              _this2.$().parent().find(".selectize-control.ember-selectize.force-disable-render-preview").attr("title", value.displayName);
            });
          }
          return value || null;
        }
      }),
      value: null
    });
  }
  var _default = _exports.default = {
    name: "selectize-mod",
    initialize: initialize
  };
});