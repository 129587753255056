define("budgeta/templates/components/actions-budget-tree-flagged-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qO9bAJIq",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"actions-inner-wrapper\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"svg/star-svg\"],null,[[\"class\"],[\"star\"]]],false],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"budget-menu\"],null,[[\"budget\",\"allBudgets\",\"budgetTypes\",\"goto\",\"delete\",\"copy\",\"showMore\",\"showLess\",\"duplicate\",\"move\",\"rename\",\"share\",\"flag\",\"mergeScenario\",\"saveToModels\",\"featureFlagModel\",\"importWizard\",\"downloadTemplate\",\"scenario\",\"showLineDependencies\"],[[\"get\",[\"budget\"]],[\"get\",[\"allBudgets\"]],[\"get\",[\"budgetTypes\"]],[\"get\",[\"goto\"]],[\"get\",[\"delete\"]],[\"get\",[\"copy\"]],[\"get\",[\"showMore\"]],[\"get\",[\"showLess\"]],[\"get\",[\"duplicate\"]],[\"get\",[\"move\"]],[\"get\",[\"rename\"]],[\"get\",[\"share\"]],[\"get\",[\"flag\"]],[\"get\",[\"mergeScenario\"]],[\"get\",[\"saveToModels\"]],[\"get\",[\"featureFlagModel\"]],[\"get\",[\"importWizard\"]],[\"get\",[\"downloadTemplate\"]],[\"get\",[\"scenario\"]],[\"get\",[\"showLineDependencies\"]]]]],false],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/actions-budget-tree-flagged-menu.hbs"
    }
  });
});