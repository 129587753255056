define("budgeta/templates/budget-template-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "llwRPgV2",
    "block": "{\"statements\":[[\"block\",[\"budget-template-code\"],null,[[\"controllertmp\"],[[\"get\",[null]]]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"tab-content\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"role\",\"tabpanel\"],[\"static-attr\",\"class\",\"tab-pane active\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"flex\"],[\"flush-element\"],[\"text\",\"\\n        \"],[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"fix-size-180\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"budget-template-code.registration-code\"],null],false],[\"close-element\"],[\"append\",[\"helper\",[\"input\"],null,[[\"value\"],[[\"get\",[\"model\",\"code\"]]]]],false],[\"text\",\"\\n      \"],[\"close-element\"],[\"text\",\"\\n      \"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"text\",\"\\n        \"],[\"open-element\",\"a\",[]],[\"static-attr\",\"class\",\"link\"],[\"static-attr\",\"target\",\"_blank\"],[\"dynamic-attr\",\"href\",[\"concat\",[[\"unknown\",[\"model\",\"provider\",\"codeRequestLink\"]]]]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"budget-template-code.request-code\"],null],false],[\"close-element\"],[\"text\",\"\\n      \"],[\"close-element\"],[\"text\",\"\\n      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"error\"],[\"flush-element\"],[\"text\",\"\\n        \"],[\"append\",[\"unknown\",[\"model\",\"error\"]],true],[\"text\",\"\\n      \"],[\"close-element\"],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/budget-template-code.hbs"
    }
  });
});