define("budgeta/components/company-admin-root", ["exports", "react", "react-router-dom", "budgeta/admin/company-admin-app/main/main"], function (_exports, _react, _reactRouterDom, _main) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = CompanyAdminRoot;
  //this is the root react component which holds the admin application
  function CompanyAdminRoot(_ref) {
    var companyId = _ref.companyId;
    var basename = "/admin/".concat(companyId);
    return _react.default.createElement(_reactRouterDom.default.BrowserRouter, {
      basename: basename
    }, _react.default.createElement(_main.default, {
      companyId: companyId,
      routerBasename: basename
    }));
  }
});