define("budgeta/components/wizard-step-models", ["exports", "budgeta/utils/model-store"], function (_exports, _modelStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    modelStore: _modelStore.default.store,
    budgetService: Ember.inject.service(),
    isNonProfitBudget: Ember.computed.alias("budgetService.isNonProfitBudget"),
    displayModels: Ember.computed("isNonProfitBudget", function () {
      if (this.get("isNonProfitBudget")) {
        return this.get("modelStore").filter(function (model) {
          return !Ember.get(model, "unavailableInNonProfitBudget");
        });
      }
      return this.get("modelStore");
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.get("modelStore").setEach("selected", false);
    }
  });
});