define("budgeta/templates/components/max-budgets-limit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "G26C1ilH",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"max-budgets-limit-modal.message\"],null],false],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/max-budgets-limit-modal.hbs"
    }
  });
});