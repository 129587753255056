define("budgeta/components/main/budget/table-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":view-table-edit", "hasRightScroll:hasRightScroll", "controllertmp.isRoleColumnFixed:role-column-fixed", "shortContent:short-content"],
    scrollHeight: 350,
    innerTableWidth: 0,
    hasRightScroll: false,
    shortContent: false,
    performanceLogger: Ember.inject.service(),
    setScrollHeight: function setScrollHeight(view) {
      if (view.$()) {
        var scrollHeight = Ember.$("body").hasClass("non-mac") ? 17 : 16;
        var scrollerWidth = Ember.$("body").hasClass("hiddenscroll") || !view.get("hasRightScroll") ? 0 : scrollHeight;
        var tableWrapper = view.$(".table-editor");
        var tableHeight = tableWrapper.height() - tableWrapper.find(".table-header").outerHeight() - scrollHeight;
        // let tableInnerScrollableHeight = tableWrapper.find('.ember-list-container').outerHeight();
        // tableHeight = tableInnerScrollableHeight < tableHeight ? tableInnerScrollableHeight + 3 : tableHeight;
        view.set("scrollHeight", tableHeight);

        // Setting the width of the table to adjust to scroll
        var viewPortWidth = Ember.$(".main-content .table-editor").width();
        var lineHeight = (this.get("controllertmp.lines.length") || 0) * 40;
        var calculatedTableWidth = this.get("scrollHeight") > lineHeight ? viewPortWidth - scrollerWidth : viewPortWidth;
        view.set("innerTableWidth", calculatedTableWidth);
      }
    },
    setScrollWidth: function setScrollWidth(_this) {
      Ember.run.debounce(_this, "syncScrollVert", 10);
    },
    scrollHeightStyle: Ember.computed("scrollHeight", "innerTableWidth", function () {
      return Ember.String.htmlSafe("height:".concat(this.get("scrollHeight"), "px;width:").concat(this.get("innerTableWidth"), "px;"));
    }),
    calcScrollableWidth: function calcScrollableWidth() {
      var widthMap = {
        "location-column": 250,
        "num-of-employees-column": 120,
        "salary-column": 120,
        "status-column": 100,
        "currency-column": 100,
        "term-column": 100,
        "rate-column": 100,
        "extras-column": 100,
        "per-column": 150,
        "start-date-column": 110,
        "end-date-column": 110,
        "employee-id-column": 115,
        "allocation-column": 125,
        "month-column": 71,
        "type-column": 170,
        "tags-column": 250
      };
      var width = 0;
      Ember.$(".table-editor .table-header .scrollable-wrapper .scrollable .column").each(function (i, col) {
        var classes = col.className.split(" ");
        var tempWidth;
        classes.forEach(function (className) {
          tempWidth = widthMap[className] || tempWidth;
        });
        width += tempWidth || 160;
      });
      return width;
    },
    syncScrollVert: function syncScrollVert() {
      var view = this;
      if (view.$()) {
        var rightScrollWidth = 16;
        var isRoleColumnFixed = view.get("controllertmp.isRoleColumnFixed");
        // update the whole table width if it's smaller than the view port
        var scrollerWidth = Ember.$("body").hasClass("hiddenscroll") || !view.get("hasRightScroll") ? 0 : rightScrollWidth;
        var tableEditorWidth = view.get("calcScrollableWidth")() + 40 + 250 + 132 + scrollerWidth; // 40 = checkbox, 250 = row name, 132 = actions
        tableEditorWidth += isRoleColumnFixed ? 160 : 0;
        var viewPortWidth = Ember.$("body").width();
        view.set("shortContent", tableEditorWidth <= viewPortWidth);
        var tableWidth = view.get("calcScrollableWidth")();
        // Ember.$('.main-content .table-editor .scrollable-wrapper .scrollable').width(tableWidth + 'px');
        view.set("controllertmp.tableScrollWidth", tableWidth);
      }
    },
    syncScrollHeight: function syncScrollHeight() {
      Ember.run.scheduleOnce("afterRender", this, function () {
        this.setScrollHeight(this);
      });
      Ember.run.scheduleOnce("afterRender", this, function () {
        this.get("performanceLogger").measureAction(this.get("controllertmp"));
      });
    },
    scrollHeightObserver: Ember.observer("controllertmp.lines", "controllertmp.lines.length", "hasRightScroll", function () {
      this.syncScrollHeight();
    }),
    syncLines: function syncLines() {
      Ember.run.scheduleOnce("afterRender", this, function () {
        var emberListWrapperHeight = Ember.$(".main-content .table-editor .table-body > .ember-list-view.ember-list-view-list").height();
        var lineHeight = (this.get("controllertmp.lines.length") || 0) * 40;

        // If the list is non existent or the line  Height exceeds
        if (lineHeight > emberListWrapperHeight || emberListWrapperHeight === null) {
          this.set("hasRightScroll", true);
        } else {
          this.set("hasRightScroll", false);
        }
      });
    },
    linesObserver: Ember.observer("controllertmp.lines", "controllertmp.lines.length", "scrollHeight", function () {
      this.syncLines();
    }),
    syncHorizontalScroll: function syncHorizontalScroll() {
      var _this2 = this;
      var view = this;
      var syncScroll = function syncScroll(event) {
        var scrollLeft = event.deltaX;
        if (scrollLeft) {
          var rows = view.$(".scrollable-wrapper");
          event.preventDefault();
          if (rows) {
            rows.each(function (idx, el) {
              el.scrollLeft += scrollLeft;
            });
          }
        }
      };
      var syncScrollFooter = function syncScrollFooter(thisEl) {
        var scrollLeft = thisEl.scrollLeft;
        // view.set('controllertmp.scrollLeft', scrollLeft);
        var rows = view.$(".scrollable-wrapper");
        if (rows) {
          rows.each(function (idx, el) {
            el.scrollLeft = scrollLeft;
          });
        }
      };
      Ember.run.schedule("afterRender", function () {
        if (_this2.$()) {
          Ember.run.schedule("afterRender", function () {
            _this2.setScrollWidth(_this2);
            _this2.$(".ember-list-view-list > div, .table-header").off("mousewheel DOMMouseScroll scroll touchmove keydown", ".scrollable-wrapper").on("mousewheel DOMMouseScroll scroll touchmove keydown", ".scrollable-wrapper", function (event) {
              syncScroll(event);
            });
            _this2.$(".table-footer .scrollable-wrapper").off("mousewheel DOMMouseScroll scroll touchmove keydown").on("mousewheel DOMMouseScroll scroll touchmove keydown", function () {
              syncScrollFooter(this);
            });
            var tableWidth = _this2.get("calcScrollableWidth")();
            Ember.$(".main-content .table-editor .scrollable-wrapper .scrollable").width(tableWidth + "px");
          });
        }
      });
    },
    horizontalScrollObserver: Ember.observer("controllertmp.employeesEdit", "controllertmp.hasMultiEmployees", "controllertmp.hasContractors", "controllertmp.showDepartment", "controllertmp.showNewHiresColumns", "controllertmp.isRoleColumnFixed", function () {
      this.syncHorizontalScroll();
    }),
    focusOut: function focusOut() {
      Ember.$(".main-content .table-editor .table-body .ember-list-view.ember-list-view-list").on("scroll", function () {
        var $activeEl = Ember.$(document.activeElement);
        // if (!$activeEl.hasClass('emp-name-input')) {
        $activeEl.blur();
        // }
      });
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;
      this._super.apply(this, arguments);
      Ember.$(window).off("resize.table").on("resize.table", function () {
        _this3.setScrollHeight(_this3);
        Ember.run.schedule("afterRender", function () {
          _this3.setScrollWidth(_this3);
        });
      });
      this.syncLines();
      this.syncScrollHeight();
      this.syncHorizontalScroll();
      this.focusOut();
    },
    willDestroyElement: function willDestroyElement() {
      var _this4 = this;
      // a fix for a bug in Ember 1.12 see https://github.com/emberjs/ember.js/issues/10869
      ["controllertmp.tableWidth", "controllertmp.lines", "controllertmp.lines.length", "controllertmp.employeesEdit", "controllertmp.hasMultiEmployees", "controllertmp.hasContractors", "controllertmp.showDepartment", "controllertmp.showNewHiresColumns", "controllertmp.isRoleColumnFixed"].forEach(function (path) {
        _this4.removeObserver(path);
      });
      Ember.$(window).off("resize.table");
    }
  });
});