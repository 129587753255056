define("budgeta/templates/components/html5-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EthaabQO",
    "block": "{\"statements\":[[\"open-element\",\"textarea\",[]],[\"dynamic-attr\",\"id\",[\"get\",[null,\"id\"]],null],[\"dynamic-attr\",\"class\",[\"get\",[null,\"class\"]],null],[\"dynamic-attr\",\"row\",[\"get\",[null,\"row\"]],null],[\"dynamic-attr\",\"col\",[\"get\",[null,\"col\"]],null],[\"dynamic-attr\",\"value\",[\"get\",[null,\"value\"]],null],[\"dynamic-attr\",\"name\",[\"get\",[null,\"name\"]],null],[\"dynamic-attr\",\"oninput\",[\"helper\",[\"action\"],[[\"get\",[null]],\"onInput\"],null],null],[\"dynamic-attr\",\"oninvalid\",[\"helper\",[\"action\"],[[\"get\",[null]],\"onInvalid\"],null],null],[\"dynamic-attr\",\"onblur\",[\"helper\",[\"action\"],[[\"get\",[null]],\"onBlur\"],null],null],[\"dynamic-attr\",\"required\",[\"get\",[null,\"required\"]],null],[\"dynamic-attr\",\"placeholder\",[\"get\",[null,\"placeholder\"]],null],[\"dynamic-attr\",\"title\",[\"get\",[null,\"title\"]],null],[\"flush-element\"],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[null,\"error\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"p\",[]],[\"static-attr\",\"class\",\"input-error\"],[\"static-attr\",\"role\",\"alert\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"get\",[null,\"error\"]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/html5-textarea.hbs"
    }
  });
});