define("budgeta/validators/local/array-sum", ["exports", "budgeta/services/ember-service-container", "ember-validations/validators/base"], function (_exports, _emberServiceContainer, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _base.default.extend({
    call: function call() {
      var a = this.model.get(this.property);
      var sum = 0;
      if (!Ember.isBlank(a) && Ember.isArray(a)) {
        a.forEach(function (element) {
          sum += element && !isNaN(element * 1) ? element * 1 : 0;
        });
        if (sum && (sum < this.options.min || sum > this.options.max)) {
          this.errors.pushObject(this.options.message || intl.t("general.array-sum-1", {
            var1: this.options.min,
            var2: this.options.max
          }));
        }
      }
    }
  });
});