define("budgeta/components/account-delete-row", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    classNameBindings: [":delete-account-row", "account.selected:selected"],
    account: Ember.computed.alias("row.account"),
    bsLines: _const.default.DEFAULT_OPENING_BALANCES_ID_NAME,
    budgetLines: Ember.computed("budgetService.budgetLinesAccounts", "budgetService.statisticalBudgetLinesAccounts", "account.isStatisticalType", function () {
      return this.get("account.isStatisticalType") ? this.get("budgetService.statisticalBudgetLinesAccounts") : this.get("budgetService.budgetLinesAccounts");
    }),
    reportingTypeOptions: Ember.computed.alias("budgetService.reportingTypeOptions"),
    showLinesMappingCell: Ember.computed.alias("account.budgetLines.length"),
    showDimensionsCell: Ember.computed.alias("account.dimensions.length"),
    showReportingTypeCell: Ember.computed.alias("account.isStatisticalType"),
    actualAccounts: Ember.computed.alias("budgetService.actualAccounts"),
    actions: {
      removeAccount: function removeAccount(row) {
        this.removeAccount(row);
      }
    }
  });
});