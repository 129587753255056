define("budgeta/templates/components/svg/community-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "odxOj26Y",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"20\"],[\"static-attr\",\"height\",\"20\"],[\"static-attr\",\"viewBox\",\"0 0 20 20\"],[\"static-attr\",\"fill\",\"none\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M6 0.5C4.07 0.5 2.5 2.07 2.5 4C2.5 5.93 4.07 7.5 6 7.5C7.93 7.5 9.5 5.93 9.5 4C9.5 2.07 7.93 0.5 6 0.5ZM14 0.5C12.07 0.5 10.5 2.07 10.5 4C10.5 5.93 12.07 7.5 14 7.5C15.93 7.5 17.5 5.93 17.5 4C17.5 2.07 15.93 0.5 14 0.5ZM2.75 8.5C1.785 8.5 1 9.285 1 10.25V15C1 17.757 3.243 20 6 20C7.271 20 8.4295 19.5199 9.3125 18.7354C8.4935 17.7094 8 16.412 8 15V10.25C8 9.586 8.23736 8.976 8.63086 8.5H2.75ZM10.75 8.5C9.785 8.5 9 9.285 9 10.25V15C9 17.757 11.243 20 14 20C16.757 20 19 17.757 19 15V10.25C19 9.285 18.215 8.5 17.25 8.5H10.75Z\"],[\"static-attr\",\"fill\",\"black\"],[\"static-attr\",\"fill-opacity\",\"0.65\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/community-svg.hbs"
    }
  });
});