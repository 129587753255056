define("budgeta/controllers/budget-info/accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    applicationController: Ember.inject.controller("application"),
    budgetService: Ember.inject.service()
  });
});