define("budgeta/components/attribute-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["budgeta-type-value", "budgeta-type-dropdown"],
    attributeBindings: ["data-key", "data-hide", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    enabled: Ember.computed("readOnly", function () {
      return this.get("readOnly") ? false : true;
    }),
    valueObserver: Ember.observer("value", function () {
      var _this = this;
      Ember.run.next(function () {
        if (_this.get("content")) {
          _this.get("content").forEach(function (item) {
            (item.attributeGroup || []).forEach(function (attr) {
              var defaultValue;
              if (_this.get("budgetModule.budgetAttributes.".concat(attr, ".defaultValueFor.length"))) {
                _this.get("budgetModule.budgetAttributes.".concat(attr, ".defaultValueFor")).forEach(function (item) {
                  if (_this.get("budgetAttributes.".concat(item.attr)) == item.value) {
                    defaultValue = item.defaultValue;
                  }
                });
                if (defaultValue) {
                  _this.set("budgetAttributes." + attr, defaultValue);
                }
              }
            });
          });
        }
      });
    }),
    actions: {
      openFormulaEditor: function openFormulaEditor(component) {
        this.sendAction("openFormulaEditor", component);
      }
    }
  });
});