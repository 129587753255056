define("budgeta/templates/components/budget-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ojXHoA14",
    "block": "{\"statements\":[[\"block\",[\"svg-icon\"],null,[[\"classNames\",\"title\",\"name\"],[\"centerIcon\",[\"get\",[\"iconText\"]],[\"get\",[\"iconName\"]]]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"svg-icon-text\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"iconText\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/budget-lock.hbs"
    }
  });
});