define("budgeta/components/budget-line-input", ["exports", "@babel/runtime/helpers/esm/typeof", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/utils/assumption"], function (_exports, _typeof2, _emberServiceContainer, _const, _budgetUtils, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/no-observers, ember/no-side-effects, ember/closure-actions */

  var periodNames = {
    m: intl.t("general.name_1695611603933").toLowerCase(),
    q: intl.t("general.name_1695611603922").toLowerCase(),
    y: intl.t("general.name_1695611603910").toLowerCase(),
    r: intl.t("general.name_1695611603933").toLowerCase()
  };
  var componentMap = {
    months: {
      component: "custom-amounts",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-custom-amounts"]
    },
    "months-numbers": {
      component: "custom-amounts",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-custom-amounts"],
      args: {
        currencyLabel: intl.t("general.type_of_amount_1698234255114"),
        currencyAttr: "pctCurrency",
        currencyOptions: "currencyNumberOptions"
      }
    },
    "months-pct-currency": {
      component: "custom-amounts",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-custom-amounts"]
    },
    selectbudget: {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown"],
      args: {
        excludeTypes: "assumption,group-model,group-model-parent"
      }
    },
    "selectbudget-expense-calculated": {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budget-without-highlight-value-border"],
      args: {
        readyContent: "expenseCalculatedWithoutParents"
      }
    },
    "selectbudget-has-employees": {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-dropdown-ellipsis", "budget-without-highlight-value-border"],
      args: {
        readyContent: "linesWithEmployees"
      }
    },
    "fixed-with-currency": {
      component: "attribute-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-dropdown-short"],
      contentAliasKey: "context.currencyNumberOptions"
    },
    "selectbudget-multi": {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budget-type-multi", "budget-without-highlight-value-border"],
      args: {
        multiple: true,
        excludeTypes: "assumption,group-model,group-model-parent,group-no-grouping,no-grouping-line"
      }
    },
    "selectbudget-expense": {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budget-without-highlight-value-border"],
      args: {
        type: "expense,expense-from-model,group-expense,group-payroll,group-ps,per-employee,per-new-employee"
      }
    },
    "selectbudget-employee": {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budget-without-highlight-value-border"],
      args: {
        type: "employee"
      }
    },
    "selectbudget-assumption": {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-dropdown-short", "budget-without-highlight-value-border"],
      args: {
        type: "assumption",
        disableAccountId: true
      }
    },
    "selectbudget-assumption-currency": {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-dropdown-short", "overflow-ellipsis", "budget-without-highlight-value-border"],
      args: {
        type: "assumption",
        onlyCurrencyModelLines: true
      }
    },
    "selectbudget-assumption-currency-pct": {
      component: "attribute-budget-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-dropdown-short", "overflow-ellipsis"],
      args: {
        type: "assumption"
      }
    },
    datepicker: {
      component: "attribute-month-year-picker"
    },
    "datepicker-split": {
      component: "attribute-month-year-picker",
      args: {
        split: true
      }
    },
    multiselect: {
      component: "attribute-select",
      addClasses: ["budgeta-type-value", "budget-type-multi", "multi-select"],
      args: {
        multiple: true
      }
    },
    multiselectAndLogic: {
      component: "attribute-select",
      addClasses: ["budgeta-type-value", "budget-type-multi", "multi-select"],
      args: {
        multiple: true,
        andCheckbox: true,
        useAndToggleAction: true,
        addClasses: "budgeta-type-value budget-type-multi multi-select ignore-budgeta-style"
      }
    },
    multiselectAndLogicBenefit: {
      component: "attribute-select",
      addClasses: ["budgeta-type-value", "budget-type-multi", "multi-select"],
      args: {
        multiple: true,
        andCheckbox: true,
        addClasses: "budgeta-type-value budget-type-multi multi-select ignore-budgeta-style"
      }
    },
    tags: {
      component: "power-tags-select",
      // delete this COMMENT after approving development/BUD-13220-Budget-line-with-2-identical-tags ("attribute-select")
      addClasses: ["budgeta-type-value", "budget-type-multi", "multi-select", "no-border-on-over"],
      contentAliasKey: "context.tags",
      args: {
        // delete after approving development/BUD-13220-Budget-line-with-2-identical-tags
        multiple: true,
        tags: true,
        unallowedChars: ","
      }
    },
    dimensionsFilter: {
      component: "dimensions-filter"
    },
    "select-dimension": {
      component: "select-dimension",
      contentAliasKey: "context.dimensionsForPerCapita",
      placeholder: intl.t("general.text_1695611608847")
    },
    "select-dimension-values-multi": {
      component: "select-dimension-values-multi"
    },
    autocomplete: {
      component: "attribute-autocomplete"
    },
    "pct-fixed": {
      component: "attribute-modifier",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-value-function", "budgeta-type-currency"],
      args: {
        shouldCleanFormulaValue: true
      }
    },
    "number-pct-currency": {
      component: "attribute-modifier",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-value-function", "budgeta-type-model-line"],
      args: {
        allowNumbers: true,
        shouldCleanFormulaValue: true
      }
    },
    percent: {
      component: "attribute-modifier",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown"],
      args: {
        percentOnly: true,
        shouldCleanFormulaValue: true
      }
    },
    textarea: {
      component: "attribute-generic",
      addClasses: ["full", "budgeta-type-value", "budgeta-type-textarea", "disable-render-preview"],
      args: {
        type: "textarea"
      }
    },
    switch: {
      component: "budgeta-checkbox",
      addClasses: ["budgeta-type-value", "budgeta-type-checkbox"],
      args: {
        labelInside: true
      }
    },
    currency: {
      component: "attribute-currency",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-value-function"]
    },
    "currency-change": {
      component: "currency-change",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-currency-multi", "budgeta-type-currency"]
    },
    "editable-list": {
      component: "editable-list",
      addClasses: ["budgeta-type-value", "budgeta-type-value-add"]
    },
    "name-value": {
      component: "name-value",
      addClasses: ["budgeta-type-value", "budgeta-name-value", "budgeta-fix-assumptions"]
    },
    "pct-array": {
      component: "pct-array",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-pct-array"]
    },
    select: {
      component: "attribute-select",
      addClasses: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-dropdown-short", "budgeta-type-dropdown-extra-short"]
    }
  };
  var Field = Ember.Object.extend({
    min: Ember.computed.alias("data.validations.min"),
    integersOnly: Ember.computed.alias("data.integersOnly"),
    max: Ember.computed.alias("data.validations.max"),
    step: Ember.computed.alias("data.validations.step"),
    maxlength: Ember.computed.alias("data.validations.maxlength"),
    componentData: Ember.computed("data.displayType", "enumLabels", function () {
      return componentMap[this.get("data.displayType")] || (this.get("enumLabels") ? {
        component: "attribute-group-select",
        addClasses: ["budgeta-type-dropdown-short"]
      } : {
        component: "attribute-generic",
        args: {
          type: "text"
        }
      });
    }),
    label: Ember.computed("budgetService.isNonProfitBudget", "data.nonProfitLabel", "data.label", "field", function () {
      var label = (this.get("budgetService.isNonProfitBudget") ? this.get("data.nonProfitLabel") : this.get("data.label")) || this.get("data.label");
      if (!label) {
        label = _const.default.camelize(this.get("field"));
      }
      return label;
    }),
    digitsOnly: Ember.computed("data.type", "data.digitsOnly", function () {
      return this.get("data.type") === "number" || this.get("data.digitsOnly");
    }),
    classNames: Ember.computed("data.attach", "data.labelAfter", "data.keepSpaceWhenHidden", "field", "componentData", function () {
      var result = "".concat(this.get("data.attach") ? "has-attached" : "", " ").concat(this.get("data.labelAfter") ? "has-label-after" : "", " ").concat(this.get("data.keepSpaceWhenHidden") ? "keep-space" : "");
      if (this.get("componentData.addClasses")) {
        result += this.get("componentData.addClasses").join(" ");
      }
      return result;
    }),
    defaultValue: Ember.computed("data.defaultValue", function () {
      return typeof this.get("data.defaultValue") === "string" ? this.get("data.defaultValue") : JSON.stringify(this.get("data.defaultValue"));
    }),
    hideOnData: Ember.computed("data.hideOn", function () {
      return this.get("data.hideOn") ? JSON.stringify(this.get("data.hideOn")) : null;
    }),
    altLabels: Ember.computed("data.altLabels", function () {
      return this.get("data.altLabels") ? JSON.stringify(this.get("data.altLabels")) : null;
    }),
    currency: Ember.computed("context.computedCurrency", "componentData.args.currency", {
      get: function get() {
        if (this.get("data.currencyAttribute") || this.get("componentData.args.currency")) {
          return this.get("context.budgetAttributes.".concat(this.get("data.currencyAttribute") || this.get("componentData.args.currency")));
        } else {
          return this.get("context.computedCurrency");
        }
      },
      set: function set(key, value) {
        if (this.get("componentData.args.currency")) {
          this.set("context.budgetAttributes.".concat(this.get("componentData.args.currency")), value);
        } else {
          this.set("context.computedCurrency", value);
        }
        return value;
      }
    }),
    currencyOptions: Ember.computed("componentData.args.currencyOptions", "context.currencyOptions", "context.currencyNumberOptions", function () {
      return this.get("componentData.args.currencyOptions") ? this.get("context.currencyNumberOptions") : this.get("context.currencyOptions");
    }),
    isFixed: Ember.computed("data.typeRef", "typeRefAlias", {
      get: function get() {
        return this.get("data.typeRef") && this.get("typeRefAlias");
      },
      set: function set(key, value) {
        this.set("typeRefAlias", value);
        return value;
      }
    }),
    readyContent: Ember.computed("componentData.args.readyContent", function () {
      return this.get("componentData.args.readyContent") && this.get("context.".concat(this.get("componentData.args.readyContent")));
    })
  });
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    changeTrigger: null,
    inGrid: false,
    observers: [],
    init: function init() {
      this._super.apply(this, arguments);
      this.set("tooltipIconText", {
        Scenario: this.get("intl").t("tooltips.scenario-shift-multiply-action")
      });
    },
    filterFieldMethods: {
      releaseTo: function releaseTo(budgeService, thisData, enumLabels, selectValue) {
        var newLabels = [];
        var enumList = Ember.get(thisData, "enum"); // getting the enum value
        var budgetCodes = _budgetUtils.default.calculatBudgetReleaseCodes(Ember.get(budgeService, "selectedRoot.absoluteRoot")) || []; // setting the budget codes
        enumList = enumList.filter(function (element, i) {
          // if the element is not selected Value, we will check that it is still valid
          if (element !== selectValue) {
            // if the element does not appear in the budget we will remove it
            if (!budgetCodes.includes(element)) {
              return false; // return that the element should be filtered
            }
          }
          newLabels.push(enumLabels[i]); // adding the filtered enum label to the list
          return true;
        });
        Ember.set(thisData, "enum", enumList);
        return newLabels; // return the new labels list
      }
    },
    enabled: Ember.computed("readOnly", function () {
      return !this.get("readOnly");
    }),
    readOnlyString: Ember.computed("readOnly", function () {
      return this.get("readOnly") ? "true" : null;
    }),
    refBudgetLines: Ember.computed("budgetLine", function () {
      return this.get("budgetLine.isModelLine") ? _assumption.default.getModelReferences(this.get("store"), this.get("budgetLine")) : null;
    }),
    showName: Ember.computed("budgetLine.budgetType", function () {
      Ember.run.debounce(this, "setupObservers", 100);
      return this.get("budgetLine.budgetType") === "expense-benefit";
    }),
    nameLabel: Ember.computed("budgetLine.budgetType", function () {
      return this.get("budgetLine.budgetType") === "expense-benefit" ? intl.t("general.benefit_name_1698234255114") : intl.t("general.label_1695611611893");
    }),
    nameIsReadOnly: Ember.computed("readOnly", function () {
      return this.get("readOnly") || this.get("disableNameEdit") ? "readonly" : undefined;
    }),
    selectedPeriod: Ember.computed("budgetAttributes.period", function () {
      return periodNames[this.get("budgetAttributes.period")];
    }),
    selectedPeriodChanged: Ember.observer("selectedPeriod", "amountTypeOptions", "atOptions", "computedCurrency", function () {
      var _this = this,
        selectedPeriod = this.get("selectedPeriod");
      var handleOption = function handleOption(opt) {
        var titleCase = function titleCase(str) {
          return "".concat(str[0].toUpperCase()).concat(str.slice(1).toLowerCase());
        };
        var val = opt.get("label").replaceAll("__PERIOD__", selectedPeriod).replaceAll("__AMOUNT__", _this.get("computedCurrency") === "%" ? intl.t("budget_modules.expense-benefit.budget-attributes.amount.alt-labels-0") : intl.t("budget_modules.expense-benefit.budget-attributes.amount.alt-labels-1"));
        opt.set("name", titleCase(val));
      };
      if (this.get("atOptions")) {
        this.get("atOptions").forEach(handleOption);
        var onMonthOption = this.get("atOptions").findBy("value", "on");
        if (this.get("budgetAttributes.period") === "y" && !onMonthOption) {
          this.get("atOptions").pushObject(Ember.Object.create({
            id: this.get("atOptions.length"),
            value: "on",
            label: intl.t("general.name_1695611601157"),
            name: intl.t("general.name_1695611601157")
          }));
        } else if (this.get("budgetAttributes.period") !== "y" && onMonthOption) {
          this.get("atOptions").removeObject(onMonthOption);
          if (this.get("budgetAttributes.at") === "on") {
            this.set("budgetAttributes.at", this.get("atOptions.firstObject.value"));
          }
        }
      }
      if (this.get("amountTypeOptions")) {
        this.get("amountTypeOptions").forEach(handleOption);
      }
    }),
    currencyNumberOptions: Ember.computed("currencyOptions", "refBudgetLines.[]", function () {
      return _assumption.default.getModelCurrencyOptions(this.get("currencyOptions"), this.get("refBudgetLines.length"));
    }),
    currencyOptions: Ember.computed("budgetLine.absoluteRoot.fxCurrencies.[]", "budgetAttributes.currency", function () {
      return _const.default.currencyOptions(this.get("budgetLine"));
    }),
    roleOptions: Ember.computed("budgetService.roles.length", function () {
      return (this.get("budgetService.roles") || []).map(function (role) {
        return {
          id: role,
          name: role,
          value: role
        };
      });
    }),
    computedCurrency: Ember.computed("budgetAttributes.currency", "budgetAttributes.benefitType", "budgetLine.isBenefit", {
      get: function get() {
        if (this.get("budgetAttributes.benefitType") === 0 && this.get("budgetLine.isBenefit")) {
          return "%";
        } else {
          return this.get("budgetAttributes.currency");
        }
      },
      set: function set(key, value) {
        this.set("budgetAttributes.currency", value);
        return value;
      }
    }),
    placeHolderAttributes: Ember.computed("budgetLine", "budgetService.scenario", "budgetLine.parent", "budgetLine.inheritedAttributes", function () {
      return _budgetUtils.default.getInheritedAttributes(this.get("budgetLine"), this.get("budgetService.scenario"), null, true);
    }),
    assumptions: Ember.computed("budgetLine", "budgetLine.budgetAttributes", function () {
      return _assumption.default.getFixedAssumptions(this.get("budgetLine.root.absoluteRoot"), this.get("budgetService.scenario"), this.get("budgetLine"), {
        withParent: true
      });
    }),
    assumptionLines: Ember.computed("budgetLine.root.absoluteRoot", "budgetLine", function () {
      var result = [];
      if (this.get("budgetLine.parent.budgetType") === "group-model") {
        result = _const.default.findBudgetsByTypes(this.get("budgetLine.root.absoluteRoot"), "assumption", this.get("budgetService.scenario"));
        result.removeObject(this.get("budgetLine"));
      }
      return result;
    }),
    budgetAttributes: Ember.computed("budgetLine", "budgetLine.budgetAttributes", "budgetService.scenario", "mainController.scenario", function () {
      if (Ember.isNone(this.get("budgetLine")) || this.get("budgetLine").isDestroyed) {
        return null;
      } else {
        Ember.run.sync(); // make sure we get the correct scenario status
        if (this.get("budgetLine") && this.get("budgetService.scenario") && !_budgetUtils.default.hasScenario(this.get("budgetLine"), this.get("budgetService.scenario"))) {
          _budgetUtils.default.createEmptyScenario(this.get("budgetLine"), this.get("budgetService.scenario"), this.get("store"));
        }
        return _budgetUtils.default.getBudgetAttributesWithScenario(this.get("budgetLine"), this.get("budgetService.scenario"));
      }
    }),
    paymentTermOptions: Ember.computed("budgetLine", "budgetAttributes.amount", "budgetAttributes.subscriptionPeriod", "budgetAttributes.currency", function () {
      var _this = this;
      var subscriptionPeriod = _const.default.convertToNum(_this.get("budgetAttributes.subscriptionPeriod"));
      var factorMap = {
        m: 1 / (subscriptionPeriod || 1),
        q: 3 / (subscriptionPeriod || 1),
        y: 12 / (subscriptionPeriod || 1)
      };
      if (this.get("budgetLine.budgetModule.budgetAttributes.paymentTerm.enum")) {
        var result = this.get("budgetLine.budgetModule.budgetAttributes.paymentTerm.enum").map(function (value, idx) {
          var label = "";
          if (_this.get("budgetLine.budgetModule.budgetAttributes.paymentTerm.hideAmountInOptions")) {
            label = _this.get("budgetLine.budgetModule.budgetAttributes.paymentTerm.enumLabels")[idx];
          } else {
            var amount = (_this.get("budgetAttributes.amount") || 0) * (factorMap[value] || 1);
            if (amount && !isNaN(amount)) {
              label = _const.default.formatCurrency(amount, _this.get("budgetAttributes.currency") || _this.get("budgetLine.forecastAttributes.currency"), 2) + " ";
            }
            label += _this.get("budgetLine.budgetModule.budgetAttributes.paymentTerm.enumLabels")[idx];
          }
          return {
            id: idx,
            value: value,
            label: label,
            name: label
          };
        });
        return result.filter(function (o) {
          return o.label;
        });
      } else {
        return [];
      }
    }),
    setOptions: function setOptions(key, thisData) {
      var _this2 = this;
      if (key === "currency") {
        return;
      }
      var options = [];
      var enumLabels = (this.get("budgetService.isNonProfitBudget") ? thisData.enumLabelsNonProfit : thisData.enumLabels) || thisData.enumLabels || [];
      var enumType;
      if (key === "paymentAfter" && !thisData.enum) {
        if (enumLabels.indexOf("Auto") !== -1) {
          enumType = _const.default.EMPLOYEE_ENUM_PAYMENTAFTER;
        } else {
          enumType = _const.default.GROUP_PAYROLL_ENUM_PAYMENTAFTER;
        }
      }
      enumLabels.forEach(function (enumLabel, i) {
        var label = enumLabel === intl.t("general.working_budget_1698234255113") && !_this2.get("budgetService.forecastVersions.length") ? intl.t("general.name_1695611602107") : enumLabel;
        var thisValue;

        // thisData.enum doesnt exist- solve issue for paymentAfter scehma BUD-20444
        if (enumType) {
          thisValue = enumType[i];
        } else {
          thisValue = thisData.enum[i];
        }
        var option = Ember.Object.create({
          id: i,
          value: thisValue,
          label: label,
          name: label
        });
        if (typeof thisData.attributeGroups !== "undefined") {
          option.set("attributeGroup", thisData.attributeGroups[i]);
        }
        options.push(option);
      });
      if (!thisData.disableOptionsCreate) {
        this.set(key + "Options", options);
      }
      if (this.get("budgetAttributes.".concat(key)) === undefined) {
        this.set("budgetAttributes.".concat(key), options[0] && options[0].value);
      }
    },
    tags: Ember.computed("budgetService.tags.[]", "budgetLine.parent.inheritedAttributes.tags.[]", function () {
      var _this3 = this;
      return this.get("budgetService.tags").filter(function (tag) {
        if (tag.added) {
          return tag.name !== "";
        }
        var tagName = Ember.get(tag, "name");
        return _this3.get("budgetLine.parent.inheritedAttributes.tags") && !_this3.get("budgetLine.parent.inheritedAttributes.tags").findBy("name", tagName);
      }).map(function (tag) {
        var tagName = Ember.get(tag, "name");
        return {
          id: tagName,
          name: tagName,
          value: tagName
        };
      });
    }),
    _budgets: Ember.computed("treeManager.budgetsList.@each.budget", function () {
      if (!this.get("treeManager.budgetsList")) {
        return [];
      }
      return this.get("treeManager.budgetsList").map(function (budgetList) {
        return budgetList.get("budget");
      });
    }),
    linesWithEmployees: Ember.computed("_budgets.@each.budgetType", function () {
      if (!this.get("treeManager.budgetsList")) {
        return [];
      }
      var result = Ember.A([this.get("budgetService.selectedRoot")]),
        empGroups = this.get("treeManager.budgetsList").filter(function (l) {
          return Ember.get(l, "budget.budgetType") === "group-payroll" || Ember.get(l, "budget.budgetType") === "group-ps";
        });
      empGroups.forEach(function (g) {
        return result.addObjects(g.get("budget.location").filter(function (b) {
          return !b.get("name").startsWith("ENCRYPTED_");
        }));
      });
      return result;
    }),
    expenseCalculatedWithoutParents: Ember.computed("_budgets.@each.hasChildren", "budgetService.scenario", "budgetLine", function () {
      if (!this.get("treeManager.budgetsList")) {
        return [];
      }
      var parentList = (this.get("budgetLine.location") || Ember.A([])).mapBy("uniqueId");
      if (this.get("budgetLine.absoluteRoot.uniqueId")) {
        parentList.push(this.get("budgetLine.absoluteRoot.uniqueId"));
      }
      var currentSelection = this.get("budgetLine.budgetAttributes.allocatedFrom");
      var result = Ember.A();
      if (currentSelection && parentList.includes(currentSelection)) {
        parentList.removeObject(currentSelection);
      }
      return result.addObjects(this.get("treeManager.budgetsList").mapBy("budget").filter(function (line) {
        return !Ember.get(line, "isModelLine") && !parentList.includes(line.get("uniqueId")) && !Ember.get(line, "isNoGrouping");
      }));
    }),
    dimensionsForPerCapita: Ember.computed("budgetService.dimensions.@each.name", function () {
      var result = (this.get("budgetService.dimensions") || []).map(function (v) {
        return {
          id: Ember.get(v, "uniqueId"),
          text: Ember.get(v, "pluralName")
        };
      });
      result.insertAt(0, {
        id: null,
        text: intl.t("general.text_1695611608847")
      });
      if (result.length && !result.findBy("id", this.get("value"))) {
        this.set("value", "");
      }
      return result;
    }),
    displaySectionsTitle: Ember.computed("budgetLine.isModelLine", "sections.length", function () {
      return this.get("budgetLine.isModelLine") || this.get("sections.length") > 1;
    }),
    sections: Ember.computed("budgetService.isNonProfitBudget", "budgetLine.budgetModule", "budgetService.scenario", function () {
      var _this4 = this;
      var module = this.get("budgetLine.budgetModule");
      var isModelLine = this.get("budgetLine.isModelLine");
      var addedFields = [];
      var excludeFields = [];
      var allFields = [];
      var isNonProfitBudget = this.get("budgetService.isNonProfitBudget");
      var mapField = function mapField(field) {
        var _thisData;
        var enumType;
        // Hide some properties for the add employee popup
        if (_this4.get("addEmpPopup") && module.get("hideOnEmployeePopup") && module.get("hideOnEmployeePopup").includes(field)) {
          excludeFields.addObject(field);
          return null;
        }
        var thisData = module.get("budgetAttributes.".concat(field));
        thisData = Ember.isArray(thisData) ? thisData[0] : thisData;
        if (!thisData.displayType && (0, _typeof2.default)(thisData) === "object") {
          // find the first property that has the display data
          var found = Object.keys(thisData).find(function (attr) {
            return thisData[attr] && thisData[attr].displayType;
          });
          if (found) {
            thisData = thisData[found];
          }
        }
        var enumLabels = (_this4.get("budgetService.isNonProfitBudget") ? thisData.enumLabelsNonProfit : thisData.enumLabels) || thisData.enumLabels;
        var componentData = componentMap[thisData.displayType];
        if (enumLabels) {
          var filterFieldMethods = _this4.get("filterFieldMethods");
          var oldValue = _this4.get("budgetAttributes." + field); // storing the value

          // checking if we have afilter function for this field
          if (filterFieldMethods[field]) {
            thisData = Object.assign({}, thisData); // asking to clone this Data so we won't override the module
            // filtering the labels
            enumLabels = filterFieldMethods[field](_this4.get("budgetService"), thisData, enumLabels, oldValue);

            // setting the labels in this Data
            if (_this4.get("budgetService.isNonProfitBudget")) {
              Ember.set(thisData, "enumLabelsNonProfit", enumLabels);
            } else {
              Ember.set(thisData, "enumLabels", enumLabels);
            }
          }
          var defaultValeue;
          if (enumLabels.length) {
            // thisData.enum doesnt exist- solve issue for paymentAfter scehma BUD-20444
            if (field === "paymentAfter" && !thisData.enum) {
              if (enumLabels.indexOf("Auto") !== -1) {
                enumType = _const.default.EMPLOYEE_ENUM_PAYMENTAFTER;
              } else {
                enumType = _const.default.GROUP_PAYROLL_ENUM_PAYMENTAFTER;
              }
              defaultValeue = enumType[0];
            } else {
              defaultValeue = thisData.enum[0];
            }
          }
          // make sure the current value is one of the enum options
          if (thisData && defaultValeue && (thisData.enum && thisData.enum.indexOf(oldValue) < 0 || enumType && enumType.indexOf(oldValue) < 0)) {
            _this4.set("budgetAttributes." + field, defaultValeue);
          }
          _this4.setOptions(field, thisData);
        }
        addedFields.pushObject(field);
        var contentAliasKey = componentData && componentData.contentAliasKey || thisData.displayTypeArg === "tags" && "context.tags" || thisData.displayTypeArg && "context.".concat(thisData.displayTypeArg, "Options") || "context.".concat(field, "Options");
        var fieldData = Field.extend({
          field: field,
          context: _this4,
          data: thisData,
          enumLabels: enumLabels,
          value: Ember.computed.alias("context.budgetAttributes.".concat(field)),
          content: Ember.computed.alias(contentAliasKey),
          error: Ember.computed.alias("context.errors.".concat(field)),
          period: Ember.computed.alias("context.budgetAttributes.".concat(thisData.period || "period")),
          arraySize: Ember.computed("data.displayTypeArg", "context.budgetAttributes.".concat(thisData.displayTypeArg), function () {
            var displayTypeArg = this.get("data.displayTypeArg");
            return displayTypeArg && this.get("context.budgetAttributes.".concat(displayTypeArg));
          }),
          placeholder: Ember.computed("context.placeHolderAttributes.".concat(field), "componentData.placeholder", function () {
            return this.get("componentData.placeholder") || this.get("context.placeHolderAttributes.".concat(this.get("field")));
          })
        });
        var options = (_thisData = thisData) !== null && _thisData !== void 0 && _thisData.typeRef ? {
          typeRefAlias: Ember.computed.alias("context.budgetAttributes.".concat(thisData.typeRef))
        } : {};
        var thisField = fieldData.create(options);
        allFields.pushObject(thisField);
        return thisField;
      };
      if (module && module.get("sections")) {
        var deleteSelections = [];
        var result = Object.keys(module.get("sections")).map(function (s) {
          var sectionData = module.get("sections.".concat(s));
          if (sectionData.hide || sectionData.excludeParentTypes && sectionData.excludeParentTypes.includes(_this4.get("budgetLine.parent.budgetType")) || sectionData.excludeTypes && sectionData.excludeTypes.includes(_this4.get("budgetLine.budgetType")) || sectionData.hideWhenEmpty && Ember.isEmpty(_this4.get("budgetAttributes." + sectionData.hideWhenEmpty))) {
            deleteSelections.push(s);
          }
          if (sectionData.order === undefined) {
            sectionData.order = 99;
          }
          var sectionTitle = sectionData.sectionTitle || sectionData.moduleTitle && intl.t(sectionData.moduleTitle) || s;
          if (isNonProfitBudget && s.toLowerCase() === intl.t("general.bookings_1698234255113")) {
            sectionTitle = intl.t("general.name_1695611602498");
          }
          var sectionResult = {
            id: s,
            title: sectionTitle,
            titleHyphenated: s.replace(/\s+/g, "-").toLowerCase(),
            data: sectionData,
            fields: (module.get("sections.".concat(s, ".budgetAttributes")) || []).map(mapField).filter(function (f) {
              return f && f.get("data.displayType") !== "hidden";
            })
          };
          if (sectionData.hideOn) {
            sectionResult.hideOn = JSON.stringify(sectionData.hideOn);
          }
          if (sectionData.injectComponent) {
            sectionResult.injectComponent = sectionData.injectComponent;
          }
          if (sectionData.rows) {
            sectionResult.rows = sectionData.rows.map(function (row) {
              return row.map(function (f) {
                return sectionResult.fields.findBy("field", f);
              });
            });
            sectionResult.showRowSection = true;
          } else {
            sectionResult.rows = [sectionResult.fields];
            sectionResult.showRowSection = false;
          }
          if (isModelLine) {
            var moduleTitleTranslation = sectionData.moduleTitle && intl.t(sectionData.moduleTitle);
            var sectionsTitles = [intl.t("budget_modules_sections.dimensions"), intl.t("budget_modules_sections.scenario")];
            sectionResult.showSectionTitle = sectionData.sectionTitle || sectionsTitles.includes(moduleTitleTranslation) || sectionsTitles.includes(s);
          } else {
            sectionResult.showSectionTitle = true;
          }
          return sectionResult;
        }).filter(function (s) {
          return s;
        }).sortBy("data.order");
        result = result.filter(function (s) {
          return deleteSelections.indexOf(s.id) === -1;
        });

        // add all unused fields to Main section
        if (!module.get("hideMainSection")) {
          var additionalFields = Object.keys(module.get("budgetAttributes")).filter(function (f) {
            return !addedFields.includes(f);
          }).map(mapField).filter(function (f) {
            return f && f.get("data.displayType") !== "hidden";
          });
          if (additionalFields.length) {
            result.insertAt(0, {
              rows: [additionalFields],
              fields: additionalFields
            });
          }
        }

        // add attached fields to fields with attached fields
        allFields.filterBy("data.attach").forEach(function (f) {
          return Ember.set(f, "attached", allFields.findBy("field", Ember.get(f, "data.attach")));
        });
        return result;
      }
      return [];
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.debounce(this, "setupObservers", 100);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      Ember.run.debounce(this, "setupObservers", 100);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removeObservers();
    },
    removeObservers: function removeObservers() {
      var _this5 = this;
      Ember.Logger.debug("removeObservers");
      this.$() && this.$().off("focus", 'input[name="name"]');
      this.get("observers").forEach(function (item) {
        _this5.removeObserver(item.key, item.func);
      });
      if (!this.isDestroying || !this.isDestroyed) {
        this.set("observers", []);
      }
    },
    setupObservers: function setupObservers() {
      var _this6 = this;
      Ember.run.scheduleOnce("afterRender", function () {
        var view = _this6,
          container = _this6.$();
        _this6.removeObservers();
        if (!container) {
          return;
        }
        Ember.Logger.debug("setupObservers");
        container.find("[data-alt-labels]").each(function (idx, el) {
          var $el = Ember.$(el);
          var $label = $el.find(">label").eq(0);
          var $tips = $el.find(".help-iq");
          var originalLabel = $el.data("original-label") || $label.text();
          var altLables = $el.data("alt-labels");
          var changeLabel = function changeLabel(v) {
            var curValue = v.get("budgetAttributes." + altLables.key);
            if (!Ember.isEmpty(curValue)) {
              var altLabel = altLables.labels[curValue.toString()];
              if (altLabel) {
                $label.text(altLabel);
              } else {
                $label.text(originalLabel);
              }
              if ($tips.length) {
                $tips.hide();
                if ($tips.filter("[data-alt-key=\"".concat(curValue, "\"]")).length) {
                  $tips.filter("[data-alt-key=\"".concat(curValue, "\"]")).show();
                } else {
                  $tips.first().show();
                }
              }
            }
          };
          changeLabel(view);
          view.addObserver("budgetAttributes." + altLables.key, changeLabel);
          view.get("observers").pushObject({
            key: "budgetAttributes." + altLables.key,
            func: changeLabel
          });
        });
        container.find("[data-hide]").each(function (idx, el) {
          var $el = Ember.$(el);
          var hideOn = $el.data("hide");
          if (!hideOn) {
            return;
          }
          var hideUnless = $el.data("hide-unless");
          var key = $el.data("key");
          var showHide = function showHide(v /*, property*/) {
            Ember.run.scheduleOnce("afterRender", function () {
              var attrData = view.get("budgetLine.budgetModule.budgetAttributes.".concat(key));
              if (attrData) {
                hideOn = Ember.isArray(attrData) ? attrData[0].hideOn : attrData.hideOn;
                hideUnless = Ember.isArray(attrData) ? attrData[0].hideUnless : attrData.hideUnless;
              }
              $el.removeClass("attribute-hidden").trigger("show");
              $el.next(".attribute-hidden-extra").remove();
              for (var k in hideUnless) {
                var prop = "budgetAttributes." + k;
                if (v.get(prop) === undefined || v.get(prop) === null) {
                  prop = "placeHolderAttributes." + k;
                }
                if (hideUnless[k] === false && !v.get(prop) || hideUnless[k].indexOf(null) >= 0 && v.get(prop) === undefined || hideUnless[k] && (hideUnless[k].indexOf(v.get(prop)) >= 0 || hideUnless[k][0] === false && !v.get(prop))) {
                  return;
                }
              }
              var shouldHide = false;
              for (var _k in hideOn) {
                var _prop = "budgetAttributes." + _k;
                if (v.get(_prop) === undefined || v.get(_prop) === null) {
                  _prop = "placeHolderAttributes." + _k;
                }
                if (hideOn[_k] === false && !v.get(_prop) || Ember.isArray(hideOn[_k]) && hideOn[_k].indexOf(null) >= 0 && v.get(_prop) === undefined || hideOn[_k] && (hideOn[_k].indexOf(v.get(_prop)) >= 0 || Ember.isArray(hideOn[_k]) && hideOn[_k][0] === false && !v.get(_prop))) {
                  shouldHide = true;
                  break;
                }
              }
              if (shouldHide) {
                $el.addClass("attribute-hidden");
                $el.after("<div class='budgeta-type-value attribute-hidden attribute-hidden-extra'></div>");
                if ($el.data("default") !== undefined && view.get("budgetAttributes")) {
                  // reset to default
                  var defaultValue = $el.data("default");
                  var thisValue = view.get("budgetAttributes." + $el.data("key"));
                  if (Ember.isArray(defaultValue) && thisValue) {
                    for (var i = 0; i < thisValue.length; i++) {
                      thisValue[i] = null;
                    }
                  } else {
                    view.set("budgetAttributes." + $el.data("key"), defaultValue);
                  }
                }
              }
              view.updateEvenOddClassDebounced();
            });
          };
          var bindEvent = function bindEvent(key) {
            view.addObserver("budgetAttributes." + key, showHide);
            view.get("observers").pushObject({
              key: "budgetAttributes." + key,
              func: showHide
            });
          };
          showHide(view);
          if (hideOn) {
            var keysToWatch = Object.keys(hideOn);
            if (hideUnless) {
              keysToWatch = keysToWatch.concat(Object.keys(hideUnless));
            }
            keysToWatch.forEach(bindEvent);
          }
        });
        view.updateEvenOddClassDebounced();
        container.on("focus", 'input[name="name"]', function () {
          var $el = Ember.$(this),
            initial = $el.parent().data("initial");
          if ($el.val() === initial && !$el.attr("readonly")) {
            $el.val("");
            $el.parent().data("initial", "");
          }
        });
      });
    },
    updateEvenOddClassDebounced: function updateEvenOddClassDebounced() {
      Ember.run.debounce(this, "updateEvenOddClass", 100);
    },
    updateEvenOddClass: function updateEvenOddClass() {
      if (this.$()) {
        _const.default.updateEvenOddClass(this.$());
      }
    },
    actions: {
      gotoModel: function gotoModel(model) {
        this.sendAction("gotoModel", model);
      },
      changed: function changed(rerender, disableSave) {
        this.sendAction("changed", undefined, disableSave);
        if (rerender) {
          this.sendAction("renderPreview");
        }
      },
      openFormulaEditor: function openFormulaEditor(component) {
        this.sendAction("openFormulaEditor", component);
      },
      showLineDependencies: function showLineDependencies(budgetLine) {
        this.sendAction("showLineDependencies", budgetLine);
      }
    }
  });
});