define("budgeta/templates/components/dashboard-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fo9B9/4z",
    "block": "{\"statements\":[[\"block\",[\"modal-dialog\"],null,[[\"close\",\"confirm\",\"title\",\"templateName\",\"lastStep\",\"hideCancel\",\"confirmActionName\",\"addClass\"],[[\"get\",[\"close\"]],[\"get\",[\"confirm\"]],[\"get\",[\"title\"]],[\"get\",[\"templateName\"]],[\"get\",[\"lastStep\"]],[\"get\",[\"hideCancel\"]],[\"get\",[\"confirmActionName\"]],[\"get\",[\"addClass\"]]]],0]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/dashboard-modal.hbs"
    }
  });
});