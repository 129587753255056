define("budgeta/routes/budget-info/index", ["exports", "budgeta/mixins/budgeta-route"], function (_exports, _budgetaRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_budgetaRoute.default, {
    budgetService: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      Ember.run.next(this, function () {
        this.transitionTo("login");
      });
    },
    model: function model(params, transition) {
      this.set("budgetService.budgetInfoToken", transition.params["budget-info"].token);
    }
  });
});