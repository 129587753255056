define("budgeta/models/dimension-mapping", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    financialDimensionId: _emberData.default.attr("string"),
    financialDimensionName: _emberData.default.attr("string"),
    financialInternalName: _emberData.default.attr("string"),
    financialDimensionDisplay: Ember.computed("financialDimensionId", "financialDimensionName", function () {
      return "".concat(this.get("financialDimensionName"), " (").concat(this.get("financialDimensionId"), ")");
    }),
    dimensionId: _emberData.default.attr("string"),
    dimension: Ember.computed("budget", "dimensionId", function () {
      return (this.get("budget.dimensions") || Ember.A([])).findBy("uniqueId", this.get("dimensionId"));
    }),
    budget: _emberData.default.belongsTo("budget", {
      async: false,
      inverse: "dimensionsMapping"
    })
  });
});