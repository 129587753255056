define("budgeta/utils/__mocks__/import", ["@babel/runtime/helpers/esm/objectSpread2"], function (_objectSpread2) {
  /* eslint-disable no-undef */
  "use strict";

  var ImportUtils = function () {
    var realModule = jest.requireActual("../import");
    return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, realModule), {}, {
      __esModule: true
    });
  }();
  module.exports = ImportUtils;
});