define("budgeta/templates/components/dimensions-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zO0B1M7V",
    "block": "{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"dimensionFilters\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"dynamic-attr\",\"id\",[\"concat\",[[\"unknown\",[\"dimFilter\",\"elementId\"]]]]],[\"static-attr\",\"class\",\"budgeta-type-value budget-type-multi multi-select\"],[\"dynamic-attr\",\"data-key\",[\"concat\",[[\"unknown\",[\"data-key\"]]]]],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"unknown\",[\"dimFilter\",\"dimension\",\"name\"]],false],[\"close-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"cssClass\",\"enabled\",\"multiple\",\"content\",\"value\",\"optionIdPath\",\"optionValuePath\",\"optionLabelPath\"],[\"wrap\",[\"get\",[\"enabled\"]],true,[\"get\",[\"dimFilter\",\"content\"]],[\"get\",[\"dimFilter\",\"filter\",\"values\"]],\"uniqueId\",\"uniqueId\",\"displayName\"]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"dimFilter\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/dimensions-filter.hbs"
    }
  });
});