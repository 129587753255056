define("budgeta/components/formula-editor-modal-old", ["exports", "budgeta/components/modal-new"], function (_exports, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function moveCaretToEnd(el) {
    if (typeof el.selectionStart === "number") {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange !== "undefined") {
      el.focus();
      var range = el.createTextRange();
      range.collapse(false);
      range.select();
    } else {
      var _range = document.createRange();
      var sel = window.getSelection();
      _range.selectNodeContents(el);
      _range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(_range);
      el.focus();
    }
  }
  var _default = _exports.default = _modalNew.default.extend({
    classNames: ["formula-editor-modal"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set("controllertmp.initialValue", this.get("controllertmp.model.value"));
      Ember.run.schedule("afterRender", function () {
        var fomulaTextarea = Ember.$(".formula-textarea");
        if (fomulaTextarea && fomulaTextarea.length) {
          moveCaretToEnd(fomulaTextarea.get(0));
          fomulaTextarea.on("paste", function (e) {
            e.preventDefault();
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      // a fix for a bug in Ember 1.12 see https://github.com/emberjs/ember.js/issues/10869
      this.removeObserver("controllertmp.model.value");
      this._super();
    },
    valueObserver: Ember.observer("controllertmp.model.value", function () {
      Ember.run.scheduleOnce("afterRender", function () {
        var fomulaTextarea = Ember.$(".formula-textarea");
        if (fomulaTextarea && fomulaTextarea.length) {
          moveCaretToEnd(fomulaTextarea.get(0));
        }
      });
    }),
    mouseUp: function mouseUp(e) {
      var fomulaTextarea = Ember.$(e.target).closest(".formula-textarea");
      if (fomulaTextarea.length) {
        moveCaretToEnd(fomulaTextarea.get(0));
      }
    },
    keyUp: function keyUp(e) {
      var fomulaTextarea = Ember.$(e.target).closest(".formula-textarea");
      if (fomulaTextarea.length) {
        moveCaretToEnd(fomulaTextarea.get(0));
      }
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === 8) {
        this.get("controllertmp").send("backspace");
        this.get("controllertmp").send("keyPress", e);
        return e.preventDefault();
      }
    },
    keyPress: function keyPress(e) {
      if (e.keyCode === 13) {
        // enter
        return e.preventDefault();
      }
      if (e.keyCode === 8) {
        this.get("controllertmp").send("backspace");
        return e.preventDefault();
      }
      this.get("controllertmp").send("keyPress", e);
    }
  });
});