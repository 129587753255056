define("budgeta/components/dimensions-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    budgetService: Ember.inject.service(),
    recalcFilters: function recalcFilters() {
      var _this = this;
      var result = [];
      var ignoreDimensionsTypes = this.get("budget.ignoreDimensionsTypes") || [];
      if (this.get("budgetAttributes")) {
        if (!this.get("budgetAttributes.filterDimensions")) {
          this.set("budgetAttributes.filterDimensions", []);
        }
        (this.get("budgetService.dimensions") || []).forEach(function (dim) {
          var filter = _this.get("budgetAttributes.filterDimensions").filter(function (v) {
            return v;
          }).findBy("dimension", dim.get("uniqueId"));
          if (!filter) {
            filter = {
              dimension: dim.get("uniqueId"),
              values: []
            };
            _this.get("budgetAttributes.filterDimensions").pushObject(filter);
          }
          dim.valuesChanged();
          if (!ignoreDimensionsTypes.length || ignoreDimensionsTypes.indexOf(Ember.get(dim, "type")) === -1) {
            Ember.set(filter, "values", filter.values.filter(function (v) {
              return dim.get("values").findBy("uniqueId", v);
            })); // filter out values that don't exist
            result.pushObject({
              elementId: "attribute-filter-".concat(dim.get("uniqueId")),
              dimension: dim,
              content: dim.get("values"),
              filter: filter
            });
          }
        });
      }
      this.set("dimensionFilters", result);
    },
    didInsertElement: function didInsertElement() {
      this.recalcFilters();
    },
    recalcObserver: Ember.observer("budgetAttributes.filterDimensions", "budgetService.dimensions.length", function () {
      this.recalcFilters();
    })
  });
});