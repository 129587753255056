define("budgeta/utils/balance-sheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = balanceSheet;
  function balanceSheet() {
    return true;
  }
});