define("budgeta/templates/components/svg/move-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FmJ8nndO",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M15.92 8.81997C16 8.65998 16 8.41998 15.92 8.17998C15.84 8.09998 15.84 8.01998 15.76 7.93998L10.16 2.33998C9.84 2.01998 9.36 2.01998 9.04 2.33998C8.72 2.65998 8.72 3.13998 9.04 3.45998L13.28 7.69998L0.8 7.69998C0.32 7.69998 0 8.01998 0 8.49998C0 8.97998 0.32 9.29998 0.8 9.29998L13.28 9.29998L9.12 13.46C8.8 13.78 8.8 14.26 9.12 14.58C9.28 14.74 9.52 14.82 9.68 14.82C9.84 14.82 10.08 14.74 10.24 14.58L15.76 9.05998C15.84 8.97998 15.92 8.89997 15.92 8.81997Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/move-svg.hbs"
    }
  });
});