define("budgeta/controllers/move-lines-modal", ["exports", "budgeta/controllers/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var containsRecursive = function containsRecursive(budgetLine, destinations) {
    if (destinations.includes(budgetLine)) {
      return true;
    }
    if (budgetLine.get("children.length")) {
      var childArray = budgetLine.get("children").toArray();
      for (var i = 0; i < budgetLine.get("children.length"); i++) {
        if (containsRecursive(childArray[i], destinations)) {
          return true;
        }
      }
    }
    return false;
  };
  var _default = _exports.default = _modal.default.extend({
    treeManager: Ember.inject.service(),
    attachTips: true,
    cache: Ember.inject.service(),
    confirmDisabled: Ember.computed("budgetLines.@each.selected", function () {
      return !this.get("budgetLines").find(function (b) {
        return b.selected && b.allowed;
      });
    }),
    budgetLines: Ember.computed("treeManager.budgetsList.length", "destinations", function () {
      var _this = this;
      return this.get("treeManager.budgetsList").filter(function (line) {
        var budgetLine = Ember.get(line, "budget");
        return containsRecursive(budgetLine, _this.get("destinations"));
      }).map(function (line) {
        var budgetLine = Ember.get(line, "budget");
        return Ember.merge({
          allowed: _this.get("destinations").includes(budgetLine)
        }, line);
      });
    }),
    actions: {
      select: function select(line) {
        var selected = this.get("budgetLines").findBy("selected");
        if (selected) {
          Ember.set(selected, "selected", false);
        }
        Ember.get(line, "allowed") && Ember.set(line, "selected", true);
        this.set("confirmActionArg", Ember.get(line, "budget"));
      }
    }
  });
});