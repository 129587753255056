define("budgeta/models/user-model", ["exports", "ember-data", "budgeta/services/ember-service-container"], function (_exports, _emberData, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    createdOn: _emberData.default.attr("date"),
    createdBy: _emberData.default.belongsTo("user", {
      async: true
    }),
    description: _emberData.default.attr("string"),
    about: _emberData.default.attr("string"),
    companyName: _emberData.default.attr("string"),
    companyType: _emberData.default.attr("string"),
    companyTypeOther: _emberData.default.attr("string"),
    isPublic: _emberData.default.attr("boolean", {
      defaultValue: false
    }),
    permissionType: _emberData.default.attr("string", {
      defaultValue: "email"
    }),
    shareWith: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    sharedWith: Ember.computed("isPublic", "shareWith", function () {
      if (this.get("isPublic")) {
        return intl.t("general.public_1698234256888");
      }
      if (this.get("shareWith.length")) {
        return intl.t("general.selected_users_1698234256888");
      }
      return intl.t("general.only_me_1698234256888");
    }),
    modelData: _emberData.default.attr("raw")
  });
});