define("budgeta/components/forecast-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    viewSelections: Ember.inject.service(),
    actuals: false,
    classNameBindings: [":scroll-columns", ":group", "isSelected:selected", "data.children:has-children", "data.outOfRange:out-of-range", "data.isOdd:odd:even", "data.budget.isBold:bold-line-display", "data.budget.isTurquoise:turquoise-line-display", "data.budget.isOlive:olive-line-display", "data.budget.isOrange:orange-line-display", "data.budget.isPurple:purple-line-display", "data.budget.isPink:pink-line-display", "isDisplayGroupBold:group-bold"],
    attributeBindings: ["data-level", "data-id", "data-type", "data-hook"],
    "data-level": Ember.computed.alias("data.level"),
    "data-id": Ember.computed.alias("data.id"),
    "data-type": Ember.computed.alias("data.type"),
    "data-hook": "forecast-row",
    isSelected: Ember.computed("selectedBudget", function () {
      return this.get("data.id") === this.get("selectedBudget.id");
    }),
    nextLevel: Ember.computed("data.level", function () {
      return this.get("data.level") + 1;
    }),
    isDisplayGroupBold: Ember.computed("viewType", "viewSelections.isPnLView", "viewSelections.isSheetsView", "data.level", function () {
      return this.get("viewSelections.isPnLView") && this.get("viewSelections.isSheetsView") || this.get("viewType") === "bs" ? this.get("data.level") === 1 : this.get("data.budget.isCompany") || this.get("data.budget.isBudgetGrouping");
    }),
    actions: {
      actualChanged: function actualChanged(budgetId, key) {
        this.sendAction("actualChanged", budgetId, key);
      }
    }
  });
});