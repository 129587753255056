define("budgeta/store/actions/stepsActions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PREV_STEP = _exports.NEXT_STEP = _exports.CLEAR_STEP_DEPENDENCIES = _exports.ACC_CAT_SKIP = void 0;
  var NEXT_STEP = _exports.NEXT_STEP = "NEXT_STEP";
  var PREV_STEP = _exports.PREV_STEP = "PREV_STEP";
  var ACC_CAT_SKIP = _exports.ACC_CAT_SKIP = "ACC_CAT_SKIP";
  var CLEAR_STEP_DEPENDENCIES = _exports.CLEAR_STEP_DEPENDENCIES = "CLEAR_STEP_DEPENDENCIES";
  var nextStep = function nextStep(budgetConfig) {
    return {
      type: NEXT_STEP,
      payload: {
        budgetConfig: budgetConfig
      }
    };
  };
  var prevStep = function prevStep(budgetConfig) {
    return {
      type: PREV_STEP,
      payload: {
        budgetConfig: budgetConfig
      }
    };
  };
  var accountCategorySkip = function accountCategorySkip(skip) {
    return {
      type: ACC_CAT_SKIP,
      payload: skip
    };
  };
  var clearDependencies = function clearDependencies(stepDependencies) {
    return {
      type: CLEAR_STEP_DEPENDENCIES,
      payload: {
        stepDependencies: stepDependencies
      }
    };
  };
  var _default = _exports.default = {
    nextStep: nextStep,
    prevStep: prevStep,
    accountCategorySkip: accountCategorySkip,
    clearDependencies: clearDependencies
  };
});