define("budgeta/templates/components/add-note-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FbbsJUj0",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"add-note-modal.note\"],null],false],[\"close-element\"],[\"text\",\" \"],[\"append\",[\"helper\",[\"textarea\"],null,[[\"value\"],[[\"get\",[\"model\",\"notes\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/add-note-modal.hbs"
    }
  });
});