define("budgeta/helpers/string-camelize", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringCamelize = stringCamelize;
  function stringCamelize(input) {
    return _const.default.camelize(input);
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return stringCamelize(params[0]);
  });
});