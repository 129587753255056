define("budgeta/components/pie-chart", ["exports", "budgeta/utils/const", "budgeta/utils/dashboard", "budgeta/components/budgeta-chart", "budgeta/config/environment"], function (_exports, _const, _dashboard, _budgetaChart, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _budgetaChart.default.extend({
    chartTypeName: "pie-chart",
    chartOptions: Ember.computed("chartCategories", "chartType", "highchartShowAnimation", "disablePiePercentage", "highchartCurrency", "colorPalette", "highchartHeight", "highchartWidth", "inZoom", function () {
      var colorPalette = this.get("colorPalette");
      var chartWidth = _dashboard.default.getChartWidth(this.get("isExpanded"));
      var chartHeight = _dashboard.default.getChartHeight(this.get("isExpanded"));
      var showAnimation = this.get("highchartShowAnimation") ? true : false;
      var disablePiePercentage = this.get("disablePiePercentage"),
        isRollingForecastViewType = this.get("isRollingForecastViewType");
      if (this.get("highchartHeight")) {
        chartHeight = this.get("highchartHeight");
      }
      if (this.get("highchartWidth")) {
        chartWidth = this.get("highchartWidth");
      }
      var currecnyArray = [""];
      if (this.get("highchartCurrency")) {
        currecnyArray = this.get("highchartCurrency");
      }
      var currencySymbol = currecnyArray[0];
      var inZoom = this.get("inZoom");
      var centerH = inZoom ? 350 : 110;
      var borderWidth = this.get("chartType") === "donut" ? 1 : 0;
      return {
        chart: {
          type: "pie",
          height: chartHeight,
          width: chartWidth
        },
        title: {
          text: ""
        },
        exporting: {
          enabled: false
        },
        colors: colorPalette,
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false
            }
          },
          pie: {
            dataLabels: {
              enabled: false
            },
            animation: showAnimation,
            showInLegend: true,
            borderWidth: borderWidth,
            size: chartHeight - 30,
            center: [centerH, "50%"]
          }
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          layout: "vertical",
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 6,
          itemMarginTop: inZoom ? 20 : 0,
          itemStyle: {
            color: "#878d94",
            fontSize: "15px",
            fontWeight: "normal"
          },
          labelFormatter: function labelFormatter() {
            var labelName = typeof this.name === "string" && this.name.length > 20 && !inZoom ? this.name.substr(0, 20) + "..." : this.name;
            var value = disablePiePercentage ? currencySymbol + _const.default.formatKMBT(this.y, 2) : _const.default.round(this.percentage, 2) + "%";
            if (!this.y) {
              value = currencySymbol + _const.default.formatKMBT(this.series.yData[this.index] || 0, 2);
            }
            if (inZoom) {
              labelName = "<span style=\"font-size: 32px; font-weight: 300;\">".concat(labelName, "</span>");
            }
            return labelName + '<br><span style="color: #334356; font-size: 32px; font-weight: 300">' + value + "</span>";
          }
        },
        tooltip: {
          formatter: function formatter() {
            var valueWithCurrency;
            if (currencySymbol === "%") {
              valueWithCurrency = _const.default.formatKMBT(this.y, 2) + currencySymbol;
            }
            valueWithCurrency = currencySymbol + _const.default.formatKMBT(this.y, 2);
            var key = this.key;
            if (isRollingForecastViewType) {
              key = key.replace(" (Actual)", "");
              key += " (Rolling)";
            }
            return "<b>" + key + "</b>  " + valueWithCurrency;
          }
        }
      };
    }),
    chartData: Ember.computed("budgetaChartData", "chartType", function () {
      var chartData = this.get("budgetaChartData");
      var isDonut = this.get("chartType") === "donut";
      chartData = chartData.map(function (serie) {
        Ember.set(serie, "y", _const.default.round(serie.y));
        return serie;
      });
      if (chartData && chartData.length > 1 && !isDonut) {
        chartData = chartData.sort(function (a, b) {
          return b.y - a.y;
        });
      }
      if (isDonut) {
        var levelData1 = [],
          levelData0 = [];
        chartData.forEach(function (data) {
          if (!data.level) {
            levelData0.push(data);
          } else if (data.level === 1) {
            levelData1.push(data);
          }
        });
        if (levelData0.map(function (a) {
          return a.name;
        }).join(",") === levelData1.map(function (b) {
          return b.name;
        }).join(",")) {
          return [{
            colorByPoint: true,
            data: levelData1
          }];
        }
        return [{
          colorByPoint: true,
          data: levelData1,
          size: "60%"
        }, {
          colorByPoint: true,
          data: levelData0,
          size: "80%",
          innerSize: "60%"
        }];
      }
      return [{
        colorByPoint: true,
        data: chartData
      }];
    }),
    attributeBindings: ["data-highchart-data", "data-highchart-categories"],
    "data-highchart-data": Ember.computed("chartData", function () {
      if (_environment.default.environment === "development" || _environment.default.environment === "staging" || _environment.default.budgetaEnvironment === "qa" || _environment.default.budgetaEnvironment === "dev") {
        return (JSON.stringify(this.get("chartData")) || "").replace(new RegExp('"', "g"), "'");
      }
      return "";
    }),
    "data-highchart-categories": Ember.computed("chartCategories", function () {
      if (_environment.default.environment === "development" || _environment.default.environment === "staging" || _environment.default.budgetaEnvironment === "qa" || _environment.default.budgetaEnvironment === "dev") {
        return (JSON.stringify(this.get("chartCategories")) || "").replace(new RegExp('"', "g"), "'");
      }
      return "";
    })
  });
});