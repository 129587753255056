define("budgeta/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    date: {
      "time-style": {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      }
    },
    number: {
      EUR: {
        style: "currency",
        currency: "EUR"
      },
      USD: {
        style: "currency",
        currency: "USD"
      }
    },
    time: {
      hhmmss: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      }
    }
  };
});