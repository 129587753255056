define("budgeta/routes/login", ["exports", "budgeta/services/ember-service-container", "budgeta/mixins/budgeta-route"], function (_exports, _emberServiceContainer, _budgetaRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Route.extend(_budgetaRoute.default, {
    budgetService: Ember.inject.service(),
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      if (transition.queryParams && transition.queryParams.email && transition.queryParams.password) {
        controller.set("identification", transition.queryParams.email);
        Ember.$.cookie("tempPassword", transition.queryParams.password, {
          path: "/"
        });
        controller.send("authenticate");
      }
    },
    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        if (Ember.$.cookie("sessionExpired")) {
          noty({
            text: intl.t("general.text_1695611610702")
          });
          Ember.$.cookie("sessionExpired", "");
        }
        if (window.zE) {
          window.zE(function () {
            window.zE.hide();
          });
        }
        var _this = this,
          controller = _this.get("controller");
        controller.set("errorMessage", null);
        var inviteSender = Ember.$.cookie("inviteSender");
        Ember.$.removeCookie("inviteSender", {
          path: "/"
        });
        controller.set("inviteSender", inviteSender);
        if (inviteSender) {
          controller.setProperties({
            action: "invite"
          });
        }
        if (_this.get("session.isAuthenticated")) {
          var invitationToken = _this.get("controller.applicationController.invitationToken") || Ember.$.cookie("invitationToken");
          Ember.$.removeCookie("invitationToken", {
            path: "/"
          });
          if (invitationToken) {
            _this.transitionTo("invitation.accept", invitationToken);
            _this.set("controller.applicationController.invitationToken", null);
          } else {
            var budgetInfoToken = _this.get("budgetService.budgetInfoToken");
            if (budgetInfoToken) {
              _this.transitionTo("budget-info.accept", budgetInfoToken);
            } else if (!_this.controller.get("isVerifyPage")) {
              if (_this.get("session.data.currentUser.status") !== "change_pass" && _this.get("session.data.currentUser.status") !== "pending") {
                _this.transitionTo("main");
              }
            }
          }
        }
      }
    }
  });
});