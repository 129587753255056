define("budgeta/store/actions/budgetActions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SET_SHOULD_SHOW_PREVIEW_WALKTHROUGH = _exports.SET_SELECTED_DIMENSIONS = _exports.SET_PREVIEW_ACTIVE_STEP = _exports.SET_NOT_ALLOWED_CURRENCIES_LINES = _exports.SET_NOT_ALLOWED_CATEGORY_LINES = _exports.SET_HEADER_ROW_MAIN_COLUMN = _exports.SET_DIMENSION_STRUCTURE_SKIP = _exports.SET_DIMENSION_HIERARCHY = _exports.SET_DIMENSIONS_MAPPING_STEP = _exports.SET_DATA_FORMAT = _exports.SET_CURRENCIES_MAPPING = _exports.SET_COLUMNS_KEYS = _exports.SET_CATEGORIES_MAPPING = _exports.SET_BUDGET_TREE = _exports.SET_BUDGET_TABLE_META = _exports.SET_BUDGET_STRUCTURE_SKIP = _exports.SET_BUDGET_STRUCTURE_MODIFY_SKIP = _exports.SET_BUDGET_SETTINGS = _exports.SET_BUDGET_SELECTED_LINES = _exports.SET_BUDGET_ROOT_ID = _exports.SET_BUDGET_COLUMN_MAPPING = _exports.CLEAR_BUDGET_DEPENDENCIES = void 0;
  var SET_BUDGET_SETTINGS = _exports.SET_BUDGET_SETTINGS = "SET_BUDGET_SETTINGS";
  var SET_BUDGET_ROOT_ID = _exports.SET_BUDGET_ROOT_ID = "SET_BUDGET_ROOT_ID";
  var SET_BUDGET_TABLE_META = _exports.SET_BUDGET_TABLE_META = "SET_BUDGET_TABLE_META";
  var SET_BUDGET_COLUMN_MAPPING = _exports.SET_BUDGET_COLUMN_MAPPING = "SET_BUDGET_COLUMN_MAPPING";
  var SET_DATA_FORMAT = _exports.SET_DATA_FORMAT = "SET_DATA_FORMAT";
  var SET_DIMENSIONS_MAPPING_STEP = _exports.SET_DIMENSIONS_MAPPING_STEP = "SET_DIMENSIONS_MAPPING_STEP";
  var SET_COLUMNS_KEYS = _exports.SET_COLUMNS_KEYS = "SET_COLUMNS_KEYS";
  var SET_DIMENSION_STRUCTURE_SKIP = _exports.SET_DIMENSION_STRUCTURE_SKIP = "SET_DIMENSION_STRUCTURE_SKIP";
  var SET_BUDGET_STRUCTURE_SKIP = _exports.SET_BUDGET_STRUCTURE_SKIP = "SET_BUDGET_STRUCTURE_SKIP";
  var SET_BUDGET_STRUCTURE_MODIFY_SKIP = _exports.SET_BUDGET_STRUCTURE_MODIFY_SKIP = "SET_BUDGET_STRUCTURE_MODIFY_SKIP";
  var SET_BUDGET_SELECTED_LINES = _exports.SET_BUDGET_SELECTED_LINES = "SET_BUDGET_SELECTED_LINES";
  var SET_HEADER_ROW_MAIN_COLUMN = _exports.SET_HEADER_ROW_MAIN_COLUMN = "SET_HEADER_ROW_MAIN_COLUMN";
  var SET_SELECTED_DIMENSIONS = _exports.SET_SELECTED_DIMENSIONS = "SET_SELECTED_DIMENSIONS";
  var SET_NOT_ALLOWED_CATEGORY_LINES = _exports.SET_NOT_ALLOWED_CATEGORY_LINES = "SET_NOT_ALLOWED_CATEGORY_LINES";
  var SET_NOT_ALLOWED_CURRENCIES_LINES = _exports.SET_NOT_ALLOWED_CURRENCIES_LINES = "SET_NOT_ALLOWED_CURRENCIES_LINES";
  var SET_CATEGORIES_MAPPING = _exports.SET_CATEGORIES_MAPPING = "SET_CATEGORIES_MAPPING";
  var SET_DIMENSION_HIERARCHY = _exports.SET_DIMENSION_HIERARCHY = "SET_DIMENSION_HIERARCHY";
  var SET_BUDGET_TREE = _exports.SET_BUDGET_TREE = "SET_BUDGET_TREE";
  var SET_CURRENCIES_MAPPING = _exports.SET_CURRENCIES_MAPPING = "SET_CURRENCIES_MAPPING";
  var SET_PREVIEW_ACTIVE_STEP = _exports.SET_PREVIEW_ACTIVE_STEP = "SET_PREVIEW_ACTIVE_STEP";
  var SET_SHOULD_SHOW_PREVIEW_WALKTHROUGH = _exports.SET_SHOULD_SHOW_PREVIEW_WALKTHROUGH = "SET_SHOULD_SHOW_PREVIEW_WALKTHROUGH";
  var CLEAR_BUDGET_DEPENDENCIES = _exports.CLEAR_BUDGET_DEPENDENCIES = "CLEAR_BUDGET_DEPENDENCIES";
  var setSettings = function setSettings(settings) {
    var name = settings.name,
      mainCurrency = settings.mainCurrency,
      budgetRootType = settings.budgetRootType,
      startDate = settings.startDate,
      endDate = settings.endDate,
      fiscalYearStartMonth = settings.fiscalYearStartMonth;
    return {
      type: SET_BUDGET_SETTINGS,
      payload: {
        name: name,
        mainCurrency: mainCurrency,
        budgetRootType: budgetRootType,
        startDate: startDate,
        endDate: endDate,
        fiscalYearStartMonth: fiscalYearStartMonth
      }
    };
  };
  var setBudgetRootId = function setBudgetRootId(budgetRootId) {
    return {
      type: SET_BUDGET_ROOT_ID,
      payload: {
        budgetRootId: budgetRootId
      }
    };
  };
  var setDataFormat = function setDataFormat(format) {
    return {
      type: SET_DATA_FORMAT,
      payload: {
        format: format
      }
    };
  };
  var setDimensionsMappingStep = function setDimensionsMappingStep(stepDimensionsMapping) {
    return {
      type: SET_DIMENSIONS_MAPPING_STEP,
      payload: {
        stepDimensionsMapping: stepDimensionsMapping
      }
    };
  };
  var setColumnsKeys = function setColumnsKeys(columnKeys) {
    var currencyColumnKey = columnKeys.currencyColumnKey,
      accountColumnKey = columnKeys.accountColumnKey;
    return {
      type: SET_COLUMNS_KEYS,
      payload: {
        currencyColumnKey: currencyColumnKey,
        accountColumnKey: accountColumnKey
      }
    };
  };
  var setDimensionStructureSkip = function setDimensionStructureSkip(skipDimensionStructure) {
    return {
      type: SET_DIMENSION_STRUCTURE_SKIP,
      payload: {
        skipDimensionStructure: skipDimensionStructure
      }
    };
  };
  var setBudgetStructureSkip = function setBudgetStructureSkip(skipBudgetStructure) {
    return {
      type: SET_BUDGET_STRUCTURE_SKIP,
      payload: {
        skipBudgetStructure: skipBudgetStructure
      }
    };
  };
  var setBudgetStructureModifySkip = function setBudgetStructureModifySkip(skipBudgetStructureModify) {
    return {
      type: SET_BUDGET_STRUCTURE_MODIFY_SKIP,
      payload: {
        skipBudgetStructureModify: skipBudgetStructureModify
      }
    };
  };
  var setHeaderRowMainColumn = function setHeaderRowMainColumn(tableMeta) {
    var headerLineNumber = tableMeta.headerLineNumber,
      nameColumn = tableMeta.nameColumn,
      nameColumnIsAccount = tableMeta.nameColumnIsAccount;
    return {
      type: SET_HEADER_ROW_MAIN_COLUMN,
      payload: {
        headerLineNumber: headerLineNumber,
        nameColumn: nameColumn,
        nameColumnIsAccount: nameColumnIsAccount
      }
    };
  };
  var setColumnMapping = function setColumnMapping(columnMapping) {
    return {
      type: SET_BUDGET_COLUMN_MAPPING,
      payload: {
        columnMapping: columnMapping
      }
    };
  };
  var setSelectedLines = function setSelectedLines(selectedLines, columnMapping, formattedFile) {
    return {
      type: SET_BUDGET_SELECTED_LINES,
      payload: {
        selectedLines: selectedLines,
        columnMapping: columnMapping,
        formattedFile: formattedFile
      }
    };
  };
  var setSelectedDimensions = function setSelectedDimensions(dimensionsData) {
    var selectedDimensions = dimensionsData.selectedDimensions,
      suggestedDimensions = dimensionsData.suggestedDimensions;
    return {
      type: SET_SELECTED_DIMENSIONS,
      payload: {
        selectedDimensions: selectedDimensions,
        suggestedDimensions: suggestedDimensions
      }
    };
  };
  var setCategoryMapping = function setCategoryMapping(categoryMapping) {
    return {
      type: SET_CATEGORIES_MAPPING,
      payload: {
        categoryMapping: categoryMapping
      }
    };
  };
  var setNotAllowedCategory = function setNotAllowedCategory(notAllowedCategory) {
    return {
      type: SET_NOT_ALLOWED_CATEGORY_LINES,
      payload: {
        notAllowedCategory: notAllowedCategory
      }
    };
  };
  var setNotAllowedCurrencies = function setNotAllowedCurrencies(currenciesMappingDetails) {
    var notAllowedCurrencies = currenciesMappingDetails.notAllowedCurrencies;
    return {
      type: SET_NOT_ALLOWED_CURRENCIES_LINES,
      payload: {
        notAllowedCurrencies: notAllowedCurrencies
      }
    };
  };
  var setPreviewActiveStep = function setPreviewActiveStep(activeStep) {
    return {
      type: SET_PREVIEW_ACTIVE_STEP,
      payload: {
        activeStep: activeStep
      }
    };
  };
  var setDimensionHierarchy = function setDimensionHierarchy(dimensionHierarchy) {
    return {
      type: SET_DIMENSION_HIERARCHY,
      payload: {
        dimensionHierarchy: dimensionHierarchy
      }
    };
  };
  var setBudgetTree = function setBudgetTree(budgetTree) {
    return {
      type: SET_BUDGET_TREE,
      payload: {
        budgetTree: budgetTree
      }
    };
  };
  var setCurrenciesMapping = function setCurrenciesMapping(mappingDetails) {
    var currenciesMapping = mappingDetails.currenciesMapping,
      fileCurrenciesToSIBP = mappingDetails.fileCurrenciesToSIBP;
    return {
      type: SET_CURRENCIES_MAPPING,
      payload: {
        currenciesMapping: currenciesMapping,
        fileCurrenciesToSIBP: fileCurrenciesToSIBP
      }
    };
  };
  var setShouldShowPreviewWalkthrough = function setShouldShowPreviewWalkthrough(showPreviewStepWalkthrough) {
    return {
      type: SET_SHOULD_SHOW_PREVIEW_WALKTHROUGH,
      payload: {
        showPreviewStepWalkthrough: showPreviewStepWalkthrough
      }
    };
  };
  var clearDependencies = function clearDependencies(stepDependencies) {
    return {
      type: CLEAR_BUDGET_DEPENDENCIES,
      payload: {
        stepDependencies: stepDependencies
      }
    };
  };
  var _default = _exports.default = {
    setSettings: setSettings,
    setBudgetRootId: setBudgetRootId,
    setColumnMapping: setColumnMapping,
    setDataFormat: setDataFormat,
    setDimensionsMappingStep: setDimensionsMappingStep,
    setColumnsKeys: setColumnsKeys,
    setDimensionStructureSkip: setDimensionStructureSkip,
    setBudgetStructureSkip: setBudgetStructureSkip,
    setBudgetStructureModifySkip: setBudgetStructureModifySkip,
    setSelectedLines: setSelectedLines,
    setHeaderRowMainColumn: setHeaderRowMainColumn,
    setSelectedDimensions: setSelectedDimensions,
    setCategoryMapping: setCategoryMapping,
    setDimensionHierarchy: setDimensionHierarchy,
    setBudgetTree: setBudgetTree,
    setNotAllowedCategory: setNotAllowedCategory,
    setNotAllowedCurrencies: setNotAllowedCurrencies,
    setPreviewActiveStep: setPreviewActiveStep,
    setCurrenciesMapping: setCurrenciesMapping,
    setShouldShowPreviewWalkthrough: setShouldShowPreviewWalkthrough,
    clearDependencies: clearDependencies
  };
});