define("budgeta/components/formula-input", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    displayValue: Ember.computed("formulaValue", {
      get: function get() {
        var returnValue = this.get("formulaValue");
        returnValue = _const.default.addCommasToFormula(returnValue);
        return returnValue;
      },
      set: function set(key, value) {
        var returnValue = value.replace(/,/g, "");
        this.set("formulaValue", returnValue);
        returnValue = _const.default.addCommasToFormula(returnValue);
        return returnValue;
      }
    })
  });
});