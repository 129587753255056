define("budgeta/components/type-ahead-lazy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    active: false,
    enabled: true,
    placeholder: "",
    label: Ember.computed("value", "placeholder", function () {
      return this.get("value") ? this.get("value") : this.get("placeholder");
    }),
    isPlaceHolder: Ember.computed("value", "placeholder", function () {
      return !this.get("value");
    }),
    actions: {
      activate: function activate() {
        var _this = this;
        if (this.get("enabled")) {
          this.set("active", true);
          Ember.run.scheduleOnce("afterRender", function () {
            _this.$(".ember-view.ember-text-field.tt-input").focus();
          });
        }
      }
    }
  });
});