define("budgeta/utils/budget-root-utils", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/config/environment", "lodash/lodash", "budgeta/utils/const"], function (_exports, _typeof2, _objectSpread2, _environment, _lodash, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FF_CONNECTED_USERS_INDICATION = _const.default.FF_CONNECTED_USERS_INDICATION === "true";
  var BudgetRootUtils = (0, _objectSpread2.default)({
    newView: function newView(root, view) {
      var rootId = typeof root === "string" ? root : root.get("id");
      if (rootId) {
        try {
          return new Promise(function (resolve, reject) {
            return Ember.$.ajax("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/budgetRoot/").concat(rootId, "/views"), {
              method: "POST",
              data: JSON.stringify({
                view: view
              }),
              contentType: "application/json",
              success: function success(viewId) {
                resolve(viewId);
              },
              error: function error(_error) {
                Ember.Logger.error(_error);
                reject();
              }
            });
          });
        } catch (err) {
          Ember.Logger.error(err);
        }
      }
    },
    updateView: function updateView(root, viewId, update) {
      var rootId = typeof root === "string" ? root : root.get("id");
      if (rootId && viewId) {
        try {
          return new Promise(function (resolve, reject) {
            return Ember.$.ajax("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/budgetRoot/").concat(rootId, "/views/update/").concat(viewId), {
              method: "PUT",
              data: JSON.stringify(update),
              contentType: "application/json",
              success: function success() {
                resolve();
              },
              error: function error(_error2) {
                Ember.Logger.error(_error2);
                reject();
              }
            });
          });
        } catch (err) {
          Ember.Logger.error(err);
        }
      }
    },
    deleteView: function deleteView(root, viewId) {
      var rootId = typeof root === "string" ? root : root.get("id");
      if (rootId && viewId) {
        try {
          return new Promise(function (resolve, reject) {
            return Ember.$.ajax("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/budgetRoot/").concat(rootId, "/views/delete/").concat(viewId), {
              method: "PUT",
              contentType: "application/json",
              success: function success() {
                resolve();
              },
              error: function error(_error3) {
                Ember.Logger.error(_error3);
                reject();
              }
            });
          });
        } catch (err) {
          Ember.Logger.error(err);
        }
      }
    },
    equalityOfArrayOrPrimitive: function equalityOfArrayOrPrimitive(a, b) {
      if ((0, _typeof2.default)(a) === (0, _typeof2.default)(b)) {
        return _lodash.default.isEqual(a, b);
      }
      if (Array.isArray(a)) {
        return a.includes(b);
      }
      if (Array.isArray(b)) {
        return b.includes(a);
      }
      return a === b;
    }
  }, FF_CONNECTED_USERS_INDICATION && {
    updateSubscriptions: function updateSubscriptions(root, subscriptions) {
      var rootId = typeof root === "string" ? root : root.get("id");
      if (rootId) {
        return new Promise(function (resolve, reject) {
          return Ember.$.ajax("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/budgetRoot/").concat(rootId, "/subscriptions/update"), {
            method: "PUT",
            data: JSON.stringify({
              subscriptions: subscriptions
            }),
            contentType: "application/json",
            success: resolve,
            error: reject
          });
        });
      }
    }
  });
  var _default = _exports.default = BudgetRootUtils;
});