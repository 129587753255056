define("budgeta/services/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_NUM_OF_BUDGETS_FOR_ONE_COMPANY = 5;
  var MAX_NUM_OF_BUDGETS_FOR_MULTI_COMPANY = Infinity;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    currentUser: null,
    currentUserPromise: null,
    dashboardPermissions: [],
    showDashboardOnly: Ember.computed("dashboardPermissions", "dashboardPermissions.length", function () {
      return this.get("dashboardPermissions.length") > 0;
    }),
    isIntacctQAUser: Ember.computed("userRoles.length", function () {
      return this.get("userRoles").indexOf("intacct-automation") !== -1;
    }),
    userRoles: Ember.computed("currentUser.roles", function () {
      return this.get("currentUser.roles") ? this.get("currentUser.roles") : [];
    }),
    isAdmin: Ember.computed("userRoles", function () {
      return this.get("userRoles") && this.get("userRoles").includes("admin");
    }),
    isBetaUser: Ember.computed("userRoles", function () {
      return this.get("userRoles") && this.get("userRoles").includes("beta");
    }),
    isUnlimited: Ember.computed("userRoles", function () {
      return this.get("userRoles") && this.get("userRoles").includes("unlimited");
    }),
    isNoBudgetLimit: true,
    canUseVersions: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    canUseScenarios: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    canViewActuals: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    canViewPnl: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    canViewBalanceSheet: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    canCreatePdfReport: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    canCustomizeDashboard: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    canShareWithEdit: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    canShareNonRootBudget: Ember.computed("isAdmin", "isUnlimited", function () {
      return this.get("isAdmin") || this.get("isUnlimited");
    }),
    maxNumOfBudgets: Ember.computed(function () {
      if (this.get("isAdmin") || this.get("isUnlimited") || this.get("isNoBudgetLimit")) {
        return MAX_NUM_OF_BUDGETS_FOR_MULTI_COMPANY;
      } else {
        return MAX_NUM_OF_BUDGETS_FOR_ONE_COMPANY;
      }
    }),
    featureFlagModel: Ember.computed("currentUser", function () {
      return Ember.isArray(this.get("currentUser.roles")) && this.get("currentUser.roles").indexOf("models") >= 0;
    }),
    featureFlagIntacct: Ember.computed("currentUser", function () {
      return true;
    }),
    featureFlagManualWizard: Ember.computed("currentUser", function () {
      return Ember.isArray(this.get("currentUser.roles")) && this.get("currentUser.roles").indexOf("manual-wizard") >= 0;
    })
  });
});