define("budgeta/components/list-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    searchEnable: Ember.computed("searchValue", "showSearch", function () {
      return !Ember.isEmpty(this.get("searchValue")) && this.get("showSearch");
    }),
    displayList: Ember.computed("listElements", "searchValue", function () {
      var _this = this;
      var listElements = this.get("listElements").sortBy("name");
      if (Ember.isEmpty(this.get("searchValue"))) {
        return listElements;
      }
      listElements = listElements.filter(function (b) {
        return Ember.get(b, "name").toLowerCase().indexOf(_this.get("searchValue").toLowerCase()) >= 0;
      });
      return listElements;
    }),
    isEmptyList: Ember.computed("displayList", function () {
      return Ember.isEmpty(this.get("displayList")) || this.get("displayList.length") === 0;
    }),
    showSearch: Ember.computed("listElements.length", function () {
      return this.get("listElements.length") > 5;
    }),
    actions: {
      goto: function goto(budget) {
        this.sendAction("goto", budget);
      }
    }
  });
});