define("budgeta/templates/modal-iframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "p3vKnjgJ",
    "block": "{\"statements\":[[\"open-element\",\"iframe\",[]],[\"static-attr\",\"style\",\"height: 400px; width: 100%; border: 0\"],[\"dynamic-attr\",\"src\",[\"unknown\",[\"model\"]],null],[\"flush-element\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/modal-iframe.hbs"
    }
  });
});