define("budgeta/validators/local/dimensions-validator", ["exports", "budgeta/services/ember-service-container", "ember-validations/validators/base", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _base, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _base.default.extend({
    call: function call() {
      var _this = this;
      var a = this.model.get(this.property);
      var errors = [];
      if (!Ember.isBlank(a) && Ember.isArray(a)) {
        var isPayrollOrLicense = this.model.get("model.budgetType") === "group-payroll" || this.model.get("model.budgetType") === "sales-perpetual-license";
        a.forEach(function (dimensionData) {
          var dim = (_this.model.get("budgetService.dimensions") || Ember.A([])).findBy("uniqueId", Ember.get(dimensionData, "dimension"));
          if (dim) {
            var allowDuplicates = dim.get("isCategory") && isPayrollOrLicense;
            var sum = 0;
            var values = Ember.get(dimensionData, "values").filterBy("value");
            if (values) {
              values.forEach(function (element) {
                sum += (Ember.get(element, "allocation") || 0) * 1;
              });
              if (!allowDuplicates && _const.default.findDuplicates(values.mapBy("value")).length) {
                errors.push(_this.options.message || "Cannot use the same value more than once ".concat(dim ? intl.t("general.for_dimension__1698234257438") + dim.get("name").toLowerCase() : ""));
              }
            }
            if (sum && sum > 100) {
              errors.push(_this.options.message || intl.t("general.dimensions-validator-1", {
                var1: dim ? intl.t("general.for_dimension__1698234257438") + dim.get("name").toLowerCase() : ""
              }));
            }
          }
        });
        this.errors.pushObjects(errors);
      }
    }
  });
});