define("budgeta/validators/local/required-when", ["exports", "budgeta/services/ember-service-container", "ember-validations/validators/base"], function (_exports, _emberServiceContainer, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _base.default.extend({
    init: function init() {
      this._super();
      var _this = this;
      _this.dependentValidationKeys.pushObject(this.options.ref);
    },
    call: function call() {
      if (this.model.get(this.options.ref) && Ember.isBlank(this.model.get(this.property))) {
        this.errors.pushObject(this.options.message || intl.t("general.value_is_required_1698234257461"));
      }
    }
  });
});