define("budgeta/components/versions-modal", ["exports", "budgeta/components/modal-new", "budgeta/config/environment"], function (_exports, _modalNew, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    budgetService: Ember.inject.service(),
    classNames: ["modal-versions"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // refresh versions data
      (this.get("budgetService.selectedRoot.versions") || Ember.A([])).reload();
    },
    change: function change(event) {
      var target = Ember.$(event.target);
      var parent = target.closest("[data-id]");
      if (parent.length) {
        var versionId = parent.data("id");
        var thisVersion = this.get("budgetService.selectedRoot.versions") && this.get("budgetService.selectedRoot.versions").findBy("id", versionId);
        if (thisVersion) {
          Ember.$.ajax("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/versions/").concat(thisVersion.get("id")), {
            method: "PUT",
            data: JSON.stringify({
              tag: thisVersion.get("tag"),
              type: thisVersion.get("type"),
              note: thisVersion.get("note")
            }),
            contentType: "application/json"
          });
        }
      }
    }
  });
});