define("budgeta/models/budget", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/objectSpread2", "ember-data", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/config/environment", "ember-validations", "budgeta/utils/forecast", "budgeta/utils/budget-utils", "budgeta/utils/wizard-template-utils", "budgeta/utils/dashboard", "budgeta/utils/formula-helper"], function (_exports, _typeof2, _objectSpread2, _emberData, _emberServiceContainer, _const, _environment, _emberValidations, _forecast, _budgetUtils, _wizardTemplateUtils, _dashboard, _formulaHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/no-observers, no-prototype-builtins */

  var FF_CONNECTED_USERS_INDICATION = _const.default.FF_CONNECTED_USERS_INDICATION === "true";
  var getRoot = function getRoot(budget, absolute) {
    return !absolute && budget.get("isRoot") || Ember.isEmpty(budget.get("parent")) ? budget : getRoot(budget.get("parent"), absolute);
  };
  var getScenarios = function getScenarios(budget) {
    var scenarios = Ember.A([]);
    scenarios.addObjects((budget.get("scenarios") || []).map(function (scenario) {
      if (scenario) {
        return scenario.get("scenario");
      } else {
        return null;
      }
    }));
    if (budget.get("parent")) {
      scenarios.addObjects(getScenarios(budget.get("parent")));
    }
    return scenarios;
  };
  var getNumOfEmployees = function getNumOfEmployees(budget, budgetAttributes, store, cache) {
    var sum = 0;
    if (budget.get("budgetType") === "employee" || budget.get("budgetType") === "contractor" || budget.get("budgetType") === "employee-transferred" || budget.get("budgetType") === "employee-allocated") {
      sum = 1;
    }
    if (budget.get("budgetType") === "employee-multi") {
      // if it's existing employees, return the amount
      if (budgetAttributes.amountType === 0) {
        return _forecast.default.getAmount(budgetAttributes.amount, budget, null, store, {
          cacheService: cache
        });
      }

      // if it's new employees, calc the sum of them
      if (budgetAttributes.amountType === 1) {
        var customAmounts = budgetAttributes.customAmounts;
        if (customAmounts) {
          var lineStartDate = budget.get("forecastAttributes.startDate");
          var lineEndDate = budget.get("forecastAttributes.endDate");
          var keys = Object.keys(customAmounts).sort();
          keys.forEach(function (key, index) {
            if (!key) {
              return;
            }
            var dateStr = key.slice(1);
            dateStr = dateStr.substr(2) + "/" + dateStr.substr(0, 2) + "/01";
            var newDate = new Date(dateStr);
            if ((!lineStartDate || !newDate.isBefore(lineStartDate)) && (!lineEndDate || !newDate.isAfter(lineEndDate))) {
              sum += +_forecast.default.getAmount(customAmounts[key], budget, newDate, store, {
                cacheService: cache
              }, index);
            }
          });
        }
      }

      // if it's new hires from model, calc the sum of them
      if (budgetAttributes.amountType === 2) {
        var _customAmounts = _forecast.default.calcForecast(cache, store, _budgetUtils.default.getIdByUniqueId(store, cache, budgetAttributes.allocatedFrom), budget, {
          metrics: true
        }).items;
        if (_customAmounts) {
          var _keys = Object.keys(_customAmounts);
          _keys.forEach(function (key) {
            sum += +_customAmounts[key];
          });
        }
      }
    }
    return Math.round(sum);
  };
  var _default = _exports.default = _emberData.default.Model.extend(_emberValidations.default, (0, _objectSpread2.default)({
    budgetService: Ember.inject.service(),
    changeLogService: Ember.inject.service(),
    setupGuide: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    exchangeRatesService: Ember.inject.service("exchange-rates"),
    exchangeRates: Ember.computed.alias("exchangeRatesService.rates"),
    cache: Ember.inject.service(),
    actualsMapping: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    cacheChangedTime: null,
    savePromise: null,
    childrenLength: Ember.computed("children", "children.[]", function () {
      return this.get("children").length;
    }),
    init: function init() {
      var _this2 = this;
      this._super.apply(this, arguments);

      // SIM-73 computed property is called to ensure that it is computed for dependent key in front-end/app/services/tree-manager.js
      this.get("childrenLength");

      // fix dates
      if (this.get("budgetAttributes")) {
        this.set("budgetAttributes.startDate", _const.default.fixDate(this.get("budgetAttributes.startDate")));
        this.set("budgetAttributes.endDate", _const.default.fixDate(this.get("budgetAttributes.endDate")));
      }

      // BUD-7258 models cannot have a date range
      if (this.get("budgetType").startsWith("group-model")) {
        if (this.get("budgetAttributes.startDate")) {
          this.set("budgetAttributes.startDate", null);
        }
        if (this.get("budgetAttributes.endDate")) {
          this.set("budgetAttributes.endDate", null);
        }
      }

      // BUD-6362 fix state where customAmounts is set and amountType is 0
      if (this.get("budgetType") === "expense" && this.get("budgetAttributes.amountType") === 0 && this.get("budgetAttributes.customAmounts") && Object.keys(this.get("budgetAttributes.customAmounts")).length) {
        this.set("budgetAttributes.customAmounts", {});
      }

      // remove non-supported currencies
      if (this.get("budgetAttributes.currency") && !_const.default.currency_symbols[this.get("budgetAttributes.currency")]) {
        this.set("budgetAttributes.currency", null);
      }
      if (this.get("isRoot") && this.get("fxCurrencies.length")) {
        var removeCurrencies = this.get("fxCurrencies").filter(function (c) {
          return !_const.default.currency_symbols[c];
        });
        this.get("fxCurrencies").removeObjects(removeCurrencies);
      }
      this.set("budgetTypeBefore", this.get("budgetType"));

      // set uniqueId for backward compatibility
      this.on("didLoad", function () {
        if (!_this2.get("isEmpty") && Ember.isEmpty(_this2.get("uniqueId"))) {
          _this2.set("uniqueId", _this2.get("id"));
        }
      });
      this.dashboardListChanged();

      // @ToDo: Remove side effect
      this.verifyDashboardLines();
    },
    destroyRecord: function destroyRecord() {
      var _arguments = arguments,
        _this3 = this;
      if (this.get("isSaving")) {
        this.get("savePromise").then(function () {
          _this3._super.apply(_this3, _arguments);
        });
      } else {
        this._super.apply(this, arguments);
      }
    },
    save: function save() {
      var _this = this;
      // in case we try to delete budget in view mode
      if (this.get("budgetService.sharedReadOnlyOrVersion") && !this.get("isNew")) {
        return this._super.apply(this, arguments);
      }
      if (!this.get("isRoot") && !this.get("absoluteRootId")) {
        this.set("absoluteRootId", this.get("budgetService.selectedRoot.absoluteRoot.id"));
      }
      _budgetUtils.default.addAdvancedBenefitCategory(this);
      if (this.get("budgetAttributes.releaseTo") && !_budgetUtils.default.isReleaseToLine(this)) {
        this.set("budgetAttributes.releaseTo", undefined);
      }
      if (!this.get("isDeleted")) {
        this.set("schemaVersion", _const.default.BUDGET_SCHEMA_VERSION);
      }

      // create a list of duplicated scenarios to be removed after the save
      var recordsToDelete = [];
      var assumptions = this.get("budgetAttributes.assumptions") || Ember.A([]);
      var isModelGroup = this.get("isModelGroup");
      this.getWithDefault("scenarios", []).forEach(function (scenario) {
        if (_const.default.isInteractiveScenario(Ember.get(scenario, "scenario"))) {
          var scenarioAssumptions = scenario.get("budgetAttributes.assumptions") || Ember.A([]);
          if (isModelGroup) {
            assumptions.forEach(function (assumption) {
              var scenarioAssumption = scenarioAssumptions.findBy("uniqueId", Ember.get(assumption, "uniqueId"));
              if (!scenarioAssumption) {
                scenarioAssumptions.pushObject(assumption);
              }
            });
            var removeScenarioAssumptions = Ember.A([]);
            scenarioAssumptions.forEach(function (scenarioAssumption) {
              var assumption = assumptions.findBy("uniqueId", Ember.get(scenarioAssumption, "uniqueId"));
              if (!assumption) {
                removeScenarioAssumptions.pushObject(scenarioAssumption);
              }
            });
            scenarioAssumptions.removeObjects(removeScenarioAssumptions);
          }
        }
        if (scenario.get("isNew")) {
          recordsToDelete.push(scenario);
        }
      });
      this.set("savePromise", this._super.apply(this, arguments).then(function (budget) {
        // trigger version auto-save
        _this.get("budgetService").autoSaveVersion(budget);
        _this.get("setupGuide").notifyPropertyChange("recalcGuide");

        // remove duplicated scenarios
        recordsToDelete.forEach(function (record) {
          if (!record.get("isDeleted")) {
            record.deleteRecord();
          }
        });

        // trigger change when scenario mode is active to fix the binding after deleting duplicated scenarios
        if (_this.get("budgetService.scenario")) {
          _this.notifyPropertyChange("budgetAttributesWithScenarios");
        }
        return budget;
      }));
      return this.get("savePromise");
    },
    hideDoc: Ember.computed.and("isCompany", "parent.isCompany"),
    wizardType: "",
    wizardOrBudgetType: Ember.computed.or("wizardType", "budgetType"),
    wizardTemplate: Ember.computed("budgetModule", "wizardType", "parent.budgetType", "children.length", function () {
      return _wizardTemplateUtils.default.getBudgetLineStepName(this);
    }),
    __v: _emberData.default.attr("number"),
    loopError: false,
    companyId: _emberData.default.attr("string"),
    subBudgetType: _emberData.default.attr("string"),
    schemaVersion: _emberData.default.attr("number"),
    exampleId: _emberData.default.attr("string"),
    uniqueId: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    calcVersion: _emberData.default.attr("string"),
    absoluteRootId: _emberData.default.attr("string"),
    isSnapshotTree: _emberData.default.attr("boolean"),
    scenarioChanges: Ember.computed("scenarios.@each.budgetAttributes", "budgetService.scenario", "budgetAttributes", "budgetModule", function () {
      var scenario = this.get("budgetService.scenario");
      if (scenario) {
        return _const.default.getScenarioChanges(this, scenario);
      }
      return [];
    }),
    hasScenarioChanges: Ember.computed.notEmpty("scenarioChanges"),
    isBold: Ember.computed("budgetAttributes.displayBold", function () {
      return this.get("budgetAttributes.displayBold");
    }),
    noneColor: Ember.computed("budgetAttributes.displayColor", function () {
      return !this.get("budgetAttributes.displayColor");
    }),
    isTurquoise: Ember.computed("budgetAttributes.displayColor", function () {
      return this.get("budgetAttributes.displayColor") === "turquoise";
    }),
    isOlive: Ember.computed("budgetAttributes.displayColor", function () {
      return this.get("budgetAttributes.displayColor") === "olive";
    }),
    isOrange: Ember.computed("budgetAttributes.displayColor", function () {
      return this.get("budgetAttributes.displayColor") === "orange";
    }),
    isPurple: Ember.computed("budgetAttributes.displayColor", function () {
      return this.get("budgetAttributes.displayColor") === "purple";
    }),
    isPink: Ember.computed("budgetAttributes.displayColor", function () {
      return this.get("budgetAttributes.displayColor") === "pink";
    }),
    isDropAllowed: Ember.computed("treeManager.dragBudgetLine", "budgetModule.allowedChildTypes.length", function () {
      var budget = this;
      var dragBudgetLine = this.get("treeManager.dragBudgetLine");
      if (!dragBudgetLine) {
        return;
      }
      if (budget.get("id") === dragBudgetLine.get("id")) {
        return false;
      }
      if (budget.get("subBudgetType") !== dragBudgetLine.get("subBudgetType") && !budget.get("isRoot")) {
        return false;
      }
      var overBudgetLine = this;
      var dragBudgetLineType = dragBudgetLine.get("budgetType");
      var isNoGroupingGroup = this.get("budgetType") === "group-no-grouping" || dragBudgetLineType === "group-no-grouping";
      var allowed = this.get("budgetModule.allowedChildTypes") || [];
      if (allowed.indexOf(dragBudgetLineType) >= 0 && !isNoGroupingGroup) {
        if (overBudgetLine.get("isCompany") && dragBudgetLineType !== "group-model" && dragBudgetLineType !== "group-model-parent") {
          // if it's direct child under the root
          var parentId = dragBudgetLine.get("parent.id");
          if (!dragBudgetLine.get("isCompany") && dragBudgetLine.get("parent.isCompany") && !overBudgetLine.get("children").findBy("budgetType", dragBudgetLineType) ||
          // direct under company / sub - company
          dragBudgetLine.get("isCompany") // or is sub-company
          ) {
            return true;
          } else if (parentId === budget.get("id")) {
            return true;
          } else {
            return false;
          }
        } else if (!dragBudgetLine.get("isModelGroup") && dragBudgetLine.get("parent.budgetType") && dragBudgetLine.get("parent.isCompany") && !dragBudgetLine.get("isCompany")) {
          return false;
        } else if (budget.get("selectedRoot") && (dragBudgetLineType === "group-model" || dragBudgetLineType === "group-model-parent")) {
          return true;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }),
    dragType: undefined,
    recalcCollapse: false,
    isNameEncrypted: Ember.computed("name", function () {
      return this.get("name") && this.get("name").startsWith("ENCRYPTED_");
    }),
    hasModels: Ember.computed("children.@each.hasModels", "children.@each.isModelGroup", function () {
      return this.get("children.length") && this.get("children").find(function (c) {
        return c && (c.get("isModelGroup") || c.get("hasModels"));
      });
    }),
    hasRoles: Ember.computed("roles.[]", function () {
      return this.get("roles.length") > 2;
    }),
    hasCurrencies: Ember.computed("currencies.[]", function () {
      return this.get("currencies.length") > 2;
    }),
    allBudgetLines: Ember.computed("treeManager.budgetsList", "treeManager.budgetsList.length", function () {
      return this.get("treeManager.budgetsList").mapBy("budget");
    }),
    _budgetAttributes: Ember.computed("children.@each.budgetAttributes", function () {
      return this.get("children").map(function (child) {
        return child.get("budgetAttributes");
      });
    }),
    roleList: Ember.computed("isRoot", "parent", "budgetService.scenario", "budgetAttributes.role", "_budgetAttributes.@each.role", "budgetService.selectedRoot", function () {
      if (!this.get("root.id") || this.get("budgetService.selectedRoot.id") !== this.get("root.id").split("|")[0]) {
        return [];
      }
      if (this.get("isRoot") || !this.get("parent")) {
        return _budgetUtils.default.getAttributeValuesForBudget(this, "role", this.get("budgetService.scenario")).role;
      } else {
        return [];
      }
    }),
    roles: Ember.computed("roleList", function () {
      if (!this.get("root.id") || this.get("budgetService.selectedRoot.id") !== this.get("root.id").split("|")[0]) {
        return [];
      }
      var result = Ember.A([_forecast.default.roleGrouping]);
      result.addObjects(this.get("roleList"));
      return result;
    }),
    currencies: Ember.computed("fxCurrencies.length", "isRoot", "parent", "budgetService.scenario", "budgetAttributes.currency", "_budgetAttributes.@each.currency", "budgetService.selectedRoot", "budgetService.selectedRoot.absoluteRoot.fxCurrencies.length", function () {
      if (!this.get("root.id") || this.get("budgetService.selectedRoot.id") !== this.get("root.id").split("|")[0]) {
        return [];
      }
      if (this.get("isRoot") || !this.get("parent")) {
        var result = Ember.A([_forecast.default.currencyGrouping]);
        result.addObjects(_budgetUtils.default.getAttributeValuesForBudget(this, "currency", this.get("budgetService.scenario")).currency);
        result.addObjects(this.get("fxCurrencies"));
        result.addObjects(this.get("budgetService.selectedRoot.absoluteRoot.fxCurrencies"));
        return result;
      } else if (this.get("budgetType") === "group-company") {
        return this.get("root.currencies");
      } else {
        return [];
      }
    }),
    currencyChanged: Ember.observer("budgetAttributesWithScenarios.currency", "root.currencies", function () {
      var _this4 = this;
      if (!this.get("isReloading") && !this.get("isDeleted") && (this.get("currencyBefore") || this.get("budgetAttributesWithScenarios.currency"))) {
        var inheritedCurrency = this.get("inheritedAttributes.currency");
        if (inheritedCurrency !== this.get("budgetAttributesWithScenarios.currency")) {
          this.get("children").filter(function (child) {
            return child.get("budgetType") !== "expense-benefit";
          }).forEach(function (child) {
            if (!child.get("budgetAttributesWithScenarios.currency")) {
              if (child.get("budgetService.scenario") && !_budgetUtils.default.hasScenario(child, child.get("budgetService.scenario"))) {
                _budgetUtils.default.createEmptyScenario(child, child.get("budgetService.scenario"), child.get("store"));
              }
              Ember.setProperties(child, {
                "budgetAttributesWithScenarios.currency": _this4.get("currencyBefore") || inheritedCurrency,
                inheritedCurrencyChanged: true
              });
            }
          });
        }
      }
      if (this.get("budgetAttributes.currency") && this.get("root.currencies")) {
        this.get("root.currencies").addObject(this.get("budgetAttributes.currency"));
      }

      // currency changed for budget with single cash opening balance - convert it to cash balance by currency
      if (this.get("isRoot") && this.get("currencyBefore") && this.get("currencyBefore") !== this.get("budgetAttributes.currency") && this.get("cashBalance") && this.getWithDefault("cashBalanceByCurrency.length", 0) === 0) {
        var result = [];
        var cashBalance = this.get("cashBalance");
        var currencyBefore = this.get("currencyBefore");
        this.get("currencies").forEach(function (cur) {
          result.push({
            currency: cur,
            value: cur === currencyBefore ? cashBalance : null
          });
        });
        this.set("cashBalanceByCurrency", result);
      }
      this.set("currencyBefore", this.get("budgetAttributes.currency"));
    }),
    assumptionTypeChanged: Ember.observer("budgetType", "budgetAttributes.amountType", "budgetAttributes.budgetLine", function () {
      if (this.get("budgetType") === "assumption" && this.get("budgetAttributes.amountType") === 3 /* beginning month from */ && this.get("budgetAttributes.modelLine")) {
        var modelLine = _budgetUtils.default.findLineByUniqueId(this.store, this.get("cache"), this.get("budgetAttributes.modelLine"));
        this.set("budgetAttributes.pctCurrency", modelLine && modelLine.get("budgetAttributes.pctCurrency"));
      }
    }),
    assumptionPctCurrencyChanged: Ember.observer("budgetAttributes.pctCurrency", function () {
      if (this.get("budgetType") === "assumption" && this.get("budgetAttributes.pctCurrency") === "%" && this.get("_periodValueBefore") !== "%") {
        this.set("budgetAttributes.periodValue", "a");
      }
      if (this.get("budgetType") === "assumption") {
        this.set("_periodValueBefore", this.get("budgetAttributes.pctCurrency"));
      }
    }),
    isBudgetGrouping: Ember.computed("budgetType", function () {
      // Revenue/Cost/Opex/Other grouping
      return !this.get("isCompany") && this.get("parent.isCompany") && this.get("budgetType").indexOf("group-") === 0;
    }),
    isCompany: Ember.computed("budgetType", function () {
      var isCompany = this.get("budgetType") && this.get("budgetType").indexOf("group-company") === 0;
      return isCompany;
    }),
    isMultiCompany: Ember.computed("isAbsoluteRoot", "children.@each.isCompany", function () {
      return this.get("isAbsoluteRoot") && (this.get("children") || Ember.A([])).findBy("isCompany");
    }),
    ignoreDimensionsTypes: Ember.computed("budgetModule.ignoreDimensionsTypes", function () {
      return this.get("budgetModule.ignoreDimensionsTypes") || [];
    }),
    isRevenue: Ember.computed("budgetType", "budgetModule.revenue", function () {
      return this.get("budgetModule.revenue") || /group.*revenue/.test(this.get("budgetType"));
    }),
    isIntacct: Ember.computed("budgetType", function () {
      return this.get("budgetType") && this.get("budgetType") === "group-company-intacct";
    }),
    isModelLine: Ember.computed("budgetType", function () {
      return this.get("budgetType") && (this.get("budgetType") === "assumption" || this.get("budgetType") === "group-model" || this.get("budgetType") === "group-model-parent");
    }),
    isModelGroup: Ember.computed("budgetType", function () {
      return this.get("budgetType") && (this.get("budgetType") === "group-model" || this.get("budgetType") === "group-model-parent");
    }),
    isModelGroupParent: Ember.computed("budgetType", function () {
      return this.get("budgetType") && this.get("budgetType") === "group-model-parent";
    }),
    isNoGrouping: Ember.computed("budgetType", function () {
      return this.get("budgetType") === "group-no-grouping" || this.get("budgetType") === "no-grouping-line";
    }),
    isGeography: Ember.computed("oldBudgetType", function () {
      return this.get("oldBudgetType") && (this.get("oldBudgetType") === "group-department-geo" || this.get("oldBudgetType") === "group-expense-op-bygeo-bydep");
    }),
    isDepartment: Ember.computed("oldBudgetType", function () {
      return this.get("oldBudgetType") && this.get("oldBudgetType").indexOf("group-department") === 0;
    }),
    isEmptyGroup: Ember.computed("allowChildren", "hasChildren", function () {
      return this.get("allowChildren") && !this.get("hasChildren");
    }),
    isEmployeeOrContractor: Ember.computed("budgetType", function () {
      return this.get("budgetType") === "employee" || this.get("budgetType") === "employee-multi" || this.get("budgetType") === "contractor";
    }),
    isEmployee: Ember.computed("budgetType", function () {
      return this.get("budgetType") === "employee" || this.get("budgetType") === "employee-multi";
    }),
    isEmployeeIncludeAllocated: Ember.computed("budgetType", function () {
      return this.get("budgetType") === "employee" || this.get("budgetType") === "employee-multi" || this.get("budgetType") === "employee-allocated" || this.get("budgetType") === "employee-transferred";
    }),
    isNotEmployeeMultiOrPer: Ember.computed("budgetType", function () {
      return this.get("budgetType") !== "employee" && this.get("budgetType") !== "per-employee" && this.get("budgetType") !== "employee-multi" && this.get("budgetType") !== "per-new-employee";
    }),
    isBenefit: Ember.computed("budgetType", function () {
      return this.get("budgetType") === "expense-benefit";
    }),
    title: Ember.computed("name", "budgetAttributes.role", "budgetType", function () {
      if ((this.get("budgetType") === "employee" || this.get("budgetType") === "employee-multi") && this.get("budgetAttributes.role") && (!this.get("name") || this.get("name") === "-")) {
        return this.get("budgetAttributes.role");
      }
      return this.get("name");
    }),
    readOnly: _emberData.default.attr("boolean"),
    inheritedReadOnly: Ember.computed("readOnly", "isRoot", "parent.inheritedReadOnly", "budgetService.selectedRootPermissions", "budgetService.sharedScenarios.length", "budgetService.scenario", "budgetService.selectedRoot.isPartialShare", "budgetService.sharedReadOnly", function () {
      var includeModels = this.get("budgetService.selectedRootPermissions.options.includeModels");
      if (includeModels && includeModels.includes(this.get("id"))) {
        return this.get("budgetService.selectedRoot.isPartialShare") && this.get("budgetService.sharedReadOnly") || null;
      }
      if (this.get("budgetService.sharedScenarios.length")) {
        return !this.get("budgetService.sharedScenarios").includes(this.get("budgetService.scenario")) || null;
      }
      return this.get("readOnly") || !this.get("isRoot") && this.get("parent.inheritedReadOnly") || null;
    }),
    inheritedReadOnlyOrLock: Ember.computed("inheritedReadOnly", "inheritedIsLocked", function () {
      return this.get("inheritedIsLocked") || this.get("inheritedReadOnly") || this.get("isNoGrouping") || null;
    }),
    isLocked: _emberData.default.attr("boolean"),
    inheritedIsLocked: Ember.computed("isLocked", "parent", "parent.inheritedIsLocked", "budgetService.scenario", function () {
      if (this.get("budgetService.scenario")) {
        return false;
      }
      return this.get("isLocked") || this.get("parent") && this.get("parent.inheritedIsLocked");
    }),
    isRoot: _emberData.default.attr("boolean"),
    isAbsoluteRoot: Ember.computed("absoluteRoot", function () {
      return this === this.get("absoluteRoot") && this.get("isRoot");
    }),
    flagged: _emberData.default.attr("boolean"),
    isNewMissingLine: _emberData.default.attr("boolean"),
    isNewLine: _emberData.default.attr("boolean"),
    root: Ember.computed("isRoot", "parent", "isNew", "budgetService.selectedRoot", function () {
      var rootBudget;
      if (this.get("isNew")) {
        rootBudget = this.get("budgetService.selectedRoot");
      }
      return rootBudget || getRoot(this);
    }),
    absoluteRoot: Ember.computed("root", "root.parent", "isNew", function () {
      if (this.get("root") && this.get("root.parent")) {
        return getRoot(this.get("root.parent"), true);
      }
      return this.get("root");
    }),
    isPartialShare: Ember.computed("parent", "isRoot", function () {
      return this.get("isRoot") && this.get("parent");
    }),
    yearStartInherited: Ember.computed("absoluteRoot.yearStart", function () {
      return Ember.isEmpty(this.get("absoluteRoot.yearStart")) ? new Date(Date.parse(this.get("absoluteRoot.forecastAttributes.startDate"))).getMonth() : this.get("absoluteRoot.yearStart");
    }),
    defaultRoute: Ember.computed("isRoot", function () {
      return this.get("isRoot") ? "main.budget.forecast" : "main.budget";
    }),
    oldBudgetType: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    budgetType: _emberData.default.attr("string", {
      defaultValue: "group"
    }),
    budgetRootType: _emberData.default.attr("string", {
      defaultValue: "group-company"
    }),
    hasNoGroupingChild: Ember.computed("children.length", function () {
      return this.get("children").filter(function (c) {
        return c.get("budgetType") === "group-no-grouping";
      }).length > 0;
    }),
    isNonProfitBudget: Ember.computed("budgetRootType", "parent", function () {
      return this.get("budgetRootType") === "group-company-non-profit";
    }),
    displayBudgetType: Ember.computed("budgetModule.name", "budgetModule.typeLabel", "budgetType", "parent", "isNonProfitBudget", function () {
      if (this.get("parent.isCompany") && this.get("budgetType") === "group-revenue") {
        return intl.t("general.title_1695611597600");
      } else if (this.get("isCompany") && this.get("parent.isCompany")) {
        return intl.t("general.subsidiary_1698234256840");
      } else if (this.get("isCompany") && !this.get("parent.isCompany") && this.get("isNonProfitBudget")) {
        return intl.t("general.name_1695611601240");
      } else if ((this.get("budgetType") === "group-expense" || this.get("budgetType") === "group-expense-op-bygeo" || this.get("budgetType") === "group-expense-op-bydepartment" || this.get("budgetType") === "group-expense-op-simple") && this.get("parent.isCompany")) {
        return intl.t("general.text_1695611611351");
      } else if (this.get("budgetType") === "group-expense-other" && this.get("parent.isCompany")) {
        return intl.t("general.title_1695611599673");
      } else {
        return this.get("budgetModule.typeLabel") || this.get("budgetModule.name");
      }
    }),
    budgetModule: Ember.computed("budgetType", function () {
      var budgetModule = this.store.peekRecord("budgetModule", this.get("budgetType"));
      if (!budgetModule) {
        // fail safe
        budgetModule = this.store.peekRecord("budgetModule", "group");
      }
      if (budgetModule && budgetModule.get("id") === "employee-multi" && budgetModule.get("budgetAttributes.customAmounts.defaultValue")) {
        Ember.set(budgetModule, "budgetAttributes.customAmounts.defaultValue", {});
      }
      return budgetModule;
    }),
    exchangeRatesCustom: Ember.computed.alias("root.fx"),
    budgetModuleObserver: Ember.observer("budgetModule", function () {
      var budgetTypeBefore = this.get("budgetTypeBefore");
      if (this.get("isNew")) {
        _const.default.setInitialAttributes(this);
      }
      if (budgetTypeBefore !== this.get("budgetType")) {
        _const.default.setDefaultRevenueType(this);
        this.set("budgetAttributes.allocatedFrom", undefined);
        (this.get("budgetAttributes.dimensions") || Ember.A([])).setEach("values", []);

        // reset to default values
        var attrs = this.get("budgetModule.budgetAttributes");
        var didChangeAttr = false;
        for (var attr in attrs) {
          var attrData = attrs[attr];
          if (Ember.isArray(attrData) && attrData[0].name) {
            attrData = attrData[0].name;
          }
          if (attrData.hasOwnProperty("defaultValue")) {
            if (this.get("budgetService").shouldResetAttribute({
              attr: attr,
              budgetTypeBefore: budgetTypeBefore,
              budgetTypeAfter: this.get("budgetType")
            })) {
              didChangeAttr = true;
              this.set("budgetAttributes." + attr, attrData.defaultValue);
            }
          }
        }
        if (didChangeAttr) {
          this.notifyPropertyChange("budgetAttributes");
        }
      }
      this.setSiblingsType();
      this.set("budgetTypeBefore", this.get("budgetType"));
    }),
    setSiblingsType: function setSiblingsType() {
      if (this.get("isNew") && !Ember.isEmpty(this.get("budgetType"))) {
        // find all new siblings with no type, and change to the same type
        var siblings = this.get("parent.children");
        if (siblings) {
          siblings.filter(function (sibling) {
            return sibling.get("isNew") && Ember.isEmpty(sibling.get("budgetType"));
          }).setEach("budgetType", this.get("budgetType"));
        }
      }
    },
    useTemplate: Ember.computed("hasChildren", "budgetModule", function () {
      return !this.get("hasChildren") && !Ember.isEmpty(this.get("budgetModule.template"));
    }),
    allowChildren: Ember.computed("budgetModule", function () {
      return this.get("budgetModule.isGroup");
    }),
    startDate: _emberData.default.attr("date"),
    startDateMoment: Ember.computed("budgetAttributes.startDate", {
      get: function get() {
        return this.get("budgetAttributes.startDate") ? moment(this.get("budgetAttributes.startDate")) : null;
      },
      set: function set(key, value) {
        if (value) {
          this.set("budgetAttributes.startDate", value.toDate());
          return value;
        }
        return this.get("budgetAttributes.startDate") ? moment(this.get("budgetAttributes.startDate")) : null;
      }
    }),
    startDateFormatted: Ember.computed("startDateMoment", {
      get: function get() {
        return this.get("startDateMoment") ? this.get("startDateMoment").format(_const.default.MomentMonthYearDateFormat) : null;
      },
      set: function set(key, value) {
        if (value) {
          this.set("budgetAttributes.startDate", moment(value, _const.default.MomentMonthYearDateFormat).toDate());
        } else {
          this.set("budgetAttributes.startDate", null);
        }
        return value;
      }
    }),
    endDateMoment: Ember.computed("budgetAttributes.endDate", {
      get: function get() {
        return this.get("budgetAttributes.endDate") ? moment(this.get("budgetAttributes.endDate")) : null;
      },
      set: function set(key, value) {
        if (value) {
          this.set("budgetAttributes.endDate", value.toDate());
          return value;
        }
        return this.get("budgetAttributes.endDate") ? moment(this.get("budgetAttributes.endDate")) : null;
      }
    }),
    endDateFormatted: Ember.computed("endDateMoment", {
      get: function get() {
        return this.get("endDateMoment") ? this.get("endDateMoment").format(_const.default.MomentMonthYearDateFormat) : null;
      },
      set: function set(key, value) {
        if (value) {
          this.set("budgetAttributes.endDate", moment(value, _const.default.MomentMonthYearDateFormat).toDate());
        } else {
          this.set("budgetAttributes.endDate", null);
        }
        return value;
      }
    }),
    parent: _emberData.default.belongsTo("budget", {
      inverse: "children",
      async: false
    }),
    scenarios: _emberData.default.hasMany("scenario", {
      inverse: "budget",
      async: _environment.default.environment === "test",
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    comments: _emberData.default.hasMany("comment", {
      inverse: "budget",
      async: false,
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    displayComments: Ember.computed("comments.@each.comment", function () {
      return this.get("comments").filter(function (comment) {
        return comment.get("comment");
      }).map(function (comment) {
        if (comment.get("comment")) {
          var name = !comment.get("user.firstName") || !comment.get("user.lastName") ? "Unknown" : "".concat(comment.get("user.firstName"), " ").concat(comment.get("user.lastName"));
          return "".concat(name, ": ").concat(comment.get("comment"));
        }
        return comment.get("comment");
      }).join(". ");
    }),
    dimensions: _emberData.default.hasMany("dimension", {
      inverse: "budget",
      async: _environment.default.environment === "test",
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    actualAccounts: _emberData.default.hasMany("actual-account", {
      inverse: "budget",
      async: false,
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    dimensionsMapping: _emberData.default.hasMany("dimension-mapping", {
      inverse: "budget",
      async: false,
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    dimensionsValuesMapping: _emberData.default.hasMany("dimension-value-mapping", {
      inverse: "budget",
      async: false,
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    balanceSheetAccounts: Ember.computed("actualAccounts.@each.type", function () {
      return this.get("actualAccounts").filterBy("type", "bs");
    }),
    transactions: _emberData.default.hasMany("transaction", {
      async: false,
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    accountsRawList: Ember.computed("actualsMapping.accounts.@each.budgetLinesLength", function () {
      return this.get("actualsMapping").getAccountsForLine(this.get("uniqueId"));
    }),
    accountIds: Ember.computed("accountsRawList", function () {
      var accountsForLine = this.get("accountsRawList");
      var accounts = accountsForLine && accountsForLine.mapBy("account").join(",");
      return this.get("isNoGrouping") && this.get("budgetAttributes.accountId") ? this.get("budgetAttributes.accountId") : accounts;
    }),
    directAccountIds: Ember.computed("accountsRawList", "parent.directAccountIds", function () {
      var _this5 = this;
      var accountsForLine = this.get("accountsRawList");
      var budgetId = this.get("uniqueId");
      var currentAccountslist = accountsForLine && accountsForLine.filter(function (account) {
        var lines = Ember.get(account, "budgetLines").map(function (line) {
          return line.split(":")[0];
        });
        if (Ember.get(account, "mappingType") === "clone") {
          lines = _this5.getCloneAccountBudgetLines(account);
        }
        return (lines || []).includes(budgetId);
      }).mapBy("account");
      if (this.get("parent") && (this.get("parent.budgetType") !== "group-payroll" || this.get("parent.budgetType") === "group-payroll" && (this.get("isBenefit") || this.get("isEmployeeIncludeAllocated")))) {
        return this.get("parent.directAccountIds").concat(currentAccountslist); // Add the accounts of the ancestors to the current
      }
      return currentAccountslist;
    }),
    getCloneAccountBudgetLines: function getCloneAccountBudgetLines(account) {
      if (account && Ember.get(account, "mappingType") !== "clone") {
        return Ember.get(account, "budgetLines");
      }
      var nextAccount = (this.get("actualsMapping.accounts") || []).findBy("account", Ember.get(account, "copyFromAccount"));
      return this.getCloneAccountBudgetLines(nextAccount);
    },
    accountIdsList: Ember.computed("accountIds", function () {
      var accountIds = this.get("accountIds");
      return (accountIds && accountIds.split(",") || []).filter(function (id) {
        return id;
      });
    }),
    hierarchicalAccountIdList: Ember.computed("accountIds", "parent", "parent.hierarchicalAccountIdList", function () {
      var currentAccountslist = this.get("accountIdsList"); // Getting the accounts list of the current budget line
      // Checking if a parent is present
      if (this.get("parent")) {
        return this.get("parent.hierarchicalAccountIdList").concat(currentAccountslist); // Add the accounts of the ancestors to the current
      }
      return currentAccountslist;
    }),
    changes: _emberData.default.hasMany("change", {
      async: true,
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    prettyChanges: Ember.computed("changes", function () {
      // 'after':
      // if there is a value, it means that it was changed to.
      // if there is no value, it means that it was deleted.
      var _this = this;
      var budgetModule = _this.get("budgetModule");
      if (!this.get("changes")) {
        return [];
      }
      var filteredChanges = this.get("changes").filter(function (change) {
        var prettyChange = change.toJSON();
        if ((0, _typeof2.default)(prettyChange.before) === "object" && (Object.keys(prettyChange.before).length === 0 || _const.default.isEmptyObject(prettyChange.before))) {
          prettyChange.before = null;
        }
        if ((0, _typeof2.default)(prettyChange.after) === "object" && (Object.keys(prettyChange.after).length === 0 || _const.default.isEmptyObject(prettyChange.after))) {
          prettyChange.after = null;
        }
        if (Ember.isArray(prettyChange.before) && prettyChange.before.length === 1 && (0, _typeof2.default)(prettyChange.before[0]) === "object" && prettyChange.before[0].name === "") {
          prettyChange.before = null;
        }
        if (Ember.isArray(prettyChange.after) && prettyChange.after.length === 1 && (0, _typeof2.default)(prettyChange.after[0]) === "object" && prettyChange.after[0].name === "") {
          prettyChange.after = null;
        }
        if (typeof prettyChange.after === "string" && prettyChange.after.endsWith("T00:00:00.000Z") && Date.create(prettyChange.after).daysSince(prettyChange.before) === 0) {
          return false;
        }
        if (prettyChange.what === "category" && prettyChange.after === _const.default.DEFAULT_SALARY_CATEGORIES_ARRAY.join(",") && !prettyChange.before) {
          return false;
        }
        if (prettyChange.what.indexOf("dimensions") === 0) {
          return true;
        }
        return (prettyChange.before || prettyChange.after) && !(Ember.isEmpty(prettyChange.before) && prettyChange.after === budgetModule.get("budgetAttributes.".concat(prettyChange.what, ".defaultValue"))) && !((!change.get("before") || (0, _typeof2.default)(change.get("before")) === "object" && Object.keys(change.get("before")).length === 0) && (!change.get("after") || (0, _typeof2.default)(change.get("after")) === "object" && Object.keys(change.get("after")).length === 0)) && !(change.get("what") === "openingBalances" && !change.get("after")) && change.get("before") !== "[object Object]" && change.get("after") !== "[object Object]" && change.get("what").split(",")[2] !== "formatted" && change.get("what").split(",")[2] !== "index" && change.get("what").split(",")[2] !== "isCash" && change.get("what").split(",")[2] !== "_id" || change.get("what") === "import";
      });

      // break down custom amounts changes
      filteredChanges.filter(function (c) {
        return Ember.get(c, "what") === "customAmounts" && (0, _typeof2.default)(Ember.get(c, "after")) === "object" && Object.keys(Ember.get(c, "after")).length > 0;
      }).forEach(function (c) {
        var args = [filteredChanges.indexOf(c), 1];
        args.pushObjects(Object.keys(Ember.get(c, "after")).map(function (k) {
          return {
            timestamp: c.get("timestamp").toJSON(),
            user: c.get("user"),
            budget: c.get("budget"),
            what: "customAmounts,".concat(k),
            before: null,
            after: c.get("after.".concat(k))
          };
        }));
        Array.prototype.splice.apply(filteredChanges, args);
      });
      var changes = filteredChanges.map(function (change) {
        var prettyChange = change.toJSON ? change.toJSON() : change;
        if (prettyChange.before && (0, _typeof2.default)(prettyChange.before) === "object" && (Object.keys(prettyChange.before).length === 0 || _const.default.isEmptyObject(prettyChange.before))) {
          prettyChange.before = null;
        }
        if (prettyChange.after && (0, _typeof2.default)(prettyChange.after) === "object" && (Object.keys(prettyChange.after).length === 0 || _const.default.isEmptyObject(prettyChange.after))) {
          prettyChange.after = null;
        }
        if (Ember.isArray(prettyChange.before) && prettyChange.before.length === 1 && (0, _typeof2.default)(prettyChange.before[0]) === "object" && prettyChange.before[0].name === "") {
          prettyChange.before = null;
        }
        if (Ember.isArray(prettyChange.after) && prettyChange.after.length === 1 && (0, _typeof2.default)(prettyChange.after[0]) === "object" && prettyChange.after[0].name === "") {
          prettyChange.after = null;
        }
        if (prettyChange.after && (0, _typeof2.default)(prettyChange.after) === "object" && prettyChange.after.hasOwnProperty("amount")) {
          prettyChange.after = Ember.get(prettyChange.after, "amount");
        }
        prettyChange.user = Ember.get(change, "user");
        var what = Ember.get(change, "what").split(",");
        if (Ember.get(change, "what") === "import") {
          prettyChange.import = true;
        }
        var thisAttr = budgetModule.get("budgetAttributes." + what[0]);
        if (thisAttr && Ember.isArray(thisAttr)) {
          thisAttr = thisAttr[0];
        }
        var isPctBefore = false;
        var isPctAfter = false;
        if (thisAttr) {
          if (thisAttr.label) {
            Ember.set(prettyChange, "what", thisAttr.label);
          } else {
            Ember.set(prettyChange, "what", _const.default.camelize(what[0] || ""));
          }
          if (what[0] === "openingBalances") {
            prettyChange.what = _this.get("budgetAttributes.openingBalances.".concat(what[1], ".displayName")) + intl.t("general._opening_balance_1698234256840");
          } else if (what[0] === "dimensions") {
            var dimensionChanges = _this.get("changeLogService").getDimensionChanges(prettyChange, what, _this.get("absoluteRoot.dimensions"), _this.get("budgetAttributes.dimensions"));
            if (!dimensionChanges) {
              return null;
            } else {
              prettyChange.what += intl.t("general._for__1698234256839") + dimensionChanges.what;
              prettyChange.before = dimensionChanges.before;
              prettyChange.after = dimensionChanges.after;
            }
          } else if (what[1]) {
            prettyChange.what += intl.t("general._for__1698234256839") + what[1].replace(/m(\d{2})(\d{4})/g, "$1/$2");
          }
          if (thisAttr.enum && thisAttr.enumLabels) {
            try {
              prettyChange.before = thisAttr.enumLabels[thisAttr.enum.indexOf(Ember.get(change, "before"))];
              if (prettyChange.before === undefined) {
                prettyChange.before = thisAttr.enumLabels[thisAttr.enum.indexOf(1 * Ember.get(change, "before"))];
              }
              prettyChange.before = prettyChange.before.replace(/each {{.*}}/g, "");
            } catch (e) {
              // ignore
            }
            try {
              prettyChange.after = thisAttr.enumLabels[thisAttr.enum.indexOf(Ember.get(change, "after"))];
              if (prettyChange.after === undefined) {
                prettyChange.after = thisAttr.enumLabels[thisAttr.enum.indexOf(1 * Ember.get(change, "after"))];
              }
              prettyChange.after = prettyChange.after.replace(/each {{.*}}/g, "");
            } catch (e) {
              // ignore
            }
          }
          if (thisAttr.displayType === "pct-fixed") {
            var beforeChange = _const.default.getTextValue(prettyChange.before);
            var afterChange = _const.default.getTextValue(prettyChange.after);
            prettyChange.before = beforeChange;
            prettyChange.after = afterChange;
          }
          if (thisAttr.type === "date") {
            if (prettyChange.before) {
              prettyChange.before = moment(prettyChange.before).format(_const.default.MomentMonthYearDateFormat);
            }
            if (prettyChange.after) {
              prettyChange.after = moment(prettyChange.after).format(_const.default.MomentMonthYearDateFormat);
            }
            if (prettyChange.after === prettyChange.before) {
              // UTC issue (no change)
              return null;
            }
          } else {
            if (prettyChange.before && prettyChange.before.toString() === "[object Object]") {
              prettyChange.before = null;
            }
            if (prettyChange.after && prettyChange.after.toString() === "[object Object]") {
              prettyChange.after = null;
            }
            try {
              var beforeBudget = _budgetUtils.default.findLineByUniqueId(_this.store, _this.get("cache"), prettyChange.before);
              if (beforeBudget) {
                prettyChange.before = Ember.get(beforeBudget, "title") || intl.t("general.budget_line_1698234256839");
              } else if (_formulaHelper.default.isFormulaString(prettyChange.before)) {
                prettyChange.before = _formulaHelper.default.convertFormulaToStringWithoutCheck(prettyChange.before);
              } else if (!isNaN(parseInt(prettyChange.before))) {
                prettyChange.before = (1 * prettyChange.before).round(2);
                if (isPctBefore || thisAttr.labelAfter === "%") {
                  prettyChange.before += "%";
                }
              }
            } catch (e) {
              // ignore
            }
            try {
              var afterBudget = _budgetUtils.default.findLineByUniqueId(_this.store, _this.get("cache"), prettyChange.after);
              if (afterBudget) {
                prettyChange.after = Ember.get(afterBudget, "title") || intl.t("general.budget_line_1698234256839");
              } else if (_formulaHelper.default.isFormulaString(prettyChange.after)) {
                prettyChange.after = _formulaHelper.default.convertFormulaToStringWithoutCheck(prettyChange.after);
              } else if (!isNaN(parseInt(prettyChange.after))) {
                prettyChange.after = (1 * prettyChange.after).round(2);
                if (isPctAfter || thisAttr.labelAfter === "%") {
                  prettyChange.after += "%";
                }
              }
            } catch (e) {
              // ignore
            }
          }
        }
        return prettyChange;
      }).filter(function (item) {
        return item;
      }).sortBy("timestamp").reverse();
      return changes;
    }),
    originalBudgetTree: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    shares: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    sharingTokens: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    fixSharingTokens: Ember.observer("sharingTokens", function () {
      if (this.get("sharingTokens") && this.get("sharingTokens").constructor.name !== "Array") {
        this.set("sharingTokens", []);
      }
    }),
    baselineData: _emberData.default.attr("raw"),
    // for testing only

    editors: Ember.computed("shares", function () {
      return this.get("shares") ? this.get("shares").filter(function (share) {
        return share.access !== "view";
      }).map(function (share) {
        return share.user.email;
      }) : [];
    }),
    viewers: Ember.computed("shares", function () {
      return this.get("shares") ? this.get("shares").filter(function (share) {
        return share.access === "view";
      }).map(function (share) {
        return share.user.email;
      }) : [];
    }),
    hasShares: Ember.computed("shares", "sharingTokens", function () {
      return this.get("shares.length") > 1 || this.get("sharingTokens.length") > 0 && this.get("sharingTokens").filterBy("email").length > 0;
    }),
    ancestorShares: Ember.computed("hasShares", "parent.ancestorShares", function () {
      if (this.get("hasShares")) {
        return this.get("shares.length") + this.get("sharingTokens.length") - 1;
      } else {
        return this.get("parent") ? this.get("parent.ancestorShares") : 0;
      }
    }),
    hideTotal: Ember.computed("budgetType", "budgetAttributes.pctCurrency", function () {
      return false;
    }),
    children: _emberData.default.hasMany("budget", {
      async: true,
      inverse: "parent"
    }),
    benefits: Ember.computed("children.@each.budgetType", "budgetService.scenario", function () {
      return _budgetUtils.default.getBenefits(this, {
        scenario: this.get("budgetService.scenario")
      });
    }),
    inheritedBenefits: Ember.computed("budgetType", "parent.benefits.length", "absoluteRoot.benefits.length", function () {
      return _budgetUtils.default.getInheritedBenefits(this);
    }),
    combinedBenefits: Ember.computed("budgetType", "budgetAttributesWithScenarios.allocatedFrom", "benefits.@each.name", "inheritedBenefits.@each.name", function () {
      var _this6 = this;
      var result = [];
      if (this.get("budgetType") === "employee-transferred" || this.get("budgetType") === "employee-allocated") {
        if (this.get("budgetAttributesWithScenarios.allocatedFrom")) {
          var allocatedFrom = _budgetUtils.default.findLineByUniqueId(this.store, this.get("cache"), this.get("budgetAttributesWithScenarios.allocatedFrom"), this.get("absoluteRoot.id"));
          if (allocatedFrom) {
            return allocatedFrom.get("combinedBenefits");
          }
          return [];
        }
        return [];
      }
      result.pushObjects(this.get("benefits"));
      result.pushObjects(this.get("inheritedBenefits").filter(function (b) {
        return !_this6.get("benefits").mapBy("name").includes(b.get("name"));
      }));
      return result;
    }),
    hasChildren: Ember.computed("children.@each.isBenefit", function () {
      var _this$get;
      return (_this$get = this.get("children")) === null || _this$get === void 0 ? void 0 : _this$get.any(function (c) {
        return c && !c.get("isBenefit");
      });
    }),
    hasNewChildren: Ember.computed("children.@each.isNew", function () {
      return this.get("children").find(function (c) {
        return c && c.get("isNew");
      });
    }),
    downloadFilename: Ember.computed("name", function () {
      return this.get("name") + ".bdg";
    }),
    backupLink: Ember.computed("id", "name", function () {
      if (!_const.default.FF_DUP_BUD_NO_VERSIONS) {
        return "".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/budgets/").concat(this.get("id"), "/export?name=").concat(this.get("name").replace(/[^\w.-\s]/g, "_"));
      } else {
        return null;
        //TODO delete backupLink computed after opening FF
      }
    }),
    bsAccounts: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    closedActuals: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    includedInForecast: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    actuals: _emberData.default.attr("raw"),
    forecast: _emberData.default.attr("raw"),
    importMapping: _emberData.default.attr("string"),
    cashBalance: _emberData.default.attr("number"),
    cashBalanceByCurrency: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    fx: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    fxCurrencies: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return _const.default.DEFAULT_CURRENCIES.slice();
      }
    }),
    fxBase: _emberData.default.attr("string"),
    yearStart: _emberData.default.attr("number"),
    accountsInInput: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    accountsInSheets: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    revenueCategories: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    setupGuideOptions: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isIntacctSync: _emberData.default.attr("boolean"),
    intacctBook: _emberData.default.attr("string"),
    intacctImportFilter: _emberData.default.attr("raw"),
    dashboardOrder: _emberData.default.attr("raw"),
    dashboardList: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    dashboardListObserver: Ember.observer("isNonProfitBudget", "dashboardList", "dashboardList.length", "uniqueId", function () {
      this.dashboardListChanged();
    }),
    dashboardListChanged: function dashboardListChanged() {
      var _this7 = this;
      Ember.run.next(function () {
        var dashboardList = _this7.get("dashboardList"),
          budgetId = _this7.get("uniqueId"),
          updateDashboardList = false;
        if (!_this7.get("isRoot") || !budgetId) {
          return;
        }
        if (dashboardList && Ember.get(dashboardList, "length")) {
          dashboardList = dashboardList.filter(function (dashboard) {
            if (!Ember.get(dashboard, "uniqueId")) {
              updateDashboardList = true;
            }
            return Ember.get(dashboard, "uniqueId");
          });
          if (!Ember.get(dashboardList, "length")) {
            dashboardList = undefined;
          }
        }
        if (Ember.isEmpty(dashboardList)) {
          updateDashboardList = false;
          _this7.set("dashboardList", Ember.A([{
            uniqueId: budgetId,
            title: intl.t("general.title_1695611599235"),
            type: "budget",
            fixAssumptions: Ember.A([])
          }]));
          _this7.set("dashboardOrder", _dashboard.default.getDefaultChartsForBudget(budgetId, _this7.get("isNonProfitBudget")));
        }
        if (updateDashboardList) {
          _this7.set("dashboardList", dashboardList);
        }
      });
    },
    budgetAttributes: _emberData.default.attr("budgetAttributes"),
    currentAttributes: _emberData.default.attr("raw"),
    inheritedAttributes: Ember.computed("budgetAttributes", "parent.inheritedAttributes", "budgetService.scenario", function () {
      var result = _budgetUtils.default.getInheritedAttributes(this, this.get("budgetService.scenario"));
      result.currency = result.currency || "USD";
      return result;
    }),
    isPerEmployee: Ember.computed("budgetType", function () {
      return this.get("budgetType") === "per-employee" || this.get("budgetType") === "per-new-employee";
    }),
    isEmptyAmounts: Ember.computed("isPerEmployee", "allowChildren", "budgetAttributes", "budgetAttributes.customAmounts", "budgetAttributes.amount", "budgetAttributes.newAmount", "budgetAttributes.newModelLine", "budgetAttributes.base", "budgetAttributes.bonus", "budgetAttributes.rate", "budgetAttributes.allocatedFrom", "isModelLine", "hasScenarioChanges", function () {
      return !this.get("allowChildren") && !this.get("isModelLine") && (this.get("isPerEmployee") || Ember.isEmpty(this.get("budgetAttributes.allocatedFrom"))) && Ember.isEmpty(this.get("budgetAttributes.newModelLine")) && (Ember.isEmpty(this.get("budgetAttributes.amount")) || Object.keys(this.get("budgetModule.budgetAttributes")).includes("amountType") && this.get("budgetAttributes.amountType") === 1) && (Ember.isEmpty(this.get("budgetAttributes.newAmount")) || this.get("budgetAttributes.newAmountType") === 1) && Ember.isEmpty(this.get("budgetAttributes.base")) && Ember.isEmpty(this.get("budgetAttributes.bonus")) && Ember.isEmpty(this.get("budgetAttributes.rate")) && _budgetUtils.default.isEmptyCustomAmounts(this.get("budgetAttributes.customAmounts")) && _budgetUtils.default.isEmptyCustomAmounts(this.get("budgetAttributes.newCustomAmounts")) && !this.get("hasScenarioChanges");
    }),
    forecastAttributes: Ember.computed("budgetAttributes", "parent.budgetAttributes", "parent.parent.budgetAttributes", "parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.parent.parent.budgetAttributes", function () {
      return _budgetUtils.default.getForecastAttributes(this);
    }),
    forecastAttributesWithScenario: Ember.computed("budgetService.scenario", "budgetAttributes", "parent.budgetAttributes", "parent.parent.budgetAttributes", "parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.parent.parent.budgetAttributes", function () {
      return _budgetUtils.default.getForecastAttributes(this, this.get("budgetService.scenario"));
    }),
    forecastAttributesScenario: Ember.computed("budgetAttributes", "parent.budgetAttributes", "parent.parent.budgetAttributes", "parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.parent.budgetAttributes", "parent.parent.parent.parent.parent.parent.parent.budgetAttributes", "scenarios", function () {
      var result = {},
        _this = this;
      getScenarios(_this).forEach(function (scenario) {
        result[scenario] = _budgetUtils.default.getForecastAttributes(_this, scenario);
      });
      return result;
    }),
    departments: Ember.computed("forecastAttributes.dimensions.@each.values", "budgetService.dimensions.@each.values", function () {
      var departmentDimension = (this.get("budgetService.dimensions") || Ember.A([])).findBy("isDepartment");
      var thisValues = departmentDimension && (this.get("forecastAttributes.dimensions") || Ember.A([])).findBy("dimension", departmentDimension.get("uniqueId"));
      thisValues = thisValues && Ember.get(thisValues, "values");
      return (thisValues || []).map(function (v) {
        var thisValue = departmentDimension.get("values").findBy("uniqueId", Ember.get(v, "value"));
        return thisValue;
      }).filter(function (v) {
        return v;
      });
    }),
    isAddedToCurrentScenario: Ember.computed("budgetService.scenario", "parent.scenarios", "parent.isAddedToCurrentScenario", function () {
      var budgetLine = this,
        activeScenario = budgetLine.get("budgetService.scenario"),
        parentScenarios = budgetLine.get("parent.scenarios") || [],
        budgetUniqueId = budgetLine.get("uniqueId"),
        currentScenario = parentScenarios.find(function (singelScenario) {
          return singelScenario.get("scenario") === activeScenario;
        });
      if (currentScenario) {
        var addedChildren = currentScenario.get("addedChildren") || [];
        if (addedChildren.includes(budgetUniqueId)) {
          return true;
        }
      }
      return budgetLine.get("parent.isAddedToCurrentScenario") || false;
    }),
    departmentNames: Ember.computed("departments.@each.name", function () {
      return (this.get("departments") || Ember.A([])).mapBy("name");
    }),
    categories: Ember.computed("forecastAttributes.dimensions.@each.values", "budgetService.dimensions.@each.values", function () {
      var categoryDimension = (this.get("budgetService.dimensions") || Ember.A([])).findBy("isCategory");
      var thisValues = categoryDimension && (this.get("forecastAttributes.dimensions") || Ember.A([])).findBy("dimension", categoryDimension.get("uniqueId"));
      thisValues = thisValues && Ember.get(thisValues, "values") || [];
      if (thisValues && this.get("isBenefit") && categoryDimension) {
        var dim = categoryDimension.get("values").findBy("benefitRef", this.get("uniqueId"));
        dim && thisValues.push({
          value: dim.uniqueId
        });
      }
      return (thisValues || []).map(function (v) {
        var thisValue = categoryDimension.get("values").findBy("uniqueId", Ember.get(v, "value"));
        return thisValue;
      }).filter(function (v) {
        return v;
      });
    }),
    initAttributes: Ember.observer("budgetAttributes", function () {
      if (Ember.isEmpty(this.get("budgetAttributes.assumptions"))) {
        this.set("budgetAttributes.assumptions", []);
      }
    }),
    verifyDashboardLines: function verifyDashboardLines() {
      if (typeof this.get("budgetAttributes.dashboardLines") === "string") {
        this.set("budgetAttributes.dashboardLines", []);
      }
    },
    versions: _emberData.default.hasMany("version", {
      async: true
    }),
    hasDisabledVersions: Ember.computed("budgetService.selectedRoot.isPartialShare", "versions", "versions.@each.disableVersion", function () {
      if (this.get("budgetService.selectedRoot.isPartialShare")) {
        return true;
      }
      if ((this.get("versions") || []).any(function (version) {
        return version.get("disableVersion");
      })) {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.DISABLED_VERSIONS"), {
          budget_root_name: this.get("absoluteRoot.name")
        });
        return true;
      }
    }),
    manualVersions: Ember.computed("versions", function () {
      return this.get("versions") ? this.get("versions").filterBy("auto", false) : null;
    }),
    addedForScenarios: Ember.computed("parent.scenarios.@each.addedChildren", "parent.addedForScenarios.length", function () {
      var _this = this,
        result = Ember.A([]);
      result.addObjects(this.get("parent.addedForScenarios") || []);
      if (Ember.isEmpty(this.get("parent.scenarios"))) {
        return result;
      }
      this.get("parent.scenarios").forEach(function (parentScenario) {
        if (parentScenario.get("addedChildren") && parentScenario.get("addedChildren").indexOf(_this.get("uniqueId")) >= 0) {
          result.addObject(parentScenario.get("scenario"));
        }
      });
      return result;
    }),
    deletedForScenarios: Ember.computed("parent.scenarios.@each.deletedChildren", "parent.deletedForScenarios.length", function () {
      var _this = this,
        result = Ember.A([]);
      result.addObjects(this.get("parent.deletedForScenarios") || []);
      if (Ember.isEmpty(this.get("parent.scenarios"))) {
        return result;
      }
      this.get("parent.scenarios").forEach(function (parentScenario) {
        if (parentScenario.get("deletedChildren") && parentScenario.get("deletedChildren").indexOf(_this.get("uniqueId")) >= 0) {
          result.addObject(parentScenario.get("scenario"));
        }
      });
      return result;
    }),
    isBudgetAddedForScenario: Ember.computed("addedForScenarios.[]", function () {
      return this.get("addedForScenarios.length");
    }),
    expenseCategories: _emberData.default.attr("raw"),
    // deprecated
    expenseCategoryOther: _emberData.default.attr("string"),
    numOfBudgetLines: Ember.computed("children.@each.numOfBudgetLines", function () {
      if (this.getWithDefault("children.length", 0) === 0) {
        return 1;
      }
      var sum = 1;
      this.get("children").forEach(function (child) {
        if (!child.get("isNew")) {
          sum += child.get("numOfBudgetLines");
        }
      });
      return sum;
    }),
    company: Ember.computed("parent", function () {
      var result = null,
        _this = this;
      if (this.get("root.isMultiCompany") && this.get("root.children.length")) {
        this.get("root.children").forEach(function (child) {
          if (_const.default.isAncestor(child, _this)) {
            result = child;
          }
        });
      }
      return result;
    }),
    displaySuffix: Ember.computed("numOfEmployees", "budgetType", function () {
      if (this.get("budgetType") !== "employee-multi") {
        return "";
      }
      var num = this.get("numOfEmployees") * 1;
      return num >= 0 && num < 1 ? "" : "×" + this.get("numOfEmployees");
    }),
    budgetAttributesWithScenarios: Ember.computed("budgetService.scenario", "budgetAttributes", "scenarios.length", function () {
      return _budgetUtils.default.getBudgetAttributesWithScenario(this, this.get("budgetService.scenario"));
    }),
    numOfEmployees: Ember.computed("budgetAttributesWithScenarios.amount", "budgetAttributesWithScenarios.customAmounts", "budgetType", "budgetService.scenario", "addedForScenarios.length", "isAddedToCurrentScenario", function () {
      if (this.get("addedForScenarios.length") && (!this.get("budgetService.scenario") || !this.get("isAddedToCurrentScenario"))) {
        return;
      }
      return getNumOfEmployees(this, this.get("budgetAttributesWithScenarios"), this.store, this.get("cache"));
    }),
    employeeStatus: Ember.computed("budgetType", "budgetAttributesWithScenarios.amountType", function () {
      if (this.get("budgetType") === "employee-multi") {
        return this.get("budgetAttributesWithScenarios.amountType");
      } else {
        if (this.get("budgetType") === "employee-transferred" || this.get("budgetType") === "employee-allocated") {
          return 0;
        } else {
          var employeeStartDate = new Date(this.get("budgetAttributesWithScenarios.startDate"));
          var budgetStartDate = new Date(this.get("root.budgetAttributesWithScenarios.startDate"));
          return employeeStartDate.isBefore(budgetStartDate) ? 0 : 1;
        }
      }
    }),
    total: Ember.computed("id", "budgetService.scenario", "cacheChangedTime", "absoluteRoot.budgetAttributesWithScenarios", function () {
      var result = 0,
        startDate = new Date(this.get("absoluteRoot.budgetAttributes.startDate")),
        endDate = new Date(this.get("absoluteRoot.budgetAttributes.endDate")),
        startKey = _const.default.formatDate(startDate),
        endKey = _const.default.formatDate(endDate);
      var lineForecast = _forecast.default.calcForecast(this.get("cache"), this.store, this.get("id"), this, {
        scenario: this.get("budgetService.scenario"),
        type: "cf",
        currency: this.get("absoluteRoot.budgetAttributesWithScenarios.currency"),
        startDate: startDate,
        endDate: endDate
      });
      Object.keys(lineForecast.items).forEach(function (key) {
        if (key >= startKey && key <= endKey) {
          result += lineForecast.items[key] || 0;
        }
      });
      return result;
    }),
    location: Ember.computed("parent.location", function () {
      if (!this.get("parent")) {
        return Ember.A([]);
      }
      var result = Ember.A([]);
      result.pushObjects(this.get("parent.location"));
      result.pushObject(this);
      return result;
    }),
    path: Ember.computed("location.length", function () {
      var names = (this.get("location") || Ember.A([])).mapBy("name");
      names.pop();
      return "".concat(names.join(" > ")).concat(names.length ? " > " : "");
    }),
    fullPathWithoutAccounts: Ember.computed("path", "name", function () {
      return "".concat(this.get("path")).concat(this.get("name"));
    }),
    budgetFullPath: Ember.computed("fullPathWithoutAccounts", "accountIds", "budgetService.accountsInInput", function () {
      if (this.get("budgetService.accountsInInput") && this.get("accountIds")) {
        return this.get("fullPathWithoutAccounts") + " (" + this.get("accountIds").replace(/,/g, " | ") + ")";
      }
      return this.get("fullPathWithoutAccounts");
    }),
    enableDepartment: Ember.computed("budgetModule", function () {
      var module = this.get("budgetModule");
      var displayed = true;
      if (module) {
        var showConditions = module.get("show.department");
        if (showConditions) {
          displayed = false;
          for (var cond in showConditions) {
            if (showConditions[cond].constructor.name === "Array" && showConditions[cond].indexOf(module.get(cond)) >= 0 || module.get(cond) === showConditions[cond]) {
              displayed = true;
            }
          }
        }
      }
      return displayed;
    }),
    getCompanyName: function getCompanyName(budget) {
      if (!budget || budget.get("isRoot") || !budget.get("parent")) {
        return "";
      }
      if (budget.get("budgetType") === "group-company") {
        return budget.get("name");
      }
      return this.getCompanyName(budget.get("parent"));
    },
    companyName: Ember.computed("root.budgetType", "root.name", function () {
      /// keep this for backward compatibility
      if (!this.get("root.oldBudgetType") || this.get("root.oldBudgetType") && this.get("root.oldBudgetType") === "group-company") {
        return this.get("root.name");
      } else if (this.get("root.oldBudgetType") === "group-company-with-sub") {
        return this.getCompanyName(this);
      } else {
        return null;
      }
    }),
    isOnDashboard: Ember.computed("budgetAttributes.dashboardLines.length", "budgetAttributes.dashboardCharts.length", function () {
      var modelCharts = this.get("budgetAttributes.dashboardCharts"),
        dashboardList = this.get("root.dashboardList"),
        dashboardCharts = this.get("root.dashboardOrder") || Ember.A([]);
      modelCharts = _const.default.getModelDashboardCharts(this.get("id"), this.get("uniqueId"), modelCharts, dashboardList, dashboardCharts);
      if (modelCharts && modelCharts.length) {
        modelCharts = modelCharts.filter(function (chart) {
          return chart.lines && chart.lines.length;
        });
      }
      return modelCharts && modelCharts.length ? modelCharts : undefined;
    }),
    fullPath: Ember.computed("parent.fullPath", "name", function () {
      if (!this.get("parent") || this.get("parent.isRoot")) {
        return this.get("name");
      } else {
        return this.get("parent.fullPath") + " > " + this.get("name");
      }
    }),
    modelLineLabel: Ember.computed("modelLineLabelType", function () {
      var result = null;
      switch (this.get("modelLineLabelType")) {
        case "reference":
          return intl.t("general.reference_1698234256839");
        case "linked":
          return intl.t("general.linked_1698234256839");
        case "input":
          return intl.t("general.input_1698234256839");
        case "error":
          return intl.t("general.title_1695611598480");
        case "loop":
          return intl.t("general.loop_1698234256839");
      }
      return result;
    }),
    modelLineLabelType: Ember.computed("isModelLine", "isModelGroup", "isNewMissingLine", "calculationError", "loopError", "lineRefsChanged", "parent.budgetAttributes.dashboardCharts.@each.linesLength", "parent.budgetAttributes.dashboardCharts.@each.dashboardIncludeLength", "budgetAttributes.amountType", "budgetService.scenario", function () {
      var result = null;
      if (this.get("isModelLine") && !this.get("isModelGroup")) {
        if (this.get("isNewMissingLine")) {
          return intl.t("general.reference_1698234256838");
        }
        if (this.get("calculationError")) {
          return "error";
        }
        if (this.get("loopError")) {
          return "loop";
        }

        // check if line has references to it from a budget line
        var refs = this.get("cache").findReferencesToId(this.get("uniqueId"), undefined, this.get("budgetService.scenario"));
        if (refs && refs.length && refs.find(function (line) {
          return !Ember.get(line, "parent.isModelGroup");
        })) {
          return "linked";
        }

        // check if line was added to the dashboard
        var dashboardLines = [],
          modelCharts = (this.get("parent.budgetAttributes.dashboardCharts") || []).filter(function (chart) {
            return Ember.get(chart, "dashboardInclude") && Ember.get(chart, "dashboardInclude").findBy("selected");
          });
        if (modelCharts) {
          modelCharts.forEach(function (chart) {
            dashboardLines.pushObjects(Ember.get(chart, "lines") || []);
          });
          if (dashboardLines.includes(this.get("uniqueId"))) {
            return "linked";
          }
        }

        // if the line has no references then it's label should be input
        if (this.get("budgetAttributes.amountType") > 2) {
          return null;
        }
        refs = this.get("cache.dependencyTableWithScenario.lines.".concat(this.get("uniqueId")));
        if (refs && Object.keys(refs).length) {
          return null;
        }
        return "input";
      }
      return result;
    }),
    titleForMap: Ember.computed("name", "parent", "parent.name", function () {
      var result = this.get("name"),
        parentList = _budgetUtils.default.getParentsList(this),
        opexList = false,
        cosList = false,
        multiCompAddition = "",
        isMultiCompany = false;
      if (parentList[0].type === "group-company-with-sub") {
        isMultiCompany = true;
        if (parentList.length > 2) {
          multiCompAddition = parentList[1].title + " > "; // add the company
        }
      }
      if (this.get("parent.isRoot")) {
        return this.get("name");
      }
      opexList = parentList.filter(function (parent) {
        return _budgetUtils.default.isOpexBudgetType(parent);
      });
      cosList = parentList.filter(function (parent) {
        return _budgetUtils.default.isCOSBudgetType(parent);
      });
      if (opexList.length === 0 && cosList.length === 0) {
        return multiCompAddition + (parentList[1] && parentList[1].title ? parentList[1].title + " > " : "") + result;
      }
      var departments = parentList.filter(function (parent) {
        return _budgetUtils.default.isDepartment(parent);
      });
      parentList = parentList.filter(function (parent) {
        return parent.type !== "group-payroll";
      });
      if (departments.length > 0 && departments[departments.length - 1].title !== result) {
        result = departments[departments.length - 1].title + " > " + result;
      } else {
        result = (parentList[parentList.length - 2] && parentList[parentList.length - 2].title ? parentList[parentList.length - 2].title : parentList[0].title || "") + " > " + result;
      }
      if (opexList.length > 0) {
        if (opexList[0].type === "group-expense-op-bygeo") {
          var geosWithoutDep = parentList.filter(function (parent) {
            return parent.type === "group-department-geo";
          });
          if (geosWithoutDep.length > 0) {
            result = geosWithoutDep[geosWithoutDep.length - 1].title + " > " + this.get("name");
          }
          var geosWithDep = parentList.filter(function (parent) {
            return parent.type === "group-expense-op-bygeo-bydep";
          });
          if (geosWithDep.length > 0) {
            if (geosWithDep[geosWithDep.length - 1].id !== this.get("id")) {
              result = geosWithDep[geosWithDep.length - 1].title + " > ";
            } else {
              result = "";
            }
            if (departments.length > 0 && departments[departments.length - 1].title !== this.get("name")) {
              result += departments[departments.length - 1].title + " > ";
            }
            result += this.get("name");
          }
        }
      }
      if (isMultiCompany && parentList.length <= 3) {
        return result;
      }
      return multiCompAddition + result;
    }),
    hasDependencies: Ember.computed("lineRefsChanged", function () {
      return Object.keys(this.get("cache.dependencyTable.lines.".concat(this.get("uniqueId"))) || {}).length || this.get("cache").findReferencesToId(this.get("uniqueId")).length;
    }),
    lineRefsChanged: null,
    departmentCostCenter: Ember.computed("parent.departmentCostCenter", "budgetAttributes.costCenter", function () {
      if (this.get("budgetAttributes.costCenter") && this.get("isDepartment") && !this.get("isGeography")) {
        return this.get("budgetAttributes.costCenter");
      }
      if (!this.get("parent")) {
        return null;
      }
      return this.get("parent.departmentCostCenter");
    }),
    geoCostCenter: Ember.computed("parent.geoCostCenter", "budgetAttributes.costCenter", function () {
      if (this.get("budgetAttributes.costCenter") && this.get("isGeography")) {
        return this.get("budgetAttributes.costCenter");
      }
      if (!this.get("parent")) {
        return null;
      }
      return this.get("parent.geoCostCenter");
    }),
    showOnce: Ember.computed("budgetType", "parent.budgetModule", "parent.wizardTemplate.template", function () {
      var _this8 = this;
      var parentModuleTemplate = this.get("parent.wizardTemplate.template") || this.get("parent.budgetModule.template");
      var childTemplateData = parentModuleTemplate && (parentModuleTemplate.findBy("type", this.get("budgetType")) || parentModuleTemplate.find(function (item) {
        return item && item.children && item.children.findBy("id", _this8.get("budgetType"));
      }));
      return childTemplateData && childTemplateData.showOnce;
    }),
    disablePopulateBudget: Ember.computed("budgetType", "isEmployeeIncludeAllocated", "isModelLine", "isBenefit", function () {
      return this.get("isEmployeeIncludeAllocated") || this.get("budgetType") === "contractor" || this.get("budgetType").indexOf("group") === 0 || this.get("isModelLine") || this.get("isBenefit");
    }),
    views: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  }, FF_CONNECTED_USERS_INDICATION && {
    subscriptions: _emberData.default.attr("raw")
  }));
});