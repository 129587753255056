define("budgeta/templates/components/display-budget-structure-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "C3vb9sl6",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"display-budget-structure-row-content centerElementsV\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"budget\",\"isGroup\"]]],null,3,0],[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"budget-name letter-spacing-2 overflow-ellipsis \",[\"helper\",[\"if\"],[[\"get\",[\"budget\",\"isGroup\"]],\"is-group\"],null]]]],[\"dynamic-attr\",\"title\",[\"concat\",[[\"unknown\",[\"budget\",\"name\"]]]]],[\"flush-element\"],[\"append\",[\"unknown\",[\"budget\",\"name\"]],false],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"name\"],[\"empty\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"action\",\"actionArg\",\"name\"],[\"collapse-expend-icon expend\",\"collapseBudgetTree\",[\"get\",[\"budget\"]],\"arrow_down\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"action\",\"actionArg\",\"name\"],[\"collapse-expend-icon\",\"collapseBudgetTree\",[\"get\",[\"budget\"]],\"arrow_down\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"budget\",\"collapse\"]]],null,2,1]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/display-budget-structure-row.hbs"
    }
  });
});