define("budgeta/components/formula-editable", ["exports", "budgeta/utils/formula", "budgeta/utils/assumption"], function (_exports, _formula, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    attributeBindings: ["contenteditable", "data-gramm", "spellcheck", "data-gramm_editor", "hook:data-hook"],
    "data-gramm": false,
    spellcheck: false,
    "data-gramm_editor": false,
    contenteditable: true,
    processValue: function processValue() {
      if (this.get("value")) {
        return this.setContent();
      }
    },
    valueObserver: Ember.observer("value", function () {
      Ember.run.once(this, "processValue");
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setContent();
    },
    keyDown: function keyDown(event) {
      var newVal = this.$().html();
      var lastVarRE = /<span[^>]*>[^<>]*<\/span>$/;
      if (event.keyCode === 8 && lastVarRE.test(newVal)) {
        newVal = newVal.replace(lastVarRE, "");
        this.$().html(newVal);
        this.set("value", newVal);
        event.preventDefault();
      }
    },
    keyUp: function keyUp( /*event*/
    ) {
      return this.set("value", this.$().html());
    },
    setContent: function setContent() {
      var selection = window.getSelection(),
        selectionNode = selection.anchorNode,
        anchorOffset = selection.anchorOffset,
        range,
        isFormulaSelection,
        isFormulaSpanSelection,
        isFormulaTextSelection,
        selectionNodeIndex;
      if (selectionNode && selectionNode.nextSibling && selectionNode.nextSibling.nodeName === "P") {
        selectionNode = selectionNode.nextSibling;
        anchorOffset = 0;
      }
      if (selectionNode && selectionNode.nodeName === "P") {
        selectionNode = selectionNode.firstChild;
        if (selectionNode && selectionNode.nodeName === "SPAN") {
          selectionNode = selectionNode.firstChild;
        }
        anchorOffset = 0;
      }
      if (selectionNode) {
        isFormulaSpanSelection = _formula.default.isFormulaSpanSection(selectionNode);
        isFormulaTextSelection = _formula.default.isFormulaTextSection(selectionNode);
      }
      if (isFormulaTextSelection || isFormulaSpanSelection) {
        isFormulaSelection = true;
        if (isFormulaSpanSelection) {
          selectionNode = selectionNode.parentNode;
        }
        var formulaContents = Ember.$(".formula-textarea p")[0].childNodes;
        for (var i = 0; i < formulaContents.length; i++) {
          if (formulaContents[i].isSameNode(selectionNode)) {
            selectionNodeIndex = i;
          }
        }
      }
      if (this.$()) {
        this.$().html(this.get("value"));
        if (isFormulaSelection && selectionNodeIndex !== undefined) {
          var newNode = Ember.$(".formula-textarea p")[0].childNodes[selectionNodeIndex];
          if (newNode) {
            if (isFormulaSpanSelection) {
              newNode = newNode.childNodes[0];
            }
            range = document.createRange();
            range.setStart(newNode, anchorOffset);
            range.setEnd(newNode, anchorOffset);
            selection.removeAllRanges();
            selection.addRange(range);
            if (isFormulaTextSelection && newNode && newNode.nodeValue) {
              var result = null;
              var textInOffset = newNode.nodeValue.substr(0, anchorOffset),
                found;
              if (textInOffset) {
                result = textInOffset.toLowerCase();
                found = this.get("assumptionList").filter(function (a) {
                  return Ember.get(a, "allowed") && result.endsWith(Ember.get(a, "realName").toLowerCase());
                });
              }
              if (found && found.length === 1 && this.get("assumptionList.length") === 1) {
                found = found[0];
                var newNodeValue = newNode.nodeValue;
                var firstValue = newNodeValue.substr(0, anchorOffset); // first part

                var secondValue = newNodeValue.substr(anchorOffset);
                var secondNode = document.createTextNode(secondValue);

                // remove assumption name
                var assumptionWrap = _assumption.default.wrapName(Ember.get(found, "name"), Ember.get(found, "type"), Ember.get(found, "uniqueId"), Ember.get(found, "parent.uniqueId"), found);
                var newAssumptionNode = Ember.$(assumptionWrap).insertAfter(newNode);
                secondNode = Ember.$(secondNode).insertAfter(newAssumptionNode);
                newNode.nodeValue = firstValue.substring(0, firstValue.length - Ember.get(found, "name").length);
                range = document.createRange();
                range.setStart(secondNode[0], 0);
                range.setEnd(secondNode[0], 0);
                selection.removeAllRanges();
                selection.addRange(range); // found assumption with that name

                this.set("value", this.$().html());
              }
            }
            return;
          }
        }
        var formulaNode = Ember.$(".formula-textarea p")[0];
        if (!formulaNode) {
          return;
        }
        var nodes = formulaNode.childNodes,
          lastNode = nodes[nodes.length - 1];
        if (lastNode) {
          // move to the end - without selection

          if (lastNode.nodeName === "SPAN") {
            lastNode = lastNode.childNodes[0];
          }
          range = document.createRange();
          range.setStart(lastNode, lastNode.length);
          range.setEnd(lastNode, lastNode.length);
          selection.removeAllRanges();
          selection.addRange(range);
          Ember.$(".formula-textarea p").focus();
        }
      }
    }
  });
});