define("budgeta/components/forecast-budget-scenario-row", ["exports", "budgeta/components/forecast-row-master"], function (_exports, _forecastRowMaster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _forecastRowMaster.default.extend({
    intl: Ember.inject.service(),
    displayCompareValues: Ember.computed("displayValues", function () {
      var displayValues = this.get("displayValues") || [];
      displayValues.forEach(function (value) {
        var diff = (Ember.get(value, "value") || 0) - (Ember.get(value, "value2") || 0);
        Ember.setProperties(value, {
          diff: diff,
          negative: diff < 0,
          positive: diff > 0
        });
      });
      return displayValues;
    }),
    sameUnitTypeTooltip: Ember.computed(function () {
      return this.get("intl").t("sheets.same-unit-type-warning");
    })
  });
});