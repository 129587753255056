define("budgeta/store/actions/fileActions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SET_FORMATTED_FILE = _exports.SET_FILE_NAME = _exports.SET_FILE = _exports.SET_CONFIG_FILE_NAME = _exports.CLEAR_FILE_DEPENDENCIES = void 0;
  var SET_FILE_NAME = _exports.SET_FILE_NAME = "SET_FILE_NAME";
  var SET_FILE = _exports.SET_FILE = "SET_FILE";
  var SET_FORMATTED_FILE = _exports.SET_FORMATTED_FILE = "SET_FORMATTED_FILE";
  var SET_CONFIG_FILE_NAME = _exports.SET_CONFIG_FILE_NAME = "SET_CONFIG_FILE_NAME";
  var CLEAR_FILE_DEPENDENCIES = _exports.CLEAR_FILE_DEPENDENCIES = "CLEAR_FILE_DEPENDENCIES";
  var setFileName = function setFileName(name) {
    return {
      type: SET_FILE_NAME,
      payload: {
        name: name
      }
    };
  };
  var setFile = function setFile(file) {
    return {
      type: SET_FILE,
      payload: {
        file: file
      }
    };
  };
  var setFormattedFile = function setFormattedFile(formattedFile) {
    return {
      type: SET_FORMATTED_FILE,
      payload: {
        formattedFile: formattedFile
      }
    };
  };
  var clearDependencies = function clearDependencies(stepDependencies) {
    return {
      type: CLEAR_FILE_DEPENDENCIES,
      payload: {
        stepDependencies: stepDependencies
      }
    };
  };
  var _default = _exports.default = {
    setFileName: setFileName,
    setFile: setFile,
    setFormattedFile: setFormattedFile,
    clearDependencies: clearDependencies
  };
});