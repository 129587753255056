define("budgeta/templates/admin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Kj1VHixq",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"main-content login-page\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"admin-menu\"],null,[[\"goto\"],[\"goto\"]]],false],[\"text\",\"\\n\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/admin/index.hbs"
    }
  });
});