define("budgeta/components/forecast-row-root", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      actualChanged: function actualChanged(budgetId, key) {
        this.sendAction("actualChanged", budgetId, key);
      }
    }
  });
});