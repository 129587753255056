define("budgeta/components/display-account-ids", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    viewSelections: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    // used in hbs
    classNameBindings: [":display-account-ids", "isInputsDisplay:inputs-display", "sideBarView:sidebar-accounts", "tableView:tableView", "sheets:sheets", "link:link", "showParenthesis:show-parenthesis"],
    attributeBindings: ["title"],
    tagName: "span",
    showParenthesis: Ember.computed("viewSelections.treeViewOption.key", "sideBarView", function () {
      return this.get("viewSelections.treeViewOption.key") === "budget" || !this.get("sideBarView");
    }),
    title: Ember.computed("groupedAccountIdTitle", "disableTitle", function () {
      if (this.get("disableTitle")) {
        return "";
      }
      return this.get("groupedAccountIdTitle");
    }),
    sortedAccounts: Ember.computed("budget.accountIdsList.length", function () {
      return (this.get("budget.accountIdsList") || []).sort();
    }),
    firstAccount: Ember.computed("sortedAccounts.length", function () {
      return this.get("sortedAccounts.firstObject");
    }),
    shortAccount: Ember.computed("firstAccount", function () {
      return this.get("firstAccount.length") < 3;
    }),
    otherAccount: Ember.computed("sortedAccounts.length", function () {
      if (this.get("sortedAccounts.length") < 2) {
        return;
      }
      var account = this.get("sortedAccounts.length") === 2 ? this.get("sortedAccounts.1") : intl.t("general.display-account-ids-1", {
        var1: this.get("sortedAccounts.length") - 1
      });
      return account;
    }),
    groupedAccountIdTitle: Ember.computed("sortedAccounts", function () {
      return (this.get("sortedAccounts") || []).join(" | ");
    })
  });
});