define("budgeta/generic-file/components/dimension-tree/dimension-tree-step", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectWithoutProperties", "react", "react-redux", "@sibp/ui-components", "budgeta/services/ember-service-container", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "lodash/lodash", "budgeta/generic-file/utils/utils"], function (_exports, _objectSpread2, _toConsumableArray2, _slicedToArray2, _objectWithoutProperties2, _react, _reactRedux, _uiComponents, _emberServiceContainer, _stepsActions, _budgetActions, _lodash, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = DimensionTreeStep;
  var _excluded = ["getDataAction"];
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function DimensionTreeStep(_ref) {
    var getDataAction = _ref.getDataAction,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
    var stepName = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var budgetName = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.name;
    });
    var selectedDimensions = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.selectedDimensions;
    });
    var dimensionStructure = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.dimensionHierarchy;
    });
    var formattedFile = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.formattedFile;
    });
    var notAllowedLinesIndexes = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.notAllowedLinesDetails.notAllowedCategory;
    });
    var _React$useState = _react.default.useState([]),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      dimensionOptions = _React$useState2[0],
      setDimensionOptions = _React$useState2[1];
    var _React$useState3 = _react.default.useState(null),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      currentDimension = _React$useState4[0],
      setCurrentDimension = _React$useState4[1];
    var _React$useState5 = _react.default.useState([]),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      currentDimensionValues = _React$useState6[0],
      setCurrentDimensionValues = _React$useState6[1];
    var _React$useState7 = _react.default.useState(dimensionStructure),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      dimensionsHierarchy = _React$useState8[0],
      setDimensionsHierarchy = _React$useState8[1];
    var _React$useState9 = _react.default.useState(!(dimensionStructure && Object.keys(dimensionStructure).length)),
      _React$useState10 = (0, _slicedToArray2.default)(_React$useState9, 2),
      showWalkThrough = _React$useState10[0],
      setShowWalkThrough = _React$useState10[1];
    var _React$useState11 = _react.default.useState(new Set()),
      _React$useState12 = (0, _slicedToArray2.default)(_React$useState11, 2),
      showFilteredNodes = _React$useState12[0],
      setShowFilteredNodes = _React$useState12[1];
    var _React$useState13 = _react.default.useState(""),
      _React$useState14 = (0, _slicedToArray2.default)(_React$useState13, 2),
      inputValue = _React$useState14[0],
      setInputValue = _React$useState14[1];
    var dispatch = _reactRedux.default.useDispatch();
    var options = {
      hideClose: true,
      height: "100%",
      width: "100%",
      className: "dimension-tree-container"
    };
    var inputClassName = "underline width-inherit no-padding no-outline";
    var inputContainerClassName = "highlight-field width-inherit";
    var minimumNumberOfLettersToFitWalkthrough = 7;
    _react.default.useEffect(function () {
      var hierarchiesWithOptions = (0, _utils.getDimensionsHierarchiesWithOptions)(getDataAction, selectedDimensions, dimensionsHierarchy, formattedFile, _lodash.default.flatten(Object.values(notAllowedLinesIndexes)));
      setDimensionsHierarchy(hierarchiesWithOptions.newDimensionHierarchy);
      setDimensionOptions(hierarchiesWithOptions.options);
      setCurrentDimension(hierarchiesWithOptions.options[0]);
    }, [stepName]);
    _react.default.useEffect(function () {
      if (currentDimension && currentDimension.value && dimensionsHierarchy[currentDimension.value]) {
        var dimensionsData = (0, _toConsumableArray2.default)(dimensionsHierarchy[currentDimension.value]);
        setCurrentDimensionValues(dimensionsData);
      }
    }, [currentDimension]);
    var searchInputChange = function searchInputChange(event) {
      setInputValue(event.currentTarget.value);
      var addParentsRecursively = function addParentsRecursively(dimensionsData, nodes, parentId) {
        var parentNode = dimensionsData[parentId];
        nodes.add(parentNode.label);
        parentNode.parent && addParentsRecursively(dimensionsData, nodes, parentNode.parent);
      };
      var inputValue = event.currentTarget.value ? event.currentTarget.value.toLowerCase() : "";
      var dimensionsData = (0, _toConsumableArray2.default)(dimensionsHierarchy[currentDimension.value]);
      var dimensionDataById = dimensionsData.reduce(function (nodes, node) {
        nodes[node.id] = node;
        return nodes;
      }, {});
      var filteredDimensionsData = dimensionsData.reduce(function (nodes, node) {
        if (node.label.trim().toLowerCase().includes(inputValue)) {
          nodes.add(node.label);
          node.parent && addParentsRecursively(dimensionDataById, nodes, node.parent);
        }
        return nodes;
      }, new Set());
      setShowFilteredNodes(filteredDimensionsData);
    };
    var filterOptions = function filterOptions(dataSource, id) {
      var getSiblings = function getSiblings(id) {
        if (!id) return [];
        var currNode = dataSource.find(function (node) {
          return node.id === id;
        });
        var siblings = currNode.parent ? dataSource.filter(function (node) {
          return node.parent === currNode.parent && node.id !== id;
        }) : dataSource.filter(function (node) {
          return !node.parent && id !== node.id;
        });
        return currNode.parent ? siblings.concat(getSiblings(currNode.parent)) : siblings;
      };
      var currNode = dataSource.find(function (node) {
        return node.id === id;
      });
      var children = dataSource.filter(function (node) {
        return node.parent === id;
      }).map(function (child) {
        return child.id;
      });
      var siblings = currNode.parent ? dataSource.filter(function (node) {
        return node.parent === currNode.parent && node.id !== id;
      }).map(function (child) {
        return child.id;
      }) : dataSource.filter(function (node) {
        return !node.parent && id !== node.id;
      }).map(function (child) {
        return child.id;
      });
      return dataSource.filter(function (data) {
        return siblings.includes(data.id) || children.includes(data.id);
      }).concat(getSiblings(currNode.parent));
    };
    var onTreeChangeHandler = function onTreeChangeHandler(tree) {
      var newHierarchy = {};
      newHierarchy[currentDimension.value] = tree;
      setDimensionsHierarchy((0, _objectSpread2.default)((0, _objectSpread2.default)({}, dimensionsHierarchy), newHierarchy));
      searchInputChange({
        currentTarget: ""
      });
    };
    var isHierarchyMapped = function isHierarchyMapped() {
      //user mapped at least one hierarchy
      for (var _i = 0, _Object$keys = Object.keys(dimensionsHierarchy); _i < _Object$keys.length; _i++) {
        var dimension = _Object$keys[_i];
        if (dimensionsHierarchy[dimension].some(function (seg) {
          return seg.level > 0;
        })) {
          return true;
        }
      }
      return false;
    };
    var customStyles = {
      option: function option(provided) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, provided), {}, {
          color: "#334356"
        });
      }
    };
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back border big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        if (!_lodash.default.isEqual(dimensionsHierarchy, dimensionStructure)) {
          dispatch(_budgetActions.default.setDimensionHierarchy(dimensionsHierarchy));
          rest.clearDependencies();
        }
        dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_HIERARCHY_STEP);
        if (!_lodash.default.isEqual(dimensionsHierarchy, dimensionStructure)) {
          isHierarchyMapped() && trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.HIERARCHY_CREATED);
          dispatch(_budgetActions.default.setDimensionHierarchy(dimensionsHierarchy));
          rest.clearDependencies();
        }
        dispatch(_stepsActions.default.nextStep());
      },
      disabled: false
    }));
    var body = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("section", {
      className: "hierarchy-nav-container"
    }, _react.default.createElement("label", {
      className: "header-name large-text"
    }, "".concat(budgetName)), dimensionOptions.length > 1 ? _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("span", {
      className: "arrow-hierarchy"
    }, ">", showWalkThrough ? _react.default.createElement(_uiComponents.default.COMPONENTS.PopupWithLabel, {
      dataHook: "walk-through-tooltip",
      className: "walk-through-tooltip ".concat(budgetName.length < minimumNumberOfLettersToFitWalkthrough ? "popup-min-left" : ""),
      defaultPopupIsOpen: true,
      closeOnLeave: false,
      popupContent: _react.default.createElement("div", {
        className: "popup-content"
      }, _react.default.createElement("p", null, intl.t("generic-file.step-dimensions-hierarchies.walkthrough")), _react.default.createElement("button", {
        onClick: function onClick() {
          return setShowWalkThrough(false);
        }
      }, intl.t("generic-file.step-dimensions-hierarchies.got-it")))
    }) : null), _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
      hideSelectedOptions: false,
      value: currentDimension,
      onChange: function onChange(selectedDimension) {
        return setCurrentDimension(selectedDimension);
      },
      options: dimensionOptions,
      classNames: "dimension-select",
      dataHook: "dimension-select",
      isClearable: false,
      isSearchable: false,
      styles: customStyles
    }), " ") : null), _react.default.createElement("section", {
      className: "hierarchy-tree-container"
    }, _react.default.createElement("section", {
      className: "filter-section"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
      inputClassName: inputClassName,
      className: inputContainerClassName,
      dataHook: "search-input",
      placeholder: intl.t("placeholders.searching"),
      value: inputValue,
      onChange: searchInputChange
    }), inputValue && inputValue.length ? _react.default.createElement(_uiComponents.default.IMAGES.CloseSvg, {
      onClick: function onClick() {
        return searchInputChange({
          currentTarget: ""
        });
      }
    }) : null), currentDimension && currentDimensionValues && currentDimensionValues.length ? _react.default.createElement(_uiComponents.default.COMPONENTS.TreeStructure, {
      NodeComponent: _uiComponents.default.COMPONENTS.TreeNodeCombo,
      filterOptions: filterOptions,
      showFilteredNodes: showFilteredNodes,
      dataSource: currentDimensionValues,
      onChange: onTreeChangeHandler,
      showExpandAll: true,
      expandAllText: intl.t("uic.expand_all"),
      collapseAllText: intl.t("uic.collapse_all"),
      selectAllText: intl.t("uic.selectAllText"),
      clearAllText: intl.t("uic.clearAllText"),
      requiredMessage: intl.t("uic.required_field")
    }) : null));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        footer: footer,
        body: body
      },
      options: options
    });
  }
});