define("budgeta/templates/components/content-budget-tree-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "d+pbSlzN",
    "block": "{\"statements\":[[\"open-element\",\"i\",[]],[\"static-attr\",\"class\",\"fa leaf\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"span\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"budget-name \",[\"helper\",[\"if\"],[[\"get\",[\"budget\",\"isScenarioRemoved\"]],\"scenarioRemovedWrapper\"],null]]]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"span\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"budget-name-text \",[\"helper\",[\"if\"],[[\"get\",[\"budget\",\"isScenarioRemoved\"]],\"strike scenarioRemoved\"],null]]]],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"showBudgetTitle\"]]],null,2],[\"text\",\"  \"],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"unless\"],[[\"get\",[\"hideType\"]]],null,0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"type\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"budget\",\"displayBudgetType\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"          \"],[\"append\",[\"unknown\",[\"budgetService\",\"displayPartialSource\"]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"budget-name-text-display\"],[\"flush-element\"],[\"text\",\"\\n        \"],[\"append\",[\"helper\",[\"highlight\"],[[\"get\",[\"budget\",\"title\"]],[\"get\",[\"treeManager\",\"searchValue\"]]],null],false],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"budget\",\"selectedRoot\"]]],null,1],[\"text\",\"        \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"budget-name-suffix\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"budget\",\"displaySuffix\"]],false],[\"close-element\"],[\"text\",\"\\n      \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/content-budget-tree-simple.hbs"
    }
  });
});