define("budgeta/components/integration-wizard-department-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    showOptions: Ember.computed("trackingCategoriesOptions.length", "model.hasDepartmentCategory", function () {
      return this.get("model.hasDepartmentCategory") && this.get("trackingCategoriesOptions.length") > 1;
    })
  });
});