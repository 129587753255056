define("budgeta/templates/components/employees-table-num-of-emp-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DJ3dYJRK",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"isEditable\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"num-of-emp\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"numOfEmployees\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"currency-input\"],null,[[\"currency\",\"isFixed\",\"value\",\"openFormulaEditor\",\"assumptions\",\"budget\",\"hideFormulaButton\",\"readonly\",\"showFormulaOnHover\",\"valueChangedCB\"],[null,\"#\",[\"get\",[\"numOfEmployees\"]],\"openFormulaEditor\",[\"get\",[\"assumptions\"]],[\"get\",[\"line\"]],true,false,[\"get\",[\"isFormula\"]],\"attributeChanged\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/employees-table-num-of-emp-cell.hbs"
    }
  });
});