define("budgeta/templates/components/inline-edit-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xD3DkZHQ",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"readonly\"]]],null,3,2]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"unknown\",[\"displayValue\"]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"cssClass\",\"searchEnabled\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\",\"value\"],[\"wrap\",false,[\"get\",[\"content\"]],[\"get\",[\"optionValuePath\"]],[\"get\",[\"optionLabelPath\"]],[\"get\",[\"placeholder\"]],[\"get\",[\"value\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"editing\"]]],null,1,0]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"unknown\",[\"displayValue\"]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/inline-edit-select.hbs"
    }
  });
});