define("budgeta/services/actuals-mapping", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/utils/dimensions", "budgeta/utils/is-account-ids-match", "budgeta/utils/intacct", "budgeta/utils/forecast", "budgeta/utils/accounts"], function (_exports, _toConsumableArray2, _slicedToArray2, _createForOfIteratorHelper2, _const, _budgetUtils, _dimensions, _isAccountIdsMatch, _intacct, _forecast, _accounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This function is used in order to reduce the code from account filtering
  var someAccountsHaveMappingComputed = function someAccountsHaveMappingComputed(accountType) {
    return Ember.computed("recalcAccount", function () {
      // checking if there is at least one account that has a mapping
      return (this.get("accounts") || []).find(function (account) {
        return account.get("type") === accountType && account.get("budgetLines.length") > 0;
      });
    });
  };
  var noMappingLinesComputed = function noMappingLinesComputed(metrics) {
    return Ember.computed("recalcAccount", function () {
      // Upon change in the mappings of the budget lines, we would like to get the empty budget lines
      var options = Ember.Object.create({
        store: this.get("store"),
        selectedPeriod: this.get("model.exportPeriod"),
        forceDetails: true,
        metrics: metrics
      });
      var rootModel = _forecast.default.getForecastDataModel(options, this.get("cache"), "pnl", this.get("budgetService.selectedRoot.absoluteRoot.id"), options);
      return _intacct.default.getLinesWithMissingAccounts(this.get("accounts"), rootModel.root, this, {
        ignoreIntacct: true,
        metrics: metrics
      });
    });
  };
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    budgetRoot: Ember.computed("budgetService.selectedRoot", function () {
      this.set("accountsMap", {});
      return this.get("budgetService.selectedRoot") || this.get("store").peekAll("budget").findBy("isRoot");
    }),
    accounts: Ember.computed.alias("budgetService.selectedRoot.absoluteRoot.actualAccounts"),
    forceUseCache: false,
    accountsMap: {},
    recalcAccount: false,
    // A notification hookup to observe if a any account was changed
    transactionMappingCache: {},
    transactionMappingForLineCache: {},
    transactionAccountDimCache: {},
    // Needs to be Removed due to BUD-15959
    faultyNotificationCookie: Ember.computed("budgetService.selectedRoot.absoluteRoot.id", function () {
      var budgetId = this.get("budgetService.selectedRoot.absoluteRoot.id");
      var cookieList = Ember.$.cookie("faultyNotification");
      return cookieList && cookieList.includes(budgetId);
    }),
    transactionFaultySet: new Set(),
    accountsFaultySet: new Set(),
    totalFaultyTransactions: 0,
    // end of comment for BUD-15959
    someAccountsHaveMapping: someAccountsHaveMappingComputed("pnl"),
    someStatAccountsHaveMapping: someAccountsHaveMappingComputed("stat"),
    noMappingBudgetLines: noMappingLinesComputed(),
    noMappingModelLines: noMappingLinesComputed(true),
    initCache: function initCache() {
      this.setProperties({
        accountsMap: {},
        transactionMappingCache: {},
        transactionMappingForLineCache: {},
        transactionAccountDimCache: {}
      });
    },
    findLineByAccountId: function findLineByAccountId(accountId, filter, dimensionsFilter, idsFilterSet) {
      var _this = this;
      var match,
        account = this.getAccount(accountId);
      if (account && account.get("isClone") && account.get("copyFromAccount")) {
        account = this.getAccount(account.get("copyFromAccount"));
      }
      if (account) {
        var lines = account.get("mappingType") === "none" ? [] : account.get("budgetLines").map(function (uniqueId) {
          return _budgetUtils.default.findLineByUniqueId(_this.get("store"), _this.get("cache"), uniqueId.split(":")[0]);
        });
        if (lines.length === 1 && (!idsFilterSet || lines[0] && idsFilterSet.has(lines[0].get("id")))) {
          return lines[0];
        }
        if (idsFilterSet) {
          lines = lines.filter(function (line) {
            return line && idsFilterSet.has(line.get("id"));
          });
        }
        if (filter) {
          var props = Object.keys(filter);
          match = lines.find(function (line) {
            if (!line) {
              return false;
            }
            var isMatch = true;
            for (var _i = 0, _props = props; _i < _props.length; _i++) {
              var prop = _props[_i];
              var lineDimValue = line.get(prop);
              if (!lineDimValue || lineDimValue !== filter[prop] && (!Ember.isArray(lineDimValue) || !lineDimValue.mapBy("name").includes(filter[prop]))) {
                isMatch = false;
                break;
              }
            }
            return isMatch;
          });
        }
        if (dimensionsFilter && dimensionsFilter.length) {
          match = lines.find(function (line) {
            if (!line) {
              return false;
            }
            var isMatch = true;
            var _iterator = (0, _createForOfIteratorHelper2.default)(dimensionsFilter),
              _step;
            try {
              var _loop = function _loop() {
                var dimFilter = _step.value;
                var dimension = (_this.get("budgetRoot.dimensions") || Ember.A([])).findBy("uniqueId", dimFilter.dimId);
                if (dimension) {
                  _dimensions.default.setPathForValues(dimension.get("values"));
                  var lineDimensionData = (line.get("forecastAttributes.dimensions") || Ember.A([])).findBy("dimension", dimension.get("uniqueId"));
                  var lineValues = (Ember.get(lineDimensionData, "values") || []).map(function (v) {
                    return dimension.get("values").findBy("uniqueId", Ember.get(v, "value"));
                  });
                  var splitValue = (dimFilter.value || "").split(/[\.\,\/\>]/) || [];
                  splitValue = splitValue.map(_dimensions.default.normalizeDimensionValue);
                  var linePaths = lineValues.filter(function (v) {
                    return v;
                  }).map(function (v) {
                    return (Ember.get(v, "path") || []).join(">");
                  });
                  if ((dimFilter.value || linePaths.length) && !linePaths.includes(dimFilter.value || "") && !linePaths.includes(splitValue.join(">"))) {
                    isMatch = false;
                    return 1; // break
                  }
                }
              };
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                if (_loop()) break;
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            return isMatch;
          });
        }
        return match || lines[0];
      }
      return null;
    },
    fillTransactionMappingCache: function fillTransactionMappingCache(options) {
      var _this2 = this;
      this.get("store").peekAll("transaction").forEach(function (trx) {
        var mapping = _this2.getTransactionMapping(trx, options);
        if (mapping) {
          if (!_this2.get("transactionMappingForLineCache.".concat(mapping))) {
            _this2.set("transactionMappingForLineCache.".concat(mapping), []);
          }
          _this2.get("transactionMappingForLineCache.".concat(mapping)).push(trx);
        }
      });
      var mappedBudgetLines = new Set(Object.keys(this.get("transactionMappingForLineCache")));
      this.get("budgetService.treeManager.budgetsList").forEach(function (line) {
        var mapping = line.budget.get("id");
        if (!mappedBudgetLines.has(mapping)) {
          _this2.set("transactionMappingForLineCache.".concat(mapping), []);
        }
      });

      // Needs to be Removed due to BUD-15959
      if (!this.get("faultyNotificationCookie")) {
        var budgetId = this.get("budgetService.selectedRoot.absoluteRoot.id");
        var _map = ["transactionFaultySet", "accountsFaultySet"].map(function (key) {
            var currentSet = _this2.get(key);
            var setCount = currentSet.size;
            currentSet.clear(); // clearing the set to clear user's memory
            return setCount;
          }),
          _map2 = (0, _slicedToArray2.default)(_map, 2),
          trxFaultCount = _map2[0],
          accountFaultCount = _map2[1];
        if (trxFaultCount || accountFaultCount) {
          var errorLine = "Budget Line: ".concat(this.get("budgetService.selectedRoot.absoluteRoot.name"), " \n        Budget Id: ").concat(budgetId, "\n        Estimated Trx Sum: ").concat(this.get("totalFaultyTransactions") || 0, "\n        Estimated Trx Count: ").concat(trxFaultCount, "\n        Estimated Account Count: ").concat(accountFaultCount);
          window.Raven.captureException(new Error("Faulty Mapping Error:\n ".concat(errorLine)));
        }
        var cookieList = "".concat(Ember.$.cookie("faultyNotification") || "", "_").concat(budgetId);
        Ember.$.cookie("faultyNotification", cookieList, {
          expires: 7,
          path: "/"
        }); // setting in the cookie that the notifciation was sent (expires after 7 days)
      }
      // end of comment BUD-15959
    },
    getTransactionsForLine: function getTransactionsForLine(line) {
      var _this3 = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var useCache = options.useCache;
      options.version = line && line.id && line.id.split("|")[1];
      // if using cache and it's empty then fill it
      if (useCache && Object.keys(this.get("transactionMappingForLineCache")).length === 0 && this.get("store").peekAll("transaction").get("length") > 0) {
        var versionSuffix = options.version ? "|" + options.version : "";
        var root = this.get("budgetService.selectedRoot.absoluteRoot") || this.get("store").peekAll("budget").findBy("isRoot");
        if (versionSuffix) {
          root = this.get("store").peekRecord("budget", root.id + versionSuffix);
        }
        _dimensions.default.setPathForAllDimensionValuesDebounced(root);
        this.fillTransactionMappingCache(options);
      }
      if (useCache && this.get("transactionMappingForLineCache").hasOwnProperty(line.id)) {
        return this.get("transactionMappingForLineCache.".concat(line.id)) || [];
      }
      var result = this.get("store").peekAll("transaction").filter(function (trx) {
        return _this3.getTransactionMapping(trx, options) === line.id;
      });
      if (useCache) {
        this.set("transactionMappingForLineCache.".concat(line.id), result);
      }
      return result;
    },
    getAccount: function getAccount(accountId, options) {
      var _ref = options || {},
        type = _ref.type,
        getFinal = _ref.getFinal,
        root = _ref.root;
      var accounts = root ? Ember.get(root, "actualAccounts") : this.get("accounts");
      if (!accounts) {
        return null;
      }
      if (!root && this.get("accountsMap")[accountId] !== undefined && (!getFinal || this.get("accountsMap")[accountId] === null || this.get("accountsMap")[accountId].get("mappingType") !== "clone")) {
        var account = this.get("accountsMap")[accountId];

        // Checking if the account matches the type
        if (!type || account.get("type") === type) {
          return account;
        }
      }
      var fastMatch = accounts.findBy("account", accountId);
      var result = fastMatch && (!type || fastMatch.get("type") === type) ? fastMatch : accounts.find(function (a) {
        return (!type || a.get("type") === type) && (0, _isAccountIdsMatch.default)(a.get("account"), accountId);
      });
      if (!root) {
        this.get("accountsMap")[accountId] = result;
      }
      return getFinal ? this.getFinalAccount(result) : result;
    },
    findLineMatchInVersion: function findLineMatchInVersion(uniqueId, rootId) {
      var deepSearch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var level = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
      if (level > 20) {
        return null;
      }
      // deepSearch - if we wish for the function to match the parent (by default true)
      var result = _budgetUtils.default.findLineByUniqueId(this.get("store"), this.get("cache"), uniqueId, rootId);
      var originalBudgetTree = this.get("budgetRoot.originalBudgetTree");
      if (!result && originalBudgetTree) {
        var lineItem;
        if (this.get("cache")) {
          lineItem = Ember.get(this.get("cache"), "originalRootUniqueIdMap.".concat(uniqueId));
        }
        if (!lineItem) {
          lineItem = originalBudgetTree.findBy("uniqueId", uniqueId);
          if (this.get("cache")) {
            Ember.set(this.get("cache"), "originalRootUniqueIdMap.".concat(uniqueId), lineItem || null);
          }
        }
        if (deepSearch && lineItem && Ember.get(lineItem, "parentUniqueId")) {
          result = this.findLineMatchInVersion(Ember.get(lineItem, "parentUniqueId"), rootId, deepSearch, level + 1);
        }
      }
      return result;
    },
    findLineMatchInVersionById: function findLineMatchInVersionById(id) {
      var deepSearch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      // deepSearch - if we wish for the function to match the parent (by default true)
      var realId = id,
        version,
        idParts = id && id.split("|");
      if (idParts && idParts[1]) {
        realId = idParts[0];
        version = idParts[1];
      }
      var result = this.get("store").peekRecord("budget", id);
      var originalBudgetTree = this.get("budgetRoot.originalBudgetTree");
      if (!result && originalBudgetTree) {
        var lineItem = originalBudgetTree.findBy("id", realId);
        result = lineItem ? this.findLineMatchInVersion(lineItem.uniqueId, this.get("budgetRoot.id") + "|" + version, deepSearch) : null;
        if (deepSearch && !result && lineItem && Ember.get(lineItem, "parentId")) {
          result = this.findLineMatchInVersionById(Ember.get(lineItem, "parentId") + (version ? "|".concat(version) : ""));
        }
      }
      return result;
    },
    getSpecialAccountCategory: function getSpecialAccountCategory(budgetType, accountId) {
      var result = null,
        account = accountId && this.getAccount(accountId),
        catList = budgetType === "group-payroll" ? _const.default.DEFAULT_SALARY_CATEGORIES_ARRAY : _const.default.DEFAULT_REVENUE_TYPES_MAPPING.mapBy("name");
      account = this.getFinalAccount(account);
      if (account) {
        result = catList[0];
        var lineId = account.get("budgetLines").find(function (l) {
          return l.indexOf(":") >= 0;
        });
        if (lineId) {
          var id = lineId.split(":");
          if (id.length === 2) {
            //DEFAULT_SALARY_CATEGORIES
            result = catList[id[1] * 1] || result;
          } else {
            // advanced benefit
            var categoryDim = this.get("budgetService.dimensions").findBy("isCategory", true);
            var benefitCategory = (categoryDim.get("values") || Ember.A([])).findBy("benefitRef", id[2]);
            result = benefitCategory && benefitCategory.name || result;
          }
        }
      }
      return result;
    },
    /**
     *
     * @param {String} lineId
     * @returns line category values [uniqueId \ index of category const]
     */
    parseLineCategoryValue: function parseLineCategoryValue(lineId) {
      if (lineId) {
        var line = _budgetUtils.default.findLineByUniqueId(this.get("store"), this.get("cache"), lineId.split(":")[0]);
        if (line) {
          var categoryDim = this.get("budgetService.dimensions").findBy("isCategory");
          if (categoryDim) {
            var id = lineId.split(":");
            if (id.length == 2) {
              var lineDimValues = (line.get("inheritedAttributes.dimensions") || Ember.A([])).findBy("dimension", categoryDim.get("uniqueId"));
              lineDimValues = lineDimValues && lineDimValues.values;
              // Will return uniqueId if exist, otherwise will return index of category const (salary, taxes...).
              if (lineDimValues) {
                var result = lineDimValues[id[1] * 1] && (Ember.isArray(lineDimValues[id[1] * 1]) || lineDimValues[id[1] * 1].value) ? lineDimValues[id[1] * 1] : {
                  value: id[1]
                };
                return result ? result.value : lineDimValues[0] && lineDimValues[0].value;
              }
            } else if (id.length === 3) {
              // handle advanced benefits + mapping changes
              var benefitCategory = (categoryDim.get("values") || Ember.A([])).findBy("benefitRef", id[2]);
              var benefitsCategoryGroupLevel = line.get("budgetAttributes.benefitsCategory");
              var currentBenefit;
              if (benefitsCategoryGroupLevel && benefitsCategoryGroupLevel.length) {
                if (benefitCategory) {
                  currentBenefit = benefitsCategoryGroupLevel.findBy("label", benefitCategory.name);
                } else {
                  var benefitLine = _budgetUtils.default.findLineByUniqueId(this.get("store"), this.get("cache"), id[2]);
                  if (benefitLine) {
                    currentBenefit = benefitsCategoryGroupLevel.findBy("label", benefitLine.get("name"));
                  }
                }
                if (currentBenefit && !currentBenefit.isFromSettings) {
                  return currentBenefit.value;
                }
              }
              if (benefitCategory) {
                return benefitCategory.uniqueId;
              }
            } else {
              var categoryValues = [];
              if (!line.get("isEmployeeIncludeAllocated")) {
                var dimensions = line.get("budgetAttributes.dimensions");
                if (dimensions) {
                  var lineCategoryDim = dimensions.findBy("dimension", categoryDim.get("uniqueId"));
                  if (lineCategoryDim && _dimensions.default.dimensionHasValueNotInheritance(lineCategoryDim)) {
                    categoryValues = lineCategoryDim.values.mapBy("value");
                  }
                }
              }
              categoryValues = categoryValues.concat(line.get("budgetAttributes.benefitsCategory").map(function (benefit) {
                if (benefit.value && !benefit.isFromSettings) {
                  return benefit.value;
                }
                var benefitLine = categoryDim.get("values").find(function (categoryValue) {
                  return categoryValue.name === benefit.label && categoryValue.benefitRef;
                });
                return benefitLine.uniqueId;
              }));
              return categoryValues && categoryValues.length ? categoryValues : null;
            }
          }
        }
      }
    },
    getAllAccountCategoryValues: function getAllAccountCategoryValues(accountIds, ancestorsUniqueIds) {
      var _this4 = this;
      var values = [];
      if (!Array.isArray(accountIds)) {
        accountIds = [accountIds];
      }
      accountIds.forEach(function (accountId) {
        var account = accountId && _this4.getAccount(accountId);
        account = _this4.getFinalAccount(account);
        if (account) {
          values.addObjects((0, _toConsumableArray2.default)(account.get("budgetLines").reduce(function (elements, element) {
            if (ancestorsUniqueIds && !ancestorsUniqueIds.has(element === null || element === void 0 ? void 0 : element.split(":")[0])) {
              return elements;
            }
            var value = _this4.parseLineCategoryValue(element);
            if (Ember.isArray(value)) {
              var _values = value.filter(function (v) {
                return v;
              });
              _values.forEach(elements.add, elements);
            } else {
              value && elements.add(value);
            }
            return elements;
          }, new Set())));
        }
      });
      return values;
    },
    // Needs to be Removed due to BUD-15959
    addToSetsIfFaulty: function addToSetsIfFaulty(trx, account) {
      if (!this.get("faultyNotificationCookie")) {
        var total = this.get("totalFaultyTransactions");
        this.get("transactionFaultySet").add(Ember.get(trx, "id"));
        this.get("accountsFaultySet").add(account);
        this.set("totalFaultyTransactions", total + (Ember.get(trx, "amount") || 0));
      }
    },
    // end of comment
    getSpecialAccountCategoryValue: function getSpecialAccountCategoryValue(budgetType, accountId) {
      var result = null,
        account = accountId && this.getAccount(accountId);
      account = this.getFinalAccount(account);
      if (account) {
        var lineId = account.get("budgetLines").find(function (l) {
          return l.indexOf(":") >= 0;
        });
        if (lineId) {
          result = this.parseLineCategoryValue(lineId);
        }
      }
      return result;
    },
    getTransactionMapping: function getTransactionMapping(trx) {
      var _this5 = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var result,
        useCache = options.useCache,
        version = options.version,
        versionSuffix = version ? "|" + version : "",
        accountDimCacheKey,
        cacheKey = trx.id + versionSuffix;
      useCache = useCache || this.get("forceUseCache");
      if (useCache && this.get("transactionMappingCache").hasOwnProperty(cacheKey)) {
        return this.get("transactionMappingCache.".concat(cacheKey));
      }
      var root = this.get("budgetService.selectedRoot.absoluteRoot") || this.get("store").peekAll("budget").findBy("isRoot");
      if (version) {
        root = this.get("store").peekRecord("budget", root.id + versionSuffix);
      }
      if (Ember.get(trx, "statement") === "bs") {
        return;
      }
      // if trx has a saved mapping, use it
      if (Ember.get(trx, "savedMapping") && Ember.get(trx, "budgetLine")) {
        result = Ember.get(trx, "budgetLineId") + versionSuffix;
        if (version && !this.get("store").peekRecord("budget", result)) {
          // line doesn't exist in version, try to find the closest match
          var matchResult = this.findLineMatchInVersionById(result, false);
          result = matchResult ? matchResult.id : result;
        }
        if (useCache) {
          this.set("transactionMappingCache.".concat(cacheKey), result);
        }
        return result;
      }
      if (Ember.get(trx, "accountId") && Ember.get(trx, "accountId").trim()) {
        var account = this.getAccount(Ember.get(trx, "accountId"), {
          type: Ember.get(trx, "statement") === "bs" ? "bs" : undefined
        });
        account = account && this.getFinalAccount(account);
        if (account) {
          if (account.get("mappingType") === "none") {
            if (useCache) {
              this.set("transactionMappingCache.".concat(cacheKey), null);
            }
            return null;
          }
          if (account.get("type") === "bs") {
            result = root.id;
            if (useCache) {
              this.set("transactionMappingCache.".concat(cacheKey), result);
            }
            return result;
          }
          if (account.get("mappingType") === "clone") {
            var originalAccount = account.get("copyFromAccount");
            account = this.get("accounts").findBy("account", account.get("copyFromAccount"));
            if (!account) {
              Ember.Logger.warn("Could not find account to clone", originalAccount);
              if (useCache) {
                this.set("transactionMappingCache.".concat(cacheKey), null);
              }
              return null;
            }
          }

          // if we already have a match in the cache for this account and the same dimension values
          // retrun the cached result
          if (useCache) {
            var trxDimensions = Ember.get(trx, "dimensions") || {};
            accountDimCacheKey = "".concat(account.get("account")).concat(JSON.stringify(trxDimensions)).replace(/\./g, "_");
            result = this.get("transactionAccountDimCache.".concat(accountDimCacheKey));
            if (result && Object.keys(trxDimensions).length) {
              this.set("transactionMappingCache.".concat(cacheKey), result);
              this.set("transactionAccountDimCache.".concat(accountDimCacheKey), result);
              return result;
            }
          }
          var budgetLines = (account.get("budgetLines") || []).map(function (uniqueId) {
            var line = _this5.findLineMatchInVersion(uniqueId.split(":")[0], root.id);
            return line || root;
          });
          // TODO - special handling for payroll categories
          if (account.get("mappingType") === "single") {
            result = budgetLines[0] && budgetLines[0].id;
          } else if (account.get("mappingType") === "multi") {
            var _matchingLine$id, _matchingLine;
            if (!useCache) {
              _dimensions.default.setPathForAllDimensionValuesDebounced(root);
            }
            // find the right budget line accoring to the account dimensions
            // first we try to get an exact match with only one value
            var matchingLine = budgetLines.find(function (line) {
              if (!line) {
                return false;
              }
              var matching = true;
              (account.get("dimensions") || []).forEach(function (dimId) {
                var dimension = (root.get("dimensions") || Ember.A([])).findBy("uniqueId", dimId);
                var trxDimension = Ember.get(trx, "dimensions.".concat(dimId));
                var lineDimensionData = dimension ? (line.get("forecastAttributes.dimensions") || Ember.A([])).findBy("dimension", dimension.get("uniqueId")) : null;
                if (!lineDimensionData || Ember.get(lineDimensionData, "values.length") > 1 || _dimensions.default.isDimensionValuesEmpty(lineDimensionData) && trxDimension) {
                  matching = false;
                } else if (!(Ember.get(lineDimensionData, "values") || []).reduce(function (values, dimValue) {
                  if (_dimensions.default.isDimensionValueEmptyAllowInheritance(dimValue)) {
                    values.push(undefined);
                  } else {
                    values.push(dimValue.value);
                  }
                  return values;
                }, []).includes(trxDimension) && !(Ember.get(lineDimensionData, "values") || []).map(function (v) {
                  var value = dimension.findValueByUniqueId(v.value);
                  return value && value.name;
                }).filter(function (v) {
                  return v;
                }).includes(trxDimension)) {
                  // could not find exact match, try to match full path
                  var splitValue = trxDimension && trxDimension.split ? trxDimension.split(/[\.\,\/\>]/) : [];
                  splitValue = splitValue.map(_dimensions.default.normalizeDimensionValue);
                  var lineValues = (Ember.get(lineDimensionData, "values") || []).map(function (v) {
                    return dimension.findValueByUniqueId(Ember.get(v, "value"));
                  });
                  if (splitValue.length <= 1 || !lineValues.filter(function (v) {
                    return v;
                  }).map(function (v) {
                    return (Ember.get(v, "path") || []).join(">");
                  }).includes(splitValue.join(">"))) {
                    matching = false;
                  }
                }
                // Needs to be Removed due to BUD-15959
                // if we have a matching line, but trx doesn't have any dimensions
                if (matching && !trxDimension) {
                  _this5.addToSetsIfFaulty(trx, account.get("id"));
                }
              });
              return matching;
            });
            // if no match was found, try to match lines with ancestor value
            if (!matchingLine) {
              matchingLine = budgetLines.find(function (line) {
                if (!line) {
                  return false;
                }
                var matching = true;
                (account.get("dimensions") || []).forEach(function (dimId) {
                  var dimension = (root.get("dimensions") || Ember.A([])).findBy("uniqueId", dimId);
                  var trxDimension = Ember.get(trx, "dimensions.".concat(dimId));
                  var lineDimensionData = dimension ? (line.get("forecastAttributes.dimensions") || Ember.A([])).findBy("dimension", dimension.get("uniqueId")) : null;
                  if (!lineDimensionData || Ember.get(lineDimensionData, "values.length") > 1 || _dimensions.default.isDimensionValuesEmpty(lineDimensionData) || !(Ember.get(lineDimensionData, "values") || []).mapBy("value").find(function (val) {
                    return _dimensions.default.isAncestor(val, trxDimension, dimension.get("values"), _this5.get("cache"));
                  })) {
                    matching = false;
                  }
                  // Needs to be Removed due to BUD-15959
                  // if we have a matching line, but trx doesn't have any dimensions
                  if (matching && !trxDimension) {
                    _this5.addToSetsIfFaulty(trx, account.get("id"));
                  }
                });
                return matching;
              });
            }
            // if no match was found, try to match lines with multiple dimension values
            if (!matchingLine) {
              matchingLine = budgetLines.find(function (line) {
                if (!line) {
                  return false;
                }
                var matching = true;
                (account.get("dimensions") || []).forEach(function (dimId) {
                  var dimension = (root.get("dimensions") || Ember.A([])).findBy("uniqueId", dimId);
                  var trxDimension = Ember.get(trx, "dimensions.".concat(dimId));
                  var lineDimensionData = dimension ? (line.get("forecastAttributes.dimensions") || Ember.A([])).findBy("dimension", dimension.get("uniqueId")) : null;
                  if (!lineDimensionData || !(Ember.get(lineDimensionData, "values") || []).reduce(function (values, dimValue) {
                    if (!dimValue.hasOwnProperty("value") || dimValue.value === null) {
                      values.push(undefined);
                    } else {
                      values.push(dimValue.value);
                    }
                    return values;
                  }, []).includes(trxDimension)) {
                    matching = false;
                  }
                  // if we have a matching line, but trx doesn't have any dimensions
                  // Needs to be Removed due to BUD-15959
                  if (matching && !trxDimension) {
                    _this5.addToSetsIfFaulty(trx, account.get("id"));
                  }
                });
                return matching;
              });
            }
            result = (_matchingLine$id = (_matchingLine = matchingLine) === null || _matchingLine === void 0 ? void 0 : _matchingLine.id) !== null && _matchingLine$id !== void 0 ? _matchingLine$id : null;
          }
          if (useCache) {
            this.set("transactionMappingCache.".concat(cacheKey), result);
            this.set("transactionAccountDimCache.".concat(accountDimCacheKey), result);
          }
          return result;
        }
      }
      if (useCache) {
        this.set("transactionMappingCache.".concat(cacheKey), null);
      }
      return null;
    },
    getFinalAccountMapping: function getFinalAccountMapping(account) {
      if (!account) {
        return null;
      }
      return account.get("mappingType") === "clone" ? this.getFinalAccountMapping(this.getAccount(account.get("copyFromAccount"))) : account.get("mappingType");
    },
    getFinalAccount: function getFinalAccount(account) {
      if (!account) {
        return null;
      }
      return account.get("mappingType") === "clone" ? this.getFinalAccount(this.getAccount(account.get("copyFromAccount"))) : account;
    },
    getAccountIdFilter: function getAccountIdFilter(account) {
      var _this6 = this;
      return function (line) {
        if (!line) {
          return false;
        }
        return _this6.findLineByAccountId(account) === line;
      };
    },
    getAccountsForLine: function getAccountsForLine(lineId) {
      var accountMap = (this.get("accounts") || []).reduce(function (accountMap, account) {
        accountMap[account.get("account")] = account;
        return accountMap;
      }, {});
      return (this.get("accounts") || []).filter(function (account) {
        if (account.get("isClone")) {
          account = _accounts.default.findCloneAccount(account, accountMap);
        }
        return !account || account.get("mappingType") === "none" ? null : account.get("budgetLines").find(function (l) {
          return l.split(":")[0] === lineId;
        });
      });
    },
    addLinesToAccount: function addLinesToAccount(accountId, _ref2) {
      var lineIds = _ref2.lineIds,
        description = _ref2.description,
        _ref2$type = _ref2.type,
        type = _ref2$type === void 0 ? "pnl" : _ref2$type,
        root = _ref2.root;
      var thisAccount = this.getAccount(accountId, {
        root: root
      });
      if (!Ember.isArray(lineIds)) {
        lineIds = [lineIds];
      }
      if (thisAccount) {
        if (!(thisAccount.get("type") === "bs" && thisAccount.get("budgetLines.length") === 1)) {
          thisAccount.get("budgetLines").addObjects(lineIds);
          thisAccount.set("mappingType", thisAccount.get("budgetLines.length") === 1 ? "single" : "multi");
        }
      } else {
        var accountObject = this.createAccountObject(accountId, lineIds, description, type, root);
        thisAccount = this.get("store").createRecord("actual-account", accountObject);
      }
      if (thisAccount.get("isSaving")) {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve();
        });
      } else {
        return thisAccount.save();
      }
    },
    createAccountObject: function createAccountObject(accountId, lineIds, description) {
      var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "pnl";
      var root = arguments.length > 4 ? arguments[4] : undefined;
      var mappingType = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : "single";
      var account = {
        description: description,
        account: accountId,
        type: type,
        mappingType: mappingType,
        budgetLines: lineIds,
        budget: root || this.get("budgetService.selectedRoot.absoluteRoot"),
        created: new Date()
      };
      if (type !== "bs") {
        account.dimensions = [];
      }
      return account;
    }
  });
});