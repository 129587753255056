define("budgeta/admin/store/actions/notifActions", ["exports", "@sibp/ui-components"], function (_exports, _uiComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SHOW_NOTY = _exports.SHOW_LOADER = _exports.HIDE_NOTY = _exports.HIDE_LOADER = void 0;
  var SHOW_LOADER = _exports.SHOW_LOADER = "SHOW_LOADER";
  var HIDE_LOADER = _exports.HIDE_LOADER = "HIDE_LOADER";
  var SHOW_NOTY = _exports.SHOW_NOTY = "SHOW_NOTY";
  var HIDE_NOTY = _exports.HIDE_NOTY = "HIDE_NOTY";
  var showLoader = function showLoader() {
    return {
      type: SHOW_LOADER
    };
  };
  var hideLoader = function hideLoader() {
    return {
      type: HIDE_LOADER
    };
  };
  var showNoty = function showNoty(_ref) {
    var text = _ref.text,
      notiContent = _ref.notiContent,
      _ref$errorLevel = _ref.errorLevel,
      errorLevel = _ref$errorLevel === void 0 ? _uiComponents.default.ERROR_LEVEL.ERROR : _ref$errorLevel;
    return {
      type: SHOW_NOTY,
      text: text,
      notiContent: notiContent,
      errorLevel: errorLevel
    };
  };
  var hideNoty = function hideNoty() {
    return {
      type: HIDE_NOTY
    };
  };
  var _default = _exports.default = {
    showLoader: showLoader,
    hideLoader: hideLoader,
    showNoty: showNoty,
    hideNoty: hideNoty
  };
});