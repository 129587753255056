define("budgeta/templates/components/svg/search-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YLHU8ekI",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"20\"],[\"static-attr\",\"height\",\"20\"],[\"static-attr\",\"viewBox\",\"0 0 20 20\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M8.25 1C4.25778 1 1 4.25778 1 8.25C1 12.2422 4.25778 15.5 8.25 15.5C9.88658 15.5 11.3945 14.9465 12.6104 14.0254L17.293 18.707C17.5438 18.9683 17.9162 19.0735 18.2667 18.9821C18.6171 18.8908 18.8908 18.6171 18.9821 18.2667C19.0735 17.9162 18.9683 17.5438 18.707 17.293L14.0254 12.6104C14.9465 11.3945 15.5 9.88658 15.5 8.25C15.5 4.25778 12.2422 1 8.25 1ZM8.25 3C11.1613 3 13.5 5.33866 13.5 8.25C13.5 9.64786 12.957 10.9103 12.0742 11.8486C11.9883 11.9108 11.9128 11.9863 11.8506 12.0723C10.9121 12.9562 9.64886 13.5 8.25 13.5C5.33866 13.5 3 11.1613 3 8.25C3 5.33866 5.33866 3 8.25 3Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/search-svg.hbs"
    }
  });
});