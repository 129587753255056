define("budgeta/services/filter-visibility", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var createVisibilityProperty = function createVisibilityProperty(propName) {
    return Ember.computed("selectedRoot", "navigationPath", {
      get: function get() {
        var rootId = this.get("selectedRoot.id");
        return _const.default.getAttributeDropdownVisibility(rootId, "".concat(this.get("navigationPath")).concat(propName));
      },
      set: function set(key, value) {
        var rootId = this.get("selectedRoot.id");
        _const.default.setAttributeDropdownVisibility(rootId, "".concat(this.get("navigationPath")).concat(propName), value);
        return value;
      }
    });
  };
  var _default = _exports.default = Ember.Service.extend({
    budgetService: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    selectedRoot: Ember.computed.alias("budgetService.selectedRoot"),
    isTableView: Ember.computed.alias("viewSelections.isTableView"),
    employeesEdit: Ember.computed.alias("viewSelections.employeesEdit"),
    isMetricsView: Ember.computed.alias("viewSelections.isMetricsView"),
    isSheetsView: Ember.computed.alias("viewSelections.isSheetsView"),
    isDashboardView: Ember.computed.alias("viewSelections.isDashboardView"),
    navigationPath: Ember.computed("isTableView", "employeesEdit", "isMetricsView", "isSheetsView", "isDashboardView", function () {
      var navigationPath = "";
      if (this.get("isSheetsView")) {
        navigationPath = "sheets_";
      }
      if (this.get("isDashboardView")) {
        navigationPath = "dashboard_";
      }
      if (this.get("isTableView")) {
        if (this.get("employeesEdit")) {
          navigationPath = "employee_table_";
        } else {
          navigationPath = "general_table_";
        }
      }
      return navigationPath;
    }),
    budgetVisibility: createVisibilityProperty("budgetVisibility"),
    periodVisibility: createVisibilityProperty("periodVisibility"),
    currencyVisibility: createVisibilityProperty("currencyVisibility"),
    numbersVisibility: createVisibilityProperty("numbersVisibility"),
    compareToVersionVisibility: createVisibilityProperty("compareToVersionVisibility"),
    versionNameVisibility: createVisibilityProperty("versionNameVisibility"),
    bookingsTypeVisibility: createVisibilityProperty("bookingsTypeVisibility"),
    headcountTypeVisibility: createVisibilityProperty("headcountTypeVisibility"),
    headcountDetailsVisibility: createVisibilityProperty("headcountDetailsVisibility"),
    rolesVisibility: createVisibilityProperty("rolesVisibility"),
    tagsVisibility: createVisibilityProperty("tagsVisibility"),
    accountsVisibility: createVisibilityProperty("accountsVisibility"),
    statAccountsVisibility: createVisibilityProperty("statAccountsVisibility")
  });
});