define("budgeta/services/audit-logging/audit-logging-messages", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@sibp/ui-components", "react", "budgeta/admin/admin-app/routes", "react-router-dom", "budgeta/services/ember-service-container", "budgeta/services/audit-logging/audit-logging-utils"], function (_exports, _defineProperty2, _uiComponents, _react, _routes, _reactRouterDom, _emberServiceContainer, _auditLoggingUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AUDIT_LOG_MESSAGES = void 0;
  /* eslint-disable no-undef */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var _require = require("budgeta/services/audit-logging/audit-logging-const"),
    ADMIN_APP_ACTION = _require.ADMIN_APP_ACTION,
    SIBP_ACTION = _require.SIBP_ACTION;
  var AUDIT_LOG_MESSAGES = _exports.AUDIT_LOG_MESSAGES = function AUDIT_LOG_MESSAGES() {
    var history = _reactRouterDom.default.useHistory();
    return {
      ADMIN_APP_MESSAGES: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ADMIN_APP_ACTION.ADD_COMPANY, function (logObject) {
        return _react.default.createElement("div", null, _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
          text: intl.t("admin_app.audit_logging.company_details"),
          onClick: function onClick() {
            history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_EDIT_DETAILS, {
              companyId: logObject.company.id
            }));
          }
        }));
      }), ADMIN_APP_ACTION.DELETE_COMPANY, function (logObject) {
        return _react.default.createElement("div", null, logObject.companyId.name);
      }), ADMIN_APP_ACTION.EDIT_COMPANY, function (logObject) {
        var details = logObject.actionDetails[0].details;
        var logTextDiffComponents = _auditLoggingUtils.default.getLogTextDiffComponents(details);
        return _react.default.createElement("div", null, logTextDiffComponents, _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
          text: intl.t("admin_app.audit_logging.company_details"),
          onClick: function onClick() {
            history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_EDIT_DETAILS, {
              companyId: logObject.companyId._id
            }));
          }
        }));
      }), ADMIN_APP_ACTION.ADD_COMPANY_USER, function (logObject) {
        var userObj = logObject.actionDetails[0].entities[0].id;
        var companyId = logObject.companyId._id;
        return _react.default.createElement("div", null, _react.default.createElement("div", null, userObj.firstName + " " + userObj.lastName), _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
          text: intl.t("admin_app.audit_logging.user_details"),
          onClick: function onClick() {
            history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_USER_EDIT, {
              companyId: companyId,
              userId: userObj._id
            }));
          }
        }));
      }), ADMIN_APP_ACTION.EDIT_COMPANY_USER, function (logObject) {
        var companyId = logObject.companyId._id;
        var userObj = logObject.actionDetails[0].entities[0].id;
        var details = logObject.actionDetails[0].details;
        var logTextDiffComponents = _auditLoggingUtils.default.getLogTextDiffComponents(details);
        return _react.default.createElement("div", null, logTextDiffComponents, _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
          text: intl.t("admin_app.audit_logging.user_details"),
          onClick: function onClick() {
            history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.COMPANY_USER_EDIT, {
              companyId: companyId,
              userId: userObj._id
            }));
          }
        }));
      }),
      SIBP_MESSAGES: (0, _defineProperty2.default)({}, SIBP_ACTION.UPDATE_ATTRIBUTE, function () {
        return "";
      })
    };
  };
});