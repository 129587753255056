define("budgeta/common-react-components/utils/tabsUtil", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "budgeta/admin/utils/routes"], function (_exports, _toConsumableArray2, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var findActiveTab = function findActiveTab(tabs) {
    var routerBasename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    for (var i = 0; i < tabs.length; i++) {
      var tab = tabs[i];
      var routesOfTab = tab.baseInnerRoutes ? [tab.route].concat((0, _toConsumableArray2.default)(tab.baseInnerRoutes)) : [tab.route];
      if ((0, _routes.isInRoutes)(location.pathname.replace(routerBasename, ""), routesOfTab)) {
        return i;
      }
    }
    return -1;
  };
  var _default = _exports.default = {
    findActiveTab: findActiveTab
  };
});