define("budgeta/templates/components/integration-wizard-export-select-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DXDi1m91",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"isLoadingSettings\"]]],null,4,3]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"integration-wizard-export-select-reports.model\"],null],false]],\"locals\":[]},{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"integration-wizard-export-select-reports.balance-sheet\"],null],false]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"unknown\",[\"budgetService\",\"pnlFullName\"]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"static-attr\",\"data-automation\",\"export-pnl\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"budgeta-checkbox\"],null,[[\"checked\"],[[\"get\",[\"model\",\"exportPnl\"]]]],2],[\"text\",\"  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"static-attr\",\"data-automation\",\"export-balance-sheet\"],[\"flush-element\"],[\"block\",[\"budgeta-checkbox\"],null,[[\"disabled\",\"checked\"],[[\"get\",[\"options\",\"disableBalanceSheet\"]],[\"get\",[\"model\",\"exportBS\"]]]],1],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"static-attr\",\"data-automation\",\"export-model\"],[\"flush-element\"],[\"block\",[\"budgeta-checkbox\"],null,[[\"checked\"],[[\"get\",[\"model\",\"exportModel\"]]]],0],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"unknown\",[\"budgeta-spinner\"]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/integration-wizard-export-select-reports.hbs"
    }
  });
});