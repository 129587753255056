define("budgeta/templates/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "279UAdHI",
    "block": "{\"statements\":[[\"block\",[\"modal-new\"],null,[[\"controllertmp\"],[[\"get\",[null]]]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"component\"],[[\"get\",[\"templateName\"]]],[[\"model\",\"chart\"],[[\"get\",[\"model\"]],[\"get\",[\"chart\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/modal.hbs"
    }
  });
});