define("budgeta/components/pct-array", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var updateData = function updateData(data, input) {
    if (data.length > 0) {
      input.focus();
      var value = data.shift().replace(/\((.*)\)/, "-$1").replace(/[^\d\.\-]/g, "");
      if (!isNaN(value * 1)) {
        input.val(value * 1);
      }
      input.trigger("keyPress");
      if (data.length) {
        var next = input.closest(".custom-amount").next().find("input");
        next.focus();
        updateData(data, next);
      }
    }
  };
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNameBindings: [":wrap", ":budgeta-type-custom-amounts", "addBudgetaClass:budgeta-type-value", "allowFormula:has-formula-button"],
    attributeBindings: ["data-key", "data-hide", "data-order", "data-default"],
    addBudgetaClass: true,
    "data-key": Ember.computed.alias("key"),
    willDestroyElement: function willDestroyElement() {
      if (this.$()) {
        this.$().off("paste", "input");
      }
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _this = this,
        container = _this.$();
      //handle paste from excel
      container.on("paste", "input", function (e) {
        var data = e.originalEvent.clipboardData.getData("text/plain");
        var result = VerEx().tab().or().lineBreak().replace(data, "/\t").split("/\t");
        if (result.length > 1) {
          e.preventDefault();
          _this.set("pasting", true);
          _this.set("lockPreviewRefresh", true);
          updateData(result, Ember.$(this));
          _this.set("pasting", false);
          _this.set("lockPreviewRefresh", false);
          _this.sendAction("renderPreview");
        }
      });
    },
    keyDown: function keyDown(e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if (Ember.$.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true) ||
      // Allow: Ctrl+V, Command+V
      e.key === "v" && (e.ctrlKey === true || e.metaKey === true) ||
      // Allow: home, end, left, right, down, up
      e.keyCode >= 35 && e.keyCode <= 40) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if (!e.key.match(/\d/)) {
        e.preventDefault();
      }
    },
    focusIn: function focusIn(e) {
      var ele = Ember.$(e.target);
      if (ele.val()) {
        return;
      }
      var prevEle = ele.closest("div").prev().find("input");
      if (prevEle.length && prevEle.val()) {
        ele.val(prevEle.val());
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set("elementId", "attribute-".concat(this.get("key"), "-").concat(this.get("elementId")));
      this.populateValue();
      if (!this.get("value")) {
        this.set("budgetAttributes.".concat(this.get("key")), []);
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.populateValue();
    },
    populateValue: function populateValue() {
      var value = this.get("budgetAttributes.".concat(this.get("key")));
      var arraySize = 1 * this.get("arraySize");
      if (!arraySize || isNaN(arraySize)) {
        arraySize = 12;
      }
      while (value.length < arraySize) {
        value.pushObject(null);
      }
      while (value.length > arraySize) {
        value.removeAt(value.length - 1);
      }
      this.set("value", value);
    },
    items: Ember.computed("value", "arraySize", function () {
      var _this2 = this;
      return this.get("value").map(function (value, index) {
        return Item.create({
          index: index,
          label: "".concat(intl.t("general.name_1695611603933"), " ").concat(index + 1),
          array: _this2.get("budgetAttributes.".concat(_this2.get("key")))
        });
      });
    }),
    resetValueObserver: Ember.observer("clearOnChangeOf", function () {
      if (!this.get("clearOnChangeOf") && this.get("value") && Ember.isArray(this.get("value"))) {
        for (var i = 0; i < this.get("value.length"); i++) {
          this.get("value")[i] = null;
        }
        this.notifyPropertyChange("value");
      }
    })
  });
  var Item = Ember.Object.extend({
    value: Ember.computed("array", "index", {
      get: function get() {
        return this.get("array") ? this.get("array")[this.get("index")] : null;
      },
      set: function set(key, value) {
        if (this.get("array")) {
          this.get("array")[this.get("index")] = value;
        }
        return value;
      }
    })
  });
});