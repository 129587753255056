define("budgeta/models/change", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo("user", {
      async: true
    }),
    timestamp: _emberData.default.attr("date"),
    what: _emberData.default.attr("string"),
    before: _emberData.default.attr("raw", {
      defaultValue: null
    }),
    after: _emberData.default.attr("raw", {
      defaultValue: null
    }),
    budget: _emberData.default.belongsTo("budget", {
      async: false,
      inverse: "changes"
    })
  });
});