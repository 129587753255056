define("budgeta/templates/components/tag-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+PH6RQYC",
    "block": "{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"uniqueTags\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"tag\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"tag\",\"name\"]],false],[\"close-element\"]],\"locals\":[\"tag\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/tag-list.hbs"
    }
  });
});