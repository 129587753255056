define("budgeta/components/import-column-resizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":import-column-resizer"],
    attributeBindings: ["draggable", "data-hook"],
    draggable: "true",
    minimumColWidth: 100,
    "data-hook": Ember.computed("columnIndex", function () {
      return "import-column-resizer-".concat(this.get("columnIndex"));
    }),
    drag: function drag(event) {
      if (this.get("resizerPageX") && this.get("resizerPageX") !== 0) {
        this.get("action")(this.get("resizerPageX"), null, false);
      } else if (event.originalEvent.pageX !== 0) {
        this.get("action")(event.originalEvent.pageX, null, false);
      }
    },
    dragStart: function dragStart() {
      var _this = this;
      this.set("parent", this.$().parent().parent().offset().left);
      if (navigator.userAgent.indexOf("Firefox") !== -1) {
        Ember.$(document).off("dragover").on("dragover", function (e) {
          Ember.run.schedule("afterRender", function () {
            _this.set("resizerPageX", e.originalEvent.pageX);
          });
        });
      }
    },
    dragEnd: function dragEnd(event) {
      var parent = this.get("parent");
      var newWidth;
      if (navigator.userAgent.indexOf("Firefox") !== -1) {
        var cursorPosition = this.get("resizerPageX") && this.get("resizerPageX") > 0 ? this.get("resizerPageX") : event.originalEvent.screenX;
        newWidth = cursorPosition - parent > this.get("minimumColWidth") ? cursorPosition - parent : this.get("minimumColWidth");
      } else {
        newWidth = event.originalEvent.pageX - parent > this.get("minimumColWidth") ? event.originalEvent.pageX - parent : this.get("minimumColWidth");
      }
      this.get("action")(newWidth, this.get("columnIndex"), true);
      Ember.$(document).off("dragover");
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(document).off("dragover");
    }
  });
});