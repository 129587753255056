define("budgeta/components/forecast-budget-actual-row", ["exports", "budgeta/components/forecast-row-master"], function (_exports, _forecastRowMaster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _forecastRowMaster.default.extend({
    intl: Ember.inject.service(),
    displayActualValues: Ember.computed("displayValues.@each.convertedActualValue", "headerItems.@each.hasActuals", function () {
      if (Ember.isEmpty(this.get("headerItems"))) {
        return this.get("displayValues");
      }
      var _this = this,
        displayValues = this.get("displayValues");
      displayValues && displayValues.forEach(function (value) {
        var headerItem = _this.get("headerItems").findBy("key", Ember.get(value, "key"));
        if (headerItem) {
          Ember.set(value, "hasActuals", Ember.get(headerItem, "hasActuals"));
        }
        var diff = (Ember.get(value, "convertedActualValue") || 0) - (Ember.get(value, "value") || 0);
        Ember.setProperties(value, {
          diff: diff,
          negative: diff < 0,
          positive: diff > 0
        });
      });
      return displayValues;
    }),
    sameUnitTypeTooltip: Ember.computed(function () {
      return this.get("intl").t("sheets.same-unit-type-warning");
    })
  });
});