define("budgeta/generic-file/components/table-row-selection-step/table-row-selection-step", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectWithoutProperties", "react", "@sibp/ui-components", "react-redux", "budgeta/utils/useEmberBridge", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "budgeta/services/ember-service-container", "lodash/lodash"], function (_exports, _slicedToArray2, _objectWithoutProperties2, _react, _uiComponents, _reactRedux, _useEmberBridge, _stepsActions, _budgetActions, _emberServiceContainer, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = TableRowSelectionStep;
  var _excluded = ["startTableAction", "getDataAction", "endTableAction", "updateDataAction"];
  /* eslint-disable no-console */
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function TableRowSelectionStep(_ref) {
    var startTableAction = _ref.startTableAction,
      getDataAction = _ref.getDataAction,
      endTableAction = _ref.endTableAction,
      updateDataAction = _ref.updateDataAction,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
    var dispatch = _reactRedux.default.useDispatch();
    var stepName = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore;
      }),
      columnMapping = _ReactRedux$useSelect.columnMapping,
      selectedLines = _ReactRedux$useSelect.selectedLines;
    var formattedFile = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.formattedFile;
    });
    var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      nextButtonEnabled = _React$useState2[0],
      setNextButtonEnabled = _React$useState2[1];
    (0, _useEmberBridge.default)("lineNames.@each.selectedFirst", function () {
      var data = getDataAction(stepName);
      setNextButtonEnabled(data && data.selectedLines ? data.selectedLines.length : false);
    });
    (0, _useEmberBridge.default)("currentStep", function () {
      var stepData = getDataAction(stepName);
      endTableAction(stepName, {
        removeListeners: true,
        closeTable: true
      });
      if (!_lodash.default.isEqual(selectedLines, stepData.selectedLines)) {
        dispatch(_budgetActions.default.setSelectedLines(stepData.selectedLines, columnMapping, formattedFile));
        rest.clearDependencies();
      }
      dispatch(_stepsActions.default.nextStep());
    });
    _react.default.useEffect(function () {
      startTableAction(stepName, {
        currentStep: 2
      });
      if (selectedLines) {
        updateDataAction(stepName, {
          selectedLines: selectedLines
        });
        var data = getDataAction(stepName);
        setNextButtonEnabled(data && data.selectedLines ? data.selectedLines.length : false);
      }
    }, []);
    var handleDataValidation = function handleDataValidation() {
      return true;
    };
    var handleNextButton = function handleNextButton() {
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_SELECT_LINES);
      var stepData = getDataAction(stepName);
      if (handleDataValidation(stepData)) {
        endTableAction(stepName);
      } else {
        console.log("Some validation error");
      }
    };
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        var stepData = getDataAction(stepName);
        if (!_lodash.default.isEqual(selectedLines, stepData.selectedLines)) {
          dispatch(_budgetActions.default.setSelectedLines(stepData.selectedLines, columnMapping, formattedFile));
          rest.clearDependencies();
        }
        endTableAction(stepName, {
          removeListeners: true,
          closeTable: true,
          back: true
        });
        dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: handleNextButton,
      disabled: !nextButtonEnabled
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.Footer, footer);
  }
});