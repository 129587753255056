define("budgeta/components/display-budget-structure-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":display-budget-structure-row"],
    attributeBindings: ["data-level", "data-is-parent"],
    "data-level": Ember.computed.alias("budget.level"),
    "data-is-parent": Ember.computed.alias("budget.isGroup"),
    actions: {
      collapseBudgetTree: function collapseBudgetTree(budget) {
        this.sendAction("collapseBudgetTree", budget);
      }
    }
  });
});