define("budgeta/components/integration-wizard-select-date-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    enablePastDataRange: true,
    customRange: Ember.computed.equal("model.dateRangeOption", "custom"),
    ytde: Ember.computed.equal("model.dateRangeOption", "ytde"),
    startDate: Ember.computed("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate", function () {
      return new Date(this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate")).addYears(-2);
    })
  });
});