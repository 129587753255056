define("budgeta/models/budget-module", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-data", "budgeta/utils/const"], function (_exports, _defineProperty2, _emberData, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _DS$Model$extend;
  var _default = _exports.default = _emberData.default.Model.extend((_DS$Model$extend = {
    allowedForRole: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    altName: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    typeGroup: _emberData.default.attr("string"),
    typeGroupOrder: _emberData.default.attr("number"),
    forecast: _emberData.default.attr("string"),
    editType: _emberData.default.attr("string"),
    tipId: _emberData.default.attr("string"),
    importMappingType: _emberData.default.attr("string"),
    addRootName: _emberData.default.attr("string"),
    addRootOrder: _emberData.default.attr("number"),
    addRootOnSelectAction: _emberData.default.attr("string"),
    addRootHelpLink: _emberData.default.attr("string"),
    helpLink: _emberData.default.attr("string"),
    bsOther: _emberData.default.attr("boolean"),
    isGroup: _emberData.default.attr("boolean"),
    isSum: _emberData.default.attr("boolean"),
    abstract: _emberData.default.attr("boolean"),
    deprecated: _emberData.default.attr("boolean"),
    onlyInNonProfit: _emberData.default.attr("boolean"),
    unavailableInNonProfitBudget: _emberData.default.attr("boolean"),
    selected: _emberData.default.attr("boolean"),
    canBeRoot: _emberData.default.attr("boolean"),
    expense: _emberData.default.attr("boolean"),
    revenue: _emberData.default.attr("boolean"),
    hideInTree: _emberData.default.attr("boolean"),
    showInTree: Ember.computed.not("hideInTree"),
    excludeFromTotal: _emberData.default.attr("boolean"),
    excludeFromWizard: _emberData.default.attr("boolean"),
    includeInHeadcount: _emberData.default.attr("boolean"),
    allowNegative: _emberData.default.attr("boolean"),
    allowedChildTypes: _emberData.default.attr("raw"),
    allowedChildTypesForImport: _emberData.default.attr("raw"),
    template: _emberData.default.attr("raw"),
    interchange: _emberData.default.attr("raw"),
    uncategorizedActualsLabel: _emberData.default.attr("string"),
    hideMainSection: _emberData.default.attr("boolean"),
    mainSectionTitle: _emberData.default.attr("string"),
    youtubeId: _emberData.default.attr("string"),
    wizardTitle: _emberData.default.attr("string"),
    wizardSubtitle: _emberData.default.attr("string"),
    wizardTip: _emberData.default.attr("string"),
    menuImportEnabled: _emberData.default.attr("boolean"),
    wizardImportEnabled: _emberData.default.attr("boolean"),
    wizardImportQuestion: _emberData.default.attr("string"),
    wizardAddChildBeforeImport: _emberData.default.attr("boolean"),
    wizardAddChildType: _emberData.default.attr("string"),
    wizardAddChildQuestion: _emberData.default.attr("string"),
    wizardAddChildTypeQuestion: _emberData.default.attr("string"),
    wizardAddChildTypeOptions: _emberData.default.attr("raw"),
    wizardTypeChange: _emberData.default.attr("raw")
  }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_DS$Model$extend, _const.default.BUDGET_MODULES_TRANSLATIONS.TRANSLATION_KEYS, _emberData.default.attr("raw")), "addWizardQuestions", _emberData.default.attr("raw")), "wizardSteps", _emberData.default.attr("raw")), "wizardCompleteAction", _emberData.default.attr("string")), "sections", _emberData.default.attr("raw")), "show", _emberData.default.attr("raw")), "reports", _emberData.default.attr("raw")), "defaultCategories", _emberData.default.attr("raw")), "budgetAttributes", _emberData.default.attr("raw")), "ignoreDimensionsTypes", _emberData.default.attr("raw")), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_DS$Model$extend, "forecastFunc", Ember.computed("forecast", function () {
    var forecastFunc = null;
    if (!Ember.isEmpty(this.get("forecast"))) {
      var funcData = this.get("forecast").match(/function\s*\(([^\)]+)\)[^{]+\{([\s\S]*)\}$/);
      var argNames = funcData[1].split(",");
      var calculationName = argNames.length > 4 ? argNames[4].trim() : null;
      if (calculationName && calculationName.indexOf("count") === 0) {
        // calculate additional data needed for forecast function
        forecastFunc = new Function(argNames[0].trim(), argNames[1].trim(), argNames[2].trim(), argNames[3].trim(), argNames[4].trim(), funcData[2]);
      } else {
        forecastFunc = new Function("budget", "util", "store", "options", funcData[2]);
      }
      this.set("forecastCalc", calculationName);
    }
    return forecastFunc;
  })), "hideOnEmployeePopup", _emberData.default.attr("raw")), "subBudgetType", _emberData.default.attr("string"))));
});