define("budgeta/components/add-employee-modal", ["exports", "budgeta/components/modal-new"], function (_exports, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    classNames: ["add-employee-modal"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$(".modal-content .modal-footer #confirm-btn").addClass("disabled");
    }
  });
});