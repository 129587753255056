define("budgeta/controllers/rename-chart", ["exports", "budgeta/controllers/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modal.default.extend({
    newName: Ember.computed.alias("model.title"),
    validations: {
      newName: {
        presence: true
      }
    }
  });
});