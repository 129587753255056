define("budgeta/components/integration-wizard-delete-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    isLoading: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    }),
    selectAll: false,
    deletableAccountsRows: Ember.computed("step.deletableAccountsRows", function () {
      return (this.get("step.deletableAccountsRows") || []).sortBy("account.account");
    }),
    deletableAccountsRowsPreview: Ember.computed("deletableAccountsRows.@each.shouldDelete", function () {
      return (this.get("deletableAccountsRows") || []).filter(function (row) {
        return !row.shouldDelete;
      });
    }),
    showGlobalDelete: Ember.computed("deletableAccountsRows.@each.selected", function () {
      return this.get("deletableAccountsRows").findBy("selected");
    }),
    actions: {
      selectAll: function selectAll() {
        this.get("deletableAccountsRows").setEach("selected", this.get("selectAll"));
      },
      removeAccount: function removeAccount(row) {
        Ember.set(row, "shouldDelete", true);
      },
      removeAccounts: function removeAccounts() {
        var accountsToRemove = this.get("deletableAccountsRows").filterBy("selected");
        accountsToRemove.map(function (row) {
          Ember.set(row, "selected", false);
          Ember.set(row, "shouldDelete", true);
        });
      }
    }
  });
});