define("budgeta/routes/change-password", ["exports", "budgeta/routes/login", "budgeta/mixins/budgeta-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _login, _budgetaRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _login.default.extend(_authenticatedRouteMixin.default, _budgetaRoute.default, {
    renderTemplate: function renderTemplate() {
      this.render("login");
    }
  });
});