define("budgeta/components/wizard-modal", ["exports", "budgeta/components/modal-new", "budgeta/templates/components/modal-new"], function (_exports, _modalNew, _modalNew2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    classNameBindings: ["controllertmp.parent.budgetType", "controllertmp.hasGroupChildren:has-group-children"],
    layout: _modalNew2.default,
    updateChildClasses: Ember.observer("controllertmp.parent.children.@each.budgetType", function (_this) {
      _this = _this || this;
      Ember.run.schedule("afterRender", function () {
        if (_this.$()) {
          var inGroup = false;
          _this.$(".sortable>li").each(function (idx, el) {
            if (Ember.$(el).is('[data-selected-type^="group"]')) {
              inGroup = true;
              Ember.$(el).removeClass("child-normal");
            } else {
              if (inGroup) {
                Ember.$(el).addClass("child-normal");
              } else {
                Ember.$(el).removeClass("child-normal");
              }
            }
          });
        }
      });
    }),
    checkForScroll: function checkForScroll(_this) {
      var scrollable = _this.$(".scrollable:not(.disable-more-btn)");
      var initialScroll = 0;
      if (scrollable) {
        initialScroll = scrollable.scrollTop();
      }
      Ember.run.scheduleOnce("afterRender", function () {
        if (_this.$()) {
          _this.$("#more-btn").hide();
          _this.$().removeClass("has-scrollbar");
        }
        Ember.$(".modal").scrollTop(0); // fix for BUD-3689
        scrollable = _this.$(".scrollable:not(.disable-more-btn)");
        if (scrollable && scrollable.get(0)) {
          if (initialScroll) {
            scrollable.scrollTop(initialScroll);
          }
          if (scrollable.get(0).scrollHeight > scrollable.get(0).clientHeight) {
            _this.$().addClass("has-scrollbar");
            // change button to More until scrolled all the way to bottom
            if (scrollable.get(0).clientHeight + scrollable.get(0).scrollTop < scrollable.get(0).scrollHeight - 10) {
              _this.$("#more-btn").show();
            }
            scrollable.on("scroll", function () {
              if (_this.$() && this.clientHeight + this.scrollTop > this.scrollHeight - 10) {
                _this.$("#more-btn").hide();
              }
            });
          } else {
            _this.$("#more-btn").hide();
            _this.$().removeClass("has-scrollbar");
          }
        }
        var sortable = _this.$(".sortable");
        if (sortable) {
          sortable.sortable({
            update: function update() {
              _this.updateChildClasses(_this);
            }
          });
        }
      });
    },
    stepObserver: Ember.observer("controllertmp.currentStep", "controllertmp.currentStep.data.length", function () {
      this.checkForScroll(this);
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce("afterRender", function () {
        var _this = _this2;
        _this2.checkForScroll(_this2);
        _this2.$().on("click focus", function () {
          var animated = _this.$(":animated");
          if (animated.length) {
            animated.promise().done(function () {
              _this.checkForScroll(_this);
            });
          } else {
            _this.checkForScroll(_this);
          }
        }).on("change", function () {
          _this.set("controllertmp.changed", true);
        }).on("click", ".budgeta-checkbox", function () {
          _this.set("controllertmp.changed", true);
        });
      });
    }
  });
});