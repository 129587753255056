define("budgeta/components/budgeta-chart", ["exports", "budgeta/utils/dashboard"], function (_exports, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layoutName: "components/budgeta-chart",
    colorPalette: Ember.computed("injectColorPalette", function () {
      return this.get("injectColorPalette") ? this.get("injectColorPalette") : _dashboard.default.getColorPalette();
    }),
    noData: Ember.computed("budgetaChartData", "chartCategories", "ignoreCategories", function () {
      return _dashboard.default.isEmptyChart(this.get("ignoreCategories"), this.get("budgetaChartData"), this.get("chartCategories"));
    })
  });
});