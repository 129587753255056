define("budgeta/common-react-components/profile/pages/myPassword/myPassword", ["exports", "@sibp/ui-components", "react", "budgeta/services/ember-service-container"], function (_exports, _uiComponents, _react, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MyPassword = void 0;
  /* eslint-disable react/display-name */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var MyPassword = _exports.MyPassword = function MyPassword(_ref) {
    var user = _ref.user,
      onFieldBlur = _ref.onFieldBlur,
      passwords = _ref.passwords;
    return function () {
      var leftBody = _react.default.createElement("div", null, _react.default.createElement("div", {
        className: "change-password"
      }, intl.t("user-profile.my-password.change-password")), _react.default.createElement("div", {
        className: "update-password-message"
      }, intl.t("user-profile.my-password.update-password-for"), " ", user.email), _react.default.createElement("div", {
        className: "update-password-notice-message"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Alert, {
        text: intl.t("user-profile.my-password.password-notice-message"),
        status: "notice"
      })), _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        onBlur: function onBlur(event) {
          return onFieldBlur("currentPassword", event.target.value);
        },
        value: passwords.currentPassword || "",
        type: "password",
        dataHook: "currentPassword",
        label: intl.t("user-profile.my-password.current-password")
      }), _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        onBlur: function onBlur(event) {
          return onFieldBlur("newPassword", event.target.value);
        },
        value: passwords.newPassword || "",
        type: "password",
        dataHook: "newPassword",
        label: intl.t("user-profile.my-password.new-password")
      }), _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        onBlur: function onBlur(event) {
          return onFieldBlur("confirmPassword", event.target.value);
        },
        value: passwords.confirmPassword || "",
        type: "password",
        dataHook: "confirmPassword",
        label: intl.t("user-profile.my-password.confirm-password")
      }));
      return _react.default.createElement("div", {
        className: "my-details full-width"
      }, _react.default.createElement("div", {
        className: "left-right-sides"
      }, _react.default.createElement("div", {
        className: "left-side"
      }, leftBody)));
    };
  };
});