define("budgeta/templates/components/actual-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "f91Kbh4a",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"html5-input\"],null,[[\"name\",\"readonly\",\"class\",\"min\",\"step\",\"value\",\"placeholder\"],[[\"get\",[\"name\"]],[\"get\",[\"readonly\"]],[\"get\",[\"class\"]],[\"get\",[\"min\"]],[\"get\",[\"step\"]],[\"get\",[\"formattedValue\"]],[\"get\",[\"placeholder\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/actual-input.hbs"
    }
  });
});