define("budgeta/adapters/application", ["exports", "ember-data", "budgeta/services/ember-service-container", "ember-simple-auth/mixins/data-adapter-mixin", "budgeta/config/environment", "ember-inflector"], function (_exports, _emberData, _emberServiceContainer, _dataAdapterMixin, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  // import idGenerator from 'budgeta/utils/id-generator';

  // export default DS.FirebaseAdapter.extend({
  //   firebase: new Firebase('https://sizzling-fire-9898.firebaseio.com')
  // });

  var options = {
    namespace: _environment.default.apiPath,
    authorizer: "authorizer:application",
    handleResponse: function handleResponse(status) {
      if (status === 0) {
        noty({
          type: "error",
          text: intl.t("general.text_1695611608646"),
          timeout: null
        });
      } else if (status === 403) {
        return;
      } else {
        if (status === 401) {
          Ember.$.cookie("sessionExpired", true);
        }
        return this._super.apply(this, arguments);
      }
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord( /*store, snapshot*/
    ) {
      return false;
    },
    shouldReloadAll: function shouldReloadAll(store, snapshot) {
      return store.peekAll(snapshot.type.modelName).get("length") === 0;
    },
    // TODO: Remove this override when ember-data's `RestAdapter` no longer uses
    // the deprecated `Ember.String.pluralize` method.
    pathForType: function pathForType(modelName) {
      var camelized = Ember.String.camelize(modelName);
      return (0, _emberInflector.pluralize)(camelized);
    }
  };
  if (_environment.default.serverAddress) {
    options.host = _environment.default.serverAddress;
  }

  // options.generateIdForRecord = idGenerator;
  var _default = _exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, options);
});