define("budgeta/generic-file/components/dimensions-hierarchies-question-intro/dimensions-hierarchies-question-intro", ["exports", "@babel/runtime/helpers/esm/objectDestructuringEmpty", "@babel/runtime/helpers/esm/extends", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "react", "budgeta/store/actions/budgetActions"], function (_exports, _objectDestructuringEmpty2, _extends2, _uiComponents, _reactRedux, _stepsActions, _emberServiceContainer, _react, _budgetActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = DimensionsIntro;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function DimensionsIntro(_ref) {
    var rest = (0, _extends2.default)({}, ((0, _objectDestructuringEmpty2.default)(_ref), _ref));
    var dispatch = _reactRedux.default.useDispatch();
    var previousStep = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.notAllowedLinesDetails.activeStep;
    });
    var skipDimensionStructure = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.skipDimensionStructure;
    });
    var options = {
      hideClose: true,
      height: "405px"
    };
    var prefix = _react.default.createElement(_uiComponents.default.IMAGES.SegmentsLayersSvg, null);
    var header = {
      text: intl.t("generic-file.step-dimensions-hierarchies-question-intro.header"),
      options: {
        prefix: prefix,
        prefixClass: "dimensions-hierarchies-question-intro-header-container title-next-to-prefix",
        titleClass: "title",
        hideSeperator: true
      }
    };
    var body = _react.default.createElement("div", {
      className: "dimensions-hierarchies-question-intro-body-container padding-intro-body recomended-bar"
    }, _react.default.createElement("div", {
      className: "description description-left"
    }, intl.t("generic-file.step-dimensions-hierarchies-question-intro.description")), _react.default.createElement("div", {
      className: "recommended-label"
    }, _react.default.createElement(_uiComponents.default.IMAGES.ThumbsUpSvg, null), _react.default.createElement("span", null, intl.t("generic-file.step-dimensions-hierarchies-question-intro.recommended-label"))));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button",
      dataHook: "footer-tertiary-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep(previousStep));
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button with-border",
      dataHook: "footer-secondary-button",
      text: intl.t("generic-file.no"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.SKIPPED_HIERARCHY_STEP);
        if (!skipDimensionStructure) {
          dispatch(_budgetActions.default.setDimensionStructureSkip(true));
          rest.clearDependencies();
        }
        dispatch(_stepsActions.default.nextStep({
          continue: false
        }));
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.yes"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CHOOSED_HIERARCHY_STEP);
        if (skipDimensionStructure) {
          dispatch(_budgetActions.default.setDimensionStructureSkip(false));
          rest.clearDependencies();
        }
        dispatch(_stepsActions.default.nextStep({
          continue: true
        }));
      }
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});