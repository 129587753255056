define("budgeta/components/budget-line-dimensions-input", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/utils/dimensions"], function (_exports, _emberServiceContainer, _const, _budgetUtils, _dimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/closure-actions */

  var DimensionObj = Ember.Object.extend({
    dimensionData: null,
    dimension: null,
    elementId: Ember.computed("dimension.uniqueId", function () {
      return "attribute-dimension-".concat(this.get("dimension.uniqueId"));
    }),
    readonly: null,
    editable: Ember.computed.not("readonly"),
    allowCreate: Ember.computed("readonly", "scenario", function () {
      return !this.get("readonly") && !this.get("scenario");
    }),
    fixedLength: false,
    placeholder: Ember.computed("isSWCategory", "inheritedValue", "dimensionData.values.length", "dimensionData.values.firstObject.value", function () {
      return this.get("isSWCategory") && this.get("inheritedValue") ? this.get("inheritedValue") : _dimensions.default.isDimensionValuesInheritance(this.get("dimensionData")) ? this.get("inheritedValue") : " ";
    }),
    showRevert: Ember.computed("inheritedValue", "dimensionData.values.firstObject.value", function () {
      return this.get("inheritedValue") && this.get("dimensionData.values.firstObject.value") !== undefined;
    }),
    showDelete: Ember.computed("inheritedValue", "dimensionData.values.firstObject.value", function () {
      return this.get("inheritedValue") && this.get("dimensionData.values.firstObject.value") === undefined || this.get("dimensionData.values.firstObject.value");
    }),
    hide: false,
    showAllocation: Ember.computed("dimension.allowAllocation", "dimensionData.allocatePerCapita", "isMulti", "fixedLength", "dimensionData.values.firstObject.value", function () {
      return this.get("dimension.allowAllocation") && !this.get("fixedLength") && !this.get("dimensionData.allocatePerCapita") && (this.get("isMulti") || this.get("dimensionData.values.firstObject.allocation") && this.get("dimensionData.values.firstObject.allocation") !== 100);
    }),
    isMulti: Ember.computed("dimensionData.values.length", function () {
      return this.get("dimensionData.values.length") > 1;
    }),
    exclude: Ember.computed("dimensionData.values.@each.value", function () {
      var _this = this;
      var values = (this.get("dimensionData.values") || Ember.A([])).mapBy("value");
      if (this.get("isSWCategory")) {
        var advBenefitsValues = (this.get("addedAdvBenefits") || []).map(function (benefit) {
          if (benefit.value) {
            return benefit.value;
          } else {
            // settings
            return (_this.get("dimension.values") || []).findBy("name", benefit.label).uniqueId;
          }
        });
        values = values.concat(advBenefitsValues);
      }
      return values;
    })
  });
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    capitaTypeOptions: null,
    init: function init() {
      this._super.apply(this, arguments);
      var capitaTypeOptions = [{
        id: "employee",
        text: intl.t("general.text_1695611608836")
      }, {
        id: "contractor",
        text: intl.t("general.text_1695611608826")
      }, {
        id: "all",
        text: intl.t("general.title_1695611598297")
      }];
      this.set("capitaTypeOptions", capitaTypeOptions);
    },
    hidePerCapita: Ember.computed("model.isEmployeeOrContractor", "data.excludeAllocationByHeadcount.length", "model.budgetType", function () {
      var excludeAllocationByHeadcount = this.get("data.excludeAllocationByHeadcount") || [];
      return this.get("model.isEmployeeOrContractor") || excludeAllocationByHeadcount.includes(this.get("model.budgetType"));
    }),
    budgetHasContractors: Ember.computed("budgetService.selectedRoot", function () {
      var result = _const.default.findBudgetsByTypeFast(this.get("store"), this.get("model.absoluteRoot"), "contractor");
      if (!result) {
        this.get("budgetAttributes.dimensions").setEach("allocatePerCapitaType", "employee");
      }
      return result;
    }),
    budgetLine: Ember.computed.alias("model"),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (this.get("budgetService.dimensions") || []).forEach(function (dim) {
        return dim.valuesChanged();
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.get("budgetAttributes.dimensions")) {
        this.set("budgetAttributes.dimensions", []);
      }
    },
    dimensions: Ember.computed("model", "budgetAttributes", "readOnly", "model.budgetType", "budgetService.dimensions.[]", "model.absoluteRoot.dimensions.length", function () {
      var _this2 = this;
      if (!this.get("budgetAttributes") || !this.get("budgetService.dimensions")) {
        return [];
      }
      var d = (this.get("model.absoluteRoot.dimensions") || []).map(function (dimension) {
        // add missing dimensions
        if (!_this2.get("budgetAttributes.dimensions").findBy("dimension", Ember.get(dimension, "uniqueId"))) {
          _this2.get("budgetAttributes.dimensions").pushObject({
            dimension: Ember.get(dimension, "uniqueId"),
            values: []
          });
        }
        var dim = _this2.get("budgetAttributes.dimensions").findBy("dimension", Ember.get(dimension, "uniqueId"));
        var parentDimensions = _this2.get("budgetService.scenario") ? _this2.get("model.parent.forecastAttributesWithScenario.dimensions") : _this2.get("model.parent.forecastAttributes.dimensions");
        var parentData = parentDimensions && parentDimensions.findBy("dimension", Ember.get(dim, "dimension"));
        var placeholder = "";
        if (parentData && Ember.get(parentData, "values.length") && (!dimension.get("isCategory") || _this2.get("model.parent.budgetType") !== "group-payroll")) {
          placeholder = _const.default.getDimensionDisplayValues(parentData, dimension);
        }
        var validValuesList = (Ember.get(dim, "values") || []).filter(function (val) {
          return _dimensions.default.isDimensionValueEmptyAllowInheritance(val) || (Ember.get(dimension, "values") || []).find(function (currVal) {
            return Ember.get(currVal, "uniqueId") === Ember.get(val, "value");
          });
        });
        // if the dimension does not have values, or it holds only empty values, override
        if (!Ember.get(validValuesList, "length")) {
          Ember.set(dim, "values", [{}]);
        }
        var fixedLength = 0,
          labels;
        var advBenefitsWithCategories = [];
        var budgetType = _this2.get("model.budgetType");
        // special handling for categoies of payroll and license
        if (Ember.get(dimension, "isCategory") && ["group-payroll", "sales-perpetual-license"].includes(budgetType)) {
          labels = budgetType === "group-payroll" ? _const.default.DEFAULT_SALARY_CATEGORIES_ARRAY : [intl.t("general.name_1695611602657"), intl.t("general.name_1695611602647")];
          fixedLength = labels.length;
          if (budgetType === "group-payroll") {
            advBenefitsWithCategories = _budgetUtils.default.calcBeneiftsCategory(_this2.get("budgetLine"), dimension, _this2.get("budgetService.scenario"));
          }
          if (Ember.get(dim, "values.length") < fixedLength) {
            var categoriesLabels = labels.map(function (cat) {
              var thisValue = dimension.get("values").findBy("name", cat);
              return {
                value: thisValue && Ember.get(thisValue, "uniqueId"),
                label: cat
              };
            });
            Ember.set(dim, "values", categoriesLabels);
          } else {
            Ember.get(dim, "values").forEach(function (v, idx) {
              Ember.set(v, "label", labels[idx]);
            });
          }
        }
        return DimensionObj.create({
          scenario: _this2.get("budgetService.scenario"),
          readonly: _this2.get("readOnly") ? "readonly" : null,
          dimension: dimension,
          fixedLength: fixedLength,
          dimensionData: dim,
          inheritedValue: placeholder,
          hide: Ember.get(dimension, "isCategory") && _this2.get("model.budgetType").startsWith("employee"),
          addedAdvBenefits: advBenefitsWithCategories,
          isSWCategory: budgetType === "group-payroll" && Ember.get(dimension, "isCategory")
        });
      });
      return d;
    }),
    optionCreateFunction: function optionCreateFunction(data, escape) {
      return '<div class="create">' + intl.t("general.add__1695803188311") + " <strong>" + escape(data.input) + "</strong></div>";
    },
    trackUpdateDimensionValue: function trackUpdateDimensionValue() {
      this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.USER_EDIT_DIMENSION"), {
        edited_on: this.get("model.budgetModule.name")
      });
    },
    actions: {
      createDimensionValue: function createDimensionValue(value, element) {
        var _this3 = this;
        var promise = new Ember.RSVP.Promise(function (resolve) {
          element._selectize.$control_input.val(value);
          var dimension = element.get("dimension");
          var newValue = dimension.addValue(value, {
            callback: function callback() {
              if (_this3.get("inputController")) {
                _this3.get("inputController").send("submit", {
                  shouldCloseFlyout: false
                });
              }
              _this3.get("model").notifyPropertyChange("budgetAttributes"); // notyfing the forecast attributes to be changed
              resolve();
            }
          });
          element.set("value", Ember.get(newValue, "uniqueId"));
        });
        if (this.get("inputController")) {
          this.set("inputController.addDimensionValuePromise", promise);
        }
      },
      updateDimensionValue: function updateDimensionValue() {
        this.trackUpdateDimensionValue();
        this.get("model").notifyPropertyChange("budgetAttributes"); // notyfing the forecast attributes to be changed
      },
      allocatePerCapita: function allocatePerCapita(checked, dimData) {
        Ember.set(dimData, "allocatePerCapita", !checked);
        if (!Ember.get(dimData, "allocatePerCapitaType")) {
          Ember.set(dimData, "allocatePerCapitaType", "employee");
        }
      },
      add: function add(dim) {
        Ember.get(dim, "dimensionData.values").pushObject({
          value: null,
          allocation: 0
        });
        this.get("model").notifyPropertyChange("budgetAttributes"); // notyfing the forecast attributes to be changed
        this.sendAction("changed");
      },
      removeValue: function removeValue(dim, value) {
        Ember.get(dim, "dimensionData.values").removeObject(value);
        if (Ember.get(dim, "dimensionData.values.length") === 0) {
          if (Ember.get(dim, "inheritedValue")) {
            Ember.get(dim, "dimensionData.values").pushObject({
              value: null
            });
          } else {
            Ember.get(dim, "dimensionData.values").pushObject({});
          }
        }
        this.get("model").notifyPropertyChange("budgetAttributes");
        this.sendAction("changed", "dimensions");
      },
      removeAllValues: function removeAllValues(dim) {
        Ember.get(dim, "dimensionData.values").clear();
        Ember.get(dim, "dimensionData.values").pushObject({});
        this.get("model").notifyPropertyChange("budgetAttributes");
        this.sendAction("changed");
      }
    }
  });
});