define("budgeta/components/attribute-autocomplete", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    classNames: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-dropdown-short"],
    attributeBindings: ["data-key", "data-hide", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    thisPlaceholder: Ember.computed("placeholder", function () {
      return this.get("placeholder") || " ";
    }),
    actions: {
      autocompleteQuery: function autocompleteQuery(query, deferred) {
        var result = Ember.A([]);
        var key = Ember.$(query.target || query.element).closest("[data-key]").data("key");
        if (!key) {
          return result;
        }
        var allData = [].addObjects(this.get("budgetService.autocompleteOptions.".concat(key)).map(function (v) {
          return Ember.get(v, "id.name") || Ember.get(v, "id");
        }));
        if (query.term) {
          var found = allData.find(function (s) {
            return s && s.toLowerCase() === query.term.toLowerCase();
          });
          if (found) {
            return deferred.resolve([{
              id: found,
              text: found
            }]);
          }
          result.pushObject({
            id: query.term,
            text: query.term,
            description: intl.t("general.attribute-autocomplete-1", {
              var1: key
            })
          });
        }
        deferred.resolve(result.pushObjects(allData.filter(function (s) {
          return Ember.isEmpty(query.term) || s.toLowerCase().indexOf(query.term.toLowerCase()) >= 0;
        }).map(function (s) {
          return {
            id: s,
            text: s
          };
        })));
      }
    }
  });
});