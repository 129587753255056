define("budgeta/controllers/main/budget/import-actuals", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/services/ember-service-container", "budgeta/controllers/main/budget/import", "budgeta/utils/import", "budgeta/utils/const", "lodash/lodash", "budgeta/utils/import-actuals", "budgeta/utils/assumption"], function (_exports, _objectSpread2, _emberServiceContainer, _import, _import2, _const, _lodash, _importActuals, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/no-side-effects, ember/no-observers, no-useless-escape */

  var TemplateDataType = Ember.Object.extend({
    value: null,
    name: null,
    dataType: "",
    statement: null,
    noMultiChangeStatement: false,
    dataTypeForBSStatement: null,
    allowCategoryMap: false,
    mapByDepartment: false,
    nameColumn: null,
    accountColumn: null,
    extractAccountFromName: false,
    columnMapping: null,
    defaultMapToRoot: false,
    noReverseBSLiabilities: false,
    reverseExpenses: false,
    reverseRevenues: false,
    reverseAll: false,
    showFiscalDate: false,
    autoFillFiscalDate: false,
    headerLineMatch: null,
    accountLine: null,
    trxDateInLine: false,
    isMonthOrderReversed: false,
    headerLineFirstValue: null,
    hideShowHiddenCheckbox: false,
    hideCurrencyOption: false,
    mandatoryFields: {},
    notImportableCurrencyLines: false
  });
  var _default = _exports.default = _import.default.extend({
    currentStepLines: Ember.computed("currentStep", function () {
      var _this2 = this;
      var linesByStep = {
        0: function _() {
          return _this2.get("sheetArrayForDisplay");
        },
        1: function _() {
          return _this2.get("sheetArrayForDisplay");
        },
        2: function _() {
          return _this2.get("lineNamesForMappingView");
        },
        3: function _() {
          return _this2.get("lineNamesForMappingView");
        },
        4: function _() {
          return _this2.get("lineNamesForFinalPreviewActuals");
        }
      };
      return linesByStep[this.get("currentStep")]();
    }),
    stepsName: Ember.computed("intl", function () {
      return [{
        index: 1,
        title: this.get("intl").t("import-steps.actuals-step1")
      }, {
        index: 2,
        title: this.get("intl").t("import-steps.step1")
      }, {
        index: 3,
        title: this.get("intl").t("import-steps.step3")
      }, {
        index: 4,
        title: this.get("intl").t("import-steps.step2")
      }, {
        index: 5,
        title: this.get("intl").t("import-steps.step4")
      }];
    }),
    columnAttributesByOrder: Ember.computed(function () {
      return [{
        value: "accountId",
        name: this.get("intl").t("actuals.actualsAttributes.account"),
        constColumn: true
      }, {
        value: "amount",
        name: this.get("intl").t("actuals.actualsAttributes.amount"),
        templatesBelong: new Set(["excel-trx", "excel-balances", "model-trx", "model-balances", "trx", "balances", "bs", "pnl", "qbpnl-1", "qbpnl", "qbtrx", "qbbs", "xerobs", "xerois", "xeropnlf", "sap", "sapje", "amex", "priority-tbm", "priority-tb", "priority-natb", "priority-monthly", "rivhit-tbm", "rivhit-tb"])
      }, {
        value: "currency",
        name: this.get("intl").t("actuals.actualsAttributes.currency"),
        templatesBelong: new Set(["excel-trx", "excel-balances"])
      }, {
        value: "fiscalDate",
        name: this.get("intl").t("actuals.actualsAttributes.fiscalDate"),
        templatesBelong: new Set(["hashavshevet"])
      }, {
        value: "trxDate",
        name: this.get("intl").t("actuals.actualsAttributes.trxDate"),
        templatesBelong: new Set(["qbtrx", "sapje", "amex", "priority-monthly", "hashavshevet"])
      }, {
        value: "credit",
        name: this.get("intl").t("actuals.actualsAttributes.credit"),
        templatesBelong: new Set(["qbtb", "hashavshevet", "nstb"])
      }, {
        value: "debit",
        name: this.get("intl").t("actuals.actualsAttributes.debit"),
        templatesBelong: new Set(["qbtb", "hashavshevet", "nstb"])
      }, {
        value: "books",
        name: this.get("intl").t("actuals.actualsAttributes.books"),
        constColumn: true
      }, {
        value: "note",
        name: this.get("intl").t("actuals.actualsAttributes.comments"),
        constColumn: true
      }, {
        value: "trxId",
        name: this.get("intl").t("actuals.actualsAttributes.trxId"),
        constColumn: true
      }];
    }),
    isModelImport: Ember.computed("dataType", function () {
      return new Set(["model-trx", "model-balances"]).has(this.get("dataType"));
    }),
    dataTypes: Ember.computed("intl", function () {
      var templates = [TemplateDataType.create({
        value: "excel-trx",
        name: this.get("intl").t("actuals.templates.excel-transactions"),
        dataType: "trx",
        allowCategoryMap: true,
        noMultiChangeStatement: true,
        reverseRevenues: true,
        hideShowHiddenCheckbox: true,
        hideCurrencyOption: true,
        mandatoryFields: {
          currency: true,
          accountId: true,
          amount: true
        },
        notImportableCurrencyLines: true
      }), TemplateDataType.create({
        value: "excel-balances",
        name: this.get("intl").t("actuals.templates.excel-balances"),
        dataType: "balance",
        allowCategoryMap: true,
        noMultiChangeStatement: true,
        hideShowHiddenCheckbox: true,
        hideCurrencyOption: true,
        mandatoryFields: {
          currency: true,
          accountId: true,
          amount: true
        },
        notImportableCurrencyLines: true
      })];
      templates.push(TemplateDataType.create({
        value: "model-trx",
        name: this.get("intl").t("actuals.templates.model-transactions"),
        dataType: "trx",
        allowCategoryMap: false,
        noMultiChangeStatement: true,
        reverseRevenues: true,
        hideShowHiddenCheckbox: true,
        hideCurrencyOption: true,
        statement: "stat",
        mandatoryFields: {
          accountId: true,
          amount: true
        }
      }));
      templates.push(TemplateDataType.create({
        value: "model-balances",
        name: this.get("intl").t("actuals.templates.model-balances"),
        dataType: "balance",
        allowCategoryMap: false,
        noMultiChangeStatement: true,
        reverseRevenues: true,
        hideShowHiddenCheckbox: true,
        hideCurrencyOption: true,
        statement: "stat",
        mandatoryFields: {
          accountId: true,
          amount: true
        }
      }));
      templates = templates.concat([TemplateDataType.create({
        value: "trx",
        name: this.get("intl").t("actuals.templates.generic-transactions"),
        dataType: "trx",
        allowCategoryMap: true
      }), TemplateDataType.create({
        value: "balances",
        name: this.get("intl").t("actuals.templates.generic-balances"),
        dataType: "balance",
        allowCategoryMap: true
      }), TemplateDataType.create({
        value: "bs",
        name: this.get("intl").t("actuals.templates.generic-balance-sheet"),
        dataType: "balance",
        statement: "bs",
        allowCategoryMap: false,
        nameColumn: ["B"],
        accountColumn: ["Account", "A"],
        noReverseBSLiabilities: true
      }), TemplateDataType.create({
        value: "pnl",
        name: this.get("intl").t("actuals.templates.generic-profit-loss"),
        dataType: "trx",
        statement: "pnl",
        reverseExpenses: true,
        extractAccountFromName: true
      }), TemplateDataType.create({
        value: "qbpnl-1",
        name: this.get("intl").t("actuals.templates.quickbooks-profit-loss"),
        dataType: "trx",
        statement: "pnl",
        extractAccountFromName: true,
        reverseExpenses: true
      }), TemplateDataType.create({
        value: "qbpnl",
        name: this.get("intl").t("actuals.templates.quickbooks-profit-loss-by-class"),
        dataType: "trx",
        statement: "pnl",
        showFiscalDate: true,
        autoFillFiscalDate: "A3",
        mapByDepartment: true
      }), TemplateDataType.create({
        value: "qbtrx",
        name: this.get("intl").t("actuals.templates.quickbooks-transactions-by-class"),
        dataType: "trx",
        statement: "cash",
        nameColumn: [intl.t("general.label_1695611611893")],
        columnMapping: {
          amount: ["Amount"],
          trxDate: ["Date"]
        },
        headerLineMatch: {
          col: "B",
          value: "Date"
        },
        accountLine: {
          columnIndex: 0,
          condition: [{
            columnIndex: 0,
            isDate: false
          }]
        },
        trxDateInLine: true,
        extractAccountFromName: true,
        showFiscalDate: false,
        reverseAll: true
      }), TemplateDataType.create({
        value: "qbtb",
        name: this.get("intl").t("actuals.templates.quickbooks-trial-balance"),
        dataType: "balance",
        statement: "pnl",
        columnMapping: {
          credit: ["Credit"],
          debit: ["Debit"]
        },
        headerLineMatch: {
          col: "B",
          value: "Debit"
        },
        noMultiChangeStatement: true,
        extractAccountFromName: true,
        showFiscalDate: true,
        reverseAll: true
      }), TemplateDataType.create({
        value: "qbbs",
        name: this.get("intl").t("actuals.templates.quickbooks-balance-sheet"),
        dataType: "balance",
        statement: "pnl",
        extractAccountFromName: true,
        showFiscalDate: true,
        columnMapping: {
          amount: ["Total"]
        },
        headerLineMatch: {
          col: "B",
          value: "Total"
        },
        autoFillFiscalDate: "A3",
        noReverseBSLiabilities: true
      }), TemplateDataType.create({
        value: "xerobs",
        name: this.get("intl").t("actuals.templates.xero-balance-sheet"),
        dataType: "balance",
        statement: "bs",
        accountColumn: ["A"],
        columnMapping: {
          amount: ["B"]
        },
        headerLineMatch: {
          col: "B",
          value: "*"
        }
      }), TemplateDataType.create({
        value: "xerois",
        name: this.get("intl").t("actuals.templates.xero-income-statement"),
        dataType: "balance",
        statement: "pnl",
        reverseExpenses: true
      }), TemplateDataType.create({
        value: "xeropnlf",
        name: this.get("intl").t("actuals.templates.xero-profit-loss-by-category"),
        dataType: "trx",
        statement: "pnl",
        reverseExpenses: true,
        showFiscalDate: true,
        autoFillFiscalDate: "A4",
        mapByDepartment: true,
        headerLineMatch: {
          col: "B",
          value: "*"
        }
      }), TemplateDataType.create({
        value: "sap",
        name: this.get("intl").t("actuals.templates.sap-trial-balance-by-month"),
        dataType: "trx",
        nameColumn: [intl.t("general.text_1695611609974"), intl.t("general.account_name_1698234256549"), intl.t("general.description_1698234256549")],
        accountColumn: ["Account"],
        reverseAll: true,
        noMultiChangeStatement: true,
        statement: "pnl"
      }), TemplateDataType.create({
        value: "sapje",
        name: this.get("intl").t("actuals.templates.sap-journal-entries"),
        dataType: "trx",
        nameColumn: ["הערות"],
        columnMapping: {
          amount: ["חובה/זכות (מערכת)"],
          trxDate: ["תאריך ערך"]
        },
        accountLine: {
          columnIndex: 1,
          condition: [{
            columnIndex: 0,
            isDate: false
          }]
        },
        reverseAll: true,
        trxDateInLine: true,
        statement: "pnl"
      }), TemplateDataType.create({
        value: "amex",
        name: this.get("intl").t("actuals.templates.amex-transactions"),
        dataType: "trx",
        allowCategoryMap: true,
        nameColumn: [intl.t("general.description_1698234256549")],
        columnMapping: {
          trxDate: ["Date"],
          amount: ["Amount"],
          category: ["Category"]
        },
        reverseAll: true,
        trxDateInLine: true,
        statement: "cash",
        defaultMapToRoot: true
      }), TemplateDataType.create({
        value: "priority-tbm",
        name: this.get("intl").t("actuals.templates.priority-trial-balance-by-month"),
        dataType: "trx",
        nameColumn: ["תאור חשבון", "תאור החשבון"],
        accountColumn: ["חשבון"],
        reverseAll: true,
        noMultiChangeStatement: true,
        statement: "pnl"
      }), TemplateDataType.create({
        value: "priority-tb",
        name: this.get("intl").t("actuals.templates.priority-trial-balance"),
        dataType: "balance",
        nameColumn: ["תאור חשבון", "תאור החשבון"],
        accountColumn: ["חשבון"],
        reverseAll: true,
        noMultiChangeStatement: true,
        statement: "pnl",
        headerLineMatch: {
          col: "A",
          value: "*"
        }
      }), TemplateDataType.create({
        value: "priority-natb",
        name: this.get("intl").t("actuals.templates.priority-non-accumulative-trial-balance"),
        dataType: "trx",
        dataTypeForBSStatement: "balance",
        nameColumn: ["תאור חשבון", "תאור החשבון"],
        accountColumn: ["ACCNAME", "חשבון"],
        reverseAll: true,
        noMultiChangeStatement: true,
        statement: "pnl",
        headerLineMatch: {
          col: "A",
          value: "*"
        }
      }), TemplateDataType.create({
        value: "priority-monthly",
        name: this.get("intl").t("actuals.templates.priority-monthly-budget-usage"),
        dataType: "trx",
        nameColumn: ["תאור תקציב"],
        accountColumn: ["סעיף"],
        trxDateInLine: true,
        columnMapping: {
          trxDate: ["חודש"],
          amount: ["ניצול"]
        },
        reverseAll: true,
        statement: "pnl"
      }), TemplateDataType.create({
        value: "rivhit-tbm",
        name: this.get("intl").t("actuals.templates.rivhit-trial-balance-by-month"),
        dataType: "trx",
        nameColumn: ["BA"],
        accountColumn: ["BC"],
        headerLineFirstValue: 'סה"כ יתרה',
        reverseAll: true,
        noMultiChangeStatement: true,
        statement: "pnl",
        isMonthOrderReversed: true
      }), TemplateDataType.create({
        value: "rivhit-tb",
        name: this.get("intl").t("actuals.templates.rivhit-trial-balance"),
        dataType: "balance",
        nameColumn: ["כרטיס חשבון"],
        accountColumn: ["T"],
        columnMapping: {
          amount: ["יתרה"]
        },
        headerLineFirstValue: "יתרה",
        reverseAll: true,
        noMultiChangeStatement: true,
        statement: "pnl",
        showFiscalDate: true
      }), TemplateDataType.create({
        value: "hashavshevet",
        name: this.get("intl").t("actuals.templates.hashavshevet-transactions"),
        dataType: "trx",
        nameColumn: ["פרטים"],
        accountColumn: ["מפתח חשבון"],
        columnMapping: {
          fiscalDate: ["ת.אסמ"],
          trxDate: ["ת.ערך"],
          credit: ['חובה/זכות(מט"ח) זכות'],
          debit: ['חובה/זכות(מט"ח) חובה']
        },
        headerLineMatch: {
          col: "D",
          value: "*"
        },
        statement: "both",
        trxDateInLine: true
      }), TemplateDataType.create({
        value: "nstb",
        name: this.get("intl").t("actuals.templates.netsuite-trial-balance"),
        dataType: "balance",
        columnMapping: {
          credit: ["Credit"],
          debit: ["Debit"]
        },
        headerLineMatch: {
          col: "B",
          value: "*"
        },
        statement: "pnl",
        showFiscalDate: true,
        extractAccountFromName: true,
        autoFillFiscalDate: "A3"
      })]);
      return templates;
    }),
    dataTypeData: Ember.computed("dataType", function () {
      return this.get("dataTypes").findBy("value", this.get("dataType"));
    }),
    dataType: "excel-trx",
    statementOptions: Ember.computed("budgetService.pnlName", "isModelImport", function () {
      var pnlName = this.get("budgetService.pnlName");
      var options;
      if (this.get("isModelImport")) {
        options = [{
          id: "stat",
          text: intl.t("general.name_1695611604096")
        }];
      } else {
        options = [{
          id: "pnl",
          text: pnlName
        }, {
          id: "cash",
          text: intl.t("general.name_1695611602487")
        }, {
          id: "both",
          text: intl.t("general.import-actuals-1", {
            var1: pnlName
          })
        }, {
          id: "bs",
          text: intl.t("general.name_1695611604193")
        }];
      }
      return options;
    }),
    categoryOptions: Ember.computed("budgetService.categoryDimension.values.@each.displayName", function () {
      return (this.get("budgetService.categoryDimension.values") || []).map(function (value) {
        return {
          id: Ember.get(value, "uniqueId"),
          value: Ember.get(value, "name"),
          text: Ember.get(value, "displayName")
        };
      });
    }),
    qbPnl: Ember.computed.equal("dataType", "qbpnl"),
    showFiscalDate: Ember.computed.alias("dataTypeData.showFiscalDate"),
    mapByCategoryAllowed: Ember.computed.alias("dataTypeData.allowCategoryMap"),
    mapByDepartment: Ember.computed.alias("dataTypeData.mapByDepartment"),
    noMultiChangeStatement: Ember.computed.alias("dataTypeData.noMultiChangeStatement"),
    attributeListActuals: Ember.computed("dataType", "dataTypeData", "budgetService.dimensions.length", "mapCategories", function () {
      var _this3 = this;
      var templateType = this.get("dataType");
      var columnAttributes = this.get("columnAttributesByOrder").reduce(function (attributes, attribute) {
        if (attribute.constColumn || attribute.templatesBelong.has(templateType)) {
          attributes.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, attribute), {}, {
            id: attribute.value
          }));
        }
        return attributes;
      }, []);
      if (this.get("budgetService.dimensions.length")) {
        columnAttributes.pushObjects(this.get("budgetService.dimensions").reduce(function (dimensions, dimension) {
          if (!dimension.get("isCategory") || _this3.get("mapCategories")) {
            dimensions.push({
              id: "dimensions.".concat(dimension.get("uniqueId")),
              name: dimension.get("name"),
              data: {
                isDimension: true
              }
            });
          }
          return dimensions;
        }, []));
      }
      return columnAttributes.map(function (column) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, column), {}, {
          text: column.name
        });
      });
    }),
    maxEndDate: Ember.computed("budgetService.selectedRoot.absoluteRoot.budgetAttributes.endDate", function () {
      return new Date(_const.default.strToDate(this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.endDate"))).addMonths(1);
    }),
    isPayrollLineExist: Ember.computed("lineNamesForMappingView.@each.isPayroll", function () {
      return this.get("lineNamesForMappingView").any(function (line) {
        return line.get("isPayroll");
      });
    }),
    isCategoryDimensionMapped: Ember.computed("currentTypeMappings.@each.selectedAttribute", "currentTypeMappings.@each.hide", function () {
      var categoryUniqueId = "dimensions.".concat(this.get("budgetService.categoryDimension.uniqueId"));
      return this.get("currentTypeMappings").some(function (mapping) {
        return mapping.col.selectedAttribute === categoryUniqueId && !mapping.col.hide;
      });
    }),
    categoryHeaderKey: Ember.computed("isCategoryDimensionMapped", "currentTypeMappings.@each.selectedAttribute", "currentTypeMappings.@each.hide", function () {
      if (this.get("isCategoryDimensionMapped")) {
        var categoryUniqueId = "dimensions.".concat(this.get("budgetService.categoryDimension.uniqueId"));
        var mapping = this.get("currentTypeMappings").find(function (mapping) {
          return mapping.col.selectedAttribute === categoryUniqueId;
        });
        return mapping.col.key;
      }
      return "category";
    }),
    lastAmountColumnKey: Ember.computed("currentTypeMappings", "currentTypeMappings.@each.hide", "currentTypeMappings.@each.selectedAttribute", function () {
      var amountKeys = this.get("currentTypeMappings").reduce(function (keys, mapping) {
        if (mapping.col.selectedAttribute === "amount" && !mapping.col.hide) {
          keys.push(mapping.col.key);
        }
        return keys;
      }, []);
      return amountKeys[amountKeys.length - 1];
    }),
    previewHeaderColumns: Ember.computed("currentTypeMappings", "currentTypeMappings.@each.hide", "currentTypeMappings.@each.selectedAttribute", function () {
      var _this4 = this;
      if (this.get("isModelImport")) {
        // Need to change budget to model
        return this.get("currentTypeMappings").reduce(function (columns, column) {
          if (!column.get("col.hide")) {
            columns.push(column);
          }
          if (column.get("col.key") === _this4.get("lastAmountColumnKey")) {
            var unitTypeColumn = _import2.default.HeaderColumn.create({
              col: _import2.default.createHeader("unitType", _this4.get("intl").t("actuals.headers.unit-type")),
              ctl: _this4
            });
            columns.push(unitTypeColumn);
          }
          return columns;
        }, []);
      }
      return this.get("currentTypeMappings");
    }),
    headerColumnsWithMapping: Ember.computed("currentTypeMappings", "currentTypeMappings.@each.hide", "currentTypeMappings.@each.selectedAttribute", "mapByDepartment", "mapCategories", "isPayrollLineExist", function () {
      var _this5 = this;
      var headers = this.get("currentTypeMappings").map(function (mapping) {
        return _import2.default.HeaderColumn.create({
          col: mapping.col,
          ctl: _this5
        });
      });
      if (!this.get("mapByDepartment") && (this.get("mapCategories") && !this.get("isCategoryDimensionMapped") || this.get("isPayrollLineExist"))) {
        headers.unshift(_import2.default.HeaderColumn.create({
          col: _import2.default.createHeader("category", this.get("intl").t("actuals.headers.category")),
          ctl: this
        }));
      }
      headers.unshift(_import2.default.HeaderColumn.create({
        col: _import2.default.createHeader("actualType", this.get("intl").t("actuals.headers.actual-type")),
        ctl: this
      }));
      if (!this.get("mapByDepartment")) {
        headers.unshift(_import2.default.HeaderColumn.create({
          col: _import2.default.createHeader("budgetLine", this.get("isModelImport") ? this.get("intl").t("actuals.headers.model-line") : this.get("intl").t("actuals.headers.budget-line")),
          ctl: this
        }));
      }
      return headers;
    }),
    addMappingColumnsForLines: function addMappingColumnsForLines() {
      var _this6 = this;
      var addMappingColumns = function addMappingColumns(line) {
        var addedValues = [];
        if (!_this6.get("mapByDepartment") && (_this6.get("mapCategories") || line.get("isPayroll"))) {
          if (_this6.get("mapCategories") && !_this6.get("isCategoryDimensionMapped")) {
            addedValues.unshift({
              derivedAttribute: "category",
              headerKey: _this6.get("categoryHeaderKey")
            });
          }
        } else if (_this6.get("isPayrollLineExist")) {
          addedValues.unshift({
            empty: true
          });
        }
        addedValues.unshift({
          derivedAttribute: "statement",
          headerKey: "actualType"
        });
        if (!_this6.get("mapByDepartment")) {
          addedValues.unshift({
            derivedAttribute: line.get("isBalanceSheet") ? "bsLine" : "mapping",
            headerKey: "budgetLine"
          });
        }
        Ember.set(line, "showValues", addedValues.concat(line.showValues));
      };
      var lines = this.get("lineNamesForMappingView");
      var currentTypeMappings = this.get("currentTypeMappings");
      var mainCurrency = this.get("model.absoluteRoot.budgetAttributes.currency");
      lines.forEach(function (line) {
        if (_this6.get("dataTypeData.notImportableCurrencyLines")) {
          line.currency = (_import2.default.getColumnCurrency(currentTypeMappings, line) || mainCurrency).toUpperCase();
          var notImportable = _this6.get("model.absoluteRoot.fxCurrencies").includes(line.currency) ? false : true;
          line.set("notImportable", notImportable);
          if (notImportable) {
            Ember.set(line, "selectedFirst", false);
          }
        }
        addMappingColumns(line);
      });
      if (this.get("dataTypeData.notImportableCurrencyLines") && lines.findBy("notImportable", true)) {
        this.send("showNotExistedCurrencyWarning");
      }
    },
    storageKey: Ember.computed("file.name", "selectedSheet", "importType", "dataType", "mainController.selectedRoot.id", function () {
      return "".concat(this.get("mainController.selectedRoot.id"), "_").concat(this.get("importType") + "_").concat(this.get("file.name"), "_").concat(this.get("selectedSheet"), "_").concat(this.get("dataType"));
    }),
    budgetLinesForMapping: Ember.computed("budgetLines", function () {
      var lines = this.get("budgetLines");
      if (!lines || !lines.length) return [];
      return lines.map(function (line) {
        return {
          id: line.get("id"),
          text: line.get("name")
        };
      });
    }),
    dateHeaderKeys: Ember.computed("currentTypeMappings", "currentTypeMappings.@each.hide", "currentTypeMappings.@each.selectedAttribute", function () {
      return (this.get("currentTypeMappings") || []).reduce(function (keys, column) {
        if (column.get("col.isDate") && !column.get("col.hide")) {
          keys.add(column.get("col.key"));
        }
        return keys;
      }, new Set());
    }),
    lineNamesForFinalPreviewActuals: Ember.computed("step5", function () {
      var _this7 = this;
      var getPreviewLines = function getPreviewLines(lines) {
        var getAmountColumnsKeysMap = function getAmountColumnsKeysMap() {
          var amountColumnsKeysMap = {};
          _this7.get("currentTypeMappings").forEach(function (mapping) {
            if (mapping.col.selectedAttribute === "amount" && !mapping.col.hide) {
              amountColumnsKeysMap[mapping.col.key] = mapping.col;
            }
          });
          return amountColumnsKeysMap;
        };
        var flipRevenue = function flipRevenue(line) {
          var newPreviewValues = [];
          line.previewValues.forEach(function (previewValue) {
            var newPreviewValue = previewValue;
            var numericCellValue = _import2.default.getCellNumbericValue(previewValue.value);
            if (amountColumnsKeysMap[previewValue.headerKey] && numericCellValue) {
              var showValueClone = JSON.parse(JSON.stringify(previewValue));
              showValueClone.value.v = Math.round(-numericCellValue);
              showValueClone.value.w = "" + -Math.round(-numericCellValue);
              newPreviewValue = showValueClone;
            }
            newPreviewValues.push(newPreviewValue);
          });
          line.previewValues = newPreviewValues;
        };
        var parseAmountWithCurrency = function parseAmountWithCurrency(previewValue, currency) {
          var value = previewValue.value.v || previewValue.value.w || "0";
          return _const.default.formatAmount(typeof value === "string" ? value.replace(/[^0-9\.\-]/g, "") : value, currency);
        };
        var amountColumnsKeysMap = getAmountColumnsKeysMap();
        var result = lines.reduce(function (filtered, line) {
          if (line.selectedFirst) {
            var lineShowValues = line.showValues.reduce(function (values, value) {
              values.push(value);
              if (_this7.get("isModelImport") && value.headerKey === _this7.get("lastAmountColumnKey")) {
                var unitTypeValue;
                var lineMappingBudget = _this7.get("store").peekRecord("budget", line.mapping);
                unitTypeValue = _assumption.default.getUnitTypeForMapping(lineMappingBudget, _this7.get("store"), _this7.get("cache"), _this7.get("budgetService.scenario"));
                unitTypeValue = unitTypeValue === "." ? "#.##" : unitTypeValue;
                values.push({
                  headerKey: "unitType",
                  value: {
                    headerKey: "unitType",
                    w: unitTypeValue,
                    v: unitTypeValue
                  }
                });
              }
              return values;
            }, []);
            var newPreviewline = _import2.default.Item.create({
              ctl: _this7,
              mapping: line.mapping,
              statement: line.statement,
              value: line.value,
              currency: line.currency,
              previewValues: lineShowValues,
              store: line.store
            });
            if (!_this7.get("isModelImport")) {
              newPreviewline.category = line.get("category");
              if (_this7.get("dataTypeData.reverseRevenues") && line.mapping) {
                var record = line.store.peekRecord("budget", line.mapping);
                if (record.get("isRevenue")) {
                  flipRevenue(newPreviewline);
                }
              }
              newPreviewline.previewValues.forEach(function (previewValue) {
                if (previewValue.value && (_this7.get("dateHeaderKeys") || new Set()).has(previewValue.headerKey)) {
                  Ember.set(previewValue, "value.w", parseAmountWithCurrency(previewValue, line.currency));
                }
              });
            }
            filtered.push(newPreviewline);
          }
          return filtered;
        }, []);
        return result;
      };
      var lines = this.get("lineNames") || [];
      return getPreviewLines(lines);
    }),
    balanceSheetLines: Ember.computed(function () {
      return _const.default.BALANCE_SHEET_LINES.map(function (item) {
        return {
          id: item.name,
          text: item.displayName,
          name: item.displayName
        };
      });
    }),
    emptyMappingNum: Ember.computed("lineNames.@each.selectedFirst", "lineNames.@each.mapping", function () {
      var selected = this.get("lineNames").filter(function (line) {
        return Ember.get(line, "selectedFirst") && Ember.get(line, "statement") !== "bs" && !Ember.get(line, "empty");
      });
      return selected.length - selected.filterBy("mapping").length;
    }),
    emptyCategoryNum: Ember.computed("lineNames.@each.selectedFirst", "lineNames.@each.category", "mapCategories", function () {
      if (!this.get("mapCategories")) {
        return 0;
      }
      var selected = this.get("lineNames").filter(function (line) {
        return Ember.get(line, "selectedFirst") && !Ember.get(line, "empty");
      });
      return selected.length - selected.filterBy("category").length;
    }),
    mapCategoriesObserver: Ember.observer("mapCategories", function () {
      var _this8 = this;
      var removeCategoryMapping = function removeCategoryMapping(_this) {
        var category = _this.get("budgetService.dimensions").findBy("type", "category");
        var mappedCategory = category && _this.get("currentTypeMappings").findBy("col.selectedAttribute", "dimensions.".concat(Ember.get(category, "uniqueId")));
        if (!_this.get("mapCategories") && mappedCategory) {
          _this.send("clearSelectedAttribute", mappedCategory.col);
        }
      };
      if (this.get("dataTypeData.columnMapping.category")) {
        var thisColumn = this.get("currentTypeMappings").find(function (item) {
          return _this8.get("dataTypeData.columnMapping.category").contains(Ember.get(item, "col.value.w"));
        });
        if (thisColumn) {
          Ember.setProperties(thisColumn, {
            selectedAttribute: "category",
            hide: false
          });
        }
      }
      if (this.get("lineNames")) {
        _import2.default.matchLines(this, this.get("sheet"));
      }
      removeCategoryMapping(this);
    }),
    actions: {
      gotoStep: function gotoStep(stepIndex) {
        var _this9 = this;
        var sendPendoEvent = function sendPendoEvent(stepIndex, stepForward) {
          var sendNextStepEvent = function sendNextStepEvent() {
            var stepName = _this9.get("stepsName").find(function (step) {
              return step.index === stepIndex;
            });
            stepName && _this9.get("trackingEvents").trackEvent(_this9.get("trackingEvents.events.IMPORT_ACTUALS_FROM_EXCEL.IMPORT_ACTUALS_NEXT_SCREEN_X"), {
              nameParameters: [stepName.title]
            });
          };
          var sendBackStepEvent = function sendBackStepEvent() {
            var stepName = _this9.get("stepsName").find(function (step) {
              return step.index === stepIndex + 2;
            });
            stepName && _this9.get("trackingEvents").trackEvent(_this9.get("trackingEvents.events.IMPORT_ACTUALS_FROM_EXCEL.IMPORT_ACTUALS_BACK_SCREEN_X"), {
              nameParameters: [stepName.title]
            });
          };
          var sendStepEvent = function sendStepEvent() {
            stepForward ? sendNextStepEvent() : sendBackStepEvent();
          };
          var pendoEventByStep = {
            1: function _() {
              if (stepForward) {
                _this9.get("trackingEvents").trackEvent(_this9.get("trackingEvents.events.IMPORT_ACTUALS_FROM_EXCEL.IMPORT_ACTUALS_TEMPLATE_X"), {
                  nameParameters: [_this9.get("dataTypeData.name")]
                });
              }
            },
            3: function _() {
              if (stepForward) {
                if (_this9.get("mapCategories")) {
                  _this9.get("trackingEvents").trackEvent(_this9.get("trackingEvents.events.IMPORT_ACTUALS_FROM_EXCEL.IMPORT_ACTUALS_MAP_BY_CATEGORY"));
                }
              }
            }
          };
          sendStepEvent();
          pendoEventByStep[stepIndex] && pendoEventByStep[stepIndex]();
        };
        var isFiscalDateRequired = this.get("dataTypeData.showFiscalDate");
        var isMovingStep1ToStep2 = this.get("step1") && stepIndex === 1;
        var isMovingStep2ToStep3 = this.get("step2") && stepIndex === 2;
        var isMovingStep3ToStep4 = this.get("step3") && stepIndex === 3;
        var isMovingStep4ToStep5 = this.get("step4") && stepIndex === 4;
        var isFiscalDateSelected = this.get("fiscalDate");
        var isStepForward = isMovingStep1ToStep2 || isMovingStep2ToStep3 || isMovingStep3ToStep4 || isMovingStep4ToStep5;
        Ember.$.noty.closeAll();
        if (isFiscalDateRequired && isMovingStep1ToStep2 && !isFiscalDateSelected) {
          return this.get("modal").show({
            type: "warning",
            text: "<h1>".concat(this.get("intl").t("import-all.warning-msg.fiscal-not-selected"), "</h1>"),
            selector: ".import-step .numbered-steps .month-picker",
            backupSelector: this.get("backupImportClass")
          });
        }
        if (isMovingStep2ToStep3) {
          // save sheet selections in local storage
          _const.default.localStorageSetItem(this.get("storageKey"), JSON.stringify({
            headerLocation: this.get("headerLocation"),
            nameColumn: this.get("nameColumn")
          }));
        }
        // verify that there are selected rows
        if (isMovingStep4ToStep5) {
          if (!this.get("lineNames") || this.get("lineNames").filterBy("selectedFirst").length === 0) {
            return this.get("modal").show({
              type: "warning",
              text: "<h1>".concat(this.get("intl").t("import-all.warning-msg.lines-not-selected"), "</h1>"),
              selector: ".import-step .step-header",
              backupSelector: ".import-top"
            });
          }
          if (!this.get("mapByDepartment") && this.get("emptyMappingNum") !== 0) {
            return this.get("modal").show({
              type: "warning",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.actuals-mapped-selected"), "</h1>"),
              selector: ".ui-selected span:contains('Map to')",
              backupSelector: this.get("backupImportClass"),
              scrollIntoView: true
            });
          } else if (!this.get("mapByDepartment") && this.get("emptyCategoryNum") !== 0) {
            return this.get("modal").show({
              type: "warning",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.actuals-category-selected"), "</h1>"),
              selector: ".numbered-steps label",
              backupSelector: this.get("backupImportClass")
            });
          }
          // check the balace sheet data is balanced
          if (this.get("checkBalances")) {
            var balance = _import2.default.getBalances(this.get("lineNames"), this.get("currentTypeMappings"));
            if (Math.abs(balance.assets + (this.get("dataTypeData.noReverseBSLiabilities") ? 1 : -1) * balance.liabilities) > 1) {
              this.set("checkBalances", false);
              return this.get("modal").show({
                type: "error",
                text: "".concat(this.get("intl").t("actuals.warning-msg.not-balanced-title"), "<p>").concat(this.get("intl").t("actuals.warning-msg.not-balanced-body1", {
                  assetsAmount: _const.default.formatCurrency(balance.assets, this.get("currency"), 0, true)
                }), "<br>").concat(this.get("intl").t("actuals.warning-msg.not-balanced-body2", {
                  equityAmount: _const.default.formatCurrency(balance.liabilities, this.get("currency"), 0, true)
                }), "</p>"),
                selector: ".import-step .step-header",
                backupSelector: this.get("backupImportClass"),
                controller: this,
                cancelLabel: intl.t("general.dismiss_1698234256549"),
                okLabel: intl.t("general.check_again_1698234256549"),
                okAction: "checkBalances"
              });
            }
          }
        } else if (isMovingStep3ToStep4) {
          // verify there are no duplicate dates
          var selectedDates = this.get("currentTypeMappings").filter(function (item) {
            return !Ember.get(item, "col.hide") && Ember.get(item, "selectedMonth");
          }).map(function (item) {
            return _const.default.formatDate(item.get("selectedMonth"), true);
          });
          if (selectedDates.length > _lodash.default.uniq(selectedDates).length) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("import-all.popup-duplicate-dates"), "</h1>"),
              selector: ".import-preview .scroll-list.fixed.main.top",
              backupSelector: this.get("backupImportClass")
            });
          }
        }
        if (isMovingStep3ToStep4) {
          if (!this.get("currentTypeMappings").find(function (item) {
            return !Ember.get(item, "col.hide");
          })) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.min-column-select"), "</h1>"),
              selector: ".import-preview .scroll-list.fixed.main.top",
              backupSelector: this.get("backupImportClass")
            });
          }
          // make sure all selected columns are mapped
          if (this.get("currentTypeMappings").find(function (item) {
            return !Ember.get(item, "col.hide") && !Ember.get(item, "col.selectedAttribute");
          })) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.map-all-columns"), "</h1>"),
              selector: ".import-step .numbered-steps li:nth-child(2)",
              backupSelector: this.get("backupImportClass")
            });
          }
          // when transaction date in in line, make sure it is mapped only once
          if (this.get("dataTypeData.trxDateInLine") && this.get("currentTypeMappings").filter(function (item) {
            return !Ember.get(item, "col.hide") && Ember.get(item, "col.selectedAttribute") === "trxDate";
          }).length > 1) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.transaction-one-mapping"), "</h1>"),
              selector: ".import-preview .scroll-list.fixed.main.top",
              backupSelector: this.get("backupImportClass")
            });
          }
          if (this.get("dataTypeData.trxDateInLine") && this.get("currentTypeMappings").filter(function (item) {
            return !Ember.get(item, "col.hide") && Ember.get(item, "col.selectedAttribute") === "amount";
          }).length > 1) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.amount-one-mapping"), "</h1>"),
              selector: ".import-preview .scroll-list.fixed.main.top",
              backupSelector: this.get("backupImportClass")
            });
          }
          // make sure a transaction date column is mapped when required
          if (this.get("dataTypeData.trxDateInLine") && !this.get("currentTypeMappings").find(function (item) {
            return !Ember.get(item, "col.hide") && Ember.get(item, "col.selectedAttribute") === "trxDate";
          })) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.transaction-not-mapped"), "</h1>"),
              selector: ".import-preview .scroll-list.fixed.main.top",
              backupSelector: this.get("backupImportClass")
            });
          }
          if (this.get("dataTypeData.mandatoryFields.currency") && !this.get("currentTypeMappings").find(function (item) {
            return !Ember.get(item, "col.hide") && Ember.get(item, "col.selectedAttribute") === "currency";
          })) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.currency-not-mapped"), "</h1>"),
              selector: ".import-preview .scroll-list.fixed.main.top",
              backupSelector: this.get("backupImportClass")
            });
          }
          if (this.get("dataTypeData.mandatoryFields.accountId") && !this.get("currentTypeMappings").find(function (item) {
            return !Ember.get(item, "col.hide") && Ember.get(item, "col.selectedAttribute") === "accountId";
          })) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.account-not-mapped"), "</h1>"),
              selector: ".import-preview .scroll-list.fixed.main.top",
              backupSelector: this.get("backupImportClass")
            });
          }
          if (this.get("dataTypeData.mandatoryFields.amount") && !this.get("currentTypeMappings").find(function (item) {
            return !Ember.get(item, "col.hide") && Ember.get(item, "col.selectedAttribute") === "amount";
          })) {
            return this.get("modal").show({
              type: "error",
              text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.amount-not-mapped"), "</h1>"),
              selector: ".import-preview .scroll-list.fixed.main.top",
              backupSelector: this.get("backupImportClass")
            });
          }
          _import2.default.matchLines(this, this.get("sheet"));
        }
        if (this.get("mapByDepartment")) {
          if (isMovingStep2ToStep3) {
            // auto match departments
            this.get("currentTypeMappings").forEach(function (item) {
              var colValue = Ember.get(item, "col.value.w");
              var dep;
              Ember.set(item, "col.hide", true);
              if (Ember.get(item, "col.key") !== _this9.get("nameColumn") && colValue && colValue.toLowerCase().indexOf("total") < 0) {
                // try to match by cost center code
                var costCenter = colValue.match(/^\s*(\d+)\s*\-\s*.*/);
                costCenter = costCenter && costCenter[1] ? costCenter[1] : null;
                var costCenterName = colValue.match(/^(?:\s*\d+\s*\-\s*)?(.*)/);
                costCenterName = costCenterName && costCenterName[1] ? costCenterName[1] : null;
                dep = _const.default.findByCostCenterFast(_this9.store, _this9.get("model"), [costCenter, costCenterName]);

                // otherwise match by name
                if (!dep) {
                  dep = _this9.get("departmentList").findBy("text", colValue.replace(/^\s*\d+\s*\-\s*/, ""));
                }
                // try fuzzy match
                try {
                  if (!dep) {
                    var depMap = _this9.get("departmentList").map(function (d) {
                      return {
                        dep: d,
                        score: clj_fuzzy.metrics.dice(Ember.get(d, "text"), colValue)
                      };
                    }).sortBy("score");
                    var bestMatch = Ember.get(depMap, "lastObject");
                    if (Ember.get(bestMatch, "score") >= 0.5) {
                      dep = Ember.get(bestMatch, "dep");
                    }
                  }
                } catch (e) {
                  // ignore
                }
                if (dep) {
                  Ember.set(item, "col.hide", false);
                  Ember.set(item, "selectedAttribute", Ember.get(dep, "id"));
                }
              }
            });
          } else if (isMovingStep3ToStep4) {
            var selectedColumnsWithNoMapping = this.get("currentTypeMappings").filter(function (item) {
              return !Ember.get(item, "col.hide") && !Ember.get(item, "selectedAttribute");
            });
            if (selectedColumnsWithNoMapping.length) {
              return this.get("modal").show({
                type: "error",
                text: "<h1>".concat(intl.t("general.please_select_the_mapping_for_each_column_1695803191204"), "</h1>"),
                selector: ".import-preview .scroll-list.fixed.main.top",
                backupSelector: this.get("backupImportClass")
              });
            }
          } else if (isMovingStep4ToStep5) {
            var selectedColumns = this.get("currentTypeMappings").filter(function (item) {
              return !item.col.hide;
            }).mapBy("key");
            var emptyRow = this.get("lineNamesAfterRowSelection").find(function (line) {
              var selectedValues = line.valuesArray.filter(function (v) {
                return selectedColumns.contains(v.headerKey) && !v.zeroValue;
              });
              return selectedValues.length && selectedValues.filterBy("mapping").length === 0;
            });
            if (emptyRow) {
              return this.get("modal").show({
                type: "error",
                text: "<h1>".concat(intl.t("general.please_select_mapping_for_all_selected_rows_1695803191193"), "</h1>"),
                selector: ".import-step .step-header",
                backupSelector: this.get("backupImportClass")
              });
            }
          }
        }
        if (isMovingStep2ToStep3) {
          if (this.get("dataTypeData.accountColumn")) {
            var mappingColumn = this.get("currentTypeMappings").find(function (item) {
              return _this9.get("dataTypeData.accountColumn").contains(Ember.get(item, "col.value.w"));
            });
            if (mappingColumn) {
              this.set("mappingColumn", Ember.get(mappingColumn, "key"));
              Ember.setProperties(mappingColumn, {
                selectedAttribute: "accountId",
                hide: false
              });
            }
          }
          if (!this.get("mappingColumn")) {
            var _mappingColumn = this.get("currentTypeMappings").findBy("selectedAttribute", "accountId");
            if (_mappingColumn) {
              this.set("mappingColumn", Ember.get(_mappingColumn, "key"));
            }
          }
          if (!this.get("dataTypeData.trxDateInLine") && !this.get("mapByDepartment")) {
            var colDates = this.get("currentTypeMappings").filterBy("col.isDate");
            this.set("autoDateSelection", true);
            colDates.forEach(function (colDate) {
              if (!_import2.default.colIsEmpty(_this9.get("sheet"), _import2.default.getCol(Ember.get(colDate, "key")), _import2.default.getRowNumber(Ember.get(colDate, "key")) + 1)) {
                colDate.setProperties({
                  selectedAttribute: "amount"
                });
                _this9.send("unhideColumn", colDate);
              }
            });
            this.set("autoDateSelection", false);
          }
        }
        if (this.get("dataTypeData.columnMapping") && isMovingStep2ToStep3) {
          // automatically select and map columns according to selected data type definition
          Object.keys(this.get("dataTypeData.columnMapping")).forEach(function (columnKey) {
            if (columnKey === "category" && !_this9.get("mapCategories")) {
              return;
            }
            var thisColumn = _this9.get("currentTypeMappings").find(function (item) {
              return _this9.get("dataTypeData.columnMapping.".concat(columnKey)).contains((Ember.get(item, "col.value.w") || "").trim()) || _this9.get("dataTypeData.columnMapping.".concat(columnKey)).contains(_import2.default.getCol(Ember.get(item, "col.key")));
            });
            if (thisColumn) {
              Ember.setProperties(thisColumn, {
                selectedAttribute: columnKey,
                hide: false
              });
            }
          });
        }
        this.set("mainController.maximized", true);
        this.setLoadingMessage(this.get("intl").t("import-all.please-wait"));
        sendPendoEvent(stepIndex, isStepForward);
        Ember.run.next(this, function () {
          if (this.get("currentStep") >= stepIndex - 1) {
            this.set("currentStep", stepIndex);
          }
          Ember.run.sync();
          Ember.run.next(this, function () {
            this.setLoadingMessage(null);
            if (isMovingStep3ToStep4) {
              this.addMappingColumnsForLines();
            }
          });
        });
      },
      doImportActualsWithoutClosedMonths: function doImportActualsWithoutClosedMonths(importActualsData) {
        this.send("doImportActuals", importActualsData, false);
      },
      doImportActuals: function doImportActuals(importActualsData) {
        var _this10 = this;
        var withClosedMonths = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        var importActualsResult = _importActuals.default.importActuals(this, importActualsData, withClosedMonths);
        Ember.RSVP.all(importActualsResult.promises).then(function () {
          _this10.get("trackingEvents").trackEvent(_this10.get("trackingEvents.events.IMPORT_ACTUALS_FROM_EXCEL.IMPORT_ACTUALS_FINISH_IMPORT"));
          _this10.set("viewService.actualsSummaryTypeSetByUser", importActualsResult.viewType);
          _this10.get("budgetService").updateTransactions();
          _this10.send("reset");
          _this10.transitionToRoute("main.budget.forecast", {
            queryParams: {
              viewType: importActualsResult.viewType,
              actuals: true,
              budgetViewType: "act",
              forceDetails: true,
              subsidiary: "*"
            }
          });
          _this10.showSuccessModalMessage();
        });
      },
      confirm: function confirm() {
        if (!this.get("confirmDisabled")) {
          this.set("confirmDisabled", true);
          this.get("cache").clearAllCache();
          this.set("errorMessage", null);
          var importActualsData = _importActuals.default.importActualsGetTransactions(this);
          if (importActualsData.transactions.findBy("closed")) {
            return this.get("modal").show({
              type: "error",
              timeout: 0,
              text: "<h1>".concat(this.get("intl").t("actuals.confirm-error-title"), "</h1><p>").concat(this.get("intl").t("actuals.confirm-error-body"), "</p>"),
              selector: ".import-step .step-header",
              backupSelector: this.get("backupImportClass"),
              controller: this,
              cancelLabel: this.get("intl").t("wizard-modal.no"),
              okLabel: this.get("intl").t("wizard-modal.yes"),
              okAction: "doImportActuals",
              okActionArg: importActualsData,
              cancelAction: "doImportActualsWithoutClosedMonths",
              cancelActionArg: importActualsData
            });
          } else {
            this.send("doImportActuals", importActualsData);
          }
        }
      },
      cancel: function cancel() {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.IMPORT_ACTUALS_FROM_EXCEL.IMPORT_ACTUALS_CANCEL_IMPORT"));
        this.transitionToRoute("main.budget.forecast", this.get("budgetService.selectedRoot"), {
          queryParams: {
            actuals: true,
            budgetViewType: "act",
            forceDetails: true,
            subsidiary: "*"
          }
        });
      },
      showNotExistedCurrencyWarning: function showNotExistedCurrencyWarning() {
        this.get("modal").show({
          type: "warning",
          text: "<h1>".concat(this.get("intl").t("actuals.warning-msg.not-existed-currency"), "</h1>"),
          selector: ".import-top .large_header_light",
          backupSelector: "div[data-hook=\"expand-collapse-panel\"]",
          timeout: 15000
        });
      }
    }
  });
});