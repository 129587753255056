define("budgeta/templates/components/remove-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pRYcd3WK",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"remove-share.are-you-sure\"],null],false],[\"text\",\" \"],[\"append\",[\"helper\",[\"user-name\"],null,[[\"user\"],[[\"get\",[\"model\",\"user\"]]]]],false],[\"text\",\" \"],[\"append\",[\"helper\",[\"t\"],[\"remove-share.from-share\"],null],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/remove-share.hbs"
    }
  });
});