define("budgeta/templates/components/email-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GODAFZMJ",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"email-input.email\"],null],false],[\"close-element\"],[\"text\",\" \"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"type\",\"value\",\"required\"],[\"email\",[\"get\",[\"model\",\"changeOwnershipNewUser\"]],true]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/email-input.hbs"
    }
  });
});