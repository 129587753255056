define("budgeta/components/chart-master", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BVA = "#bva#";
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["chart-master"],
    budgetaChartData: Ember.computed("chart.highchartData", "chart.highchartType", "chart.chartType", "chart.pieSumsLastValue", "chartType", "isBudgetVsScenario", "isFvB", "scenario", "forecastViewOptionName", function () {
      var highchartData = this.get("chart.highchartData");
      if (!highchartData) {
        return [];
      }
      var chartType = this.get("chart.highchartType"),
        chartTypeMaster = this.get("chart.chartType"),
        chartId = this.get("chart.id"),
        pieSumsLastValue = this.get("chart.pieSumsLastValue"),
        isBudgetVsScenario = this.get("isBudgetVsScenario"),
        isFvB = this.get("isFvB"),
        forecastViewOptionName = (this.get("forecastViewOptionName") || "").toLowerCase(),
        scenario = ((this.get("scenario") === BVA ? "Actual" : this.get("scenario")) || "").toLowerCase();
      if (chartId === "kpiChart" || chartId === "single-kpi") {
        return highchartData;
      }
      if (highchartData[0] && highchartData[0].hasOwnProperty("level") && typeof highchartData[0]["level"] !== "undefined") {
        // check drill down
        if (chartTypeMaster !== "donut") {
          var firstLevel = highchartData[0]["level"];
          var minLevel = firstLevel;
          highchartData.forEach(function (data) {
            if (typeof data.level !== "undefined" && data.level < minLevel) {
              minLevel = data.level;
            }
          });
          highchartData = highchartData.filter(function (data) {
            return minLevel === data.level;
          });
          highchartData.map(function (data) {
            delete data.level;
            delete data.color;
            delete data.parentKeys;
            return data;
          });
        }
      }
      if (chartType === "pie-chart") {
        highchartData.map(function (serie) {
          if (!serie.hasOwnProperty("y")) {
            if (serie.data) {
              var sum = 0;
              for (var i = 0; i < serie.data.length; i++) {
                sum += serie.data[i];
              }
              serie.y = sum;
            }
          }
          delete serie.type;
          if (pieSumsLastValue) {
            serie.y = serie.data && serie.data[serie.data.length - 1] ? serie.data[serie.data.length - 1] : 0;
          }
          return serie;
        });
      } else if (chartType === "line-chart") {
        highchartData.map(function (serie) {
          serie.type = "line";
          return serie;
        });
      } else if (chartType === "column-chart") {
        highchartData.map(function (serie) {
          serie.type = "column";
          return serie;
        });
      } else if (chartType === "area-chart") {
        highchartData.map(function (serie) {
          delete serie.type;
          return serie;
        });
      }
      if (chartType === "pie-chart" || chartType === "area-chart") {
        if (isBudgetVsScenario) {
          highchartData = highchartData.filter(function (serie) {
            return serie.stack.toLowerCase() === scenario;
          });
        } else if (isFvB) {
          highchartData = highchartData.filter(function (serie) {
            return serie.stack.toLowerCase() === forecastViewOptionName;
          });
        }
      }
      return highchartData;
    }),
    ignoreCategories: Ember.computed("chart.highchartType", function () {
      return this.get("chart.highchartType") === "pie-chart";
    })
  });
});