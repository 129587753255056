define("budgeta/templates/components/rename-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "W6yxFNzZ",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"rename-version.version-name\"],null],false],[\"close-element\"],[\"text\",\" \"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"type\",\"required\",\"value\"],[\"text\",true,[\"get\",[\"model\",\"tag\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/rename-version.hbs"
    }
  });
});