define("budgeta/components/dynamic-high-charts", ["exports", "ember-highcharts/components/high-charts"], function (_exports, _highCharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _highCharts.default.extend({
    contentDidChange: Ember.observer("content.@each.isLoaded", function () {
      var chart = this.get("chart");
      if (!chart || !chart.series) {
        chart && chart.redraw();
        return;
      }
      while (chart.series.length > 0) {
        chart.series[0].remove(false);
      }
      var chartType = this.get("controller.parentView.chartType");
      for (var i = 0; i < this.get("content").length; i++) {
        var newSeries = {
          name: this.get("content")[i].name,
          data: this.get("content")[i].data,
          stack: this.get("content")[i].stack
        };
        if (chartType === "donut") {
          if (i === 0) {
            newSeries.size = "60%";
          } else {
            newSeries.size = "80%";
            newSeries.innerSize = "60%";
          }
        }
        if (this.get("content.".concat(i, ".type"))) {
          newSeries.type = this.get("content")[i].type;
        }
        if (this.get("content.".concat(i, ".yAxis"))) {
          newSeries.yAxis = this.get("content")[i].yAxis;
        }
        if (this.get("content.".concat(i, ".color"))) {
          newSeries.color = this.get("content")[i].color;
        }
        chart.addSeries(newSeries);
      }
      if (!Ember.isEmpty(this.get("chartOptions.xAxis.categories"))) {
        chart.xAxis[0].setCategories(this.get("chartOptions.xAxis.categories"));
      }
      if (this.get("controller.parentView.showLegend") || this.get("controller.parentView.chartTypeName") === "pie-chart") {
        for (var _i = 0; _i < chart.series.length; _i++) {
          chart.series[_i].update({
            showInLegend: true
          }, false);
        }
      } else {
        for (var _i2 = 0; _i2 < chart.series.length; _i2++) {
          chart.series[_i2].update({
            showInLegend: false
          }, false);
        }
      }
      chart.redraw();
    })
  });
});