define("budgeta/components/react/list/videosTooltip", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@sibp/ui-components", "react"], function (_exports, _slicedToArray2, _uiComponents, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable react/react-in-jsx-scope */
  // import React from "react";

  var VideosTooltip = function VideosTooltip(_ref) {
    var iconName = _ref.iconName,
      getVideoTitles = _ref.getVideoTitles,
      selectedVideo = _ref.selectedVideo,
      tooltipParentSelector = _ref.tooltipParentSelector,
      forcePlacement = _ref.forcePlacement,
      direction = _ref.direction,
      hook = _ref.hook,
      openBy = _ref.openBy,
      showArrow = _ref.showArrow;
    var TooltipIcon = _uiComponents.default.IMAGES[iconName];
    var childRef = _react.default.useRef();
    var _React$useState = _react.default.useState([]),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      _videos = _React$useState2[0],
      setVideos = _React$useState2[1];

    // retrive videos title for drop-down
    var _onReferenceClicked = function _onReferenceClicked() {
      // fire function passed by ref
      setVideos(getVideoTitles());
    };
    var handleItem = function handleItem(item) {
      var _childRef$current;
      // activate function from child
      (_childRef$current = childRef.current) === null || _childRef$current === void 0 || _childRef$current.closeTooltipOnItemClicked();
      selectedVideo(item);
    };
    var tooltipContent = _react.default.createElement("ul", {
      className: "menu"
    }, _videos.map(function (item) {
      return _react.default.createElement("li", {
        key: item.youtubeId,
        className: "menu-item"
      }, _react.default.createElement("span", {
        onClick: function onClick() {
          return handleItem(item.youtubeId);
        },
        role: "button"
      }, item.title));
    }));
    var clickProps = {
      _onReferenceClicked: _onReferenceClicked
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.Tooltip, {
      tooltipClassName: "video-tooltip",
      forcePlacement: forcePlacement,
      tooltipParentSelector: tooltipParentSelector,
      direction: direction,
      iconName: _react.default.createElement(TooltipIcon, null),
      hook: hook,
      openBy: openBy,
      showArrow: showArrow,
      ref: childRef,
      tooltipContent: tooltipContent,
      clickProps: clickProps
    });
  };
  var _default = _exports.default = VideosTooltip;
});