define("budgeta/templates/main/budget/forecast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sb86upnu",
    "block": "{\"statements\":[[\"block\",[\"main/budget/forecast-page\"],null,[[\"scrollHeight\",\"controllertmp\"],[[\"get\",[\"scrollHeight\"]],[\"get\",[null]]]],3]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"partial\",\"main/budget/forecast-body-content\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"react-component\"],null,[[\"componentName\",\"type\"],[\"Skeleton\",\"grid\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"highlight\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"left\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"forecast.view-only-mode\"],null],false],[\"close-element\"],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"budget-top-bar\"],null,[[\"selectedBudget\",\"editMode\",\"budget\",\"forecastTarget\",\"showImport\",\"closeImport\"],[[\"get\",[\"mainController\",\"selectedBudget\"]],false,[\"get\",[\"mainController\",\"selectedBudget\"]],[\"get\",[\"forecastTarget\"]],[\"get\",[\"mainController\",\"showImport\"]],\"closeImport\"]]],false],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"budgetService\",\"version\"]]],null,2],[\"block\",[\"if\"],[[\"get\",[\"isLoading\"]]],null,1,0]],\"locals\":[\"forecastDraggableScrollHeightStyle\",\"rowDimensions\",\"isForecastPreview\",\"scrollHeightStyle\"]}],\"hasPartials\":true}",
    "meta": {
      "moduleName": "budgeta/templates/main/budget/forecast.hbs"
    }
  });
});