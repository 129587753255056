define("budgeta/generic-file/components/file-upload/body/file-upload-body", ["exports", "react", "react-redux", "@sibp/ui-components", "budgeta/services/ember-service-container", "budgeta/store/actions/fileActions", "lodash/lodash"], function (_exports, _react, _reactRedux, _uiComponents, _emberServiceContainer, _fileActions, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = FileUpload;
  var intl = new _emberServiceContainer.default().lookup("intl");
  function FileUpload(props) {
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.fileStore;
      }),
      fileName = _ReactRedux$useSelect.name;
    var dispatch = _reactRedux.default.useDispatch();
    var handleFile = function handleFile(file /*:File*/) {
      if (!_lodash.default.isEqual(fileName, file.name)) {
        dispatch(_fileActions.default.setFileName(file.name));
      }
      props.setNewFile(file);
    };
    return _react.default.createElement("div", {
      className: "drop-file"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.FileInput, {
      dataHook: "file-input",
      handleFile: handleFile,
      label: fileName,
      icon: _react.default.createElement(_uiComponents.default.IMAGES.BrowseSvg, null),
      textBefore: intl.t("generic-file.step-file-upload.instructions")
    }));
  }
});