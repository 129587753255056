define("budgeta/helpers/json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.json = json;
  /*
  example:
  {{json obj }}
  */

  function json(jsValue) {
    return JSON.stringify(jsValue);
  }
  var _default = _exports.default = Ember.Helper.helper(json);
});