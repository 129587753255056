define("budgeta/components/integration-wizard-dimension-values-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":view-table-edit", ":intacct-account-list", ":short-content", ":integration-wizard-dimension-values-mapping-table", ":export-missing-mapping"],
    scrollLeft: 0,
    scrollTop: 0,
    itemSizesDimensions: Ember.computed("model.missingDimensionValues.length", function () {
      var tableWidth = 796;
      var scrollWidth = 17;
      tableWidth = tableWidth - (Ember.$("body").hasClass("hiddenscroll") ? 0 : scrollWidth);
      return this.get("model.missingDimensionValues").map(function (dimension) {
        var height = (dimension.get("maxItemsLength") || 1) * 40;
        if (height > 300) {
          height = 300;
        }
        return {
          width: tableWidth,
          height: height
        };
      });
    }),
    actions: {
      updateItemSizesDimensions: function updateItemSizesDimensions(index, length) {
        var item = this.get("itemSizesDimensions")[index];
        if (item) {
          var height = (length || 1) * 40;
          if (height > 300) {
            height = 300;
          }
          Ember.set(item, "height", height);
        }
        this.notifyPropertyChange("itemSizesDimensions");
      }
    }
  });
});