define("budgeta/components/integration-wizard-dimensions-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["integration-wizard-dimensions-mapping"],
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    dimensionsMapping: [],
    intacctDimensions: Ember.computed("model.intacctDimensions", function () {
      var _this = this;
      return (this.get("model.intacctDimensions") || []).filter(function (v) {
        return !(_this.get("budgetService.dimensionsMapping") || Ember.A([])).findBy("financialDimensionId", v.id);
      }).map(function (v) {
        return {
          id: v.id,
          text: v.dimension
        };
      });
    }),
    selectedDimensions: Ember.computed("dimensionsMapping.@each.financialDimensionId", function () {
      return this.get("dimensionsMapping").mapBy("financialDimensionId").filter(function (v) {
        return v;
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;
      this._super.apply(this, arguments);
      this.set("dimensionsMapping", (this.get("model.missingDimensions") || []).map(function (dimId) {
        var result = _this2.get("budgetService.dimensionsMapping").findBy("dimensionId", dimId);
        if (!result) {
          result = _this2.get("store").createRecord("dimension-mapping", {
            budget: _this2.get("budgetService.selectedRoot.absoluteRoot"),
            dimensionId: dimId
          });
        }
        return result;
      }));
    }
  });
});