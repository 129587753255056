define("budgeta/routes/admin", ["exports", "budgeta/mixins/budgeta-route", "ember-simple-auth/mixins/authenticated-route-mixin", "budgeta/utils/const"], function (_exports, _budgetaRoute, _authenticatedRouteMixin, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _budgetaRoute.default, {
    session: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var userRoles = this.get("session.data.currentUser.roles") || [];
      if (userRoles.includes(_const.USER_ROLE.ADMIN)) {
        this.get("trackingEvents").bootPendo();
      } else {
        this.transitionTo("unauthorized");
      }
    },
    actions: {
      goto: function goto(budgetId) {
        this.transitionTo("main.budget.index", budgetId);
      }
    }
  });
});