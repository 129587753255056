define("budgeta/router", ["exports", "budgeta/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("login");
    this.route("change-password", {
      path: "change-password"
    });
    this.route("login", {
      path: "/login"
    }, function () {
      this.route("login", {
        path: "/*path"
      });
    });
    this.route("logout");
    this.route("my-profile-container", {
      path: "/my-profile/my-details"
    }, function () {
      this.route("my-profile-container", {
        path: "/*path"
      });
    });
    this.route("user-profile");
    this.route("invitation", {
      path: "/invitation/:token"
    }, function () {
      this.route("accept");
    });
    this.route("main", {
      path: "/"
    }, function () {
      this.route("budget", {
        path: "/budget/:budget_id"
      }, function () {
        this.route("forecast");
        this.route("dashboard");
        this.route("import");
        this.route("import-generic-file");
        this.route("import-actuals");
        this.route("models");
        this.route("trx");
        this.route("table");
        this.route("accounts");
        this.route("delete");
      });
    });
    this.route("model-not-found");
    this.route("admin", {
      path: "/admin-old"
    }, function () {
      this.route("activity");
      this.route("budgets");
      this.route("flagged");
      this.route("tips");
      this.route("list");
      this.route("find-budget");
    });
    this.route("unauthorized");
    this.route("browser-not-supported");
    this.route("tutorials");
    this.route("server-unavailable");
    this.route("budget-info", {
      path: "/budget-info/:token"
    }, function () {
      this.route("accept");
    });
    this.route("budget-not-loaded");
    this.route("admin-app-route", {
      path: "/admin-app"
    }, function () {
      this.route("admin-app-route", {
        path: "/*path"
      });
    });
    this.route("company-admin-route", {
      path: "/admin/:companyId"
    }, function () {
      this.route("company-admin-route", {
        path: "/*path"
      });
    });
  });
  var _default = _exports.default = Router;
});