define("budgeta/components/add-root-modal", ["exports", "budgeta/components/modal-new", "budgeta/templates/components/modal-new"], function (_exports, _modalNew, _modalNew2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    layout: _modalNew2.default,
    init: function init() {
      this._super.apply(this, arguments);

      // don't allow to cancel if user has no budgets
      if (this.get("controllertmp.store").peekAll("budget").get("length") === 0) {
        this.set("controllertmp.hideCancel", true);
      }
    }
  });
});