define("budgeta/components/dropdown-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["dropdown"],
    classNameBindings: ["readOnly:dropdown-readonly"],
    optionValuePath: "value",
    optionLabelPath: "name",
    prompt: "",
    addClass: Ember.computed("placeholder", "value", function () {
      return !Ember.isEmpty(this.get("placeholder")) && this.get("emptyValue") ? "inherited" : "";
    }),
    valuesOnly: Ember.computed("content.[]", function () {
      return this.get("content") && typeof this.get("content")[0] === "string";
    }),
    emptyValue: Ember.computed("value", function () {
      return Ember.isEmpty(this.get("value"));
    }),
    selectedOption: Ember.computed("content", "value", function () {
      if (this.get("valuesOnly")) {
        if (!Ember.isEmpty(this.get("placeholder")) && this.get("emptyValue")) {
          return this.get("placeholder");
        } else {
          return this.get("value") || this.get("prompt");
        }
      } else if (this.get("content")) {
        var value = this.get("content").findBy(this.get("optionValuePath"), this.get("value")) || this.get("value");
        if (value) {
          if (this.get("emptyValue") && !Ember.isEmpty(this.get("placeholder"))) {
            var newVal = {};
            newVal[this.get("optionValuePath")] = "";
            newVal[this.get("optionLabelPath")] = this.get("placeholder");
            return newVal;
          }
          return value;
        } else {
          var empty = {};
          empty[this.get("optionValuePath")] = null;
          empty[this.get("optionLabelPath")] = this.get("placeholder") || this.get("prompt");
          return empty;
        }
      } else {
        return null;
      }
    }),
    dataHookName: Ember.computed("dataHook", function () {
      return this.get("dataHook") || "dropdown-container";
    }),
    actions: {
      select: function select(opt) {
        this.$().trigger("change");
        this.set("value", this.get("valuesOnly") ? opt : opt[this.get("optionValuePath")]);
      }
    }
  });
});