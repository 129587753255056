define("budgeta/generic-file/components/generic-app/generic-app", ["exports", "budgeta/generic-file/components/generic-manager/GenericManager", "budgeta/generic-file/components/generic-progress-bar/generic-progress-bar", "react", "react-redux", "budgeta/store/store"], function (_exports, _GenericManager, _genericProgressBar, _react, _reactRedux, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GenericApp = function GenericApp(props) {
    var store = (0, _store.getStore)(true);
    return _react.default.createElement(_reactRedux.default.Provider, {
      store: store
    }, _react.default.createElement(_genericProgressBar.default, props), _react.default.createElement(_GenericManager.default, props));
  };
  var _default = _exports.default = GenericApp;
});