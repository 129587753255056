define("budgeta/initializers/set-browser-alert", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    var intl = new _emberServiceContainer.default().lookup("intl");
    var isChromeOrEdge = (navigator.userAgent.indexOf("Chrome") > -1 || navigator.userAgent.indexOf("Edge") > -1) && navigator.userAgent.indexOf("Vivaldi") === -1 && navigator.userAgent.indexOf("OPR") === -1;
    if (!isChromeOrEdge) {
      var currentBrowser;
      if (navigator.userAgent.indexOf("Opera") !== -1 || navigator.userAgent.indexOf("OPR") !== -1) {
        currentBrowser = "Opera";
      } else if (navigator.userAgent.indexOf("Vivaldi") !== -1) {
        currentBrowser = "Vivaldi";
      } else if (navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.match(/\(Macintosh/)) {
        currentBrowser = "Safari";
      } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        currentBrowser = "Firefox";
      } else if (navigator.userAgent.indexOf("MSIE") !== -1) {
        currentBrowser = "IE";
      }
      Ember.run.later(this, function () {
        noty({
          type: "warning",
          text: intl.t("general.browser_recommendation_1695957078639", {
            currentBrowser: currentBrowser
          })
        });
      }, 3000);
    }
    if (window.devicePixelRatio >= 1 && (Ember.$(document).height() < 700 || Ember.$(document).width() < 1000)) {
      var time = isChromeOrEdge ? 3000 : 12000;
      Ember.run.later(this, function () {
        noty({
          type: "warning",
          text: intl.t("general.screen_recommendation_1695957078639")
        });
      }, time);
    }
  }
  var _default = _exports.default = {
    name: "set-browser-alert",
    after: ["intl", "get-config"],
    initialize: initialize
  };
});