define("budgeta/templates/components/forecast-budget-only-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6CBL5HOV",
    "block": "{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"displayValues\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"format-numbers\"],[[\"get\",[\"displayValue\",\"value\"]],[\"get\",[\"displayValue\",\"currency\"]],null,[\"get\",[\"precision\"]],[\"get\",[\"showNegativeAsPositive\"]],\"column\"],null],false],[\"text\",\"\\n\"]],\"locals\":[\"displayValue\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/forecast-budget-only-row.hbs"
    }
  });
});