define("budgeta/templates/components/budgeta-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wDvnQ6rj",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"input\"],null,[[\"id\",\"type\",\"checked\",\"disabled\",\"class\"],[[\"get\",[\"inputId\"]],\"checkbox\",[\"get\",[\"checked\"]],[\"get\",[\"disabled\"]],[\"helper\",[\"concat\"],[[\"helper\",[\"if\"],[[\"get\",[\"inheritChecked\"]],\"inheritChecked\",\"\"],null],\" \"],null]]]],false],[\"text\",\"\\n\"],[\"open-element\",\"label\",[]],[\"dynamic-attr\",\"for\",[\"unknown\",[\"inputId\"]],null],[\"flush-element\"],[\"text\",\"\\n  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/budgeta-checkbox.hbs"
    }
  });
});