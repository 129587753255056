define("budgeta/templates/components/confirm-revert-scenario", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FitKg6H3",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"confirm-revert-scenario.message.changes-made\"],null],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/confirm-revert-scenario.hbs"
    }
  });
});