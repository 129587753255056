define("budgeta/utils/import-actuals", ["exports", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/utils/import", "budgeta/utils/forecast"], function (_exports, _const, _budgetUtils, _import, _forecast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ImportActualsUtils = {
    importActualsGetTransactions: function importActualsGetTransactions(ctl) {
      var result = {
          overwrite: {}
        },
        transactions = [],
        lineToReload = [],
        pnlTotal,
        closedMonths = ctl.get("budgetService.selectedRoot.closedActuals") || [],
        departmentDimension = (ctl.get("budgetService.selectedRoot.dimensions") || Ember.A([])).findBy("isDepartment"),
        budgetStartDate = ctl.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate").format(_const.default.SugerYearMonthDateFormat);
      if (ctl.get("lineNames")) {
        ctl.get("lineNames").forEach(function (item) {
          if (Ember.get(item, "selectedFirst")) {
            if (ctl.get("mapByDepartment")) {
              result.viewType = "pnl";
              var fiscalDate = ctl.get("fiscalDate");
              if (fiscalDate) {
                (Ember.get(item, "valuesArray") || Ember.A([])).filterBy("mapping").forEach(function (curValue) {
                  var budgetLine = ctl.store.peekRecord("budget", curValue.mapping);
                  var key = fiscalDate.format(_const.default.SugerYearMonthDateFormat);
                  result.overwrite[key] = ["pnl"];
                  var isRevenue = budgetLine.get("budgetModule.revenue") || budgetLine.get("budgetType").indexOf("group-revenue") === 0;
                  lineToReload.addObject(budgetLine);
                  var thisTrx = {
                    budget: ctl.get("model.id"),
                    budgetLine: budgetLine.id,
                    accountId: item.get("accountId"),
                    fiscalDate: fiscalDate,
                    description: item.get("value") ? item.get("value").trim() : null,
                    source: ctl.get("selectedSheet"),
                    statement: "pnl",
                    type: "trx",
                    dimensions: {},
                    currency: ctl.get("currency"),
                    amount: (isRevenue ? 1 : -1) * curValue.value.v,
                    closed: closedMonths.contains(key),
                    savedMapping: true,
                    trxDateString: key,
                    fiscalDateString: key
                  };
                  if (departmentDimension && curValue.department) {
                    thisTrx.dimensions[departmentDimension.get("uniqueId")] = curValue.department;
                  }
                  if (budgetStartDate > key) {
                    thisTrx.set("isPastTrx", true);
                  }
                  transactions.push(thisTrx);
                });
              }
            } else {
              var creditCol, debitCol;
              var budgetLine = Ember.get(item, "mapping") ? ctl.store.peekRecord("budget", Ember.get(item, "mapping")) : null;
              if (Ember.get(item, "statement") === "bs" && Ember.get(item, "bsLine")) {
                budgetLine = ctl.get("model");
              }
              if (budgetLine) {
                if (!result.viewType) {
                  result.viewType = item.get("statement") === "both" || item.get("statement") === "cash" ? "cf" : "pnl";
                }
                ctl.get("currentTypeMappings").filter(function (col) {
                  return (Ember.get(col, "selectedAttribute") === "amount" || Ember.get(col, "selectedAttribute") === "debit" || Ember.get(col, "selectedAttribute") === "credit" && !ctl.get("currentTypeMappings").find(function (c) {
                    return Ember.get(c, "selectedAttribute") === "debit" && !Ember.get(c, "hide");
                  })) && !Ember.get(col, "hide");
                }).forEach(function (amountCol) {
                  var selectedMonth = ctl.get("dataTypeData.showFiscalDate") ? ctl.get("fiscalDate") : amountCol.get("selectedMonth");
                  selectedMonth = selectedMonth && selectedMonth.toDate ? selectedMonth.toDate() : selectedMonth;
                  var monthKey = selectedMonth && selectedMonth.format("{yyyy}{MM}");

                  // remember category mapping
                  if (ctl.get("mapCategories")) {
                    _const.default.localStorageSetItem("actualImport.categoryMapping.".concat(item.get("value").trim()), item.get("category"));
                  }
                  lineToReload.addObject(budgetLine);
                  var newTrx = Ember.Object.create({
                    budget: ctl.get("model.id"),
                    budgetLine: budgetLine.id,
                    bsLine: item.get("isBalanceSheet") ? item.get("bsLine") : null,
                    accountId: item.get("accountId"),
                    fiscalDate: ctl.get("showFiscalDate") ? ctl.get("fiscalDate") : selectedMonth,
                    description: item.get("value") ? item.get("value").trim() : null,
                    source: ctl.get("selectedSheet"),
                    statement: item.get("statement"),
                    dimensions: {},
                    type: item.get("statement") === "bs" ? ctl.get("dataTypeData.dataTypeForBSStatement") || ctl.get("dataTypeData.dataType") : ctl.get("dataTypeData.dataType"),
                    currency: ctl.get("currency"),
                    savedMapping: true
                  });
                  var lineNum = _import.default.getRowNumber(Ember.get(item, "location"));
                  ctl.get("currentTypeMappings").filterBy("selectedAttribute").filterBy("hide", false).forEach(function (a) {
                    var key = a.get("key");
                    var attrName = a.get("selectedAttribute");
                    var finalAttrName = attrName;
                    switch (attrName) {
                      case "debit":
                        debitCol = a.get("key");
                        finalAttrName = "amount";
                        break;
                      case "credit":
                        creditCol = a.get("key");
                        finalAttrName = "amount";
                        break;
                    }
                    var cell = item.values[_import.default.getCol(key) + lineNum];
                    var value = cell ? cell.v : null;
                    if (value) {
                      if (attrName === "trxDate" || attrName === "fiscalDate") {
                        value = _import.default.getKeyMoment(cell, ctl.get("date1904"), undefined, true);
                        if (value) {
                          value = value.toDate();
                          if (attrName === "trxDate" && Ember.isEmpty(newTrx.get("fiscalDate"))) {
                            newTrx.set("fiscalDate", new Date(value).beginningOfMonth());
                          }
                        }
                      }
                      if (attrName == "currency" && ctl.get("columnAttributesByOrder").findBy("value", "currency").templatesBelong.has(ctl.get("dataType"))) {
                        value = item.currency;
                      }
                      newTrx.set(finalAttrName, value);
                    }
                  });
                  var amountCell = item.values[_import.default.getCol(amountCol.get("key")) + lineNum];
                  var thisAmount = amountCell ? amountCell.v : null;
                  if (creditCol && debitCol) {
                    var creditCell = item.values[_import.default.getCol(creditCol) + lineNum];
                    var debitCell = item.values[_import.default.getCol(debitCol) + lineNum];
                    thisAmount = (creditCell ? creditCell.v : 0) - (debitCell ? debitCell.v : 0);
                  }
                  newTrx.set("amount", thisAmount);
                  if (Ember.isEmpty(newTrx.get("trxDate")) && newTrx.get("statement") !== "pnl") {
                    newTrx.set("trxDate", new Date(selectedMonth).endOfMonth());
                  }

                  // BUD-7468 if this is a retained earnings transaction and the amount is equal to the pnl total actuals, don't import it
                  var importTrx = true;
                  if (newTrx.get("statement") === "bs" && newTrx.get("bsLine") === "re") {
                    pnlTotal = pnlTotal || _forecast.default.calcForecast(ctl.get("cache"), ctl.get("store"), ctl.get("budgetService.selectedRoot.id"), ctl.get("budgetService.selectedRoot"), {
                      type: "pnl",
                      budgetViewType: "act"
                    });
                    var pnlActualsForMonth = pnlTotal.actualsItems[newTrx.get("trxDate").format(_const.default.SugerYearMonthDateFormat)] || 0;
                    if (Math.abs(pnlActualsForMonth - (newTrx.get("amount") || 0)) < 2) {
                      importTrx = false;
                    }
                  }
                  if (importTrx && !Ember.isEmpty(newTrx.get("amount")) && !isNaN(newTrx.get("amount")) && (Ember.isEmpty(newTrx.get("trxDate")) || newTrx.get("trxDate").isValid() && (!monthKey || newTrx.get("trxDate").format("{yyyy}{MM}") === monthKey))) {
                    if (!selectedMonth) {
                      selectedMonth = newTrx.get("trxDate");
                    }
                    var key = selectedMonth && selectedMonth.format(_const.default.SugerYearMonthDateFormat);
                    if (key) {
                      // delete current transactions if user chose to overwrite
                      var types = item.get("statement") === "both" ? ["cash", "pnl"] : [item.get("statement")];
                      result.overwrite[key] = result.overwrite[key] || [];
                      result.overwrite[key].addObjects(types);
                      newTrx.setProperties({
                        trxDateString: key,
                        fiscalDateString: key
                      });
                    }
                    if (ctl.get("dataTypeData.reverseAll") && newTrx.get("statement") !== "bs" || ctl.get("dataTypeData.reverseExpenses") && !budgetLine.get("isRevenue") || ctl.get("dataTypeData.reverseRevenues") && budgetLine.get("isRevenue") || !ctl.get("dataTypeData.noReverseBSLiabilities") && newTrx.get("statement") === "bs" && newTrx.get("bsLine") && _const.default.isBSLineLiability(newTrx.get("bsLine"))) {
                      newTrx.set("amount", -newTrx.get("amount"));
                    }
                    newTrx.set("closed", closedMonths.contains(key));
                    if (budgetStartDate > key) {
                      newTrx.set("isPastTrx", true);
                    }
                    transactions.push(newTrx);
                  }
                });
              }
            }
          }
        });
        result.transactions = transactions;
      }
      return result;
    },
    importActuals: function importActuals(ctl, data, includeClosedMonths) {
      var promises = [],
        closedMonths = ctl.get("budgetService.selectedRoot.closedMonths") || [];
      if (ctl.get("overwrite")) {
        var _loop = function _loop(key) {
          if (includeClosedMonths || closedMonths.contains(key)) {
            var types = data.overwrite[key];
            var includeBoth = types.contains("pnl") && types.contains("cash");
            promises.pushObjects(types.map(function (type) {
              return _budgetUtils.default.deleteActualsForMonth(ctl.get("rootBudgetLine"), key, type, includeBoth);
            }));
          }
        };
        for (var key in data.overwrite) {
          _loop(key);
        }
      }
      var transactions = includeClosedMonths ? data.transactions : data.transactions.filter(function (t) {
        return !t.closed;
      });
      promises.push(_budgetUtils.default.createTransactionsBulk(ctl.get("store"), ctl.get("budgetService"), transactions).then(function () {
        ctl.get("budgetService").updateMonthsIncludedInForecast(transactions);
        ctl.get("mainBudgetTrxController").calcAllTransactions();
      }));
      return {
        viewType: data.viewType,
        promises: promises
      };
    }
  };
  var _default = _exports.default = ImportActualsUtils;
});