define("budgeta/components/forecast-row-master", ["exports", "budgeta/utils/forecast"], function (_exports, _forecast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    displayValues: Ember.computed("allContent", "valueName", function () {
      var valueName = this.get("valueName");
      if (valueName && this.get("allContent")) {
        return this.get("allContent").map(function (content) {
          if (content[valueName]) {
            var result = content[valueName].length <= 1 ? content[valueName][0] : content[valueName];
            if (Ember.isArray(result)) {
              result.setEach("hasActuals", content.hasActuals);
            } else {
              result.hasActuals = content.hasActuals;
            }
            return result;
          }
          return content;
        });
      }
      return this.get("allContent");
    }),
    precision: Ember.computed("defaultPrecision", "dataPrecision", "rowCurrency", function () {
      var precision = Ember.isEmpty(this.get("dataPrecision")) ? this.get("defaultPrecision") : this.get("dataPrecision");
      if (this.get("rowCurrency") === "%" || precision === 2) {
        this.get("displayValues").forEach(function (displayValue) {
          if (displayValue.value < 0.01 && displayValue.value > 0.0000001) {
            precision = 4;
          }
        });
      }
      return precision;
    }),
    rowCurrency: Ember.computed("displayValues", "isPercent", "isCount", "budget.budgetType", "budget.budgetAttributes.pctCurrency", "budget.budgetAttributes.amountType", "scenario", "vsOption", "sideBySide", function () {
      return _forecast.default.getForecastRowCurrency(this.get("store"), this.get("cache"), this.get("budget"), this.get("scenario"), this.get("sideBySide"), this.get("isCount"), this.get("isPercent"), this.get("displayValues.0.currency"), this.get("vsOption"));
    }),
    rowCurrencyInCompare: Ember.computed("budget.budgetType", "scenario", "isPercent", "isCount", "displayValues.[]", "rowCurrency", function () {
      if (this.get("budget.budgetType") === "assumption") {
        return _forecast.default.getModelCurrency(this.get("store"), this.get("budget"), this.get("scenario"));
      } else {
        return this.get("rowCurrency");
      }
    }),
    isPercentRow: Ember.computed("isPercent", "budget.budgetType", "budget.budgetAttributes.pctCurrency", "budget.budgetAttributes.amountType", function () {
      return this.get("isPercent") || this.get("budget.budgetType") === "assumption" && this.get("budget.budgetAttributes.pctCurrency") === "%" && this.get("budget.budgetAttributes.amountType") <= 3;
    }),
    isCountRow: Ember.computed("isCount", "budget.budgetType", "budget.budgetAttributes.pctCurrency", "budget.budgetAttributes.amountType", function () {
      return this.get("isCount") || this.get("budget.budgetType") === "assumption" && this.get("budget.budgetAttributes.pctCurrency") !== "%" && (Ember.isEmpty(this.get("budget.budgetAttributes.pctCurrency")) || this.get("budget.budgetAttributes.pctCurrency").length === 1 || this.get("budget.budgetAttributes.amountType") === 5 && this.get("budget.budgetAttributes.headcountUse") === "cost");
    }),
    isCurrencyRow: Ember.computed("isPercentRow", "isCountRow", function () {
      return !(this.get("isPercentRow") || this.get("isCountRow"));
    })
  });
});