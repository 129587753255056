define("budgeta/components/select-filter-dropdown", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    trackingEvents: Ember.inject.service(),
    classNameBindings: [":select-filter-dropdown", "hasFilterOption:has-filter-option"],
    hasFilterOption: Ember.computed("listenOnValue", "dimensionSelect", "value", "isTags", "isAccounts", "isRoles", "isHeadcount", "isBookingsType", "content", function () {
      var _this = this;
      if (this.get("dimensionSelect")) {
        return this.get("listenOnValue") !== "all" && this.get("listenOnValue") !== "by";
      }
      var value = this.get("value");
      if (value !== _const.ALL_ID && !this.get("content").some(function (option) {
        return option[_this.get("optionValuePath")] === value;
      })) {
        Ember.run.later(function () {
          // TODO: this implementation is temp until replacing select-2 with power-select
          // select-2 wont set default selection when receiving a value that not in content
          Ember.set(_this, "value", _this.get("content.firstObject.".concat(_this.get("optionValuePath"))));
        }, 100);
        return false;
      }
      if ((this.get("isHeadcount") || this.get("isTags") || this.get("isAccounts")) && value !== _const.ALL_ID) {
        return true;
      }
      if (this.get("isRoles") && value !== "All roles" && value !== "By role") {
        return true;
      }
      if (this.get("isBookingsType") && value !== _const.ALL_ID && value !== "#") {
        return true;
      }
      return;
    }),
    actions: {
      onOptionSelecting: function onOptionSelecting() {
        var onOptionSelecting = this.get("onOptionSelecting");
        if (onOptionSelecting) {
          this.sendAction(onOptionSelecting);
        }
      },
      dimensionValueSelected: function dimensionValueSelected() {
        this.sendAction("attributeChanged", this.get("targetValue"), this.get("targetProperty"));
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.USER_FILTER_GRID_BY_DIMENSION"), {});
      },
      attributeChanged: function attributeChanged() {
        if (this.get("isAccounts")) {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.USER_FILTER_GRID_BY_ACCOUNT"), {});
        }
      }
    }
  });
});