define("budgeta/helpers/percentage-columns-layout", ["exports", "ember-collection/layouts/percentage-columns"], function (_exports, _percentageColumns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return new _percentageColumns.default(params[0], params[1], params[2]);
  });
});