define("budgeta/components/integration-wizard-export-missing-pnl", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/closure-actions */

  var Line = Ember.Object.extend({
    line: null,
    accounts: Ember.computed.alias("line.addAccounts")
  });
  var payrollLine = _const.default.DEFAULT_SALARY_COMPONENTS.map(function (cat) {
    return Ember.Object.extend({
      line: null,
      accounts: Ember.computed.alias("line.addAccounts.".concat(cat))
    });
  });
  var pepertualLine = _const.default.DEFAULT_PERPETUAL_LICENCE_CATEGORIES_ARRAY.map(function (cat) {
    return Ember.Object.extend({
      line: null,
      accounts: Ember.computed.alias("line.addAccounts.".concat(cat))
    });
  });
  var _default = _exports.default = Ember.Component.extend({
    filteredAccounts: Ember.computed("intacctPnlAccounts.length", function () {
      return this.get("intacctPnlAccounts").sortBy("id");
    }),
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    }),
    lines: Ember.computed("missingAccounts.pnl", function () {
      var result = [];
      (this.get("missingAccounts.pnl") || []).forEach(function (line) {
        if (Ember.get(line, "budget.budgetType") === "group-payroll" || Ember.get(line, "budget.budgetType") === "sales-perpetual-license") {
          var categoriesWithErrors = Ember.get(line, "categoriesWithErrors");
          var components = Ember.get(line, "budget.budgetType") === "group-payroll" ? _const.default.DEFAULT_SALARY_COMPONENTS : _const.default.DEFAULT_PERPETUAL_LICENCE_CATEGORIES_ARRAY;
          var addAccounts = Ember.Object.create({});
          var lineArray = Ember.get(line, "budget.budgetType") === "group-payroll" ? payrollLine : pepertualLine;
          components.forEach(function (cat) {
            Ember.set(addAccounts, cat, Ember.A([]));
          });
          Ember.set(line, "addAccounts", addAccounts);
          if (categoriesWithErrors && categoriesWithErrors.length) {
            result.pushObjects(categoriesWithErrors.map(function (catIdx) {
              return typeof catIdx === "number" ? lineArray[catIdx].create({
                title: Ember.get(line, "budget.fullPathWithoutAccounts") + " - " + components[catIdx],
                name: Ember.get(line, "budget.name") + " - " + components[catIdx],
                line: line
              }) : Ember.Object.create({
                title: Ember.get(line, "budget.fullPathWithoutAccounts") + " - " + Ember.get(catIdx, "name"),
                line: line,
                name: Ember.get(line, "budget.name") + " - " + Ember.get(catIdx, "name"),
                accounts: Ember.computed.alias("line.addAccounts.benefit#".concat(Ember.get(catIdx, "uniqueId")))
              });
            }));
          } else {
            result.push(lineArray[0].create({
              title: Ember.get(line, "budget.fullPathWithoutAccounts"),
              line: line,
              name: Ember.get(line, "budget.name")
            }));
          }
        } else {
          result.push(Line.create({
            title: Ember.get(line, "budget.fullPathWithoutAccounts"),
            name: Ember.get(line, "budget.name"),
            line: line
          }));
        }
      });
      return result;
    }),
    didRender: function didRender() {
      this.sendAction(this.get("continueActionName"), this.get("model.isSkipSteps.exportMissingPnl") ? intl.t("general.skip_1698234255443") : "");
    },
    actions: {
      attributeChanged: function attributeChanged() {
        this.set("model.isSkipSteps.exportMissingPnl", false);
        this.sendAction(this.get("continueActionName"), "");
      }
    }
  });
});