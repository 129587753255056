define("budgeta/transforms/utc", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      if (value) {
        return new Date(value).addMinutes(-value.getTimezoneOffset()).toJSON();
      } else {
        return null;
      }
    },
    deserialize: function deserialize(value) {
      if (value) {
        var d = new Date(value);

        // dirty fix for transactions that were saved before the timezone fix
        if (value.indexOf("T21:00:00.000Z") > 0 || value.indexOf("T22:00:00.000Z") > 0) {
          var d1 = new Date(value).addDays(1);
          if (d1.getMonth() !== d.getMonth()) {
            return d1.beginningOfMonth();
          }
        }
        return value.indexOf("T00:00:00.000Z") > 0 ? d.addMinutes(d.getTimezoneOffset()) : d; // BUD-4349 only add the timezone offset if it was substracted in the first place
      } else {
        return null;
      }
    }
  });
});