define("budgeta/generic-file/components/data-format/data-format", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "react", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "budgeta/generic-file/components/input-box/input-box", "budgeta/store/actions/budgetActions"], function (_exports, _slicedToArray2, _react, _uiComponents, _reactRedux, _stepsActions, _emberServiceContainer, _inputBox, _budgetActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = DataFormat;
  var intl = new _emberServiceContainer.default().lookup("intl");
  function DataFormat() {
    var budgetFormat = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.format;
    });
    var dispatch = _reactRedux.default.useDispatch();
    var _React$useState = _react.default.useState(budgetFormat),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      dataFormat = _React$useState2[0],
      setDataFormat = _React$useState2[1];
    var options = {
      hideClose: true,
      height: "675px"
    };
    var header = {
      text: intl.t("general.text_1695611610511")
    };
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.next"),
      onClick: function onClick() {
        dispatch(_budgetActions.default.setDataFormat(dataFormat));
        dispatch(_stepsActions.default.nextStep());
      },
      disabled: !dataFormat
    }));
    var footer = {
      buttons: buttons
    };
    var handleDataFormatChanged = function handleDataFormatChanged(value) {
      setDataFormat(value);
    };
    var body = _react.default.createElement("div", {
      className: "data-format-container"
    }, _react.default.createElement("p", {
      className: "description"
    }, intl.t("generic-file.step-data-format.description")), _react.default.createElement("div", {
      className: "options"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.RadioGroup, {
      onChange: function onChange(event) {
        return handleDataFormatChanged(event.target.value);
      },
      defaultValue: dataFormat,
      dataHook: "budget-format-radio-group",
      className: "number-in-row-2"
    }, _react.default.createElement(_inputBox.default, {
      boxClassName: dataFormat === intl.t("generic-file.step-data-format.seperate-columns") ? "checked" : "",
      value: intl.t("generic-file.step-data-format.seperate-columns"),
      className: "no-margin-radio",
      label: intl.t("generic-file.step-data-format.seperate-columns"),
      input: _react.default.createElement(_uiComponents.default.COMPONENTS.Radio, {
        requiredMessage: intl.t("uic.required_field")
      }),
      footer: _react.default.createElement(_uiComponents.default.IMAGES.SeperateColumnsSvg, null)
    }), _react.default.createElement(_inputBox.default, {
      boxClassName: dataFormat === intl.t("generic-file.step-data-format.gl-string") ? "checked" : "",
      label: intl.t("generic-file.step-data-format.gl-string"),
      value: intl.t("generic-file.step-data-format.gl-string"),
      className: "no-margin-radio",
      input: _react.default.createElement(_uiComponents.default.COMPONENTS.Radio, {
        requiredMessage: intl.t("uic.required_field")
      }),
      footer: _react.default.createElement(_uiComponents.default.IMAGES.GlStringSvg, null)
    }))));
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});