define("budgeta/admin/store/reducers/notifReducer", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/admin/store/actions/notifActions", "@sibp/ui-components"], function (_exports, _objectSpread2, _notifActions, _uiComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = rootReducer;
  var initialState = {
    showLoader: false,
    noty: {
      visible: false,
      text: "",
      errorLevel: _uiComponents.default.ERROR_LEVEL.ERROR,
      notiContent: null
    }
  };
  function rootReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case _notifActions.SHOW_LOADER:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            showLoader: true
          });
        }
      case _notifActions.HIDE_LOADER:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            showLoader: false
          });
        }
      case _notifActions.HIDE_NOTY:
        {
          var noty = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state.noty), {}, {
            visible: false,
            text: "",
            notiContent: null
          });
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            noty: noty
          });
        }
      case _notifActions.SHOW_NOTY:
        {
          var _noty = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state.noty), {}, {
            visible: true,
            text: action.text,
            notiContent: action.notiContent,
            errorLevel: action.errorLevel
          });
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            noty: _noty
          });
        }
      default:
        return state;
    }
  }
});