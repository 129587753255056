define("budgeta/templates/components/svg/star-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pGiGMzq2",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M9.14762 0.562437C8.98201 0.218726 8.63205 0 8.24772 0C7.86338 0 7.51655 0.218726 7.34782 0.562437L5.33867 4.69635L0.851669 5.35878C0.476711 5.41502 0.164246 5.67749 0.0486335 6.03682C-0.0669786 6.39616 0.0267608 6.79299 0.295481 7.05858L3.55137 10.2801L2.7827 14.8327C2.72021 15.2077 2.87644 15.5889 3.18578 15.8107C3.49512 16.0326 3.90445 16.0607 4.24191 15.8826L8.25084 13.7422L12.2598 15.8826C12.5972 16.0607 13.0066 16.0357 13.3159 15.8107C13.6252 15.5858 13.7815 15.2077 13.719 14.8327L12.9472 10.2801L16.2031 7.05858C16.4718 6.79299 16.5687 6.39616 16.4499 6.03682C16.3312 5.67749 16.0218 5.41502 15.6469 5.35878L11.1568 4.69635L9.14762 0.562437Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/star-svg.hbs"
    }
  });
});