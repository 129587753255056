define("budgeta/components/currency-input", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/mixins/formula-editor-component"], function (_exports, _emberServiceContainer, _const, _formulaEditorComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var formatValue = function formatValue(num, convertToPercent, precision) {
    if (typeof num === "string" && num.substr(0, 1) === "=") {
      if (num === "=<p></p>") {
        return "=";
      } else {
        return num;
      }
    }
    num = convertToPercent ? +(num * 100).toFixed(2) : precision ? _const.default.round(num, precision) : num;
    return Ember.isEmpty(num) || num === 0 ? "" : _const.default.formatStringNumber(num);
  };
  var _default = _exports.default = Ember.Component.extend(_formulaEditorComponent.default, {
    classNameBindings: [":currency", "displayCurrency", "currencyOptions:has-currency-selection:has-formula-button", "inFocus:in-focus", "hasValue:has-value:"],
    attributeBindings: ["data-key"],
    currencySymbol: _const.default.currency_symbols,
    "data-key": Ember.computed.alias("key"),
    enabled: Ember.computed("readonly", "currencyOptionsDisabled", function () {
      return !this.get("readonly") && !this.get("currencyOptionsDisabled");
    }),
    allowNegative: false,
    removeCurrencyType: false,
    disablePercentConvertion: false,
    inputType: Ember.computed("type", function () {
      return this.get("type") || "text";
    }),
    keyDown: function keyDown(event) {
      if (event.key === "=" && this.get("value")) {
        // only allow to enter equal sign when field is empty
        return false;
      }
    },
    hasValue: Ember.computed("ignoreAttributes", "formattedValue", function () {
      if (this.get("ignoreAttributes")) {
        return "";
      }
      return !Ember.isEmpty(this.get("formattedValue")) && this.get("formattedValue") !== "";
    }),
    title: Ember.computed("ignoreAttributes", "isNonEmptyFormula", "inheritedCurrency", function () {
      if (this.get("ignoreAttributes")) {
        return "";
      }
      return this.get("isNonEmptyFormula") ? this.get("cleanFormula") : this.get("formattedValue");
    }),
    placeholderObserver: Ember.observer("placeholder", function () {
      if (this.get("placeholder") === null) {
        this.set("placeholder", "");
      }
    }),
    displayCurrency: Ember.computed("ignoreAttributes", "currency", "inheritedCurrency", "isFormula", function () {
      if (this.get("ignoreAttributes")) {
        return "";
      }
      return this.get("isFormula") ? null : this.get("currency") || this.get("inheritedCurrency");
    }),
    isNegativeAllowed: Ember.computed("allowNegative", "budget.budgetAttributesWithScenarios.amountType", function () {
      var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
      var isInputsMain = viewSelections.get("currentPath") === "main.budget.index" && !viewSelections.get("isMetricsView");
      var isDifferentAmountInInputsMain = this.get("budget.budgetAttributesWithScenarios.amountType") === 1 && isInputsMain;
      return this.get("allowNegative") || isDifferentAmountInInputsMain;
    }),
    formattedValue: Ember.computed("value", "currency", "precision", "inheritedCurrency", {
      get: function get() {
        var currency = this.get("isFormula") || this.get("removeCurrencyType") ? null : this.get("currency") || this.get("inheritedCurrency");
        var currencySymbol = currency === "." ? "" : this.get("currencySymbol." + currency);
        var convertToPercent = !this.get("disablePercentConvertion") && this.get("currency") === "%" && !this.get("isFormula");
        return (currencySymbol || "") + formatValue(this.get("value"), convertToPercent, this.get("precision"));
      },
      set: function set(key, value) {
        var _this = this;
        var convertToPercent = !this.get("disablePercentConvertion") && this.get("currency") === "%" && !this.get("isFormula");
        var currency = this.get("isFormula") || this.get("removeCurrencyType") ? null : this.get("currency") || this.get("inheritedCurrency");
        var currencySymbol = currency === "." ? "" : this.get("currencySymbol." + currency);
        if (typeof value === "string" && value.substr(1, 2) === "=") {
          value = value.substr(1);
        }
        if (value === "") {
          this.set("value", value);
          if (this.get("disableFormula")) {
            Ember.run.schedule("afterRender", function () {
              // the input field will not be updated, need to force it
              _this.$("input").eq(0).val(currencySymbol);
            });
          }
        } else if (typeof value === "string" && value.substr(0, 1) === "=") {
          if (value.substr(1, 2) === currencySymbol) {
            value = "=" + value.substr(2);
          }
          this.set("value", value);
        } else {
          var num = value.replace(currencySymbol, "").replace(/[^\-\.0-9km]/gi, "");
          if (num === "") {
            this.set("value", "");
            Ember.run.schedule("afterRender", function () {
              // the input field will not be updated, need to force it
              _this.$("input").eq(0).val(currencySymbol);
            });
            return currencySymbol;
          }
          if (num && num.match(/k/i)) {
            num = num.replace(/k/i, "") * 1000;
          } else if (num && num.match(/m/i)) {
            num = num.replace(/m/i, "") * 1000000;
          }
          if (!this.get("isNegativeAllowed")) {
            if (typeof num === "string") {
              num = num.replace("-", "");
            } else {
              num = Math.abs(num);
            }
          }
          if (num === this.get("value") && value.substr(value.length - 1) !== ".") {
            // the input field will not be updated, need to force it
            var newFormatted = (currencySymbol || "") + formatValue(num, convertToPercent, this.get("precision"));
            Ember.run.schedule("afterRender", function () {
              _this.$("input").eq(0).val(newFormatted);
            });
            return newFormatted;
          } else {
            num = convertToPercent ? num / 100 : num;
            this.set("value", num);
          }
        }
        if (value.substr(0, 1) === "=") {
          return formatValue(this.get("value"), convertToPercent, this.get("precision"));
        }
        return (currencySymbol || "") + formatValue(this.get("value"), convertToPercent, this.get("precision"));
      }
    }),
    focusIn: function focusIn(e) {
      this._super(e);
      this.set("inFocus", true);
      return true;
    },
    focusOut: function focusOut(e) {
      this._super(e);
      this.set("inFocus", false);
      if (this.get("valueChangedCB") && this.get("value") !== "=<p></p>") {
        this.sendAction("valueChangedCB");
      }
      return true;
    },
    updateSize: function updateSize() {
      var leftOffset = 0,
        checkValue = this.value + "";
      if (this.get("currency") === "ILS" || this.get("inheritedCurrency") === "ILS" && this.get("currency") === "" && checkValue > 0) {
        leftOffset = (checkValue.length + 1) * 8 + 10 + Math.floor(checkValue.length / 3) * 3;
        Ember.$(this.element).children(".currencySymbol").css({
          left: leftOffset
        });
      } else {
        Ember.$(this.element).children(".currencySymbol").css({
          left: 0
        });
      }
    },
    sizeObserver: Ember.observer("formattedValue", "displayCurrency", function () {
      this.updateSize();
    }),
    willDestroyElement: function willDestroyElement() {
      if (this.$()) {
        this.$().off("paste", "input");
      }
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      var allowNegative = this.get("isNegativeAllowed");
      Ember.run.scheduleOnce("afterRender", function () {
        if (_this2.$() && allowNegative) {
          _this2.$().on("paste", "input", function (e) {
            var data = e.originalEvent.clipboardData.getData("text/plain");
            var match = data.match(/\((.*)\)/);
            if (match && match[1]) {
              e.target.value = "-" + match[1];
              e.preventDefault();
              Ember.$(e.target).trigger("change");
            }
          });
        }
      });
      this.updateSize();
    },
    actions: {
      onFocusOut: function onFocusOut(value) {
        this.get("onFocusOut") && this.get("onFocusOut")(value);
      },
      onInput: function onInput(value) {
        var _this$get;
        this.set("formattedValue", value);
        (_this$get = this.get("onInput")) === null || _this$get === void 0 || _this$get(value);
      }
    }
  });
});