define("budgeta/common-react-components/profile/pages/myDetails/myDetails", ["exports", "@sibp/ui-components", "budgeta/services/ember-service-container", "budgeta/utils/local-storage-util", "budgeta/admin/utils/validator", "budgeta/utils/const", "react"], function (_exports, _uiComponents, _emberServiceContainer, _localStorageUtil, _validator, _const, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MyDetails = void 0;
  /* eslint-disable react/display-name */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var session = new _emberServiceContainer.default().lookup("session");
  var MyDetails = _exports.MyDetails = function MyDetails(_ref) {
    var user = _ref.user,
      onFieldBlur = _ref.onFieldBlur,
      _ref$showSupportAndIn = _ref.showSupportAndIntacctSettings,
      showSupportAndIntacctSettings = _ref$showSupportAndIn === void 0 ? false : _ref$showSupportAndIn;
    return function () {
      var showIntacctAPISetttings = _const.default.INTACCT_API_ALLOW_OVERRIDE;
      var saveInLocalStorage = function saveInLocalStorage(key, value) {
        if (showIntacctAPISetttings) {
          _localStorageUtil.default.setItem(key, value);
        }
      };
      var apiSettings = {
        apiHost: _localStorageUtil.default.getItem("SI_API_HOST") || "www.intacct.com",
        apiPath: _localStorageUtil.default.getItem("SI_API_PATH") || "/ia/xml/xmlgw.phtml",
        apiSender: _localStorageUtil.default.getItem("SI_API_SENDER") || "",
        apiPassword: _localStorageUtil.default.getItem("SI_API_PASSWORD") || ""
      };
      var intacctSettings = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
        className: "page-header"
      }, intl.t("user-profile.sage-intacct-api-settings")), _react.default.createElement("div", {
        className: "user-input-with-label"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        label: intl.t("user-profile.host"),
        dataHook: "intacctHost",
        placeholder: intl.t("user-profile.sage-intacct-api-host"),
        value: apiSettings.apiHost,
        onBlur: function onBlur(event) {
          return saveInLocalStorage("SI_API_HOST", event.target.value);
        }
      })), _react.default.createElement("div", {
        className: "user-input-with-label"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        label: intl.t("user-profile.path"),
        dataHook: "intacctPath",
        placeholder: intl.t("user-profile.sage-intacct-api-path"),
        value: apiSettings.apiPath,
        onBlur: function onBlur(event) {
          return saveInLocalStorage("SI_API_PATH", event.target.value);
        }
      })), _react.default.createElement("div", {
        className: "user-input-with-label"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        label: intl.t("user-profile.sender-id"),
        dataHook: "intacctSenderId",
        placeholder: intl.t("user-profile.sage-intacct-api-sender-id"),
        value: apiSettings.apiSender,
        onBlur: function onBlur(event) {
          return saveInLocalStorage("SI_API_SENDER", event.target.value);
        }
      })), _react.default.createElement("div", {
        className: "user-input-with-label"
      }, _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        label: intl.t("user-profile.password"),
        type: "password",
        dataHook: "intacctApiPassword",
        placeholder: intl.t("user-profile.sage-intacct-api-password"),
        value: apiSettings.apiPassword,
        onBlur: function onBlur(event) {
          return saveInLocalStorage("SI_API_PASSWORD", event.target.value);
        }
      })));
      var ssoAuth = session.get("data.currentUser.authenticatedBy") === _const.AUTH_PROVIDERS.SIF;
      return _react.default.createElement("div", {
        className: "my-details full-width"
      }, _react.default.createElement("div", {
        className: "profile-details"
      }, intl.t("user-profile.my-details.profile-details")), _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        onBlur: function onBlur(event) {
          return onFieldBlur("email", event.target.value);
        },
        dataHook: "email",
        label: intl.t("user-profile.my-details.email"),
        value: user.email,
        validators: [{
          errorMessage: intl.t("admin_app.validations.email-is-not-valid"),
          isValid: function isValid(value) {
            return _validator.default.isEmailValid(value);
          }
        }],
        required: !ssoAuth,
        disabled: ssoAuth,
        isForceDisplayError: true,
        requiredMessage: intl.t("uic.required_field")
      }), _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        onBlur: function onBlur(event) {
          return onFieldBlur("firstName", event.target.value);
        },
        label: intl.t("user-profile.my-details.first-name"),
        dataHook: "firstName",
        value: user.firstName,
        disabled: ssoAuth
      }), _react.default.createElement(_uiComponents.default.COMPONENTS.Input, {
        onBlur: function onBlur(event) {
          return onFieldBlur("lastName", event.target.value);
        },
        label: intl.t("user-profile.my-details.last-name"),
        dataHook: "lastName",
        value: user.lastName,
        disabled: ssoAuth
      }), showSupportAndIntacctSettings && showIntacctAPISetttings ? intacctSettings : null);
    };
  };
});