define("budgeta/templates/move-lines-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tLUUJzOX",
    "block": "{\"statements\":[[\"block\",[\"move-lines-modal\"],null,[[\"controllertmp\"],[[\"get\",[null]]]],1]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"      \"],[\"open-element\",\"li\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"budget-line \",[\"helper\",[\"if\"],[[\"get\",[\"line\",\"allowed\"]],\"allowed\"],null],\" \",[\"helper\",[\"if\"],[[\"get\",[\"line\",\"selected\"]],\"selected\"],null]]]],[\"dynamic-attr\",\"title\",[\"concat\",[[\"helper\",[\"if\"],[[\"get\",[\"line\",\"allowed\"]],\"Move here\"],null]]]],[\"dynamic-attr\",\"data-level\",[\"concat\",[[\"unknown\",[\"line\",\"level\"]]]]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"select\",[\"get\",[\"line\"]]]],[\"flush-element\"],[\"text\",\"\\n        \"],[\"append\",[\"unknown\",[\"line\",\"budget\",\"name\"]],false],[\"text\",\"\\n      \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"line\"]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"ul\",[]],[\"static-attr\",\"class\",\"budget-tree\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"budgetLines\"]]],null,0],[\"text\",\"  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/move-lines-modal.hbs"
    }
  });
});