define("budgeta/components/budget-lock", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    tagName: "li",
    isIn: false,
    mouseEnter: function mouseEnter() {
      this.set("isIn", true);
    },
    mouseLeave: function mouseLeave() {
      this.set("isIn", false);
    },
    iconName: Ember.computed("isIn", "isLocked", function () {
      if (this.get("isLocked")) {
        if (this.get("isIn")) {
          return "lock_open";
        }
        return "lock";
      } else {
        if (this.get("isIn")) {
          return "lock";
        }
        return "lock_open";
      }
    }),
    iconText: Ember.computed("isLocked", function () {
      return this.get("isLocked") ? intl.t("general.unlock_1695953254651") : intl.t("general.lock_1695953254651");
    }),
    click: function click() {
      this.sendAction("lockBudget");
    }
  });
});