define("budgeta/components/employees-table-num-of-emp-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    numOfEmployees: Ember.computed("line.numOfEmployees", {
      get: function get() {
        return this.get("line.numOfEmployees");
      },
      set: function set(key, value) {
        if (this.get("line.budgetType") === "employee-multi" && this.get("budgetAttributes.amountType") === 0) {
          this.set("budgetAttributes.amount", value);
        }
      }
    }),
    isEditable: Ember.computed("line.budgetType", "budgetAttributes.amountType", function () {
      return this.get("line.budgetType") === "employee-multi" && this.get("budgetAttributes.amountType") === 0;
    }),
    isFormula: Ember.computed("numOfEmployees", function () {
      return isNaN(parseFloat(this.get("numOfEmployees")));
    }),
    actions: {
      openFormulaEditor: function openFormulaEditor(component) {
        this.sendAction("openFormulaEditor", component);
      },
      attributeChanged: function attributeChanged() {
        this.sendAction("attributeChanged");
      }
    }
  });
});