define("budgeta/components/forecast-budget-only-row", ["exports", "budgeta/components/forecast-row-master"], function (_exports, _forecastRowMaster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _forecastRowMaster.default.extend({
    attributeBindings: ["data-hook"]
  });
});