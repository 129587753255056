define("budgeta/templates/components/select-dimension-values-multi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "D+QMoP5+",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"close-element\"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"multiple\",\"enabled\",\"value\",\"placeholder\",\"content\"],[true,[\"get\",[\"enabled\"]],[\"get\",[\"selectedValues\"]],[\"get\",[\"placeholder\"]],[\"get\",[\"content\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/select-dimension-values-multi.hbs"
    }
  });
});