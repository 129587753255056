define("budgeta/models/dimension-value-mapping", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    dimensionValueIds: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    dimensionId: _emberData.default.attr("string"),
    financialDimensionId: _emberData.default.attr("string"),
    financialDimensionValueId: _emberData.default.attr("string"),
    financialDimensionValueName: _emberData.default.attr("string"),
    financialDimensionValueDisplay: Ember.computed("financialDimensionValueId", "financialDimensionValueName", function () {
      if (!this.get("financialDimensionValueName")) {
        return this.get("financialDimensionValueId");
      }
      return "".concat(this.get("financialDimensionValueName"), " (").concat(this.get("financialDimensionValueId"), ")");
    }),
    dimension: Ember.computed("budget.dimensions.length", "dimensionId", function () {
      return (this.get("budget.dimensions") || Ember.A([])).findBy("uniqueId", this.get("dimensionId"));
    }),
    dimensionValues: Ember.computed("dimension.values.length", function () {
      return this.get("dimension.values") || [];
    }),
    selectedDimensionValues: Ember.computed("dimensionValues", "dimensionValueIds.length", function () {
      var dimensionValues = this.get("dimensionValues") || [];
      return (this.get("dimensionValueIds") || []).map(function (dimensionValueId) {
        return dimensionValues.findBy("uniqueId", dimensionValueId);
      });
    }),
    dimensionIdDisplay: Ember.computed("dimensionId", {
      get: function get() {
        return this.get("dimensionId");
      },
      set: function set(key, value) {
        this.setProperties({
          dimensionId: value,
          dimensionValueIds: []
        });
        return value;
      }
    }),
    budget: _emberData.default.belongsTo("budget", {
      async: false,
      inverse: "dimensionsValuesMapping"
    }),
    maxItemsLength: Ember.computed("selectedDimensionValues.length", function () {
      return this.get("selectedDimensionValues.length") || 1;
    }),
    firstDimensionValue: Ember.computed("dimensionValueIds.firstObject", function () {
      if (this.get("dimensionValueIds")) {
        var dimId = this.get("dimensionValueIds")[0];
        return dimId ? this.get("dimensionValues").findBy("uniqueId", dimId) : undefined;
      }
    })
  });
});