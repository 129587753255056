define("budgeta/components/react/grid-components/currency-cell-template", ["exports", "@sibp/ui-components", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _uiComponents, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CurrencyCellTemplate = void 0;
  var emberSeviceContainer = new _emberServiceContainer.default();
  var viewSelections = emberSeviceContainer.lookup("viewSelections");
  var budgetService = emberSeviceContainer.lookup("budgetService");
  var CurrencyCellTemplate = _exports.CurrencyCellTemplate = function CurrencyCellTemplate(_ref) {
    var openFormulaForCell = _ref.openFormulaForCell,
      isCopyClicked = _ref.isCopyClicked,
      onCopyClick = _ref.onCopyClick;
    var actions = [{
      test: function test() {
        var _cell$PROPS;
        var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          cell = _ref2.cell,
          _ref2$value = _ref2.value,
          value = _ref2$value === void 0 ? "" : _ref2$value,
          keyCode = _ref2.keyCode;
        return !cell.nonEditable && (((_cell$PROPS = cell.PROPS) === null || _cell$PROPS === void 0 ? void 0 : _cell$PROPS.isFormula) && keyCode !== _const.KEY_CODES.ENTER && keyCode === _const.KEY_CODES.POINTER || value === "=");
      },
      disableEdit: true,
      action: function action(_ref3) {
        var cell = _ref3.cell;
        // this timeout is for not rendering the '=' sign in the formula value
        setTimeout(function () {
          openFormulaForCell(cell);
        });
      }
    }, {
      test: function test() {
        var _cell$PROPS2;
        var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          cell = _ref4.cell,
          keyCode = _ref4.keyCode;
        return !cell.nonEditable && ((_cell$PROPS2 = cell.PROPS) === null || _cell$PROPS2 === void 0 ? void 0 : _cell$PROPS2.isFormula) && keyCode === _const.KEY_CODES.ENTER;
      },
      disableEdit: true,
      action: function action() {
        // do nothing
      }
    }, {
      test: function test() {
        var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          cell = _ref5.cell,
          keyCode = _ref5.keyCode;
        return keyCode === _const.KEY_CODES.POINTER && cell.nonEditable;
      },
      action: function action() {
        budgetService.set("disableTriggerChanges", true);
        viewSelections.set("isBudgetFlyoutOpen", true);
        Ember.run.schedule("afterRender", function () {
          budgetService.set("disableTriggerChanges", false);
        });
      }
    }];
    var checkValue = function checkValue() {
      var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        cell = _ref6.cell,
        _ref6$value = _ref6.value,
        value = _ref6$value === void 0 ? "" : _ref6$value,
        keyCode = _ref6.keyCode;
      return actions.find(function (rule) {
        return rule.test({
          cell: cell,
          value: value,
          keyCode: keyCode
        });
      });
    };
    return new _uiComponents.default.COMPONENTS.CurrencyCellTemplate({
      checkValue: checkValue,
      isCopyClicked: isCopyClicked,
      onCopyClick: onCopyClick
    });
  };
});