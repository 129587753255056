define("budgeta/validators/local/divide-without-remainder", ["exports", "budgeta/services/ember-service-container", "ember-validations/validators/base"], function (_exports, _emberServiceContainer, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _base.default.extend({
    init: function init() {
      this._super();
      this.dependentValidationKeys.pushObjects([this.options.divide]);
    },
    call: function call() {
      if (this.options.if && !this.model.get(this.options.if)) {
        return;
      }
      if (!Ember.isBlank(this.model.get(this.property))) {
        var firstNumber = this.model.get(this.options.divide);
        var secondNumber = this.model.get(this.property);
        if (firstNumber && secondNumber && !isNaN(firstNumber) && !isNaN(secondNumber) && secondNumber % firstNumber !== 0) {
          this.errors.pushObject(this.options.message || intl.t("general.loan_term_divided_by_quarterly_payments_should_be_a_whole_number_1698234257450"));
        }
      }
    }
  });
});