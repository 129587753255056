define("budgeta/components/budget-structure-line", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":budget-structure-line", "isPlaceholder::dropped-budget-structure-line", "disableDrop:hidden", "displayValue::hidden"],
    attributeBindings: ["data-level", "draggable", "data-name"],
    draggable: "true",
    "data-level": Ember.computed.alias("level"),
    "data-name": Ember.computed("level", "dropGroup", "isFirstLevel", function () {
      if (!this.get("isFirstLevel")) {
        return this.get("level") !== -1 ? this.get("dropGroup") + "-level-" + this.get("level") : "";
      }
      return "first-level-" + this.get("level");
    }),
    childLevel: Ember.computed("level", function () {
      return this.get("level") * 1 + 1;
    }),
    showSubtree: Ember.computed("value", "inDragMode", "showAllLines", function () {
      return this.get("showAllLines") || this.get("value") || this.get("isPlaceholder");
    }),
    dragText: Ember.computed("inDragMode", function () {
      return (this.get("inDragMode.byText") || "").toLowerCase();
    }),
    lastLevel: Ember.computed("level", function () {
      return this.get("level") === _const.default.createBudgetLevelLimit - 1;
    }),
    disableDrop: Ember.computed("showAllLines", "inDragMode", "isFirstLevel", "value", "parentValue.id", "structureFirstLevel.levels.length", "structureRevenueLevel.levels.length", "structureCosLevel.levels.length", "structureOpexLevel.levels.length", "structureOtherLevel.levels.length", function () {
      if (this.get("showAllLines")) {
        return false;
      }
      var draggedDimension = this.get("inDragMode");
      if (!draggedDimension || this.get("isPlaceholder") || this.get("value")) {
        return false;
      }
      var isSub = Ember.get(draggedDimension, "isSub");
      var dimensionName = Ember.get(draggedDimension, "dimension.dimension");
      if (this.get("isFirstLevel")) {
        // first level

        // cheack first level limit
        if (this.get("structureFirstLevel.levels.length") + this.get("structureRevenueLevel.levels.length") === _const.default.createBudgetLevelLimit || this.get("structureFirstLevel.levels.length") + this.get("structureCosLevel.levels.length") === _const.default.createBudgetLevelLimit || this.get("structureFirstLevel.levels.length") + this.get("structureOpexLevel.levels.length") === _const.default.createBudgetLevelLimit || this.get("structureFirstLevel.levels.length") + this.get("structureOtherLevel.levels.length") === _const.default.createBudgetLevelLimit) {
          return true;
        }

        //check other groups levels
        var structureGroups = ["structureRevenueLevel", "structureCosLevel", "structureOpexLevel", "structureOtherLevel"];
        for (var i = 0; i < structureGroups.length; i++) {
          var findLevel = (this.get("".concat(structureGroups[i], ".levels")) || []).find(function (level) {
            return level.dimension.dimension === dimensionName;
          });
          if (findLevel && (!findLevel.isSub || findLevel.subLevel <= draggedDimension.subLevel)) {
            return true;
          }
        }
      } else {
        // all other levels

        // cheack level limit
        if (this.get("groupValue.levels.length") === _const.default.createBudgetLevelLimit - this.get("structureFirstLevel.levels.length")) {
          return true;
        }

        // first level reached to limit
        if ((this.get("structureFirstLevel.levels") || []).length === _const.default.createBudgetLevelLimit) {
          return true;
        }
        var firstLevelFiltered = (this.get("structureFirstLevel.levels") || []).filterBy("dimension.dimension", dimensionName).filterBy("isSub", true);
        if (isSub && firstLevelFiltered.length && firstLevelFiltered.find(function (dim) {
          return dim.subLevel >= draggedDimension.subLevel;
        })) {
          return true;
        }
        if (!isSub && firstLevelFiltered.length) {
          return true;
        }
      }
      if (this.get("parentValue")) {
        var parentDimension = dimensionName ? this.get("parentValue")[dimensionName] : null;
        if (isSub && parentDimension && draggedDimension.subLevel <= parentDimension.subLevel) {
          return true;
        }
        if (!isSub && parentDimension && parentDimension.isSub) {
          return true;
        }
        if (draggedDimension === parentDimension) {
          return true;
        }
      }
      return false;
    }),
    displayValue: Ember.computed("showAllLines", "isPlaceholder", "value.id", "inDragMode", function () {
      return this.get("showAllLines") || this.get("isPlaceholder") || this.get("value.id") || this.get("inDragMode");
    }),
    value: Ember.computed("level", "lastLevel", "groupValue.levels.@each.id", {
      get: function get() {
        if (this.get("level") >= 0) {
          return this.get("groupValue.levels")[this.get("level")];
        } else {
          return this.get("groupValue");
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),
    valueAutomated: Ember.computed("structureAutomated.[]", "groupName", "level", {
      get: function get() {
        if (this.get("level")) {
          return this.get("structureAutomated")[this.get("groupName") + this.get("level")];
        }
        return "";
      },
      set: function set(key, value) {
        if (this.get("structureAutomated")) {
          this.get("structureAutomated")[this.get("groupName") + this.get("level")] = value;
          return value;
        }
      }
    }),
    computedParentValue: Ember.computed("parentValue", "value", function () {
      if (this.get("value") && this.get("value.dimension")) {
        var parentValue = this.get("parentValue") ? Object.assign({}, this.get("parentValue")) : {};
        parentValue[this.get("value.dimension.dimension")] = this.get("value");
        return parentValue;
      }
    }),
    dragOver: function dragOver() {
      return false;
    },
    dragStart: function dragStart() {
      return false;
    },
    drag: function drag() {
      return false;
    },
    dragEnter: function dragEnter() {
      return false;
    },
    dragLeave: function dragLeave() {
      return false;
    },
    dragEnd: function dragEnd() {
      return false;
    },
    drop: function drop(event) {
      var checkLowerLevels = function checkLowerLevels(level, group) {
        for (var i = level; i < group.length; i++) {
          if (dimension.isSub && group[i].id === dimension.id && (!group[i].subLevel || group[i].subLevel <= dimension.subLevel)) {
            return true;
          }
        }
        return false;
      };
      var checkUpperLevels = function checkUpperLevels(level, group) {
        for (var i = level; i >= 0; i--) {
          if (!dimension.isSub && group[i].id === dimension.id && group[i].isSub || dimension.isSub && group[i].id === dimension.id && group[i].subLevel >= dimension.subLevel) {
            return true;
          }
        }
        return false;
      };
      if (this.get("isPlaceholder")) {
        return false;
      }
      var idArr = event.dataTransfer.getData("text/data").split("#");
      idArr[1] = idArr[1] === "true";
      var dimension = this.get("dimensions").find(function (dim) {
        if (Ember.get(dim, "id") === idArr[0]) {
          if (!idArr[1]) {
            return !Ember.get(dim, "isSub");
          } else {
            return Ember.get(dim, "isSub") && Ember.get(dim, "subLevel") == idArr[2];
          }
        }
      });
      if (dimension) {
        var groupLevel = this.get("groupValue.levels");
        if (this.get("isFirstLevel")) {
          //check firstLevel group
          if (groupLevel.length) {
            if (checkLowerLevels(this.get("level") + 1, groupLevel) || checkUpperLevels(this.get("level") - 1, groupLevel)) {
              return false;
            }
          }
          //check rest of the groups
          if (checkLowerLevels(0, this.get("structureRevenueLevel.levels")) || checkLowerLevels(0, this.get("structureCosLevel.levels")) || checkLowerLevels(0, this.get("structureOpexLevel")) || checkLowerLevels(0, this.get("structureOtherLevel.levels"))) {
            return false;
          }
        } else {
          //check group
          if (groupLevel.length) {
            if (checkLowerLevels(this.get("level") + 1, groupLevel) || checkUpperLevels(this.get("level") - 1, groupLevel)) {
              return false;
            }
          }
          //check firstLevel group
          if (checkUpperLevels(this.get("structureFirstLevel.levels.length") - 1, this.get("structureFirstLevel.levels"))) {
            return false;
          }
        }
        this.get("groupValue.levels").replace(this.get("level"), 1, [dimension]);
        this.get("groupValue.levels").notifyPropertyChange("length");
        this.set("value", dimension);
      }
      return false;
    },
    actions: {
      deleteLine: function deleteLine() {
        this.get("groupValue.levels").splice(this.get("level"));
        this.get("groupValue.levels").notifyPropertyChange("length");
        this.set("value", null);
      }
    }
  });
});