define("budgeta/controllers/video-modal", ["exports", "budgeta/controllers/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modal.default.extend({
    videoWidth: Ember.computed("model.videoWidth", function () {
      return this.get("model.videoWidth") + "";
    }),
    videoHeight: Ember.computed("model.videoHeight", function () {
      return this.get("model.videoHeight") + "";
    }),
    youtubeId: Ember.computed("model.youtubeId", {
      get: function get() {
        if (this.get("model")) {
          return this.get("model.youtubeId");
        } else {
          return null;
        }
      }
    }),
    playlist: Ember.computed("model.playlist", function () {
      return this.get("model.playlist");
    })
  });
});