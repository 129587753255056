define("budgeta/utils/ajax", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ajax;
  function ajax() {
    var _arguments = arguments;
    return new Promise(function (resolve, reject) {
      var _Ember$$;
      (_Ember$$ = Ember.$).ajax.apply(_Ember$$, (0, _toConsumableArray2.default)(_arguments)).done(resolve).fail(reject);
    });
  }
});