define("budgeta/services/subscription-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    subscription: {
      subscriptions: {},
      subscribe: function subscribe(subscriptionName, func) {
        this.subscriptions[subscriptionName] = func;
      },
      publish: function publish(subscriptionName) {
        this.subscriptions[subscriptionName] && this.subscriptions[subscriptionName]();
      }
    }
  });
});