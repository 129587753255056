define("budgeta/controllers/main/budget/import-generic-file", ["exports", "@babel/runtime/helpers/esm/objectDestructuringEmpty", "@babel/runtime/helpers/esm/extends", "@babel/runtime/helpers/esm/objectWithoutProperties", "@sibp/ui-components", "budgeta/utils/import-generic-file", "budgeta/utils/const", "budgeta/controllers/main/budget/import", "budgeta/config/environment", "budgeta/utils/excel"], function (_exports, _objectDestructuringEmpty2, _extends2, _objectWithoutProperties2, _uiComponents, _importGenericFile, _const, _import, _environment, _excel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["renderTable"]; // eslint-disable-next-line no-unused-vars
  var _default = _exports.default = _import.default.extend({
    nameColumnIsAccount: false,
    dimensions: [],
    genericFinalPreview: null,
    taggedOnly: false,
    genericBudgetRoot: null,
    reportData: null,
    rowSelectionPopupOpen: false,
    publishSubsciption: function publishSubsciption(sender, key) {
      this.get("subscriptionService.subscription").publish(key);
    },
    translationExcelData: _excel.default.translationData,
    downloadPath: function () {
      return "".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/excel");
    }.property(),
    compressedReportsData: Ember.computed("reportData", function () {
      return window.LZString.compressToBase64(this.get("reportData"));
    }),
    genericFileColumnOptions: Ember.computed("importGenericFile", "nameColumnIsAccount", "dimensions.@each.dimensionName", "dimensions.[]", function () {
      var generalGroup = Ember.A([]);
      var keysAdded = new Set();
      var store = this.store;
      var genericModule = store.peekRecord("budgetModule", "generic");
      for (var key in genericModule.get("budgetAttributes")) {
        var attr = genericModule.get("budgetAttributes")[key];
        attr = Ember.isArray(attr) && attr[0].name ? attr[0].name : attr;
        if (key !== "amount" && !keysAdded.has(key) && attr.showOnImport && (!this.get("nameColumnIsAccount") || key !== "accountId")) {
          keysAdded.add(key);
          var name = attr.importLabel || attr.label || _const.default.camelize(key);
          generalGroup.pushObject({
            text: name,
            id: key,
            lc: name.toLowerCase(),
            data: attr
          });
        }
      }

      // add dimensions
      generalGroup.pushObjects((this.get("dimensions") || []).map(function (dim) {
        return {
          text: dim["dimensionName"],
          id: dim["id"],
          data: {
            isDimension: true
          }
        };
      }));
      return generalGroup.sortBy("text");
    }),
    linesGenericFinalPreview: Ember.computed("genericFinalPreview.notAllowedLinesIndexes", "genericFinalPreview.notAllowedLinesIndexes.categoryMismatch", "genericFinalPreview.notAllowedLinesIndexes.categoryEmpty", "lineNamesForImportConsolidated", function () {
      var _this$get = this.get("genericFinalPreview"),
        notAllowedLinesIndexes = _this$get.notAllowedLinesIndexes;
      var lines = this.get("lineNamesForImportConsolidated");
      var mismatchList = notAllowedLinesIndexes && notAllowedLinesIndexes.notAllowedCategory.categoryMismatch;
      var missingList = notAllowedLinesIndexes && notAllowedLinesIndexes.notAllowedCategory.categoryEmpty;
      return lines.map(function (x) {
        x.mismatch = false;
        x.missing = false;
        if (mismatchList.includes(x.location)) {
          x.mismatch = true;
        } else if (missingList.includes(x.location)) {
          x.missing = true;
        }
        return x;
      });
    }),
    rowSelectionMismatchLinesExist: Ember.computed("lineNames.@each.notImportable", function () {
      return (this.get("lineNames") || []).some(function (line) {
        return line.notImportable;
      });
    }),
    rowSelectionPopupTooltip: Ember.computed("rowSelectionPopupOpen", function () {
      return React.createElement("div", {
        className: "tooltip-icon ".concat(this.get("rowSelectionPopupOpen") && "popup-open")
      }, React.createElement(_uiComponents.default.IMAGES.WarningSvg, null));
    }),
    rowSelectionPopupContent: Ember.computed("intl", function () {
      return React.createElement("div", {
        className: "desc"
      }, this.get("intl").t("generic-file.step-table-row-selection.pop-up-msg"));
    }),
    actions: {
      renderTable: function renderTable(stepName, _ref) {
        var _this = this;
        var _ref$renderTable = _ref.renderTable,
          renderTable = _ref$renderTable === void 0 ? true : _ref$renderTable,
          rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
        renderTable && this.set("loadingMessage", "".concat(this.get("intl").t("generic-file.load-screen.label")));
        rest.selectedDimensions && this.set("dimensions", rest.selectedDimensions);
        Ember.run.next(function () {
          _importGenericFile.default.stepsDataMap(_this, stepName, rest).start();
          renderTable && _this.set("renderTableInReact", true);
        });
      },
      unRenderTable: function unRenderTable(stepName) {
        var _this2 = this;
        var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          rest = (0, _extends2.default)({}, ((0, _objectDestructuringEmpty2.default)(_ref2), _ref2));
        if (!rest.back) {
          this.set("loadingMessage", stepName === "previewStep" ? "".concat(this.get("intl").t("generic-file.load-screen.import-label")) : "".concat(this.get("intl").t("generic-file.load-screen.label")));
        }
        Ember.$.noty.closeAll();
        Ember.run.next(function () {
          rest.closeTable && _this2.set("renderTableInReact", false);
          Ember.run.scheduleOnce("afterRender", function () {
            _importGenericFile.default.stepsDataMap(_this2, stepName, rest).end(rest);
            stepName !== "previewStep" && Ember.run.next(function () {
              _this2.set("loadingMessage", null);
            });
          });
        });
      },
      getEvaluatedData: function getEvaluatedData(stepName) {
        var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          rest = (0, _extends2.default)({}, ((0, _objectDestructuringEmpty2.default)(_ref3), _ref3));
        return _importGenericFile.default.stepsDataMap(this, stepName).getData(rest);
      },
      setTableData: function setTableData(stepName) {
        var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          rest = (0, _extends2.default)({}, ((0, _objectDestructuringEmpty2.default)(_ref4), _ref4));
        var updateType = arguments.length > 2 ? arguments[2] : undefined;
        _importGenericFile.default.stepsDataMap(this, stepName, rest).update(updateType);
      },
      displayTagged: function displayTagged() {
        this.toggleProperty("taggedOnly");
      },
      cancel: function cancel() {
        this.set("loadingMessage", null);
        this.set("renderTableInReact", false);
        this._super();
      },
      exportExcel: function exportExcel(report) {
        this.set("reportData", JSON.stringify(report));
        Ember.run.scheduleOnce("afterRender", function () {
          Ember.$("form.excel-data").submit();
        });
      },
      showRowSelectionPopup: function showRowSelectionPopup() {
        this.set("rowSelectionPopupOpen", true);
      },
      hideRowSelectionPopup: function hideRowSelectionPopup() {
        this.set("rowSelectionPopupOpen", false);
      }
    }
  });
});