define("budgeta/templates/components/formula-editor-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pApJr772",
    "block": "{\"statements\":[[\"block\",[\"modal-dialog\"],null,[[\"close\",\"confirm\",\"next\",\"back\",\"confirmActionName\",\"title\",\"templateName\",\"hideCancel\",\"showBack\",\"lastStep\",\"confirmDisabled\",\"hideHeader\"],[[\"get\",[\"close\"]],[\"get\",[\"confirm\"]],[\"get\",[\"next\"]],[\"get\",[\"back\"]],[\"get\",[\"confirmActionName\"]],[\"get\",[\"title\"]],[\"get\",[\"templateName\"]],[\"get\",[\"hideCancel\"]],[\"get\",[\"showBack\"]],[\"get\",[\"lastStep\"]],[\"get\",[\"confirmDisabled\"]],[\"get\",[\"hideHeader\"]]]],0]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/formula-editor-modal.hbs"
    }
  });
});