define("budgeta/templates/components/intacct-entities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cWyE4SKa",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"isLoadingSettings\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question wizard-question-single modal-padding\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"t\"],[\"intacct-entities.wizard-question\"],null],false],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"wizard-question-radio-simple\"],null,[[\"name\",\"value\",\"selectedOption\",\"label\"],[\"allEntities\",0,[\"get\",[\"data\",\"singleEntity\"]],[\"helper\",[\"t\"],[\"general.all_entities_1698824574741\"],null]]]],false],[\"text\",\"\\n      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"checkbox-with-dropdown\"],[\"flush-element\"],[\"text\",\"\\n        \"],[\"append\",[\"helper\",[\"wizard-question-radio-simple\"],null,[[\"name\",\"value\",\"selectedOption\",\"label\"],[\"singleEntity\",1,[\"get\",[\"data\",\"singleEntity\"]],[\"helper\",[\"t\"],[\"general.single_entity_1698824574738\"],null]]]],false],[\"text\",\"\\n        \"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"enabled\",\"placeholder\",\"value\",\"content\",\"optionLabelPath\",\"classNames\"],[[\"get\",[\"singleEntity\"]],[\"helper\",[\"t\"],[\"placeholders.please-select\"],null],[\"get\",[\"data\",\"selectedEntity\"]],[\"get\",[\"data\",\"entities\"]],\"name\",\"wrap\"]]],false],[\"text\",\"\\n      \"],[\"close-element\"],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"unknown\",[\"budgeta-spinner\"]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/intacct-entities.hbs"
    }
  });
});