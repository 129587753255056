define("budgeta/components/trx-row", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/assumption"], function (_exports, _emberServiceContainer, _const, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    pnlName: Ember.computed.alias("budgetService.pnlName"),
    createdByDisplayName: Ember.computed.oneWay("row.createdBy.displayName"),
    modifiedByDisplayName: Ember.computed.readOnly("row.modifiedBy.displayName"),
    classNames: ["trx-row-content"],
    MomentFullDateDisplayFormat: _const.default.MomentFullDateDisplayFormat,
    MomentMonthYearDisplayFormat: _const.default.MomentMonthYearDisplayFormat,
    noMapping: Ember.computed("row.budgetLineId", "row.computedBsLine", function () {
      return !this.get("row.budgetLineId") && !this.get("row.computedBsLine");
    }),
    maxEndDate: Ember.computed("budgetService.selectedRoot.absoluteRoot.budgetAttributes.endDate", function () {
      return new Date(this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.endDate")).endOfMonth();
    }),
    minStartDate: Ember.computed("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate", function () {
      return new Date(this.get("budgetService.selectedRoot.absoluteRoot.budgetAttributes.startDate")).addYears(-2);
    }),
    amountRounding: Ember.computed("row.readOnly", function () {
      return this.get("row.readOnly") ? 2 : null;
    }),
    booksReadOnly: Ember.computed.or("row.readOnly", "row.isIntacct"),
    lineCurrency: Ember.computed("row.{currency,unitType}", "isModelDetailsView", {
      get: function get() {
        if (this.get("isModelDetailsView")) {
          return _assumption.default.getUnitTypeAsPctCurrency(this.get("row.unitType"));
        }
        return this.get("row.currency");
      },
      set: function set(key, value) {
        Ember.set(this.get("row"), "currency", value);
        return value;
      }
    }),
    checkFilter: function checkFilter(filterName, newValue, dateStr) {
      var filtersSet = this.get("row.filters.".concat(filterName)); // The set that the current transaction assigned to
      var allTransactionMap = this.get("filters.".concat(filterName, ".transactions")); // Retreving all the trxs divided by values
      var filterToValueMapping = this.get("filterToValueMapping");
      var trxValue = filterToValueMapping[filterName].call(this, this.get("row"));
      if (filtersSet) {
        filtersSet.size -= 1; // Removing the transaction from the set
      }
      var pnlName = this.get("pnlName");
      if (filterName) {
        var filter = this.get("filters.".concat(filterName, ".filters"));
        var hasFilter;
        if (filterName === "fiscalDates" || filterName === "trxDates" || filterName === "created" || filterName === "modified") {
          if (newValue && dateStr || dateStr === "") {
            hasFilter = filter.findBy("dateStr", dateStr);
          } else {
            hasFilter = false;
          }
        } else if (filterName === "statementOptions") {
          hasFilter = filter.findBy("statementId", newValue);
        } else if (filterName === "budgetLines") {
          hasFilter = filter.findBy("budgetId", newValue);
        } else if (filterName === "transactionTypes") {
          hasFilter = filter.findBy("id", newValue);
        } else {
          hasFilter = filter.findBy("name", newValue);
        }
        var transctionsSet;
        if (!hasFilter) {
          transctionsSet = {
            size: 1
          }; // Creating the new Transactions set for the new Value
          var newObject;
          // need to add
          if (filterName === "budgetLines") {
            newObject = {
              selected: false,
              name: this.get("row.computedBudgetLine.name"),
              title: this.get("row.computedBudgetLine.uniqueName"),
              budgetId: this.get("row.computedBudgetLine.id"),
              transactions: transctionsSet
            };
          } else if (filterName === "fiscalDates" || filterName === "trxDates" || filterName === "created" || filterName === "modified") {
            newObject = {
              selected: false,
              name: newValue,
              dateStr: dateStr,
              transactions: transctionsSet
            };
          } else if (filterName === "statementOptions") {
            var statement = "";
            if (newValue === "both") {
              statement = intl.t("general.trx-row-1", {
                var1: pnlName
              });
            } else if (newValue === "bs") {
              statement = intl.t("general.name_1695611604193");
            } else if (newValue === "pnl") {
              statement = pnlName;
            } else {
              statement = intl.t("general.name_1695611602487");
            }
            newObject = {
              selected: false,
              name: statement,
              statementId: newValue,
              transactions: transctionsSet
            };
          } else if (filterName === "balanceSheetLines") {
            var bsLine = this.get("balanceSheetLines").findBy("text", newValue);
            if (bsLine && bsLine.id) {
              newObject = {
                selected: false,
                name: newValue,
                bsId: bsLine.id,
                transactions: transctionsSet
              };
            }
          } else if (filterName === "transactionTypes") {
            newObject = {
              selected: false,
              name: newValue === "trx" ? intl.t("general.text_1695611610226") : intl.t("general.text_1695611610237"),
              id: newValue,
              transactions: transctionsSet
            };
          } else {
            newObject = {
              selected: false,
              name: newValue,
              transactions: transctionsSet
            };
          }
          if (newObject) {
            filter.addObject(newObject);
            this.get("filterMap.".concat(filterName, ".filters")).addObject(newObject);
          }
          this.set("filters.".concat(filterName, ".filters"), filter.sortBy("name"));
          if (allTransactionMap) {
            allTransactionMap[trxValue] = transctionsSet; // Creating a new set for the new value
          }
        } else {
          if (allTransactionMap && allTransactionMap[trxValue]) {
            transctionsSet = allTransactionMap[trxValue]; // Getting all the transactions

            if (transctionsSet) {
              transctionsSet.size += 1; // Adding the transaction to the filters list
            }
          }
        }

        // If the trx does not contain a filter yet
        if (this.get("row.filters") && transctionsSet) {
          this.set("row.filters.".concat(filterName), transctionsSet); // Resetting the trasactions set
        }
      }
    },
    checkDimension: function checkDimension(trx) {
      var _this = this;
      var dimensionsMap = this.get("dimensionsMap");
      var computedBudgetLine = Ember.get(trx, "computedBudgetLine");
      if (computedBudgetLine) {
        (this.get("budgetService.dimensions") || []).forEach(function (d) {
          var filtersSet = _this.get("row.filters.".concat(Ember.get(d, "uniqueId"))) || []; // The set that the current transaction assigned to
          filtersSet.forEach(function (dimSet) {
            if (dimSet) {
              dimSet.size -= 1;
            }
          });
        });
        (computedBudgetLine.get("forecastAttributes.dimensions") || []).forEach(function (budgetDimension) {
          var dimension = Ember.get(budgetDimension, "dimension");
          var values = (Ember.get(budgetDimension, "values") || Ember.A([])).mapBy("value").filter(function (v) {
            return v;
          });
          var allTransactionMap = _this.get("filters.".concat(dimension, ".transactions")); // Retreving all the trxs divided by values
          var transctionsSet;
          var filter = _this.get("filters.".concat(dimension, ".filters"));

          // Checking if the filter actually exist before updating the transactions
          if (filter) {
            // Reseting the dimensions list for the transaction
            _this.set("row.filters.".concat(dimension), Ember.A([])); // Resetting the trasactions set
            // If the dimesion is empty
            if (!values.length) {
              values.push("_empty");
            }
            for (var i = 0; i < values.length; i++) {
              var trxValue = values[i] === "_empty" ? "" : values[i];
              if (filter.findBy("budgetId", values[i])) {
                if (allTransactionMap && allTransactionMap[trxValue]) {
                  transctionsSet = allTransactionMap[trxValue]; // Getting all the transactions

                  if (transctionsSet) {
                    transctionsSet.size += 1; // Adding the transaction to the filters list
                  }
                }
              } else {
                transctionsSet = {
                  size: 1
                }; // Creating the new Transactions set for the new Value
                var newObject = {
                  selected: false,
                  name: dimensionsMap[dimension].values[values[i]],
                  budgetId: values[i],
                  transactions: transctionsSet,
                  title: dimensionsMap[dimension].values[values[i]]
                };
                filter.addObject(newObject);
                if (_this.get("filterMap.".concat(dimension, ".filters"))) {
                  _this.get("filterMap.".concat(dimension, ".filters")).addObject(newObject);
                }
                if (allTransactionMap) {
                  allTransactionMap[trxValue] = transctionsSet; // Creating a new set for the new value
                }
              }
              if (transctionsSet && _this.get("row.filters.".concat(dimension))) {
                _this.get("row.filters.".concat(dimension)).addObject(transctionsSet); // Adding the transaction set to the dimension
              }
            }
          }
        });
      }
    },
    change: function change() {
      var _this$get,
        _this2 = this;
      this.set("blockRecalc", true);
      this.checkFilter("descriptions", this.get("row.description"));
      this.checkFilter("accountIds", this.get("row.accountId"));
      this.checkFilter("statementOptions", this.get("row.statement"));
      this.checkFilter("fiscalDates", this.get("row.fiscalDate"), this.get("row.fiscalDate") ? this.get("row.fiscalDate").format(_const.default.SugarMonthYearDisplayFormat) : "");
      this.checkFilter("trxDates", this.get("row.trxDate"), this.get("row.trxDate") ? moment(this.get("row.trxDate").endOfMonth().reset()).format(_const.default.MomentFullDateDisplayFormat) : "");
      this.checkFilter("amounts", this.get("row.amount") ? this.get("row.amount") * 1 : this.get("row.amount"));
      this.checkFilter("currencies", this.get("row.currency"));
      this.checkFilter("unitTypes", this.get("row.unitType"));
      this.checkFilter("budgetLines", this.get("row.budgetLineId"));
      this.checkFilter("categories", this.get("row.computedCategory"));
      var bsLine;
      if (this.get("row.computedBsLine")) {
        bsLine = Ember.get(this.get("balanceSheetLines").findBy("id", this.get("row.computedBsLine")), "text");
      }
      this.checkFilter("balanceSheetLines", bsLine);
      this.checkFilter("transactionTypes", this.get("row.type"));
      this.checkFilter("sources", this.get("row.sourceToDisplay"));
      this.checkFilter("trxIds", this.get("row.trxId"));
      this.checkFilter("created", this.get("row.created"), this.get("row.created") ? moment(this.get("row.created")).format(_const.default.MomentDateAndTimeDisplayFormat) : "");
      this.checkFilter("createdBy", this.get("row.createdBy.displayName"));
      this.checkFilter("notes", this.get("row.note"));
      this.checkFilter("books", this.get("row.books"));
      this.checkDimension(this.get("row"));
      (_this$get = this.get("submit")) === null || _this$get === void 0 || _this$get(Ember.run.bind(this, "checkModifiedFilters"));
      this.get("cache").clearActualsCache();
      Ember.run.next(function () {
        _this2.set("blockRecalc", false);
      });
    },
    checkModifiedFilters: function checkModifiedFilters() {
      if (!this.isDestroyed) {
        this.checkFilter("modified", this.get("row.modified"), this.get("row.modified") ? moment(this.get("row.modified")).format(_const.default.MomentDateAndTimeDisplayFormat) : "");
        this.checkFilter("modifiedBy", this.get("row.modifiedBy.displayName"));
      }
    },
    actions: {
      lineToggle: function lineToggle() {
        this.sendAction("lineToggle", this.get("row"));
        this.change();
      },
      duplicateTrx: function duplicateTrx() {
        this.sendAction("duplicateTrx", this.get("row"));
      },
      reverseTrx: function reverseTrx() {
        this.sendAction("reverseTrx", this.get("row"));
      },
      deleteTrx: function deleteTrx() {
        this.sendAction("deleteTrx", this.get("row"));
      },
      changed: function changed() {
        this.change();
      },
      onStatementSelect: function onStatementSelect(transaction, statement) {
        if (statement === "pnl") {
          transaction.set("trxDate", null);
        }
      }
    }
  });
});