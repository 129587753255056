define("budgeta/templates/components/create-version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kUvRJsm4",
    "block": "{\"statements\":[[\"open-element\",\"form\",[]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"confirm\"],[[\"on\"],[\"submit\"]]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"create-version.name\"],null],false],[\"close-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"type\",\"required\",\"value\",\"placeholder\"],[\"text\",true,[\"get\",[\"model\",\"newVersionName\"]],[\"helper\",[\"t\"],[\"create-version.type-version-name\"],null]]]],false],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/create-version.hbs"
    }
  });
});