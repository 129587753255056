define("budgeta/models/scenario", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    scenario: _emberData.default.attr("string"),
    addedChildren: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    deletedChildren: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    budgetAttributes: _emberData.default.attr("budgetAttributes", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    budget: _emberData.default.belongsTo("budget", {
      async: false,
      inverse: "scenarios"
    }),
    addedChildrenLength: Ember.computed("addedChildren", "addedChildren.[]", function () {
      return this.get("addedChildren").length;
    }),
    deletedChildrenLength: Ember.computed("deletedChildren", "deletedChildren.[]", function () {
      return this.get("deletedChildren").length;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      // get computed properties to ensure they are computed for dependent keys
      this.get("addedChildrenLength");
      this.get("deletedChildrenLength");
    }
  });
});