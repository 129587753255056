define("budgeta/models/object-models/intacct-wizard", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Object.extend({
    intacctBudgetDefault: false,
    importExport: "import",
    exportSelection: "add",
    exportPeriod: "Monthly",
    exportPnl: true,
    exportBS: false,
    exportModel: false,
    hasDepartmentCategory: "",
    reports: null,
    dateRangeOptions: null,
    entities: null,
    isMultiCurrency: false,
    dateRangeOption: "ytd",
    customDateRangeStart: null,
    customDateRangeEnd: null,
    selectedBudgetType: "top",
    selectedBudgetId: null,
    selectedEntity: null,
    selectedCurrency: null,
    budgetVersion: null,
    addDataType: "replace",
    refresh: true,
    isSkipSteps: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        reports: {
          pnl: true,
          cash: true,
          bs: true,
          stat: true
        },
        dateRangeOptions: [{
          id: "ytd",
          text: intl.t("general.text_1695611610545")
        }, {
          id: "ytde",
          text: intl.t("general.text_1695611610534")
        }, {
          id: "prev",
          text: intl.t("general.intacct-wizard-5", {
            var1: new Date().addMonths(-1).format("{Month} {yyyy}")
          })
        }, {
          id: "custom",
          text: intl.t("general.text_1695611610523"),
          isCustom: true
        }],
        entites: [],
        isSkipSteps: {
          exportMissingPnl: true,
          exportMissingBs: true,
          exportMissingLocations: true,
          exportMissingDimensions: true,
          exportMissingDimensionsValues: true
        }
      });
    },
    selectDimensionsData: Ember.computed("intacctDimensions", function () {
      var result = [];
      var savedFilter = this.get("intacctImportFilter");
      (this.get("intacctDimensions") || []).forEach(function (dimData) {
        if (dimData.values.length) {
          var idProp = dimData.isCustomDim ? "NAME" : "ID";
          var getPathForValue = function getPathForValue(v) {
            if (v.PARENTID) {
              var parent = dimData.values.findBy("ID", v.PARENTID);
              if (parent) {
                return getPathForValue(parent) + " → " + v.NAME;
              }
            }
            return v.NAME;
          };
          dimData.selectedValue = "*";
          if (Ember.isArray(savedFilter)) {
            var savedFilterDim = savedFilter.filter(function (dim) {
              return dim.dimension.toUpperCase() === dimData.internalName.toUpperCase();
            })[0];
            if (savedFilterDim && (savedFilterDim.isValuesFiltered || savedFilterDim.selectedValue)) {
              var savedValue = Ember.get(savedFilterDim, "selectedValues") || Ember.get(savedFilterDim, "selectedValue");
              if (savedValue) {
                if (typeof savedValue === "string") {
                  savedFilterDim.isSingleSelectedValue = true;
                  dimData.selectedValue = [savedValue];
                } else {
                  dimData.selectedValue = Object.keys(savedValue);
                }
              }
              if (Ember.get(savedFilterDim, "importNoValues")) {
                dimData.selectedValue.push("no_val");
              }
            }
          }
          if (dimData.selectedValue === "*") {
            dimData.selectedValue = (dimData.values || []).map(function (val) {
              return val[idProp];
            });
            dimData.selectedValue.push("no_val");
          }
          dimData.options = [];
          dimData.options.pushObjects(dimData.values.map(function (v) {
            return {
              id: v[idProp],
              text: getPathForValue(v)
            };
          }).sortBy("text"));
          dimData.options.push({
            text: intl.t("general.intacct-wizard-6", {
              var1: dimData.dimension
            }),
            id: "no_val"
          });
          result.push(dimData);
        }
      });
      return result;
    })
  });
});