define("budgeta/locales/en", ["exports", "ember-intl/models/locale"], function (_exports, _locale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _locale.default.extend({
    locale: "en",
    messages: {
      product: {
        info: "{product} will cost {price, number, EUR} if ordered by {deadline, date, time}",
        html: {
          info: "<strong>{product}</strong> will cost <em>{price, number, EUR}</em> if ordered by {deadline, date, time}"
        }
      }
    }
  });
});