define("budgeta/controllers/edit-custom-view-modal", ["exports", "budgeta/controllers/modal", "ember-validations"], function (_exports, _modal, _emberValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _modal.default.extend({
    intl: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    mainBudgetForecastController: Ember.inject.controller("main/budget/forecast"),
    oldViewName: Ember.computed.oneWay("model.viewName"),
    viewName: Ember.computed("model.viewName", "mainBudgetForecastController.viewName", {
      get: function get() {
        return this.get("model.viewName");
      },
      set: function set(key, value) {
        return this.set("mainBudgetForecastController.viewName", value.trim());
      }
    }),
    validations: {
      viewName: {
        presence: true,
        inline: (0, _emberValidations.validator)(function () {
          var _this = this;
          if ((this.get("viewSelections.customViews") || []).some(function (view) {
            var _this$model$get;
            return view.name === ((_this$model$get = _this.model.get("viewName")) === null || _this$model$get === void 0 ? void 0 : _this$model$get.trim()) && view.name !== _this.model.get("oldViewName");
          })) {
            return this.get("intl").t("view-toolbar.custom-view.errors.double-name-warning");
          }
        })
      }
    }
  });
});