define("budgeta/templates/components/growl-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/vKRlQ7z",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"alert\\n    \",[\"helper\",[\"if\"],[[\"get\",[\"notification\",\"isError\"]],\"alert-danger\"],null],\"\\n    \",[\"helper\",[\"if\"],[[\"get\",[\"notification\",\"isAlert\"]],\"alert-warning\"],null],\"\\n    \",[\"helper\",[\"if\"],[[\"get\",[\"notification\",\"isInfo\"]],\"alert-success\"],null],\"\\n    alert-dismissible\"]]],[\"static-attr\",\"role\",\"alert\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"button\",[]],[\"static-attr\",\"type\",\"button\"],[\"static-attr\",\"class\",\"close\"],[\"static-attr\",\"data-dismiss\",\"alert\"],[\"static-attr\",\"aria-label\",\"Close\"],[\"flush-element\"],[\"open-element\",\"span\",[]],[\"static-attr\",\"aria-hidden\",\"true\"],[\"flush-element\"],[\"text\",\"×\"],[\"close-element\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"append\",[\"unknown\",[\"notification\",\"content\"]],true],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/growl-instance.hbs"
    }
  });
});