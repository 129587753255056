define("budgeta/utils/conversion", ["exports", "@babel/runtime/helpers/esm/typeof", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/config/environment", "lodash/lodash"], function (_exports, _typeof2, _emberServiceContainer, _const, _budgetUtils, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/new-module-imports */

  var Conversion = {
    addDimensionValue: function addDimensionValue(values, name, parentValue, isCategory) {
      if (!name) {
        return undefined;
      }
      var value = values.find(function (v) {
        return v.name === name && (!parentValue || parentValue && parentValue.uniqueId === v.parent);
      });
      if (!value) {
        if (parentValue && !isCategory) {
          // try to find a value with the same name but no parent and assign it this parent
          value = values.find(function (v) {
            return v.name === name && !v.parent && v !== parentValue;
          });
          if (value) {
            Ember.set(value, "parent", parentValue.uniqueId);
          }
        }
        if (!value) {
          value = {
            uniqueId: _const.default.generateUUID(),
            name: name,
            parent: parentValue && parentValue.uniqueId
          };
          values.pushObject(value);
        }
      }
      return value;
    },
    createDepartmentValue: function createDepartmentValue(departmentDimension, department, version) {
      var values = departmentDimension.get("values"),
        parentValue,
        name = (typeof department === "string" ? department : department.get("name")).trim();
      if ((0, _typeof2.default)(department) === "object" && department.get("parent.isDepartment")) {
        parentValue = Conversion.createDepartmentValue(departmentDimension, department.get("parent"), version);
      }
      var newValue = Conversion.addDimensionValue(values, name, parentValue);
      if (department.get("budgetAttributes.costCenter")) {
        var newMapping = department.store.createRecord("dimension-value-mapping", {
          dimensionValueIds: [Ember.get(newValue, "uniqueId")],
          dimensionId: departmentDimension.get("uniqueId"),
          financialDimensionValueId: department.get("budgetAttributes.costCenter"),
          financialDimensionValueName: department.get("name"),
          budget: department.get("absoluteRoot")
        });
        if (!version) {
          newMapping.save();
        }
      }
      return newValue;
    },
    createGeographyValue: function createGeographyValue(values, geo) {
      var parentValue,
        name = (typeof geo === "string" ? geo : geo.get("name")).trim();
      if ((0, _typeof2.default)(geo) === "object" && geo.get("parent.isGeography")) {
        parentValue = Conversion.createGeographyValue(values, geo.get("parent"));
      }
      return Conversion.addDimensionValue(values, name, parentValue);
    },
    upgradeTo2: function upgradeTo2(budgetRoot, version) {
      version = version || budgetRoot.get("readOnly");
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Conversion.convertDimensions(budgetRoot, version).then(function () {
          Conversion.actualAccountsMapping(budgetRoot, version).then(function () {
            if (version) {
              resolve();
            } else {
              budgetRoot.save().then(resolve, reject);
            }
          }, reject);
        }, reject);
      });
    },
    addCategoryValue: function addCategoryValue(values, cat, parentCat) {
      if (!cat || !cat.name) {
        return;
      }
      var thisCat = Conversion.addDimensionValue(values, cat.name.trim(), parentCat, true);
      if (cat.children) {
        cat.children.forEach(function (child) {
          Conversion.addCategoryValue(values, child, thisCat);
        });
      }
    },
    findDepartmentValue: function findDepartmentValue(budgetLine, name, departmentDimension) {
      var matching = departmentDimension.get("values").filter(function (v) {
        return Ember.get(v, "name").trim() === name.trim();
      });
      if (matching.length > 1) {
        var result = matching[0];
        matching.forEach(function (match) {
          if (Ember.get(match, "parent")) {
            var parentValue = departmentDimension.get("values").findBy("uniqueId", Ember.get(match, "parent"));
            if (parentValue && _budgetUtils.default.belongsToDepartment(budgetLine, Ember.get(parentValue, "name"))) {
              result = match;
            }
          }
        });
        return Ember.get(result, "uniqueId");
      } else if (matching.length === 1) {
        return Ember.get(matching[0], "uniqueId");
      } else {
        // error
        Ember.Logger.error("No department found", name);
        return null;
      }
    },
    convertDepartment: function convertDepartment(budgetLine, budgetAttributes, departmentDimension) {
      var result = false;
      if (Ember.get(budgetAttributes, "department.length") && Ember.get(budgetAttributes, "department").findBy("name")) {
        result = true;
        var dimData = {
          dimension: departmentDimension.get("uniqueId"),
          allocatePerCapita: Ember.get(budgetAttributes, "departmentAllocationPerCapita"),
          allocatePerCapitaType: Ember.get(budgetAttributes, "departmentPerCapitaType"),
          values: Ember.get(budgetAttributes, "department").filter(function (d) {
            return Ember.get(d, "name").trim();
          }).map(function (d) {
            return {
              value: Conversion.findDepartmentValue(budgetLine, Ember.get(d, "name"), departmentDimension),
              allocation: Ember.get(d, "allocation")
            };
          })
        };
        if (!Ember.get(budgetAttributes, "dimensions")) {
          Ember.set(budgetAttributes, "dimensions", []);
        }
        Ember.get(budgetAttributes, "dimensions").pushObject(dimData);
      }
      var convertFilters = Conversion.convertDimensionFilters(budgetLine, budgetAttributes, departmentDimension, "filterDepartments");
      if (budgetLine.get("budgetType") === "expense-allocated" && Ember.get(budgetAttributes, "appliesTo") === "d") {
        // handle expense allocation per capita
        result = true;
        Ember.set(budgetAttributes, "applyToDimension", departmentDimension.get("uniqueId"));
        Ember.set(budgetAttributes, "applyToDimensionValues", (Ember.get(budgetAttributes, "applyToDepartment") || "").split(",").map(function (v) {
          return Conversion.findDimensionValue(v, departmentDimension);
        }).filter(function (v) {
          return v;
        }));
      }
      return result || convertFilters;
    },
    convertDimensionFilters: function convertDimensionFilters(budgetLine, budgetAttributes, dimension, filterName) {
      var result = false;
      if (Ember.get(budgetAttributes, filterName + ".length")) {
        var newFilter = {
          dimension: dimension.get("uniqueId"),
          values: Ember.get(budgetAttributes, filterName).filter(function (d) {
            return d && d.trim();
          }).map(function (d) {
            if (filterName === "filterDepartments") {
              return Conversion.findDepartmentValue(budgetLine, d, dimension);
            }
            if (filterName === "filterCompany") {
              var companyLine = _budgetUtils.default.findLineByUniqueId(budgetLine.get("store"), budgetLine.get("cache"), d, budgetLine.get("absoluteRoot.id"));
              return Conversion.findDimensionValue(companyLine && companyLine.get("name"), dimension);
            } else {
              return Conversion.findDimensionValue(d, dimension);
            }
          })
        };
        if (newFilter.values.length) {
          result = true;
          var filterDimensions = Ember.get(budgetAttributes, "filterDimensions");
          if (!filterDimensions) {
            Ember.set(budgetAttributes, "filterDimensions", [newFilter]);
          } else {
            filterDimensions.pushObject(newFilter);
          }
        }
      }
      return result;
    },
    findDimensionValue: function findDimensionValue(name, dimension) {
      var value = name && dimension.get("values").find(function (v) {
        return Ember.get(v, "name") && name.trim() && Ember.get(v, "name").trim() === name.trim();
      });
      return value && Ember.get(value, "uniqueId");
    },
    convertDimension: function convertDimension(budgetLine, budgetAttributes, dimension, dimensionName) {
      var result = false;
      if (Ember.get(budgetAttributes, dimensionName + ".length") && Ember.get(budgetAttributes, dimensionName).findBy("name")) {
        result = true;
        var dimData = {
          dimension: dimension.get("uniqueId"),
          allocatePerCapita: false,
          allocatePerCapitaType: "employee",
          values: Ember.get(budgetAttributes, dimensionName).filter(function (d) {
            return Ember.get(d, "name").trim();
          }).map(function (d) {
            return {
              value: Conversion.findDimensionValue(Ember.get(d, "name"), dimension),
              allocation: Ember.get(d, "allocation")
            };
          })
        };
        if (!Ember.get(budgetAttributes, "dimensions")) {
          Ember.set(budgetAttributes, "dimensions", []);
        }
        Ember.get(budgetAttributes, "dimensions").pushObject(dimData);
      }
      var convertFilters = Conversion.convertDimensionFilters(budgetLine, budgetAttributes, dimension, "filter ".concat(dimensionName).camelize());
      return result || convertFilters;
    },
    convertByGeo: function convertByGeo(budgetLine, budgetAttributes, dimension, linesToSave, version, scenario) {
      var promises = [];
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (Ember.get(budgetAttributes, "benefitsByGeo.length") && Ember.get(budgetAttributes, "benefitsByGeo").findBy("value")) {
          Ember.get(budgetAttributes, "benefitsByGeo").filterBy("value").forEach(function (v) {
            // create advanced benefit for benfit by geo
            if (Ember.get(v, "geography")) {
              var geoValue = Conversion.findDimensionValue(Ember.get(v, "geography"), dimension);
              var newBenefitName = intl.t("general.conversion-1", {
                var1: Ember.get(v, "geography")
              });
              var newBenefit = (budgetLine.get("children") || []).find(function (child) {
                return child.get("budgetType") === "expense-benefit" && child.get("name") === newBenefitName;
              }) || _const.default.createBudget({
                store: budgetLine.store,
                type: "expense-benefit",
                name: newBenefitName,
                parent: budgetLine,
                budgetAttributes: {
                  amount: isNaN(Ember.get(v, "value")) ? Ember.get(v, "value") : Ember.get(v, "value") / 100,
                  amountType: 0,
                  paymentAfter: Ember.get(budgetAttributes, "paymentAfter"),
                  payOnTermination: false,
                  filterDimensions: [{
                    dimension: dimension.get("uniqueId"),
                    values: [geoValue]
                  }]
                }
              });
              // go through all children that have benefit override and mark the advanced benefit as deleted
              budgetLine.get("children").forEach(function (child) {
                if (child.get("budgetType") !== "expense-benefit" && (_budgetUtils.default.getInheritedAttributes(child, scenario).region || []).map(function (geo) {
                  return (Ember.get(geo, "name") || "").trim();
                }).includes((Ember.get(v, "geography") || "").trim())) {
                  if (!child.get("budgetAttributes.benefits")) {
                    child.set("budgetAttributes.benefits", 0);
                  } else {
                    if (!child.get("budgetAttributes.deletedBenefits")) {
                      child.set("budgetAttributes.deletedBenefits", []);
                    }
                    child.get("budgetAttributes.deletedBenefits").pushObject(newBenefit.get("uniqueId"));
                  }
                  linesToSave.addObject(child);
                }
              });
              if (!version) {
                if (newBenefit.get("isNew") && !newBenefit.get("isSaving")) {
                  promises.push(newBenefit.save());
                }
              } else {
                budgetLine.get("children").addObject(newBenefit);
              }
            } else {
              // if there was benefit amout defined for all and there wasn't once defined at the line level, set it as default
              if (!Ember.get(budgetAttributes, "benefits")) {
                Ember.set(budgetAttributes, "benefits", Ember.get(v, "value"));
              }
            }
          });
          if (promises.length) {
            Ember.RSVP.all(promises).then(function (newChildren) {
              budgetLine.get("children").pushObjects(newChildren);
              if (version) {
                resolve();
              } else {
                budgetLine.save().then(resolve, reject);
              }
            }, reject);
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    convertCompany: function convertCompany(budgetLine, budgetAttributes, dimension) {
      var result = false;
      if (budgetLine.get("isCompany")) {
        result = true;
        var dimData = {
          dimension: dimension.get("uniqueId"),
          allocatePerCapita: false,
          allocatePerCapitaType: "employee",
          values: [{
            value: Conversion.findDimensionValue(Ember.get(budgetLine, "name"), dimension),
            allocation: 100
          }]
        };
        if (!Ember.get(budgetAttributes, "dimensions")) {
          Ember.set(budgetAttributes, "dimensions", []);
        }
        Ember.get(budgetAttributes, "dimensions").pushObject(dimData);
      }
      var convertFilters = Conversion.convertDimensionFilters(budgetLine, budgetAttributes, dimension, "filterCompany");
      return result || convertFilters;
    },
    convertCategory: function convertCategory(budgetLine, budgetAttributes, dimension, attr) {
      var result = false;
      if (Ember.get(budgetAttributes, attr + ".length")) {
        result = true;
        // special handling for salary & wages and for license which have seperate categories for each component
        var currentValues = Ember.get(budgetAttributes, attr);
        if (budgetLine.get("budgetType") === "group-payroll" || budgetLine.get("budgetType") === "sales-perpetual-license") {
          currentValues = currentValues.split(",");
        } else {
          currentValues = [currentValues];
        }
        var dimData = {
          dimension: dimension.get("uniqueId"),
          allocatePerCapita: false,
          allocatePerCapitaType: "employee",
          values: currentValues.map(function (value, idx) {
            if (Ember.isEmpty(value)) {
              if (budgetLine.get("budgetType") === "group-payroll") {
                value = _const.default.DEFAULT_SALARY_CATEGORIES_ARRAY[idx];
              } else {
                value = idx === 0 ? intl.t("general.name_1695611602657") : intl.t("general.name_1695611602647");
              }
            }
            return {
              value: Conversion.findDimensionValue(value, dimension)
            };
          })
        };
        if (!Ember.get(budgetAttributes, "dimensions")) {
          Ember.set(budgetAttributes, "dimensions", []);
        }
        Ember.get(budgetAttributes, "dimensions").pushObject(dimData);
      }
      var convertFilters = Conversion.convertDimensionFilters(budgetLine, budgetAttributes, dimension, "filterCategory");
      return result || convertFilters;
    },
    convertDimensions: function convertDimensions(budgetRoot, version) {
      var promises = [];
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var companyDimension,
          departmentDimension,
          geoDimension,
          productDimension,
          categoryDimension,
          newDimensions = [];
        var departments = _budgetUtils.default.getAttributeValuesForBudget(budgetRoot, "department")["department"];
        var regions = _budgetUtils.default.getAttributeValuesForBudget(budgetRoot, "region")["region"];
        var products = _budgetUtils.default.getAttributeValuesForBudget(budgetRoot, "product")["product"];
        var categories = _budgetUtils.default.getAttributeValuesForBudget(budgetRoot, "category")["category"];
        categories.addObjects(_budgetUtils.default.getAttributeValuesForBudget(budgetRoot, "revenueType")["revenueType"]);

        // remove existing dimensions (if previous conversion failed)
        if (budgetRoot.get("dimensions.length") && !version) {
          budgetRoot.get("dimensions").forEach(function (d) {
            return d && d.destroyRecord();
          });
        }

        // convert companies
        if (budgetRoot.get("isMultiCompany")) {
          companyDimension = (budgetRoot.get("dimensions") || Ember.A([])).findBy("type", "entity") || budgetRoot.store.createRecord("dimension", {
            uniqueId: _const.default.generateUUID(),
            name: intl.t("general.name_1695611601441"),
            type: "entity",
            values: [],
            allowHierarchy: false,
            allowAllocation: false,
            budget: budgetRoot
          });
          budgetRoot.store.peekAll("budget").filterBy("isCompany").forEach(function (company) {
            if (!company.get("isAbsoluteRoot")) {
              Conversion.addDimensionValue(companyDimension.get("values"), company.get("name"));
            }
          });
          newDimensions.pushObject(companyDimension);
          if (!version) {
            promises.push(companyDimension.save());
          }
        }

        // convert departments
        departmentDimension = (budgetRoot.get("dimensions") || Ember.A([])).findBy("type", "department") || budgetRoot.store.createRecord("dimension", {
          uniqueId: _const.default.generateUUID(),
          name: intl.t("general.name_1695611604908"),
          type: "department",
          values: [],
          allowHierarchy: true,
          allowAllocation: true,
          budget: budgetRoot
        });
        if (departments && departments.length) {
          budgetRoot.store.peekAll("budget").filterBy("isDepartment").forEach(function (dep) {
            Conversion.createDepartmentValue(departmentDimension, dep, version);
          });
          departments.forEach(function (depValue) {
            Conversion.addDimensionValue(departmentDimension.get("values"), depValue);
          });
        }
        newDimensions.pushObject(departmentDimension);

        // convert geographies
        geoDimension = (budgetRoot.get("dimensions") || Ember.A([])).findBy("type", "geography") || budgetRoot.store.createRecord("dimension", {
          uniqueId: _const.default.generateUUID(),
          name: intl.t("general.name_1695611604898"),
          type: "geography",
          values: [],
          allowHierarchy: true,
          allowAllocation: true,
          budget: budgetRoot
        });
        if (regions && regions.length) {
          budgetRoot.store.peekAll("budget").filterBy("isGeography").forEach(function (geo) {
            Conversion.createGeographyValue(geoDimension.get("values"), geo);
          });
          regions.forEach(function (geoValue) {
            Conversion.addDimensionValue(geoDimension.get("values"), geoValue);
          });
        }
        newDimensions.pushObject(geoDimension);

        // convert category
        categoryDimension = (budgetRoot.get("dimensions") || Ember.A([])).findBy("type", "category") || budgetRoot.store.createRecord("dimension", {
          uniqueId: _const.default.generateUUID(),
          name: intl.t("general.name_1695611604887"),
          values: [],
          type: "category",
          allowHierarchy: true,
          allowAllocation: false,
          budget: budgetRoot
        });
        if (categories && categories.length) {
          (budgetRoot.get("expenseCategories") || []).forEach(function (cat) {
            Conversion.addCategoryValue(categoryDimension.get("values"), cat);
          });
          categories.forEach(function (cat) {
            if (!categoryDimension.get("values").findBy("name", cat)) {
              Conversion.addDimensionValue(categoryDimension.get("values"), cat);
            }
          });
        }
        newDimensions.pushObject(categoryDimension);

        // convert product
        productDimension = (budgetRoot.get("dimensions") || Ember.A([])).findBy("type", "product") || budgetRoot.store.createRecord("dimension", {
          uniqueId: _const.default.generateUUID(),
          name: intl.t("general.name_1695611604876"),
          type: "product",
          values: [],
          allowHierarchy: false,
          allowAllocation: true,
          budget: budgetRoot
        });
        if (products && products.length) {
          products.forEach(function (prod) {
            Conversion.addDimensionValue(productDimension.get("values"), prod);
          });
        }
        newDimensions.pushObject(productDimension);
        if (!version) {
          promises.push(departmentDimension.save());
          promises.push(geoDimension.save());
          promises.push(categoryDimension.save());
          promises.push(productDimension.save());
        }
        budgetRoot.set("dimensions", newDimensions);

        // convert values in all budgetLines including scenario data
        budgetRoot.store.peekAll("budget").forEach(function (budgetLine) {
          if (budgetLine.get("absoluteRoot") !== budgetRoot || !budgetLine.get("budgetAttributes")) {
            return;
          }
          var lineChanged = false;
          budgetLine.set("budgetAttributes.dimensions", []);
          if (budgetLine.get("scenarios.length")) {
            budgetLine.get("scenarios").forEach(function (scenarioData) {
              Ember.set(scenarioData, "budgetAttributes.dimensions", []);
            });
          }
          if (companyDimension && (budgetLine.get("budgetType") === "group-company" || budgetLine.get("budgetType") === "expense-benefit")) {
            lineChanged = Conversion.convertCompany(budgetLine, budgetLine.get("budgetAttributes"), companyDimension) || lineChanged;
            if (budgetLine.get("scenarios.length")) {
              budgetLine.get("scenarios").forEach(function (scenarioData) {
                lineChanged = Conversion.convertCompany(budgetLine, Ember.get(scenarioData, "budgetAttributes"), companyDimension) || lineChanged;
              });
            }
          }
          if (departmentDimension) {
            lineChanged = Conversion.convertDepartment(budgetLine, budgetLine.get("budgetAttributes"), departmentDimension) || lineChanged;
            if (budgetLine.get("scenarios.length")) {
              budgetLine.get("scenarios").forEach(function (scenarioData) {
                lineChanged = Conversion.convertDepartment(budgetLine, Ember.get(scenarioData, "budgetAttributes"), departmentDimension) || lineChanged;
              });
            }
          }
          if (geoDimension) {
            var extraLinesToSave = [];
            lineChanged = Conversion.convertDimension(budgetLine, budgetLine.get("budgetAttributes"), geoDimension, "region") || lineChanged;
            if (budgetLine.get("budgetType") === "group-payroll") {
              promises.push(Conversion.convertByGeo(budgetLine, budgetLine.get("budgetAttributes"), geoDimension, extraLinesToSave, version));
            }
            if (budgetLine.get("scenarios.length")) {
              budgetLine.get("scenarios").forEach(function (scenarioData) {
                lineChanged = Conversion.convertDimension(budgetLine, Ember.get(scenarioData, "budgetAttributes"), geoDimension, "region") || lineChanged;
                if (budgetLine.get("budgetType") === "group-payroll") {
                  promises.push(Conversion.convertByGeo(budgetLine, Ember.get(scenarioData, "budgetAttributes"), geoDimension, extraLinesToSave, version, Ember.get(scenarioData, "scenario")));
                }
              });
            }
            if (!version) {
              extraLinesToSave.map(function (l) {
                return l.save();
              });
            }
          }
          if (productDimension) {
            lineChanged = Conversion.convertDimension(budgetLine, budgetLine.get("budgetAttributes"), productDimension, "product") || lineChanged;
            if (budgetLine.get("scenarios.length")) {
              budgetLine.get("scenarios").forEach(function (scenarioData) {
                lineChanged = Conversion.convertDimension(budgetLine, Ember.get(scenarioData, "budgetAttributes"), productDimension, "product") || lineChanged;
              });
            }
          }
          if (categoryDimension) {
            var attr = budgetLine.get("isRevenue") ? "revenueType" : "category";
            lineChanged = Conversion.convertCategory(budgetLine, budgetLine.get("budgetAttributes"), categoryDimension, attr) || lineChanged;
            if (budgetLine.get("scenarios.length")) {
              budgetLine.get("scenarios").forEach(function (scenarioData) {
                lineChanged = Conversion.convertCategory(budgetLine, Ember.get(scenarioData, "budgetAttributes"), categoryDimension, attr) || lineChanged;
              });
            }
          }
          if (lineChanged && !version) {
            promises.push(budgetLine.save());
          }
        });
        Ember.RSVP.all(promises).then(resolve, reject);
      });
    },
    actualAccountsMapping: function actualAccountsMapping(budgetRoot, version) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        budgetRoot.set("schemaVersion", 2);

        // save mapping for all current transactions
        var allTransactions = budgetRoot.store.peekAll("transaction");
        allTransactions.setEach("savedMapping", true);
        if (version) {
          return resolve();
        }
        var lines = budgetRoot.store.peekAll("budget").filterBy("absoluteRoot", budgetRoot);
        var accountMap = {};
        lines.forEach(function (line) {
          var handleAccountIds = function handleAccountIds(accountIds, idx, benefitId) {
            if (!accountIds) {
              return;
            }
            accountIds.trim().split(",").forEach(function (accountId) {
              accountId = accountId.trim();
              if (!accountId) {
                return;
              }
              if (!accountMap[accountId]) {
                accountMap[accountId] = {
                  dimensions: [],
                  account: accountId,
                  type: "pnl",
                  mappingType: "single",
                  budgetLinesModels: [line],
                  budgetLines: [line.get("uniqueId") + (idx === undefined ? "" : ":" + idx) + (typeof benefitId === "string" ? ":" + benefitId : "")],
                  budget: budgetRoot.id,
                  created: new Date()
                };
              } else {
                // account was already used, need to check that it's not under the same hierarchy
                var account = accountMap[accountId],
                  allDimensions = [],
                  addLines = [];
                var thisDepartment = line.get("location").findBy("oldBudgetType", "group-department-multi") || line.get("location").findBy("oldBudgetType", "group-department");
                var thisGeo = line.get("location").findBy("oldBudgetType", "group-department-geo");
                var thisDepName = thisDepartment && thisDepartment.get("name");
                var thisGeoName = thisGeo && thisGeo.get("name");
                account.budgetLinesModels.forEach(function (otherLine) {
                  var otherDepartment = otherLine.get("location").findBy("oldBudgetType", "group-department-multi") || otherLine.get("location").findBy("oldBudgetType", "group-department");
                  var otherGeo = otherLine.get("location").findBy("oldBudgetType", "group-department-geo");
                  var otherDepName = otherDepartment && otherDepartment.get("name");
                  var otherGeoName = otherGeo && otherGeo.get("name");
                  var dimensions = [];
                  if (thisDepartment !== otherDepartment && thisDepName !== otherDepName) {
                    dimensions.pushObject(budgetRoot.get("dimensions").findBy("isDepartment").get("uniqueId"));
                  }
                  if (thisGeo !== otherGeo && thisGeoName !== otherGeoName) {
                    dimensions.pushObject(budgetRoot.get("dimensions").findBy("isGeography").get("uniqueId"));
                  }
                  if (otherLine.get("companyName") !== line.get("companyName")) {
                    dimensions.pushObject(budgetRoot.get("dimensions").findBy("isEntity").get("uniqueId"));
                  }
                  if (dimensions.length) {
                    allDimensions.addObjects(dimensions);
                    addLines.pushObject(line);
                    account.mappingType = "multi";
                  }
                });
                account.budgetLines.addObjects(addLines.map(function (l) {
                  return l.get("uniqueId") + (idx === undefined ? "" : ":" + idx);
                }));
                account.budgetLinesModels.addObjects(addLines);
                account.dimensions.addObjects(allDimensions);
              }
            });
          };
          if (line.get("budgetType") === "group-payroll") {
            if (line.get("budgetAttributes.accountIdPerCategory.length")) {
              line.get("budgetAttributes.accountIdPerCategory").forEach(handleAccountIds);
            }
            if (line.get("budgetAttributes.accountIdPerBenefit")) {
              Object.keys(line.get("budgetAttributes.accountIdPerBenefit")).forEach(function (benefitName) {
                var benefit = line.get("benefits.length") && line.get("benefits").findBy("name", benefitName) || line.get("inheritedBenefits.length") && line.get("inheritedBenefits").findBy("name", benefitName);
                if (benefit) {
                  handleAccountIds(line.get("budgetAttributes.accountIdPerBenefit")[benefitName], "", benefit.get("uniqueId"));
                }
              });
            }
          } else if (line.get("budgetAttributes.accountId")) {
            handleAccountIds(line.get("budgetAttributes.accountId"));
          }
        });
        // add BS accounts to map
        if (budgetRoot.get("bsAccounts.length")) {
          budgetRoot.get("bsAccounts").forEach(function (bsAccount) {
            if (Ember.get(bsAccount, "accountId")) {
              Ember.get(bsAccount, "accountId").trim().split(",").forEach(function (accountId) {
                accountId = accountId.trim();
                if (!accountId) {
                  return;
                }
                if (!accountMap[accountId]) {
                  accountMap[accountId] = {
                    description: Ember.get(bsAccount, "name"),
                    dimensions: [],
                    account: accountId,
                    type: "bs",
                    mappingType: "single",
                    budgetLines: [Ember.get(bsAccount, "lineId")],
                    budget: budgetRoot.id,
                    created: new Date()
                  };
                } else {
                  accountMap[accountId].budgetLines.addObject(Ember.get(bsAccount, "lineId"));
                }
              });
            }
          });
        }
        var newAccounts = Object.keys(accountMap).map(function (key) {
          delete accountMap[key].budgetLinesModels;
          return accountMap[key];
        });
        if (!version) {
          Ember.$.ajax("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/budgets/").concat(budgetRoot.id, "/upgrade-transactions"), {
            type: "POST",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(allTransactions.mapBy("id"))
          });

          // convert account map to array
          budgetRoot.store.unloadAll("actual-account");
          // import new accounts in chunks of 500
          var promises = _lodash.default.chunk(newAccounts, 500).map(function (chunk) {
            return new Ember.RSVP.Promise(function (resolveImport, rejectImport) {
              Ember.$.ajax({
                type: "POST",
                url: "".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/actualAccounts/import"),
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify({
                  budget: budgetRoot.id,
                  actualAccounts: chunk,
                  replace: true
                }),
                success: function success(result) {
                  budgetRoot.store.push({
                    data: result.actualAccounts.map(function (t) {
                      return {
                        type: "actual-account",
                        id: t._id,
                        attributes: t,
                        relationships: {
                          budget: {
                            data: {
                              type: "budget",
                              id: budgetRoot.id
                            }
                          }
                        }
                      };
                    })
                  });
                  resolveImport();
                },
                error: rejectImport
              });
            });
          });
          Ember.RSVP.all(promises).then(function () {
            budgetRoot.reload().then(resolve, reject);
          }, reject);
        }
      });
    }
  };
  var _default = _exports.default = Conversion;
});