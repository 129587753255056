define("budgeta/templates/components/budgeta-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YC9RSQdM",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"checked\"]]],null,1,0],[\"open-element\",\"label\",[]],[\"dynamic-attr\",\"for\",[\"unknown\",[\"inputId\"]],null],[\"flush-element\"],[\"text\",\"\\n  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"input\",[]],[\"dynamic-attr\",\"data-hook\",[\"concat\",[[\"unknown\",[\"value\"]]]]],[\"dynamic-attr\",\"id\",[\"concat\",[[\"unknown\",[\"inputId\"]]]]],[\"static-attr\",\"type\",\"radio\"],[\"dynamic-attr\",\"name\",[\"concat\",[[\"unknown\",[\"name\"]]]]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"input\",[]],[\"dynamic-attr\",\"data-hook\",[\"concat\",[[\"unknown\",[\"value\"]]]]],[\"dynamic-attr\",\"id\",[\"concat\",[[\"unknown\",[\"inputId\"]]]]],[\"static-attr\",\"type\",\"radio\"],[\"dynamic-attr\",\"name\",[\"concat\",[[\"unknown\",[\"name\"]]]]],[\"static-attr\",\"checked\",\"checked\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/budgeta-radio.hbs"
    }
  });
});