define("budgeta/templates/components/input-comma-separator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tS/akwwc",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"input\"],null,[[\"value\",\"readonly\",\"placeholder\"],[[\"get\",[\"displayValue\"]],[\"get\",[\"readOnly\"]],[\"get\",[\"placeholder\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/input-comma-separator.hbs"
    }
  });
});