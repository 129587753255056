define("budgeta/routes/company-admin-route", ["exports", "budgeta/utils/const", "lodash/lodash"], function (_exports, _const, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var companyId = _lodash.default.get(transition, "params.company-admin-route.companyId");
      var company = (this.get("session.data.currentUser.companies") || []).find(function (comp) {
        return comp._id === companyId;
      });
      var isAdmin = company && company.user.roles.includes(_const.COMPANY_USER_ROLE.COMPANY_ADMIN);
      if (!isAdmin) {
        this.transitionTo("unauthorized");
      } else {
        this.get("trackingEvents").bootPendo();
      }
    }
  });
});