define("budgeta/mixins/formula-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    classNames: ["calculator-field"] /* ,
                                     -- Disabled for now - collides with new formula feauture
                                     keyDown: function(e) {
                                     var calcEl = Ember.$('#formula-calculator');
                                     if (e.keyCode === 187) {
                                     // open calculator
                                     var target = Ember.$(e.target);
                                     var oldapi = target.qtip('api');
                                     if (oldapi) {
                                     oldapi.destroy();
                                     }
                                     target.qtip({
                                     style: {
                                     classes: 'qtip-shadow'
                                     },
                                     show: {
                                     event: false,
                                     ready: true
                                     },
                                     hide: {
                                     event: 'unfocus'
                                     },
                                     position: {
                                     viewport: true
                                     },
                                     content: {
                                     text: calcEl
                                     },
                                     events: {
                                     show: function(event, api) {
                                     var inputEl = calcEl.find('input');
                                     var confirm = calcEl.find('.confirm');
                                     calcEl.find('.close-calc').off('click.calc').on('click.calc', function() {
                                     api.hide();
                                     });
                                     confirm.off('click.calc').on('click.calc', function() {
                                     try {
                                     var result = eval(inputEl.val());
                                     target.val(result);
                                     target.trigger('change');
                                     api.hide();
                                     } catch (e) {}
                                     });
                                     inputEl.val('');
                                     inputEl.off('keypress.calc').on('keypress.calc', function(event) {
                                     if (event.keyCode === 13) {
                                     confirm.click();
                                     }
                                     });
                                     Ember.run.next(function() {
                                     inputEl.focus();
                                     });
                                     }
                                     }
                                     });
                                     return false;
                                     }
                                     return true;
                                     }*/
  });
});