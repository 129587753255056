define("budgeta/components/collapse-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":collapse-expand-component", "collapse:collapse-mode:expand-mode"],
    attributeBindings: ["data-hook"],
    "data-hook": Ember.computed("collapse", function () {
      return this.get("collapse") ? "collapse-tree" : "expand-tree";
    }),
    click: function click() {
      this.sendAction();
    }
  });
});