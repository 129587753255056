define("budgeta/utils/is-account-ids-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isAccountIdsMatch;
  var SPACES_REGEXP = /\s+/g;
  function isAccountIdsMatch(accountA, accountB) {
    if (!accountA || !accountB) {
      return false;
    }
    if (accountA === accountB) {
      return true;
    }
    accountA = (accountA + "").trim();
    accountB = (accountB + "").trim();
    if (accountA === accountB) {
      return true;
    }
    if (accountA.replace(SPACES_REGEXP, " ") === accountB.replace(SPACES_REGEXP, " ")) {
      return true;
    }
    return false;
  }
});