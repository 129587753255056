define("budgeta/templates/components/svg/download-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GEXXUtt5",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M4 4.8C4.48 4.8 4.8 5.12 4.8 5.6C4.8 6.08 4.48 6.4 4 6.4H2.4C1.92 6.4 1.6 6.72 1.6 7.2V13.6C1.6 14.08 1.92 14.4 2.4 14.4H13.6C14.08 14.4 14.4 14.08 14.4 13.6V7.2C14.4 6.72 14.08 6.4 13.6 6.4H12C11.52 6.4 11.2 6.08 11.2 5.6C11.2 5.12 11.52 4.8 12 4.8H13.6C14.96 4.8 16 5.84 16 7.2V13.6C16 14.96 14.96 16 13.6 16H2.4C1.04 16 0 14.96 0 13.6V7.2C0 5.84 1.04 4.8 2.4 4.8H4ZM8 0C8.48 0 8.8 0.32 8.8 0.8V10.08L10.64 8.24C11.12 7.76 12 8.08 12 8.8C12 9.04 11.92 9.2 11.76 9.36L8.56 12.56C8.48 12.64 8.4 12.64 8.32 12.72C8.08 12.8 7.84 12.8 7.68 12.72C7.6 12.72 7.52 12.64 7.44 12.56L4.24 9.36C4.08 9.2 4 9.04 4 8.8C4 8.08 4.88 7.76 5.36 8.24L7.2 10.08V0.8C7.2 0.32 7.52 0 8 0Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/download-svg.hbs"
    }
  });
});