define("budgeta/templates/components/actions-budget-tree-no-grouping-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "62W389HS",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"actions-inner-wrapper\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"name\"],[\"share-icon\",\"share\"]]],false],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"help-iq\"],null,[[\"tooltipText\",\"isNoGrouping\"],[[\"helper\",[\"t\"],[\"tooltips.no-grouping\"],null],true]]],false],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/actions-budget-tree-no-grouping-share.hbs"
    }
  });
});