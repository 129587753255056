define("budgeta/services/audit-logging/audit-logging-utils", ["exports", "budgeta/services/audit-logging/audit-logging-creator", "react", "@sibp/ui-components", "lodash/lodash", "budgeta/admin/utils/const"], function (_exports, _auditLoggingCreator, _react, _uiComponents, _lodash, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function compareStringValuesOfObjects(oldObject, newObject, keys) {
    var details = keys.map(function (key) {
      if (_lodash.default.get(oldObject, key) !== _lodash.default.get(newObject, key)) {
        return _auditLoggingCreator.default.createChangeDetail(key, _lodash.default.get(oldObject, key), _lodash.default.get(newObject, key));
      } else {
        return null;
      }
    }).filter(function (o) {
      return o;
    });
    return details;
  }
  var AuditLogUtils = {
    getCompaniesDiffDetails: function getCompaniesDiffDetails(oldCompany, newCompany) {
      var keys = ["name", "contactEmail", "contactName", "contactPhone"];
      var details = compareStringValuesOfObjects(oldCompany, newCompany, keys);
      for (var i = 0; i < oldCompany["licenses"].length; i++) {
        var oldLicense = oldCompany["licenses"][i];
        var newLicense = newCompany["licenses"][i];
        if (oldLicense.count !== newLicense.count) {
          details.push(_auditLoggingCreator.default.createChangeDetail("License " + oldLicense.type, oldLicense.count, newLicense.count));
        }
      }
      return details;
    },
    compareOwner: function compareOwner(oldUser, newUser, details) {
      if (oldUser.roles.includes(_const.COMPANY_USER_ROLE.COMPANY_ADMIN) && newUser.roles.includes(_const.COMPANY_USER_ROLE.COMPANY_USER)) {
        details.push(_auditLoggingCreator.default.createChangeDetail("roles", "owner", "user"));
      }
      if (oldUser.roles.includes(_const.COMPANY_USER_ROLE.COMPANY_USER) && newUser.roles.includes(_const.COMPANY_USER_ROLE.COMPANY_ADMIN)) {
        details.push(_auditLoggingCreator.default.createChangeDetail("roles", "user", "owner"));
      }
    },
    getCompanyUserDiffDetails: function getCompanyUserDiffDetails(oldUser, newUser) {
      var keys = ["status", "expDate", "userType", "adminConsole.status"];
      var details = compareStringValuesOfObjects(oldUser, newUser, keys);
      AuditLogUtils.compareOwner(oldUser, newUser, details);
      return details;
    },
    getLogTextDiffComponents: function getLogTextDiffComponents(details) {
      var logTextDiffComponents = details && details.length ? details.map(function (detail, index) {
        return _react.default.createElement(_uiComponents.default.COMPONENTS.LogTextDiff, {
          fieldName: detail.fieldName,
          oldValue: detail.oldValue ? detail.oldValue + "" : "",
          newValue: detail.newValue ? detail.newValue + "" : "",
          key: index
        });
      }) : null;
      return logTextDiffComponents;
    }
  };
  var _default = _exports.default = AuditLogUtils;
});