define("budgeta/templates/main/budget/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pFKXiCNM",
    "block": "{\"statements\":[[\"block\",[\"main/budget/dashboard-component\"],null,[[\"controllertmp\"],[[\"get\",[null]]]],2]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"          \"],[\"open-element\",\"a\",[]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"unhide\"]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"dashboard.show-all\"],null],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"right\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"hasHidden\"]]],null,0],[\"text\",\"      \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"dashboard \",[\"helper\",[\"if\"],[[\"get\",[\"isInteractiveDashboard\"]],\"interactive-dashboard\"],null]]]],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"budget-top-bar\"],null,[[\"selectedBudget\",\"editMode\",\"budget\",\"forecastTarget\",\"showImport\",\"closeImport\"],[[\"get\",[\"mainController\",\"selectedBudget\"]],false,[\"get\",[\"budget\"]],[\"get\",[\"budget\",\"id\"]],[\"get\",[\"mainController\",\"showImport\"]],\"closeImport\"]],1],[\"text\",\"\\n    \"],[\"partial\",\"main/budget/dashboard-body\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":true}",
    "meta": {
      "moduleName": "budgeta/templates/main/budget/dashboard.hbs"
    }
  });
});