define("budgeta/admin/components/modal/root", ["exports", "@babel/runtime/helpers/esm/extends", "react", "budgeta/admin/components/modal/context"], function (_exports, _extends2, _react, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // We subscribe to context changes, access the component and props,
  // and render the component if we need to, or close it
  var ModalRoot = function ModalRoot() {
    return _react.default.createElement(_context.ModalConsumer, null, function (_ref) {
      var Component = _ref.component,
        props = _ref.props,
        hideModal = _ref.hideModal;
      return Component ? _react.default.createElement(Component, (0, _extends2.default)({}, props, {
        onRequestClose: hideModal
      })) : null;
    });
  };
  var _default = _exports.default = ModalRoot;
});