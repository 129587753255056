define("budgeta/components/react/synced-users-menu/extra-users-list", ["exports", "@sibp/ui-components", "react"], function (_exports, _uiComponents, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var customOption = function customOption(props) {
    var _props$data = props.data,
      fullName = _props$data.fullName,
      initials = _props$data.initials;
    return _react.default.createElement("div", {
      className: "user-option"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.CircleWithText, {
      label: initials,
      className: "users-list"
    }), _react.default.createElement("div", null, fullName));
  };
  var ExtraUsersList = function ExtraUsersList(_ref) {
    var users = _ref.users,
      onOutsideClick = _ref.onOutsideClick;
    return _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
      value: "",
      options: users,
      menuIsOpen: true,
      components: {
        Option: customOption
      },
      classNames: "extra-users-list",
      onClickingOutside: onOutsideClick,
      outsideContainerSelector: ".extra-users-container"
    });
  };
  var _default = _exports.default = ExtraUsersList;
});