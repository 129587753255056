define("budgeta/components/modal-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: "classNameBindingsComputed",
    classNameBindingsComputed: Ember.computed("controllertmp.classNames", "controllertmp.isElegantModal", function () {
      var classNames = this.get("controllertmp.classNames") || [];
      if (this.get("controllertmp.isElegantModal")) {
        classNames += " elegant-modal";
      } else {
        classNames += " modal-container";
      }
      return classNames;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      //send action onOpen up, so it can be handled in controller
      if (this.onOpen) {
        this.onOpen();
      }
      this.set("controllertmp.errors", {});
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$("body").addClass("modal-open");
      Ember.run.scheduleOnce("afterRender", this, function () {
        var dialog = this.$(".modal-dialog");
        dialog.css({
          marginTop: Ember.$(window).height() / 2 - this.$(".modal-dialog").height() / 2 - 20
        }).draggable({
          handle: ".modal-header"
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set("controllertmp.displayCustomBody", false);
      this.set("controllertmp.modalCustomBody", null);
      Ember.$("body").removeClass("modal-open");
      if (this.onClose) {
        this.onClose();
      }
    },
    actions: {
      close: function close() {
        this.controllertmp.send("close");
      },
      confirm: function confirm() {
        this.controllertmp.send("confirm");
      },
      next: function next() {
        this.controllertmp.send("next");
      },
      back: function back() {
        this.controllertmp.send("back");
      },
      scrollMore: function scrollMore() {
        this.controllertmp.send("scrollMore");
      },
      openVideoModal: function openVideoModal() {
        this.controllertmp.send("openVideoModal");
      },
      secondaryButtonAction: function secondaryButtonAction() {
        this.controllertmp.secondaryButtonAction();
      }
    }
  });
});