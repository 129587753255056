define("budgeta/admin/utils/tableUtil", ["exports", "@sibp/ui-components", "lodash/lodash"], function (_exports, _uiComponents, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var sort = function sort(array, _ref) {
    var sort = _ref.sort;
    var colName = sort.colName,
      sortDir = sort.sortDir,
      dataType = sort.dataType;
    var theSort;
    switch (dataType) {
      case _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING:
        theSort = function theSort(a, b) {
          var s1 = _lodash.default.get(a, colName) || "";
          var s2 = _lodash.default.get(b, colName) || "";
          s1 = s1 instanceof Object ? JSON.stringify(s1).toLowerCase() : s1.toLowerCase();
          s2 = s2 instanceof Object ? JSON.stringify(s2).toLowerCase() : s2.toLowerCase();
          return s1.localeCompare(s2) * sortDir;
        };
        break;
      case _uiComponents.default.TABLE_SORTER.DATA_TYPES.NUMBER:
        theSort = function theSort(a, b) {
          var num1 = _lodash.default.get(a, colName) || 0;
          var num2 = _lodash.default.get(b, colName) || 0;
          return (num1 - num2) * sortDir;
        };
        break;
      case _uiComponents.default.TABLE_SORTER.DATA_TYPES.DATE:
        theSort = function theSort(a, b) {
          var date1 = _lodash.default.get(a, colName);
          var date2 = _lodash.default.get(b, colName);
          date1 = date1 ? new Date(date1) : new Date(0);
          date2 = date2 ? new Date(date2) : new Date(0);
          return (date1 - date2) * sortDir;
        };
        break;
    }
    var sortedArray = array.sort(theSort);
    return sortedArray;
  };
  var calcTableHeight = function calcTableHeight() {
    var bodyHeight = document.body.offsetHeight;
    var tableTop = document.querySelector(".table-container").offsetTop;
    return bodyHeight - tableTop;
  };
  var _default = _exports.default = {
    sort: sort,
    calcTableHeight: calcTableHeight
  };
});