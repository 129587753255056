define("budgeta/controllers/modal", ["exports", "ember-validations"], function (_exports, _emberValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend(_emberValidations.default, {
    trackingEvents: Ember.inject.service(),
    step: 1,
    steps: 1,
    showBack: Ember.computed("step", function () {
      return this.get("step") > 1;
    }),
    lastStep: Ember.computed("step", "steps", function () {
      return this.get("step") === this.get("steps");
    }),
    hideHeader: false,
    hideCancel: false,
    confirmActionDismiss: true,
    validateOnConfirm: true,
    confirmDisabled: false,
    addOverlay: true,
    confirm: function confirm() {
      var _this = this;
      var confirmFn = function confirmFn() {
        if (!_this.get("lastStep")) {
          return _this.send("next");
        }
        if (_this.get("confirmAction")) {
          (_this.get("actionTarget") || _this).send(_this.get("confirmAction"), _this.get("confirmActionArg") || _this.get("model"));
        }
        _this.set("confirmActionArg", null);
        if (_this.get("confirmActionCallback")) {
          _this.get("confirmActionCallback")();
        }
        return _this.get("confirmActionDismiss") ? _this.send("closeModal", _this.get("outlet")) : false;
      };
      if (_this.get("validateOnConfirm")) {
        _this.validate().then(confirmFn, function () {});
      } else {
        return confirmFn();
      }
    },
    actions: {
      next: function next() {
        var _this2 = this;
        this.validate().then(function () {
          _this2.incrementProperty("step");
        }, function () {
          // do nothing
        });
      },
      back: function back() {
        if (this.get("backAction")) {
          (this.get("actionTarget") || this).send(this.get("backAction"), this.get("backActionArg") || this.get("model"));
        } else {
          this.decrementProperty("step");
        }
      },
      close: function close() {
        this.set("addClass", null);
        if (this.get("cancelAction")) {
          (this.get("actionTarget") || this).send(this.get("cancelAction"), this.get("cancelActionArg") || this.get("model"), this.get("cancelActionArg2"));
        }
        if (this.get("cancelActionCallback")) {
          this.get("cancelActionCallback")();
        }
        this.set("confirmActionArg", null);
        return this.send("closeModal", this.get("outlet"));
      },
      confirm: function confirm() {
        this.set("addClass", null);
        return this.confirm();
      },
      filesDropped: function filesDropped(files) {
        return this.send("filesDroppedFromModal", files);
      },
      continueActionName: function continueActionName(text) {
        this.set("continueBtnText", text);
      }
    }
  });
});