define("budgeta/templates/components/location-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CuHYi7Qf",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"data\",\"length\"]]],null,3,0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"t\"],[\"location-list.loading\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"          \"],[\"open-element\",\"li\",[]],[\"static-attr\",\"class\",\"flex name-add-delete\"],[\"flush-element\"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"class\",\"value\",\"placeholder\",\"required\"],[\"prevent-submit\",[\"get\",[\"location\",\"name\"]],[\"helper\",[\"t\"],[\"placeholders.enter-name\"],null],\"true\"]]],false],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"classNames\",\"name\",\"action\",\"actionArg\"],[\"delete\",\"delete\",\"deleteDataItem\",[\"get\",[\"location\"]]]]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"unless\"],[[\"get\",[\"location\",\"deleted\"]]],null,1]],\"locals\":[\"location\"]},{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"ul\",[]],[\"static-attr\",\"class\",\"sortable\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"filteredData\"]]],null,2],[\"text\",\"    \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/location-list.hbs"
    }
  });
});