define("budgeta/templates/components/import-account-mapping-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ixP8WYZe",
    "block": "{\"statements\":[[\"block\",[\"ember-collection\"],null,[[\"items\",\"classNames\",\"cell-layout\"],[[\"get\",[\"values\"]],\"scroll-hidden\",[\"helper\",[\"mixed-grid-layout\"],[[\"get\",[\"valuesColumnSizes\"]]],null]]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"column\"],[\"dynamic-attr\",\"title\",[\"get\",[\"value\"]],null],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"column-content\"],[\"flush-element\"],[\"append\",[\"get\",[\"value\"]],false],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"value\",\"index\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/import-account-mapping-display.hbs"
    }
  });
});