define("budgeta/templates/invitation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QVD6Feln",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"template-index.invite-to-share\"],null],false],[\"text\",\" \"],[\"append\",[\"unknown\",[\"model\",\"name\"]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/invitation/index.hbs"
    }
  });
});