define("budgeta/templates/my-profile-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8kfh4m3X",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"id\",\"my-profile-root\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"append\",[\"unknown\",[\"my-profile-container\"]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/my-profile-container.hbs"
    }
  });
});