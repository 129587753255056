define("budgeta/generic-file/components/finalize-mapping-intro/finalize-mapping-intro", ["exports", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "react"], function (_exports, _uiComponents, _reactRedux, _stepsActions, _emberServiceContainer, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = finalizeMappingIntro;
  /* eslint-disable react-hooks/rules-of-hooks */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function finalizeMappingIntro() {
    var dispatch = _reactRedux.default.useDispatch();
    var options = {
      hideClose: true,
      height: "675px"
    };
    var header = {
      text: intl.t("generic-file.step-finalize-mapping-intro.header")
    };
    var body = _react.default.createElement("div", {
      className: "finalize-mapping-intro-body-container padding-intro-body"
    }, _react.default.createElement("div", {
      className: "sub-header header-meduim"
    }, intl.t("generic-file.step-finalize-mapping-intro.sub-header")), _react.default.createElement(_uiComponents.default.COMPONENTS.LottieAnimation, {
      animationData: _uiComponents.default.LOTTIE.FinalizeMapping
    }), _react.default.createElement("div", {
      className: "description"
    }, intl.t("generic-file.step-finalize-mapping-intro.description")));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button",
      dataHook: "footer-tertiary-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.got-it"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CLICKED_ACCOUNT_GROUP_INTRO_NEXT);
        dispatch(_stepsActions.default.nextStep(true));
      }
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});