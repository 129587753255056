define("budgeta/components/type-ahead", ["exports", "ember-cli-typeahead/components/type-ahead"], function (_exports, _typeAhead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _typeAhead.default.extend({
    selectionObserver: Ember.observer("selection", function () {
      this.set("value", this.get("selection.value"));
    })
  });
});