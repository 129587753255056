define("budgeta/templates/components/actions-budget-tree-flagged-no-grouping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "anWAW7P9",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"actions-inner-wrapper\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"svg/star-svg\"],null,[[\"class\"],[\"star\"]]],false],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"help-iq\"],null,[[\"tooltipText\",\"isNoGrouping\"],[[\"helper\",[\"t\"],[\"tooltips.no-grouping\"],null],true]]],false],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/actions-budget-tree-flagged-no-grouping.hbs"
    }
  });
});