define("budgeta/templates/components/employees-table-new-hire-amount-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qxGDHiUc",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"currency-input\"],null,[[\"allowNegative\",\"currency\",\"value\",\"openFormulaEditor\",\"assumptions\",\"budget\",\"hideFormulaButton\",\"readonly\"],[true,null,[\"get\",[\"numOfEmployees\"]],\"openFormulaEditor\",[\"get\",[\"assumptions\"]],[\"get\",[\"line\"]],true,[\"get\",[\"readOnly\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/employees-table-new-hire-amount-cell.hbs"
    }
  });
});