define("budgeta/components/main/budget/dashboard-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var view = this,
        wrapper = this.$(".charts-wrapper");
      if (!this.get("controllertmp.dashboardReadOnly")) {
        wrapper.sortable({
          cursor: "move",
          update: function update() {
            view.get("controllertmp").send("saveOrder", wrapper.find(".chart").map(function (idx, el) {
              return Ember.$(el).data("id");
            }).get());
          }
        });
        wrapper.disableSelection();
      }
      Ember.run.scheduleOnce("afterRender", function () {
        // https://github.com/ember-cli/eslint-plugin-ember/blob/master/docs/rules/jquery-ember-run.md
        Ember.$(document).mousemove(Ember.run.bind(_this, _this._positionTooltip));
      });
    },
    _positionTooltip: function _positionTooltip(event) {
      var tooltip = Ember.$(".chart-tooltip");
      tooltip.width(tooltip.find("table").width());
      tooltip.position({
        my: "left+20 top+20",
        of: event,
        collision: "fit",
        within: this.element
      });
    },
    willDestroyElement: function willDestroyElement() {
      if (this.$(".charts-wrapper").sortable("instance")) {
        this.$(".charts-wrapper").sortable("destroy");
      }
    }
  });
});