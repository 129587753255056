define("budgeta/components/integration-wizard-export-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    }),
    didInsertElement: function didInsertElement() {
      this.set("model.refresh", false);
    },
    didRender: function didRender() {
      if (this.get("message")) {
        Ember.$(".modal-content .modal-footer #confirm-btn").addClass("disabled");
      } else {
        Ember.$(".modal-content .modal-footer #confirm-btn").removeClass("disabled");
      }
    }
  });
});