define("budgeta/generic-file/components/file-upload/file-upload-step", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@babel/runtime/helpers/esm/objectWithoutProperties", "react", "react-redux", "@sibp/ui-components", "budgeta/generic-file/components/file-upload/body/file-upload-body", "budgeta/store/actions/stepsActions", "budgeta/services/ember-service-container", "budgeta/utils/useEmberBridge", "budgeta/store/actions/fileActions", "budgeta/utils/import"], function (_exports, _slicedToArray2, _objectWithoutProperties2, _react, _reactRedux, _uiComponents, _fileUploadBody, _stepsActions, _emberServiceContainer, _useEmberBridge, _fileActions, _import) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = FileUploadStep;
  var _excluded = ["updateDataAction", "getDataAction", "endTableAction", "startTableAction"];
  /* eslint-disable no-undef */
  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function FileUploadStep(_ref) {
    var updateDataAction = _ref.updateDataAction,
      getDataAction = _ref.getDataAction,
      endTableAction = _ref.endTableAction,
      startTableAction = _ref.startTableAction,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
    var dispatch = _reactRedux.default.useDispatch();
    var stepName = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.fileStore;
      }),
      fileName = _ReactRedux$useSelect.name,
      file = _ReactRedux$useSelect.file;
    var _React$useState = _react.default.useState(file),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      newFile = _React$useState2[0],
      setNewFile = _React$useState2[1];
    _react.default.useEffect(function () {
      startTableAction(stepName, {
        renderTable: false
      });
    });
    (0, _useEmberBridge.default)(["workbook", "selectedSheet"], function () {
      var data = getDataAction(stepName);
      if (data && data.ws) {
        dispatch(_fileActions.default.setFile(newFile));
        var ws = data.ws,
          numOfColumns = data.numOfColumns;
        formatFile(ws, numOfColumns);
        updateDataAction(stepName);
        endTableAction(stepName, {
          removeListeners: true,
          closeTable: false
        });
        rest.clearDependencies();
        dispatch(_stepsActions.default.nextStep());
      }
    });
    var formatFile = function formatFile(data, numOfColumns) {
      var numOfRows = _import.default.linesInSheet(data);
      var result = {
        numOfRows: numOfRows,
        numOfColumns: numOfColumns
      };
      for (var i = 1; i < numOfRows + 1; i++) {
        result[i] = {};
        for (var j = 1; j < numOfColumns + 1; j++) {
          var letter = _import.default.colToLetterRef(j);
          var cell = data["".concat(letter).concat(i)];
          if (cell) {
            result[i][letter] = cell;
          }
        }
      }
      dispatch(_fileActions.default.setFormattedFile(result));
    };
    var options = {
      hideClose: true,
      height: "675px"
    };
    var header = {
      text: intl.t("generic-file.step-file-upload.header")
    };
    var body = _react.default.createElement("div", {
      className: "file-upload-body-container"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.LottieAnimation, {
      animationData: _uiComponents.default.LOTTIE.FileUpload
    }), _react.default.createElement(_fileUploadBody.default, {
      clearDependencies: rest.clearDependencies,
      updateDataAction: updateDataAction,
      getDataAction: getDataAction,
      setNewFile: setNewFile
    }));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.lets-start"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.COMPLETED_UPLOAD_FILE);
        if (!file || !_.isEqual(file.size, newFile.size)) {
          updateDataAction(stepName, {
            file: newFile
          });
        } else {
          endTableAction(stepName, {
            removeListeners: true,
            closeTable: false
          });
          dispatch(_stepsActions.default.nextStep());
        }
      },
      disabled: !fileName
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});