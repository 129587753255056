define("budgeta/controllers/main/budget/models", ["exports", "budgeta/mixins/query-param-delegate-fix", "budgeta/utils/const"], function (_exports, _queryParamDelegateFix, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend(_queryParamDelegateFix.default, {
    queryParams: ["community"],
    community: false,
    mainController: Ember.inject.controller("main"),
    user: Ember.computed.alias("mainController.currentUser"),
    MomentDateAndTimeDisplayFormat: _const.default.MomentDateAndTimeDisplayFormat,
    actions: {
      done: function done() {
        this.set("mainController.showImport", false);
        this.transitionToRoute("main.budget.forecast");
      },
      community: function community(value) {
        this.set("community", value);
      }
    }
  });
});