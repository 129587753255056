define("budgeta/components/budget-top-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      closeImport: function closeImport() {
        this.sendAction("closeImport");
      },
      closeModels: function closeModels() {
        this.sendAction("closeModels");
      }
    }
  });
});