define("budgeta/components/select-2-type-ahead-lazy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    active: false,
    classNames: ["select-2-type-ahead-lazy"],
    selected: Ember.computed("targetValue", "targetProperty", function () {
      return this.get("targetValue.".concat(this.get("targetProperty")));
    }),
    selectedLabel: Ember.computed("selected", function () {
      return this.get("selected") || this.get("placeholder");
    }),
    isPlaceHolder: Ember.computed.not("selected"),
    focusOut: function focusOut() {
      this.$(".select2-container").select2("close");
      this.set("active", false);
    },
    actions: {
      activate: function activate() {
        var _this = this;
        if (this.get("enabled")) {
          this.set("active", true);
          Ember.run.scheduleOnce("afterRender", function () {
            _this.$(".select2-container").select2("open");
          });
        }
      },
      query: function query(_query, deferred) {
        this.sendAction(this.get("queryName"), _query, deferred);
      },
      attributeChanged: function attributeChanged() {
        this.sendAction("attributeChanged");
      }
    }
  });
});