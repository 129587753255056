define("budgeta/templates/components/svg/duplicate-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GIMybPcg",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M11.28 2.4C12.56 2.4 13.6 3.44 13.6 4.72L13.6 13.68C13.6 14.96 12.56 16 11.28 16L2.32 16C1.04 16 0 14.96 0 13.68L0 4.72C0 3.44 1.04 2.4 2.32 2.4L11.28 2.4ZM13.76 0C14.96 0 16 1.04 16 2.24L16 10.96C16 11.84 15.52 12.56 14.4 12.96L14.4 3.2C14.4 2.32 13.68 1.6 12.8 1.6L3.04 1.6C3.44 0.48 4.16 0 5.04 0L13.76 0Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/duplicate-svg.hbs"
    }
  });
});