define("budgeta/services/cache", ["exports", "@babel/runtime/helpers/esm/typeof", "budgeta/utils/const", "budgeta/utils/formula", "budgeta/utils/formula-helper", "budgeta/utils/assumption", "budgeta/utils/budget-utils", "budgeta/services/ember-service-container"], function (_exports, _typeof2, _const, _formula, _formulaHelper, _assumption, _budgetUtils, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var attributesToReplace = [{
    attr: "budget"
  }, {
    attr: "dimension"
  }, {
    attr: "groupByDimension",
    modelName: "dimension"
  }];
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    performanceLogger: Ember.inject.service(),
    actualsMapping: Ember.inject.service(),
    isNonProfitBudget: Ember.computed.alias("budgetService.isNonProfitBudget"),
    lastSheet: "cf",
    forecastCache: {},
    uniqueIdMap: {},
    versionsUniqueIdMap: {},
    originalRootUniqueIdMap: {},
    isAncestorCache: {},
    dimensionTree: {},
    actualsCacheItems: [],
    calculationError: false,
    trackingEvents: Ember.inject.service(),
    updateCalcTime: function updateCalcTime(startCalcTime) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var functionName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
      var newOptions = {
        type: options.type,
        calculation_time: (Date.now() - startCalcTime) / 1000,
        path: (window.location.pathname || "").split("/").splice(-1).pop(),
        functionName: functionName
      };
      if (newOptions.calculation_time < 0.5 && functionName === "runCalculation") {
        return;
      }
      if (options.role && options.role !== "") {
        newOptions.role = options.role;
      }
      if (options.currency && options.currency !== "") {
        newOptions.currency = options.currency;
      }
      if (options.scale && options.scale !== "") {
        newOptions.scale = options.scale;
      }
      if (options.scenario && options.scenario !== "") {
        newOptions.scenario = options.scenario;
      }
      if (options.hc && options.hc !== "") {
        newOptions.hc = options.hc;
      }
      if (options.headcountViewOption && options.headcountViewOption !== "") {
        newOptions.headcountViewOption = options.headcountViewOption;
      }
      if (options.headcountType && options.headcountType !== "") {
        newOptions.headcountType = options.headcountType;
      }
      if (options.actualViewOption && options.actualViewOption !== "") {
        newOptions.actualViewOption = options.actualViewOption;
      }
      if (options.budgetViewType && options.budgetViewType !== "") {
        newOptions.budgetViewType = options.budgetViewType;
      }
      if (options.bookingType && options.bookingType !== "") {
        newOptions.bookingType = options.bookingType;
      }
      this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CALCULATION_TIME"), newOptions);
    },
    dependencyTableObserver: Ember.observer("budgetService.selectedRoot", "budgetService.scenarios.[]", "budgetService.scenario", function () {
      this.calculateAndSetDependencyTables();
    }),
    calculateAndSetDependencyTables: function calculateAndSetDependencyTables() {
      var _this2 = this;
      var dependencyTable = null;
      var dependencyTableForAllScenarios = {};
      var dependencyTableForScenario = {};
      var selectedRoot = this.get("budgetService.selectedRoot");
      var scenarios = this.get("budgetService.scenarios");
      var scenario = this.get("budgetService.scenario");
      if (selectedRoot) {
        dependencyTable = this.calculateDependencyTable(selectedRoot);
      }
      if (selectedRoot && scenario) {
        dependencyTableForScenario[scenario] = this.calculateDependencyTable(selectedRoot, scenario);
      }
      if (selectedRoot && scenarios.length) {
        scenarios.forEach(function (scenarioName) {
          dependencyTableForAllScenarios[scenarioName] = scenarioName === scenario ? dependencyTableForScenario[scenarioName] : _this2.calculateDependencyTable(selectedRoot, scenarioName);
        });
      }
      this.setProperties({
        dependencyTable: dependencyTable,
        dependencyTableForAllScenarios: dependencyTableForAllScenarios,
        dependencyTableForScenario: dependencyTableForScenario
      });
    },
    dependencyTableWithScenario: Ember.computed("budgetService.scenario", "dependencyTable", "dependencyTableForScenario", function () {
      var scenario = this.get("budgetService.scenario");
      if (scenario && !_const.default.isInteractiveScenario(scenario)) {
        return this.get("dependencyTableForScenario")[scenario];
      }
      return this.get("dependencyTable");
    }),
    lastDirtyTime: new Date(),
    /*
      Does md5 on the json to get the approprite hash key
     */
    hashOptions: function hashOptions(data, opts) {
      if (opts && opts.fast) {
        return md5(JSON.stringify(data));
      }
      var result = "";
      var eachEl = function eachEl(el) {
        if (el) {
          result += (0, _typeof2.default)(el) === "object" && el.id ? el.id : el;
        }
      };
      for (var k in data) {
        if (!(opts && opts.exclude && opts.exclude.includes(k)) && data[k]) {
          if (typeof data[k] === "string") {
            result += k + ":" + data[k];
          } else if (data[k] && data[k].constructor.name === "Array") {
            result += k + ":[";
            data[k].forEach(eachEl);
            result += "]";
          } else {
            result += k + ":" + JSON.stringify(data[k]);
          }
          result += ";";
        }
      }
      return md5(result);
    },
    getItem: function getItem(budgetId, type, hash) {
      var data = this.get("forecastCache.".concat(budgetId, ".").concat(type, ".").concat(hash || "none"));
      return data;
    },
    setItem: function setItem(budgetId, type, hash, data) {
      if (!this.get("forecastCache.".concat(budgetId))) {
        this.set("forecastCache.".concat(budgetId), {});
      }
      if (!this.get("forecastCache.".concat(budgetId, ".").concat(type))) {
        this.set("forecastCache.".concat(budgetId, ".").concat(type), {});
      }
      var cacheEntry = "forecastCache.".concat(budgetId, ".").concat(type, ".").concat(hash || "none");
      this.set(cacheEntry, data);
      if (data && data.options && (data.options.budgetViewType === "act" || data.options.actuals)) {
        this.get("actualsCacheItems").push({
          cacheEntry: "forecastCache.".concat(budgetId, ".").concat(type),
          hash: hash || "none"
        });
      }
    },
    replaceModelsWithId: function replaceModelsWithId(data) {
      var _this3 = this;
      var replaced = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      attributesToReplace.forEach(function (a) {
        if (data && data[a.attr] && data[a.attr].id) {
          data["_".concat(a.attr, "Replaced")] = true;
          replaced[data[a.attr].id] = data[a.attr];
          Ember.set(data, a.attr, data[a.attr].id);
        }
      });
      if (data && data.children) {
        data.children.forEach(function (c) {
          return _this3.replaceModelsWithId(c, replaced);
        });
      }
      if (data && data.previewChildren) {
        data.previewChildren.forEach(function (c) {
          return _this3.replaceModelsWithId(c, replaced);
        });
      }
      if (data && data.options) {
        if (data.options.dimensions) {
          data.options.dimensions.forEach(function (c) {
            return _this3.replaceModelsWithId(c, replaced);
          });
        }
        this.replaceModelsWithId(data.options, replaced);
      }
      if (data && data.dimensions) {
        data.dimensions.forEach(function (c) {
          return _this3.replaceModelsWithId(c, replaced);
        });
      }
      if (data && data.empOptions && data.empOptions.dimensions) {
        data.empOptions.dimensions.forEach(function (c) {
          return _this3.replaceModelsWithId(c, replaced);
        });
      }
      if (data && data.dimension) {
        this.replaceModelsWithId(data.dimension, replaced);
      }
    },
    replaceIdWithModel: function replaceIdWithModel(data) {
      var _this4 = this;
      var replaced = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      attributesToReplace.forEach(function (a) {
        if (data && data["_".concat(a.attr, "Replaced")] && typeof data[a.attr] === "string") {
          Ember.set(data, a.attr, replaced[data[a.attr]] || _this4.get("store").peekRecord(a.modelName || a.attr, data[a.attr]));
        }
      });
      if (data && data.children) {
        data.children.forEach(function (c) {
          return _this4.replaceIdWithModel(c, replaced);
        });
      }
      if (data && data.previewChildren) {
        data.previewChildren.forEach(function (c) {
          return _this4.replaceIdWithModel(c, replaced);
        });
      }
      if (data && data.options) {
        if (data.options.dimensions) {
          data.options.dimensions.forEach(function (c) {
            return _this4.replaceIdWithModel(c, replaced);
          });
        }
        this.replaceIdWithModel(data.options, replaced);
      }
      if (data && data.dimensions) {
        data.dimensions.forEach(function (c) {
          return _this4.replaceIdWithModel(c, replaced);
        });
      }
      if (data && data.empOptions && data.empOptions.dimensions) {
        data.empOptions.dimensions.forEach(function (c) {
          return _this4.replaceIdWithModel(c, replaced);
        });
      }
      if (data && data.dimension) {
        this.replaceIdWithModel(data.dimension, replaced);
      }
    },
    budgetCache: function budgetCache(budget) {
      return this.get("forecastCache.".concat(budget.get("id")));
    },
    clearCalcCache: function clearCalcCache() {
      this.set("forecastCache", {});
    },
    clearIsAncestorCache: function clearIsAncestorCache() {
      this.set("isAncestorCache", {});
    },
    clearUniqueIdMap: function clearUniqueIdMap() {
      this.set("uniqueIdMap", {});
    },
    clearOriginalRootUniqueIdMap: function clearOriginalRootUniqueIdMap() {
      this.set("originalRootUniqueIdMap", {});
    },
    clearActualsCache: function clearActualsCache() {
      var _this5 = this;
      this.get("actualsMapping").initCache();
      // clear all model lines that use actuals
      this.get("store").peekAll("budget").filter(function (l) {
        return l.get("isModelLine") && l.get("budgetAttributesWithScenarios.amountType") === 4 && (l.get("budgetAttributesWithScenarios.useDataType") === "rollf" || l.get("budgetAttributesWithScenarios.useDataType") === "act");
      }).forEach(function (l) {
        return _this5.clearBudgetCache(l);
      });
      this.get("actualsCacheItems").forEach(function (entry) {
        return _this5.get(entry.cacheEntry) && delete _this5.get(entry.cacheEntry)[entry.hash];
      });
    },
    clearAllCache: function clearAllCache() {
      this.get("actualsMapping").initCache();
      this.clearCalcCache();
      this.clearUniqueIdMap();
      this.clearOriginalRootUniqueIdMap();
      this.set("actualsCacheItems", []);
      this.set("dimensionTree", {});
      this.clearIsAncestorCache();
      this.calculateAndSetDependencyTables();
    },
    clearBudgetCache: function clearBudgetCache(budget, type, _this) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      _this = _this || this;
      if (typeof budget === "string") {
        budget = this.get("store").peekRecord("budget", budget);
      }
      if (!budget || !this.get("dependencyTable") || options.exclude && options.exclude.includes(budget)) {
        return;
      }
      if (!options || !(options.level || options.noDependecyChanges)) {
        this.updateDependencyTable(budget, options);
      }
      Ember.Logger.debug("Clearing cache for ".concat(Ember.get(budget, "name")));
      options.level = (options.level || 0) + 1;
      options.exclude = options.exclude || [];
      options.exclude.addObject(budget);
      if (options.level > _const.default.MAX_TREE_DEPTH) {
        return noty({
          error: "".concat(intl.t("general.calculation_loop"), " ").concat(Ember.get(budget, "name"), " ").concat(Ember.get(budget, "parent.name") ? intl.t("general.under") + Ember.get(budget, "parent.name") : "")
        });
      }
      this.set("lastDirtyTime", new Date());
      Ember.set(budget, "cacheChangedTime", this.get("lastDirtyTime"));
      if (type) {
        if (_this.get("forecastCache")[Ember.get(budget, "id")]) {
          if (typeof type === "string") {
            type = [type];
          }
          type.forEach(function (t) {
            delete _this.get("forecastCache")[Ember.get(budget, "id")][t];
          });
        }
      } else {
        delete _this.get("forecastCache")[Ember.get(budget, "id")];
      }
      // if this is a fixed assumption remove from unique Id map
      if (budget.constructor.modelName !== "budget") {
        delete _this.get("uniqueIdMap")[Ember.get(budget, "uniqueId")];
        // clear combinedAssumptions cache
        var rootCache = _this.get("forecastCache.".concat(_this.get("budgetService.selectedRoot.id")));
        if (rootCache) {
          var keys = Object.keys(rootCache).filter(function (k) {
            return k.startsWith("combinedAssumptions_");
          });
          keys.forEach(function (k) {
            return delete rootCache[k];
          });
        }
      }
      // Ember.Logger.debug("Cache cleared for " + Ember.get(budget, 'name'));
      var children = Ember.get(budget, "children");
      if (!options.noChildren && children && Ember.get(children, "length")) {
        var _newOptions = Object.assign({}, options);
        _newOptions.noParent = true;
        children.forEach(function (child) {
          _this.clearBudgetCache(child, type, _this, _newOptions);
        });
      }
      var newOptions = Object.assign({}, options);
      newOptions.noChildren = true;
      if (!newOptions.noParent) {
        _this.clearBudgetCache(Ember.get(budget, "parent"), type, _this, newOptions);
      }
      if (!options.noRefs) {
        _this.findReferencesToId(Ember.get(budget, "uniqueId")).forEach(function (dep) {
          if (!newOptions.exclude.includes(dep)) {
            _this.clearBudgetCache(dep, type, _this, newOptions);
            if (Ember.get(dep, "assumption")) {
              _this.clearBudgetCache(Ember.get(dep, "parent"), undefined, undefined, {
                noChildren: true,
                noRefs: true,
                noDependecyChanges: true
              });
            }
          }
        });
      }
    },
    clearByTypeSearch: function clearByTypeSearch(types) {
      if (typeof types === "string") {
        types = [types];
      }
      var forecastCache = this.get("forecastCache");
      var handleType = function handleType(lineKey, typeKey) {
        return function (type) {
          if (typeKey.indexOf(type) >= 0) {
            delete forecastCache[lineKey][typeKey];
          }
        };
      };
      for (var lineKey in forecastCache) {
        for (var typeKey in forecastCache[lineKey]) {
          types.forEach(handleType(lineKey, typeKey));
        }
      }
    },
    getLineReferences: function getLineReferences(budgetLine, scenario, combinedAssumptions) {
      var store = budgetLine.store,
        _this = this,
        result = [],
        twoWayRefs = [],
        budgetAttributes = scenario ? budgetLine.get("forecastAttributesScenario")[scenario] || budgetLine.get("forecastAttributes") : budgetLine.get("forecastAttributes");
      var addRef = function addRef(prop) {
        if (budgetAttributes[prop]) {
          var ref = _budgetUtils.default.findLineByUniqueId(store, _this, budgetAttributes[prop]);
          if (ref) {
            result.addObject(ref);
            if (prop === "allocatedFrom" && (budgetLine.get("budgetType") === "employee-allocated" || budgetLine.get("budgetType") === "employee-transferred" || budgetLine.get("budgetType") === "expense-allocated")) {
              twoWayRefs.addObject(ref);
            }
            if (prop === "allocatedFrom" && (budgetLine.get("budgetType") === "per-new-employee" || budgetLine.get("budgetType") === "per-employee")) {
              // add all employees under ref
              twoWayRefs.addObjects(_const.default.findBudgetsByTypes(ref, ["employee", "employee-multi"]));
            }
          }
        }
      };
      addRef("allocatedFrom");
      addRef("modelLine");
      addRef("newModelLine");
      addRef("churnModelLine");
      addRef("expansionModelLine");

      // special handling for advanced benefits
      if (budgetLine.get("isEmployee")) {
        var empBenefits = _budgetUtils.default.getBenefits(budgetLine, {
          scenario: scenario
        });
        empBenefits.addObjects(_budgetUtils.default.getInheritedBenefits(budgetLine, {
          scenario: scenario
        }));
        twoWayRefs.addObjects(empBenefits);
        result.addObjects(empBenefits);
      }

      // special handling for past balances
      if (budgetLine.get("budgetModule.typeGroup") === "past-balance") {
        var addRefs = _const.default.findBudgetsByTypeFast(store, budgetLine.get("root"), ["group-revenue", "group-expense-costofsale", "group-expense-other", "group-expense"]).filter(function (line) {
          return line.get("budgetType") !== "group-expense" || line.get("parent.isCompany");
        });
        result.addObjects(addRefs);
        twoWayRefs.addObjects(addRefs);
      }
      if (budgetAttributes.budgetLine) {
        if (typeof budgetAttributes.budgetLine === "string") {
          var ref = _budgetUtils.default.findLineByUniqueId(store, _this, budgetAttributes.budgetLine);
          if (ref) {
            result.addObject(ref);
          }
        } else {
          budgetAttributes.budgetLine.forEach(function (line) {
            var ref = _budgetUtils.default.findLineByUniqueId(store, _this, line);
            if (ref) {
              result.addObject(ref);
            }
          });
        }
      }
      // special handling of model lines that take from budget
      // if no budget lines are selected, then need to add the root budget line
      if (Ember.get(budgetLine, "budgetType") === "assumption" && (Ember.get(budgetAttributes, "amountType") === 4 || Ember.get(budgetAttributes, "amountType") === 5) && !Ember.get(budgetAttributes, "budgetLine.length")) {
        result.addObject(budgetLine.get("root"));
      }

      // go over all budget attributes and look for references in formulas
      var options = {
        store: store,
        fixedAssumptions: combinedAssumptions,
        budget: budgetLine,
        cache: this
      };
      var handleString = function handleString(str) {
        if (_formulaHelper.default.isFormulaString(str)) {
          result.addObjects(_formula.default.getFormulaReferences(str, options));
        }
      };
      var handleElementItem = function handleElementItem(element) {
        return function (elementKey) {
          handleString(element[elementKey]);
        };
      };
      var handleElement = function handleElement(element) {
        if (typeof element === "string") {
          handleString(element);
        } else if (element && (0, _typeof2.default)(element) === "object") {
          Object.keys(element).forEach(handleElementItem(element));
        }
      };
      var getCustomAmountsFunction = function getCustomAmountsFunction(key) {
        return function (monthKey) {
          handleString(budgetAttributes[key][monthKey]);
        };
      };
      for (var key in budgetAttributes) {
        if (key === "customAmounts" || key === "rampUpAmounts" || key.indexOf("CustomAmounts") > 0) {
          if (budgetAttributes[key]) {
            Object.keys(budgetAttributes[key]).forEach(getCustomAmountsFunction(key));
          }
        } else if (key !== "assumptions" && budgetAttributes[key] && Ember.isArray(budgetAttributes[key])) {
          budgetAttributes[key].forEach(handleElement);
        } else if (budgetAttributes[key] && typeof budgetAttributes[key] === "string") {
          handleString(budgetAttributes[key]);
        }
      }

      // reserve depends on its siblings
      if (budgetLine.get("budgetType") === "expense-buffer" && budgetLine.get("parent.children")) {
        result.addObjects(budgetLine.get("parent.children").filter(function (child) {
          return child !== budgetLine;
        }));
      }

      // if allocated per capita, add all relevant S&W groups
      if (budgetAttributes.dimensions && budgetAttributes.dimensions.findBy("allocatePerCapita")) {
        result.addObjects(_const.default.findBudgetsByTypeFast(store, budgetLine.get("root"), "group-payroll"));
      }

      // if this line has amortize over property set, then all siblings with amortizeExpense property are dependants
      if (budgetAttributes.amortizeOver) {
        result.addObjects(_const.default.findBudgets(budgetLine, {
          "budgetAttributes.amortizeExpense": true
        }));
      }
      return result.map(function (ref) {
        if (ref.notifyPropertyChange) {
          ref.notifyPropertyChange("lineRefsChanged");
        }
        return {
          ref: ref,
          isTwoWay: twoWayRefs.includes(ref)
        };
      });
    },
    getAssumptionReferences: function getAssumptionReferences(store, assumption, scenario, fixedAssumptions) {
      if (_formulaHelper.default.isFormulaString(assumption.value)) {
        return _formula.default.getFormulaReferences(assumption.value, {
          store: store,
          fixedAssumptions: fixedAssumptions,
          cache: this
        });
      }
      return [];
    },
    calculateDependencyTable: function calculateDependencyTable(rootLine, scenario) {
      var _this6 = this;
      var result = {
        lines: {},
        assumptions: {}
      };
      var store = rootLine.get("store");
      var combinedAssumptions = _formula.default.getCombinedAssumptions(store, rootLine, scenario);
      var fixedAssumptions = _assumption.default.getFixedAssumptions(rootLine.get("absoluteRoot"), scenario, null, {
        ignoreScope: true
      });
      rootLine.store.peekAll("budget").forEach(function (line) {
        if (line && line.get("absoluteRoot") === rootLine.get("absoluteRoot")) {
          _this6.set("uniqueIdMap.".concat(Ember.get(line, "uniqueId")), line);
          _this6.setLineReferencesInDependencyTable(result, line, {
            scenario: scenario,
            combinedAssumptions: combinedAssumptions,
            fixedAssumptions: fixedAssumptions
          });
        }
      });
      return result;
    },
    setLineReferencesInDependencyTable: function setLineReferencesInDependencyTable(table, line, options) {
      var _this7 = this;
      var scenario = options.scenario,
        combinedAssumptions = options.combinedAssumptions,
        fixedAssumptions = options.fixedAssumptions;
      fixedAssumptions = fixedAssumptions || _assumption.default.getFixedAssumptions(this.get("budgetService.selectedRoot.absoluteRoot"), scenario, null, {
        ignoreScope: true
      });
      if (line.constructor.modelName === "budget") {
        combinedAssumptions = combinedAssumptions || _formula.default.getCombinedAssumptions(this.get("store"), line.get("root"), scenario);
        if (table.lines[line.get("uniqueId")]) {
          Object.keys(table.lines[line.get("uniqueId")]).forEach(function (refKey) {
            if (table.lines[line.get("uniqueId")][refKey].notifyPropertyChange) {
              table.lines[line.get("uniqueId")][refKey].notifyPropertyChange("lineRefsChanged");
            }
          });
        }
        table.lines[line.get("uniqueId")] = {};
        this.getLineReferences(line, scenario, combinedAssumptions).forEach(function (refObj) {
          var ref = refObj.ref;
          table.lines[line.get("uniqueId")][Ember.get(ref, "uniqueId")] = ref;
          if (refObj.isTwoWay) {
            table.lines[Ember.get(ref, "uniqueId")] = table.lines[Ember.get(ref, "uniqueId")] || {};
            table.lines[Ember.get(ref, "uniqueId")][line.get("uniqueId")] = line;
          }
        });
        if (line.get("budgetType") === "group-model") {
          var budgetAttributes = _budgetUtils.default.getBudgetAttributesWithScenario(line, scenario);
          if (budgetAttributes.assumptions) {
            budgetAttributes.assumptions.forEach(function (assumption) {
              table.assumptions[Ember.get(assumption, "uniqueId")] = _this7.getAssumptionReferences(line.get("store"), assumption, scenario, fixedAssumptions);
            });
          }
        }
        line.notifyPropertyChange("lineRefsChanged");
      } else {
        // this is an assumption
        table.assumptions[Ember.get(line, "uniqueId")] = this.getAssumptionReferences(this.get("store"), line, scenario, fixedAssumptions);
      }
    },
    findOneReferenceRecursive: function findOneReferenceRecursive(budgetLine, excludeScope) {
      var ref = this.findOneReferenceToIdInAllScenariosAndBase(budgetLine.get("uniqueId"), excludeScope);
      if (ref) {
        ref.budgetLine = budgetLine;
        return ref;
      } else {
        if (budgetLine.get("budgetAttributes.assumptions.length")) {
          for (var i = 0; i < budgetLine.get("budgetAttributes.assumptions.length"); i++) {
            ref = this.findOneReferenceToIdInAllScenariosAndBase(Ember.get(budgetLine.get("budgetAttributes.assumptions")[i], "uniqueId"), excludeScope);
            if (ref) {
              ref.budgetLine = budgetLine;
              return ref;
            }
          }
        }
        if (budgetLine.get("children.length")) {
          var childrenArray = budgetLine.get("children").toArray();
          for (var _i = 0; _i < budgetLine.get("children.length"); _i++) {
            ref = this.findOneReferenceRecursive(childrenArray[_i], excludeScope);
            if (ref) {
              ref.budgetLine = budgetLine;
              return ref;
            }
          }
        }
      }
      return null;
    },
    findOneReferenceToIdInAllScenariosAndBase: function findOneReferenceToIdInAllScenariosAndBase(uniqueId, excludeScope) {
      var result = this.findReferencesToId(uniqueId, this.get("dependencyTable"));
      var resultFilter = function resultFilter(item) {
        return item !== excludeScope && Ember.get(item, "parent") !== excludeScope && Ember.get(item, "parent.parent") !== excludeScope && Ember.get(item, "uniqueId") !== uniqueId;
      };
      result = result ? result.filter(resultFilter) : null;
      if (result && result.length) {
        return {
          scenario: null,
          ref: result[0]
        };
      }
      if (this.get("budgetService.scenarios.length")) {
        for (var i = 0; i < this.get("budgetService.scenarios.length"); i++) {
          var scenario = this.get("budgetService.scenarios")[i];
          var _result = this.findReferencesToId(uniqueId, this.get("dependencyTableForAllScenarios")[scenario], scenario);
          _result = _result ? _result.filter(resultFilter) : null;
          if (_result && _result.length) {
            return {
              scenario: scenario,
              ref: _result[0]
            };
          }
        }
      }
      return null;
    },
    findReferencesToId: function findReferencesToId(uniqueId, table, scenario) {
      var _this8 = this;
      var dependencyTable = table || this.get("dependencyTableWithScenario");
      var result = [];
      if (dependencyTable && dependencyTable.lines) {
        result.pushObjects(Object.keys(dependencyTable.lines).map(function (lineId) {
          if (dependencyTable.lines[lineId] && dependencyTable.lines[lineId][uniqueId]) {
            return _budgetUtils.default.findLineByUniqueId(_this8.get("store"), _this8, lineId);
          }
          return null;
        }).filter(function (val) {
          return val;
        }));
      }
      if (dependencyTable && dependencyTable.assumptions) {
        result.pushObjects(Object.keys(dependencyTable.assumptions).map(function (lineId) {
          if (dependencyTable.assumptions[lineId] && dependencyTable.assumptions[lineId].findBy("uniqueId", uniqueId)) {
            return (scenario ? _assumption.default.getFixedAssumptions(_this8.get("budgetService.selectedRoot.absoluteRoot"), scenario, null, {
              ignoreScope: true,
              withParent: true
            }) : _this8.get("budgetService.fixedAssumptions")).findBy("assumption.uniqueId", lineId);
          }
          return null;
        }).filter(function (val) {
          return val;
        }));
      }
      return result;
    },
    updateDependencyTable: function updateDependencyTable(budgetLine) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.updateDependencyTableForScenario(budgetLine, this.get("dependencyTable"), options);
      if (this.get("budgetService.scenario")) {
        var scenarioTables = this.get("dependencyTableForScenario");
        this.updateDependencyTableForScenario(budgetLine, scenarioTables[this.get("budgetService.scenario")], Object.assign({
          scenario: this.get("budgetService.scenario")
        }, options));
      }
    },
    updateDependencyTableForScenario: function updateDependencyTableForScenario(budgetLine, dependencyTable) {
      var _this9 = this;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var linesToDelete = options.linesToDelete;
      linesToDelete = linesToDelete || [];
      if (!dependencyTable) {
        return;
      }
      this.setLineReferencesInDependencyTable(dependencyTable, budgetLine, options);
      // if the line has a reserve sibling need to add it to its dependencies
      if (Ember.get(budgetLine, "parent.children.length") && (Ember.get(budgetLine, "budgetModule.allowedChildTypes") || []).includes("expense-buffer")) {
        var reserve = Ember.get(budgetLine, "parent.children").findBy("budgetType", "expense-buffer");
        if (reserve && !linesToDelete.includes(reserve)) {
          dependencyTable.lines[reserve.get("uniqueId")] = dependencyTable.lines[reserve.get("uniqueId")] || {};
          dependencyTable.lines[reserve.get("uniqueId")][Ember.get(budgetLine, "uniqueId")] = budgetLine;
        }
      }

      // if this is a Salary & Wages group, need to add it to all allocate per capita lines and update all employees under it
      if (Ember.get(budgetLine, "budgetType") === "group-payroll") {
        var departmentAllocationPerCapita = budgetLine.get("store").peekAll("budget").filter(function (line) {
          return line && !linesToDelete.includes(line) && line.get("budgetAttributesWithScenarios") && line.get("budgetAttributesWithScenarios").departmentAllocationPerCapita;
        });
        if (departmentAllocationPerCapita) {
          departmentAllocationPerCapita.forEach(function (line) {
            if (dependencyTable.lines[line.get("uniqueId")]) {
              dependencyTable.lines[line.get("uniqueId")][Ember.get(budgetLine, "uniqueId")] = budgetLine;
            }
          });
        }
        if (Ember.get(budgetLine, "children.length")) {
          Ember.get(budgetLine, "children").forEach(function (emp) {
            return emp && emp.get("isEmployee") && _this9.updateDependencyTableForScenario(emp, dependencyTable, options);
          });
        }
      }
    },
    deleteFromDependencyTable: function deleteFromDependencyTable(element, type) {
      // type can be 'lines' or 'assumptions'
      // if deleting a line from base, also delete from all scenario tables
      if (type === "lines" && !this.get("budgetService.scenario")) {
        var scenarioTables = this.get("dependencyTableForScenario");
        Object.keys(scenarioTables).forEach(function (scenario) {
          delete scenarioTables[scenario][type][Ember.get(element, "uniqueId")];
        });
      }
      if (this.get("dependencyTableWithScenario")) {
        delete this.get("dependencyTableWithScenario")[type][Ember.get(element, "uniqueId")];
      }
    },
    lineHasReferences: function lineHasReferences(budgetLine, scenario) {
      var depTableProp = scenario ? "dependencyTable" : "dependencyTableForAllScenarios.".concat(scenario);
      var refs = this.get("".concat(depTableProp, ".lines.").concat(budgetLine.get("uniqueId")));
      return refs && Object.keys(refs).length > 0;
    },
    getForecastCache: function getForecastCache() {
      return this.get("forecastCache");
    },
    actualsCacheItemsCache: function actualsCacheItemsCache() {
      return this.get("actualsCacheItems");
    },
    updateVersionsUniqueIdMap: function updateVersionsUniqueIdMap(budget, uniqueId, rootId) {
      var versionsUniqueIdMap = this.get("versionsUniqueIdMap.".concat(rootId));
      if (!versionsUniqueIdMap) {
        versionsUniqueIdMap = {};
        this.set("versionsUniqueIdMap.".concat(rootId), versionsUniqueIdMap);
      }
      Ember.set(versionsUniqueIdMap, "" + uniqueId, budget || null);
    }
  });
});