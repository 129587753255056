define("budgeta/utils/assumption", ["exports", "budgeta/utils/const", "budgeta/utils/budget-utils", "budgeta/services/ember-service-container"], function (_exports, _const, _budgetUtils, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var Assumption = {
    getCombinedAssumptions: function getCombinedAssumptions(store, budgetRoot, scenario, options) {
      options = options || {};
      options.ignoreScope = true;
      var absoluteRoot = budgetRoot.get("absoluteRoot");
      var fixedAssumptions = Assumption.getFixedAssumptions(absoluteRoot, scenario, null, options);
      var modelLines = _const.default.findBudgetsByTypeFast(store, absoluteRoot, "assumption");
      return Assumption.combineAssumptions(modelLines, fixedAssumptions);
    },
    combineAssumptions: function combineAssumptions(a1, a2) {
      var combinedAssumptions = [];
      combinedAssumptions.pushObjects(a1);
      combinedAssumptions.pushObjects(a2);
      combinedAssumptions = combinedAssumptions.sort(function (a, b) {
        return Ember.get(b, "name.length") - Ember.get(a, "name.length");
      });
      return combinedAssumptions;
    },
    nameExists: function nameExists(root, scope, name, value, uniqueId, modelLine, fixedAssumption) {
      var found = _const.default.findBudgetsByTypes(root, "assumption").find(function (line) {
        return line.get("name").trim().toLowerCase() === name.trim().toLowerCase() && (!modelLine || modelLine.get("parent.title") && line.get("parent.title") && modelLine.get("parent.title").trim().toLowerCase() === line.get("parent.title").trim().toLowerCase()) && (!modelLine && !uniqueId && Ember.isEmpty(value) || modelLine && modelLine !== line || uniqueId && line.get("uniqueId") && line.get("uniqueId") !== uniqueId || !Ember.isEmpty(value) && line.get("amount") !== value);
      });
      if (found) {
        return found;
      }
      return Assumption.findAssumptionByName(root, fixedAssumption, name, value, uniqueId, scope);
    },
    findAssumptionByName: function findAssumptionByName(root, fixedAssumption, name, value, id, scope) {
      if (Ember.get(root, "isModelGroup") && root.get("budgetAttributesWithScenarios.assumptions.length")) {
        var found = root.get("budgetAttributesWithScenarios.assumptions").find(function (line) {
          return Ember.get(line, "name").trim().toLowerCase() === name.trim().toLowerCase() && (!id && !fixedAssumption || id && Ember.get(line, "uniqueId") && Ember.get(line, "uniqueId") !== id);
        });
        if (found) {
          return found;
        }
      }
      if (root.get("children.length")) {
        for (var i = 0; i < root.get("children.length"); i++) {
          var _found = Assumption.findAssumptionByName(root.get("children").objectAt(i), fixedAssumption, name, value, id, scope);
          if (_found) {
            return _found;
          }
        }
      }
      return false;
    },
    getFixedAssumptions: function getFixedAssumptions(root, scenario, scope, options) {
      var result = [];
      options = options || {};
      if (!root) {
        return result;
      }
      if (Ember.get(root, "isModelGroup")) {
        var scenarioData = scenario && Ember.get(root, "scenarios") ? Ember.get(root, "scenarios").findBy("scenario", scenario) : null;
        if (!scenarioData && options.addScenarioDataIfMissing) {
          scenarioData = options.store.createRecord("scenario", {
            scenario: scenario,
            budgetAttributes: _const.default.cloneAttributesForScenario(Ember.get(root, "budgetAttributes"))
          });
          Ember.get(root, "scenarios").pushObject(scenarioData);
        }
        var assumptions;
        if (options.mergeWithBase || _const.default.isInteractiveScenario(scenario)) {
          assumptions = Ember.get(root, "budgetAttributes.assumptions");
          var scenarioAssumptions = scenarioData ? Ember.get(scenarioData, "budgetAttributes.assumptions") : Ember.A([]);
          if (assumptions && scenarioAssumptions) {
            assumptions = assumptions.map(function (assumption) {
              return scenarioAssumptions.findBy("uniqueId", assumption.uniqueId) || Ember.$.extend(true, {}, assumption);
            });
          }
        } else {
          assumptions = Ember.get(scenarioData || root, "budgetAttributes.assumptions");
        }
        if (assumptions) {
          result.pushObjects(assumptions);
          if (options.withOriginalValue) {
            result.forEach(function (a) {
              var originalAssumptions = Ember.get(root, "budgetAttributes.assumptions");
              var originalAssumption = originalAssumptions && originalAssumptions.findBy("uniqueId", Ember.get(a, "uniqueId"));
              Ember.setProperties(a, {
                originalValue: originalAssumption ? Ember.get(originalAssumption, "value") : Ember.get(a, "value"),
                originalCurrency: originalAssumption ? Ember.get(originalAssumption, "currency") : Ember.get(a, "currency"),
                originalName: originalAssumption ? Ember.get(originalAssumption, "name") : Ember.get(a, "name")
              });
            });
          }
          if (options.withParent) {
            result = result.map(function (a) {
              return {
                parent: root,
                assumption: a,
                uniqueId: Ember.get(a, "uniqueId"),
                name: Ember.get(a, "name")
              };
            });
          }
        }
      }
      if (Ember.get(root, "children.length")) {
        Ember.get(root, "children").filterBy("isModelGroup").forEach(function (child) {
          result.pushObjects(Assumption.getFixedAssumptions(child, scenario, scope, options));
        });
      }
      return result;
    },
    modelLineInScope: function modelLineInScope(line1, line2) {
      if (Ember.get(line1, "budgetType") === "assumption") {
        line1 = Ember.get(line1, "parent");
      }
      if (Ember.get(line2, "budgetType") === "assumption") {
        line2 = Ember.get(line2, "parent");
      }
      if (!line1 || !line2) {
        return false;
      }
      return line1.get("isModelGroup") && line1 === line2 || line1.get("parent.isModelGroup") && line1.get("parent") === line2.get("parent") || line1.get("parent.parent.isModelGroup") && line1.get("parent.parent") === line2.get("parent.parent");
    },
    wrapName: function wrapName(name, type, id, parent, assumption, editModel) {
      var finalName = name.htmlSafe();
      if (parent && editModel && (Ember.get(editModel, "parent.uniqueId") === parent || Ember.get(editModel, "uniqueId") === parent)) {
        finalName = Ember.get(assumption, "realName") || name;
      }
      return "<span class=\"".concat(type, "\" data-id=\"").concat(id, "\" data-parent=\"").concat(parent, "\">").concat(finalName, "</span>");
    },
    getModelReferences: function getModelReferences(store, modelLine) {
      if (!modelLine || !modelLine.get("uniqueId")) {
        return null;
      }
      var scenario = modelLine.get("budgetService.scenario"),
        budgets = store.peekAll("budget"),
        filteredBudgets;
      if (!scenario) {
        filteredBudgets = budgets.filter(function (budget) {
          return !budget.get("isBudgetAddedForScenario");
        });
      } else {
        filteredBudgets = budgets.filter(function (budget) {
          return !budget.get("isBudgetAddedForScenario") || budget.get("addedForScenarios").includes(scenario);
        });
      }
      return filteredBudgets.filter(function (line) {
        return line && line.get("root") === modelLine.get("root") && (line.get("budgetType") === "expense-from-model" || line.get("budgetType") === "revenue-from-model" || line.get("budgetType") === "sales-subscription") && line.get("budgetAttributesWithScenarios.allocatedFrom") === modelLine.get("uniqueId");
      });
    },
    removeModelReferences: function removeModelReferences(store, modelLine) {
      if (!modelLine || !modelLine.get("uniqueId")) {
        return null;
      }
      store.peekAll("budget").filter(function (line) {
        return line && line.get("root") === modelLine.get("root") && (line.get("budgetType") === "expense-from-model" || line.get("budgetType") === "revenue-from-model" || line.get("budgetType") === "sales-subscription") && line.get("budgetAttributes.allocatedFrom") === modelLine.get("uniqueId");
      }).forEach(function (line) {
        line.set("budgetAttributes.allocatedFrom", undefined);
        line.save();
      });
    },
    isStandAloneAssumption: function isStandAloneAssumption(cache, assumption) {
      if (!cache || !assumption) {
        return false;
      }
      var tempAssumptionDep = Ember.get(cache, "dependencyTable.assumptions.".concat(Ember.get(assumption, "uniqueId")));
      if (tempAssumptionDep && tempAssumptionDep.length) {
        return false;
      }
      return true;
    },
    getModelCurrencyOptions: function getModelCurrencyOptions(currencyOptions) {
      var isRefBudgetLinesExist = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      // if the model line has budget lines referencing it, it has to be currency
      var initial = isRefBudgetLinesExist ? [] : [{
        id: "%",
        name: "%",
        value: "%"
      }, {
        id: "#",
        name: "#",
        value: "#"
      }, {
        id: ".",
        name: "#.##",
        value: "."
      }];
      return initial.concat(currencyOptions.filter(function (option) {
        return option.value;
      }).map(function (c) {
        return {
          id: Ember.get(c, "value"),
          name: Ember.get(c, "value"),
          value: Ember.get(c, "value")
        };
      }));
    },
    getUnitTypeAsPctCurrency: function getUnitTypeAsPctCurrency(unitType) {
      return unitType === "." ? "#.##" : unitType;
    },
    getModelLineCurrency: function getModelLineCurrency(store, budget, options, level) {
      if ((level || options.level) > _const.default.MAX_CALCULATION_DEPTH) {
        noty({
          type: "error",
          timeout: false,
          text: "".concat(intl.t("general.a_loop_was_detected_in_model_line"), " ").concat(Ember.get(budget, "name"), " ").concat(Ember.get(budget, "parent.name") ? intl.t("general.under") + Ember.get(budget, "parent.name") : "")
        });
        Ember.set(budget, "loopError", true);
        return null;
      }
      var scenarioData = options.scenario && Ember.get(budget, "scenarios") ? Ember.get(budget, "scenarios").findBy("scenario", options.scenario) : null;
      var budgetAttributes = Ember.get(scenarioData || budget, "budgetAttributes") || {};
      var amountType = budgetAttributes.amountType;
      var lineCurrency = budgetAttributes.pctCurrency;
      if (amountType === 5 && budgetAttributes.headcountUse && budgetAttributes.headcountUse !== "count") {
        return budgetAttributes.currency || options && options.currency || Ember.get(budget, "forecastAttributes.currency");
      }
      if (amountType === 5) {
        return ".";
      }
      if (amountType === 4) {
        // take from budget
        return budgetAttributes.currency || Ember.get(budget, "forecastAttributes.currency");
      }
      if (amountType === 7 || amountType === 6 || amountType === 3) {
        // monthly diff or running total or previous month value
        var modelLine = _budgetUtils.default.findLineByUniqueId(store, Ember.get(budget, "cache"), budgetAttributes.modelLine, Ember.get(budget, "root.id"));
        if (modelLine) {
          lineCurrency = Assumption.getModelLineCurrency(store, modelLine, options, (level || options.level || 0) + 1);
        }
      }
      return lineCurrency;
    },
    getUnitTypeForMapping: function getUnitTypeForMapping(assumption, store, cache, scenario) {
      var isMappingMappedToEmpty = function isMappingMappedToEmpty(modelLine) {
        if (!modelLine) return true;
        var isTakenFromModelLineAndNotMapped = modelLine.get("budgetAttributes.amountType") === 3 && !modelLine.get("budgetAttributes.modelLine");
        if (isTakenFromModelLineAndNotMapped) {
          return true;
        }
        if (modelLine.get("budgetAttributes.amountType") !== 3) {
          return false;
        }
        var mappedModelLine = _budgetUtils.default.findLineByUniqueId(store, cache, modelLine.get("budgetAttributes.modelLine"), undefined, scenario);
        return isMappingMappedToEmpty(mappedModelLine);
      };
      if (assumption && assumption.get("budgetType") === "assumption") {
        if (assumption.get("loopError")) {
          return "";
        }
        if (isMappingMappedToEmpty(assumption)) {
          return "";
        }
        var unitType = Assumption.getModelLineCurrency(store, assumption, {});
        return unitType;
      }
      return "";
    },
    getModelLineGroup: function getModelLineGroup(assumption) {
      if (!Ember.get(assumption, "isModelGroup")) {
        return Assumption.getModelLineGroup(Ember.get(assumption, "parent"));
      }
      return assumption;
    }
  };
  var _default = _exports.default = Assumption;
});