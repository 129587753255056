define("budgeta/controllers/setup-guide-modal", ["exports", "budgeta/controllers/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modal.default.extend({
    setupGuide: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    // used in hbs
    selectedRoot: Ember.computed.alias("budgetService.selectedRoot"),
    showBack: true,
    hideCancel: true,
    hideConfirm: true,
    lastSelectedRoot: null,
    selectedGroup: undefined,
    totalSteps: 1,
    stepsCount: 1,
    lastStep: Ember.computed("totalSteps", "stepsCount", function () {
      return this.get("totalSteps") === this.get("stepsCount");
    }),
    stepsObserver: Ember.observer("selectedGroup", "selectedGroup.missions.@each.active", "guideMissions", "guideMissions.groups.@each.missionStatuses", "guideMissions.groups.@each.missionsActives", function () {
      this.stepsChanged();
    }),
    stepsChanged: function stepsChanged() {
      var totalSteps = 0,
        stepsCount = 0;
      var countGroup = function countGroup(group) {
        var subTotal = 0,
          subStep = 0;
        group.missions.forEach(function (mission) {
          if (mission.active) {
            subTotal++;
            if (mission.status === "complete") {
              subStep++;
            }
          }
        });
        Ember.set(group, "totalSteps", subTotal);
        Ember.set(group, "steps", subStep);
        totalSteps += subTotal;
        stepsCount += subStep;
      };
      var groupMissions = this.get("guideMissions");
      groupMissions.groups.forEach(countGroup);
      this.setProperties({
        totalSteps: totalSteps,
        stepsCount: stepsCount
      });
    },
    guideMissions: Ember.computed.alias("setupGuide.guideMissions"),
    init: function init() {
      this._super.apply(this, arguments);
      this.set("selectedGroup", undefined);
      this.stepsChanged();
    },
    gotoNextIncompleteGroup: function gotoNextIncompleteGroup(startIndex) {
      for (var i = startIndex; i < this.get("guideMissions.groups.length"); i++) {
        var thisGroup = this.get("guideMissions.groups")[i];
        if (thisGroup.missions.find(function (mission) {
          return mission.active && mission.status !== "complete";
        })) {
          this.set("selectedGroup", thisGroup);
          return true;
        }
      }
      return false;
    },
    toggleStep: function toggleStep(mission, add) {
      var _this = this;
      Ember.run.later(function () {
        // run after the dropdown closes
        Ember.set(mission, "active", add);
        var selectedRoot = _this.get("selectedRoot");
        var setupGuideOptions = _this.get("selectedRoot.setupGuideOptions");
        var missionOption = setupGuideOptions.findBy("optionName", mission.optionName);
        if (missionOption) {
          Ember.set(missionOption, "active", add);
        }
        _this.send("saveRoot", {
          rootBudget: selectedRoot,
          noRefresh: true,
          noClearCache: true,
          noReload: true,
          ignoreNotify: true
        }, {
          setupGuideOptions: selectedRoot.get("setupGuideOptions")
        });
      }, 500);
    },
    actions: {
      next: function next() {
        // go to the next incomplete group
        var startIndex = this.get("selectedGroup") ? this.get("guideMissions.groups").indexOf(this.get("selectedGroup")) + 1 : 0;
        if (!this.gotoNextIncompleteGroup(startIndex)) {
          this.gotoNextIncompleteGroup(0);
        }
      },
      back: function back() {
        this.set("selectedGroup", undefined);
      },
      setupAnimation: function setupAnimation() {
        this.set("selectedGroup", undefined);
        Ember.$("body > .app-view > .main-view").append('<div class="setup-guide-box-wrapper">' + '<div class="setup-guide-box">' + '<svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">' + '<path d="M0 0h24v24H0z" fill="none"/>' + '<path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>' + "</svg>" + "</div>" + "</div>");
        Ember.run.later(this, function () {
          Ember.$("body > .app-view > .main-view > .setup-guide-box-wrapper").remove();
        }, 450);
      },
      close: function close() {
        this.send("setupAnimation");
        this.get("setupGuide").notifyPropertyChange("recalcGuide");
        this._super();
      },
      confirm: function confirm() {
        this.set("selectedGroup", undefined);
        this.send("setupAnimation");
        this.get("setupGuide").notifyPropertyChange("recalcGuide");
        this._super();
      },
      gotoAction: function gotoAction(mission) {
        var _this2 = this;
        this.send("confirm");
        Ember.run.later(this, function () {
          _this2.send("gotoActionFromSetupGuide", mission);
        }, 450);
      },
      selectGroup: function selectGroup(group) {
        this.set("selectedGroup", group);
      },
      removeStep: function removeStep(mission) {
        this.toggleStep(mission, false);
      },
      addStep: function addStep(mission) {
        this.toggleStep(mission, true);
      }
    }
  });
});