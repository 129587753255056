define("budgeta/services/server-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var data = {};
  var getConfig = function getConfig() {
    return data;
  };
  var setConfig = function setConfig(_data) {
    data = _data;
  };
  var _default = _exports.default = {
    getConfig: getConfig,
    setConfig: setConfig
  };
});