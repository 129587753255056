define("budgeta/helpers/is-even", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEven = isEven;
  /*
    example usage:
      {{#if (is-even 2)}} //true
        {{debugger}}
      {{/if}}
    example usage:
      {{#unless (is-even 1)}} //true
        {{debugger}}
      {{/unless}}
  */
  function isEven(index) {
    return index % 2 === 0;
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return isEven(params[0]);
  });
});