define("budgeta/templates/components/select-intacct-dimension-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WfYyu1aZ",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"dimension\",\"hasLevels\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"select-intacct-dimemsion-level\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"searchEnabled\",\"content\",\"value\",\"optionValuePath\"],[false,[\"get\",[\"content\"]],[\"get\",[\"selected\",\"level\"]],\"id\"]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/select-intacct-dimension-level.hbs"
    }
  });
});