define("budgeta/components/youtube-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["youtube-thumbnail"],
    thumbnailSrc: Ember.computed("youtubeId", function () {
      return "https://i.ytimg.com/vi/".concat(this.get("youtubeId"), "/hqdefault.jpg");
    })
  });
});