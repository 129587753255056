define("budgeta/components/selectize-exclusions", ["exports", "ember-cli-selectize/components/ember-selectize"], function (_exports, _emberSelectize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberSelectize.default.extend({
    content: Ember.computed("allContent.length", "exclude.[]", "optionValuePath", "value", function () {
      var optionValuePath = this.get("optionValuePath").replace("content.", ""),
        exclude = this.get("exclude"),
        value = this.get("value");
      if (this.get("dimension")) {
        this.get("dimension").valuesChanged();
      }
      return this.get("allContent").filter(function (item) {
        return exclude.indexOf(Ember.get(item, optionValuePath)) === -1 || Ember.get(item, optionValuePath) === value;
      });
    })
  });
});