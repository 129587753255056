define("budgeta/templates/components/integration-wizard-import-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TW/b2+yF",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"model\",\"transactions\",\"length\"]]],null,2,0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"t\"],[\"integration-wizard-import-done.we-didnt-find\"],null],false],[\"text\",\"\\n    \"],[\"open-element\",\"a\",[]],[\"static-attr\",\"class\",\"link\"],[\"modifier\",[\"action\"],[[\"get\",[null]],\"changeCurrentStep\",\"integration-wizard-select-reports\"]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"integration-wizard-import-done.start-again\"],null],false],[\"close-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"t\"],[\"integration-wizard-import-done.modify-selections\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"unknown\",[\"message\"]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"message\"]]],null,1]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/integration-wizard-import-done.hbs"
    }
  });
});