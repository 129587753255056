define("budgeta/components/integration-wizard-select-book", ["exports", "budgeta/utils/intacct"], function (_exports, _intacct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("model.selectedMainBooksWizard")) {
        this.set("model.selectedMainBooksWizard", this.get("model.financialSetup.defaultBook"));
      }
    },
    mainReportiongBooksList: Ember.computed("model.{mainReportingBooks,userDefinedBooks}", function () {
      return _intacct.default.mainReportiongBooksList(this.get("model"));
    }),
    additionalReportiongBooksList: Ember.computed("model.userDefinedBooks", function () {
      return _intacct.default.additionalReportiongBooksList(this.get("model"));
    }),
    mainBookTitle: Ember.computed("model.userDefinedBooks", function () {
      return _intacct.default.mainBookTitle(this.get("model"));
    }),
    bookToolTip: Ember.computed("model.selectedMainBooksWizard", function () {
      var mainBooks = this.get("mainReportiongBooksList");
      var selecedtMainBook = this.get("model.selectedMainBooksWizard") || this.get("model.financialSetup.defaultBook");
      return mainBooks.findBy("id", selecedtMainBook) ? mainBooks.findBy("id", selecedtMainBook).text : "";
    }),
    actions: {
      additionalBookChanged: function additionalBookChanged(book) {
        this.set("model.selectedAdditionalBooksWizard", book.mapBy("id"));
      }
    }
  });
});