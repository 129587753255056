define("budgeta/utils/wait-for-job", ["exports", "budgeta/config/environment", "budgeta/utils/const"], function (_exports, _environment, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var module = {
    waitForJob: function waitForJob(jobId) {
      var wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3000;
      return new Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: "GET",
          url: "".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/jobs/").concat(jobId),
          contentType: "application/json; charset=utf-8",
          success: function success(result) {
            var isScheduledJob = function isScheduledJob(job) {
              return !!job.repeatInterval;
            };
            if (result.disabled && !isScheduledJob(result)) {
              reject();
            } else if (result.failReason) {
              reject(result.failReason);
              Ember.Logger.error(result.failReason);
            } else if (result.lastFinishedAt) {
              var response = result.data && result.data.result;
              resolve(response);
            } else {
              _const.default.sleep(wait).then(function () {
                return module.waitForJob(jobId).then(resolve, reject);
              });
            }
          },
          error: reject
        });
      });
    }
  };
  var _default = _exports.default = module.waitForJob;
});