define("budgeta/generic-file/components/input-box/input-box", ["exports", "@babel/runtime/helpers/esm/objectWithoutProperties", "react"], function (_exports, _objectWithoutProperties2, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _excluded = ["input", "footer", "boxClassName", "boxDataHook"];
  var InputBox = function InputBox(_ref) {
    var input = _ref.input,
      footer = _ref.footer,
      boxClassName = _ref.boxClassName,
      _ref$boxDataHook = _ref.boxDataHook,
      boxDataHook = _ref$boxDataHook === void 0 ? "input-box" : _ref$boxDataHook,
      rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
    var containerClasses = "input-box-container ".concat(boxClassName, " ").concat(footer ? "input-box-footer" : "");
    var childWithExtraProp = _react.default.Children.map(input, function (child) {
      return _react.default.cloneElement(child, rest);
    });
    return _react.default.createElement("div", {
      "data-hook": boxDataHook,
      className: containerClasses
    }, _react.default.createElement("div", {
      className: "base-input-box-container"
    }, childWithExtraProp), footer ? _react.default.createElement("div", {
      className: "input-box-footer"
    }, footer) : null);
  };
  var _default = _exports.default = InputBox;
});