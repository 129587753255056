define("budgeta/templates/components/attribute-group-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tF+OLm54",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"label\"]]],null,0],[\"append\",[\"helper\",[\"select-2\"],null,[[\"cssClass\",\"searchEnabled\",\"enabled\",\"multiple\",\"placeholder\",\"value\",\"content\",\"optionLabelPath\",\"optionValuePath\"],[\"wrap\",false,[\"get\",[\"enabled\"]],[\"get\",[\"multiple\"]],[\"get\",[\"displayPlaceholder\"]],[\"get\",[\"value\"]],[\"get\",[\"content\"]],[\"get\",[\"optionLabelPath\"]],[\"get\",[\"optionValuePath\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"label\",[]],[\"dynamic-attr\",\"class\",[\"unknown\",[\"labelClass\"]],null],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/attribute-group-select.hbs"
    }
  });
});