define("budgeta/components/by-geo", ["exports", "budgeta/templates/components/by-geo"], function (_exports, _byGeo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _byGeo.default,
    classNames: ["wrap", "by-geo"],
    init: function init() {
      this._super.apply(this, arguments);
      this.set("elementId", "attribute-" + this.get("key") + "-" + this.get("elementId"));
      this.valueChanged();
    },
    valueObserver: Ember.observer("value", function () {
      this.valueChanged();
    }),
    valueChanged: function valueChanged() {
      if (Ember.isEmpty(this.get("value")) || this.get("value.length") === 0) {
        this.set("value", [{
          geographyId: null,
          value: null
        }]);
      }
    },
    enabled: Ember.computed.not("readOnly"),
    actions: {
      addGeo: function addGeo() {
        var newVal = {
          geographyId: null,
          value: null
        };
        if (this.get("value")) {
          this.get("value").pushObject(newVal);
        } else {
          this.set("value", Ember.A([newVal]));
        }
        this.sendAction("changed");
      },
      deleteRow: function deleteRow(amount) {
        this.get("value").removeObject(amount);
        if (this.get("value.length") === 0) {
          this.send("addGeo");
        } else {
          this.rerender();
        }
        this.sendAction("changed");
      },
      openFormulaEditor: function openFormulaEditor(component) {
        this.sendAction("openFormulaEditor", component);
      }
    }
  });
});