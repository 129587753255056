define("budgeta/components/steps-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    stepsBarWidthStyle: Ember.computed("steps.[]", function () {
      var width = 21 * this.get("steps.length") + 6 * (this.get("steps.length") - 1);
      return Ember.String.htmlSafe("width:".concat(width, "px;"));
    })
  });
});