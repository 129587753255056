define("budgeta/components/attribute-budget-select", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray", "budgeta/services/ember-service-container", "budgeta/utils/budget-utils"], function (_exports, _toConsumableArray2, _slicedToArray2, _emberServiceContainer, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    classNames: ["budgeta-type-value"],
    classNameBindings: ["isOverlappingBudgetLines:budgeta-multi-select-alert"],
    attributeBindings: ["data-key", "data-hide", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    prompt: Ember.computed("fieldData.emptyLabel", function () {
      return this.get("fieldData.emptyLabel") || intl.t("general.none_1698234255092");
    }),
    selectedLine: Ember.computed("value", function () {
      var result = null;
      if (typeof this.get("value") === "string") {
        result = _budgetUtils.default.findLineByUniqueId(this.get("store"), this.get("cache"), this.get("value"), undefined, this.get("budgetService.scenario"));
        if (!result) {
          this.set("value", null);
        } else if (result.get("name").startsWith("ENCRYPTED_")) {
          return null;
        }
      }
      return result;
    }),
    isOverlappingBudgetLines: Ember.computed("value", function () {
      var _this = this;
      if (this.get("args.multiple") && this.get("value") && this.get("value").length > 1 && Ember.get(this.get("budget"), "budgetType") === "assumption") {
        try {
          var ancestors = this.get("value").map(function (uniqueId) {
            var budgetsResult = _budgetUtils.default.findLineByUniqueId(_this.get("store"), _this.get("cache"), uniqueId, undefined, _this.get("budgetService.scenario"));
            return budgetsResult ? _budgetUtils.default.getParentsList(budgetsResult).map(function (budget) {
              return budget.id;
            }) : [];
          });

          // Seperates selected ids and ancestors ids
          var _ancestors$reduce = ancestors.reduce(function (_ref, itemsArray) {
              var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
                parentList = _ref2[0],
                budgets = _ref2[1];
              var child = itemsArray.slice(-1);
              var parent = itemsArray.slice(0, -1);
              parentList = (0, _toConsumableArray2.default)(new Set(parentList.concat(parent)));
              budgets = budgets.concat(child);
              return [parentList, budgets];
            }, [[], []]),
            _ancestors$reduce2 = (0, _slicedToArray2.default)(_ancestors$reduce, 2),
            parents = _ancestors$reduce2[0],
            selectedBudgetLines = _ancestors$reduce2[1];

          // Get length of united and united distinct
          var numberOfSelectedAndAncestors = parents.length + selectedBudgetLines.length;
          var distinctNumberOfSelectedAndAncestors = new Set([].concat((0, _toConsumableArray2.default)(parents), (0, _toConsumableArray2.default)(selectedBudgetLines))).size;
          return numberOfSelectedAndAncestors !== distinctNumberOfSelectedAndAncestors;
        } catch (error) {
          return false;
        }
      }
      return false;
    }),
    actions: {
      gotoModel: function gotoModel(model) {
        this.sendAction("gotoModel", model);
      }
    }
  });
});