define("budgeta/components/multi-select-dropdown", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNameBindings: [":multi-select-dropdown", "isOpen:active", "disabled:disabled"],
    attributeBindings: ["hook:data-hook"],
    hook: "multi-select-dropdown",
    searchValue: "",
    isOpen: false,
    columnsLayout: null,
    newValueText: Ember.computed("intl", function () {
      return this.get("intl").t("multi-select-dropdown.create-new-value");
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set("columnsLayout", [100]);
    },
    isEmptySelection: Ember.computed("selection.[]", function () {
      return this.get("selection.length");
    }),
    click: function click() {
      if (!this.get("clicked") && this.get("enabledDropdown")) {
        this.set("clicked", true);
      }
    },
    contentComputed: Ember.computed("content", {
      get: function get() {
        return this.get("content");
      },
      set: function set(key, value) {
        return value;
      }
    }),
    enabledDropdown: Ember.computed("enabled", "readonly", function () {
      return this.get("enabled") || typeof this.get("readonly") !== "undefined" && !this.get("readonly");
    }),
    allOrSomeSelection: Ember.computed("allOrSomeDim", "selection.[]", "contentComputed.[]", function () {
      if (this.get("allOrSomeDim")) {
        if (this.get("selection.length") === this.get("contentComputed.length")) {
          return intl.t("general.all__1698234255757");
        } else if (this.get("selection.length") > 1) {
          return intl.t("general.selected__1698234255757");
        }
      }
    }),
    allOrSomeTitle: Ember.computed("allOrSomeSelection", "allOrSomeDim", "selection.[]", "contentComputed.[]", function () {
      if (this.get("allOrSomeSelection") && this.get("contentComputed")) {
        return "".concat(this.get("allOrSomeSelection"), " ").concat(this.get("allOrSomeDim"), "s (").concat(this.get("selection.length"), "/").concat(this.get("contentComputed.length"), ")");
      }
      return "";
    }),
    allOrSomeTitleTip: Ember.computed("allOrSomeDim", "selection.[]", "selectionOptionsDisplay.[]", function () {
      if (this.get("allOrSomeDim")) {
        if (this.get("selection") && this.get("selection.length") > 1 && this.get("selection.length") !== this.get("selectionOptionsDisplay.length")) {
          var valuesListString = this.get("selection").join(", ");
          return valuesListString.length > 200 ? "".concat(valuesListString.substring(0, 200), "...") : valuesListString;
        }
        return intl.t("general.multi-select-dropdown-1", {
          var1: this.get("allOrSomeSelection"),
          var2: this.get("allOrSomeDim")
        });
      }
    }),
    disableSelectAll: Ember.computed("selectionOptionsDisplay.[]", function () {
      if (!this.get("selectionOptionsDisplay")) {
        return true;
      }
      var unselected = this.get("selectionOptionsDisplay").find(function (v) {
        return !Ember.get(v, "selected");
      });
      return unselected ? false : true;
    }),
    disableClear: Ember.computed("selectionOptionsDisplay.[]", function () {
      if (!this.get("selectionOptionsDisplay")) {
        return true;
      }
      var selected = this.get("selectionOptionsDisplay").find(function (v) {
        return Ember.get(v, "selected");
      });
      return selected ? false : true;
    }),
    selection: Ember.computed("value.[]", "contentComputed", "optionValuePath", "optionLabelPath", "enableAddNewValue", function () {
      var content = this.get("contentComputed") || [],
        value = this.get("value") || [],
        optionValuePath = this.get("optionValuePath"),
        optionLabelPath = this.get("optionLabelPath");
      if (this.get("singleSelection") && !Array.isArray(value)) {
        value = [value];
      }
      if (this.get("enableAddNewValue") && value[0] === "new_val") {
        return [this.get("newValueText")];
      }
      return content.filter(function (item) {
        return value.indexOf(Ember.get(item, optionValuePath)) !== -1;
      }).map(function (item) {
        return optionLabelPath ? Ember.get(item, optionLabelPath) : item;
      });
    }),
    selectionOptions: Ember.computed("contentComputed", "optionValuePath", "value.[]", "optionLabelPath", "enableAddNewValue", function () {
      var _this = this;
      var selections = Ember.A([]);
      var content = this.get("contentComputed") || [],
        value = this.get("value") || [],
        optionValuePath = this.get("optionValuePath"),
        optionLabelPath = this.get("optionLabelPath");
      content.forEach(function (item) {
        var title = Ember.get(item, "title");
        if (_this.get("showBudgetPath")) {
          title = (Ember.get(item, "budget.path") || "") + Ember.get(item, "name");
          if (_this.get("budgetService.accountsInInput") && !_this.get("disableAccountId") && Ember.get(item, "budget.accountIds")) {
            title + " (" + _this.get("accountIds").replace(/,/g, " | ") + ")";
          }
        }
        var selection = {
          id: Ember.get(item, optionValuePath) || Ember.get(item, "id"),
          name: Ember.get(item, optionLabelPath || "name"),
          budget: Ember.get(item, "budget"),
          title: Ember.get(item, "title"),
          selected: value.indexOf(Ember.get(item, optionValuePath)) !== -1
        };
        selections.pushObject(selection);
      });
      if (this.get("enableAddNewValue")) {
        selections.unshift({
          id: "new_val",
          name: this.get("newValueText"),
          type: "create-new-val"
        });
      }
      return selections;
    }),
    selectionOptionsDisplay: Ember.computed("searchValue", "selectionOptions", "showBudgetPath", function () {
      var _this2 = this;
      var selectionOptions = this.get("selectionOptions"),
        searchValue = this.get("searchValue"),
        searchRegExp = _const.default.safeRegExp(searchValue, "gi", true);
      if (searchValue) {
        selectionOptions = selectionOptions.filter(function (item) {
          if (_this2.get("showBudgetPath")) {
            return (Ember.get(item, "title") || Ember.get(item, "budget.budgetFullPath") || Ember.get(item, "budget.fullPathWithoutAccounts") || "").search(searchRegExp) !== -1;
          }
          return Ember.get(item, "name").search(searchRegExp) !== -1;
        });
      }
      return selectionOptions;
    }),
    linesHeight: Ember.computed("selectionOptions.[]", function () {
      return Math.min(this.get("selectionOptions.length") * 40, 320) || 40;
    }),
    linesStyle: Ember.computed("linesHeight", "calculatedWidth", function () {
      var style = "height:".concat(this.get("linesHeight"), "px; position:relative;");
      if (this.get("calculatedWidth")) {
        style += " min-width:".concat(this.get("calculatedWidth"), "px");
      }
      return Ember.String.htmlSafe(style);
    }),
    qtipClasses: Ember.computed("isWide", function () {
      var _class = this.get("isWide") ? "full-size" : "";
      return _class + " popup-menu";
    }),
    didRender: function didRender() {
      var _this3 = this;
      this._super.apply(this, arguments);
      if (this.get("resizeList")) {
        if (this.get("isWide") && this.$()) {
          this.set("calculatedWidth", this.$().width());
        }
        Ember.$(window).on("resize.multiselectdropdown", function () {
          Ember.run.schedule("afterRender", function () {
            if (_this3.$() && _this3.$().width() > 0) {
              _this3.set("calculatedWidth", _this3.$().width());
            }
          });
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off("resize.multiselectdropdown");
    },
    actions: {
      closeDropdown: function closeDropdown() {
        var _this4 = this;
        Ember.run.next(function () {
          var selected = _this4.get("selectionOptions").filterBy("selected");
          _this4.sendAction("onClose", selected);
        });
      },
      onOpenAction: function onOpenAction() {
        this.set("searchValue", "");
        if (this.get("multiSelectOnOpenAction")) {
          this.sendAction("multiSelectOnOpenAction", this);
        }
      },
      selectionItem: function selectionItem(item) {
        this.set("value", Ember.get(item, this.get("optionValuePath")) || Ember.get(item, "id"));
        this.sendAction("attributeChanged", item);
      },
      selectionToggle: function selectionToggle(checked, item) {
        var _this5 = this;
        Ember.run.next(function () {
          var selected = _this5.get("selectionOptions").filterBy("selected");
          _this5.set("value", selected.mapBy("id"));
          _this5.sendAction("attributeChanged", selected, !checked ? item : undefined);
        });
      },
      clear: function clear() {
        this.set("value", []);
        this.set("scrollTop", 0); // After 'Clear selection' clicked, the scroll will reset to top
        this.sendAction("attributeChanged", []);
      },
      clearDisplayOptions: function clearDisplayOptions() {
        (this.get("selectionOptionsDisplay") || Ember.A([])).setEach("selected", false);
        var selected = this.get("selectionOptions").filterBy("selected");
        this.set("value", selected.mapBy("id"));
        this.sendAction("attributeChanged", this.get("value"));
      },
      selectDisplayOptions: function selectDisplayOptions() {
        (this.get("selectionOptionsDisplay") || Ember.A([])).setEach("selected", true);
        var selected = this.get("selectionOptions").filterBy("selected");
        this.set("value", selected.mapBy("id"));
        this.sendAction("attributeChanged", this.get("value"));
      },
      scrollChange: function scrollChange(scrollLeft, scrollTop) {
        /**
         * This function is part of the 'ember-collection' api, support custom behaviour for scrolling.
         * Required for the reset scrolling after 'Clear selection' clicked
         */
        this.set("scrollLeft", scrollLeft);
        this.set("scrollTop", scrollTop);
      }
    }
  });
});