define("budgeta/components/budget-tree-element", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TemplateOptions = {
    BUDGET_GROUP: "budget-tree-group",
    BUDGET_ELEMENT: "budget-tree-simple",
    BUDGET_LOADING: "-loading",
    BUDGET_MISSING_NAME: "-missing-name",
    BUDGET_SHARE: "-share",
    BUDGET_ACCOUNT: "-account",
    BUDGET_SCENARIO: "-scenario"
  };
  var TemplateActions = {
    BUDGET_ACTIONS: "budget-tree",
    BUDGET_ACTIONS_VIEW_ONLY: "budget-tree-view-only",
    BUDGET_FLAGGED: "-flagged",
    BUDGET_NO_GROUPING: "-no-grouping",
    BUDGET_ADD: "-add",
    BUDGET_MENU: "-menu",
    BUDGET_NO_MENU: "-no-menu"
  };
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":actions-toggle", "editName:has-dropdown", "budget.hasNewChildren:has-action-links", "treeEditMode:tree-edit-mode", "budget.flagged:budget-flagged", "budget.hasShares:shared-budget", "budget.hasNoGroupingChild:no-grouping-child", "budget.inheritedReadOnly:read-only-budget", "allowChildren:allow-children", "budget.isModelGroup:model-group", "budget.hasChildren:has-children", "budget.isBudgetAddedForScenario:any-scenario-added:", "isMouseEnter:tooltip-toggle"],
    isMouseEnter: false,
    budgetService: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    intl: Ember.inject.service(),
    allowChildren: Ember.computed("budget", "budget.allowChildren", "budget.hasNewChildren", function () {
      return this.get("budget.allowChildren") || this.get("budget.hasNewChildren");
    }),
    displayCommentIcon: Ember.computed("budget.comments.[]", "viewSelections.isBudgetFlyoutOpen", function () {
      return this.get("budget.comments").any(function (item) {
        return item.get("comment");
      });
    }),
    comments: Ember.computed("budget.comments.[]", "viewSelections.isBudgetFlyoutOpen", function () {
      var _this = this;
      var allComments = this.get("budget.comments");
      var data = allComments.map(function (item) {
        return {
          fullName: "".concat(item.get("user.firstName"), " ").concat(item.get("user.lastName")),
          created: moment(item.get("created")).format(_const.default.MomentDateAndTimeDisplayFormat),
          comment: item.get("comment")
        };
      });

      // remove empty comments
      data = data.filter(function (item) {
        return item.comment;
      });
      var userComments = [];
      data.forEach(function (item) {
        userComments.push("".concat(_this.get("intl").t("index.by"), " ").concat(item.fullName, " ").concat(_this.get("intl").t("index.comment-created-on"), " ").concat(item.created, ":"));
        userComments.push(item.comment);
      });
      return userComments.join("\n");
    }),
    contentComponent: Ember.computed("budget.hasChildren", "budget.isLoading", "budget.inheritedReadOnly", "budget.inheritedIsLocked", "budget.root.budgetAttributes.showAccountId", "budget.isScenarioAdded", "displayName", "budgetService.accountsInInput", function () {
      /*
      options list
      =====================================
      content-budget-tree-group
      content-budget-tree-group-missing-name
      content-budget-tree-group-loading
      content-budget-tree-simple
      content-budget-tree-simple-missing-name
      content-budget-tree-simple-loading
       content-budget-tree-group-account
      content-budget-tree-simple-account
       content-budget-tree-group-scenario
      content-budget-tree-simple-scenario
       content-budget-tree-group-account-scenario
      content-budget-tree-simple-account-scenario
      */
      var componentName = "content-";
      var isGroup = this.get("budget.hasChildren");
      var hasName = typeof this.get("displayName") === "string" && this.get("displayName") !== "";
      var isLoading = this.get("budget.isLoading");
      if (isGroup) {
        componentName += TemplateOptions.BUDGET_GROUP;
      } else {
        componentName += TemplateOptions.BUDGET_ELEMENT;
      }
      if (!hasName) {
        if (isLoading) {
          componentName += TemplateOptions.BUDGET_LOADING;
        } else {
          componentName += TemplateOptions.BUDGET_MISSING_NAME;
        }
      }
      if (hasName) {
        componentName += TemplateOptions.BUDGET_ACCOUNT;
        if (this.get("budget.isScenarioAdded")) {
          componentName += TemplateOptions.BUDGET_SCENARIO;
        }
      }
      return componentName;
    }),
    actionsComponent: Ember.computed("budget.flagged", "budget.inheritedReadOnly", "budget.hasNewChildren", "budget.allowChildren", "budget.hasShares", "isTreeViewOnly", "showMenu", function () {
      /*
      options list
      ==========================
      actions-budget-tree
      actions-budget-tree-view-only
      actions-budget-tree-flagged
      actions-budget-tree-flagged-add-menu
      actions-budget-tree-flagged-add-no-menu
      actions-budget-tree-flagged-menu
      actions-budget-tree-flagged-no-menu
      actions-budget-tree-add-menu
      actions-budget-tree-add-no-menu
      actions-budget-tree-menu
      actions-budget-tree-no-menu
       actions-budget-tree-share
      actions-budget-tree-flagged-share
      actions-budget-tree-flagged-add-menu-share
      actions-budget-tree-flagged-add-no-menu-share
      actions-budget-tree-flagged-menu-share
      actions-budget-tree-flagged-no-menu-share
      actions-budget-tree-add-menu-share
      actions-budget-tree-add-no-menu-share
      actions-budget-tree-menu-share
      actions-budget-tree-no-menu-share
      */

      var componentName = "actions-";
      var isViewOnly = this.get("isTreeViewOnly");
      if (isViewOnly) {
        componentName += TemplateActions.BUDGET_ACTIONS_VIEW_ONLY;
        return;
      }
      componentName += TemplateActions.BUDGET_ACTIONS;
      if (this.get("budget.flagged")) {
        componentName += TemplateActions.BUDGET_FLAGGED;
      }
      if (this.get("budget.hasNoGroupingChild")) {
        componentName += TemplateActions.BUDGET_NO_GROUPING;
      }
      if (!this.get("budget.inheritedReadOnly")) {
        if (!this.get("budget.inheritedReadOnlyOrLock")) {
          if (!this.get("budget.hasNewChildren") && this.get("budget.allowChildren")) {
            componentName += TemplateActions.BUDGET_ADD;
          }
        }
        if (this.get("showMenu")) {
          componentName += TemplateActions.BUDGET_MENU;
        } else {
          componentName += TemplateActions.BUDGET_NO_MENU;
        }
      }
      var hasShares = !this.get("budget.inheritedReadOnly") && this.get("budget.hasShares");
      if (hasShares) {
        componentName += TemplateOptions.BUDGET_SHARE;
      }
      return componentName;
    }),
    showMenu: false,
    mouseEnter: function mouseEnter() {
      this.set("showMenu", true);
    },
    click: function click() {
      this.send("goto", this.get("budget"));
    },
    doubleClick: function doubleClick() {
      if (this.get("viewSelections.isGridView")) {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.GRID.FLYOUT.OPEN"), {
          isTransform: true,
          url: "/grid/open-flyout"
        });
        this.set("budgetService.inTransition", true);
        this.set("viewSelections.isBudgetFlyoutOpen", true);
      }
    },
    emptyComponent: Ember.computed("budget.isEmptyAmounts", "budget.isNewLine", "budget.isBudgetAddedForScenario", function () {
      if (this.get("budget.isEmptyAmounts") && !this.get("budget.isBudgetAddedForScenario")) {
        return "content-budget-tree-no-data";
      }
      if (this.get("budget.isNewLine")) {
        return "content-budget-tree-new-line";
      }
      return undefined;
    }),
    actions: {
      onCommentHover: function onCommentHover(event) {
        var count = this.get("iconEvent") || 0; // observe one event, for `pendo`
        this.set("iconEvent", ++count);
        var isIconHover = event.currentTarget.classList.contains("reference");
        if (this.get("viewSelections.isGridView") && isIconHover && count === 1) {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.GRID.HOVER_ON_COMMENT"));
        }
        this.set("isMouseEnter", true);
      },
      onCommentLeave: function onCommentLeave() {
        // the event `onMouseEnter` called twice and therefore the protection
        var count = this.get("iconEvent") || 0;
        this.set("iconEvent", --count);
        this.set("isMouseEnter", false);
      },
      goto: function goto(budget) {
        if (budget.get("budgetType")) {
          var dest;
          if (this.get("currentPath") === "main.budget.trx") {
            dest = "main.budget.trx";
          } else if (this.get("rootOnly")) {
            dest = "main.budget.forecast";
          }
          this.sendAction("goto", budget, dest, this.get("rootOnly") && this.get("version"));
        }
        return true;
      },
      duplicate: function duplicate(budget, formatOnly) {
        this.sendAction("duplicate", budget, formatOnly);
      },
      delete: function _delete(budget) {
        this.sendAction("delete", budget);
      },
      addChildren: function addChildren(budget) {
        this.sendAction("addChildren", budget);
        return false;
      },
      showMore: function showMore() {
        this.sendAction("showMore");
      },
      showLess: function showLess() {
        this.sendAction("showLess");
      },
      copy: function copy(budget, dest) {
        this.sendAction("copy", budget, dest);
      },
      move: function move(budget, dest, position) {
        this.sendAction("move", budget, dest, position);
      },
      flag: function flag(budget) {
        this.sendAction("flag", budget);
      },
      mergeScenario: function mergeScenario(options) {
        this.sendAction("mergeScenario", options);
      },
      share: function share(budget) {
        this.sendAction("share", budget);
      },
      rename: function rename(budget) {
        this.sendAction("rename", budget);
      },
      importWizard: function importWizard(budget) {
        this.sendAction("importWizard", budget || this.get("budget"));
      },
      downloadTemplate: function downloadTemplate(budget) {
        this.sendAction("downloadTemplate", budget);
      },
      saveToModels: function saveToModels(budget) {
        this.sendAction("saveToModels", budget);
      },
      showLineDependencies: function showLineDependencies(budget) {
        this.sendAction("showLineDependencies", budget);
      },
      collapse: function collapse(inSearchMode) {
        return inSearchMode;
      }
    }
  });
});