define("budgeta/components/employees-table-status-cell", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    statusOptions: [{
      text: intl.t("general.text_1695611608965"),
      id: 0
    }, {
      text: intl.t("general.text_1695611608955"),
      id: 1
    }],
    isEditable: Ember.computed("line.budgetType", "status", {
      get: function get() {
        return this.get("line.budgetType") === "employee-multi" && this.get("status") !== 2;
      }
    }),
    status: Ember.computed("line.budgetType", "budgetAttributes.amountType", {
      get: function get() {
        return this.get("line.employeeStatus");
      },
      set: function set(key, value) {
        if (this.get("line.budgetType") === "employee-multi") {
          return this.set("budgetAttributes.amountType", value);
        }
      }
    }),
    statusText: Ember.computed("status", {
      get: function get() {
        if (this.get("status") === 0) {
          return intl.t("general.text_1695611608965");
        } else {
          return intl.t("general.text_1695611608955");
        }
      }
    }),
    actions: {
      attributeChanged: function attributeChanged() {
        this.sendAction("attributeChanged");
      }
    }
  });
});