define("budgeta/components/dashboard-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["modal-container", "dashboard-modal"],
    adjustScrollTop: function adjustScrollTop() {
      Ember.run.scheduleOnce("afterRender", this, function () {
        Ember.$(".choose-charts").scrollTop(this.get("controllertmp.scrollPosition"));
      });
    },
    scrollHeightObserver: Ember.observer("controllertmp.selectedWidgets.[]", "controllertmp.selectedWidgets", function () {
      this.adjustScrollTop();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.adjustScrollTop();
    }
  });
});