define("budgeta/components/tour-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var reposition = function reposition(target, popup, offsetPosition) {
    var overlay = Ember.$(".tour-overlay");
    var offset = target.offset();
    if (offsetPosition) {
      offset.left += offsetPosition.x || 0;
      offset.top += offsetPosition.y || 0;
    }
    var targetHeight = target.outerHeight();
    var targetWidth = target.outerWidth();
    var offsetTop = Math.round(offset.top + targetHeight / 2);
    var offsetLeft = Math.round(offset.left + targetWidth / 2);
    overlay.find(".overlay-top").css("height", offsetTop);
    overlay.find(".overlay-bottom").css("height", "calc(100% - ".concat(offsetTop, "px)"));
    overlay.find(".overlay-left").css("width", offsetLeft);
    overlay.find(".overlay-right").css("width", "calc(100% - ".concat(offsetLeft, "px)"));

    // check overflow to the right or left
    var popupWidth = popup.outerWidth();
    var windowWidth = Ember.$(window).width();
    var offsetRight = 0;
    if (offsetLeft + popupWidth / 2 > windowWidth) {
      offsetRight = offsetLeft + popupWidth - windowWidth + 20;
      popup.addClass("right-align");
    } else if (offsetLeft - popupWidth / 2 < 0) {
      offsetLeft = 30;
      offsetRight = 10;
      popup.addClass("left-align");
    } else {
      popup.removeClass("right-align left-align");
    }
    popup.css({
      position: "absolute",
      top: offset.top + targetHeight + 40,
      left: offsetLeft - (offsetRight ? offsetRight : popupWidth / 2)
    });
  };
  var _default = _exports.default = Ember.Component.extend({
    trackingEvents: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateCurrentPage();
    },
    updateCurrentPage: function updateCurrentPage() {
      Ember.run.schedule("afterRender", this, function () {
        var pageEl = Ember.$(document.getElementById(this.get("controllertmp.currentPage"))),
          target = Ember.$(this.get("controllertmp.currentPageTarget"));
        if (pageEl.is(".popup") && target.length) {
          reposition(target, pageEl, this.get("controllertmp.currentPageData.offset"));
        }
      });
    },
    currentPageObserver: Ember.observer("controllertmp.currentPage", function () {
      this.updateCurrentPage();
    })
  });
});