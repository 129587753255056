define("budgeta/templates/components/versions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Fw3UVDYp",
    "block": "{\"statements\":[[\"block\",[\"modal-dialog\"],null,[[\"close\",\"confirm\",\"next\",\"back\",\"openVideoModal\",\"confirmActionName\",\"help\",\"title\",\"templateName\",\"hideCancel\",\"showBack\",\"lastStep\",\"youtubeId\",\"confirmDisabled\"],[[\"get\",[\"close\"]],[\"get\",[\"confirm\"]],[\"get\",[\"next\"]],[\"get\",[\"back\"]],[\"get\",[\"openVideoModal\"]],[\"get\",[\"confirmActionName\"]],[\"get\",[\"help\"]],[\"get\",[\"title\"]],[\"get\",[\"templateName\"]],[\"get\",[\"hideCancel\"]],[\"get\",[\"showBack\"]],[\"get\",[\"lastStep\"]],[\"get\",[\"youtubeId\"]],[\"get\",[\"confirmDisabled\"]]]],0]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/versions-modal.hbs"
    }
  });
});