define("budgeta/utils/collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isString = _exports.isNullOrUndefined = _exports.isEmptyString = void 0;
  var isString = _exports.isString = function isString(str) {
    return "string" === typeof str;
  };

  // returns true if value is null or undefined. (since null == undefined is true, comparing to null will catch them both)
  var isNullOrUndefined = _exports.isNullOrUndefined = function isNullOrUndefined(value) {
    return value == null;
  };

  // returns true if a string has no value or contains only white spaces
  var isEmptyString = _exports.isEmptyString = function isEmptyString(str) {
    var checkWhiteSpace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return isNullOrUndefined(str) || checkWhiteSpace && isString(str) && !str.trim();
  };
});