define("budgeta/components/intacct-budget-structure-edit", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "budgeta/services/ember-service-container", "budgeta/utils/const", "@sibp/ui-components"], function (_exports, _toConsumableArray2, _emberServiceContainer, _const, _uiComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/no-side-effects */

  // eslint-disable-next-line no-unused-vars

  var lineHasPayroll = function lineHasPayroll(line) {
    if (Ember.get(line, "budgetType") === "group-payroll") {
      return true;
    }
    if (Ember.get(line, "children.length") && Ember.get(line, "children").find(lineHasPayroll)) {
      return true;
    }
    return false;
  };
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":intacct-budget-structure-edit", "hasActualsData:has-actuals-data:no-actuals-data", "showInvalidBalancesMessage:invalid-balances-shown"],
    columnsLayout: [100],
    budgetService: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    intl: Ember.inject.service(),
    isInBudgetLinesTab: Ember.computed("data.budgetStructure", function () {
      return this.get("data.budgetStructure.length");
    }),
    isInImportStatisticalWizard: Ember.computed.alias("data.isInImportStatisticalWizard"),
    popupMessageVisible: false,
    tooltipContent: Ember.computed(function () {
      return React.createElement("div", {
        className: "tooltip-icon ".concat(this.get("popupMessageVisible") && "popup-open")
      }, React.createElement(_uiComponents.default.COMPONENTS.Tooltip, null));
    }),
    popupContent: Ember.computed("intl", "hasExceededBudgetLines", function () {
      return this.get("hasExceededBudgetLines") ? this.getExceededLinesPopupContainer() : this.getUnImportedLinesPopupContainer();
    }),
    importStatisticalSelection: Ember.computed("data.importStatisticalSelection", function () {
      return this.get("data.importStatisticalSelection");
    }),
    currency: Ember.computed("isInBudgetLinesTab", "budgetService.selectedRoot.budgetAttributes", function () {
      return this.get("isInBudgetLinesTab") ? this.get("budgetService.selectedRoot.budgetAttributes.currency") : "";
    }),
    removeAllEmptyBudgetLines: true,
    removeAllEmptyStatLines: true,
    budgetLinesTab: Ember.computed("data.budgetLinesTab", function () {
      return this.get("data.budgetLinesTab");
    }),
    numberOfEmptyLines: Ember.computed("budgetStructureFlat.length", "data.reCalcLines", function () {
      if (!this.get("data.reCalcLines")) {
        return 0;
      }
      return this.get("budgetStructureFlat").filter(function (line) {
        return !Ember.get(line, "totalActuals") && !Ember.get(line, "isBorder") && !Ember.get(line, "isEmptyRow");
      }).length;
    }),
    showInvalidBalancesMessage: Ember.computed("data.invalidBalances.size", function () {
      return this.get("data.invalidBalances") && this.get("data.invalidBalances.size") > 0;
    }),
    showLinesWarning: Ember.computed("data.invalidBalances.size", "numberOfLines", function () {
      return this.get("hasExceededBudgetLinesWarning") || this.get("showInvalidBalancesMessage");
    }),
    hasActualsData: Ember.computed("data.havePastData", "data.refresh", function () {
      var hasActualsData = false;
      if (!this.get("data.refresh")) {
        return false;
      } else if (this.get("data.havePastData")) {
        hasActualsData = true;
      }
      return hasActualsData;
    }),
    hasExceededBudgetLinesWarning: Ember.computed("numberOfLines", function () {
      return this.get("numberOfLines") > 5000;
    }),
    hasExceededBudgetLines: Ember.computed("numberOfLines", function () {
      var _this = this;
      var result = this.get("numberOfLines") > 5000;
      Ember.run.next(function () {
        _this.set("data.hasExceededBudgetLines", result);
      });
      return result;
    }),
    isLoadingActuals: Ember.computed("data.refresh", "budgetStructureFlat.length", "includeEmployees", "employees.length", function () {
      return !this.get("data.refresh");
    }),
    numberOfLines: Ember.computed("budgetLinesStructure.length", "statisticalStructure.length", "data.refresh", "data.reCalcLines", "isInImportStatisticalWizard", function () {
      var rootLineCount = this.get("isInImportStatisticalWizard") ? 0 : 1;
      var linesTotal = this.get("data.reCalcLines") ? [].concat((0, _toConsumableArray2.default)(this.get("budgetLinesStructure")), (0, _toConsumableArray2.default)(this.get("statisticalStructure"))).filter(function (b) {
        return !Ember.get(b, "deleted") && !Ember.get(b, "isBorder") && !Ember.get(b, "isEmptyRow");
      }).length + (this.get("includeEmployees") ? this.get("employees.length") || 0 : 0) + rootLineCount : 0;
      if (linesTotal === 0) {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CREATE_BUDGET_FROM_INTACCT.CREATE_WIZARD_SOMETHING_WENT_WRONG"));
      }
      return linesTotal;
    }),
    helpLink: _const.default.HELP_URL + "/intacct-planning/SIP_Topics/Import_data_to_parent.htm",
    statisticalStructure: Ember.computed("data.statisticalAccountsStructure", "data.refresh", function () {
      return this.buildStructure(this.get("data.statisticalAccountsStructure"));
    }),
    budgetLinesStructure: Ember.computed("data.budgetStructure", "data.structure.firstLevel", "data.refresh", function () {
      return this.buildStructure(this.get("data.budgetStructure"));
    }),
    budgetStructureFlat: Ember.computed("isInBudgetLinesTab", "data.budgetStructure", "data.statisticalAccountsStructure", "data.structure.firstLevel", "data.refresh", function () {
      if (this.get("isInBudgetLinesTab")) {
        return this.get("budgetLinesStructure");
      }
      return this.get("statisticalStructure");
    }),
    budgetStructureVisibale: Ember.computed("toggleCollapse", "isInBudgetLinesTab", function () {
      return (this.get("budgetStructureFlat") || Ember.A([])).filterBy("collapse", false).filterBy("isEmptyRow", undefined);
    }),
    setChildrenDeletedRecursive: function setChildrenDeletedRecursive(line, deleted) {
      var _this2 = this;
      this.set("toggleCollapse", false);
      if (Ember.get(line, "children")) {
        Ember.get(line, "children").forEach(function (child) {
          Ember.set(child, "deleted", deleted);
          _this2.setChildrenDeletedRecursive(child, deleted);
        });
      }
    },
    setParentDeletedRecursive: function setParentDeletedRecursive(line, deleted) {
      if (Ember.get(line, "parent")) {
        Ember.set(Ember.get(line, "parent"), "deleted", deleted);
        this.setParentDeletedRecursive(Ember.get(line, "parent"), deleted);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.set("data.hasExceededBudgetLines", false);
      if (!this.get("isInImportStatisticalWizard")) {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CREATE_BUDGET_FROM_INTACCT.CREATE_WIZARD_REMOVE_LINE_NO_PAST_BUDGET"), {
          nameParameters: [this.get("removeAllEmptyBudgetLines") ? "Enabled" : "Disabled"]
        });
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CREATE_BUDGET_FROM_INTACCT.CREATE_WIZARD_REMOVE_LINE_NO_PAST_STAT"), {
          nameParameters: [this.get("removeAllEmptyStatLines") ? "Enabled" : "Disabled"]
        });
      }
    },
    getReport: function getReport() {
      var columns = this.formatColumnsData();
      var data = this.formatData(columns);
      return {
        name: intl.t("general.name_1695611601289"),
        sheets: [{
          name: intl.t("general.name_1695611601279"),
          inactiveBalances: [{
            columns: columns,
            data: data
          }]
        }]
      };
    },
    formatColumnsData: function formatColumnsData() {
      var _this3 = this;
      var result = [{
        key: "source",
        label: intl.t("general.label_1695611611828")
      }, {
        key: "glaccountno",
        label: intl.t("general.label_1695611611817")
      }, {
        key: "endDate",
        label: intl.t("general.label_1695611611807")
      }, {
        key: "endbalance",
        label: intl.t("general.name_1695611604274")
      }];
      var dimensionData = this.get("data.dimensionsForInvalidBalancesReport");
      Object.keys(dimensionData).forEach(function (dim) {
        var label = dim.charAt(0).toUpperCase() + dim.slice(1);
        if (dimensionData[dim].isCustomDim) {
          var balanceKey = _this3.get("data.balanceUDDFieldsMap")[dim.toLowerCase()];
          var budgetKey = _this3.get("data.budgetUDDFieldsMap")[dim.toUpperCase()];
          result.push({
            key: "".concat(dim),
            label: "".concat(label),
            isCustomDim: true,
            balanceKey: balanceKey,
            budgetKey: budgetKey
          });
        } else {
          result.push({
            key: "".concat(dim.toLowerCase(), "id"),
            label: "".concat(label)
          });
        }
      });
      return result;
    },
    formatData: function formatData(columns) {
      return (0, _toConsumableArray2.default)(this.get("data.invalidBalances")).map(function (b) {
        var balance = JSON.parse(b);
        var obj = {};
        if (balance.source === "Actual") {
          columns.forEach(function (col) {
            if (col.isCustomDim) {
              obj[col.key] = balance[col.balanceKey];
            } else {
              obj[col.key] = balance[col.key];
            }
          });
          obj["currency"] = balance["currency"];
        } else {
          columns.forEach(function (col) {
            if (col.key === "endbalance") {
              obj[col.key] = balance["AMOUNT"];
            } else if (col.key === "endDate") {
              obj[col.key] = moment(balance["PENDDATE"]).format(_const.default.MomentMonthYearDateFormat);
            } else if (col.isCustomDim) {
              obj[col.key] = balance[col.budgetKey];
            } else {
              obj[col.key] = balance[col.key.toUpperCase()];
            }
            obj["currency"] = balance["CURRENCY"] || "USD";
          });
        }
        return obj;
      }).sort(function (a, b) {
        for (var i = 0; i < columns.length; i++) {
          if (a[columns[i].key] > b[columns[i].key]) {
            return 1;
          } else if (a[columns[i].key] < b[columns[i].key]) {
            return -1;
          }
        }
        return 0;
      });
    },
    getUnImportedLinesPopupContainer: function getUnImportedLinesPopupContainer() {
      var _this4 = this;
      return React.createElement("div", {
        className: "message"
      }, this.get("intl").t("intacct-budget-structure-edit.invalid-message.invalid-balances-desc"), React.createElement("span", {
        className: "link",
        onClick: function onClick() {
          return _this4.send("exportExcel");
        }
      }, this.get("intl").t("intacct-budget-structure-edit.invalid-message.download")), this.get("intl").t("intacct-budget-structure-edit.invalid-message.or"), React.createElement("a", {
        className: "link no-underline",
        href: this.get("helpLink"),
        target: "_blank"
      }, this.get("intl").t("intacct-budget-structure-edit.invalid-message.article")));
    },
    getExceededLinesPopupContainer: function getExceededLinesPopupContainer() {
      return React.createElement("div", {
        className: "message"
      }, this.get("intl").t("intacct-budget-structure-edit.lines-amount-exceeded-message"));
    },
    buildStructure: function buildStructure(structure) {
      var lines = [];
      var processChildren = function processChildren(children) {
        var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        if (children && children.length) {
          children.forEach(function (child) {
            if (Ember.get(child, "children.length")) {
              Ember.set(child, "expand", true);
              Ember.set(child, "displayExpandCollapse", true);
            }
            Ember.set(child, "level", level);
            lines.push(child);
            processChildren(Ember.get(child, "children"), level + 1);
          });
        }
      };
      processChildren(structure);
      var firstLevel = lines.filterBy("level", 0);
      for (var i = 0; i < firstLevel.length; i++) {
        var lineIndex = lines.indexOf(firstLevel[i]);
        lines.insertAt(lineIndex + 1, Ember.Object.create({
          isBorder: true,
          collapse: false,
          expand: true
        }));
      }
      if (this.get("data.structure.firstLevel")) {
        var secondLevel = lines.filterBy("level", 1);
        for (var _i = 0; _i < secondLevel.length - 1; _i++) {
          // Ember.set(secondLevel[i], "disableDelete", true);
          if (secondLevel[_i].budgetType !== "group-revenue") {
            var _lineIndex = lines.indexOf(secondLevel[_i]);
            lines.insertAt(_lineIndex, Ember.Object.create({
              isEmptyRow: true,
              collapse: !secondLevel[0].parent.expand
            }));
          }
        }
      }
      return lines;
    },
    actions: {
      toggleLine: function toggleLine(line) {
        if (Ember.get(line, "isBorder") || Ember.get(line, "isEmptyRow") || Ember.get(line, "disableDelete")) {
          return;
        }
        var deleted = !Ember.get(line, "deleted");
        Ember.set(line, "deleted", deleted);
        if (deleted) {
          this.setChildrenDeletedRecursive(line, deleted);
        } else {
          this.setParentDeletedRecursive(line, deleted);
          this.setChildrenDeletedRecursive(line, deleted);
        }
        this.notifyPropertyChange("data.refresh");
      },
      checkToggle: function checkToggle(toggle) {
        var _this5 = this;
        Ember.run.next(function () {
          var removeAllEmptyLines = !toggle;
          var linesStructure = _this5.get("isInBudgetLinesTab") ? _this5.get("budgetLinesStructure") : _this5.get("statisticalStructure");
          if (removeAllEmptyLines) {
            linesStructure.filter(function (line) {
              return Ember.get(line, "level") !== 0 && !Ember.get(line, "deleted") && Ember.get(line, "totalActuals") === undefined && !lineHasPayroll(line);
            }).forEach(function (line) {
              return Ember.set(line, "deleted", true);
            });
          } else {
            linesStructure.filter(function (line) {
              return Ember.get(line, "level") !== 0 && Ember.get(line, "deleted") && Ember.get(line, "totalActuals") === undefined;
            }).forEach(function (line) {
              Ember.set(line, "deleted", false);
              _this5.setParentDeletedRecursive(line, false);
            });
          }
          _this5.notifyPropertyChange("data.refresh");
          if (!_this5.get("isInImportStatisticalWizard")) {
            _this5.get("trackingEvents").trackEvent(_this5.get("trackingEvents.events.CREATE_BUDGET_FROM_INTACCT.CREATE_WIZARD_REMOVE_LINE_TOGGLE"), {
              nameParameters: [_this5.get("isInBudgetLinesTab") ? "budget" : "statistical"]
            });
          }
        });
      },
      collapse: function collapse(item) {
        Ember.set(item, "expand", !Ember.get(item, "expand"));
        var budgetStructureFlat = this.get("budgetStructureFlat");
        var curLevel = Ember.get(item, "level");
        var startPos = budgetStructureFlat.indexOf(item) + 1;
        for (var i = startPos; i < budgetStructureFlat.length; i++) {
          if (budgetStructureFlat[i].level <= curLevel) {
            break;
          }
          if (!budgetStructureFlat[i].level && !budgetStructureFlat[i].isBorder && !budgetStructureFlat[i].isEmptyRow) {
            this.set("toggleCollapse", !this.get("toggleCollapse"));
            return;
          }
          if (!budgetStructureFlat[i].isBorder) {
            Ember.set(budgetStructureFlat[i], "collapse", !Ember.get(item, "expand"));
            Ember.set(budgetStructureFlat[i], "expand", Ember.get(item, "expand"));
          }
        }
        this.set("toggleCollapse", !this.get("toggleCollapse"));
      },
      exportExcel: function exportExcel() {
        var report = this.getReport();
        this.set("data.reportData", JSON.stringify(report));
        Ember.run.scheduleOnce("afterRender", function () {
          Ember.$("form.excel-data").submit();
        });
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.EXPORT_INVALID_BALANCES_EXCEL"), {});
      },
      budgetLinesTabClicked: function budgetLinesTabClicked() {
        this.set("isInBudgetLinesTab", true);
      },
      statisticalTabClicked: function statisticalTabClicked() {
        this.set("isInBudgetLinesTab", false);
      },
      togglePopupMessageVisibality: function togglePopupMessageVisibality() {
        this.set("popupMessageVisible", !this.get("popupMessageVisible"));
      }
    }
  });
});