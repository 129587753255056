define("budgeta/components/edit-budget-tree-element-master", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    treeManager: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    classNameBindings: [":actions-toggle", "editName:has-dropdown", "budget.hasNewChildren:has-action-links", "treeEditMode:tree-edit-mode", "budget.flagged:budget-flagged", "budget.allowChildren:allow-children", "budget.hasNewChildren:allow-children", "budget.isModelGroup:model-group"],
    focusOut: function focusOut() {
      this.set("changed", true);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var inputEle = this.$('input[type="text"]');
      var tmpStr = inputEle.val();
      inputEle.focus().val("").val(tmpStr).on("keyup", function (event) {
        var budget = _this.get("budget");
        var nameBefore = _this.get("budget.name");
        if (event.which === 13 || event.keyCode === 13) {
          budget.set("renameMode", false);
          _this.send("lineRenamed", budget, nameBefore);
          return;
        }
        if (event.which === 27 || event.keyCode === 27) {
          if (budget.get("isNew")) {
            _this.send("discardAll");
          } else {
            budget.set("renameMode", false);
            budget.rollbackAttributes();
          }
          return;
        }
      });
    },
    // Uncomment if you want auto move to new line created in the tree (BUD-4228)
    // focusIn: function() {
    //   if (!this.get('active')) {
    //     Ember.run.schedule('afterRender', this, function () {
    //       this.sendAction('goto', this.get('budget'), undefined, this.get('rootOnly') && this.get('version'));
    //     });
    //   }
    // },
    actions: {
      save: function save() {
        this.get("budgetService").calcUniqueBudgetName();
        if (!this.getWithDefault("noSave", false)) {
          this.get("budget").set("renameMode", false);
          return this.get("budget").save();
        }
        return false;
      },
      focused: function focused() {
        if (this.get("budget.id") && !this.get("budget.chosen")) {
          this.set("budget.focusAfterRender", true);
          this.sendAction("newLineChosen", this.get("budget"));
        }
      },
      newLineChosen: function newLineChosen(budget) {
        this.sendAction("newLineChosen", budget);
      },
      clearAddLine: function clearAddLine(budget) {
        budget.deleteRecord();
        this.get("treeManager").recalcBudgetList();
        if (budget.get("parent")) {
          this.sendAction("goto", budget.get("parent"));
        }
      },
      saveNew: function saveNew(budget) {
        if (!budget.get("isSaving")) {
          this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CLICK_ON_CONFIRM_ADD_NEW_BUDGET_LINE"));
          this.sendAction("saveNew", budget);
        }
        return false;
      },
      lineRenamed: function lineRenamed(budget, nameBefore) {
        this.sendAction("lineRenamed", budget, nameBefore);
      },
      doneEditing: function doneEditing() {
        if (this.get("budget.name")) {
          this.sendAction("saveNew", this.get("budget"));
        }
      },
      editTree: function editTree() {
        this.sendAction("editTree");
      },
      discardAll: function discardAll() {
        this.sendAction("discardAll");
      }
    }
  });
});