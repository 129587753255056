define("budgeta/admin/utils/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calcDaysAbsoluteDiff = void 0;
  var calcDaysAbsoluteDiff = _exports.calcDaysAbsoluteDiff = function calcDaysAbsoluteDiff(date1, date2) {
    try {
      return Math.abs(parseInt((date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24), 10));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
});