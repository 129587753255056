define("budgeta/store/store", ["exports", "budgeta/store/reducers", "redux-thunk", "redux"], function (_exports, _reducers, _reduxThunk, _redux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getStore = void 0;
  //eslint-disable-next-line import/default

  var _store = null;
  var getStore = _exports.getStore = function getStore(createNew) {
    if (!_store || createNew) {
      var createStore = _redux.default.createStore,
        applyMiddleware = _redux.default.applyMiddleware,
        compose = _redux.default.compose;
      var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
      var store = createStore(_reducers.default, composeEnhancers(applyMiddleware(_reduxThunk.default)));
      _store = store;
    }
    return _store;
  };
});