define("budgeta/components/actual-input", ["exports", "budgeta/components/currency-input"], function (_exports, _currencyInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _currencyInput.default.extend({
    displayCurrency: Ember.computed("currency", "value", function () {
      if (Ember.isEmpty(this.get("value"))) {
        return null;
      }
      return this.get("currency");
    }),
    prefix: "budget.actuals",
    init: function init() {
      this._super();
      Ember.bind(this, "value", this.get("prefix") + "." + this.get("key"));
    },
    keyUp: function keyUp() {
      var _this = this;
      Ember.run.next(function () {
        if (_this.$("input").val() === "") {
          _this.set(_this.get("prefix") + "." + _this.get("key"), undefined);
        }
      });
    }
  });
});