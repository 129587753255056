define("budgeta/initializers/noty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    Ember.merge(Ember.$.noty.defaults, {
      layout: "top",
      theme: "bootstrap",
      type: "information",
      dismissQueue: true,
      maxVisible: 1,
      animation: {
        open: "animated fadeInDown",
        close: "animated fadeOutUp"
      },
      timeout: 5000,
      force: true,
      killer: false,
      closeWith: ["click", "button"]
    });
  }
  var _default = _exports.default = {
    name: "noty",
    initialize: initialize
  };
});