define("budgeta/templates/components/attribute-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Vkf4vEg6",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"readOnly\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"select-2-typeahead\"],null,[[\"name\",\"cssClass\",\"allowClear\",\"query\",\"targetValue\",\"targetProperty\",\"placeholder\",\"trimValue\"],[\"key\",\"wrap\",true,\"autocompleteQuery\",[\"get\",[\"budgetAttributes\"]],[\"get\",[\"key\"]],[\"get\",[\"thisPlaceholder\"]],true]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"input\"],null,[[\"name\",\"readonly\",\"value\",\"placeholder\"],[[\"get\",[\"key\"]],\"readonly\",[\"get\",[\"value\"]],[\"get\",[\"placeholder\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/attribute-autocomplete.hbs"
    }
  });
});