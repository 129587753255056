define("budgeta/admin/store/reducers/securityReducer", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/admin/store/actions/securityActions"], function (_exports, _objectSpread2, _securityActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initialState = {
    loggedInUser: null
  };
  function rootReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case _securityActions.GET_LOGGED_IN_USER:
        {
          var loggedInUser = action.loggedInUser;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            loggedInUser: loggedInUser
          });
        }
      default:
        return state;
    }
  }
  var _default = _exports.default = rootReducer;
});