define("budgeta/components/react/componentX/componentX", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable react/react-in-jsx-scope */
  //TODO delete this file once we start working with react and have other examples for react components in ember
  var ComponentX = function ComponentX(_ref) {
    var text = _ref.text,
      _ref$onClick = _ref.onClick,
      onClick = _ref$onClick === void 0 ? function () {} : _ref$onClick;
    return React.createElement("div", null, "COMPONENT X: ", React.createElement("button", {
      onClick: onClick
    }, text));
  };
  var _default = _exports.default = ComponentX;
});