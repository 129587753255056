define("budgeta/components/formula-editor-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["formula-editor-button"],
    click: function click() {
      this.sendAction();
    }
  });
});