define("budgeta/components/html5-input", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    error: "",
    edited: false,
    onInvalid: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!this.get("inputId")) {
        this.set("inputId", "input-" + Ember.guidFor(this));
      }
      this.set("onInvalid", function () {
        if (!_this.get("edited")) {
          //validate only if it was not already edited and validated.
          _this.set("edited", true);
          _this.validate();
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var htmlInput = document.querySelector("#" + this.get("inputId"));
      htmlInput.addEventListener("invalid", this.get("onInvalid"));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.querySelector("#".concat(this.get("inputId"))).removeEventListener("invalid", this.get("onInvalid"));
    },
    validate: function validate() {
      var value = this.get("value") || "";
      var htmlInput = document.querySelector("#".concat(this.get("inputId")));
      if (this.get("required") && !value) {
        this.set("error", intl.t("general.this_field_is_required_1698234255345"));
        return false;
      }
      if (!value) {
        //value can be invalid number or empty string. In both cases value is empty.
        //checkValidity is used, because it checks invalid number like --2 (value is empty in this case), also it validates nubmer based on step attribute.
        if (this.get("type") === "number" && !htmlInput.checkValidity()) {
          this.set("error", intl.t("general.number_is_invalid_1698234255345"));
          return false;
        }
        if (!this.get("required")) {
          this.set("error", "");
          return false;
        }
      }
      if (this.get("pattern") && !value.match(this.get("pattern"))) {
        this.set("error", intl.t("general.this_field_is_invalid_1698234255345"));
        return false;
      }
      if (this.get("min") && value < this.get("min")) {
        var errorMessage = this.get("errors.min") ? this.get("errors.min") : intl.t("general.html5-input-1", {
          var1: this.get("min")
        });
        this.set("error", errorMessage);
        return false;
      }
      if (this.get("max") && value > this.get("max")) {
        this.set("error", intl.t("general.html5-input-2", {
          var1: this.get("max")
        }));
        return false;
      }
      if (this.get("minlength") && value.length < this.get("minlength")) {
        this.set("error", intl.t("general.this_field_is_too_short_1698234255345"));
        return false;
      }
      if (this.get("maxlength") && value.length > this.get("maxlength")) {
        this.set("error", intl.t("general.this_field_is_too_long_1698234255345"));
        return false;
      }
      if (this.get("type") === "email" && !value.match(/^[^@]+@[^@]+\.[^@]+$/)) {
        this.set("error", intl.t("general.this_field_is_invalid_1698234255345"));
        return false;
      }

      //checkValidity is used, because it checks invalid number like --2 (value is empty in this case), also it validates nubmer based on step attribute.
      if (this.get("type") === "number" && !htmlInput.checkValidity()) {
        this.set("error", intl.t("general.number_is_invalid_1698234255345"));
        return false;
      }
      this.set("error", "");
    },
    actions: {
      _onInput: function _onInput(e) {
        if (this.onInput) {
          this.onInput(e.target.value);
        } else {
          this.set("value", e.target.value);
        }
        if (this.get("edited")) {
          this.validate();
        }
      },
      _onFocusOut: function _onFocusOut(value) {
        this.get("onFocusOut") && this.get("onFocusOut")(value);
        this.set("edited", true);
        this.validate();
      },
      _onKeyUp: function _onKeyUp(value, e) {
        this.get("onKeyUp") && this.get("onKeyUp")(value, e);
      },
      _onKeyDown: function _onKeyDown(value, e) {
        if ((e.key === "e" || e.key === "E") && this.get("type") === "number") {
          e.preventDefault();
        }
        if (e.keyCode === 13 && this.get("insert-newline")) {
          e.preventDefault();
          e.stopPropagation();
          this.get("insert-newline")();
        }
      }
    }
  });
});