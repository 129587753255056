define("budgeta/components/month-year-picker", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/closure-actions */

  var genMonths = function genMonths(ctl, value, phValue) {
    var result = new Ember.A([]);
    ctl.get("months").forEach(function (month) {
      var thisMonth = Ember.Object.create(month);
      thisMonth.set("selected", value && value.substr(0, 2) === month.value);
      thisMonth.set("pSelected", phValue && phValue.substr(0, 2) === month.value);
      result.pushObject(thisMonth);
    });
    return result;
  };
  var genYears = function genYears(value, phValue) {
    var years = new Ember.A([]),
      thisYear = new Date().getFullYear();
    for (var year = thisYear - 10; year <= thisYear + 10; year++) {
      years.pushObject(Ember.Object.create({
        year: year,
        selected: value && "" + year === value.substr(-4),
        pSelected: phValue && "" + year === phValue.substr(-4)
      }));
    }
    return years;
  };
  var formatDate = function formatDate(ctx, property, value, isInvalidProperty) {
    if (typeof value !== "undefined") {
      if (value) {
        var m = moment(value, ctx.getWithDefault("displayFormat", _const.default.MomentMonthYearDateFormat));
        if (m.isValid()) {
          if (ctx.get("endOfMonth")) {
            m.endOf("month").startOf("day");
          }
          ctx.set(property, m.toDate());
          ctx.set(isInvalidProperty, false);
        } else {
          ctx.set(property, null);
          ctx.set(isInvalidProperty, true);
        }
      } else {
        ctx.set(property, null);
        ctx.set(isInvalidProperty, false);
      }
      return value;
    }
    var thisDate = ctx.get(property);
    if (typeof thisDate === "string") {
      thisDate = _const.default.deserializeDate(thisDate);
    }
    var d = _const.default.getMoment(thisDate);
    if (d && ctx.get("endOfMonth")) {
      d.endOf("month").startOf("day");
    }
    ctx.set(isInvalidProperty, false);
    return d ? d.format(ctx.getWithDefault("displayFormat", _const.default.MomentMonthYearDateFormat)) : null;
  };
  var scrollSelectedIntoView = function scrollSelectedIntoView(popup) {
    popup.find("ul.month, ul.year").each(function (idx, list) {
      var selected = Ember.$(list).find("li.selected, li.p-selected").first();
      if (selected.length) {
        if (Math.abs(Ember.$(list).scrollTop() - selected.position().top + 30) > 10) {
          Ember.$(list).scrollTop(0).animate({
            scrollTop: selected.position().top - 30
          }, "fast");
        }
      } else {
        popup.find("ul.year").each(function (idx, yearList) {
          var yearPos = Ember.$(yearList).find('[data-value="' + new Date().getFullYear() + '"]').position().top;
          if (Math.abs(Ember.$(yearList).scrollTop() - yearPos + 30) > 10) {
            Ember.$(yearList).animate({
              scrollTop: yearPos - 30
            }, "fast");
          }
        });
      }
    });
  };
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":month-picker-wrapper", "showTo:has-to:without-to", "showToText:show-to-text", "showFromText:show-from-text"],
    init: function init() {
      this._super();
      this.set("defaultFrom", this.get("fromPlaceholder"));
      this.set("defaultTo", this.get("toPlaceholder"));
    },
    autoToDate: false,
    isInValidFrom: false,
    isInValidTo: false,
    computedReadOnly: Ember.computed.or("readOnly", "disableTyping"),
    fromId: Ember.computed("elementId", function () {
      return "from-" + this.get("elementId");
    }),
    toId: Ember.computed("elementId", function () {
      return "to-" + this.get("elementId");
    }),
    valueFrom: Ember.computed("from", {
      get: function get() {
        return formatDate(this, "from", undefined, "isInValidFrom");
      },
      set: function set(key, value) {
        return formatDate(this, "from", value, "isInValidFrom");
      }
    }),
    valueTo: Ember.computed("to", {
      get: function get() {
        return formatDate(this, "to", undefined, "isInValidTo");
      },
      set: function set(key, value) {
        return formatDate(this, "to", value, "isInValidTo");
      }
    }),
    fromPlaceholderFormatted: Ember.computed("fromPlaceholder", "disabled", function () {
      return this.get("fromPlaceholder") ? moment(this.get("fromPlaceholder")).format(_const.default.MomentMonthYearDateFormat) : this.get("disabled") ? "" : _const.default.MomentMonthYearDateFormat;
    }),
    toPlaceholderFormatted: Ember.computed("toPlaceholder", "disabled", function () {
      return this.get("toPlaceholder") ? moment(this.get("toPlaceholder")).format(_const.default.MomentMonthYearDateFormat) : this.get("disabled") ? "" : _const.default.MomentMonthYearDateFormat;
    }),
    fromYears: Ember.computed("valueFrom", "fromPlaceholderFormatted", function () {
      return genYears(this.get("valueFrom"), this.get("fromPlaceholderFormatted"));
    }),
    toYears: Ember.computed("valueTo", "toPlaceholderFormatted", function () {
      return genYears(this.get("valueTo"), this.get("toPlaceholderFormatted"));
    }),
    fromMonths: Ember.computed("valueFrom", "fromPlaceholderFormatted", function () {
      return genMonths(this, this.get("valueFrom"), this.get("fromPlaceholderFormatted"));
    }),
    toMonths: Ember.computed("valueTo", "toPlaceholderFormatted", function () {
      return genMonths(this, this.get("valueTo"), this.get("toPlaceholderFormatted"));
    }),
    months: [{
      value: "01",
      name: intl.t("general.name_1695611601425")
    }, {
      value: "02",
      name: intl.t("general.name_1695611601414")
    }, {
      value: "03",
      name: intl.t("general.name_1695611601404")
    }, {
      value: "04",
      name: intl.t("general.name_1695611601393")
    }, {
      value: "05",
      name: intl.t("general.name_1695611601382")
    }, {
      value: "06",
      name: intl.t("general.name_1695611601371")
    }, {
      value: "07",
      name: intl.t("general.name_1695611601360")
    }, {
      value: "08",
      name: intl.t("general.name_1695611601349")
    }, {
      value: "09",
      name: intl.t("general.name_1695611601338")
    }, {
      value: "10",
      name: intl.t("general.name_1695611601327")
    }, {
      value: "11",
      name: intl.t("general.name_1695611601316")
    }, {
      value: "12",
      name: intl.t("general.name_1695611601305")
    }],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var stopFocusOut = false;
      Ember.run.scheduleOnce("afterRender", this, function () {
        var _this = this;
        if (this.$()) {
          var thisInput = this.$("input");
          this.$("input").on("focus", function () {
            Ember.$(this).data("no-hide", false);
          }).on("focusout", function (e) {
            if (stopFocusOut) {
              e.preventDefault();
              stopFocusOut = false;
              Ember.$(this).focus();
              return false;
            }
            if (_this.get("autoToDate") && Ember.$(this).closest(".month-picker").is(".from") && Ember.isEmpty(_this.get("to"))) {
              _this.set("to", moment(_this.get("from")).add(11, "months").toDate());
            }
          }) /*.each(function(idx, target) {
             var api = Ember.$(target).qtip('api');
             if (api) {
             api.set('events.visible', function(event, api) {
             scrollSelectedIntoView(api.tooltip);
             var offsetParent = api.tooltip.offsetParent();
             if (offsetParent.is('.scrollable')) {
             var overflow = api.tooltip.position().top - offsetParent.scrollTop() + api.tooltip.height() - offsetParent.height() - 45;
             if ( overflow > 0) {
             offsetParent.animate({scrollTop: offsetParent.scrollTop() + overflow});
             }
             }
             });
             }
             })*/;
          Ember.$("body").on("mousedown.".concat(_this.get("elementId")), ".month-picker-control *", function () {
            thisInput.data("no-hide", true);
            stopFocusOut = true;
            return true;
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$("body").off("mousedown.".concat(this.get("elementId")), ".month-picker-control *");
      this._super.apply(this, arguments);
    },
    actions: {
      removeNotAllowedCharacters: function removeNotAllowedCharacters(ev) {
        ev.target.value = ev.target.value ? ev.target.value.replace(/[^0-9/]/g, "") : "";
      },
      "select-from-month": function selectFromMonth(month) {
        var defaultYear = (this.get("defaultFrom") ? new Date(this.get("defaultFrom")) : new Date()).getFullYear();
        this.set("valueFrom", month + (this.get("valueFrom") && /\d{4}/.test(this.get("valueFrom").substr(2)) ? this.get("valueFrom").substr(2) : "/" + defaultYear));
        Ember.run.scheduleOnce("afterRender", this, function () {
          var popup = Ember.$("[for=" + this.get("fromId") + "]");
          scrollSelectedIntoView(popup);
        });
        if (this.$()) {
          this.$().trigger("change");
        }
      },
      "select-from-year": function selectFromYear(year) {
        this.set("valueFrom", (this.get("valueFrom") && /\d{2}\//.test(this.get("valueFrom").substr(0, 3)) ? this.get("valueFrom").substr(0, 3) : "01/") + year);
        Ember.run.scheduleOnce("afterRender", this, function () {
          var popup = Ember.$("[for=" + this.get("fromId") + "]");
          scrollSelectedIntoView(popup);
        });
        if (this.$()) {
          this.$().trigger("change");
        }
      },
      "select-to-month": function selectToMonth(month) {
        var defaultYear = (this.get("defaultTo") ? new Date(this.get("defaultTo")) : new Date()).getFullYear();
        this.set("valueTo", month + (this.get("valueTo") && /\d{4}/.test(this.get("valueTo").substr(2)) ? this.get("valueTo").substr(2) : "/" + defaultYear));
        Ember.run.scheduleOnce("afterRender", this, function () {
          var popup = Ember.$("[for=" + this.get("toId") + "]");
          scrollSelectedIntoView(popup);
        });
        if (this.$()) {
          this.$().trigger("change");
        }
      },
      "select-to-year": function selectToYear(year) {
        this.set("valueTo", (this.get("valueTo") && /\d{2}\//.test(this.get("valueTo").substr(0, 3)) ? this.get("valueTo").substr(0, 3) : "01/") + year);
        Ember.run.scheduleOnce("afterRender", this, function () {
          var popup = Ember.$("[for=" + this.get("toId") + "]");
          scrollSelectedIntoView(popup);
        });
        if (this.$()) {
          this.$().trigger("change");
        }
      },
      changed: function changed() {
        if (this.$()) {
          this.$().trigger("change");
        }
        this.sendAction("changed");
      },
      setDatePickerOpenedByStartDate: function setDatePickerOpenedByStartDate(showTo) {
        this.set("isDatePickerOpenedWithStartDate", showTo ? true : false);
      },
      close: function close() {
        var elements = this.$("input");
        elements.each(function (i, ele) {
          var api = Ember.$(ele).qtip("api");
          if (api) {
            api.hide();
          }
        });
        this.sendAction("changed");
      }
    }
  });
});