define("budgeta/components/integration-wizard-new-accounts", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["table-editor", "export-missing-mapping", "integration-wizard-account-mapping-table"],
    budgetService: Ember.inject.service(),
    intl: Ember.inject.service(),
    mapOptions: _const.default.DEFAULT_OPENING_BALANCES.map(function (line) {
      return {
        id: line.name,
        text: line.displayName
      };
    }),
    budgetLines: Ember.computed("budgetService.budgetLinesAccounts", "budgetService.statisticalBudgetLinesAccounts", "currentStepId", function () {
      if (this.get("currentStepId") === "integration-wizard-new-stat-accounts") {
        return this.get("budgetService.statisticalBudgetLinesAccounts");
      }
      return this.get("budgetService.budgetLinesAccounts");
    }),
    bsLines: _const.default.DEFAULT_OPENING_BALANCES_ID_NAME,
    statementOptions: Ember.computed("budgetService.pnlFullName", function () {
      var pnlFullName = this.get("budgetService.pnlFullName");
      return [{
        id: "pnl",
        text: pnlFullName
      }, {
        id: "bs",
        text: intl.t("general.name_1695611604193")
      }];
    }),
    showDimensions: Ember.computed("newAccounts.@each.mappingType", function () {
      return this.get("newAccounts").findBy("mappingType", "multi") ? true : false;
    }),
    toggleAll: false,
    isNewStatisticalMapping: Ember.computed.equal("currentStepId", "integration-wizard-select-date-range"),
    subtitle: Ember.computed("intl", function () {
      return this.get("isNewStatisticalMapping") ? this.get("intl").t("integration-wizard-new-accounts.subtitle-stat") : this.get("intl").t("integration-wizard-new-accounts.subtitle");
    }),
    toggleAllObserver: Ember.observer("toggleAll", function () {
      this.get("newAccounts").setEach("checked", this.get("toggleAll"));
    }),
    change: function change(e) {
      var el = Ember.$(e.target);
      var lineEl = el.closest(".table-edit-line");
      if (!el.is('[type="checkbox"]') && lineEl && lineEl.length && e.val) {
        this.get("newAccounts").filterBy("checked").setEach("mapping", e.val);
        this.get("newAccounts").setEach("checked", false);
      }
    },
    click: function click(e) {
      var target = Ember.$(e.target);
      var wrapper = target.closest(".budgeta-checkbox");
      if (e.shiftKey && wrapper.length && !wrapper.find('input[type="checkbox"]').is(":checked")) {
        // find the last selected line and select all the lines in between
        wrapper.closest(".ember-list-view.ember-list-view-list > div > div > div").prevUntil(":has(:checked)").each(function (idx, line) {
          Ember.$(line).find(".budgeta-checkbox label").click();
        });
      }
    }
  });
});