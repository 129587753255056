define("budgeta/templates/components/select-dimension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eZI7c0gI",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"close-element\"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"enabled\",\"value\",\"placeholder\",\"optionValuePath\",\"content\"],[[\"get\",[\"enabled\"]],[\"get\",[\"selectedValue\"]],[\"get\",[\"placeholder\"]],\"id\",[\"get\",[\"content\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/select-dimension.hbs"
    }
  });
});