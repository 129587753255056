define("budgeta/components/editable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var runLater;
  var Item = Ember.Object.extend({
    value: "",
    isUnallowed: undefined
  });
  var setValue = function setValue() {
    var _this2 = this;
    if (this.$()) {
      var focusedIdx = this.$("input:focus").closest(".data-row").prevAll().length;
      this.set("value", this.get("items").mapBy("value").join(","));
      Ember.run.scheduleOnce("afterRender", function () {
        var inputEle = _this2.$("input").eq(focusedIdx);
        if (_this2.get("lastFocusElement")) {
          if (_this2.get("lastFocusElement").has(inputEle).length > 0) {
            inputEle.focus();
          } else {
            return;
          }
        }
        inputEle.focus();
      });
    }
  };
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ["singleValue:single-value"],
    init: function init() {
      this._super.apply(this, arguments);
      this.set("elementId", "attribute-".concat(this.get("key"), "-").concat(this.get("elementId")));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _this = this,
        container = _this.$();
      container.on("paste", "input", function (e) {
        //handle paste from excel
        var data = e.originalEvent.clipboardData.getData("text/plain");
        var rows = VerEx().tab().or().lineBreak().replace(data, "/\t").split("/\t");
        var changed = false;
        if (rows.length > 0) {
          e.preventDefault();
          rows.forEach(function (row) {
            if (row.trim()) {
              _this.set("value", _this.get("value") ? _this.get("value").replace(/\,\s*$/, "") + "," + row.trim() : row.trim());
              changed = true;
            }
          });
          Ember.run.scheduleOnce("afterRender", function () {
            if (_this.$() && _this.$("input").last()) {
              _this.$("input").last().focus();
            }
          });
          if (changed) {
            _this.sendAction("changed", _this.get("value"));
          }
        }
      });
    },
    isEmpty: Ember.computed("singleValue", function () {
      return this.get("singleValue") && (!this.get("items.firstObject.value") || this.get("items.firstObject.value") === "");
    }),
    singleValue: Ember.computed("items.length", function () {
      return this.get("items.length") === 1;
    }),
    items: Ember.computed("value", "unallowedValues", function () {
      var unallowedValues = this.get("unallowedValues");
      return Ember.A(this.get("value") ? this.get("value").split(",").map(function (v) {
        return Item.create({
          value: v,
          isUnallowed: unallowedValues && unallowedValues.indexOf(v) !== -1
        });
      }) : [Item.create()]);
    }),
    itemsObserver: Ember.observer("items.@each.value", function () {
      runLater = Ember.run.throttle(this, setValue, 1000, false);
    }),
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(runLater);
    },
    actions: {
      add: function add() {
        var _this3 = this;
        this.get("items").pushObject(Item.create());
        Ember.run.scheduleOnce("afterRender", function () {
          if (_this3.$()) {
            _this3.$(".data-row").last().find("input").focus();
          }
        });
      },
      removeItem: function removeItem(item) {
        var items = this.get("value").split(",");
        if (this.get("value")) {
          if (items.length > 1) {
            items.splice(items.indexOf(item.get("value")), 1);
            this.set("value", items.join(","));
          } else {
            this.set("value", "");
          }
          this.sendAction("changed", this.get("value"));
        }
      }
    }
  });
});