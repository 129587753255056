define("budgeta/templates/components/user-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MKkciDcQ",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"user\",\"firstName\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"unknown\",[\"user\",\"email\"]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"unknown\",[\"user\",\"firstName\"]],false],[\"text\",\"\\n  \"],[\"append\",[\"unknown\",[\"user\",\"lastName\"]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/user-name.hbs"
    }
  });
});