define("budgeta/components/react/tooltipWithIcon/tooltipWithIcon", ["exports", "@sibp/ui-components"], function (_exports, _uiComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable react/react-in-jsx-scope */
  // import React from "react";

  var TooltipWithIcon = function TooltipWithIcon(_ref) {
    var text = _ref.text,
      forcePlacement = _ref.forcePlacement,
      hook = _ref.hook,
      tooltipParentSelector = _ref.tooltipParentSelector,
      onMouseEnter = _ref.onMouseEnter,
      onMouseLeave = _ref.onMouseLeave,
      iconName = _ref.iconName;
    var TooltipIcon = _uiComponents.default.IMAGES[iconName];
    var hoverProps = {
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave
    };
    return React.createElement(_uiComponents.default.COMPONENTS.Tooltip, {
      forcePlacement: forcePlacement,
      tooltipParentSelector: tooltipParentSelector,
      iconName: React.createElement(TooltipIcon, null),
      hook: hook,
      text: text,
      hoverProps: hoverProps
    });
  };
  var _default = _exports.default = TooltipWithIcon;
});