define("budgeta/controllers/add-employee-modal", ["exports", "budgeta/services/ember-service-container", "budgeta/controllers/modal", "budgeta/utils/assumption", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _modal, _assumption, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = _modal.default.extend({
    mainController: Ember.inject.controller("main"),
    mainBudgetIndexController: Ember.inject.controller("main/budget/index"),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    cache: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        typeOptions: [{
          text: intl.t("general.text_1695611608836"),
          id: "employee"
        }, {
          text: intl.t("general.text_1695611608922"),
          id: "employee-multi"
        }, {
          text: intl.t("general.text_1695611609292"),
          id: "multiNewHire"
        }, {
          text: intl.t("general.text_1695611609280"),
          id: "multiNewHireModel"
        }, {
          text: intl.t("general.text_1695611608826"),
          id: "contractor"
        }, {
          text: intl.t("general.text_1695611609258"),
          id: "employee-allocated"
        }, {
          text: intl.t("general.text_1695611609247"),
          id: "employee-transferred"
        }],
        termOptions: [{
          text: intl.t("general.name_1695611602073"),
          id: "y"
        }, {
          text: intl.t("general.name_1695611602094"),
          id: "m"
        }],
        perOptions: [{
          text: intl.t("general.text_1695611608890"),
          id: "h"
        }, {
          text: intl.t("general.name_1695611603933"),
          id: "m"
        }, {
          text: intl.t("general.text_1695611608870"),
          id: "f"
        }]
      });
    },
    refreshFixedAssumptions: null,
    typeBefore: null,
    selectedComputed: Ember.computed("location", {
      get: function get() {
        return this.get("locations").findBy("id", this.get("location"));
      },
      set: function set(key, value) {
        this.set("location", value);
        return value;
      }
    }),
    typeOptions: null,
    termOptions: null,
    perOptions: null,
    location: Ember.computed("model.parent", "model.parent.id", "type", {
      get: function get() {
        return this.get("model.parent.id");
      },
      set: function set(key, value) {
        this.set("model.parent", this.store.peekRecord("budget", value));
        return value;
      }
    }),
    locationText: Ember.computed("employeeLocations", "model.parent.id", function () {
      var locations = this.get("employeeLocations");
      var location = locations.findBy("id", this.get("model.parent.id"));
      return location ? location.text : "";
    }),
    locations: Ember.computed("isContractor", function () {
      var type = this.get("isContractor") ? "contractor" : "employee";
      return this.get("store").peekAll("budget").filter(function (line) {
        return line && (type === "employee" && line.get("budgetType") === "group-payroll" || type === "contractor" && line.get("budgetType") === "group-ps" || (line.get("budgetType") === "group-department" || line.get("budgetType") === "group-department-geo" || line.get("budgetType") === "group-expense-op-simple" || line.get("budgetType") === "group-expense-costofsale") && line.get("children") && !line.get("children").findBy("budgetType", type === "employee" ? "group-payroll" : "group-ps"));
      }).map(function (line) {
        return {
          id: line.get("id"),
          text: line.get("fullPath"),
          budget: line,
          name: line.get("name")
        };
      });
    }),
    assumptions: Ember.computed("model", function () {
      return _assumption.default.getFixedAssumptions(this.get("root"), this.get("scenario"), this.get("model"), {
        withParent: true
      });
    }),
    type: Ember.computed("model.budgetType", "model.budgetAttributesWithScenarios.amountType", {
      get: function get() {
        if (this.get("model.budgetType") === "employee" || this.get("model.budgetType") === "contractor" || this.get("model.budgetType") === "employee-allocated" || this.get("model.budgetType") === "employee-transferred") {
          return this.get("model.budgetType");
        } else if (this.get("model.budgetType") === "employee-multi") {
          switch (this.get("model.budgetAttributesWithScenarios.amountType")) {
            case 0:
              return "employee-multi";
            case 1:
              return "multiNewHire";
            case 2:
              return "multiNewHireModel";
          }
        }
      },
      set: function set(key, value) {
        Ember.run.scheduleOnce("afterRender", this, function () {
          switch (value) {
            case "employee":
            case "contractor":
            case "employee-allocated":
            case "employee-transferred":
              this.set("model.budgetType", value);
              break;
            case "employee-multi":
              this.set("model.budgetType", "employee-multi");
              this.set("model.budgetAttributesWithScenarios.amountType", 0);
              break;
            case "multiNewHire":
              this.set("model.budgetType", "employee-multi");
              this.set("model.budgetAttributesWithScenarios.amountType", 1);
              break;
            case "multiNewHireModel":
              this.set("model.budgetType", "employee-multi");
              this.set("model.budgetAttributesWithScenarios.amountType", 2);
              break;
          }
        });
      }
    }),
    isEmployee: Ember.computed("type", function () {
      return this.get("type") === "employee" || this.get("isEmployeeMulti") || this.get("isEmployeeMultiNewHire") || this.get("isEmployeeMultiNewHireModel");
    }),
    isContractor: Ember.computed("type", function () {
      return this.get("type") === "contractor";
    }),
    isEmployeeAllocated: Ember.computed("type", function () {
      return this.get("type") === "employee-allocated";
    }),
    isEmployeeTransferred: Ember.computed("type", function () {
      return this.get("type") === "employee-transferred";
    }),
    isEmployeeMulti: Ember.computed("type", function () {
      return this.get("type") === "employee-multi";
    }),
    isEmployeeMultiNewHire: Ember.computed("type", function () {
      return this.get("type") === "multiNewHire";
    }),
    isEmployeeMultiNewHireModel: Ember.computed("type", function () {
      return this.get("type") === "multiNewHireModel";
    }),
    currency: Ember.computed("model.budgetAttributesWithScenarios.currency", "model.parent.budgetAttributesWithScenarios.currency", "location", "model.parent", "budgetService.selectedRoot.budgetAttributesWithScenarios.currency", function () {
      if (!this.get("location") && !this.get("model.parent")) {
        return this.get("budgetService.selectedRoot.budgetAttributesWithScenarios.currency");
      } else if (this.get("model.parent")) {
        return this.get("model.parent.budgetAttributesWithScenarios.currency");
      } else {
        return this.get("model.budgetAttributesWithScenarios.currency");
      }
    }),
    currencyOptions: Ember.computed("model.root.fxCurrencies.[]", "model.budgetAttributes.currency", function () {
      var result = [],
        currencies = [];
      if (this.get("model.root.fxCurrencies.length")) {
        currencies.pushObjects(this.get("model.root.fxCurrencies"));
      } else {
        currencies.pushObjects(_const.default.DEFAULT_CURRENCIES);
      }
      if (this.get("model.budgetAttributes.currency")) {
        currencies.addObject(this.get("model.budgetAttributes.currency"));
      }
      if (!this.get("model.isRoot")) {
        result = [{
          id: "auto",
          name: intl.t("general.name_1695611601766"),
          value: null,
          label: intl.t("general.name_1695611601766")
        }];
      }
      return result.concat(currencies.map(function (v) {
        return {
          id: v,
          value: v,
          label: v,
          name: v
        };
      }));
    }),
    parentObserver: Ember.observer("model.parent", function () {
      var _this = this;
      Ember.run.next(function () {
        if (_this.get("model.parent")) {
          Ember.$(".modal-dialog .modal-content .modal-footer #confirm-btn").removeClass("disabled");
        } else {
          Ember.$(".modal-dialog .modal-content .modal-footer #confirm-btn").addClass("disabled");
        }
      });
    }),
    actions: {
      updateType: function updateType(data) {
        this.set("type", data.id);
        var type = this.get("isContractor") ? "contractor" : "employee";
        if (this.get("typeBefore") && this.get("typeBefore") !== type) {
          this.set("model.parent", null);
        }
        this.set("typeBefore", type);
      },
      toogleShowMore: function toogleShowMore() {
        this.set("showMore", !this.get("showMore"));
      },
      closeFormulaEditor: function closeFormulaEditor() {
        this.send("closeModal", "modal2");
      },
      openFormulaEditor: function openFormulaEditor(component) {
        if (this.get("model.isModelGroup")) {
          // cause the fixed assumtions list to refresh
          this.notifyPropertyChange("refreshFixedAssumptions");
        }
        if (!Ember.isEmpty(component.get("value")) && component.get("value").toString().substr(0, 1) !== "=") {
          component.set("value", "=" + component.get("value"));
        }
        var formulaTemplate = _const.default.FORMULA_VERSION;
        this.send("openModal", formulaTemplate, {
          model: component,
          modelLine: this.get("editLine"),
          title: intl.t("general.title_1695611597384"),
          templateName: formulaTemplate,
          confirmActionName: intl.t("general.confirmActionName_1695611613098"),
          confirmAction: "closeFormulaEditor",
          actionTarget: this,
          outlet: "modal2",
          hideCancel: false
        });
      },
      deleteComment: function deleteComment(comment) {
        this.set("commentToDelete", comment);
        return this.send("openModal", "modal", {
          model: this.get("model"),
          title: intl.t("general.title_1695611597396"),
          templateName: "confirm-delete-comment",
          confirmAction: "deleteCommentConfirmed",
          confirmActionName: intl.t("general.confirmActionName_1695611613076"),
          outlet: "modal2",
          actionTarget: this
        });
      },
      deleteCommentConfirmed: function deleteCommentConfirmed() {
        this.get("commentToDelete").unloadRecord();
        this.send("closeModal", "modal2");
      },
      addComment: function addComment() {
        var comment = this.store.createRecord("comment", {
          user: this.store.peekRecord("user", this.get("session.data.currentUser.id")),
          budget: this.get("model"),
          created: new Date()
        });
        comment.readonly = false;
      },
      changed: function changed() {
        this.set("changed", true);
      },
      didSelect: function didSelect() {
        return;
      },
      powerSelectChange: function powerSelectChange(line) {
        this.set("location", line.id);
      }
    }
  });
});