define("budgeta/components/main/budget/trx-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    lineHeight: 40,
    scrollHeight: 300,
    scrollWidth: 300,
    performanceLogger: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    checkHasInnerScroll: function checkHasInnerScroll() {
      var _this2 = this;
      Ember.run.schedule("afterRender", function () {
        if (!_this2.$() || !_this2.$(".trx-table-body") || !_this2.$(".trx-table-body").height) {
          return;
        }
        var tableHeight = _this2.$(".trx-table-body").height();
        var trxHeight = _this2.get("controllertmp.filteredTransactions.length") * 40;
        if (trxHeight > tableHeight) {
          _this2.set("controllertmp.hasInnerScroll", true);
        } else {
          _this2.set("controllertmp.hasInnerScroll", false);
        }
      });
    },
    hasInnerScrollObserver: Ember.observer("controllertmp.filteredTransactions.length", function () {
      this.checkHasInnerScroll();
    }),
    scrollHeightStyle: Ember.computed("scrollHeight", function () {
      return Ember.String.htmlSafe("height:".concat(this.get("scrollHeight"), "px;"));
    }),
    syncScroll: function syncScroll() {
      var _this3 = this;
      Ember.run.schedule("afterRender", function () {
        // BUD-19461 waiting till table data rendering will be finished, to assure scroll events will be set properly
        setTimeout(function () {
          if (!_this3.$()) {
            return;
          }
          var tableHeader = _this3.$(".trx-table-header");
          var tableBody = _this3.$(".trx-table-body .ember-list-view-list");
          var headerIsScrolling = false;
          var bodyIsScrolling = false;
          _this3.checkHasInnerScroll();
          if (tableHeader) {
            tableHeader.on("scroll", function (e) {
              if (!bodyIsScrolling) {
                headerIsScrolling = true;
                tableBody.get(0).scrollLeft = e.target.scrollLeft;
                headerIsScrolling = false;
              }
            });
          }
          if (tableBody) {
            tableBody.on("scroll", function (e) {
              if (!headerIsScrolling) {
                bodyIsScrolling = true;
                tableHeader.get(0).scrollLeft = e.target.scrollLeft;
                bodyIsScrolling = false;
              }
            });
          }
          var view = _this3;
          var rows = view.$(".scrollable-wrapper");
          if (rows) {
            rows.each(function (idx, el) {
              el.scrollLeft = tableHeader ? tableHeader.find(".scrollable-wrapper").scrollLeft() : 0;
            });
          }
          var syncScroll = function syncScroll(event) {
            var scrollLeft = event.deltaX;
            if (scrollLeft) {
              var _rows = view.$(".scrollable-wrapper");
              event.preventDefault();
              if (_rows) {
                _rows.each(function (idx, el) {
                  el.scrollLeft += scrollLeft;
                });
              }
            }
            if (event.deltaY) {
              document.activeElement.blur();
            }
          };
          var syncScrollFooter = function syncScrollFooter(thisEl) {
            var scrollLeft = thisEl.scrollLeft;
            var rows = view.$(".scrollable-wrapper");
            if (rows) {
              rows.each(function (idx, el) {
                el.scrollLeft = scrollLeft;
              });
            }
          };
          _this3.$(".table-header").off("mousewheel DOMMouseScroll scroll touchmove", ".scrollable-wrapper").on("mousewheel DOMMouseScroll scroll touchmove", ".scrollable-wrapper", function (event) {
            syncScroll(event);
          });
          _this3.$(".ember-list-view-list").off("mousewheel DOMMouseScroll scroll touchmove").on("mousewheel DOMMouseScroll scroll touchmove", function (event) {
            syncScroll(event);
          });
          _this3.$(".table-footer .scrollable-wrapper").off("mousewheel DOMMouseScroll scroll touchmove").on("mousewheel DOMMouseScroll scroll touchmove", function () {
            syncScrollFooter(this);
          });
        }, 0);
      });
    },
    syncScrollObserver: Ember.observer("controllertmp.allTransactions.length", "controllertmp.filteredTransactions.length", "viewSelections.trxPageDoneLoading", function () {
      if (this.get("viewSelections.trxPageDoneLoading")) {
        this.syncScroll();
      }
    }),
    adjustSize: function adjustSize(_this) {
      var _this4 = this;
      _this.adjustWidth.apply(_this);
      Ember.run.schedule("afterRender", function () {
        if (_this.$()) {
          var width = _this.$().width();
          var placeForScroll = 22;
          _this.setProperties({
            scrollHeight: _this.$().height() - 90 - placeForScroll,
            scrollWidth: width
          });
          _this4.checkHasInnerScroll();
        }
      });
    },
    adjustWidth: Ember.observer("controllertmp.previewTransactions.length", function () {
      var _this5 = this;
      Ember.run.schedule("afterRender", function () {
        if (_this5.$() && _this5.$().find(".table-scrollable").length) {
          var scrollWidth = 0;
          // adjust scrollable width according to total width of the content
          _this5.$().find(".table-scrollable").first().find(">*").each(function (idx, col) {
            scrollWidth += col.offsetWidth;
          });
          _this5.set("controllertmp.scrollWidth", scrollWidth);
        }
      });
    }),
    didInsertElement: function didInsertElement() {
      var _this6 = this;
      this._super.apply(this, arguments);
      Ember.run.schedule("afterRender", function () {
        Ember.run.next(function () {
          if (_this6.$() && _this6.$().find(".table-scrollable").length) {
            var scrollWidth = 0;
            // adjust scrollable width according to total width of the content
            _this6.$().find(".table-scrollable").first().find(">*").each(function (idx, col) {
              scrollWidth += col.offsetWidth;
            });
            _this6.set("controllertmp.scrollWidth", scrollWidth);
          }
        });
      });
      this.adjustSize(this);
      Ember.$(window).off("resize.trx").on("resize.trx", function () {
        _this6.adjustSize(_this6);
      });
      Ember.$(".main-content .secondary ol.tree.nav").scrollTo("li.budget-list-item.active");
      this.syncScroll();
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off("resize.trx").off("beforeunload.trx");
    }
  });
});