define("budgeta/controllers/admin/tips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    current: null,
    textObserver: Ember.observer("current.text", function () {
      var _this = this;
      Ember.run.scheduleOnce("afterRender", function () {
        Ember.$(".note-editable").html(_this.get("current.text"));
      });
    })
  });
});