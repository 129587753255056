define("budgeta/admin/admin-app/components/header/header", ["exports", "react-router-dom", "budgeta/admin/admin-app/routes", "@sibp/ui-components", "react-redux", "react", "budgeta/admin/store/actions/notifActions", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _reactRouterDom, _routes, _uiComponents, _reactRedux, _react, _notifActions, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Header = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var routerService = new _emberServiceContainer.default().lookup("routerService");
  var sessionService = new _emberServiceContainer.default().lookup("sessionService");
  var Header = _exports.Header = function Header() {
    var history = _reactRouterDom.default.useHistory();
    var showLoader = _reactRedux.default.useSelector(function (state) {
      return state.notifStore.showLoader;
    });
    var noty = _reactRedux.default.useSelector(function (state) {
      return state.notifStore.noty;
    });
    var loggedInUser = _reactRedux.default.useSelector(function (state) {
      return state.securityStore.loggedInUser;
    });
    var dispatch = _reactRedux.default.useDispatch();
    var closeNoty = function closeNoty() {
      dispatch(_notifActions.default.hideNoty());
    };
    var defaultOptions = {
      options: [{
        id: 0,
        label: intl.t("admin_app.user-profile.my-profile"),
        dataHook: "admin-my-profile",
        onClick: function onClick() {
          return history.push(_routes.ROUTES.MY_DETAILS(_routes.BASE_ROUTE));
        }
      }, {
        id: 1,
        label: intl.t("admin_app.header.my-budgets"),
        dataHook: "admin-my-budgets",
        onClick: function onClick() {
          window.location = window.location.origin;
        }
      }, {
        id: 2,
        label: intl.t("admin_app.user-profile.sign-out"),
        dataHook: "admin-sign-out",
        onClick: function onClick() {
          if (_const.default.FF_SSO) {
            routerService.logout();
          } else {
            sessionService.doLogout();
            // logout and refresh
            history.push("/logout");
            history.go(0);
          }
        }
      }]
    };
    return _react.default.createElement("div", {
      className: "app-header-container"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Loader, {
      visible: showLoader
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Noty, {
      text: noty.text,
      visible: noty.visible,
      errorLevel: noty.errorLevel,
      onClose: closeNoty
    }), _react.default.createElement("div", {
      id: "logo",
      className: "company-logo"
    }, _react.default.createElement("span", {
      tabIndex: "0",
      role: "button",
      onClick: function onClick() {
        return window.location = window.location.origin;
      },
      onKeyUp: function onKeyUp() {
        return window.location = window.location.origin;
      }
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.AppLogo, null))), _react.default.createElement("div", {
      className: "header-username"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.LabelWithDropdown, {
      options: defaultOptions.options,
      labelText: loggedInUser && loggedInUser.fullName
    })));
  };
});