define("budgeta/utils/password-validation", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var getPasswordValidationError = function getPasswordValidationError(userPassword, confirmUserPassword) {
    if (userPassword.length < 8 || userPassword.length > 15) {
      return intl.t("user-profile.validations.password-length-error");
    }
    if (!/\d/.test(userPassword) || !/[A-Z]/.test(userPassword) || !/[a-z]/.test(userPassword) || !/\W/.test(userPassword)) {
      return intl.t("user-profile.validations.password-validation-error");
    }
    if (userPassword !== confirmUserPassword) {
      return intl.t("user-profile.validations.confirm-password-error");
    }
    return "";
  };
  var _default = _exports.default = {
    getPasswordValidationError: getPasswordValidationError
  };
});