define("budgeta/components/intacct-wizard", ["exports", "budgeta/components/modal-new", "budgeta/templates/components/modal-new"], function (_exports, _modalNew, _modalNew2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    classNameBindings: ["controllertmp.wideView:extra-wide", ":wizard-modal", ":intacct-wizard", ":group-company-intacct"],
    layout: _modalNew2.default,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get("controllertmp").notifyPropertyChange("actualsImported");
    }
  });
});