define("budgeta/components/power-budget-select-lazy", ["exports", "budgeta/utils/budget-utils"], function (_exports, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    classNameBindings: [":path-list-element-lazy", "active:active", "loading:loading", "readOnly:read-only", "wrap:wrap"],
    loading: false,
    mapByDatabaseId: false,
    active: Ember.computed("readOnly", {
      get: function get() {
        if (this.get("readOnly")) {
          return false;
        }
        return false;
      },
      set: function set(key, value) {
        if (this.get("readOnly")) {
          return false;
        }
        return value;
      }
    }),
    selectedLabel: Ember.computed("content", "readyContent", "selectedBudgets", function () {
      var content = this.get("content") || this.get("readyContent");
      var selected = content && content.findBy(this.get("mapByDatabaseId") ? "id" : "uniqueId", this.get("selectedBudgets"));
      if (!content) {
        selected = this.get("mapByDatabaseId") ? this.get("store").peekRecord("budget", this.get("selectedBudgets")) : _budgetUtils.default.findLineByUniqueId(this.get("store"), this.get("cache"), this.get("selectedBudgets"));
      }
      if (selected) {
        return selected;
      } else {
        return "";
      }
    }),
    actions: {
      activate: function activate() {
        if (this.get("readOnly")) {
          return;
        } else {
          this.set("active", true);
        }
      }
    },
    powerSelectChange: function powerSelectChange(val) {
      if (this.get("powerSelectChange")) {
        this.sendAction(this.get("powerSelectChange"), val);
      } else {
        this.set("selected", val["".concat(this.get("optionValuePath"))]);
      }
    }
  });
});