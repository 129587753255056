define("budgeta/components/select-intacct-dimension-level", ["exports", "budgeta/services/ember-service-container", "lodash/lodash"], function (_exports, _emberServiceContainer, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects */
  var _default = _exports.default = Ember.Component.extend({
    // classNameBindings: ['dimension.hasLevels:has-levels:empty', ':select-intacct-dimemsion-level'],
    tagName: "",
    dimension: Ember.computed("selected.id", "dimensions", function () {
      return this.get("dimensions").findBy("dimension", this.get("selected.id"));
    }),
    content: Ember.computed("dimension", function () {
      var maxLevel = _lodash.default.max(this.get("dimension.values").mapBy("level"));
      var result = [{
        id: 0,
        text: intl.t("general.name_1695611603952")
      }, {
        id: maxLevel,
        text: intl.t("general.text_1695611609138")
      }];
      this.set("selected.level", 0);
      return result;
    })
  });
});