define("budgeta/utils/useEmberBridge", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function useEmberBridge(subsriptionName, func) {
    var subscriptionService = new _emberServiceContainer.default().lookup("subscriptionService");
    function handleSubscription() {
      func();
    }
    if (Array.isArray(subsriptionName)) {
      subsriptionName.forEach(function (subName) {
        return subscriptionService.subscription.subscribe(subName, handleSubscription);
      });
    } else {
      subscriptionService.subscription.subscribe(subsriptionName, handleSubscription);
    }
  }
  var _default = _exports.default = useEmberBridge;
});