define("budgeta/admin/company-admin-app/store/reducers/companyReducer", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/admin/company-admin-app/store/actions/companyActions"], function (_exports, _toConsumableArray2, _objectSpread2, _companyActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initialState = {
    selectedCompany: {
      _id: "",
      name: "",
      users: []
    }
  };
  function rootReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case _companyActions.SELECT_COMPANY:
        {
          var selectedCompany = (0, _objectSpread2.default)({}, action.company);
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: selectedCompany
          });
        }
      case _companyActions.GET_COMPANY_USER_LIST:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state.selectedCompany), {}, {
              users: (0, _toConsumableArray2.default)(action.users)
            })
          });
        }
      case _companyActions.ADD_COMPANY_USER:
        {
          var _selectedCompany = (0, _objectSpread2.default)({}, state.selectedCompany);
          _selectedCompany.users.push(action.companyUserDetails);
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: _selectedCompany
          });
        }
      case _companyActions.UPDATE_COMPANY_USER:
        {
          var _selectedCompany2 = (0, _objectSpread2.default)({}, state.selectedCompany);
          var idxUser = _selectedCompany2.users.findIndex(function (u) {
            return u._id === action.companyUserDetails._id;
          });
          _selectedCompany2.users[idxUser] = action.companyUserDetails;
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: _selectedCompany2
          });
        }
      case _companyActions.DELETE_COMPANY_USER:
        {
          var _selectedCompany3 = (0, _objectSpread2.default)({}, state.selectedCompany);
          var idx = _selectedCompany3.users.findIndex(function (u) {
            return u.user._id === action.userId;
          });
          if (idx > -1) {
            _selectedCompany3.users.splice(idx, 1);
          }
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            selectedCompany: _selectedCompany3
          });
        }
      default:
        return state;
    }
  }
  var _default = _exports.default = rootReducer;
});