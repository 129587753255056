define("budgeta/templates/components/papa-parse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/OyHznTz",
    "block": "{\"statements\":[[\"open-element\",\"input\",[]],[\"static-attr\",\"type\",\"file\"],[\"modifier\",[\"action\"],[[\"get\",[null]],\"parse\"],[[\"on\"],[\"change\"]]],[\"flush-element\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/papa-parse.hbs"
    }
  });
});