define("budgeta/admin/admin-app/routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ROUTES = _exports.BASE_USER_ROUTE = _exports.BASE_ROUTE = _exports.BASE_COMPANY_ROUTE = _exports.BASE_BUDGET_ROUTE = _exports.BASE_ADMIN_USER_ROUTE = void 0;
  var BASE_ROUTE = _exports.BASE_ROUTE = "/admin-app";
  var BASE_COMPANY_ROUTE = _exports.BASE_COMPANY_ROUTE = "".concat(BASE_ROUTE, "/company");
  var BASE_COMPANY_EDIT_ROUTE = "".concat(BASE_COMPANY_ROUTE, "/edit/:companyId");
  var BASE_ADMIN_USER_ROUTE = _exports.BASE_ADMIN_USER_ROUTE = "".concat(BASE_ROUTE, "/admin-user");
  var BASE_USER_ROUTE = _exports.BASE_USER_ROUTE = "".concat(BASE_ROUTE, "/users");
  var BASE_BUDGET_ROUTE = _exports.BASE_BUDGET_ROUTE = "".concat(BASE_ROUTE, "/budgets");
  var ROUTES = _exports.ROUTES = {
    ADMIN_USER_ADD: "".concat(BASE_ADMIN_USER_ROUTE, "/add"),
    ADMIN_USER_LIST: "".concat(BASE_ROUTE, "/admin-users"),
    ADMIN_USER_EDIT: "".concat(BASE_ADMIN_USER_ROUTE, "/edit/:userId"),
    USER_LIST: "".concat(BASE_USER_ROUTE),
    USER_BUDGET_LIST: "".concat(BASE_USER_ROUTE, "/:userId"),
    COMPANY_LIST: "".concat(BASE_ROUTE, "/companies"),
    BUDGET: "".concat(BASE_BUDGET_ROUTE, "/:budgetId"),
    BUDGET_SEARCH: BASE_BUDGET_ROUTE,
    COMPANY_EDIT: "".concat(BASE_COMPANY_EDIT_ROUTE),
    COMPANY_ADD: "".concat(BASE_COMPANY_ROUTE, "/add"),
    COMPANY_EDIT_DETAILS: "".concat(BASE_COMPANY_EDIT_ROUTE, "/details"),
    COMPANY_USER_LIST: "".concat(BASE_COMPANY_EDIT_ROUTE, "/users"),
    COMPANY_USER_ADD: "".concat(BASE_COMPANY_EDIT_ROUTE, "/user/add"),
    COMPANY_USER_EDIT: "".concat(BASE_COMPANY_EDIT_ROUTE, "/user/edit/:userId"),
    COMPANY_BUDGET_LIST: "".concat(BASE_COMPANY_EDIT_ROUTE, "/budgets"),
    PROFILE: function PROFILE() {
      var baseRoute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      return "".concat(baseRoute, "/my-profile");
    },
    MY_PASSWORD: function MY_PASSWORD() {
      var baseRoute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      return "".concat(baseRoute, "/my-profile/my-password");
    },
    MY_DETAILS: function MY_DETAILS() {
      var baseRoute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      return "".concat(baseRoute, "/my-profile/my-details");
    },
    AUDIT_LOGGING_LIST: "".concat(BASE_ROUTE, "/audit-logging"),
    REPORTS: "".concat(BASE_ROUTE, "/reports")
  };
});