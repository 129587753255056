define("budgeta/components/dependencies-modal", ["exports", "budgeta/components/modal-new"], function (_exports, _modalNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.later(function () {
        _this.set("controllertmp.history", []);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get("controllertmp.lastSelectedRoot") !== this.get("controllertmp.budgetService.selectedRoot")) {
        this.set("controllertmp.model", this.get("controllertmp.budgetService.selectedRoot"));
      }
      this.set("controllertmp.lastSelectedRoot", this.get("controllertmp.budgetService.selectedRoot"));
    }
  });
});