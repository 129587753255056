define("budgeta/templates/components/svg/expand-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RHnh/YrX",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M7.88571 13.7667C8.01726 13.7667 8.1311 13.7186 8.22723 13.6225L11.6272 10.2225C11.7234 10.1264 11.7714 10.0125 11.7714 9.88098C11.7714 9.74943 11.7234 9.63559 11.6272 9.53946C11.5311 9.44333 11.4173 9.39526 11.2857 9.39526L4.48571 9.39526C4.35417 9.39526 4.24033 9.44333 4.1442 9.53946C4.04807 9.63559 4 9.74943 4 9.88098C4 10.0125 4.04807 10.1264 4.1442 10.2225L7.5442 13.6225C7.64033 13.7186 7.75417 13.7667 7.88571 13.7667Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M11.2857 7.27145C11.4173 7.27145 11.5311 7.22339 11.6272 7.12726C11.7234 7.03112 11.7714 6.91729 11.7714 6.78574C11.7714 6.65419 11.7234 6.54035 11.6272 6.44422L8.22723 3.04422C8.1311 2.94809 8.01726 2.90002 7.88571 2.90002C7.75417 2.90002 7.64033 2.94809 7.5442 3.04422L4.1442 6.44422C4.04807 6.54035 4 6.65419 4 6.78574C4 6.91729 4.04807 7.03112 4.1442 7.12726C4.24033 7.22339 4.35417 7.27145 4.48571 7.27145L11.2857 7.27145Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/expand-svg.hbs"
    }
  });
});