define("budgeta/components/b-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["panel", "panel-default"],
    attributeBindings: ["data-hide"]
    /*
    didInsertElement() {
    this._super(...arguments);
    Ember.run.scheduleOnce('afterRender', this, function() {
    	this.$('[data-toggle="collapse"]').off('click.panel').on('click.panel', function() {
    		Ember.$(this).parent().toggleClass("collapsed")
    			.closest('.panel').find('.panel-collapse').slideToggle("fast");
    	});
    });
    }
    */
  });
});