define("budgeta/templates/components/model-share-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8qi4QPMf",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"model-share-modal.model-name\"],null],false],[\"close-element\"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"required\",\"value\"],[true,[\"get\",[\"model\",\"name\"]]]]],false],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"model-share-modal.model-description\"],null],false],[\"close-element\"],[\"append\",[\"helper\",[\"html5-textarea\"],null,[[\"required\",\"value\"],[true,[\"get\",[\"model\",\"description\"]]]]],false],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"model-share-modal.company-name\"],null],false],[\"close-element\"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"required\",\"value\"],[true,[\"get\",[\"model\",\"companyName\"]]]]],false],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"model-share-modal.about-me\"],null],false],[\"close-element\"],[\"append\",[\"helper\",[\"textarea\"],null,[[\"value\"],[[\"get\",[\"model\",\"about\"]]]]],false],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/model-share-modal.hbs"
    }
  });
});