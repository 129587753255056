define("budgeta/templates/components/svg/share-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fq9fJZPp",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M12.8 10.1C11.84 10.1 11.04 10.5 10.48 11.14L5.6 8.58C6.56 8.1 8.16 7.3 10.72 6.1C11.2 6.58 12 6.9 12.8 6.9C14.56 6.9 16 5.46 16 3.7C16 1.94 14.56 0.5 12.8 0.5C11.04 0.5 9.6 1.94 9.6 3.7C9.6 4.02 9.68 4.34 9.76 4.66L5.76 6.66C5.2 5.86 4.24 5.3 3.2 5.3C1.44 5.3 0 6.74 0 8.5C0 10.26 1.44 11.7 3.2 11.7C4.24 11.7 5.12 11.22 5.68 10.42L9.68 12.5C9.6 12.82 9.6 13.06 9.6 13.3C9.6 15.06 11.04 16.5 12.8 16.5C14.56 16.5 16 15.06 16 13.3C16 11.54 14.56 10.1 12.8 10.1Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/share-svg.hbs"
    }
  });
});