define("budgeta/components/dimension-draggable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":dimension-draggable", "disableDimension:disable-dimension"],
    attributeBindings: ["draggable", "data-name"],
    "data-name": Ember.computed.alias("dimension.text"),
    stopScroll: true,
    scroll: function scroll(container, step) {
      var _this = this;
      var scrollY = container.scrollTop;
      if (scrollY + step > 0 || container.scrollHeight - container.offsetHeight + step < scrollY) {
        container.scrollTop += step;
        if (!this.get("stopScroll")) {
          setTimeout(function () {
            return _this.scroll(container, step);
          }, 20);
        }
      } else {
        this.set("stopScroll", true);
      }
    },
    disableDimension: Ember.computed("dimension", "structureFirstLevel.levels.[]", "structureFirstLevel.levels.length", function () {
      var _this2 = this;
      return this.get("structureFirstLevel.levels").find(function (level) {
        return _this2.get("dimension").text === level.text;
      });
    }),
    triggerId: Ember.computed("elementId", function () {
      return this.get("elementId") + "-trigger";
    }),
    draggable: "true",
    dragStart: function dragStart(event) {
      if (this.get("disableDimension")) {
        return;
      }
      this.set("scrollContainer", Ember.$(".budget-edit-structure-drop"));
      this.set("inDragMode", this.get("dimension"));
      return event.dataTransfer.setData("text/data", "".concat(this.get("dimension.id"), "#").concat(this.get("dimension.isSub")) + (this.get("dimension.isSub") ? "#".concat(this.get("dimension.subLevel")) : ""));
    },
    drag: function drag(e) {
      var container = this.get("scrollContainer"),
        offset = container.offset(),
        containerHeight = container.height(),
        containerWidth = container.width();
      this.set("stopScroll", true);

      // first check if we're over the container
      if (e.originalEvent.clientX >= offset.left && e.originalEvent.clientX <= offset.left + containerWidth) {
        if (e.originalEvent.clientY >= offset.top - 20 && e.originalEvent.clientY < offset.top + 40) {
          this.set("stopScroll", false);
          this.scroll(container.get(0), -1);
        }
        if (e.originalEvent.clientY <= offset.top + containerHeight + 20 && e.originalEvent.clientY > offset.top + containerHeight - 40) {
          this.set("stopScroll", false);
          this.scroll(container.get(0), 1);
        }
      }
    },
    dragEnd: function dragEnd() {
      this.set("stopScroll", true);
      if (!this.get("showAllLines")) {
        this.set("inDragMode", undefined);
      }
    },
    dimensionValues: Ember.computed("dimension.dimension.values", function () {
      if (this.get("dimension.isSub")) {
        return this.get("dimension.dimension.values").filterBy("level", this.get("dimension.subLevel"));
      }
      return this.get("dimension.dimension.values").filterBy("level", 0);
    })
  });
});