define("budgeta/components/select-dimension", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["budgeta-type-value", "budgeta-type-dropdown", "budgeta-type-dropdown-short"],
    attributeBindings: ["data-key", "data-hide", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    budgetService: Ember.inject.service(),
    searchEnabled: false,
    placeholder: intl.t("general.text_1695611608847"),
    selectedValue: Ember.computed("budgetAttributes", "key", {
      get: function get() {
        return this.get("budgetAttributes.".concat(this.get("key"))) || null;
      },
      set: function set(key, value) {
        this.set("budgetAttributes.".concat(this.get("key")), value);
        return value;
      }
    })
  });
});