define("budgeta/components/intacct-emp-map-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["employee-row", "flex"],
    locations: Ember.computed("data.length", function () {
      var locs = this.get("data") || [];
      return locs.filter(function (loc) {
        return !loc.deleted && !locs.findBy("parentid", loc.locationid);
      }).map(function (loc) {
        return {
          id: loc.locationid,
          text: loc.name
        };
      });
    }),
    locationObserver: Ember.observer("emp.selectedloc", function () {
      var deps = this.get("departments");
      if (this.get("emp.selecteddep") && !deps.findBy("id", this.get("emp.selecteddep"))) {
        this.set("emp.selecteddep", undefined);
      }
      if (!this.get("emp.selecteddep") && deps.findBy("id", this.get("emp.departmentid"))) {
        this.set("emp.selecteddep", this.get("emp.departmentid"));
      }
    }),
    departments: Ember.computed("data.length", "hasLocations", "emp.selectedloc", function () {
      var deps = [];
      if (this.get("hasLocations")) {
        if (this.get("emp.selectedloc")) {
          deps = this.get("data").findBy("locationid", this.get("emp.selectedloc")).departments || [];
        }
      } else {
        deps = this.get("data") || [];
      }
      var result = deps.filter(function (dep) {
        return !dep.deleted && !deps.findBy("parentid", dep.departmentid);
      }).map(function (dep) {
        return {
          id: dep.departmentid,
          text: dep.title
        };
      });
      return result;
    })
  });
});