define("budgeta/templates/components/svg/video-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4hSFDMUO",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"20\"],[\"static-attr\",\"height\",\"20\"],[\"static-attr\",\"viewBox\",\"0 0 20 20\"],[\"static-attr\",\"fill\",\"none\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM7.78906 6.07715C7.90856 6.07715 8.02865 6.10556 8.13965 6.16406L14.166 9.33691C14.4135 9.46691 14.5669 9.72148 14.5664 10.001C14.5664 10.2805 14.4135 10.5341 14.166 10.6641L8.13965 13.8359C8.02915 13.8939 7.90856 13.9229 7.78906 13.9229C7.65506 13.9229 7.52184 13.8865 7.40234 13.8145C7.17534 13.6775 7.04004 13.4369 7.04004 13.1719V6.82812C7.04004 6.56313 7.17534 6.32255 7.40234 6.18555C7.52184 6.11305 7.65556 6.07715 7.78906 6.07715Z\"],[\"static-attr\",\"fill\",\"black\"],[\"static-attr\",\"fill-opacity\",\"0.65\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/video-svg.hbs"
    }
  });
});