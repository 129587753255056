define("budgeta/components/actions-budget-tree-master", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    collapsedBudgets: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    classNames: ["actions-wrapper"],
    actions: {
      addChildren: function addChildren(budget) {
        this.get("trackingEvents").trackEvent(this.get("trackingEvents.events.CLICK_ON_ADD_NEW_BUDGET_LINE"));
        if (this.get("isCollapsed")) {
          this.get("collapsedBudgets").removeTreeItem(this.get("budget"), true);
          this.get("collapsedBudgets").save();
        }
        this.sendAction("addChildren", budget);
        return false;
      },
      duplicate: function duplicate(budget, formatOnly) {
        this.sendAction("duplicate", budget, formatOnly);
      },
      delete: function _delete(budget) {
        this.sendAction("delete", budget);
      },
      showMore: function showMore() {
        this.sendAction("showMore");
      },
      showLess: function showLess() {
        this.sendAction("showLess");
      },
      copy: function copy(budget, dest) {
        this.sendAction("copy", budget, dest);
      },
      move: function move(budget, dest, position) {
        this.sendAction("move", budget, dest, position);
      },
      flag: function flag(budget) {
        this.sendAction("flag", budget);
      },
      mergeScenario: function mergeScenario(options) {
        this.sendAction("mergeScenario", options);
      },
      share: function share(budget) {
        this.sendAction("share", budget);
      },
      rename: function rename(budget) {
        this.sendAction("rename", budget);
      },
      importWizard: function importWizard(budget) {
        this.sendAction("importWizard", budget || this.get("budget"));
      },
      downloadTemplate: function downloadTemplate(budget) {
        this.sendAction("downloadTemplate", budget);
      },
      saveToModels: function saveToModels(budget) {
        this.sendAction("saveToModels", budget);
      },
      showLineDependencies: function showLineDependencies(budget) {
        this.sendAction("showLineDependencies", budget);
      }
    }
  });
});