define("budgeta/admin/admin-app/store/store", ["exports", "budgeta/admin/admin-app/store/reducers", "redux-thunk", "redux"], function (_exports, _reducers, _reduxThunk, _redux) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable import/no-unresolved */

  var createStore = _redux.default.createStore,
    applyMiddleware = _redux.default.applyMiddleware;
  var store = createStore(_reducers.default, applyMiddleware(_reduxThunk.default));
  var _default = _exports.default = store;
});