define("budgeta/components/actuals-accounts-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ["levelClass", "focusInElement:focus", "line.isCollapse:collapse", "hasDuplications:has-duplications"],
    levelClass: Ember.computed("line.level", function () {
      return "level-".concat(this.get("line.level"));
    }),
    focusInElement: false,
    isGroup: Ember.computed("line.accountId", "line.accountId.length", function () {
      if (this.get("line.accountId")) {
        return this.get("line.accountId").split(",").length > 1;
      }
      return false;
    }),
    collapseValue: Ember.computed("line.accountId", "line.accountId.length", function () {
      if (this.get("line.accountId")) {
        var accountText = "";
        var accounts = this.get("line.accountId").split(",");
        var count = 0;
        for (var i = 0; i < accounts.length; i++) {
          if (count <= 3) {
            if (!Ember.isEmpty(accounts[i])) {
              if (!Ember.isEmpty(accountText)) {
                accountText += ", ";
              }
              accountText += count < 3 ? accounts[i] : count === 3 ? accounts[i].charAt(0) + "..." : "";
              count++;
            }
          } else {
            break;
          }
        }
        return accountText;
      }
      return "";
    }),
    hasDuplications: Ember.computed("line.duplicateAccounts", "line.duplicateAccounts.length", function () {
      return this.get("line.duplicateAccounts") && this.get("line.duplicateAccounts").length > 0;
    }),
    focusOut: function focusOut() {
      if (this.get("line.accountId") !== this.get("line.startValue")) {
        this.sendAction("changed", this.get("line"));
      }
      this.set("focusInElement", false);
    },
    focusIn: function focusIn() {
      if (this.get("readOnly")) {
        return;
      }
      this.setProperties({
        lastFocusElement: this.$(),
        focusInElement: true
      });
    },
    keyDown: function keyDown(e) {
      var keyCode = e.which || e.keyCode;
      if (keyCode === 188) {
        return e.preventDefault();
      }
    },
    actions: {
      changed: function changed(newValue) {
        this.set("line.accountId", newValue);
        this.sendAction("changed", this.get("line"));
      },
      collapse: function collapse() {
        this.set("line.isCollapse", !this.get("line.isCollapse"));
      },
      collapseInputClick: function collapseInputClick() {
        if (this.get("line.isCollapse")) {
          this.set("line.isCollapse", false);
        }
      }
    }
  });
});