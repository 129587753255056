define("budgeta/templates/components/edit-line-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8yjTdbz6",
    "block": "{\"statements\":[[\"block\",[\"modal-dialog\"],null,[[\"confirmDisabled\",\"close\",\"title\",\"templateName\",\"hideCancel\",\"hideFooter\",\"addClass\",\"lastStep\",\"confirmActionName\",\"confirm\"],[[\"get\",[\"confirmDisabled\"]],[\"get\",[\"close\"]],[\"get\",[\"title\"]],[\"get\",[\"templateName\"]],[\"get\",[\"hideCancel\"]],[\"get\",[\"hideFooter\"]],[\"get\",[\"addClass\"]],[\"get\",[\"lastStep\"]],[\"get\",[\"confirmActionName\"]],[\"get\",[\"confirm\"]]]],0]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/edit-line-modal.hbs"
    }
  });
});