define("budgeta/components/custom-amounts", ["exports", "@babel/runtime/helpers/esm/typeof", "budgeta/utils/const", "budgeta/utils/forecast", "budgeta/utils/budget-utils"], function (_exports, _typeof2, _const, _forecast, _budgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var updateData = function updateData(data, input) {
    if (data.length > 0) {
      input.focus();
      var value = data.shift().replace(/\((.*)\)/, "-$1").replace(/[^\d\.\-]/g, "");
      if (value === "-") {
        value = 0;
      }
      if (!isNaN(value * 1)) {
        input.val(value * 1);
      }
      input.trigger("keyPress");
      var next = input.closest(".custom-amount").next().find("input");
      next.focus();
      updateData(data, next);
    }
  };
  var labels = [];
  var customAmounts = Ember.Component.extend({
    cache: Ember.inject.service(),
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    classNameBindings: [":wrap", "removeCurrencyType:without-currency-type", "budget.isModelLine:model-line"],
    attributeBindings: ["data-hide", "data-key"],
    "data-key": Ember.computed.alias("key"),
    currency: "",
    budgetStart: undefined,
    budgetEnd: undefined,
    oldPeriod: undefined,
    enabled: Ember.computed("readOnly", function () {
      return !this.get("readOnly");
    }),
    labelAfter: Ember.computed("labelSuffix", "currency", function () {
      return this.get("currency") === "%" ? "%" : this.get("labelSuffix");
    }),
    showCurrency: function () {
      return !this.get("hideCurrencySelect") && (this.get("currency") || this.get("inheritedCurrency"));
    }.property("currency", "inheritedCurrency", "hideCurrencySelect"),
    init: function init() {
      this._super();
      this.set("elementId", "attribute-".concat(this.get("key"), "-").concat(Math.round(Math.random() * 999999)));
    },
    amountTypeAttr: Ember.computed("fieldData.refAmountType", function () {
      return this.get("fieldData.refAmountType") || "amountType";
    }),
    removeCurrencyType: Ember.computed("fieldData.displayTypeArg", function () {
      return this.get("fieldData.displayTypeArg") === "removeCurrencyType";
    }),
    labelSuffix: Ember.computed("fieldData.labelSuffix", function () {
      return this.get("fieldData.labelSuffix") || "";
    }),
    limitMonths: Ember.computed("fieldData.limitMonths", "budgetAttributes", "budgetAttributes.shiftByMonths", "budgetAttributes.workingFor", function () {
      return this.get("fieldData.limitMonths") && this.get("budgetAttributes.".concat(this.get("fieldData.limitMonths")));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _this = this,
        container = _this.$();
      //handle paste from excel
      container.on("paste", "input", function (e) {
        var data = e.originalEvent.clipboardData.getData("text/plain");
        var result = VerEx().tab().or().lineBreak().replace(data, "/\t").split("/\t");
        if (result.length > 1) {
          e.preventDefault();
          _this.set("pasting", true);
          _this.set("lockPreviewRefresh", true);
          updateData(result, Ember.$(this));
          _this.set("pasting", false);
          _this.set("lockPreviewRefresh", false);
          _this.sendAction("renderPreview");
        }
      });
      container.on("show", function () {
        _this.notifyPropertyChange("period"); // trigger recalc of labelsRange
        var defaultValue = Ember.merge({}, _this.get("defaultValue"));
        if (_this.get("budget") && defaultValue && Ember.$.map(container.find("input"), function (i) {
          return Ember.$(i).val();
        }).join("").length === 0) {
          var dateRange = _budgetUtils.default.getDateRange(_this.get("budget"), _this.get("budgetService.scenario"));
          var budgetStart = dateRange[0] ? moment(dateRange[0]).startOf("month") : moment().startOf("month"),
            budgetEnd = dateRange[1] ? moment(dateRange[1]).startOf("month") : budgetStart.clone().add(11, "months"),
            months = budgetEnd.diff(budgetStart, "months") + 1;
          for (var j = 0; j < months; j++) {
            var month = budgetStart.add(j > 0 ? 1 : 0, "months");
            var monthTag = month.format(_const.default.MomentMonthYearDateFormat).replace(/\//g, "");
            var valuePath = "budgetAttributes.".concat(_this.get("key"), ".m").concat(monthTag);
            if (Ember.isEmpty(_this.get("budgetAttributes." + _this.get("key")))) {
              _this.set("budgetAttributes." + _this.get("key"), {});
            }
            var periodLength = _this.get("period") === "q" ? 3 : _this.get("period") === "y" ? 12 : 1;
            var yearStart = _this.get("budget.root.yearStart") || 0;
            if (periodLength > 1 && month.month() % periodLength !== yearStart % periodLength) {
              _this.set(valuePath, null);
            } else if (j === 0 && (0, _typeof2.default)(defaultValue) !== "object") {
              _this.set(valuePath, defaultValue);
            }
          }
          if (labels[0] && (0, _typeof2.default)(defaultValue) !== "object") {
            labels[0].set("valuePath", defaultValue);
          }
          _this.set("defaultValue", null);
        }
      }).on("hide", function (e, didValueChange) {
        if (didValueChange) {
          var customAmounts = _this.get("budgetAttributes.".concat(_this.get("key")));
          if (_this.get("setAttributeOnHide") && customAmounts && Object.keys(customAmounts).length > 0 && customAmounts[Object.keys(customAmounts).sort()[0]] && (0, _typeof2.default)(customAmounts[Object.keys(customAmounts).sort()[0]]) !== "object") {
            _this.set("budgetAttributes." + _this.get("setAttributeOnHide"), customAmounts[Object.keys(customAmounts).sort()[0]]);
          }
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var container = this.$();
      if (container) {
        container.off("paste", "input").off("show hide");
      }
    },
    labelsRange: function () {
      var key = this.get("key");
      if (Ember.isEmpty(this.get("budget")) || Ember.isEmpty(this.get("budgetAttributes"))) {
        return [];
      }
      if (!this.get("ignoreAmountType") && !(this.get("budgetAttributes." + this.getWithDefault("amountTypeAttr", "amountType")) === 1 || this.get("budget.budgetType") === "assumption" && this.get("budgetAttributes." + this.getWithDefault("amountTypeAttr", "amountType")) === 3 || key === "rampUpAmounts")) {
        return [];
      }
      var months;
      if (this.get("limitMonths")) {
        var dateRange = _budgetUtils.default.getDateRange(this.get("budget"), this.get("budgetService.scenario"));
        var budgetStart = dateRange[0] ? moment(dateRange[0]).startOf("month") : moment().startOf("month");
        months = _forecast.default.getAmount(this.get("limitMonths"), this.get("budget"), budgetStart, this.get("store"), {}, 0);
      }
      var periodValues = _budgetUtils.default.calcPeriodValues({
        key: key,
        period: this.get("period"),
        budget: this.get("budget"),
        months: months,
        budgetAttributes: this.get("budgetAttributes")
      });
      labels = periodValues.map(function (obj) {
        return Item.create(obj);
      });
      return labels;
    }.property("budget", "period", "budgetAttributes.startDate", "budgetAttributes.endDate", "budgetAttributes", "budgetService.scenario", "limitMonths", "budgetService.selectedRoot.absoluteRoot.yearStart", "budget.triggerCustomAmountsChange"),
    focusIn: function focusIn(e) {
      if (this.get("pasting") || this.get("readOnly")) {
        return true;
      }
      var ele = Ember.$(e.target),
        brothers = ele.closest("[data-key='customAmounts']").find(".currency input"),
        index = brothers.index(ele),
        realValue = ele.val();
      realValue = Ember.isEmpty(realValue) ? "" : typeof realValue === "string" && realValue.charAt(0) === "=" ? realValue : _const.default.formatStringNumber(realValue);
      if (realValue === "" && index > 0) {
        var lastMonth = Ember.$(brothers[index - 1]).closest("[data-month]").data("month") + "";
        var thisMonth = ele.closest("[data-month]").data("month") + "";
        var lastMonthItem = this.get("labelsRange").findBy("monthTag", lastMonth);
        var thisMonthItem = this.get("labelsRange").findBy("monthTag", thisMonth);
        if (lastMonthItem && thisMonthItem && lastMonthItem.get("valuePath")) {
          thisMonthItem.set("valuePath", lastMonthItem.get("valuePath"));
          ele.trigger("change");
          // select the current input text
          Ember.run.scheduleOnce("afterRender", function () {
            ele.select();
          });
        }
      }
    },
    actions: {
      openFormulaEditor: function openFormulaEditor(component) {
        this.sendAction("openFormulaEditor", component);
      }
    }
  });
  var Item = Ember.Object.extend({
    label: null,
    monthTag: null,
    month: null,
    key: null,
    index: 0,
    budgetAttributes: null,
    cloneValue: true,
    valuePath: Ember.computed("key", "monthTag", "budgetAttributes", {
      get: function get() {
        var returnVal = this.get("budgetAttributes.".concat(this.get("key"), ".m").concat(this.get("monthTag")));
        if (this.get("index") !== 0 && returnVal !== labels[0].get("valuePath")) {
          this.set("cloneValue", false);
        }
        return returnVal;
      },
      set: function set(key, val) {
        if (!this.get("budgetAttributes.".concat(this.get("key")))) {
          this.set("budgetAttributes.".concat(this.get("key")), {});
        }
        this.set("budgetAttributes.".concat(this.get("key"), ".m").concat(this.get("monthTag")), val);
        this.notifyPropertyChange("budgetAttributes");
        var returnVal = this.get("budgetAttributes.".concat(this.get("key"), ".m").concat(this.get("monthTag")));
        if (this.get("index") !== 0 && returnVal !== labels[0].get("valuePath")) {
          this.set("cloneValue", false);
        }
        this.get("budget").notifyPropertyChange("budgetAttributes.".concat(this.get("key")));
        this.get("budget").notifyPropertyChange("budgetAttributesWithScenarios.".concat(this.get("key")));
        return returnVal;
      }
    })
  });
  var _default = _exports.default = customAmounts;
});