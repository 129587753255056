define("budgeta/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.transitionTo("main");
    },
    viewSelections: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    activate: function activate() {
      this.set("viewSelections.maximized", !this.get("budgetService.selectedBudget"));
    },
    deactivate: function deactivate() {
      this.set("viewSelections.maximized", false);
    }
  });
});