define("budgeta/templates/components/svg/profile-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EhmFRgWb",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"20\"],[\"static-attr\",\"height\",\"20\"],[\"static-attr\",\"viewBox\",\"0 0 20 20\"],[\"static-attr\",\"fill\",\"none\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M4 12.5C2.9 12.5 2 13.4 2 14.5V15.3C2 17 2.9 18.4 4.7 19.3C6.1 20.1 8 20.5 10 20.5C13.9 20.5 18 18.9 18 15.3V14.5C18 13.4 17.1 12.5 16 12.5H4ZM10 10.5C12.7614 10.5 15 8.26142 15 5.5C15 2.73858 12.7614 0.5 10 0.5C7.23858 0.5 5 2.73858 5 5.5C5 8.26142 7.23858 10.5 10 10.5Z\"],[\"static-attr\",\"fill\",\"black\"],[\"static-attr\",\"fill-opacity\",\"0.65\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/profile-svg.hbs"
    }
  });
});