define("budgeta/components/import-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ["isGrayedDisabled"],
    isSelected: Ember.computed("value", "mapping.@each.hide", function () {
      var col = this.get("mapping").findBy("key", this.get("value.headerKey"));
      return col && !Ember.get(col, "hide");
    }),
    isPastDate: Ember.computed("value", "mapping.@each.isPastDate", function () {
      var col = this.get("mapping").findBy("key", this.get("value.headerKey"));
      return col && Ember.get(col, "isPastDate");
    })
  });
});