define("budgeta/utils/budget-fixtures", ["exports", "budgeta/services/ember-service-container", "budgeta/models/budget"], function (_exports, _emberServiceContainer, _budget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var BudgetFixtures = {
    createFixtures: function createFixtures() {
      _budget.default.reopenClass({
        FIXTURES: [{
          id: 1,
          __v: null,
          name: intl.t("general.name_1695611604392"),
          readOnly: false,
          isRoot: false,
          flagged: true,
          budgetType: "employee",
          startDate: null,
          shares: [{
            user: {
              _id: "560d41927dda5c720e9fecb7",
              email: "liad@budgeta.com",
              firstName: "Liad",
              lastName: "Local"
            },
            access: "owner",
            isRoot: false,
            _id: "5774ceade770cead1770da03",
            exclude: [],
            dashboardPermissions: []
          }],
          sharingTokens: [],
          baselineData: {},
          reports: {},
          closedActuals: [],
          actuals: {},
          forecast: {},
          importMapping: null,
          cashBalance: null,
          cashBalanceByCurrency: [],
          fx: [],
          fxCurrencies: ["USD", "EUR", "ILS"],
          fxBase: null,
          yearStart: null,
          dashboardOrder: [{
            _id: "576109e581b6d41400673268"
          }],
          dashboardList: [{
            _id: "57d6a22322e24b3c4190a7e9",
            fixAssumptions: []
          }],
          budgetAttributes: {
            startDate: "2016-01-31T22:00:00.000Z",
            endDate: "2016-10-31T22:00:00.000Z",
            currency: null,
            costCenter: null,
            departmentAllocationPerCapita: null,
            departmentPerCapitaType: "employee",
            region: "usa",
            product: null,
            category: null,
            revenueType: null,
            notes: null,
            shift: null,
            multiplyBy: null,
            amountType: null,
            amount: null,
            customAmounts: null,
            role: "Employee1",
            employeeId: "159",
            base: "=<p><span class='null'>Base Salary renamed</span>+<span class='null'>Sales salary renamed</span>+<span class='null'>Model 1 Assumption2</span></p>",
            term: "m",
            bonus: null,
            bonusIsFixed: null,
            bonusPaymentAfter: null,
            benefits: null,
            yearlyVacationDays: "",
            avgAccruedVacation: null,
            maxAccruedVacation: null,
            yearlyIncrease: "",
            yearlyIncreaseOn: 1,
            paymentAfter: null,
            benefitsByGeo: [{
              geography: null,
              value: null,
              _id: "576109e581b6d41400673266"
            }],
            dashboardLines: [],
            assumptions: [],
            shortTermPeriod: 12,
            openingBalances: [],
            lookupData: [],
            bonusPeriod: null,
            amountChanges: [],
            department: [{
              name: "",
              allocation: 100,
              _id: "576109e581b6d41400673267"
            }],
            budgetLine: [],
            filterDepartments: [],
            filterRoles: [],
            filterProduct: [],
            filterRegion: []
          },
          currentAttributes: {},
          expenseCategories: [null],
          expenseCategoryOther: null,
          parent: "5774ceade770cead1770d9ed",
          scenarios: [],
          comments: [],
          transactions: [],
          changes: [],
          children: [],
          versions: []
        }]
      });
    }
  };
  var _default = _exports.default = BudgetFixtures;
});