define("budgeta/components/hide-filter-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    filterVisibility: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    isNonProfitBudget: Ember.computed.alias("budgetService.isNonProfitBudget"),
    classNames: ["visibility-filter"],
    recalcFilter: true,
    triggerId: Ember.computed("elementId", function () {
      return this.get("elementId") + "-trigger";
    }),
    budgetVisibility: Ember.computed.alias("filterVisibility.budgetVisibility"),
    budgetVisibilityName: Ember.computed("budgetVisibility", function () {
      return this.get("budgetVisibility") ? "visibility_on" : "visibility_cross";
    }),
    periodVisibility: Ember.computed.alias("filterVisibility.periodVisibility"),
    periodVisibilityName: Ember.computed("periodVisibility", function () {
      return this.get("periodVisibility") ? "visibility_on" : "visibility_cross";
    }),
    currencyVisibility: Ember.computed.alias("filterVisibility.currencyVisibility"),
    currencyVisibilityName: Ember.computed("currencyVisibility", function () {
      return this.get("currencyVisibility") ? "visibility_on" : "visibility_cross";
    }),
    numbersVisibility: Ember.computed.alias("filterVisibility.numbersVisibility"),
    numbersVisibilityName: Ember.computed("numbersVisibility", function () {
      return this.get("numbersVisibility") ? "visibility_on" : "visibility_cross";
    }),
    compareToVersionVisibility: Ember.computed.alias("filterVisibility.compareToVersionVisibility"),
    compareToVersionVisibilityName: Ember.computed("compareToVersionVisibility", function () {
      return this.get("compareToVersionVisibility") ? "visibility_on" : "visibility_cross";
    }),
    versionNameVisibility: Ember.computed.alias("filterVisibility.versionNameVisibility"),
    versionNameVisibilityName: Ember.computed("versionNameVisibility", function () {
      return this.get("versionNameVisibility") ? "visibility_on" : "visibility_cross";
    }),
    bookingsTypeVisibility: Ember.computed.alias("filterVisibility.bookingsTypeVisibility"),
    bookingsTypeVisibilityName: Ember.computed("bookingsTypeVisibility", function () {
      return this.get("bookingsTypeVisibility") ? "visibility_on" : "visibility_cross";
    }),
    headcountTypeVisibility: Ember.computed.alias("filterVisibility.headcountTypeVisibility"),
    headcountTypeVisibilityName: Ember.computed("headcountTypeVisibility", function () {
      return this.get("headcountTypeVisibility") ? "visibility_on" : "visibility_cross";
    }),
    headcountDetailsVisibility: Ember.computed.alias("filterVisibility.headcountDetailsVisibility"),
    headcountDetailsVisibilityName: Ember.computed("headcountDetailsVisibility", function () {
      return this.get("headcountDetailsVisibility") ? "visibility_on" : "visibility_cross";
    }),
    rolesVisibility: Ember.computed.alias("filterVisibility.rolesVisibility"),
    rolesVisibilityName: Ember.computed("rolesVisibility", function () {
      return this.get("rolesVisibility") ? "visibility_on" : "visibility_cross";
    }),
    tagsVisibility: Ember.computed.alias("filterVisibility.tagsVisibility"),
    tagsVisibilityName: Ember.computed("tagsVisibility", function () {
      return this.get("tagsVisibility") ? "visibility_on" : "visibility_cross";
    }),
    accountsVisibility: Ember.computed.alias("filterVisibility.accountsVisibility"),
    accountsVisibilityName: Ember.computed("accountsVisibility", function () {
      return this.get("accountsVisibility") ? "visibility_on" : "visibility_cross";
    }),
    statAccountsVisibility: Ember.computed.alias("filterVisibility.statAccountsVisibility"),
    statAccountsVisibilityName: Ember.computed("statAccountsVisibility", function () {
      return this.get("statAccountsVisibility") ? "visibility_on" : "visibility_cross";
    }),
    iconName: Ember.computed("hasHidden", function () {
      return this.get("hasHidden") ? "visibility_off" : "visibility_on";
    }),
    hasHidden: Ember.computed("budgetVisibility", "periodVisibility", "currencyVisibility", "numbersVisibility", "compareToVersionVisibility", "versionNameVisibility", "bookingsTypeVisibility", "headcountTypeVisibility", "headcountDetailsVisibility", "rolesVisibility", "tagsVisibility", "accountsVisibility", "statAccountsVisibility", "isTableView", "employeesEdit", "isMetricsView", "showPeriod", "isSheetsView", "isHeadcountView", "showCurrency", "showScale", "isBookingsView", "isBalanceSheetView", "hc", "headcountViewOption", "hasRoles", "budgetService.hideEmployees", "hasTags", "hasAccounts", "hasStatAccounts", "isCategory", "isDashboardView", "recalcFilter", function () {
      var _this = this;
      var dimensions = this.get("viewSelections.dimensions").mapBy("dimension").filter(function (d) {
        return Ember.get(d, "values.length");
      });
      if (this.get("isTableView")) {
        if (this.get("employeesEdit")) {
          if (!this.get("headcountTypeVisibility")) {
            return true;
          }
          return dimensions.filterBy("isCategory", false).findBy("isVisibleInToolbar", false);
        } else {
          return dimensions.findBy("isVisibleInToolbar", false);
        }
        return;
      }
      if (this.get("isMetricsView")) {
        if (this.get("showPeriod") && !this.get("periodVisibility")) {
          return true;
        }
        if (dimensions.findBy("isVisibleInToolbar", false)) {
          return true;
        }
        if (this.get("hasStatAccounts") && !this.get("statAccountsVisibility")) {
          return true;
        }
        return;
      }
      if (this.get("isSheetsView")) {
        if (this.get("isHeadcountView") && !this.get("headcountDetailsVisibility")) {
          return true;
        }
        if (this.get("showPeriod") && !this.get("periodVisibility")) {
          return true;
        }
        if (this.get("showCurrency") && !this.get("currencyVisibility")) {
          return true;
        }
        if (this.get("showScale") && !this.get("numbersVisibility")) {
          return true;
        }
        if (this.get("isBookingsView") && !this.get("bookingsTypeVisibility")) {
          return true;
        }
        if (this.get("isHeadcountView") && !this.get("headcountTypeVisibility")) {
          return true;
        }
        if (!this.get("isBalanceSheetView")) {
          var found;
          dimensions.forEach(function (dimension) {
            var isDimensionVisable = dimension.get("isVisibleInToolbar");
            if (dimension.get("isCategory")) {
              if (_this.get("isBookingsView")) {
                if (!isDimensionVisable) {
                  found = true;
                }
              } else if (_this.get("isHeadcountView")) {
                if (_this.get("headcountViewOption") && !isDimensionVisable) {
                  found = true;
                }
              } else if (!isDimensionVisable) {
                found = true;
              }
            } else if (!isDimensionVisable) {
              found = true;
            }
          });
          if (found) {
            return true;
          }
          if (this.get("hc") && this.get("hasRoles") && !this.get("budgetService.hideEmployees") && !this.get("rolesVisibility")) {
            return true;
          }
          if (this.get("hasTags") && !this.get("tagsVisibility")) {
            return true;
          }
          if (this.get("hasAccounts") && !this.get("accountsVisibility")) {
            return true;
          }
        }
      }
      if (this.get("isDashboardView")) {
        return dimensions.findBy("isVisibleInToolbar", false);
      }
    }),
    actions: {
      changeVisibility: function changeVisibility(type) {
        switch (type) {
          case "headcountTypeVisibility":
            this.set("headcountType", "all");
            break;
          case "rolesVisibility":
            this.set("roll", "");
            break;
          case "bookingsTypeVisibility":
            this.set("bookingType", "all");
            break;
          case "tagsVisibility":
            this.set("selectedTag", "");
            break;
          case "accountsVisibility":
            this.set("selectedAccount", "");
            break;
          case "statAccountsVisibility":
            this.set("selectedStatAccount", "");
            break;
        }
        this.set(type, !this.get(type));
        if (this.get("onOptionSelecting")) {
          this.sendAction(this.get("onOptionSelecting"));
        }
      },
      changeDimensionVisibility: function changeDimensionVisibility(dimension) {
        var dimensionSelection = this.get("viewSelections.dimensions").findBy("dimension.uniqueId", dimension.get("uniqueId"));
        if (dimensionSelection && Ember.get(dimensionSelection, "selectedValue") !== "all") {
          Ember.set(dimensionSelection, "selectedValue", "all");
        }
        dimension.set("isVisibleInToolbar", !dimension.get("isVisibleInToolbar"));
        this.notifyPropertyChange("recalcFilter");
        if (this.get("onOptionSelecting")) {
          this.sendAction(this.get("onOptionSelecting"));
        }
      }
    }
  });
});