define("budgeta/store/reducers/index", ["exports", "budgeta/store/reducers/stepsReducer", "budgeta/store/reducers/budgetReducer", "budgeta/store/reducers/fileReducer", "budgeta/admin/store/reducers/notifReducer"], function (_exports, _stepsReducer, _budgetReducer, _fileReducer, _notifReducer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = window.Redux.combineReducers({
    stepsStore: _stepsReducer.default,
    budgetStore: _budgetReducer.default,
    fileStore: _fileReducer.default,
    notifStore: _notifReducer.default
  });
});