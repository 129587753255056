define("budgeta/components/forecast-row-total", ["exports", "budgeta/utils/forecast"], function (_exports, _forecast) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    classNameBindings: [":total-column", ":group", "isSelected:selected", "data.outOfRange:out-of-range", "data.budget.isBold:bold-line-display", "data.budget.isTurquoise:turquoise-line-display", "data.budget.isOlive:olive-line-display", "data.budget.isOrange:orange-line-display", "data.budget.isPurple:purple-line-display", "data.budget.isPink:pink-line-display", "isDisplayGroupBold:group-bold"],
    attributeBindings: ["data-level", "data-id", "data-type", "data-hook"],
    "data-hook": "forecast-row-total",
    "data-level": Ember.computed.alias("data.level"),
    "data-id": Ember.computed.alias("data.id"),
    "data-type": Ember.computed.alias("data.type"),
    isSelected: Ember.computed("selectedBudget", function () {
      return this.get("data.id") === this.get("selectedBudget.id");
    }),
    nextLevel: Ember.computed("data.level", function () {
      return this.get("data.level") + 1;
    }),
    editMode: Ember.computed("mode", "actuals", function () {
      return this.get("mode") === "e" && this.get("actuals");
    }),
    showBudget: Ember.computed("showColumns.@each.show", function () {
      return Ember.get(this.get("showColumns").findBy("id", "b"), "show");
    }),
    showActual: Ember.computed("showColumns.@each.show", function () {
      return Ember.get(this.get("showColumns").findBy("id", "a"), "show");
    }),
    showDiff: Ember.computed("showColumns.@each.show", function () {
      return Ember.get(this.get("showColumns").findBy("id", "d"), "show");
    }),
    isPercent: Ember.computed("currency", "data.isPercent", "data.budget.budgetType", function () {
      return this.get("data.isPercent") || this.get("data.budget.budgetType") === "assumption" && this.get("currency") === "%";
    }),
    isCount: Ember.computed("data.isCount", "currency", "data.budget.budgetType", function () {
      return this.get("data.isCount") || this.get("data.budget.budgetType") === "assumption" && this.get("currency") === "#";
    }),
    isDisplayGroupBold: Ember.computed("viewType", "viewSelections.isPnLView", "viewSelections.isSheetsView", "data.level", function () {
      return this.get("viewSelections.isPnLView") && this.get("viewSelections.isSheetsView") || this.get("viewType") === "bs" ? this.get("data.level") === 1 : this.get("data.budget.isCompany") || this.get("data.budget.isBudgetGrouping");
    }),
    precision: Ember.computed("isPercent", "totalDesimalNumber", "data.precision", function () {
      return Ember.isEmpty(this.get("data.precision")) ? this.get("isPercent") ? 2 : this.get("totalDesimalNumber") : this.get("data.precision");
    }),
    totalForBVA: Ember.computed("data.totalForBVA", "data.total", "actualsTotalType", function () {
      return this.get("actualsTotalType") === "a" ? this.get("data.totalForBVA") : this.get("data.total");
    }),
    currency: Ember.computed("data.budget.budgetAttributes.pctCurrency", "data.budget.budgetType", "data.isCount", "data.isPercent", "data.values.firstObject.currency", "scenario", "vsOption", "sideBySide", function () {
      return _forecast.default.getForecastRowCurrency(this.get("store"), this.get("cache"), this.get("data.budget"), this.get("scenario"), this.get("sideBySide"), this.get("data.budget.budgetType") === "assumption" ? false : this.get("data.isCount"), this.get("data.budget.budgetType") === "assumption" ? false : this.get("data.isPercent"), this.get("data.values.firstObject.currency"), this.get("vsOption"));
    }),
    inCompareCurrency: Ember.computed("data.budget.budgetType", "scenario", "currency", function () {
      if (this.get("data.budget.budgetType") === "assumption") {
        return _forecast.default.getModelCurrency(this.get("store"), this.get("data.budget"), this.get("scenario"));
      } else {
        return this.get("currency");
      }
    })
  });
});