define("budgeta/templates/components/attribute-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dDTRuWt4",
    "block": "{\"statements\":[[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"fieldData\",\"tipId\"]]],null,0],[\"append\",[\"helper\",[\"currency-input\"],null,[[\"allowNegative\",\"openFormulaEditor\",\"assumptions\",\"assumptionLines\",\"inGrid\",\"currencyOptions\",\"currency\",\"inheritedCurrency\",\"readonly\",\"type\",\"class\",\"name\",\"value\",\"placeholder\"],[[\"get\",[\"budgetModule\",\"allowNegative\"]],\"openFormulaEditor\",[\"get\",[\"assumptions\"]],[\"get\",[\"assumptionLines\"]],[\"get\",[\"inGrid\"]],[\"get\",[\"currencyOptions\"]],[\"get\",[\"budgetAttributes\",\"currency\"]],[\"get\",[\"placeHolderAttributes\",\"currency\"]],[\"get\",[\"readOnly\"]],\"text\",[\"get\",[\"fieldData\",\"displayType\"]],[\"get\",[\"key\"]],[\"get\",[\"value\"]],[\"get\",[\"placeHolder\"]]]]],false],[\"text\",\"\\n\"],[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"label-after\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"fieldData\",\"labelAfter\"]],false],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"help-iq\"],null,[[\"type\",\"docId\"],[\"tip\",[\"get\",[\"fieldData\",\"tipId\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/attribute-currency.hbs"
    }
  });
});