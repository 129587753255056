define("budgeta/templates/components/svg/copy-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h4yAmbgF",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"id\",\"Vector\"],[\"static-attr\",\"d\",\"M2.39999 2.9L2.39999 12.5C2.39999 13.86 3.43999 14.9 4.79999 14.9L11.2 14.9C11.2 15.78 10.48 16.5 9.59999 16.5L3.99999 16.5C2.23999 16.5 0.799988 15.06 0.799988 13.3L0.799988 4.5C0.799988 3.62 1.51999 2.9 2.39999 2.9ZM12.0964 0.5C13.3674 0.5 14.3206 1.46 14.4 2.74L14.4 11.86C14.4 13.14 13.3674 14.1 12.1759 14.1L5.4241 14.1C4.15318 14.1 3.19999 13.14 3.19999 11.94L3.19999 2.74C3.19999 1.46 4.15318 0.5 5.4241 0.5L12.0964 0.5Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/copy-svg.hbs"
    }
  });
});