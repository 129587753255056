define("budgeta/generic-file/components/modify-budget-structure-intro/modify-budget-structure-intro", ["exports", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "budgeta/services/ember-service-container", "react"], function (_exports, _uiComponents, _reactRedux, _stepsActions, _budgetActions, _emberServiceContainer, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = modifyBudgetStructureIntro;
  /* eslint-disable react-hooks/rules-of-hooks */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  function modifyBudgetStructureIntro() {
    var dispatch = _reactRedux.default.useDispatch();
    var options = {
      hideClose: true,
      height: "405px"
    };
    var prefix = _react.default.createElement(_uiComponents.default.IMAGES.SettingsSvg, null);
    var header = {
      text: intl.t("generic-file.step-modify-budget-structure-intro.header"),
      options: {
        prefix: prefix,
        prefixClass: "modify-budget-structure-intro-header-container title-next-to-prefix",
        titleClass: "title",
        hideSeperator: true
      }
    };
    var body = _react.default.createElement("div", {
      className: "modify-budget-structure-intro-body-container padding-intro-body recomended-bar"
    }, _react.default.createElement("div", {
      className: "description description-left"
    }, intl.t("generic-file.step-modify-budget-structure-intro.description")), _react.default.createElement("div", {
      className: "recommended-label"
    }, _react.default.createElement(_uiComponents.default.IMAGES.ThumbsUpSvg, null), _react.default.createElement("span", null, intl.t("generic-file.step-modify-budget-structure-intro.recommended-label"))));
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button",
      dataHook: "footer-tertiary-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        return dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back big-button with-border",
      dataHook: "footer-secondary-button",
      text: intl.t("generic-file.no"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.SKIPPED_BUDGET_STRUCTURE);
        dispatch(_budgetActions.default.setBudgetStructureSkip(true));
        dispatch(_stepsActions.default.nextStep({
          continue: false
        }));
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.yes"),
      onClick: function onClick() {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CHOOSED_BUDGET_STRUCTURE);
        dispatch(_budgetActions.default.setBudgetStructureSkip(false));
        dispatch(_stepsActions.default.nextStep({
          continue: true
        }));
      }
    }));
    var footer = {
      buttons: buttons
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        header: header,
        footer: footer,
        body: body
      },
      options: options
    });
  }
});