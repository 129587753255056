define("budgeta/routes/budget-info/accept", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "budgeta/mixins/budgeta-route", "budgeta/config/environment"], function (_exports, _authenticatedRouteMixin, _budgetaRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _budgetaRoute.default, {
    budgetService: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      var budgetInfoToken = transition.params["budget-info"].token;
      if (budgetInfoToken) {
        // /budget-info/[rootId]_[linkID]_[versionId]
        var infoValue = budgetInfoToken.split("_");
        var _this = this;
        if (infoValue[2] && infoValue[2] === "0") {
          // exiting from versions

          // exit from budget version info
          Ember.$.cookie("selectedRootNew", infoValue[0], {
            expires: 365,
            path: "/"
          });
          var linkTo = infoValue.slice(3);
          _this.set("budgetService.budgetInfoId", infoValue[1]);
          _this.set("budgetService.budgetInfoLinkTo", linkTo);
          _this.set("budgetService.hideBudgetInfoMassage", true);
          setTimeout(function () {
            _this.transitionTo("main.budget.index", infoValue[0], {
              // we need to get the budget id for the working budget, currently we are moving to the root budget
              // find budget line name
              queryParams: {
                version: ""
              }
            });
            Ember.run.next(function () {
              _this.set("budgetService.budgetInfoToken", undefined);
            });
          }, 2000);
          return;
        }

        // trying to move to version
        Ember.$.ajax("".concat(_environment.default.serverAddress, "/").concat(_environment.default.apiPath, "/budgets/getBudgetInfo/").concat(infoValue[0], "/").concat(infoValue[1], "/").concat(infoValue[2]), {
          method: "GET",
          data: {},
          success: function success(data) {
            var budgetLineId = data.budgetLine;
            var rootId = data.root;
            var versionId = infoValue[2] || "";
            var isPartial = data.isPartial;
            var linkTo = Array.isArray(data.linkTo) ? data.linkTo : [data.linkTo];
            if (rootId !== infoValue[0] && isPartial) {
              // if partial share
              versionId = "";
              _this.set("budgetService.isPartialInfo", true);
            }
            Ember.$.cookie("selectedRootNew", rootId, {
              expires: 365,
              path: "/"
            });
            _this.set("budgetService.budgetInfoId", infoValue[1]);
            _this.set("budgetService.budgetInfoLinkTo", linkTo);
            setTimeout(function () {
              _this.transitionTo("main.budget.index", budgetLineId, {
                queryParams: {
                  version: versionId
                }
              });
              Ember.run.next(function () {
                _this.set("budgetService.budgetInfoToken", undefined);
              });
            }, 2000);
          },
          error: function error() {
            _this.set("budgetService.budgetInfoId", infoValue[1]);
            _this.set("budgetService.budgetInfoLinkTo", [infoValue[1]]);
            _this.transitionTo("model-not-found");
            Ember.run.next(function () {
              _this.set("budgetService.budgetInfoToken", undefined);
            });
          }
        });
      }
    },
    model: function model() {}
  });
});