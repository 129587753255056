define("budgeta/components/breadcrumbs-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    treeList: Ember.computed("selectedBudget", {
      get: function get() {
        var result = [];
        this.getTreeList(result, this.get("selectedBudget"));
        if (result.length > 1) {
          result.shift();
        }
        return result;
      }
    }),
    disableCurrentClick: Ember.computed("isCurrentClickable", function () {
      return !this.get("isCurrentClickable");
    }),
    disableElementsClick: Ember.computed("isEditModelFlyoutOpen", function () {
      return this.get("isEditModelFlyoutOpen");
    }),
    getTreeList: function getTreeList(list, budget) {
      if (list && budget) {
        if (!(budget.get("isRoot") && budget.get("root.id") !== budget.get("absoluteRoot.id"))) {
          if (budget.get("isRoot")) {
            return;
          }
          if (budget.get("parent")) {
            list.unshift(budget.get("parent"));
            this.getTreeList(list, budget.get("parent"));
          }
        }
      }
    },
    actions: {
      onCurrentElementClick: function onCurrentElementClick() {
        this.get("onCurrentClick") && this.get("onCurrentClick")();
      }
    }
  });
});