define("budgeta/components/intacct-budget-structure", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/config/environment", "ember-inflector"], function (_exports, _emberServiceContainer, _const, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    classNameBindings: [":intacct-budget-structure", "firstLevel:has-first-level"],
    columnsLayout: [100],
    budgetService: Ember.inject.service(),
    selectedRootName: Ember.computed.alias("budgetService.selectedRoot.name"),
    structureFirstLevel: Ember.computed.alias("data.structure.firstLevel"),
    structureRevenueLevel: Ember.computed.alias("data.structure.revenueLevel"),
    structureCosLevel: Ember.computed.alias("data.structure.cosLevel"),
    structureOpexLevel: Ember.computed.alias("data.structure.opexLevel"),
    structureOtherLevel: Ember.computed.alias("data.structure.otherLevel"),
    firstLevel: Ember.computed("structureFirstLevel.levels.[]", "structureFirstLevel.levels.length", {
      get: function get() {
        return this.get("structureFirstLevel.levels")[0];
      },
      set: function set(key, value) {
        return value;
      }
    }),
    structure: Ember.computed.alias("data.structure"),
    showAllLines: Ember.computed("permissions.isIntacctQAUser", "config.INTACCT_DRAG_AND_DROP_VISIBLE", "config.budgetaEnvironment", function () {
      return this.get("permissions.isIntacctQAUser") && _environment.default.INTACCT_DRAG_AND_DROP_VISIBLE && (_environment.default.environment === "staging" || _environment.default.environment === "development" || _environment.default.budgetaEnvironment === "qa" || _environment.default.budgetaEnvironment === "dev");
    }),
    inDragMode: Ember.computed("showAllLines", function () {
      return this.get("showAllLines");
    }),
    numTopLevelsClass: Ember.computed("structureFirstLevel.levels.length", "inDragMode", function () {
      var structureFirstLevel = this.get("structureFirstLevel.levels") ? this.get("structureFirstLevel.levels") : [];
      if (this.get("inDragMode")) {
        var draggedDimension = this.get("inDragMode");
        if (structureFirstLevel.length + this.get("structureRevenueLevel.levels.length") === _const.default.createBudgetLevelLimit || structureFirstLevel.length + this.get("structureCosLevel.levels.length") === _const.default.createBudgetLevelLimit || structureFirstLevel.length + this.get("structureOpexLevel.levels.length") === _const.default.createBudgetLevelLimit || structureFirstLevel.length + this.get("structureOtherLevel.levels.length") === _const.default.createBudgetLevelLimit) {
          return "num-top-levels-".concat(structureFirstLevel.length - 1);
        }
        var isSub = Ember.get(draggedDimension, "isSub");
        var dimensionName = Ember.get(draggedDimension, "dimension.dimension");
        for (var i = 0; i < structureFirstLevel.length; i++) {
          var dimLevel = structureFirstLevel[i];
          if (dimLevel.dimension.dimension == dimensionName) {
            if (dimLevel === draggedDimension || !isSub && dimLevel.isSub || isSub && draggedDimension.subLevel < dimLevel.subLevel) {
              return "num-top-levels-".concat(structureFirstLevel.length - 1);
            }
          }
        }
        if (isSub) {
          var structureGroups = ["structureRevenueLevel", "structureCosLevel", "structureOpexLevel", "structureOtherLevel"];
          for (var _i = 0; _i < structureGroups.length; _i++) {
            var findLevel = (this.get("".concat(structureGroups[_i], ".levels")) || []).find(function (level) {
              return level.dimension.dimension === dimensionName && level.isSub;
            });
            if (findLevel && findLevel.subLevel <= draggedDimension.subLevel) {
              return "num-top-levels-".concat(structureFirstLevel.length - 1);
            }
          }
        }
      }
      return "num-top-levels-".concat(structureFirstLevel.length);
    }),
    addHighLevelValuesWithNoSubValues: function addHighLevelValuesWithNoSubValues(addToValues, allValues, level) {
      var levelNumber = level.isSub ? level.subLevel - 1 : 0;
      var values = addToValues.slice(0);
      for (var i = levelNumber; i >= 0; i--) {
        allValues.filterBy("level", i).forEach(function (firstLevel) {
          if (!values.findBy("PARENTID", firstLevel.ID)) {
            addToValues.push(firstLevel);
          }
          values.push(firstLevel);
        });
      }
    },
    isDimAndSubDim: function isDimAndSubDim(dim, subDim) {
      if (!subDim.length || !dim.length) {
        return false;
      }
      for (var i = 0; i < subDim.length; i++) {
        if (subDim[i].level && subDim[i].PARENTID && dim.findBy("ID", subDim[i].PARENTID)) {
          return true;
        }
      }
      return false;
    },
    sumsNumberOfLinesForLevel: function sumsNumberOfLinesForLevel(leafLevelValues, allValues, numberOfFirstLevelLines, accounts) {
      var numberOfAccounts = accounts.length || 1;
      numberOfAccounts = numberOfAccounts * (leafLevelValues || 1); // leaf level values

      return (1 + numberOfAccounts +
      // num of accounts
      allValues) * (
      // num of values
      numberOfFirstLevelLines || 1);
    },
    numberOfFirstLevelLines: Ember.computed("structureFirstLevel.levels.[]", "structureFirstLevel.levels.length", "data.accounts", function () {
      return this.calcLeafGroupLines(this.get("structureFirstLevel.levels"));
    }),
    calcLeafGroupLines: function calcLeafGroupLines(group) {
      var _this = this;
      var totalValues = [];
      var map = {};
      (group || []).forEach(function (level) {
        if (level.dimension && level.dimension.dimension) {
          map[level.dimension.dimension] = level;
        }
      });
      Object.values(map).forEach(function (level) {
        var values = (Ember.get(level, "dimension.values") || Ember.A([])).filterBy("level", Ember.get(level, "subLevel") ? Ember.get(level, "subLevel") : 0);
        if (Ember.get(level, "isSub")) {
          _this.addHighLevelValuesWithNoSubValues(values, Ember.get(level, "dimension.values"), level);
        }
        totalValues = totalValues.concat(values); // nodes per accounts
      });
      return totalValues.length;
    },
    calcAllGroupLines: function calcAllGroupLines(group) {
      var _this2 = this;
      var totalValues = [];
      (group || []).forEach(function (level) {
        var values = (Ember.get(level, "dimension.values") || Ember.A([])).filterBy("level", Ember.get(level, "subLevel") ? Ember.get(level, "subLevel") : 0);
        if (Ember.get(level, "isSub")) {
          _this2.addHighLevelValuesWithNoSubValues(values, Ember.get(level, "dimension.values"), level);
        }
        totalValues = totalValues.concat(values);
      });
      return totalValues.length;
    },
    mergeAccounts: function mergeAccounts(accounts) {
      var mergeAccounts = accounts.filter(function (account) {
        return account.groupName;
      });
      accounts.removeObjects(mergeAccounts);
      var mergeNames = {};
      mergeAccounts.forEach(function (account) {
        if (!mergeNames[account.groupName]) {
          mergeNames[account.groupName] = account;
          accounts.push(account);
        }
      });
      return accounts;
    },
    numberOfRevenueLines: Ember.computed("numberOfFirstLevelLines", "structureRevenueLevel.levels.[]", "structureRevenueLevel.levels.length", "data.accounts", function () {
      var numberOfFirstLevelLines = this.get("numberOfFirstLevelLines");
      var revAccounts = this.mergeAccounts((this.get("data.accounts") || Ember.A([])).filterBy("mapping", "rev"));
      return this.sumsNumberOfLinesForLevel(this.calcLeafGroupLines(this.get("structureRevenueLevel.levels")), this.calcAllGroupLines(this.get("structureRevenueLevel.levels")), numberOfFirstLevelLines, revAccounts);
    }),
    numberOfCosLines: Ember.computed("numberOfFirstLevelLines", "structureCosLevel.levels.[]", "structureCosLevel.levels.length", "data.accounts", function () {
      var numberOfFirstLevelLines = this.get("numberOfFirstLevelLines");
      var cogsAccounts = this.mergeAccounts((this.get("data.accounts") || Ember.A([])).filterBy("mapping", "cogs"));
      return this.sumsNumberOfLinesForLevel(this.calcLeafGroupLines(this.get("structureCosLevel.levels")), this.calcAllGroupLines(this.get("structureCosLevel.levels")), numberOfFirstLevelLines, cogsAccounts);
    }),
    numberOfOpexLines: Ember.computed("numberOfFirstLevelLines", "structureOpexLevel.levels.[]", "structureOpexLevel.levels.length", "data.accounts", "includeEmployees", "employees", function () {
      var numberOfFirstLevelLines = this.get("numberOfFirstLevelLines");
      var expAccounts = this.mergeAccounts((this.get("data.accounts") || Ember.A([])).filterBy("mapping", "exp"));
      var numberOfEmployees = this.get("includeEmployees") ? this.get("employees.length") || 0 : 0;
      return this.sumsNumberOfLinesForLevel(this.calcLeafGroupLines(this.get("structureOpexLevel.levels")), this.calcAllGroupLines(this.get("structureOpexLevel.levels")), numberOfFirstLevelLines, expAccounts) + numberOfEmployees;
    }),
    numberOfOtherLines: Ember.computed("numberOfFirstLevelLines", "structureOtherLevel.levels.[]", "structureOtherLevel.levels.length", "data.accounts", function () {
      var numberOfFirstLevelLines = this.get("numberOfFirstLevelLines");
      var otherAccounts = this.mergeAccounts((this.get("data.accounts") || Ember.A([])).filterBy("mapping", "other"));
      return this.sumsNumberOfLinesForLevel(this.calcLeafGroupLines(this.get("structureOtherLevel.levels")), this.calcAllGroupLines(this.get("structureOtherLevel.levels")), numberOfFirstLevelLines, otherAccounts);
    }),
    numberOfLines: Ember.computed("numberOfFirstLevelLines", "numberOfRevenueLines", "numberOfCosLines", "numberOfOpexLines", "numberOfOtherLines", function () {
      return this.get("numberOfRevenueLines") + this.get("numberOfCosLines") + this.get("numberOfOpexLines") + this.get("numberOfOtherLines") + this.get("numberOfFirstLevelLines") + 1; // plus company name;
    }),
    dimensionsList: Ember.computed("step.id", "data.dimensions.@each.selected", function () {
      var _this3 = this;
      var result = [];
      (this.get("data.dimensions") || []).filter(function (dim) {
        if (!dim.selected || !dim.values || !dim.values.length && dim.importNoValues) {
          return false;
        }
        return true;
      }).forEach(function (dim) {
        if (dim.values.length) {
          if (_this3.get("data.singleEntity") !== "1" || dim.internalName.toLowerCase() !== "location") {
            result.pushObject({
              isSub: false,
              id: dim.id,
              dimension: dim,
              text: (0, _emberInflector.pluralize)(dim.dimension || ""),
              byText: intl.t("general.intacct-budget-structure-1", {
                var1: (0, _emberInflector.pluralize)(dim.dimension || "").toLowerCase()
              })
            });
          }
          var maxLevel = Math.max.apply(Math, dim.values.map(function (val) {
            return parseInt(val.level);
          }));
          for (var i = 1; i <= maxLevel; i++) {
            if (dim.values.findBy("level", i)) {
              result.pushObject({
                isSub: true,
                subLevel: i,
                id: dim.id,
                dimension: dim,
                text: intl.t("general.intacct-budget-structure-2", {
                  var1: (0, _emberInflector.pluralize)(dim.dimension || "").toLowerCase()
                }) + (i !== 1 ? " ".concat(i) : ""),
                byText: intl.t("general.intacct-budget-structure-3", {
                  var1: (0, _emberInflector.pluralize)(dim.dimension || "").toLowerCase()
                }) + (i !== 1 ? " ".concat(i) : "")
              });
            }
          }
        }
      });
      return result;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      // remove unhidden tooltip (BUD-17215 + BUD-17285)
      Ember.$(".qtip.popup-menu[aria-hidden=false]").remove();
      var structureAutomated = {};
      for (var i = 0; i < _const.default.createBudgetLevelLimit; i++) {
        structureAutomated["structureFirstLevel" + i] = "";
        structureAutomated["structureRevenueLevel" + i] = "";
        structureAutomated["structureCosLevel" + i] = "";
        structureAutomated["structureOpexLevel" + i] = "";
        structureAutomated["structureOtherLevel" + i] = "";
      }
      this.set("structureAutomated", structureAutomated);
    },
    actions: {
      buildStructure: function buildStructure() {
        var _this4 = this;
        Object.keys(this.get("structureAutomated")).forEach(function (key) {
          var value = _this4.get("structureAutomated")[key];
          if (value) {
            var level = key.slice(0, -1);
            var levelNum = parseInt(key.slice(-1));
            var levelDim = _this4.get("dimensionsList").findBy("text", value);
            _this4.get("".concat(level, ".levels"))[levelNum] = levelDim;
          }
        });
      }
    }
  });
});