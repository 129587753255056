define("budgeta/components/papa-parse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["upload", "import-csv"],
    config: {
      heading: true
    },
    actions: {
      parse: function parse() {
        var self = this;
        self.$("input[type=file]").parse({
          config: Ember.$.extend(self.get("config"), {
            error: function error(_error, file, element) {
              self.sendAction("error", _error, file, element);
            },
            complete: function complete(results, file, element, event) {
              self.sendAction("complete", results, file, element, event);
            }
          })
        });
      }
    }
  });
});