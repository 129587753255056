define("budgeta/templates/main/budget/import-actuals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "orihH37M",
    "block": "{\"statements\":[[\"block\",[\"main/budget/import-modal\"],null,[[\"controllertmp\"],[[\"get\",[null]]]],4]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"      \"],[\"partial\",\"import-file-drop\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"partial\",\"import-actuals-table\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"workbook\"]]],null,1,0]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"uploading\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"unknown\",[\"loadingMessage\"]],false],[\"text\",\"…\\n      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"progress\"],[\"flush-element\"],[\"text\",\"\\n        \"],[\"open-element\",\"span\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n      \"],[\"close-element\"],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"loadingMessage\"]]],null,3,2],[\"text\",\"  \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"name\",\"classNames\",\"action\"],[\"delete\",\"close-import\",\"cancel\"]]],false],[\"text\",\"\\n\"]],\"locals\":[\"scrollHeightStyle\"]}],\"hasPartials\":true}",
    "meta": {
      "moduleName": "budgeta/templates/main/budget/import-actuals.hbs"
    }
  });
});