define("budgeta/templates/components/wizard-question-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qOh+Or3e",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"checked\"]]],null,2,1],[\"text\",\"\\n\"],[\"open-element\",\"label\",[]],[\"dynamic-attr\",\"data-hook\",[\"concat\",[[\"unknown\",[\"label\"]]]]],[\"dynamic-attr\",\"for\",[\"concat\",[[\"unknown\",[\"inputId\"]]]]],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"tipId\"]]],null,0],[\"text\",\"\\n  \"],[\"yield\",\"default\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"help-iq\"],null,[[\"title\",\"docId\",\"type\",\"width\"],[[\"get\",[\"label\"]],[\"get\",[\"tipId\"]],\"tip\",400]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"input\",[]],[\"dynamic-attr\",\"id\",[\"concat\",[[\"unknown\",[\"inputId\"]]]]],[\"dynamic-attr\",\"type\",[\"concat\",[[\"unknown\",[\"type\"]]]]],[\"dynamic-attr\",\"name\",[\"concat\",[[\"unknown\",[\"name\"]]]]],[\"dynamic-attr\",\"value\",[\"unknown\",[\"value\"]],null],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"input\",[]],[\"dynamic-attr\",\"id\",[\"concat\",[[\"unknown\",[\"inputId\"]]]]],[\"dynamic-attr\",\"type\",[\"concat\",[[\"unknown\",[\"type\"]]]]],[\"dynamic-attr\",\"name\",[\"concat\",[[\"unknown\",[\"name\"]]]]],[\"dynamic-attr\",\"value\",[\"unknown\",[\"value\"]],null],[\"static-attr\",\"checked\",\"checked\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/wizard-question-radio.hbs"
    }
  });
});