define("budgeta/services/audit-logging/audit-logging-creator", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* ====== EXAMPLES CAN BE FOUND in auditLoggingService ====== */
  var AuditLogCreator = {
    createChangeDetail: function createChangeDetail(fieldName, oldValue, newValue) {
      return {
        fieldName: fieldName,
        oldValue: oldValue,
        newValue: newValue
      };
    },
    createEntity: function createEntity(refId, refTable) {
      var moreProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return (0, _objectSpread2.default)({
        id: refId,
        entityType: refTable
      }, moreProps);
    },
    createActionDetails: function createActionDetails(detailsArray, entitiesArray) {
      return {
        details: detailsArray,
        entities: entitiesArray
      };
    }
  };
  var _default = _exports.default = AuditLogCreator;
});