define("budgeta/generic-file/utils/utils", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "budgeta/utils/const"], function (_exports, _toConsumableArray2, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDimensionsHierarchiesWithOptions = void 0;
  var getDimensionsHierarchiesWithOptions = _exports.getDimensionsHierarchiesWithOptions = function getDimensionsHierarchiesWithOptions(getDataAction, selectedDimensions, dimensionsHierarchy, formattedFile, notAllowedLinesIndexes) {
    var dimensionsData = getDataAction("dimensionTreeStep", {
      formattedFile: formattedFile,
      notAllowedLinesIndexes: new Set(notAllowedLinesIndexes)
    });
    Object.keys(dimensionsData.values).forEach(function (dimensionId) {
      dimensionsData.values[dimensionId] = (0, _toConsumableArray2.default)(dimensionsData.values[dimensionId]).sort();
    });
    var options = (selectedDimensions || []).filter(function (dimension) {
      return dimensionsData.values[dimension.id] && dimensionsData.values[dimension.id].length;
    }).map(function (dimension) {
      return {
        value: dimension.id,
        label: dimension.dimensionName
      };
    });
    var newDimensionHierarchy = [];
    options.forEach(function (option) {
      newDimensionHierarchy[option.value] = dimensionsHierarchy[option.value] ? (0, _toConsumableArray2.default)(dimensionsHierarchy[option.value]) : (0, _toConsumableArray2.default)(dimensionsData.values[option.value]).map(function (dimension) {
        return {
          id: _const.default.generateUUID(),
          value: dimension.toString(),
          label: dimension.toString(),
          level: 0,
          parent: null
        };
      });
    });
    return {
      newDimensionHierarchy: newDimensionHierarchy,
      options: options
    };
  };
});