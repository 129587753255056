define("budgeta/generic-file/components/preview-step/preview-step", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "@babel/runtime/helpers/esm/slicedToArray", "@sibp/ui-components", "react-redux", "budgeta/store/actions/stepsActions", "budgeta/store/actions/budgetActions", "budgeta/services/ember-service-container", "react", "lodash/lodash", "budgeta/utils/import-generic-file", "budgeta/admin/store/actions/notifActions", "budgeta/generic-file/utils/utils", "budgeta/utils/budget-utils", "budgeta/utils/const", "budgeta/utils/import"], function (_exports, _objectSpread2, _toConsumableArray2, _createForOfIteratorHelper2, _slicedToArray2, _uiComponents, _reactRedux, _stepsActions, _budgetActions, _emberServiceContainer, _react, _lodash, _importGenericFile, _notifActions, _utils, _budgetUtils, _const, _import) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = PreviewStep;
  /* eslint-disable no-prototype-builtins, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var options = {
    hideClose: true,
    height: "100%",
    width: "100%",
    className: "preview-step-container"
  };
  var MAX_BUDGET_LINES_ALLOWED = 5000;
  var _SIBP$COMPONENTS$Tree = _uiComponents.default.COMPONENTS.TreeMenu,
    Tree = _SIBP$COMPONENTS$Tree.Tree,
    Folder = _SIBP$COMPONENTS$Tree.Folder,
    File = _SIBP$COMPONENTS$Tree.File;
  function PreviewStep(_ref) {
    var endTableAction = _ref.endTableAction,
      getDataAction = _ref.getDataAction;
    var dispatch = _reactRedux.default.useDispatch();
    var _React$useState = _react.default.useState(null),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      countLines = _React$useState2[0],
      setCountLines = _React$useState2[1];
    var _React$useState3 = _react.default.useState(true),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      showTotalAmounts = _React$useState4[0],
      setShowTotalAmounts = _React$useState4[1];
    var _React$useState5 = _react.default.useState(false),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      showLinesNotImportedWarning = _React$useState6[0],
      setShowLinesNotImportedWarning = _React$useState6[1];
    var _React$useState7 = _react.default.useState(new Set()),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      invalidLinesIndexes = _React$useState8[0],
      setInvalidLinesIndexes = _React$useState8[1];
    var _React$useState9 = _react.default.useState(""),
      _React$useState10 = (0, _slicedToArray2.default)(_React$useState9, 2),
      currDimension = _React$useState10[0],
      setCurrDimension = _React$useState10[1];
    var _React$useState11 = _react.default.useState([]),
      _React$useState12 = (0, _slicedToArray2.default)(_React$useState11, 2),
      dimensionsOptions = _React$useState12[0],
      setDimensionsOptions = _React$useState12[1];
    var _React$useState13 = _react.default.useState({}),
      _React$useState14 = (0, _slicedToArray2.default)(_React$useState13, 2),
      formattedFile = _React$useState14[0],
      setFormattedFile = _React$useState14[1];
    var _React$useState15 = _react.default.useState({}),
      _React$useState16 = (0, _slicedToArray2.default)(_React$useState15, 2),
      categoryMapping = _React$useState16[0],
      setCategoryMapping = _React$useState16[1];
    var _React$useState17 = _react.default.useState(null),
      _React$useState18 = (0, _slicedToArray2.default)(_React$useState17, 2),
      root = _React$useState18[0],
      setRoot = _React$useState18[1];
    var _React$useState19 = _react.default.useState(),
      _React$useState20 = (0, _slicedToArray2.default)(_React$useState19, 2),
      budgetRoot = _React$useState20[0],
      setBudgetRoot = _React$useState20[1];
    var _React$useState21 = _react.default.useState(null),
      _React$useState22 = (0, _slicedToArray2.default)(_React$useState21, 2),
      budgetDimensionsHierarchy = _React$useState22[0],
      setBudgetDimensionsHierarchy = _React$useState22[1];
    var _React$useState23 = _react.default.useState([]),
      _React$useState24 = (0, _slicedToArray2.default)(_React$useState23, 2),
      selectedLines = _React$useState24[0],
      setSelectedLines = _React$useState24[1];
    var _React$useState25 = _react.default.useState([]),
      _React$useState26 = (0, _slicedToArray2.default)(_React$useState25, 2),
      chosenDimension = _React$useState26[0],
      setChosenDimension = _React$useState26[1];
    var _React$useState27 = _react.default.useState([]),
      _React$useState28 = (0, _slicedToArray2.default)(_React$useState27, 2),
      amountColumns = _React$useState28[0],
      setAmountColumns = _React$useState28[1];
    var _React$useState29 = _react.default.useState(),
      _React$useState30 = (0, _slicedToArray2.default)(_React$useState29, 2),
      startDateColumnKey = _React$useState30[0],
      setStartDateColumnKey = _React$useState30[1];
    var _React$useState31 = _react.default.useState(),
      _React$useState32 = (0, _slicedToArray2.default)(_React$useState31, 2),
      endDateColumnKey = _React$useState32[0],
      setEndDateColumnKey = _React$useState32[1];
    var _React$useState33 = _react.default.useState(),
      _React$useState34 = (0, _slicedToArray2.default)(_React$useState33, 2),
      commentColumnKey = _React$useState34[0],
      setCommentColumnKey = _React$useState34[1];
    var _React$useState35 = _react.default.useState(),
      _React$useState36 = (0, _slicedToArray2.default)(_React$useState35, 2),
      tagsColumnKey = _React$useState36[0],
      setTagsColumnKey = _React$useState36[1];
    var stepName = _reactRedux.default.useSelector(function (state) {
      return state.stepsStore.currentStep;
    });
    var _formattedFile = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.formattedFile;
    });
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore;
      }),
      selectedDimensions = _ReactRedux$useSelect.selectedDimensions,
      _categoryMapping = _ReactRedux$useSelect.categoryMapping,
      currenciesMapping = _ReactRedux$useSelect.currenciesMapping,
      dimensionHierarchy = _ReactRedux$useSelect.dimensionHierarchy,
      _selectedLines = _ReactRedux$useSelect.selectedLines,
      validColumnMappings = _ReactRedux$useSelect.validColumnMappings,
      nameColumn = _ReactRedux$useSelect.nameColumn,
      name = _ReactRedux$useSelect.name,
      budgetTree = _ReactRedux$useSelect.budgetTree,
      showPreviewStepWalkthrough = _ReactRedux$useSelect.showPreviewStepWalkthrough,
      mainCurrency = _ReactRedux$useSelect.mainCurrency,
      fileCurrenciesToSIBP = _ReactRedux$useSelect.fileCurrenciesToSIBP,
      budgetRootId = _ReactRedux$useSelect.budgetRootId,
      budgetRootType = _ReactRedux$useSelect.budgetRootType,
      currencyColumnKey = _ReactRedux$useSelect.currencyColumnKey,
      accountColumnKey = _ReactRedux$useSelect.accountColumnKey,
      budgetStartDate = _ReactRedux$useSelect.startDate,
      budgetEndDate = _ReactRedux$useSelect.endDate,
      headerLineNumber = _ReactRedux$useSelect.headerLineNumber;
    var notAllowedLinesIndexes = _reactRedux.default.useSelector(function (state) {
      return state.budgetStore.notAllowedLinesDetails.notAllowedCategory;
    });
    var defaultBudgetGrouping = _const.default.BUDGET_TYPE_TO_GROUPS[budgetRootType].profit.map(function (group) {
      return {
        id: group.type,
        label: group.text,
        value: group.type,
        level: 0,
        parent: null
      };
    });
    var minimumNumberOfLettersToFitWalkthrough = 7;
    var budgetTreeStructure = budgetTree && budgetTree.length ? budgetTree : defaultBudgetGrouping;
    var currOptions = [];
    var runCount = 0;
    var nameColumnLetter = nameColumn.replace(/[0-9]/g, "");
    var dimValMap = {};
    var dimensionsToLevels = {};
    var subsidiariesDimensions = {};
    var hasDimensionsOptions = !!(dimensionsOptions.length && dimensionsOptions[0].value.level !== 0);
    var categoryMappingKeysMap = {
      revenues: "group-revenue",
      "operating expenses": "group-expense",
      "other income and expenses": "group-expense-other",
      "cost of revenues": "group-expense-costofsale"
    };
    var budgetTypeGroupMap = {
      "group-expense-other": {
        groupType: "group-expense-other",
        sign: 1,
        regularType: "expense",
        adjType: "expense-adjustment"
      },
      "group-revenue": {
        groupType: "group-revenue",
        sign: -1,
        regularType: "sales",
        adjType: "revenue-adjustment"
      },
      "group-expense": {
        groupType: "group-expense",
        sign: 1,
        regularType: "expense",
        adjType: "expense-adjustment"
      },
      "group-expense-costofsale": {
        groupType: "group-expense",
        sign: 1,
        regularType: "expense",
        adjType: "expense-adjustment"
      }
    };
    var mergeLinesHeaders = [currencyColumnKey, accountColumnKey].filter(function (key) {
      return key;
    });
    var memorizedDimensionsHierarchies = _react.default.useCallback((0, _utils.getDimensionsHierarchiesWithOptions)(getDataAction, selectedDimensions, dimensionHierarchy, _formattedFile, _lodash.default.flatten(Object.values(notAllowedLinesIndexes))), [dimensionHierarchy, selectedDimensions, notAllowedLinesIndexes, _formattedFile]);
    _react.default.useMemo(function () {
      setBudgetDimensionsHierarchy(Object.keys(dimensionHierarchy).length ? dimensionHierarchy : memorizedDimensionsHierarchies.newDimensionHierarchy);
    }, [dimensionHierarchy, memorizedDimensionsHierarchies]);
    _react.default.useMemo(function () {
      setBudgetRoot(getDataAction(stepName, {
        budgetRootId: budgetRootId
      }));
    }, [budgetRootId]);
    _react.default.useMemo(function () {
      if (!budgetDimensionsHierarchy) return;
      var allNotAllowedIndexes = new Set(_lodash.default.flatten(Object.values(notAllowedLinesIndexes)));
      var lineIndexToContainedIndex = {};
      var dimensionColumnKeys = (validColumnMappings || []).reduce(function (keys, _ref2) {
        var selectedAttribute = _ref2.selectedAttribute,
          key = _ref2.key;
        if (/[a-z0-9]{32}/.test(selectedAttribute)) {
          keys.push(key.replace(/[0-9]/g, ""));
        }
        return keys;
      }, []);
      var commentColumn = validColumnMappings.find(function (x) {
        return x.selectedAttribute === "comments";
      });
      var tagsColumn = validColumnMappings.find(function (x) {
        return x.selectedAttribute === "tags";
      });
      var startDateColumn = validColumnMappings.find(function (mapping) {
        return mapping.selectedAttribute === "startDate";
      });
      var endDateColumn = validColumnMappings.find(function (mapping) {
        return mapping.selectedAttribute === "endDate";
      });
      var startDateKey = startDateColumn && startDateColumn.key.replace(/[0-9]/g, "");
      var endDateKey = endDateColumn && endDateColumn.key.replace(/[0-9]/g, "");
      var commentKey = commentColumn && commentColumn.key.replace(/[0-9]/g, "");
      var tagsKey = tagsColumn && tagsColumn.key.replace(/[0-9]/g, "");
      var amountColumn = (validColumnMappings || []).reduce(function (filtered, _ref3) {
        var selectedAttribute = _ref3.selectedAttribute,
          key = _ref3.key,
          value = _ref3.value;
        if (selectedAttribute === "#date#") {
          filtered.push({
            key: key.replace(/[0-9]/g, ""),
            value: moment(value)
          });
        }
        return filtered;
      }, []);
      var combineAmountValues = function combineAmountValues(newLine, line) {
        var getTrimmedReplacedValue = function getTrimmedReplacedValue(data) {
          if (typeof data === "string") {
            return data.trim().replace(/[^0-9.]/g, "") * 1;
          }
          return data;
        };
        if (!amountColumn.length) return;
        var _iterator = (0, _createForOfIteratorHelper2.default)(amountColumn.map(function (col) {
            return col.key;
          })),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var amountKey = _step.value;
            if (!newLine[amountKey] && !line[amountKey]) {
              continue;
            }
            if (!newLine[amountKey] && line[amountKey]) {
              newLine[amountKey] = line[amountKey];
            } else {
              var newLineAmount = newLine[amountKey] && getTrimmedReplacedValue(isNaN(newLine[amountKey].v) ? newLine[amountKey].v || newLine[amountKey].w : newLine[amountKey].v);
              var lineAmount = line[amountKey] && getTrimmedReplacedValue(line[amountKey].v || line[amountKey].w);
              newLine[amountKey].v = [lineAmount, newLineAmount].reduce(function (values, value) {
                values = value ? values + value * 1 : values;
                return values;
              }, 0);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      };
      var combineCommentValues = function combineCommentValues(newLine, line) {
        if (!commentKey || !newLine[commentKey] && !line[commentKey]) return;
        if (!newLine[commentKey] && line[commentKey]) {
          newLine[commentKey] = line[commentKey];
        } else {
          newLine[commentKey].w = [newLine[commentKey] && newLine[commentKey].w, line[commentKey] && line[commentKey].w].reduce(function (values, value) {
            value && values.push(value);
            return values;
          }, []).join(",");
        }
      };
      var combineTagValues = function combineTagValues(newLine, line) {
        if (!tagsKey || !newLine[tagsKey] && !line[tagsKey]) return;
        if (!newLine[tagsKey] && line[tagsKey]) {
          newLine[tagsKey] = line[tagsKey];
        } else {
          newLine[tagsKey].w = [newLine[tagsKey] && newLine[tagsKey].w, line[tagsKey] && line[tagsKey].w].reduce(function (values, value) {
            value && values.push(value);
            return values;
          }, []).join(",");
        }
      };
      var combineDateValues = function combineDateValues(newLine, line) {
        if (!startDateKey && !endDateKey) return;
        var lineStartDate = line[startDateKey] && getDataAction(stepName, {
          date: line[startDateKey]
        }).date;
        var lineEndDate = line[endDateKey] && getDataAction(stepName, {
          date: line[endDateKey]
        }).date;
        var newLineStartDate = newLine[startDateKey] && getDataAction(stepName, {
          date: newLine[startDateKey]
        }).date;
        var newLineEndDate = newLine[endDateKey] && getDataAction(stepName, {
          date: newLine[endDateKey]
        }).date;
        if (!newLine[startDateKey] && line[startDateKey] || lineStartDate && newLineStartDate && lineStartDate.isBefore(newLineStartDate)) {
          newLine[startDateKey] = newLine[startDateKey] || {};
          newLine[startDateKey].w = line[startDateKey].w;
          newLine[startDateKey].v = line[startDateKey].v;
          newLine[startDateKey].t = line[startDateKey].t;
        }
        if (!newLine[endDateKey] && line[endDateKey] || lineEndDate && newLineEndDate && lineEndDate.isAfter(newLineEndDate)) {
          newLine[endDateKey] = newLine[endDateKey] || {};
          newLine[endDateKey].w = line[endDateKey].w;
          newLine[endDateKey].v = line[endDateKey].v;
          newLine[endDateKey].t = line[endDateKey].t;
        }
      };
      var getExcelToMapped = function getExcelToMapped(mapping) {
        var excelToMapping = {};
        mapping && Object.keys(mapping).forEach(function (map) {
          mapping[map].forEach(function (excelMapping) {
            excelToMapping[excelMapping.value.toString().trim()] = map;
          });
        });
        return excelToMapping;
      };
      var getCategoryMappingForMergedLines = function getCategoryMappingForMergedLines(lineIndexToContainedIndex) {
        var categoryMap = {};
        Object.values(categoryMappingKeysMap).forEach(function (key) {
          if (_categoryMapping[key]) {
            categoryMap[key] = _categoryMapping[key];
            categoryMap[key].lines = (0, _toConsumableArray2.default)(new Set((_categoryMapping[key] || []).reduce(function (arr, x) {
              arr.push.apply(arr, (0, _toConsumableArray2.default)(x.lines.reduce(function (lines, line) {
                var lineIndex = line.replace(/[A-Z]/g, "");
                lines.push(lineIndexToContainedIndex[lineIndex * 1] && lineIndexToContainedIndex[lineIndex * 1].toString() || lineIndex);
                return lines;
              }, [])));
              return arr;
            }, [])));
          }
        });
        Object.keys(_categoryMapping).forEach(function (key) {
          if (!categoryMappingKeysMap[key]) {
            categoryMap[key] = _categoryMapping[key];
          }
        });
        return categoryMap;
      };
      var getLineCurrencyBySymbol = function getLineCurrencyBySymbol(line) {
        var currency;
        amountColumn.forEach(function (amountCol) {
          currency = currency || _import.default.getCellCurrency(line[amountCol.key], {
            mainCurrency: mainCurrency
          });
        });
        return currency;
      };
      var excelToCurrencyMap = getExcelToMapped(currenciesMapping);
      var dimensionsNaming = Object.keys(budgetDimensionsHierarchy).reduce(function (dimensionsIds, dimensionId) {
        var dimensionKeyLetter = validColumnMappings.find(function (mapping) {
          return mapping.selectedAttribute === dimensionId;
        }).key.replace(/[0-9]/g, "");
        dimensionsIds[dimensionKeyLetter] = budgetDimensionsHierarchy[dimensionId].reduce(function (dimensionValues, dimensionValue) {
          dimensionValues[dimensionValue.value.toLowerCase()] = dimensionValue.value;
          return dimensionValues;
        }, {});
        return dimensionsIds;
      }, {});
      var lineNumbersByName = _selectedLines.reduce(function (lines, line) {
        var duplicatedLine = false;
        var lineName = line.text.toLowerCase().trim();
        if (!lines[lineName]) {
          lines[lineName] = [];
        }
        var _iterator2 = (0, _createForOfIteratorHelper2.default)(lines[lineName]),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var lineNumbers = _step2.value;
            if (allNotAllowedIndexes.has(line.location)) break;
            var currentLineCurrencyValue = void 0;
            var exampleLineCurrencyValue = void 0;
            duplicatedLine = true;
            var exampleLineNumber = lineNumbers[0];
            var currentLine = _formattedFile[line.lineNumber];
            var exampleLine = _formattedFile[exampleLineNumber];
            if (!currencyColumnKey) {
              currentLineCurrencyValue = getLineCurrencyBySymbol(currentLine) || mainCurrency;
              exampleLineCurrencyValue = getLineCurrencyBySymbol(exampleLine) || mainCurrency;
            }
            if (!currencyColumnKey && currentLineCurrencyValue !== exampleLineCurrencyValue) {
              duplicatedLine = false;
            } else {
              var _iterator3 = (0, _createForOfIteratorHelper2.default)(mergeLinesHeaders.concat(dimensionColumnKeys)),
                _step3;
              try {
                for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                  var mergeLineHeader = _step3.value;
                  if (mergeLineHeader === currencyColumnKey || currentLine[mergeLineHeader] && !exampleLine[mergeLineHeader] || !currentLine[mergeLineHeader] && exampleLine[mergeLineHeader] || currentLine[mergeLineHeader] && exampleLine[mergeLineHeader]) {
                    if (mergeLineHeader === currencyColumnKey || currentLine[mergeLineHeader] && exampleLine[mergeLineHeader]) {
                      if (mergeLineHeader === currencyColumnKey) {
                        var currentLineCurrency = (!currentLine[mergeLineHeader] || !currentLine[mergeLineHeader].w ? getLineCurrencyBySymbol(currentLine) : excelToCurrencyMap[currentLine[mergeLineHeader].w.toString().trim()]) || mainCurrency;
                        var exampleLineCurrency = (!exampleLine[mergeLineHeader] || !exampleLine[mergeLineHeader].w ? getLineCurrencyBySymbol(exampleLine) : excelToCurrencyMap[exampleLine[mergeLineHeader].w.toString().trim()]) || mainCurrency;
                        if (currentLineCurrency !== exampleLineCurrency) {
                          duplicatedLine = false;
                        }
                      } else {
                        var currentLinedimensionValues = dimensionsNaming[mergeLineHeader] && dimensionsNaming[mergeLineHeader][currentLine[mergeLineHeader].w.toString().toLowerCase().trim()];
                        var exampleLinedimensionValues = dimensionsNaming[mergeLineHeader] && dimensionsNaming[mergeLineHeader][exampleLine[mergeLineHeader].w.toString().toLowerCase().trim()];
                        var currentLineValue = currentLine[mergeLineHeader] && (dimensionsNaming[mergeLineHeader] && currentLinedimensionValues ? currentLinedimensionValues : currentLine[mergeLineHeader].w);
                        var exampleLineValue = exampleLine[mergeLineHeader] && (exampleLinedimensionValues ? exampleLinedimensionValues : exampleLine[mergeLineHeader].w);
                        if (currentLineValue !== exampleLineValue) {
                          duplicatedLine = false;
                        }
                      }
                    } else {
                      duplicatedLine = false;
                    }
                  }
                }
              } catch (err) {
                _iterator3.e(err);
              } finally {
                _iterator3.f();
              }
            }
            if (duplicatedLine) {
              lineNumbers.push(line.lineNumber * 1);
              break;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        !duplicatedLine && !allNotAllowedIndexes.has(line.location) && lines[lineName].push([line.lineNumber * 1]);
        return lines;
      }, {});
      _lodash.default.flatten(Object.values(lineNumbersByName)).forEach(function (lineNumbers) {
        if (lineNumbers.length > 1) {
          lineNumbers.slice(1).forEach(function (lineNumber) {
            lineIndexToContainedIndex[lineNumber] = lineNumbers[0];
          });
        }
      });
      var getTrimmedValue = function getTrimmedValue(data) {
        if (typeof data === "string") {
          return data.replace(/\s{2,}/g, " ").trim();
        }
        return data.toString();
      };
      var newFormattedLines = _lodash.default.flatten(Object.values(lineNumbersByName)).reduce(function (newLines, lineNumbers) {
        var createNewLine = function createNewLine(lineNumber) {
          return Object.keys(_formattedFile[lineNumber]).reduce(function (newObject, row) {
            var wValue = dimensionsNaming.hasOwnProperty(row) ? dimensionsNaming[row][getTrimmedValue(_formattedFile[lineNumber][row].w).toLowerCase()] : _formattedFile[lineNumber][row].w;
            newObject[row] = {
              v: _formattedFile[lineNumber][row].v,
              w: wValue,
              t: _formattedFile[lineNumber][row].t
            };
            return newObject;
          }, {});
        };
        if (lineNumbers.length === 1) {
          if (_formattedFile[lineNumbers[0]] && Object.keys(_formattedFile[lineNumbers[0]]).length) {
            newLines[lineNumbers[0]] = createNewLine(lineNumbers[0]);
          }
        } else {
          var newLine;
          var _iterator4 = (0, _createForOfIteratorHelper2.default)(lineNumbers),
            _step4;
          try {
            for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
              var lineNumber = _step4.value;
              if (!newLine) {
                newLine = createNewLine(lineNumber);
              } else {
                combineAmountValues(newLine, _formattedFile[lineNumber]);
                combineCommentValues(newLine, _formattedFile[lineNumber]);
                combineTagValues(newLine, _formattedFile[lineNumber]);
                combineDateValues(newLine, _formattedFile[lineNumber]);
              }
            }
          } catch (err) {
            _iterator4.e(err);
          } finally {
            _iterator4.f();
          }
          if (newLine && Object.keys(newLine).length) {
            newLines[lineNumbers[0]] = newLine;
          }
        }
        return newLines;
      }, {});
      newFormattedLines[headerLineNumber] = _formattedFile[headerLineNumber];
      setAmountColumns(amountColumn);
      setStartDateColumnKey(startDateKey);
      setEndDateColumnKey(endDateKey);
      setCommentColumnKey(commentKey);
      setTagsColumnKey(tagsKey);
      setCategoryMapping(getCategoryMappingForMergedLines(lineIndexToContainedIndex));
      setFormattedFile(newFormattedLines);
      setSelectedLines(Object.keys(newFormattedLines).map(function (lineNumber) {
        return {
          lineNumber: lineNumber,
          text: newFormattedLines[lineNumber][nameColumnLetter] ? newFormattedLines[lineNumber][nameColumnLetter].w : ""
        };
      }));
    }, [_formattedFile, _selectedLines, _categoryMapping, currenciesMapping, validColumnMappings, budgetDimensionsHierarchy]);
    _react.default.useMemo(function () {
      setChosenDimension((selectedDimensions || []).reduce(function (dimensions, dim) {
        if (dim.key) {
          dimensions.push({
            name: dim.dimensionName,
            key: dim.key.replace(/[0-9]/g, ""),
            id: dim.id
          });
        }
        return dimensions;
      }, []));
    }, [selectedDimensions]);
    var isConstGroup = function isConstGroup(groupName) {
      var groupNameToSearch = groupName.toUpperCase();
      return _const.default.BUDGET_GROUPING_GENERIC_UPLOAD.some(function (constGroup) {
        return constGroup.text.toUpperCase() === groupNameToSearch || constGroup.type.toUpperCase() === groupNameToSearch;
      });
    };

    //group type - group-revenue, group-expense..
    var isValidLine = function isValidLine(lineIndex, groupType) {
      var lineHasDimension = function lineHasDimension(line, dimensionId) {
        var dimLevel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
        var dimensionKey = ((chosenDimension || []).find(function (dim) {
          return dim.id === dimensionId;
        }) || []).key;
        var dimValue = line && line[dimensionKey] && line[dimensionKey].w && line[dimensionKey].w.toString().trim();
        return dimValue && (!dimLevel || dimensionsToLevels[dimensionId][dimLevel].has(dimValue));
      };
      var lineIsValid;
      var line = formattedFile[lineIndex];

      //if there subsidiaries dimensions (dims before const groups - rev,opex..) - the only rule for valid line is to have all the subs dimensions (can float)
      if (Object.keys(subsidiariesDimensions).length) {
        lineIsValid = Object.keys(subsidiariesDimensions).every(function (subsDimensionKey) {
          return lineHasDimension(line, subsidiariesDimensions[subsDimensionKey].id, subsidiariesDimensions[subsDimensionKey].dimLevel);
        });
      }
      //no subs dimensions - the rules for valid line:  1. if the group (rev,opex,..) the line belongs has dimensions defined - the line must have the first in the hierarchy (cant float). 2. else no rule - the line is valid (floating line)
      else {
        //first child dimension of the group (if exist)
        var dimensionChildOfLineCategory = (budgetTreeStructure || []).find(function (node) {
          return node.parent === groupType;
        });

        //if no child - valid (float). else need to has this dim
        lineIsValid = !dimensionChildOfLineCategory || lineHasDimension(line, dimensionChildOfLineCategory.value, dimensionChildOfLineCategory.dimLevel);
      }
      return lineIsValid;
    };
    var isLinesLimitExceeded = function isLinesLimitExceeded(numOfLines) {
      return Number.isInteger(numOfLines) && numOfLines > MAX_BUDGET_LINES_ALLOWED;
    };
    var getEmptyDefaultLineName = function getEmptyDefaultLineName(groupName) {
      return _const.default.BUDGET_GROUPING_GENERIC_UPLOAD.find(function (group) {
        return group.type === groupName;
      }).defaultChildName;
    };
    var createStructure = function createStructure(node) {
      var currentGroup = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var lines = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var isSub = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      var parentId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      //sum child amounts to parent and convert if needed
      var updateParentAmounts = function updateParentAmounts(parent, childAmounts, childCurrency, toConvertChild, childStartDate, childEndDate) {
        var parentTotal = 0;
        var validMonthes = [];
        toConvertChild && parent.currency !== mainCurrency && Object.keys(parent.amounts).forEach(function (month) {
          parent.amounts[month] !== undefined && _budgetUtils.default.addCurrencyToBudget(budgetRoot, parent.currency);
          parent.amounts[month] = parent.amounts[month] !== undefined ? _const.default.convertCurrency(budgetRoot, parent.amounts[month], parent.currency, mainCurrency, month) : 0;
        });

        //update parent with childs amounts
        Object.keys(childAmounts).forEach(function (month) {
          var currentMonth = new Date("".concat(month.slice(1, 3), "-01-").concat(month.slice(3)));
          if ((!childStartDate || currentMonth.isAfter(childStartDate) || currentMonth.is(childStartDate)) && (!childEndDate || currentMonth.isBefore(childEndDate) || currentMonth.is(childEndDate)) && (currentMonth.isAfter(budgetStartDate.beginningOfMonth()) || currentMonth.is(budgetStartDate.beginningOfMonth())) && (currentMonth.isBefore(budgetEndDate.beginningOfMonth()) || currentMonth.is(budgetEndDate.beginningOfMonth()))) {
            toConvertChild && _budgetUtils.default.addCurrencyToBudget(budgetRoot, childCurrency);
            parent.amounts[month] = (parent.amounts[month] || 0) + (childAmounts[month] //if child amount exist
            ? toConvertChild ? _const.default.convertCurrency(budgetRoot, childAmounts[month], childCurrency, mainCurrency, month) : childAmounts[month] : 0);
            parentTotal += parent.amounts[month];
            validMonthes.push(month);
          }
        });
        var leftOverMonthes = _lodash.default.difference(Object.keys(parent.amounts), validMonthes);
        leftOverMonthes.forEach(function (month) {
          parentTotal += parent.amounts[month];
        });
        parent.total = parentTotal;
        parent.currency = toConvertChild ? mainCurrency : childCurrency;
      };
      var areConstGroups = function areConstGroups(currentGroups) {
        return currentGroups.some(function (group) {
          return isConstGroup(group.label);
        });
      };
      //currencies priority: 1. cell in currency column. 2. currency sign in one of the amounts cell. 3. main-currency.
      var buildLineByIndex = function buildLineByIndex(lineIndex, columns, groupType) {
        runCount++;
        var getLineUniqueDimensions = function getLineUniqueDimensions(lineValues) {
          var lineUniqueDimensions = [];
          var dimensionName;
          var dimensionValueId;
          (chosenDimension || []).forEach(function (dimension) {
            //if curr dimension is unique to lineValues
            dimensionName = lineValues[dimension.key] && lineValues[dimension.key].w && lineValues[dimension.key].w.trim();
            dimensionValueId = ((budgetDimensionsHierarchy[dimension.id] || []).find(function (dimensionValue) {
              return dimensionValue.value === dimensionName;
            }) || []).id;
            if (dimension.id && dimensionValueId) {
              lineUniqueDimensions.push({
                dimension: dimension.id,
                values: [{
                  value: dimensionValueId
                }]
              });
            }
          });
          return lineUniqueDimensions;
        };
        var _budgetTypeGroupMap$g = budgetTypeGroupMap[groupType],
          sign = _budgetTypeGroupMap$g.sign,
          regularType = _budgetTypeGroupMap$g.regularType,
          adjType = _budgetTypeGroupMap$g.adjType;
        var amounts = {};
        var line = formattedFile[lineIndex];
        var accountId = line[accountColumnKey].w && line[accountColumnKey].w.toString().trim();
        var currency = line[currencyColumnKey] && line[currencyColumnKey].v && fileCurrenciesToSIBP[line[currencyColumnKey].v.toString().trim()]; // extract currency column
        var name = line[nameColumnLetter] && line[nameColumnLetter].w && line[nameColumnLetter].w.toString().trim() || getEmptyDefaultLineName(groupType);
        var startDate = startDateColumnKey && line[startDateColumnKey] && getDataAction(stepName, {
          date: line[startDateColumnKey]
        }).date;
        var endDate = endDateColumnKey && line[endDateColumnKey] && getDataAction(stepName, {
          date: line[endDateColumnKey]
        }).date;
        var comment = "";
        var total = 0;
        var keys = [];
        var tags = [];
        var isAllNegative = true;
        var type = regularType;
        var lineUniqueDimensions = getLineUniqueDimensions(line);
        var getTrimmedReplacedValue = function getTrimmedReplacedValue(data) {
          if (typeof data === "string") {
            return data.trim().replace(/[^0-9.]/g, "");
          }
          return data;
        };
        var lastSign;
        var currentSign;
        columns.forEach(function (_ref4) {
          var key = _ref4.key,
            value = _ref4.value;
          var isAdjustmentRow = function isAdjustmentRow() {
            return currentSign !== sign || lastSign !== undefined && currentSign !== lastSign;
          };
          var getCurrentSignOrDefaultIfZero = function getCurrentSignOrDefaultIfZero(amount, defaultSign) {
            return amount === 0 ? defaultSign : amount > 0 ? 1 : -1;
          };
          var amount = line[key] ? getTrimmedReplacedValue(isNaN(line[key].v) ? line[key].v || line[key].w : line[key].v) * sign : 0;
          currency = currency || line[key] && _import.default.getCellCurrency(line[key], {
            mainCurrency: mainCurrency
          });
          isAllNegative = !isAllNegative ? isAllNegative : amount <= 0;
          currentSign = getCurrentSignOrDefaultIfZero(amount * sign, sign);
          if (isAdjustmentRow()) {
            type = adjType;
          }
          lastSign = currentSign;
          var attributeKey = value.format("[m]MMYYYY");
          if (attributeKey && value.isValid()) {
            while (keys.indexOf(attributeKey) >= 0) {
              value.add(1, "year");
              attributeKey = value.format("[m]MMYYYY");
            }
            keys.push(attributeKey);
          }
          amounts[attributeKey] = amount;
          var timeValue = new Date(value).beginningOfMonth();
          if ((!startDate || timeValue.isAfter(startDate) || timeValue.is(startDate)) && (!endDate || timeValue.isBefore(endDate) || timeValue.is(endDate)) && (timeValue.isAfter(budgetStartDate.beginningOfMonth()) || timeValue.is(budgetStartDate.beginningOfMonth())) && (timeValue.isBefore(budgetEndDate.beginningOfMonth()) || timeValue.is(budgetEndDate.beginningOfMonth()))) {
            total += amount;
          }
        });
        currency = currency || mainCurrency;
        if (isAllNegative && groupType === "group-expense-other") {
          type = "sales";
        }
        if (commentColumnKey) {
          comment = line[commentColumnKey] && line[commentColumnKey].w || "";
        }
        if (tagsColumnKey && line[tagsColumnKey]) {
          line[tagsColumnKey].w.split(",").forEach(function (val) {
            var v = val.trim();
            if (v && !tags.findBy("name", v)) {
              tags.pushObject({
                name: v
              });
            }
          });
        }
        return {
          accountId: accountId,
          name: name,
          amounts: amounts,
          total: total,
          lineIndex: lineIndex,
          type: type,
          currency: currency,
          dimensions: lineUniqueDimensions,
          comment: comment,
          startDate: startDate,
          endDate: endDate,
          tags: tags
        };
      };
      var createLeaves = function createLeaves(parent, lines) {
        var toConvertChild;

        //set all the lines after all the intersection and return :)
        var listOfChilds = lines.map(function (line) {
          var child = buildLineByIndex(line, amountColumns, parent.type);
          if (child.currency) {
            toConvertChild = parent.currency && parent.currency !== child.currency;
            updateParentAmounts(parent, child.amounts, child.currency, toConvertChild, child.startDate, child.endDate);
          }
          return child;
        });
        if (!isConstGroup(parent.name)) {
          runCount++;
        }
        parent.children = listOfChilds;
        return parent;
      };
      var getGroupDimension = function getGroupDimension(node, dimensionId) {
        //root or const group (rev/cogs/opex/other) dont add new dimension values
        if (node.level === 0 || isConstGroup(node.name)) {
          return [];
        }
        var groupDimension = {};
        if (dimensionId) {
          var valueFound = (budgetDimensionsHierarchy || [])[dimensionId].find(function (dimensionValue) {
            return dimensionValue.value === node.name;
          });
          if (valueFound) {
            groupDimension.dimension = dimensionId;
            groupDimension.values = [{
              value: valueFound.id
            }];
          }
        }
        return [groupDimension];
      };
      var createDimensionsGroups = function createDimensionsGroups(parent, lines, groups, isSub) {
        if (!isConstGroup(parent.name)) {
          runCount++;
        }
        var toConvertChild;
        var childSet;
        var _groups = (0, _slicedToArray2.default)(groups, 1),
          curLevel = _groups[0];
        var dimLevel = curLevel.dimLevel,
          value = curLevel.value,
          id = curLevel.id,
          level = curLevel.level;
        var values = (budgetDimensionsHierarchy && budgetDimensionsHierarchy[value] || []).filter(function (x) {
          return x.level === dimLevel;
        });
        if (!isSub) {
          childSet = new Set(lines);
        }
        parent.children = values.reduce(function (children, v) {
          var child = {
            name: v.label,
            relativePath: parent.level === 0 ? v.label : parent.relativePath + " > " + v.label,
            type: isSub ? "group-company" : budgetTypeGroupMap[parent.type].groupType,
            children: [],
            level: parent.level + 1,
            amounts: {},
            total: 0
          };

          // get lines of this group
          var currentLinesIndexes = dimValMap[value][child.name].descendants ? dimValMap[value][child.name].lines.concat(dimValMap[value][child.name].descendants) : dimValMap[value][child.name].lines;
          if (parent.level > 0) {
            currentLinesIndexes = _lodash.default.intersection(lines, currentLinesIndexes);
          }
          if (!isSub) {
            currentLinesIndexes.forEach(function (line) {
              childSet.delete(line);
            });
          }
          if (currentLinesIndexes.length > 0) {
            children.push(child); // need to ask about !isSub -> if not all const group wont be created
            var computedChild = createStructure(child, budgetTreeStructure.filter(function (x) {
              return x.level === level + 1 && x.parent === id;
            }), currentLinesIndexes, isSub, curLevel.parent);
            toConvertChild = parent.currency && parent.currency !== computedChild.currency;
            updateParentAmounts(parent, computedChild.amounts, computedChild.currency, toConvertChild, child.startDate, child.endDate);
          }

          // no lines  => meaning there is no budget lines with this combinantion -> there is no need to create that "path"
          return children;
        }, []);

        // not subsidary => groups under rev cogs...
        // ownLines need
        if (!isSub && childSet.size > 0) {
          childSet.forEach(function (line) {
            if (isValidLine(line, parent.type)) {
              var child = buildLineByIndex(line, amountColumns, parent.type);
              var _toConvertChild = parent.currency && child.currency !== parent.currency;

              //in case currency of the new child different from parent -> convert parent (to mainCurrency)
              updateParentAmounts(parent, child.amounts, child.currency, _toConvertChild, child.startDate, child.endDate);
              parent.children.push(child);
            } else {
              invalidLinesIndexes.add(line);
            }
          });
        }
        return parent;
      };
      var createConstGroups = function createConstGroups(parent, lines, groups) {
        runCount++;
        // until here we had dimensions hierarchy
        currOptions.push({
          value: parent,
          label: parent.relativePath
        });
        var toConvertChild;

        //create default groups -> Revenues Opex Cogs Other
        var children = groups.map(function (curLevel) {
          var defaultGroup = {
            name: curLevel.label,
            type: curLevel.value,
            children: [],
            level: parent.level + 1,
            amounts: {},
            total: 0
          };

          //create groups inside those groups
          if (curLevel) {
            var currentLinesIndexes = categoryMapping[categoryMappingKeysMap[defaultGroup.name.toLowerCase()]] && categoryMapping[categoryMappingKeysMap[defaultGroup.name.toLowerCase()]].lines || [];
            currentLinesIndexes.forEach(function (lineIndex) {
              //currLevel.id is group type (group-revenue, group-expense..)
              if (!isValidLine(lineIndex, curLevel.id)) {
                invalidLinesIndexes.add(lineIndex);
              }
            });
            if (parent.level > 0) {
              currentLinesIndexes = _lodash.default.intersection(lines, currentLinesIndexes);
            }
            var child = createStructure(defaultGroup, budgetTreeStructure.filter(function (x) {
              return x.level === curLevel.level + 1 && x.parent === curLevel.id;
            }), currentLinesIndexes, false, curLevel.parent);
            if (child.currency) {
              toConvertChild = parent.currency && parent.currency !== child.currency;
              updateParentAmounts(parent, child.amounts, child.currency, toConvertChild, child.startDate, child.endDate);
            }
          }
          runCount++;
          return defaultGroup;
        });
        parent.children = children;
        return parent;
      };
      var currentStructureNode = budgetTreeStructure.filter(function (x) {
        return x.level === node.level - 1 && x.parent === parentId;
      });
      var nodeDimensions = getGroupDimension(node, currentStructureNode && currentStructureNode.length === 1 && currentStructureNode[0].value);
      //create leaves
      if (!currentGroup.length) {
        node = createLeaves(node, lines, nodeDimensions);
      }
      //create const groups (rev,opex,cogs,other)
      else if (areConstGroups(currentGroup)) {
        node = createConstGroups(node, lines, currentGroup, nodeDimensions);
      } else {
        node = createDimensionsGroups(node, lines, currentGroup, isSub, nodeDimensions);
      }
      node.dimensions = nodeDimensions;
      return node;
    };
    _react.default.useMemo(function () {
      if (!selectedLines.length) return;
      //create dimensions to values map by groups - { dimId: { 0:{software}, 1:{qa,r&d} }, dimId2: { 0:{usa},1:{dallas, texas}} }
      Object.keys(budgetDimensionsHierarchy).forEach(function (dimensionId) {
        dimensionsToLevels[dimensionId] = {};
        budgetDimensionsHierarchy[dimensionId].forEach(function (dimension) {
          var dimLevel = dimension.level;
          if (!dimensionsToLevels[dimensionId][dimLevel]) {
            dimensionsToLevels[dimensionId][dimLevel] = new Set();
          }
          dimensionsToLevels[dimensionId][dimLevel].add(dimension.value);
        });
      });

      //create subsidiaries dimensions (dimensions before the const groups - rev, opex, cogs, other)
      var groupsLowerLevel = budgetTreeStructure.find(function (node) {
        return isConstGroup(node.id);
      }).level || 0;
      budgetTreeStructure.forEach(function (currNode) {
        if (currNode.level < groupsLowerLevel && (!subsidiariesDimensions[currNode.value] || subsidiariesDimensions[currNode.value].dimLevel < currNode.dimLevel)) {
          var subsidiaryDimension = chosenDimension.find(function (dim) {
            return dim.id === currNode.value;
          }) || [];
          subsidiariesDimensions[subsidiaryDimension.id] = {
            id: subsidiaryDimension.id,
            dimLevel: currNode.dimLevel
          };
        }
      });

      //Dimension values map
      //key -> dim values
      //value -> list of lines indexes
      chosenDimension && selectedLines.forEach(function (_ref5) {
        var lineNumber = _ref5.lineNumber;
        var currentLine = formattedFile[lineNumber];
        chosenDimension.forEach(function (dim) {
          var dimValue = currentLine[dim.key] && currentLine[dim.key].w && currentLine[dim.key].w.toString().trim();
          if (dimValue) {
            if (!dimValMap[dim.id]) {
              dimValMap[dim.id] = {};
            }
            if (!dimValMap[dim.id][dimValue]) {
              dimValMap[dim.id][dimValue] = {
                lines: []
              };
            }
            dimValMap[dim.id][dimValue].lines.push(lineNumber);
          }
        });
      });
      // sum bottom up dimnension lines
      // set dimension "parent" descendants prop to include all child lines
      Object.keys(dimValMap).forEach(function (key) {
        var maxDepth = Math.max.apply(Math, (0, _toConsumableArray2.default)(budgetDimensionsHierarchy && budgetDimensionsHierarchy[key] ? budgetDimensionsHierarchy[key].map(function (x) {
          return x.level;
        }) : [0]));
        while (maxDepth > 0) {
          var currentLevel = (budgetDimensionsHierarchy && budgetDimensionsHierarchy[key] || []).filter(function (x) {
            return x.level === maxDepth;
          });
          currentLevel.forEach(function (_ref6) {
            var value = _ref6.value,
              parent = _ref6.parent;
            var parentName = (budgetDimensionsHierarchy && budgetDimensionsHierarchy[key] || []).find(function (dimensionValue) {
              return dimensionValue.id === parent;
            }).value;
            if (dimValMap[key][parentName]) {
              if (!dimValMap[key][parentName].descendants) {
                dimValMap[key][parentName].descendants = dimValMap[key][value] ? (0, _toConsumableArray2.default)(dimValMap[key][value].lines) : null;
              } else {
                var _dimValMap$key$parent;
                (_dimValMap$key$parent = dimValMap[key][parentName].descendants).push.apply(_dimValMap$key$parent, (0, _toConsumableArray2.default)(dimValMap[key][value].lines));
              }
            }
          });
          maxDepth--;
        }
      });
    }, [dimensionHierarchy, chosenDimension, amountColumns, notAllowedLinesIndexes, selectedLines]);
    _react.default.useMemo(function () {
      if (selectedLines.length) {
        setRoot(createStructure({
          name: name,
          children: [],
          level: 0,
          amounts: {},
          total: 0
        }, budgetTreeStructure.filter(function (x) {
          return x.level === 0;
        })));
        setCountLines(runCount);
        setDimensionsOptions(currOptions);
        setCurrDimension(currOptions[0]);
        setInvalidLinesIndexes(invalidLinesIndexes);
        setShowLinesNotImportedWarning(invalidLinesIndexes.size > 0);
      }
    }, [selectedDimensions, amountColumns, categoryMapping, dimensionHierarchy, formattedFile, selectedLines, validColumnMappings, nameColumn, name]);
    _react.default.useEffect(function () {
      if (isLinesLimitExceeded(countLines)) {
        trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.BUDGET_LINES_EXCEEDED);
        dispatch(_notifActions.default.showNoty({
          text: intl.t("generic-file.step-budget-preview.too-many-lines", {
            MAX_BUDGET_LINES_ALLOWED: MAX_BUDGET_LINES_ALLOWED
          })
        }));
      }
    }, [countLines]);
    var createMismatchLinesReport = function createMismatchLinesReport() {
      var getColumnKeyValueOrText = function getColumnKeyValueOrText() {
        return amountColumns.reduce(function (columns, column) {
          columns[column.key] = "v";
          return columns;
        });
      };
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.DOWNLOAD_MISSING_LINES);
      var columnsToExport = [nameColumn].concat((0, _toConsumableArray2.default)(validColumnMappings.map(function (column) {
        return column.key;
      })));
      var report = _importGenericFile.default.createMismatchLinesReport(formattedFile, headerLineNumber, columnsToExport, (0, _toConsumableArray2.default)(invalidLinesIndexes).sort(function (a, b) {
        return a - b;
      }), getColumnKeyValueOrText());

      //export missings to excel - send export action -> download report
      getDataAction(stepName, {
        report: report
      });
      return report;
    };
    var nextHandler = function nextHandler() {
      dispatch(_notifActions.default.hideNoty());
      trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.CREATED_BUDGET);
      endTableAction(stepName, {
        budgetStructure: root,
        budgetRoot: budgetRoot,
        dimensionHierarchy: budgetDimensionsHierarchy,
        selectedDimensions: selectedDimensions,
        categoryMapping: categoryMapping,
        currencyColumnKey: currencyColumnKey,
        fileCurrenciesToSIBP: fileCurrenciesToSIBP,
        mainCurrency: mainCurrency,
        accountColumnKey: accountColumnKey
      });
    };
    var BudgetLineWithTotal = function BudgetLineWithTotal(item) {
      var name = item.name,
        total = item.total,
        currency = item.currency;
      return _react.default.createElement("div", {
        className: "tree-budget-line"
      }, _react.default.createElement("span", {
        className: "name"
      }, name), showTotalAmounts && _react.default.createElement("span", {
        className: "total"
      }, isNaN(total) ? "-" : _const.default.formatAmount(total, currency)));
    };
    var buildTree = function buildTree(item, index) {
      var isFolder = function isFolder(item, index) {
        return index === 0 || item.children && item.children.length > 0;
      };
      if (isFolder(item, index)) {
        return _react.default.createElement(Folder, {
          isOpen: true,
          content: function content() {
            return BudgetLineWithTotal(item);
          },
          key: index
        }, item.children.map(function (item, childIndex) {
          return buildTree(item, "".concat(index, "-").concat(childIndex));
        }));
      } else {
        return _react.default.createElement(File, {
          key: index,
          content: function content() {
            return BudgetLineWithTotal(item);
          }
        });
      }
    };
    var shouldDisplayGroupTree = function shouldDisplayGroupTree(group) {
      return group.children && group.children.length > 0;
    };
    var createBudgetGroupDatahook = function createBudgetGroupDatahook(name) {
      return name.split(/[ ]+/).join("-").toLowerCase() + "-group-container";
    };
    var isSelectValueChanged = function isSelectValueChanged(selectedDimension) {
      // user switched dimension entity view
      return selectedDimension.label !== currDimension.label;
    };
    var customStyles = {
      option: function option(provided) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, provided), {}, {
          color: "#334356"
        });
      }
    };
    var buttons = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-secondary-button",
      className: "footer-button secondary back big-button",
      text: intl.t("generic-file.back"),
      onClick: function onClick() {
        dispatch(_notifActions.default.hideNoty());
        dispatch(_stepsActions.default.prevStep());
      }
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      dataHook: "footer-primary-button",
      className: "footer-button primary approve big-button",
      text: intl.t("generic-file.import"),
      onClick: nextHandler,
      disabled: isLinesLimitExceeded(countLines)
    }));
    var footer = {
      buttons: buttons
    };
    var body = _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "header"
    }, _react.default.createElement("div", {
      className: "left-header-side"
    }, _react.default.createElement("label", {
      className: "header-name"
    }, name), hasDimensionsOptions && _react.default.createElement("span", {
      className: "arrow-hierarchy"
    }, ">", showPreviewStepWalkthrough && hasDimensionsOptions ? _react.default.createElement(_uiComponents.default.COMPONENTS.PopupWithLabel, {
      dataHook: "walk-through-tooltip",
      className: "walk-through-tooltip ".concat(name.length < minimumNumberOfLettersToFitWalkthrough ? "popup-min-left" : ""),
      defaultPopupIsOpen: true,
      closeOnLeave: false,
      popupContent: _react.default.createElement("div", {
        className: "popup-content"
      }, _react.default.createElement("p", null, intl.t("generic-file.step-budget-preview.walkthrough")), _react.default.createElement("button", {
        onClick: function onClick() {
          dispatch(_budgetActions.default.setShouldShowPreviewWalkthrough(false));
        }
      }, intl.t("generic-file.step-budget-preview.got-it")))
    }) : null), hasDimensionsOptions && _react.default.createElement(_uiComponents.default.COMPONENTS.Select, {
      hideSelectedOptions: false,
      value: currDimension,
      onChange: function onChange(selectedDimension) {
        isSelectValueChanged(selectedDimension) && trackEvents.trackEvent(trackEvents.events.GENERIC_FILE.PREVIEW_VIEW_SWITCH);
        setCurrDimension(selectedDimension);
      },
      options: dimensionsOptions,
      classNames: "dimension-select",
      dataHook: "dimensions-preview-select",
      isClearable: false,
      isSearchable: false,
      styles: customStyles
    })), _react.default.createElement("div", {
      className: "right-header-side"
    }, _react.default.createElement("div", {
      className: "total-lines"
    }, _react.default.createElement("div", {
      className: "text"
    }, intl.t("generic-file.step-budget-preview.total-lines") + countLines), showLinesNotImportedWarning && _react.default.createElement(_uiComponents.default.COMPONENTS.PopupWithLabel, {
      dataHook: "preview-error-popup",
      className: "error-container",
      tooltipContent: _react.default.createElement(_uiComponents.default.IMAGES.WarningSvg, null),
      popupContent: _react.default.createElement("div", {
        className: "message"
      }, intl.t("generic-file.step-budget-preview.budget-lines-not-imported-warning"), _react.default.createElement("div", {
        className: "download-section"
      }, _react.default.createElement("span", {
        className: "link",
        onClick: createMismatchLinesReport
      }, intl.t("generic-file.step-budget-preview.download-section.download-link")), intl.t("generic-file.step-budget-preview.download-section.download-description")))
    })), _react.default.createElement("div", {
      className: "divider"
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Switch, {
      dataHook: "preview-toggle-switch",
      text: intl.t("generic-file.step-budget-preview.show-totals"),
      isChecked: showTotalAmounts,
      onChange: function onChange() {
        return setShowTotalAmounts(!showTotalAmounts);
      }
    }))), _react.default.createElement("div", {
      className: "budget-tree-body"
    }, currDimension && currDimension.value.children.map(function (group) {
      return _react.default.createElement("div", {
        key: group.name,
        "data-hook": createBudgetGroupDatahook(group.name),
        className: "single-budget-type-container"
      }, _react.default.createElement("div", {
        className: "budget-type"
      }, group.name), shouldDisplayGroupTree(group) ? _react.default.createElement(Tree, null, buildTree(group, 0)) : _react.default.createElement("div", {
        className: "empty-lines"
      }, _react.default.createElement(_uiComponents.default.IMAGES.EmptySvg, null), _react.default.createElement("div", {
        className: "title"
      }, intl.t("generic-file.step-budget-preview.empty-lines"))));
    })));
    return _react.default.createElement(_uiComponents.default.COMPONENTS.ModalBase, {
      content: {
        footer: footer,
        body: body
      },
      options: options
    });
  }
});