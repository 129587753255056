define("budgeta/admin/utils/const", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.USER_STATUS = _exports.USER_ROLE = _exports.USER_ADMIN_ACTION = _exports.SESSION_EXPIRED_COOKIE = _exports.LICENSE_TYPE = _exports.HTTP_STATUS_CODE = _exports.COMPANY_USER_TYPE = _exports.COMPANY_USER_STATUS = _exports.COMPANY_USER_ROLE = _exports.COMPANY_STATUS = _exports.COMPANY_ORIGIN = _exports.AUTH_PROVIDERS = _exports.AUDIT_LOG = _exports.ADMIN_CONSOLE_USER_STATUS = _exports.ADMIN_APP_ACTION = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var COMPANY_STATUS = _exports.COMPANY_STATUS = Object.freeze({
    ACTIVE: "active",
    INACTIVE: "inactive"
  });
  var LICENSE_TYPE = _exports.LICENSE_TYPE = Object.freeze({
    ADMIN: "admin",
    EDIT: "edit",
    VIEW: "view"
  });
  var USER_ROLE = _exports.USER_ROLE = Object.freeze({
    ADMIN: "admin",
    SUPER_ADMIN: "super-admin",
    UNLIMITED: "unlimited"
  });
  var USER_STATUS = _exports.USER_STATUS = Object.freeze({
    CHANGE_PASS: "change_pass",
    PENDING: "pending",
    EXPIRED: "expired"
  });
  var COMPANY_USER_TYPE = _exports.COMPANY_USER_TYPE = Object.freeze({
    USER: {
      text: intl.t("general.text_1695611608782"),
      value: "user"
    },
    CONSULTANT: {
      text: intl.t("general.text_1695611608772"),
      value: "consultant"
    },
    SUPPORT: {
      text: intl.t("general.name_1695611602647"),
      value: "support"
    }
  });
  var ADMIN_CONSOLE_USER_STATUS = _exports.ADMIN_CONSOLE_USER_STATUS = Object.freeze({
    ACTIVE: {
      text: intl.t("general.text_1695611608731"),
      value: "active"
    },
    INACTIVE: {
      text: intl.t("general.text_1695611608720"),
      value: "inactive"
    }
  });
  var COMPANY_USER_STATUS = _exports.COMPANY_USER_STATUS = Object.freeze({
    ACTIVE: {
      text: intl.t("general.text_1695611608731"),
      value: "active"
    },
    INACTIVE: {
      text: intl.t("general.text_1695611608720"),
      value: "inactive"
    }
  });
  var AUDIT_LOG = _exports.AUDIT_LOG = Object.freeze({
    USER_DETAILS: intl.t("general.user_details_1698234255020"),
    USER_PREFIX: intl.t("general.user__1698234255019"),
    COMPANY_PREFIX: intl.t("general.company__1698234255019")
  });
  var COMPANY_USER_ROLE = _exports.COMPANY_USER_ROLE = Object.freeze({
    COMPANY_ADMIN: "company-admin",
    COMPANY_USER: "company-user"
  });
  var HTTP_STATUS_CODE = _exports.HTTP_STATUS_CODE = Object.freeze({
    NOT_FOUND: 404,
    EMAIL_ALREADY_EXISTS: 409,
    UNAUTHORIZED: 401,
    INTERNAL: 5 // catch 500-599
  });
  var ADMIN_APP_ACTION = _exports.ADMIN_APP_ACTION = Object.freeze({
    ADD_COMPANY: "ADD_COMPANY",
    EDIT_COMPANY: "EDIT_COMPANY",
    DISABLE_COMPANY: "DISABLE_COMPANY",
    DELETE_COMPANY: "DELETE_COMPANY",
    ADD_COMPANY_USER: "ADD_COMPANY_USER",
    EDIT_COMPANY_USER: "EDIT_COMPANY_USER",
    DELETE_COMPANY_USER: "DELETE_COMPANY_USER",
    ADD_ADMIN_USER: "ADD_ADMIN_USER",
    DELETE_ADMIN_USER: "DELETE_ADMIN_USER",
    EDIT_ADMIN_USER: "EDIT_ADMIN_USER"
  });
  var USER_ADMIN_ACTION = _exports.USER_ADMIN_ACTION = Object.freeze({
    ADD_COMPANY_USER: "ADD_COMPANY_USER",
    EDIT_COMPANY_USER: "EDIT_COMPANY_USER",
    DELETE_COMPANY_USER: "DELETE_COMPANY_USER"
  });
  var COMPANY_ORIGIN = _exports.COMPANY_ORIGIN = Object.freeze({
    SI: {
      value: "SI",
      text: intl.t("general.text_1695611608710")
    },
    STANDALONE: {
      value: "standalone",
      text: intl.t("general.text_1695611608699")
    },
    SIAP: {
      value: "siap",
      text: intl.t("general.text_1695611608689")
    },
    MEDC: {
      value: "medc",
      text: intl.t("general.text_1695611608678")
    },
    OTHER: {
      value: "other",
      text: intl.t("general.name_1695611601958")
    }
  });
  var SESSION_EXPIRED_COOKIE = _exports.SESSION_EXPIRED_COOKIE = "AdminApp:sessionExpired";
  var AUTH_PROVIDERS = _exports.AUTH_PROVIDERS = Object.freeze({
    SIP: "SIP",
    SIF: "SIF" //intacct (sso by sage-id)
  });
});