define("budgeta/templates/components/svg/excel-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3GcsNqBR",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"fill-rule\",\"evenodd\"],[\"static-attr\",\"clip-rule\",\"evenodd\"],[\"static-attr\",\"d\",\"M8.04023 0.5L8.04023 5.14C8.04023 6.1 8.84526 6.9 9.73079 6.9L14.4 6.9L14.4 14.74C14.4 15.7 13.5949 16.5 12.6289 16.5L3.37104 16.5C2.40501 16.5 1.59998 15.7 1.59998 14.66L1.59998 2.26C1.59998 1.3 2.40501 0.5 3.37104 0.5L8.04023 0.5ZM10.64 9.46C10.88 9.22 10.88 8.9 10.64 8.66C10.4 8.42 9.99998 8.42 9.83998 8.66L7.99998 10.5L6.15998 8.66C6.07998 8.58 5.91997 8.5 5.75997 8.5C5.64285 8.5 5.56859 8.54287 5.47444 8.59723C5.43997 8.61713 5.40285 8.63856 5.35998 8.66C5.11998 8.9 5.11998 9.22 5.35998 9.46L7.19998 11.3L5.35998 13.14C5.11998 13.38 5.11998 13.7 5.35998 13.94C5.43998 14.02 5.59998 14.1 5.75997 14.1C5.91997 14.1 6.07998 14.02 6.15998 13.94L7.99998 12.1L9.83998 13.94C9.91998 14.02 10.08 14.1 10.24 14.1C10.3571 14.1 10.4314 14.0571 10.5255 14.0028C10.56 13.9829 10.5971 13.9614 10.64 13.94C10.88 13.7 10.88 13.38 10.64 13.14L8.79998 11.3L10.64 9.46Z\"],[\"static-attr\",\"fill\",\"black\"],[\"static-attr\",\"fill-opacity\",\"0.65\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M14.4 6.1L9.51997 6.1C9.11997 6.1 8.79998 5.78 8.79998 5.38L8.79998 0.5L14.4 6.1Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"    \"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/excel-svg.hbs"
    }
  });
});