define("budgeta/admin/company-admin-app/components/body/tabs", ["exports", "budgeta/admin/company-admin-app/routes", "budgeta/services/ember-service-container"], function (_exports, _routes, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var intl = new _emberServiceContainer.default().lookup("intl");
  var tabs = [{
    name: intl.t("company_admin_app.nav-bar.users"),
    id: "users",
    route: _routes.ROUTES.USER_LIST,
    baseInnerRoutes: [_routes.ROUTES.USER_ADD, _routes.ROUTES.USER_EDIT]
  }, {
    name: intl.t("company_admin_app.nav-bar.auditLog"),
    id: "auditLog",
    route: _routes.ROUTES.AUDIT_LOGGING_LIST,
    onClick: function onClick() {
      return trackEvents.trackEvent(trackEvents.events.COMPANY_ADMIN.ENTER_AUDIT_LOG);
    }
  }];
  var _default = _exports.default = tabs;
});