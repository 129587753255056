define("budgeta/templates/components/html5-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1iM20LvO",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"input\"],[[\"helper\",[\"-input-type\"],[[\"helper\",[\"or\"],[[\"get\",[null,\"type\"]],\"text\"],null]],null]],[[\"id\",\"class\",\"type\",\"value\",\"name\",\"input\",\"key-down\",\"key-up\",\"focus-out\",\"required\",\"step\",\"min\",\"max\",\"pattern\",\"minlength\",\"maxlength\",\"placeholder\",\"title\",\"readonly\"],[[\"get\",[null,\"inputId\"]],[\"helper\",[\"concat\"],[\"ember-view ember-text-field \",[\"get\",[null,\"class\"]]],null],[\"helper\",[\"or\"],[[\"get\",[null,\"type\"]],\"text\"],null],[\"get\",[null,\"value\"]],[\"get\",[null,\"name\"]],[\"helper\",[\"action\"],[[\"get\",[null]],\"_onInput\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"_onKeyDown\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"_onKeyUp\"],null],[\"helper\",[\"action\"],[[\"get\",[null]],\"_onFocusOut\"],null],[\"get\",[null,\"required\"]],[\"get\",[null,\"step\"]],[\"get\",[null,\"min\"]],[\"get\",[null,\"max\"]],[\"get\",[null,\"pattern\"]],[\"get\",[null,\"minlength\"]],[\"get\",[null,\"maxlength\"]],[\"get\",[null,\"placeholder\"]],[\"get\",[null,\"title\"]],[\"get\",[null,\"readonly\"]]]]],false],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[null,\"error\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"p\",[]],[\"static-attr\",\"class\",\"input-error\"],[\"static-attr\",\"role\",\"alert\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"get\",[null,\"error\"]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/html5-input.hbs"
    }
  });
});