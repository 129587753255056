define("budgeta/initializers/hack-scroll-spy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function hackScrollSpy( /* application */
  ) {
    // Allows scroll spy to work without change URL fragment
    Ember.$(document).on("click", function (e) {
      Ember.run(function () {
        Ember.$('[data-spy="scroll"]').each(function () {
          var spyTarget = Ember.$(this).data("target");
          var $target = Ember.$(e.target);
          if ($target.closest(spyTarget).length) {
            e.preventDefault();
            var $anchor = Ember.$($target.attr("href"));
            if ($anchor.length) {
              Ember.$(this).scrollTo($target.attr("href"), 200);
            }
          }
        });
      });
    });
  }
  var _default = _exports.default = {
    name: "hack-scroll-spy",
    initialize: hackScrollSpy
  };
});