define("budgeta/templates/components/pct-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wPIGa6JW",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"custom-amount no-border\"],[\"flush-element\"],[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"green\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"label\"]],false],[\"close-element\"],[\"close-element\"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"custom-amount no-border\"],[\"flush-element\"],[\"text\",\" \"],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"items\"]]],null,0],[\"yield\",\"default\"],[\"text\",\"\\n\"],[\"open-element\",\"label\",[]],[\"static-attr\",\"class\",\"error\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"error\"]],false],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"custom-amount\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"unknown\",[\"item\",\"label\"]],false],[\"close-element\"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"readonly\",\"type\",\"class\",\"name\",\"value\",\"min\",\"max\",\"step\"],[[\"get\",[\"readOnly\"]],\"number\",[\"get\",[\"inputClass\"]],[\"get\",[\"key\"]],[\"get\",[\"item\",\"value\"]],0,100,\"any\"]]],false],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"text\",\"%\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"item\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/pct-array.hbs"
    }
  });
});