define("budgeta/transforms/budget-attributes", ["exports", "@babel/runtime/helpers/esm/typeof", "ember-data", "budgeta/utils/const"], function (_exports, _typeof2, _emberData, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var deserializeObject = function deserializeObject(result) {
    Object.keys(result).forEach(function (key) {
      var value = result[key];
      if (_const.default.isDateString(value)) {
        Ember.set(result, key, _const.default.deserializeDate(value));
      } else if (Ember.isArray(value)) {
        value.forEach(function (val) {
          if (val && (0, _typeof2.default)(val) === "object") {
            deserializeObject(val);
          }
        });
      }
    });
  };
  var serializeObject = function serializeObject(result) {
    Object.keys(result).forEach(function (key) {
      var value = result[key];
      if (value && value.constructor.name === "Date") {
        Ember.set(result, key, _const.default.serializeDate(value));
      } else if (Ember.isArray(value)) {
        value.forEach(function (val) {
          if (val && (0, _typeof2.default)(val) === "object") {
            serializeObject(val);
          }
        });
      }
    });
  };
  var _default = _exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var result = serialized || {};
      deserializeObject(result);
      return result;
    },
    serialize: function serialize(deserialized) {
      var result = deserialized || {};
      result = _const.default.deepClone(result); // Making a clone of the object, to prevent the start date to be a string
      serializeObject(result);
      return result;
    }
    /*
    serialize: function(value) {
      if (value) {
        return (new Date(value)).addMinutes(-value.getTimezoneOffset()).toJSON();
      } else {
        return null;
      }
    },
     deserialize: function(value) {
      if (value) {
        let d = new Date(value);
         // dirty fix for transactions that were saved before the timezone fix
        if (value.indexOf("T21:00:00.000Z") > 0 || value.indexOf("T22:00:00.000Z") > 0) {
          let d1 = new Date(value).addDays(1);
          if (d1.getMonth() !== d.getMonth()) {
            return d1.beginningOfMonth();
          }
        }
         return value.indexOf("T00:00:00.000Z") > 0 ? d.addMinutes(d.getTimezoneOffset()) : d; // BUD-4349 only add the timezone offset if it was substracted in the first place
      } else {
        return null;
      }
    }
    */
  });
});