define("budgeta/initializers/qtip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    Ember.$.fn.qtip.defaults.style.classes = "qtip-light";
    Ember.$.fn.qtip.defaults.style.tip.corner = false;
    Ember.$.fn.qtip.defaults.position.adjust.x = 20;
  }
  var _default = _exports.default = {
    name: "qtip",
    initialize: initialize
  };
});