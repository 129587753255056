define("budgeta/admin/company-admin-app/routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ROUTES = void 0;
  var ROUTES = _exports.ROUTES = {
    USER_ADD: "/user/add",
    USER_LIST: "/users",
    USER_EDIT: "/user/edit/:userId",
    AUDIT_LOGGING_LIST: "/audit-logging"
  };
});