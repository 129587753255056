define("budgeta/templates/components/svg/import-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Wkabrbot",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M13.6569 2.84317C10.5456 -0.268098 5.45443 -0.268098 2.34316 2.84317C-0.768113 5.95444 -0.768113 11.0456 2.34316 14.1569C5.45443 17.2682 10.5456 17.2682 13.6569 14.1569C16.7681 11.0456 16.7681 5.95444 13.6569 2.84317ZM11.1678 12.1204C11.1226 12.1657 11.0411 12.1747 10.9524 12.1765L6.35952 12.177C6.13325 12.177 5.96354 12.1204 5.79384 11.9507C5.28472 11.4416 5.6807 10.5931 6.35952 10.5931L8.96168 10.5931L4.66247 6.29385C4.32306 5.95444 4.32306 5.50189 4.66247 5.16248C5.00188 4.82307 5.45443 4.82307 5.79384 5.16248L10.093 9.46169L10.093 6.85954C10.093 6.18072 10.9416 5.78474 11.4507 6.29385C11.6204 6.46356 11.677 6.63326 11.677 6.85954L11.677 11.385C11.677 11.4982 11.6204 11.5547 11.6204 11.6679C11.5073 11.8941 11.3376 12.0638 11.1678 12.1204Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"    \"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/import-svg.hbs"
    }
  });
});