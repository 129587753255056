define("budgeta/templates/components/confirm-scenario-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mcdJIKW/",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"confirm-scenario-delete.message\"],null],false],[\"text\",\"\\n\"],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"append\",[\"helper\",[\"t\"],[\"confirm-scenario-delete.are-you-sure\"],null],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/confirm-scenario-delete.hbs"
    }
  });
});