define("budgeta/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.RESTSerializer.extend({
    primaryKey: "_id",
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      var key = relationship.key;
      var payloadKey = this.keyForRelationship ? this.keyForRelationship(key, "hasMany") : key;
      json[payloadKey] = record.hasMany(key, {
        ids: true
      }) ? record.hasMany(key, {
        ids: true
      }).filter(function (v) {
        return v;
      }) : [];
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.merge(hash, this.serialize(record, options));
    }
  });
});