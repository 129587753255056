define("budgeta/components/currency-input-with-type", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    typeIsCurrency: Ember.computed.alias("budget.budgetAttributesWithScenarios.bonusIsFixed"),
    budgetCurrency: Ember.computed("budget.budgetAttributesWithScenarios.currency", "budget.inheritedAttributes.currency", function () {
      return this.get("budget.budgetAttributesWithScenarios.currency") || this.get("budget.inheritedAttributes.currency");
    }),
    type: Ember.computed("typeIsCurrency", "value", {
      get: function get() {
        return this.get("typeIsCurrency") ? "currency" : "percentage";
      },
      set: function set(key, value) {
        if (value === "currency") {
          if (this.get("value")) {
            this.set("value", this.get("value") * 100);
          }
          this.set("typeIsCurrency", true);
        } else {
          if (this.get("value")) {
            this.set("value", this.get("value") / 100);
          }
          this.set("typeIsCurrency", false);
        }
        this.sendAction("attributeChanged");
      }
    }),
    typeOptions: Ember.computed("budgetCurrency", function () {
      var currencySymbol = _const.default.currency_symbols[this.get("budgetCurrency")];
      return [{
        text: "%",
        id: "percentage"
      }, {
        text: currencySymbol,
        id: "currency"
      }];
    }),
    currency: Ember.computed("typeIsCurrency", function () {
      return this.get("typeIsCurrency") ? null : "%";
    }),
    convertedPlaceholder: Ember.computed("placeholder", "currency", function () {
      return this.get("currency") === "%" && !isNaN(this.get("placeholder")) ? this.get("placeholder") * 100 : this.get("placeholder");
    }),
    actions: {
      attributeChanged: function attributeChanged() {
        this.sendAction("attributeChanged");
      },
      openFormulaEditor: function openFormulaEditor(component) {
        this.sendAction("openFormulaEditor", component);
      },
      onInput: function onInput(value) {
        if (!value) {
          this.set("typeIsCurrency", this.get("placeholderIsFixed"));
        }
      }
    }
  });
});