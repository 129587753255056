define("budgeta/lib/cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    setCookie: function setCookie(key, value, options) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          Ember.$.cookie(key, value, options);
          Ember.run(null, resolve);
        } catch (e) {
          Ember.run(null, reject, e);
        }
      });
    },
    getCookie: function getCookie(key) {
      return Ember.$.cookie(key);
    },
    removeCookie: function removeCookie(key, options) {
      return Ember.$.removeCookie(key, options);
    }
  });
});