define("budgeta/components/kpi-chart", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/dashboard", "budgeta/config/environment"], function (_exports, _emberServiceContainer, _dashboard, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects */
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":kpi-chart", "isInteractiveDashboardType:interactive:"],
    kpiData: Ember.computed("budgetaChartData", "kpiSelection.@each.selected", "highchartCurrency", "scenario", "isBudgetVsScenario", "forecastViewOption", "isFvB", "isRollingForecastViewType", function () {
      var highchartCurrency = this.get("highchartCurrency");
      var objectData = this.get("budgetaChartData");
      var kpiSelection = this.get("kpiSelection");
      var kpiData = _dashboard.default.convertKPIData(objectData, highchartCurrency, kpiSelection, {
        scenario: this.get("scenario"),
        isBudgetVsScenario: this.get("isBudgetVsScenario"),
        forecastViewOption: this.get("forecastViewOption"),
        isFvB: this.get("isFvB")
      });
      if (kpiData.length === 3 || kpiData.length === 5) {
        kpiData.push({
          emptyData: true
        });
      }
      if (this.get("isRollingForecastViewType")) {
        kpiData.forEach(function (data) {
          if (Ember.get(data, "name")) {
            Ember.set(data, "name", intl.t("general.name_1695611603445"));
          }
        });
      }
      return kpiData;
    }),
    attributeBindings: ["data-highchart-data"],
    "data-highchart-data": Ember.computed("kpiData", function () {
      if (_environment.default.environment === "development" || _environment.default.environment === "staging" || _environment.default.budgetaEnvironment === "qa" || _environment.default.budgetaEnvironment === "dev") {
        return (JSON.stringify(this.get("kpiData")) || "").replace(new RegExp('"', "g"), "'");
      }
      return "";
    })
  });
});