define("budgeta/templates/components/chart-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+pRZu/qm",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"chart\",\"filters\",\"byOptions\"]]],null,0],[\"text\",\"\\n\"],[\"append\",[\"helper\",[\"chart-master\"],null,[[\"chart\",\"secondSeriesName\",\"scenario\",\"isBudgetVsScenario\",\"isFvB\",\"isRollingForecastViewType\",\"forecastViewOptionName\",\"forecastViewOption\",\"isInteractiveDashboardType\",\"ignoreTooltip\",\"isExpanded\",\"inZoom\"],[[\"get\",[\"chart\"]],[\"get\",[\"chart\",\"secondSeriesName\"]],[\"get\",[\"chart\",\"scenario\"]],[\"get\",[\"chart\",\"isBudgetVsScenario\"]],[\"get\",[\"chart\",\"isFvB\"]],[\"get\",[\"chart\",\"isRollingForecastViewType\"]],[\"get\",[\"chart\",\"forecastViewOptionName\"]],[\"get\",[\"chart\",\"forecastViewOption\"]],[\"get\",[\"chart\",\"isInteractiveDashboardType\"]],false,true,true]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"classNames\",\"searchEnabled\",\"content\",\"value\",\"optionIdPath\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\"],[\"short-value-size wrap\",false,[\"get\",[\"chart\",\"filters\",\"byOptions\"]],[\"get\",[\"chart\",\"filters\",\"by\"]],\"value\",\"value\",\"name\",[\"get\",[\"chart\",\"filters\",\"byPrompt\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/chart-modal.hbs"
    }
  });
});