define("budgeta/controllers/budget-template-code", ["exports", "budgeta/controllers/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modal.default.extend({
    confirmDisabled: Ember.computed.empty("model.code")
  });
});