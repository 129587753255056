define("budgeta/components/single-kpi", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/dashboard", "budgeta/config/environment"], function (_exports, _emberServiceContainer, _dashboard, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects */
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":single-kpi-chart", "isInteractiveDashboardType:interactive:"],
    kpi: Ember.computed("budgetaChartData", "isRollingForecastViewType", function () {
      var highchartCurrency = this.get("highchartCurrency");
      var objectData = this.get("budgetaChartData");
      var kpiData = _dashboard.default.convertSingleData(objectData, highchartCurrency);
      if (this.get("isRollingForecastViewType") && Ember.get(kpiData, "name")) {
        Ember.set(kpiData, "name", intl.t("general.name_1695611603445"));
      }
      return kpiData;
    }),
    attributeBindings: ["data-highchart-data"],
    "data-highchart-data": Ember.computed("kpi", function () {
      if (_environment.default.environment === "development" || _environment.default.environment === "staging" || _environment.default.budgetaEnvironment === "qa" || _environment.default.budgetaEnvironment === "dev") {
        return (JSON.stringify(this.get("kpi")) || "").replace(new RegExp('"', "g"), "'");
      }
      return "";
    })
  });
});