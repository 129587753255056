define("budgeta/components/import-account-mapping", ["exports", "budgeta/components/import-value"], function (_exports, _importValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _importValue.default.extend({
    classNames: ["column", "inline-select"],
    searchInChildren: false,
    // In the case we want to use a seperator in select-2, then we need to display the children
    copyFromAccount: undefined,
    isMappingTypeOption: Ember.computed("mapping", "mapping.@each.selectedAttribute", "mapping.length", {
      get: function get() {
        var mappingType;
        var headerColumn = this.get("mapping").findBy("key", this.get("value.headerKey"));
        if (headerColumn && headerColumn.get("selectedAttribute")) {
          mappingType = headerColumn.get("selectedAttribute");
          return mappingType === "mappingType";
        }
      }
    }),
    isHeaderSelected: Ember.computed("mapping", "mapping.@each.selectedAttribute", "value.headerKey", function () {
      var headerColumn = this.get("mapping").findBy("key", this.get("value.headerKey"));
      return headerColumn && headerColumn.get("selectedAttribute");
    }),
    readyContent: Ember.computed("isHeaderSelected", "mapping", "mapping.@each.selectedAttribute", "value.value.w", "mapping.length", {
      get: function get() {
        return this.get("value.mapping");
      },
      set: function set(key, value) {
        this.set("value.mapping", value);
        if (value === "clone") {
          this.set("value.copyFromAccount", this.get("copyFromAccount"));
        } else {
          this.set("value.copyFromAccount", undefined);
        }
        return value;
      }
    }),
    content: Ember.computed("mapping.@each.selectedAttribute", "value.headerKey", "valuesArray.@each.mapping", function () {
      var headerColumn = this.get("mapping").findBy("key", this.get("value.headerKey"));
      if (headerColumn && headerColumn.get("selectedAttribute")) {
        var columnType = headerColumn.get("selectedAttribute");
        if (columnType === "type") {
          return this.get("accountTypeOptions");
        } else if (columnType === "mappingType") {
          if (this.get("valuesArray").filterBy("mapping", "bs").filterBy("mappingType", "type").length) {
            if (this.get("readyContent") === "multi") {
              this.set("readyContent", "single");
            }
            return this.get("accountMappingTypeOptions").filter(function (item) {
              return item.id !== "multi";
            });
          }
          return this.get("accountMappingTypeOptions");
        } else if (columnType === "dimVal") {
          return this.get("dimensionValuesOptions");
        }
      }
    }),
    actions: {
      attributeChanged: function attributeChanged() {
        var mapping = this.get("readyContent");
        this.set("value.mapping", mapping);
      }
    }
  });
});