define("budgeta/templates/components/integration-wizard-export-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AmlMSqh9",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"wizard-question-radio-simple\"],null,[[\"value\",\"selectedOption\",\"label\"],[\"add\",[\"get\",[\"model\",\"exportSelection\"]],[\"helper\",[\"t\"],[\"general.add_to_an_existing_budget_in_sage_intacct_1698824574744\"],null]]]],false],[\"text\",\"\\n\"],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"wizard-question-selection\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"wizard-question-radio-simple\"],null,[[\"value\",\"selectedOption\",\"label\"],[\"create\",[\"get\",[\"model\",\"exportSelection\"]],[\"helper\",[\"t\"],[\"general.create_a_new_budget_in_sage_intacct_1698824574741\"],null]]]],false],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/integration-wizard-export-selection.hbs"
    }
  });
});