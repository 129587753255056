define("budgeta/validators/local/date-valid", ["exports", "budgeta/services/ember-service-container", "ember-validations/validators/base"], function (_exports, _emberServiceContainer, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = _base.default.extend({
    call: function call() {
      if (!Ember.isBlank(this.model.get(this.property))) {
        var d = Date.create(this.model.get(this.property));
        if (!d.isValid()) {
          this.errors.pushObject(this.options.message || intl.t("general.invalid_date_1698234257413"));
        }
      }
    }
  });
});