define("budgeta/admin/utils/userUtil", ["exports", "budgeta/admin/utils/const", "budgeta/services/ember-service-container"], function (_exports, _const, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isUserOwner = _exports.isUserNoLongerOwner = _exports.isUserBecameOwner = _exports.isNewUserOwner = _exports.getUserChangeNotification = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var isUserBecameOwner = _exports.isUserBecameOwner = function isUserBecameOwner(oldUserRoles, newUserRoles) {
    if (oldUserRoles && newUserRoles) {
      return oldUserRoles[0] === _const.COMPANY_USER_ROLE.COMPANY_USER && newUserRoles[0] === _const.COMPANY_USER_ROLE.COMPANY_ADMIN;
    }
    return false;
  };
  var isUserNoLongerOwner = _exports.isUserNoLongerOwner = function isUserNoLongerOwner(oldUserRoles, newUserRoles) {
    if (oldUserRoles && newUserRoles) {
      return oldUserRoles[0] === _const.COMPANY_USER_ROLE.COMPANY_ADMIN && newUserRoles[0] === _const.COMPANY_USER_ROLE.COMPANY_USER;
    }
  };
  var isNewUserOwner = _exports.isNewUserOwner = function isNewUserOwner(userRoles) {
    if (userRoles) {
      return userRoles[0] === _const.COMPANY_USER_ROLE.COMPANY_ADMIN;
    }
    return false;
  };
  var isUserOwner = _exports.isUserOwner = function isUserOwner(userRoles) {
    if (userRoles) {
      return userRoles[0] === _const.COMPANY_USER_ROLE.COMPANY_ADMIN;
    }
  };
  var getUserChangeNotification = _exports.getUserChangeNotification = function getUserChangeNotification(userRoles, isEdit) {
    if (isEdit && isUserOwner(userRoles)) return intl.t("admin.notifications.edit-company-owner");else if (isEdit && !isUserOwner(userRoles)) return intl.t("admin.notifications.edit-company-user");else if (!isEdit && !isUserOwner(userRoles)) return intl.t("admin.notifications.add-company-user");else if (!isEdit && isUserOwner(userRoles)) return intl.t("admin.notifications.add-company-owner");
  };
});