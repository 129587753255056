define("budgeta/templates/components/select-2-lazy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cAfwl8oS",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"active\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"span\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"select-2-lazy-inactive-label \",[\"helper\",[\"if\"],[[\"get\",[\"enabled\"]],\"enabled\"],null],\" \",[\"helper\",[\"if\"],[[\"get\",[\"isPlaceHolder\"]],\"placeholder\"],null]]]],[\"dynamic-attr\",\"title\",[\"concat\",[[\"unknown\",[\"selectedLabel\"]]]]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"activate\"]],[\"flush-element\"],[\"append\",[\"unknown\",[\"selectedLabel\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"onOpenAction\",\"didSelect\",\"searchEnabled\",\"placeholder\",\"enabled\",\"content\",\"value\",\"optionIdPath\",\"optionValuePath\",\"optionLabelPath\",\"optionHeadlinePath\",\"cssClass\",\"title\"],[\"onOpenAction\",[\"get\",[\"didSelect\"]],[\"get\",[\"searchEnabled\"]],[\"get\",[\"placeholder\"]],[\"get\",[\"enabled\"]],[\"get\",[\"content\"]],[\"get\",[\"value\"]],[\"get\",[\"optionIdPath\"]],[\"get\",[\"optionValuePath\"]],[\"get\",[\"optionLabelPath\"]],[\"get\",[\"optionLabelPath\"]],[\"get\",[\"cssClass\"]],[\"get\",[\"selectedLabel\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/select-2-lazy.hbs"
    }
  });
});