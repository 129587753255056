define("budgeta/components/header-subtitle-bva", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    tagName: "span",
    intl: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    label: Ember.computed("viewType", "index", "checkActuals", "hasActuals", "workingBudgetLabel", "isForecastViewType", "item.includedInForecast", "budgetService.version", function () {
      var workingBudgetLabel = this.get("workingBudgetLabel") || this.get("intl").t("preview-header.working");
      if (this.get("isForecastViewType")) {
        workingBudgetLabel = intl.t("general.text_1695611610579");
      }
      if (this.get("hasActuals") && (!this.get("budgetService.version") || this.get("item.includedInForecast"))) {
        return this.get("intl").t("preview-header.actuals");
      }
      if (this.get("viewType") !== "bs") {
        return workingBudgetLabel;
      }
      if (!this.get("checkActuals") || !this.get("rollingForecastMode")) {
        return this.get("index") === 0 ? this.get("intl").t("preview-header.actuals") : workingBudgetLabel;
      }
      if (this.get("index") === 0 || this.get("hasActuals")) {
        return this.get("intl").t("preview-header.actuals");
      }
      return workingBudgetLabel;
    })
  });
});