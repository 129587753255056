define("budgeta/components/select-2-lazy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ["active:active", "wrap:wrap"],
    active: false,
    searchInChildren: false,
    // If we want to use a seperator in the select-2

    selected: Ember.computed("content.@each.optionValuePath", "content.@each.children", "value", function () {
      var _this = this;
      var content = this.get("content");
      var selected = content && content.findBy(this.get("optionValuePath"), this.get("value"));
      if (!selected && this.get("searchInChildren")) {
        content.forEach(function (element) {
          if (element.children) {
            selected = selected || element.children.findBy(_this.get("optionValuePath"), _this.get("value"));
          }
        });
      }
      return selected;
    }),
    selectedLabel: Ember.computed("selected", "optionLabelPath", "placeholder", function () {
      var selected = this.get("selected");
      if (selected) {
        return Ember.get(selected, this.get("optionLabelPath") || "text");
      } else {
        return this.get("placeholder") || "-";
      }
    }),
    isPlaceholder: Ember.computed.not("selected"),
    actions: {
      onOpenAction: function onOpenAction() {
        if (this.get("onOpenAction")) {
          this.sendAction("onOpenAction");
        }
      },
      attributeChanged: function attributeChanged(newValue) {
        this.sendAction("attributeChanged", newValue);
      },
      activate: function activate() {
        var _this2 = this;
        if (this.get("enabled")) {
          this.set("active", true);
          Ember.run.scheduleOnce("afterRender", function () {
            _this2.$(".select2-container").select2("open");
          });
        }
      }
    }
  });
});