define("budgeta/templates/model-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oKXF6R4X",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"main-content login-page\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"content-page centerElement\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"budgetService\",\"budgetInfoId\"]]],null,2,1],[\"text\",\"    \"],[\"block\",[\"link-to\"],[\"main\"],null,0],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"model-not-found.go-back\"],null],false]],\"locals\":[]},{\"statements\":[[\"text\",\"\\n      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"page-header\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"model-not-found.oops\"],null],false],[\"close-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"t\"],[\"model-not-found.page-not-available\"],null],false],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"page-header\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"model-not-found.alert\"],null],false],[\"close-element\"],[\"text\",\"\\n      \"],[\"append\",[\"helper\",[\"t\"],[\"model-not-found.not-authorized\"],null],false],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"open-element\",\"br\",[]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/model-not-found.hbs"
    }
  });
});