define("budgeta/templates/components/breadcrumbs-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/ve2Zt6f",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"helper\",[\"or\"],[[\"helper\",[\"not\"],[[\"get\",[\"selectedBudget\",\"isRoot\"]]],null],[\"get\",[\"isGridFlyout\"]]],null]],null,2]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"append\",[\"unknown\",[\"budget\",\"name\"]],false]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"block\",[\"link-to\"],[\"main.budget.index\",[\"get\",[\"budget\"]],[\"helper\",[\"query-params\"],null,[[\"navToLine\",\"selectedBudgetId\"],[true,[\"get\",[\"budget\",\"id\"]]]]]],[[\"class\",\"disabled\"],[\"breadcrumbs-element\",[\"get\",[\"disableElementsClick\"]]]],0],[\"text\",\"\\n      \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"breadcrumbs-separator\"],[\"flush-element\"],[\"text\",\" > \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"budget\"]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"breadcrumbs-bar\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"each\"],[[\"get\",[\"treeList\"]]],null,1],[\"text\",\"    \"],[\"open-element\",\"div\",[]],[\"dynamic-attr\",\"class\",[\"concat\",[\"breadcrumbs-element current \",[\"helper\",[\"if\"],[[\"get\",[\"disableCurrentClick\"]],\"disable\",\"clickable\"],null]]]],[\"static-attr\",\"role\",\"button\"],[\"modifier\",[\"action\"],[[\"get\",[null]],\"onCurrentElementClick\"]],[\"flush-element\"],[\"text\",\"\\n      \"],[\"append\",[\"unknown\",[\"selectedBudget\",\"name\"]],false],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/breadcrumbs-bar.hbs"
    }
  });
});