define("budgeta/templates/components/user-activity-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "F8XtNs9z",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"user-name\"],[\"dynamic-attr\",\"title\",[\"concat\",[[\"unknown\",[\"user\",\"name\"]]]]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"selectUser\"]],[\"flush-element\"],[\"append\",[\"unknown\",[\"user\",\"name\"]],false],[\"close-element\"],[\"text\",\"\\n\"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"user-action-num\"],[\"dynamic-attr\",\"title\",[\"concat\",[[\"unknown\",[\"user\",\"y\"]]]]],[\"flush-element\"],[\"append\",[\"unknown\",[\"user\",\"y\"]],false],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/user-activity-row.hbs"
    }
  });
});