define("budgeta/templates/components/model-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cqIQiw3d",
    "block": "{\"statements\":[[\"block\",[\"file-upload\"],null,[[\"id\",\"name\",\"accept\",\"onfileadd\"],[[\"get\",[\"fileUploadId\"]],[\"get\",[\"name\"]],\".bdgm\",[\"helper\",[\"action\"],[[\"get\",[null]],\"uploadfile\",[\"get\",[\"subBudgetType\"]]],null]]],2]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"yield\",\"default\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"t\"],[\"model-upload.uploading\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"queue\",\"files\",\"length\"]]],null,1,0]],\"locals\":[\"queue\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/model-upload.hbs"
    }
  });
});