define("budgeta/routes/main/budget/accounts", ["exports", "budgeta/utils/accounts", "ember-simple-auth/mixins/authenticated-route-mixin", "budgeta/mixins/budgeta-route"], function (_exports, _accounts, _authenticatedRouteMixin, _budgetaRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _budgetaRoute.default, {
    queryParams: {
      viewType: {
        refreshModel: true
      }
    },
    viewSelections: Ember.inject.service(),
    setupController: function setupController(controller, model, params) {
      this._super.apply(this, arguments);
      var viewType = params.queryParams.viewType;
      var externalFilterAccountsType = params.queryParams.externalFilterAccountsType;

      // If the user wishes to move to the viewType of Dimensions
      if (viewType) {
        controller.set("viewType", viewType);
      }
      controller.set("userChangedTableView", false);
      if (externalFilterAccountsType) {
        controller.get("viewSelections").set("selectedAccountType", externalFilterAccountsType);
      }

      // trigger refresh of account list
      controller.notifyPropertyChange("actualAccounts.length");
      controller.get("budgetService").notifyPropertyChange("recalcBudgetLinesAccounts");
      controller.get("budgetService").notifyPropertyChange("recalcStatisticalAccounts");
    },
    activate: function activate() {
      this.set("viewSelections.maximized", true);
    },
    deactivate: function deactivate() {
      this.set("viewSelections.maximized", false);
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.get("controller.allAccounts") && this.get("controller.hasErrors") && (transition.targetName !== this.get("routeName") || !this.get("controller.isDimensionsViewType"))) {
          if (this.get("controller.hasDuplicateAccounts")) {
            _accounts.default.showDuplicateAccountNoty(this.get("controller.hasDuplicateAccounts"));
          } else if (this.get("controller.hasMissingBudgetLines")) {
            _accounts.default.showMissingBudgetsAccountNoty(this.get("controller.hasMissingBudgetLines"));
          } else if (this.get("controller.hasMissingDimensions")) {
            _accounts.default.showMissingBudgetsDimensionNoty(this.get("controller.hasMissingDimensions"));
          } else {
            _accounts.default.showAccountsNoty();
          }
          this.set("controller.showErrors", true);
          Ember.$("header .busy").hide();
        }
        (this.get("controller.changedTransactionsMapping") || []).forEach(function (trx) {
          trx.notifyPropertyChange("accountId");
        });
        this.set("controller.changedTransactionsMapping", Ember.A([]));
        this.get("controller.budgetService.actualsMapping").notifyPropertyChange("recalcAccount"); // Notifing that an account has created
        this._super.apply(this, arguments);
      },
      didTransition: function didTransition() {
        Ember.run.scheduleOnce("afterRender", this, function () {
          if (this.get("controller.hasErrors")) {
            if (this.get("controller.hasDuplicateAccounts")) {
              _accounts.default.showDuplicateAccountNoty(this.get("controller.hasDuplicateAccounts"));
            } else if (this.get("controller.hasMissingBudgetLines")) {
              _accounts.default.showMissingBudgetsAccountNoty(this.get("controller.hasMissingBudgetLines"));
            } else if (this.get("controller.hasMissingDimensions")) {
              _accounts.default.showMissingBudgetsDimensionNoty(this.get("controller.hasMissingDimensions"));
            } else {
              _accounts.default.showAccountsNoty();
            }
            this.set("controller.showErrors", true);
          }
          Ember.$(window).trigger("resize.table");
        });
        this._super.apply(this, arguments);
      }
    }
  });
});