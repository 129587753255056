define("budgeta/controllers/dependencies-modal", ["exports", "@babel/runtime/helpers/esm/typeof", "budgeta/services/ember-service-container", "budgeta/controllers/modal", "budgeta/utils/budget-utils", "budgeta/utils/assumption", "lodash/lodash"], function (_exports, _typeof2, _emberServiceContainer, _modal, _budgetUtils, _assumption, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/require-super-in-init */

  var mapLine = function mapLine(line, model) {
    var className = "assumption",
      type = "Assumption",
      name = Ember.get(line, "name"),
      gotolink = intl.t("general.go_to_assumption_1698234256161"),
      showGoto = true;
    if (Ember.get(line, "budgetType") === "assumption") {
      className = "model-line";
      type = "Model line";
      gotolink = intl.t("general.go_to_model_line_1698234256161");
    } else if (Ember.get(line, "budgetType") === "group-model") {
      className = "model-group";
      type = "Model";
      gotolink = intl.t("general.go_to_model_1695803189583");
    } else if (Ember.get(line, "budgetType")) {
      className = "budget-line";
      type = "Budget line";
      name = Ember.get(line, "titleForMap");
      gotolink = intl.t("general.go_to_budget_line_1698234256161");
      if (model && (0, _typeof2.default)(model) === "object" && model.get("budgetType") === "assumption" && model.get("budgetAttributes.amountType") === 5) {
        // headcount
        return buildHeadcountLine(line, model);
      }
    }
    return {
      name: name,
      type: type,
      className: className,
      line: line,
      gotolink: gotolink,
      showGoto: showGoto
    };
  };
  var buildHeadcountLine = function buildHeadcountLine(line, modelLine) {
    var className = "headcount",
      type = "Headcount",
      name = Ember.get(line, "name"),
      gotolink,
      showGoto = false,
      buildFilters = [];
    if (modelLine.get("budgetAttributes.filterDimensions.length")) {
      modelLine.get("budgetAttributes.filterDimensions").forEach(function (v) {
        var dimension = v && (modelLine.get("budgetService.dimensions") || Ember.A([])).findBy("uniqueId", Ember.get(v, "dimension"));
        if (dimension && Ember.get(v, "values.length")) {
          buildFilters.pushObjects(Ember.get(v, "values").map(function (vv) {
            return Ember.get(dimension.get("values").findBy("uniqueId", vv) || {}, "name");
          }).filter(function (vv) {
            return vv;
          }));
        }
      });
    }
    if (modelLine.get("budgetAttributes.filterRoles.length")) {
      buildFilters.pushObjects(modelLine.get("budgetAttributes.filterRoles"));
    }
    if (modelLine.get("budgetAttributes.filterTag.length")) {
      buildFilters.pushObjects(modelLine.get("budgetAttributes.filterTag"));
    }
    if (buildFilters.length) {
      name += " (".concat(buildFilters.slice(0, 3).join(", ")).concat(buildFilters.length > 3 ? "..." : "", ")");
    }
    return {
      name: name,
      type: type,
      className: className,
      line: line,
      gotolink: gotolink,
      showGoto: showGoto
    };
  };
  var _default = _exports.default = _modal.default.extend({
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    showBack: true,
    hideCancel: true,
    hideConfirm: true,
    lastSelectedRoot: null,
    history: [],
    types: [{
      title: intl.t("general.title_1695611597485"),
      type: "Assumption"
    }, {
      title: intl.t("general.title_1695611598437"),
      type: "Model line"
    }, {
      title: intl.t("general.title_1695611597516"),
      type: "Model"
    }, {
      title: intl.t("general.title_1695611598415"),
      type: "Budget line"
    }, {
      title: intl.t("general.title_1695611597579"),
      type: "Headcount"
    }],
    init: function init() {
      if (!this.get("model")) {
        this.set("model", this.get("budgetService.selectedRoot"));
      }
      this.set("lastSelectedRoot", this.get("budgetService.selectedRoot"));
    },
    isBenefit: Ember.computed("model.budgetType", function () {
      return this.get("model.budgetType") === "expense-benefit";
    }),
    getMapForList: function getMapForList(list) {
      return this.get("types").map(function (item) {
        var listForType = list.filterBy("type", item.type);
        var disableAccountId;
        if (item.type !== "Budget line") {
          listForType.sortBy("name");
          disableAccountId = true;
        }
        return {
          title: item.title,
          list: listForType,
          isCollapsed: false,
          disableAccountId: disableAccountId
        };
      });
    },
    modelType: Ember.computed("model", function () {
      if (this.get("model.budgetType") === "assumption") {
        return intl.t("general.model_line_1698234256161");
      } else if (this.get("model.budgetType") === "group-model") {
        return intl.t("general.model_1698234256161");
      } else if (this.get("model.budgetType")) {
        return intl.t("general.budget_line_1698234256161");
      }
      return intl.t("general.assumption_1698234255794");
    }),
    usedByList: Ember.computed("model", "model.lineRefsChanged", "modelType", function () {
      var _this2 = this;
      var usedBy = this.get("cache").findReferencesToId(this.get("model.uniqueId")).filter(function (dependency) {
        return Ember.get(dependency, "uniqueId") !== _this2.get("model.uniqueId");
      });
      if (this.get("model.isRoot")) {
        usedBy = usedBy.filter(function (line) {
          if (Ember.get(line, "budgetAttributes.amountType") === 5) {
            return false;
          }
          if (_this2.get("model.isRoot") && (Ember.get(line, "budgetType") === "per-new-employee" || Ember.get(line, "budgetType") === "per-employee")) {
            return false;
          }
          return true;
        });
      }
      var addModelLinesParent = Ember.A([]);
      var assumptionsWithParent = this.get("budgetService.fixedAssumptions") || [];
      usedBy.forEach(function (used) {
        if (used && Ember.get(used, "budgetType") === "assumption" && Ember.get(used, "parent")) {
          // add the model of the model line
          addModelLinesParent.addObject(Ember.get(used, "parent"));
        }
        if (used && !Ember.get(used, "budgetType")) {
          // assumption - add the model parent of the using assumption
          var assumptionWithParent = assumptionsWithParent.findBy("uniqueId", Ember.get(used, "uniqueId"));
          if (assumptionWithParent && Ember.get(assumptionWithParent, "parent") && Ember.get(assumptionWithParent, "parent.uniqueId") !== _this2.get("model.uniqueId")) {
            var assumptionParent = Ember.get(assumptionWithParent, "parent");
            addModelLinesParent.addObject(assumptionParent);
          }
        }
      });
      if (this.get("model.budgetType") === "group-model") {
        // add child and assumption dependencies
        var children = this.get("model.children");
        var modelUniqueId = this.get("model.uniqueId");
        var addOutsideDependency = function addOutsideDependency(dependency) {
          if (Ember.get(dependency, "budgetType")) {
            // is model line / budget
            if (!Ember.get(dependency, "isModelLine")) {
              // add budget line
              usedBy.addObject(dependency);
            } else if (modelUniqueId !== Ember.get(dependency, "parent.uniqueId") && modelUniqueId !== Ember.get(dependency, "uniqueId") && Ember.get(dependency, "parent")) {
              // add model line and parent model
              usedBy.addObject(dependency);
              usedBy.addObject(Ember.get(dependency, "parent"));
            }
          } else {
            // add assumption
            var depAss = Ember.get(dependency, "assumption"),
              parentModel = Ember.get(dependency, "parent");
            if (depAss && parentModel && Ember.get(parentModel, "uniqueId") !== modelUniqueId) {
              usedBy.addObject(depAss);
              usedBy.addObject(parentModel);
            }
          }
        };
        if (children) {
          children.forEach(function (child) {
            var dependencies = _this2.get("cache").findReferencesToId(Ember.get(child, "uniqueId"));
            dependencies.forEach(addOutsideDependency);
          });
        }
        var assumptions = this.get("model.budgetAttributes.assumptions") || [];
        assumptions.forEach(function (assumption) {
          var dependencies = _this2.get("cache").findReferencesToId(Ember.get(assumption, "uniqueId"));
          dependencies.forEach(addOutsideDependency);
        });
      }
      usedBy.addObjects(addModelLinesParent);
      if (!this.get("model.isBenefit") && this.get("model.parent") && !this.get("model.isModelLine")) {
        usedBy.addObject(this.get("model.parent"));
      }
      return usedBy.map(mapLine);
    }),
    usedBy: Ember.computed("usedByList", function () {
      return this.getMapForList(this.get("usedByList"));
    }),
    isUsingList: Ember.computed("model", "model.lineRefsChanged", "modelType", "exceptionList.length", function () {
      var _this3 = this;
      var key = this.get("modelType") === "assumption" ? "assumptions" : "lines";
      var usingObj = _lodash.default.clone(this.get("cache.dependencyTable.".concat(key, ".").concat(this.get("model.uniqueId"))) || {});
      var headcountLines = {};

      // remove self
      if (this.get("model.budgetType") && usingObj[this.get("model.uniqueId")]) {
        delete usingObj[this.get("model.uniqueId")];
      }
      if (this.get("model.budgetAttributes.allocatedFrom") && usingObj[this.get("model.budgetAttributes.allocatedFrom")] && this.get("model.budgetType") === "per-employee" || this.get("model.budgetType") === "per-new-employee") {
        if (!headcountLines[this.get("model.budgetAttributes.allocatedFrom")]) {
          headcountLines[this.get("model.budgetAttributes.allocatedFrom")] = [];
        }
        headcountLines[this.get("model.budgetAttributes.allocatedFrom")].push({
          budgetLine: usingObj[this.get("model.budgetAttributes.allocatedFrom")],
          modelLine: this.get("model")
        });
        delete usingObj[this.get("model.budgetAttributes.allocatedFrom")];
      }
      if (!this.get("model.isModelLine") && this.get("model.children")) {
        // add children
        this.get("model.children").forEach(function (child) {
          if (!Ember.get(child, "isModelGroup")) {
            if (!usingObj[Ember.get(child, "uniqueId")]) {
              usingObj[Ember.get(child, "uniqueId")] = child;
            }
          }
        });
      }
      if (this.get("modelType") === "budget line") {
        // remove non-stand alone assumptions
        var removeKeys = [],
          tempAssumption;
        for (key in usingObj) {
          if (!Ember.get(usingObj[key], "budgetType")) {
            // is assumption
            tempAssumption = usingObj[key];
            if (!_assumption.default.isStandAloneAssumption(this.get("cache"), tempAssumption)) {
              removeKeys.push(key);
            }
          }
        }
        removeKeys.forEach(function (key) {
          delete usingObj[key];
        });
      }
      if (this.get("modelType") !== "assumption" && !this.get("model.isModelLine")) {
        // add dependencies for all descendent budget lines
        var allChildren = _budgetUtils.default.getChildrenArrayRecursive(this.get("model"), false, {
          withoutModel: true
        });
        allChildren.forEach(function (child) {
          var childDependencies = _this3.get("cache.dependencyTable.lines.".concat(Ember.get(child, "uniqueId")));
          for (var _key in childDependencies) {
            var dependency = childDependencies[_key];
            if (Ember.get(dependency, "budgetType") === "assumption" && !usingObj[Ember.get(dependency, "parent.uniqueId")]) {
              // as model line dep - add the model parent
              usingObj[Ember.get(dependency, "parent.uniqueId")] = Ember.get(dependency, "parent");
            } else if (!Ember.get(dependency, "budgetType") && !usingObj[Ember.get(dependency, "uniqueId")]) {
              // is assumption and stand alone
              if (_assumption.default.isStandAloneAssumption(_this3.get("cache"), dependency)) {
                usingObj[Ember.get(dependency, "uniqueId")] = dependency;
              }
            }
          }
        });
      }

      // add model assumptions and model lines
      if (this.get("model.budgetType") === "group-model") {
        var assumptions = this.get("model.budgetAttributes.assumptions") || [];
        assumptions.forEach(function (assumption) {
          if (_assumption.default.isStandAloneAssumption(_this3.get("cache"), assumption)) {
            if (!usingObj[Ember.get(assumption, "uniqueId")]) {
              usingObj[Ember.get(assumption, "uniqueId")] = assumption;
            }
          } else {
            var assumptionDependencies = _this3.get("cache.dependencyTable.assumptions.".concat(Ember.get(assumption, "uniqueId")));
            assumptionDependencies.forEach(function (assumptionD) {
              if (!usingObj[Ember.get(assumptionD, "uniqueId")]) {
                usingObj[Ember.get(assumptionD, "uniqueId")] = assumptionD;
              }
            });
          }
        });
        if (this.get("model.children")) {
          this.get("model.children").forEach(function (modelLine) {
            var modelLineDependencies = _this3.get("cache.dependencyTable.lines.".concat(Ember.get(modelLine, "uniqueId"))) || {};
            if (Object.keys(modelLineDependencies).length === 0 && !usingObj[modelLine.get("uniqueId")]) {
              usingObj[modelLine.get("uniqueId")] = modelLine;
            } else if (Object.keys(modelLineDependencies).length) {
              delete usingObj[modelLine.get("uniqueId")];
              for (var _key2 in modelLineDependencies) {
                var dependency = modelLineDependencies[_key2];
                // if headcount or budget line
                if (Ember.get(dependency, "parent.uniqueId") !== _this3.get("model.uniqueId")) {
                  if (modelLine.get("budgetAttributes.amountType") === 5 && !Ember.get(dependency, "isModelLine")) {
                    // this is a budget line for headcount
                    if (!headcountLines[dependency.get("uniqueId")]) {
                      headcountLines[dependency.get("uniqueId")] = [];
                    }
                    headcountLines[dependency.get("uniqueId")].push({
                      budgetLine: dependency,
                      modelLine: modelLine
                    });
                  } else if (!usingObj[Ember.get(dependency, "uniqueId")]) {
                    usingObj[Ember.get(dependency, "uniqueId")] = dependency;
                  }
                }
              }
            }
          });
        }
      }

      // add model-group
      var keys = Object.keys(usingObj);
      var assumptionsWithParent = this.get("budgetService.fixedAssumptions") || [];
      keys.forEach(function (key) {
        if (usingObj[key] && Ember.get(usingObj[key], "budgetType") === "assumption" &&
        // add model group for used model line
        !usingObj[Ember.get(usingObj[key], "parent.uniqueId")] && Ember.get(usingObj[key], "parent.uniqueId") !== _this3.get("model.uniqueId") && Ember.get(usingObj[key], "parent.uniqueId") !== _this3.get("model.parent.uniqueId")) {
          usingObj[Ember.get(usingObj[key], "parent.uniqueId")] = Ember.get(usingObj[key], "parent");
        }
        if (!Ember.get(usingObj[key], "budgetType")) {
          // assumption - add the model parent of the using assumption
          var assumptionWithParent = assumptionsWithParent.findBy("uniqueId", Ember.get(usingObj[key], "uniqueId"));
          if (assumptionWithParent && Ember.get(assumptionWithParent, "parent")) {
            var assumptionParent = Ember.get(assumptionWithParent, "parent");
            if (Ember.get(assumptionParent, "uniqueId") && !usingObj[Ember.get(assumptionParent, "uniqueId")] && Ember.get(assumptionParent, "uniqueId") !== _this3.get("model.uniqueId")) {
              usingObj[Ember.get(assumptionParent, "uniqueId")] = assumptionParent;
            }
          }
        }
      });
      var isUsingList = Object.keys(usingObj).map(function (uid) {
        return mapLine(usingObj[uid], _this3.get("model"), _this3.get("cache"));
      });

      // add headcount lines
      var addHeadcountLine = function addHeadcountLine(lineObj) {
        isUsingList.push(buildHeadcountLine(lineObj.budgetLine, lineObj.modelLine));
      };
      for (var headcountKey in headcountLines) {
        var linesObj = headcountLines[headcountKey];
        linesObj.forEach(addHeadcountLine);
      }
      isUsingList.removeObjects(isUsingList.filter(function (l) {
        return _this3.get("exceptionList").mapBy("line").includes(l.line);
      }));
      return isUsingList;
    }),
    isUsing: Ember.computed("isUsingList", function () {
      return this.getMapForList(this.get("isUsingList"));
    }),
    exceptionList: Ember.computed("model", "model.lineRefsChanged", "modelType", function () {
      var thisModel = this.get("model");
      var findExceptionsInSubtree = function findExceptionsInSubtree(node) {
        var result = [];
        if (node) {
          if (node.getWithDefault("budgetAttributesWithScenarios.deletedBenefits", []).includes(node.get("uniqueId"))) {
            result.addObject(node);
          } else if (node.get("children") && node.get("children").find(function (c) {
            return c !== thisModel && thisModel.get("name") === c.get("name") && c.get("budgetType") === "expense-benefit";
          })) {
            result.addObject(node);
          }
          if (node.get("children.length")) {
            result.addObjects(node.get("children").map(findExceptionsInSubtree).reduce(function (acc, cur) {
              return acc.addObjects(cur);
            }, []));
          }
        }
        return result;
      };
      return findExceptionsInSubtree(this.get("model.parent")).map(mapLine);
    }),
    exceptionListByType: Ember.computed("exceptionList", function () {
      return this.getMapForList(this.get("exceptionList"));
    }),
    actions: {
      back: function back() {
        this.set("model", this.get("history").popObject());
      },
      select: function select(element) {
        this.get("history").pushObject(this.get("model"));
        this.set("model", element.line);
      },
      collapse: function collapse(item) {
        Ember.set(item, "isCollapsed", !Ember.get(item, "isCollapsed"));
      },
      goto: function goto(item) {
        this.send("close");
        this.send("closeModal", "modal");
        this.send("gotoDependency", item);
      },
      gotoFromHeader: function gotoFromHeader() {
        var _this = this;
        this.send("goto", mapLine(_this.get("model")));
      }
    }
  });
});