define("budgeta/templates/dimension-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "f8jt+UuF",
    "block": "{\"statements\":[[\"block\",[\"dimension-settings\"],null,[[\"controllertmp\"],[[\"get\",[null]]]],5]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"        \"],[\"append\",[\"helper\",[\"t\"],[\"dimension-settings.allow-allocations\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"budgeta-checkbox\"],null,[[\"disabled\",\"checked\"],[[\"get\",[\"disabledAllocations\"]],[\"get\",[\"model\",\"allowAllocation\"]]]],0]],\"locals\":[]},{\"statements\":[[\"text\",\"        \"],[\"append\",[\"helper\",[\"t\"],[\"dimension-settings.allow-allocations\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"budgeta-checkbox\"],null,[[\"disabled\",\"checked\"],[true,false]],2]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"t\"],[\"dimension-settings.allow-hierarchies\"],null],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"name-value-input\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"dimension-settings.name\"],null],false],[\"close-element\"],[\"open-element\",\"div\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"html5-input\"],null,[[\"type\",\"required\",\"value\"],[\"text\",true,[\"get\",[\"model\",\"name\"]]]]],false],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"mt20\"],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"budgeta-checkbox\"],null,[[\"disabled\",\"checked\"],[[\"get\",[\"disabledHierarchies\"]],[\"get\",[\"model\",\"allowHierarchy\"]]]],4],[\"block\",[\"if\"],[[\"get\",[\"model\",\"originalDimension\",\"isCategory\"]]],null,3,1],[\"text\",\"  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/dimension-settings.hbs"
    }
  });
});