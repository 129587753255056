define("budgeta/components/import-multiplied-value", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "span",
    displayValue: Ember.computed("stringValue", "numberValue", "multiplyBy", function () {
      var _this = this;
      var headerCol = this.get("headerColumns").findBy("col.key", this.get("headerKey"));
      if (!headerCol || !(headerCol.get("isDate") || headerCol.get("selectedAttribute") && (headerCol.get("selectedAttribute") === "bonus" || headerCol.get("selectedAttribute").indexOf("base") === 0))) {
        return this.get("stringValue");
      }
      var multiplyBy = this.get("multiplyBy") * (this.get("thousands") ? 1000 : 1);
      var supportedCurrency;
      if (this.get("stringValue") && this.get("stringValue").length > 0) {
        supportedCurrency = Object.keys(_const.default.currency_symbols).find(function (key) {
          return _const.default.currency_symbols[key] === _this.get("stringValue").trim()[0];
        });
      }
      var currency = supportedCurrency || this.get("currency");
      if (multiplyBy === 0 && !isNaN(this.get("numberValue"))) {
        if (this.get("thousands")) {
          return _const.default.formatCurrency(this.get("numberValue") * 1000, currency, undefined, true);
        }
        return _const.default.formatCurrency(this.get("numberValue"), currency, undefined, true);
      }
      if (isNaN(multiplyBy) || !multiplyBy || isNaN(this.get("numberValue"))) {
        return isNaN(this.get("numberValue")) ? this.get("stringValue") : _const.default.formatCurrency(this.get("numberValue") * (this.get("thousands") ? 1000 : 1), currency, undefined, true);
      }
      var value = Number(this.get("numberValue"));
      var sign = value > 0 ? 1 : -1;
      var result = (this.get("thousands") ? value * 1000 : value) + value * sign * multiplyBy / 100;
      return isNaN(result) ? this.get("stringValue") : _const.default.formatCurrency(result, currency, undefined, true);
    })
  });
});