define("budgeta/components/integration-wizard-export-dimensions-mapping", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    }),
    intacctDimensions: Ember.computed("model.intacctDimensions", function () {
      var _this = this;
      return (this.get("model.intacctDimensions") || []).filter(function (v) {
        return !(_this.get("budgetService.dimensionsMapping") || Ember.A([])).findBy("financialDimensionId", v.id);
      }).map(function (v) {
        return {
          id: v.id,
          text: v.dimension,
          disabled: _const.default.INTACCT_BLOCK_DIMENSIONS.has(v.dimension.toUpperCase()) ? true : false // currently task dimension can't be exported
        };
      });
    }),
    didRender: function didRender() {
      this.sendAction(this.get("continueActionName"), this.get("model.isSkipSteps.exportMissingDimensions") ? intl.t("general.skip_1698234255443") : "");
    },
    actions: {
      attributeChanged: function attributeChanged() {
        this.set("model.isSkipSteps.exportMissingDimensions", false);
        this.sendAction(this.get("continueActionName"), "");
      }
    }
  });
});