define("budgeta/helpers/list-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.listLines = listLines;
  function listLines(budget) {
    var result = Ember.get(budget, "name");
    if (Ember.get(budget, "children")) {
      Ember.get(budget, "children").forEach(function (child) {
        result += ", " + listLines(child);
      });
    }
    return result;
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return listLines(params[0]);
  });
});