define("budgeta/templates/components/svg/change-log-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zolRP3vD",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"id\",\"Vector\"],[\"static-attr\",\"d\",\"M7.76259 0.505026C12.3212 0.345077 16 4.02392 16 8.50251V8.74244C16 9.06234 15.84 9.30226 15.6001 9.46221C15.3602 9.62216 15.0403 9.62216 14.8004 9.46221C14.5605 9.30226 14.4005 9.06234 14.4005 8.74244V8.58249C14.4005 5.78337 12.6411 3.22417 9.92191 2.42442C5.12342 0.984875 0.804775 5.06359 1.6845 9.86209C2.16435 12.2613 4.16372 14.2607 6.56297 14.7406C9.12216 15.3004 11.5214 14.2607 12.961 12.5013L12.0013 12.5013C11.6814 12.5013 11.4414 12.3413 11.2815 12.1014C11.1215 11.8615 11.1215 11.5416 11.2815 11.3016C11.4414 11.0617 11.6814 10.9018 12.0013 10.9018L14.4005 10.9018C14.8804 10.9018 15.2003 11.2217 15.2003 11.7015L15.2003 14.1008C15.2003 14.5806 14.8804 14.9005 14.4005 14.9005C13.9207 14.9005 13.6008 14.5806 13.6008 14.1008V13.7809C13.6008 13.7809 10.4018 16.5 8.00251 16.5C3.52392 16.5 -0.154923 12.8212 0.00502625 8.26259C0.164976 4.1039 3.6039 0.664976 7.76259 0.505026ZM8.00503 4.50503C8.24503 4.50503 8.40503 4.58503 8.56503 4.74503C8.72503 4.90503 8.80503 5.06503 8.80503 5.30503L8.80503 8.50503L10.805 8.50503C11.125 8.50503 11.365 8.66503 11.525 8.90503C11.685 9.14503 11.685 9.46503 11.525 9.70503C11.365 9.94503 11.125 10.105 10.805 10.105L8.00503 10.105C7.52503 10.105 7.20503 9.78503 7.20503 9.30503L7.20503 5.30503C7.20503 4.82503 7.52503 4.50503 8.00503 4.50503Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/change-log-svg.hbs"
    }
  });
});