define("budgeta/models/dimension", ["exports", "ember-data", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/dimensions", "ember-inflector"], function (_exports, _emberData, _emberServiceContainer, _const, _dimensions, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/no-observers */
  var _default = _exports.default = _emberData.default.Model.extend({
    budgetService: Ember.inject.service(),
    filterVisibility: Ember.inject.service(),
    uniqueId: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    valuesLength: Ember.computed("values", "values.[]", function () {
      return this.get("values").length;
    }),
    lowerCaseName: Ember.computed("name", function () {
      return (this.get("name") || "").toLowerCase();
    }),
    pluralName: Ember.computed("name", function () {
      return (0, _emberInflector.pluralize)(this.get("name"));
    }),
    lowerCasePlural: Ember.computed("pluralName", function () {
      return (0, _emberInflector.pluralize)((this.get("pluralName") || "").toLowerCase());
    }),
    dimensionValuesOrder: Ember.computed("values.[]", function () {
      return _dimensions.default.getDimensionValuesOrder(this);
    }),
    type: _emberData.default.attr("string"),
    isNewDimension: false,
    values: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    valuesMap: {},
    valuesObserver: Ember.observer("values.@each.name", function () {
      this.valuesChanged();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      // call computed property to ensure that computed property is computed for dependent key
      this.get("valuesLength");
    },
    valuesChanged: function valuesChanged() {
      var _this = this;
      this.get("values").forEach(function (v) {
        var parent = Ember.get(v, "parent") && _this.get("values").findBy("uniqueId", Ember.get(v, "parent"));
        Ember.set(v, "displayName", (parent ? (Ember.get(parent, "displayName") || Ember.get(parent, "name")) + " → " : "") + Ember.get(v, "name"));
      });
    },
    ready: function ready() {
      this.valuesChanged();
    },
    isDepartment: Ember.computed("type", function () {
      return this.get("type") === "department";
    }),
    isEntity: Ember.computed("type", function () {
      return this.get("type") === "entity";
    }),
    isCategory: Ember.computed("type", function () {
      return this.get("type") === "category";
    }),
    isProduct: Ember.computed("type", function () {
      return this.get("type") === "product";
    }),
    isGeography: Ember.computed("type", function () {
      return this.get("type") === "geography";
    }),
    allowHierarchy: _emberData.default.attr("boolean"),
    allowAllocation: _emberData.default.attr("boolean"),
    budget: _emberData.default.belongsTo("budget", {
      async: false,
      inverse: "dimensions"
    }),
    isVisibleInToolbar: Ember.computed("budget.id", "uniqueId", "filterVisibility.navigationPath", {
      get: function get() {
        var rootId = this.get("budget.id");
        return _const.default.getAttributeDropdownVisibility(rootId, "".concat(this.get("filterVisibility.navigationPath")).concat(this.get("uniqueId")));
      },
      set: function set(key, value) {
        var rootId = this.get("budget.id");
        _const.default.setAttributeDropdownVisibility(rootId, "".concat(this.get("filterVisibility.navigationPath")).concat(this.get("uniqueId")), value);
        return value;
      }
    }),
    visibilityName: Ember.computed("isVisibleInToolbar", function () {
      return this.get("isVisibleInToolbar") ? "visibility_on" : "visibility_cross";
    }),
    allPlaceholder: Ember.computed("name", function () {
      return intl.t("general.dimension-1", {
        var1: (0, _emberInflector.pluralize)(this.get("name"))
      });
    }),
    addValue: function addValue(value) {
      var _this2 = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var newValue,
        treeLocation = options.treeLocation,
        budgetLineParent = options.budgetLineParent,
        parent = options.parent,
        noSave = options.noSave,
        callback = options.callback,
        uniqueId = options.uniqueId,
        benefitRef = options.benefitRef;
      value = _dimensions.default.normalizeDimensionValue(value);
      if (value) {
        var dimensionValues = this.get("values");
        if (budgetLineParent && this.get("allowHierarchy")) {
          // try to place the new value under the parent value if this is a hierarchical dimenasion
          var parentDimensions = budgetLineParent.get("budgetAttributesWithScenarios.dimensions");
          var parentDim = parentDimensions && parentDimensions.findBy("dimension", this.get("uniqueId"));
          if (parentDim && Ember.get(parentDim, "values.length") === 1) {
            parent = Ember.get(parentDim, "values.firstObject.value");
          }
          newValue = dimensionValues.find(function (v) {
            return Ember.get(v, "name") === value && Ember.get(v, "parent") === parent;
          }) || dimensionValues.findBy("name", value);
        } else if (treeLocation) {
          newValue = dimensionValues.filterBy("name", value);
          if (newValue.length > 1) {
            // try to figure out the best option based on the tree location
            newValue = newValue.find(function (v) {
              return treeLocation.find(function (line) {
                if (!Ember.get(v, "parent")) {
                  return false;
                }
                var lineDimensionData = (line.get("budgetAttributes.dimensions") || Ember.A([])).findBy("dimension", _this2.get("uniqueId"));
                var lineDimensionValues = lineDimensionData && Ember.get(lineDimensionData, "values");
                return lineDimensionValues && lineDimensionValues.findBy("value", Ember.get(v, "parent"));
              });
            }) || newValue.find(function (v) {
              return !Ember.get(v, "parent");
            });
          } else {
            newValue = newValue[0];
          }
        } else {
          newValue = dimensionValues.find(function (dValue) {
            if (parent && Ember.get(dValue, "parent") === parent && Ember.get(dValue, "name") === value) {
              return true;
            }
            if (!parent && !Ember.get(dValue, "parent") && Ember.get(dValue, "name") === value) {
              return true;
            }
          });
        }
        if (!newValue) {
          newValue = {
            uniqueId: uniqueId || _const.default.generateUUID(),
            name: value,
            parent: parent,
            benefitRef: benefitRef
          };
          dimensionValues.pushObject(newValue);
          if (!noSave) {
            this.save().then(function () {
              _this2.valuesChanged();
              if (callback) {
                callback();
              }
            });
          }
        } else {
          Ember.set(newValue, "benefitRef", benefitRef);
        }
      }
      return newValue;
    },
    findValueByUniqueId: function findValueByUniqueId(uniqueId) {
      if (!uniqueId) {
        return;
      }
      var result = this.get("valuesMap.".concat(uniqueId));
      if (result === undefined) {
        result = this.get("values").findBy("uniqueId", uniqueId);
        this.set("valuesMap.".concat(uniqueId), result);
      }
      return result;
    }
  });
});