define("budgeta/templates/components/svg-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wNxrejr4",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"unicodeValue\"]]],null,2,1],[\"yield\",\"default\"]],\"locals\":[],\"named\":[],\"yields\":[\"default\"],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"open-element\",\"svg\",[]],[\"dynamic-attr\",\"viewBox\",[\"unknown\",[\"viewBox\"]],null],[\"dynamic-attr\",\"preserveAspectRatio\",[\"unknown\",[\"preserveAspectRatio\"]],null],[\"static-attr\",\"class\",\"icon\"],[\"flush-element\"],[\"text\",\"\\n      \"],[\"open-element\",\"use\",[]],[\"dynamic-attr\",\"href\",[\"concat\",[[\"unknown\",[\"path\"]]]]],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n    \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"name\"]]],null,0]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"icon\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"unicode\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg-icon.hbs"
    }
  });
});