define("budgeta/helpers/array-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayOf = arrayOf;
  _exports.default = void 0;
  // return array of data
  function arrayOf(array) {
    return array;
  }
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    return arrayOf(params);
  });
});