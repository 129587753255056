define("budgeta/templates/components/confirm-delete-dimension-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Bi7sLidg",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"t\"],[\"confirm-delete-dimension-value.delete-value\"],null],false],[\"text\",\"\\n\"],[\"append\",[\"unknown\",[\"model\",\"name\"]],false],[\"text\",\"\\n\"],[\"append\",[\"helper\",[\"t\"],[\"confirm-delete-dimension-value.remove-from-budget\"],null],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/confirm-delete-dimension-value.hbs"
    }
  });
});