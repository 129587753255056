define("budgeta/mixins/formula-editor-component", ["exports", "budgeta/utils/assumption", "budgeta/utils/formula-helper", "budgeta/utils/formula", "budgeta/utils/const"], function (_exports, _assumption, _formulaHelper, _formula, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    classNameBindings: ["isFormula:formula"],
    showFormulaOnHover: false,
    keyUp: function keyUp(e) {
      if (!this.get("disableFormula") && e.target.tagName.toLowerCase() === "input" && e.target.value === "=" && !this.get("readOnly")) {
        this.sendAction("openFormulaEditor", this);
      }
      return true;
    },
    focusIn: function focusIn(e) {
      if (!this.get("readOnly") && !this.get("disableFormula") && e.target.tagName.toLowerCase() === "input" && e.target.value && (e.target.value.toString().substr(0, 1) === "=" || this.get("showFormulaOnHover"))) {
        this.sendAction("openFormulaEditor", this);
      }
      return true;
    },
    isFormula: Ember.computed("value", function () {
      return typeof this.get("value") === "string" && this.get("value").substr(0, 1) === "=";
    }),
    isNonEmptyFormula: Ember.computed("isFormula", "cleanFormula", function () {
      return this.get("isFormula") && this.get("cleanFormula").length > 1;
    }),
    cleanFormula: Ember.computed("value", "model", function () {
      if (this.get("value")) {
        var options = {
          store: this.get("store"),
          fixedAssumptions: this.get("budgetService.fixedAssumptions"),
          budget: this.get("budgetService.selectedRoot"),
          model: this.get("budget")
        };
        return _formula.default.JSONToString(this.get("value"), options);
      }
    }),
    getCurrencyValue: function getCurrencyValue() {
      var dataTypeRef = this.get("data-type-ref");
      var dataTypeValue = this.get("budgetAttributes.".concat(dataTypeRef));
      return this.get("currency") === "%" || typeof dataTypeValue !== "string" ? "" : dataTypeValue;
    },
    cleanFormulaValue: Ember.computed("cleanFormula", "showFormulaOnHover", "formulaObject", "value", "showFormulaOutput", "isFixed", "triggerFormulaPreviewRecalc", function () {
      if (this.get("showFormulaOnHover") || this.get("showFormulaOutput") || this.get("shouldCleanFormulaValue")) {
        var formula = this.get("formulaObject");
        var currency = this.get("isPercent") ? "%" : this.getCurrencyValue() || this.get("budget.budgetAttributes.currency");
        var formattedValue = _formula.default.calcFormula(formula, {
          store: this.get("budget.store"),
          fixedAssumptions: this.get("budgetService.fixedAssumptions"),
          currency: currency,
          budget: this.get("budget"),
          month: moment(this.get("budget.budgetAttributes.startDate"))
        }, {
          scenario: this.get("budgetService.scenario"),
          cache: this.get("cache")
        });
        return _const.default.formatCurrency(formattedValue, currency);
      } else {
        return this.get("cleanFormula");
      }
    }),
    cleanPlaceholder: Ember.computed("placeholder", function () {
      if (this.get("placeholder") && _formulaHelper.default.isFormulaString(this.get("placeholder"))) {
        var options = {
          store: this.get("store"),
          fixedAssumptions: this.get("budgetService.fixedAssumptions"),
          budget: this.get("budgetService.selectedRoot")
        };
        return _formula.default.JSONToString(this.get("placeholder"), options);
      }
      return this.get("placeholder") || "";
    }),
    isFormulaError: Ember.computed("formulaOutput", "formulaObject", function () {
      return this.get("formulaObject.error");
    }),
    formulaError: Ember.computed("formulaObject", "isFormulaError", function () {
      var result = this.get("formulaObject");
      if (this.get("isFormulaError")) {
        result = Ember.get(result, "error");
      }
      return result;
    }),
    allFixedAssumptions: Ember.computed("budgetService.selectedRoot", function () {
      return _assumption.default.getFixedAssumptions(this.get("budgetService.selectedRoot"), null, null, {
        ignoreScope: true,
        withParent: true
      });
    }),
    formulaOutput: Ember.computed("value", function () {
      if (this.get("isFormula")) {
        var options = {
          store: this.get("store"),
          fixedAssumptions: this.get("budgetService.fixedAssumptions"),
          budget: this.get("budgetService.selectedRoot")
        };
        return _formula.default.JSONToString(this.get("value"), options);
      }
      return null;
    }),
    formulaObject: Ember.computed("value", function () {
      if (this.get("isFormula")) {
        var options = {
          store: this.get("store"),
          fixedAssumptions: this.get("budgetService.fixedAssumptions"),
          budget: this.get("budgetService.selectedRoot"),
          scenario: this.get("budgetService.scenario")
        };
        return _formula.default.parseToFormula(this.get("value"), options);
      }
      return null;
    }),
    actions: {
      openFormulaEditor: function openFormulaEditor() {
        if (!this.get("readOnly")) {
          this.sendAction("openFormulaEditor", this);
        }
      }
    }
  });
});