define("budgeta/components/account-mapping-clone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    triggerId: Ember.computed("elementId", function () {
      return this.get("elementId") + "-trigger";
    }),
    click: function click() {
      this.set("clicked", true);
    },
    clicked: false,
    hasDestinations: Ember.computed("destinations.length", function () {
      return this.get("destinations.length") > 0;
    }),
    destinations: Ember.computed("recalcAccount", "account", "fromImport", "sortedAccounts.length", "sortedAccounts", "clicked", function () {
      var _this = this;
      var allAccounts = this.get("sortedAccounts") || this.get("allAccounts").sortBy("account");
      if (this.get("fromImport")) {
        return allAccounts.map(function (item) {
          return {
            displayName: item.get("account") + (item.get("description") ? " - " + item.get("description") : ""),
            account: item
          };
        });
      }
      var checkAccountsLinked = function checkAccountsLinked(account1, account2) {
        var checked = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
        if (checked.includes(account1)) {
          return false;
        }
        checked.pushObject(account1);
        if (account1.get("copyFromAccount")) {
          if (account1.get("copyFromAccount") === account2.get("account")) {
            return true;
          }
          var copyFromAccount = allAccounts.findBy("account", account1.get("copyFromAccount"));
          if (copyFromAccount && checkAccountsLinked(copyFromAccount, account2, checked)) {
            return true;
          }
        }
        return false;
      };
      var accountId = this.get("account.account");
      var allAccountsFilters = allAccounts.filter(function (item) {
        return item.get("account") !== accountId && !checkAccountsLinked(item, _this.get("account"));
      });
      allAccountsFilters = allAccountsFilters.map(function (item) {
        return {
          displayName: item.get("account") + (item.get("description") ? " - " + item.get("description") : ""),
          account: item
        };
      });
      return allAccountsFilters;
    }),
    displayDestinations: Ember.computed("searchValue", "destinations", "destinations.length", "account.type", function () {
      var _this2 = this;
      var selections = Ember.A([]);
      var content = this.get("destinations"),
        searchValue = this.get("searchValue"),
        searchRegExp = new RegExp(this.get("searchValue"), "gi");
      content.forEach(function (item) {
        if (_this2.get("account.type") === Ember.get(item, "account.type") && (!searchValue || Ember.get(item, "displayName").search(searchRegExp) !== -1)) {
          selections.pushObject(item);
        }
      });
      return selections;
    }),
    actions: {
      setAsClone: function setAsClone(cloneAccount) {
        if (this.get("isNewMapping")) {
          return this.setProperties({
            copyFromAccount: cloneAccount.get("account"),
            mappingType: "clone",
            type: cloneAccount.get("type")
          });
        }
        this.setProperties({
          "account.copyFromAccount": cloneAccount.get("account"),
          "account.mappingType": "clone",
          "account.type": cloneAccount.get("type")
        });
        this.onSetAsClone();
        var target = Ember.$("#" + this.get("triggerId"));
        var api = target ? target.qtip("api") : null;
        if (api) {
          api.hide();
        }
      }
    }
  });
});