define("budgeta/initializers/raw-transform", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RawTransform = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized || {};
    },
    serialize: function serialize(deserialized) {
      return deserialized || {};
    }
  });
  var _default = _exports.default = {
    name: "raw-transform",
    initialize: function initialize(application) {
      application.register("transform:raw", RawTransform);
    }
  };
});