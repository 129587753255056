define("budgeta/components/budgeta-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "span",
    classNames: ["budgeta-checkbox"],
    checked: Ember.computed("model.value", "value", function () {
      return this.get("model.value") === this.get("value");
    }),
    inputId: Ember.computed("elementId", function () {
      return "rb" + this.get("elementId");
    }),
    change: function change() {
      if (this.get("onChange")) {
        // if onChange is defined, then data down, actions up
        this.get("onChange")(this.get("value"));
      } else {
        // if onChange is not defined, then two way binding
        Ember.set(this.get("model"), "value", this.get("value"));
      }
    }
  });
});