define("budgeta/components/power-tags-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    classNameBindings: ["budget.inheritedReadOnlyOrLock:disabled"],
    selectedValues: [],
    init: function init() {
      this._super();
      var currentValues = this.get("budget.budgetAttributes.tags") || [];
      this.set("selectedValues", currentValues);
    },
    differenceBy: function differenceBy(arr1, arr2, prop) {
      arr1 = arr1 || [];
      arr2 = arr2 || [];
      return arr1.filter(function (item1) {
        return !arr2.find(function (item2) {
          return item1[prop] === item2[prop];
        });
      });
    },
    options: Ember.computed("budgetService.tags.length", "budgetLine.parent.inheritedAttributes.tags.length", "selectedValues.length", function () {
      var content = this.get("budgetService.tags") || [];
      content = content.filter(function (opt) {
        return opt["name"];
      });
      var allUsedOptions = this.getAllUsedOptions();
      return this.differenceBy(content, allUsedOptions, "name");
    }),
    validateTerm: function validateTerm(event) {
      if (event.key.indexOf(",") >= 0) {
        event.preventDefault();
      }
    },
    isTermFound: function isTermFound(term, options) {
      return options.find(function (obj) {
        return obj["name"] === term;
      });
    },
    shouldAddCustomOption: function shouldAddCustomOption(term, options) {
      var usedOptions = this.getAllUsedOptions();
      return term.length > 1 && !this.isTermFound(term, options) && !this.isTermFound(term, usedOptions);
    },
    updateSelectedValuesByDifference: function updateSelectedValuesByDifference(currentValues, inheritedValues) {
      var differenceValues = this.differenceBy(currentValues, inheritedValues, "name");
      this.set("selectedValues", differenceValues);
    },
    getAllUsedOptions: function getAllUsedOptions() {
      var inheritedValues = this.get("budget.parent.inheritedAttributes.tags") || [];
      var selectedValues = this.get("budget.budgetAttributesWithScenarios.tags") || [];
      return inheritedValues.concat(selectedValues);
    },
    actions: {
      onKeyEnter: function onKeyEnter(term, select) {
        if (!select) return;
        var userTypingKey = "userInput";
        select.options = select.options.filter(function (opt) {
          return opt.type !== userTypingKey;
        });
        if (this.shouldAddCustomOption(term, select.options)) {
          select.options.unshift({
            name: term,
            type: userTypingKey
          });
        }
      },
      attributeChanged: function attributeChanged(selectedValues) {
        var _this = this;
        var inheritedValues = this.get("budget.parent.inheritedAttributes.tags") || [];
        this.updateSelectedValuesByDifference(selectedValues, inheritedValues);
        this.set("budgetAttributes.tags", []);
        selectedValues.forEach(function (selected) {
          _this.get("budgetAttributes.tags").pushObject({
            name: selected.name
          });
        });
        this.$().trigger("change");
      }
    }
  });
});