define("budgeta/initializers/ember-css-modules", ["exports", "ember-css-modules/initializers/ember-css-modules"], function (_exports, _emberCssModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberCssModules.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _emberCssModules.initialize;
    }
  });
});