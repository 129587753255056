define("budgeta/services/websocket/websocket", ["exports", "budgeta/services/websocket/const", "budgeta/services/websocket/config"], function (_exports, _const, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "MESSAGE_TYPE", {
    enumerable: true,
    get: function get() {
      return _const.MESSAGE_TYPE;
    }
  });
  _exports.webSocket = void 0;
  var socket;
  var messageHandlers = {};
  var budgetId;
  var userId;
  var webSocket = _exports.webSocket = {
    init: function init(data) {
      try {
        budgetId = data.budgetId;
        userId = data.userId;
        this.addMessageHandler(_const.MESSAGE_TYPE.NEW_CONNECTION, function (_ref) {
          var data = _ref.data;
          return console.log(_const.LOGS.RECIEVED_CONNECTION_ID, data.socketId);
        });
        connect();
      } catch (error) {
        console.error(_const.LOGS.INIT_ERROR, error);
      }
    },
    addMessageHandler: function addMessageHandler(type, messageHandler) {
      messageHandlers[type] = messageHandler;
    },
    sendMessage: function sendMessage(message) {
      socket.send(message);
    }
  };
  var connect = function connect() {
    try {
      socket = new WebSocket(_config.serverSocketUrl);
      initEventsHandlers();
    } catch (error) {
      console.error(_const.LOGS.CONNECT_SERVER_ERROR, error);
    }
  };
  var initEventsHandlers = function initEventsHandlers() {
    try {
      socket.onopen = function () {
        console.log(_const.LOGS.CONNECTION_ESTABLISHED);
        socket.send(JSON.stringify({
          type: _const.MESSAGE_TYPE.NEW_CONNECTION,
          budgetId: budgetId,
          userId: userId
        }));
      };
      socket.onmessage = function (message) {
        var data = JSON.parse(message.data);
        if (data !== null && data !== void 0 && data.type && messageHandlers[data.type]) {
          messageHandlers[data.type](data);
        }
      };
      socket.onerror = function (error) {
        console.error(_const.LOGS.ON_ERROR, error);
        socket.close();
      };
      socket.onclose = function (event) {
        console.log("".concat(_const.LOGS.SOCKET_CLOSED, ", code: ").concat(event === null || event === void 0 ? void 0 : event.code));
        setTimeout(connect, _config.reconnectTimeout);
      };
    } catch (error) {
      console.error(_const.LOGS.INIT_HANDLERS_ERROR, error);
    }
  };
});