define("budgeta/templates/components/dimension-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MQ6nO1li",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"dimension-mapping-wrapper flexbox items-center\"],[\"static-attr\",\"data-hook\",\"dimension-mapping\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"pr-10 big-mid-text-steel\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"t\"],[\"general.map_1698824574932\"],null],false],[\"text\",\"\\n    \"],[\"open-element\",\"span\",[]],[\"static-attr\",\"data-hook\",\"si-dimension-name\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"dimensionMapping\",\"financialDimensionDisplay\"]],false],[\"close-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"t\"],[\"general.to_budget_dimension_1698824574933\"],null],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"append\",[\"helper\",[\"power-select-lazy\"],null,[[\"selected\",\"options\",\"searchEnabled\",\"enabled\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\",\"powerSelectChange\",\"isHighlight\",\"dropdownClass\"],[[\"get\",[\"dimensionMapping\",\"dimensionId\"]],[\"get\",[\"dimensionOptionsDisplay\"]],false,[\"get\",[\"enabled\"]],\"id\",\"text\",[\"helper\",[\"t\"],[\"general.please_select_1698824574709\"],null],\"powerSelectChange\",\"true\",\"dimension-mapping\"]]],false],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/dimension-mapping.hbs"
    }
  });
});