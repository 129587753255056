define("budgeta/components/drop-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DropDown = Ember.Component.extend({
    classNameBindings: ["isOpen:displayed"],
    qtipClasses: "",
    attributeBindings: ["for", "hook:data-hook"],
    hook: "drop-down",
    showEvent: "click",
    hideEvent: "mouseleave unfocus click",
    positionAt: "bottom",
    positionMy: "top",
    open: false,
    disableHide: false,
    adjustX: 0,
    adjustY: 0,
    visibleAction: null,
    hideAction: null,
    isOpen: false,
    ignoreSelect2: false,
    hideDelay: 50,
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.$()) {
        this.$(".close-popup").off("click");
      }
      var target = Ember.$("#" + this.get("for"));
      target.off("focusout");
      var api = this.get("api");
      if (api) {
        if (api.elements && api.elements.tooltip) {
          api.elements.tooltip.remove();
        }
        api.destroy();
        this.set("api", null);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.createDropDown();
    },
    containerSelector: ".my-qtip-container, body.ember-application > div.app-view",
    createDropDown: function createDropDown() {
      Ember.run.schedule("afterRender", this, function () {
        var _this = this;
        var target = Ember.$("#" + this.get("for"));
        var api = target ? target.qtip("api") : null;
        if (api) {
          api.destroy();
        }
        if (!this.isDestroying) {
          var tooltip = target.qtip({
            position: {
              container: target.closest(this.get("containerSelector")),
              at: this.get("positionAt"),
              my: this.get("positionMy"),
              viewport: true,
              adjust: {
                x: this.get("adjustX"),
                y: this.get("adjustY"),
                method: "shift"
              }
            },
            content: this.$(),
            show: {
              ready: this.get("open"),
              event: this.get("showEvent")
            },
            hide: {
              event: this.get("hideEvent"),
              delay: this.get("hideDelay"),
              fixed: true
            },
            style: {
              width: this.get("width") ? this.get("width") : target.is("input") ? target.outerWidth() : false,
              classes: "sibp-qtip " + this.get("qtipClasses"),
              tip: this.get("tip") || false
            },
            events: {
              show: function show() {
                if (_this.get("showAction")) {
                  _this.sendAction("showAction");
                }
              },
              visible: function visible(e, qtip) {
                if (!_this.isDestroying && !_this.isDestroyed) {
                  _this.set("isOpen", true);
                }
                if (_this.get("visibleAction")) {
                  _this.sendAction(_this.get("visibleAction"), qtip);
                }
              },
              hide: function hide(event) {
                if (event.originalEvent && event.originalEvent.type !== "externalHide" && (_this.get("disableHide") || _this.get("ignoreSelect2") && event.originalEvent.target.className.includes("select2") || event.originalEvent && event.originalEvent.type === "click" && !Ember.$(event.originalEvent.target).hasClass("close-popup") && (Ember.$(event.originalEvent.target).parents(".prevent-close-on-content-click").length || Ember.$(event.target).hasClass("prevent-close-on-content-click") || Ember.$(event.originalEvent.target).hasClass("prevent-close-on-content-click")))) {
                  event.preventDefault();
                } else if (!_this.isDestroying && !_this.isDestroyed) {
                  _this.set("isOpen", false);
                  if (_this.get("hideAction")) {
                    _this.sendAction("hideAction");
                  }
                }
              }
            }
          });
          this.set("api", tooltip.qtip("api"));
          this.set("tooltip", tooltip);
          var hideTip = function hideTip() {
            if (!Ember.$(this).data("no-hide")) {
              tooltip.qtip("api").hide();
            }
          };
          if (target.is("input")) {
            target.on("focusout", hideTip);
          }
          if (this.$()) {
            this.$(".close-popup").on("click", hideTip);
          }
        }
      });
    },
    forObserver: Ember.observer("for", function () {
      this.createDropDown();
    }),
    openObserver: Ember.observer("isOpen", "for", function () {
      if (this.get("for")) {
        if (this.get("isOpen")) {
          Ember.$("#" + this.get("for")).addClass("displayed");
        } else {
          Ember.$("#" + this.get("for")).removeClass("displayed");
        }
      }
    }),
    disabledObserver: Ember.observer("disabled", function () {
      this.set("api.disabled", this.get("disabled"));
    })
  });
  var _default = _exports.default = DropDown;
});