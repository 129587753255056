define("budgeta/generic-file/components/generic-progress-bar/generic-progress-bar", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "react", "@sibp/ui-components", "react-redux", "budgeta/services/ember-service-container", "budgeta/generic-file/components/generic-manager/stepMap"], function (_exports, _slicedToArray2, _react, _uiComponents, _reactRedux, _emberServiceContainer, _stepMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = GenericProgressBar;
  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var PROGRESS_BAR_PATH = "generic-file.progress-bar";
  var stepMap = (0, _stepMap.getStepHierarchy)();
  var initialMainSteps = Object.getOwnPropertyNames(stepMap).map(function (mainStepName) {
    var _stepMap$mainStepName = stepMap[mainStepName],
      number = _stepMap$mainStepName.number,
      renderContent = _stepMap$mainStepName.renderContent;
    return {
      title: mainStepName,
      number: number,
      renderContent: renderContent
    };
  });
  function GenericProgressBar(_ref) {
    var close = _ref.close;
    var _ReactRedux$useSelect = _reactRedux.default.useSelector(function (state) {
        return state.stepsStore;
      }),
      currentStep = _ReactRedux$useSelect.currentStep;
    var _ReactRedux$useSelect2 = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore;
      }),
      columnMapping = _ReactRedux$useSelect2.columnMapping;
    var _ReactRedux$useSelect3 = _reactRedux.default.useSelector(function (state) {
        return state.budgetStore.notAllowedLinesDetails;
      }),
      activeStep = _ReactRedux$useSelect3.activeStep;
    var fileName = _reactRedux.default.useSelector(function (state) {
      return state.fileStore.name;
    });
    var _React$useState = _react.default.useState(1),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      currMainStep = _React$useState2[0],
      setCurrMainStep = _React$useState2[1];
    var _React$useState3 = _react.default.useState(""),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      currSubStep = _React$useState4[0],
      setCurrSubStep = _React$useState4[1];
    var _React$useState5 = _react.default.useState([]),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      currTextList = _React$useState6[0],
      setCurrTextList = _React$useState6[1];
    var _React$useState7 = _react.default.useState(false),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      shouldShowCurrencies = _React$useState8[0],
      setShouldShowCurrencies = _React$useState8[1];
    _react.default.useEffect(function () {
      var _currSubStep = (0, _stepMap.getCurrentSubStep)(currentStep);
      var _currMainStep, _currTextList;
      for (var mainStep in stepMap) {
        var _stepMap$mainStep = stepMap[mainStep],
          textList = _stepMap$mainStep.textList,
          number = _stepMap$mainStep.number;
        if (textList.has(_currSubStep)) {
          _currMainStep = number;
          _currTextList = textList;
        }
      }
      if (currentStep === "finailizePreview" || currentStep === "categoryError") {
        _currSubStep = activeStep === "accountCategory" ? intl.t("".concat(PROGRESS_BAR_PATH, ".sub-steps.account-category")) : intl.t("".concat(PROGRESS_BAR_PATH, ".sub-steps.currencies"));
      }
      if (!shouldShowCurrencies && _currMainStep === stepMap[intl.t("".concat(PROGRESS_BAR_PATH, ".main-steps.map-data"))].number) {
        _currTextList = Array.from(_currTextList).filter(function (text) {
          return text !== intl.t("".concat(PROGRESS_BAR_PATH, ".sub-steps.currencies"));
        });
      }
      setCurrMainStep(_currMainStep);
      setCurrTextList(Array.from(_currTextList));
      setCurrSubStep(_currSubStep);
    }, [currentStep, shouldShowCurrencies]);
    _react.default.useEffect(function () {
      if (columnMapping && columnMapping.find(function (column) {
        return column.selectedAttribute === "currency";
      })) {
        setShouldShowCurrencies(true);
      } else setShouldShowCurrencies(false);
    }, [columnMapping]);
    return _react.default.createElement("section", {
      className: "generic-wizard-section"
    }, _react.default.createElement("div", {
      className: "progress-div"
    }, currTextList && _react.default.createElement(_uiComponents.default.COMPONENTS.AccordionManager, {
      steps: initialMainSteps,
      activeStep: currMainStep,
      contentParams: [currTextList, currSubStep],
      dataHook: "progress-bar-accordion"
    })), _react.default.createElement("div", {
      className: "progress-footer"
    }, fileName && _react.default.createElement("div", {
      className: "file-upload"
    }, _react.default.createElement("div", {
      className: "title"
    }, intl.t("generic-file.progress-bar.uploaded-file")), _react.default.createElement("div", {
      className: "file-name overflow-ellipsis",
      title: "".concat(fileName)
    }, fileName)), _react.default.createElement("div", {
      className: "cancel-container",
      id: "cancel-container",
      onClick: close
    }, _react.default.createElement(_uiComponents.default.IMAGES.CloseSvg, null), _react.default.createElement(_uiComponents.default.COMPONENTS.Button, {
      className: "footer-button secondary back",
      text: intl.t("generic-file.cancel")
    }))));
  }
});