define("budgeta/utils/__mocks__/const", ["@babel/runtime/helpers/esm/objectSpread2"], function (_objectSpread2) {
  /* eslint-disable no-undef */
  "use strict";

  var Const = function () {
    var moment = jest.requireActual("moment");
    global.moment = (0, _objectSpread2.default)({}, moment);
    var realModule = jest.requireActual("../const");
    return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, realModule), {}, {
      __esModule: true
    });
  }();
  module.exports = Const;
});