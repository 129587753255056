define("budgeta/components/dynamic-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    filter: Ember.computed("filters", "attr", function () {
      return this.get("filters").findBy("key", this.get("attributeName"));
    }),
    attributeName: Ember.computed("attr", "existing", function () {
      return this.get("existing") ? "".concat(this.get("attr"), "Existing") : this.get("attr");
    }),
    actions: {
      sort: function sort(attr) {
        this.sendAction("sort", attr);
      }
    }
  });
});