define("budgeta/templates/components/employees-table-status-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IGMdOyOW",
    "block": "{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"isEditable\"]]],null,1,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"text-status\"],[\"dynamic-attr\",\"title\",[\"concat\",[[\"unknown\",[\"statusText\"]]]]],[\"flush-element\"],[\"append\",[\"unknown\",[\"statusText\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"  \"],[\"append\",[\"helper\",[\"select-2\"],null,[[\"enabled\",\"searchEnabled\",\"class\",\"value\",\"content\",\"optionValuePath\",\"didSelect\"],[[\"get\",[\"isEditable\"]],false,\"status-select\",[\"get\",[\"status\"]],[\"get\",[\"statusOptions\"]],\"id\",[\"get\",[\"attributeChanged\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/employees-table-status-cell.hbs"
    }
  });
});