define("budgeta/services/collapsed-budgets", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    treeManager: Ember.inject.service(),
    viewSelections: Ember.inject.service(),
    collapsed_map: {},
    collapsed_tree_map: {},
    cache_collapsed_map: {},
    cache_collapsed_tree_map: {},
    budgetId: "",
    collapseTrigger: null,
    getLineId: function getLineId(line) {
      var id = Ember.get(line, "id") || Ember.get(line, "budget.id");
      var dimeId = id.split("#")[1];
      var uniqueId = Ember.get(line, "uniqueId") || Ember.get(line, "budget.uniqueId");
      return "".concat(uniqueId).concat(dimeId ? "#" + dimeId : "");
    },
    getLinePath: function getLinePath(line) {
      var path = "";
      if (line) {
        if (Ember.get(line, "group_value") && Ember.get(line, "group_value").toString().trim()) {
          var budgetId = this.getLineId(line);
          path = Ember.get(line, "group_value").toString().trim() + "#" + budgetId;
        }
        path = Ember.get(line, "parent") ? this.getLinePath(Ember.get(line, "parent")) + "_" + path : path;
        return _const.default.hashCode(path.replace(/\./g, "$"));
      }
      return "";
    },
    selections: {},
    // eslint-disable-next-line ember/no-observers
    selectionsLis: Ember.observer("viewSelections.dimensions.@each.selectedValue", "viewSelections.account", function () {
      var dimensions = this.get("viewSelections.dimensions");
      var account = this.get("viewSelections.account");
      var selections = this.get("selections");
      var resetCache = false;
      dimensions.forEach(function (dim) {
        if (selections[Ember.get(dim, "dimension.uniqueId")]) {
          // the user used to select
          if (selections[Ember.get(dim, "dimension.uniqueId")] !== Ember.get(dim, "selectedValue")) {
            // the user changed selcetion;
            resetCache = true;
          }
        } else if (Ember.get(dim, "selectedValue") && Ember.get(dim, "selectedValue") !== "all") {
          // new dimension filtering
          if (Ember.get(dim, "selectedValue") === "by") {
            resetCache = true;
          }
          selections[Ember.get(dim, "dimension.uniqueId")] = Ember.get(dim, "selectedValue");
        }
      });
      if ((selections.account || "") !== account) {
        resetCache = true;
      }
      selections.account = account;
      if (resetCache) {
        this.resetCacheCollapsed();
      }
    }),
    resetCacheCollapsed: function resetCacheCollapsed() {
      this.set("cache_collapsed_map", {});
      this.set("cache_collapsed_tree_map", {});
      var dimensions = this.get("viewSelections.dimensions");
      var selections = {};
      dimensions.forEach(function (dim) {
        if (Ember.get(dim, "selectedValue") && Ember.get(dim, "selectedValue") !== "all") {
          selections[Ember.get(dim, "dimension.uniqueId")] = Ember.get(dim, "selectedValue");
        }
      });
      this.set("selections", selections);
    },
    isCacheCollapsed: false,
    addCacheTreeItem: function addCacheTreeItem(line, doRecursive) {
      var _this = this;
      if (Ember.get(line, "group_value") && Ember.get(line, "group_value").toString().trim()) {
        var budgetPath = this.getLinePath(line);
        this.set("cache_collapsed_tree_map.".concat(budgetPath), true);
        if (doRecursive && Ember.get(line, "children")) {
          Ember.get(line, "children").forEach(function (child) {
            _this.addCacheItem(child, doRecursive);
          });
        }
      }
    },
    addCacheItem: function addCacheItem(line, doRecursive) {
      var _this2 = this;
      if (Ember.get(line, "group_value") && Ember.get(line, "group_value").toString().trim()) {
        var budgetPath = this.getLinePath(line);
        this.set("cache_collapsed_map.".concat(budgetPath), true);
        if (doRecursive && Ember.get(line, "children")) {
          Ember.get(line, "children").forEach(function (child) {
            _this2.addCacheItem(child, doRecursive);
          });
        }
      }
    },
    setLineCollapsed: function setLineCollapsed(line, isCollapsed, doRecursive) {
      if (isCollapsed) {
        this.addCacheItem(line, doRecursive);
        this.addCacheTreeItem(line.parent);
      } else {
        this.removeCacheItem(line, doRecursive);
      }
    },
    removeCacheTreeItem: function removeCacheTreeItem(line, doRecursive) {
      var _this3 = this;
      if (Ember.get(line, "group_value") && Ember.get(line, "group_value").toString().trim()) {
        var budgetPath = this.getLinePath(line);
        this.set("cache_collapsed_tree_map.".concat(budgetPath), false);
        if (doRecursive && Ember.get(line, "children")) {
          Ember.get(line, "children").forEach(function (child) {
            if (!_this3.getCacheTreeItem(child)) {
              _this3.removeCacheItem(child, true); // remove all sub
            } else {
              _this3.removeCacheItem(child, false); // remove only the first level
            }
          });
        }
      }
    },
    removeCacheItem: function removeCacheItem(line, doRecursive) {
      var _this4 = this;
      if (Ember.get(line, "group_value") && Ember.get(line, "group_value").toString().trim()) {
        var budgetPath = this.getLinePath(line);
        this.set("cache_collapsed_map.".concat(budgetPath), false);
        if (doRecursive && Ember.get(line, "children") && !this.getCacheTreeItem(line)) {
          Ember.get(line, "children").forEach(function (child) {
            _this4.removeCacheItem(child, doRecursive);
          });
        }
      }
    },
    getCacheItem: function getCacheItem(line, checkParent) {
      if (line && Ember.get(line, "group_value") && Ember.get(line, "group_value").toString().trim()) {
        var budgetPath = this.getLinePath(line);
        if (this.cache_collapsed_map[budgetPath]) {
          return true;
        } else if (checkParent) {
          return this.getCacheItem(Ember.get(line, "parent"), checkParent);
        } else {
          return false;
        }
      }
      return undefined;
    },
    getCacheTreeItem: function getCacheTreeItem(line, checkParent, checkUndefined) {
      if (line && Ember.get(line, "group_value") && Ember.get(line, "group_value").toString().trim()) {
        var budgetPath = this.getLinePath(line);
        if (this.cache_collapsed_tree_map[budgetPath]) {
          return true;
        } else if (checkParent) {
          return this.getCacheTreeItem(Ember.get(line, "parent"), checkParent);
        } else if (checkUndefined && typeof this.cache_collapsed_tree_map[budgetPath] === "undefined") {
          return undefined;
        } else {
          return false;
        }
      }
      return undefined;
    },
    setCollapsedBudget: function setCollapsedBudget(budget) {
      var collapsed_map = {},
        collapsed_tree_map = {};
      if (Ember.get(budget, "id")) {
        this.set("budgetId", Ember.get(budget, "id"));
        collapsed_map = JSON.parse(localStorage.getItem(Ember.get(budget, "id") + "_collapsed") || "{}");
        collapsed_tree_map = JSON.parse(localStorage.getItem(Ember.get(budget, "id") + "_collapsed_tree") || "{}");
      }
      if (!collapsed_map["timestamp"] || !collapsed_tree_map["timestamp"]) {
        collapsed_map = {
          timestamp: Date.now()
        };
        collapsed_tree_map = {
          timestamp: Date.now()
        };
      }
      this.set("collapsed_map", collapsed_map);
      this.set("collapsed_tree_map", collapsed_tree_map);
      return collapsed_map;
    },
    getItem: function getItem(ele) {
      if (!ele) {
        return false;
      }
      var parent = Ember.get(ele, "parent");
      if (parent) {
        return this.get("collapsed_tree_map.".concat(Ember.get(parent, "id"))) ? true : this.getItem(parent);
      }
      return false;
    },
    getTreeItem: function getTreeItem(ele) {
      if (!ele) {
        return false;
      }
      var budgetId = Ember.get(ele, "id");
      if (budgetId) {
        return this.get("collapsed_tree_map.".concat(budgetId)) ? true : false;
      }
      return false;
    },
    addItem: function addItem(ele, doRecursive, isSearchMode, waitWithBudgetCollapsed, elementIdArray) {
      var _this5 = this;
      if (ele && ele.id) {
        if (!isSearchMode) {
          this.set("collapsed_map.".concat(ele.id), true);
        }
        if (!waitWithBudgetCollapsed) {
          this.get("treeManager").setBudgetCollapsed(ele.id, true, isSearchMode);
        } else {
          elementIdArray.push(ele.id);
        }
        if (Ember.get(ele, "children") && (doRecursive && !this.getTreeItem(ele) || isSearchMode)) {
          Ember.get(ele, "children").forEach(function (child) {
            _this5.addItem(child, doRecursive, isSearchMode, waitWithBudgetCollapsed, elementIdArray);
          });
        }
      }
    },
    addItems: function addItems(eleArr, doRecursive) {
      var _this6 = this;
      if (!Ember.isEmpty(eleArr)) {
        eleArr.forEach(function (ele) {
          if (ele && ele.id) {
            _this6.addItem(ele, doRecursive);
          }
        });
      }
    },
    addTreeItem: function addTreeItem(ele, doRecursive, isSearchMode, waitWithBudgetCollapsed) {
      var _this7 = this;
      var elementIdArray = [];
      if (ele && ele.id) {
        if (!isSearchMode) {
          this.set("collapsed_tree_map.".concat(ele.id), true);
        }
        if (doRecursive && Ember.get(ele, "children")) {
          Ember.get(ele, "children").forEach(function (child) {
            _this7.addItem(child, doRecursive, isSearchMode, waitWithBudgetCollapsed, elementIdArray);
          });
        }
      }
      return elementIdArray;
    },
    addTreeItems: function addTreeItems(eleArr, doRecursive) {
      var _this8 = this;
      if (!Ember.isEmpty(eleArr)) {
        eleArr.forEach(function (ele) {
          if (ele && ele.id) {
            _this8.addTreeItem(ele, doRecursive);
          }
        });
      }
    },
    removeItem: function removeItem(ele, doRecursive, isSearchMode, waitWithBudgetCollapsed, elementIdArray) {
      var _this9 = this;
      if (ele && ele.id) {
        if (!isSearchMode) {
          delete this.get("collapsed_map")[ele.id];
        }
        if (!waitWithBudgetCollapsed) {
          var ignoreChildren = this.get("treeManager").setBudgetCollapsed(ele.id, false, isSearchMode);
          if (ignoreChildren) {
            return;
          }
        } else {
          elementIdArray.push(ele.id);
        }
        if (Ember.get(ele, "children") && (doRecursive && !this.getTreeItem(ele) || isSearchMode)) {
          Ember.get(ele, "children").forEach(function (child) {
            _this9.removeItem(child, doRecursive, isSearchMode, waitWithBudgetCollapsed, elementIdArray);
          });
        }
      }
    },
    removeTreeItem: function removeTreeItem(ele, doRecursive, isSearchMode, waitWithBudgetCollapsed) {
      var _this10 = this;
      var elementIdArray = [];
      if (ele && ele.id) {
        if (!isSearchMode) {
          delete this.get("collapsed_tree_map")[ele.id];
        }
        if (doRecursive && Ember.get(ele, "children")) {
          Ember.get(ele, "children").forEach(function (child) {
            if (!_this10.getTreeItem(child)) {
              _this10.removeItem(child, true, isSearchMode, waitWithBudgetCollapsed, elementIdArray); // remove all sub
            } else {
              _this10.removeItem(child, false, isSearchMode, waitWithBudgetCollapsed, elementIdArray); // remove only the first level
            }
          });
        }
      }
      return elementIdArray;
    },
    setToParentTree: function setToParentTree(parent, child) {
      if (parent && parent.id && (this.get("collapsed_tree_map.".concat(parent.id)) || this.get("collapsed_map.".concat(parent.id)))) {
        this.addItem(child, true);
      }
    },
    save: function save() {
      var collapsed_map_str = JSON.stringify(this.get("collapsed_map"));
      _const.default.localStorageSetItem(this.get("budgetId") + "_collapsed", collapsed_map_str);
      var collapsed_tree_map_str = JSON.stringify(this.get("collapsed_tree_map"));
      _const.default.localStorageSetItem(this.get("budgetId") + "_collapsed_tree", collapsed_tree_map_str);
      this.notifyPropertyChange("collapsed_map");
      this.notifyPropertyChange("collapsed_tree_map");
    },
    saveCache: function saveCache() {
      this.notifyPropertyChange("cache_collapsed_map");
      this.notifyPropertyChange("cache_collapsed_tree_map");
    },
    setCollapsePosition: function setCollapsePosition(copy) {
      if (this.getTreeItem(copy.get("parent")) || this.getItem(copy.get("parent"))) {
        this.addItem(copy, true);
        this.save();
      }
    }
  });
});