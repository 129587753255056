define("budgeta/admin/admin-app/pages/adminUserList/columns", ["exports", "react", "react-redux", "react-router-dom", "@sibp/ui-components", "budgeta/admin/admin-app/routes", "budgeta/services/ember-service-container", "budgeta/admin/utils/const"], function (_exports, _react, _reactRedux, _reactRouterDom, _uiComponents, _routes, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.columns = void 0;
  /* eslint-disable react-hooks/rules-of-hooks */

  var columns = _exports.columns = function columns() {
    var intl = new _emberServiceContainer.default().lookup("intl");
    var history = _reactRouterDom.default.useHistory();
    var loggedInUser = _reactRedux.default.useSelector(function (state) {
      return state.securityStore.loggedInUser;
    });
    var userStatuses = Object.values(_const.ADMIN_CONSOLE_USER_STATUS);
    return [{
      header: intl.t("admin_app.form.full_name"),
      accessor: "fullName",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref) {
        var value = _ref.value,
          userDetails = _ref.otherProps;
        var isSuperAdmin = loggedInUser && loggedInUser.isSuperAdmin;
        return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_uiComponents.default.COMPONENTS.Link, {
          text: value,
          onClick: function onClick() {
            return isSuperAdmin && history.push(_reactRouterDom.default.generatePath(_routes.ROUTES.ADMIN_USER_EDIT, {
              userId: userDetails._id
            }));
          },
          disabled: !isSuperAdmin
        }), userDetails.roles.includes(_const.USER_ROLE.SUPER_ADMIN) && _react.default.createElement("span", {
          className: "sibp-badge"
        }, intl.t("admin_app.super_admin_role")));
      },
      sortDir: 1
    }, {
      header: intl.t("admin_app.form.email"),
      accessor: "email",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING
    }, {
      header: intl.t("admin_app.form.status"),
      accessor: "adminConsole.status",
      dataType: _uiComponents.default.TABLE_SORTER.DATA_TYPES.STRING,
      cell: function cell(_ref2) {
        var value = _ref2.value;
        var userStatus = userStatuses.find(function (s) {
          return s.value === value;
        });
        return userStatus ? userStatus.text : "";
      }
    }];
  };
});