define("budgeta/components/formula-editor-modal", ["exports", "budgeta/components/modal-new", "budgeta/utils/formula", "budgeta/utils/const"], function (_exports, _modalNew, _formula, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function moveCaretToEnd(el, target) {
    if (typeof el.selectionStart === "number") {
      el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange !== "undefined") {
      el.focus();
      var range = el.createTextRange();
      range.collapse(false);
      range.select();
    } else {
      var _range = document.createRange();
      var sel = window.getSelection();
      var anchorNode = sel.anchorNode;
      if (!_const.default.IS_CHROME && target && target.className && target.className === "ember-view formula-textarea" && target.firstChild && target.firstChild.nextSibling && target.firstChild.nextSibling.nodeName === "P" && target.firstChild.nextSibling.lastChild && !(anchorNode && anchorNode.nodeValue === "=" && sel.anchorNode.parentNode && anchorNode.parentNode.nodeName === "DIV")) {
        var paragraph = target.firstChild.nextSibling;
        var anchorOffset = sel.anchorOffset;
        var focusChild = paragraph.childNodes[anchorOffset - 1]; // for edge

        if (_const.default.IS_SAFARI) {
          focusChild = paragraph.lastChild;
        }
        if (focusChild) {
          if (focusChild.nodeName === "SPAN") {
            focusChild = focusChild.firstChild;
          }
          _range = document.createRange();
          var offset = _const.default.IS_SAFARI || anchorOffset > 1 ? focusChild.nodeValue.length : 0;
          _range.setStart(focusChild, offset);
          _range.setEnd(focusChild, offset);
          sel.removeAllRanges();
          sel.addRange(_range);
          el.focus();
          return;
        }
      } else if (anchorNode && anchorNode.parentNode && anchorNode.parentNode.nodeName === "SPAN" &&
      // click on model/assumption
      anchorNode.parentNode.parentNode && anchorNode.parentNode.parentNode.nodeName === "P" || anchorNode && anchorNode.nodeName === "SPAN" &&
      // click on model/assumption - for edge support
      anchorNode.parentNode && anchorNode.parentNode.nodeName === "P") {
        var _anchorOffset = sel.anchorOffset === 0 ? 0 : anchorNode.length;
        if (anchorNode.nodeName === "SPAN") {
          // for edge support
          anchorNode = anchorNode.firstChild;
          if (sel.anchorOffset) {
            // at start or end
            _anchorOffset = anchorNode.length;
          }
        }
        _range.setStart(anchorNode, _anchorOffset);
        _range.setEnd(anchorNode, _anchorOffset);
        sel.removeAllRanges();
        sel.addRange(_range);
        el.focus();
      } else {
        if (anchorNode && anchorNode && anchorNode.nodeName === "#text" && anchorNode.parentNode.nodeName === "P") {
          // click on text
          // do nothing
        } else {
          if (anchorNode && anchorNode.nodeName === "P") {
            // click on the end of a text element - edge only
            var _anchorOffset2 = sel.anchorOffset;
            var _focusChild = anchorNode.childNodes[_anchorOffset2 - 1];
            if (_focusChild) {
              if (_focusChild.nodeName === "SPAN") {
                _focusChild = _focusChild.firstChild;
              }
              _range = document.createRange();
              _range.setStart(_focusChild, _focusChild.nodeValue.length);
              _range.setEnd(_focusChild, _focusChild.nodeValue.length);
              sel.removeAllRanges();
              sel.addRange(_range);
              return;
            }
          }
          if (anchorNode && anchorNode.nodeValue === "=") {
            var para = anchorNode.nextSibling; // get <p>
            var firstChild = para ? para.firstChild : null;
            if (firstChild) {
              // if first child is a model line/assumption
              if (firstChild.nodeName === "SPAN") {
                firstChild = firstChild.firstChild;
              }
              _range = document.createRange();
              _range.setStart(firstChild, 0);
              _range.setEnd(firstChild, 0);
              sel.removeAllRanges();
              sel.addRange(_range);
            } else {
              _range = document.createRange();
              _range.setStart(para, 0);
              _range.setEnd(para, 0);
              sel.removeAllRanges();
              sel.addRange(_range);
            }
          } else {
            _range.selectNodeContents(el);
            _range.collapse(false);
            sel.removeAllRanges();
            sel.addRange(_range);
            el.focus();
          }
        }
      }
    }
  }
  var _default = _exports.default = _modalNew.default.extend({
    classNames: ["formula-editor-modal"],
    attributeBindings: ["hook:data-hook"],
    hook: "formula-editor-modal",
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set("controllertmp.initialValue", this.get("controllertmp.model.value"));
      Ember.run.schedule("afterRender", function () {
        var fomulaTextarea = Ember.$(".formula-textarea");
        if (fomulaTextarea && fomulaTextarea.length) {
          moveCaretToEnd(fomulaTextarea.get(0));
          fomulaTextarea.on("paste", function (e) {
            e.preventDefault();
          });
        }
      });
    },
    scrollPositionObserver: Ember.observer("controllertmp.chosenAssumptionPos", function () {
      var _this = this;
      Ember.run.scheduleOnce("afterRender", function () {
        var assumption = _this.get("controllertmp.chosenAssumptionPos");
        if (assumption) {
          var liElement = Ember.$(".assumption-list > .assumption-list-wrapper > .model-content > ul > li[data-uniqueId=\"".concat(Ember.get(assumption, "uniqueId"), "\"]"));
          Ember.$(".assumption-list-wrapper").scrollTo(liElement);
        }
      });
    }),
    willDestroyElement: function willDestroyElement() {
      // When you pass props to open modal, someone set it on the controller.
      // That's why we need to remove it. We added it in grid-main (openFormulaEditor).
      this.set("controllertmp.onClose", null);
      this.set("controllertmp.onSave", null);

      // a fix for a bug in Ember 1.12 see https://github.com/emberjs/ember.js/issues/10869
      this.removeObserver("controllertmp.model.value");
      this._super();
    },
    valueObserver: Ember.observer("controllertmp.model.value", function () {
      Ember.run.scheduleOnce("afterRender", function () {
        var fomulaTextarea = Ember.$(".formula-textarea");
        if (fomulaTextarea && fomulaTextarea.length) {
          moveCaretToEnd(fomulaTextarea.get(0));
        }
      });
    }),
    mouseUp: function mouseUp(e) {
      var fomulaTextarea = Ember.$(e.target).closest(".formula-textarea");
      if (fomulaTextarea.length) {
        moveCaretToEnd(fomulaTextarea.get(0), e.target);
      }
    },
    mouseDown: function mouseDown() {
      var selection = window.getSelection();
      this.set("controllertmp.savedSelection", {
        selectionNode: selection.anchorNode,
        anchorOffset: selection.anchorOffset
      });
      return true;
    },
    getFormulaHTML: function getFormulaHTML() {
      return "=<p>".concat(this.$("p").html(), "</p>");
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === 8) {
        // backspace
        Ember.set(this.get("controllertmp"), "lastResult", null);
        if (this.get("controllertmp").backspaceManual(false)) {
          return e.preventDefault();
        }
      } else if (e.keyCode === 46) {
        // delete
        Ember.set(this.get("controllertmp"), "lastResult", null);
        if (this.get("controllertmp.formulaValue") === "=" || this.get("controllertmp.formulaValue") === "=<p></p>") {
          this.set("controllertmp.formulaValue", this.getFormulaHTML());
          return e.preventDefault();
        }
        var selection = window.getSelection(),
          selectionNode = selection.anchorNode;
        if (_formula.default.isFormulaTextSection(selection) && selectionNode) {
          if (selectionNode.nextSibling && selectionNode.nextSibling.nodeName === "SPAN" && selectionNode.nodeValue.length === selection.anchorOffset) {
            var parentElement = selectionNode.parentElement;
            var next = selectionNode.nextSibling;
            var nextText = next.nextSibling;
            if (nextText && nextText.nodeName === "#text") {
              // merge with the next text element
              var position = selectionNode.nodeValue.length;
              selectionNode.nodeValue += nextText.nodeValue;
              var range = document.createRange();
              parentElement.removeChild(nextText);
              range.setStart(selectionNode, position);
              range.setEnd(selectionNode, position);
              selection.removeAllRanges();
              selection.addRange(range);
            }
            parentElement.removeChild(selectionNode.nextSibling);
            this.set("controllertmp.formulaValue", this.getFormulaHTML());
            return e.preventDefault();
          } else if (selection.anchorOffset === 0) {
            selectionNode.nodeValue = selectionNode.nodeValue.substr(1);
            if (!selectionNode.nodeValue) {
              // remove the last char - delete the text element
              var _next = selectionNode.nextSibling;
              var prev = selectionNode.previousSibling;
              var _range2 = document.createRange();
              if (_next) {
                if (_next.nodeName === "SPAN") {
                  _next = _next.firstChild;
                }
                _range2.setStart(_next, 0);
                _range2.setEnd(_next, 0);
                selection.removeAllRanges();
                selection.addRange(_range2);
              } else if (prev) {
                if (prev.nodeName === "SPAN") {
                  prev = _next ? _next.firstChild : prev;
                }
                _range2.setStart(prev, prev.nodeValue ? prev.nodeValue.length : 0);
                _range2.setEnd(prev, prev.nodeValue ? prev.nodeValue.length : 0);
                selection.removeAllRanges();
                selection.addRange(_range2);
              }
              selectionNode.parentElement.removeChild(selectionNode);
            }
            this.set("controllertmp.formulaValue", this.getFormulaHTML());
            return e.preventDefault();
          }
        } else if (_formula.default.isFormulaSpanSection(selection) && selectionNode) {
          var spanEle = selectionNode.parentElement;
          if (selection.anchorOffset) {
            // at the end
            var _next2 = spanEle.nextSibling;
            if (_next2 && _next2.nodeName === "SPAN") {
              spanEle.parentElement.removeChild(_next2);
              this.set("controllertmp.formulaValue", this.getFormulaHTML());
              return e.preventDefault();
            }
          } else {
            // at the begining
            var _next3 = spanEle.nextSibling;
            var _prev = spanEle.previousSibling;
            var _range3 = document.createRange();
            if (_next3) {
              if (_next3.nodeName === "SPAN") {
                _next3 = _next3.firstChild;
              }
              _range3.setStart(_next3, 0);
              _range3.setEnd(_next3, 0);
              selection.removeAllRanges();
              selection.addRange(_range3);
            } else if (_prev) {
              if (_prev.nodeName === "SPAN") {
                _prev = _next3 ? _next3.firstChild : _prev;
              }
              _range3.setStart(_prev, _prev.nodeValue ? _prev.nodeValue.length : 0);
              _range3.setEnd(_prev, _prev.nodeValue ? _prev.nodeValue.length : 0);
              selection.removeAllRanges();
              selection.addRange(_range3);
            }
            spanEle.parentElement.removeChild(spanEle);
            this.set("controllertmp.formulaValue", this.getFormulaHTML());
            return e.preventDefault();
          }
        }
      } else if (e.keyCode === 37) {
        // left arrow
        var _selection = window.getSelection(),
          _selectionNode = _selection.anchorNode;
        if (_formula.default.isFormulaSpanSection(_selection) && _selectionNode) {
          var _range4 = document.createRange();
          if (_selectionNode.parentElement.previousSibling) {
            var _prev2 = _selectionNode.parentElement.previousSibling;
            if (_prev2.nodeName === "SPAN") {
              _prev2 = _prev2.firstChild;
              _range4.setStart(_prev2, _prev2.length);
              _range4.setEnd(_prev2, _prev2.length);
            } else {
              _range4.setStart(_prev2, _prev2.length);
              _range4.setEnd(_prev2, _prev2.length);
            }
          } else {
            _range4.setStart(_selectionNode, 0);
            _range4.setEnd(_selectionNode, 0);
          }
          _selection.removeAllRanges();
          _selection.addRange(_range4);
          return e.preventDefault();
        } else if (_formula.default.isFormulaTextSection(_selection) && _selectionNode && !_selectionNode.previousSibling) {
          // move to start
          var _range5 = document.createRange();
          if (_selection.anchorOffset === 0 || _selection.anchorOffset === 1) {
            _range5.setStart(_selectionNode, 0);
            _range5.setEnd(_selectionNode, 0);
            _selection.removeAllRanges();
            _selection.addRange(_range5);
            return e.preventDefault();
          }
        }
        if (_selectionNode.nodeName === "P" || _selectionNode.nodeValue === "=" && _selectionNode.nextSibling && _selectionNode.nextSibling.nodeName === "P") {
          var _next4 = _selectionNode.nodeName === "P" ? _selectionNode : _selectionNode.nextSibling;
          if (_next4.firstChild) {
            _next4 = _next4.firstChild;
            if (_next4.nodeName === "SPAN") {
              _next4 = _next4.firstChild;
            }
          }
          var _range6 = document.createRange();
          _range6.setStart(_next4, 0);
          _range6.setEnd(_next4, 0);
          _selection.removeAllRanges();
          _selection.addRange(_range6);
          return e.preventDefault();
        }
      } else if (e.keyCode === 38) {
        // up arrow
        return e.preventDefault();
      } else if (e.keyCode === 39) {
        // right arrow
        var _selection2 = window.getSelection(),
          _selectionNode2 = _selection2.anchorNode;
        if (_formula.default.isFormulaSpanSection(_selection2) && _selectionNode2) {
          var _range7 = document.createRange();
          if (_selectionNode2.parentElement.nextSibling) {
            var _next5 = _selectionNode2.parentElement.nextSibling;
            if (_next5.nodeName === "SPAN") {
              _next5 = _next5.firstChild;
              _range7.setStart(_next5, _next5.nodeValue.length);
              _range7.setEnd(_next5, _next5.nodeValue.length);
            } else {
              _range7.setStart(_next5, _next5.nodeValue && _next5.nodeValue.length > 0 ? 1 : 0);
              _range7.setEnd(_next5, _next5.nodeValue && _next5.nodeValue.length > 0 ? 1 : 0);
            }
          } else {
            return e.preventDefault();
          }
          _selection2.removeAllRanges();
          _selection2.addRange(_range7);
          return e.preventDefault();
        } else if (_formula.default.isFormulaTextSection(_selection2) && _selectionNode2 && _selection2.anchorOffset === _selectionNode2.length) {
          // on last charecter
          var _next6 = _selectionNode2.nextSibling;
          if (_next6 && _next6.nodeName === "SPAN" && _next6.firstChild) {
            var _range8 = document.createRange();
            _range8.setStart(_next6.firstChild, _next6.firstChild.length);
            _range8.setEnd(_next6.firstChild, _next6.firstChild.length);
            _selection2.removeAllRanges();
            _selection2.addRange(_range8);
            return e.preventDefault();
          }
        }
      } else if (e.keyCode === 40) {
        // down arrow
        return e.preventDefault();
      }
    },
    keyPress: function keyPress(e) {
      if (e.keyCode === 13) {
        // enter
        return e.preventDefault();
      }
      this.get("controllertmp").send("keyPress", e);
    }
  });
});