define("budgeta/components/add-to-dashboard-modal", ["exports", "budgeta/components/modal-new", "budgeta/templates/components/modal-new"], function (_exports, _modalNew, _modalNew2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalNew.default.extend({
    layout: _modalNew2.default,
    classNames: ["add-model-dashboard-modal"]
  });
});