define("budgeta/initializers/moment-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    moment.locale(navigator && navigator.language || "en");
  }
  var _default = _exports.default = {
    name: "moment-locale",
    initialize: initialize
  };
});