define("budgeta/templates/components/clone-account-budgets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "EwTjq3FI",
    "block": "{\"statements\":[[\"block\",[\"unless\"],[[\"get\",[\"cloneAccount\",\"disableBudgetLines\"]]],null,3]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"multi-budget-lines-mapping\"],null,[[\"account\",\"thisAccount\",\"isClone\",\"enableMulti\",\"isSingle\",\"selectedBudgets\",\"budgetLines\",\"bsLines\",\"attributeChanged\",\"cssClass\",\"disableAccountId\",\"isWide\",\"resizeList\"],[[\"get\",[\"cloneAccount\"]],[\"get\",[\"account\"]],true,[\"get\",[\"cloneAccount\",\"enableMulti\"]],[\"get\",[\"cloneAccount\",\"isSingle\"]],[\"get\",[\"cloneBudgetLines\"]],[\"get\",[\"budgetLines\"]],[\"get\",[\"bsLines\"]],[\"get\",[\"attributeChanged\"]],[\"get\",[\"cssClass\"]],[\"get\",[\"disableAccountId\"]],[\"get\",[\"isWide\"]],[\"get\",[\"resizeList\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"      \"],[\"append\",[\"helper\",[\"multi-budget-lines-mapping\"],null,[[\"isDimensions\",\"account\",\"thisAccount\",\"isClone\",\"enableMulti\",\"selectedBudgets\",\"dimensionOptions\",\"attributeChanged\",\"cssClass\",\"disableAccountId\",\"isWide\",\"resizeList\"],[true,[\"get\",[\"cloneAccount\"]],[\"get\",[\"account\"]],true,true,[\"get\",[\"cloneDimensions\"]],[\"get\",[\"dimensionOptions\"]],[\"get\",[\"attributeChanged\"]],[\"get\",[\"cssClass\"]],[\"get\",[\"disableAccountId\"]],[\"get\",[\"isWide\"]],[\"get\",[\"resizeList\"]]]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"cloneAccount\",\"enableMulti\"]]],null,1]],\"locals\":[]},{\"statements\":[[\"block\",[\"if\"],[[\"get\",[\"isDimensions\"]]],null,2,0]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/clone-account-budgets.hbs"
    }
  });
});