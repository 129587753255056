define("budgeta/models/version", ["exports", "ember-data", "budgeta/services/ember-service-container"], function (_exports, _emberData, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = _emberData.default.Model.extend({
    tag: _emberData.default.attr("string"),
    created: _emberData.default.attr("date"),
    createdBy: _emberData.default.belongsTo("user", {
      async: true
    }),
    createdByDisplayName: Ember.computed("createdBy.displayName", "isAuto", function () {
      if (!this.get("isAuto") && !Ember.isEmpty(this.get("createdBy.displayName"))) {
        return this.get("createdBy.displayName");
      }
      return intl.t("general.text_1695611610559");
    }),
    auto: _emberData.default.attr("boolean"),
    disableVersion: _emberData.default.attr("boolean", {
      defaultValue: false
    }),
    disableDelete: _emberData.default.attr("boolean"),
    subType: _emberData.default.attr("string"),
    type: _emberData.default.attr("string"),
    note: _emberData.default.attr("string"),
    isAuto: Ember.computed.equal("type", "auto"),
    versionTypes: [{
      id: "snapshot",
      text: intl.t("general.text_1695611610589")
    }, {
      id: "forecast",
      text: intl.t("general.text_1695611610579")
    }, {
      id: "base",
      text: intl.t("general.text_1695611610569")
    }],
    linkMapping: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {}
    }),
    versionTypesWithAuto: Ember.computed("versionTypes", function () {
      return [{
        id: "auto",
        text: intl.t("general.text_1695611610559")
      }].pushObjects(this.get("versionTypes"));
    }),
    versionTypesSelection: Ember.computed("isAuto", "versionTypesWithAuto", "versionTypes", function () {
      if (this.get("isAuto")) {
        return this.get("versionTypesWithAuto");
      }
      return this.get("versionTypes");
    }),
    iconName: Ember.computed("type", function () {
      var result = "restore";
      switch (this.get("type")) {
        case "auto":
          result = "restore";
          break;
        case "snapshot":
          result = "photo_camera";
          break;
        case "forecast":
          result = "bookmark";
          break;
        case "base":
          result = "star";
          break;
      }
      return result;
    })
  });
});