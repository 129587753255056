define("budgeta/components/select-2-typeahead", ["exports", "ember-select-2/components/select-2"], function (_exports, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _select.default.extend({
    cssClass: "select-2-typeahead",
    // layoutName: "select-2",
    value: Ember.computed("targetValue", "targetProperty", "trimValue", {
      get: function get() {
        var value = this.get("targetValue") ? Ember.get(this.get("targetValue"), this.get("targetProperty")) : null;
        if (value && this.get("trimValue")) {
          value = value.trim();
        }
        return value ? {
          id: value,
          text: value
        } : undefined;
      },
      set: function set(key, value) {
        if (value && Ember.get(value, "text") && this.get("trimValue")) {
          Ember.set(value, "text", Ember.get(value, "text").trim());
        }
        Ember.set(this.get("targetValue"), this.get("targetProperty"), value && Ember.get(value, "text") || "");
        if (this.get("updateTarget")) {
          this.set("targetValue", value && Ember.get(value, "text") || "");
        }
        if (this.get("attributeChanged")) {
          this.sendAction("attributeChanged");
        }
        return value ? {
          id: Ember.get(value, "text"),
          text: Ember.get(value, "text")
        } : undefined;
      }
    })
  });
});