define("budgeta/components/import-account-mapping-display", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    values: Ember.computed("showValues.length", "budgetService.pnlFullName", function () {
      var _this = this;
      var foundClone, typeIndex;
      var pnlFullName = this.get("budgetService.pnlFullName");
      var values = this.get("showValues").map(function (item, index) {
        if (item.mappingType === "type") {
          typeIndex = index;
          return item.mapping === "bs" ? intl.t("general.name_1695611604150") : pnlFullName;
        }
        if (item.mappingType === "mappingType") {
          if (item.mapping === "single") {
            return intl.t("general.single_budget_line_1698234255394");
          }
          if (item.mapping === "multi") {
            return intl.t("general.multiple_budget_lines_1698234255394");
          }
          if (item.mapping === "none") {
            return intl.t("general.no_mapping_1698234255393");
          }
          if (item.mapping === "clone" && item.copyFromAccount) {
            foundClone = intl.t("general.same_as_account__1698234255393") + item.copyFromAccount;
            return foundClone;
          }
        }
        // If the user decieded to map the dimensions from the excel, we would like to extract them
        if (item.mappingType === "dimVal") {
          // If the Mapping that was chosen is from the dimension values list, then just read it
          if (item.mapping !== "new_val") {
            var dimensionValues = _this.get("dimensionValues"); // Get the selected dimension value chosen from list
            var dimensionValueObjectChosen = dimensionValues.findBy("id", item.mapping); //Getting the chosen dimension value
            return dimensionValueObjectChosen ? dimensionValueObjectChosen.text : ""; // The case where the selection is invalid
          } else {
            //  In the case where the dimension value needs to be created from the excel
            var text = (Ember.get(item, "value.w") || "").replace(/\s+/g, " ").trim(); // getting the text
            if (text && text.length) {
              return text; // Return the text in the cell
            } else {
              return _this.get("fixedColVal"); // return the dimension SI name as the new dimension value
            }
          }
        }
        return item.mapping || Array(index).join(" ");
      });
      if (foundClone && typeof typeIndex !== "undefined") {
        values[typeIndex] = foundClone + " ";
      }
      return values;
    })
  });
});