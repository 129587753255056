define("budgeta/models/transaction", ["exports", "ember-data", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/utils/assumption"], function (_exports, _emberData, _emberServiceContainer, _const, _assumption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-side-effects, ember/no-observers */
  var _default = _exports.default = _emberData.default.Model.extend({
    permissions: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    actualsMapping: Ember.inject.service(),
    init: function init() {
      if (this.get("statement") === "bs" || this.get("statement") === "cash") {
        this.set("fiscalDate", null);
      } else if (this.get("statement") === "pnl") {
        this.set("trxDate", null);
      }
    },
    save: function save() {
      if (!this.get("source") && !this.get("isDeleted")) {
        if (!this.get("isNew")) {
          this.setProperties({
            modified: new Date(),
            modifiedBy: this.get("permissions.currentUser")
          });
        }
        this.get("budgetService").updateMonthsIncludedInForecast([this]);
      }
      return this._super.apply(this, arguments);
    },
    budget: Ember.computed("budgetService.selectedRoot", function () {
      if (this.get("budgetService.selectedRoot") && !this.get("budgetRoot")) {
        this.set("budgetRoot", this.get("budgetService.selectedRoot.id"));
      }
      if (this.id && this.id.indexOf("|") > 0) {
        return this.get("store").peekRecord("budget", this.get("budgetService.selectedRoot.id") + "|" + this.id.split("|")[1]);
      }
      return this.get("budgetService.selectedRoot");
    }),
    budgetRoot: _emberData.default.attr("string"),
    budgetLine: _emberData.default.attr("string"),
    savedMapping: _emberData.default.attr("boolean"),
    bsLine: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    computedBsLine: Ember.computed("accountId", "statement", "savedMapping", "bsLine", {
      get: function get() {
        if (this.get("savedMapping") && this.get("bsLine")) {
          return this.get("bsLine");
        }
        var thisAccount = this.get("actualsMapping").getAccount(this.get("accountId"), {
          type: "bs",
          getFinal: true
        });
        if (thisAccount && thisAccount.get("type") === "bs" && this.get("statement") !== "bs" && !(this.get("bsLine") === "cash" && this.get("statement") === "cash")) {
          this.set("statement", "bs");
        }
        return this.get("statement") === "bs" ? thisAccount && thisAccount.get("budgetLines.firstObject") : null;
      },
      set: function set(key, value) {
        this.setProperties({
          bsLine: value,
          savedMapping: true,
          modified: new Date(),
          modifiedBy: this.get("permissions.currentUser")
        });
        this.save();
        return value;
      }
    }),
    computedBudgetLine: Ember.computed("budgetLineId", "budgetLine", function () {
      if (this.get("budgetLineId")) {
        var budgetLineObject = this.store.peekRecord("budget", this.get("budgetLineId"));
        if (!budgetLineObject) {
          // If the mapped line was deleted then it must recalc budgetLineId.
          this.notifyPropertyChange("budgetLine");
        }
        return this.store.peekRecord("budget", this.get("budgetLineId"));
      } else {
        return null;
      }
    }),
    budgetLineId: Ember.computed("accountId", "budgetLine", "computedBudgetLine.forecastAttributes.dimensions.@each.valuesLength", "savedMapping", {
      get: function get() {
        if (this.get("savedMapping") && this.get("budgetLine")) {
          if (this.store.peekRecord("budget", this.get("budgetLine"))) {
            return this.get("budgetLine");
          } else {
            // try to find the closes match
            /* IMPORTANT TODO:
            // findLineMatchInVersionById gets "budgetLine.id" while "budgetLine" is a String.
            // This is an error from BUD-10716 BUD-10596 and causing a mapping issue.
            // Fixing this will cause a change of mapping in active users budgets and
            // therefore not fixed for now.
            */
            return Ember.get(this.get("actualsMapping").findLineMatchInVersionById(this.get("budgetLine.id")) || this.get("budgetService.selectedRoot"), "id");
          }
        }
        var thisAccount = this.get("actualsMapping").getAccount(this.get("accountId"), {
          type: "pnl",
          getFinal: true
        });
        if (thisAccount && thisAccount.get("type") === "pnl" && this.get("statement") === "bs") {
          this.set("statement", "pnl");
        }
        var thisMapping = this.get("actualsMapping").getTransactionMapping(this);
        return thisMapping;
      },
      set: function set(key, value) {
        this.setProperties({
          budgetLine: value,
          savedMapping: true,
          modified: new Date(),
          modifiedBy: this.get("permissions.currentUser")
        });
        this.save();
        return value;
      }
    }),
    created: _emberData.default.attr("date"),
    createdFinal: Ember.computed("created", function () {
      return this.get("created") && moment(this.get("created")).format(_const.default.MomentDateAndTimeDisplayFormat);
    }),
    createdBy: _emberData.default.belongsTo("user", {
      async: true
    }),
    modified: _emberData.default.attr("date"),
    modifiedFinal: Ember.computed("modified", function () {
      return this.get("modified") && moment(this.get("modified")).format(_const.default.MomentDateAndTimeDisplayFormat);
    }),
    modifiedBy: _emberData.default.belongsTo("user", {
      async: true
    }),
    source: _emberData.default.attr("string"),
    sourceToDisplay: Ember.computed("source", function () {
      return this.get("source") === "intacct" ? intl.t("general.text_1695611608710") : this.get("source") ? intl.t("general.excel_1698234256864") : intl.t("general.manual_1698234256864");
    }),
    isIntacct: Ember.computed("source", function () {
      return this.get("source") === "intacct" ? true : undefined;
    }),
    books: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    importFilters: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    type: _emberData.default.attr("string", {
      defaultValue: "trx"
    }),
    // enum: ['balance','trx']
    computedType: Ember.computed("type", function () {
      return this.get("type") === "balance" ? intl.t("general.text_1695611610237") : intl.t("general.text_1695611610226");
    }),
    statement: _emberData.default.attr("string", {
      defaultValue: "both"
    }),
    // enum: ['cash', 'pnl', 'both', 'bs']
    trxDate: _emberData.default.attr("utc"),
    formattedDate: Ember.computed("trxDate", function () {
      return this.get("trxDate") ? moment(this.get("trxDate").endOfMonth().reset()).format(_const.default.MomentFullDateDisplayFormat) : "";
    }),
    fiscalDate: _emberData.default.attr("utc"),
    formattedFiscalDate: Ember.computed("fiscalDate", function () {
      return this.get("fiscalDate") ? this.get("fiscalDate").format(_const.default.SugarMonthYearDisplayFormat) : "";
    }),
    sortByKeyCash: Ember.computed("trxDate", function () {
      return (this.get("trxDate") ? _const.default.formatDate(this.get("trxDate")) : "") + this.get("type");
    }),
    sortByKeyPnl: Ember.computed("fiscalDate", function () {
      return (this.get("fiscalDate") ? _const.default.formatDate(this.get("fiscalDate")) : "") + this.get("type");
    }),
    computedMonth: Ember.computed("trxDate", "fiscalDate", function () {
      return this.get("fiscalDate") || this.get("trxDate") ? _const.default.formatDate(this.get("fiscalDate") || this.get("trxDate")) : null;
    }),
    isPastTrx: Ember.computed("budgetService.budgetStartDateUTC", "trxDate", "fiscalDate", "statement", function () {
      var budgetStartDate = this.get("budgetService.budgetStartDateUTC") && this.get("budgetService.budgetStartDateUTC").reset();
      return this.get("trxDate") && _const.default.dateToUTC(this.get("trxDate")).isBefore(budgetStartDate) || this.get("fiscalDate") && _const.default.dateToUTC(this.get("fiscalDate")).isBefore(budgetStartDate);
    }),
    fiscalDateString: Ember.computed("fiscalDate", function () {
      return this.get("fiscalDate") ? _const.default.formatDate(this.get("fiscalDate")) : "";
    }),
    trxDateString: Ember.computed("trxDate", function () {
      return this.get("trxDate") ? _const.default.formatDate(this.get("trxDate")) : "";
    }),
    isCurrentTrx: Ember.computed("budgetService.budgetStartDateUTC", "trxDate", "fiscalDate", "statement", function () {
      var statement = this.get("statement");
      if (!this.get("fiscalDate") && (statement === "both" || statement === "pnl" || statement === "stat")) {
        return true;
      }
      if (!this.get("trxDate") && (statement === "both" || statement === "cash" || statement === "bs")) {
        return true;
      }
      var budgetStartDate = this.get("budgetService.budgetStartDateUTC");
      return this.get("trxDate") && budgetStartDate && !budgetStartDate.isAfter(_const.default.dateToUTC(this.get("trxDate"))) || this.get("fiscalDate") && budgetStartDate && !budgetStartDate.isAfter(_const.default.dateToUTC(this.get("fiscalDate")));
    }),
    amount: _emberData.default.attr("number"),
    currency: _emberData.default.attr("string"),
    unitType: Ember.computed("computedBudgetLine", "computedBudgetLine.budgetAttributes.{modelLine,currency,pctCurrency,amountType}", "computedBudgetLine.loopError", function () {
      return _assumption.default.getUnitTypeForMapping(this.get("computedBudgetLine"), this.get("store"), this.get("cache"), this.get("budgetService.scenario"));
    }),
    description: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    trxId: _emberData.default.attr("string"),
    accountId: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    dimensions: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {}
    }),
    specialCategory: Ember.computed("budgetService.dimensions.length", "computedBudgetLine", "accountId", function () {
      var result;
      if (this.get("budgetService.dimensions") && (this.get("computedBudgetLine.budgetType") === "group-payroll" || this.get("computedBudgetLine.budgetType") === "sales-perpetual-license")) {
        var categoryDim = this.get("budgetService.dimensions").findBy("isCategory");
        if (categoryDim) {
          result = this.get("actualsMapping").getSpecialAccountCategoryValue(this.get("computedBudgetLine.budgetType"), this.get("accountId"));
        }
      }
      return result;
    }),
    computedDimensions: Ember.computed("budgetService.dimensions.length", "computedBudgetLine", function () {
      var _this = this;
      return (this.get("budgetService.dimensions") || []).map(function (dim) {
        if (dim.get("isCategory") && (_this.get("computedBudgetLine.budgetType") === "group-payroll" || _this.get("computedBudgetLine.budgetType") === "sales-perpetual-license")) {
          return _this.get("actualsMapping").getSpecialAccountCategory(_this.get("computedBudgetLine.budgetType"), _this.get("accountId")) || _this.get("dimensions.".concat(dim.get("uniqueId")));
        }
        var dimValue = (_this.get("computedBudgetLine.forecastAttributes.dimensions") || Ember.A([])).findBy("dimension", dim.get("uniqueId"));
        if (dimValue) {
          return _const.default.getDimensionDisplayValues(dimValue, dim, true);
        }
        return null;
      });
    }),
    note: _emberData.default.attr("string", {
      defaultValue: ""
    }),
    flagged: _emberData.default.attr("boolean"),
    isBudgetTransaction: _emberData.default.attr("boolean"),
    notReadOnly: Ember.computed.not("readOnly"),
    sharedReadOnlyOrVersion: Ember.computed.alias("budgetService.sharedReadOnlyOrVersion"),
    isBalanceSheet: Ember.computed.equal("statement", "bs"),
    readOnly: Ember.computed("source", "disableAll", "budgetService.selectedRoot.isPartialShare", function () {
      return this.get("disableAll") || this.get("source") || this.get("budgetService.selectedRoot.isPartialShare");
    }),
    enableBSLine: Ember.computed("isBalanceSheet", "disableAll", function () {
      return !this.get("disableAll") && this.get("isBalanceSheet");
    }),
    disableBudgetLine: Ember.computed("isBalanceSheet", "disableAll", "budgetService.selectedRoot.isPartialShare", function () {
      return this.get("disableAll") || this.get("isBalanceSheet") || this.get("budgetService.selectedRoot.isPartialShare");
    }),
    disableAll: Ember.computed("trxDate", "fiscalDate", "budget.closedActuals.length", "budget.readOnly", function () {
      return this.get("budget.readOnly") || this.get("budget.closedActuals") && (this.get("fiscalDate") && this.get("budget.closedActuals").indexOf(_const.default.formatDate(this.get("fiscalDate"))) >= 0 || this.get("trxDate") && this.get("budget.closedActuals").indexOf(_const.default.formatDate(this.get("trxDate"))) >= 0);
    }),
    fiscalDateDisabled: Ember.computed("readOnly", "statement", function () {
      var result = this.get("readOnly") || this.get("statement") === "cash" || this.get("statement") === "bs";
      if (this.get("statement") === "cash" || this.get("statement") === "bs") {
        this.set("fiscalDate", null);
      }
      return result;
    }),
    trxDateDisabled: Ember.computed("readOnly", "statement", function () {
      return this.get("readOnly") || this.get("statement") === "pnl";
    }),
    fiscalDateEnabled: Ember.computed.not("fiscalDateDisabled"),
    trxDateEnabled: Ember.computed.not("trxDateDisabled"),
    dateObserver: Ember.observer("fiscalDate", function () {
      if (!this.get("readOnly") && this.get("statement") === "both" && Ember.isEmpty(this.get("trxDate")) && !Ember.isEmpty(this.get("fiscalDate"))) {
        this.set("trxDate", this.get("fiscalDate"));
      }
    }),
    bsObserver: Ember.observer("isBalanceSheet", "budgetLine", function () {
      if (this.get("isBalanceSheet") && this.get("budgetLine") && this.get("budget.id") !== this.get("budgetLine")) {
        this.set("budgetLine", this.get("budget.id"));
      }
      if (!this.get("isBalanceSheet")) {
        this.set("bsLine", null);
      } else if (!this.get("bsLine")) {
        this.set("bsLine", "assets");
      }
    })
  });
});