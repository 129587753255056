define("budgeta/common-react-components/login/utils", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validators = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var validators = _exports.validators = {
    VALUE_NOT_EMPTY: {
      errorMessage: intl.t("login-form.required-field"),
      isValid: function isValid(value) {
        return value;
      }
    },
    VALID_EMAIL: {
      errorMessage: intl.t("login-form.invalid-email"),
      isValid: function isValid(email) {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
      }
    }
  };
});