define("budgeta/templates/components/path-list-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "C8bp1DDa",
    "block": "{\"statements\":[[\"open-element\",\"bdi\",[]],[\"flush-element\"],[\"text\",\"\\n  \"],[\"append\",[\"unknown\",[\"pathPath\"]],false],[\"open-element\",\"span\",[]],[\"static-attr\",\"class\",\"path-item-name\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"pathName\"]],false],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"showAccountId\"]]],null,0],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"display-account-ids\"],null,[[\"budget\",\"disableTitle\"],[[\"get\",[\"budget\"]],true]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/path-list-element.hbs"
    }
  });
});