define("budgeta/store/reducers/fileReducer", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "budgeta/store/actions/fileActions"], function (_exports, _objectSpread2, _fileActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-undef */

  var initialState = {
    name: null,
    file: null,
    formattedFile: null
  };
  function fileReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    switch (action.type) {
      case _fileActions.SET_FILE_NAME:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            name: action.payload.name
          });
        }
      case _fileActions.SET_FILE:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            file: action.payload.file
          });
        }
      case _fileActions.SET_FORMATTED_FILE:
        {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, state), {}, {
            formattedFile: action.payload.formattedFile
          });
        }
      case _fileActions.CLEAR_FILE_DEPENDENCIES:
        {
          var stepDependencies = action.payload.stepDependencies;
          var updatedProperties = (0, _objectSpread2.default)({}, state);
          if (stepDependencies && stepDependencies.length) {
            stepDependencies.forEach(function (dep) {
              if (_.has(initialState, dep)) {
                _.set(updatedProperties, dep, _.get(initialState, dep));
              }
            });
          }
          return (0, _objectSpread2.default)({}, updatedProperties);
        }
      default:
        return state;
    }
  }
  var _default = _exports.default = fileReducer;
});