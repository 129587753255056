define("budgeta/services/tracking-events", ["exports", "budgeta/config/environment", "budgeta/utils/const", "budgeta/utils/automation"], function (_exports, _environment, _const, _automation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var postAddLineFunction = function postAddLineFunction(eventName, data) {
    this.set("totalRowsInSession", this.get("totalRowsInSession") + (data.wizard_new_children_length || 1));
    this.isPendoReady() && this.get("pendo").track("Create Row", {
      total_rows_in_session: this.get("totalRowsInSession"),
      created_date: data.timestamp
    });
  };
  var getWizardScreenName = function getWizardScreenName(budgetType, name) {
    if (budgetType === "group-company-with-sub") {
      return "Build Your Multi-Budget Wizard";
    }
    if (budgetType === "group-company") {
      return "Build Your Budget Wizard";
    }
    if (budgetType === "group-past-revenues") {
      return "Past balances and transactions Wizard";
    }
    if (budgetType === "group-department") {
      return "Department Wizard";
    }
    if (budgetType === "group-department-multi") {
      return "Multi Department Wizard";
    }
    if (budgetType === "group-expense-op-bydepartment") {
      return "Operational Expenses By Department Wizard";
    }
    if (budgetType === "group-expense-op-bygeo") {
      return "Operational Expenses By Geography Wizard";
    }
    if (budgetType === "group-expense-op-bygeo-bydep") {
      return "Operational Expenses By Geography By Department Wizard";
    }
    if (budgetType === "group-department-geo") {
      return "Operational Expenses By Geography Department Wizard";
    }
    if (!budgetType && !name) {
      return null;
    }
    return name + " Wizard";
  };
  var EV_PREFIX_BUDGET_TREE_OPTIONS = "Budget Tree Options";
  var EV_PREFIX_GRID = "Simple Grid Entry";
  var EV_PREFIX_BUDGET_MENU = "Budget Menu";
  var EV_PREFIX_FORM = "Input View Type Form";
  var _default = _exports.default = Ember.Service.extend({
    budgetService: Ember.inject.service(),
    treeManager: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: undefined,
    totalRows: 0,
    events: {
      MODEL_NOT_FOUND: {
        name: "model-not-found"
      },
      ERROR: {
        name: "Error"
      },
      OPEN_BUDGET: {
        name: "Open budget"
      },
      LOGOUT: {
        name: "Click on Logout"
      },
      ADD_LINE: {
        name: "Add",
        postTrack: postAddLineFunction
      },
      SAVE_LINE: {
        name: "Save line"
      },
      DUPLICATE_LINE: {
        name: "Click on Duplicate line",
        postTrack: postAddLineFunction
      },
      COPY_LINE: {
        name: "Click on Copy line",
        postTrack: postAddLineFunction
      },
      DUPLICATE_BUDGET: {
        name: "Click on Duplicate"
      },
      DELETE_LINE: {
        name: "Click on Delete line"
      },
      DELETE_LINES: {
        name: "Click on Delete lines"
      },
      CLICK_ON_HISTORY: {
        name: "Click on History"
      },
      CLICK_ON_ADD_NEW_BUDGET_LINE: {
        name: "Click on Add New Budget Line"
      },
      CLICK_ON_CONFIRM_ADD_NEW_BUDGET_LINE: {
        name: "Confirm to Add New Budget Line"
      },
      SEARCH_TERM_IN_BUDGET_TREE: {
        name: "Search Term in Budget Tree"
      },
      MOVE_LINE: {
        name: "Click on Move line"
      },
      SORT_TABLE: {
        name: "Click on Sort table"
      },
      SELECT_VERSION: {
        name: "Click on Version"
      },
      DELETE_VERSION: {
        name: "Click on Delete Version"
      },
      REVERT_VERSION: {
        name: "Click on Revert Version"
      },
      CREATE_SCENARIO: {
        name: "Click on Create Scenario"
      },
      SWITCH_SCENARIO: {
        name: "Click on Scenario"
      },
      MERGE_SCENARIO: {
        name: "Scenarios | Merge full scenario"
      },
      MERGE_PARTIAL_SCENARIO: {
        name: "Scenarios | Partial merge scenario - budget line"
      },
      MERGE_PARTIAL_GROUP_SCENARIO: {
        name: "Scenarios | Partial merge scenario - group"
      },
      DELETE_SCENARIO: {
        name: "Click on Delete Scenario"
      },
      RENAME_SCENARIO: {
        name: "Click on Rename Scenario"
      },
      OPEN_SETTINGS: {
        name: "Click on Settings"
      },
      SAVE_SETTINGS: {
        name: "Click on Save Settings"
      },
      DISCARD_SETTINGS: {
        name: "Click on Discard Settings"
      },
      CHANGE_TAB_SETTINGS: {
        name: "Click on Tab Settings"
      },
      LOGIN: {
        name: "Click on Login"
      },
      CHANGE_PASSWORD: {
        name: "Password change request"
      },
      GOTO_LINE: {
        name: "Click on Budget Line"
      },
      USER_EDIT_DIMENSION: {
        name: "User edit a dimension value"
      },
      USER_CREATE_ACCOUNT: {
        name: "User create new account"
      },
      USER_MAP_ACCOUNT_TO_LINE: {
        name: "Account Mapping | User map account to a line"
      },
      CREATE_ANOTHER_MODEL_LINE_CHECKED: {
        name: "Inputs | User checked create another model line"
      },
      MODEL_LINE_FLYOUT_CHANGE_MODAL_WIDTH: function MODEL_LINE_FLYOUT_CHANGE_MODAL_WIDTH(isWide) {
        return {
          name: "Inputs | model line flyout | user changed width to: ".concat(isWide ? "wide" : "narrow")
        };
      },
      USER_FILTER_GRID_BY_DIMENSION: {
        name: "Sheets | User filter grid view by dimension"
      },
      USER_FILTER_GRID_BY_ACCOUNT: {
        name: "Sheets | User filter grid view by account"
      },
      VIEW_BUILD_WIZARD: {
        name: "View Wizard Screen",
        beforeTrack: function beforeTrack(eventName, data) {
          var budgetType = getWizardScreenName(data.budgetType, data.name),
            returnData = {};
          if (budgetType) {
            returnData["Screen Name"] = budgetType;
          }
          return {
            eventName: eventName,
            data: returnData
          };
        }
      },
      BUILD_WIZARD_NEXT: {
        name: "Click Wizard Next",
        beforeTrack: function beforeTrack(eventName, data) {
          var budgetType = getWizardScreenName(data.budgetType, data.name),
            returnData = {};
          if (budgetType) {
            returnData["Screen Name"] = budgetType;
          }
          return {
            eventName: eventName,
            data: returnData
          };
        }
      },
      WIZARD_NEW: {
        name: "wizard_new_children",
        postTrack: postAddLineFunction
      },
      CHANGE_TAB: {
        name: "change_tab",
        beforeTrack: function beforeTrack(eventName, data) {
          var params = data.change_to.split("_"),
            newName = "Click ";
          if (this.get("budgetService.selectedRoot.id")) {
            data.root_budget_line_id = this.get("budgetService.selectedRoot.id");
            data.number_of_budget_lines = this.get("treeManager.budgetsList.length");
          }
          if (params.indexOf("sheets") !== -1) {
            if (params.length === 1) {
              newName += "Header Budget Sheets";
            } else {
              newName += "Sheets Tab ";
              if (params.indexOf("cf") !== -1) {
                newName += "Cash";
              } else if (params.indexOf("pnl") !== -1) {
                newName += "P&L";
              } else if (params.indexOf("bs") !== -1) {
                newName += "Balance";
              } else if (params.indexOf("hc") !== -1) {
                newName += "Headcount";
              } else if (params.indexOf("booking") !== -1) {
                newName += "Booking";
              } else if (params.indexOf("models") !== -1) {
                newName += "Model";
              } else {
                newName += "Cash";
              }
            }
          } else if (params.indexOf("dashboard") !== -1) {
            if (params.length === 1) {
              newName += "Header Budget Dashboard";
            } else {
              // check multi dashboard
            }
          } else if (params.indexOf("inputs") !== -1) {
            if (params.length === 1) {
              newName += "Header Budget Inputs";
            } else {
              newName += "Inputs Tab ";
              if (params.indexOf("main") !== -1) {
                newName += "Main";
              } else if (params.indexOf("actuals") !== -1) {
                newName += "Actual";
                if (params.indexOf("trx") !== -1) {
                  newName += " Transactions";
                } else if (params.indexOf("summary") !== -1) {
                  newName += " Summary";
                } else if (params.indexOf("forecast") !== -1) {
                  newName += " Forecast";
                } else if (params.indexOf("accounts") !== -1) {
                  newName += " Accounts";
                } else if (params.indexOf("bs") !== -1) {
                  newName += " Balance Sheet Accounts";
                }
              } else if (params.indexOf("models") !== -1) {
                newName += "Model";
              } else if (params.indexOf("table") !== -1) {
                newName += "Table";
              } else {
                newName += "Main";
              }
            }
          }
          return {
            eventName: newName,
            data: data
          };
        }
      },
      REPORT: {
        name: "report"
      },
      PLAY_VIDEO: {
        name: "Click on Play Video"
      },
      PAUSE_VIDEO: {
        name: "Click on Pause Video"
      },
      END_VIDEO: {
        name: "Click on End Video"
      },
      USED_TEMPLATE: {
        name: "Used template",
        beforeTrack: function beforeTrack(eventName, data) {
          var currentUser = this.get("currentUser");
          if (currentUser) {
            Ember.setProperties(currentUser, data);
            if (currentUser.is_beta_user) {
              currentUser["hide_default_launcher"] = true;
            }
          }
        }
      },
      ACTIVATE_ACCOUNT: {
        name: "Verified Email",
        beforeTrack: function beforeTrack(eventName) {
          return {
            eventName: eventName,
            data: {}
          };
        }
      },
      CHANGE_CHARTS_ORDER: {
        name: "Change Order widget"
      },
      CHANGE_DASHBOARD_MODE: {
        name: "Change Mode Dashboard"
      },
      DELETE_DASHBOARD: {
        name: "Click Dashboard Tab Delete Dashboard"
      },
      ADD_DASHBOARD: {
        name: "Click Dashboard Tab Add Dashboard"
      },
      CHANGE_DASHBOARD: {
        name: "Change Dashboard"
      },
      ADD_CHART: {
        name: "Click Dashboard Tab Add Widget"
      },
      ADD_NEW_CHART: {
        name: "Click Dashboard Tab Add New Widget"
      },
      RENAME_CHART: {
        name: "Click Dashboard Tab Rename Widget"
      },
      DUPLICATE_CHART: {
        name: "Click on Duplicate widget"
      },
      RESTORE_CHARTS: {
        name: "Click on restore widget"
      },
      ADVANCED_CHART: {
        name: "Click on advanced widget"
      },
      DELETE_CHART: {
        name: "Click on delete widget"
      },
      SHARE_BUDGET: {
        name: "share_budget" // liad
      },
      CALCULATION_TIME: {
        name: "Calculation time"
      },
      REGITSTRATION: {
        name: "Registration",
        beforeTrack: function beforeTrack(eventName, data) {
          return {
            eventName: eventName,
            data: data
          };
        }
      },
      VIEW_REGITSTRATION: {
        name: "View Registeration Form"
      },
      VIEW_REGITSTRATION_ABOUT_USER: {
        name: "View About User Form No Email",
        beforeTrack: function beforeTrack(eventName) {
          return {
            eventName: eventName,
            data: {
              "Screen Name": "About User"
            }
          };
        }
      },
      SKIP_REGITSTRATION_ABOUT_USER: {
        name: "Click Skip About User Form No Email",
        beforeTrack: function beforeTrack(eventName) {
          return {
            eventName: eventName,
            data: {
              "Screen Name": "About User"
            }
          };
        }
      },
      DONE_REGITSTRATION_ABOUT_USER: {
        name: "Click Done About User Form No Email",
        beforeTrack: function beforeTrack(eventName) {
          return {
            eventName: eventName,
            data: {
              "Screen Name": "About User"
            }
          };
        }
      },
      VIEW_REGITSTRATION_ABOUT_USER_VERIFIED: {
        name: "View About User Form Verified",
        beforeTrack: function beforeTrack(eventName) {
          return {
            eventName: eventName,
            data: {
              "Screen Name": "Verification User"
            }
          };
        }
      },
      COMPLETED_USER_VERIFIED: {
        name: "Completed About User Info Form Verified",
        beforeTrack: function beforeTrack(eventName) {
          return {
            eventName: eventName,
            data: {
              "Screen Name": "Verification User"
            }
          };
        }
      },
      CLICK_HAMBURGER: {
        name: "Click Header Menu Left"
      },
      CLICK_VIEDO_HEADER: {
        name: "Click Header Videos"
      },
      CLICK_QUESTION_HEADER: {
        name: "Click Header Question Icon"
      },
      CLICK_SHARE_HEADER: {
        name: "Click Header Share Budget"
      },
      CLICK_RIGHT_MENU_HEADER: {
        name: "Click Header Menu Right"
      },
      CLICK_USER_HEADER: {
        name: "Click Header User Name"
      },
      CLICK_CHANGE_DISPLAY: {
        name: "Click Header Display Resolution"
      },
      CLICK_NEW_BUDGET: {
        name: "Click Hamburger Menu New Budget"
      },
      CLICK_NEW_FROM_BACKUP: {
        name: "Click Hamburger Menu New Budget Backup"
      },
      CLICK_EXAMPLE_BUDGETS: {
        name: "Click Hamburger Menu Example Budget"
      },
      CLICK_EXAMPLE_BUDGET: {
        name: "Click Example Budget Type"
      },
      CLICK_RENAME_HEADER: {
        name: "Click Right 3 dots Menu Rename Budget"
      },
      CLICK_DELETE_HEADER: {
        name: "Click Right 3 dots Menu Delete Budget"
      },
      CLICK_ACCOUNT_SETTINGS: {
        name: "Click User Name Menu Account Setting"
      },
      CLICK_CHANGE_PASSWORD: {
        name: "Click User Name Menu Change Password"
      },
      CLICK_DOCUMENTATION_HEADER: {
        name: "Click User Name Menu Documentation"
      },
      CUSTOM_KPI_REORDER: {
        name: "Cusom KPI Reorder"
      },
      CUSTOM_KPI_SELECT: {
        name: "Cusom KPI Select"
      },
      CLICK_TUTORIAL_HEADER: {
        name: "User Name Menu Tutorial",
        beforeTrack: function beforeTrack(eventName) {
          return {
            eventName: eventName,
            data: {}
          };
        }
      },
      CLICK_VIDEO: {
        name: "Click Video Screen",
        beforeTrack: function beforeTrack(eventName, data) {
          var newName = data && data.fromTutorial ? "Tutorial Video View" : eventName;
          return {
            eventName: newName,
            data: data
          };
        }
      },
      CREATE_REPORT: {
        name: "report",
        beforeTrack: function beforeTrack(eventName, data) {
          var newName = "Click ";
          if (data.is_sheet_view) {
            newName += "Sheets Tab";
          } else if (data.is_dashboard_view) {
            newName += "Dashboard Tab";
          }
          newName += " Create Report ".concat(data.report_type);
          return {
            eventName: newName,
            data: {}
          };
        }
      },
      VIEW_TUTORIAL: {
        name: "View Tutorial Screen",
        beforeTrack: function beforeTrack(eventName, data) {
          var screenName = data.screenName,
            returnData = {};
          if (screenName === "tour-page-1") {
            returnData["Screen name"] = "Welcome to Sage Intacct Planning Wizard first screen";
          } else if (screenName === "tour-page-2") {
            returnData["Screen name"] = "Here are a few things you can do with";
          } else if (screenName === "tour-page-3") {
            returnData["Screen name"] = "Were excited to help get started";
            returnData[completed_tutorial] = true;
          }
          return {
            eventName: eventName,
            data: returnData
          };
        }
      },
      TUTORIAL_LOAD_EXAMPLE: {
        name: "Click Tutorial Check out example budget",
        beforeTrack: function beforeTrack(eventName) {
          return {
            eventName: eventName,
            data: {
              "Screen name": "Were excited to help get started"
            }
          };
        }
      },
      JUMP_TUTORIAL: {
        name: "Click Tutorial Jump right in",
        beforeTrack: function beforeTrack(eventName, data) {
          var newName = data.page !== "tour-page-1" && data.page !== "tour-page-2" ? "Click Tutorial Start building my budget" : eventName;
          var screenName = "";
          if (data.page === "tour-page-1") {
            screenName = "Welcome to Sage Intacct Planning Wizard first screen";
          } else if (data.page === "tour-page-2") {
            screenName = "Here are a few things you can do with";
          } else if (data.page === "tour-page-3") {
            screenName = "Were excited to help get started";
          }
          return {
            eventName: newName,
            data: {
              "Screen name": screenName
            }
          };
        }
      },
      START_WIZARD: {
        name: "View Wizard Start",
        beforeTrack: function beforeTrack(eventName, data) {
          var returnData = {};
          if (data.fromTutorial) {
            this.isPendoReady() && this.get("pendo").track("First Wizard Start ", {});
          }
          return {
            eventName: eventName,
            data: returnData
          };
        }
      },
      WIZARD_NEXT: {
        name: "Click Wizard Next",
        postTrack: function postTrack(eventName, data) {
          if (data.step === 1) {
            this.isPendoReady() && this.get("pendo").track("View Wizard Screen", {
              wizard_screen: "Create a New Budget Wizard Currency Screen"
            });
          }
        }
      },
      WIZARD_NEW_BUDGET_FROM_FILE: {
        name: "New Budget From File"
      },
      INPUT_MODEL_SAVE_BDGM: {
        name: "Inputs | Model - Save Models To File"
      },
      INPUT_MODEL_UPLOAD_BDGM: {
        name: "Inputs | Model - Upload Models From File"
      },
      WIZARD_BACK_TO_VIDEO: {
        name: "Click Wizard Back to Video"
      },
      WIZARD_BACK: {
        name: "Click Wizard Back"
      },
      ADD_MODEL_FROM_STORE: {
        name: "Add model from store"
      },
      LOCK_BUDGET: {
        name: "Click Right 3 dots Lock Budget"
      },
      VIEW_SETUP_GUIDE: {
        name: "View Setup Guide"
      },
      VIEW_BUDGET_MAP: {
        name: "View Budget Map"
      },
      OPEN_ADV_BENEFIT: {
        name: "Open advanced benefit"
      },
      USE_AND_FOR_MODEL_LINE_TAGS: {
        name: "Inputs | Model - Check Use_AND checkbox on model line edit"
      },
      USE_SUM_IN_FORMULA: {
        name: "Inputs | Model - Add SUM() component to the formula calculation"
      },
      USE_SUMTOTAL_IN_FORMULA: {
        name: "Inputs | Model - Add SUMTOTAL() component to the formula calculation"
      },
      CUSTOM_DIMENSION_CHOOSED: {
        name: "Inputs | Main - Select customed dimension"
      },
      COLLAPSE_TOTAL_BENEFITS: {
        name: "Inputs | Main | Details forecast - collapse Total Benefits row"
      },
      IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_BULK_ACTION: {
        name: "Import Excel | Budgetline - Bulk Action"
      },
      IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_SHOW_DIFFERENCES: {
        name: "Import Excel | Budgetline - Show Differences"
      },
      IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_ACTION_CHANGE: {
        name: "Import Excel | Budgetline - Action Type Change"
      },
      IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_BUDGET_TYPE_CHANGE_ALERT: {
        name: "Import Excel | Budgetline - Budget Type Change Alert"
      },
      IMPORT_EXCEL_OVERRIDED_FROM_ALL_LINES: {
        name: "Import Excel | Override Budget Lines From Excel | {0} From {1} Lines"
      },
      INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE: {
        name: "Index | Import Excel - Import Budget lines"
      },
      IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_COMPLETED: {
        name: "Import Excel | Budgetline - Completed Import"
      },
      INDEX_EXPORT_TEMPLATE_ROOT: {
        name: "Index | Root - Export Template"
      },
      INDEX_EXPORT_TEMPLATE_SUB_GROUP: {
        name: "Index | Subsidiary-Group - Export Template"
      },
      SHEETS_CREATE_REPORT_EXPORT_TEMPLATE: {
        name: "Sheets | Create Report - Export Template"
      },
      INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE_ROOT: {
        name: "Index | Import Excel - Import Budget lines From Root"
      },
      INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE_SUBSIDIARY: {
        name: "Index | Import Excel - Import Budget lines From Subsidiary"
      },
      INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE_GROUPING: {
        name: "Index | Import Excel - Import Budget lines From Grouping"
      },
      INDEX_IMPORTֹֹֹֹֹ_EXCEL_BUDGETLINE_GROUP: {
        name: "Index | Import Excel - Import Budget lines From Group"
      },
      IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_GROUPING_CHANGE: {
        name: "Import Excel | Budgetline - Change Grouping"
      },
      IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_LINE_TYPE_CHANGE: {
        name: "Import Excel | Budgetline - Change Line Type"
      },
      IMPORTֹֹֹֹֹ_EXCELֹֹ_BUDGETLINE_NUMBER_NOGROUPING: {
        name: "Import Excel | Budgetline - Number of No Groupings"
      },
      INDEXֹ_MOVE_BUDGETLINE_NOGROUPING: {
        name: "Index | No Grouping Line - Move to valid place"
      },
      INDEXֹ_DELETE_BUDGETLINE_NOGROUPING: {
        name: "Index | No Grouping Line - Delete line"
      },
      EXPORT_INVALID_BALANCES_EXCEL: {
        name: "Download excel with missing SI data from budget"
      },
      SWITCH_COMPANY: {
        name: "Companies Menu | Switch Company"
      },
      CLICK_MY_PROFILE: {
        name: "User Menu | My Profile"
      },
      CLICK_ADMINISTRATION: {
        name: "User Menu | Administration"
      },
      CLICK_COMMUNITY: {
        name: "User Menu | Community"
      },
      CLICK_VIDEOS: {
        name: "User Menu | Videos"
      },
      ADMIN: {
        ENTER_AUDIT_LOG: {
          name: "Internal Admin | Audit Log - Enter"
        },
        SEARCH_AUDIT_LOG: {
          name: "Internal Admin | Audit Log - Search"
        },
        SORT_AUDIT_LOG: {
          name: "Internal Admin | Audit Log - Sort"
        },
        ENTER_COMPANY_LIST: {
          name: "Internal Admin | Company List - Enter"
        },
        SEARCH_COMPANY_LIST: {
          name: "Internal Admin | Company List - Search"
        },
        SORT_COMPANY_LIST: {
          name: "Internal Admin | Company List - Sort"
        },
        CLICKS_ON_BUDGET_LIST: {
          name: "Internal Admin | Budget List - Enter"
        },
        SHARE_BUDGET: {
          name: "Internal Admin | Budget List - Share Access"
        },
        DOWNLOAD_BUDGET: {
          name: "Internal Admin | Budget List - Download budget"
        },
        CHANGE_USER_TO_OWNER: {
          name: "Internal Admin | User Management - Change user to Owner"
        },
        ADD_OWNER: {
          name: "Internal Admin | User Management - Add New Owner"
        },
        DISABLE_COMPANY: {
          name: "Internal Admin | Company - Disable Company" // TOOD - find a away to add the company name
        },
        DELETE_COMPANY: {
          name: "Internal Admin | Company - Delete Company" // TOOD - find a away to add the company name
        }
      },
      COMPANY_ADMIN: {
        CLICK_MY_BUDGET: {
          name: "External Admin | Clicks on My Budgets"
        },
        ADD_NEW_SIBP_USERS: {
          name: "External Admin | Add User - Add New User"
        },
        CHANGE_OWNER: {
          name: "External Admin | Add User - Change Owner"
        },
        ENTER_AUDIT_LOG: {
          name: "External Admin | Audit Log - Enter"
        },
        SEARCH_AUDIT_LOG: {
          name: "External Admin | Audit Log - Search"
        },
        SORT_AUDIT_LOG: {
          name: "External Admin | Audit Log - Sort"
        }
      },
      DISABLED_VERSIONS: {
        name: "Index | Versions - Disabled Versions"
      },
      GENERIC_FILE: {
        OPEN_WIZARD: {
          name: "Generic Wizard - Wizard Launched"
        },
        COMPANY_BUDGET_TYPE: {
          name: "Generic Wizard | Budget Settings Step - Selected Company Budget Type"
        },
        NON_PROFIT_BUDGET_TYPE: {
          name: "Generic Wizard | Budget Settings Step - Selected Non-Profit Budget Type"
        },
        CREATED_BUDGET: {
          name: "Generic Wizard | Preview Step - New Budget Created"
        },
        CURRENCIES_MAPPED: {
          name: "Generic Wizard | Currencies Mapping - Additional Currency Mapped"
        },
        BALANCE_SHEET_MAPPED: {
          name: "Generic Wizard | Account Category - Balance Sheet Category Mapped"
        },
        MAP_DATA_NEXT_ERROR: {
          name: "Generic Wizard | Table Coloumn Mapping - Next Clicked With Mandatory Fields Missing"
        },
        MISMATCH_POPUP: {
          name: "Generic Wizard | Finalize Mapping - Missing/Mismatch Pop-Up Appeared"
        },
        DOWNLOAD_MISSING_LINES: {
          name: "Generic Wizard | Preview Step - Downloaded Missing Lines Report"
        },
        PREVIEW_VIEW_SWITCH: {
          name: "Generic Wizard | Preview Step | Different Entity View Clicked"
        },
        BUDGET_LINES_EXCEEDED: {
          name: "Generic Wizard | Preview Step | Max Budget Lines Error Appeared"
        },
        COMPLETED_START_SCREEN: {
          name: "Generic Wizard | Let’s Start - Got It!"
        },
        COMPLETED_UPLOAD_FILE: {
          name: "Generic Wizard | File Upload - Let’s Start"
        },
        COMPLETED_BUDGET_SETTINGS: {
          name: "Generic Wizard | Set Your Budget Properties - Next"
        },
        SKIPPED_DIMENSIONS_MAPPING: {
          name: "Generic Wizard | Your Budget Dimensions Intro - No"
        },
        CHOOSED_DIMENSIONS_MAPPING: {
          name: "Generic Wizard | Your Budget Dimensions Intro - Yes"
        },
        COMPLETED_DIMENSIONS_MAPPING: {
          name: "Generic Wizard | Your Budget Dimensions - Next"
        },
        CLICKED_HEADERS_SELECT_INTRO_NEXT: {
          name: "Generic Wizard | Select Your Headers Intro - Next"
        },
        COMPLETED_HEADERS_SELECT: {
          name: "Generic Wizard | Select Your Headers - Next"
        },
        CLICKED_COLUMN_MAPPING_INTRO_NEXT: {
          name: "Generic Wizard | Map Your Data Intro - Next"
        },
        COMPLETED_COLUMN_MAPPING: {
          name: "Generic Wizard | Map Your Data - Next"
        },
        CLICKED_SELECT_LINES_INTRO_NEXT: {
          name: "Generic Wizard | Select Your Budget Lines Intro - Next"
        },
        COMPLETED_SELECT_LINES: {
          name: "Generic Wizard | Select Your Budget Lines - Next"
        },
        CLICKED_ACCOUNT_GROUP_INTRO_NEXT: {
          name: "Generic Wizard | Finalize Your Mapping Intro - Next"
        },
        COMPLETED_ACCOUNT_GROUP: {
          name: "Generic Wizard | Finalize Your Mapping | Account Groups - Next"
        },
        CATEGORY_ERROR_PREVIEW: {
          name: "Generic Wizard | Finalize Mapping - Mapping Error Preview Clicked"
        },
        CLICKED_MISMATCH_LINES_PREVIEW: {
          name: "Generic Wizard | Finalize Your Mapping | Can't Import Lines Warning Intro - Preview"
        },
        SKIPPED_ACCOUNT_GROUP_ERROR: {
          name: "Generic Wizard | Finalize Your Mapping | Can't Import Lines Warning Intro - Continue Anyway"
        },
        COMPLETED_CURRENCY_MAPPING: {
          name: "Generic Wizard | Finalize Your Mapping | Currncies Mapping - Next"
        },
        CHOOSED_HIERARCHY_STEP: {
          name: "Generic Wizard | Your Dimensions Hierarchy Question Intro - Yes"
        },
        SKIPPED_HIERARCHY_STEP: {
          name: "Generic Wizard | Your Dimensions Hierarchy Question Intro - No"
        },
        CLICKED_HIERARCHY_STEP_INTRO_NEXT: {
          name: "Generic Wizard | Your Dimensions Hierarchy Intro - Got It!"
        },
        COMPLETED_HIERARCHY_STEP: {
          name: "Generic Wizard | Your Dimensions Hierarchy - Next"
        },
        HIERARCHY_CREATED: {
          name: "Generic Wizard | Dimensions Hierarchy - Hierarchy Created"
        },
        CLICKED_BUDGET_STRUCTURE_INTRO_NEXT: {
          name: "Generic Wizard | Your Budget Structure Intro - Got It!"
        },
        SKIPPED_BUDGET_STRUCTURE: {
          name: "Generic Wizard | Your Budget Structure Intro Question - No"
        },
        CHOOSED_BUDGET_STRUCTURE: {
          name: "Generic Wizard | Your Budget Structure Intro Question - Yes"
        },
        COMPLETED_BUDGET_STRUCTURE: {
          name: "Generic Wizard | Budget Structure - Next"
        },
        STRUCTURE_CREATED: {
          name: "Generic Wizard | Your Budget Structure - Structure Created"
        },
        CLICKED_PREVIEW_STEP_INTRO_NEXT: {
          name: "Generic Wizard | Preview Step Intro - Next"
        }
      },
      IMPORT_ACTUALS_FROM_EXCEL: {
        IMPORT_ACTUALS_FROM_EXCEL: {
          name: "Import Actuals from Excel | Start Import"
        },
        IMPORT_ACTUALS_TEMPLATE_X: {
          name: "Import Actuals from Excel | {0} template"
        },
        IMPORT_ACTUALS_NEXT_SCREEN_X: {
          name: "Import Actuals from Excel | {0} - Next"
        },
        IMPORT_ACTUALS_BACK_SCREEN_X: {
          name: "Import Actuals from Excel | {0} - Back"
        },
        IMPORT_ACTUALS_MAP_BY_CATEGORY: {
          name: "Import Actuals from Excel | Map by category"
        },
        IMPORT_ACTUALS_FINISH_IMPORT: {
          name: "Import Actuals from Excel | Import"
        },
        IMPORT_ACTUALS_CANCEL_IMPORT: {
          name: "Import Actuals from Excel | Cancel import"
        }
      },
      STATISTICAL_DATA: {
        STATISTICAL_ACCOUNT_MAPPING_ADD_ACCOUNT_PERIOD: {
          name: 'Statistical Accounts | Actuals> Mapping> Add account - Click "For the period"'
        },
        STATISTICAL_ACCOUNT_MAPPING_ADD_ACCOUNT_CUMULATIVE: {
          name: 'Statistical Accounts | Actuals> Mapping> Add account - Click "Cumulative"'
        },
        STATISTICAL_ACCOUNT_MAPPING_TABLE_PERIOD: {
          name: 'Statistical Accounts | Actuals> Mapping> Statistical - Click "For the period"'
        },
        STATISTICAL_ACCOUNT_MAPPING_TABLE_CUMULATIVE: {
          name: 'Statistical Accounts | Actuals> Mapping> Statistical - Click "Cumulative"'
        },
        STATISTICAL_ACCOUNT_ACTUALS_DETAILS_MODEL: {
          name: "Statistical Accounts | Actuals> Details> Model"
        },
        STATISTICAL_ACCOUNT_ACTUALS_DETAILS_ADD_MODEL_MANUALLY: {
          name: "Statistical Accounts | Actuals> Details> Statistical - Add Manually"
        },
        STATISTICAL_CREATE_WIZARD_SELECT_ALL_STAT_ACCOUNTS: {
          name: "Statistical Accounts | Create budget wizard> Select your statistical accounts - Select all accounts"
        },
        STATISTICAL_CREATE_WIZARD_YES_STAT_ACCOUNTS: {
          name: "Statistical Accounts | Create budget wizard> Import statistical data - yes"
        },
        STATISTICAL_CREATE_WIZARD_SELECT_DIMENSION_HIERARCHY: {
          name: "Statistical Accounts | Create budget wizard> Match your dimensions to the selected accounts - Select hierarchy dimension"
        },
        STATISTICAL_ACCOUNT_SHEETS_MODEL_BUDGET_VS_ACTUALS: {
          name: 'Statistical Accounts | Sheets> Model> - Click "Budget vs actuals"'
        },
        STATISTICAL_ACCOUNT_SHEETS_MODEL_FORECAST_VS_ACTUALS: {
          name: 'Statistical Accounts | Sheets> Model> - Click "Forecast vs actuals"'
        },
        STATISTICAL_ACCOUNT_INPUTS_MODEL_BUDGET_VIEW: {
          name: 'Statistical Accounts | Inputs> Model> - Click "Budget"'
        },
        STATISTICAL_ACCOUNT_INPUTS_MODEL_ROLLING_VIEW: {
          name: 'Statistical Accounts | Inputs> Model> - Click "Rolling"'
        },
        STATISTICAL_ACCOUNT_INPUTS_MODEL_ACTUALS_VIEW: {
          name: 'Statistical Accounts | Inputs> Model> - Click "Actuals"'
        },
        STATISTICAL_ACCOUNT_INPUTS_MODEL_DROP_DOWN_CLICK: {
          name: 'Statistical Accounts | Inputs> Model> - Click "Model"'
        },
        STATISTICAL_ACCOUNT_INPUTS_STATISTICAL_DROP_DOWN_CLICK: {
          name: 'Statistical Accounts | Inputs> Model> - Click "Statistical account"'
        },
        STATISTICAL_ACCOUNT_INPUTS_ADD_MODEL_MODEL: {
          name: "Statistical Accounts | Inputs> Model> Model> - Add Model"
        },
        STATISTICAL_ACCOUNT_INPUTS_ADD_STATISTICAL_MODEL: {
          name: "Statistical Accounts | Inputs> Model> Statistical accounts> - Add New"
        },
        STATISTICAL_ACCOUNT_INPUTS_ADD_MODEL_GROUP: {
          name: "Statistical Accounts | Inputs> Model> Model> - Add Model Group"
        },
        STATISTICAL_ACCOUNT_INPUTS_ADD_STATISTICAL_GROUP: {
          name: "Statistical Accounts | Inputs> Model> Statistical accounts> - Add New Group"
        },
        STATISTICAL_ACCOUNT_IMPORT_ACTUALS_SELECT_STAT_REPORT: {
          name: "Statistical Accounts | Import Actuals> Model> Select statistical data report"
        },
        STATISTICAL_EXPORT_WIZARD_STAT: {
          name: "Statistical Accounts | Export Budget> Select financial reports> Model selected"
        },
        STATISTICAL_IMPORT_WIZARD: {
          name: "Statistical Accounts | Import statistical data"
        },
        STATISTICAL_IMPORT_WIZARD_SELECT_ACCOUNTS: {
          name: "Statistical Accounts | Import statistical data | Select your statistical accounts"
        },
        STATISTICAL_IMPORT_WIZARD_ACCOUNT_TO_DIMENSIONS: {
          name: "Statistical Accounts | Import statistical data | Match your accounts to dimensions"
        },
        STATISTICAL_IMPORT_WIZARD_POPULATE: {
          name: "Statistical Accounts | Import statistical data | Select data to populate your budget"
        },
        STATISTICAL_IMPORT_WIZARD_MODIFY_STRUCTURE: {
          name: "Statistical Accounts | Import statistical data | DONE"
        },
        FLYOUT: {
          CLICK_DROPDOWN: {
            name: "Statistical Accounts | move between lines in the flyout"
          }
        }
      },
      CREATE_BUDGET_FROM_INTACCT: {
        CREATE_BUDGET_SELECT_ENTITIES: {
          name: "Create budget wizard> Select entities - {0}"
        },
        CREATE_BUDGET_BUDGET_SETTINGS_COMPANY_TYPE: {
          name: "Create budget wizard> Budget settings company type - {0}"
        },
        CREATE_BUDGET_BUDGET_SETTINGS_DATE_RANGE: {
          name: "Create budget wizard> Budget settings date range - {0}"
        },
        CREATE_BUDGET_IMPORT_UDD: {
          name: "Create budget wizard> Import dimensions - User defiend dimension was selected."
        },
        CREATE_BUDGET_IMPORT_EMPLOYEES: {
          name: "Create budget wizard> Import employees - {0}"
        },
        CREATE_BUDGET_MAP_YOUR_ACCOUNTS: {
          name: "Create budget wizard> Map your accounts - {0} accounts are mapped"
        },
        CREATE_BUDGET_MAP_BUILD_BUDGET_STRUCTURE: {
          name: "Create budget wizard> Build your budget structure" // TOOD - find a away to add the structure
        },
        CREATE_BUDGET_POPULATE_BUDGET: {
          name: "Create budget wizard> Populate your budget - {0}"
        },
        CREATE_BUDGET_POPULATE_BUDGET_DATA_SOURCE: {
          name: "Create budget wizard> Select data to populate your budget > Data source - {0}"
        },
        CREATE_BUDGET_POPULATE_BUDGET_ACCRUAL_MAIN_BOOK_SELECTED: {
          name: "Create budget wizard> Select data to populate your budget - ACCRUAL main book selected: {0}"
        },
        CREATE_BUDGET_POPULATE_BUDGET_ADDITINAL_BOOKS_IN_USE: {
          name: "Create budget wizard> Select data to populate your budget - Additional books are in use"
        },
        CREATE_WIZARD_REMOVE_LINE_TOGGLE: {
          name: "Create budget wizard> Modify your budget structure - remove {0} lines without past actuals - toggle "
        },
        CREATE_WIZARD_REMOVE_LINE_NO_PAST_BUDGET: {
          name: "Create budget wizard> Modify your budget structure - remove budget lines without past actuals : {0} "
        },
        CREATE_WIZARD_REMOVE_LINE_NO_PAST_STAT: {
          name: "Create budget wizard> Modify your budget structure - remove statistical lines without past actuals {0}"
        },
        CREATE_WIZARD_MAP_LOCATION: function CREATE_WIZARD_MAP_LOCATION() {
          return {
            name: "Create budget wizard> Map budget lines without locations"
          };
        },
        CREATE_WIZARD_COMPLETE: {
          name: "Create budget wizard> Wizard complete - Budget was created",
          beforeTrack: function beforeTrack(eventName, data) {
            return {
              eventName: eventName,
              data: data
            };
          }
        },
        CREATE_WIZARD_SOMETHING_WENT_WRONG: {
          name: "Create budget wizard | Modify your budget structure | Something went wrong"
        }
      },
      EXPORT_BUDGET_TO_INTACCT: {
        EXPORT_BUDGET_EXPORT_TYPE_CREATE_TOP_LEVEL: {
          name: "Export Budget | Select how to export your budget> Create | Top Level"
        },
        EXPORT_BUDGET_EXPORT_TYPE_CREATE_TOP_LEVEL_CONSOLIDATED: {
          name: "Export Budget | Select how to export your budget> Create | Top Level Consolidated"
        },
        EXPORT_BUDGET_EXPORT_TYPE_CREATE_ENTITY: {
          name: "Export Budget | Select how to export your budget> Create | Entity"
        },
        EXPORT_BUDGET_EXPORT_TYPE_ADD: {
          name: "Export Budget | Select how to export your budget> Add"
        },
        EXPORT_BUDGET_EXPORT_TYPE_REPLACE: {
          name: "Export Budget | Select how to export your budget> Replace"
        },
        EXPORT_BUDGET_EXPORT_TIME_PERIOD_MONTHLY: {
          name: "Export Budget | Select time period interval> Monthly"
        },
        EXPORT_BUDGET_EXPORT_TIME_PERIOD_QUARTERLY: {
          name: "Export Budget | Select time period interval> Quarterly"
        },
        EXPORT_BUDGET_EXPORT_TIME_PERIOD_YEARLY: {
          name: "Export Budget | Select time period interval> Yearly"
        },
        EXPORT_BUDGET_EXPORT_REPORT_PNL: {
          name: "Export Budget | Select financial reports> Profit & Loss"
        },
        EXPORT_BUDGET_EXPORT_REPORT_BS: {
          name: "Export Budget | Select financial reports> Balance Sheet"
        },
        EXPORT_BUDGET_EXPORT_MAP_PNL: {
          name: "Export Budget | Map Profit & Loss budget lines"
        },
        EXPORT_BUDGET_EXPORT_MAP_PNL_SKIP: {
          name: "Export Budget | Map Profit & Loss budget lines> Skip"
        },
        EXPORT_BUDGET_EXPORT_MAP_BS: {
          name: "Export Budget | Map Balance sheet lines"
        },
        EXPORT_BUDGET_EXPORT_MAP_BS_SKIP: {
          name: "Export Budget | Map Balance sheet lines> Skip"
        },
        EXPORT_BUDGET_EXPORT_MAP_DIMENSIONS: {
          name: "Export Budget | Map budget dimensions"
        },
        EXPORT_BUDGET_EXPORT_MAP_DIMENSIONS_SKIP: {
          name: "Export Budget | Map budget dimensions> Skip"
        },
        EXPORT_BUDGET_EXPORT_MAP_LINES_WITHOUT_LOCATION: {
          name: "Export Budget | Map lines without locations"
        },
        EXPORT_BUDGET_EXPORT_MAP_LINES_WITHOUT_LOCATION_SKIP: {
          name: "Export Budget | Map lines without locations> Skip"
        },
        EXPORT_BUDGET_EXPORT_MAP_DIMENSIONS_VALUES: {
          name: "Export Budget | Map budget dimensions values"
        },
        EXPORT_BUDGET_EXPORT_MAP_DIMENSIONS_VALUES_SKIP: {
          name: "Export Budget | Map budget dimensions values> Skip"
        },
        EXPORT_BUDGET_TO_INTACCT_SUCCESS: {
          name: "Export Budget | Export success"
        },
        EXPORT_BUDGET_TO_INTACCT_FAILURE: {
          name: "Export Budget | Export failure"
        }
      },
      IMPORT_ACTUALS_FROM_INTACCT: {
        IMPORT_ACTUALS_SELECT_REPORT: {
          name: "Import Actuals | Select Reports > Select {0} report"
        },
        IMPORT_ACTUALS_ADDITIONAL_BOOKS_IN_USE: {
          name: "Import Actuals | Select reporting book > Additional books are in use"
        },
        IMPORT_ACTUALS_DATE_RANGE: {
          name: "Import Actuals | Select date range > Range option: {0}"
        },
        IMPORT_ACTUALS_MAP_MISSING_DIMENSIONS: {
          name: "Import Actuals | New dimensions > Step is displayed"
        },
        IMPORT_ACTUALS_MAP_MISSING_DIMENSIONS_VALUES: {
          name: "Import Actuals | New dimension values > Step is displayed"
        },
        IMPORT_ACTUALS_MAP_MISSING_ACCOUNTS: {
          name: "Import Actuals | New Accounts > Step is displayed"
        },
        IMPORT_ACTUALS_MAP_MISSING_STAT_ACCOUNTS: {
          name: "Import Actuals | New Statistical Accounts > Step is displayed"
        },
        IMPORT_ACTUALS_SUCCESS: {
          name: "Import Actuals | Import success"
        },
        IMPORT_ACTUALS_FAILURE: {
          name: "Import Actuals | Import failure"
        }
      },
      SYNC_ACCOUNTS: {
        SYNC_ACCOUNTS_OPTION_SELECTED: {
          name: "Account sync wizard | Select to sync accounts"
        },
        SYNC_ACCOUNTS_SELECT_PNL_REPORT: {
          name: "Account sync wizard | Select Reports > Select P&L report"
        },
        SYNC_ACCOUNTS_SELECT_ALL_REPORTS: {
          name: "Account sync wizard | Select Reports > Select all reports"
        },
        SYNC_ACCOUNTS_MATCH_ACCOUNT: {
          name: "Account sync wizard | Modify Accounts > Match SIF account to a SIP account"
        },
        SYNC_ACCOUNTS_CREATE_ACCOUNT: {
          name: "Account sync wizard | Modify Accounts > Create a new SIP account"
        },
        SYNC_ACCOUNTS_CREATE_ACCOUNT_MAPPING_TYPE: {
          name: "Account sync wizard | Modify Accounts > Selected mapping type: {0}"
        },
        SYNC_ACCOUNTS_SELECT_ACCOUNT_DESCRIPTION: {
          name: "Account sync wizard | Modify Accounts > {0} account description selected"
        },
        SYNC_ACCOUNTS_DELETE_SOME_ACCOUNTS: {
          name: "Account sync wizard | Delete Accounts > Some accounts have been deleted."
        },
        SYNC_ACCOUNTS_DELETE_ALL_ACCOUNTS: {
          name: "Account sync wizard | Delete Accounts > All accounts have been deleted."
        },
        SYNC_ACCOUNTS_END_SUCCESSFULY: {
          name: "Account sync wizard | Finish - Success"
        },
        SYNC_ACCOUNTS_END_FAILURE: {
          name: "Account sync wizard | Finish - Failed"
        }
      },
      EXPORT_IMPORT_INTACCT_OPEN: {
        name: "Integration Import/Export Wizard | Open wizard"
      },
      EXPORT_IMPORT_INTACCT_CLOSE: {
        name: "Integration Import/Export Wizard | Close wizard"
      },
      BUDGET_TREE_OPTIONS: {
        USER_PICK: {
          BUDGET_LINES: {
            name: "".concat(EV_PREFIX_BUDGET_TREE_OPTIONS, " | User picks 'Budget Lines'")
          },
          ACCOUNT_AND_NAME: {
            name: "".concat(EV_PREFIX_BUDGET_TREE_OPTIONS, " | User picks 'Account & Name'")
          },
          ACCOUNT: {
            name: "".concat(EV_PREFIX_BUDGET_TREE_OPTIONS, " | User picks 'Account'")
          },
          LEAF_LEVEL: {
            name: "".concat(EV_PREFIX_BUDGET_TREE_OPTIONS, " | User picks 'Leaf Level'")
          }
        }
      },
      GRID: {
        TOGGLE: function TOGGLE(on) {
          return {
            name: "".concat(EV_PREFIX_GRID, " | Grid view toggle: ").concat(on)
          };
        },
        FLYOUT: {
          OPEN: {
            name: "".concat(EV_PREFIX_GRID, " | Open flyout")
          },
          CHANGE_WIDTH: function CHANGE_WIDTH(isWide) {
            return {
              name: "".concat(EV_PREFIX_GRID, " | Grid flyout change width to: ").concat(isWide ? "wide" : "narrow")
            };
          },
          ADD_COMMENT: {
            name: "".concat(EV_PREFIX_GRID, " | Grid flyout add comment")
          },
          CLICK_DROPDOWN: {
            name: "".concat(EV_PREFIX_GRID, " | Grid flyout move between lines in the flyout")
          }
        },
        FILTERS: {
          BUILD_FILTER_EVENT_OF: function BUILD_FILTER_EVENT_OF(_ref) {
            var filterName = _ref.filterName;
            return {
              name: "".concat(EV_PREFIX_GRID, " | Filter dimension: ").concat(filterName)
            };
          },
          BUILD_MULTI_SELECTION_EVENT_OF: function BUILD_MULTI_SELECTION_EVENT_OF(_ref2) {
            var filterName = _ref2.filterName;
            return {
              name: "".concat(EV_PREFIX_GRID, " | Multiple selection filter | Filter dimension: ").concat(filterName)
            };
          }
        },
        EDIT: {
          START_SAVE: {
            name: "".concat(EV_PREFIX_GRID, " | cell edit | start save")
          },
          END_SAVE: {
            name: "".concat(EV_PREFIX_GRID, " | cell edit | end save")
          }
        },
        HOVER_ON_COMMENT: {
          name: "".concat(EV_PREFIX_GRID, " | Grid view hover on comment icon")
        }
      },
      BUDGET_MENU: {
        OPEN: {
          name: "".concat(EV_PREFIX_BUDGET_MENU, " | Open")
        },
        OPTION_SELECTED: {
          BUILD_OPTION_EVENT_OF: function BUILD_OPTION_EVENT_OF(optionValue) {
            return {
              name: "".concat(EV_PREFIX_BUDGET_MENU, " | Select option: '").concat(optionValue)
            };
          }
        }
      },
      FORM_VIEW: {
        ADD_COMMENT: {
          name: "".concat(EV_PREFIX_FORM, " | Form view add comment")
        }
      },
      DOWNLOAD_OPTIONS_MODAL: {
        SAVE: {
          buildEvent: function buildEvent(action, versionTypes) {
            var buildName = function buildName(action, versionTypes) {
              var prefix = action === "download" ? "Download budget" : "Duplicate budget";
              var versionsStr = versionTypes.length ? [versionTypes.includes("snapshot") && "Snapshot", versionTypes.includes("forecast") && "Forecast", versionTypes.includes("base") && "Base budget"].filter(function (str) {
                return str;
              }).join(" & ") : "Working Budget";
              var output = [prefix, versionsStr].filter(function (str) {
                return str;
              }).join(" | ");
              return output;
            };
            return {
              name: buildName(action, versionTypes)
            };
          }
        }
      },
      SHEETS_CUSTOM_VIEW: {
        CREATE: {
          name: "POV for Sheets | Create a new view"
        },
        EDIT: {
          name: "POV for Sheets | Edit view"
        },
        DELETE: {
          name: "POV for Sheets | Delete view"
        },
        SELECT_WORKING_BUDGET: {
          name: "POV for Sheets | Select working budget"
        },
        SELECT_SCENARIO: {
          name: "POV for Sheets | Select scenario"
        },
        SELECT_FORECAST: {
          name: "POV for Sheets | Select forecast"
        },
        SELECT_FORECAST_NOT_EXIST: {
          name: "POV for Sheets | Select modified forecast"
        },
        SELECT_CURRENCY_NOT_EXIST: {
          name: "POV for Sheets | Select modified currency"
        },
        SELECT_DISABLED_FORECAST: {
          name: "POV for Sheets | Select disabled forecast"
        },
        SELECT_DISABLED_SCENARIO: {
          name: "POV for Sheets | Select disabled scenario"
        }
      },
      REAL_TIME_COLLABORATION: {
        STATUS_CHANGE: function STATUS_CHANGE(enabled) {
          return {
            name: "Settings | Real-time collaboration | ".concat(enabled ? "on" : "off")
          };
        }
      }
    },
    trackEvent: function trackEvent() {
      var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        name: "missingEventName"
      };
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var additionalData = {};
      var parseEventNameWithParameters = function parseEventNameWithParameters(name, parameters) {
        var index = 0;
        if (!parameters || !parameters.length) return name;
        while (name.includes("{".concat(index, "}")) && parameters[index]) {
          name = name.replace("{".concat(index, "}"), parameters[index]);
          index++;
        }
        return name;
      };
      var eventName = parseEventNameWithParameters(event.name, data.nameParameters);
      if (this.get("budgetService.selectedBudget.id") && !Ember.get(data, "budget_line_id") && !Ember.get(data, "budget_line_title")) {
        data["Active Line Type"] = this.get("budgetService.selectedBudget.budgetType");
        data["Active Line Id"] = this.get("budgetService.selectedBudget.id");
      }
      if (this.get("budgetService.selectedRoot.id")) {
        data["Active Root Id"] = this.get("budgetService.selectedRoot.id");
        data["Active Root Title"] = this.get("budgetService.selectedRoot.title");
        data["Active Root #lines"] = this.get("treeManager.budgetsList.length");
      }
      data.timestamp = new Date().toUTCString();
      if (event.beforeTrack) {
        var returnEvent = event.beforeTrack.apply(this, [eventName, data]);
        if (returnEvent) {
          eventName = returnEvent.eventName;
          data = returnEvent.data;
        }
      }
      if (event.postTrack) {
        event.postTrack.apply(this, [eventName, data]);
      }
      if (this.isPendoReady()) {
        if (data.additionalData) {
          additionalData["additionalData"] = data.nameParameters;
        }
        if (data.isTransform) {
          this.get("pendo").location.addTransforms([{
            attr: "pathname",
            action: "Replace",
            data: data.url
          }, {
            attr: "search",
            action: "Clear"
          }]);
        } else {
          this.get("pendo").location.clearTransforms();
        }
        this.get("pendo").track(eventName, additionalData);
      }

      // write session action
      this.get("writeSessionLastAction").apply(this);
    },
    writeSessionLastAction: function writeSessionLastAction() {
      if (!this.get("currentUser")) {
        return;
      }
      var sessionCount = {
        lastActivity: this.get("currentUser.lastActivity"),
        "Lifetime Sessions": 0,
        "Customer Age Days": 0,
        "First Login": this.get("currentUser.firstLogin"),
        did20logins: false,
        did10logins: false,
        did3logins: false
      };
      var lastActionTime = sessionCount.lastActivity * 1,
        nowDate = new Date(),
        now = nowDate.getTime(),
        lastSessionDate = nowDate.toISOString(),
        timeDiff = (now - lastActionTime) / (1000 * 60),
        // in minutes
        sendNewSession = false;
      if (timeDiff >= 30) {
        // last action 30 min ago => new session
        if (sessionCount["Lifetime Sessions"] >= 20 && !sessionCount.did20logins) {
          sessionCount.did20logins = true;
        } else if (sessionCount["Lifetime Sessions"] >= 10 && !sessionCount.did10logins) {
          sessionCount.did10logins = true;
        } else if (sessionCount["Lifetime Sessions"] >= 3 && !sessionCount.did3logins) {
          sessionCount.did3logins = true;
        }
        sessionCount["Lifetime Sessions"] = sessionCount["Lifetime Sessions"] + 1;
        this.set("totalRowsInSession", 0);
        sendNewSession = true;
      }
      var peopleSet = {
        "Last Session Date": lastSessionDate,
        "Lifetime Sessions": sessionCount["Lifetime Sessions"],
        "First Login": this.get("currentUser.firstLogin")
      };
      if (!sessionCount["First Login"]) {
        sessionCount["First Login"] = this.get("currentUser.firstLogin");
        peopleSet["First Login"] = this.get("currentUser.firstLogin");
      } else {
        peopleSet["First Login"] = this.get("currentUser.firstLogin");
      }
      sessionCount.lastActivity = now;
      sessionCount["Last Session Date"] = lastSessionDate;
      sessionCount["Customer Age Days"] = nowDate.daysSince(Date.create(this.get("currentUser.firstLogin")));
      peopleSet["Customer Age Days"] = sessionCount["Customer Age Days"];
      if (sendNewSession && this.isPendoReady()) {
        this.get("pendo").track("User Session", {
          session_date: lastSessionDate
        });
      }
    },
    pendo: window.pendo,
    getVisitorId: function getVisitorId(id) {
      return _automation.default.isAutomationMode() ? "AUTOMATION_USER_".concat(id) : id;
    },
    isPendoReady: function isPendoReady() {
      return this.get("pendo") && this.get("pendo.isReady") && this.get("pendo").isReady();
    },
    getUserTypeByEmail: function getUserTypeByEmail(email) {
      var userType = "User";
      var currentCompany = this.get("budgetService.selectedCompany");
      var currentUserCompanies = this.get("session.data.currentUser.companies") || [];
      if (!email || typeof email !== "string") return userType;
      var isEmployee = function isEmployee() {
        return email.endsWith("@sage.com") || email.endsWith("@intacct.com") || email.endsWith("@budgeta.com");
      };
      var isPartner = function isPartner() {
        try {
          var userCurrentCompany = currentUserCompanies.find(function (company) {
            return company._id === currentCompany._id;
          });
          if (userCurrentCompany && Ember.get(userCurrentCompany, "user.userType") === "consultant") {
            return true;
          }
        } catch (e) {
          return false;
        }
      };
      if (isEmployee()) {
        userType = "Employee";
      } else if (isPartner()) {
        userType = "Partner";
      }
      return userType;
    },
    bootPendoAdminApp: function bootPendoAdminApp() {
      var user = this.get("session.data.currentUser");
      if (user) {
        var _this$get;
        var id = user.id,
          email = user.email;
        email = (email || "").toLowerCase();
        if (email && email.endsWith("@sibpa.com")) {
          return;
        }
        var userRole = user.roles.includes(_const.USER_ROLE.SUPER_ADMIN) ? _const.USER_ROLE.SUPER_ADMIN : user.roles.includes(_const.USER_ROLE.ADMIN) ? _const.USER_ROLE.ADMIN : "not admin";
        var data = {
          visitor: {
            id: this.getVisitorId(id),
            userType: this.getUserTypeByEmail(email),
            role: userRole
          },
          account: {
            id: id,
            userType: this.getUserTypeByEmail(email),
            role: userRole
          }
        };
        (_this$get = this.get("pendo")) === null || _this$get === void 0 || _this$get.initialize(data);
      }
    },
    bootPendo: function bootPendo() {
      var userCompanies = this.get("session.data.currentUser.companies") || Ember.A([]);
      var absoluteRoot = this.get("budgetService.selectedRoot.absoluteRoot");
      var user = this.get("currentUser");
      var selectedCompany = this.get("budgetService.selectedCompany");
      if (selectedCompany && user) {
        var _this$get2;
        var user_id = user.user_id,
          email = user.email;
        email = (email || "").toLowerCase();
        if (email && email.endsWith("@sibpa.com")) {
          return;
        }
        var userRole = selectedCompany.user.roles.includes(_const.COMPANY_USER_ROLE.COMPANY_ADMIN) ? "company-owner" : _const.COMPANY_USER_ROLE.COMPANY_USER;
        var data = {
          visitor: {
            id: this.getVisitorId(user_id),
            role: userRole,
            userType: this.getUserTypeByEmail(email),
            companyCount: userCompanies.length
          },
          account: {
            id: selectedCompany._id,
            name: selectedCompany.name,
            source: selectedCompany.origin,
            expectedImplementDate: selectedCompany.expectedImplementDate,
            salesforceId: selectedCompany.salesforceId
          }
        };
        if (absoluteRoot) {
          data.visitor.budgetId = absoluteRoot.get("id");
        }
        (_this$get2 = this.get("pendo")) === null || _this$get2 === void 0 || _this$get2.initialize(data);
      }
    },
    boot: function boot(currentUser, newOptions) {
      currentUser = currentUser || this.get("currentUser") || {};
      if (newOptions) {
        currentUser = Ember.merge(currentUser, newOptions);
      }
      currentUser["budgetaVersion"] = _environment.default.APP.version;
      currentUser["Budgeta Environment"] = _environment.default.budgetaEnvironment;
      this.set("currentUser", currentUser);
      if (currentUser.is_beta_user) {
        currentUser["hide_default_launcher"] = true;
      }
      if (currentUser.created || currentUser.lastActivity) {
        var firstLogin = new Date(currentUser.created || currentUser.lastActivity).toISOString();
        currentUser["firstLogin"] = firstLogin;
      }
      this.set("totalRowsInSession", 0);
    },
    trackConvert: function trackConvert() {
      _ubaq.push(["trackGoal", "convert"]);
      (function () {
        var ub_script = document.createElement("script");
        ub_script.type = "text/javascript";
        ub_script.src = ("https:" === document.location.protocol ? "https://" : "http://") + "d3pkntwtp2ukl5.cloudfront.net/uba.js";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(ub_script, s);
      })();
    }
  });
});