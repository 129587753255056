define("budgeta/components/react/synced-users-menu/synced-users-menu", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@sibp/ui-components", "react", "budgeta/components/react/synced-users-menu/circlesWithTooltip", "budgeta/components/react/synced-users-menu/extra-users-list"], function (_exports, _slicedToArray2, _uiComponents, _react, _circlesWithTooltip, _extraUsersList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var getInitials = function getInitials(fullName) {
    return fullName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
  };
  var SyncedUsersMenu = function SyncedUsersMenu(_ref) {
    var users = _ref.users,
      maxUsers = _ref.maxUsers;
    var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      isMenuOpen = _React$useState2[0],
      setIsMenuOpen = _React$useState2[1];
    var _users = users.map(function (user) {
      var fullName = "".concat(user.firstName, " ").concat(user.lastName);
      return {
        fullName: fullName,
        initials: getInitials(fullName),
        id: user.id
      };
    }).sort(function (a, b) {
      return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase());
    });
    var displayedUsers = _users.slice(0, maxUsers);
    var extraUsersNum = Math.max(_users.length - maxUsers, 0);
    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("div", {
      className: "users-container"
    }, _react.default.createElement(_circlesWithTooltip.default, {
      users: displayedUsers
    }), !!extraUsersNum && _react.default.createElement("div", {
      "aria-hidden": true,
      className: "extra-users-container ".concat(isMenuOpen ? "open" : ""),
      onClick: function onClick() {
        setIsMenuOpen(!isMenuOpen);
      }
    }, extraUsersNum && _react.default.createElement(_uiComponents.default.COMPONENTS.CircleWithText, {
      label: "+" + extraUsersNum,
      className: "extra-users"
    }), _react.default.createElement("div", {
      className: "dropdown-arrow"
    }, _react.default.createElement(_uiComponents.default.IMAGES.ArrowDownSvg, null)))), isMenuOpen && _react.default.createElement(_extraUsersList.default, {
      users: _users,
      onOutsideClick: function onOutsideClick() {
        setIsMenuOpen(false);
      }
    }));
  };
  var _default = _exports.default = SyncedUsersMenu;
});