define("budgeta/admin/admin-app/main/main", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "react", "react-redux", "budgeta/admin/admin-app/store/store", "budgeta/admin/admin-app/components/header/header", "budgeta/admin/admin-app/components/body/body", "budgeta/admin/components/modal", "budgeta/admin/axios.init", "budgeta/admin/admin-app/components/body/bodyContext"], function (_exports, _slicedToArray2, _react, _reactRedux, _store, _header, _body, _modal, _axios, _bodyContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = Main;
  /* eslint-disable import/no-unresolved */

  (0, _axios.axiosInit)(_store.default);
  function Main() {
    var _React$useState = _react.default.useState(true),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      showNavBar = _React$useState2[0],
      setShowNavBar = _React$useState2[1];
    var bodyContextValue = {
      showNavBar: showNavBar,
      setShowNavBar: setShowNavBar
    };
    return (
      // <React.StrictMode> // TODO uncomment when version 5 of @material-ui/core is available
      _react.default.createElement(_modal.default.ModalContext.ModalProvider, null, _react.default.createElement(_modal.default.ModalRoot, null), _react.default.createElement(_reactRedux.default.Provider, {
        store: _store.default
      }, _react.default.createElement("div", {
        className: "admin-app-container"
      }, _react.default.createElement(_header.Header, null), _react.default.createElement(_bodyContext.default.Provider, {
        value: bodyContextValue
      }, _react.default.createElement(_body.Body, null)))))

      // </React.StrictMode> // TODO uncomment when version 5 of @material-ui/core is available
    );
  }
});