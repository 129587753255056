define("budgeta/templates/components/dynamic-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZTIggaVW",
    "block": "{\"statements\":[[\"append\",[\"helper\",[\"trx-filter\"],null,[[\"attr\",\"sort\",\"filterMap\",\"hasAnyFilter\",\"filteredColumns\",\"filteredTransactions\",\"filter\"],[[\"get\",[\"attributeName\"]],[\"helper\",[\"action\"],[[\"get\",[null]],\"sort\"],null],[\"get\",[\"filterMap\"]],[\"get\",[\"hasAnyFilter\"]],[\"get\",[\"filteredColumns\"]],[\"get\",[\"filteredTransactions\"]],[\"get\",[\"filter\"]]]]],false]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/dynamic-filter.hbs"
    }
  });
});