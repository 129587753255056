define("budgeta/components/integration-wizard-select-import-export", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    FF_ACCOUNT_SYNC: _const.default.FF_ACCOUNT_SYNC,
    importLable: Ember.computed("intl.locale", function () {
      return this.get("intl").t("sync.import-option");
    }),
    exportLable: Ember.computed("intl.locale", function () {
      return this.get("intl").t("sync.export-option");
    }),
    importStatisticalLabel: Ember.computed("intl.locale", function () {
      return this.get("intl").t("sync.import-statistical-option");
    }),
    syncAccountsLabel: Ember.computed("intl.locale", function () {
      return this.get("intl").t("sync.sync-accounts-option");
    }),
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    })
  });
});