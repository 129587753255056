define("budgeta/models/actual-account", ["exports", "ember-data", "budgeta/services/ember-service-container", "budgeta/utils/accounts", "budgeta/utils/collection", "budgeta/utils/const"], function (_exports, _emberData, _emberServiceContainer, _accounts, _collection, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers */
  var _default = _exports.default = _emberData.default.Model.extend({
    budgetService: Ember.inject.service(),
    intl: Ember.inject.service(),
    actualAccounts: Ember.computed("budgetService.actualAccounts", function () {
      return this.get("budgetService.actualAccounts");
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.initAccountSetup();

      // get computed properties to ensure that properties are computed for dependent keys
      this.get("budgetLinesLength");
      this.get("dimensionsLength");
    },
    account: _emberData.default.attr("string"),
    budgetLinesLength: Ember.computed("budgetLines.[]", function () {
      return this.get("budgetLines").length;
    }),
    dimensionsLength: Ember.computed("dimensions.[]", function () {
      return this.get("dimensions").length;
    }),
    isMissingAccountId: Ember.computed("account", function () {
      return (0, _collection.isEmptyString)(this.get("account"));
    }),
    isMissingBudgetLines: Ember.computed("budgetLines.[]", "mappingType", function () {
      if (this.get("mappingType") === "single" || this.get("mappingType") === "multi") {
        if (!this.get("budgetLines.length")) {
          return true;
        }
      }
      return false;
    }),
    isMissinDimensions: Ember.computed("budgetLines.[]", "mappingType", "dimensions.[]", function () {
      if (this.get("mappingType") === "multi") {
        if (!this.get("dimensions.length")) {
          return true;
        }
      }
      return false;
    }),
    accountError: Ember.computed("isMissingAccountId", "isMissingType", "isMissingBudgetLines", "isMissinDimensions", function () {
      return this.get("isMissingAccountId") || this.get("isMissingType") || this.get("isMissingBudgetLines") || this.get("isMissinDimensions");
    }),
    description: _emberData.default.attr("string"),
    title: Ember.computed("account", "description", function () {
      return this.get("description") && this.get("description").trim() ? "".concat(this.get("account"), " - ").concat(this.get("description")) : this.get("account");
    }),
    type: _emberData.default.attr("string", {
      defaultValue: "pnl"
    }),
    copyFromAccount: _emberData.default.attr("string"),
    typeDisplay: Ember.computed("type", {
      get: function get() {
        return this.get("type");
      },
      set: function set(key, value) {
        this.setProperties({
          mappingType: "single",
          budgetLines: [],
          dimensions: [],
          copyFromAccount: undefined,
          type: value,
          reportingType: value === "stat" ? _const.default.ACCOUNT_REPORTING_TYPE.PERIOD : null
        });
        return value;
      }
    }),
    isMissingType: Ember.computed("type", function () {
      return !this.get("type");
    }),
    isSingle: Ember.computed("mappingType", function () {
      return this.get("mappingType") === "single";
    }),
    mappingType: _emberData.default.attr("string", {
      defaultValue: "single"
    }),
    mappingTypeDisplay: Ember.computed("mappingType", "copyFromAccount", "isStatisticalType", {
      get: function get() {
        if (this.get("mappingType") === "single") {
          return this.get("intl").t("account-mapping-menu.single-".concat(this.get("isStatisticalType") ? "model" : "budget", "-line"));
        }
        if (this.get("mappingType") === "multi") {
          return this.get("intl").t("account-mapping-menu.multiple-".concat(this.get("isStatisticalType") ? "model" : "budget", "-lines"));
        }
        if (this.get("mappingType") === "none") {
          return this.get("intl").t("account-mapping-menu.no-mapping");
        }
        if (this.get("mappingType") === "clone") {
          return intl.t("general.actual-account-1", {
            var1: this.get("copyFromAccount")
          });
        }
        return "";
      },
      set: function set(key, value) {
        this.set("mappingType", value);
        if (!this.get("mappingTypeBefore")) {
          this.set("mappingTypeBefore", value);
        }
        return value;
      }
    }),
    cloneAccount: Ember.computed("copyFromAccount", "isClone", function () {
      if (this.get("isClone")) {
        return _accounts.default.findCloneAccount(this, this.get("actualAccounts").reduce(function (accountMap, account) {
          accountMap[account.get("account")] = account;
          return accountMap;
        }, {}));
      }
    }),
    cloneBudgetLines: Ember.computed("cloneAccount.budgetLines.[]", "cloneAccount", "isClone", function () {
      if (!this.get("isClone")) {
        return [];
      }
      return (this.get("cloneAccount.budgetLines") || []).map(function (item) {
        return item;
      });
    }),
    cloneDimensions: Ember.computed("cloneAccount.dimensions.[]", "cloneAccount", "isClone", function () {
      if (!this.get("isClone")) {
        return [];
      }
      return (this.get("cloneAccount.dimensions") || []).map(function (item) {
        return item;
      });
    }),
    maxItemsLength: Ember.computed("cloneDimensions.[]", "cloneBudgetLines.[]", "budgetLines.[]", "dimensions.[]", "isSingle", function () {
      var budgetLines = this.get("budgetLines.length") || this.get("cloneBudgetLines.length") || 1;
      var dimensionLines = this.get("isSingle") ? 1 : this.get("dimensions.length") || this.get("cloneDimensions.length") || 1;
      return Math.max(budgetLines, dimensionLines);
    }),
    initAccountSetup: function initAccountSetup() {
      this.set("initAccount", this.get("account"));
      // reset lines which have issus when changed mappingType
      if (this.get("mappingType") === "none" && this.get("budgetLines").length) {
        this.set("budgetLines", []);
        this.set("dimensions", []);
        this.save();
      } else if (this.get("mappingType") === "single" && this.get("budgetLines").length > 1) {
        this.set("budgetLines", [this.get("budgetLines")[this.get("budgetLines").length - 1]]);
        this.set("dimensions", []);
        this.save();
      }
    },
    mappingTypeObserver: Ember.observer("mappingType", function () {
      var value = this.get("mappingType");
      if (this.get("mappingTypeBefore") !== value) {
        // handle mapping type changes
        if (value === "none" || value === "clone") {
          this.setProperties({
            budgetLines: [],
            dimensions: []
          });
        } else if (value === "single" && (this.get("mappingTypeBefore") === "clone" || this.get("mappingTypeBefore") === "multi" || !this.get("mappingTypeBefore"))) {
          this.setProperties({
            budgetLines: this.get("budgetLines.length") > 1 ? [] : this.get("budgetLines"),
            dimensions: []
          });
        }
      }
      this.set("mappingTypeBefore", this.get("mappingType"));
    }),
    disableBudgetLines: Ember.computed("mappingType", function () {
      return this.get("mappingType") === "none";
    }),
    enableMulti: Ember.computed("type", "mappingType", function () {
      return (this.get("type") === "pnl" || this.get("type") === "stat") && this.get("mappingType") === "multi";
    }),
    canSelectMulti: Ember.computed("type", "mappingType", function () {
      return this.get("type") === "pnl" || this.get("type") === "stat";
    }),
    isClone: Ember.computed("mappingType", "copyFromAccount", function () {
      return this.get("mappingType") === "clone";
    }),
    isBalanceSheetType: Ember.computed("type", function () {
      return this.get("type") === "bs";
    }),
    isStatisticalType: Ember.computed("type", function () {
      return this.get("type") === "stat";
    }),
    budgetLines: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    dimensions: _emberData.default.attr("raw", {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    created: _emberData.default.attr("date"),
    budget: _emberData.default.belongsTo("budget", {
      inverse: "actualAccounts",
      async: false
    }),
    reportingType: _emberData.default.attr("string", {
      defaultValue: null
    }),
    reportingTypeDisplay: Ember.computed("reportingType", function () {
      if (this.get("reportingType") === _const.default.ACCOUNT_REPORTING_TYPE.CUMULATIVE) {
        return this.get("intl").t("accounts.report-type.cumulative");
      }
      return this.get("intl").t("accounts.report-type.for-the-period");
    })
  });
});