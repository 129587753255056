define("budgeta/components/account-mapping-menu", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ["account-mapping-menu"],
    isOpen: false,
    showActive: Ember.computed("fromImport", "isNewMapping", "isOpen", function () {
      return this.get("isOpen") && !this.get("fromImport") && !this.get("isNewMapping");
    }),
    mappingTypeDisplay: Ember.computed("mappingType", "copyFromAccount", "account.isStatisticalType", {
      get: function get() {
        if (this.get("mappingType") === "single") {
          return this.get("intl").t("account-mapping-menu.single-".concat(this.get("account.isStatisticalType") ? "model" : "budget", "-line"));
        }
        if (this.get("mappingType") === "multi") {
          return this.get("intl").t("account-mapping-menu.multiple-".concat(this.get("account.isStatisticalType") ? "model" : "budget", "-lines"));
        }
        if (this.get("mappingType") === "none") {
          return this.get("intl").t("account-mapping-menu.no-mapping");
        }
        if (this.get("mappingType") === "clone") {
          return this.get("intl").t("account-mapping-menu.same-as-account");
        }
        return intl.t("general.select_mapping_1698234255039");
      },
      set: function set(key, value) {
        this.set("mappingType", value);
        return value;
      }
    }),
    menuTriggerId: Ember.computed("elementId", function () {
      return this.get("elementId") + "-trigger";
    }),
    click: function click() {
      if (!this.get("clicked") && !this.get("readOnly")) {
        this.set("clicked", true);
      }
    },
    actions: {
      setAsSingle: function setAsSingle() {
        if (this.get("isNewMapping")) {
          this.mappingTypeChanged && this.mappingTypeChanged("single");
          return this.set("mappingType", "single");
        }
        if (this.get("account.mappingType") !== "single") {
          this.set("account.mappingType", "single");
          this.$().trigger("change");
        }
      },
      setAsMulti: function setAsMulti() {
        if (this.get("isNewMapping")) {
          this.mappingTypeChanged && this.mappingTypeChanged("multi");
          return this.set("mappingType", "multi");
        }
        this.set("account.mappingType", "multi");
        this.$().trigger("change");
      },
      setAsClone: function setAsClone() {
        this.$().trigger("change");
      },
      setAsNone: function setAsNone() {
        if (this.get("isNewMapping")) {
          this.mappingTypeChanged && this.mappingTypeChanged("none");
          return this.set("mappingType", "none");
        }
        this.set("account.mappingType", "none");
        this.$().trigger("change");
      }
    }
  });
});