define("budgeta/admin/company-admin-app/components/header/header", ["exports", "@sibp/ui-components", "react-redux", "react", "budgeta/admin/store/actions/notifActions", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _uiComponents, _reactRedux, _react, _notifActions, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Header = void 0;
  /* eslint-disable import/no-unresolved, react/jsx-no-target-blank, no-undef  */

  var intl = new _emberServiceContainer.default().lookup("intl");
  var trackEvents = new _emberServiceContainer.default().lookup("trackingEvents");
  var routerService = new _emberServiceContainer.default().lookup("routerService");
  var sessionService = new _emberServiceContainer.default().lookup("sessionService");
  var Header = _exports.Header = function Header() {
    var showLoader = _reactRedux.default.useSelector(function (state) {
      return state.notifStore.showLoader;
    });
    var noty = _reactRedux.default.useSelector(function (state) {
      return state.notifStore.noty;
    });
    var dispatch = _reactRedux.default.useDispatch();
    var selectedCompany = _reactRedux.default.useSelector(function (state) {
      return state.companyStore.selectedCompany;
    });
    var loggedInUser = _reactRedux.default.useSelector(function (state) {
      return state.securityStore.loggedInUser;
    });
    var closeNoty = function closeNoty() {
      dispatch(_notifActions.default.hideNoty());
    };
    var defaultOptions = {
      options: [{
        id: 0,
        dataHook: "admin-my-profile",
        component: _react.default.createElement("a", {
          className: "help-link",
          onClick: function onClick() {
            window.location = window.location.origin + "/my-profile/my-details";
          }
        }, _react.default.createElement(_uiComponents.default.IMAGES.ProfileSvg, null), intl.t("application.my-profile"))
      }, {
        id: 1,
        dataHook: "admin-my-budgets",
        component: _react.default.createElement("a", {
          className: "help-link",
          onClick: function onClick() {
            trackEvents.trackEvent(trackEvents.events.COMPANY_ADMIN.CLICK_MY_BUDGET);
            window.location = window.location.origin;
          }
        }, _react.default.createElement(_uiComponents.default.IMAGES.BudgetSvg, null), intl.t("company_admin_app.header.my-budgets"))
      }, {
        id: 2,
        dataHook: "admin-sign-out",
        component: _react.default.createElement("div", {
          className: "signout-heading",
          onClick: function onClick() {
            if (_const.default.FF_SSO) {
              routerService.logout();
            } else {
              sessionService.doLogout();
              window.location = window.location.origin + "/logout";
            }
          }
        }, intl.t("application.sign-out"), _react.default.createElement(_uiComponents.default.IMAGES.LogoutSvg, null))
      }]
    };
    return _react.default.createElement("div", {
      className: "app-header-container"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.Loader, {
      visible: showLoader
    }), _react.default.createElement(_uiComponents.default.COMPONENTS.Noty, {
      text: noty.text,
      visible: noty.visible,
      errorLevel: noty.errorLevel,
      onClose: closeNoty
    }), _react.default.createElement("div", {
      className: "company-logo",
      id: "logo"
    }, _react.default.createElement("span", {
      role: "button",
      tabIndex: "0",
      onClick: function onClick() {
        return window.location = window.location.origin;
      },
      onKeyUp: function onKeyUp() {
        return window.location = window.location.origin;
      }
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.AppLogo, null))), _react.default.createElement("div", {
      className: "right-side"
    }, _react.default.createElement("div", {
      className: "header-company"
    }, selectedCompany.name), _react.default.createElement("div", {
      className: "header-username"
    }, _react.default.createElement(_uiComponents.default.COMPONENTS.LabelWithDropdown, {
      closeOnClickOutside: true,
      className: "label-with-dropdown",
      options: defaultOptions.options,
      labelText: loggedInUser && loggedInUser.fullName
    }))));
  };
});