define("budgeta/admin/components/modal/modal", ["exports", "@sibp/ui-components", "react"], function (_exports, _uiComponents, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // here we display the modal with given parameters
  var Modal = function Modal(_ref) {
    var onRequestClose = _ref.onRequestClose,
      headerPrefixIcon = _ref.headerPrefixIcon,
      header = _ref.header,
      bodyLine1 = _ref.bodyLine1,
      bodyLine2 = _ref.bodyLine2,
      okButtonText = _ref.okButtonText,
      cancelButtonText = _ref.cancelButtonText,
      width = _ref.width,
      height = _ref.height,
      _ref$useBackdrop = _ref.useBackdrop,
      useBackdrop = _ref$useBackdrop === void 0 ? true : _ref$useBackdrop,
      onOk = _ref.onOk,
      onCancel = _ref.onCancel;
    var onModalCancel = function onModalCancel() {
      onRequestClose();
      onCancel && onCancel();
    };
    var onModalOk = function onModalOk() {
      onRequestClose();
      onOk && onOk();
    };
    var content = {
      header: header,
      bodyLine1: bodyLine1,
      bodyLine2: bodyLine2,
      cancelButtonText: cancelButtonText,
      okButtonText: okButtonText
    };
    var options = {
      width: width,
      height: height,
      useBackdrop: useBackdrop
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.Modal, {
      content: content,
      options: options,
      headerPrefixIcon: headerPrefixIcon,
      onCancel: onModalCancel,
      onOk: onModalOk
    });
  };
  var _default = _exports.default = Modal;
});