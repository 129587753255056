define("budgeta/components/clone-account-budgets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    cloneAccount: Ember.computed.alias("account.cloneAccount"),
    cloneBudgetLines: Ember.computed.alias("account.cloneBudgetLines"),
    cloneDimensions: Ember.computed.alias("account.cloneDimensions")
  });
});