define("budgeta/components/html5-textarea", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    error: "",
    edited: false,
    validate: function validate() {
      if (this.get("required") && !this.get("value")) {
        this.set("error", intl.t("general.this_field_is_required_1698234255345"));
        return false;
      }
    },
    actions: {
      onInput: function onInput(e) {
        this.set("value", e.target.value);
        if (this.get("edited")) {
          this.validate();
        }
      },
      onBlur: function onBlur() {
        this.set("edited", true);
        this.validate();
      },
      onInvalid: function onInvalid() {
        this.set("edited", true);
        this.validate();
      }
    }
  });
});