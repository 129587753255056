define("budgeta/components/react/dropdown-multi-select-wrapper/dropdown-multi-select-wrapper", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@sibp/ui-components", "react", "budgeta/utils/multi-select-wrapper", "budgeta/services/ember-service-container"], function (_exports, _slicedToArray2, _uiComponents, _react, _multiSelectWrapper, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable react/react-in-jsx-scope */

  var intl = new _emberServiceContainer.default().lookup("intl");

  //TODO delete this file once we start working with react and have other examples for react components in ember
  var DropdownMultiSelectWrapper = function DropdownMultiSelectWrapper(_ref) {
    var dimensionType = _ref.dimensionType,
      addAllOption = _ref.addAllOption,
      addByOption = _ref.addByOption,
      addNoneOption = _ref.addNoneOption,
      includeValues = _ref.includeValues,
      targetValue = _ref.targetValue,
      targetProperty = _ref.targetProperty,
      allowDuplicates = _ref.allowDuplicates,
      allValues = _ref.allValues,
      dataHook = _ref.dataHook,
      searchBox = _ref.searchBox,
      _ref$searchBoxPlaceho = _ref.searchBoxPlaceholder,
      searchBoxPlaceholder = _ref$searchBoxPlaceho === void 0 ? intl.t("multi-select-dropdown.search-box-placeholder") : _ref$searchBoxPlaceho,
      _ref$defaultOption = _ref.defaultOption,
      defaultOption = _ref$defaultOption === void 0 ? "all" : _ref$defaultOption,
      isStatisticalAccount = _ref.isStatisticalAccount,
      filterType = _ref.filterType,
      content = _ref.content,
      value = _ref.value,
      controller = _ref.controller,
      externalRerenderTime = _ref.externalRerenderTime,
      classNames = _ref.classNames;
    // map from filterType to its handlers
    var handlers = {
      DIMENSION: _multiSelectWrapper.DimensionFilterSelectionHandlers,
      BOOKING: _multiSelectWrapper.BookingFilterSelectionHandlers,
      ACCOUNTS: _multiSelectWrapper.AccountFilterSelectionHandlers,
      ROLES: _multiSelectWrapper.RolesFilterSelectionHandlers,
      TAGS: _multiSelectWrapper.TagsFilterSelectionHandlers,
      HEADCOUNT: _multiSelectWrapper.HeadCountFilterSelectionHandlers
    };
    var _handlers$filterType = handlers[filterType],
      getOptions = _handlers$filterType.getOptions,
      getSelectedValues = _handlers$filterType.getSelectedValues,
      onChangeOption = _handlers$filterType.onChangeOption;
    var options = getOptions({
      addAllOption: addAllOption,
      addByOption: addByOption,
      addNoneOption: addNoneOption,
      includeValues: includeValues,
      targetValue: targetValue,
      targetProperty: targetProperty,
      allowDuplicates: allowDuplicates,
      allValues: allValues,
      content: content
    });
    var _React$useState = _react.default.useState(getSelectedValues({
        options: options,
        targetValue: targetValue,
        value: value
      })),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      selectedValues = _React$useState2[0],
      setSelectedValues = _React$useState2[1];

    // we allow selected values to be empty: it means all options
    if (selectedValues.length) {
      var allOptionsIds = options.map(function (opt) {
        return opt.id;
      });
      var byIncludedInAllOptions = function byIncludedInAllOptions(valueId) {
        return allOptionsIds.includes(valueId);
      };
      selectedValues = selectedValues.filter(byIncludedInAllOptions);
      // if user navigates to sub sheets' tab with selected values that are not found in all options, we choose the default option
      // for example: user chooses "by" in cash and navigate to 'p&l' tab.
      if (selectedValues.length === 0) {
        selectedValues = [defaultOption];
      }
    }
    _react.default.useEffect(function () {
      if (!externalRerenderTime) {
        return;
      }
      var newSelectedValues = getSelectedValues({
        options: options,
        targetValue: targetValue,
        value: value
      });
      setSelectedValues(newSelectedValues);
    }, [externalRerenderTime]);
    var onChange = function onChange(_selectedValues) {
      onChangeOption({
        selectedItems: _selectedValues,
        dimensionType: dimensionType,
        targetValue: targetValue,
        controller: controller,
        isStatisticalAccount: isStatisticalAccount
      });
      setSelectedValues(_selectedValues);
    };
    return _react.default.createElement(_uiComponents.default.COMPONENTS.DropdownMultiSelect, {
      componentName: "DropdownMultiSelect",
      classNames: classNames,
      dataHook: dataHook,
      options: options,
      searchBox: searchBox,
      searchBoxPlaceholder: searchBoxPlaceholder,
      defaultOption: defaultOption,
      currentSelectedValues: selectedValues,
      onChange: onChange,
      DropdownMultiSelectRefactor: true,
      requiredMessage: intl.t("uic.required_field")
    });
  };
  var _default = _exports.default = DropdownMultiSelectWrapper;
});