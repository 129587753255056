define("budgeta/templates/components/intacct-building-budget-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3t0bTNg7",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"subtitle modal-padding\"],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"intacct-building-budget-complete.subtitle\"],null],false],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/intacct-building-budget-complete.hbs"
    }
  });
});