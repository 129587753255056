define("budgeta/components/intacct-budget-settings", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _exports.default = Ember.Component.extend({
    budgetService: Ember.inject.service(),
    isLoadingSettings: Ember.computed("data.refresh", function () {
      return !this.get("data.refresh");
    }),
    rootBudgetModules: [{
      id: "group-company",
      name: intl.t("general.name_1695611601251")
    }, {
      id: "group-company-non-profit",
      name: intl.t("general.name_1695611601240")
    }]
  });
});