define("budgeta/templates/components/svg/plus-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y2UR6WFU",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"height\",\"24\"],[\"static-attr\",\"viewBox\",\"0 0 24 24\"],[\"static-attr\",\"width\",\"24\"],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M0 0h24v24H0z\"],[\"static-attr\",\"fill\",\"none\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/plus-svg.hbs"
    }
  });
});