define("budgeta/utils/model-store", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/new-module-imports, ember/avoid-leaking-state-in-ember-objects */

  var ModelStoreItem = Ember.Object.extend({
    id: Ember.computed(function () {
      return _const.default.generateUUID();
    }),
    types: [],
    title: null,
    description: null,
    version: "1.0",
    created: new Date(),
    fileName: null,
    selected: false
  });
  var _default = _exports.default = {
    store: [ModelStoreItem.create({
      types: [intl.t("model-item.saas")],
      title: intl.t("general.title_1695611597971"),
      description: intl.t("general.the_model_calculates_the_cost_of_revenues_based_on_the_monthly_revenue_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "SaaS",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: [intl.t("model-item.cost_of_revenues")],
      title: intl.t("general.title_1695611600217"),
      description: intl.t("general.the_model_calculates_the_cost_of_revenues_based_on_the_monthly_revenue_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Cost of Revenues",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: [intl.t("model-item.headcount")],
      title: intl.t("general.title_1695611600206"),
      description: intl.t("general.the_model_calculates_the_monthly_employee_meals_expenses_based_on_headcount"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Meals"
    }), ModelStoreItem.create({
      types: [intl.t("model-item.expenses")],
      title: intl.t("general.title_1695611600195"),
      description: intl.t("general.the_model_calculates_the_monthly_travel_expenses_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Travel Abroad"
    }), ModelStoreItem.create({
      types: [intl.t("model-item.expenses")],
      title: intl.t("general.title_1695611600184"),
      description: intl.t("general.the_model_calculates_the_travel_budget_by_destination_and_department_including_a_detailed_costs"),
      version: "1.0",
      created: new Date("2017-09-01"),
      fileName: "Travel by destination and department"
    }), ModelStoreItem.create({
      types: [intl.t("model-item.government_incentives")],
      title: intl.t("general.title_1695611600173"),
      description: intl.t("general.the_model_calculates_funds_from_Office_of_the_Chief_Scientist"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "OCS Funding",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: [intl.t("model-item.sales")],
      title: intl.t("general.title_1695611600163"),
      description: intl.t("general.the_model_calculates_the_commissions_based_on_the_monthly_revenue_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Sales Commissions",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: [intl.t("model-item.company_indicators")],
      title: intl.t("general.title_1695611600152"),
      description: intl.t("general.the_model_calculates_the_Earnings"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Ebitda",
      unavailableInNonProfitBudget: true
    }), ModelStoreItem.create({
      types: [intl.t("model-item.revenues")],
      title: intl.t("general.title_1695611600142"),
      description: intl.t("general.revenue_model_based_on_the_number_of_customers_1698234257366"),
      version: "1.0",
      created: new Date("2017-05-01"),
      fileName: "Customer based Revenue"
    }), ModelStoreItem.create({
      types: [intl.t("model-item.revenues")],
      title: intl.t("general.title_1695611600131"),
      description: intl.t("general.revenue_model_based_on_the_number_of_units_1698234257366"),
      version: "1.0",
      created: new Date("2017-09-01"),
      fileName: "Unit based Revenue"
    })]
  };
});