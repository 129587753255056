define("budgeta/templates/components/import-management-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZwmIOlNG",
    "block": "{\"statements\":[[\"open-element\",\"div\",[]],[\"static-attr\",\"data-hook\",\"expand-collapse-panel\"],[\"dynamic-attr\",\"class\",[\"concat\",[\"square \",[\"helper\",[\"if\"],[[\"get\",[\"hide\"]],\"expanded\"],null]]]],[\"flush-element\"],[\"text\",\"\\n\"],[\"block\",[\"if\"],[[\"get\",[\"hide\"]]],null,2,1],[\"close-element\"],[\"text\",\"\\n\"],[\"block\",[\"unless\"],[[\"get\",[\"hide\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"import-top\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"large_header_light\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"mainHeader\"]],false],[\"close-element\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"import-step\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"partial\",\"import-management-banner-body\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"import-footer\"],[\"flush-element\"],[\"text\",\"\\n    \"],[\"partial\",\"import-management-banner-footer\"],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"title\",\"class\",\"name\",\"action\"],[[\"helper\",[\"t\"],[\"general.hide_1698824574943\"],null],\"collapse\",\"arrow_left\",\"toggleHide\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]},{\"statements\":[[\"text\",\"    \"],[\"append\",[\"helper\",[\"svg-icon\"],null,[[\"title\",\"class\",\"name\",\"action\"],[[\"helper\",[\"t\"],[\"general.show_1698824574942\"],null],\"expand\",\"arrow_right\",\"toggleHide\"]]],false],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":true}",
    "meta": {
      "moduleName": "budgeta/templates/components/import-management-banner.hbs"
    }
  });
});