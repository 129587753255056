define("budgeta/components/month-year-picker-lazy", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var formatDate = function formatDate(ctx, property, value, isInvalidProperty) {
    if (typeof value !== "undefined") {
      if (value) {
        var m = moment(value, ctx.getWithDefault("displayFormat", _const.default.MomentMonthYearDateFormat));
        if (m.isValid()) {
          if (ctx.get("endOfMonth")) {
            m.endOf("month").startOf("day");
          }
          ctx.set(property, m.toDate());
          ctx.set(isInvalidProperty, false);
        } else {
          ctx.set(property, null);
          ctx.set(isInvalidProperty, true);
        }
      } else {
        ctx.set(property, null);
        ctx.set(isInvalidProperty, false);
      }
      return value;
    }
    var d = _const.default.getMoment(ctx.get(property));
    if (d && ctx.get("endOfMonth")) {
      d.endOf("month").startOf("day");
    }
    ctx.set(isInvalidProperty, false);
    return d ? d.format(ctx.getWithDefault("displayFormat", _const.default.MomentMonthYearDateFormat)) : null;
  };
  var _default = _exports.default = Ember.Component.extend({
    active: false,
    classNames: ["month-year-picker-lazy"],
    focusOut: function focusOut() {
      var api = this.$(".month-picker input").qtip("api");
      if (api) {
        api.hide();
      }
      this.set("active", false);
    },
    valueFrom: Ember.computed("from", {
      get: function get() {
        return formatDate(this, "from", undefined, "isInValidFrom");
      },
      set: function set(key, value) {
        return formatDate(this, "from", value, "isInValidFrom");
      }
    }),
    valueTo: Ember.computed("to", {
      get: function get() {
        return formatDate(this, "to", undefined, "isInValidTo");
      },
      set: function set(key, value) {
        return formatDate(this, "to", value, "isInValidTo");
      }
    }),
    actions: {
      activate: function activate() {
        var _this = this;
        if (!this.get("disabled")) {
          this.set("active", true);
          Ember.run.scheduleOnce("afterRender", function () {
            Ember.run.next(function () {
              var api = _this.$(".month-picker input").qtip("api");
              if (api) {
                api.show();
              }
            });
          });
        }
      },
      changed: function changed() {
        this.sendAction("changed");
      }
    }
  });
});