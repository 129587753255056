define("budgeta/components/attribute-generic", ["exports", "budgeta/utils/formula", "budgeta/mixins/formula-editor-component"], function (_exports, _formula, _formulaEditorComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_formulaEditorComponent.default, {
    store: Ember.inject.service(),
    cache: Ember.inject.service(),
    budgetService: Ember.inject.service(),
    classNameBindings: ["addBudgetaClass:budgeta-type-value", "showFormulaButton:has-formula-button", "isModelLineTextArea:budgeta-type-textarea-model-line"],
    attributeBindings: ["data-key", "data-hide", "data-order", "data-alt-labels", "data-original-label", "tipId"],
    addBudgetaClass: true,
    showFormulaButton: Ember.computed("readOnly", "allowFormula", function () {
      return this.get("allowFormula") && !this.get("readOnly");
    }),
    textareaRows: Ember.computed("budget.budgetType", function () {
      return this.get("budget.budgetType") === "assumption" || this.get("budget.budgetType") === "expense-benefit" ? 1 : 3;
    }),
    previewContent: Ember.computed.alias("cleanFormulaValue"),
    isTextArea: Ember.computed.equal("args.type", "textarea"),
    isModelLineTextArea: Ember.computed.and("budget.isModelLine", "isTextArea"),
    "data-key": Ember.computed.alias("key"),
    keyDown: function keyDown(e) {
      // Ensure that "=" cannot be pressed if formula is not allowed
      if (e.key === "=" && !e.target.value && !this.get("allowFormula")) {
        e.preventDefault();
      }
      if (this.get("integersOnly") && e.key === ".") {
        e.preventDefault();
      }
    },
    init: function init() {
      this._super();
      this.set("elementId", "attribute-".concat(this.get("key"), "-").concat(this.get("elementId")));
    },
    isFormula: Ember.computed("value", function () {
      var result = typeof this.get("value") === "string" && this.get("value").substr(0, 1) === "=";
      if (typeof this.get("value") === "string" && this.get("digitsOnly") && !result) {
        // clean up value if not a formula and digits only
        if (this.get("value").substr(0, 1) === ".") {
          this.set("value", "0".concat(this.get("value")));
        }
        this.set("value", this.get("value").replace(/[^\d\.]/g, "").replace(/^[^\d]*(\d+\.?\d*).*$/, "$1"));
      }
      return result;
    }),
    isFormulaError: Ember.computed("formulaOutput", function () {
      return this.get("formulaOutput.error");
    }),
    formulaOutput: Ember.computed("value", function () {
      if (this.get("isFormula")) {
        return _formula.default.parseToFormula(this.get("value"), {
          store: this.get("store"),
          cache: this.get("cache"),
          fixedAssumptions: this.get("budgetService.fixedAssumptions"),
          budget: this.get("budgetService.selectedRoot"),
          scenario: this.get("budgetService.scenario")
        });
      }
      return null;
    })
  });
});