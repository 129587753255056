define("budgeta/components/integration-wizard-export-dimensions", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const"], function (_exports, _emberServiceContainer, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  var _default = _exports.default = Ember.Component.extend({
    dimensions: Ember.computed("model.exportDimensions", "model.entities", "model.isMultiCurrency", function () {
      var _this = this;
      var exportDimensions = this.get("model.exportDimensions") || [];
      exportDimensions.forEach(function (dim) {
        if (Ember.get(dim, "dimension.type") === "location" && _this.get("model.entities") && _this.get("model.isMultiCurrency")) {
          dim.selected = true;
          dim.disabled = true;
          dim.tooltipText = intl.t("general.work_with_multi_entity_and_currency");
        }
        if (Ember.get(dim, "dimension.type") === "category") {
          dim.selected = false;
        }
        if (_const.default.INTACCT_BLOCK_DIMENSIONS.has(Ember.get(dim, "dimension.type").toUpperCase())) {
          // currently task dimension can't be exported
          dim.disabled = true;
          dim.selected = false;
        }
      });
      return exportDimensions;
    }),
    isLoadingSettings: Ember.computed("model.refresh", function () {
      return !this.get("model.refresh");
    })
  });
});