define("budgeta/utils/multi-select-wrapper", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/defineProperty", "@sibp/ui-components", "budgeta/services/ember-service-container", "budgeta/utils/forecast", "budgeta/utils/dimensions"], function (_exports, _createForOfIteratorHelper2, _objectSpread2, _defineProperty2, _uiComponents, _emberServiceContainer, _forecast, _dimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TagsFilterSelectionHandlers = _exports.RolesFilterSelectionHandlers = _exports.HeadCountFilterSelectionHandlers = _exports.DimensionFilterSelectionHandlers = _exports.BookingFilterSelectionHandlers = _exports.AccountFilterSelectionHandlers = void 0;
  var viewSelections = new _emberServiceContainer.default().lookup("viewSelections");
  var performanceLogger = new _emberServiceContainer.default().lookup("performanceLogger");
  var getCheckType = function getCheckType(_ref) {
    var itemId = _ref.itemId;
    var checkboxTypes = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      all: _uiComponents.default.CHECKBOX_UTILS.CHECK_TYPE_ENUM.SELECT_ALL,
      by: _uiComponents.default.CHECKBOX_UTILS.CHECK_TYPE_ENUM.SINGLE
    }, _forecast.default.bookingsGrouping, _uiComponents.default.CHECKBOX_UTILS.CHECK_TYPE_ENUM.SINGLE), _forecast.default.roleGrouping, _uiComponents.default.CHECKBOX_UTILS.CHECK_TYPE_ENUM.SINGLE), "All roles", _uiComponents.default.CHECKBOX_UTILS.CHECK_TYPE_ENUM.SELECT_ALL);
    return checkboxTypes[itemId] || _uiComponents.default.CHECKBOX_UTILS.CHECK_TYPE_ENUM.REGULAR;
  };
  var isByOptionSelected = function isByOptionSelected(selectedValues) {
    var byIds = ["by", _forecast.default.bookingsGrouping, _forecast.default.roleGrouping];
    if (Array.isArray(selectedValues)) {
      return selectedValues.length === 1 && byIds.includes(selectedValues[0]);
    } else {
      return byIds.includes(selectedValues);
    }
  };
  var DimensionFilterSelectionHandlers = _exports.DimensionFilterSelectionHandlers = {
    getOptions: function getOptions(_ref2) {
      var addAllOption = _ref2.addAllOption,
        addByOption = _ref2.addByOption,
        addNoneOption = _ref2.addNoneOption,
        includeValues = _ref2.includeValues,
        targetValue = _ref2.targetValue,
        targetProperty = _ref2.targetProperty,
        allowDuplicates = _ref2.allowDuplicates,
        allValues = _ref2.allValues;
      var toMultiSelectItem = function toMultiSelectItem(item) {
        var tempObj = (0, _objectSpread2.default)({}, item);
        tempObj.id = item.uniqueId;
        tempObj.title = item.displayName;
        tempObj.checkType = getCheckType({
          itemId: item.uniqueId
        });
        return tempObj;
      };
      var dimensionObj = targetValue;
      var dimensionOptions = _dimensions.default.getDimensionFilterOptions({
        dimension: dimensionObj.dimension,
        addAllOption: addAllOption,
        addByOption: addByOption,
        addNoneOption: addNoneOption,
        includeValues: includeValues,
        targetValue: targetValue,
        targetProperty: targetProperty,
        allowDuplicates: allowDuplicates,
        allValues: allValues
      });
      var multiSelectItems = dimensionOptions.map(toMultiSelectItem);
      return multiSelectItems;
    },
    getSelectedValues: function getSelectedValues(_ref3) {
      var targetValue = _ref3.targetValue;
      var dimensionObj = targetValue;
      if (Array.isArray(dimensionObj.selectedValue)) {
        return dimensionObj.selectedValue;
      }
      return [dimensionObj.selectedValue];
    },
    onChangeOption: function onChangeOption(_ref4) {
      var selectedItems = _ref4.selectedItems,
        dimensionType = _ref4.dimensionType,
        targetValue = _ref4.targetValue;
      var dimensionObj = targetValue;
      if (selectedItems.length === 0 || selectedItems.includes("all")) {
        Ember.set(dimensionObj, "selectedValue", "all");
      } else {
        performanceLogger.setFromDataStartTime(performanceLogger.get("actions.FILTER_BY"));
        if (isByOptionSelected(selectedItems)) {
          Ember.set(dimensionObj, "selectedValue", "by");
          if (viewSelections.get("currency") === _forecast.default.currencyGrouping) {
            viewSelections.set("currency", "");
          }

          // keep only one dimension to get filtered by BY
          // sp if other dimension is filtered by BY, change it to all
          var shouldUpdate = false;
          var _iterator = (0, _createForOfIteratorHelper2.default)(viewSelections.get("dimensions")),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var _dimensionObj = _step.value;
              if (_dimensionObj.dimension.get("type") !== dimensionType && isByOptionSelected(_dimensionObj.selectedValue)) {
                Ember.set(_dimensionObj, "selectedValue", "all");
                shouldUpdate = true;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          shouldUpdate && viewSelections.set("filtersUpdatedTime.DIMENSIONS", new Date().getTime());
        } else {
          Ember.set(dimensionObj, "selectedValue", selectedItems);
        }
      }
    }
  };
  var BookingFilterSelectionHandlers = _exports.BookingFilterSelectionHandlers = {
    getOptions: function getOptions(_ref5) {
      var content = _ref5.content;
      return content.map(function (item) {
        item.id = item.value;
        item.title = item.name;
        item.checkType = getCheckType({
          itemId: item.value
        });
        return item;
      });
    },
    getSelectedValues: function getSelectedValues(_ref6) {
      var value = _ref6.value;
      if (!value || value === "all") {
        return ["all"];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    },
    onChangeOption: function onChangeOption(_ref7) {
      var selectedItems = _ref7.selectedItems,
        controller = _ref7.controller;
      if (selectedItems.length === 0 || selectedItems.some(function (item) {
        return item === "all";
      })) {
        Ember.set(controller, "bookingType", "all");
      } else if (isByOptionSelected(selectedItems)) {
        Ember.set(controller, "bookingType", _forecast.default.bookingsGrouping);
      } else {
        Ember.set(controller, "bookingType", selectedItems);
      }
    }
  };
  var AccountFilterSelectionHandlers = _exports.AccountFilterSelectionHandlers = {
    getOptions: function getOptions(_ref8) {
      var content = _ref8.content;
      return content.map(function (item) {
        item.id = item.value;
        item.title = item.name;
        item.checkType = getCheckType({
          itemId: item.value
        });
        return item;
      });
    },
    getSelectedValues: function getSelectedValues(_ref9) {
      var value = _ref9.value;
      if (!value || value === "all") {
        return ["all"];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    },
    onChangeOption: function onChangeOption(_ref10) {
      var selectedItems = _ref10.selectedItems,
        controller = _ref10.controller,
        isStatisticalAccount = _ref10.isStatisticalAccount;
      var keyToUpdate = isStatisticalAccount ? "selectedStatAccount" : "selectedAccount";
      if (selectedItems.length === 0 || selectedItems.some(function (item) {
        return item === "all";
      })) {
        Ember.set(controller, keyToUpdate, "");
      } else if (isByOptionSelected(selectedItems)) {
        Ember.set(controller, keyToUpdate, "by");
      } else {
        Ember.set(controller, keyToUpdate, selectedItems);
      }
    }
  };
  var RolesFilterSelectionHandlers = _exports.RolesFilterSelectionHandlers = {
    getOptions: function getOptions(_ref11) {
      var content = _ref11.content;
      return content.map(function (item) {
        item.id = item.value;
        item.title = item.name;
        item.checkType = getCheckType({
          itemId: item.id
        });
        return item;
      });
    },
    getSelectedValues: function getSelectedValues(_ref12) {
      var value = _ref12.value;
      if (!value || value === "All roles") {
        return ["All roles"];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    },
    onChangeOption: function onChangeOption(_ref13) {
      var selectedItems = _ref13.selectedItems,
        controller = _ref13.controller;
      if (selectedItems.length === 0 || selectedItems.some(function (item) {
        return item === "All roles";
      })) {
        Ember.set(controller, "role", "");
      } else if (isByOptionSelected(selectedItems)) {
        Ember.set(controller, "role", _forecast.default.roleGrouping);
      } else {
        Ember.set(controller, "role", selectedItems);
      }
    }
  };
  var HeadCountFilterSelectionHandlers = _exports.HeadCountFilterSelectionHandlers = {
    getOptions: function getOptions(_ref14) {
      var content = _ref14.content;
      return content.map(function (item) {
        item.title = item.text;
        item.checkType = getCheckType({
          itemId: item.id
        });
        return item;
      });
    },
    getSelectedValues: function getSelectedValues(_ref15) {
      var value = _ref15.value;
      if (value === "all" || value.length === 0) {
        return ["all"];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    },
    onChangeOption: function onChangeOption(_ref16) {
      var selectedItems = _ref16.selectedItems,
        controller = _ref16.controller;
      if (selectedItems.length === 0 || selectedItems.some(function (item) {
        return item === "all";
      })) {
        Ember.set(controller, "headcountType", "all");
      } else {
        Ember.set(controller, "headcountType", selectedItems);
      }
    }
  };
  var TagsFilterSelectionHandlers = _exports.TagsFilterSelectionHandlers = {
    getOptions: function getOptions(_ref17) {
      var content = _ref17.content;
      return content.map(function (item) {
        item.id = item.value;
        item.title = item.name;
        item.checkType = getCheckType({
          itemId: item.id
        });
        return item;
      });
    },
    getSelectedValues: function getSelectedValues(_ref18) {
      var value = _ref18.value;
      if (!value || value === "all") {
        return ["all"];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    },
    onChangeOption: function onChangeOption(_ref19) {
      var selectedItems = _ref19.selectedItems,
        controller = _ref19.controller;
      if (selectedItems.length === 0 || selectedItems.some(function (item) {
        return item === "all";
      })) {
        Ember.set(controller, "selectedTag", []);
      } else {
        Ember.set(controller, "selectedTag", selectedItems);
      }
    }
  };
});