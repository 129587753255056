define("budgeta/components/intacct-data-populate", ["exports", "budgeta/services/ember-service-container", "budgeta/utils/const", "budgeta/config/environment", "budgeta/utils/intacct"], function (_exports, _emberServiceContainer, _const, _environment, _intacct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/closure-actions, ember/no-side-effects, ember/no-observers */
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: [":intacct-data-populate"],
    budgetService: Ember.inject.service(),
    permissions: Ember.inject.service(),
    didRender: function didRender() {},
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get("data.selectedMainBooksWizard")) {
        this.set("data.selectedMainBooksWizard", this.get("data.financialSetup.defaultBook"));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.set("data.lastFiscalYear", this.get("data.pastFiscalYear"));
    },
    click: function click() {
      this.sendAction("removeError");
    },
    fiscalYearOptions: Ember.computed("budgetService.selectedRoot.budgetAttributes.startDate", "budgetService.selectedRoot.budgetAttributes.endDate", "permissions.isIntacctQAUser", "config.INTACCT_DRAG_AND_DROP_VISIBLE", "config.budgetaEnvironment", function () {
      var result = [],
        thisYear = new Date().getYear() + 1900,
        minYear = thisYear - 1,
        maxYear = thisYear;
      var budgetStartYear = _const.default.getFiscalYearForDate(this.get("budgetService.selectedRoot.budgetAttributes.startDate"), this.get("budgetService.selectedRoot.yearStart"));
      if (budgetStartYear - 1 < minYear) {
        minYear = budgetStartYear - 1;
      }
      var budgetEndYear = new Date(this.get("budgetService.selectedRoot.budgetAttributes.endDate")).getFullYear();
      if (budgetEndYear > maxYear) {
        maxYear = budgetEndYear;
      }
      for (var curYear = minYear; curYear <= maxYear; curYear++) {
        result.pushObject({
          id: curYear,
          text: curYear + ""
        });
      }
      if (!this.get("data.pastFiscalYear")) {
        this.set("data.pastFiscalYear", budgetStartYear - 1);
      }
      if (this.get("permissions.isIntacctQAUser") && _environment.default.INTACCT_DRAG_AND_DROP_VISIBLE && (_environment.default.environment === "staging" || _environment.default.environment === "development" || _environment.default.budgetaEnvironment === "qa" || _environment.default.budgetaEnvironment === "dev")) {
        result.addObject({
          id: 2019,
          text: "2019"
        });
        result.addObject({
          id: 2018,
          text: "2018"
        });
      }
      return result;
    }),
    sliderValue: Ember.computed("data.pastFiscalYear", function () {
      if (this.get("data.pastDataCutoffDate") && this.get("data.pastFiscalYear") === this.get("data.lastFiscalYear")) {
        var month = this.get("data.pastDataCutoffDate").getMonth();
        var year = this.get("data.pastDataCutoffDate").getFullYear();
        var selectedMonth = this.get("monthsList").find(function (item) {
          return item.month === month && item.year === year;
        });
        return selectedMonth ? this.get("monthsList").indexOf(selectedMonth) : 12;
      }
      this.set("data.lastFiscalYear", this.get("data.pastFiscalYear"));
      var currentDate = new Date().beginningOfMonth();
      var pastFisicalYearDateStart = new Date(this.get("data.pastFiscalYear") * 1 - (this.get("budgetService.selectedRoot.yearStart") ? 1 : 0), this.get("budgetService.selectedRoot.yearStart"));
      var pastFisicalYearDateEnd = new Date(pastFisicalYearDateStart).addDays(364);
      var numberOfMonth = 6;
      if (currentDate.isAfter(pastFisicalYearDateEnd)) {
        return 12;
      } else if (currentDate.isBefore(pastFisicalYearDateStart)) {
        return 0;
      } else {
        numberOfMonth = currentDate.getMonth() - pastFisicalYearDateStart.getMonth();
        if (numberOfMonth < 0) {
          numberOfMonth = 12 + numberOfMonth;
        }
      }
      return numberOfMonth;
    }),
    sliderValueChanged: Ember.observer("sliderValue", "data.pastFiscalYear", "monthsList", "budgetService.selectedRoot.yearStart", "budgetService.selectedRoot.budgetAttributes.endDate", function () {
      var pastDataCutoffDate;
      if (this.get("budgetList.length") && this.get("sliderValue") !== 12) {
        pastDataCutoffDate = new Date(this.get("monthsList")[this.get("sliderValue")].year, this.get("monthsList")[this.get("sliderValue")].month);
        this.set("data.pastDataCutoffDate", pastDataCutoffDate);
      } else {
        pastDataCutoffDate = new Date(this.get("monthsList")[0].year + 1, this.get("monthsList")[0].month);
        this.set("data.pastDataCutoffDate", pastDataCutoffDate);
      }
    }).on("didInsertElement"),
    hideActuals: Ember.computed("sliderValue", function () {
      return this.get("sliderValue") / 12 * 100 < 1;
    }),
    hideBudget: Ember.computed("sliderValue", function () {
      return (12 - this.get("sliderValue")) / 12 * 100 < 1;
    }),
    actualsWidthStyle: Ember.computed("sliderValue", function () {
      return Ember.String.htmlSafe("width: ".concat(this.get("sliderValue") / 12 * 100, "%;"));
    }),
    budgetWidthStyle: Ember.computed("sliderValue", function () {
      return Ember.String.htmlSafe("width: ".concat((12 - this.get("sliderValue")) / 12 * 100, "%;"));
    }),
    budgetList: Ember.computed("data.{budgetList,singleEntity}", function () {
      var _this = this;
      return (this.get("data.budgetList") || []).filter(function (b) {
        return _this.get("data.singleEntity") !== "1" || !b.MEGAENTITYID || b.MEGAENTITYID === _this.get("data.selectedEntity.id");
      }).map(function (b) {
        return {
          id: b.RECORDNO,
          text: b.BUDGETID
        };
      }).sortBy("id");
    }),
    mainReportiongBooksList: Ember.computed("data.{mainReportingBooks,userDefinedBooks}", function () {
      return _intacct.default.mainReportiongBooksList(this.get("data"));
    }),
    additionalReportiongBooksList: Ember.computed("data.userDefinedBooks", function () {
      return _intacct.default.additionalReportiongBooksList(this.get("data"));
    }),
    mainBookTitle: Ember.computed("data.userDefinedBooks", function () {
      return _intacct.default.mainBookTitle(this.get("data"));
    }),
    bookToolTip: Ember.computed("data.selectedMainBooksWizard", function () {
      var mainBooks = this.get("mainReportiongBooksList");
      var selecedtMainBook = this.get("data.selectedMainBooksWizard") || this.get("data.financialSetup.defaultBook");
      return mainBooks.findBy("id", selecedtMainBook) ? mainBooks.findBy("id", selecedtMainBook).text : "";
    }),
    monthsList: Ember.computed("budgetService.selectedRoot.yearStart", "data.pastFiscalYear", function () {
      var pastFiscalYear = this.get("data.pastFiscalYear") * 1;
      var result = [{
        name: intl.t("general.name_1695611601425"),
        isJan: true,
        month: 0,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601414"),
        isJan: false,
        month: 1,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601404"),
        isJan: false,
        month: 2,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601393"),
        isJan: false,
        month: 3,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601382"),
        isJan: false,
        month: 4,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601371"),
        isJan: false,
        month: 5,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601360"),
        isJan: false,
        month: 6,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601349"),
        isJan: false,
        month: 7,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601338"),
        isJan: false,
        month: 8,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601327"),
        isJan: false,
        month: 9,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601316"),
        isJan: false,
        month: 10,
        year: pastFiscalYear
      }, {
        name: intl.t("general.name_1695611601305"),
        isJan: false,
        month: 11,
        year: pastFiscalYear
      }];
      if (this.get("budgetService.selectedRoot.yearStart") > 0) {
        var moveMonths = result.splice(this.get("budgetService.selectedRoot.yearStart"));
        moveMonths.setEach("year", pastFiscalYear - 1);
        result = moveMonths.concat(result);
      }
      return result;
    }),
    years: Ember.computed("monthsList.length", "data.pastFiscalYear", function () {
      var fiscalYear = this.get("data.pastFiscalYear") * 1 - (this.get("budgetService.selectedRoot.yearStart") ? 1 : 0);
      var years = [{
        yearTitle: fiscalYear,
        style: Ember.String.htmlSafe("width: 100%;")
      }];
      var index = this.get("monthsList").indexOf(this.get("monthsList").findBy("isJan"));
      if (index !== 0) {
        years[0].style = Ember.String.htmlSafe("width: calc(".concat(index / 12 * 100, "% + 32px);"));
        years.push({
          yearTitle: years[0].yearTitle + 1,
          style: Ember.String.htmlSafe("")
        });
      }
      return years;
    }),
    hasTwoYears: Ember.computed("years.length", function () {
      return this.get("years.length") > 1;
    }),
    actions: {
      additionalBookChanged: function additionalBookChanged(book) {
        this.set("data.selectedAdditionalBooksWizard", book.mapBy("id"));
      }
    }
  });
});