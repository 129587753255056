define("budgeta/components/textarea-auto-height", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ["textarea-auto-height", "no-border", "no-outline", "no-padding"],
    tagName: "textarea",
    attributeBindings: ["contenteditable", "data-gramm", "spellcheck", "data-gramm_editor"],
    "data-gramm": false,
    spellcheck: false,
    "data-gramm_editor": false,
    keyUp: function keyUp() {
      var _this = this;
      Ember.run.next(function () {
        if (_this.$() && _this.$()[0]) {
          _this.$()[0].style.height = 5 + "px";
          _this.$()[0].style.height = _this.$()[0].scrollHeight + "px";
          _this.set("value", _this.$()[0].value.trim());
        }
      });
    }
  });
});