define("budgeta/templates/add-custom-view-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Vv4lolFL",
    "block": "{\"statements\":[[\"block\",[\"add-custom-view-modal\"],null,[[\"controllertmp\"],[[\"get\",[null]]]],0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"form\",[]],[\"modifier\",[\"action\"],[[\"get\",[null]],\"confirm\"],[[\"on\"],[\"submit\"]]],[\"flush-element\"],[\"text\",\"\\n    \"],[\"open-element\",\"label\",[]],[\"flush-element\"],[\"append\",[\"helper\",[\"t\"],[\"view-toolbar.custom-view.add-custom-view-modal.name\"],null],false],[\"close-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"input\"],null,[[\"value\",\"placeholder\"],[[\"get\",[\"newView\"]],[\"helper\",[\"t\"],[\"view-toolbar.custom-view.add-custom-view-modal.type-view-name\"],null]]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"error\"],[\"static-attr\",\"style\",\"margin-left: 31%;\"],[\"flush-element\"],[\"append\",[\"unknown\",[\"errors\",\"newView\"]],false],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/add-custom-view-modal.hbs"
    }
  });
});