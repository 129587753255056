define("budgeta/components/documentation-link", ["exports", "budgeta/utils/const"], function (_exports, _const) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "a",
    attributeBindings: ["target", "href"],
    target: "_blank",
    href: _const.default.HELP_URL,
    click: function click() {
      this.sendAction();
    }
  });
});