define("budgeta/services/audit-logging/audit-logging-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* ====== EXAMPLES CAN BE FOUND BELOW ====== */

  var AuditLoggingParser = function AuditLoggingParser(appMessages) {
    return {
      parse: function parse(logObject) {
        if (!logObject) {
          return "";
        }
        var messageBuilderFunc = appMessages[logObject.actionId];
        if (messageBuilderFunc) {
          try {
            return messageBuilderFunc(logObject);
          } catch (ex) {
            //handle below in return "";
          }
        }
        return "";
      }
    };
  };
  var _default = _exports.default = AuditLoggingParser;
});