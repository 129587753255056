define("budgeta/templates/components/svg/delete-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "v7gvkthS",
    "block": "{\"statements\":[[\"open-element\",\"svg\",[]],[\"static-attr\",\"width\",\"16\"],[\"static-attr\",\"height\",\"16\"],[\"static-attr\",\"viewBox\",\"0 0 16 16\"],[\"static-attr\",\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[\"flush-element\"],[\"text\",\"\\n  \"],[\"open-element\",\"path\",[]],[\"static-attr\",\"d\",\"M2.64 14C2.8 15.12 3.68 16 4.8 16H11.2C12.32 16 13.28 15.12 13.36 14L14.24 5.6H1.76L2.64 14ZM15.2 3.2H14.56L13.28 1.36C13.04 1.04 12.72 0.8 12.24 0.8H9.92C10 0.64 9.92 0.4 9.84 0.24C9.76 0.08 9.6 0 9.44 0H6.56C6.4 0 6.24 0.08 6.08 0.24C6 0.4 6 0.64 6 0.8H3.76C3.36 0.8 2.96 1.04 2.8 1.36L1.44 3.2H0.8C0.32 3.2 0 3.52 0 4C0 4.48 0.32 4.8 0.8 4.8H15.2C15.68 4.8 16 4.48 16 4C16 3.52 15.68 3.2 15.2 3.2Z\"],[\"flush-element\"],[\"close-element\"],[\"text\",\"\\n\"],[\"close-element\"]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/svg/delete-svg.hbs"
    }
  });
});