define("budgeta/mixins/budgeta-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var pageMap = {
    index: "input",
    forecast: "view"
  };
  var _default = _exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),
    activityLog: Ember.inject.service(),
    trackingEvents: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _arguments = arguments,
        _this = this;
      if (this.routeName === "main.budget.forecast") {
        return new Ember.RSVP.Promise(function (resolve) {
          Ember.$("header .busy").show();
          Ember.run.next(function () {
            _this._super.apply(_this, _arguments);
            resolve();
          });
        });
      }
      return this._super.apply(this, arguments);
    },
    actions: {
      willTransition: function willTransition() {
        Ember.run(function () {
          Ember.$("header .busy").show();
        });
        return this._super.apply(this, arguments);
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        if (window.zE) {
          window.zE(function () {
            window.zE.show();
          });
        }
        var page = this.get("routeName");
        if (this.get("session.isAuthenticated")) {
          try {
            page = this.get("routeName").replace("main.budget.", "");
            page = pageMap[page] || page;
            if (this.get("controller.actuals")) {
              page = "actuals";
            }
            this.get("activityLog").log(page);
          } catch (e) {}
        }
        if (this.get("controller.action") === "signup") {
          page = "signup";
        }
        Ember.run.scheduleOnce("afterRender", this, function () {
          Ember.$("header .busy").hide();
          for (var i in Ember.$.cache) {
            if (Ember.$.cache.hasOwnProperty(i)) {
              if (Ember.$.cache[i].handle && Ember.$.cache[i].handle.elem && document !== Ember.$.cache[i].handle.elem && !Ember.$.includes(document, Ember.$.cache[i].handle.elem)) {
                //we have an event handler pointing to a detached dom element!
                //this is a memory leak as this detached dom element cannot be garbage collected until
                //all references to it are removed. So lets delete the event handler to get memory back!
                var orphan = Ember.$(Ember.$.cache[i].handle.elem);
                Ember.$("body").append(orphan);
                orphan.off();
                orphan.remove();
                orphan = null;
              }
            }
          }
        });
      }
    }
  });
});