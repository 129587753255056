define("budgeta/components/dimension-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    enabled: true,
    dimensionOptionsDisplay: Ember.computed("dimensionsMapping.@each.dimensionId", "dimensionOptions.length", "dimensionMapping.dimensionId", function () {
      var dimensionId = this.get("dimensionMapping.dimensionId");
      var selectedDimensions = this.get("dimensionsMapping").mapBy("dimensionId");
      if (!this.get("dimensionOptions").findBy("id", dimensionId)) {
        this.set("dimensionMapping.dimensionId", null);
      }
      return this.get("dimensionOptions").filter(function (dimension) {
        return Ember.get(dimension, "id") === dimensionId || selectedDimensions.indexOf(Ember.get(dimension, "id")) === -1;
      });
    }),
    actions: {
      powerSelectChange: function powerSelectChange(dimId) {
        this.get("onDimensionMappingChange")(dimId);
      }
    }
  });
});