define("budgeta/services/drag-drop-service", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  // If you use this service, please read the comment in the bottom
  var _default = _exports.default = Ember.Service.extend({
    create: function create(_ref) {
      var _ref$onDraggedItemInd = _ref.onDraggedItemIndexChanged,
        onDraggedItemIndexChanged = _ref$onDraggedItemInd === void 0 ? function () {} : _ref$onDraggedItemInd,
        isDraggableEnabled = _ref.isDraggableEnabled,
        _ref$ghostElementClas = _ref.ghostElementClassName,
        ghostElementClassName = _ref$ghostElementClas === void 0 ? "drag-and-drop-ghost" : _ref$ghostElementClas;
      var dragDropKeyId = "treeManager.dragAndDrop";
      var DRAGGING_ELEMENT_INDEX_KEY = "".concat(dragDropKeyId, ".draggingElementIndex");
      var CLASSNAMES = {
        PLACEHOLDER: intl.t("general.PLACEHOLDER_1695611612806"),
        DROP_TARGET_TOP: "drop-target-top",
        DROP_TARGET_BOTTOM: "drop-target-bottom"
      };
      var removeDropTargets = function removeDropTargets(element) {
        element.parentElement.childNodes.forEach(function (child) {
          child.classList.remove(CLASSNAMES.DROP_TARGET_TOP);
          child.classList.remove(CLASSNAMES.DROP_TARGET_BOTTOM);
        });
      };
      return {
        draggable: "true",
        dragStart: function dragStart(ev) {
          if (!isDraggableEnabled()) {
            return false;
          }
          this.set(dragDropKeyId, {}); // init the object
          this.set(DRAGGING_ELEMENT_INDEX_KEY, this.get("itemIndex"));
          ev.currentTarget.parentNode.classList.add(ghostElementClassName);
        },
        dragEnd: function dragEnd(ev) {
          removeDropTargets(ev.currentTarget.parentNode);
          ev.currentTarget.parentNode.classList.remove(ghostElementClassName);
          return true;
        },
        dragEnter: function dragEnter() {
          return false;
        },
        dragLeave: function dragLeave(ev) {
          removeDropTargets(ev.currentTarget.parentNode);
        },
        drop: function drop(ev) {
          var itemIndex = this.get("itemIndex");
          removeDropTargets(ev.currentTarget.parentNode);
          ev.currentTarget.parentNode.classList.remove(ghostElementClassName);
          if (this.get(DRAGGING_ELEMENT_INDEX_KEY) !== itemIndex) {
            onDraggedItemIndexChanged({
              currentIndex: this.get(DRAGGING_ELEMENT_INDEX_KEY),
              newIndex: itemIndex
            });
            this.set(dragDropKeyId, null);
          }
          return true;
        },
        dragOver: function dragOver(e) {
          var itemIndex = this.get("itemIndex");
          var currentElementOver = e.currentTarget.parentNode;
          var isElementDragOverItself = itemIndex === this.get(DRAGGING_ELEMENT_INDEX_KEY);
          removeDropTargets(currentElementOver);
          if (isElementDragOverItself) {
            return;
          }
          var isDragTop = itemIndex < this.get(DRAGGING_ELEMENT_INDEX_KEY);
          var isDragBottom = itemIndex > this.get(DRAGGING_ELEMENT_INDEX_KEY);
          if (isDragTop) {
            currentElementOver.classList.add(CLASSNAMES.DROP_TARGET_TOP);
          } else if (isDragBottom) {
            currentElementOver.classList.add(CLASSNAMES.DROP_TARGET_BOTTOM);
          }
          return false;
        }
      };
    }
  }); // PLEASE NOTE:
  // itemIndex is the index of the element in the array.
  // The consumer of this service (i.e. forecast-row-draggable) should compute item index by itself because of Ember bug:
  // https://github.com/adopted-ember-addons/ember-collection/pull/110/commits/519a565c07241db4b45990212b2d48da9246733a
  // It looks like it was fixed in version: 2.16 (Currently, we are on 2.14).
});