define("budgeta/components/help-iq", ["exports", "budgeta/services/ember-service-container"], function (_exports, _emberServiceContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var intl = new _emberServiceContainer.default().lookup("intl");
  /* eslint-disable ember/no-observers, ember/closure-actions */
  var _default = _exports.default = Ember.Component.extend({
    tooltips: Ember.inject.service(),
    tagName: "span",
    classNameBindings: [":help-iq", "showHelpIq::hidden"],
    attributeBindings: ["href", "data-alt-key"],
    showHelpIq: Ember.computed("docId", "tooltipText", "tooltipTextListValues.length", function () {
      return this.get("docId") || this.get("tooltipText") || this.get("tooltipTextListValues.length");
    }),
    type: "modal",
    // allowed values: modal, tip
    docId: null,
    enableChoice: false,
    iconName: Ember.computed("defaultName", function () {
      return this.get("defaultName") || "info";
    }),
    dontShow: Ember.computed("docId", "tipsToHide", "tooltipText", "tooltipTextListValues.length", function () {
      return this.get("tipsToHide") ? this.get("tipsToHide").indexOf(this.get("docId")) >= 0 : false;
    }),
    neverAgain: false,
    neverAgainObserver: Ember.observer("neverAgain", function () {
      var api = this.$().qtip("api");
      if (api) {
        api.destroy();
      }
      if (this.get("neverAgain")) {
        this.sendAction("hideTip", this.get("docId"));
      }
    }),
    isTip: Ember.computed("type", function () {
      return this.get("type") === "tip";
    }),
    style: Ember.computed("height", "width", function () {
      return Ember.String.htmlSafe("max-width:".concat(this.get("maxWidth") ? this.get("maxWidth") + "px;" : "100%", ";overflow-y: auto;").concat(this.get("height") ? "max-height: " + this.get("height") + "px;" : "").concat(this.get("width") ? "width: " + this.get("width") + "px;" : "").concat(this.get("textAlign") ? "text-align: " + this.get("textAlign") : ""));
    }),
    tooltip: Ember.computed("docId", function () {
      return this.get("tooltips").findById(this.get("docId"));
    }),
    mouseEnter: function mouseEnter() {
      if (this.$() && this.$().qtip("api")) {
        return true;
      }
      var positionObj = {
        my: "left top",
        at: "right center",
        viewport: true
      };
      var tipObj = {
        width: 12,
        offset: 10,
        mimic: "left center"
      };
      if (this.get("showAbove")) {
        positionObj = {
          my: "bottom center",
          at: "top center",
          viewport: true,
          adjust: {
            x: 0,
            y: -15
          }
        };
        tipObj = {
          width: 12,
          offset: 0,
          mimic: "bottom center"
        };
      }
      if (this.$() && !this.$().qtip("api") && (!this.get("dontShow") && this.get("docId") || this.get("tooltipText") || this.get("tooltipTextListValues.length"))) {
        var content = this.$(".tip-content");
        var classes = "qtip-light help-iq-tip " + (this.get("addClasses") || "");
        this.$().qtip({
          content: {
            text: content
          },
          position: positionObj,
          show: {
            event: "mouseenter",
            ready: true
          },
          hide: {
            delay: 200,
            fixed: true
          },
          style: {
            classes: classes,
            tip: tipObj
          }
        });
      }
      return true;
    },
    tooltipTextListValues: Ember.computed("tooltipTextList", "valueId", "valueId2", function () {
      if (this.get("tooltipTextList")) {
        var valueId = this.get("valueId");
        var valueId2 = this.get("valueId2");
        return this.get("tooltipTextList").map(function (value) {
          if (valueId2) {
            return "".concat(Ember.get(value, valueId), " (").concat(Ember.get(value, valueId2), ")");
          }
          return Ember.get(value, valueId);
        });
      }
    }),
    willDestroyElement: function willDestroyElement() {
      var api = this.$().qtip("api");
      if (api) {
        api.destroy();
      }
    },
    actions: {
      readMore: function readMore() {
        var api = this.$().qtip("api");
        if (api) {
          api.hide();
        }
        this.sendAction("action", "modal", {
          model: this.get("href"),
          title: this.get("title"),
          templateName: "modal-iframe",
          hideCancel: true,
          confirmActionName: intl.t("general.confirmActionName_1695611613119")
        });
      }
    }
  });
});