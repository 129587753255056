define("budgeta/generic-file/components/loader-screen/loader-screen", ["exports", "react", "@sibp/ui-components"], function (_exports, _react, _uiComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LoaderScreen = function LoaderScreen(_ref) {
    var text = _ref.text,
      visible = _ref.visible;
    return _react.default.createElement("div", {
      className: "loader-screen-container ".concat(visible ? "show-loader" : "hide-loader")
    }, _react.default.createElement("label", {
      className: "large_header"
    }, text), _react.default.createElement(_uiComponents.default.COMPONENTS.Loader, {
      visible: visible
    }));
  };
  var _default = _exports.default = LoaderScreen;
});