define("budgeta/templates/components/intacct-account-departments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ykmg5Tcj",
    "block": "{\"statements\":[[\"block\",[\"each\"],[[\"get\",[\"account\",\"selectedDepartments\"]]],null,0]],\"locals\":[],\"named\":[],\"yields\":[],\"blocks\":[{\"statements\":[[\"text\",\"  \"],[\"open-element\",\"div\",[]],[\"static-attr\",\"class\",\"column status-column\"],[\"dynamic-attr\",\"data-idx\",[\"get\",[\"idx\"]],null],[\"flush-element\"],[\"text\",\"\\n    \"],[\"append\",[\"helper\",[\"budgeta-checkbox\"],null,[[\"checked\"],[[\"get\",[\"dep\",\"selected\"]]]]],false],[\"text\",\"\\n  \"],[\"close-element\"],[\"text\",\"\\n\"]],\"locals\":[\"dep\",\"idx\"]}],\"hasPartials\":false}",
    "meta": {
      "moduleName": "budgeta/templates/components/intacct-account-departments.hbs"
    }
  });
});